var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-card-title',[_vm._v("Бързо добавяне на начални тарифи и услуги")]),_c('v-card-actions',[(_vm.showSaveButton)?_c('btn',{attrs:{"action":"Save"},on:{"click":_vm.save}}):_vm._e()],1),_c('v-card-text',[_c('alert',{staticClass:"ma-5",attrs:{"type":"info","prominent":""}},[_vm._v(_vm._s(_vm.alertInfoText))]),(
                _vm.practiceTariffsAndServicesInfo.practiceHasNhifFund &&
                !_vm.practiceTariffsAndServicesInfo.isAddedNhifExamOffer &&
                !_vm.practiceTariffsAndServicesInfo.isAddedRetiredExamOffer
            )?_c('v-radio-group',{attrs:{"label":`Варианти за добавяне на услуги за преглед по тарифа "${
                _vm.practiceTariffsAndServicesInfo.nhifTariffName ?? 'НЗОК'
            }"`},model:{value:(_vm.practiceTariffsAndServicesInfo.selectedExamChoice),callback:function ($$v) {_vm.$set(_vm.practiceTariffsAndServicesInfo, "selectedExamChoice", $$v)},expression:"practiceTariffsAndServicesInfo.selectedExamChoice"}},[_c('v-radio',{attrs:{"label":_vm.twoServicesExamText,"value":"twoServices"}}),_c('v-radio',{attrs:{"label":_vm.threeServicesText,"value":"threeServices"}}),_c('v-radio',{attrs:{"label":_vm.fiveServicesText,"value":"fiveServices"}}),_c('v-radio',{attrs:{"label":_vm.fourServicesText,"value":"fourServices"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }