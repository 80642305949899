<!-- Модален диалог за конкретно съобщение, най-често съобщение за грешка. -->
<template>
    <v-dialog v-if="notification" v-model="notification" persistent max-width="80%">
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="auto">
                        <v-icon x-large class="mt-n2" :color="notification.color">{{ notification.icon }}</v-icon>
                    </v-col>
                    <v-col>
                        <div v-if="notification.title">
                            <div v-for="(line, index) in notification.title.split('\r\n')" :key="index">{{ line }}</div>
                        </div>
                    </v-col>
                    <v-col cols="auto">
                        <btn icon action="Close" @click="hideModalNotification()" />
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-subtitle>{{ notification.timeText }}</v-card-subtitle>
            <v-card-text>
                <pre class="overflow-y-scroll expandable-text">{{ notification.text }}</pre>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { notifications } from '@/store/Notifications';

    @Component
    export default class NotificationDialog extends Vue {
        // Името displayedModalNotification е сменено само за прегледност.
        private get notification() {
            return notifications.displayedModalNotification;
        }

        private hideModalNotification() {
            notifications.displayedModalNotification = null;
        }
    }
</script>

<style lang="scss">
    .overflow-auto {
        overflow: auto;
        min-width: 85%;
    }

    .overflow-y-scroll {
        max-height: 300px;
        overflow-y: auto;
    }
</style>
