<template>
    <v-card>
        <v-card-title>Опис към месечния отчет - разширен</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" lg="6">
                    <report-date-picker v-model="filter" @input="getExamClaimReport" />
                </v-col>
                <v-col cols="12" lg="6">
                    <dropdown
                        v-model="filter.deputyDoctorIdAndRole"
                        :items="doctors"
                        item-value="deputyDoctorIdAndRole"
                        item-text="title"
                        label="Филтрирай по"
                        @input="getExamClaimReport"
                    />
                </v-col>
            </v-row>
            <gp-exam-claim-report :items="gpClaimReport" :filter="filter" :show-doctor-name="showDoctorName" />
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import Dropdown from '@/component/Dropdown/Dropdown.vue';
    import { DeputyDoctorDropdownDto } from '@/model/Employee/Deputy/DeputyDoctorDropdownDto';
    import { ExamClaimCommand } from '@/model/Report/Pis/GpExamClaim/ExamClaimCommand';
    import { ExamClaimReportDto } from '@/model/Report/Pis/GpExamClaim/ExamClaimReportDto';
    import { doctorDeputyService } from '@/service/Employee/Deputy/DoctorDeputyService';
    import { examClaimReportService } from '@/service/Query/Exam/ExamClaimReportService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import GpExamClaimReport from '@/view/Query/Exam/GpExamClaimReport.vue';

    @Component({
        components: { Dropdown, ReportDatePicker, GpExamClaimReport }
    })
    export default class ExamClaimReportExtended extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private filter: ExamClaimCommand = new ExamClaimCommand();
        private gpClaimReport: ExamClaimReportDto = new ExamClaimReportDto();
        private doctors: DeputyDoctorDropdownDto[] = [];

        private get showDoctorName(): boolean {
            const practiceInfoId = -2147483648;
            const primaryDoctorId = 2147483647;
            return (
                this.filter.deputyDoctorIdAndRole === practiceInfoId ||
                this.filter.deputyDoctorIdAndRole === primaryDoctorId
            );
        }

        private async getExamClaimReport() {
            this.$loading.show();
            try {
                this.fillDoctorEmployee();
                const response = await examClaimReportService.generateGpExamClaimReport(this.filter);
                if (response) {
                    this.gpClaimReport = response;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private fillDoctorEmployee() {
            this.filter.practiceId = this.practiceId;
            this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async getDeputyDoctors() {
            this.doctors = await doctorDeputyService.getDeputyDoctors(this.practiceId, this.doctorEmployeeSeqNumber);

            // Титулярът се добавя без зам./наети лекари. Познава се по това, че id-то му е 0.
            const primaryDoctor = new DeputyDoctorDropdownDto();
            primaryDoctor.deputyDoctorIdAndRole = 0;
            primaryDoctor.deputyDoctorName = userContextCache.current?.doctorName ?? '';
            primaryDoctor.deputyDoctorUin = userContextCache.current?.doctorUin ?? '';
            this.doctors.splice(0, 0, primaryDoctor);

            // Титулярът се добавя със зам./наети лекари. Познава се по това, че id-то му е Number.MAX_VALUE.
            const maxValue = 2147483647;
            const primaryDoctorDoctors = new DeputyDoctorDropdownDto();
            primaryDoctorDoctors.deputyDoctorIdAndRole = maxValue;
            primaryDoctorDoctors.deputyDoctorName = userContextCache.current?.doctorName ?? '';
            primaryDoctorDoctors.deputyDoctorUin = userContextCache.current?.doctorUin ?? '';
            this.doctors.splice(0, 0, primaryDoctorDoctors);

            if (userContextCache.currentIsGlobalAdminOrPracticeOwner) {
                // Цялата практика се добавя. Познава се по това, че id-то му е Number.MIN_VALUE.
                this.addPracticeModel();
            }

            this.filter.deputyDoctorIdAndRole = maxValue;
        }

        private addPracticeModel() {
            const practice = new DeputyDoctorDropdownDto();
            practice.deputyDoctorIdAndRole = -2147483648;
            practice.deputyDoctorName = 'Цялата практика';
            practice.deputyDoctorUin = '';
            this.doctors.splice(0, 0, practice);
        }

        private async mounted() {
            this.filter.fillPreviousMonth();
            await this.getDeputyDoctors();
            await this.getExamClaimReport();
        }
    }
</script>

<style scoped></style>
