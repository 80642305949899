export class AccessTokenDto {
    public accessToken: string = '';
    public tokenType: string = '';
    public expiresIn: number = 0;
    public issuedOn: string = '';
    public expiresOn: string = '';

    public get isExpired(): boolean {
        const expiresOn = new Date(this.expiresOn);
        return new Date().getTime() >= expiresOn.getTime();
    }
}
