import { User } from 'oidc-client-ts';
import Vue from 'vue';

// Тест на дълъг dependency cycle.
//import { userContextCache } from './UserContextCache';
//
//console.log(userContextCache);

// Реактивен wrapper на резултата от userManager.getUser().
class CurrentUser {
    public user: User | null = null;

    public get isAuthenticated() {
        return this.user && !this.user.expired;
    }

    // Ако браузърът е бил спрян за повече от час, може да има потребител, но access token-ът да е изтекъл.
    // OidcClient ще засече това и автоматично ще поднови сесията или ще излезе от системата, но това отнема време.
    // Преди да е станало ясно дали сесията е подновена, не трябва да се правят обръщения към API-то.
    public waitUntilAuthenticated() {
        const pollingInterval = 500;
        let isWaiting = false;

        // Реализацията е взета от https://stackoverflow.com/questions/22125865/how-to-wait-until-a-predicate-condition-becomes-true-in-javascript/52657929#52657929
        const poll = (resolve: (unused?: unknown) => void) => {
            if (this.isAuthenticated) {
                resolve();
            } else {
                if (!isWaiting) {
                    isWaiting = true;
                    console.log('Сесията е изтекла. Изчакване...');
                }
                setTimeout(() => poll(resolve), pollingInterval);
            }
        };

        return new Promise(poll);
    }

    public get userId() {
        return this.user ? this.user.profile.sub : null;
    }

    public get isGlobalAdmin() {
        return !this.globalAdminIsDisabled && this.hasGlobalAdminRole;
    }

    // Когато този флаг е вдигнат, глобалният администратор работи с правата на UserContext-а.
    // Целта е да се тества потребителският интерфейс с различни права, без да се сменя потребителят.
    public globalAdminIsDisabled: boolean = false;

    public get hasGlobalAdminRole() {
        if (!this.user) {
            return false;
        }
        const globalAdminRoleName = 'Kontrax';
        const { role } = this.user.profile;
        // Най-често няма роля. Ако ролята е една, идва като string. Ако ролите са няколко, идват като масив.
        return !role
            ? false
            : typeof role === 'string'
              ? role === globalAdminRoleName
              : (role as string[]).includes(globalAdminRoleName);
    }
}

export const currentUser = Vue.observable(new CurrentUser());
