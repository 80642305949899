import { AxiosResponse } from 'axios';

import { InformedConsentTreatmentDto } from '@/model/Exam/InformedConsentTreatment/InformedConsentTreatmentDto';
import { InformedConsentTreatmentViewDto } from '@/model/Exam/InformedConsentTreatment/InformedConsentTreatmentViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const informedConsentTreatmentService = {
    getInformedConsentTreatment(patientId: number): Promise<AxiosResponse<InformedConsentTreatmentDto>> {
        return httpService.get(`/InformedConsentTreatment/GetInformedConsentTreatment/${patientId}`);
    },

    createInformedConsentTreatment(
        data: InformedConsentTreatmentDto
    ): Promise<AxiosResponse<InformedConsentTreatmentDto>> {
        return httpService.post('/InformedConsentTreatment/CreateInformedConsentTreatment', data);
    },

    updateInformedConsentTreatment(
        data: InformedConsentTreatmentDto
    ): Promise<AxiosResponse<InformedConsentTreatmentDto>> {
        return httpService.put('/InformedConsentTreatment/UpdateInformedConsentTreatment/', data);
    },

    deleteInformedConsentTreatment(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/InformedConsentTreatment/DeleteInformedConsentTreatment/${id}`);
    },

    getInformedConsentTreatmentInfoByExamId(examId: number): Promise<AxiosResponse<InformedConsentTreatmentDto>> {
        return httpService.get(`/InformedConsentTreatment/GetInformedConsentTreatmentInfoByExamId/${examId}`);
    },

    getInformedConsentTreatmentsByExamId(examId: number): Promise<AxiosResponse<InformedConsentTreatmentViewDto[]>> {
        return httpService.get(`/InformedConsentTreatment/GetInformedConsentTreatmentViewsByExam`, {
            params: { examId }
        });
    }
};
