import { NhisPatientImmunizationReactionKey } from './NhisPatientImmunizationReactionKey';

export class NhisImmunizationReactionDto {
    public key: NhisPatientImmunizationReactionKey = new NhisPatientImmunizationReactionKey();
    public startDate: Date | null = null;
    public endDate: Date | null = null;
    public isSelfReported: boolean = false;
    public description: string | null = null;
    public nrn: string = '';
    public uploadDate: Date | null = null;
}
