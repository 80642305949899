import { AxiosResponse } from 'axios';

import { Referral4QuickAddDto } from '@/model/Exam/Referral/Referral4QuickAddDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referral4QuickAddService = {
    getAllServices(): Promise<AxiosResponse<Referral4QuickAddDto[]>> {
        return httpService.get('/Referral4QuickAdd/GetAllServices');
    },
    create(selectedServices: Referral4QuickAddDto): Promise<AxiosResponse<number>> {
        return httpService.post('/Referral4QuickAdd/Create', selectedServices);
    }
};
