class InvoiceRecipientSearchType {
    public code: string = '';
    public name: string = '';
}

const invoiceRecipientSearchTypes: InvoiceRecipientSearchType[] = [
    { name: 'Пациент', code: 'Patient' },
    { name: 'Клиенти', code: 'Recipient' },
    { name: 'РЗОК', code: 'Rhif' }
];

export { InvoiceRecipientSearchType, invoiceRecipientSearchTypes };
