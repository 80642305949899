<template>
    <v-select
        v-if="currentVisitIsLoaded"
        label="Диагноза"
        :value="value"
        :items="examDiagnoses"
        clearable
        :dense="dense"
        :item-text="useMainIcd ? 'icdName' : 'bothIcdCodes'"
        :rules="required ? autocompleteRule.concat([$validator.required]) : []"
        :class="required ? 'required' : ''"
        return-object
        @change="$emit('input', ...arguments)"
    />
    <span v-else>Не е избран преглед</span>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';
    import { currentVisitCache } from '@/store/CurrentVisitCache';

    @Component
    export default class ExamDiagnosisPicker extends Vue {
        @Prop({ type: Object })
        private value!: ExamDiagnosisDto;

        @Prop()
        private useMainIcd!: boolean;

        @Prop()
        private required!: boolean;

        @Prop({ default: false })
        private dense!: boolean;

        private autocompleteRule: ((val: ExamDiagnosisDto) => void)[] | boolean[] | string[] = [
            (val: ExamDiagnosisDto) => (val && val.bothIcdCodes !== '') || `Попълнете задължителните полета`
        ];

        private get currentVisitIsLoaded() {
            return currentVisitCache.isLoaded;
        }

        private get examDiagnoses() {
            return currentVisitCache.value.exam?.examDiagnoses;
        }
    }
</script>
