<template>
    <div>
        <data-table
            :headers="veteranTableHeaders"
            :loading="loadingVeterans"
            :items="veteranTableItems"
            title="Рецептурни книжки - ветерани"
            class="elevation-1 mb-5"
            disable-pagination
        >
            <template v-if="$allowWrite" #actions>
                <btn action="New" @click="openNewVeteranBookDialog" />
            </template>
            <template v-if="$allowWrite" #rowActions="{ item }">
                <btn icon action="Edit" @click="openEditVeteranBookDialog(item)" />
            </template>
        </data-table>
        <v-row justify="center" class="mx-5">
            <v-dialog v-model="veteranBookDialogIsVisible" persistent max-width="75%">
                <v-card>
                    <v-card-title>
                        <span class="headline">Рецептурна книжка - ветерани</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="veteranForm" v-model="isVeteranFormValid">
                            <veteran-prescription-book v-model="cloneVeteranBook" :errors="veteranErrors">
                            </veteran-prescription-book>
                        </v-form>
                    </v-card-text>
                    <v-card-actions v-if="$allowWrite">
                        <btn v-if="cloneVeteranBook.id > 0" action="Delete" @click="deleteVeteranBook" />
                        <v-spacer></v-spacer>
                        <btn action="Cancel" @click="closeVeteranBookDialog" />
                        <btn action="Save" @click="saveVeteranBook" />
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <data-table
            :headers="injuredTableHeaders"
            :loading="loadingInjured"
            :items="injuredTableItems"
            title="Рецептурни книжки - военно пострадали/инвалиди"
            class="elevation-1 my-5"
            disable-pagination
        >
            <template v-if="$allowWrite" #actions>
                <btn action="New" @click="openNewInjuredBookDialog" />
            </template>
            <template v-if="$allowWrite" #rowActions="{ item }">
                <btn icon action="Edit" @click="openEditInjuredBookDialog(item)" />
            </template>
        </data-table>
        <v-row justify="center" class="mx-5">
            <v-dialog v-model="injuredBookDialogIsVisible" persistent max-width="75%">
                <v-card>
                    <v-card-title>
                        <span class="headline">Рецептурна книжка - военно постр./инв.</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="injuredForm" v-model="isInjuredFormValid">
                            <injured-prescription-book v-model="cloneInjuredBook"></injured-prescription-book>
                        </v-form>
                    </v-card-text>
                    <v-card-actions v-if="$allowWrite">
                        <btn v-if="cloneInjuredBook.id > 0" action="Delete" @click="deleteInjuredBook" />
                        <v-spacer></v-spacer>
                        <btn action="Cancel" @click="closeInjuredBookDialog" />
                        <btn action="Save" @click="saveInjuredBook" />
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { InjuredPrescriptionBookDto } from '@/model/Patient/PrescriptionBook/InjuredPrescriptionBookDto';
    import { VeteranPrescriptionBookDto } from '@/model/Patient/PrescriptionBook/VeteranPrescriptionBookDto';
    import { injuredPrescriptionBookService } from '@/service/Patient/InjuredPrescriptionBookService';
    import { veteranPrescriptionBookService } from '@/service/Patient/VeteranPrescriptionBookService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    import InjuredPrescriptionBook from './InjuredPrescriptionBook.vue';
    import VeteranPrescriptionBook from './VeteranPrescriptionBook.vue';

    @Component({
        components: { VeteranPrescriptionBook, InjuredPrescriptionBook }
    })
    export default class PrescriptionBook extends Vue {
        @Prop()
        public id!: number;

        private loadingVeterans: boolean = false;
        private loadingInjured: boolean = false;
        private veteranBookDialogIsVisible: boolean = false;
        private injuredBookDialogIsVisible: boolean = false;
        private veteranBookModel: VeteranPrescriptionBookDto = new VeteranPrescriptionBookDto();
        private cloneVeteranBook: VeteranPrescriptionBookDto = new VeteranPrescriptionBookDto();
        private injuredBookModel: InjuredPrescriptionBookDto = new InjuredPrescriptionBookDto();
        private cloneInjuredBook: InjuredPrescriptionBookDto = new InjuredPrescriptionBookDto();
        private veteranTableItems: VeteranPrescriptionBookDto[] = [];
        private injuredTableItems: InjuredPrescriptionBookDto[] = [];
        private isVeteranFormValid: boolean = false;
        private isInjuredFormValid: boolean = false;
        private veteranErrors: string[] = [];

        private openNewVeteranBookDialog() {
            this.veteranBookDialogIsVisible = true;
        }

        private openNewInjuredBookDialog() {
            this.injuredBookDialogIsVisible = true;
        }

        private openEditVeteranBookDialog(item: VeteranPrescriptionBookDto) {
            Object.assign(this.cloneVeteranBook, item);
            this.veteranBookDialogIsVisible = true;
        }

        private openEditInjuredBookDialog(item: InjuredPrescriptionBookDto) {
            Object.assign(this.cloneInjuredBook, item);
            this.injuredBookDialogIsVisible = true;
        }

        private async saveVeteranBook() {
            this.loadingVeterans = true;
            try {
                (this.$refs.veteranForm as Vue & { validate: () => boolean }).validate();
                this.veteranBookModel = this.cloneVeteranBook;
                this.veteranBookModel.patientId = this.id;
                if (this.isVeteranFormValid) {
                    this.veteranErrors = (await veteranPrescriptionBookService.canSave(this.veteranBookModel)).data;
                }
                if (this.isVeteranFormValid && this.veteranErrors.length <= 0) {
                    if (this.veteranBookModel.id <= 0) {
                        await veteranPrescriptionBookService.createVeteranPrescriptionBook(this.veteranBookModel);
                    } else {
                        await veteranPrescriptionBookService.updateVeteranPrescriptionBook(this.veteranBookModel);
                    }
                    this.$notifier.showSuccess('', `Успешен запис`);
                    await this.getVeteranTableItems();
                    this.closeVeteranBookDialog();
                }
            } finally {
                this.loadingVeterans = false;
            }
        }

        private async saveInjuredBook() {
            this.loadingInjured = true;
            try {
                (this.$refs.injuredForm as Vue & { validate: () => boolean }).validate();
                if (this.isInjuredFormValid) {
                    this.injuredBookModel = this.cloneInjuredBook;
                    this.injuredBookModel.patientId = this.id;
                    if (this.injuredBookModel.id <= 0) {
                        await injuredPrescriptionBookService.createInjuredPrescriptionBook(this.injuredBookModel);
                    } else {
                        await injuredPrescriptionBookService.updateInjuredPrescriptionBook(this.injuredBookModel);
                    }
                    this.$notifier.showSuccess('', `Успешен запис`);
                    await this.getInjuredTableItems();
                    this.closeInjuredBookDialog();
                }
            } finally {
                this.loadingInjured = false;
            }
        }

        private async deleteVeteranBook() {
            this.loadingVeterans = true;
            try {
                await veteranPrescriptionBookService.deleteVeteranPrescriptionBook(this.cloneVeteranBook.id);
                await this.getVeteranTableItems();
                this.closeVeteranBookDialog();
            } finally {
                this.loadingVeterans = false;
            }
        }

        private async deleteInjuredBook() {
            this.loadingInjured = true;
            try {
                await injuredPrescriptionBookService.deleteInjuredPrescriptionBook(this.cloneInjuredBook.id);
                await this.getInjuredTableItems();
                this.closeInjuredBookDialog();
            } finally {
                this.loadingInjured = false;
            }
        }

        private closeVeteranBookDialog() {
            this.cloneVeteranBook = new VeteranPrescriptionBookDto();
            (this.$refs.veteranForm as Vue & { resetValidation: () => boolean }).resetValidation();
            this.veteranBookDialogIsVisible = false;
        }

        private closeInjuredBookDialog() {
            this.injuredBookDialogIsVisible = false;
            this.cloneInjuredBook = new InjuredPrescriptionBookDto();
            (this.$refs.injuredForm as Vue & { resetValidation: () => boolean }).resetValidation();
        }

        private get veteranTableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Дата на издаване', value: 'releaseDate', formatter: formatters.date },
                { text: 'Номер', value: 'number' },
                {
                    text: 'Брой страници',
                    value: 'pagesCount'
                },
                {
                    text: 'Удостоверение номер',
                    value: 'certificateNumber'
                },
                { text: 'Дата на решението', value: 'certificateDate', formatter: formatters.date },
                { text: 'Анулирана на', value: 'cancelationDate', formatter: formatters.date },
                { text: 'Причина за анулиране', value: 'cancelationReason' },
                { text: '', value: 'actionButtons' }
            ];
        }

        private get injuredTableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Дата на издаване', value: 'releaseDate', formatter: formatters.date },
                { text: 'Номер', value: 'number' },
                {
                    text: 'Неработоспособност %',
                    value: 'workIncapabilityPercent'
                },
                {
                    text: 'Удостоверение номер',
                    value: 'certificateNumber'
                },
                { text: 'Дата на решението', value: 'certificateDate', formatter: formatters.date },
                { text: 'Решение на ТЕЛК', value: 'telkResultNumber' },
                { text: 'Решение на ТЕЛК - дата', value: 'telkResultDate', formatter: formatters.date },
                { text: 'Анулирана на', value: 'cancelationDate', formatter: formatters.date },
                { text: 'Причина за анулиране', value: 'cancelationReason' },
                { text: '', value: 'actionButtons' }
            ];
        }

        private async getVeteranTableItems() {
            this.loadingVeterans = true;
            try {
                const response = await veteranPrescriptionBookService.getVeteranTableItems(this.id);
                if (response?.data) {
                    this.veteranTableItems = response.data;
                }
            } finally {
                this.loadingVeterans = false;
            }
        }

        private async getInjuredTableItems() {
            this.loadingInjured = true;
            try {
                const response = await injuredPrescriptionBookService.getInjuredTableItems(this.id);
                if (response?.data) {
                    this.injuredTableItems = response.data;
                }
            } finally {
                this.loadingInjured = false;
            }
        }

        private async mounted() {
            if (userContextCache.currentDoctorEmployeeId) {
                if (this.id) {
                    await this.getVeteranTableItems();
                    await this.getInjuredTableItems();
                }
            } else {
                this.$router.push(`/Patient/PersonalData/${this.id}`);
            }
        }
    }
</script>
