<template>
    <div>
        <h3>ЛИЧНА АНАМНЕЗА</h3>
        <h4>Жени и мъже</h4>
        <text-field v-model="value.cigarettesCount" label="Брой цигари" />
        <v-checkbox
            v-model="value.sedentaryLife"
            label="Заседнал начин на живот (по - малко от 30 мин. физическа активност на ден)"
        ></v-checkbox>
        <v-checkbox v-model="value.adenoma" label="Аденом/и на дебелото черво"></v-checkbox>
        <v-checkbox v-model="value.polyp" label="Полипи на дебелото черво"></v-checkbox>
        <v-checkbox v-model="value.colorectalCarcinoma" label="Колоректален карцином"></v-checkbox>
        <v-checkbox v-model="value.crohn" label="Болест на Crohn"></v-checkbox>
        <v-checkbox v-model="value.colitis" label="Улцерозен колит"></v-checkbox>
        <v-checkbox v-model="value.celiacDisease" label="Цьолиакия"></v-checkbox>
        <v-checkbox v-model="value.type1Diabetes" label="Захарен диабет тип 1 (E10)"></v-checkbox>
        <v-checkbox v-model="value.type2Diabetes" label="Захарен диабет тип 2 (E11)"></v-checkbox>
        <v-checkbox v-model="value.dyslipidaemia" label="Предшестващи данни за дислипидемия"></v-checkbox>
        <v-checkbox
            v-model="value.fruitsVegetables"
            label="Ежедневна консумация на плодове и зеленчуци (поне 100 гр.)"
        ></v-checkbox>
        <v-checkbox
            v-model="value.antihypertensive"
            label="Редовен прием на антихипертензивни медикаменти сега или в миналото"
        ></v-checkbox>
        <v-checkbox v-model="value.prediabetic" label="Повишена кръвна захар в миналото"></v-checkbox>
        <h4>Жени</h4>
        <v-checkbox
            v-model="value.pregnancyChildBirth"
            label=">=3 износени бременности или първо раждане на възраст <= 17г."
            :disabled="isMale"
        ></v-checkbox>
        <v-checkbox
            v-model="value.prolongedHrt"
            label="продължителна (повече от 5 г.) употреба на естрогени (контрацептиви или хормонална заместителна терапия)"
            :disabled="isMale"
        ></v-checkbox>
        <v-checkbox v-model="value.immunosuppressed" label="Имуносупресирани болни" :disabled="isMale"></v-checkbox>
        <v-checkbox
            v-model="value.hpvVaccine"
            label="Неваксинирани срещу HPV (за възрастова група >=18 и <=25 + един от изброените рискови фактори"
            :disabled="isMale"
        ></v-checkbox>
        <v-checkbox v-model="value.womenCancers" label="Лекуван друг карцином" :disabled="isMale"></v-checkbox>
        <v-checkbox
            v-model="value.benignMammary"
            label="Доброкачествена дисплазия на млечната жлеза"
            :disabled="isMale"
        ></v-checkbox>
        <v-checkbox
            v-model="value.menarche"
            label="Ранно менархе (<= 10 годишна възраст)"
            :disabled="isMale"
        ></v-checkbox>
        <v-checkbox v-model="value.pregnancy" label="Първо раждане на възраст >= 35 г." :disabled="isMale"></v-checkbox>
        <h4>Мъже</h4>
        <v-checkbox
            v-model="value.dynamismPsa"
            label="PSA > 4.0 ng/ml при мъже над 40 г."
            :disabled="!isMale"
        ></v-checkbox>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { GenderCode } from '@/enum/Nomenclature/GenderCode';
    import { AnamnesisPersonalDto } from '@/model/Exam/Gp/RiskAssessmentCard/AnamnesisPersonalDto';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    @Component
    export default class AnamnesisPersonalCard extends Vue {
        // Този компонент може да получи undefined v-model. В този случай се bind-ва default стойността, която е празен обект.
        @Prop({ type: Object })
        private value!: AnamnesisPersonalDto;

        private get isMale() {
            return currentPatientCache.value.personIdentification.genderCode === GenderCode.Male;
        }
    }
</script>
