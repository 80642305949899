import { Route, RouteConfig } from 'vue-router';

export const examProtocolRoutes: RouteConfig[] = [
    {
        path: 'NhifProtocol/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/DrugProtocol/NhifProtocol.vue')
        }
    },
    {
        path: 'NhifProtocol/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/DrugProtocol/NhifProtocol.vue')
        }
    },
    {
        path: 'NhifProtocol/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/DrugProtocol/NhifProtocolView.vue')
        }
    },
    {
        path: 'DrugProtocol/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/DrugProtocol/DrugProtocol.vue')
        }
    },
    {
        path: 'DrugProtocol/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/DrugProtocol/DrugProtocol.vue')
        }
    },
    {
        path: 'DrugProtocol/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/DrugProtocol/DrugProtocolPaperView.vue')
        }
    }
];
