import { render, staticRenderFns } from "./NhisFamilyHistory.vue?vue&type=template&id=3143e20e&scoped=true"
import script from "./NhisFamilyHistory.vue?vue&type=script&lang=ts"
export * from "./NhisFamilyHistory.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3143e20e",
  null
  
)

export default component.exports