<template>
    <v-card>
        <v-card-title>
            Резултат от направление
            <v-spacer />
            <btn action="Next" :to="`/Exam/Referral7/Edit/${referralResult.documentId}`">Към направлението...</btn>
            <btn action="Download" class="ma-1" @click="checkReferral">Изтегляне на резултати</btn>
        </v-card-title>
        <v-col>
            <referral-result-main-info
                :nrn="referralResult.resultNrn"
                :open-date="referralResult.openDate"
                :result-referral-performer-data="referralResult.performerInfo"
                :status="referralResult.status"
            >
            </referral-result-main-info>
            <v-card-actions>
                <v-row>
                    <v-col v-if="referralResult.epicrisisFilePath" cols="auto">
                        <btn :disabled="isUrlExpired" action="Download" target="_blank" @click="downloadEpicrisis">
                            Изтегляне на епикриза
                        </btn>
                    </v-col>
                    <v-col>
                        <alert :value="isUrlExpired" type="warning">
                            Времето за изтегляне на епикризата изтече. Изпълнете "Изтегляне на резултати" или "Проверка
                            на статус" през бланката.
                        </alert>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-col>
        <v-card-title>Информация за хоспитализация</v-card-title>
        <v-col>
            <v-row dense>
                <text-field v-model="referralResult.admissionType" label="Тип на приема" readonly></text-field>
                <date-field model="referralResult.admissionDate" class="pl-3" label="Дата на постъпване" readonly />
                <date-field v-model="referralResult.dischargeDate" class="pl-3" label="Дата на изписване" readonly />
                <text-field
                    v-model="referralResult.daysHospitalized"
                    class="pl-3"
                    label="Брой дни пролежани в лечебното заведение"
                    readonly
                ></text-field>
            </v-row>
        </v-col>
        <v-row v-if="referralResult.diagnoses" dense> <v-card-title> Диагнози при изписване </v-card-title></v-row>
        <v-row v-for="(item, index) in referralResult.diagnoses" :key="index" no-gutters>
            <v-col cols="6" class="pa-1"><icd-picker :value="item.icdCode" :disabled="true" /></v-col>
            <v-col cols="6" class="pa-1"
                ><icd-picker :value="item.icdCodeAdd" :disabled="true" label="Допълнително МКБ"
            /></v-col>
        </v-row>
        <div v-if="referralResult.comorbiditiesNames">
            <v-row dense><v-card-title> Придружаващи заболявания и усложнения </v-card-title></v-row>
            <v-row v-for="(item, index) in referralResult.comorbiditiesNames" :key="index" no-gutters>
                <v-col cols="6" class="pa-1"><icd-picker :value="item.icdCode" :disabled="true" /></v-col>
                <v-col cols="6" class="pa-1"
                    ><icd-picker :value="item.icdCodeAdd" :disabled="true" label="Допълнително МКБ"
                /></v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReferralResultMainInfo from '@/component/Exam/ReferralResultMainInfo.vue';
    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import { ResultReferral7Dto } from '@/model/Exam/Referral/ResultReferral/ResultReferral7Dto';
    import { ReferralResult7Service } from '@/service/Exam/Referral/ReferralResult7Service';
    import { nhisReferralService } from '@/service/Nhis/NhisReferralService';
    import { nhisService } from '@/service/Nhis/NhisService';
    import { accessTokenCache, nhisKey } from '@/store/NhisNhifAccessTokenCache';

    @Component({ components: { IcdPicker, ReferralResultMainInfo } })
    export default class ReferralResult7 extends Vue {
        @Prop()
        private nrn!: string;

        @Prop()
        private examId!: number;

        private referralResult: ResultReferral7Dto = new ResultReferral7Dto();
        private isUrlExpired: boolean = false;

        private async checkIfEpicrisisUrlIsExpired() {
            const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
            this.isUrlExpired = !tokenInfo?.accessToken || this.referralResult.userToken !== tokenInfo.accessToken;
        }

        private async downloadEpicrisis() {
            this.checkIfEpicrisisUrlIsExpired();
            if (!this.isUrlExpired) {
                await ReferralResult7Service.getEpicrisisFile(
                    this.referralResult.epicrisisFilePath,
                    this.referralResult.userToken
                );
            }
        }

        private async mounted() {
            await this.load();
        }

        private async load() {
            this.referralResult = (await ReferralResult7Service.getResultData(this.examId, this.nrn)).data;
            this.checkIfEpicrisisUrlIsExpired();
        }

        private async checkReferral() {
            this.$loading.show();
            try {
                const response = await nhisReferralService.checkReferral(
                    nhisService.requestData(this.referralResult.documentId, 'Referral7')
                );
                if (response) {
                    await this.load();
                }
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
