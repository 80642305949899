<template>
    <ExamLayout
        :can-delete="document.id > 0 && !disabled"
        :can-print="document.id > 0"
        title="Карта за оценка на физическата годност на водач"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
    >
        <nhis-certificate-drive
            :id="id"
            v-model="document.nhisCertificateDrive"
            :exam-id="examId"
            :disable="disabled"
            class="mb-2"
        />
        <v-card :disabled="disabled">
            <v-card-title>
                <v-row>
                    <v-col cols="6">
                        УДОСТОВЕРЕНИЕ за здравословното състояние на водач / кандидат за придобиване на правоспособност
                        за управление на МПС
                    </v-col>
                    <v-col cols="6">
                        Карта за оценка на физическата годност на водач / кандидат за придобиване на правоспособност за
                        управление на МПС
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <AmbulatorySheetHeaderInfoCard v-once class="mb-5" />
                <v-row class="align-baseline">
                    <v-col md="3" cols="6">
                        <dropdown
                            v-model="document.licenseCategory"
                            :items="certificateDriveTypes"
                            label="Категория"
                            required
                        />
                    </v-col>
                    <v-col md="3" cols="6">
                        <date-picker v-model="document.issueDate" label="Дата на издаване" required />
                    </v-col>
                    <v-col md="3" cols="6">
                        <icd-picker v-model="document.icdCode" label="Основно МКБ" />
                    </v-col>
                    <v-col md="3" cols="6">
                        <icd-picker v-model="document.icdCodeAdd" label="Допълнително МКБ" />
                    </v-col>
                    <v-col md="3" cols="6">
                        <v-checkbox v-model="document.hasTelk" label="Има ТЕЛК" hide-details />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            v-model="document.symptoms"
                            label="Анамнеза"
                            class="required"
                            :rules="[$validator.required]"
                            rows="3"
                        />
                    </v-col>
                </v-row>
                <h4 class="mb-2">Необходими консултации от специалист</h4>
                <div v-for="(consultation, index) in document.consultations" :key="index" class="flex-row d-flex">
                    <certificate-drive-consultation :consultation="consultation" :is-sent="isSent" />
                    <btn
                        :disabled="isSent || consultation.isNecessary"
                        action="Delete"
                        icon
                        @click="removeConsultation(index)"
                        >Изтриване на консултация
                    </btn>
                </div>
                <v-card-actions>
                    <btn v-if="!isSent" action="New" :disabled="isLoading" @click="addConsultation(null, false)"
                        >Добавяне на консултация
                    </btn>
                </v-card-actions>
                <v-row>
                    <v-col v-if="isSent" md="3" cols="6">
                        <v-checkbox
                            v-model="document.isTolek"
                            label="Подлежи на освидетелстване от ТОЛЕК"
                            hide-details
                        />
                    </v-col>
                    <v-col v-if="isSent" md="3" cols="6">
                        <v-checkbox v-model="document.isCompliant" label="Отговаря на изискванията" hide-details />
                    </v-col>
                    <v-col v-if="isSent" cols="12">
                        <v-textarea
                            v-model="document.motive"
                            label="Мотиви за становището"
                            class="required"
                            :rules="[$validator.required]"
                            rows="3"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <nhis-exam-extraction v-if="isSent" hide-xml-result />
            <nhis-consultation-extraction v-if="isSent" hide-xml-result />
        </v-card>
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DatePicker from '@/component/Date/DatePicker.vue';
    import Dropdown from '@/component/Dropdown/Dropdown.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import { NhisCertificateStatusCode } from '@/enum/Nhis/NhisCertificateStatusCode';
    import { CertificateDriveConsultationDto } from '@/model/Exam/Certificate/CertificateDriveConsultationDto';
    import { CertificateDriveNhisDto } from '@/model/Exam/Certificate/CertificateDriveNhisDto';
    import { CertificateDriveTypeDto } from '@/model/Nomenclature/CertificateDriveTypeDto';
    import { certificateDriveService } from '@/service/Exam/Certificate/CertificateDriveService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { certificateDriveTypeService } from '@/service/Nomenclature/CertificateDriveTypeService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import NhisConsultationExtraction from '@/view/DevTool/NhisConsultationExtraction.vue';
    import NhisExamExtraction from '@/view/DevTool/NhisExamExtraction.vue';
    import AmbulatorySheetHeaderInfoCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetHeaderInfoCard.vue';
    import CertificateDriveConsultation from '@/view/Exam/Certificate/CertificateDriveConsultation.vue';
    import NhisCertificateDrive from '@/view/Nhis/CertificateDrive/NhisCertificateDrive.vue';

    const specialistNecessaryConsultation = [
        'InternalDiseases',
        'Neurology',
        'Ophthalmology',
        'Otorhinolaryngology',
        'Surgery'
    ];
    @Component({
        components: {
            IcdPicker,
            NhisConsultationExtraction,
            NhisExamExtraction,
            NhisCertificateDrive,
            ExamLayout,
            CertificateDriveConsultation,
            AmbulatorySheetHeaderInfoCard,
            DatePicker,
            Dropdown
        }
    })
    export default class CertificateDriveNhis extends Vue {
        @Prop()
        examId!: number;

        @Prop()
        id!: number;

        private certificateDriveTypes: CertificateDriveTypeDto[] = [];
        private document: CertificateDriveNhisDto = new CertificateDriveNhisDto();

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private async getCertificateDriveTypes() {
            this.$loading.show();
            try {
                this.certificateDriveTypes = await certificateDriveTypeService.getCertificateDriveTypes();
            } finally {
                this.$loading.hide();
            }
        }

        private get isSent() {
            return (
                (this.document?.nhisCertificateDrive?.nrn !== null &&
                    this.document?.nhisCertificateDrive?.nrn !== '') ||
                this.isOldCertificate
            );
        }

        private get isOldCertificate() {
            //Считаме, че от 01.01.2024 вече са електронни
            return this.document.issueDate.getTime() < new Date('01.01.2024').getTime();
        }

        private get disabled() {
            return (
                this.document.nhisCertificateDrive.statusCode === NhisCertificateStatusCode.Canceled ||
                this.document.nhisCertificateDrive.statusCode === NhisCertificateStatusCode.Closed
            );
        }

        private addConsultation(specialtyCode: string | null, isNecessary: boolean) {
            this.document.consultations.push(new CertificateDriveConsultationDto(specialtyCode, isNecessary));
        }

        private removeConsultation(index: number) {
            arrayUtil.removeAt(this.document.consultations, index);
        }

        private pushNecessaryConsultation() {
            for (const specialist of specialistNecessaryConsultation) {
                this.addConsultation(specialist, true);
            }
        }

        private async load() {
            this.$loading.show();
            try {
                if (!this.id) {
                    this.document = await certificateDriveService.getCertificateDriveNhisInfoByExam(this.examId);
                    this.pushNecessaryConsultation();
                } else {
                    const response = await certificateDriveService.getCertificateDriveNhis(this.id);
                    this.document = response.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                await certificateDriveService.deleteCertificateDrive(this.id);
                this.$router.push(`/Exam/ECertificateDrive/Create/${currentVisitCache.value.id}`);
                eventBus.$emit('delete-referral-event', this.id, 'ECertificateDrive', 'Медицинско за шофьор');
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                if (!this.id) {
                    this.document.examId = this.examId;
                    const response = await certificateDriveService.createCertificateDriveNhis(this.document);
                    this.document.id = response?.data;
                    eventBus.$emit('create-referral-event', response.data, 'ECertificateDrive', 'Медицинско за шофьор');
                    this.$router.push(`/Exam/ECertificateDrive/Edit/${this.document.id}`);
                } else {
                    await certificateDriveService.updateCertificateDriveNhis(this.document);
                    eventBus.$emit('update-referral-event', 'Медицинско за шофьор');
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.getCertificateDriveTypes();
            await this.load();
        }
    }
</script>

<style scoped></style>
