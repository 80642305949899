<template>
    <v-row no-gutters>
        <v-row no-gutters>
            <v-col md="3" class="px-1"><text-field v-model="value.number" disabled label="напр. номер" /></v-col>
            <v-col md="3" class="px-1"><text-field v-model="value.codes[0]" disabled label="код изсл." /></v-col>
            <v-col md="3" class="px-1"><text-field v-model="value.codes[1]" disabled label="код изсл." /></v-col>
            <v-col md="3" class="px-1"><text-field v-model="value.codes[2]" disabled label="код изсл." /></v-col>
        </v-row>
        <v-row class="justify-end" no-gutters>
            <v-col md="3" class="px-1"><text-field v-model="value.codes[3]" disabled label="код изсл." /></v-col>
            <v-col md="3" class="px-1"><text-field v-model="value.codes[4]" disabled label="код изсл." /></v-col>
            <v-col md="3" class="px-1"><text-field v-model="value.codes[5]" disabled label="код изсл." /></v-col>
        </v-row>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { AmbulatorySheetReferral4Dto } from '@/model/Exam/AmbulatorySheetReferral4Dto';

    @Component
    export default class AmbulatorySheetReferral4Card extends Vue {
        // Този компонент може да получи undefined v-model. В този случай се bind-ва default стойността, която е празен обект.
        @Prop({ default: () => new AmbulatorySheetReferral4Dto() })
        private value!: AmbulatorySheetReferral4Dto;
    }
</script>
