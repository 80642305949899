import { AxiosResponse } from 'axios';

import { ServiceCategoryDto } from '@/model/Practice/Service/ServiceCategoryDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const serviceCategoryService = {
    getAllServiceCategories(practiceId: number): Promise<AxiosResponse<ServiceCategoryDto[]>> {
        return httpService.get(`/ServiceCategory/GetAllServiceCategories/${practiceId}`);
    },
    saveServiceCategories(data: ServiceCategoryDto[]): Promise<AxiosResponse<void>> {
        return httpService.post('/ServiceCategory/SaveServiceCategories', data);
    },
    deleteServiceCategory(practiceId: number, seqNumber: number): Promise<AxiosResponse<void>> {
        return httpService.delete('/ServiceCategory/DeleteServiceCategory', { params: { practiceId, seqNumber } });
    }
};
