import { Type } from 'class-transformer';

import { FundServiceDto } from '@/model/Nomenclature/FundServiceDto';
import { ServiceOfferEditDto } from '@/model/Practice/Service/ServiceOfferEditDto';

export class QuickAddLabTestDto {
    public practiceId: number = 0;
    public tariffSeqNumber: number | null = null;

    @Type(() => FundServiceDto)
    public labTests: FundServiceDto[] = [];

    @Type(() => ServiceOfferEditDto)
    public addedLabTests: ServiceOfferEditDto[] = [];

    @Type(() => FundServiceDto)
    public selectedLabTests: FundServiceDto[] = [];
}
