import { AnamnesticDataReferralDto } from '@/model/Exam/Referral/PregnancyReferral/AnamnesticDataReferralDto';
import { CurrentPregnancyDto } from '@/model/Exam/Referral/PregnancyReferral/CurrentPregnancyDto';
import { PersonalInfoDto } from '@/model/Exam/Referral/PregnancyReferral/PersonalInfoDto';
import { PreviousPregnancyDto } from '@/model/Exam/Referral/PregnancyReferral/PreviousPregnancyDto';

export class PregnancyReferralDto {
    public id: number = 0;
    public pregnancyId: number = 0;
    public sectionNumber: string | null = null;
    public city: string | null = null;
    public otherCity: string | null = null;
    public municipality: string | null = null;
    public personalInfo: PersonalInfoDto = new PersonalInfoDto();
    public anamnesticData: AnamnesticDataReferralDto = new AnamnesticDataReferralDto();
    public previousPregnancy: PreviousPregnancyDto = new PreviousPregnancyDto();
    public currentPregnancy: CurrentPregnancyDto = new CurrentPregnancyDto();
}
