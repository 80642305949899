export class CertificateWorkDto {
    public id: number = 0;
    public number: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();
    public employer: string = '';
    public department: string = '';
    public position: string = '';
    public isPastEmployee: boolean = false;
    public fromDate1: Date | null = null;
    public toDate1: Date | null = null;
    public position1: string = '';
    public fromDate2: Date | null = null;
    public toDate2: Date | null = null;
    public position2: string = '';
    public sickLeave: string = '';
    public previousDisease: string = '';
    public disability: string = '';
    public examResults: string = '';
    public requiresSpecialistExam: boolean = false;
    public specialistExamResult: string = '';
    public notes: string = '';
    public isMedicallyApproved: boolean = true;
}
