import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { PregnancyReferralDto } from '@/model/Exam/Referral/PregnancyReferral/PregnancyReferralDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const pregnancyReferralService = {
    async getPregnancyReferral(id: number): Promise<PregnancyReferralDto> {
        const response = await httpService.get(`/PregnancyReferral/GetPregnancyReferral/${id}`);
        return plainToInstance(PregnancyReferralDto, response.data);
    },

    async getInfoByPregnancyId(id: number): Promise<PregnancyReferralDto> {
        const response = await httpService.get(`/PregnancyReferral/GetByPregnancyId/${id}`);
        return plainToInstance(PregnancyReferralDto, response.data);
    },

    createPregnancyReferral(data: PregnancyReferralDto): Promise<AxiosResponse<number>> {
        return httpService.post('/PregnancyReferral/CreatePregnancyReferral/', data);
    },

    updatePregnancyReferral(data: PregnancyReferralDto): Promise<AxiosResponse<void>> {
        return httpService.put('/PregnancyReferral/UpdatePregnancyReferral/', data);
    },

    deletePregnancyReferral(id: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/PregnancyReferral/DeletePregnancyReferral/${id}`);
    },

    getPregnancyReferralId(registerId: number): Promise<AxiosResponse<number>> {
        return httpService.get(`/PregnancyReferral/GetByPregnancyReferralIdByRegisterId/${registerId}`);
    }
};
