<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Карта за оценка на рискови фактори за развитие на заболяване"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <alert v-if="!document.isPreventiveExam && hasChildIcdCode" type="warning">
                За пациента не се издава този тип документ
            </alert>
            <alert v-else-if="!document.isPreventiveExam" type="warning">
                Опитвате се да издадете Карта за оценка на рискови фактори към преглед, който не е профилактика
            </alert>
            <main-title>Карта за оценка на рискови фактори за развитие на заболяване</main-title>
            <v-card-text>
                <v-row v-if="document.id <= 0">
                    <v-col cols="6" md="5">
                        <btn action="Edit" @click="fillFromPreviousCard()">Зареждане на данни от предишна карта</btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <AnamnesisPersonalCard :value="document.anamnesisPersonal" />
                        <RiskByScoreCard :value="document" />
                        <FindRiskCard :value="document" />
                    </v-col>

                    <v-col cols="12" md="6">
                        <AnamnesisFamilyCard :value="document.anamnesisFamily" />
                        <BiometricCard :value="document.biometricData" />
                        <MedicalDiagnosticTestCard :value="document.medicalDiagnosticTest" :exam-id="visitId" />
                        <v-checkbox v-model="document.isFull" label="Завършена"></v-checkbox>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <RiskAssessmentIncludeRegisterDialog
            :dialog-is-visible="dialogIsVisible"
            :risk-groups="riskGroups"
            @closed="save"
        />
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { FindRiskCheckDto } from '@/model/Exam/FindRiskCheckDto';
    import { RiskAssessmentCardDto } from '@/model/Exam/Gp/RiskAssessmentCard/RiskAssessmentCardDto';
    import { RiskGroupCheckDto } from '@/model/Exam/Gp/RiskAssessmentCard/RiskGroupCheckDto';
    import { RiskGroupRuleCardDto } from '@/model/Exam/Gp/RiskAssessmentCard/RiskGroupRuleCardDto';
    import { ScoreCheckDto } from '@/model/Exam/ScoreCheckDto';
    // Sevice for CRUD operations
    import { riskAssessmentCardService } from '@/service/Exam/Gp/RiskAssessmentCardService';
    // Notification service
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import RiskAssessmentIncludeRegisterDialog from '@/view/Exam/Gp/RiskAssessmentCard/RiskAssessmentIncludeRegisterDialog.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    import AnamnesisFamilyCard from './AnamnesisFamilyCard.vue';
    import AnamnesisPersonalCard from './AnamnesisPersonalCard.vue';
    import BiometricCard from './BiometricCard.vue';
    import FindRiskCard from './FindRiskCard.vue';
    import MedicalDiagnosticTestCard from './MedicalDiagnosticTestCard.vue';
    import RiskByScoreCard from './RiskByScoreCard.vue';

    @Component({
        components: {
            RiskAssessmentIncludeRegisterDialog,
            UnsavedChanges,
            AnamnesisFamilyCard,
            AnamnesisPersonalCard,
            BiometricCard,
            MedicalDiagnosticTestCard,
            ExamLayout,
            RiskByScoreCard,
            FindRiskCard
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class RiskAssessmentCard extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private document: RiskAssessmentCardDto = new RiskAssessmentCardDto();
        private initialValue: RiskAssessmentCardDto = new RiskAssessmentCardDto();
        private scoreCheckDto: ScoreCheckDto = new ScoreCheckDto();
        private findRiskCheckDto: FindRiskCheckDto = new FindRiskCheckDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private dialogIsVisible: boolean = false;
        private riskGroups: RiskGroupRuleCardDto[] = [];
        private visitId: number = 0;

        private get hasChildIcdCode(): boolean {
            if (currentVisitCache.isLoaded) {
                const childIcdCodes = ['Z00.1', 'Z00.2', 'Z00.3'];
                const examIcdCodes = currentVisitCache.value.exam?.examDiagnoses.filter((fi) => fi.icdCode !== null);
                if (examIcdCodes) {
                    return examIcdCodes.some((fi) => childIcdCodes.includes(fi.icdCode ?? ''));
                }
            }
            return false;
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                if (!this.documentId) {
                    const responseCard = await riskAssessmentCardService.getRiskAssessmentCardInfoByExamId(this.examId);
                    this.document = responseCard.data;
                } else if (this.documentId) {
                    const responseCard = await riskAssessmentCardService.getRiskAssessmentCard(this.documentId);
                    this.document = responseCard.data;
                    await currentVisitCache.load(this.document.examId);
                    this.visitId = this.document.examId;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async fillFromPreviousCard() {
            if (currentPatientCache.isLoaded && currentVisitCache.isLoaded) {
                const responseCard = await riskAssessmentCardService.getLastRiskAssessmentCardInfo(
                    currentPatientCache.value.key.patientId
                );

                const { isPreventiveExam } = this.document;
                if (responseCard.data.examId >= 0) {
                    this.document = responseCard.data;
                }
                this.document.isPreventiveExam = isPreventiveExam;
                this.document.examId = currentVisitCache.value.id;
                this.document.issueDate =
                    currentVisitCache.value.startDateTime !== null ? currentVisitCache.value.startDateTime : new Date();
            }
        }

        @Watch('document.biometricData.bdSystolic')
        private onBdSystolicChanged(value: boolean) {
            if (value) {
                this.calculateScoreRisk();
            }
        }

        @Watch('document.medicalDiagnosticTest.mdiTh')
        private onMdiThChanged(value: boolean) {
            if (value) {
                this.calculateScoreRisk();
            }
        }

        @Watch('document.biometricData.bdItm')
        private onItmChanged() {
            this.calculateFindrisk();
        }

        @Watch('document.anamnesisFamily.diabetesRelatives')
        private onDiabetesRelativesChanged() {
            this.calculateFindrisk();
        }

        @Watch('document.anamnesisFamily.diabetesRelativesSecond')
        private onDiabetesRelativesSecondChanged() {
            this.calculateFindrisk();
        }

        @Watch('document.anamnesisPersonal.cigarettesCount')
        private onCigarettesCountChanged(value: boolean) {
            if (value) {
                this.calculateScoreRisk();
            }
        }

        @Watch('document.anamnesisPersonal.prediabetic')
        private onPrediabeticChanged() {
            this.calculateFindrisk();
        }

        @Watch('document.anamnesisPersonal.antihypertensive')
        private onAntihypertensiveChanged() {
            this.calculateFindrisk();
        }

        @Watch('document.anamnesisPersonal.fruitsVegetables')
        private onFruitsVegetablesChanged() {
            this.calculateFindrisk();
        }

        @Watch('document.anamnesisPersonal.sedentaryLife')
        private onSedentaryLifeChanged() {
            this.calculateFindrisk();
        }

        @Watch('document.biometricData.bdGirtWaist')
        private onBdGirtWaistChanged() {
            if (this.document.biometricData.bdGirtWaist) {
                this.calculateFindrisk();
            }
        }

        private async calculateFindrisk() {
            if (currentPatientCache.value.age !== null) {
                this.findRiskCheckDto.age = currentPatientCache.value.age;
            }
            if (
                this.document.biometricData.bdGirtWaist === null ||
                this.document.biometricData.bdGirtWaist.toString() === ''
            ) {
                this.document.biometricData.bdGirtWaist = 0;
            }
            this.findRiskCheckDto.antihypertensive = this.document.anamnesisPersonal.antihypertensive;
            this.findRiskCheckDto.diabetesRelatives = this.document.anamnesisFamily.diabetesRelatives;
            this.findRiskCheckDto.diabetesRelativesSecond = this.document.anamnesisFamily.diabetesRelativesSecond;
            this.findRiskCheckDto.fruit = this.document.anamnesisPersonal.fruitsVegetables;
            this.findRiskCheckDto.genderCode = currentPatientCache.value.personIdentification.genderCode;
            this.findRiskCheckDto.girtWaist = this.document.biometricData.bdGirtWaist;
            this.findRiskCheckDto.itm = this.document.biometricData.bdItm;
            this.findRiskCheckDto.prediabetic = this.document.anamnesisPersonal.prediabetic;
            this.findRiskCheckDto.sedentaryLife = this.document.anamnesisPersonal.sedentaryLife;

            const response = await riskAssessmentCardService.calculateFindRisk(this.findRiskCheckDto);
            this.document.findRisk = response.data;
        }

        private async calculateScoreRisk() {
            if (currentPatientCache.value.age !== null && this.document.medicalDiagnosticTest.mdiTh !== null) {
                this.scoreCheckDto.age = currentPatientCache.value.age;
                this.scoreCheckDto.bdSystolic = this.document.biometricData.bdSystolic;
                this.scoreCheckDto.cholesterol = this.document.medicalDiagnosticTest.mdiTh;
                this.scoreCheckDto.genderCode = currentPatientCache.value.personIdentification.genderCode;
                this.scoreCheckDto.isSmoker =
                    this.document.anamnesisPersonal.cigarettesCount === null ||
                    this.document.anamnesisPersonal.cigarettesCount === 0
                        ? false
                        : true;

                const response = await riskAssessmentCardService.calculateScore(this.scoreCheckDto);
                this.document.score = response.data;
            }
        }

        private async save(registerInclude: boolean) {
            this.dialogIsVisible = false;
            let cardId = this.document.id;
            this.document.registerInclude = registerInclude;
            this.document.riskGroups = this.riskGroups;
            this.document.patientId = currentPatientCache.value.key.patientId;
            if (cardId === 0) {
                const response = await riskAssessmentCardService.createRiskAssessmentCard(this.document);
                cardId = Number(response.data);
                this.copyObject();
                eventBus.$emit(
                    'create-referral-event',
                    cardId,
                    'RiskAssessmentCard',
                    'Карта за оценка на рисковите фактори'
                );
            } else {
                await riskAssessmentCardService.updateRiskAssessmentCard(this.document);
                this.copyObject();
                eventBus.$emit('update-referral-event', 'Карта за оценка на рисковите фактори');
            }

            this.$router.push(`/Exam/RiskAssessmentCard/Edit/${cardId}`);
        }

        private async updateEventHandler() {
            const errors = (await riskAssessmentCardService.canSaveRiskAssessmentCard(this.document)).data;
            if (errors.length <= 0) {
                await this.checkForRiskGroups();
            } else {
                const errorMessages: string[] = [];
                for (let errorPos: number = 0; errorPos < errors.length; errorPos++) {
                    errorMessages.push(errors[errorPos]);
                }
                if (errors.length > 0) {
                    this.$notifier.showError('Грешка', errorMessages.join(', '));
                }
            }
        }

        private async checkForRiskGroups() {
            const response = await riskAssessmentCardService.checkForRiskGroups(
                new RiskGroupCheckDto(
                    this.document,
                    currentPatientCache.value.key.patientId,
                    currentPatientCache.value.age ?? 0,
                    currentPatientCache.value.personIdentification.genderCode ?? ''
                )
            );

            if (response?.data.length > 0) {
                this.riskGroups = response.data;
                this.dialogIsVisible = true;
            } else {
                await this.save(false);
            }
        }

        private async deleteEventHandler() {
            const cardId = this.document.id;
            await riskAssessmentCardService.deleteRiskAssessmentCard(cardId);
            this.copyObject();
            this.$router.push(`/Exam/RiskAssessmentCard/Create/${currentVisitCache.value.id}`);
            eventBus.$emit(
                'delete-referral-event',
                cardId,
                'RiskAssessmentCard',
                'Карта за оценка на рисковите фактори'
            );
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Gp.RiskAssessmentCard/${this.documentId}`);
            } else {
                // Todo: notification
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Gp.RiskAssessmentCard';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
