import { plainToInstance } from 'class-transformer';
import Vue from 'vue';

import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';

const reportIntervalFilterKey: string = 'reportIntervalFilter';
const emptyValue = new ReportIntervalFilterDto();

class ReportIntervalCache {
    private _value: ReportIntervalFilterDto = emptyValue;

    public get value() {
        return this._value;
    }

    public clear() {
        localStorage.removeItem(reportIntervalFilterKey);
        this._value = emptyValue;
    }

    public set(item: ReportIntervalFilterDto) {
        if (item) {
            localStorage.setItem(reportIntervalFilterKey, JSON.stringify(item));
            this._value = item;
        }
    }

    public loadFromLocalStorage() {
        const json = localStorage.getItem(reportIntervalFilterKey);
        if (json) {
            const reportInterval = plainToInstance(ReportIntervalFilterDto, JSON.parse(json));
            reportInterval.startDate = reportInterval.startDate ? new Date(reportInterval.startDate) : null;
            reportInterval.endDate = reportInterval.endDate ? new Date(reportInterval.endDate) : null;
            this._value = reportInterval;
        }
    }
}

export const reportIntervalCache = Vue.observable(new ReportIntervalCache());
