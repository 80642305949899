import { NhifResponseDto } from '@/model/Nhif/NhifResponseDto';

export class NhifProtocolDocumentCheckDto extends NhifResponseDto {
    public sequenceId: number = 1;
    public documentType: string | null = null;
    public documentName: string | null = null;
    public documentFormat: string = '';
    public documentContent: string = '';
    public documentBytes: number | null = null;
    public doctorEmployeeSeqNumber: number = 0;
    public practiceId: number = 0;
}
