<template>
    <data-table title="Фискални памети" :headers="headers" :items="items" :loading="$loading.isVisible">
        <template #actions>
            <btn action="Back" :to="`/Practice/Fiscal/Device/Edit/${practiceId}/${fiscalDeviceSeqNumber}`" />
            <btn action="New" :to="`/Practice/Fiscal/Memory/Create/${practiceId}/${fiscalDeviceSeqNumber}`">
                Добавяне на фискална памет
            </btn>
        </template>
        <template #rowActions="{ item }">
            <btn
                icon
                action="Edit"
                :to="`/Practice/Fiscal/Memory/Edit/${practiceId}/${fiscalDeviceSeqNumber}/${item.code}`"
            />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { FiscalMemoryDto } from '@/model/Practice/Fiscal/FiscalMemoryDto';
    import { fiscalMemoryService } from '@/service/Practice/Fiscal/FiscalMemoryService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class MemoryList extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private fiscalDeviceSeqNumber!: number;

        private items: FiscalMemoryDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'Код', value: 'code' },
            { text: 'Валидна от', value: 'validFrom', formatter: formatters.date },
            { text: 'Валидна до', value: 'validTo', formatter: formatters.date }
        ];

        private async mounted() {
            this.$loading.show();
            try {
                const response = await fiscalMemoryService.getDeviceFiscalMemories(
                    this.practiceId,
                    this.fiscalDeviceSeqNumber
                );
                this.items = response.data || [];
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
