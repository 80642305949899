import { Type } from 'class-transformer';

import { ServiceOfferEditDto } from './ServiceOfferEditDto';
import { ServiceTitleDto } from './ServiceTitleDto';

export class PracticeServiceEditDto {
    public practiceId: number = 0;

    @Type(() => ServiceTitleDto)
    public serviceTitle: ServiceTitleDto = new ServiceTitleDto();

    @Type(() => ServiceOfferEditDto)
    public serviceOffers: ServiceOfferEditDto[] = [];

    public coreServiceTypeCode: string = '';
}
