import { DiagnosisDto } from '@/model/Common/DiagnosisDto';

import { ResultReferralPerformerDto } from './ResultReferralPerformerDto';

export class ResultReferral7Dto {
    public documentId: number = 0;
    public resultNrn: string = '';
    public epicrisisFilePath: string = '';
    public userToken: string = '';
    public openDate: Date | null = null;
    public performerInfo: ResultReferralPerformerDto = new ResultReferralPerformerDto();
    public admissionType: string = '';
    public admissionDate: Date | null = null;
    public dischargeDate: Date | null = null;
    public daysHospitalized: number = 0;
    public status: string = '';
    public diagnoses: DiagnosisDto[] = [];
    public comorbiditiesNames: DiagnosisDto[] = [];
}
