export class PatientWorkplaceDto {
    public patientId: number = 0;
    public id: number | null = null;
    public company: string = '';
    public eik: string = '';
    public profession: string = '';
    public position: string = '';
    public cityId: number | null = null;
    public street: string = '';
    public streetNumber: string = '';
    public neighborhood: string = '';
    public postalCode: string = '';
    public foreignCity: string = '';
    public isDeleted: boolean = false;

    public isItUse: boolean = false;
    public isValid: boolean = false;
}
