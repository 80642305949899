import { Type } from 'class-transformer';

import { Report365PhysiotherapeuticActivityRowDto } from '@/model/Report/Rhi/Report365/Report365PhysiotherapeuticActivityRowDto';

export class Report365PhysiotherapeuticActivityDto {
    public name: string = '';

    @Type(() => Report365PhysiotherapeuticActivityRowDto)
    public physiotherapeuticActivities: Report365PhysiotherapeuticActivityRowDto[] = [];
}
