export class ExternalVisitRowDto {
    public patientId: number = 0;
    public seqNumber: number = 0;
    public visitId: number | null = null;
    public date: Date = new Date();
    public documentType: string = '';
    public documentNumber: string = '';
    public practiceName: string = '';
    public doctorName: string = '';
    public specialty: string = '';
    public visitNumberAndDate: string = '';
}
