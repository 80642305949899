import { plainToInstance } from 'class-transformer';

import { DeputyDoctorDropdownDto } from '@/model/Employee/Deputy/DeputyDoctorDropdownDto';
import { DoctorDeputyCreateDeputyCommand } from '@/model/Employee/Deputy/DoctorDeputyCreateDeputyCommand';
import { DoctorDeputyCreateHiredCommand } from '@/model/Employee/Deputy/DoctorDeputyCreateHiredCommand';
import { DoctorDeputyEditDto } from '@/model/Employee/Deputy/DoctorDeputyEditDto';
import { DoctorDeputyKey } from '@/model/Employee/Deputy/DoctorDeputyKey';
import { DoctorDeputyUpdateCommand } from '@/model/Employee/Deputy/DoctorDeputyUpdateCommand';
import { DoctorDeputyWithLastScheduleDto } from '@/model/Employee/Deputy/DoctorDeputyWithLastScheduleDto';
import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
import { UserExistanceDto } from '@/model/Employee/Invitation/UserExistanceDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const doctorDeputyService = {
    async getDoctorEmployeesWithDifferentUin(
        practiceId: number,
        doctorEmployeeSeqNumber: number
    ): Promise<EmployeeTitleDto[]> {
        const response = await httpService.get<EmployeeTitleDto[]>('DoctorDeputy/GetDoctorEmployeesWithDifferentUin', {
            params: { practiceId, doctorEmployeeSeqNumber }
        });
        return plainToInstance(EmployeeTitleDto, response.data);
    },

    async getHiredDeputyDoctors(practiceId: number, doctorEmployeeSeqNumber: number): Promise<EmployeeTitleDto[]> {
        const response = await httpService.get<EmployeeTitleDto[]>('DoctorDeputy/GetHiredDeputyDoctors', {
            params: { practiceId, doctorEmployeeSeqNumber }
        });
        return plainToInstance(EmployeeTitleDto, response.data);
    },

    async getDeputyDoctors(practiceId: number, employeeSeqNumber: number): Promise<DeputyDoctorDropdownDto[]> {
        const response = await httpService.get<DeputyDoctorDropdownDto[]>('DoctorDeputy/GetDeputyDoctors', {
            params: { practiceId, employeeSeqNumber }
        });
        return plainToInstance(DeputyDoctorDropdownDto, response.data);
    },

    async getDoctorDeputiesWithLastSchedule(
        practiceId: number,
        employeeSeqNumber: number
    ): Promise<DoctorDeputyWithLastScheduleDto[]> {
        const response = await httpService.get<DoctorDeputyWithLastScheduleDto[]>(
            'DoctorDeputy/GetDoctorDeputiesWithLastSchedule',
            { params: { practiceId, employeeSeqNumber } }
        );
        return plainToInstance(DoctorDeputyWithLastScheduleDto, response.data);
    },

    async getDoctorDeputyForEdit(ddKey: DoctorDeputyKey): Promise<DoctorDeputyEditDto> {
        const response = await httpService.get<DoctorDeputyEditDto>('DoctorDeputy/GetDoctorDeputyForEdit', {
            params: ddKey
        });
        return plainToInstance(DoctorDeputyEditDto, response.data);
    },

    async createDoctorDeputyAsDeputy(command: DoctorDeputyCreateDeputyCommand): Promise<void> {
        await httpService.post<number>('DoctorDeputy/CreateDoctorDeputyAsDeputy', command);
    },

    async createDoctorDeputyAsHired(command: DoctorDeputyCreateHiredCommand): Promise<number> {
        const response = await httpService.post<number>('DoctorDeputy/CreateDoctorDeputyAsHired', command);
        return response.data;
    },

    async checkDoctorDeputyExistance(ddKey: DoctorDeputyKey, email: string): Promise<UserExistanceDto> {
        const response = await httpService.get<UserExistanceDto>('DoctorDeputy/CheckDoctorDeputyExistance', {
            params: { ...ddKey, email }
        });
        return plainToInstance(UserExistanceDto, response.data);
    },

    async updateDoctorDeputy(command: DoctorDeputyUpdateCommand): Promise<void> {
        await httpService.put(`DoctorDeputy/UpdateDoctorDeputy`, command);
    },

    async deleteDoctorDeputyInvitation(ddKey: DoctorDeputyKey, email: string): Promise<void> {
        await httpService.delete('DoctorDeputy/DeleteDoctorDeputyInvitation', {
            // Комбинация от POST и GET параметри.
            data: ddKey,
            params: { email }
        });
    },

    async deleteUnusedDoctorDeputy(ddKey: DoctorDeputyKey): Promise<void> {
        await httpService.delete('DoctorDeputy/DeleteUnusedDoctorDeputy', { data: ddKey });
    }
};
