import { plainToInstance } from 'class-transformer';

import { PatientAllergyDto } from '@/model/Patient/Allergy/PatientAllergyDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientAllergyService = {
    async getPatientAllergies(patientId: number): Promise<PatientAllergyDto[]> {
        const response = await httpService.get<PatientAllergyDto[]>(`/PatientAllergy/GetPatientAllergies`, {
            params: { patientId }
        });
        return plainToInstance(PatientAllergyDto, response.data);
    },

    async getById(id: number): Promise<PatientAllergyDto> {
        const response = await httpService.get<PatientAllergyDto>(`/PatientAllergy/GetById/${id}`);
        return plainToInstance(PatientAllergyDto, response.data);
    },

    async createPatientAllergy(data: PatientAllergyDto): Promise<number> {
        const response = await httpService.post<number>('PatientAllergy/CreatePatientAllergy', data);
        return response?.data ?? 0;
    },

    async updatePatientAllergy(data: PatientAllergyDto) {
        const response = await httpService.put('PatientAllergy/UpdatePatientAllergy', data);
        return response?.data;
    },

    async deletePatientAllergy(patientAllergyId: number) {
        const response = await httpService.delete(`/PatientAllergy/DeletePatientAllergy`, {
            params: { patientAllergyId }
        });
        return response?.data;
    }
};
