<template>
    <v-card-text>
        <v-row>
            <v-col cols="12" md="3" class="py-0">
                <v-select
                    v-model="value.isIssuedByLkkCommission"
                    class="required"
                    dense
                    :rules="[$validator.required]"
                    :items="[
                        { id: false, name: 'Лекар' },
                        { id: true, name: 'Членове на ЛКК' }
                    ]"
                    item-text="name"
                    item-value="id"
                    label="Лекар/Членове на ЛКК"
                />
            </v-col>
            <v-col cols="12" md="4" class="py-0">
                <text-field v-model="value.familyNameDoctor1" label="1." hint="фамилия" required />
            </v-col>
            <v-col cols="12" md="5" class="py-0">
                <text-field v-model="value.uin1" hint="УИН" required />
            </v-col>
        </v-row>
        <v-row>
            <v-col offset-md="3" cols="12" md="4" class="py-0">
                <text-field v-model="value.familyNameDoctor2" label="2." hint="фамилия" />
            </v-col>
            <v-col md="5" class="py-0">
                <text-field v-model="value.uin2" hint="УИН" />
            </v-col>
        </v-row>
        <v-row>
            <v-col offset-md="3" cols="12" md="4" class="pt-0">
                <text-field v-model="value.familyNameDoctor3" label="3." hint="фамилия" />
            </v-col>
            <v-col md="5" class="pt-0">
                <text-field v-model="value.uin3" hint="УИН" />
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { SickSheetDto } from '@/model/Exam/SickSheet/SickSheetDto';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class SickSheetDoctor extends Vue {
        @Prop()
        value!: SickSheetDto;

        @Watch('value.familyNameDoctor1')
        private onFamilyNameDoctor1LastNameChanged() {
            this.value.familyNameDoctor1 = formatters.capitalizeFirstLetter(this.value.familyNameDoctor1);
        }

        @Watch('value.familyNameDoctor2')
        private onFamilyNameDoctor2LastNameChanged() {
            this.value.familyNameDoctor2 = formatters.capitalizeFirstLetter(this.value.familyNameDoctor2);
        }

        @Watch('value.familyNameDoctor3')
        private onFamilyNameDoctor3LastNameChanged() {
            this.value.familyNameDoctor3 = formatters.capitalizeFirstLetter(this.value.familyNameDoctor3);
        }
    }
</script>
