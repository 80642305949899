<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Медицинско направление за комплексно диспансерно наблюдение"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <main-title>Медицинско направление за комплексно диспансерно наблюдение</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6"><PatientInfo /></v-col>
                    <v-col cols="12" md="6"><DoctorInfo /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="2">
                        <date-picker v-model="document.issueDate" label="Дата" required />
                    </v-col>
                    <v-col cols="6" md="2">
                        <text-field v-model="document.ambulatorySheetNumber" disabled label="Амб. лист номер" />
                    </v-col>
                    <v-col cols="6" md="2">
                        <text-field v-model="document.number" label="Номер" required />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-textarea v-model="document.diagnosesText" label="Диагнози" />
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-row>
                            <v-col cols="12"><ExamDiagnosisPicker v-model="examDiagnosis1" /></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"><ExamDiagnosisPicker v-model="examDiagnosis2" /></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"><ExamDiagnosisPicker v-model="examDiagnosis3" /></v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-row class="justify-end">
                            <v-col md="5"><text-field disabled label="код специалност" /></v-col>
                            <v-col md="7"><text-field disabled label="рег. номер на лечебно заведение" /></v-col>
                        </v-row>
                        <v-row class="justify-end">
                            <v-col md="7"><text-field disabled label="УИН на лекаря" /></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4"><text-field disabled label="Представляващ леч. зав." /></v-col>
                            <v-col cols="12" md="4">
                                <text-field disabled label="Началник клиника/отделение" />
                            </v-col>
                            <v-col cols="12" md="4"><text-field disabled label="Лекуващ лекар" /></v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <dropdown
                            v-model="document.referralSubtypeId"
                            :items="referralSubtypes"
                            label="Изпраща се за"
                            required
                        ></dropdown>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-textarea v-model="document.attachedDocs" label="Приложени мед. документи" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamDiagnosisPicker from '@/component/Exam/ExamDiagnosisPicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { ReferralDispensaryObservationDto } from '@/model/Exam/Referral/ReferralDispensaryObservationDto';
    import { ReferralSubtypeDto } from '@/model/Nomenclature/ReferralSubtypeDto';
    import { referralDispensaryObservationService } from '@/service/Exam/Referral/ReferralDispensaryObservationService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { referralSubtypeService } from '@/service/Nomenclature/ReferralSubtypeService';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: {
            UnsavedChanges,
            ExamDiagnosisPicker,
            PatientInfo,
            DoctorInfo,
            ExamLayout
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class ReferralDispensaryObservation extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private referralSubtypes: ReferralSubtypeDto[] = [];
        private document: ReferralDispensaryObservationDto = new ReferralDispensaryObservationDto();
        private initialValue: ReferralDispensaryObservationDto = new ReferralDispensaryObservationDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                const response = await referralSubtypeService.getReferralDispObservSubtypes();
                this.referralSubtypes = response.data;

                if (!this.documentId) {
                    const responseExam = await referralDispensaryObservationService.getReferralInfoByExamId(
                        this.examId
                    );
                    this.document = responseExam.data;
                } else {
                    const responseReferral =
                        await referralDispensaryObservationService.getReferralDispensaryObservation(this.documentId);
                    this.document = responseReferral.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private get examDiagnosis1() {
            return currentVisitCache.value.exam?.getExamDiagnosis(this.document.diagnosis1Id);
        }

        private set examDiagnosis1(examDiagnosis) {
            this.document.diagnosis1Id = examDiagnosis ? examDiagnosis.id : null;
        }

        private get examDiagnosis2() {
            return currentVisitCache.value.exam?.getExamDiagnosis(this.document.diagnosis2Id);
        }

        private set examDiagnosis2(examDiagnosis) {
            this.document.diagnosis2Id = examDiagnosis ? examDiagnosis.id : null;
        }

        private get examDiagnosis3() {
            return currentVisitCache.value.exam?.getExamDiagnosis(this.document.diagnosis3Id);
        }

        private set examDiagnosis3(examDiagnosis) {
            this.document.diagnosis3Id = examDiagnosis ? examDiagnosis.id : null;
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (documentId === 0) {
                    const refResponse = await referralDispensaryObservationService.createReferralDispensaryObservation(
                        this.document
                    );
                    documentId = Number(refResponse.data);
                    this.copyObject();
                    eventBus.$emit(
                        'create-referral-event',
                        documentId,
                        'ReferralDispObserv',
                        'Медицинско направление за комплексно диспансерно наблюдение'
                    );
                    this.$router.push(`/Exam/ReferralDispObserv/Edit/${documentId}`);
                } else {
                    await referralDispensaryObservationService.updateReferralDispensaryObservation(this.document);
                    eventBus.$emit(
                        'update-referral-event',
                        'Медицинско направление за комплексно диспансерно наблюдение'
                    );
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                await referralDispensaryObservationService.deleteReferralDispensaryObservation(documentId);
                this.copyObject();
                this.$router.push(`/Exam/ReferralDispObserv/Create/${currentVisitCache.value.id}`);
                eventBus.$emit(
                    'delete-referral-event',
                    documentId,
                    'ReferralDispObserv',
                    'Медицинско направление за комплексно диспансерно наблюдение'
                );
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Referral.ReferralDispensaryObservation/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Referral.ReferralDispensaryObservation';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
