<template>
    <v-dialog v-model="value.dialogIsVisible" max-width="auto">
        <v-form ref="documentForm" v-model="isValid">
            <v-card>
                <v-card-title>
                    Бележки към болничния лист
                    <v-spacer></v-spacer>
                    <btn action="Next" @click="sickSheetNotesUpdated">Попълване</btn>
                    <btn action="Cancel" @click="cancel" />
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-row>
                        <!--
                Видовете бележки са спуснати от НОИ и са изброени в базата данни.
                Показва се по една отметка за всеки вид бележки. Някои видове бележки имат допълнителни параметри,
                които се показват само ако съответната отметка е включена.
                От имената на избраните отметки(видове бележки) и параметрите им се сглобява общ текст за печат.
            -->
                        <v-col v-for="item in noteTypes" :key="item.nssiCode" cols="12" md="6" pt="0">
                            <v-checkbox
                                v-model="value.selectedNssiCodes"
                                :value="item.nssiCode"
                                :label="item.name"
                                hide-details
                            />

                            <!-- Ако текущата отметка(от цикъла) е включена, се показват параметрите за съответния вид бележки. -->
                            <div v-if="value.selectedNssiCodes.includes(item.nssiCode)">
                                <text-field
                                    v-if="item.code === 'MultipleEmployers'"
                                    v-model.number="multipleEmployers"
                                    label="Брой работодатели"
                                    hint="При попълване на тази бележка се вписва и броят на работодателите/ осигурителите, за които е издаден."
                                    type="number"
                                />

                                <div v-if="item.code === 'Employment'">
                                    <v-row>
                                        <v-col cols="12" md="6" pt="0">
                                            <v-checkbox
                                                v-model="employmentCheckBoxes"
                                                label="работно място/изисквания"
                                                hide-details
                                                value="1"
                                                :disabled="employmentCheckBoxes.includes('2')"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="6" pt="0">
                                            <text-field
                                                v-model="employmentWorkplaceMessage"
                                                hint="Посочва се работното място/изискванията на работното място"
                                                :disabled="!employmentCheckBoxes.includes('1')"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6" pt="0">
                                            <v-checkbox
                                                v-model="employmentCheckBoxes"
                                                label="длъжност/изисквания"
                                                hide-details
                                                value="2"
                                                :disabled="employmentCheckBoxes.includes('1')"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="6" pt="0">
                                            <text-field
                                                v-model="employmentPossitionMessage"
                                                hint="Посочва се длъжноста/изискванията на работното място"
                                                :disabled="!employmentCheckBoxes.includes('2')"
                                            />
                                        </v-col>
                                    </v-row>
                                </div>

                                <div v-if="item.code === 'PastTime'">
                                    <v-row>
                                        <v-col cols="12" md="4" pt="0">
                                            <v-checkbox
                                                v-model="pastTimePublishCheckBoxes"
                                                label="№ и дата на амбулаторния лист"
                                                hide-details
                                                value="1"
                                            />
                                        </v-col>
                                        <v-col cols="6" md="4" pt="0">
                                            <text-field
                                                v-model="pastTimePublishAmbSheetNumber"
                                                label="№ Амб. лист"
                                                hint="Попълва се № на Амб. лист, на който е регистриран прегледа"
                                                :disabled="!pastTimePublishCheckBoxes.includes('1')"
                                            />
                                        </v-col>
                                        <v-col cols="6" md="4" pt="0">
                                            <date-picker
                                                v-model="pastTimePublishAmbSheetDate"
                                                label="Дата"
                                                :disabled="!pastTimePublishCheckBoxes.includes('1')"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6" pt="0">
                                            <v-checkbox
                                                v-model="pastTimePublishCheckBoxes"
                                                label="Причините за неизвършения предварително преглед"
                                                hide-details
                                                value="2"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="6" pt="0">
                                            <text-field
                                                v-model="pastTimePublishCancelReason"
                                                label="Причини"
                                                hint="Попълват се причините за неизвършения предварително преглед."
                                                :disabled="!pastTimePublishCheckBoxes.includes('2')"
                                            />
                                        </v-col>
                                    </v-row>
                                </div>

                                <dropdown
                                    v-if="item.code === 'ForeignCountry'"
                                    v-model="insuredInCountryName"
                                    :items="countries"
                                    item-value="name"
                                    label="Държава"
                                />

                                <text-field
                                    v-if="item.code === 'SickFamilyMember'"
                                    v-model="sickFamilyMemberNumberSickHistory"
                                    label="№ на ист. на заболяване"
                                    hint="Попълва се номерът на историята на заболяването на болния член от семейството."
                                />

                                <v-textarea
                                    v-if="item.code === 'Other'"
                                    v-model="value.additionalNotes"
                                    rows="1"
                                    counter="300"
                                    auto-grow
                                    no-resize
                                    clearable
                                    outlined
                                    :rules="minAndMaxAdditionalNotesCanceledChars"
                                    clear-icon="mdi-close-circle"
                                />
                            </div>
                        </v-col>
                    </v-row>
                    <v-textarea
                        v-model="value.generatedNotes"
                        class="mt-8"
                        label="Текст, сглобен от избраните по-горе категории:"
                        counter="200"
                        height="100"
                        readonly
                        outlined
                        :rules="minAndMaxCanceledChars"
                    />
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

    import { SickSheetNoteTypeCode } from '@/enum/Exam/SickSheetNoteTypeCode';
    import { SickSheetDto } from '@/model/Exam/SickSheet/SickSheetDto';
    import { SickSheetNotesDialogModel } from '@/model/Exam/SickSheet/SickSheetNotesDialogModel';
    import { CountryDto } from '@/model/Nomenclature/CountryDto';
    import { SickSheetNoteTypeDto } from '@/model/Nomenclature/SickSheetNoteTypeDto';
    import { countryService } from '@/service/Nomenclature/CountryService';
    import { sickSheetNoteTypeCache, sickSheetNoteTypeService } from '@/service/Nomenclature/SickSheetNoteTypeService';

    const configValues = {
        datePresicion: 10,
        maxCanceledChars: 200,
        maxAddNotesChars: 300
    };

    @Component
    export default class SickSheetNotes extends Vue {
        @Prop({ required: true })
        private value!: SickSheetNotesDialogModel;

        @Prop({ required: true })
        private sickSheet!: SickSheetDto;

        private multipleEmployers: number = 0;
        private employmentCheckBoxes: string[] = [];
        private employmentWorkplaceMessage: string = '';
        private employmentPossitionMessage: string = '';
        private pastTimePublishCheckBoxes: string[] = [];
        private pastTimePublishAmbSheetNumber: string = '';
        private pastTimePublishAmbSheetDate: Date | null = null;
        private pastTimePublishCancelReason: string = '';
        private sickFamilyMemberNumberSickHistory: string = '';
        private insuredInCountryName: string = '';
        private countryId: number = 0;
        private isItConfirmedComit: boolean = false;
        private isValid: boolean = false;

        private countries: CountryDto[] = [];

        private minAndMaxCanceledChars: ((val: string) => void)[] | boolean[] | string[] = [
            (val: string) =>
                (val && val.length <= configValues.maxCanceledChars) ||
                `Дължината на текста не може да е повече от ${configValues.maxCanceledChars} символа`
        ];

        private minAndMaxAdditionalNotesCanceledChars: ((val: string) => void)[] | boolean[] | string[] = [
            (val: string) =>
                (val && val.length <= configValues.maxAddNotesChars) ||
                `Дължината на текста не може да е повече от ${configValues.maxAddNotesChars} символа`
        ];

        private async mounted() {
            await this.loadNoteTypes();
            await sickSheetNoteTypeService.loadOnceSickSheetNoteTypes();
            await this.getCountries();
            this.setInitialNotes();
        }

        private async getCountries() {
            const response = await countryService.getCountries();
            this.countries = response.data;
        }

        private get noteTypesOld() {
            return sickSheetNoteTypeCache.items;
        }

        private async loadNoteTypes() {
            this.noteTypes = await sickSheetNoteTypeService.loadNoteTypes();
        }

        private noteTypes: SickSheetNoteTypeDto[] = [];

        @Watch('value.selectedNssiCodes')
        private onCheckBoxesChange(nssiCodes: string[]) {
            const noteNames: string[] = [];
            if (nssiCodes && nssiCodes.length) {
                for (const nssiCode of nssiCodes) {
                    const noteType = sickSheetNoteTypeService.getNoteType(nssiCode);
                    if (noteType) {
                        const { code } = noteType;
                        if (code === SickSheetNoteTypeCode.MultipleEmployers) {
                            noteNames.push(this.generateMultipleEmployersText(noteType.name));
                        } else if (code === SickSheetNoteTypeCode.Employment) {
                            noteNames.push(this.generateEmploymentText(noteType.name));
                        } else if (code === SickSheetNoteTypeCode.PastTime) {
                            noteNames.push(this.generatePastTimePublishText(noteType.name));
                        } else if (code === SickSheetNoteTypeCode.SickFamilyMember) {
                            noteNames.push(this.generateSickFamilyMemberText(noteType.name));
                        } else if (code === SickSheetNoteTypeCode.ForeignCountry) {
                            noteNames.push(this.generateForeignCountryText(noteType.name));
                        } else if (code !== SickSheetNoteTypeCode.Other) {
                            noteNames.push(noteType.name);
                        }
                    } else {
                        noteNames.push(`Код ${nssiCode}`);
                    }
                }
                this.value.generatedNotes = noteNames.join(';\r\n');
            } else {
                this.value.generatedNotes = '';
            }
        }

        @Emit()
        private sickSheetNotesUpdated() {
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();
            if (
                this.isValid ||
                (this.value.selectedNssiCodes.length === 1 &&
                    this.value.selectedNssiCodes.includes('20') &&
                    this.value.additionalNotes)
            ) {
                this.value.dialogIsVisible = false;
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private cancel() {
            this.setInitialNotes();
            this.value.dialogIsVisible = false;
        }

        private setInitialNotes() {
            this.value.selectedNssiCodes = this.sickSheet.noteNssiCodes;
            this.value.generatedNotes = this.sickSheet.generatedNotes;
            this.value.additionalNotes = this.sickSheet.additionalNotes;
            this.extractValuesFromGeneratedNotes();
        }

        private generateMultipleEmployersText(typoNoteName: string) {
            let text = typoNoteName;
            if (this.multipleEmployers) {
                text += `Брой работодатели/осигурители: ${this.multipleEmployers}`;
            }

            return text;
        }

        private generateEmploymentText(typeNoteName: string) {
            let text = typeNoteName;
            if (this.employmentCheckBoxes.includes('1')) {
                text += `: Работно място/Изисквания: ${this.employmentWorkplaceMessage}`;
            } else if (this.employmentCheckBoxes.includes('2')) {
                text += `: Длъжност/Изисквания: ${this.employmentPossitionMessage}`;
            }

            return text;
        }

        private generatePastTimePublishText(typeNoteName: string) {
            let text = typeNoteName;
            if (this.pastTimePublishCheckBoxes.includes('1') && this.pastTimePublishAmbSheetDate) {
                text += `Амбулаторен лист №${
                    this.pastTimePublishAmbSheetNumber
                } от дата: ${this.pastTimePublishAmbSheetDate?.getDate()}.${
                    this.pastTimePublishAmbSheetDate?.getMonth() + 1
                }.${this.pastTimePublishAmbSheetDate?.getFullYear()}`;
            } else if (this.pastTimePublishCheckBoxes.includes('2')) {
                text += `Причина: ${this.pastTimePublishCancelReason}`;
            }

            return text;
        }

        private generateSickFamilyMemberText(typeNoteName: string) {
            let text = typeNoteName;
            if (this.sickFamilyMemberNumberSickHistory) {
                text += `№ ${this.sickFamilyMemberNumberSickHistory}`;
            }

            return text;
        }

        private generateForeignCountryText(typeNoteName: string) {
            let text = typeNoteName;
            if (this.insuredInCountryName) {
                text += `: ${this.insuredInCountryName}`;
            }

            return text;
        }

        // Н. Дойчев получи се сложен метод за прихващане на всички по специални бележки и тяхното попълване
        // за момента оставям метода така докато не измисля по-хубав и прост вариант
        private extractValuesFromGeneratedNotes() {
            const notes = this.sickSheet.generatedNotes;
            for (const nssiCode of this.sickSheet.noteNssiCodes) {
                const noteType = sickSheetNoteTypeService.getNoteType(nssiCode);
                if (noteType) {
                    const { code } = noteType;
                    if (code === SickSheetNoteTypeCode.MultipleEmployers) {
                        const match = notes.match(/(?<=осигурители: )(?<number>\d+)/gmu);
                        this.multipleEmployers = match ? Number(match) : 0;
                    } else if (code === SickSheetNoteTypeCode.Employment) {
                        this.extractEmploymentValues();
                    } else if (code === SickSheetNoteTypeCode.PastTime) {
                        this.extractPastTimeValues();
                    } else if (code === SickSheetNoteTypeCode.ForeignCountry) {
                        const match = notes.match(/(?<=държава.:)(?<country>[\wа-яА-Я]*\s*[\wа-яА-Я]*)/gmu);
                        [this.insuredInCountryName] = match ? match : '';
                    } else if (code === SickSheetNoteTypeCode.SickFamilyMember) {
                        const match = notes.match(/(?<=ЛЗ\.№ )(?<number>[\d]+)/gmu);
                        [this.sickFamilyMemberNumberSickHistory] = match ? match : '';
                    }
                }
            }
        }

        private extractEmploymentValues() {
            const notes = this.sickSheet.generatedNotes;
            const match = notes.match(/(?<=Работно място\/Изисквания: )[\wа-яА-Я]*\s*[\wа-яА-Я]*/gmu);
            const secondMatch = notes.match(/(?<=Длъжност\/Изисквания: )[\wа-яА-Я]*\s*[\wа-яА-Я]*/gmu);
            if (match) {
                this.employmentCheckBoxes.push('1');
                [this.employmentWorkplaceMessage] = match;
            } else if (secondMatch) {
                this.employmentCheckBoxes.push('2');
                [this.employmentPossitionMessage] = secondMatch;
            }
        }

        private extractPastTimeValues() {
            const notes = this.sickSheet.generatedNotes;
            const match = notes.match(
                /(?<=Амбулаторен лист №)(?<number>[\d]+)(?<template>\sот дата:\s)(?<date>[\d]{4}-[\d]{2}-[\d]{2})/mu
            );
            const secondMatch = notes.match(/(?<=Причина: )[\wа-яА-Я]*\s*[\wа-яА-Я]*/gmu);
            if (match?.groups && match?.groups.number && match.groups.date) {
                this.pastTimePublishCheckBoxes.push('1');
                this.pastTimePublishAmbSheetNumber = match.groups.number;
                this.pastTimePublishAmbSheetDate = match.groups.date ? new Date(match.groups.date) : null;
            } else if (secondMatch) {
                this.pastTimePublishCheckBoxes.push('2');
                [this.pastTimePublishCancelReason] = secondMatch;
            }
        }

        @Watch('multipleEmployers')
        private getMultipleEmployers(value: number) {
            if (value > 0) {
                this.onCheckBoxesChange(this.value.selectedNssiCodes);
            }
        }

        @Watch('employmentWorkplaceMessage')
        private onEmploymentWorkplaceChange(value: string) {
            if (value) {
                this.onCheckBoxesChange(this.value.selectedNssiCodes);
            }
        }

        @Watch('employmentPossitionMessage')
        private onEmploymentPosotionChange(value: string) {
            if (value) {
                this.onCheckBoxesChange(this.value.selectedNssiCodes);
            }
        }

        @Watch('pastTimePublishAmbSheetNumber')
        private onPastTimePublishAmbNumber(value: string) {
            if (value) {
                this.onCheckBoxesChange(this.value.selectedNssiCodes);
            }
        }

        @Watch('pastTimePublishAmbSheetDate')
        private onPastTimePublishAmbDate(value: string) {
            if (value) {
                this.onCheckBoxesChange(this.value.selectedNssiCodes);
            }
        }

        @Watch('pastTimePublishCancelReason')
        private onPastTimePublishCancelReason(value: string) {
            if (value) {
                this.onCheckBoxesChange(this.value.selectedNssiCodes);
            }
        }

        @Watch('sickFamilyMemberNumberSickHistory')
        private onNumberSickHistoryChange(value: string) {
            if (value) {
                this.onCheckBoxesChange(this.value.selectedNssiCodes);
            }
        }

        @Watch('insuredInCountryName')
        private onCountryChange(countryName: string) {
            if (countryName) {
                this.onCheckBoxesChange(this.value.selectedNssiCodes);
            }
        }
    }
</script>
