<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">ДЕЙНОСТИ, СВЪРЗАНИ С ДЕТСКОТО ЗДРАВЕОПАЗВАНЕ</h3></v-col>
            <v-col cols="12"><h3 class="mb-2">3. Наблюдение на новородени</h3></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><DataTableComponent v-model="value.newbornRegisters" :headers="headers" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Annex3NewbornRegisterDto } from '@/model/Report/Rhi/Annex3/Annex3NewbornRegisterDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class NewbornRegister extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Annex3NewbornRegisterDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: 'Шифър', value: 'code' },
            { text: 'Постъпили през I-вия месец от живота си', value: 'countFirstMonth' },
            { text: 'Новородени, посетени до 24-я час от изписването', value: 'countVisited' }
        ];
    }
</script>
