import { AxiosResponse } from 'axios';

import { PracticeFundDto } from '@/model/Practice/PracticeFundDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const practiceFundService = {
    getActiveFunds(
        practiceId: number,
        fundCode: string | null,
        loadAll: boolean | null
    ): Promise<AxiosResponse<PracticeFundDto[]>> {
        return httpService.get(`/PracticeFund/GetActiveFunds`, { params: { practiceId, fundCode, loadAll } });
    }
};
