<template>
    <v-card>
        <v-card-title>
            {{ seqNumber ? 'Редактиране' : 'Добавяне' }} на {{ dto.isDeleted ? 'НЕАКТИВНО ' : '' }}фискално устройство
        </v-card-title>

        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn action="Save" @click="save" />
            <btn v-if="seqNumber" action="Account" :to="`/Practice/Fiscal/Operator/List/${practiceId}/${seqNumber}`">
                Оператори
            </btn>
            <btn v-if="seqNumber" action="Memory" :to="`/Practice/Fiscal/Memory/List/${practiceId}/${seqNumber}`" />
        </v-card-actions>

        <v-form ref="documentForm" v-model="isValid">
            <v-card-text>
                <v-row class="align-baseline">
                    <v-col cols="3">
                        <text-field v-model="dto.code" required label="Код на фискално устройство" />
                    </v-col>
                    <v-col cols="3">
                        <text-field v-model="dto.displayName" label="Наименование" />
                    </v-col>
                    <v-col cols="3">
                        <v-checkbox v-model="dto.isDeleted" label="Неактивно" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { FiscalDeviceDto } from '@/model/Practice/Fiscal/FiscalDeviceDto';
    import { fiscalDeviceService } from '@/service/Practice/Fiscal/FiscalDeviceService';

    @Component
    export default class DeviceEdit extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop()
        private seqNumber!: number;

        private isValid: boolean = false;
        private dto: FiscalDeviceDto = new FiscalDeviceDto();

        private async mounted() {
            if (this.seqNumber) {
                this.$loading.show();
                try {
                    const response = await fiscalDeviceService.getFiscalDevice(this.practiceId, this.seqNumber);
                    this.dto = response.data;
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private async save() {
            let newDevice = null;
            this.$loading.show();
            try {
                (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

                if (this.isValid) {
                    if (this.seqNumber) {
                        await fiscalDeviceService.updateFiscalDevice(this.dto);
                    } else if (this.practiceId) {
                        this.dto.practiceId = this.practiceId;
                        newDevice = (await fiscalDeviceService.createFiscalDevice(this.dto)).data;
                    }
                    this.$router.push(this.backRoute);
                }
            } finally {
                this.$loading.hide();
            }
            if (newDevice) {
                this.$router.push(`/Practice/Fiscal/Device/Edit/${newDevice.practiceId}/${newDevice.seqNumber}`);
            }
        }

        private async deleteFiscalDevice() {
            this.$loading.show();
            try {
                if (this.practiceId && this.seqNumber) {
                    await fiscalDeviceService.deleteFiscalDevice(this.practiceId, this.seqNumber);
                }
                this.$router.push(this.backRoute);
            } finally {
                this.$loading.hide();
            }
        }

        private get backRoute() {
            return `/Practice/Fiscal/Device/List/${this.practiceId}`;
        }
    }
</script>
