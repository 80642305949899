type objectType = Record<string, unknown>;

export const objectUtil = {
    deepEqual(object1: unknown, object2: unknown): boolean {
        const keys1 = Object.keys(object1 as objectType);
        const keys2 = Object.keys(object2 as objectType);
        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const val1 = (object1 as objectType)[key];
            const val2 = (object2 as objectType)[key];

            const areObjects = this.isObject(val1) && this.isObject(val2);
            const areNumbers = typeof val1 === 'number' && typeof val2 === 'number';
            const areDates = val1 instanceof Date && val2 instanceof Date;

            if (
                (areDates && val1.getTime() !== val2.getTime()) ||
                (areObjects && !this.deepEqual(val1 as objectType, val2 as objectType)) ||
                (!areObjects && val1 !== val2 && ((areNumbers && (!isNaN(val1) || !isNaN(val2))) || !areNumbers))
            ) {
                //console.log(`Key: ${key}, obj1: ${val1}, obj2: ${val2}`);
                return false;
            }
        }

        return true;
    },

    isObject<T>(object: T): boolean {
        return object !== null && typeof object === 'object';
    }
};
