import { Route, RouteConfig } from 'vue-router';

import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';

export const examPrescriptionRoutes: RouteConfig[] = [
    {
        path: 'PrescriptionNhif/Create/:examId',
        props: {
            exam: (route: Route) => ({ prescriptionKey: new PrescriptionKey(Number(route.params.examId), 0) })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhif.vue')
        }
    },
    {
        path: 'PrescriptionNhif/Edit/:examId/:documentId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), Number(route.params.documentId))
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhif.vue')
        }
    },
    {
        path: 'PrescriptionNhif/:examId',
        props: {
            exam: (route: Route) => ({
                examId: Number(route.params.examId),
                formType: PrescriptionFormTypeCode.Nhif5
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifView.vue')
        }
    },
    {
        path: 'PrescriptionNhifTypeA/Create/:examId',
        props: {
            exam: (route: Route) => ({ prescriptionKey: new PrescriptionKey(Number(route.params.examId), 0) })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifTypeA.vue')
        }
    },
    {
        path: 'PrescriptionNhifTypeA/Edit/:examId/:documentId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), Number(route.params.documentId))
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifTypeA.vue')
        }
    },
    {
        path: 'PrescriptionNhifTypeA/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhif5aView.vue')
        }
    },
    {
        path: 'PrescriptionNhifTypeB/Create/:examId',
        props: {
            exam: (route: Route) => ({ prescriptionKey: new PrescriptionKey(Number(route.params.examId), 0) })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifTypeB.vue')
        }
    },
    {
        path: 'PrescriptionNhifTypeB/Edit/:examId/:documentId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), Number(route.params.documentId))
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifTypeB.vue')
        }
    },
    {
        path: 'PrescriptionNhifTypeB/:examId',
        props: {
            exam: (route: Route) => ({
                examId: Number(route.params.examId),
                formType: PrescriptionFormTypeCode.Nhif5b
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifView.vue')
        }
    },
    {
        path: 'PrescriptionNhifTypeC/Create/:examId',
        props: {
            exam: (route: Route) => ({ prescriptionKey: new PrescriptionKey(Number(route.params.examId), 0) })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifTypeC.vue')
        }
    },
    {
        path: 'PrescriptionNhifTypeC/Edit/:examId/:documentId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), Number(route.params.documentId))
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifTypeC.vue')
        }
    },
    {
        path: 'PrescriptionNhifTypeC/:examId',
        props: {
            exam: (route: Route) => ({
                examId: Number(route.params.examId),
                formType: PrescriptionFormTypeCode.Nhif5c
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifView.vue')
        }
    },
    {
        path: 'PrescriptionNhifWhite/Create/:examId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), 0),
                formType: PrescriptionFormTypeCode.White
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifWhite.vue')
        }
    },
    {
        path: 'PrescriptionNhifWhite/Edit/:examId/:documentId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), Number(route.params.documentId)),
                formType: PrescriptionFormTypeCode.White
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifWhite.vue')
        }
    },
    {
        path: 'PrescriptionNhifWhite/:examId',
        props: {
            exam: (route: Route) => ({
                examId: Number(route.params.examId),
                formType: PrescriptionFormTypeCode.White
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifWhiteView.vue')
        }
    },
    {
        path: 'PrescriptionNhifGreen/Create/:examId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), 0),
                formType: PrescriptionFormTypeCode.Green
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifWhite.vue')
        }
    },
    {
        path: 'PrescriptionNhifGreen/Edit/:examId/:documentId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), Number(route.params.documentId)),
                formType: PrescriptionFormTypeCode.Green
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifWhite.vue')
        }
    },
    {
        path: 'PrescriptionNhifGreen/:examId',
        props: {
            exam: (route: Route) => ({
                examId: Number(route.params.examId),
                formType: PrescriptionFormTypeCode.Green
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifWhiteView.vue')
        }
    },
    {
        path: 'PrescriptionNhifYellow/Create/:examId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), 0),
                formType: PrescriptionFormTypeCode.Yellow
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifWhite.vue')
        }
    },
    {
        path: 'PrescriptionNhifYellow/Edit/:examId/:documentId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), Number(route.params.documentId)),
                formType: PrescriptionFormTypeCode.Yellow
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifWhite.vue')
        }
    },
    {
        path: 'PrescriptionNhifYellow/:examId',
        props: {
            exam: (route: Route) => ({
                examId: Number(route.params.examId),
                formType: PrescriptionFormTypeCode.Yellow
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifWhiteView.vue')
        }
    }
];
