import { Type } from 'class-transformer';

import { NhifProtocolDocumentDto } from '@/model/Exam/DrugProtocol/NhifProtocolDocumentDto';
import { NhifProtocolDrugDto } from '@/model/Exam/DrugProtocol/NhifProtocolDrugDto';
import { NhifProtocolInfoDto } from '@/model/Exam/DrugProtocol/NhifProtocolInfoDto';
import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';

export class NhifProtocolDto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();
    public number: number = 0;

    @Type(() => ExamDiagnosisDto)
    public examDiagnosis: ExamDiagnosisDto | null = null;

    public validityPeriod: number = 0;
    public previousProtocol: string | null = null;
    public decisionNumber: string | null = null;
    public groupCode: string = 'IA';
    public nhifOfficeId: number | null = null;
    public requirementId: number | null = null;
    public communicationCode: string | null = null;

    @Type(() => NhifProtocolInfoDto)
    public nhifProtocolInfo: NhifProtocolInfoDto = new NhifProtocolInfoDto();

    @Type(() => NhifProtocolDrugDto)
    public drugs: NhifProtocolDrugDto[] = [];

    @Type(() => NhifProtocolDocumentDto)
    public documents: NhifProtocolDocumentDto[] = [];
}
