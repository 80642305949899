import moment from 'moment';

import { GenderCode } from '@/enum/Nomenclature/GenderCode';
import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';

// Следните проверки на ЕГН и ЛНЧ са пълни с аритметика, използват над 40 magic numbers
// и биха били нечетими ако всички тези числа се изнесат в именовани константи.
/* eslint-disable @typescript-eslint/no-magic-numbers */
const parseEgnBirthDate = (egn: string) => {
    if (egn && egn.length >= 6) {
        let month = Number(egn.substr(2, 2));
        let century = null;
        if (month >= 41 && month <= 52) {
            century = 2000;
            month -= 40;
        } else if (month >= 21 && month <= 32) {
            century = 1800;
            month -= 20;
        } else if (month >= 1 && month <= 12) {
            century = 1900;
        }

        if (century) {
            const year = Number(egn.substr(0, 2)) + century;
            const monthText = month.toString().padStart(2, '0');
            const dayText = egn.substr(4, 2);
            const defaultBirthHour = 12;
            const momentDate = moment(`${year}${monthText}${dayText}${defaultBirthHour}`, 'YYYYMMDDHH', true);
            if (momentDate.isValid()) {
                return momentDate.toDate();
            }
        }
    }
    return null;
};

const parseEgnGender = (egn: string) => {
    // Полът се определя от четността на предпоследната цифра в ЕГН-то.
    if (egn && egn.length > 8) {
        const genderDigit = Number(egn[8]);
        if (!isNaN(genderDigit)) {
            return genderDigit % 2 === 0 ? GenderCode.Male : GenderCode.Female;
        }
    }
    return null;
};

const isEgnValid = (value: string): boolean => {
    if (!value || value.length !== 10 || !parseEgnBirthDate(value)) {
        return false;
    }

    const checkSum = Number(value.substr(9, 1));
    let egnSum = 0;

    const egnWeights = [2, 4, 8, 5, 10, 9, 7, 3, 6];
    for (let index = 0; index < 9; index++) {
        egnSum += Number(value.substr(index, 1)) * egnWeights[index];
    }
    let validChecksum = egnSum % 11;
    if (validChecksum === 10) {
        validChecksum = 0;
    }

    return checkSum === validChecksum;
};

const isLnchValid = (value: string): boolean => {
    if (!value || value.length !== 10) {
        return false;
    }

    const checkSum = Number(value.substr(9, 1));
    let lnchSum = 0;

    const lnchWeights = [21, 19, 17, 13, 11, 9, 7, 3, 1];
    for (let index = 0; index < 9; index++) {
        lnchSum += Number(value.substr(index, 1)) * lnchWeights[index];
    }
    let validChecksum = lnchSum % 10;
    if (validChecksum === 10) {
        validChecksum = 0;
    }

    return checkSum === validChecksum;
};

export const identifierUtil = {
    isEgnValid,

    isLnchValid,

    isBabyValid(value: string): boolean {
        const babyPattern = /^\d{6}0{4}$/u;
        return babyPattern.test(value) && Boolean(parseEgnBirthDate(value));
    },

    parseBirthDate(pidTypeCode: PidTypeCode | null, identifier: string): Date | null {
        return pidTypeCode === PidTypeCode.Egn || pidTypeCode === PidTypeCode.Baby
            ? parseEgnBirthDate(identifier)
            : null;
    },

    parseGender(pidTypeCode: PidTypeCode | null, identifier: string): GenderCode | null {
        return pidTypeCode === PidTypeCode.Egn ? parseEgnGender(identifier) : null;
    }
};
/* eslint-enable @typescript-eslint/no-magic-numbers */
