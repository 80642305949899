import { Type } from 'class-transformer';

import { Annex56FactorForHealthStatusChildrenRowDto } from '@/model/Report/Rhi/Annex56/Annex56FactorForHealthStatusChildrenRowDto';

export class Annex56FactorForHealthStatusChildrenDto {
    public name: string = '';

    @Type(() => Annex56FactorForHealthStatusChildrenRowDto)
    public factorForHealthStatusesChildren: Annex56FactorForHealthStatusChildrenRowDto[] = [];
}
