<template>
    <v-card>
        <v-card-title>Бързо добавяне на изследвания</v-card-title>
        <v-card-text>
            <v-row class="ma-3">
                <dropdown
                    v-model="selectedTariff"
                    :items="tariffs"
                    label="Тарифа"
                    return-object
                    @input="onTariffChange"
                ></dropdown>
                <dropdown
                    v-if="selectedTariff && selectedTariff.defaultFundCode == `Nhif`"
                    v-model="selectedPackage"
                    class="ml-3"
                    label="Пакети"
                    return-object
                    :items="fundServicePackages"
                    @input="onPackageChange"
                ></dropdown>
            </v-row>
            <v-row v-if="selectedTariff" class="ma-3">
                <v-col>
                    <v-card-title>Изследвания</v-card-title>
                    <v-data-table
                        v-model="quickAddLabTestDto.selectedLabTests"
                        :headers="headers"
                        :items="selectedTariffLabTests"
                        show-select
                        item-key="seqNumber"
                        :search="search"
                        :footer-props="{ itemsPerPageText: 'Редове на страница', itemsPerPageAllText: 'Всички' }"
                    >
                        <template #top>
                            <v-row>
                                <v-col cols="9"><text-field v-model="search" label="Търсене"></text-field></v-col>
                                <v-col cols="3"
                                    ><btn
                                        :disabled="
                                            quickAddLabTestDto.selectedLabTests.length === 0 ||
                                            quickAddLabTestDto.tariffSeqNumber === null
                                        "
                                        action="Next"
                                        @click="save"
                                        >Добавяне
                                    </btn>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col>
                    <v-card-title>Добавени изследвания</v-card-title>
                    <v-data-table
                        :headers="addedHeaders"
                        :items="filteredAddedLabTests"
                        :search="searchAdded"
                        :footer-props="{ itemsPerPageText: 'Редове на страница', itemsPerPageAllText: 'Всички' }"
                    >
                        <template #top>
                            <text-field v-model="searchAdded" label="Търсене"></text-field>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import Dropdown from '@/component/Dropdown/Dropdown.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { FundServiceDto } from '@/model/Nomenclature/FundServiceDto';
    import { LabTestCategoryDto } from '@/model/Nomenclature/LabTestCategoryDto';
    import { QuickAddLabTestDto } from '@/model/Practice/Service/QuickAddLabTestDto';
    import { ServiceOfferEditDto } from '@/model/Practice/Service/ServiceOfferEditDto';
    import { TariffDto } from '@/model/Practice/Service/TariffDto';
    import { fundServiceService } from '@/service/Nomenclature/FundServicesService';
    import { quickAddLabTestService } from '@/service/Practice/Service/QuickAddLabTestService';
    import { tariffService } from '@/service/Practice/Service/TariffService';

    @Component({ components: { Dropdown } })
    export default class QuickAddLabTest extends Vue {
        @Prop()
        private practiceId!: number;

        private quickAddLabTestDto: QuickAddLabTestDto = new QuickAddLabTestDto();
        private tariffs: TariffDto[] = [];
        private selectedTariff: TariffDto | null = null;
        private selectedTariffLabTests: FundServiceDto[] = [];
        private filteredAddedLabTests: ServiceOfferEditDto[] = [];
        private fundServicePackages: LabTestCategoryDto[] = [];
        private selectedPackage: LabTestCategoryDto | null = null;
        private search: string = '';
        private searchAdded: string = '';

        private headers: IDataTableHeader[] = [
            { text: 'Код', value: 'code' },
            { text: 'Изследване', value: 'name' },
            { text: 'Пакет', value: 'fundServicePackageName' }
        ];

        private addedHeaders: IDataTableHeader[] = [
            { text: 'Код', value: 'selectedFundService.code' },
            { text: 'Изследване', value: 'selectedFundService.name' },
            { text: 'Пакет', value: 'selectedFundService.fundServicePackageName' }
        ];

        private async mounted() {
            this.tariffs = await tariffService.getPracticeTariffs(this.practiceId);
            const response = await fundServiceService.getFundServicePackages();
            this.fundServicePackages = response.filter((fsp) => fsp.fundCode === 'Nhif');
            await this.getLabTests();
        }

        private async getLabTests() {
            this.quickAddLabTestDto = await quickAddLabTestService.getLabTests(this.practiceId);
        }

        private onTariffChange() {
            if (this.selectedTariff) {
                this.filteredAddedLabTests = this.quickAddLabTestDto.addedLabTests.filter(
                    (lab) => lab.tariffSeqNumber === this.selectedTariff?.seqNumber
                );
                this.selectedTariffLabTests = this.getSelectedTariffLabTests();
                this.quickAddLabTestDto.tariffSeqNumber = this.selectedTariff.seqNumber;
            } else {
                this.selectedTariffLabTests = [];
                this.filteredAddedLabTests = [];
                this.quickAddLabTestDto.tariffSeqNumber = null;
            }
        }

        private onPackageChange() {
            if (this.selectedPackage) {
                const filteredByPackageLabTests = this.getSelectedTariffLabTests().filter(
                    (lab) => lab.fundServicePackageCode === this.selectedPackage?.code
                );
                this.selectedTariffLabTests = filteredByPackageLabTests;
            } else {
                this.onTariffChange();
            }
        }

        private getSelectedTariffLabTests() {
            const addedFundServices = this.filteredAddedLabTests.map((lab) => lab.selectedFundService);
            return this.quickAddLabTestDto.labTests.filter(
                (lab) =>
                    lab.fundCode === this.selectedTariff?.defaultFundCode &&
                    // eslint-disable-next-line max-nested-callbacks
                    !addedFundServices.some((fs) => fs?.coreServiceCode === lab.coreServiceCode)
            );
        }

        private async save() {
            this.$loading.show();
            const result = await quickAddLabTestService.save(this.quickAddLabTestDto);
            if (!result) {
                this.$notifier.showSuccess('Успех', 'Данните са записани успешно');
                await this.getLabTests();
                this.onTariffChange();
            } else {
                this.$notifier.showError('Грешка', result);
            }
            this.$loading.hide();
        }
    }
</script>
