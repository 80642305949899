<template>
    <div>
        <v-row>
            <v-col v-for="(adv, index) in currentAds" :key="index" cols="6">
                <img v-if="adv.fileContent" :src="imgSrc(adv.fileContent)" />
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { AdvertisementDto } from '@/model/Advertisement/AdvertisementDto';

    @Component({})
    export default class PrescriptionAdvertisement extends Vue {
        @Prop()
        advertisements!: AdvertisementDto[];

        private currentAds: AdvertisementDto[] = [];
        private imgSrc(fileContent: string) {
            return `data:image/png;base64,${fileContent}`;
        }

        @Watch('advertisements', { immediate: true })
        onAdsChange(newAds: AdvertisementDto[], oldAds: AdvertisementDto[]) {
            const maxAdvCount = 2;
            //При смяна на рекламите по този начин освобождаваме старите и ги слагаме като неизползвани
            if (this.currentAds?.length > 0) {
                const currentAds = oldAds.filter((advertisement) => this.currentAds.includes(advertisement));
                if (currentAds) {
                    for (const element of currentAds) {
                        element.isUsed = false;
                    }
                }
            }
            if (newAds.length > 0) {
                this.currentAds = this.advertisements
                    .filter((advertisement) => advertisement.isUsed === false)
                    .slice(0, maxAdvCount);
                for (const currentAd of this.currentAds) {
                    currentAd.isUsed = true;
                }
            } else {
                this.currentAds = [];
            }
        }
    }
</script>

<style scoped></style>
