<template>
    <div>
        <v-dialog v-model="extractionDialogIsVisible" persistent max-width="75%">
            <v-form ref="form" v-model="isFormValid">
                <v-card>
                    <v-card-title>Извличане на медицинска бележка</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6" md="3">
                                <date-picker v-model="model.fromDate" label="Oт дата:" />
                            </v-col>
                            <v-col cols="6" md="3">
                                <date-picker v-model="model.toDate" label="До дата:" />
                            </v-col>
                            <v-col cols="6" md="3"> </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <btn action="Cancel" text @click="closeExtractionDialog" />
                        <btn action="Download" text @click="extract">Извличане</btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';
    import { NhisMedicalNoteExtractionCommand } from '@/model/Nhis/MedicalNote/NhisMedicalNoteExtractionCommand';
    import { nhisMedicalNoteService } from '@/service/Nhis/NhisMedicalNoteService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';

    @Component
    export default class NhisMedicalNoteExtraction extends Vue {
        @Prop()
        extractionDialogIsVisible!: boolean;

        @Prop()
        examId!: number;

        private isFormValid: boolean = false;
        private model: NhisMedicalNoteExtractionCommand = new NhisMedicalNoteExtractionCommand();

        private closeExtractionDialog() {
            this.$emit('close');
            this.model = new NhisMedicalNoteExtractionCommand();
        }

        private async extract() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isFormValid) {
                const path = await nhisMedicalNoteService.extractMedicalNote(this.getRequestData());

                if (path) {
                    this.$router.push(path);
                }
                this.closeExtractionDialog();
            }
        }

        private getRequestData() {
            this.model.doctorUin = userContextCache.current?.doctorUin ?? '';
            this.model.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
            this.model.identifier = currentPatientCache?.value?.personIdentification?.identifier;
            this.model.pidTypeCode = currentPatientCache?.value?.personIdentification?.pidTypeCode ?? PidTypeCode.Egn;
            this.model.examId = this.examId;
            return this.model;
        }

        @Watch('extractionDialogIsVisible')
        onDialogVisibilityChange(isVisible: boolean) {
            if (isVisible) {
                this.fillPeriod();
            }
        }

        private fillPeriod() {
            this.model.fromDate = dateUtil.startOfThisYear();
            this.model.toDate = dateUtil.endOfThisYear();
        }
    }
</script>

<style scoped></style>
