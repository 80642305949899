import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';

export class VisitSearchCommand extends ReportIntervalFilterDto {
    public identifier: string = '';
    public ambulatorySheetNumber: string = '';
    public icdCode: string = '';
    public referralNumber: number | null = null;
    public nhifVisitReasonCode: string = '';
    public hasIncompletedRiskAssessmentCard: boolean = false;
}
