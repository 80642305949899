import { plainToInstance } from 'class-transformer';

import { CurrentRegulatoryStandardDoctorDto } from '@/model/Practice/RegulatoryStandard/CurrentRegulatoryStandardDoctorDto';
import { RegulatoryStandardDoctorDto } from '@/model/Practice/RegulatoryStandard/RegulatoryStandardDoctorDto';
import { RegulatoryStandardDto } from '@/model/Practice/RegulatoryStandard/RegulatoryStandardDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const regulatoryStandardService = {
    async getRegStandardsForPractice(practiceId: number): Promise<RegulatoryStandardDto[]> {
        const response = await httpService.get<RegulatoryStandardDto[]>(
            `/RegulatoryStandard/GetRegStandardsForPractice/${practiceId}`
        );
        return plainToInstance(RegulatoryStandardDto, response.data);
    },

    async initRegStandardForPractice(practiceId: number): Promise<RegulatoryStandardDto> {
        const response = await httpService.get<RegulatoryStandardDto>(
            '/RegulatoryStandard/InitRegStandardForPractice',
            { params: { practiceId } }
        );
        return plainToInstance(RegulatoryStandardDto, response.data);
    },

    async getRegStandard(practiceId: number, seqNumber: number): Promise<RegulatoryStandardDto> {
        const response = await httpService.get<RegulatoryStandardDto>('/RegulatoryStandard/GetRegStandard', {
            params: { practiceId, seqNumber }
        });
        return plainToInstance(RegulatoryStandardDto, response.data);
    },

    async getRegStandardForDoctor(
        practiceId: number,
        seqNumber: number,
        doctorEmployeeSeqNumber: number
    ): Promise<RegulatoryStandardDoctorDto | null> {
        const response = await httpService.get<RegulatoryStandardDoctorDto>(
            '/RegulatoryStandard/GetRegStandardForDoctor',
            {
                params: { practiceId, seqNumber, doctorEmployeeSeqNumber }
            }
        );
        return response.data ? plainToInstance(RegulatoryStandardDoctorDto, response.data) : null;
    },

    async getCurrentRegStandardForDoctor(
        practiceId: number,
        doctorEmployeeSeqNumber: number
    ): Promise<CurrentRegulatoryStandardDoctorDto | null> {
        const response = await httpService.get<CurrentRegulatoryStandardDoctorDto>(
            '/RegulatoryStandard/GetCurrentRegStandardForDoctor',
            {
                params: { practiceId, doctorEmployeeSeqNumber }
            }
        );
        return response.data ? plainToInstance(CurrentRegulatoryStandardDoctorDto, response.data) : null;
    },

    async getUsedRegStandardByDoctor(
        practiceId: number,
        doctorEmployeeSeqNumber: number,
        startDate: Date,
        endDate: Date
    ): Promise<RegulatoryStandardDoctorDto> {
        const response = await httpService.get<RegulatoryStandardDoctorDto>(
            `/RegulatoryStandard/GetUsedRegStandardByDoctor/`,
            {
                params: { practiceId, doctorEmployeeSeqNumber, startDate, endDate }
            }
        );
        return plainToInstance(RegulatoryStandardDoctorDto, response.data);
    },

    async createOrUpdateRegStandard(data: RegulatoryStandardDto): Promise<void> {
        await httpService.post('/RegulatoryStandard/CreateOrUpdateRegStandard', data);
    },

    async deleteRegStandard(practiceId: number, seqNumber: number): Promise<void> {
        await httpService.delete('/RegulatoryStandard/DeleteRegStandard', { params: { practiceId, seqNumber } });
    }
};
