<template>
    <v-card>
        <v-card-title class="mx-2 my-3">
            Е-Направления
            <v-spacer />
            <div v-if="showClosePointer" class="red--text pointer" @click="close">x</div>
        </v-card-title>
        <data-table :items="items" :headers="tableHeaders" disable-pagination>
            <template v-if="!isReception" #rowActions="{ item }">
                <btn icon action="Fill" :disabled="isLoading" @click.once="fillIncomingReferral(item)" />
            </template>
        </data-table>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhisExtractedReferralDto } from '@/model/Nhis/Referral/NhisExtractedReferralDto';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class NhisReferralExtractionResult extends Vue {
        @Prop()
        items!: NhisExtractedReferralDto[];

        @Prop()
        private showClosePointer!: boolean;

        @Prop({ default: false })
        private isReception!: boolean;

        @Prop({ default: false })
        private isLoading!: boolean;

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'НРН:', value: 'incomingReferral.nrn' },
                { text: 'Пациент:', value: 'incomingReferral.patientName' },
                { text: 'Тип идентификатор:', value: 'incomingReferral.pidTypeName' },
                { text: 'Идентификатор:', value: 'incomingReferral.patientIdentifier' },
                {
                    text: 'Вид направление:',
                    value: 'incomingReferral.referralType'
                },
                {
                    text: 'Тип направление:',
                    value: 'incomingReferral.referralSubType'
                },
                {
                    text: 'Дата на издаване:',
                    value: 'incomingReferral.issueDate',
                    formatter: formatters.date
                },
                {
                    text: 'Насочен към:',
                    value: 'specialties[0].name'
                },
                {
                    text: 'Водеща диагноза:',
                    value: 'diagnoses[0].icdCode.code'
                },
                {
                    text: 'Уин на изпращащ лекар:',
                    value: 'incomingReferral.uin'
                },
                { text: 'Практика номер на изпращащ', value: 'incomingReferral.practiceNumber' },
                { text: '', value: 'actionButtons' }
            ];
        }

        private close() {
            this.$emit('close-table');
        }

        private fillIncomingReferral(item: NhisExtractedReferralDto) {
            this.$emit('input', item);
        }
    }
</script>
