import { AxiosResponse } from 'axios';

import { ResultReferralsDto } from '@/model/Exam/Referral/ResultReferral/ResultReferralsDto';
import { ResultReferralSubmitCommand } from '@/model/Exam/Referral/ResultReferral/ResultReferralSubmitCommand';
import { ResultReferralXmlDto } from '@/model/Exam/Referral/ResultReferral/ResultReferralXmlDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referralResultsService = {
    getAllResultReferrals(examId: number): Promise<AxiosResponse<ResultReferralsDto[]>> {
        return httpService.get('/ReferralResults/GetAllResultReferrals', { params: { examId } });
    },
    getReferralsXmls(examId: number): Promise<AxiosResponse<ResultReferralXmlDto[]>> {
        return httpService.get('/ReferralResults/GetReferralsXmls', { params: { examId } });
    },
    processReferralsXmls(command: ResultReferralSubmitCommand): Promise<AxiosResponse<string>> {
        return httpService.post('/ReferralResults/ProcessReferralsXmls', command);
    }
};
