import { Type } from 'class-transformer';

import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';

export class PatientPrescriptionDrugDto {
    @Type(() => PrescriptionKey)
    public prescriptionKey: PrescriptionKey = new PrescriptionKey(0, 0);

    public prescriptionDrugId: number = 0;
    public issueDate: Date | null = null;
    public nextPrescriptionDate: Date | null = null;
    public segment: string = '';
    public drug: string = '';
    public packageCount: number = 0;
    public dosage: string = '';
    public days: number = 0;
    public formType: string = '';
    public formTypeCode: string = '';
    public icdCode: string = '';
    public nrn: string = '';
    public nrnProtocol: string = '';
    public protocolStartDate: Date | null = null;
    public protocolEndDate: Date | null = null;
    public isFullPayed: boolean = false;
    public drugProtocolId: number | null = null;

    public get isFullPayedText() {
        return this.isFullPayed ? 'Да' : 'Не';
    }
}
