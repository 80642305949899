<template>
    <v-list>
        <menu-item
            v-for="(item, index) in menuItems"
            :key="index"
            :item="item"
            @click="mainMenuItemClicked(item)"
        ></menu-item>
    </v-list>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import MenuItem from '@/component/Menu/MenuItem.vue';
    import { createMainMenuItems } from '@/model/Menu/MainMenuItems';
    import { MenuItemModel } from '@/model/Menu/MenuItemModel';
    import { menuState } from '@/store/MenuState';

    @Component({ components: { MenuItem } })
    export default class MainMenu extends Vue {
        private get menuItems() {
            return createMainMenuItems();
        }

        private mainMenuItemClicked(item: MenuItemModel) {
            menuState.secondaryMenuAppBarIcon = item.icon;
        }
    }
</script>
