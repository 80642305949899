import { plainToInstance } from 'class-transformer';

import { DoctorEmployeeDropdownDto } from '@/model/Employee/Deputy/DoctorEmployeeDropdownDto';
import { EmployeeCreateCommand } from '@/model/Employee/EmployeeCreateCommand';
import { EmployeeCreateResultDto } from '@/model/Employee/EmployeeCreateResultDto';
import { EmployeeEditDto } from '@/model/Employee/EmployeeEditDto';
import { EmployeeRowDto } from '@/model/Employee/EmployeeRowDto';
import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const employeeService = {
    async getEmployeeGrid(practiceId: number): Promise<EmployeeRowDto[]> {
        const response = await httpService.get<EmployeeRowDto[]>('/Employee/GetEmployeeGrid', {
            params: { practiceId }
        });
        return plainToInstance(EmployeeRowDto, response.data);
    },

    async getEmployeeTitles(practiceId: number): Promise<EmployeeTitleDto[]> {
        const response = await httpService.get<EmployeeTitleDto[]>('/Employee/GetEmployeeTitles', {
            params: { practiceId }
        });
        return plainToInstance(EmployeeTitleDto, response.data);
    },

    async getDoctorEmployees(practiceId: number): Promise<EmployeeTitleDto[]> {
        const response = await httpService.get<EmployeeTitleDto[]>('/Employee/GetDoctorEmployees', {
            params: { practiceId }
        });
        return plainToInstance(EmployeeTitleDto, response.data);
    },

    async getPublicActiveDoctorEmployees(practiceId: number): Promise<DoctorEmployeeDropdownDto[]> {
        const response = await httpService.get<DoctorEmployeeDropdownDto[]>(
            '/Employee/GetPublicActiveDoctorEmployees',
            { params: { practiceId } }
        );
        return plainToInstance(DoctorEmployeeDropdownDto, response.data);
    },

    async getEmployeeTitle(practiceId: number, employeeSeqNumber: number): Promise<EmployeeEditDto> {
        const response = await httpService.get<EmployeeEditDto>('/Employee/GetEmployeeTitle', {
            params: { practiceId, employeeSeqNumber }
        });
        return plainToInstance(EmployeeEditDto, response.data);
    },

    async getEmployeeForEdit(practiceId: number, employeeSeqNumber: number): Promise<EmployeeEditDto> {
        const response = await httpService.get<EmployeeEditDto>('/Employee/GetEmployeeForEdit', {
            params: { practiceId, employeeSeqNumber }
        });
        return plainToInstance(EmployeeEditDto, response.data);
    },

    async createEmployee(command: EmployeeCreateCommand): Promise<EmployeeCreateResultDto> {
        const response = await httpService.post<EmployeeCreateResultDto>('/Employee/CreateEmployee', command);
        return plainToInstance(EmployeeCreateResultDto, response.data);
    },

    async updateEmployee(employee: EmployeeEditDto): Promise<void> {
        await httpService.put('/Employee/UpdateEmployee', employee);
    },

    async deleteEmployee(practiceId: number, employeeSeqNumber: number): Promise<void> {
        await httpService.delete('/Employee/DeleteEmployee', {
            params: { practiceId, employeeSeqNumber }
        });
    }
};
