import { CertificateDriveConsultationDto } from '@/model/Exam/Certificate/CertificateDriveConsultationDto';
import { NhisCertificateDriveDto } from '@/model/Nhis/CertificateDrive/NhisCertificateDriveDto';

export class CertificateDriveNhisDto extends NhisCertificateDriveDto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();
    public licenseCategory: string | null = null;
    public symptoms: string | null = null;
    public isTolek: boolean = false;
    public hasTelk: boolean = false;
    public isCompliant: boolean = false;
    public icdCode: string | null = null;
    public icdCodeAdd: string | null = null;
    public motive: string = '';
    public consultations: CertificateDriveConsultationDto[] = [];
    public nhisCertificateDrive: NhisCertificateDriveDto = new NhisCertificateDriveDto();
}
