import { AxiosResponse } from 'axios';

import { CanceledExamDto } from '@/model/Query/Exam/CanceledExamDto';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const canceledExamService = {
    getCanceledExams(filter: ReportIntervalFilterDto): Promise<AxiosResponse<CanceledExamDto[]>> {
        return httpService.post('CanceledExam/GetCanceledExams', filter);
    },

    exportCanceledExams(filter: ReportIntervalFilterDto): Promise<void> {
        return httpService.download('/CanceledExam/ExportExcel', { params: filter });
    }
};
