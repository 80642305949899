import { AxiosResponse } from 'axios';

import { FiscalMemoryDto } from '@/model/Practice/Fiscal/FiscalMemoryDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const fiscalMemoryService = {
    getActiveFiscalMemories(
        practiceId: number,
        date: Date,
        employeeSeqNumber: number
    ): Promise<AxiosResponse<FiscalMemoryDto[]>> {
        return httpService.get('FiscalMemory/GetActiveFiscalMemories', {
            params: { practiceId, date, employeeSeqNumber }
        });
    },

    getDeviceFiscalMemories(
        practiceId: number,
        fiscalDeviceSeqNumber: number
    ): Promise<AxiosResponse<FiscalMemoryDto[]>> {
        return httpService.get('FiscalMemory/GetDeviceFiscalMemories', {
            params: { practiceId, fiscalDeviceSeqNumber }
        });
    },

    getFiscalMemory(
        practiceId: number,
        fiscalDeviceSeqNumber: number,
        fiscalMemoryCode: string
    ): Promise<AxiosResponse<FiscalMemoryDto>> {
        return httpService.get('FiscalMemory/GetFiscalMemory', {
            params: { practiceId, fiscalDeviceSeqNumber, fiscalMemoryCode }
        });
    },

    createFiscalMemory(data: FiscalMemoryDto): Promise<AxiosResponse<FiscalMemoryDto>> {
        return httpService.post('FiscalMemory/CreateFiscalMemory', data);
    },

    updateFiscalMemory(data: FiscalMemoryDto): Promise<AxiosResponse<FiscalMemoryDto>> {
        return httpService.post('FiscalMemory/UpdateFiscalMemory', data);
    },

    async deleteFiscalMemory(
        practiceId: number,
        fiscalDeviceSeqNumber: number,
        fiscalMemoryCode: string
    ): Promise<void> {
        await httpService.delete('FiscalMemory/DeleteFiscalMemory', {
            params: { practiceId, fiscalDeviceSeqNumber, fiscalMemoryCode }
        });
    }
};
