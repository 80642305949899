export class RhiImmunizationReportDetailDto {
    public id: number = 0;
    public rhiImmunizationReportId: number = 0;
    public immunizationId: number | null = null;
    public liableCount: number | null = null;
    public coveredCount: number | null = null;
    public notCoveredMedical: number | null = null;
    public notCoveredRefusal: number | null = null;
    public notCoveredOther: number | null = null;
    public immunizationCode: string = '';
    public immunizationName: string = '';
}
