import { Type } from 'class-transformer';

import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { PatientVersionDto } from '@/model/Patient/PatientVersionDto';
import { ServiceOrderCreateDto } from '@/model/Reception/Service/ServiceOrderCreateDto';

export class ReceptionServiceCreateCommand {
    createdByEmployeeSeqNumber: number | null = null;
    doctorId: number | null = null;
    specialtyCode: SpecialtyCode | null = null;
    creditToRoomSeqNumber: number | null = null;

    @Type(() => PatientVersionDto)
    patient: PatientVersionDto = new PatientVersionDto();

    @Type(() => ServiceOrderCreateDto)
    serviceOrders: ServiceOrderCreateDto[] = [];

    ordersToDelete: number[] = [];

    constructor() {
        this.serviceOrders.push(new ServiceOrderCreateDto());
    }
}
