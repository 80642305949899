import { StagedEpicrisisExamDto } from './StagedEpicrisisExamDto';
import { StagedEpicrisisExamFilterTypeCode } from './StagedEpicrisisExamFilterType';

export class StagedEpicrisisExamSearchModel {
    public examFilterTypeCode: StagedEpicrisisExamFilterTypeCode | null = null;
    public fromDate: Date | null = null;
    public toDate: Date | null = null;

    public patientExams: StagedEpicrisisExamDto[] = [];
    public selectedExams: StagedEpicrisisExamDto[] = [];
}
