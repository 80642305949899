<template>
    <v-row class="align-baseline">
        <v-col cols="12" md="3">
            <text-field
                v-model="value.company"
                label="Фирма"
                :disabled="disabled"
                required
                :rules="[$validator.maxLength(100)]"
        /></v-col>
        <v-col cols="12" md="3">
            <text-field
                v-model="value.eik"
                label="ЕИК"
                :disabled="disabled"
                :rules="[$validator.digitsOnly, $validator.minLength(9), $validator.maxLength(13)]"
        /></v-col>
        <v-col cols="12" md="3">
            <text-field
                v-model="value.profession"
                label="Професия"
                :disabled="disabled"
                required
                :rules="[$validator.maxLength(50)]"
        /></v-col>
        <v-col cols="12" md="3">
            <text-field
                v-model="value.position"
                label="Длъжност"
                :disabled="disabled"
                required
                :rules="[$validator.maxLength(50)]"
            />
        </v-col>
        <v-col cols="12" md="3">
            <CityPicker
                v-model="value.cityId"
                :required="!value.foreignCity"
                label="гр."
                :disabled="disabled"
                @input="onCityIdChange"
            />
        </v-col>
        <v-col cols="12" md="3">
            <text-field
                v-model="value.foreignCity"
                label="гр. извън България"
                :required="!value.cityId"
                :disabled="disabled"
            ></text-field>
        </v-col>
        <v-col cols="12" md="3">
            <text-field v-model="value.street" label="Улица" :disabled="disabled" :rules="[$validator.maxLength(50)]" />
        </v-col>
        <v-col cols="12" md="3">
            <text-field
                v-model="value.streetNumber"
                label="Улица номер"
                :disabled="disabled"
                :rules="[$validator.maxLength(20)]"
        /></v-col>
        <v-col cols="12" md="3">
            <text-field
                v-model="value.neighborhood"
                label="Квартал"
                :disabled="disabled"
                :rules="[$validator.maxLength(30)]"
        /></v-col>
        <v-col cols="12" md="3">
            <text-field
                v-model="value.postalCode"
                label="Пощенски код"
                :disabled="disabled"
                :rules="[$validator.postalCode]"
        /></v-col>
        <v-col cols="12" md="3">
            <v-checkbox v-model="value.isDeleted" label="Неактивна" :disabled="disabled" @click="checkValidation"
        /></v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import CityPicker from '@/component/Picker/CityPicker.vue';
    import { PatientWorkplaceDto } from '@/model/Patient/PatientWorkplaceDto';
    import { getPostCode } from '@/service/Nomenclature/CityService';

    @Component({ components: { CityPicker } })
    export default class PatientWorkplace extends Vue {
        @Prop({ type: Object })
        private value!: PatientWorkplaceDto;

        @Prop()
        private disabled!: boolean;

        private checkValidation() {
            this.$emit('check-validation');
        }

        private async onCityIdChange(value: number | null) {
            if (value) {
                const response = await getPostCode.getPostCodeByCityId(value);
                //Правя toString защото валидатора не го приема за string и не сработва коректно
                this.value.postalCode = response.toString();
            } else {
                this.value.postalCode = '';
            }
        }
    }
</script>
