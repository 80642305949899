import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { MenuItemModel } from '@/model/Menu/MenuItemModel';
import { userContextCache } from '@/store/User/UserContextCache';

const addNhisQueryItems = (menuItems: MenuItemModel[], practiceId: number, doctorEmployeeSeqNumber: number) => {
    menuItems.push(
        new MenuItemModel(
            'Неизпратени НЗИС документи',
            'mdi-book-outline',
            `/Query/UnpostedForms/${practiceId}/${doctorEmployeeSeqNumber}`
        )
    );
};

const addImportantExamQueryItems = (
    menuItems: MenuItemModel[],
    practiceId: number,
    doctorEmployeeSeqNumber: number
) => {
    menuItems.push(
        new MenuItemModel('Групово изпращане НЗИС', 'mdi-note-multiple-outline', `/Query/NhisGroupUpload`),
        new MenuItemModel(
            'Неотпечатани АЛ',
            'mdi-printer-off-outline',
            `/Query/ExamNotPrinted/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Неподписани АЛ',
            'mdi-signature-freehand',
            `/Query/ExamNotSigned/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Анулирани АЛ',
            'mdi-playlist-remove',
            `/Query/CanceledExams/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'АЛ на неосигурени',
            'mdi-account-cancel',
            `/Query/UninsuredPatientExams/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'АЛ на пенсионери без касова бележка',
            'mdi-book-off-outline',
            `/Query/ExamsWithoutFiscalReceipt/${practiceId}/${doctorEmployeeSeqNumber}`
        )
    );
};

const addItemIfAdmin = (menuItems: MenuItemModel[], practiceId: number, doctorEmployeeSeqNumber: number) => {
    menuItems.push(
        new MenuItemModel(
            'Опис на месечен отчет по лекари',
            'mdi-book-outline',
            `/Query/ExamClaimReportExtended/${practiceId}/${doctorEmployeeSeqNumber}`
        )
    );
};

const addImmunizationQueryItems = (menuItems: MenuItemModel[], practiceId: number, doctorEmployeeSeqNumber: number) => {
    menuItems.push(
        new MenuItemModel(
            'Извършени имунизации',
            'mdi-bottle-tonic-outline',
            `/Query/ImmunizationReport/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Подлежащи на имунизация',
            'mdi-needle',
            `/Query/SubjectToImmunization/${practiceId}/${doctorEmployeeSeqNumber}`
        )
    );
};

const addRegisterQueryItems = (menuItems: MenuItemModel[], practiceId: number, doctorEmployeeSeqNumber: number) => {
    menuItems.push(
        new MenuItemModel(
            'Подлежащи на диспансерен преглед',
            'mdi-stethoscope',
            `/Query/SubjectToDispensaryExam/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Подлежащи на профилактика',
            'mdi-stethoscope',
            `/Query/SubjectToPrevention/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Подлежащи на профилактичен преглед',
            'mdi-stethoscope',
            `/Query/SubjectToPreventiveExam/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Профилактични прегледи без вкл. в регистър',
            'mdi-format-list-checks',
            `/Query/PreventiveExamNotRegisterIncluded/${practiceId}/${doctorEmployeeSeqNumber}`
        )
    );
};

const addServiceOrderReportItems = (menuItems: MenuItemModel[], practiceId: number) => {
    menuItems.push(
        new MenuItemModel(
            'Финансов отчет-детайлен',
            'mdi-cash-check',
            `/Query/ServiceOrderDetailedReport/${practiceId}`
        ),
        new MenuItemModel(
            'Финансов отчет-обобщен',
            'mdi-cash-check',
            `/Query/ServiceOrderSummarizedReport/${practiceId}`
        )
    );
};

const addMoreReportItems = (menuItems: MenuItemModel[], practiceId: number, doctorEmployeeSeqNumber: number) => {
    menuItems.push(
        new MenuItemModel(
            'Минути между прегледи',
            'mdi-stethoscope',
            `/Query/TimeBetweenExams/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'БЛ за период',
            'mdi-clipboard-list-outline',
            `/Query/SickSheetUpload/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Проверка осиг. статус',
            'mdi-account-search',
            `/Query/InsuranceStatus/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Проверка пенсионен статус',
            'mdi-account-search',
            `/Query/PensionStatus/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Суми за изследвания',
            'mdi-cash-check',
            `/Query/Referral4AmountsReport/${practiceId}/${doctorEmployeeSeqNumber}`
        )
    );
};

const addOtherReportItems = (
    menuItems: MenuItemModel[],
    practiceId: number,
    doctorEmployeeSeqNumber: number,
    isGp: boolean
) => {
    if (!isGp) {
        menuItems.push(
            new MenuItemModel(
                'Изтеглени входящи направления',
                'mdi-book-account-outline',
                `/Query/IncomingReferralExamReport/${practiceId}/${doctorEmployeeSeqNumber}`
            )
        );
    }
    if (isGp) {
        menuItems.push(
            new MenuItemModel(
                'Капитация на пациенти - разширен',
                'mdi-book-account-outline',
                `/Query/PatientServicedByReport/${practiceId}/${doctorEmployeeSeqNumber}`
            )
        );
    }
};

export const createQueryMenuItems = () => {
    const { current } = userContextCache;
    if (!current) {
        return [];
    }
    const { practiceId, employeeSeqNumber } = current;
    const isGp = current.specialtyCode === SpecialtyCode.Gp;
    const menuItems: MenuItemModel[] = [];

    addNhisQueryItems(menuItems, practiceId, employeeSeqNumber);
    addImportantExamQueryItems(menuItems, practiceId, employeeSeqNumber);
    if (userContextCache.currentIsGlobalAdminOrPracticeOwner) {
        addItemIfAdmin(menuItems, practiceId, employeeSeqNumber);
    }
    addImmunizationQueryItems(menuItems, practiceId, employeeSeqNumber);
    addRegisterQueryItems(menuItems, practiceId, employeeSeqNumber);
    addServiceOrderReportItems(menuItems, practiceId);
    addMoreReportItems(menuItems, practiceId, employeeSeqNumber);
    addOtherReportItems(menuItems, practiceId, employeeSeqNumber, isGp);

    return menuItems;
};
