import { AxiosResponse } from 'axios';

import { PregnancyPeriodTypeDto } from '@/model/Nomenclature/PregnancyPeriodTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const pregnancyPeriodTypeService = {
    getPregnancyPeriodTypes(): Promise<AxiosResponse<PregnancyPeriodTypeDto[]>> {
        return httpService.get('/PregnancyPeriodType/GetPregnancyPeriodTypes');
    }
};
