import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { Referral4Dto } from '@/model/Exam/Referral/Referral4Dto';
import { Referral4ViewDto } from '@/model/Exam/Referral/Referral4ViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referral4Service = {
    async getReferralInfoByExamId(examId: number): Promise<Referral4Dto> {
        const response = await httpService.get(`/Referral4/GetReferralInfoByExamId/${examId}`);
        return plainToInstance(Referral4Dto, response.data);
    },

    async getReferral4(id: number): Promise<Referral4Dto> {
        const response = await httpService.get(`/Referral4/GetReferral4/${id}`);
        return plainToInstance(Referral4Dto, response.data);
    },

    createReferral4(data: Referral4Dto): Promise<AxiosResponse<Referral4Dto>> {
        return httpService.post('/Referral4/CreateReferral4', data);
    },

    updateReferral4(data: Referral4Dto): Promise<AxiosResponse<Referral4Dto>> {
        return httpService.post('/Referral4/UpdateReferral4/', data);
    },

    deleteReferral4(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/Referral4/DeleteReferral4/${id}`);
    },

    getReferralsByExamId(examId: number): Promise<AxiosResponse<Referral4ViewDto[]>> {
        return httpService.get(`/Referral4/GetReferral4ViewsByExamId`, {
            params: {
                examId
            }
        });
    }
};
