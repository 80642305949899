<template>
    <div>
        <v-row>
            <v-col cols="12"
                ><h3 class="mb-2">
                    Фактори, влияещи върху здравното състояние на населението и контакта със здравните служби на деца от
                    0 до 17 годишна възраст
                </h3></v-col
            >
        </v-row>
        <v-row>
            <v-col cols="12"
                ><DataTableComponent v-model="value.factorForHealthStatusesChildren" :headers="headers"
            /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Annex56FactorForHealthStatusChildrenDto } from '@/model/Report/Rhi/Annex56/Annex56FactorForHealthStatusChildrenDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class FactorForHealthStatusChildren extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Annex56FactorForHealthStatusChildrenDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: 'НАИМЕНОВАНИЕ НА БОЛЕСТИТЕ ПО МКБ-10', value: 'name' },
            { text: 'Шифър', value: 'code' },
            { text: 'Всичко', value: 'total' },
            { text: 'в т. ч. деца до 1 год.', value: 'countUnder1Year' },
            { text: 'Новооткрити заболявания', value: 'countNewFound' }
        ];
    }
</script>
