<template>
    <v-row>
        <v-col cols="12">
            <v-select
                v-if="currentVisitIsLoaded"
                label="Диагноза"
                :value="value"
                :items="examDiagnoses"
                clearable
                item-text="icdName"
                :rules="[$validator.required]"
                class="required"
                return-object
                @change="$emit('input', ...arguments)"
            />
            <span v-else>Не е избран преглед</span>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';
    import { nhifProtocolDiagnosisService } from '@/service/Exam/Protocol/NhifProtocolDiagnosisService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';

    @Component
    export default class NhifProtocolDiagnosisPicker extends Vue {
        @Prop({ type: Object })
        private value!: ExamDiagnosisDto;

        private examDiagnoses: ExamDiagnosisDto[] = [];

        private get currentVisitIsLoaded() {
            return currentVisitCache.isLoaded;
        }

        private async getDiagnoses() {
            this.examDiagnoses = await nhifProtocolDiagnosisService.getExamDiagnosesByVisitDoctorSpecialty(
                currentVisitCache.value.id
            );
        }

        private async mounted() {
            if (this.currentVisitIsLoaded) {
                await this.getDiagnoses();
            }
        }
    }
</script>
