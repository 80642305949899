import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { currentUser } from '@/store/User/CurrentUser';
import { userContextCache } from '@/store/User/UserContextCache';

import { MenuItemModel } from './MenuItemModel';

export const createMainMenuItems: () => MenuItemModel[] = () => {
    const menuItems: MenuItemModel[] = [new MenuItemModel('Начало', 'mdi-home-outline', '/')];

    if (currentUser.isAuthenticated && userContextCache.userContexts.length > 0 && userContextCache.current) {
        const { practiceId, employeeSeqNumber, hasReception } = userContextCache.current;
        const isSpecialist = userContextCache?.current?.specialtyCode !== SpecialtyCode.Gp;

        if (hasReception) {
            menuItems.push(new MenuItemModel('Регистратура', 'mdi-registered-trademark', '/Reception/Dashboard'));
        }

        if (isSpecialist) {
            menuItems.push(
                new MenuItemModel('Входящи направления', 'mdi-folder-download-outline', '/IncomingReferralDownload')
            );
        }
        menuItems.push(
            new MenuItemModel('Пациенти', 'mdi-account-outline', `/Register/PatientSearch/${practiceId}`),
            new MenuItemModel('Посещения', 'mdi-stethoscope', '/VisitList/VisitSearch'),
            new MenuItemModel(
                'Отчети',
                'mdi-file-chart-outline',
                `/Report/Pis/${isSpecialist ? 'GpExamClaim' : 'PatientList'}/${practiceId}/${employeeSeqNumber}`
            ),
            new MenuItemModel('Справки', 'mdi-chart-bar', `/Query/UnpostedForms/${practiceId}/${employeeSeqNumber}`),
            new MenuItemModel('Финанси', 'mdi-finance', `/Finance/Invoice/${practiceId}`),
            new MenuItemModel('Управление', 'mdi-account-cog-outline', `/Practice/Edit/${practiceId}`),
            new MenuItemModel('Настройки', 'mdi-cog-outline', '/Setting/UserSettings'),
            new MenuItemModel('Номенклатури', 'mdi-format-list-text ', '/Nomenclature/NhifDrug'),
            new MenuItemModel('Dev tools', 'mdi-bug-outline', '/DevTool/Diagnostic'),
            new MenuItemModel('Помощ', 'mdi-help-circle-outline', `/Help/Contact/${practiceId}`)
        );
    } else {
        menuItems.push(
            new MenuItemModel('Настройки', 'mdi-cog-outline', '/Setting/UserSettings'),
            new MenuItemModel('Номенклатури', 'mdi-format-list-text ', '/Nomenclature/NhifDrug'),
            new MenuItemModel('Dev tools', 'mdi-bug-outline', '/DevTool/Diagnostic'),
            new MenuItemModel('Помощ', 'mdi-help-circle-outline', '/Help/Contact')
        );
    }

    return menuItems;
};
