import { AxiosResponse } from 'axios';

import { CounterTypeDto } from '@/model/Nomenclature/CounterTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const counterTypeService = {
    getDoctorCounterTypes(): Promise<AxiosResponse<CounterTypeDto[]>> {
        return httpService.get('/CounterType/GetDoctorEmployeeCounterTypes');
    },

    getPracticeCounterTypes(): Promise<AxiosResponse<CounterTypeDto[]>> {
        return httpService.get('/CounterType/GetPracticeCounterTypes');
    }
};
