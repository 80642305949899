<template>
    <v-card>
        <v-card-title>Договори с НЗОК</v-card-title>
        <v-card-text>
            <data-table :headers="headers" :items="practiceNhifContracts">
                <template #actions>
                    <btn action="New" :to="`/Practice/NhifContract/Create/${practiceId}`">
                        Добавяне на договор с НЗОК
                    </btn>
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Practice/NhifContract/Edit/${item.id}`" />
                </template>
            </data-table>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PracticeNhifContractDto } from '@/model/Practice/PracticeNhifContractDto';
    import { practiceNhifContractService } from '@/service/Practice/PracticeNhifContractService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class PracticeNhifContractList extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private practiceNhifContracts: PracticeNhifContractDto[] = [];
        private headers: IDataTableHeader[] = [
            { text: 'Номер на договор', value: 'contractNumber' },
            { text: 'от дата', value: 'contractDate', formatter: formatters.date },
            { text: '', value: 'actionButtons', sortable: false }
        ];

        private async mounted() {
            this.$loading.show();
            try {
                this.practiceNhifContracts = await practiceNhifContractService.getByPracticeId(this.practiceId);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
