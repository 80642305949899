import { Transform, Type } from 'class-transformer';

import { ChoiceMakerTypeCode } from '@/enum/Patient/ChoiceMakerTypeCode';
import { PatientRegistrationTypeCode } from '@/enum/Patient/PatientRegistrationTypeCode';
import { AddressDto } from '@/model/Common/AddressDto';
import { ContactDto } from '@/model/Common/ContactDto';
import { PersonIdentificationDto } from '@/model/Common/PersonIdentificationDto';
import { ChoiceMakerDto } from '@/model/Patient/ChoiceMaker/ChoiceMakerDto';
import { FeeDisengagementDto } from '@/model/Patient/FeeDisengagement/FeeDisengagementDto';
import { InsuranceStatusDto } from '@/model/Patient/InsuranceStatus/InsuranceStatusDto';

import { PatientVersionKey } from './PatientVersionKey';

export class PatientVersionDto {
    @Type(() => PatientVersionKey)
    public key: PatientVersionKey = new PatientVersionKey();

    public lastVersionSeqNumber: number | null = null;

    public hasFiles: boolean = false;

    @Type(() => PersonIdentificationDto)
    public personIdentification: PersonIdentificationDto = new PersonIdentificationDto();

    public gpEmployeeSeqNumber: number | null = null;
    public creditToEmployeeSeqNumber: number | null = null;
    public idCardNumber: string = '';
    public idCardAuthority: string = '';
    public idCardIssueDate: Date | null = null;

    public isRetired: boolean = false;
    public firstName: string = '';
    public middleName: string = '';
    public lastName: string = '';
    public prescriptionBook: string = '';
    public healthInsuranceBook: string = '';
    public lakNumber: string = '';
    public rhif: string = '';
    public rhifArea: string = '';
    public isNewVersion: boolean | null = null;
    public validFrom: string | null = null;
    public validTo: string | null = null;
    public age: number | null = null;
    public notes: string = '';
    public isDeclarationEsignPrinted: boolean = false;
    public isDeclarationGdprPrinted: boolean = false;

    public choiceMakerTypeCode: ChoiceMakerTypeCode | null = null;
    @Type(() => ChoiceMakerDto)
    public choiceMaker: ChoiceMakerDto = new ChoiceMakerDto();

    @Transform(({ value }) => value ?? new InsuranceStatusDto())
    @Type(() => InsuranceStatusDto)
    public insuranceStatus: InsuranceStatusDto = new InsuranceStatusDto();

    public pensionStatusCheckDate: Date | null = null;

    public patientRegistrationTypeCode: PatientRegistrationTypeCode = PatientRegistrationTypeCode.Registered;
    public registrationDate: Date = new Date();
    public deregistrationDate: Date | null = null;

    @Type(() => AddressDto)
    public address: AddressDto = new AddressDto();

    @Type(() => ContactDto)
    public contact: ContactDto = new ContactDto();

    public feeDisengagements: FeeDisengagementDto[] = [];

    public get fullName() {
        return `${this.firstName} ${this.middleName} ${this.lastName}`;
    }

    public get nameAndIdentifier() {
        return `${this.fullName} - ${this.personIdentification.identifier}`;
    }

    // Мвасилев: За момента изчислението на възрастта го правим на сървъра. Ако има нужда при въвеждане на нов клиент,
    // преди да е записан да се изчисли възрастта, ще измислим изчисление в СПА частта.
    public calculateAge(): void {
        const epochYear = 1970;
        this.age = this.personIdentification.birthDate
            ? new Date(Date.now() - new Date(this.personIdentification.birthDate).valueOf()).getFullYear() - epochYear
            : null;
    }

    // Dkaraminov: Проверяваме дали един пациент е отписан или временно регистриран с изтекъл период на регистрация
    public isDeregistered(): boolean {
        // Сглобяваме така датата, защото deregistrationDate e с час 0:00:00, a когато правим сравнение между
        // deregistrationDate и new Date(), тогава взема текущия час и проверките в граничните случаи са грешни
        const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        return (
            this.patientRegistrationTypeCode === PatientRegistrationTypeCode.Deregistered ||
            (this.deregistrationDate && this.deregistrationDate.getTime() < today.getTime()) ||
            false
        );
    }

    // Mpetrov: Проверяваме дали пациентът е починал
    public isDeceased(): boolean {
        return this.patientRegistrationTypeCode === PatientRegistrationTypeCode.Deceased;
    }
}
