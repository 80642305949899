import { InsuranceStatusDto } from '@/model/Patient/InsuranceStatus/InsuranceStatusDto';

export class PatientInsuranceCheckRequestDto {
    constructor(
        public patientId: number,
        public patientPidTypeCode: string,
        public patientIdentifier: string,
        public insuranceStatus: InsuranceStatusDto
    ) {}
}
