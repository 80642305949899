<template>
    <v-dialog v-model="dialogIsVisible" persistent width="75%">
        <v-card>
            <main-title>Общи условия за {{ currentUserContext?.practiceName ?? '' }}</main-title>
            <v-card-title>
                Необходимо е да прочетете общите условия и да се съгласите с тях преди да продължите работа с Хипократ.
                <router-link :to="`doc/user-agreement/version-${versionSeqNumber}.html`" target="_blank">
                    Отваряне на документа с общите условия
                </router-link>
            </v-card-title>
            <v-card-text>
                <v-checkbox v-model="agree" label="Прочетох и се съгласявам с общите условия" />
            </v-card-text>
            <v-card-actions>
                <btn action="Save" :disabled="!agree" @click="createUserAgreement">Потвърждавам</btn>
                <btn action="Cancel" @click="clearUserContext" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator';

    import MainTitle from '@/component/Common/MainTitle.vue';
    import { UserAgreementDto } from '@/model/User/UserAgreementDto';
    import { UserContextDto } from '@/model/User/UserContext/UserContextDto';
    import { userAgreementService } from '@/service/User/UserAgreementService';
    import { currentUser } from '@/store/User/CurrentUser';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { MainTitle }
    })
    export default class UserAgreementDialog extends Vue {
        private dialogIsVisible: boolean = false;
        private agree: boolean = false;
        private versionSeqNumber: number = 1;

        private get currentUserContext() {
            return userContextCache.current;
        }

        @Watch('currentUserContext', { immediate: true })
        async onUserContextChange(value: UserContextDto | null) {
            if (value) {
                await this.checkForUserAgreement();
            }
        }

        private async checkForUserAgreement() {
            if (currentUser.isAuthenticated && userContextCache.currentPracticeId) {
                const hasUserAgreement = await userAgreementService.checkForUserAgreement(this.userAgreementDto());
                if (!hasUserAgreement) {
                    this.agree = false;
                    this.dialogIsVisible = true;
                }
            }
        }

        private async createUserAgreement() {
            await userAgreementService.createUserAgreement(this.userAgreementDto());
            this.dialogIsVisible = false;
        }

        private userAgreementDto() {
            return new UserAgreementDto(this.versionSeqNumber, userContextCache.currentPracticeId ?? 0);
        }

        private clearUserContext() {
            userContextCache.current = null;
            this.dialogIsVisible = false;
            // Ако вече е заредена началната страница, не пренасочва към нея, за да не излиза грешка в конзолата 'NavigationDuplicated'.
            const path = '/';
            if (this.$route.path !== path) {
                this.$router.push(path);
            }
        }
    }
</script>
