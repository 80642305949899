import { AxiosResponse } from 'axios';

import { VeteranPrescriptionBookDto } from '@/model/Patient/PrescriptionBook/VeteranPrescriptionBookDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { userContextCache } from '@/store/User/UserContextCache';

const setCurrentUserContext = (data: VeteranPrescriptionBookDto) => {
    const { current } = userContextCache;
    if (!current) {
        throw Error('Не е избрана месторабота.');
    }
    data.doctorEmployeeSeqNumber = current.employeeSeqNumber;
};

export const veteranPrescriptionBookService = {
    getVeteranPrescriptionBook(veteranPrescriptionBookId: number): Promise<AxiosResponse<VeteranPrescriptionBookDto>> {
        return httpService.get(`/VeteranPrescriptionBook/GetVeteranPrescriptionBook`, {
            params: { veteranPrescriptionBookId }
        });
    },

    getVeteranTableItems(patientId: number): Promise<AxiosResponse<VeteranPrescriptionBookDto[]>> {
        return httpService.get(`/VeteranPrescriptionBook/GetPatientVeteranPrescriptionBooks`, {
            params: { patientId }
        });
    },

    createVeteranPrescriptionBook(data: VeteranPrescriptionBookDto): Promise<AxiosResponse<number>> {
        setCurrentUserContext(data);
        return httpService.post('/VeteranPrescriptionBook/CreateVeteranPrescriptionBook', data);
    },

    updateVeteranPrescriptionBook(
        data: VeteranPrescriptionBookDto
    ): Promise<AxiosResponse<VeteranPrescriptionBookDto>> {
        setCurrentUserContext(data);
        return httpService.post('/VeteranPrescriptionBook/UpdateVeteranPrescriptionBook/', data);
    },

    canSave(data: VeteranPrescriptionBookDto): Promise<AxiosResponse<string[]>> {
        setCurrentUserContext(data);
        return httpService.post('/VeteranPrescriptionBook/CanSave', data);
    },

    deleteVeteranPrescriptionBook(veteranPrescriptionBookId: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/VeteranPrescriptionBook/DeleteVeteranPrescriptionBook`, {
            params: { veteranPrescriptionBookId }
        });
    }
};
