// Целият този файл е генериран от командата "vue add i18n", която инициализира проекта чрез plugin-а "vue-cli-plugin-i18n".
// Във файла "vue.config.js" привидно има подобни настройки, но те не се използват runtime. Runtime важи само този файл.

import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

// Трябва да се изпълни преди new VueI18n...().
Vue.use(VueI18n);

const loadLocaleMessages = () => {
    const locales = require.context('@/plugin/I18n/locales', true, /[A-Za-z0-9-_,\s]+\.json$/iu);
    const messages: LocaleMessages = {};
    for (const key of locales.keys()) {
        const matched = key.match(/(?<locale>[A-Za-z0-9-_]+)\./iu);
        if (matched?.groups) {
            const { locale } = matched.groups;
            if (locale) {
                messages[locale] = locales(key);
            }
        }
    }
    return messages;
};

export const i18n = new VueI18n({
    // Виж src/.env
    // Зарежда преводите от файла "bg.json", който трябва да се намира в папка "src/plugin/I18n/locales".
    locale: process.env.VUE_APP_I18N_LOCALE || '',
    // "Fallback" в случая означава само, че ако няма файл fr.json, се ползва файлът en.json.
    // Ако обаче във файла bg.json няма даден ключ, НЕ се чете файлът en.json, а се показва самият ключ.
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || '',
    // Съобщенията се зареждат от този метод. Настройките от vue.config.js/pluginOptions/i18n не се ползват runtime.
    messages: loadLocaleMessages()
});
