import { AxiosResponse } from 'axios';

import { Referral8Dto } from '@/model/Exam/Referral/Referral8Dto';
import { Referral8ViewDto } from '@/model/Exam/Referral/Referral8ViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referral8Service = {
    getReferral8(patientId: number): Promise<AxiosResponse<Referral8Dto>> {
        return httpService.get(`/Referral8/GetReferral8/${patientId}`);
    },

    createReferral8(data: Referral8Dto): Promise<AxiosResponse<Referral8Dto>> {
        return httpService.post('/Referral8/CreateReferral8', data);
    },

    createReferral8a(data: Referral8Dto): Promise<AxiosResponse<Referral8Dto>> {
        return httpService.post('/Referral8/Create8a', data);
    },

    updateReferral8(data: Referral8Dto): Promise<AxiosResponse<Referral8Dto>> {
        return httpService.put('/Referral8/UpdateReferral8/', data);
    },

    deleteReferral8(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/Referral8/DeleteReferral8/${id}`);
    },

    getReferral8InfoByExamId(examId: number): Promise<AxiosResponse<Referral8Dto>> {
        return httpService.get(`/Referral8/GetReferralInfoByExamId/${examId}`);
    },

    getReferral8aInfoByExamId(examId: number): Promise<AxiosResponse<Referral8Dto>> {
        return httpService.get(`/Referral8/GetReferral8aInfoByExamId/${examId}`);
    },

    getReferralsByExamId(examId: number, is8a: boolean): Promise<AxiosResponse<Referral8ViewDto[]>> {
        return httpService.get(`/Referral8/GetReferral8ViewsByExam`, {
            params: {
                examId,
                is8a
            }
        });
    }
};
