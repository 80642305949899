import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { config } from '@/Config';
import { NhifErrorDto } from '@/model/Nhif/Error/NhifErrorDto';
import { notifierService } from '@/service/Infrastructure/NotifierService';

const axiosConfig: AxiosRequestConfig = {
    baseURL: config.nhifApiBaseUrl
};

const axiosInstance = axios.create(axiosConfig);

const parseError = (errorMessage: NhifErrorDto) => {
    let errorTitle = '';
    let errorDetails = '';
    const errors = errorMessage?.contents?.error;
    if (errors) {
        for (const err of errors) {
            errorTitle += `\r\n${err.type} - ${err.reason}`;
            errorDetails += `\r\n${err?.description}`;
        }
    } else {
        errorTitle = 'Error';
        errorDetails = JSON.stringify(errorMessage);
    }
    notifierService.showError(errorTitle, errorDetails);
};

axiosInstance.interceptors.response.use(
    (response) => {
        console.log(response);
        return response;
    },
    (error: AxiosError<NhifErrorDto>) => {
        console.log(error);
        const nhifErrorMessage = error?.response?.data as NhifErrorDto;
        if (nhifErrorMessage) {
            parseError(nhifErrorMessage);
        }
        return Promise.reject(error);
    }
);

export const nhifHttpService = {
    get<T>(url: string, axiosRequestConfig?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axiosInstance.get(url, axiosRequestConfig);
    },

    post<T>(url: string, data: unknown): Promise<AxiosResponse<T>> {
        return axiosInstance.post<T>(url, data, {
            headers: { 'Content-Type': 'application/json' }
        });
    }
};
