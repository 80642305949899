export class AnamnesticDataCardDto {
    public age: number | null = null;
    public nomrDeviations: boolean = false;
    public periodDuration: number | null = null;
    public periodFrequency: number | null = null;
    public otherDeviations: string | null = null;
    public lastMenstruation: Date | null = null;
    public infectiousDiseases: string | null = null;
    public familyBurden: string | null = null;
    public probableBirthTerm: Date | null = null;
    public smoking: boolean = false;
    public alcohol: boolean = false;
    public overweight: boolean = false;
    public overAge: boolean = false;
    public malnutrition: boolean = false;
    public foodAlergy: boolean = false;
    public medicineAlergy: boolean = false;
    public otherAlergy: string | null = null;
    public operationDiagnosis: string | null = null;
    public previousPregnancyProblems: string | null = null;
    public gestWeek: number | null = null;
    public abortByRequestCount: number | null = null;
    public spontaneousAbortCount: number | null = null;
    public abortGestWeek: number | null = null;
    public abortByMedicineNeedCount: number | null = null;
    public abortDiagnosis: string | null = null;
    public lastAbortDate: Date | null = null;
    public otherDiseases: string | null = null;
    public takingDrugs: string | null = null;
    public pregnancySequence: number | null = null;
    public lastBirthDate: Date | null = null;
    public aliveChildrenCount: number | null = null;
    public stillBornChildrenCount: number | null = null;
    public prematureChildrenCount: number | null = null;
    public transferredChildrenCount: number | null = null;
    public sterilityDiagnosis: string | null = null;
    public unwantedPregnancy: boolean = false;
    public parentsIncompatibility: boolean = false;
}
