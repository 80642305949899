import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';

export class ScheduledExamCalendarDto {
    public id: number | null = null;
    public employeeSeqNumber: number | null = null;
    public patientId: number | null = null;
    public practiceId: number | null = null;
    public patientName: string = '';
    public examDateTime: Date | null = null;
    public workTimeVisitReasonId: number | null = null;
    public notes: string = '';
    public fundCode: string = '';
    public color: string = '';
    public doctorName: string = '';
    public specialtyCode: SpecialtyCode | null = null;
    public specialtyName: string = '';
    public workTimeVisitReasonName: string = '';
    public examDurationInMinutes: number | null = null;
}
