import { plainToInstance } from 'class-transformer';

import { ServiceOrderDetailedFinancialReportDto } from '@/model/Query/Reception/ServiceOrderDetailedFinancialReportDto';
import { ServiceOrderFinancialReportCommand } from '@/model/Query/Reception/ServiceOrderFinancialReportCommand';
import { httpService } from '@/service/Infrastructure/HttpService';

export const serviceOrderDetailedReportService = {
    async getDetailedFinancialReport(
        command: ServiceOrderFinancialReportCommand
    ): Promise<ServiceOrderDetailedFinancialReportDto[]> {
        const response = await httpService.post<ServiceOrderDetailedFinancialReportDto[]>(
            'ServiceOrderDetailedReport/GetDetailedFinancialReport',
            command
        );
        return plainToInstance(ServiceOrderDetailedFinancialReportDto, response?.data);
    },

    exportDataDetailedReport(command: ServiceOrderFinancialReportCommand): Promise<void> {
        return httpService.download('/ServiceOrderDetailedReport/ExportExcelDetailedReport', { params: command });
    }
};
