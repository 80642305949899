import { plainToInstance } from 'class-transformer';

import { InvoiceRecipientDto } from '@/model/Finance/Invoice/InvoiceRecipientDto';
import { IDropdownSearchService } from '@/service/Dropdown/IDropdownSearchService';
import { httpService } from '@/service/Infrastructure/HttpService';

export const invoiceRecipientService: IDropdownSearchService<InvoiceRecipientDto> = {
    getItemsById(): Promise<InvoiceRecipientDto[]> {
        return Promise.resolve([]);
    },

    async searchItems(filters: []): Promise<InvoiceRecipientDto[]> {
        const practiceIdIndex = 2;
        const recipientTypeIndex = 1;
        const filterIndex = 0;
        const response = await httpService.get<InvoiceRecipientDto[]>('/InvoiceRecipient/Search', {
            params: {
                filter: filters.at(filterIndex),
                recipientTypeCode: filters.at(recipientTypeIndex),
                practiceId: filters.at(practiceIdIndex)
            }
        });
        return plainToInstance(InvoiceRecipientDto, response.data);
    }
};
