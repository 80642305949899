<template>
    <div>
        <v-form ref="form" v-model="isFormValid">
            <v-container>
                <v-card>
                    <v-card-title class="mx-2 my-3"> Извличане на Е-Преглед </v-card-title>
                    <v-card-text>
                        <alert v-if="!isPatientLoaded" type="info"
                            >Не е избран пациент! Необходимо е да изберете пациент, за извличане на преглед.</alert
                        >
                        <v-row>
                            <v-col cols="3">
                                <date-picker v-model="command.startDateTime" label="Дата на прегледа" />
                            </v-col>
                            <v-col cols="3">
                                <text-field v-model="command.nrn" :rules="[$validator.nrn]" label="НРН номер" />
                            </v-col>
                            <v-col cols="3">
                                <text-field v-model="command.lrn" label="ЛРН номер"></text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <btn action="Refresh" class="ml-2" :disabled="isLoading" @click="extractExam">Извличане</btn>
                    </v-card-actions>
                    <data-table
                        v-if="tableItems && tableItems.length > 0"
                        :headers="headers"
                        :items="tableItems"
                        title="Изтеглени прегледи"
                    >
                        <template #rowActions="{ item }">
                            <btn icon action="Show" @click="examPreview(item)" />
                        </template>
                    </data-table>
                </v-card>
            </v-container>
            <v-container v-if="resultXml && !hideXmlResult" class="mt-5">
                <h3>Резултат в XML</h3>
                <v-row class="mt-3">
                    <v-col cols="12">
                        <v-textarea v-model="resultXml"></v-textarea>
                    </v-col>
                </v-row>
            </v-container>
            <nhis-extracted-exam
                v-model="selectedItem"
                :dialog-is-visible="dialogIsVisible"
                @close-dialog="dialogIsVisible = false"
            />
        </v-form>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import format from 'xml-formatter';

    import Alert from '@/component/Common/Alert.vue';
    import DataTable from '@/component/Common/DataTable.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhisExamExtractionCommand } from '@/model/Nhis/Exam/NhisExamExtractionCommand';
    import { NhisExamExtractionDto } from '@/model/Nhis/Exam/NhisExamExtractionDto';
    import { NhisExamExtractionResultDto } from '@/model/Nhis/Exam/NhisExamExtractionResultDto';
    import { nhisExamService } from '@/service/Nhis/NhisExamService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { loadingState } from '@/store/LoadingState';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';
    import NhisExtractedExam from '@/view/Nhis/Exam/NhisExtractedExam.vue';

    @Component({
        components: { NhisExtractedExam, DataTable, Alert }
    })
    export default class NhisExamExtraction extends Vue {
        private isFormValid: boolean = false;
        private command: NhisExamExtractionCommand = new NhisExamExtractionCommand();
        private extractionResult: NhisExamExtractionResultDto = new NhisExamExtractionResultDto();
        private resultXml: string = '';
        private tableItems: NhisExamExtractionDto[] = [];
        private selectedItem: NhisExamExtractionDto = new NhisExamExtractionDto();
        private dialogIsVisible: boolean = false;

        @Prop({ default: false })
        hideXmlResult!: boolean;

        private get isLoading() {
            return loadingState.isVisible;
        }

        private get isPatientLoaded() {
            return currentPatientCache.isLoaded;
        }

        private async extractExam(): Promise<NhisExamExtractionResultDto> {
            const result = new NhisExamExtractionResultDto();
            if (!this.isPatientLoaded) {
                return result;
            }
            this.command.patientVersionKey = currentPatientCache.value?.key;
            this.command.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
            this.command.doctorUin = userContextCache.current?.doctorUin ?? '';

            const extractionResult = await nhisExamService.postExamExtraction(this.command);
            if (extractionResult?.response) {
                this.tableItems = extractionResult.exams;
                this.resultXml = format(extractionResult.response, {
                    indentation: '  ',
                    filter: (node) => node.type !== 'Comment',
                    collapseContent: true,
                    lineSeparator: '\n'
                });
            }
            return result;
        }

        private examPreview(item: NhisExamExtractionDto) {
            this.selectedItem = item;
            this.dialogIsVisible = true;
        }

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'НРН:', value: 'nrn' },
                { text: 'Специалност:', value: 'performerInfo.doctorQualificationCode' },
                { text: 'Дата:', value: 'openDate', formatter: formatters.date },
                { text: 'Статус:', value: 'status' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }
    }
</script>
