import { Type } from 'class-transformer';

import { NhifVisitReasonCode } from '@/enum/Exam/NhifVisitReasonCode';
import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

const maxCreatedByNames = 2;

export class VisitResultDto {
    public id: number = 0;

    @Type(() => PatientVersionKey)
    public patientVersionKey: PatientVersionKey = new PatientVersionKey();

    public startDateTime: Date = new Date();
    public ambulatorySheetNumber: number | null = null;
    public hasStagedEpicrisis: boolean = false;
    public hasFiles: boolean = false;
    public hasExternalVisit: boolean = false;
    public nrn: string = '';
    public patientIdentifier: string = '';
    public firstName: string = '';
    public middleName: string = '';
    public lastName: string = '';
    public icdCode: string = '';
    public referral3: string = '';
    public referral3a: string = '';
    public referral4: string = '';
    public labTestOrders: string = '';
    public referral7: string = '';
    public immunization: string = '';
    public prescription: string = '';
    public sickSheetCount: number | null = null;
    public nhifVisitReasonCode: string = '';
    public fundCode: string = '';
    public statusCode: string | null = null;
    public isSecondary: boolean | null = null;
    public createdBy: string = '';

    public get shortCreatedBy() {
        const names = this.createdBy.split(' ');
        return names.length > maxCreatedByNames ? `${names[0]} ${names[names.length - 1]}` : this.createdBy;
    }

    public get patientFullName() {
        // Презимето не се показва, за да не се претрупва и без друго обемният резултат.
        return `${this.firstName} ${this.lastName}`;
    }

    public get examNumberOrVisitType() {
        return this.ambulatorySheetNumber !== null
            ? this.nhifVisitReasonCode !== NhifVisitReasonCode.HighSpecialized
                ? `${this.ambulatorySheetNumber} ${this.isSecondary ? `Вторичен` : 'Първичен'}`
                : `${this.ambulatorySheetNumber}`
            : `${this.sickSheetCount ? 'БЛ без АЛ' : ''}${this.hasStagedEpicrisis ? ' Етапна' : ''}${
                  this.hasFiles ? ' Прикачени' : ''
              }${this.hasExternalVisit ? ' Резултати' : ''}`;
    }
}
