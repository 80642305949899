<template>
    <div>
        <v-dialog v-model="extractionDialogIsVisible" persistent max-width="75%">
            <v-card>
                <v-card-title>Извличане на медицинско за шофьор</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6" md="3">
                            <text-field v-model="model.nrn" label="НРН на удостоверение" :rules="[$validator.nrn]"
                        /></v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <btn action="Cancel" text @click="closeExtractionDialog" />
                    <btn action="Download" text @click="extract">Извличане</btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import TextField from '@/component/Common/TextField.vue';
    import { NhisCertificateDriveExtractionCommand } from '@/model/Nhis/CertificateDrive/NhisCertificateDriveExtractionCommand';
    import { nhisCertificateDriveService } from '@/service/Nhis/NhisCertificateDriveService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { TextField }
    })
    export default class NhisCertificateDriveExtraction extends Vue {
        @Prop()
        extractionDialogIsVisible!: boolean;

        @Prop()
        examId!: number;

        private model: NhisCertificateDriveExtractionCommand = new NhisCertificateDriveExtractionCommand();

        private closeExtractionDialog() {
            this.$emit('close');
            this.model = new NhisCertificateDriveExtractionCommand();
        }

        private async extract() {
            const path = await nhisCertificateDriveService.extractCertificateDrive(this.getRequestData());
            if (path) {
                this.$router.push(path);
            }
            this.closeExtractionDialog();
        }

        private getRequestData() {
            this.model.doctorUin = userContextCache.current?.doctorUin ?? '';
            this.model.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
            this.model.examId = this.examId;
            return this.model;
        }
    }
</script>

<style scoped></style>
