import { plainToInstance } from 'class-transformer';

import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhifProtocolDiagnosisService = {
    async getExamDiagnosesByVisitDoctorSpecialty(id: number): Promise<ExamDiagnosisDto[]> {
        const response = await httpService.get<ExamDiagnosisDto[]>(
            `NhifProtocolDiagnosis/GetExamDiagnosesByVisitDoctorSpecialty/${id}`
        );
        return plainToInstance(ExamDiagnosisDto, response?.data);
    }
};
