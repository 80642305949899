import { AxiosResponse } from 'axios';

import { ExamNotPrintedDto } from '@/model/Query/Exam/ExamNotPrintedDto';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const examNotPrintedService = {
    getExamNotPrinted(filter: ReportIntervalFilterDto): Promise<AxiosResponse<ExamNotPrintedDto[]>> {
        return httpService.post('ExamNotPrinted/GetNotPrintedExams', filter);
    }
};
