<template>
    <Bar
        :responsive="true"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :width="width"
        :height="height"
        :chart-options="options"
    />
</template>

<script lang="ts">
    import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
    import { Bar } from 'vue-chartjs/legacy';
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ChartDataModel } from '@/model/Common/Chart/ChartDataModel';

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

    @Component({ components: { Bar } })
    export default class BarChart extends Vue {
        @Prop()
        private chartData!: ChartDataModel;

        @Prop({ default: 'bar-chart' })
        private chartId!: string;

        @Prop({ default: 'label' })
        private datasetIdKey!: string;

        @Prop({ default: 400 })
        private width!: number;

        @Prop({ default: 400 })
        private height!: number;

        @Prop({ default: 0 })
        private legendColorBoxWidth!: number;

        @Prop({ default: '' })
        private title!: string;

        @Prop({ default: false })
        private showLegend!: boolean;

        // eslint-disable-next-line @typescript-eslint/typedef
        private options = {
            plugins: {
                legend: {
                    display: false,
                    labels: {
                        boxWidth: 0
                    }
                },
                title: {
                    display: true,
                    text: this.title
                },
                datalabels: {
                    labels: {
                        title: null
                    }
                }
            }
        };

        private mounted() {
            if (this.legendColorBoxWidth) {
                this.options.plugins.legend.labels.boxWidth = this.legendColorBoxWidth;
            }
            if (this.title) {
                this.options.plugins.title.text = this.title;
            }
            this.options.plugins.legend.display = this.showLegend;
        }
    }
</script>
