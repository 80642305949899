import { AxiosResponse } from 'axios';

import { ResultReferral4Dto } from '@/model/Exam/Referral/ResultReferral/ResultReferral4Dto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const ReferralResult4Service = {
    getResultData(examId: number, nrn: string): Promise<AxiosResponse<ResultReferral4Dto>> {
        return httpService.get('ReferralResult4/GetResultData', { params: { examId, nrn } });
    }
};
