import { Type } from 'class-transformer';

import { Report365PsychiatricPatientRowDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricPatientRowDto';

export class Report365PsychiatricPatientDto {
    public name: string = '';

    @Type(() => Report365PsychiatricPatientRowDto)
    public psychiatricPatients: Report365PsychiatricPatientRowDto[] = [];
}
