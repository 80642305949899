<template>
    <v-card-text>
        <v-row class="align-end">
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12">
                        <PatientInfo />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="value.workplaceId"
                            :items="patientWorkplaces"
                            :item-text="workplacePosition"
                            item-value="id"
                            label="Трудоустрояване:"
                            dense
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <date-picker v-model="value.workplaceDate" label="Дата:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <text-field v-model="value.socialStatus" label="Семейно положение:" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field v-model="value.citizenship" label="Гражданство:" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model="value.marriageYears"
                            type="number"
                            label="Настоящия брак е сключен преди (години):"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12">
                        <date-picker v-model="value.dispensaryDate" label="Обхваната под дисп. наблюдение:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field
                            v-model="value.pregnancyPeriod"
                            type="number"
                            label="Срок на бременността в седмици"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <date-picker v-model="value.issueDate" label="Направлението издадено на:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <date-picker v-model="value.lastMenstruation" label="Последна редовна менструация:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <date-picker v-model="value.firstMove" label="Първо движение на плода:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <date-picker v-model="value.expectedBirth" label="Очаквано раждане:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="value.pregnancyCount" type="number" label="Настоящата бременност е:" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-textarea v-model="value.familyBurden" label="Фамилна обремененост: не, да - колко:" dense />
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { PersonalInfoDto } from '@/model/Exam/Referral/PregnancyReferral/PersonalInfoDto';
    import { PatientWorkplaceDto } from '@/model/Patient/PatientWorkplaceDto';
    import { patientWorkplaceService } from '@/service/Patient/PatientWorkplaceService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    @Component({
        components: { PatientInfo }
    })
    export default class PersonalInfo extends Vue {
        @Prop()
        private value!: PersonalInfoDto;

        private patientWorkplaces: PatientWorkplaceDto[] = [];

        private async getPatientWorkplaces() {
            this.patientWorkplaces = await patientWorkplaceService.getActivePatientWorkplaces(
                currentPatientCache?.value?.key?.patientId ?? 0
            );
        }

        private workplacePosition(workplace: PatientWorkplaceDto) {
            return `${workplace.company} - ${workplace.position}`;
        }

        private async mounted() {
            await this.getPatientWorkplaces();
        }
    }
</script>
