<template>
    <div>
        <v-row class="ma-3">
            <alert type="info">
                Прикачените документи са за информация на лекаря. Не се изпращат към НЗИС или към други системи.
            </alert>
        </v-row>
        <data-table
            :headers="headers"
            title="Прикачени документи към посещение"
            :loading="$loading.isVisible"
            :items="items"
        >
            <template #actions>
                <btn action="New" :to="`/Exam/FileUpload/Create/${visitId}`" />
            </template>
            <template #rowActions="{ item }">
                <btn icon action="Download" @click="downloadFile(item)" />
                <btn icon action="Delete" @click="deleteVisitFile(item)" />
            </template>
        </data-table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { FileInfoDto } from '@/model/File/FileInfoDto';
    import { externalFileService } from '@/service/File/ExternalFileService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class VisitFileView extends Vue {
        @Prop({ required: true })
        private visitId!: number;

        private items: FileInfoDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Име на файл:', value: 'fileName' },
                { text: 'Добавен на:', value: 'uploadTime', formatter: formatters.dateTime },
                { text: 'Описание:', value: 'description' }
            ];
        }

        private async mounted() {
            this.$loading.show();
            try {
                if (this.visitId) {
                    this.items = await externalFileService.loadFilesForVisit(this.visitId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async downloadFile(file: FileInfoDto) {
            this.$loading.show();
            try {
                await externalFileService.downloadFile(file.key);
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteVisitFile(file: FileInfoDto) {
            this.$loading.show();
            try {
                const visitIsDeleted = await externalFileService.deleteVisitFile(file.key);
                // Параметърът documentId само се проверява дали е truthy (във VisitMenu.vue), така че не трябва да бъде нула.
                const documentId = 123;
                eventBus.$emit('delete-referral-event', documentId, 'FileUpload', 'Прикачен документ');
                // TODO: currentPatientCache.value.hasFiles трябва да се обнови от сървъра, или сега, или при преход към досието.
                // Ако сме изтрили последния файл за пациента, при преход към досието, менюто с файлове още ще е зелено.
                if (visitIsDeleted) {
                    this.$router.push(`/Patient/VisitSummary/${currentPatientCache.value.key.patientId}`);
                } else if (this.items.length === 1) {
                    this.$router.push(`/Exam/FileUpload/Create/${this.visitId}`);
                } else {
                    arrayUtil.removeFirst(this.items, file);
                }
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
