export class PatientFundDto {
    public patientId: number = 0;
    public practiceId: number = 0;
    public id: number | null = null;
    public fundCode: string = '';
    public cardNumber: string = '';
    public validFrom: Date | null = null;
    public validTo: Date | null = null;
    public isDeleted: boolean = false;
    public isValid: boolean = false;
}
