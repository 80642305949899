<template>
    <div>
        <data-table
            title="Подадени документи"
            :headers="tableHeaders"
            :loading="$loading.isVisible"
            :items="value"
            sort-by="id"
            class="mt-5"
            sort-desc
        >
            <template #rowActions="{ item }">
                <btn icon action="CheckDocument" @click="checkStatus(item)">Проверка на статус</btn>
            </template>
        </data-table>
        <SuccessDialog v-model="resultDialogIsVisible" :text="dialogText" color="info" title="Проверка на статус" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PisFileRowDto } from '@/model/Report/Pis/PisFile/PisFileRowDto';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { localServerPisService } from '@/service/Report/LocalServerPisService';
    import { pisFileService } from '@/service/Report/PisFileService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    import SuccessDialog from './SuccessDialog.vue';

    @Component({
        components: { SuccessDialog }
    })
    export default class PisFile extends Vue {
        @Prop()
        private value!: PisFileRowDto[];

        @Prop({ default: 5 })
        itemsPerPage!: number;

        private resultDialogIsVisible: boolean = false;
        private dialogText: string = '';

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Идентификатор на документа', value: 'pisFileId' },
                { text: 'Период от', value: 'startDate', formatter: formatters.date },
                { text: 'Период до', value: 'endDate', formatter: formatters.date },
                { text: 'Качен на', value: 'uploadDate', formatter: formatters.date },
                { text: 'Статус', value: 'status' },
                {
                    text: 'Последна проверка на',
                    value: 'lastCheckDate',
                    formatter: formatters.date
                }
            ];
        }

        private async checkStatus(item: PisFileRowDto) {
            this.$loading.show();
            try {
                if (await localServerDiagnosticService.checkIsLocalServerActive()) {
                    const response = await localServerPisService.checkDocumentStatus(
                        item.pisFileId,
                        userContextCache.current?.practiceNumber ?? '',
                        userContextCache.current?.doctorIdentifier ?? ''
                    );
                    if (!response.data.isError) {
                        const { practiceId, seqNumber } = item;

                        // Това е индексът, на който се получава статусът от тестовата среда, за реалната ще се наложи преработка, защото се различават.
                        // 10 е индексът в тестовата среда,в продукционна е 6
                        const fileStatusPosition = 6;
                        const splitResponse = response.data.additionalInformation.split('\t');
                        item.status = splitResponse[fileStatusPosition];

                        item.lastCheckDate = new Date().toISOString();
                        this.dialogText = `${response.data.message} Резултатът от проверката е: ${item.status}`;
                        this.resultDialogIsVisible = true;

                        await pisFileService.updatePisFile(
                            practiceId,
                            seqNumber,
                            item.status,
                            // TODO: От къде се взима съобщението за грешка?
                            null
                        );
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
