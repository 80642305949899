<template>
    <div>
        <data-table
            :headers="tableHeaders"
            :loading="$loading.isVisible"
            :items="items"
            title="Рискови групи"
            class="elevation-1 mb-5"
            disable-pagination
        >
            <template v-if="$allowWrite" #actions>
                <btn action="New" @click="openNewRiskGroupDialog" />
            </template>
            <template #rowActions="{ item }">
                <btn icon action="Edit" @click="openEditRiskGroupDialog(item)" />
            </template>
        </data-table>
        <v-row justify="center" class="mx-5">
            <v-dialog v-model="dialogIsVisible" persistent max-width="75%">
                <v-card>
                    <v-card-title>
                        <span class="headline">Рискова група</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form" v-model="isFormValid" :disabled="!$allowWrite">
                            <risk-group-editor v-if="dialogIsVisible" v-model="clone"></risk-group-editor>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <btn v-if="clone.id > 0" action="Delete" @click="deleteEntity" />
                        <v-spacer></v-spacer>
                        <btn action="Cancel" @click="closeRiskGroupDialog" />
                        <btn action="Save" @click="saveRiskGroup" />
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RiskGroupRegisterDto } from '@/model/Register/RiskGroup/RiskGroupRegisterDto';
    import { riskGroupRegisterService } from '@/service/Register/RiskGroupRegisterService';
    import { formatters } from '@/util/Formatters';

    import RiskGroupEditor from './RiskGroupEditor.vue';

    @Component({
        components: { RiskGroupEditor }
    })
    export default class RiskGroup extends Vue {
        @Prop()
        public id!: number;

        private dialogIsVisible: boolean = false;
        private riskGroupModel: RiskGroupRegisterDto = new RiskGroupRegisterDto();
        private clone: RiskGroupRegisterDto = new RiskGroupRegisterDto();
        private items: RiskGroupRegisterDto[] = [];
        private isFormValid: boolean = false;

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Дата на записване', value: 'startDate', formatter: formatters.date },
                { text: 'Рискова група', value: 'riskGroupRule.name' },
                { text: 'Към амб. лист', value: 'ambulatorySheetNumber' },
                { text: 'МКБ код(ове)', value: 'icdCodes' },
                { text: 'Причина за отписване', value: 'riskGroupDeregisterReason.name' },
                { text: 'Дата на отписане', value: 'endDate', formatter: formatters.date },
                { text: '', value: 'actionButtons' }
            ];
        }

        private openNewRiskGroupDialog() {
            this.dialogIsVisible = true;
        }

        private openEditRiskGroupDialog(item: RiskGroupRegisterDto) {
            Object.assign(this.clone, item);
            this.dialogIsVisible = true;
        }

        private async saveRiskGroup() {
            try {
                (this.$refs.form as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    this.riskGroupModel = this.clone;
                    this.riskGroupModel.patientId = this.id;
                    if (this.riskGroupModel.id <= 0) {
                        await riskGroupRegisterService.create(this.riskGroupModel);
                    } else {
                        await riskGroupRegisterService.update(this.riskGroupModel);
                    }
                    this.$notifier.showSuccess('', `Успешен запис`);
                    await this.getTableItems();
                    this.closeRiskGroupDialog();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEntity() {
            this.$loading.show();
            try {
                await riskGroupRegisterService.delete(this.clone.id);
                await this.getTableItems();
            } finally {
                this.closeRiskGroupDialog();
                this.$loading.hide();
            }
        }

        private closeRiskGroupDialog() {
            this.clone = new RiskGroupRegisterDto();
            this.dialogIsVisible = false;
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                const response = await riskGroupRegisterService.getPatientRiskGroups(this.id);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            if (this.id) {
                await this.getTableItems();
            }
        }
    }
</script>

<style scoped>
    .v-icon.v-icon::after {
        background-color: transparent;
    }
</style>
