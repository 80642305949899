<template>
    <v-autocomplete
        :value="value"
        :loading="$loading.isVisible"
        :items="items"
        item-text="text"
        return-object
        label="Търсене на лице от регистъра"
        :no-data-text="noDataOrFilterTooShort"
        :search-input.sync="filter"
        :disabled="disabled"
        clearable
        dense
        no-filter
        append-icon="mdi-magnify"
        @input="onItemSelected"
        v-on="$listeners"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { ChoiceMakerDto } from '@/model/Patient/ChoiceMaker/ChoiceMakerDto';
    import { choiceMakerService } from '@/service/Patient/ChoiceMakerService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    const minimumFilterLength: number = 3;

    @Component
    export default class ChoiceMakerPicker extends Vue {
        @Prop()
        private value!: ChoiceMakerDto;

        @Prop()
        private disabled!: boolean;

        private loadingItemsForCurrentValue: boolean = false;
        private selectingItem: boolean = false;
        private items: ChoiceMakerDto[] = [];
        private filter: string = '';

        private mounted() {
            this.loadItemsForCurrentValue();
        }

        private loadItemsForCurrentValue() {
            if (this.value) {
                // Потиска следващия onSearch, за да не изпрати заявка за търсене на началния елемент.
                // Тази заявка, освен че е излишна, не намира нищо, защото търси по цялото описание на елемента.
                this.loadingItemsForCurrentValue = true;

                // Първоначално в списъка с елементи се добавя само началната стойност.
                this.items = [this.value as ChoiceMakerDto];
            }
        }

        private onItemSelected() {
            // Потиска следващите onValueChanged и onSearch, за да не изпрати заявка за търсене на току що избрания елемент.
            // Първо се изпълнява onValueChanged - този метод НЕ сваля selectingItem флага.
            // След това се изпълнява onSearch - този метод сваля selectingItem флага.
            this.selectingItem = true;

            // Cъбитието input се emit-ва автоматично заради v-on="$listeners",
            // което forward-ва всички събития, включително изрично прихванатите, като @input.
            //this.$emit('input', newValue);
        }

        @Watch('value')
        private onValueChanged() {
            if (this.selectingItem) {
                // След малко ще се изпълни и onSearch, което ще свали selectingItem флага.
                return;
            }

            this.loadItemsForCurrentValue();
        }

        @Watch('filter')
        private async onSearch() {
            if (this.loadingItemsForCurrentValue) {
                this.loadingItemsForCurrentValue = false;
                return;
            }

            if (this.selectingItem) {
                this.selectingItem = false;
                return;
            }

            if (this.filterIsLongEnough) {
                this.$loading.show();
                try {
                    // При редактиране на пациент се търси в неговата записана практика,
                    // при добавяне на пациент се търси в практиката, избрана от user context-а.
                    const practiceId = currentPatientCache.isLoaded
                        ? currentPatientCache.value.key.practiceId
                        : userContextCache.currentPracticeId;
                    if (practiceId) {
                        this.items = await choiceMakerService.searchChoiceMaker(this.filter, practiceId);
                    }
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private get noDataOrFilterTooShort() {
            return this.filterIsLongEnough
                ? this.$t('dropdown.noDataFound')
                : `Въведете поне ${minimumFilterLength} символа`;
        }

        private get filterIsLongEnough() {
            return this.filter && this.filter.length >= minimumFilterLength;
        }
    }
</script>
