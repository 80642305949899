class ServiceOrderGroupType {
    public name: string = '';
    public code: string | null = null;
}

const serviceOrderGroupTypes = [
    { name: 'Без групиране', code: 'none' },
    { name: 'Лекар', code: 'creditToEmployeeName' },
    { name: 'Обработил', code: 'createdByEmployeeName' },
    { name: 'Услуга', code: 'serviceName' },
    { name: 'Дата', code: 'createdOn' },
    { name: 'Месец', code: 'createdOnMonth' }
];
export { ServiceOrderGroupType, serviceOrderGroupTypes };
