import { AxiosResponse } from 'axios';

import { PracticeRoomDto } from '@/model/Practice/PracticeRoomDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const practiceRoomService = {
    getAllRooms(id: number): Promise<AxiosResponse<PracticeRoomDto[]>> {
        return httpService.get(`/PracticeRoom/GetAllRooms/${id}`);
    },
    save(data: PracticeRoomDto[]): Promise<AxiosResponse<void>> {
        return httpService.post('/PracticeRoom/Save', data);
    },
    deleteRoom(practiceId: number, seqNumber: number): Promise<AxiosResponse<void>> {
        return httpService.delete('/PracticeRoom/DeleteRoom', { params: { practiceId, seqNumber } });
    }
};
