<template>
    <Pie
        :responsive="true"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :width="width"
        :height="height"
        :chart-options="options"
    />
</template>

<script lang="ts">
    import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import { Pie } from 'vue-chartjs/legacy';
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ChartDataModel } from '@/model/Common/Chart/ChartDataModel';

    ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale, ChartDataLabels);

    @Component({ components: { Pie } })
    export default class PieChart extends Vue {
        @Prop()
        private chartData!: ChartDataModel;

        @Prop({ default: 'pie-chart' })
        private chartId!: string;

        @Prop({ default: 'label' })
        private datasetIdKey!: string;

        @Prop({ default: 400 })
        private width!: number;

        @Prop({ default: 400 })
        private height!: number;

        @Prop({ default: '' })
        private title!: string;

        // eslint-disable-next-line @typescript-eslint/typedef
        private options = {
            plugins: {
                legend: {
                    position: 'top'
                },
                title: {
                    display: true,
                    text: ''
                },
                datalabels: {
                    formatter(value: number) {
                        return `${value}%`;
                    },
                    display: 'auto',
                    color: 'white'
                }
            }
        };

        private mounted() {
            if (this.title) {
                this.options.plugins.title.text = this.title;
            }
        }
    }
</script>
