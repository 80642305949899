<template>
    <div>
        <NhisReferralExtractionEditor v-model="filter" @post-request="postRequest" />
        <NhisReferralExtractionResult
            :items="extractedReferrals"
            :is-reception="isReception"
            @input="onReferralSelect"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';
    import { NhisResponseWarningDto } from '@/model/Nhis/NhisResponseWarningDto';
    import { NhisExtractedReferralDto } from '@/model/Nhis/Referral/NhisExtractedReferralDto';
    import { NhisReferralExtractionCommand } from '@/model/Nhis/Referral/NhisReferralExtractionCommand';
    import { examDurationService } from '@/service/Exam/ExamDurationService';
    import { incomingReferralExamService } from '@/service/Exam/IncomingReferralExamService';
    import { nhisReferralService } from '@/service/Nhis/NhisReferralService';
    import { signXmlService } from '@/service/Report/SignXmlService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';
    import NhisReferralExtractionEditor from '@/view/Exam/Referral/ReferralExtraction/NhisReferralExtractionEditor.vue';
    import NhisReferralExtractionResult from '@/view/Exam/Referral/ReferralExtraction/NhisReferralExtractionResult.vue';

    @Component({
        components: { NhisReferralExtractionResult, NhisReferralExtractionEditor }
    })
    export default class IncomingReferralExtraction extends Vue {
        @Prop()
        patientId!: number;

        @Prop()
        creditToEmployeeSeqNumber!: number;

        @Prop({ default: false })
        isReception!: boolean;

        private filter: NhisReferralExtractionCommand = new NhisReferralExtractionCommand();
        private extractedReferrals: NhisExtractedReferralDto[] = [];

        private async getExtractionRequestXml(): Promise<string> {
            const result = await nhisReferralService.getXmlExtractionReferral(this.filter);
            console.log(result);
            return result ?? '';
        }

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private async postRequest() {
            this.$loading.show();
            try {
                this.extractedReferrals = [];
                const xmlString = await this.getExtractionRequestXml();
                const signResponse = await signXmlService.signXml(xmlString);
                if (!signResponse.isError && signResponse.contents && userContextCache.current?.specialtyCode) {
                    const extractionResult = await nhisReferralService.postReferralExtraction(
                        signResponse.contents,
                        userContextCache.current?.specialtyCode
                    );
                    console.log(extractionResult?.response);
                    if (extractionResult?.error?.length > 0) {
                        this.$notifier.showWarning('', extractionResult.error);
                    } else {
                        if (extractionResult?.referrals?.length > 0) {
                            this.extractedReferrals = extractionResult.referrals;
                            this.$emit('referral-extracted', extractionResult.referrals);
                        } else {
                            this.$notifier.showWarning('', 'Не е намерено активно направление по посочените данни');
                        }
                        if (extractionResult?.warnings?.length > 0) {
                            this.showWarnings(extractionResult.warnings);
                        }
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private showWarnings(warnings: NhisResponseWarningDto[]) {
            for (const warning of warnings) {
                this.$notifier.showWarning(
                    `Код предупреждение: ${warning.code}`,
                    `Описание: ${warning.description}; От: ${warning.source}`
                );
            }
        }

        private async onReferralSelect(item: NhisExtractedReferralDto) {
            item.practiceId = userContextCache.currentPracticeId;
            item.doctorSeqEmployeeNumber =
                this.creditToEmployeeSeqNumber ?? userContextCache.currentDoctorEmployeeSeqNumber;
            item.deputyDoctorId = userContextCache.current?.deputyDoctorId ?? null;
            item.deputyDoctorIsHired = userContextCache.current?.deputyDoctorIsHired ?? null;
            if (userSettingsState.userSettings.ui.useMinutesBetweenVisits) {
                item.examDateTime = await examDurationService.getNextExamTimeForToday();
            }
            const examId = await incomingReferralExamService.createVisitOrUpdateReferral(item);
            if (this.isReception) {
                this.$emit('visit-created', examId);
            } else {
                this.$router.push(`/Exam/AmbulatorySheet/Edit/${examId}`);
            }
        }

        private mounted() {
            if (!this.patientId) {
                currentPatientCache.clear();
            }
            currentVisitCache.clear();
            this.filter.pidTypeCode = PidTypeCode.Egn;
        }
    }
</script>
