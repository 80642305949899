<template>
    <div>
        <data-table
            :headers="headers"
            title="Прикачени документи към пациент"
            :loading="$loading.isVisible"
            :items="items"
        >
            <template #actions>
                <btn action="New" @click="openDialog" />
            </template>
            <template #rowActions="{ item }">
                <btn icon action="Download" @click="downloadFile(item)" />
                <btn icon action="Delete" @click="deletePatientFile(item)" />
            </template>
        </data-table>
        <v-row justify="center" class="mx-5">
            <v-dialog v-model="dialogIsVisible" persistent max-width="75%">
                <patient-file-upload :patient-id="patientId" @close="closeDialog" />
            </v-dialog>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { FileInfoDto } from '@/model/File/FileInfoDto';
    import { externalFileService } from '@/service/File/ExternalFileService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import { formatters } from '@/util/Formatters';
    import PatientFileUpload from '@/view/File/PatientFileUpload.vue';

    @Component({
        components: { PatientFileUpload }
    })
    export default class PatientFileView extends Vue {
        @Prop({ required: true })
        private patientId!: number;

        private items: FileInfoDto[] = [];
        private dialogIsVisible: boolean = false;

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Име на файл:', value: 'fileName' },
                { text: 'Добавен на:', value: 'uploadTime', formatter: formatters.dateTime },
                { text: 'Описание:', value: 'description' }
            ];
        }

        private async mounted() {
            await this.loadFiles();
        }

        private openDialog() {
            this.dialogIsVisible = true;
        }

        private async closeDialog() {
            await this.loadFiles();
            this.dialogIsVisible = false;
        }

        private async downloadFile(file: FileInfoDto) {
            this.$loading.show();
            try {
                await externalFileService.downloadFile(file.key);
            } finally {
                this.$loading.hide();
            }
        }

        private adjustMenuItemColor() {
            currentPatientCache.value.hasFiles = this.items.length > 0;
        }

        private async loadFiles() {
            this.$loading.show();
            try {
                this.items = await externalFileService.loadFilesForPatient(this.patientId);
                this.adjustMenuItemColor();
            } finally {
                this.$loading.hide();
            }
        }

        private async deletePatientFile(file: FileInfoDto) {
            this.$loading.show();
            try {
                await externalFileService.deletePatientFile(file.key);
                this.$notifier.showSuccess('', `Успешно изтрит файл: ${file.fileName}`);
                arrayUtil.removeFirst(this.items, file);
                this.adjustMenuItemColor();
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
