import { AxiosResponse } from 'axios';

import { Referral7Dto } from '@/model/Exam/Referral/Referral7Dto';
import { Referral7ViewDto } from '@/model/Exam/Referral/Referral7ViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referral7Service = {
    getReferral7(id: number): Promise<AxiosResponse<Referral7Dto>> {
        return httpService.get(`/Referral7/GetReferral7/${id}`);
    },

    createReferral7(data: Referral7Dto): Promise<AxiosResponse<Referral7Dto>> {
        return httpService.post('/Referral7/CreateReferral7', data);
    },

    updateReferral7(data: Referral7Dto): Promise<AxiosResponse<Referral7Dto>> {
        return httpService.put('/Referral7/UpdateReferral7/', data);
    },

    deleteReferral7(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/Referral7/DeleteReferral7/${id}`);
    },

    getReferral7InfoByExamId(examId: number): Promise<AxiosResponse<Referral7Dto>> {
        return httpService.get(`/Referral7/GetReferralInfoByExamId/${examId}`);
    },

    haveExistingReferral7(examId: number): Promise<AxiosResponse<boolean>> {
        return httpService.get(`/Referral7/HaveExistingReferral7/${examId}`);
    },

    getReferralsByExamId(examId: number): Promise<AxiosResponse<Referral7ViewDto[]>> {
        return httpService.get(`/Referral7/GetReferral7ViewsByExam`, {
            params: {
                examId
            }
        });
    }
};
