<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">Раздел VІ. Дейност на психиатричните структури</h3></v-col>
            <v-col cols="12"><h3 class="mb-2">5. Болни, включени в рехабилитация</h3></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><DataTableComponent v-model="value.psychiatricSick" :headers="headers" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Report365PsychiatricSickDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricSickDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class PsychiatricSick extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Report365PsychiatricSickDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: '', value: 'name' },
            { text: 'Шифър', value: 'code' },
            { text: 'Болнична рехабилитация', value: 'totalSickHospital' },
            { text: 'Извънболнична рехабилитация', value: 'totalSickOutHospital' }
        ];
    }
</script>
