<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Удостоверение" :items="items">
                <template #actions>
                    <btn action="New" :to="`/Exam/CertificateSocialServices/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/CertificateSocialServices/Edit/${item.id}`" />
                    <btn icon action="Print" :to="`/Print/Exam.Certificate.CertificateSocialServices/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { CertificateSocialServicesViewDto } from '@/model/Exam/Certificate/CertificateSocialServicesViewDto';
    import { certificateSocialServicesService } from '@/service/Exam/Certificate/CertificateSocialServicesService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class CertificateSocialServicesView extends Vue {
        @Prop()
        examId!: number;

        private items: CertificateSocialServicesViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Номер:', value: 'number' },
                { text: 'Номер рец. книжка:', value: 'prescriptionBookNumber' },
                { text: 'Диагнози:', value: 'diagnoses' },
                { text: 'Обективно състояние:', value: 'condition' },
                { text: 'Лекарствени продукти:', value: 'medicines' },
                { text: 'Препоръки:', value: 'recommendations' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await certificateSocialServicesService.getCertificatesByExamId(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
