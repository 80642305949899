<template>
    <btn action="CheckDocument" :disabled="disabled" @click="prescriptionBookCheck">Проверка за ЕРК</btn>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { nhifPrescriptionBookService } from '@/service/Nhif/NhifPrescriptionBookService';

    @Component
    export default class NhifPrescriptionBookCheckButton extends Vue {
        @Prop()
        disabled!: boolean;

        private async prescriptionBookCheck() {
            this.$loading.show();
            try {
                const prescriptionBookNumber = await nhifPrescriptionBookService.prescriptionBookCheck();
                this.$emit('input', prescriptionBookNumber);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
