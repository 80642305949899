import { plainToInstance } from 'class-transformer';

import { VisitResultDto } from '@/model/VisitList/VisitSearch/VisitResultDto';
import { VisitSearchCommand } from '@/model/VisitList/VisitSearch/VisitSearchCommand';
import { httpService } from '@/service/Infrastructure/HttpService';
import { userContextCache } from '@/store/User/UserContextCache';

// Добавя текущия UserContext към филтрите за търсене на посещения.
const fillEmployee = (command: VisitSearchCommand) => {
    const { current } = userContextCache;
    if (!current) {
        throw Error('Не е избрана месторабота.');
    }
    command.practiceId = current.practiceId;
    command.doctorEmployeeSeqNumber = current.employeeSeqNumber;
};

export const visitSearchService = {
    async searchVisit(command: VisitSearchCommand): Promise<VisitResultDto[]> {
        fillEmployee(command);
        const response = await httpService.post<VisitResultDto[]>('/VisitSearch/SearchVisit', command);
        return plainToInstance(VisitResultDto, response.data);
    },

    async exportVisit(command: VisitSearchCommand): Promise<void> {
        fillEmployee(command);
        await httpService.download('/VisitSearch/ExportVisit', { params: command });
    }
};
