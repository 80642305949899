<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Бланка за консултация при ТЕЛК" :items="items">
                <template #actions>
                    <btn action="New" :to="`/Exam/ConsultationTelk/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/ConsultationTelk/Edit/${item.id}`" />
                    <btn icon action="Print" :to="`/Print/Exam.ConsultationTelk/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ConsultationTelkViewDto } from '@/model/Exam/ConsultationTelk/ConsultationTelkViewDto';
    import { consultationTelkService } from '@/service/Exam/ConsultationTelkService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class ConsultationTelkView extends Vue {
        @Prop()
        examId!: number;

        private items: ConsultationTelkViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Номер:', value: 'number' },
                { text: 'Консултативен кабинет:', value: 'consultationCabinet' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await consultationTelkService.getConsultationTelksByExamId(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
