export class StagedEpicrisisBodyDto {
    public visitStartDateTime: Date = new Date();
    public diagnosis: string = '';
    public symptoms: string = '';
    public condition: string = '';
    public tests: string = '';
    public treatment: string = '';
    public consultation: string = '';
    public conclusion: string = '';
}
