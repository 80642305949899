import { Type } from 'class-transformer';

import { Report365PerformedTestRowDto } from '@/model/Report/Rhi/Report365/Report365PerformedTestRowDto';

export class Report365PerformedTestDto {
    public name: string = '';

    @Type(() => Report365PerformedTestRowDto)
    public performedTests: Report365PerformedTestRowDto[] = [];
}
