export enum NhifVisitReasonCode {
    AdultPrevention = 'AdultPrevention',
    AdultRiskPrevention = 'AdultRiskPrevention',
    ChildPrevention = 'ChildPrevention',
    Consultation = 'Consultation',
    Dispensary = 'Dispensary',
    Expertise = 'Expertise',
    HighSpecialized = 'HighSpecialized',
    MotherPrevention = 'MotherPrevention',
    Prescription = 'Prescription',
    Telk = 'Telk',
    Screening = 'Screening'
}
