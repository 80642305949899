import { NhifCommand } from '@/model/Nhif/NhifCommand';
import { NhifPrescriptionBookDiagnosisDto } from '@/model/Nhif/NhifPrescriptionBookDiagnosisDto';
import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

export class NhifDiagnosisRequestDto extends NhifCommand {
    constructor(
        public patientVersionKey: PatientVersionKey,
        public doctorUin: string | null,
        public doctorDeputyUin: string | null,
        public diagnosis: NhifPrescriptionBookDiagnosisDto
    ) {
        super(patientVersionKey, doctorUin, doctorDeputyUin);
    }
}
