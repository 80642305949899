<template>
    <div>
        <v-card>
            <v-card-title>
                <v-row dense>
                    <v-col cols="12" md="2">Сървър</v-col>
                    <v-col cols="12" md="2"><small>API</small></v-col>
                    <v-col cols="12" md="2">
                        <small>
                            <a
                                class="text--secondary"
                                :href="`${printApiBaseUrl}/Diagnostic/GetEnvironment`"
                                target="_blank"
                            >
                                Печат
                            </a>
                        </small>
                    </v-col>
                    <v-col cols="12" md="2">
                        <small>
                            <a
                                class="text--secondary"
                                :href="`${identityServerBaseUrl}/Diagnostic/${isAuthenticated ? 'Session' : 'Server'}`"
                                target="_blank"
                            >
                                IdentityServer
                            </a>
                        </small>
                    </v-col>
                    <v-col cols="12" md="4"><small>Пояснение</small></v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <v-row dense>
                    <v-col cols="12" md="2">Рестартиран на</v-col>
                    <v-col cols="12" md="2">
                        {{ apiEnvironment.startupTimeString }}
                        <value-loading :value="!apiEnvironment.startupTimeString"></value-loading>
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ printEnvironment.startupTimeString }}
                        <value-loading :value="!printEnvironment.startupTimeString"></value-loading>
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ idSrvEnvironment.startupTimeString }}
                        <value-loading :value="!idSrvEnvironment.startupTimeString"></value-loading>
                    </v-col>
                    <v-col cols="12" md="4">показва също сървърния формат на датата</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Обновен на</v-col>
                    <v-col cols="12" md="2"><date-label v-model="apiEnvironment.uploadTime" show-time /></v-col>
                    <v-col cols="12" md="2"><date-label v-model="printEnvironment.uploadTime" show-time /></v-col>
                    <v-col cols="12" md="2"><date-label v-model="idSrvEnvironment.uploadTime" show-time /></v-col>
                    <v-col cols="12" md="4">EntryAssembly.Location LastWriteTime</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Часовник app</v-col>
                    <v-col cols="12" md="2"><date-label v-model="apiEnvironment.localTime" show-time /></v-col>
                    <v-col cols="12" md="2"><date-label v-model="printEnvironment.localTime" show-time /></v-col>
                    <v-col cols="12" md="2"><date-label v-model="idSrvEnvironment.localTime" show-time /></v-col>
                    <v-col cols="12" md="4">сериализиран DateTime.Now</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Часовник база</v-col>
                    <v-col cols="12" md="2">
                        <date-label v-model="apiDbLocalTime" show-time />
                        <value-loading :value="!apiDbLocalTime"></value-loading>
                    </v-col>
                    <v-col cols="12" md="2">
                        <date-label v-model="printDbLocalTime" show-time />
                        <value-loading :value="!printDbLocalTime"></value-loading>
                    </v-col>
                    <v-col cols="12" md="2">
                        <date-label v-model="idSrvDbLocalTime" show-time />
                        <value-loading :value="!idSrvDbLocalTime"></value-loading>
                    </v-col>
                    <v-col cols="12" md="4">linq select DateTime.Now --> sql select getdate()</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Изходящ IP адрес</v-col>
                    <v-col cols="12" md="2">
                        {{ apiOutboundIp }}
                        <value-loading :value="!apiOutboundIp"></value-loading>
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ printOutboundIp }}
                        <value-loading :value="!printOutboundIp"></value-loading>
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ idSrvOutboundIp }}
                        <value-loading :value="!idSrvOutboundIp"></value-loading>
                    </v-col>
                    <v-col cols="12" md="4">
                        <a href="http://ipecho.net/plain" target="_blank">ipecho.net</a> (ще видите Вашето IP)
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2"></v-col>
                    <v-col cols="12" md="2"></v-col>
                    <v-col cols="12" md="2">
                        <template v-if="printStorageStats.sizeInKb > 10240">
                            {{ Math.round(printStorageStats.sizeInKb / 1024) }} MB
                        </template>
                        <template v-else>{{ printStorageStats.sizeInKb }} KB</template>
                        , {{ printStorageStats.count }} бр.
                    </v-col>
                    <v-col cols="12" md="2"></v-col>
                    <v-col cols="12" md="4">фрагменти за печат</v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <current-user-debug-info />

        <v-card>
            <v-card-title>SPA настройки</v-card-title>
            <v-card-text>
                <pre>{{ vueSpecificProcessEnv }}</pre>
            </v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import ValueLoading from '@/component/Common/ValueLoading.vue';
    import DateLabel from '@/component/Date/DateLabel.vue';
    import { config } from '@/Config';
    import { EnvironmentDto } from '@/model/DevTool/EnvironmentDto';
    import { PrintStorageStatsDto } from '@/model/DevTool/PrintStorageStatsDto';
    import { diagnosticService } from '@/service/DevTool/DiagnosticService';
    import { currentUser } from '@/store/User/CurrentUser';

    import CurrentUserDebugInfo from './CurrentUserDebugInfo.vue';

    @Component({
        components: { CurrentUserDebugInfo, DateLabel, ValueLoading }
    })
    export default class Diagnostic extends Vue {
        private apiEnvironment: EnvironmentDto = new EnvironmentDto();
        private apiDbLocalTime: Date | string = '';
        private apiOutboundIp: string = '';
        private printEnvironment: EnvironmentDto = new EnvironmentDto();
        private printDbLocalTime: Date | string = '';
        private printOutboundIp: string = '';
        private printStorageStats: PrintStorageStatsDto = new PrintStorageStatsDto();
        private idSrvEnvironment: EnvironmentDto = new EnvironmentDto();
        private idSrvDbLocalTime: Date | string = '';
        private idSrvOutboundIp: string = '';

        private mounted() {
            // Пускат се паралелно по 3-4 заявки към 3 сървъра.
            diagnosticService.getEnvironment(config.mainApiBaseUrl).then((value) => {
                this.apiEnvironment = value;
            });
            diagnosticService.getDbLocalTime(config.mainApiBaseUrl).then((value) => {
                this.apiDbLocalTime = value;
            });
            diagnosticService.getOutboundIp(config.mainApiBaseUrl).then((value) => {
                this.apiOutboundIp = value;
            });

            diagnosticService.getEnvironment(config.printApiBaseUrl).then((value) => {
                this.printEnvironment = value;
            });
            diagnosticService.getDbLocalTime(config.printApiBaseUrl).then((value) => {
                this.printDbLocalTime = value;
            });
            diagnosticService.getOutboundIp(config.printApiBaseUrl).then((value) => {
                this.printOutboundIp = value;
            });
            diagnosticService.getPrintStorageStats().then((value) => {
                this.printStorageStats = value;
            });

            diagnosticService.getEnvironment(config.identityServerBaseUrl).then((value) => {
                this.idSrvEnvironment = value;
            });
            diagnosticService.getDbLocalTime(config.identityServerBaseUrl).then((value) => {
                this.idSrvDbLocalTime = value;
            });
            diagnosticService.getOutboundIp(config.identityServerBaseUrl).then((value) => {
                this.idSrvOutboundIp = value;
            });
        }

        private get isAuthenticated() {
            return currentUser.isAuthenticated;
        }

        private get printApiBaseUrl() {
            return config.printApiBaseUrl;
        }

        private get identityServerBaseUrl() {
            return config.identityServerBaseUrl;
        }

        private get vueSpecificProcessEnv() {
            return JSON.stringify(process.env, null, ' ');
        }
    }
</script>
