<template>
    <div>
        <v-card>
            <main-title>Прикачване на документи към протокол</main-title>
            <v-card-text>
                <v-row v-for="(file, index) in value.documents" :key="index">
                    <v-col class="d-flex" cols="12">
                        <v-col cols="6">
                            <v-file-input
                                show-size
                                label="Файл:"
                                dense
                                :rules="[$validator.required]"
                                @change="onFileInput($event, index)"
                                @click:clear="clearFile(index)"
                            />
                        </v-col>
                        <v-col cols="3">
                            <dropdown
                                v-model="file.externalDocumentTypeCode"
                                class="required"
                                item-text="code"
                                item-value="code"
                                :items="nhifProtocolUploadDocumentTypes"
                                required
                                label="Формат на файла"
                            />
                        </v-col>
                        <btn v-if="value.length > 1" icon action="Delete" @click="removeFile(index)" />
                    </v-col>
                </v-row>
                <v-icon @click="addFileInput">mdi-plus</v-icon>
            </v-card-text>
        </v-card>
        <v-card-actions>
            <v-row>
                <v-col cols="2" class="px-1">
                    <btn action="Send" :disabled="isLoading" @click="uploadFiles" />
                </v-col>
            </v-row>
        </v-card-actions>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhifProtocolDocumentDto } from '@/model/Exam/DrugProtocol/NhifProtocolDocumentDto';
    import {
        NhifProtocolDocumentUploadType,
        nhifProtocolDocumentUploadType
    } from '@/model/Exam/DrugProtocol/NhifProtocolDocumentUploadType';
    import { NhifProtocolUploadCommand } from '@/model/Exam/DrugProtocol/NhifProtocolUploadCommand';
    import { nhifIntegrationService } from '@/service/Integration/NhifIntegrationService';
    import { nhifProtocolService } from '@/service/Nhif/NhifProtocolService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component({})
    export default class NhifProtocolUploadDocument extends Vue {
        @Prop()
        nrnProtocol!: string | null;

        private value: NhifProtocolUploadCommand = new NhifProtocolUploadCommand();
        private nhifProtocolUploadDocumentTypes: NhifProtocolDocumentUploadType[] = nhifProtocolDocumentUploadType;

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private addFileInput(): void {
            this.value.documents.push(new NhifProtocolDocumentDto());
        }

        private removeFile(index: number) {
            arrayUtil.removeAt(this.value.documents, index);
        }

        private clearFile(index: number) {
            this.value.documents[index] = new NhifProtocolDocumentDto();
        }

        private async onFileInput(file: File, index: number) {
            if (file) {
                this.$loading.show();
                const currentFile = this.value.documents[index];
                const [, content] = ((await this.toBase64(file)) as string).split(',');
                currentFile.content = content;
                currentFile.name = file.name;
                this.$loading.hide();
            }
        }

        private toBase64(file: File) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        }

        private async uploadFiles() {
            this.$loading.show();
            try {
                this.value.patientVersionKey = currentPatientCache.value.key;
                this.value.practiceId = userContextCache.currentPracticeId ?? 0;
                this.value.doctorUin = userContextCache.current?.doctorUin ?? '';
                this.value.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
                this.value.nrnProtocol = this.nrnProtocol ?? '';
                this.value.doctorEmployeeSeqNumber = userContextCache.current?.employeeSeqNumber ?? 0;
                this.value.deputyDoctorIsHired = userContextCache.current?.deputyDoctorUin
                    ? userContextCache.current?.deputyDoctorIsHired
                    : null;

                const jsonResponse = await nhifProtocolService.getProtocolUploadDocumentsJson(this.value);
                if (jsonResponse.data) {
                    const result = await nhifIntegrationService.postProtocolUploadDocuments(jsonResponse.data);
                    if (result?.data) {
                        this.$notifier.showSuccess('', 'Успешно прикачени файлове.');
                    } else {
                        this.$notifier.showWarning('', result.data);
                    }
                }
            } finally {
                this.value.documents = [];
                this.$loading.hide();
            }
        }

        private mounted() {
            if (this.value?.documents?.length < 1) {
                this.addFileInput();
            }
        }
    }
</script>
