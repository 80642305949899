import { AxiosResponse } from 'axios';

import { PisFileTypeCode } from '@/enum/Report/Pis/PisFileTypeCode';
import { MonthlyNotificationResponseDto } from '@/model/Invoice/Pis/MonthlyNotificationResponseDto';
import { PisFileRequestDto } from '@/model/Report/Pis/PisFile/PisFileRequestDto';
import { PisFileResponseDto } from '@/model/Report/Pis/PisFile/PisFileResponseDto';
import { PisFileUploadRequestDto } from '@/model/Report/Pis/PisFileUploadRequestDto';
import { localServerHttpService } from '@/service/Infrastructure/LocalServerHttpService';
import { pisFileTypeService } from '@/service/Nomenclature/PisFileTypeService';

export const localServerPisService = {
    checkDocumentStatus(
        pisFileId: string,
        practiceNumber: string,
        userId: string
    ): Promise<AxiosResponse<PisFileResponseDto>> {
        const data = new PisFileRequestDto(pisFileId, practiceNumber, userId);
        return localServerHttpService.post('/nhif/checkpisreport', data);
    },

    getMonthlyNotification(
        egn: string,
        endDate: Date | null,
        practiceCode: string
    ): Promise<AxiosResponse<MonthlyNotificationResponseDto>> {
        console.log(egn, endDate, practiceCode);
        return localServerHttpService.post('/nhif/listnotifications', {
            egn,
            endDate,
            practiceCode
        });
    },

    async uploadReport(
        pisFileTypeCode: PisFileTypeCode,
        data: PisFileUploadRequestDto,
        pisUploadType: string
    ): Promise<PisFileResponseDto> {
        // Пример: По 'ExamClaim' връща 'AMB_IMP_R'.
        const response = await pisFileTypeService.getPisFileTypeNhifCodeByCode(pisFileTypeCode);
        if (response?.data) {
            data.purpose = response.data;
            const pisResponse = await localServerHttpService.post(`/nhif/${pisUploadType}`, data);
            return pisResponse.data;
        }

        const invalidCodeResponse = new PisFileResponseDto();
        invalidCodeResponse.isError = true;
        invalidCodeResponse.message = `Невалиден вид файл ${pisFileTypeCode}.`;
        return invalidCodeResponse;
    }
};
