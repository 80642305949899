import { Route, RouteConfig } from 'vue-router';

import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';

export const examPrescriptionV2Routes: RouteConfig[] = [
    {
        path: 'PrescriptionV2Nhif5/Create/:examId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), 0),
                formType: PrescriptionFormTypeCode.Nhif5
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifV2.vue')
        }
    },
    {
        path: 'PrescriptionV2Nhif5/Edit/:examId/:documentId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), Number(route.params.documentId)),
                formType: PrescriptionFormTypeCode.Nhif5
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifV2.vue')
        }
    },
    {
        path: 'PrescriptionV2Nhif5/:examId',
        props: {
            exam: (route: Route) => ({
                examId: Number(route.params.examId),
                formType: PrescriptionFormTypeCode.Nhif5
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifV2View.vue')
        }
    },
    {
        path: 'PrescriptionV2Nhif5a/Create/:examId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), 0),
                formType: PrescriptionFormTypeCode.Nhif5a
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifV2.vue')
        }
    },
    {
        path: 'PrescriptionV2Nhif5a/Edit/:examId/:documentId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), Number(route.params.documentId)),
                formType: PrescriptionFormTypeCode.Nhif5a
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifV2.vue')
        }
    },
    {
        path: 'PrescriptionV2Nhif5a/:examId',
        props: {
            exam: (route: Route) => ({
                examId: Number(route.params.examId),
                formType: PrescriptionFormTypeCode.Nhif5a
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifV2View.vue')
        }
    },
    {
        path: 'PrescriptionV2Nhif5b/Create/:examId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), 0),
                formType: PrescriptionFormTypeCode.Nhif5b
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifV2.vue')
        }
    },
    {
        path: 'PrescriptionV2Nhif5b/Edit/:examId/:documentId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), Number(route.params.documentId)),
                formType: PrescriptionFormTypeCode.Nhif5b
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifV2.vue')
        }
    },
    {
        path: 'PrescriptionV2Nhif5b/:examId',
        props: {
            exam: (route: Route) => ({
                examId: Number(route.params.examId),
                formType: PrescriptionFormTypeCode.Nhif5b
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifV2View.vue')
        }
    },
    {
        path: 'PrescriptionV2Nhif5c/Create/:examId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), 0),
                formType: PrescriptionFormTypeCode.Nhif5c
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifV2.vue')
        }
    },
    {
        path: 'PrescriptionV2Nhif5c/Edit/:examId/:documentId',
        props: {
            exam: (route: Route) => ({
                prescriptionKey: new PrescriptionKey(Number(route.params.examId), Number(route.params.documentId)),
                formType: PrescriptionFormTypeCode.Nhif5c
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifV2.vue')
        }
    },
    {
        path: 'PrescriptionV2Nhif5c/:examId',
        props: {
            exam: (route: Route) => ({
                examId: Number(route.params.examId),
                formType: PrescriptionFormTypeCode.Nhif5c
            })
        },
        components: {
            exam: () => import('@/view/Exam/PrescriptionNhif/PrescriptionNhifV2View.vue')
        }
    }
];
