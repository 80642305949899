import { AxiosResponse } from 'axios';

import { RhifDto } from '@/model/Nomenclature/RhifDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const rhifService = {
    getRhifs(): Promise<AxiosResponse<RhifDto[]>> {
        return httpService.get('/Rhif/GetRhifs');
    },

    getRhifById(id: number): Promise<AxiosResponse<RhifDto>> {
        return httpService.get(`/Rhif/GetRhifById/${id}`);
    },

    createRhif(data: RhifDto): Promise<AxiosResponse<RhifDto>> {
        return httpService.post('/Rhif/CreateRhif', data);
    },

    updateRhif(data: RhifDto): Promise<AxiosResponse<RhifDto>> {
        return httpService.put('/Rhif/UpdateRhif/', data);
    }
};
