export enum ReferralTypeCode {
    Referral3 = 'Referral3',
    Referral3a = 'Referral3a',
    Referral4 = 'Referral4',
    Referral6 = 'Referral6',
    Referral7 = 'Referral7',
    Referral8 = 'Referral8',
    Referral8a = 'Referral8a',
    ReferralDispObserv = 'ReferralDispObserv',
    ReferralTelk = 'ReferralTelk'
}
