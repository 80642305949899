import { Type } from 'class-transformer';

import { DoctorEditDto } from '@/model/Doctor/DoctorEditDto';

export class PreviousGpDto {
    @Type(() => DoctorEditDto)
    public doctor: DoctorEditDto = new DoctorEditDto();

    public practiceIsCustomer: boolean = false;
    public practiceName: string = '';
    public practiceNumber: string = '';
    // Не се записва в базата. Само подпомага избора на здравен район.
    public lookupRhifId: number | null = null;
    public rhifAreaId: number | null = null;
    public practiceCityId: number | null = null;
    public practiceAddress: string = '';
}
