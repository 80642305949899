<template>
    <dropdown
        :value="value"
        :items="practiceFunds"
        item-value="code"
        :required="isRequired"
        label="Заплаща се от"
        @input="$emit('input', ...arguments)"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { PracticeFundDto } from '@/model/Practice/PracticeFundDto';
    import { practiceFundService } from '@/service/Practice/PracticeFundService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class FundPicker extends Vue {
        @Prop({ required: true })
        private value!: string;

        @Prop({ default: true })
        private isRequired!: boolean;

        private practiceFunds: PracticeFundDto[] = [];
        private panelIndex: number = 0;

        private async getPracticeFunds() {
            const response = await practiceFundService.getActiveFunds(
                userContextCache.currentPracticeId ?? 0,
                this.value,
                !this.isRequired
            );
            if (response?.data) {
                this.practiceFunds = response.data;
            }
        }

        @Watch('value', { immediate: true })
        private async onValueChange() {
            await this.getPracticeFunds();
        }
    }
</script>
