import { AxiosResponse } from 'axios';

import { CertificateWorkDto } from '@/model/Exam/Certificate/CertificateWorkDto';
import { CertificateWorkViewDto } from '@/model/Exam/Certificate/CertificateWorkViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const certificateWorkService = {
    getCertificateWorkInfoByExamId(examId: number): Promise<AxiosResponse<CertificateWorkDto>> {
        return httpService.get(`/CertificateWork/GetCertificateWorkInfoByExamId/${examId}`);
    },

    getCertificateWork(id: number): Promise<AxiosResponse<CertificateWorkDto>> {
        return httpService.get(`/CertificateWork/GetCertificateWork/${id}`);
    },

    createCertificateWork(data: CertificateWorkDto): Promise<AxiosResponse<CertificateWorkDto>> {
        return httpService.post('/CertificateWork/CreateCertificateWork', data);
    },

    updateCertificateWork(data: CertificateWorkDto): Promise<AxiosResponse<CertificateWorkDto>> {
        return httpService.put('/CertificateWork/UpdateCertificateWork/', data);
    },

    deleteCertificateWork(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/CertificateWork/DeleteCertificateWork/${id}`);
    },

    canSaveCertificateWork(data: CertificateWorkDto): Promise<AxiosResponse<string[]>> {
        return httpService.post('/CertificateWork/CanSaveCertificateWork', data);
    },

    getCertificatesByExamId(examId: number): Promise<AxiosResponse<CertificateWorkViewDto[]>> {
        return httpService.get(`/CertificateWork/GetCertificateWorkViewsByExam`, {
            params: { examId }
        });
    }
};
