<template>
    <div>
        <v-row>
            <v-col>
                <data-table :headers="headers" :title="`${title} рецепта`" :items="items">
                    <template #actions>
                        <btn action="New" :to="`/Exam/PrescriptionNhif${formType}/Create/${examId}`" />
                        <btn action="Send" @click="checkPrescriptions">Проверка на рецепти</btn>
                    </template>
                    <template #rowActions="{ item }">
                        <btn icon action="Edit" :to="`/Exam/PrescriptionNhif${formType}/Edit/${examId}/${item.id}`" />
                        <btn v-if="canSendForm && !item.nrn" icon action="Send" @click="postPrescription(item)" />
                        <btn
                            v-if="isWhite"
                            icon
                            action="Print"
                            :to="`/Print/Exam.Prescription.PrescriptionNhif${formType}/Prescription/${examId}/${item.id}`"
                        />
                    </template>
                </data-table>
            </v-col>
        </v-row>
        <nhis-prescription-group-check ref="groupCheck" :exam-id="examId" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
    import { PrescriptionNhifViewDto } from '@/model/Exam/Prescription/PrescriptionNhifViewDto';
    import { PrescriptionNhifWhiteViewDto } from '@/model/Exam/Prescription/PrescriptionNhifWhiteViewDto';
    import { prescriptionService } from '@/service/Exam/PrescriptionService';
    import { nhisPrescriptionService } from '@/service/Nhis/NhisPrescriptionService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { formatters } from '@/util/Formatters';
    import NhisPrescriptionGroupCheck from '@/view/Nhis/Prescription/NhisPrescriptionGroupCheck.vue';

    @Component
    export default class PrescriptionNhifWhiteView extends Vue {
        @Prop({ required: true })
        examId!: number;

        @Prop()
        formType!: PrescriptionFormTypeCode;

        private items: PrescriptionNhifWhiteViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Лекарства:', value: 'drugs' },
                { text: 'НРН:', value: 'nrn' },
                { text: 'Статус:', value: 'status' },
                { text: 'Дата на проверка:', value: 'statusTime', formatter: formatters.dateTime },
                { text: 'Дата на изпълнение:', value: 'executionDate', formatter: formatters.dateTime },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private get title() {
            return this.formType === PrescriptionFormTypeCode.Green
                ? 'Зелена'
                : this.formType === PrescriptionFormTypeCode.Yellow
                  ? 'Жълта'
                  : 'Бяла';
        }

        private get canSendForm() {
            return (
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private async checkPrescriptions() {
            await (this.$refs.groupCheck as NhisPrescriptionGroupCheck).checkPrescriptions();
            await this.loadItems();
        }

        private async postPrescription(item: PrescriptionNhifViewDto) {
            this.$loading.show();
            try {
                if (!item.statusCode) {
                    const response = await nhisPrescriptionService.postPrescription(
                        new PrescriptionKey(this.examId, item.id),
                        this.formType
                    );
                    if (response) {
                        this.$notifier.showSuccess('', `Успешно изпратен документ с НРН: ${response.nrn}`);
                        await this.loadItems();
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private get isWhite() {
            return this.formType === PrescriptionFormTypeCode.White;
        }

        private async loadItems() {
            this.$loading.show();
            try {
                this.items = await prescriptionService.getPrescriptionWhiteByExamId(this.examId, this.formType);
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
