import { GenderCode } from '@/enum/Nomenclature/GenderCode';

export class AnthropometricNormDto {
    public id: number = 0;
    public genderCode: GenderCode | null = null;
    public age: string | null = '';
    public headNorm: number | null = null;
    public headDeviation: number | null = null;
    public heightNorm: number | null = null;
    public heightDeviation: number | null = null;
    public weightNorm: number | null = null;
    public weightDeviation: number | null = null;
    public chestPauseNorm: number | null = null;
    public chestPauseDeviation: number | null = null;
    public chestInhalationNorm: number | null = null;
    public chestInhalationDeviation: number | null = null;
    public chestExhalationNorm: number | null = null;
    public chestExhalationDeviation: number | null = null;
}
