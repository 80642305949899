import Vue from 'vue';

import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';
import { specialtyService } from '@/service/Nomenclature/SpecialtyService';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

const loadingItem = new SpecialtyDto();
loadingItem.name = 'Зареждане...';
const loadErrorItem = new SpecialtyDto();
loadErrorItem.name = 'Грешка при зареждане';

class SpecialtyCache extends ArrayCacheBase<SpecialtyDto> {
    constructor() {
        super(loadingItem, loadErrorItem);
    }

    protected async load() {
        const response = await specialtyService.getSpecialties();
        this._items = response.data;
    }
}

export const specialtyCache = Vue.observable(new SpecialtyCache());
