<template>
    <print-component :template="template" :parameters="parameters" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import PrintComponent from './PrintComponent.vue';

    @Component({
        components: { PrintComponent }
    })
    export default class Print extends Vue {
        @Prop({ required: true })
        private template!: string;

        @Prop({ required: true })
        private parameters!: unknown;
    }
</script>
