import { Type } from 'class-transformer';

import { RhiImmunizationReportDetailDto } from '@/model/Report/Rhi/Immunization/RhiImmunizationReportDetailDto';
import { dateUtil } from '@/util/DateUtil';

export class RhiImmunizationReportDto {
    public startDate: Date = new Date();
    public endDate: Date = new Date();

    @Type(() => RhiImmunizationReportDetailDto)
    public immunizations: RhiImmunizationReportDetailDto[] = [];

    public fillThisMonth(): void {
        this.startDate = dateUtil.startOfThisMonth();
        this.endDate = dateUtil.endOfThisMonth();
    }
}
