import { plainToInstance } from 'class-transformer';

import { MedicalNoteTreatmentLocationDto } from '@/model/Nomenclature/MedicalNoteTreatmentLocationDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const medicalNoteTreatmentLocationService = {
    async getMedicalNoteTreatmentLocation(): Promise<MedicalNoteTreatmentLocationDto[]> {
        const response = await httpService.get<MedicalNoteTreatmentLocationDto[]>(
            '/MedicalNoteTreatmentLocation/GetMedicalNoteTreatmentLocations'
        );
        return plainToInstance(MedicalNoteTreatmentLocationDto, response?.data);
    }
};
