export class PersonalInfoDto {
    public workplaceId: number | null = null;
    public workplaceDate: Date | null = null;
    public socialStatus: string | null = null;
    public citizenship: string | null = null;
    public marriageYears: number | null = null;
    public husbandYears: number | null = null;
    public illness: string | null = null;
    public livingConditions: string | null = null;
    public familyBurden: string | null = null;
    public dispensaryDate: Date | null = null;
    public pregnancyPeriod: number | null = null;
    public issueDate: Date | null = null;
    public lastMenstruation: Date | null = null;
    public firstMove: Date | null = null;
    public expectedBirth: Date | null = null;
    public pregnancyCount: number | null = null;
}
