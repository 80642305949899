<template>
    <v-card>
        <v-card-title class="no-margin-no-padding">Данни за е-преглед</v-card-title>
        <v-card-text class="no-margin-no-padding">
            <v-row v-if="value" no-gutters>
                <v-col md="3" class="px-1">
                    <text-field
                        v-model="value.nrn"
                        class="no-margin-no-padding"
                        :class="nrnBackground"
                        label="НРН номер"
                        readonly
                    ></text-field>
                </v-col>
                <v-col md="3" class="px-1">
                    <text-field
                        v-model="value.statusName"
                        class="no-margin-no-padding"
                        label="Статус на преглед"
                        readonly
                    ></text-field>
                </v-col>
                <v-col md="3" class="px-1">
                    <date-field
                        v-model="value.statusDate"
                        class="no-margin-no-padding"
                        label="Дата на изпращане"
                        disabled
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="no-margin pt-0">
            <v-row dense class="no-margin-no-padding">
                <v-col md="3" cols="6">
                    <v-menu open-on-hover bottom offset-y pointer>
                        <template #activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <btn action="Send" block v-bind="attrs" v-on="on">НЗИС</btn>
                            </div>
                        </template>
                        <v-list>
                            <v-list-item v-if="canOpen" :disabled="disable || isLoading" @click="postOpeningExam()">
                                <v-list-item-title>Отваряне</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="canClose" :disabled="disable || isLoading" @click="postClosingExam()">
                                <v-list-item-title>Затваряне без подпис пац.</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="canEdit"
                                :disabled="disable || isLoading"
                                @click="postCorrectionExam(false)"
                            >
                                <v-list-item-title>Редактиране без подпис пац.</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="canCancel" :disabled="disable || isLoading" @click="postSickSheets()">
                                <v-list-item-title>Изпращане на болнични</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="canCancel" :disabled="disable || isLoading" @click="postCancelExam()">
                                <v-list-item-title>Анулиране</v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="disable || isLoading" @click="dialogIsVisible = true">
                                <v-list-item-title>Проверка за хоспитализация</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="canSendAll" :disabled="disable || isLoading" @click="sendAll()">
                                <v-list-item-title>Изпращане на всички бланки без подпис пац.</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="canOpen && isDevOrTest"
                                :disabled="disable || isLoading"
                                @click="postOpeningExamV3()"
                            >
                                <v-list-item-title>Отваряне-V3</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="canClose && isDevOrTest"
                                :disabled="disable || isLoading"
                                @click="postClosingExamV3()"
                            >
                                <v-list-item-title>Затваряне без подпис пац. - V3</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="canEdit && isDevOrTest"
                                :disabled="disable || isLoading"
                                @click="postCorrectionExamV3(false)"
                            >
                                <v-list-item-title>Редактиране без подпис пац. - V3</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="canCancel && isDevOrTest"
                                :disabled="disable || isLoading"
                                @click="postSickSheetsV3()"
                            >
                                <v-list-item-title>Изпращане на болнични - V3</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="canCancel && isDevOrTest"
                                :disabled="disable || isLoading"
                                @click="postCancelExamV3()"
                            >
                                <v-list-item-title>Анулиране - V3</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
                <v-col md="3" cols="6">
                    <!--<v-btn class="primary" @click="printChoiceForm">Печат на бланка за избор</v-btn>-->
                    <v-menu open-on-hover bottom offset-y pointer>
                        <template #activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <btn action="Print" block v-bind="attrs" v-on="on">Разширен печат</btn>
                            </div>
                        </template>
                        <v-list>
                            <v-list-item @click="printAllNrnWithoutPreview()">
                                <v-list-item-title>Печат всички НРН</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printForLAKWithoutPreview()">
                                <v-list-item-title>За ЛАК</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printInsuranceStatusWithoutPreview()">
                                <v-list-item-title>Осигурителен статус</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printAllNrn()">
                                <v-list-item-title>Печат всички НРН - Преглед</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printForLAK()">
                                <v-list-item-title>За ЛАК - Преглед</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printInsuranceStatus()">
                                <v-list-item-title>Осигурителен статус - Преглед</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
                <v-col md="3" cols="6">
                    <btn action="Copy" block :disabled="isLoading" @click="copyExam"> Копиране на АЛ</btn>
                </v-col>
            </v-row>
        </v-card-actions>
        <nhis-group-upload ref="groupUpload" :exam-id="examId" :exam-date="examDate" @input="change" />
        <HospitalizationExtraction :extraction-dialog-is-visible="dialogIsVisible" @close="dialogIsVisible = false" />
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { config } from '@/Config';
    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { NhisExamDto } from '@/model/Nhis/Exam/NhisExamDto';
    import { NhisXmlRequestDto } from '@/model/Nhis/NhisXmlRequestDto';
    import { nhisExamService } from '@/service/Nhis/NhisExamService';
    import { nhisExamV3Service } from '@/service/Nhis/NhisExamV3Service';
    import { nhisService } from '@/service/Nhis/NhisService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import HospitalizationExtraction from '@/view/Nhis/Hospitalization/HospitalizationExtraction.vue';
    import NhisGroupUpload from '@/view/Query/Nhis/NhisGroupUpload.vue';

    @Component({
        components: { NhisGroupUpload, HospitalizationExtraction }
    })
    export default class NhisExam extends Vue {
        @Prop({ type: Object })
        private value!: NhisExamDto;

        @Prop()
        private examDate!: Date;

        @Prop()
        private examId!: number;

        @Prop()
        private disable!: boolean;

        private dialogIsVisible: boolean = false;

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private get isDevOrTest() {
            return config.isDevOrTest;
        }

        private printAllNrn() {
            if (this.examId) {
                this.$router.push(`/Print/Exam.AllExamNrn/${this.examId}`);
            }
        }

        private async printAllNrnWithoutPreview() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.examId.toString();
                const report = 'Exam.AllExamNrn';

                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private printForLAK() {
            if (this.examId) {
                this.$router.push(`/Print/Exam.ExamDataForLAK/${this.examId}`);
            }
        }

        private async printForLAKWithoutPreview() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.examId.toString();
                const report = 'Exam.ExamDataForLAK';

                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private printInsuranceStatus() {
            if (currentPatientCache.value.insuranceStatus) {
                this.$router.push(
                    `/Print/Patient.InsuranceStatus/${currentPatientCache.value.insuranceStatus?.id ?? 0}`
                );
            }
        }

        private async printInsuranceStatusWithoutPreview() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = `${currentPatientCache.value.insuranceStatus?.id ?? 0}`;
                const report = 'Patient.InsuranceStatus';

                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        public async postOpeningExam() {
            const response = await nhisExamService.postOpeningExam(this.requestData());
            if (response) {
                await currentVisitCache.updateNhisExam(response.nrn, response.statusCode);
                this.change(response);
            }
        }

        private async postClosingExam() {
            const response = await nhisExamService.postClosingExam(this.requestData());
            if (response) {
                this.change(response);
            }
        }

        private async postCorrectionExam(usePatientSignature: boolean) {
            const request = this.requestData();
            request.usePatientSignature = usePatientSignature;
            const response = await nhisExamService.postCorrectionExam(request);
            if (response) {
                this.change(response);
            }
        }

        private async postSickSheets() {
            const response = await nhisExamService.postSickSheets(this.requestData());
            if (response) {
                this.change(response);
            }
        }

        private async postCancelExam() {
            const response = await nhisExamService.postCancelExam(this.requestData());
            if (response) {
                await currentVisitCache.updateNhisExam(response.nrn, response.statusCode);
                this.change(response);
            }
        }

        private async sendAll() {
            await (this.$refs.groupUpload as NhisGroupUpload).sendForms();
            this.$emit('reload-patient-condition');
        }

        private copyExam() {
            currentPatientCache.loadLastVersion(currentVisitCache.value.patientVersionKey.patientId);
            this.$router.push(`/Exam/AmbulatorySheet/Copy/${this.examId}`);
        }

        private get canSendAll() {
            return this.examId > 0 && this.value?.statusCode !== NhisExamStatusCode.Canceled;
        }

        private get canOpen() {
            return this.examId > 0 && !this.value?.statusCode;
        }

        private get canClose() {
            return this.examId > 0 && this.value?.statusCode === NhisExamStatusCode.Open;
        }

        private get canEdit() {
            return (
                this.examId > 0 &&
                (this.value?.statusCode === NhisExamStatusCode.Closed ||
                    this.value?.statusCode === NhisExamStatusCode.ClosedToBeEdited)
            );
        }

        private get canCancel() {
            return (
                this.value?.statusCode?.length > 0 &&
                (this.value?.statusCode === NhisExamStatusCode.Open ||
                    this.value?.statusCode === NhisExamStatusCode.Closed ||
                    this.value?.statusCode === NhisExamStatusCode.ClosedToBeEdited)
            );
        }

        private get nrnBackground() {
            return this.value.statusCode === NhisExamStatusCode.Open
                ? 'text-field-info'
                : this.value.statusCode === NhisExamStatusCode.Closed
                  ? 'text-field-success'
                  : this.value.statusCode === NhisExamStatusCode.Canceled
                    ? 'text-field-error'
                    : this.value.statusCode === NhisExamStatusCode.ClosedToBeEdited
                      ? 'text-field-warning'
                      : '';
        }

        private change(data: NhisExamDto) {
            this.$emit('input', data);
        }

        private requestData(): NhisXmlRequestDto {
            return nhisService.requestData(this.examId, null);
        }

        public async postOpeningExamV3() {
            const response = await nhisExamV3Service.postOpeningExam(this.requestData());
            if (response) {
                await currentVisitCache.updateNhisExam(response.nrn, response.statusCode);
                this.change(response);
            }
        }

        private async postClosingExamV3() {
            const response = await nhisExamV3Service.postClosingExam(this.requestData());
            if (response) {
                this.change(response);
            }
        }

        private async postCorrectionExamV3(usePatientSignature: boolean) {
            const request = this.requestData();
            request.usePatientSignature = usePatientSignature;
            const response = await nhisExamV3Service.postCorrectionExam(request);
            if (response) {
                this.change(response);
            }
        }

        private async postSickSheetsV3() {
            const response = await nhisExamV3Service.postSickSheets(this.requestData());
            if (response) {
                this.change(response);
            }
        }

        private async postCancelExamV3() {
            const response = await nhisExamV3Service.postCancelExam(this.requestData());
            if (response) {
                await currentVisitCache.updateNhisExam(response.nrn, response.statusCode);
                this.change(response);
            }
        }
    }
</script>
