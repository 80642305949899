export class ExamDataDto {
    public height: number | null = null;
    public weight: number | null = null;
    public musculoskeletalNormal: boolean = false;
    public pathologicDeviations: string | null = null;
    public dSp: number | null = null;
    public dTr: number | null = null;
    public cExt: number | null = null;
    public cDiag: number | null = null;
    public cVera: number | null = null;
    public systolic: number | null = null;
    public diastolic: number | null = null;
    public externalGenitalia: string | null = null;
    public vagina: string | null = null;
    public exUtero: string | null = null;
    public pvcu: string | null = null;
    public oecc: string | null = null;
    public cc: string | null = null;
    public uterus: string | null = null;
    public otherDeviations: string | null = null;
    public abo: string | null = null;
    public rh: string | null = null;
    public obstetricRisk: boolean = false;
}
