import { Type } from 'class-transformer';

import { PrescriptionBaseDto } from '@/model/Exam/Prescription/PrescriptionBaseDto';
import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';

export class PrescriptionNhifV2Dto extends PrescriptionBaseDto {
    // Данни попълвани от лекаря
    public protocolTypeCode: string = '';
    public protocolNumber: string = '';
    public protocolDays: number | null = null;
    public protocolDateTime: Date | null = null;

    // Ном. на рецептурна книжка
    public prescriptionBookNumber: string = '';
    public isPregnant: boolean = false;
    public isNursing: boolean = false;

    // Код на лекарствен продукт
    @Type(() => NhifDrugDto)
    public nhifDrugs: NhifDrugDto[] = [];

    // Код на лекарствен продукт
    @Type(() => NhifDrugDto)
    public nhifDrugsB: NhifDrugDto[] = [];

    // Код на лекарствен продукт
    @Type(() => NhifDrugDto)
    public nhifDrugsC: NhifDrugDto[] = [];

    public supplements: string | null = null;
    public ambulatorySheetNumberNumber: string = '';
    public totalCostPatient: number = 0;

    //Данни за лекаря
    public doctorPhoneNumber: string = '';

    //Данни от е-протокола
    public protocolDrugs: NhifDrugDto[] | null = null;
    // Към 2023-03 изглежда не се използва, но се връща от API-то.
    public doctorSpecialtyNhifCode: string = '';
}
