<template>
    <v-card>
        <v-card-title>Токен за {{ externalSystemName }}</v-card-title>
        <v-card-text>
            <div v-if="accessToken">
                <v-row>
                    <v-col md="6">
                        <text-field v-model="accessToken.accessToken" label="Токен"></text-field>
                    </v-col>
                    <v-col md="3">
                        <text-field v-model="accessToken.tokenType" label="Тип токен"></text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="3">
                        <date-field :value="accessToken.issuedOn" show-time label="Заявен на" />
                    </v-col>
                    <v-col md="3">
                        <date-field :value="accessToken.expiresOn" show-time label="Изтича на" />
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-row>
                <v-col md="3">
                    <btn action="Download" block @click="ensureValidToken">Получаване на токен</btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { AccessTokenDto } from '@/model/Nhis/AccessToken/AccessTokenDto';
    import { accessTokenCache } from '@/store/NhisNhifAccessTokenCache';

    @Component
    export default class NhisNhifAccessTokenCard extends Vue {
        // Име на наш api-контролер и ключ на token-а в map-а от token-и: 'Nhis' или 'Nhif'.
        @Prop({ required: true })
        private tokenTypeCode!: string;

        @Prop({ required: true })
        private externalSystemName!: string;

        private accessToken: AccessTokenDto | null = null;

        private mounted() {
            this.accessToken = accessTokenCache.getCachedToken(this.tokenTypeCode);
        }

        private async ensureValidToken() {
            this.accessToken = await accessTokenCache.ensureValidToken(this.tokenTypeCode);
        }
    }
</script>
