import { Type } from 'class-transformer';

import { Annex56CausesOfMorbidityMortalityChildrenRowDto } from '@/model/Report/Rhi/Annex56/Annex56CausesOfMorbidityMortalityChildrenRowDto';

export class Annex56CausesOfMorbidityMortalityChildrenDto {
    public name: string = '';

    @Type(() => Annex56CausesOfMorbidityMortalityChildrenRowDto)
    public causesOfMorbiditiesMortalitiesChildren: Annex56CausesOfMorbidityMortalityChildrenRowDto[] = [];
}
