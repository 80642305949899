import { plainToInstance } from 'class-transformer';
import Vue from 'vue';

import { VisitCacheDto } from '@/model/Exam/Cache/VisitCacheDto';
import { visitCacheService } from '@/service/Exam/VisitCacheService';
import { currentPatientCache } from '@/store/CurrentPatientCache';

const currentVisitKey: string = 'currentVisit';

const emptyValue = new VisitCacheDto();

class CurrentVisitCache {
    // Когато кешът не е зареден, връща празен обект вместо null, за да не гърми bind-ването на компонентите.
    private _value: VisitCacheDto = emptyValue;

    public get value() {
        return this._value;
    }

    public get isLoaded() {
        return this._value !== emptyValue;
    }

    public clear() {
        localStorage.removeItem(currentVisitKey);
        this._value = emptyValue;
    }

    public async load(id: number) {
        if (id !== this._value?.id) {
            const visitCache = await visitCacheService.getVisitCacheById(id);

            // Преди в кеша да се "активира" друг преглед се зарежда съответният му пациент.
            await currentPatientCache.loadSpecificVersion(visitCache.patientVersionKey);

            localStorage.setItem(currentVisitKey, JSON.stringify(visitCache));
            this._value = visitCache;
        } else if (this._value.patientVersionKey.seqNumber) {
            // Изкуствен случай - регенерира текущия пациент, дори ако текущият преглед остава същият.
            // Това има смисъл само когато текущият пациент е бил нарочно изтрит от кеша без текущия преглед.
            await currentPatientCache.loadSpecificVersion(this._value.patientVersionKey);
        }
    }

    public async updateNhisExam(nrn: string, statusCode: string) {
        if (this._value?.exam) {
            this._value.exam.nrn = nrn;
            this._value.exam.statusCode = statusCode;
        }
        await this.set(this._value);
    }

    public async set(visitCache: VisitCacheDto) {
        // Преди в кеша да се "активира" друг преглед се зарежда съответният му пациент.
        await currentPatientCache.loadSpecificVersion(visitCache.patientVersionKey);

        localStorage.setItem(currentVisitKey, JSON.stringify(visitCache));
        this._value = visitCache;
    }

    public loadFromLocalStorage() {
        const json = localStorage.getItem(currentVisitKey);
        if (json) {
            this._value = plainToInstance(VisitCacheDto, JSON.parse(json));
        }
    }
}

export const currentVisitCache = Vue.observable(new CurrentVisitCache());
