/* eslint-disable */
import _ from 'lodash';
/* eslint-enable */
import _Vue from 'vue';

declare module 'vue/types/vue' {
    interface Vue {
        $lodash: _.LoDashStatic;
    }
}

export const lodashPlugin = (Vue: typeof _Vue) => {
    Vue.prototype.$lodash = _;
};
