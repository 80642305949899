<template>
    <div>
        <v-row>
            <v-col>
                <v-row>
                    <!-- TODO: Кабинет - dropdown с кабинети и филтриране на лекари по кабинет -->
                    <v-col>
                        <!-- Лекар -->
                        <dropdown v-model="selectedDoctorId" :items="doctors" clearable label="Лекар"></dropdown>
                    </v-col>
                    <!-- За сега не е необхофимо да се избира заведение а да се показват лекарите от това на логнатия потребител -->
                    <v-col>
                        <!-- Специалност -->
                        <dropdown
                            v-model="selectedSpecialtyCode"
                            :items="specialties"
                            item-value="code"
                            label="Специалност"
                            clearable
                        ></dropdown>
                    </v-col>
                    <!-- Тип -->
                    <v-col>
                        <dropdown
                            v-model="selectedWorkTimeVisitReasonId"
                            :items="workTimeVisitReasons"
                            label="Тип"
                            clearable
                        ></dropdown>
                    </v-col>
                    <v-col>
                        <dropdown v-model="calendarType" :items="calendarTypes" label="Тип календар"></dropdown>
                    </v-col>
                </v-row>
                <v-row>
                    <btn action="New" fab bottom right fixed dark @click="createDialogIsVisible = true" />
                    <v-col class="pt-0">
                        <v-row>
                            <v-col cols="4" class="text-start">
                                <btn action="Back" @click="previousButtonClicked" />
                            </v-col>
                            <v-col cols="4" class="text-center">
                                <div>
                                    <span v-if="$refs.calendar">{{ $refs.calendar.title }}</span>
                                </div>
                            </v-col>
                            <v-col cols="4" class="text-end">
                                <btn action="Next" @click="nextButtonClicked" />
                            </v-col>
                        </v-row>
                        <v-calendar
                            ref="calendar"
                            :locale="locale"
                            :type="calendarType"
                            :start="calendarStartDate"
                            show-month-on-first
                            :events="calendarEvents"
                            :interval-count="26"
                            :interval-minutes="intervalMinutes"
                            :interval-height="intervalHeight"
                            :first-interval="13"
                            :weekdays="weekDaysOrder"
                            class="calendar"
                            color="primary"
                            @click:day="onCalendarClicked"
                            @click:date="onCalendarClicked"
                            @click:event="openEditDialog"
                            @mousedown:event="startEventDrag"
                            @mousedown:time="startTime"
                            @mousemove:time="mouseMove"
                            @mouseup:time="endEventDrag"
                            @mouseleave.native="cancelDrag"
                        >
                            <template #event="{ event, timed }">
                                <div style="text-align: center">
                                    <p style="text-wrap: wrap">{{ JSON.stringify(event.name).split('-').shift() }}</p>
                                    <p style="text-wrap: wrap">{{ JSON.stringify(event.name).split('-').pop() }}</p>
                                    <p style="text-wrap: wrap">{{ JSON.stringify(event.category) }}</p>
                                    <p v-if="!checkRecurence(JSON.stringify(event.isRecurrence))">Еднократно</p>
                                </div>
                                <!-- TODO: Да махна името на лекаря от името на event-а и да го сложа в отделен div / strong -->
                                <div
                                    v-if="timed"
                                    class="v-event-drag-bottom"
                                    @mousedown.stop="extendBottom(event)"
                                ></div>
                            </template>
                        </v-calendar>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-10 pb-0">
                        <work-time-visit-reason-colors-legend
                            :work-time-visit-reasons="workTimeVisitReasons"
                        ></work-time-visit-reason-colors-legend>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <create-event-dialog
            :show="createDialogIsVisible"
            :practice-id="practiceId"
            :doctor-id="selectedDoctorId"
            :doctors="doctors"
            :selected-doctor-id-prop="selectedDoctorId"
            :selected-doctor-specialties-prop="specialties"
            :selected-doctor-specialty-id="selectedSpecialtyCode"
            :selected-visit-reason-id="selectedWorkTimeVisitReasonId"
            @onClose="createDialogIsVisible = false"
            @onWorkTimeEventAdded="onWorkTimeEventAdded"
        ></create-event-dialog>

        <edit-event-dialog
            :show="editDialogIsVisible"
            :event-id="selectedEventIdForEdit"
            :new-start-time="dragNewStartTime"
            :new-end-time="dragNewEndTime"
            :date-to-change="dragNewStartDate"
            @onClose="onEditDialogClosed"
            @onDelete="openDeleteDialog"
        ></edit-event-dialog>

        <delete-event-dialog
            :dialog-is-visible="deleteDialogIsVisible"
            :work-time-event-edit-dto="workTimeEventEditDto"
            @onClose="closeDeleteDialog"
            @onDeleteFinish="loadEvents"
        ></delete-event-dialog>
    </div>
</template>

<script lang="ts">
    import { RRule, RRuleSet } from 'rrule';
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { calendarConfig, VuetifyCalendarType } from '@/model/Calendar/CalendarConfig';
    import { CalendarTypeModel } from '@/model/Calendar/CalendarTypeModel';
    import { DoctorDropdownDto } from '@/model/Doctor/DoctorDropdownDto';
    import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';
    import { WorkTimeVisitReasonDto } from '@/model/Nomenclature/WorkTimeVisitReasonDto';
    import { CalendarEventModel } from '@/model/WorkTime/CalendarEventModel';
    import { EventDto } from '@/model/WorkTime/EventDto';
    import { EventEditDto } from '@/model/WorkTime/EventEditDto';
    import { doctorService } from '@/service/Doctor/DoctorService';
    import { workTimeVisitReasonService } from '@/service/Nomenclature/WorkTimeVisitReasonService';
    import { workTimeService } from '@/service/WorkTime/WorkTimeService';
    import { arrayUtil } from '@/util/ArrayUtil';

    import CreateEventDialog from './CreateEventDialog.vue';
    import DeleteEventDialog from './DeleteEventDialog.vue';
    import EditEventDialog from './EditEventDialog.vue';
    import WorkTimeVisitReasonColorsLegend from './WorkTimeVisitReasonColorsLegend.vue';

    const daysInWeek = 7;
    type CalendarDateParts = { year: number; month: number; day: number; hour: number; minute: number };
    type CalendarDateWrapper = { date: string };
    type CalendarEventWrapper = { event: CalendarEventModel };

    @Component({
        components: {
            CreateEventDialog,
            DeleteEventDialog,
            EditEventDialog,
            WorkTimeVisitReasonColorsLegend
        }
    })
    export default class WorkTime extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private locale: string = calendarConfig.locale;

        private calendarType: VuetifyCalendarType = calendarConfig.defaultType;
        private intervalHeight: number = calendarConfig.intervalHeight;
        private intervalMinutes: number = calendarConfig.intervalMinutes;
        private calendarStartDate: Date = new Date(new Date().toDateString());
        private weekDaysOrder: number[] = calendarConfig.weekDaysOrder;

        private createDialogIsVisible: boolean = false;
        private editDialogIsVisible: boolean = false;
        private deleteDialogIsVisible: boolean = false;
        private rawEvents: EventDto[] = [];
        private calendarEvents: CalendarEventModel[] = [];
        private doctors: DoctorDropdownDto[] = [];
        private selectedDoctorId: number | null = null;
        private specialties: SpecialtyDto[] = [];
        private selectedSpecialtyCode: SpecialtyCode | null = null;
        private workTimeVisitReasons: WorkTimeVisitReasonDto[] = [];
        private selectedWorkTimeVisitReasonId: number | null = null;
        private workTimeEventEditDto: EventEditDto | null = null;

        private calendarTypes: CalendarTypeModel[] = calendarConfig.types;

        private selectedEventIdForEdit: number | null = null;

        private dragEvent: CalendarEventModel | null = null;
        private dragTime: number | null = null;
        private createStart: Date | null = null;
        private extendOriginal: Date | null = null;
        private createNewEvent: CalendarEventModel | null = null;
        private dragNewStartTime: Date | null = null;
        private dragNewEndTime: Date | null = null;
        private dragNewStartDate: Date | null = null;

        async mounted() {
            this.calendarStartDate = new Date();
            this.calendarStartDate.setHours(0);
            this.doctors = await doctorService.getDoctorsByPracticeId(this.practiceId);
            this.getAllSpecialtiesForPractice();
            this.getWorkTimeVisitReasonTypes();
            this.loadEvents();
        }

        private async loadEvents() {
            this.$loading.show();

            this.calendarEvents = [];
            const response = await workTimeService.getFiltered(
                this.practiceId,
                this.selectedDoctorId,
                this.selectedSpecialtyCode,
                this.selectedWorkTimeVisitReasonId
            );

            this.rawEvents = response.data;
            this.addRepeatedEvents(this.rawEvents);

            this.$loading.hide();
        }

        addRepeatedEvents(events: EventDto[]) {
            for (const event of events) {
                if (event.recurrence) {
                    const rule = new RRule({
                        freq: event.recurrence.rRuleRecurrenceType ?? 0,
                        interval: event.recurrence.interval ?? 1,
                        count: event.recurrence.count,
                        dtstart: event.recurrence.startDate,
                        until: event.recurrence.endDate || this.calendarEndDate
                        //byweekday: ['MO', 'TU', 'WE', 'TH', 'FR'] // 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU'
                        //bymonth
                        //bymonthday
                    });

                    const rruleSet = new RRuleSet();
                    rruleSet.rrule(rule);

                    if (event.recurrence.eventRecurrenceExclusions) {
                        for (const exclusionDate of event.recurrence.eventRecurrenceExclusions) {
                            rruleSet.exdate(new Date(exclusionDate));
                        }
                    }

                    const startDate = new Date(this.calendarStartDate.getDate() - 1);
                    const dates = rruleSet.between(startDate, this.calendarEndDate);

                    for (const date of dates) {
                        this.calendarEvents.push(workTimeService.createWorkTimeEvent(event, date, true));
                    }
                } else {
                    this.calendarEvents.push(workTimeService.createWorkTimeEvent(event, null, false));
                }
            }
        }

        private checkRecurence(val: string) {
            return Boolean(val);
        }

        private get calendarEndDate() {
            const endDate = new Date(this.calendarStartDate);
            endDate.setDate(this.calendarStartDate.getDate() + daysInWeek);
            return endDate;
        }

        private onWorkTimeEventAdded(event: EventDto) {
            const events = [];
            events.push(event);
            this.addRepeatedEvents(events);
        }

        private async getWorkTimeVisitReasonTypes() {
            this.workTimeVisitReasons = (await workTimeVisitReasonService.getWorkTimeVisitReasons()).data;
        }

        private async getSelectedDoctorSpecialties() {
            if (this.selectedDoctorId) {
                this.specialties = (
                    await workTimeService.getRegisteredSpecialtiesForDoctorAndPractice(
                        this.practiceId,
                        this.selectedDoctorId ?? 0
                    )
                ).data;
            } else {
                this.getAllSpecialtiesForPractice();
            }
        }

        private async getAllSpecialtiesForPractice() {
            this.specialties = (await workTimeService.getAllSpecialtiesForPractice(this.practiceId)).data;
        }

        private previousButtonClicked() {
            const newStartDate =
                this.calendarStartDate.getDate() - calendarConfig.getCalendarOffsetByType(this.calendarType);
            this.calendarStartDate = new Date(this.calendarStartDate.setDate(newStartDate));
            this.calendarEvents = [];
            this.addRepeatedEvents(this.rawEvents);
        }

        private nextButtonClicked() {
            const newStartDate =
                this.calendarStartDate.getDate() + calendarConfig.getCalendarOffsetByType(this.calendarType);
            this.calendarStartDate = new Date(this.calendarStartDate.setDate(newStartDate));
            this.calendarEvents = [];
            this.addRepeatedEvents(this.rawEvents);
        }

        private onCalendarClicked(data: CalendarDateWrapper) {
            this.calendarType = VuetifyCalendarType.day;
            this.calendarStartDate = new Date(data.date);
        }

        private openEditDialog(data: CalendarEventWrapper) {
            this.selectedEventIdForEdit = data.event.id;
            this.editDialogIsVisible = true;
        }

        private startEventDrag(data: CalendarEventWrapper) {
            if (data) {
                this.dragEvent = data.event;
                this.dragTime = null;
                this.extendOriginal = null;
            }
        }

        private endEventDrag(data: CalendarDateWrapper) {
            this.dragNewStartDate = new Date(data.date);
        }

        private startTime(tms: CalendarDateParts) {
            const mouse = this.toTime(tms);

            if (this.dragEvent && this.dragTime === null) {
                const { start } = this.dragEvent;
                this.dragTime = mouse - (start?.getTime() ?? 0);
            } else {
                // TODO: Ако искаме да се създава от click в календара
                //this.createStart = this.roundTime(mouse)
                //this.createNewEvent = {
                //    name: `Event #${this.events.length}`,
                //    color: this.rndElement(this.colors),
                //    start: this.createStart,
                //    end: this.createStart,
                //    timed: true,
                //}
                //this.events.push(this.createNewEvent)
            }
        }

        toTime(tms: CalendarDateParts) {
            return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
        }

        private mouseMove(tms: CalendarDateParts) {
            const mouse = this.toTime(tms);

            if (this.dragEvent && this.dragTime !== null) {
                const { start, end } = this.dragEvent;
                if (start && end) {
                    const duration: number = end.getTime() - start.getTime();
                    const newStartTime = mouse - this.dragTime;
                    const newStart = this.roundTime(newStartTime);
                    const newEnd = newStart + duration;

                    this.dragEvent.start = new Date(newStart);
                    this.dragEvent.end = new Date(newEnd);

                    this.dragNewStartTime = new Date(newStart);
                    this.dragNewEndTime = new Date(newEnd);
                    this.dragNewStartDate = new Date(mouse);
                }
            } else if (this.createNewEvent && this.createStart !== null) {
                const mouseRounded = this.roundTime(mouse, false);
                const min = Math.min(mouseRounded, this.createStart.getTime());
                const max = Math.max(mouseRounded, this.createStart.getTime());
                this.createNewEvent.start = new Date(min);
                this.createNewEvent.end = new Date(max);
                this.dragNewEndTime = new Date(max);
            }
        }

        private cancelDrag() {
            if (this.createNewEvent) {
                if (this.extendOriginal) {
                    // TODO: createNewEvent.end е с правилния час, extendOriginal е със стария час
                    //this.createNewEvent.end = this.extendOriginal;
                } else {
                    arrayUtil.removeFirst(this.calendarEvents, this.createNewEvent);
                }
            }

            this.createNewEvent = null;
            this.createStart = null;
            this.dragTime = null;
            this.dragEvent = null;
        }

        private extendBottom(event: CalendarEventModel) {
            this.createNewEvent = event;
            this.createStart = event.start;
            this.extendOriginal = event.end;
            // TODO: в Edit dialog-а се bind-ва предната стойност
            this.dragNewEndTime = event.end;
        }

        // TODO: Move to some helper
        private roundTime(time: number, down = true) {
            const roundToMinutes = 15;
            const minutesInHour = 60;
            const thousand = 1000;
            const roundDownTime = roundToMinutes * minutesInHour * thousand;

            return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime));
        }

        private onEditDialogClosed(isSaved: boolean) {
            if (isSaved) {
                this.loadEvents();
            }
            this.editDialogIsVisible = false;
            this.dragNewEndTime = null;
            this.dragNewEndTime = null;
            this.dragNewStartDate = null;
        }

        private openDeleteDialog(dto: EventEditDto) {
            this.editDialogIsVisible = false;
            this.workTimeEventEditDto = dto;
            this.deleteDialogIsVisible = true;
        }

        private closeDeleteDialog() {
            this.deleteDialogIsVisible = false;
        }

        @Watch('selectedDoctorId')
        private onSelectedDoctorIdChanged() {
            this.getSelectedDoctorSpecialties();
            this.loadEvents();
        }

        @Watch('selectedSpecialtyCode')
        private async onSelectedSpecialtyCodeChanged() {
            // TODO: Да се оптимизира филтрирането на лекар да става client-side
            if (!this.selectedDoctorId && this.selectedSpecialtyCode) {
                this.doctors = await doctorService.getDoctorsByPracticeAndSpecialty(
                    this.practiceId,
                    this.selectedSpecialtyCode ?? 0
                );
            } else {
                this.doctors = await doctorService.getDoctorsByPracticeId(this.practiceId);
            }
            this.loadEvents();
        }

        @Watch('selectedWorkTimeVisitReasonId')
        private onSelectedWorkTimeVisitReasonIdChanged() {
            this.loadEvents();
        }
    }
</script>

<style scoped lang="scss">
    .v-event-draggable {
        padding-left: 6px;
    }

    .v-event-timed {
        user-select: none;
        -webkit-user-select: none;
    }

    .v-event-drag-bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4px;
        height: 4px;
        cursor: ns-resize;

        &::after {
            display: none;
            position: absolute;
            left: 50%;
            height: 4px;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
            width: 16px;
            margin-left: -8px;
            opacity: 0.8;
            content: '';
        }

        &:hover::after {
            display: block;
        }
    }
</style>
