<template>
    <data-table
        title="Оператори, настроени във фискалното устройство"
        :headers="headers"
        :items="items"
        :loading="$loading.isVisible"
    >
        <template #actions>
            <btn action="Back" :to="`/Practice/Fiscal/Device/Edit/${practiceId}/${fiscalDeviceSeqNumber}`" />
            <btn action="NewAccount" :to="`/Practice/Fiscal/Operator/Create/${practiceId}/${fiscalDeviceSeqNumber}`">
                Добавяне на оператор
            </btn>
        </template>
        <template #rowActions="{ item }">
            <btn
                icon
                action="Edit"
                :to="`/Practice/Fiscal/Operator/Edit/${practiceId}/${fiscalDeviceSeqNumber}/${item.deviceOperatorSeqNumber}`"
            />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { FiscalDeviceOperatorDto } from '@/model/Practice/Fiscal/FiscalDeviceOperatorDto';
    import { fiscalDeviceOperatorService } from '@/service/Practice/Fiscal/FiscalDeviceOperatorService';

    @Component
    export default class DeviceOperatorList extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private fiscalDeviceSeqNumber!: number;

        private items: FiscalDeviceOperatorDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'Оператор', value: 'operator' },
            { text: 'Парола', value: 'password' }
        ];

        private async mounted() {
            this.$loading.show();
            try {
                const response = await fiscalDeviceOperatorService.getFiscalDeviceOperators(
                    this.practiceId,
                    this.fiscalDeviceSeqNumber
                );
                this.items = response.data || [];
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
