export class FundServiceDto {
    public code: string | null = null;
    public name: string | null = null;
    public fundServicePackageCode: string | null = null;
    public labTestCategoryCode: string | null = null;
    public fundCode: string | null = null;
    public fundPrice: number | null = null;
    public isSpecialized: boolean | null = null;
    public computedCoreServiceTypeCode: string | null = null;
    public seqNumber: number = 0;
    public fundServicePackageName: string | null = null;
    public coreServiceCode: string | null = null;
}
