import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { ReferralRequestForDto } from '@/model/Exam/Referral/ReferralRequestForDto';
import { ReferralRequestForViewDto } from '@/model/Exam/Referral/ReferralRequestForViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referralRequestForService = {
    async getReferralRequestFor(id: number): Promise<ReferralRequestForDto> {
        const response = await httpService.get(`/ReferralRequestFor/GetReferralRequestFor/${id}`);
        return plainToInstance(ReferralRequestForDto, response.data);
    },

    async getReferralInfoByExamId(id: number): Promise<ReferralRequestForDto> {
        const response = await httpService.get(`/ReferralRequestFor/GetInfoByExamId/${id}`);
        return plainToInstance(ReferralRequestForDto, response.data);
    },

    getReferralViewsByExamId(examId: number): Promise<AxiosResponse<ReferralRequestForViewDto[]>> {
        return httpService.get(`/ReferralRequestFor/GetMenuViewsByExam/`, {
            params: {
                examId
            }
        });
    },

    createReferralRequestFor(data: ReferralRequestForDto): Promise<AxiosResponse<number>> {
        return httpService.post('/ReferralRequestFor/CreateReferralRequestFor/', data);
    },

    updateReferralRequestFor(data: ReferralRequestForDto): Promise<AxiosResponse<void>> {
        return httpService.put('/ReferralRequestFor/UpdateReferralRequestFor/', data);
    },

    deleteReferralRequestFor(id: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/ReferralRequestFor/DeleteReferralRequestFor/${id}`);
    }
};
