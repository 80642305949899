export class RequestForHistopathologicalTestDto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();
    public body: string = '';
    public sampleMethod: string = '';
    public sampleDate: Date | null = null;
    public fixedIn: string = '';
    public clinicalData: string = '';
    public probableDiagnose: string = '';
    public previousTestDate: string = '';
    public previousTestMedicalCenter: string = '';
    public previousTestResult: string = '';
}
