import { Type } from 'class-transformer';

import { Annex3ChildrenRegisterDto } from '@/model/Report/Rhi/Annex3/Annex3ChildrenRegisterDto';
import { Annex3ChildrenVisitDto } from '@/model/Report/Rhi/Annex3/Annex3ChildrenVisitDto';
import { Annex3ChildrenWithPneumoniaDto } from '@/model/Report/Rhi/Annex3/Annex3ChildrenWithPneumoniaDto';
import { Annex3NewbornRegisterDto } from '@/model/Report/Rhi/Annex3/Annex3NewbornRegisterDto';
import { Annex3OneYearRegisterDto } from '@/model/Report/Rhi/Annex3/Annex3OneYearRegisterDto';

export class Annex3ItemsDto {
    @Type(() => Annex3ChildrenVisitDto)
    public childrenVisit: Annex3ChildrenVisitDto = new Annex3ChildrenVisitDto();

    @Type(() => Annex3ChildrenRegisterDto)
    public childrenRegister: Annex3ChildrenRegisterDto = new Annex3ChildrenRegisterDto();

    @Type(() => Annex3NewbornRegisterDto)
    public newbornRegister: Annex3NewbornRegisterDto = new Annex3NewbornRegisterDto();

    @Type(() => Annex3OneYearRegisterDto)
    public oneYearRegister: Annex3OneYearRegisterDto = new Annex3OneYearRegisterDto();

    @Type(() => Annex3ChildrenWithPneumoniaDto)
    public childrenWithPneumonia: Annex3ChildrenWithPneumoniaDto = new Annex3ChildrenWithPneumoniaDto();
}
