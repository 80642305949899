import { PrescriptionNhifDto } from '@/model/Exam/Prescription/PrescriptionNhifDto';

export class PrescriptionNhifTypeBDto extends PrescriptionNhifDto {
    public certificateNumber: string = '';
    public certificateDate: Date | null = null;
    public workIncapabilityPercent: number = 0;

    public telkResultNumber: string = '';
    public telkResultDate: Date | null = null;
}
