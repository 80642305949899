export class AlgorithmDto {
    public code: string = '';
    public nhifCode: string = '';
    public ageBracketCode: string = '';
    public name: string = '';
    public icdCodes: string[] = [];

    public get nhifCodeAndName(): string {
        return `${this.nhifCode} - ${this.name}`;
    }
}
