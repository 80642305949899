import { plainToInstance } from 'class-transformer';

import { UserContextDto } from '@/model/User/UserContext/UserContextDto';
import { UserContextFilterCommand } from '@/model/User/UserContext/UserContextFilterCommand';
import { UserContextSearchResultDto } from '@/model/User/UserContext/UserContextSearchResultDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const userContextService = {
    async searchUserContexts(filter: UserContextFilterCommand): Promise<UserContextSearchResultDto> {
        const response = await httpService.get<UserContextSearchResultDto>('UserContext/SearchUserContexts', {
            params: filter
        });
        const result = plainToInstance(UserContextSearchResultDto, response.data);
        this.fillIndices(result.userContexts);
        return result;
    },

    async getCurrentUserContexts(): Promise<UserContextDto[]> {
        const response = await httpService.get<UserContextDto[]>('UserContext/GetCurrentUserContexts');
        const userContexts = plainToInstance(UserContextDto, response.data);
        this.fillIndices(userContexts);
        return userContexts;
    },

    fillIndices(userContexts: UserContextDto[]) {
        if (userContexts) {
            for (let index = 0; index < userContexts.length; index++) {
                userContexts[index].index = index;
            }
        }
    }
};
