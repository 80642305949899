import { LkkCommitteeDoctorEmployeeDto } from '@/model/Practice/LkkCommittee/LkkCommitteeDoctorEmployeeDto';

export class LkkCommitteeDto {
    public practiceId: number = 0;
    public seqNumber: number = 0;
    public number: string = '';
    public name: string = '';
    public isDeleted: boolean = false;
    public isSpecialized: boolean = false;
    public lkkCommitteeDoctorEmployees: LkkCommitteeDoctorEmployeeDto[] = [];
}
