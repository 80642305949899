<template>
    <exam-layout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Медицинско направление/Искане за"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <v-col v-if="document.id > 0" cols="3">
                <btn action="Print" @click="printWithResult()">Печат с резултат</btn>
            </v-col>
            <main-title>Медицинско направление/Искане за</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6"><PatientInfo /></v-col>
                    <v-col cols="12" md="6"><DoctorInfo /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6"
                        ><dropdown
                            v-model="document.patientWorkplaceId"
                            :items="patientWorkplaces"
                            label="Месторабота"
                            :item-text="workplacePosition"
                    /></v-col>
                    <v-col cols="12" md="6"
                        ><text-field v-model="document.medicalCentre" label="До медицинско/лечебно заведение:"
                    /></v-col>
                </v-row>
                <v-row class="my-2">
                    <v-col cols="12" md="3"
                        ><text-field v-model.trim="document.number" required label="Номер:"
                    /></v-col>
                    <v-col cols="12" md="3"
                        ><date-picker v-model="document.issueDate" required label="Дата на издаване"
                    /></v-col>
                    <v-col cols="12" md="3"
                        ><dropdown
                            v-model="document.referralRequestForTypeCode"
                            :items="referralTypes"
                            required
                            label="Медицинско направление/Искане за:"
                            item-text="name"
                            item-value="code"
                    /></v-col>
                </v-row>
                <v-row class="my-2">
                    <v-col cols="12" md="6"
                        ><v-textarea v-model="document.mainDiagnosis" label="Диагноза" dense
                    /></v-col>
                    <v-col cols="12" md="6"
                        ><v-textarea v-model="document.comorbidityDiagnoses" label="Придружаващи заболявания" dense
                    /></v-col>
                </v-row>
                <v-row class="my-2">
                    <v-col cols="12"><v-textarea v-model="document.requestFor" label="Изпраща се за:" dense /></v-col>
                </v-row>
                <v-row class="my-2">
                    <v-col cols="12"
                        ><v-textarea v-model="document.attachedDocuments" label="Приложени медицински документи:" dense
                    /></v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </exam-layout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { ReferralRequestForDto } from '@/model/Exam/Referral/ReferralRequestForDto';
    import { ReferralRequestForTypeDto } from '@/model/Nomenclature/ReferralRequestForTypeDto';
    import { PatientWorkplaceDto } from '@/model/Patient/PatientWorkplaceDto';
    import { referralRequestForService } from '@/service/Exam/Referral/ReferralRequestForService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { referralRequestForTypeService } from '@/service/Nomenclature/ReferralRequestForTypeService';
    import { patientWorkplaceService } from '@/service/Patient/PatientWorkplaceService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: { UnsavedChanges, ExamLayout, DoctorInfo, PatientInfo },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class ReferralRequestFor extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private examId!: number;

        @Prop()
        private documentId!: number;

        private document: ReferralRequestForDto = new ReferralRequestForDto();
        private initialValue: ReferralRequestForDto = new ReferralRequestForDto();
        private patientWorkplaces: PatientWorkplaceDto[] = [];
        private referralTypes: ReferralRequestForTypeDto[] = [];
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (this.document.id === 0) {
                    const response = await referralRequestForService.createReferralRequestFor(this.document);
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit(
                        'create-referral-event',
                        documentId,
                        'ReferralRequestFor',
                        'Медицинско направление/Искане за'
                    );
                    this.$router.push(`/Exam/ReferralRequestFor/Edit/${documentId}`);
                } else {
                    await referralRequestForService.updateReferralRequestFor(this.document);
                    eventBus.$emit('update-referral-event', 'Медицинско направление/Искане за');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                await referralRequestForService.deleteReferralRequestFor(documentId);
                this.copyObject();
                this.$router.push(`/Exam/ReferralRequestFor/Create/${this.document.examId}`);
                eventBus.$emit(
                    'delete-referral-event',
                    documentId,
                    'ReferralRequestFor',
                    'Медицинско направление/Искане за'
                );
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Referral.ReferralRequestFor/${this.documentId}`);
            }
        }

        private printWithResult() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Referral.ReferralRequestForWithResult/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Referral.ReferralRequestFor';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async getPatientWorkplaces() {
            this.patientWorkplaces = await patientWorkplaceService.getActivePatientWorkplaces(
                currentPatientCache.value.key.patientId
            );
        }

        private workplacePosition(workplace: PatientWorkplaceDto) {
            return `${workplace.company} - ${workplace.position}`;
        }

        private async getReferralTypes() {
            this.referralTypes = await referralRequestForTypeService.getTypes();
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                await this.getPatientWorkplaces();
                await this.getReferralTypes();
                if (this.documentId) {
                    this.document = await referralRequestForService.getReferralRequestFor(this.documentId);
                } else {
                    this.document = await referralRequestForService.getReferralInfoByExamId(this.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }
    }
</script>
