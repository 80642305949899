import { plainToInstance } from 'class-transformer';

import { LkkCommitteeDropdownDto } from '@/model/Practice/LkkCommittee/LkkCommitteeDropdownDto';
import { LkkCommitteeDto } from '@/model/Practice/LkkCommittee/LkkCommitteeDto';
import { LkkCommitteeViewDto } from '@/model/Practice/LkkCommittee/LkkCommitteeViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const lkkCommitteeService = {
    async getLkkCommitteesForPractice(id: number): Promise<LkkCommitteeViewDto[]> {
        const response = await httpService.get<LkkCommitteeViewDto[]>(
            `/LkkCommittee/GetLkkCommitteesForPractice/${id}`
        );
        return plainToInstance(LkkCommitteeViewDto, response.data);
    },

    async getLkkCommittee(practiceId: number, seqNumber: number): Promise<LkkCommitteeDto> {
        const response = await httpService.get<LkkCommitteeDto>(`/LkkCommittee/GetLkkCommittee`, {
            params: { practiceId, seqNumber }
        });
        return plainToInstance(LkkCommitteeDto, response.data);
    },

    async createLkkCommittee(data: LkkCommitteeDto): Promise<number> {
        const response = await httpService.post<number>('/LkkCommittee/CreateLkkCommittee', data);
        return response.data;
    },

    async updateLkkCommittee(data: LkkCommitteeDto): Promise<void> {
        await httpService.put('/LkkCommittee/UpdateLkkCommittee', data);
    },

    async getLkkCommitteesDropdowns(
        practiceId: number,
        doctorEmployeeSeqNumber: number
    ): Promise<LkkCommitteeDropdownDto[]> {
        const response = await httpService.get<LkkCommitteeDropdownDto[]>('/LkkCommittee/GetLkkCommitteeDropdowns', {
            params: {
                practiceId,
                doctorEmployeeSeqNumber
            }
        });

        return plainToInstance(LkkCommitteeDropdownDto, response?.data);
    }
};
