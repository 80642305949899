<template>
    <v-card>
        <v-card-title>Вижте какво връща сървърът през Developer tools - Network</v-card-title>
        <v-card-text>
            <div class="ma-2">
                <btn action="Success" @click.stop="success">OK</btn>
                Ако няма грешка, response-ът не се wrap-ва
            </div>
            <div class="ma-2">
                <btn action="Secondary" @click.stop="validationError">Валидация</btn>
                Няма разлика във формата на валидационна грешка...
            </div>
            <div class="ma-2">
                <btn action="Secondary" @click.stop="parseError">Parse грешка</btn>
                ...и грешка при parse-ването.
            </div>
            <div class="ma-2">
                <btn action="Primary" @click.stop="exception">Exception</btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { httpService } from '@/service/Infrastructure/HttpService';

    @Component
    export default class ResponseWrapperTest extends Vue {
        private async success() {
            await httpService.get<number[]>('/ResponseWrapper/Success');
        }

        private async validationError() {
            await httpService.post('/ResponseWrapper/ValidationError', {
                text: null,
                dateTime: '2022-01-01T01:23:45Z'
            });
        }

        private async parseError() {
            await httpService.post('/ResponseWrapper/ValidationError', {
                text: 'demo',
                dateTime: '0000-12-31T22:00:00Z'
            });
        }

        private async exception() {
            await httpService.get<number>('/ResponseWrapper/Exception');
        }
    }
</script>
