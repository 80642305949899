import { plainToInstance } from 'class-transformer';

import { PatientVersionDto } from '@/model/Patient/PatientVersionDto';
import { IDropdownSearchService } from '@/service/Dropdown/IDropdownSearchService';
import { httpService } from '@/service/Infrastructure/HttpService';
import { userContextCache } from '@/store/User/UserContextCache';

export const patientSearchService: IDropdownSearchService<PatientVersionDto> = {
    getItemsById(): Promise<PatientVersionDto[]> {
        return Promise.resolve([]);
    },

    async searchItems(filters: []): Promise<PatientVersionDto[]> {
        const practiceId = userContextCache.currentPracticeId ?? 0;
        const response = await httpService.get<PatientVersionDto[]>('/Patient/SearchForPatient', {
            params: { filter: filters.at(0), practiceId }
        });
        return plainToInstance(PatientVersionDto, response.data);
    }
};
