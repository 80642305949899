<template>
    <div>
        <v-row align="stretch" no-gutters>
            <v-col md="4" sm="12" :class="{ 'pr-1': $vuetify.breakpoint.mdAndUp }">
                <v-card :style="`background-color: ${backgroundColor}`" class="fill-height">
                    <AmbulatorySheetIssuedForm
                        v-model="examDto"
                        @save-fiscal-receipt="saveFiscalReceipt"
                    ></AmbulatorySheetIssuedForm>
                </v-card>
            </v-col>
            <v-col md="8" sm="12" :class="{ 'pl-1': $vuetify.breakpoint.mdAndUp }">
                <v-card :style="`background-color: ${backgroundColor}`" class="fill-height">
                    <h4>Извършени процедури</h4>
                    <v-card-title class="no-margin-no-padding"></v-card-title>
                    <v-col cols="12" class="no-margin-no-padding">
                        <AmbulatorySheetMedicalProcedureCard :value="examDto.medicalProcedures[0]" />
                    </v-col>
                    <v-col cols="12" class="no-margin-no-padding">
                        <AmbulatorySheetMedicalProcedureCard :value="examDto.medicalProcedures[1]" />
                    </v-col>
                    <v-col cols="12" class="no-margin-no-padding">
                        <AmbulatorySheetMedicalProcedureCard :value="examDto.medicalProcedures[2]" />
                    </v-col>
                    <v-col cols="12" class="no-margin-no-padding">
                        <AmbulatorySheetMedicalProcedureCard :value="examDto.medicalProcedures[3]" />
                    </v-col>
                    <v-row v-if="isRehabilitator" dense no-gutters>
                        <v-col cols="12">
                            <v-checkbox
                                v-model="examDto.isCourseCompleted"
                                dense
                                label="Завършен курс на лечение"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="examDto.id" no-gutters class="py-1">
            <v-col cols="12">
                <v-card :style="`background-color: ${backgroundColor}`">
                    <AmbulatorySheetSignature :exam="examDto" />
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { ExamDto } from '@/model/Exam/ExamDto';
    import { userContextCache } from '@/store/User/UserContextCache';
    import AmbulatorySheetIssuedForm from '@/view/Exam/AmbulatorySheet/AmbulatorySheetIssuedForm.vue';
    import AmbulatorySheetMedicalProcedureCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetMedicalProcedureCard.vue';
    import AmbulatorySheetSignature from '@/view/Exam/AmbulatorySheet/AmbulatorySheetSignature.vue';

    @Component({
        components: { AmbulatorySheetIssuedForm, AmbulatorySheetSignature, AmbulatorySheetMedicalProcedureCard }
    })
    export default class AmbulatorySheetProcedures extends Vue {
        @Prop()
        examDto!: ExamDto;

        @Prop()
        backgroundColor!: string;

        private get isRehabilitator() {
            return userContextCache.current?.specialtyCode === SpecialtyCode.Rehabilitation;
        }

        private saveFiscalReceipt() {
            this.$emit('save-fiscal-receipt');
        }
    }
</script>

<style scoped></style>
