import { AllergyVerificationStatusDto } from '@/model/Nomenclature/AllergyVerificationStatusDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const allergyVerificationStatusService = {
    async getAllergyVerificationStatuses(): Promise<AllergyVerificationStatusDto[]> {
        const response = await httpService.get<AllergyVerificationStatusDto[]>(
            '/AllergyVerificationStatus/GetAllergyVerificationStatuses'
        );
        return response.data;
    }
};
