import { AxiosResponse } from 'axios';

import { RecurrenceTypeDto } from '@/model/Nomenclature/RecurrenceTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const recurrenceTypeService = {
    getRecurrenceTypes(): Promise<AxiosResponse<RecurrenceTypeDto[]>> {
        return httpService.get('/RecurrenceType/GetRecurrenceTypes');
    }
};
