import { Type } from 'class-transformer';

import { Annex3OneYearRegisterRowDto } from '@/model/Report/Rhi/Annex3/Annex3OneYearRegisterRowDto';

export class Annex3OneYearRegisterDto {
    public name: string = '';

    @Type(() => Annex3OneYearRegisterRowDto)
    public oneYearRegisters: Annex3OneYearRegisterRowDto[] = [];
}
