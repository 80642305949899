enum HelpActionCode {
    AL = 'AL',
    BL = 'BL',
    Form5 = 'Form5',
    Form5A = 'Form5A'
}

interface IHelpAction {
    src: string;
}

const helpActions: Record<HelpActionCode, IHelpAction> = {
    AL: {
        src: 'guide/Посещения/Амбулаторен-лист.html'
    },
    BL: {
        src: 'guide/Посещения/Болничен-лист.html'
    },
    Form5: {
        src: 'guide/Посещения/НЗОК-рецепта-едномесечна.html'
    },
    Form5A: {
        src: 'guide/Посещения/НЗОК-рецепта-тримесечна.html'
    }
};

export { HelpActionCode, helpActions };
