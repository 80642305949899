import { AxiosResponse } from 'axios';

import { RiskGroupRuleDto } from '@/model/Register/RiskGroup/RiskGroupRuleDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const riskGroupRuleService = {
    getRiskGroupRules(): Promise<AxiosResponse<RiskGroupRuleDto[]>> {
        return httpService.get('/RiskGroupRule/GetRiskGroupRules');
    }
};
