import { plainToInstance } from 'class-transformer';

import { PatientPrescriptionDrugDto } from '@/model/Query/Patient/PatientPrescriptionDrugDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientPrescriptionDrugService = {
    async getPatientNhifPrescriptionDrugs(patientId: number): Promise<PatientPrescriptionDrugDto[]> {
        const response = await httpService.get<PatientPrescriptionDrugDto[]>(
            'PatientPrescriptionDrug/GetPatientNhifPrescriptionDrugs',
            { params: { patientId } }
        );
        return plainToInstance(PatientPrescriptionDrugDto, response.data);
    },

    async getPatientNhisPrescriptionDrugs(patientId: number): Promise<PatientPrescriptionDrugDto[]> {
        const response = await httpService.get<PatientPrescriptionDrugDto[]>(
            'PatientPrescriptionDrug/GetPatientNhisPrescriptionDrugs',
            { params: { patientId } }
        );
        return plainToInstance(PatientPrescriptionDrugDto, response.data);
    },

    async checkForSpirivaPrescribing(patientId: number, unitCode: string): Promise<string> {
        const response = await httpService.get<string>(
            `PatientPrescriptionDrug/CheckForSpirivaPrescribing?patientId=${patientId}&unitCode=${unitCode}`
        );
        return response.data;
    },

    async checkForCoronaryDrugExceededDuration(
        patientId: number,
        drugNhifCode: string,
        prescriptionDate: Date
    ): Promise<string> {
        const response = await httpService.get<string>(`PatientPrescriptionDrug/CheckForCoronaryDrugExceededDuration`, {
            params: { patientId, drugNhifCode, prescriptionDate }
        });
        return response.data;
    }
};
