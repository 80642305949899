/* eslint-disable  @typescript-eslint/no-use-before-define */
const ten = 10;
const twenty = 20;
const hundred = 100;
const thousand = 1000;
const million = 1000000;
const billion = 1000000000;
const doubleDigit = [
    'десет',
    'единадесет',
    'дванадесет',
    'тринадесет',
    'четиринадесет',
    'петнадесет',
    'шестнадесет',
    'седемнадесет',
    'осемнадесет',
    'деветнадесет'
];
const belowHundred = [
    'двадесет',
    'тридесет',
    'четиридесет',
    'петдесет',
    'шестдесет',
    'седемдесет',
    'осемдесет',
    'деветдесет'
];
const overHundred = ['', 'сто', 'двеста', 'триста'];
const returnSingleDigit = (index: number, isFraction: boolean = false): string => {
    const singleDigits = [
        '',
        `${isFraction ? 'една' : 'един'}`,
        `${isFraction ? 'две' : 'два'}`,
        'три',
        'четири',
        'пет',
        'шест',
        'седем',
        'осем',
        'девет'
    ];

    return singleDigits[index];
};

const translateSingleDigit = (word: string, value: number, isFraction: boolean) =>
    `${word}${returnSingleDigit(Math.trunc(value), isFraction)} `;

const translateBelowTwenty = (word: string, value: number) => `${word}${doubleDigit[Math.trunc(value - ten)]} `;

const translateBelowHundred = (word: string, value: number, isFraction: boolean) => {
    const two = 2;
    const remaining = translate(value % ten, isFraction);
    return `${word}${belowHundred[Math.trunc((value - (value % ten)) / ten - two)]}${
        value % ten > 0 ? ' и ' : ''
    }${remaining}`;
};

const translateBelowMillion = (word: string, value: number, isFraction: boolean) => {
    let wordChanged = word;
    const twoThousand = 2000;
    if (value >= thousand && value < twoThousand) {
        wordChanged = `${wordChanged}  хиляда `;
    } else {
        wordChanged = `${wordChanged}${translate(Math.trunc(value / thousand), isFraction).trim()} хиляди `;
    }
    const remaining = translate(value % thousand, isFraction);
    return `${wordChanged}${
        (value % thousand > 0 && value % hundred === 0) ||
        (value % thousand > 0 &&
            value % thousand < hundred &&
            ((value % hundred > 0 && value % hundred < twenty) || (value % hundred) % ten === 0))
            ? 'и '
            : ''
    }${remaining}`;
};

const translateBelowBillion = (word: string, value: number, isFraction: boolean) => {
    let wordChanged = `${word}${translate(Math.trunc(value / million), isFraction).trim()}`;
    if (value >= million && value < million) {
        wordChanged = `${wordChanged} милион `;
    } else {
        wordChanged = `${wordChanged} милиона `;
    }
    const remaining = translate(value % million, isFraction);
    return `${wordChanged}${value % thousand === 0 ? 'и ' : ''}${remaining}`;
};

const translateBillions = (word: string, value: number, isFraction: boolean) =>
    `${word}${translate(Math.trunc(value / billion), isFraction).trim()} милиард ${translate(
        value % billion,
        isFraction
    )}`;

const translateBelowThousand = (word: string, value: number, isFraction: boolean) => {
    let wordChanged = word;
    const one = 1;
    const four = 4;
    const hundredsValue = value / hundred;
    const tenthsValue = value % hundred;
    const remaining = translate(value % hundred, isFraction);
    if (hundredsValue >= one && hundredsValue < four) {
        wordChanged = `${wordChanged}${overHundred[Math.trunc(value / hundred)]}`;
    } else {
        wordChanged = `${wordChanged}${returnSingleDigit(Math.trunc(value / hundred), isFraction)}стотин`;
    }
    return `${wordChanged}${
        value % hundred > 0
            ? `${(tenthsValue > 0 && tenthsValue < twenty) || tenthsValue % ten === 0 ? ' и' : ''} ${remaining}`
            : ''
    }`;
};

const translate = (value: number, isFraction: boolean) => {
    let word = '';
    if (value < ten) {
        word = translateSingleDigit(word, value, isFraction);
    } else if (value < twenty) {
        word = translateBelowTwenty(word, value);
    } else if (value < hundred) {
        word = translateBelowHundred(word, value, isFraction);
    } else if (value < thousand) {
        word = translateBelowThousand(word, value, isFraction);
    } else if (value < million) {
        word = translateBelowMillion(word, value, isFraction);
    } else if (value < billion) {
        word = translateBelowBillion(word, value, isFraction);
    } else {
        word = translateBillions(word, value, isFraction);
    }
    return word;
};

const numberToWords = (value: number, isFraction: boolean = false) => {
    if (value < 0) {
        return false;
    }
    if (value === 0) {
        return 'нула';
    }
    const result = translate(value, isFraction);
    return result.trim();
};

export const financeUtil = {
    numberToWordWithDecimal(value: string) {
        const digitParts = 2;
        const nums = value.toString().split('.');
        let whole = numberToWords(Number(nums[0]));
        whole = `${whole} лв.`;
        if (nums.length === digitParts) {
            //При закръгляне се получава следния проблем 2.40 се закръглят на 2.4 и се получава грешен резултат, затова добавяме ръчно 0;
            if (nums[1].length === 1) {
                nums[1] = `${nums[1]}0`;
            }
            let fraction = numberToWords(Number(nums[1]), true);
            fraction = `${fraction} ст.`;
            return `${whole} и ${fraction}`;
        }
        return whole;
    }
};
/* eslint-enable  @typescript-eslint/no-use-before-define */
