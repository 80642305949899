<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">ПРИЛОЖЕНИЕ 2 -ДЕЙНОСТИ СВЪРЗАНИ С МАЙЧИНОТО ЗДРАВЕОПАЗВАНЕ</h3></v-col>
            <v-col cols="12"><h3 class="mb-2">1. Посещения на бременни и родилки</h3></v-col>
        </v-row>
        <DataTableComponent v-model="value.pregnancyVisits" :headers="headers" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Annex2PregnancyVisitDto } from '@/model/Report/Rhi/Annex2/Annex2PregnancyVisitDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class PregnancyVisit extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Annex2PregnancyVisitDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: 'Шифър', value: 'code' },
            { text: 'В консултацията', value: 'totalAmbulatory' },
            { text: 'По домовете', value: 'totalDomestic' }
        ];
    }
</script>
