import { plainToInstance } from 'class-transformer';

import { AlgorithmFormTypeCode } from '@/enum/Exam/AlgorithmFormTypeCode';
import { AlgorithmTypeCode } from '@/enum/Nomenclature/AlgorithmTypeCode';
import { ReferralAlgorithmListsModel } from '@/model/Exam/Referral/ReferralAlgorithmListsModel';
import { AlgorithmDto } from '@/model/Nomenclature/AlgorithmDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const algorithmService = {
    async getActiveNhifAlgorithmsByFormType(
        algorithmFormTypeCode: AlgorithmFormTypeCode
    ): Promise<ReferralAlgorithmListsModel> {
        const response = await httpService.get<AlgorithmDto[]>(`/Algorithm/GetActiveNhifAlgorithmsByFormType`, {
            params: { algorithmFormTypeCode }
        });
        const algorithms = plainToInstance(AlgorithmDto, response.data);

        // Направления 7, 8 и 8а показват по два списъка с алгоритми. Вторият списък винаги е с амбулатори процедури,
        // а първият е с клинични пътеки или с клинични процедури. По-горе двата списъка се зареждат с една заявка, а тук се разделят.
        // Кодовете на алгоритмите са във формат AlXnnn, където X указва дали е КП, КПр или АПр.
        const algTypePos = 2;
        const model = new ReferralAlgorithmListsModel();
        model.clinicalAlgorithms = algorithms.filter(
            (alg) => alg.code[algTypePos] !== AlgorithmTypeCode.AmbulatoryProcedure
        );
        model.ambulatoryProcedures = algorithms.filter(
            (alg) => alg.code[algTypePos] === AlgorithmTypeCode.AmbulatoryProcedure
        );
        return model;
    }
};
