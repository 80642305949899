import { NhisLongtermCareDto } from '@/model/Nhis/LongtermCare/NhisLongtermCareDto';
import { RiskGroupDeregisterReasonDto } from '@/model/Nomenclature/RiskGroupDeregisterReasonDto';
import { RiskGroupRuleDto } from '@/model/Register/RiskGroup/RiskGroupRuleDto';

export class RiskGroupRegisterDto {
    public id: number = 0;
    public patientId: number = 0;
    public patientIdentifier: string | null = null;
    public patientName: string | null = null;
    public ambulatorySheetNumber: number | null = null;
    public examId: number | null = null;
    public icdCodes: string[] = [];
    public startDate: Date | null = null;
    public endDate: Date | null = null;
    public riskGroupRule: RiskGroupRuleDto = new RiskGroupRuleDto();
    public riskGroupRuleId: number = 0;
    public riskGroupDeregisterReason: RiskGroupDeregisterReasonDto = new RiskGroupDeregisterReasonDto();
    public description: string = '';
    public nhisLongtermCare: NhisLongtermCareDto = new NhisLongtermCareDto();
}
