<template>
    <v-row>
        <v-col cols="0" md="2" class="pr-0">
            <Menu v-if="menuItems" :items="menuItems" />
        </v-col>
        <v-col cols="12" md="10" class="pl-0">
            <v-container fluid>
                <loading-animation></loading-animation>
                <router-view v-if="viewIsVisible" :key="$route.fullPath" :name="routerName" />
            </v-container>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import LoadingAnimation from '@/component/Common/LoadingAnimation.vue';
    import Menu from '@/component/Menu/Menu.vue';
    import { MenuItemModel } from '@/model/Menu/MenuItemModel';

    @Component({
        components: { LoadingAnimation, Menu }
    })
    export default class LeftMenuLayout extends Vue {
        @Prop({ required: true })
        private menuItems!: MenuItemModel[] | null;

        @Prop({ required: true })
        private routerName!: string;

        @Prop({ default: true })
        private viewIsVisible!: boolean;
    }
</script>
