<template>
    <v-container>
        <v-dialog v-model="dialogIsVisible" persistent :max-width="600">
            <v-form ref="editWorkTimeDialogForm" v-model="isFormValid">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Промяна на работно време</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col class="py-1">
                                    <text-field
                                        v-model="dto.name"
                                        required
                                        class="required"
                                        label="Заглавие"
                                    ></text-field>
                                </v-col>
                            </v-row>
                            <!--                                                        <v-row>
                                <v-col class="py-1">
                                    <dropdown
                                        v-model="selectedDoctorId"
                                        :items="doctors"
                                        required
                                        label="Лекар"
                                    ></dropdown>
                                </v-col>
                            </v-row>-->
                            <!-- Специалност -->
                            <!--                            <v-row>
                                <v-col class="py-1">
                                    <dropdown
                                        v-model="dto.specialtyCode"
                                        :items="selectedDoctorSpecialties"
                                        required
                                        label="Специалност"
                                    ></dropdown>
                                </v-col>
                            </v-row>-->
                            <!-- Тип-->
                            <v-row>
                                <v-col class="py-1">
                                    <dropdown
                                        v-model="dto.workTimeVisitReasonId"
                                        label="Тип"
                                        :items="workTimeVisitReasons"
                                        required
                                    >
                                    </dropdown>
                                </v-col>
                            </v-row>
                            <v-row v-if="!hasRecurrence">
                                <v-col class="py-1">
                                    <date-picker v-model="dto.date" :required="!hasRecurrence" label="Дата" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <time-picker v-model="timeFrom" format="24hr" required label="От" />
                                </v-col>
                                <v-col>
                                    <time-picker v-model="timeTo" format="24hr" required label="До" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="dto.recurrence" class="py-0">
                                    <v-checkbox v-model="hasRecurrence" label="Повторение"></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row v-if="hasRecurrence">
                                <v-col class="py-1">
                                    <dropdown
                                        v-model="dto.recurrence.rRuleRecurrenceType"
                                        :items="recurrenceTypes"
                                        :required="hasRecurrence"
                                        label="Тип повторение"
                                    ></dropdown>
                                </v-col>
                                <v-col class="py-1">
                                    <dropdown
                                        v-model="dto.recurrence.interval"
                                        :items="intervals"
                                        :required="hasRecurrence"
                                        label="Интервал на повторение"
                                    ></dropdown>
                                </v-col>
                                <v-col class="py-1">
                                    <text-field
                                        v-model="dto.recurrence.count"
                                        type="number"
                                        label="Брой повторения"
                                    ></text-field>
                                </v-col>
                            </v-row>
                            <v-row v-if="hasRecurrence">
                                <v-col class="py-1">
                                    <date-picker
                                        v-model="dto.recurrence.startDate"
                                        :required="hasRecurrence"
                                        label="Начална дата"
                                    />
                                </v-col>
                                <v-col class="py-1">
                                    <date-picker v-model="dto.recurrence.endDate" label="Крайна дата" />
                                </v-col>
                            </v-row>
                            <v-row v-if="hasRecurrence">
                                <v-col class="py-0">
                                    <v-checkbox
                                        v-model="dto.changeAllNextRecurrences"
                                        label="Промяна на цялата серия след началната дата"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <btn action="Delete" text @click="deleteEvent" />
                        <v-spacer></v-spacer>
                        <btn action="Cancel" text @click="closeDialog(false)" />
                        <btn action="Save" @click="saveEvent" />
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import TimePicker from '@/component/Date/TimePicker.vue';
    import { RecurrenceTypeDto } from '@/model/Nomenclature/RecurrenceTypeDto';
    import { WorkTimeVisitReasonDto } from '@/model/Nomenclature/WorkTimeVisitReasonDto';
    import { EventEditDto } from '@/model/WorkTime/EventEditDto';
    import { EventRecurrenceDto } from '@/model/WorkTime/EventRecurrenceDto';
    import { recurrenceTypeService } from '@/service/Nomenclature/RecurrenceTypeService';
    import { workTimeVisitReasonService } from '@/service/Nomenclature/WorkTimeVisitReasonService';
    import { workTimeService } from '@/service/WorkTime/WorkTimeService';
    import { dateUtil } from '@/util/DateUtil';

    @Component({
        components: { TimePicker }
    })
    export default class EditEventDialog extends Vue {
        @Prop({ required: true })
        private show!: boolean;

        @Prop()
        private eventId!: number | null;

        @Prop()
        private dateToChange!: Date | null;

        @Prop()
        private newStartTime!: Date | null;

        @Prop() newEndTime!: Date | null;

        private isFormValid: boolean = false;
        private dialogIsVisible: boolean = false;
        private hasRecurrence: boolean = false;
        private timeFrom: string = '';
        private timeTo: string = '';
        private dto: EventEditDto = new EventEditDto();

        private workTimeVisitReasons: WorkTimeVisitReasonDto[] = [];
        private recurrenceTypes: RecurrenceTypeDto[] = [];

        private intervals: { id: number; name: string }[] = [
            {
                id: 1,
                name: 'Всички'
            },
            {
                id: 2,
                name: 'През 1'
            },
            {
                id: 3,
                name: 'През 2 '
            },
            {
                id: 4,
                name: 'През 3'
            },
            {
                id: 5,
                name: 'През 4'
            }
        ];

        private async loadEventData() {
            this.$loading.show();
            try {
                this.dto = await workTimeService.getEventById(this.eventId);
                this.hasRecurrence = this.dto.recurrence ? true : false;

                if (this.dateToChange) {
                    if (this.dto.recurrence) {
                        this.dto.recurrence.startDate = this.dateToChange;
                    } else {
                        this.dto.date = this.dateToChange;
                    }
                }
                if (!this.newStartTime) {
                    this.timeFrom = dateUtil.getFormattedTimeString(this.dto.startHour, this.dto.startMinute);
                }
                if (!this.newEndTime) {
                    this.timeTo = dateUtil.getFormattedTimeString(this.dto.endHour, this.dto.endMinute);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async saveEvent(): Promise<void> {
            (this.$refs.editWorkTimeDialogForm as Vue & { validate: () => boolean }).validate();

            if (this.isFormValid) {
                this.addNewTimeToDto();
                await workTimeService.editEvent(this.dto);
                this.closeDialog(true);
                this.$notifier.showSuccess('Успех', 'Успешно редактирано работно време');
            }
        }

        private deleteEvent() {
            this.dto.date = this.dateToChange;
            this.$emit('onDelete', this.dto);
        }

        private closeDialog(isSaved: boolean) {
            this.dialogIsVisible = false;
            this.$emit('onClose', isSaved);
        }

        private async getWorkTimeVisitReasonTypes() {
            this.workTimeVisitReasons = (await workTimeVisitReasonService.getWorkTimeVisitReasons()).data;
        }

        private async getRecurrenceTypes() {
            this.recurrenceTypes = (await recurrenceTypeService.getRecurrenceTypes()).data;
        }

        private mounted() {
            this.getWorkTimeVisitReasonTypes();
            this.getRecurrenceTypes();
        }

        private addNewTimeToDto() {
            const timeFrom = this.getHourAndMinuteFromString(this.timeFrom);
            this.dto.startHour = timeFrom.hour;
            this.dto.startMinute = timeFrom.minute;

            const timeTo = this.getHourAndMinuteFromString(this.timeTo);
            this.dto.endHour = timeTo.hour;
            this.dto.endMinute = timeTo.minute;
        }

        private getHourAndMinuteFromString(time: string) {
            const [hour, minute] = time.split(':');
            const radix = 10;

            return { hour: parseInt(hour, radix), minute: parseInt(minute, radix) };
        }

        @Watch('show')
        private onShowChanged() {
            if (this.show) {
                if (this.newStartTime) {
                    this.timeFrom = dateUtil.getFormattedTimeString(
                        this.newStartTime.getHours(),
                        this.newStartTime.getMinutes()
                    );
                }

                if (this.newEndTime) {
                    this.timeTo = dateUtil.getFormattedTimeString(
                        this.newEndTime.getHours(),
                        this.newEndTime.getMinutes()
                    );
                }

                this.loadEventData();
            }
            this.dialogIsVisible = this.show;
        }

        @Watch('hasRecurrence')
        private onHasRecurrenceChanged() {
            if (this.hasRecurrence && !this.dto.recurrence) {
                this.dto.recurrence = new EventRecurrenceDto();
            } else {
                // TODO:
                //this.dto.recurrence = null;
            }
        }
    }
</script>
