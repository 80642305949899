<template>
    <v-card-text>
        <v-row>
            <v-col cols="12">
                <v-textarea
                    v-model="value.diseaseStated"
                    label="Установенни заболявания (на бременността и общи):"
                    dense
                />
            </v-col>
            <v-col cols="12">
                <v-textarea v-model="value.treatment" label="Проведено лечение:" dense />
            </v-col>
            <v-col cols="12">
                <v-textarea v-model="value.specializedTests" label="Проведени специализирани лечения:" dense />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <pregnancy-data-table-component
                    v-model="value.pregnancyCondition"
                    :headers="pregnancyConditionHeaders"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <text-field v-model="value.skeleton" label="Особености на таза и скелета:" />
            </v-col>
            <v-col cols="6" md="3">
                <text-field v-model="value.breasts" label="Гръдни жлези:" />
            </v-col>
            <v-col cols="6" md="3">
                <text-field v-model="value.nipples" label="Зърна:" />
            </v-col>
            <v-col cols="12" md="6">
                <text-field v-model="value.psychoprofile" label="Психопрофил подготовка - не, да:" />
            </v-col>
            <v-col cols="12">
                <text-field v-model="value.fruitPosition" label="Положение на плода - позиция, вид:" />
            </v-col>
            <v-col cols="12" md="6">
                <text-field v-model="value.presentation" label="Предлежание:" />
            </v-col>
            <v-col cols="12" md="6">
                <text-field v-model="value.childHearthTone" label="Детски сърдечни тонове:" />
            </v-col>
            <v-col cols="12">
                <v-textarea v-model="value.notes" label="Особени забележки:" dense />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <pregnancy-data-table-component v-model="value.bloodTests" :headers="bloodTestHeaders" />
            </v-col>
            <v-col cols="12" md="6">
                <v-textarea v-model="value.antibodies" label="Установени антитела в серума на бременната:" dense />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <text-field v-model="value.birthPrognosis" label="Прогноза за раждането:" />
            </v-col>
            <v-col cols="12">
                <v-textarea v-model="value.additionalTests" label="Допълнителни изследвания:" dense />
            </v-col>
            <v-col cols="12">
                <text-field v-model="value.lastTests" label="Последни изследвания:" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <pregnancy-data-table-component v-model="value.lastTestsResult" :headers="lastTestsResultHeaders" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-textarea v-model="value.otherNotes" label="Други бележки:" dense />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="3">
                <date-picker v-model="value.date" label="Дата:" />
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { CurrentPregnancyDto } from '@/model/Exam/Referral/PregnancyReferral/CurrentPregnancyDto';
    import PregnancyDataTableComponent from '@/view/Register/Pregnancy/PregnancyCard/PregnancyDataTableComponent.vue';

    @Component({
        components: { PregnancyDataTableComponent }
    })
    export default class CurrentPregnancyData extends Vue {
        @Prop()
        value!: CurrentPregnancyDto;

        private get pregnancyConditionHeaders(): IDataTableHeader[] {
            return [
                { text: '', value: 'name', filterable: false },
                { text: 'Тегло', value: 'weight', filterable: false },
                { text: 'Ръст', value: 'height', filterable: false },
                { text: 'R/R', value: 'rr', filterable: false },
                { text: 'Албумин в урината', value: 'albumin', filterable: false },
                { text: 'Hb', value: 'hb', filterable: false }
            ];
        }

        private get bloodTestHeaders(): IDataTableHeader[] {
            return [
                { text: '', value: 'name', filterable: false },
                { text: 'Кръвна група', value: 'bloodType', filterable: false },
                { text: 'Rh', value: 'rh', filterable: false }
            ];
        }

        private get lastTestsResultHeaders(): IDataTableHeader[] {
            return [
                { text: '', value: 'name', filterable: false },
                { text: 'Резултат', value: 'result', filterable: false },
                { text: 'Дата', value: 'date', filterable: false }
            ];
        }
    }
</script>
