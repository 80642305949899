<template>
    <left-menu-layout :menu-items="menuItems" router-name="report" />
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import LeftMenuLayout from '@/component/Menu/LeftMenuLayout.vue';
    import { createReportMenuItems } from '@/model/Menu/ReportMenuItems';

    @Component({
        components: { LeftMenuLayout }
    })
    export default class ReportMenu extends Vue {
        private get menuItems() {
            return createReportMenuItems();
        }
    }
</script>
