<template>
    <div>
        <v-card class="mb-md-4">
            <v-card-title>
                Справка Регулативни стандарти за даден лекар
                <v-spacer></v-spacer>
                <btn action="Back" :to="`/Practice/RegulatoryStandard/List/${practiceId}`"></btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <dropdown
                            v-model="selectedEmployee"
                            :items="doctorEmployees"
                            item-text="uinNameAndSpecialty"
                            return-object
                            label="Изберете лекар"
                            required
                            @input="onDoctorChange"
                        />
                    </v-col>
                    <v-col>
                        <dropdown
                            v-model="selectedRegStandard"
                            :items="regulatoryStandardItems"
                            :item-text="formatTextResult"
                            return-object
                            label="Изберете договор / тримесечие"
                            clearable
                            @input="onRegStandardChange"
                        />
                    </v-col>
                </v-row>
                <alert v-if="selectedEmployee && missingRegStandardForDoctor" type="warning">
                    За {{ selectedEmployee.doctorName }} - {{ selectedEmployee.specialtyName }} няма регулативни
                    стандарти или няма зададени за това тримесечие
                </alert>
            </v-card-text>

            <v-card-title class="pt-0">
                Период на издаване на направленията&nbsp;
                <small class="text--secondary">
                    (за сравнение, може да въведете период, различен от този на договора)
                </small>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <date-picker
                            v-model="startDate"
                            label="Начална Дата"
                            :disabled="!selectedEmployee"
                            @input="loadUsedRegStandardForDoctor"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <date-picker
                            v-model="endDate"
                            label="Крайна Дата"
                            :disabled="!selectedEmployee"
                            @input="loadUsedRegStandardForDoctor"
                        />
                    </v-col>
                </v-row>

                <v-radio-group v-model="segment" row>
                    <v-radio label="Общ брой" value="totalCount"></v-radio>
                    <v-radio label="МН бл.3" value="referral3"></v-radio>
                    <v-radio :label="`МН бл.3${isSpecialist ? 'A' : ''} тип 7`" value="referral3Type7"></v-radio>
                    <v-radio label="МН бл. 3а" value="referral3a"></v-radio>
                    <v-radio label="МДН бл. 4" value="worthReferral4"></v-radio>
                    <v-radio label="МДН бл. 4 ЯМР" value="worthReferral4Mri"></v-radio>
                </v-radio-group>
            </v-card-text>
        </v-card>

        <div v-if="segment === 'totalCount'">
            <regulatory-standard-progress
                title="1. Брой издавани „Медицинско направление за консултация или за провеждане на съвместно лечение” (бл.МЗ - НЗОК №3)"
                :used-reg-standards="usedRegStandardForDoctor.countReferral3Type124"
                :max-reg-standards="regStandardForDoctor.countReferral3Type124"
            />
            <regulatory-standard-progress
                v-if="!isSpecialist"
                title="2. Брой издавани „Медицинско направление за консултация или за провеждане на съвместно лечение” (бл. МЗ-НЗОК №3) за тип 7"
                :used-reg-standards="usedRegStandardForDoctor.countReferral3Type7"
                :max-reg-standards="regStandardForDoctor.countReferral3Type7"
            />
            <regulatory-standard-progress
                v-else
                title="2. Брой издавани „Медицинско направление за високоспециализирани дейности” (бл. МЗ-НЗОК №3А)"
                :used-reg-standards="usedRegStandardForDoctor.countSpecialistReferral3aAllTypes"
                :max-reg-standards="regStandardForDoctor.countSpecialistReferral3aAllTypes"
            />
            <regulatory-standard-progress
                title="3. Стойност на назначената с „Направление за медико-диагностична дейност” (бл. МЗ-НЗОК №4)"
                :used-reg-standards="usedRegStandardForDoctor.worthReferral4AllTypes"
                :max-reg-standards="regStandardForDoctor.worthReferral4AllTypes"
            />
            <regulatory-standard-progress
                title="4. Стойност на назначената с „Ядрено-магнитен резонанс” (бл. МЗ-НЗОК №4)"
                :used-reg-standards="usedRegStandardForDoctor.worthReferral4Mri"
                :max-reg-standards="regStandardForDoctor.worthReferral4Mri"
            />
            <regulatory-standard-progress
                v-if="!isSpecialist"
                title="5. Брой издавани Медицинско направление за високоспециализирани дейности (бл. МЗ-НЗОК №3А) за пакет Анестезиология и интезивно лечение и диспансерно наблюдение"
                :used-reg-standards="usedRegStandardForDoctor.countReferral3aType4"
                :max-reg-standards="regStandardForDoctor.countReferral3aType4"
            />
        </div>

        <v-slide-x-transition mode="out-in">
            <div v-if="segment === 'referral3'">
                <regulatory-standard-progress
                    title="Брой Бл.МЗ-НЗОК №3 (за тип 1 и 2)"
                    :used-reg-standards="usedRegStandardForDoctor.countReferral3Type12"
                    :max-reg-standards="regStandardForDoctor.countReferral3Type12"
                />
                <regulatory-standard-progress
                    title="Брой бл.МЗ-НЗОК №3 (за тип 4)"
                    :used-reg-standards="usedRegStandardForDoctor.countReferral3Type4"
                    :max-reg-standards="regStandardForDoctor.countReferral3Type4"
                />
            </div>
        </v-slide-x-transition>

        <v-slide-x-transition mode="out-in">
            <div v-if="segment === 'referral3Type7'">
                <regulatory-standard-progress
                    :title="`Брой бл.МЗ-НЗОК №3${isSpecialist ? 'A' : ''} (за тип 7)`"
                    :used-reg-standards="usedRegStandardForDoctor.countReferral3Type7"
                    :max-reg-standards="regStandardForDoctor.countReferral3Type7"
                />
            </div>
        </v-slide-x-transition>

        <div v-if="segment === 'referral3a'">
            <regulatory-standard-progress
                v-if="isSpecialist"
                title="Брой бл.МЗ-НЗОК №3А (за типовете извън 4)"
                :used-reg-standards="usedRegStandardForDoctor.countReferral3aType126"
                :max-reg-standards="regStandardForDoctor.countReferral3aType126"
            />
            <regulatory-standard-progress
                title="Брой бл.МЗ-НЗОК №3А (за тип 4)"
                :used-reg-standards="usedRegStandardForDoctor.countReferral3aType4"
                :max-reg-standards="regStandardForDoctor.countReferral3aType4"
            />
        </div>

        <v-slide-x-transition mode="out-in">
            <div v-if="segment === 'worthReferral4'">
                <regulatory-standard-progress
                    :title="
                        isSpecialist
                            ? 'Стойност на бл.МЗ-НЗОК №4 (за тип 1,2 и 6)'
                            : 'Стойност на бл.МЗ-НЗОК №4 (за тип 1,2,6 и 8)'
                    "
                    :used-reg-standards="usedRegStandardForDoctor.worthReferral4Type1268"
                    :max-reg-standards="regStandardForDoctor.worthReferral4Type1268"
                />
                <regulatory-standard-progress
                    title="Стойност на бл.МЗ-НЗОК №4 (за тип 4)"
                    :used-reg-standards="usedRegStandardForDoctor.worthReferral4Type4"
                    :max-reg-standards="regStandardForDoctor.worthReferral4Type4"
                />
                <regulatory-standard-progress
                    title="Стойност на бл.МЗ-НЗОК №4 (за тип 7)"
                    :used-reg-standards="usedRegStandardForDoctor.worthReferral4Type7"
                    :max-reg-standards="regStandardForDoctor.worthReferral4Type7"
                />
                <regulatory-standard-progress
                    title="Стойност на бл.МЗ-НЗОК №4 (за тип 9)"
                    :used-reg-standards="usedRegStandardForDoctor.worthReferral4Type9"
                    :max-reg-standards="regStandardForDoctor.worthReferral4Type9"
                />
                <regulatory-standard-progress
                    title="Стойност на бл.МЗ-НЗОК №4 (за тип 10)"
                    :used-reg-standards="usedRegStandardForDoctor.worthReferral4Type10"
                    :max-reg-standards="regStandardForDoctor.worthReferral4Type10"
                />
            </div>
            <div v-if="segment === 'worthReferral4Mri'">
                <regulatory-standard-progress
                    title="Стойност на бл.МЗ-НЗОК №4 (за ЯМР)"
                    :used-reg-standards="usedRegStandardForDoctor.worthReferral4Mri"
                    :max-reg-standards="regStandardForDoctor.worthReferral4Mri"
                />
            </div>
        </v-slide-x-transition>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import RegulatoryStandardProgress from '@/component/Exam/RegulatoryStandardProgress.vue';
    import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
    import { RegulatoryStandardDoctorDto } from '@/model/Practice/RegulatoryStandard/RegulatoryStandardDoctorDto';
    import { RegulatoryStandardDto } from '@/model/Practice/RegulatoryStandard/RegulatoryStandardDto';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { regulatoryStandardService } from '@/service/Practice/RegulatoryStandardService';
    import { dateUtil } from '@/util/DateUtil';

    @Component({
        components: { RegulatoryStandardProgress }
    })
    export default class RegulatoryStandardReference extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private doctorEmployees: EmployeeTitleDto[] = [];
        private selectedEmployee: EmployeeTitleDto | null = null;

        private regulatoryStandardItems: RegulatoryStandardDto[] = [];
        private selectedRegStandard: RegulatoryStandardDto | null = null;

        private startDate: Date | null = null;
        private endDate: Date | null = null;

        private regStandardForDoctor: RegulatoryStandardDoctorDto = new RegulatoryStandardDoctorDto();
        private missingRegStandardForDoctor: boolean = false;
        private usedRegStandardForDoctor: RegulatoryStandardDoctorDto = new RegulatoryStandardDoctorDto();

        private segment: string = 'totalCount';
        private gpSpecialtyName: string = 'Обща медицина';

        private get isSpecialist() {
            return this.selectedEmployee ? this.selectedEmployee.specialtyName !== this.gpSpecialtyName : false;
        }

        private async mounted() {
            this.doctorEmployees = await employeeService.getDoctorEmployees(this.practiceId);
            this.regulatoryStandardItems = await regulatoryStandardService.getRegStandardsForPractice(this.practiceId);
        }

        private formatTextResult(item: RegulatoryStandardDto | null) {
            return `Договор: ${item?.contractNumber} от: ${dateUtil.formatBgDate(
                item?.startDate ?? ''
            )}г. до: ${dateUtil.formatBgDate(item?.endDate ?? '')}г.`;
        }

        private onDoctorChange() {
            return this.loadRegStandardForDoctor(true);
        }

        private onRegStandardChange() {
            // При clear не избрания договор, текущият договор не се избира автоматично.
            return this.loadRegStandardForDoctor(false);
        }

        private async loadRegStandardForDoctor(loadCurrent: boolean) {
            let loaded: RegulatoryStandardDoctorDto | null = null;

            if (this.selectedEmployee) {
                if (this.selectedRegStandard) {
                    loaded = await regulatoryStandardService.getRegStandardForDoctor(
                        this.practiceId,
                        this.selectedRegStandard.seqNumber,
                        this.selectedEmployee.employeeSeqNumber
                    );
                    this.missingRegStandardForDoctor = !loaded;
                } else if (loadCurrent) {
                    const current = await regulatoryStandardService.getCurrentRegStandardForDoctor(
                        this.practiceId,
                        this.selectedEmployee.employeeSeqNumber
                    );
                    if (current) {
                        this.selectedRegStandard =
                            this.regulatoryStandardItems.find(
                                (rs) => rs.seqNumber === current.regulatoryStandardSeqNumber
                            ) ?? null;
                        loaded = current;
                    }
                    this.missingRegStandardForDoctor = !loaded;
                } else {
                    this.missingRegStandardForDoctor = false;
                }
            } else {
                this.missingRegStandardForDoctor = false;
            }

            this.regStandardForDoctor = loaded ?? new RegulatoryStandardDoctorDto();

            if (this.selectedRegStandard) {
                this.startDate = this.selectedRegStandard.startDate;
                this.endDate = this.selectedRegStandard.endDate;
            }
            await this.loadUsedRegStandardForDoctor();
        }

        private async loadUsedRegStandardForDoctor() {
            this.usedRegStandardForDoctor =
                this.selectedEmployee && this.startDate && this.endDate
                    ? await regulatoryStandardService.getUsedRegStandardByDoctor(
                          this.practiceId,
                          this.selectedEmployee.employeeSeqNumber,
                          this.startDate,
                          this.endDate
                      )
                    : new RegulatoryStandardDoctorDto();
        }
    }
</script>
