<template>
    <div>
        <btn v-if="showOpenBtn && value" action="Details" block @click="openFile">Отваряне на {{ btnName }}</btn>
        <btn v-if="showDownloadBtn && value" action="Download" block @click="downloadFile">
            Изтегляне на {{ btnName }}
        </btn>
        <btn v-if="showPrintBtn && value" action="Print" block @click="printFile"> Принтиране на {{ btnName }} </btn>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { base64Service } from '@/service/File/Base64Service';

    @Component
    export default class Base64ToFile extends Vue {
        @Prop()
        value!: string;

        @Prop({ default: 'application/pdf' })
        type!: string;

        @Prop()
        showDownloadBtn!: boolean;

        @Prop()
        showOpenBtn!: boolean;

        @Prop()
        showPrintBtn!: boolean;

        @Prop()
        instantDownloadFile!: boolean;

        @Prop()
        instantOpenFile!: boolean;

        @Prop()
        instantPrintFile!: boolean;

        @Prop({ default: 'file' })
        fileName!: string;

        @Prop({ default: 'файл' })
        btnName!: string;

        private processFile() {
            if (this.instantDownloadFile) {
                this.downloadFile();
            } else if (this.instantOpenFile) {
                this.openFile();
            } else if (this.instantPrintFile) {
                this.printFile();
            }
        }

        private downloadFile() {
            base64Service.downloadFile(this.value, this.type, this.fileName);
        }

        private openFile() {
            base64Service.openFile(this.value, this.type);
        }

        private printFile() {
            base64Service.printFile(this.value, this.type);
        }

        @Watch('value', { immediate: true })
        onValueChange(newVal: string): void {
            if (newVal) {
                this.processFile();
            }
        }
    }
</script>
