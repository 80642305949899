//В Hippocrates-S при създаване на нова бланка брой препарати се попълва по подразбиране 2
const defaultPreparationCount = 2;

export class RequestForCytosmearDto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();
    public isRiskRaised: boolean = false;
    public note: string = '';
    public menopauseFrom: Date | null = null;
    public lastRegularMenstruation: Date | null = null;
    public preparationCount: number | null = defaultPreparationCount;
    public code: string = '';
    public ourCode: string = '';
    public yourCode: string = '';
}
