<template>
    <scheduled-exams-calendar
        :event-id="eventId"
        :date="date"
        @onUpdateSelected="onUpdateSelected"
        @dateSelected="onDateSelected"
    ></scheduled-exams-calendar>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ScheduledExamsCalendar from './ScheduledExamsCalendar.vue';

    @Component({
        components: {
            ScheduledExamsCalendar
        }
    })
    export default class ExamSchedule extends Vue {
        @Prop()
        private eventId!: number | null;

        @Prop()
        private date!: Date | null;

        private onDateSelected(eventId: number, date: Date) {
            this.$router.push(`/Reception/ExamSchedule/Create/${eventId}/${date?.toISOString()}`);
        }

        private onUpdateSelected(eventId: number, date: Date, id: number) {
            this.$router.push(`/Reception/ExamSchedule/Create/${eventId}/${date?.toISOString()}/${id}`);
        }
    }
</script>
