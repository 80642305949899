export class PrescriptionNhifViewDto {
    public id: number = 0;
    public issueDate: Date | null = null;
    public number: string = '';
    public segment: string = '';
    public diagnosis1Code: string | null = null;
    public diagnosis2Code: string | null = null;
    public diagnosis3Code: string | null = null;
    public drug1Code: string | null = null;
    public drug2Code: string | null = null;
    public drug3Code: string | null = null;
    public nrn: string | null = null;
    public status: string | null = null;
    public statusCode: string | null = null;
    public statusTime: string | null = null;
    public executionDate: string | null = null;
}
