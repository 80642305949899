import { Type } from 'class-transformer';

import { EmployeeTitleDto } from './EmployeeTitleDto';

export class EmployeeRowDto {
    @Type(() => EmployeeTitleDto)
    public employee: EmployeeTitleDto = new EmployeeTitleDto();

    public pendingEmployeeUsers: string[] = [];
    public pendingDeputyUsers: string[] = [];
}
