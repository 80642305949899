<template>
    <v-card>
        <v-card-title><h5>Минали посещения през регистратура</h5></v-card-title>
        <data-table :items="items" :headers="headers">
            <template #rowActions="{ item }">
                <btn v-if="$allowWrite" icon action="Fill" @click="fillOrder(item)" />
            </template>
        </data-table>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ReceptionServiceCreateCommand } from '@/model/Reception/Service/ReceptionServiceCreateCommand';
    import { ReceptionVisitDto } from '@/model/Reception/Service/ReceptionVisitDto';
    import { receptionService } from '@/service/Reception/ReceptionService';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: {}
    })
    export default class ReceptionVisit extends Vue {
        @Prop()
        command!: ReceptionServiceCreateCommand;

        private items: ReceptionVisitDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата на посещение', value: 'createdOn', formatter: formatters.date },
                { text: 'Брой услуги', value: 'ordersCount' },
                { text: 'Крайна цена', value: 'totalPrice' }
            ];
        }

        public async getReceptionVisits(patientId: number) {
            this.items = await receptionService.getReceptionVisitByPatient(patientId);
        }

        private fillOrder(item: ReceptionVisitDto) {
            this.$emit('fill', item);
        }

        @Watch('command.patient.key.patientId', { deep: true })
        private async onPatientChange(newVal: number, oldVal: number) {
            if (newVal && newVal !== oldVal) {
                await this.getReceptionVisits(newVal);
            }
        }
    }
</script>
