<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="regStandard.regStandardDoctors"
            no-data-text="Няма записи"
            disable-pagination
            hide-default-footer
        >
            <template #top>
                <v-toolbar flat>
                    <v-toolbar-title>
                        Брой/стойност на назначаваните СМД и МДД от лечебното заведение по лекари специалисти за периода
                    </v-toolbar-title>
                    <template v-if="unusedDoctorEmployees.length">
                        <v-spacer></v-spacer>
                        <btn action="New" @click="beginAddDoctor">Добавяне на Запис</btn>
                    </template>
                </v-toolbar>
            </template>
            <template #[`item.rowActions`]="{ item }">
                <td class="rowActions">
                    <span class="float-right">
                        <btn icon action="Edit" @click="beginEditDoctor(item)" />
                        <btn icon action="Delete" @click="removeDoctor(item)" />
                    </span>
                </td>
            </template>
            <template #[`body.prepend`]>
                <tr v-if="regStandard.regStandardDoctors.length">
                    <th :colspan="2">Всичко</th>
                    <td class="title">{{ sumText('countZol') }}</td>
                    <td class="title">{{ sumText('worthReferral4Type1268') }}</td>
                    <td class="title">{{ sumText('countReferral3Type12') }}</td>
                    <td v-if="!isGP" class="title">{{ sumText('countReferral3aType126') }}</td>
                    <td class="title">{{ sumText('worthReferral4Type4') }}</td>
                    <td class="title">{{ sumText('countReferral3Type4') }}</td>
                    <td class="title">{{ sumText('countReferral3aType4') }}</td>
                    <td class="title">{{ sumText('worthReferral4Type10') }}</td>
                    <td class="title">{{ sumText('worthReferral4Type9') }}</td>
                    <td class="title">{{ sumText('countReferral3Type7') }}</td>
                    <td class="title">{{ sumText('worthReferral4Type7') }}</td>
                    <td v-if="isGP" class="title">{{ sumText('countReferral3Type1Women') }}</td>
                    <td class="title">{{ sumText('worthReferral4Mri') }}</td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogIsVisible">
            <v-form ref="documentForm" v-model="isValid">
                <v-card>
                    <v-card-title>
                        {{ isAdding ? 'Добавяне на запис' : 'Промяна на запис' }}
                        <v-spacer></v-spacer>
                        <btn icon action="Close" @click="dialogIsVisible = false" />
                    </v-card-title>
                    <!--Тук се създава нов елемент-->
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="8" md="9">
                                <dropdown
                                    v-if="isAdding"
                                    v-model="editedItem.doctorEmployeeSeqNumber"
                                    :items="unusedDoctorEmployees"
                                    item-value="employeeSeqNumber"
                                    item-text="uinNameAndSpecialty"
                                    label="Изберете лекар"
                                    required
                                />
                                <text-field v-else v-model="selectedDoctorEmployeeText" read-only></text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3">
                                <text-field
                                    v-model.number="editedItem.countZol"
                                    :label="
                                        isGP ? 'Брой ЗОЛ с осигурителни права' : 'Базисен брой за първични посещения'
                                    "
                                    type="number"
                                    required
                                    :rules="[$validator.digitsOnly]"
                                ></text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="3">
                                <text-field
                                    v-model.number="editedItem.worthReferral4Type1268"
                                    :label="
                                        isGP
                                            ? 'Стойност на бл.МЗ-НЗОК №4 (за тип 1,2,6 и 8)'
                                            : 'Стойност на бл.МЗ-НЗОК №4 (за тип 1,2 и 6)'
                                    "
                                    type="number"
                                    required
                                ></text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3">
                                <text-field
                                    v-model.number="editedItem.countReferral3Type12"
                                    label="Брой Бл.3 (за тип 1 и 2)"
                                    type="number"
                                    required
                                ></text-field>
                            </v-col>
                            <v-col v-if="!isGP" cols="12" sm="4" md="3">
                                <text-field
                                    v-model.number="editedItem.countReferral3aType126"
                                    label="Брой бл. МЗ-НЗОК №3А (за типовете извън 4)"
                                    type="number"
                                ></text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3">
                                <text-field
                                    v-model="editedItem.worthReferral4Type4"
                                    type="number"
                                    label="Стойност на бл.МЗ-НЗОК №4 (за тип 4)"
                                ></text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="3">
                                <text-field
                                    v-model="editedItem.countReferral3Type4"
                                    type="number"
                                    label="Брой бл.МЗ-НЗОК №3 (за тип 4)"
                                ></text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3">
                                <text-field
                                    v-model="editedItem.countReferral3aType4"
                                    type="number"
                                    label="Брой бл.МЗ-НЗОК №3А (за тип 4)"
                                ></text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3">
                                <text-field
                                    v-model="editedItem.worthReferral4Type10"
                                    type="number"
                                    label="Стойност на бл.МЗ-НЗОК №4 (за тип 10) програма 'Детско здравеопазване'"
                                ></text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3">
                                <text-field
                                    v-model="editedItem.worthReferral4Type9"
                                    type="number"
                                    label="Стойност на бл.МЗ-НЗОК №4 (за тип 9) програма 'Майчино здравеопазване'"
                                ></text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="3">
                                <text-field
                                    v-model="editedItem.countReferral3Type7"
                                    type="number"
                                    :label="isGP ? 'Брой бл.МЗ-НЗОК №3 (за тип 7)' : 'Брой бл.МЗ-НЗОК №3A (за тип 7)'"
                                ></text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3">
                                <text-field
                                    v-model="editedItem.worthReferral4Type7"
                                    type="number"
                                    label="Стойност на бл.МЗ-НЗОК №4 (за тип 7) профилактика на ЗОЛ над18 г."
                                ></text-field>
                            </v-col>
                            <v-col v-if="isGP" cols="12" sm="4" md="3">
                                <text-field
                                    v-model="editedItem.countReferral3Type1Women"
                                    type="number"
                                    label="Брой бл.МЗ-НЗОК №3 (за тип 1) по повод профилактика за жени от 30 до 40 години"
                                ></text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3">
                                <text-field
                                    v-model="editedItem.worthReferral4Mri"
                                    type="number"
                                    label="Стойност за ЯМР на бл.МЗ-НЗОК №4"
                                ></text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <btn action="Save" @click="saveEditedItemToGrid">{{ isAdding ? 'Добавяне' : 'Промяна' }}</btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
    import { RegulatoryStandardDoctorDto } from '@/model/Practice/RegulatoryStandard/RegulatoryStandardDoctorDto';
    import { RegulatoryStandardDto } from '@/model/Practice/RegulatoryStandard/RegulatoryStandardDto';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { arrayUtil } from '@/util/ArrayUtil';

    const numberPrecision = 2;

    @Component
    export default class RegulatoryStandardDoctorList extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private regStandard!: RegulatoryStandardDto;

        private editedItem: RegulatoryStandardDoctorDto = new RegulatoryStandardDoctorDto();
        private deletingItem: RegulatoryStandardDoctorDto = new RegulatoryStandardDoctorDto();
        private dialogIsVisible: boolean = false;
        private isAdding: boolean = false;
        private doctorEmployees: EmployeeTitleDto[] = [];
        private isValid: boolean = false;
        private gpSpecialtyName: string = 'Обща медицина';

        private async mounted() {
            this.$loading.show();
            try {
                this.doctorEmployees = await employeeService.getDoctorEmployees(this.practiceId);
            } finally {
                this.$loading.hide();
            }
        }

        public get unusedDoctorEmployees() {
            const used = this.regStandard.regStandardDoctors.map((doc) => doc.doctorEmployeeSeqNumber);
            return this.doctorEmployees.filter((de) => !used.includes(de.employeeSeqNumber));
        }

        public get selectedDoctorEmployeeText() {
            return this.doctorEmployees.find((de) => de.employeeSeqNumber === this.editedItem.doctorEmployeeSeqNumber)
                ?.uinNameAndSpecialty;
        }

        private get isGP() {
            if (userContextCache.current) {
                return userContextCache.isGP();
            }
            return true;
        }

        public get headers() {
            let headers = this.getBaseHeaders();
            headers = this.addConditionalHeaders(headers);
            return headers;
        }

        getBaseHeaders() {
            return [
                { text: 'УИН', value: 'doctorUin' },
                { text: 'Име и Фамилия', value: 'doctorFullName' }
            ];
        }

        addCommonHeaders(headers: IDataTableHeader[]) {
            headers.push(
                { text: 'Стойност на бл.МЗ-НЗОК №4 (за тип 4)', value: 'worthReferral4Type4' },
                { text: 'Брой бл.МЗ-НЗОК №3 (за тип 4)', value: 'countReferral3Type4' },
                { text: 'Брой бл.МЗ-НЗОК №3А (за тип 4)', value: 'countReferral3aType4' },
                {
                    text: 'Стойност на бл.МЗ-НЗОК №4 (за тип 10) програма "Детско здравеопазване"',
                    value: 'worthReferral4Type10'
                },
                {
                    text: 'Стойност на бл.МЗ-НЗОК №4 (за тип 9) програма "Майчино здравеопазване"',
                    value: 'worthReferral4Type9'
                }
            );
        }

        addConditionalHeaders(headers: IDataTableHeader[]) {
            if (!this.isGP) {
                headers.push({ text: 'Базисен брой за първични посещения', value: 'countZol' });
                headers.push({ text: 'Стойност на бл.МЗ-НЗОК №4 (за тип 1,2 и 6)', value: 'worthReferral4Type1268' });
            } else {
                headers.push({ text: 'Брой ЗОЛ с осигурителни права', value: 'countZol' });
                headers.push({ text: 'Стойност на бл.МЗ-НЗОК №4 (за тип 1,2,6 и 8)', value: 'worthReferral4Type1268' });
            }
            headers.push({ text: 'Брой Бл.МЗ-НЗОК №3 (за тип 1 и 2)', value: 'countReferral3Type12' });
            if (!this.isGP) {
                headers.push({
                    text: 'Брой бл. МЗ-НЗОК №3А (за типовете извън 4)',
                    value: 'countReferral3aType126'
                });
            }
            this.addCommonHeaders(headers);
            if (this.isGP) {
                headers.push({ text: 'Брой бл.МЗ-НЗОК №3 (за тип 7)', value: 'countReferral3Type7' });
            } else {
                headers.push({ text: 'Брой бл.МЗ-НЗОК №3A (за тип 7)', value: 'countReferral3Type7' });
            }
            headers.push({
                text: 'Стойност на бл.МЗ-НЗОК №4 (за тип 7) профилактика на ЗОЛ над18 г.',
                value: 'worthReferral4Type7'
            });
            if (this.isGP) {
                headers.push({
                    text: 'Брой бл.МЗ-НЗОК №3 (за тип 1) по повод профилактика за жени от 30 до 40 години',
                    value: 'countReferral3Type1Women'
                });
            }
            this.addLastCommonHeaders(headers);
            return headers;
        }

        private addLastCommonHeaders(headers: IDataTableHeader[]) {
            headers.push(
                { text: 'Стойност за ЯМР на бл.МЗ-НЗОК №4', value: 'worthReferral4Mri' },
                { text: 'Опции', value: 'rowActions', sortable: false }
            );
        }

        private beginAddDoctor() {
            this.isAdding = true;
            this.editedItem = new RegulatoryStandardDoctorDto();
            this.dialogIsVisible = true;
        }

        private beginEditDoctor(item: RegulatoryStandardDoctorDto) {
            this.isAdding = false;
            this.editedItem = Object.assign({}, item);
            this.dialogIsVisible = true;
        }

        private saveEditedItemToGrid() {
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

            if (!this.isValid) {
                return;
            }

            // Допълват се УИН и име на избрания лекар, за да се попълнят първите колони в grid-а.
            const selectedDoctorEmployee = this.doctorEmployees.find(
                (de) => de.employeeSeqNumber === this.editedItem.doctorEmployeeSeqNumber
            );
            if (selectedDoctorEmployee) {
                this.editedItem.doctorUin = selectedDoctorEmployee.doctorUin;
                this.editedItem.doctorFullName = selectedDoctorEmployee.doctorName;
            }

            const regStandardDoctor = this.regStandard.regStandardDoctors.find(
                (doc) => doc.doctorEmployeeSeqNumber === this.editedItem.doctorEmployeeSeqNumber
            );
            if (regStandardDoctor) {
                // Избраният в диалога лекар вече присъства в grid-а.
                Object.assign(regStandardDoctor, this.editedItem);
            } else {
                // Избраният в диалога лекар се добавя в grid-а.
                this.regStandard.regStandardDoctors.push(this.editedItem);
            }

            this.dialogIsVisible = false;
        }

        private removeDoctor(item: RegulatoryStandardDoctorDto) {
            arrayUtil.removeFirst(this.regStandard.regStandardDoctors, item);
        }

        private sumText(propertyName: keyof RegulatoryStandardDoctorDto) {
            return this.regStandard.sumField(propertyName).toFixed(numberPrecision);
        }
    }
</script>

<style>
    .rowActions {
        position: sticky;
        right: 0;
    }
</style>
