import { AxiosResponse } from 'axios';

import { PreventiveExamNotRegisterIncludedDto } from '@/model/Query/Exam/PreventiveExamNotRegisterIncludedDto';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const preventiveExamNotRegisterIncludedService = {
    getExamNotRegisterIncluded(
        filter: ReportIntervalFilterDto
    ): Promise<AxiosResponse<PreventiveExamNotRegisterIncludedDto[]>> {
        return httpService.post('PreventiveExamNotRegisterIncluded/GetExamNotRegisterIncluded', filter);
    }
};
