<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Медицинско направление за ТЕЛК" :items="items">
                <template v-if="allowCreate" #actions>
                    <btn action="New" :to="`/Exam/ReferralTelk/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/ReferralTelk/Edit/${item.id}`" />
                    <btn v-if="canSendForm && !item.nrn" icon action="Send" @click="postReferral(item)" />
                    <btn icon action="Print" :to="`/Print/Exam.Referral.ReferralTelk/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { NhisReferralStatusCode } from '@/enum/Nhis/NhisReferralStatusCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ReferralTelkViewDto } from '@/model/Exam/Referral/ReferralTelkViewDto';
    import { referralTelkService } from '@/service/Exam/Referral/ReferralTelkService';
    import { nhisReferralService } from '@/service/Nhis/NhisReferralService';
    import { nhisService } from '@/service/Nhis/NhisService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class ReferralTelkView extends Vue {
        @Prop()
        examId!: number;

        private items: ReferralTelkViewDto[] = [];
        private allowCreate: boolean = false;

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Номер:', value: 'number' },
                { text: 'Изпраща се за:', value: 'referralTelkSubtype' },
                { text: 'Диагнози:', value: 'diagnoses' },
                { text: 'НРН:', value: 'nrn' },
                { text: 'Статус:', value: 'status' },
                { text: 'Дата на проверка:', value: 'statusTime', formatter: formatters.dateTime },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private get canSendForm() {
            return (
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private async postReferral(item: ReferralTelkViewDto) {
            this.$loading.show();
            try {
                if (!item.statusCode) {
                    const requestData = nhisService.requestData(item.id, 'ReferralTelk');
                    const response = await nhisReferralService.postReferral(requestData);
                    if (response) {
                        this.$notifier.showSuccess('', `Успешно изпратен документ с НРН: ${response.nrn}`);
                        await this.loadItems();
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async loadItems() {
            this.$loading.show();
            try {
                this.$loading.show();
                const response = await referralTelkService.getReferralsByExamId(this.examId);
                if (response?.data) {
                    this.items = response.data;
                    this.allowTelkCreate(this.items);
                }
            } finally {
                this.$loading.hide();
            }
        }

        // Ако няма неизпратено и анулирано направление се позволява създаването на ново направление.
        private allowTelkCreate(items: ReferralTelkViewDto[]) {
            if (items.some((item) => item.statusCode === null)) {
                this.allowCreate = false;
            } else if (items.some((item) => item.statusCode !== NhisReferralStatusCode.Canceled)) {
                this.allowCreate = false;
            } else {
                this.allowCreate = true;
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
