<template>
    <v-stepper v-model="currentStep">
        <v-stepper-header>
            <v-stepper-step step="1">Избор на практика</v-stepper-step>
            <v-stepper-step step="2">{{ step2Title }}</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <practice-select class="pa-2" @ready="onPracticeReady" />
                <v-checkbox
                    v-model="isDeputyDoctor"
                    dense
                    label="Аз съм заместващ лекар по смисъла на НЗОК и не работя в практиката"
                ></v-checkbox>
                <v-row>
                    <v-col cols="12" md="3">
                        <btn action="Next" block color="primary" :disabled="disableNextButton" @click="nextClicked" />
                    </v-col>
                </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
                <doctor-deputy-create
                    v-if="isDeputyDoctor && practice && practice.id"
                    class="pa-2"
                    :practice-id="practice.id"
                />
                <doctor-employee-create v-else-if="practice && practice.id" class="pa-2" :practice-id="practice.id" />
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { PracticeSelectDto } from '@/model/Practice/PracticeSelectDto';
    import { practiceService } from '@/service/Practice/PracticeService';
    import PracticeSelect from '@/view/Practice/PracticeSelect.vue';

    import DoctorDeputyCreate from './DoctorDeputyCreate.vue';
    import DoctorEmployeeCreate from './DoctorEmployeeCreate.vue';

    const initialStep = 1;

    @Component({
        components: { PracticeSelect, DoctorEmployeeCreate, DoctorDeputyCreate }
    })
    export default class Registration extends Vue {
        private practice: PracticeSelectDto | null = null;
        private currentStep: number = initialStep;
        private disableNextButton: boolean = true;
        private isDeputyDoctor: boolean = false;

        private get step2Title(): string {
            return this.isDeputyDoctor ? 'Титуляр и заместващ' : 'Лекар и специалност';
        }

        private onPracticeReady(practice: PracticeSelectDto): void {
            if (practice) {
                this.disableNextButton = practice.name.length === 0;
                this.practice = practice;
            } else {
                this.disableNextButton = true;
                this.practice = null;
            }
        }

        private async nextClicked() {
            if (this.practice) {
                this.practice.id = await practiceService.createOrUpdatePractice(this.practice);
            }
            this.currentStep = 2;
        }
    }
</script>
