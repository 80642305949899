import { Type } from 'class-transformer';

import { Annex4ChildrenPreventiveResultRowDto } from '@/model/Report/Rhi/Annex4/Annex4ChildrenPreventiveResultRowDto';

export class Annex4ChildrenPreventiveResultDto {
    public name: string = '';

    @Type(() => Annex4ChildrenPreventiveResultRowDto)
    public childrenPreventiveResults: Annex4ChildrenPreventiveResultRowDto[] = [];
}
