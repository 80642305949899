import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';

export class EventFilterCommand {
    constructor(
        public practiceId: number,
        public doctorId: number | null,
        public specialtyCode: SpecialtyCode | null,
        public workTimeVisitReasonId: number | null
    ) {}
}
