import { AxiosResponse } from 'axios';

import { FundDto } from '@/model/Nomenclature/FundDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const fundService = {
    getFunds(): Promise<AxiosResponse<FundDto[]>> {
        return httpService.get('/Fund/GetFunds');
    }
};
