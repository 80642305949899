<template>
    <left-menu-layout :menu-items="menuItems" router-name="help" />
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import LeftMenuLayout from '@/component/Menu/LeftMenuLayout.vue';
    import { createHelpMenuItems } from '@/model/Menu/HelpMenuItems';

    @Component({
        components: { LeftMenuLayout }
    })
    export default class HelpMenu extends Vue {
        private get menuItems() {
            return createHelpMenuItems();
        }
    }
</script>
