<template>
    <ExamLayout
        :can-delete="document.id > 0 && !disableEdit"
        :can-print="document.id > 0"
        :can-save="!disableEdit"
        title="Медицинско направление - Бланка 3"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <div v-if="limitRegStandards">
            <v-slide-y-transition mode="out-in">
                <regulatory-standard-progress
                    :title="'Брой бл.МЗ-НЗОК №3 Общо'"
                    :used-reg-standards="usedRegStandards.countReferral3AllTypes"
                    :max-reg-standards="limitRegStandards.countReferral3AllTypes"
                />
            </v-slide-y-transition>
        </div>
        <nhis-referral
            v-if="showNhisReferral"
            v-model="document.nhisReferral"
            :referral-id="document.id"
            :form-type="'Referral3'"
            @input="refreshRegulatoryStandardData"
        />
        <v-row>
            <v-col cols="12">
                <v-card class="mb-1" :disabled="disableEdit" :style="`background-color: ${backgroundColor}`">
                    <main-title>Медицинско направление - Бланка 3</main-title>
                    <v-card-text>
                        <AmbulatorySheetHeaderInfoCard v-once :background-color="backgroundColor" />
                    </v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6" md="2">
                                <date-picker v-model="document.issueDate" label="Дата" required />
                            </v-col>
                            <v-col cols="6" md="2">
                                <text-field v-model="document.number" label="Номер" required />
                            </v-col>
                            <v-col cols="6" md="2">
                                <fund-picker v-model="document.fundCode" />
                            </v-col>
                            <v-col cols="12" md="5">
                                <specialty-picker
                                    v-model="document.specialtyCode"
                                    required
                                    @input="onSpecialtyCodeChange"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col md="2">
                                <text-field v-model="document.ambulatorySheetNumber" disabled label="Амб. лист номер" />
                            </v-col>
                            <v-col md="4">
                                <dropdown
                                    v-model="document.referralSubtypeId"
                                    :items="referralSubtypes"
                                    label="Тип направление"
                                    required
                                ></dropdown>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="mb-1" :disabled="disableEdit" :style="`background-color: ${backgroundColor}`">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="6" md="4">
                                <v-textarea v-model="document.diagnosesText" label="Диагнози" />
                            </v-col>
                            <v-col cols="6" md="2">
                                <ExamDiagnosisPicker
                                    v-model="examDiagnosis1"
                                    required
                                    @input="fillDefaultDiagnosesText"
                                />
                                <ExamDiagnosisPicker v-model="examDiagnosis2" @input="fillDefaultDiagnosesText" />
                                <ExamDiagnosisPicker v-model="examDiagnosis3" @input="fillDefaultDiagnosesText" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col cols="12" md="7">
                                        <text-field disabled label="рег. номер на лечебно заведение" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" md="2">
                                        <text-field disabled label="код специалност" />
                                    </v-col>
                                    <v-col cols="12" md="7">
                                        <text-field disabled label="УИН на лекаря" />
                                    </v-col>
                                </v-row>
                                <text-field disabled label="Име и фамилия" />
                                <v-row>
                                    <v-col md="4"><date-picker label="Дата" disabled /></v-col>
                                </v-row>
                                <v-textarea
                                    v-model="document.specialistAmbulatorySheetInfo"
                                    label="Информация от върнат от специалиста АЛ"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card :disabled="disableEdit" :style="`background-color: ${backgroundColor}`">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-textarea v-model="document.reason" label="Изпраща се за" />
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-textarea v-model="document.attachedDocs" label="Приложени мед. документи" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import ExamDiagnosisPicker from '@/component/Exam/ExamDiagnosisPicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import RegulatoryStandardProgress from '@/component/Exam/RegulatoryStandardProgress.vue';
    import FundPicker from '@/component/Picker/FundPicker.vue';
    import SpecialtyPicker from '@/component/Picker/SpecialtyPicker.vue';
    import { NhifVisitReasonCode } from '@/enum/Exam/NhifVisitReasonCode';
    import { ReferralTypeCode } from '@/enum/Exam/ReferralTypeCode';
    import { Referral3Dto } from '@/model/Exam/Referral/Referral3Dto';
    import { ReferralSubtypeDto } from '@/model/Nomenclature/ReferralSubtypeDto';
    import { CurrentRegulatoryStandardDoctorDto } from '@/model/Practice/RegulatoryStandard/CurrentRegulatoryStandardDoctorDto';
    import { RegulatoryStandardDoctorDto } from '@/model/Practice/RegulatoryStandard/RegulatoryStandardDoctorDto';
    import { examService } from '@/service/Exam/ExamService';
    import { referral3Service } from '@/service/Exam/Referral/Referral3Service';
    // Notification service
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { notifierService } from '@/service/Infrastructure/NotifierService';
    import { referralSubtypeService } from '@/service/Nomenclature/ReferralSubtypeService';
    import { regulatoryStandardService } from '@/service/Practice/RegulatoryStandardService';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { specialtyCache } from '@/store/Nomenclature/SpecialtyCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';
    import AmbulatorySheetHeaderInfoCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetHeaderInfoCard.vue';
    import NhisReferral from '@/view/Exam/Referral/NhisReferral.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: {
            FundPicker,
            AmbulatorySheetHeaderInfoCard,
            NhisReferral,
            UnsavedChanges,
            ExamDiagnosisPicker,
            ExamLayout,
            RegulatoryStandardProgress,
            SpecialtyPicker
        },
        async beforeRouteLeave(to, from, next) {
            if (this?.$data?.leaveDialogRef) {
                await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
            } else {
                next();
            }
        }
    })
    export default class Referral3 extends Vue {
        @Ref() readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private get backgroundColor() {
            return !userSettingsState.userSettings.ui.hasReferralsBackground
                ? 'transparent'
                : userSettingsState.userSettings.ui.isDarkTheme
                  ? '#8f948e'
                  : '#d5dcd4';
        }

        private get disableEdit() {
            return this.document?.nhisReferral?.nrn?.length > 0;
        }

        private get userContexts() {
            return userContextCache.userContexts;
        }

        private get examDiagnosis1() {
            return currentVisitCache.value.exam?.getExamDiagnosis(this.document.diagnosis1Id);
        }

        private set examDiagnosis1(examDiagnosis) {
            this.document.diagnosis1Id = examDiagnosis ? examDiagnosis.id : null;
        }

        private get examDiagnosis2() {
            return currentVisitCache.value.exam?.getExamDiagnosis(this.document.diagnosis2Id);
        }

        private set examDiagnosis2(examDiagnosis) {
            this.document.diagnosis2Id = examDiagnosis ? examDiagnosis.id : null;
        }

        private get examDiagnosis3() {
            return currentVisitCache.value.exam?.getExamDiagnosis(this.document.diagnosis3Id);
        }

        private set examDiagnosis3(examDiagnosis) {
            this.document.diagnosis3Id = examDiagnosis ? examDiagnosis.id : null;
        }

        private get showNhisReferral() {
            return this.document.id > 0 && this.document.nhisReferral;
        }

        private referralSubtypes: ReferralSubtypeDto[] = [];
        private document: Referral3Dto = new Referral3Dto();
        private initialValue: Referral3Dto = new Referral3Dto();
        private limitRegStandards: CurrentRegulatoryStandardDoctorDto | null = null;
        private usedRegStandards: RegulatoryStandardDoctorDto = new RegulatoryStandardDoctorDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                // Тип направление
                this.referralSubtypes = await (await referralSubtypeService.getReferral3Subtypes()).data;

                if (!this.documentId) {
                    const responseExam = await referral3Service.getReferral3InfoByExamId(this.examId);
                    this.document = responseExam.data;
                    this.fillDefaultDiagnosesText();
                    await this.getCorrectReferralSubtype();
                } else {
                    const responseReferral = await referral3Service.getReferral3(this.documentId);
                    this.document = responseReferral.data;
                    if (!this.document.diagnosesText) {
                        this.fillDefaultDiagnosesText();
                    }
                    await currentVisitCache.load(this.document.examId);
                }
                await this.getRegulatoryStandards();
            } finally {
                this.$loading.hide();
            }
        }

        private async getRegulatoryStandards() {
            if (currentVisitCache.isLoaded) {
                this.limitRegStandards = await regulatoryStandardService.getCurrentRegStandardForDoctor(
                    currentVisitCache.value.patientVersionKey.practiceId,
                    currentVisitCache.value.doctorEmployeeSeqNumber
                );
                if (this.limitRegStandards) {
                    this.usedRegStandards = await regulatoryStandardService.getUsedRegStandardByDoctor(
                        currentVisitCache.value.patientVersionKey.practiceId,
                        currentVisitCache.value.doctorEmployeeSeqNumber,
                        this.limitRegStandards.startDate ?? new Date(),
                        this.limitRegStandards.endDate ?? new Date()
                    );
                }
            }
        }

        private async getCorrectReferralSubtype() {
            const visitReasonCode = await examService.getExamVisitReason(this.examId);
            let subtypeName = '';
            switch (visitReasonCode) {
                case NhifVisitReasonCode.Dispensary: {
                    subtypeName = 'Диспансерно интердисциплинарно наблюдение';
                    break;
                }
                case NhifVisitReasonCode.ChildPrevention: {
                    subtypeName = 'Детско здравеопазване';
                    break;
                }
                case NhifVisitReasonCode.MotherPrevention: {
                    subtypeName = 'Майчино здравеопазване';
                    break;
                }
                case NhifVisitReasonCode.AdultPrevention: {
                    subtypeName = 'Профилактика на ЗОЛ над 18';
                    break;
                }
                default: {
                    subtypeName = 'Остро';
                }
            }
            this.document.referralSubtypeId =
                this.referralSubtypes.find((type) => type.name.includes(subtypeName.toLowerCase()))?.id ??
                this.referralSubtypes[0].id;
        }

        private async save() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                const title = `№ ${this.document.number}; ${
                    specialtyCache.items.find((spec) => spec.code === this.document.specialtyCode)?.name
                }`;
                if (documentId === 0) {
                    const refResponse = await referral3Service.createReferral3(this.document);
                    documentId = Number(refResponse.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, ReferralTypeCode.Referral3, title);
                    this.$router.push(`/Exam/Referral3/Edit/${documentId}`);
                } else {
                    await referral3Service.updateReferral3(this.document);
                    this.copyObject();
                    eventBus.$emit('update-referral-event', title);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                const errors = (await referral3Service.canSaveReferral3(this.document)).data;
                if (errors.length <= 0) {
                    await this.save();
                } else {
                    const errorMessages: string[] = [];
                    for (let errorPos: number = 0; errorPos < errors.length; errorPos++) {
                        errorMessages.push(errors[errorPos]);
                    }
                    if (errors.length > 0) {
                        this.$notifier.showError('Грешка', errorMessages.join(', '));
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                const title = `№ ${this.document.number}`;
                await referral3Service.deleteReferral3(documentId);
                this.copyObject();
                this.$router.push(`/Exam/Referral3/Create/${currentVisitCache.value.id}`);
                eventBus.$emit('delete-referral-event', documentId, ReferralTypeCode.Referral3, title);
            } finally {
                this.$loading.hide();
            }
        }

        private async refreshRegulatoryStandardData() {
            this.copyObject();
            await this.getRegulatoryStandards();
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private fillDefaultDiagnosesText() {
            this.document.diagnosesText = [
                this.examDiagnosis1?.icdName,
                this.examDiagnosis2?.icdName,
                this.examDiagnosis3?.icdName
            ]
                .filter((name) => Boolean(name))
                .join('\r\n');
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Referral.Referral3/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Referral.Referral3';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        async onSpecialtyCodeChange() {
            if (this.document.specialtyCode) {
                const response = (
                    await referral3Service.checkPreviousReferralBySpecialtyCode(
                        this.documentId,
                        this.document.examId,
                        this.document.specialtyCode,
                        false
                    )
                ).data;

                if (response) {
                    notifierService.showWarning('Внимание', response);
                }
            }
        }
    }
</script>
