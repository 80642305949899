<template>
    <ExamLayout
        :hide-toolbar="!isInExam || disableForm"
        :can-delete="model.immunizationSeqNumber > 0"
        @updated="updateImmunization"
        @deleted="deleteImmunization"
    >
        <v-row class="mt-2">
            <v-col>
                <btn action="RefreshBatch" @click="getCovidBatches" />
            </v-col>
        </v-row>
        <nhis-immunization
            v-if="model.immunizationSeqNumber && model.nhisImmunization"
            v-model="model.nhisImmunization"
            class="mt-2"
            :disable="disableNhis"
            :is-nhis-new="!model.previousImmunizationNrn"
            :patient-id="model.patientId"
            :immunization-seq-number="model.immunizationSeqNumber"
            @input="copyObject"
        ></nhis-immunization>
        <v-card :disabled="disableForm || !isInExam" class="mt-2">
            <v-card-title>Създаване на Е-Имунизация</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <immunization-picker
                            v-model="model.immunization"
                            filter-by-nhis
                            @input="onImmunizationChange"
                        />
                    </v-col>
                    <v-col md="3">
                        <dropdown
                            v-model="model.nhisVaccine"
                            :items="vaccines"
                            item-text="name"
                            return-object
                            label="Ваксина"
                            required
                            @input="onVaccineChange"
                        />
                    </v-col>
                    <v-col md="3">
                        <dropdown
                            v-model="model.nhisVaccineDose"
                            :items="vaccineDoses"
                            item-text="numberOfDoses"
                            return-object
                            label="Брой дози за курс"
                            required
                            @input="nextDoseDate"
                        />
                    </v-col>
                    <v-col v-if="model.nhisImmunization.nrn && !model.doctorEmployeeSeqNumber" md="3">
                        <text-field v-model="model.nhisVaccineLot" label="Партида"></text-field>
                    </v-col>
                    <v-col v-else md="3">
                        <v-row no-gutter>
                            <v-col md="10">
                                <dropdown
                                    v-model="model.nhisVaccineLot"
                                    :items="vaccineLots"
                                    item-text="lotNumber"
                                    item-value="lotNumber"
                                    label="Партида"
                                    required
                                    @input="onLotChange"
                                />
                            </v-col>
                            <v-col md="2"> <btn action="Edit" icon @click="isVisible = true" /></v-col>
                        </v-row>
                    </v-col>
                    <v-col md="3">
                        <date-field v-model="model.lotExpirationDate" label="Валидна до" disabled />
                    </v-col>
                    <v-col md="3">
                        <text-field
                            v-model="model.doseNumber"
                            label="Прием номер"
                            required
                            type="number"
                            @input="nextDoseDate"
                        ></text-field>
                    </v-col>
                    <v-col md="3">
                        <text-field v-model="model.doseQuantity" label="Количество (ml)" required></text-field>
                    </v-col>
                    <v-col md="3"
                        ><v-autocomplete
                            v-model="model.applicationBodyPart"
                            :items="applicationsBodyParts"
                            item-text="name"
                            item-value="code"
                            dense
                            label="Част от тялото, на която е поставена ваксината"
                    /></v-col>
                    <v-col md="3"
                        ><v-autocomplete
                            v-model="model.applicationRoute"
                            :items="applicationRoutes"
                            item-text="name"
                            item-value="code"
                            dense
                            label="Как ваксината е влязла в тялото"
                    /></v-col>
                    <v-col md="3">
                        <date-picker
                            v-model="model.occurrenceDate"
                            label="Дата на поставяне"
                            required
                            @input="nextDoseDate"
                        />
                    </v-col>
                    <v-col md="3">
                        <date-picker v-model="model.nextDoseDate" label="Дата на следваща ваксина" />
                    </v-col>
                    <v-col md="3">
                        <icd-picker v-model="model.icdCode" required />
                    </v-col>
                    <v-col v-if="showPreviousImmunizationNrn && !model.isExternalImmunization" md="3">
                        <text-field
                            v-model="model.previousImmunizationNrn"
                            label="Предходна ваксина НРН"
                            :required="!model.isExternalImmunization"
                            :rules="model.isExternalImmunization ? [] : [$validator.nrn]"
                        ></text-field>
                    </v-col>
                    <v-col md="3">
                        <v-checkbox v-model="model.isFromPrimarySource" label="Ваксината е поставена от лекаря" dense />
                    </v-col>
                    <v-col md="3">
                        <v-checkbox v-model="model.isSpecialCase" label="Специален случай" dense />
                    </v-col>
                    <v-col v-if="showMantuResult" cols="6" md="3"
                        ><div>
                            <label>Резултат проба манту:</label>
                            <v-switch
                                v-model="model.mantuResult"
                                :label="model.mantuResult ? 'Положителен' : 'Отрицателен'"
                            ></v-switch></div
                    ></v-col>
                    <v-col v-if="showPreviousImmunizationNrn" md="3">
                        <v-checkbox
                            v-model="model.isExternalImmunization"
                            label="Ваксината е направена преди НЗИС"
                            dense
                        />
                    </v-col>
                </v-row>
                <v-row v-if="model.isExternalImmunization">
                    <v-col md="3">
                        <date-picker
                            v-model="model.externalImmunizationOccurrenceDate"
                            label="Дата на поставяне на предходна ваксина"
                            required
                        />
                    </v-col>
                    <v-col md="3">
                        <text-field
                            v-model="model.externalImmunizationVaccineName"
                            label="Предходна ваксина име"
                            required
                        ></text-field>
                    </v-col>
                    <v-col md="3">
                        <text-field
                            v-model="model.externalImmunizationVaccineLot"
                            label="Предходна ваксина партида"
                            required
                        ></text-field>
                    </v-col>
                    <v-col md="3">
                        <text-field
                            v-model="model.externalImmunizationAuthority"
                            label="Организация, носеща отговорност за ваксинацията"
                            required
                        ></text-field>
                    </v-col>
                    <v-col md="3">
                        <dropdown
                            v-model="model.externalImmunizationCountryId"
                            class="required"
                            :items="countries"
                            label="Код държава"
                            required
                        />
                    </v-col>
                </v-row>
                <dropdown
                    v-model="model.socialGroupId"
                    :items="socialGroups"
                    item-text="name"
                    item-value="id"
                    label="Социална група на пациента"
                    required
                />
                <alert v-if="!showPreviousImmunizationNrn" type="info">
                    За да въведете имунизация поставена извън НЗИС е необходимо да изберете ваксина с повече от една
                    доза и в полето "Прием номер" да въведете съответния прием!
                </alert>
            </v-card-text>
        </v-card>
        <v-dialog v-model="isVisible" full-screen @click:outside="closeDialog">
            <div style="min-height: 17vh">
                <NhisVaccineLotView
                    :practice-id="userContext.currentPracticeId"
                    :doctor-employee-seq-number="userContext.currentDoctorEmployeeSeqNumber"
                    :vaccine-code="vaccineCode"
                    :required="false"
                    @change="closeDialog"
                />
            </div>
        </v-dialog>
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import ImmunizationPicker from '@/component/Immunization/ImmunizationPicker.vue';
    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import { NhisVaccineLotDto } from '@/model/Nhis/VaccineLot/NhisVaccineLotDto';
    import { CountryDto } from '@/model/Nomenclature/CountryDto';
    import { ImmunizationDto } from '@/model/Nomenclature/ImmunizationDto';
    import { NhisDrugApplicationBodyPartDto } from '@/model/Nomenclature/NhisDrugApplicationBodyPartDto';
    import { NhisDrugApplicationRouteDto } from '@/model/Nomenclature/NhisDrugApplicationRouteDto';
    import { NhisSocialGroupDto } from '@/model/Nomenclature/NhisSocialGroupDto';
    import { NhisVaccineDoseDto } from '@/model/Nomenclature/NhisVaccineDoseDto';
    import { NhisVaccineDto } from '@/model/Nomenclature/NhisVaccineDto';
    import { NhisImmunizationDto } from '@/model/Patient/Immunization/NhisImmunizationDto';
    import { NhisPatientImmunizationDto } from '@/model/Patient/Immunization/NhisPatientImmunizationDto';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { nhisImmunizationService } from '@/service/Nhis/NhisImmunizationService';
    import { nhisVaccineLotService } from '@/service/Nhis/NhisVaccineLotService';
    import { countryService } from '@/service/Nomenclature/CountryService';
    import { nhisDrugApplicationBodyPartService } from '@/service/Nomenclature/NhisDrugApplicationBodyPartService';
    import { nhisDrugApplicationRouteService } from '@/service/Nomenclature/NhisDrugApplicationRouteService';
    import { nhisSocialGroupService } from '@/service/Nomenclature/NhisSocialGroupService';
    import { nhisVaccineDoseService } from '@/service/Nomenclature/NhisVaccineDoseService';
    import { nhisVaccineService } from '@/service/Nomenclature/NhisVaccineService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import NhisImmunization from '@/view/Nhis/Immunization/NhisImmunization.vue';
    import NhisVaccineLotView from '@/view/Nhis/VaccineLot/NhisVaccineLotView.vue';

    const covidImmunizationKeys = ['-1000', '-1001', '-1002', '-1003'];
    @Component({
        components: {
            ImmunizationPicker,
            ExamLayout,
            NhisImmunization,
            IcdPicker,
            NhisVaccineLotView
        }
    })
    export default class NhisImmunizationCreateEditor extends Vue {
        @Prop()
        private examId!: number;

        @Prop()
        private patientId!: number;

        @Prop()
        private immunizationSeqNumber!: number;

        private isVisible: boolean = false;
        public model: NhisPatientImmunizationDto = new NhisPatientImmunizationDto();
        public initialValue: NhisPatientImmunizationDto = new NhisPatientImmunizationDto();
        private vaccines: NhisVaccineDto[] = [];
        private vaccineLots: NhisVaccineLotDto[] = [];
        private countries: CountryDto[] = [];
        private socialGroups: NhisSocialGroupDto[] = [];
        private vaccineDoses: NhisVaccineDoseDto[] = [];
        private applicationsBodyParts: NhisDrugApplicationBodyPartDto[] = [];
        private applicationRoutes: NhisDrugApplicationRouteDto[] = [];
        private vaccineCode: string | null = null;

        private isCovidCorrectIcd() {
            const diagnosis = currentVisitCache.value.exam?.examDiagnoses[0];

            if (
                covidImmunizationKeys.includes(this.model.nhisVaccine?.nhisCode ?? '') &&
                diagnosis?.icdCode !== 'U11.9'
            ) {
                this.$notifier.showWarning('Внимание', 'Водещата диагноза на амбулаторния лист трябва да бъде U11.9!');
            }
        }

        private get showPreviousImmunizationNrn() {
            const showCheckBtn = this.model.nhisVaccineDose && this.model.doseNumber > 1;
            if (!showCheckBtn) {
                this.model.isExternalImmunization = false;
            }
            return showCheckBtn;
        }

        private get disableForm() {
            return this.model?.nhisImmunization?.nrn?.length > 0;
        }

        //Изполваме тази проверка, когато сме в регистъра на пациента, там могат само да се преглеждат детайлите на ваксината
        private get disableNhis() {
            return !this.examId && !this.isInExam;
        }

        private get isInExam() {
            return this.$route.path.includes('Exam');
        }

        private get userContext() {
            return userContextCache;
        }

        private nextDoseDate() {
            if (
                this.model.nhisVaccineDose &&
                this.model.doseNumber < this.model.nhisVaccineDose.numberOfDoses &&
                this.model.nhisVaccineDose.nextDoseDays
            ) {
                const defaultHour = 12;
                const date = new Date(this.model.occurrenceDate);
                date.setHours(defaultHour);
                date.setDate(date.getDate() + this.model.nhisVaccineDose.nextDoseDays);
                this.model.nextDoseDate = date;
            } else {
                this.model.nextDoseDate = null;
            }
            this.checkDose();
        }

        private checkDose() {
            if (this.model.nhisVaccineDose && this.model.doseNumber) {
                if (this.model.doseNumber === 1) {
                    this.model.previousImmunizationNrn = null;
                }
                this.model.doseQuantity =
                    this.model.doseNumber <= this.model.nhisVaccineDose?.numberOfDoses
                        ? (this.model.nhisVaccine?.doseQuantity ?? 0)
                        : (this.model.nhisVaccine?.boosterDoseQuantity ?? this.model.nhisVaccine?.doseQuantity ?? 0);
            }
        }

        private async getNhisVaccines(immunizationId: number) {
            this.vaccines = [];
            const response = await nhisVaccineService.getNhisVaccinesByImmunization(
                immunizationId,
                this.model.occurrenceDate
            );
            if (response?.data) {
                this.vaccines = response.data;
            }
        }

        private async onImmunizationChange(immunization: ImmunizationDto) {
            this.model.nhisVaccine = null;
            this.clearObjectFields();

            if (immunization) {
                await this.getNhisVaccines(immunization.id);
            }
        }

        private async closeDialog() {
            this.isVisible = false;
            await this.getVaccineLots(this.model?.nhisVaccine?.code ?? '');
            if (this.vaccineCode === '') {
                this.vaccineCode = null;
            } else {
                this.vaccineCode = '';
            }
        }

        private async onVaccineChange(item: NhisVaccineDto) {
            //Според правило на МЗ от От 27.09.2023 г. за ваксина Comirnaty не се вписва предходна имунизация НРН и се изпращат с прием 1 от 1
            if (item.name !== 'Comirnaty') {
                await this.checkForPreviousImmunization();
            }

            if (this.model.doseQuantity === 0) {
                this.model.doseQuantity = item.doseQuantity;
            }

            this.model.icdCode = item.targetIcdCode;
            this.clearObjectFields();

            this.isCovidCorrectIcd();
            await this.getVaccineDoses(item.code);
            await this.getVaccineLots(item.code);
        }

        private clearObjectFields() {
            this.vaccineLots = [];
            this.model.nhisVaccineLot = '';
            this.model.nhisVaccineDose = null;
            this.immunizationInput();
        }

        private async getVaccineDoses(nhisVaccineCode: string) {
            this.vaccineDoses = await nhisVaccineDoseService.getVaccineDoses(nhisVaccineCode);
        }

        private async checkForPreviousImmunization() {
            this.model.patientId = currentPatientCache.value.key.patientId;
            const { immunizationSeqNumber, examId } = this.model;
            const response = await nhisImmunizationService.getPreviousImmunization(this.model);

            if (response?.data) {
                //Ако намерим имунизация, с ваксина от същата група взимаме данните, но запазваме данните за текущата имунизация
                const [immunization] = [this.model.immunization];
                this.model = response.data;
                this.model.immunizationSeqNumber = immunizationSeqNumber;
                this.model.examId = examId;
                this.model.immunization = immunization;
            }
        }

        private async onLotChange(lotNumber: string): Promise<void> {
            const match = this.vaccineLots.find((lot) => lot.lotNumber === lotNumber);
            if (match) {
                this.model.lotExpirationDate = match.expirationDate;
            } else if (lotNumber && !match) {
                const allVaccineLots = await nhisVaccineLotService.getNhisVaccineLots(
                    currentPatientCache.value.key.practiceId,
                    userContextCache.currentDoctorEmployeeSeqNumber ?? 0,
                    this.model.nhisVaccine?.code ?? ''
                );
                const missingLot = allVaccineLots?.data?.find((lot) => lot.lotNumber === lotNumber);

                if (missingLot) {
                    this.model.lotExpirationDate = missingLot.expirationDate;
                    this.vaccineLots.push(missingLot);
                }
            }
        }

        private async getVaccineLots(nhisVaccineCode: string) {
            const response = await nhisVaccineLotService.getActiveNhisVaccineLots(
                nhisVaccineCode,
                this.examId ?? this.model.examId ?? 0
            );
            if (response?.data) {
                this.vaccineLots = response.data;
            }
        }

        private async getCountries() {
            const response = await countryService.getCountries();
            this.countries = response.data;
        }

        private async getSocialGroups() {
            const response = await nhisSocialGroupService.getSocialGroups();
            this.socialGroups = response.data;
        }

        private async getApplicationsRoutesAndBodyParts() {
            this.applicationsBodyParts = (
                await nhisDrugApplicationBodyPartService.getNhisDrugApplicationBodyParts()
            ).data;
            this.applicationRoutes = (await nhisDrugApplicationRouteService.getNhisDrugApplicationRoutes()).data;
        }

        private async updateImmunization() {
            this.$loading.show();
            try {
                if (!this.model.immunizationSeqNumber) {
                    this.createImmunization();
                } else {
                    if (!this.model.doctorEmployeeSeqNumber) {
                        this.model.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
                    }
                    await nhisImmunizationService.updatePatientImmunization(this.model);
                    this.copyObject();
                    eventBus.$emit('update-referral-event', 'Е-Имунизация');
                    this.refreshMenuItems();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async createImmunization() {
            const { patientId } = currentPatientCache.value.key;
            this.model.patientId = patientId;
            this.model.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
            this.model.examId = this.examId;
            this.model.previousImmunizationNrn =
                this.showPreviousImmunizationNrn && !this.model.isExternalImmunization
                    ? this.model.previousImmunizationNrn
                    : null;
            const response = await nhisImmunizationService.createPatientImmunization(this.model);
            const immunizationSeqNumber = response?.data;
            if (immunizationSeqNumber) {
                this.copyObject();
                eventBus.$emit('create-referral-event', response.data, 'EImmunization', 'Е-Имунизация');
                this.refreshMenuItems();
                await this.$router.push(`/Exam/EImmunization/Edit/${patientId}/${immunizationSeqNumber}`);
            }
        }

        private async deleteImmunization() {
            if (this.patientId && this.immunizationSeqNumber) {
                this.$loading.show();
                try {
                    await nhisImmunizationService.deleteImmunization(this.patientId, this.immunizationSeqNumber);
                    this.copyObject();
                    eventBus.$emit(
                        'delete-referral-event',
                        this.immunizationSeqNumber,
                        'EImmunization',
                        'Е-Имунизация'
                    );
                    this.refreshMenuItems();
                    await this.$router.push(`/Exam/EImmunization/Create/${this.model.examId}`);
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private refreshMenuItems() {
            //Добавено опресняване на менюто при запис, промяна, изтриване на е-имунизация, защото в зависимост
            // от това дали е планова имунизация може да е генерирана обикновена такава
            eventBus.$emit('generate-menu-event');
        }

        private async loadImmunization() {
            const response = await nhisImmunizationService.getImmunization(this.patientId, this.immunizationSeqNumber);

            if (response) {
                this.model = response;
                console.log(this.model);
                await this.getNhisVaccines(this.model.immunization?.id ?? 0);
                await this.getVaccineDoses(this.model.nhisVaccine?.code ?? '');
                await this.getVaccineLots(this.model?.nhisVaccine?.code ?? '');
                if (this.model.doctorEmployeeSeqNumber) {
                    await this.onLotChange(this.model.nhisVaccineLot);
                }
            }
        }

        private async load() {
            this.$loading.show();
            try {
                await this.getCountries();
                await this.getSocialGroups();
                await this.getApplicationsRoutesAndBodyParts();
                if (this.patientId && this.immunizationSeqNumber) {
                    await this.loadImmunization();
                    await currentPatientCache.loadLastVersion(this.model.patientId);
                    if (this.model?.examId) {
                        await currentVisitCache.load(this.model.examId);
                    }
                } else {
                    await currentPatientCache.loadSpecificVersion(currentVisitCache.value.patientVersionKey);
                    this.model.socialGroupId = this.socialGroups.find((group) => group.name === 'Други')?.id ?? null;
                }
            } finally {
                this.$loading.hide();
            }
        }

        @Watch('model.nhisImmunization')
        onNhisImmunizationChange(newVal: NhisImmunizationDto): void {
            if (newVal && newVal.nrn) {
                this.$emit('input', newVal.nrn);
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.model);
        }

        private get showMantuResult() {
            return this.model.immunization?.name?.toLowerCase().startsWith('проба манту') ? true : false;
        }

        private immunizationInput() {
            this.model.mantuResult = this.showMantuResult ? false : null;
        }

        private async getCovidBatches() {
            try {
                this.$loading.show();
                await nhisImmunizationService.getCovidBatches();
                await this.getVaccineLots(this.model?.nhisVaccine?.code ?? '');
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }
    }
</script>
