export class PisFileRowDto {
    public practiceId: number = 0;
    public seqNumber: number = 0;
    public pisFileId: string = '';
    public startDate: string | null = null;
    public endDate: string | null = null;
    public uploadDate: string | null = null;
    public status: string = '';
    public lastCheckDate: string | null = null;
    public errorMessage: string | null = null;
}
