import { userContextCache } from '@/store/User/UserContextCache';

import { MenuItemModel } from './MenuItemModel';

const addPracticeMenuItems = (menuItems: MenuItemModel[], practiceId: number, practiceTitle: string) => {
    menuItems.push(
        new MenuItemModel(practiceTitle, '', ''),
        new MenuItemModel('График за прегледи', 'mdi-calendar', `/Setting/ExamScheduleNew/`),
        new MenuItemModel('Работни времена', 'mdi-calendar', `/Setting/WorkTime/${practiceId}`),
        new MenuItemModel('еБЛ интервали номера', 'mdi-tray', `/Setting/SickSheetCounter/List/${practiceId}`),
        new MenuItemModel('Общи текущи номера', 'mdi-counter', `/Setting/PracticeCounter/List/${practiceId}`)
    );
};

const addDoctorEmployeeMenuItems = (
    menuItems: MenuItemModel[],
    practiceId: number,
    doctorEmployeeSeqNumber: number,
    employeeTitle: string
) => {
    menuItems.push(
        new MenuItemModel(employeeTitle, '', ''),
        new MenuItemModel(
            'Лични текущи номера',
            'mdi-counter',
            `/Setting/DoctorEmployeeCounter/List/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Време за преглед',
            'mdi-calendar-clock-outline',
            `/Setting/ExamDuration/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel('Шаблони', 'mdi-text', `/Setting/Template/List/${practiceId}/${doctorEmployeeSeqNumber}`),
        new MenuItemModel(
            'Партиди на ваксини',
            'mdi-archive-sync-outline',
            `/Setting/NhisVaccineLots/${practiceId}/${doctorEmployeeSeqNumber}`
        )
    );
};

export const createSettingMenuItems = () => {
    const menuItems = [
        new MenuItemModel('Потребителски настройки', 'mdi-account-cog-outline', '/Setting/UserSettings')
    ];

    if (userContextCache.current) {
        const {
            practiceId,
            practiceName,
            employeeSeqNumber,
            employeeDescription,
            doctorId,
            doctorName,
            specialtyName
        } = userContextCache.current;

        // Настройки на текущата практика.

        // Настройки на текущия служител.
        const practiceTitle = practiceName ?? `Практика с id ${practiceId}`;
        addPracticeMenuItems(menuItems, practiceId, practiceTitle);

        // Таблиците от следващите менюта сочат DoctorEmployee, а не какво да е Employee.
        if (doctorId) {
            const employeeTitle = doctorId ? `${doctorName}, ${specialtyName}` : employeeDescription;
            addDoctorEmployeeMenuItems(menuItems, practiceId, employeeSeqNumber, employeeTitle);
        }
    }
    return menuItems;
};
