<template>
    <div>
        <data-table
            v-model="selected"
            title="Е-Имунизации"
            :headers="tableHeaders"
            :items="items"
            disable-pagination
            show-select
            single-select
            item-key="immunizationSeqNumber"
            sort-by="occurrenceDate"
        >
            <template #actions>
                <btn v-if="examId" action="New" :to="`/Exam/EImmunization/Create/${examId}`" />
                <btn action="RefreshBatch" @click="getCovidBatches" />
                <btn action="CheckList" @click="openDialog">Извличане на имунизации</btn>
                <btn
                    v-if="selected[0] && selected[0].certificateIdentifier && selected[0].certificateIdentifier.length"
                    action="Download"
                    @click="postImmunizationCertificateRequest"
                >
                    Сертификат
                </btn>
                <btn action="Print" @click="printImmunWithoutPreview">Печат на имунизации</btn>
            </template>
            <template #rowActions="{ item }">
                <btn
                    v-if="examId"
                    icon
                    action="Edit"
                    :to="`/Exam/EImmunization/Edit/${item.patientId}/${item.immunizationSeqNumber}`"
                />
                <btn v-if="examId && canSendForm && !item.nrn" icon action="Send" @click="postImmunization(item)" />
                <btn
                    v-else
                    icon
                    action="Details"
                    :to="`/Patient/EImmunization/Preview/${item.patientId}/${item.immunizationSeqNumber}`"
                />
            </template>
        </data-table>

        <v-row justify="center" class="mx-5">
            <v-dialog v-model="dialogIsVisible" persistent max-width="75%">
                <NhisImmunizationExtraction
                    :exam-id="examId"
                    :dialog-is-visible="dialogIsVisible"
                    @close="closeDialog"
                    @update="updateGrid"
                    @error="showDialogError"
                />
            </v-dialog>
        </v-row>
        <base64-to-file
            v-if="canGenerateCertificate"
            v-model="selected[0].certificateBase64"
            instant-open-file
            file-name="Immunization certificate"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import Base64ToFile from '@/component/File/Base64ToFile.vue';
    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhisImmunizationDto } from '@/model/Patient/Immunization/NhisImmunizationDto';
    import { NhisImmunizationViewDto } from '@/model/Patient/Immunization/NhisImmunizationViewDto';
    import { nhisImmunizationService } from '@/service/Nhis/NhisImmunizationService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { formatters } from '@/util/Formatters';
    import NhisImmunizationExtraction from '@/view/Nhis/Immunization/NhisImmunizationExtraction.vue';

    @Component({
        components: { NhisImmunizationExtraction, Base64ToFile }
    })
    export default class NhisImmunizationView extends Vue {
        @Prop()
        private examId!: number;

        @Prop()
        private patientId!: number;

        private items: NhisImmunizationViewDto[] = [];
        private selected: NhisImmunizationViewDto[] = [];
        private dialogIsVisible: boolean = false;

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Ваксина име:', value: 'vaccineName' },
                {
                    text: 'Дата на поставяне:',
                    value: 'occurrenceDate',
                    formatter: formatters.date
                },
                {
                    text: 'Амбулаторен лист:',
                    value: 'ambulatorySheet'
                },
                {
                    text: 'Прием:',
                    value: 'doseNumber'
                },
                {
                    text: 'Необходими дози:',
                    value: 'requiredDoses'
                },
                { text: 'Следваща доза дата:', value: 'nextDoseDate', formatter: formatters.date },
                { text: 'НРН', value: 'nrn' },
                { text: 'Статус на ваксината:', value: 'status' }
            ];
        }

        private get canGenerateCertificate() {
            return this.selected?.length > 0 && this.selected[0]?.certificateBase64?.length > 0;
        }

        private get canSendForm() {
            return (
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private async postImmunization(item: NhisImmunizationViewDto) {
            this.$loading.show();
            try {
                if (!item.statusCode) {
                    const response = await nhisImmunizationService.postImmunization(
                        item.patientId,
                        item.immunizationSeqNumber,
                        item.isNewImmunization
                    );
                    if (response) {
                        this.$notifier.showSuccess('', `Успешно изпратена имунизация с НРН: ${response.nrn}`);
                        await this.getTableItems();
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                if (this.examId) {
                    const response = await nhisImmunizationService.getImmunizationsByExam(this.examId);
                    if (response?.data) {
                        this.items = response.data;
                    }
                } else {
                    const response = await nhisImmunizationService.getImmunizationsByPatient(this.patientId);
                    if (response?.data) {
                        this.items = response.data;
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async postImmunizationCertificateRequest() {
            this.selected[0].certificateBase64 = await nhisImmunizationService.postImmunizationCertificateRequest(
                this.selected[0].patientId,
                this.selected[0].immunizationSeqNumber
            );
        }

        private showDialogError(item: NhisImmunizationDto) {
            this.dialogIsVisible = false;
            this.$notifier.showWarning('Внимание', `${item.error}`);
        }

        private openDialog(): void {
            this.dialogIsVisible = true;
        }

        private closeDialog(): void {
            this.dialogIsVisible = false;
        }

        private async updateGrid() {
            await this.getTableItems();
            this.closeDialog();
        }

        private async getCovidBatches() {
            await nhisImmunizationService.getCovidBatches();
        }

        private async printImmunWithoutPreview() {
            this.$loading.show();
            try {
                if (currentPatientCache.isLoaded) {
                    const parameters: { [key: string]: string } = {};
                    parameters.id = currentPatientCache.value.key.patientId.toString();
                    const report = 'Patient.PatientЕImmunization';
                    await printService.printReport(report, parameters);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.getTableItems();
        }
    }
</script>
