import { Type } from 'class-transformer';

import { Report365PsychiatricAccidentRowDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricAccidentRowDto';

export class Report365PsychiatricAccidentDto {
    public name: string = '';

    @Type(() => Report365PsychiatricAccidentRowDto)
    public psychiatricAccidents: Report365PsychiatricAccidentRowDto[] = [];
}
