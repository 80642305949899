<template>
    <exam-layout :can-delete="id > 0" @updated="updateEventHandler" @deleted="deleteEventHandler">
        <v-card>
            <main-title> Манипулации </main-title>
            <v-card-text>
                <v-row v-for="(serviceOrder, $index) of manipulations" :key="serviceOrder.serviceOfferKey.code">
                    <v-col md="3" class="py-0">
                        <dropdown
                            v-model="serviceOrder.serviceOfferKey.tariffSeqNumber"
                            label="Тарифа"
                            :items="tariffs"
                            item-value="seqNumber"
                            required
                            @input="tariffChanged(serviceOrder)"
                        ></dropdown>
                    </v-col>
                    <v-col v-if="serviceOrder.serviceOffersForDropdown" md="6" class="py-0">
                        <dropdown
                            v-model="serviceOrder.serviceOfferKey.serviceTitleSeqNumber"
                            label="Услуга"
                            :items="serviceOrder.serviceOffersForDropdown"
                            item-value="key.serviceTitleSeqNumber"
                            required
                            @input="setPatientPrice(serviceOrder.serviceOfferKey, $index)"
                        ></dropdown>
                    </v-col>
                    <v-col cols="2" class="py-0">
                        <text-field
                            v-model="serviceOrder.patientPrice"
                            label="Цена"
                            type="number"
                            :disabled="serviceOrder.paymentDate !== null"
                        ></text-field>
                    </v-col>
                    <v-col v-if="manipulations.length > 1" cols="1" class="py-0">
                        <btn icon action="Delete" @click="removeService($index)" />
                    </v-col>
                </v-row>
                <v-row v-if="examId">
                    <v-col>
                        <btn action="New" @click="addNewService">Добавяне на услуга</btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </exam-layout>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import MainTitle from '@/component/Common/MainTitle.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { CoreServiceTypeCode } from '@/enum/Nomenclature/CoreServiceTypeCode';
    import { ManipulationDto } from '@/model/Exam/Maipulation/ManipulationDto';
    import { ServiceOfferKey } from '@/model/Practice/Service/ServiceOfferKey';
    import { TariffDto } from '@/model/Practice/Service/TariffDto';
    import { ServiceOfferDto } from '@/model/Reception/Service/ServiceOfferDto';
    import { manipulationService } from '@/service/Exam/ManipulationService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { tariffService } from '@/service/Practice/Service/TariffService';
    import { receptionService } from '@/service/Reception/ReceptionService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component({
        components: { ExamLayout, MainTitle }
    })
    export default class Manipulation extends Vue {
        @Prop()
        examId!: number;

        @Prop()
        id!: number;

        private manipulations: ManipulationDto[] = [];
        private tariffs: TariffDto[] = [];
        private serviceOffers: ServiceOfferDto[] = [];

        private async mounted() {
            await this.getServicesForPractice();
            await this.getTariffs();
            if (this.examId) {
                this.addNewService();
            } else if (this.id) {
                await this.load();
            }
        }

        private async load() {
            this.$loading.show();
            try {
                const response = await manipulationService.getById(this.id);
                this.filterServiceOffers(response);
                this.manipulations.push(response);
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                if (this.examId) {
                    await manipulationService.create(this.manipulations);
                    eventBus.$emit('create-referral-event', 1, 'Manipulation', 'Манипулации');
                    this.pushToView();
                } else if (this.id) {
                    await manipulationService.update(this.manipulations[0]);
                    eventBus.$emit('update-referral-event', 'Манипулации');
                }
            } finally {
                this.$loading.hide();
            }
        }

        private pushToView() {
            this.$router.push(`/Exam/Manipulation/${currentVisitCache.value.id}`);
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                await manipulationService.delete(this.id);
                eventBus.$emit('delete-referral-event', this.id, 'Manipulation', 'Манипулации');
                this.pushToView();
            } finally {
                this.$loading.hide();
            }
        }

        private addNewService() {
            this.manipulations.push(new ManipulationDto());
            this.manipulations[this.manipulations.length - 1].visitId = currentVisitCache.value.id;
        }

        private removeService(index: number) {
            arrayUtil.removeAt(this.manipulations, index);
        }

        private tariffChanged(serviceOrder: ManipulationDto) {
            serviceOrder.serviceOfferKey.serviceTitleSeqNumber = 0;
            serviceOrder.patientPrice = null;
            this.filterServiceOffers(serviceOrder);
        }

        private filterServiceOffers(serviceOrder: ManipulationDto) {
            serviceOrder.serviceOffersForDropdown = this.serviceOffers.filter(
                (offer) =>
                    offer.key.tariffSeqNumber === serviceOrder.serviceOfferKey.tariffSeqNumber &&
                    offer.coreServiceTypeCode === CoreServiceTypeCode.Mp
            );
        }

        private setPatientPrice(serviceOfferKey: ServiceOfferKey, index: number) {
            const serviceOffer = this.serviceOffers.find((offer) => offer.key.code === serviceOfferKey.code);
            this.manipulations[index].patientPrice = serviceOffer?.patientPrice ?? 0;
            this.manipulations[index].manipulationName = serviceOffer?.name ?? null;
        }

        private async getServicesForPractice() {
            this.serviceOffers = await receptionService.getPracticeServiceOffers(
                userContextCache.currentPracticeId ?? 0
            );
        }

        private async getTariffs() {
            this.tariffs = await tariffService.getPracticeTariffs(userContextCache.currentPracticeId ?? 0);
        }
    }
</script>

<style scoped></style>
