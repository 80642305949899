import { Type } from 'class-transformer';

import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
import { NhisPrescriptionDto } from '@/model/Nhis/Prescription/NhisPrescriptionDto';

export class PrescriptionBaseDto {
    @Type(() => PrescriptionKey)
    public key: PrescriptionKey = new PrescriptionKey(0, 0);

    public formTypeCode: string = 'Без протокол';
    public prescriptionNumber: number | null = null;
    // Дата на издаване на рецептата
    public issueDate: Date | null = null;
    // Данни за пациента
    public patientId: number = 0;

    //Данни за електронна рецепта
    public nhisPrescription: NhisPrescriptionDto = new NhisPrescriptionDto();
}
