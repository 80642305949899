<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Искане за цитонамазка" :items="items">
                <template #actions>
                    <btn action="New" :to="`/Exam/RequestForCytosmear/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/RequestForCytosmear/Edit/${item.id}`" />
                    <btn icon action="Print" :to="`/Print/Exam.RequestForCytosmear/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RequestForCytosmearViewDto } from '@/model/Exam/RequestForCytosmear/RequestForCytosmearViewDto';
    import { requestForCytosmearService } from '@/service/Exam/RequestForCytosmearService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class RequestFromCytosmearView extends Vue {
        @Prop()
        examId!: number;

        private items: RequestForCytosmearViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: `Риск`, value: 'risk' },
                { text: 'Брой препарати', value: 'preparationCount' },
                { text: 'Код', value: 'code' },
                { text: 'Наш код', value: 'ourCode' },
                { text: 'Ваш код', value: 'yourCode' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await requestForCytosmearService.getRequestForCytosmearByExam(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
