import _Vue from 'vue';

import { licensingCache } from '@/store/LicensingCache';

declare module 'vue/types/vue' {
    interface Vue {
        get $allowWrite(): boolean;
    }
}

// TODO: Този plugin да се преименува и да се разшири с още видове проверки на правата, не само спрямо лиценза.
export const allowWritePlugin = (Vue: typeof _Vue) => {
    // Вариант 1 съхранява копие на променлива от реактивния кеша. Копието не е реактивно, така че не върши работа.
    //interface Vue { $allowWrite: boolean; }
    //Vue.prototype.$allowWrite = licensingCache.status.allowWrite;

    // Вариант 2 декларира функция, която връща променлива от реактивния кеш. Трябва да се извиква със скоби.
    //interface Vue { $allowWrite: () => boolean; }
    //Vue.prototype.$allowWrite = () => licensingCache.status.allowWrite;

    // Вариант 3 декларира getter вместо функция.
    //interface Vue { get $allowWrite(): boolean; }
    Object.defineProperty(Vue.prototype, '$allowWrite', { get: () => licensingCache.status.allowWrite });
};
