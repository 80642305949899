import { AxiosResponse } from 'axios';

import { ChoiceMakerTypeDto } from '@/model/Nomenclature/ChoiceMakerTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const choiceMakerTypeService = {
    getChoiceMakerTypes(): Promise<AxiosResponse<ChoiceMakerTypeDto[]>> {
        return httpService.get('/ChoiceMakerType/GetChoiceMakerTypes');
    }
};
