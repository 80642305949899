import { AxiosResponse } from 'axios';

import { PracticeTypeDto } from '@/model/Nomenclature/PracticeTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const practiceTypeService = {
    getPracticeTypes(): Promise<AxiosResponse<PracticeTypeDto[]>> {
        return httpService.get('/PracticeType/GetPracticeTypes');
    }
};
