<!-- Не се използва при вход през този проект. Само на началната страница на IdentityServer има линк към /SigninRedirect. -->
<template>
    <v-card flat>
        <v-card-title>Ще се зареди страницата за вход...</v-card-title>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { oidcClient } from '@/service/Infrastructure/OidcClient';

    @Component
    export default class SigninRedirect extends Vue {
        private created() {
            oidcClient.signinRedirect();
        }
    }
</script>
