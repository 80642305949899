import Vue from 'vue';

import { UserSettingsDto } from '@/model/User/UserSettings/UserSettingsDto';
import { vuetify } from '@/plugin/Vuetify';
import { examDurationSettingService } from '@/service/Setting/ExamDurationSettingService';
import { userSettingsService } from '@/service/User/UserSettingsService';
import { userContextCache } from '@/store/User/UserContextCache';

const userSettingsKey = 'userSettings';

class UserSettingsState {
    private _userSettings: UserSettingsDto = new UserSettingsDto();

    public get userSettings() {
        return this._userSettings;
    }

    public setDarkTheme(newValue: boolean) {
        this._userSettings.ui.isDarkTheme = newValue;
        this.applyTheme();
        this.saveToLocalStorage();
        this.saveToServer();
    }

    public setOutlinedButtons(newValue: boolean) {
        this._userSettings.ui.buttonsAreOutlined = newValue;
        this.saveToLocalStorage();
        this.saveToServer();
    }

    public setReferralsBackground(newValue: boolean) {
        this._userSettings.ui.hasReferralsBackground = newValue;
        this.saveToLocalStorage();
        this.saveToServer();
    }

    public setMinutesBetweenVisits(newValue: boolean) {
        this._userSettings.ui.useMinutesBetweenVisits = newValue;
        this.saveToLocalStorage();
        this.saveToServer();
    }

    public setScaleRatio(newValue: number) {
        this._userSettings.ui.scaleRatio = newValue;
        this.saveToLocalStorage();
        this.saveToServer();
    }

    public async loadFromServer() {
        this._userSettings = await userSettingsService.getCurrentUserSettings();

        // TODO: Не му е тук мястото. Минути между прегледите няма връзка с потребителя. Трябва да се сменя с местоработата.
        const { current } = userContextCache;
        if (current) {
            this._userSettings.examDurationSettings = await examDurationSettingService.getExamDurationSetting(
                current.practiceId,
                current.employeeSeqNumber
            );
        }

        this.applyTheme();
        this.saveToLocalStorage();
    }

    public loadFromLocalStorage() {
        const json = localStorage.getItem(userSettingsKey);
        if (json) {
            const storedSettings = JSON.parse(json) as UserSettingsDto;
            if (storedSettings) {
                // Копира само последната версия на настройките. Така се изтриват стари настройки, заредени от local storage.
                if (storedSettings.ui) {
                    this._userSettings.ui.isDarkTheme = storedSettings.ui.isDarkTheme;
                    this._userSettings.ui.buttonsAreOutlined = storedSettings.ui.buttonsAreOutlined;
                    this._userSettings.ui.hasReferralsBackground = storedSettings.ui.hasReferralsBackground;
                    this._userSettings.ui.useMinutesBetweenVisits = storedSettings.ui.useMinutesBetweenVisits;
                    this._userSettings.ui.scaleRatio = storedSettings.ui.scaleRatio;
                }
                if (storedSettings.examDurationSettings) {
                    this._userSettings.examDurationSettings = storedSettings.examDurationSettings;
                }
            }
        }
        this.applyTheme();
    }

    private applyTheme() {
        vuetify.framework.theme.dark = this._userSettings.ui.isDarkTheme;
    }

    private saveToServer() {
        userSettingsService.updateCurrentUserSettings(this._userSettings);
    }

    private saveToLocalStorage() {
        localStorage.setItem(userSettingsKey, JSON.stringify(this._userSettings));
    }
}

export const userSettingsState = Vue.observable(new UserSettingsState());
