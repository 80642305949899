import { AxiosResponse } from 'axios';

import { RuleFilterDto } from '@/model/Exam/RuleFilterDto';
import { PregnancyRegisterDto } from '@/model/Register/Pregnancy/PregnancyRegisterDto';
import { PregnancyRuleDto } from '@/model/Register/Pregnancy/PregnancyRuleDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const pregnancyRegisterService = {
    getAll(id: number): Promise<AxiosResponse<PregnancyRegisterDto[]>> {
        return httpService.get(`/PregnancyRegister/GetAll/${id}`);
    },

    get(id: number): Promise<AxiosResponse<PregnancyRegisterDto>> {
        return httpService.get(`/PregnancyRegister/GetPregnancyRegister/${id}`);
    },

    exportToExcel(id: number): Promise<void> {
        return httpService.download(`/PregnancyRegister/ExportToExcel/${id}`);
    },

    create(data: PregnancyRegisterDto): Promise<AxiosResponse<PregnancyRegisterDto>> {
        return httpService.post('/PregnancyRegister/CreatePregnancyRegister', data);
    },

    update(data: PregnancyRegisterDto): Promise<AxiosResponse<PregnancyRegisterDto>> {
        return httpService.put('/PregnancyRegister/UpdatePregnancyRegister/', data);
    },

    delete(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/PregnancyRegister/DeletePregnancyRegister/${id}`);
    },

    searchPregnancyRule(data: RuleFilterDto): Promise<AxiosResponse<PregnancyRuleDto[]>> {
        return httpService.post('/PregnancyRegister/SearchPregnancyRule', data);
    }
};
