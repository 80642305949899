export class AdvertisementDto {
    public id: number = 0;
    public advertisementTypeCode: string = '';
    public companyName: string = '';
    public icds: string[] = [];
    public validFrom: Date = new Date();
    public validTo: Date = new Date();
    public fileName: string = '';
    public fileContent: string | null = null;
    public isUsed: boolean = false;
}
