<template>
    <!-- Ref-ът не може да се сложи на самия v-data-table, защото той няма метод getBoundingClientRect (виж най-долу). -->
    <div v-if="items.length > 0" ref="tableContainer">
        <v-data-table
            v-resize="calcDataTableHeight"
            v-scroll="calcDataTableHeight"
            class="denseLines"
            :headers="headers"
            :items="items"
            :item-class="itemRowBackground"
            fixed-header
            :height="tableHeight"
            disable-pagination
            hide-default-footer
        >
            <template #[`item.patientIdentifier`]="{ item }">
                <router-link class="contrast--text" :to="`/Patient/VisitSummary/${item.patientVersionKey.patientId}`">
                    {{ item.patientIdentifier }}
                </router-link>
            </template>

            <template #[`item.startDateTime`]="{ item }">
                <date-label v-model="item.startDateTime" show-time />
            </template>

            <template #[`item.id`]="{ item }">
                <td class="rowActions">
                    <btn icon action="Edit" @click="editVisit(item)" />
                    <btn
                        v-if="item.ambulatorySheetNumber !== null"
                        action="Copy"
                        icon
                        @click="copyVisit(item.id, item.patientVersionKey.patientId)"
                    >
                        Копиране на преглед
                    </btn>
                </td>
            </template>
        </v-data-table>

        <v-card-text>
            <v-row dense class="text-center">
                <v-col cols="auto">Брой:&nbsp;{{ items.length }}</v-col>
                <v-col v-if="!showPatientData" cols="auto">
                    <btn action="List" :disabled="isOrderedByType" extra-small @click="orderByType">Подр. по вид</btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">Легенда:</v-col>
                <v-col class="style-consultation" cols="auto">Консултация</v-col>
                <v-col class="style-prevention" cols="auto">Профилактичен</v-col>
                <v-col class="style-isrisk" cols="auto">Профилактичен-Риск</v-col>
                <v-col class="style-dispensary" cols="auto">Диспансерен</v-col>
                <v-col class="style-canceled" cols="auto">Анулиран</v-col>
                <v-col class="style-no-nhif" cols="auto">Не се заплаща от НЗОК</v-col>
                <v-col class="style-vsd px-6" cols="auto">ВСД</v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import DateLabel from '@/component/Date/DateLabel.vue';
    import { NhifVisitReasonCode } from '@/enum/Exam/NhifVisitReasonCode';
    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { FundCode } from '@/enum/Nomenclature/FundCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { VisitResultDto } from '@/model/VisitList/VisitSearch/VisitResultDto';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    const visitSummaryHeaders = [
        { text: 'АЛ', value: 'examNumberOrVisitType' },
        { text: 'НРН', value: 'nrn' },
        { text: 'Дата час', value: 'startDateTime' },
        { text: 'МКБ', value: 'icdCode' },
        { text: 'Рецепти', value: 'prescription' },
        { text: 'Направл.', value: 'referral3' },
        { text: 'Напр. ВСД', value: 'referral3a' },
        { text: 'МДН', value: 'referral4' },
        { text: 'Изсл.', value: 'labTestOrders' },
        { text: 'Брой БЛ', value: 'sickSheetCount' },
        { text: 'Хоспит.', value: 'referral7' },
        { text: 'Имунизации', value: 'immunization' },
        { text: 'Извършен от', value: 'shortCreatedBy' },
        { text: '', value: 'id' }
    ];

    @Component({
        components: { DateLabel }
    })
    export default class VisitDataTable extends Vue {
        @Prop({ required: true })
        private items!: VisitResultDto[];

        @Prop({ required: true })
        private showPatientData!: boolean;

        // Указва колко място да се запази под таблицата, така че да се съберат footer-ът и padding-ите
        // на parent компонентите. Целта е страницата да няма скролер, за да не стават два скролера.
        @Prop({ required: true })
        private bottomAnchor!: number;

        private headers: IDataTableHeader[] = [];

        private itemRowBackground(item: VisitResultDto) {
            if (item.statusCode === NhisExamStatusCode.Canceled) {
                return 'style-canceled';
            }
            if (item.fundCode !== FundCode.Nhif) {
                return 'style-no-nhif';
            }
            if (item.nhifVisitReasonCode) {
                if (
                    item.nhifVisitReasonCode === NhifVisitReasonCode.AdultPrevention ||
                    item.nhifVisitReasonCode === NhifVisitReasonCode.ChildPrevention ||
                    item.nhifVisitReasonCode === NhifVisitReasonCode.MotherPrevention
                ) {
                    return 'style-prevention';
                }
                if (item.nhifVisitReasonCode === NhifVisitReasonCode.AdultRiskPrevention) {
                    return 'style-isrisk';
                }
                if (item.nhifVisitReasonCode === NhifVisitReasonCode.Consultation) {
                    return 'style-consultation darkMode';
                }
                if (item.nhifVisitReasonCode === NhifVisitReasonCode.Dispensary) {
                    return 'style-dispensary';
                }
                if (item.nhifVisitReasonCode === NhifVisitReasonCode.HighSpecialized) {
                    return 'style-vsd';
                }
            }
            return 'style-main';
        }

        private copyVisit(id: number, patientId: number) {
            currentPatientCache.loadLastVersion(patientId);
            this.$router.push(`/Exam/AmbulatorySheet/Copy/${id}`);
        }

        private editVisit(visit: VisitResultDto) {
            currentPatientCache.loadSpecificVersion(visit.patientVersionKey);
            this.$loading.show();
            // В краен случай, при празно/счупено посещение, опитва да зареди АЛ.
            const path =
                visit.ambulatorySheetNumber !== null
                    ? `/Exam/AmbulatorySheet/Edit/${visit.id}`
                    : visit.sickSheetCount
                      ? `/Exam/SickSheet/${visit.id}`
                      : visit.hasStagedEpicrisis
                        ? `/Exam/StagedEpicrisis/${visit.id}`
                        : visit.hasFiles
                          ? `/Exam/FileUpload/${visit.id}`
                          : visit.hasExternalVisit
                            ? `/Exam/Results/${visit.id}`
                            : `/Exam/AmbulatorySheet/Edit/${visit.id}`;
            this.$router.push(path);
        }

        private mounted() {
            if (this.showPatientData) {
                this.headers = [
                    { text: 'ЕГН / ЛНЧ', value: 'patientIdentifier' },
                    { text: 'Пациент', value: 'patientFullName' },
                    ...visitSummaryHeaders
                ];
            } else {
                this.headers = visitSummaryHeaders;
            }
        }

        private isOrderingByType: boolean = false;
        private isOrderedByType: boolean = false;

        @Watch('items')
        private onItemsLoaded() {
            this.isOrderedByType = this.isOrderingByType;
            this.isOrderingByType = false;
        }

        private orderByType() {
            const negativeOne = -1;
            this.items.sort((itemA, itemB) => {
                if (
                    itemA.statusCode === NhisExamStatusCode.Canceled &&
                    itemB.statusCode !== NhisExamStatusCode.Canceled
                ) {
                    return 1;
                }
                if (
                    itemA.statusCode !== NhisExamStatusCode.Canceled &&
                    itemB.statusCode === NhisExamStatusCode.Canceled
                ) {
                    return negativeOne;
                }
                if (this.itemRowBackground(itemA) === this.itemRowBackground(itemB)) {
                    return 0;
                }
                return this.itemRowBackground(itemA) < this.itemRowBackground(itemB) ? negativeOne : 1;
            });
            // Сортирането задейства watch-а върху items.
            this.isOrderingByType = true;
        }

        @Ref()
        private readonly tableContainer!: Element;

        private tableHeight: number = 0;

        public calcDataTableHeight() {
            const minTableHeight = 300;
            const legendHeight = 56;
            this.tableHeight =
                window.innerHeight - this.tableContainer.getBoundingClientRect().y - legendHeight - this.bottomAnchor;
            if (this.tableHeight < minTableHeight) {
                this.tableHeight = minTableHeight;
            }
        }
    }
</script>

<style scoped>
    /* Ако няма !important, редовете си губят цвета при hover, което в цветна таблица пречи. */
    :deep(.style-main) {
        background-color: transparent;
    }
    :deep(.style-prevention) {
        background-color: var(--v-accent2-base) !important;
    }
    :deep(.style-consultation) {
        background-color: var(--v-success2-base) !important;
    }
    :deep(.style-isrisk) {
        background-color: var(--v-info2-base) !important;
    }
    :deep(.style-dispensary) {
        background-color: var(--v-warning2-base) !important;
    }
    :deep(.style-no-nhif) {
        background-color: var(--v-secondary2-base) !important;
    }
    :deep(.style-canceled) {
        background-color: var(--v-error2-base) !important;
    }
    :deep(.style-vsd) {
        background-color: var(--v-primary2-base) !important;
    }

    /* Данните са много и се wrap-ват, а line-height по подразбиране е над 1. Връща се на 1, за да се спести височина без влошена четимост. */
    .denseLines {
        line-height: normal;
    }

    /* Колоните се долепят максимално, за да се събира повече текст без wrap-ване. */
    :deep(.v-data-table table > tbody > tr > td) {
        padding: 0 0 0 4px;
    }
    /* Header-ите се долепят плътно, но стрелките за сортиране ги държат на разстояние. Selector-ът трябва да бъде много специфичен, за да сработи. */
    :deep(.v-data-table table > thead.v-data-table-header > tr > th) {
        padding: 0;
        white-space: nowrap;
    }
    :deep(.v-data-table table > thead.v-data-table-header > tr > th:first-of-type) {
        padding: 0 0 0 4px;
    }
    /* Колоната с бутоните за редактиране и копиране е постоянно видима, върху останалите. */
    .v-data-table table > tbody > tr > td.rowActions {
        position: sticky;
        right: 0;
        padding: 0;
        /* Бутоните се wrap-ват и заемат двойна височина, ако не се укаже ширина на колоната. */
        min-width: 76px;
    }
</style>
