import { Type } from 'class-transformer';

import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';
import { NhisReferralDto } from '@/model/Nhis/Referral/NhisReferralDto';
import { FundServiceDto } from '@/model/Nomenclature/FundServiceDto';

export class Referral4Dto {
    public id: number = 0;
    public IsDeleted: boolean = false;
    public number: number = 0;
    public examId: number = 0;
    public referral4SubtypeId: number = 1;
    public issueDate: Date | null = null;
    public PatientId: number = 0;
    public nhifCode: string = '';
    public fundCode: string = '';

    @Type(() => ExamDiagnosisDto)
    public examDiagnosis: ExamDiagnosisDto | null = null;

    @Type(() => FundServiceDto)
    public fundServices: FundServiceDto[] = [];

    public regNumMCOfCurrentDoctor: string = '';
    public nhifCodeOfCurrentDoctor: string = '';

    @Type(() => NhisReferralDto)
    public nhisReferral: NhisReferralDto = new NhisReferralDto();
}
