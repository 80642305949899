enum NotificationCategory {
    System = 'Ситемни съобщения',
    Validation = 'Валидационни грешки',
    User = 'Потребителски съобщения'
}

enum NotificationSeverity {
    Info = 'info',
    Success = 'success',
    Warn = 'warn',
    Error = 'error'
}

export { NotificationCategory, NotificationSeverity };
