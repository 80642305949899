import { Route, RouteConfig } from 'vue-router';

export const licensingChildRoutes: RouteConfig[] = [
    {
        path: 'Licensing/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Licensing/Licensing.vue')
        }
    },
    {
        path: 'PracticeLicense/Create/:practiceId&:isNew',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                isNew: route.params.isNew === 'true'
            })
        },
        components: {
            practice: () => import('@/view/Licensing/PracticeLicenseCreate.vue')
        }
    },
    {
        path: '/Customer/CustomerCredit/Edit/:customerId/:seqNumber/:practiceId',
        props: {
            practice: (route: Route) => ({
                customerId: Number(route.params.customerId),
                seqNumber: Number(route.params.seqNumber),
                practiceId: Number(route.params.practiceId)
            })
        },
        components: {
            practice: () => import('@/view/Licensing/CustomerCreditEdit.vue')
        }
    }
];
