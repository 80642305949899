const padLength = 2;
const pad2 = (value: number) => value.toString().padStart(padLength, '0');

export class AppInfoModel {
    public branch: string = '';
    public buildTime: string = '';

    // Проверява дали има разлика във версията, build номера или друго, което трябва да предизвика презареждане на страницата.
    public equals(other: AppInfoModel | null): boolean {
        return this === other || (other !== null && this.branch === other.branch && this.buildTime === other.buildTime);
    }

    public get isEmpty() {
        return !this.branch && !this.buildTime;
    }

    public clear() {
        this.branch = '';
        this.buildTime = '';
    }

    public format(includeBuild: boolean) {
        if (this.isEmpty) {
            return '';
        }

        // Извлича "58.1" от "production58.1".
        const sprint = this.branch.match(/(?<sprint>[\d.]+)/gu);
        let text = sprint ? `v${sprint} ` : '';

        text += this.branch.startsWith('prod') ? '' : this.branch.startsWith('rc') ? ' rc' : ` ${this.branch}`;

        const dt = new Date(this.buildTime);
        text += ` от ${pad2(dt.getDate())}.${pad2(dt.getMonth() + 1)}`;
        if (includeBuild) {
            text += ` ${pad2(dt.getHours())}:${pad2(dt.getMinutes())}`;
        }

        return text;
    }
}
