import { Route, RouteConfig } from 'vue-router';

import { fiscalChildRoutes } from './FiscalRoutes';
import { licensingChildRoutes } from './LicensingRoutes';
import { regulatoryStandardChildRoutes } from './RegulatoryStandardRoutes';

const practiceUncategorizedChildRoutes: RouteConfig[] = [
    {
        path: 'Edit/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Practice/PracticeEdit.vue')
        }
    },
    {
        path: 'NhifContract/List/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Practice/PracticeNhifContractList.vue')
        }
    },
    {
        path: 'NhifContract/Create/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Practice/PracticeNhifContractEdit.vue')
        }
    },
    {
        path: 'NhifContract/Edit/:id',
        props: {
            practice: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            practice: () => import('@/view/Practice/PracticeNhifContractEdit.vue')
        }
    },
    {
        path: 'PracticeUnit/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Practice/PracticeUnit/PracticeUnits.vue')
        }
    },
    {
        path: 'Rooms/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Practice/PracticeRooms.vue')
        }
    },
    {
        path: 'LkkCommittee/List/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Practice/LkkCommittee/LkkCommitteeList.vue')
        }
    },
    {
        path: 'LkkCommittee/Create/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Practice/LkkCommittee/LkkCommitteeEdit.vue')
        }
    },
    {
        path: 'LkkCommittee/Edit/:practiceId/:seqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                seqNumber: Number(route.params.seqNumber)
            })
        },
        components: {
            practice: () => import('@/view/Practice/LkkCommittee/LkkCommitteeEdit.vue')
        }
    },
    {
        path: 'DoctorEdit/:doctorId',
        props: {
            practice: (route: Route) => ({ doctorId: Number(route.params.doctorId) })
        },
        components: {
            practice: () => import('@/view/Practice/DoctorEdit.vue')
        }
    }
];

export const practiceRoutes: RouteConfig = {
    path: '/Practice',
    // Id-тата на практика или лекар се подават и на самото меню (освен на конкретното child view).
    // Така, при първоначално зареждане на SPA, менюто показва линкове към правилните практика или лекар.
    props: (route: Route) => ({
        practiceId: route.params.practiceId ? Number(route.params.practiceId) : null,
        doctorId: route.params.doctorId ? Number(route.params.doctorId) : null
    }),
    component: () => import('@/view/Practice/PracticeMenu.vue'),
    children: [
        ...fiscalChildRoutes,
        ...regulatoryStandardChildRoutes,
        ...licensingChildRoutes,
        ...practiceUncategorizedChildRoutes
    ]
};
