import { Type } from 'class-transformer';

import { ImmunizationDto } from '@/model/Nomenclature/ImmunizationDto';

export class PatientImmunizationDto {
    public id: number = 0;
    public patientId: number = 0;

    @Type(() => ImmunizationDto)
    public immunization: ImmunizationDto | null = null;

    public occurrenceDate: Date | null = null;
    public serialNumber: string = '';
    public resultField: string = '';
    public mantuResult: boolean | null = null;
    public doctorName: string = '';
    public doctorUin: string = '';
    public examId: number = 0;
}
