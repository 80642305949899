import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { ReferralTelkDto } from '@/model/Exam/Referral/ReferralTelkDto';
import { ReferralTelkViewDto } from '@/model/Exam/Referral/ReferralTelkViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referralTelkService = {
    async getReferralInfoByExamId(examId: number): Promise<ReferralTelkDto> {
        const response = await httpService.get<ReferralTelkDto>(`/ReferralTelk/GetReferralInfoByExamId/${examId}`);
        return plainToInstance(ReferralTelkDto, response.data);
    },

    getReferralTelk(id: number): Promise<AxiosResponse<ReferralTelkDto>> {
        return httpService.get(`/ReferralTelk/GetReferralTelk/${id}`);
    },

    createReferralTelk(data: ReferralTelkDto): Promise<AxiosResponse<ReferralTelkDto>> {
        return httpService.post('/ReferralTelk/CreateReferralTelk', data);
    },

    updateReferralTelk(data: ReferralTelkDto): Promise<AxiosResponse<ReferralTelkDto>> {
        return httpService.post('/ReferralTelk/UpdateReferralTelk', data);
    },

    deleteReferralTelk(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/ReferralTelk/DeleteReferralTelk/${id}`);
    },

    getReferralsByExamId(examId: number): Promise<AxiosResponse<ReferralTelkViewDto[]>> {
        return httpService.get(`/ReferralTelk/GetReferralTelkViewsByExam`, {
            params: {
                examId
            }
        });
    }
};
