import { PisFileTypeCode } from '@/enum/Report/Pis/PisFileTypeCode';

export class PisFileFilterDto {
    public practiceId: number = 0;
    public doctorEmployeeSeqNumber: number | null = null;
    public pisFileTypeCode: PisFileTypeCode | null = null;
    public startDate: Date | null = null;
    public endDate: Date | null = null;
    public invoiceId: number | null = null;
}
