<template>
    <div>
        <v-card-title v-if="value.name">Представител: {{ value.name }}</v-card-title>
        <v-card-text>
            <h2>
                <span v-if="value.email">
                    <v-icon>mdi-at</v-icon>&nbsp;
                    <a :href="`mailto:${value.email}`">{{ value.email }}</a>
                </span>
                <br />
                <br />
                <span v-if="value.phoneNumber"><v-icon>mdi-cellphone</v-icon>&nbsp; {{ value.phoneNumber }}</span>
            </h2>
        </v-card-text>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { DealerContactDto } from '@/model/Licensing/DealerContactDto';

    @Component
    export default class DealerContact extends Vue {
        @Prop({ required: true })
        private value!: DealerContactDto;
    }
</script>
