import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';

import { config } from '@/Config';
import { financeRoutes } from '@/router/FinanceRoutes';
import { queryRoutes } from '@/router/QueryRoutes';
import { appUpdateService } from '@/service/Infrastructure/AppUpdateService';

import { authRoutes } from './AuthRoutes';
import { devToolRoutes } from './DevToolRoutes';
import { examRoutes } from './Exam/AllExamRoutes';
import { helpRoutes } from './HelpRoutes';
import { nomenclatureRoutes } from './NomenclatureRoutes';
import { patientRoutes } from './PatientRoutes';
import { practiceRoutes } from './Practice/AllPracticeRoutes';
import { employeeRoutes } from './Practice/EmployeeRoutes';
import { printRoutes } from './PrintRoutes';
import { receptionRoutes } from './ReceptionRoutes';
import { registerRoutes } from './RegisterRoutes';
import { reportRoutes } from './ReportRoutes';
import { settingRoutes } from './SettingRoutes';
import { visitListRoutes } from './VisitListRoutes';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    ...authRoutes,
    printRoutes,
    registerRoutes,
    patientRoutes,
    receptionRoutes,
    visitListRoutes,
    examRoutes,
    reportRoutes,
    financeRoutes,
    practiceRoutes,
    employeeRoutes,
    settingRoutes,
    nomenclatureRoutes,
    devToolRoutes,
    helpRoutes,
    queryRoutes,
    {
        path: '/',
        component: () => import('@/view/Home/Home.vue')
    },
    {
        path: '/IncomingReferralDownload',
        component: () => import('@/view/Exam/Referral/IncomingReferral/IncomingReferralExtraction.vue')
    },
    {
        path: '/Registration',
        component: () => import('@/view/Employee/Registration/Registration.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: config.spaBaseUrlRelative,
    routes,
    // Не намерих начин, хем да скролва в началото, хем да се включи опцията 'smooth'.
    scrollBehavior() {
        // Имената на property-тата на тип Position са еднобуквени - защо предупреждава за външен код?!
        // eslint-disable-next-line id-length
        return { x: 0, y: 0 };
    }
    // Не намерих начин да помни докъде е скролнат всеки екран поотделно - позицията се запазва между екраните, което е дразнещо.
    //scrollBehavior(to) {
    //    return {
    //        selector: to.hash,
    //        offset: { x: 0, y: 0 },
    //        behavior: 'smooth'
    //    };
    //}
});

const beforeRouting = (_toUnused: Route, _fromUnused: Route, next: NavigationGuardNext<Vue>) => {
    // При навигиране се прави асинхронна проверка за нова версия на това приложение,
    // но само ако е изминало достатъчно време от предишната проверка.
    appUpdateService.tryAutoUpdate();

    // TODO: Идея за пренасочване към вход при отваряне на защитен route.
    // Първи проблем: само route-овете за нотификации са анотирани с meta.requiresAuth, за експеримент.
    // Втори проблем: при първоначално зареждане директно на защитен route, се получава презареждане
    // (понякога безкрайно), защото isAuthenticated идва твърде късно.
    // Трети проблем: получава се циклична зависимост между router и currentUser.
    //if (!currentUser.isAuthenticated && to.matched.some((record) => record.meta.requiresAuth)) {
    //    // На кой route да се върне след успешен вход.
    //    await oidcClient.signinRedirect(to.path);
    //}
    // Потребителят вече е логнат или не се изисква автентикация.

    next();
};

router.beforeEach(beforeRouting);

export { router };
