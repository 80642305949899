<template>
    <div>
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>Показване на пациента и лекаря</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row dense>
                        <v-col cols="12" md="6"><PatientInfo /></v-col>
                        <v-col v-if="resultReferralPerformerData" cols="12" md="6"
                            ><v-card-title class="mb-3 pt-0">Данни за лекар</v-card-title>
                            <v-row no-gutters>
                                <v-col cols="12" class="pa-1">
                                    <text-field
                                        v-model="resultReferralPerformerData.practiceNumber"
                                        disabled
                                        label="РЦЗ Номер"
                                    />
                                </v-col>
                                <v-col cols="12" class="pa-1">
                                    <text-field v-model="resultReferralPerformerData.doctorUin" disabled label="УИН" />
                                </v-col>
                                <v-col cols="12" class="pa-1">
                                    <text-field
                                        v-model="resultReferralPerformerData.deputyDoctorUin"
                                        disabled
                                        label="УИН на заместващия/нает лекар/медицинска сестра/акушерка/лекарски асистенти"
                                    />
                                </v-col>
                                <v-col cols="12" class="pa-1">
                                    <text-field
                                        v-model="resultReferralPerformerData.doctorQualificationCode"
                                        disabled
                                        label="Код специалист"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" md="8" class="pa-1">
                                    <text-field
                                        v-model="resultReferralPerformerData.doctorName"
                                        disabled
                                        label="Лекар имена"
                                    />
                                </v-col>
                                <v-col cols="6" md="2" class="pa-1">
                                    <v-checkbox
                                        :input-value="resultReferralPerformerData.doctorIsDeputyDoctor"
                                        dense
                                        disabled
                                        label="Нает"
                                    />
                                </v-col>
                                <v-col cols="6" md="2" class="pa-1">
                                    <v-checkbox
                                        :input-value="resultReferralPerformerData.doctorIsHired"
                                        disabled
                                        dense
                                        label="Зам."
                                    />
                                </v-col> </v-row
                        ></v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-row dense>
            <v-card-title> Данни за прегледа </v-card-title>
        </v-row>
        <v-row>
            <v-col v-if="nrn" cols="4">
                <text-field v-model="nrn" readonly label="НРН"></text-field>
            </v-col>
            <v-col v-if="status" cols="4">
                <text-field v-model="status" readonly label="Статус"></text-field>
            </v-col>
            <v-col v-if="openDate" cols="4">
                <date-field :value="openDate" readonly label="Дата на прегледа" />
            </v-col>
        </v-row>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { ResultReferralPerformerDto } from '@/model/Exam/Referral/ResultReferral/ResultReferralPerformerDto';

    @Component({ components: { PatientInfo } })
    export default class ReferralResultMainInfo extends Vue {
        @Prop()
        private resultReferralPerformerData!: ResultReferralPerformerDto;

        @Prop()
        private nrn!: string;

        @Prop()
        private status!: string;

        @Prop()
        private openDate!: Date | null;
    }
</script>
