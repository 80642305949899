<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">Персонал в края на годината</h3></v-col>
        </v-row>
        <v-row>
            <DataTableComponent v-model="value.report365Staff" :headers="headers" />
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Report365StaffDto } from '@/model/Report/Rhi/Report365/Report365StaffDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class Staff extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Report365StaffDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: 'Наименование', value: 'name' },
            { text: 'Шиф.', value: 'code' },
            { text: 'Об.', value: 'totalWithContract' },
            { text: 'Вс.', value: 'total' },
            { text: 'със спец.', value: 'totalWithSpecialty' },
            { text: 'под 35 г.', value: 'total35' },
            { text: 'м. под 35 г.', value: 'male35' },
            { text: 'ж. под 35 г.', value: 'female35' },
            { text: '35-44 г.', value: 'total44' },
            { text: 'м. 35-44 г.', value: 'male44' },
            { text: 'ж. 35-44 г.', value: 'female44' },
            { text: '45-54 г.', value: 'total54' },
            { text: 'м. 45-54 г.', value: 'male54' },
            { text: 'ж. 45-54 г.', value: 'female54' },
            { text: '55-64 г.', value: 'total64' },
            { text: 'м. 55-64 г.', value: 'male64' },
            { text: 'ж. 55-64 г.', value: 'female64' },
            { text: '65-74 г.', value: 'total74' },
            { text: 'м. 65-74 г.', value: 'male74' },
            { text: 'ж. 65-74 г.', value: 'female74' },
            { text: '75+ г.', value: 'total75' },
            { text: 'м. 75+ г.', value: 'male75' },
            { text: 'ж. 75+ г.', value: 'female75' }
        ];

        @Watch('value.report365Staff', { deep: true })
        private onValueChanged() {
            const timeout = 3000;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => this.calculateSectionRow(), timeout);
        }

        private resetTotals(idx: number) {
            this.value.report365Staff[idx].totalWithSpecialty = 0;
            this.value.report365Staff[idx].totalWithContract = 0;
            this.value.report365Staff[idx].female35 = 0;
            this.value.report365Staff[idx].female44 = 0;
            this.value.report365Staff[idx].female54 = 0;
            this.value.report365Staff[idx].female64 = 0;
            this.value.report365Staff[idx].female74 = 0;
            this.value.report365Staff[idx].female75 = 0;
            this.value.report365Staff[idx].male35 = 0;
            this.value.report365Staff[idx].male44 = 0;
            this.value.report365Staff[idx].male54 = 0;
            this.value.report365Staff[idx].male64 = 0;
            this.value.report365Staff[idx].male74 = 0;
            this.value.report365Staff[idx].male75 = 0;
        }

        private sumTotals(idx: number, kidx: number) {
            this.value.report365Staff[idx].totalWithContract =
                (this.value.report365Staff[idx].totalWithContract ?? 0) +
                (this.value.report365Staff[kidx].totalWithContract ?? 0);
            this.value.report365Staff[idx].female35 =
                (this.value.report365Staff[idx].female35 ?? 0) + (this.value.report365Staff[kidx].female35 ?? 0);
            this.value.report365Staff[idx].female44 =
                (this.value.report365Staff[idx].female44 ?? 0) + (this.value.report365Staff[kidx].female44 ?? 0);
            this.value.report365Staff[idx].female54 =
                (this.value.report365Staff[idx].female54 ?? 0) + (this.value.report365Staff[kidx].female54 ?? 0);
            this.value.report365Staff[idx].female64 =
                (this.value.report365Staff[idx].female64 ?? 0) + (this.value.report365Staff[kidx].female64 ?? 0);
            this.value.report365Staff[idx].female74 =
                (this.value.report365Staff[idx].female74 ?? 0) + (this.value.report365Staff[kidx].female74 ?? 0);
            this.value.report365Staff[idx].female75 =
                (this.value.report365Staff[idx].female75 ?? 0) + (this.value.report365Staff[kidx].female75 ?? 0);
            this.sumMaleTotals(idx, kidx);
            this.value.report365Staff[idx].totalWithSpecialty =
                (this.value.report365Staff[idx].totalWithSpecialty ?? 0) +
                (this.value.report365Staff[kidx].totalWithSpecialty ?? 0);
        }

        private sumMaleTotals(idx: number, kidx: number) {
            this.value.report365Staff[idx].male35 =
                (this.value.report365Staff[idx].male35 ?? 0) + (this.value.report365Staff[kidx].male35 ?? 0);
            this.value.report365Staff[idx].male44 =
                (this.value.report365Staff[idx].male44 ?? 0) + (this.value.report365Staff[kidx].male44 ?? 0);
            this.value.report365Staff[idx].male54 =
                (this.value.report365Staff[idx].male54 ?? 0) + (this.value.report365Staff[kidx].male54 ?? 0);
            this.value.report365Staff[idx].male64 =
                (this.value.report365Staff[idx].male64 ?? 0) + (this.value.report365Staff[kidx].male64 ?? 0);
            this.value.report365Staff[idx].male74 =
                (this.value.report365Staff[idx].male74 ?? 0) + (this.value.report365Staff[kidx].male74 ?? 0);
            this.value.report365Staff[idx].male75 =
                (this.value.report365Staff[idx].male75 ?? 0) + (this.value.report365Staff[kidx].male75 ?? 0);
        }

        private calculateSectionRow() {
            for (let idx = 0; idx < this.value.report365Staff.length; idx++) {
                if (this.value.report365Staff[idx].isSection) {
                    this.resetTotals(idx);
                    for (let kidx = 0; kidx < this.value.report365Staff.length; kidx++) {
                        if (this.value.report365Staff[kidx].parentCode === this.value.report365Staff[idx].code) {
                            this.sumTotals(idx, kidx);
                        }
                    }
                }
            }
        }

        private mounted() {
            return this.calculateSectionRow();
        }
    }
</script>
