export class BgDoctorDto {
    public uin: string | null = '';
    public name: string | null = '';
    public districtName: string = '';
    public specialtyNames: string = '';
    public phone: string = '';
    public address: string = '';
    public workplace: string = '';
    public updatedOn: Date | null = new Date();
    public isDeleted: boolean = false;
}
