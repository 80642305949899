export class SigninProgressModel {
    public _signinSilentAttempts: number = 0;

    public get isExecutingSigninSilent() {
        return this._signinSilentAttempts > 0;
    }

    public beginSigninSilent() {
        ++this._signinSilentAttempts;
    }

    public endSigninSilent() {
        --this._signinSilentAttempts;
    }

    // Loading флаг с timeout, докато тече пренасочването към страницата за вход.
    private _isExecutingSigninRedirect: boolean = false;
    private _signinRedirectTimeoutHandler: number = 0;

    public get isExecutingSigninRedirect() {
        return this._isExecutingSigninRedirect;
    }

    public showSigninRedirect() {
        this._isExecutingSigninRedirect = true;
        clearTimeout(this._signinRedirectTimeoutHandler);
        const abortSigninRedirectMs = 10000;
        this._signinRedirectTimeoutHandler = setTimeout(() => {
            this._isExecutingSigninRedirect = false;
            this._signinRedirectTimeoutHandler = 0;
        }, abortSigninRedirectMs);
    }
}
