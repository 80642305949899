<template>
    <ExamLayout
        :can-delete="document.id > 0 && !disableEdit"
        :can-print="document.id > 0"
        :can-save="!disableEdit"
        title="Талон за медицинска експертиза - бл.МЗ-НЗОК №6"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <nhis-referral
            v-if="showNhisReferral"
            v-model="document.nhisReferral"
            :referral-id="document.id"
            :form-type="'Referral6'"
            @input="copyObject"
        />
        <v-card class="mb-md-4" :disabled="disableEdit">
            <main-title>Талон за медицинска експертиза - бл.МЗ-НЗОК №6</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <text-field v-model="document.number" label="ТАЛОН ЗА МЕ №" />
                    </v-col>
                    <v-col cols="12" md="3" offset-md="2">
                        <text-field
                            v-model="currentVisit.exam.ambulatorySheetNumber"
                            label="амбулаторен лист №"
                            disabled
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
            <v-card-text>
                <PatientInfo />
                <hr />
                <DoctorInfo row />
            </v-card-text>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-radio-group v-model="document.referralSubtypeId" :rules="[$validator.required]">
                            <v-radio
                                v-for="subtype in referralSubtypes"
                                :key="subtype.id"
                                :label="subtype.name"
                                :value="subtype.id"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="4">
                        <ExamDiagnosisPicker v-model="examDiagnosis" required label="МКБ" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <ExamDiagnosisPicker v-model="examDiagnosis2" label="Придружаващи МКБ" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3"> <h3>Към:</h3></v-col>
                    <v-col cols="12" md="3">
                        <specialty-picker v-model="document.specialty1Code" required />
                    </v-col>
                    <v-col cols="12" md="3">
                        <specialty-picker v-model="document.specialty2Code" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <specialty-picker v-model="document.specialty3Code" />
                    </v-col>
                    <v-col cols="12" md="3" offset-md="3">
                        <specialty-picker v-model="document.specialty4Code" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <specialty-picker v-model="document.specialty5Code" />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <date-picker v-model="document.issueDate" label="Дата и час" />
                    </v-col>
                </v-row>
                <br />
            </v-card-text>
            <hr />
            <v-card-text v-for="item in 5" :key="item">
                <!-- Иван: Какво се постига с този v-for и id ? -->
                <Referral6Section id="'item'" :referral6-dto="document" :value="currentVisit.id" />
                <hr />
            </v-card-text>
            <v-row>
                <v-col cols="6" md="2" offset-md="10"> Бл. МЗ-НЗОК № 6 </v-col>
            </v-row>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamDiagnosisPicker from '@/component/Exam/ExamDiagnosisPicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import SpecialtyPicker from '@/component/Picker/SpecialtyPicker.vue';
    import { ReferralTypeCode } from '@/enum/Exam/ReferralTypeCode';
    import { Referral6Dto } from '@/model/Exam/Referral/Referral6Dto';
    import { ReferralSubtypeDto } from '@/model/Nomenclature/ReferralSubtypeDto';
    import { referral6Service } from '@/service/Exam/Referral/Referral6Service';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { referralSubtypeService } from '@/service/Nomenclature/ReferralSubtypeService';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import NhisReferral from '@/view/Exam/Referral/NhisReferral.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    import Referral6Section from './Referral6Section.vue';

    @Component({
        components: {
            NhisReferral,
            UnsavedChanges,
            Referral6Section,
            ExamDiagnosisPicker,
            PatientInfo,
            DoctorInfo,
            ExamLayout,
            SpecialtyPicker
        },
        async beforeRouteLeave(to, from, next) {
            if (this?.$data?.leaveDialogRef) {
                await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
            } else {
                next();
            }
        }
    })
    export default class Referral6 extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private examId!: number;

        @Prop()
        private documentId!: number;

        private document: Referral6Dto = new Referral6Dto();
        private initialValue: Referral6Dto = new Referral6Dto();
        private referralSubtypes: ReferralSubtypeDto[] = [];
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private get currentVisit() {
            return currentVisitCache.value;
        }

        private get disableEdit() {
            return this.document?.nhisReferral?.nrn?.length > 0;
        }

        private get showNhisReferral() {
            return this.document.id > 0 && this.document.nhisReferral;
        }

        private get examDiagnosis() {
            return currentVisitCache.value.exam?.getExamDiagnosis(this.document.diagnosis1Id);
        }

        private set examDiagnosis(examDiagnosis) {
            this.document.diagnosis1Id = examDiagnosis ? examDiagnosis.id : null;
        }

        private get examDiagnosis2() {
            return currentVisitCache.value.exam?.getExamDiagnosis(this.document.diagnosis2Id);
        }

        private set examDiagnosis2(examDiagnosis2) {
            this.document.diagnosis2Id = examDiagnosis2 ? examDiagnosis2.id : null;
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                await this.getReferral6Subtypes();
                if (!this.documentId) {
                    const responseExam = await referral6Service.getReferralInfoByExamId(this.examId);
                    this.document = responseExam.data;
                } else {
                    const response = await referral6Service.getReferral6(this.documentId);
                    this.document = response.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async getReferral6Subtypes() {
            const response = await referralSubtypeService.getReferral6Subtypes();
            this.referralSubtypes = response.data;
        }

        private async save() {
            this.$loading.show();
            try {
                let refId = this.document.id;
                if (refId === 0) {
                    const refResponse = await referral6Service.createReferral6(this.document);
                    refId = Number(refResponse.data);
                    this.copyObject();
                    eventBus.$emit(
                        'create-referral-event',
                        refId,
                        ReferralTypeCode.Referral6,
                        'Талон за медицинска експертиза'
                    );
                    this.$router.push(`/Exam/Referral6/Edit/${refId}`);
                } else {
                    await referral6Service.updateReferral6(this.document);
                    eventBus.$emit('update-referral-event', 'Талон за МЕ');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            await this.save();
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const refId = this.document.id;
                await referral6Service.deleteReferral6(refId);
                this.copyObject();
                this.$router.push(`/Exam/Referral6/Create/${currentVisitCache.value.id}`);
                eventBus.$emit('delete-referral-event', refId, ReferralTypeCode.Referral6, 'Талон за МЕ');
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.document.id) {
                this.$router.push(`/Print/Exam.Referral.Referral6/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Referral.Referral6';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
