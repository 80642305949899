// Име на елемента в local storage, който съхранява всички кеша за избрания подпис.
import { plainToInstance } from 'class-transformer';
import { Vue } from 'vue-property-decorator';

import { LocalServerCertificateCacheDto } from '@/model/LocalServer/LocalServerCertificateCacheDto';

const localServerCertificateCacheKey: string = 'localServerCertificate';

class LocalServerCertificateCache {
    private _value: LocalServerCertificateCacheDto | null = null;

    public get value() {
        return this._value;
    }

    public clear() {
        localStorage.removeItem(localServerCertificateCacheKey);
        this._value = null;
    }

    public set(certificate: LocalServerCertificateCacheDto) {
        localStorage.setItem(localServerCertificateCacheKey, JSON.stringify(certificate));
        this._value = certificate;
    }

    public loadFromLocalStorage() {
        const json = localStorage.getItem(localServerCertificateCacheKey);
        if (json) {
            this._value = plainToInstance(LocalServerCertificateCacheDto, JSON.parse(json));
        }
    }
}

export const localServerCertificateCache = Vue.observable(new LocalServerCertificateCache());
