<template>
    <v-form ref="form" v-model="isFormValid">
        <v-row>
            <v-col cols="12" md="6">
                <practice-number v-model="practice.practiceNumber" required @input="searchPractice"></practice-number>
            </v-col>
            <v-col cols="12" md="6">
                <text-field
                    v-model="practice.name"
                    required
                    :readonly="existingPracticeHasName"
                    label="Име"
                    @input="checkValidation"
                ></text-field>
            </v-col>
            <v-col cols="12">
                <city-picker v-model="practice.cityId" :disabled="existingPracticeHasCity" @input="checkValidation" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import CityPicker from '@/component/Picker/CityPicker.vue';
    import PracticeNumber from '@/component/Practice/PracticeNumber.vue';
    import { PracticeSelectDto } from '@/model/Practice/PracticeSelectDto';
    import { practiceService } from '@/service/Practice/PracticeService';

    @Component({ components: { PracticeNumber, CityPicker } })
    export default class PracticeSelect extends Vue {
        @Prop()
        practiceNumber!: string;

        private practice: PracticeSelectDto = new PracticeSelectDto();
        private isFormValid: boolean = false;
        // Ако вече съществува практика с въведения номер, но тя е куха (името или населеното място не са попълнени)
        // потребителят може и трябва да въведе име, и може да въведе населено място - принцип "първият печели".
        private existingPracticeHasName: boolean = false;
        private existingPracticeHasCity: boolean = false;

        async searchPractice(): Promise<void> {
            this.checkValidation();
            const practiceNumberLength = 10;
            if (this.practice.practiceNumber.length === practiceNumberLength) {
                this.$loading.show();
                try {
                    const existingPractice = await practiceService.getPracticeByNumber(this.practice.practiceNumber);
                    if (existingPractice) {
                        this.practice = existingPractice;
                        this.existingPracticeHasName = Boolean(existingPractice.name);
                        this.existingPracticeHasCity = Boolean(existingPractice.cityId);
                        this.$emit('ready', this.practice);
                    } else {
                        this.existingPracticeHasName = false;
                        this.existingPracticeHasCity = false;
                    }
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private checkValidation() {
            if (!this.existingPracticeHasName) {
                this.isFormValid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    this.$emit('ready', this.practice);
                } else {
                    this.$emit('ready', null);
                }
            }
        }

        @Watch('practiceNumber')
        onPracticeNumberChange(value: string) {
            this.practice.practiceNumber = value;
            if (value) {
                this.searchPractice();
            }
        }
    }
</script>
