<template>
    <v-card>
        <v-card-title>Данни за е-алергия</v-card-title>
        <v-card-text>
            <v-row v-if="value" no-gutters>
                <v-col md="3" class="px-1">
                    <text-field v-model="value.nrn" :class="nrnBackground" label="НРН номер" readonly></text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-row dense>
                <v-col v-if="canCreate" md="3">
                    <btn action="Send" block :disabled="disable || isLoading" @click="postCreate" />
                </v-col>
                <v-col v-if="canUpdate" md="3">
                    <btn action="Edit" block :disabled="disable || isLoading" @click="postUpdate" />
                </v-col>
                <v-col v-if="!id" md="3">
                    <btn action="Refresh" block :disabled="disable || isLoading" @click="postExtraction">
                        Извличане на алергии
                    </btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhisAllergyDto } from '@/model/Nhis/Allergy/NhisAllergyDto';
    import { NhisAllergyExtractionCommand } from '@/model/Nhis/Allergy/NhisAllergyExtractionCommand';
    import { NhisXmlRequestExtendedDto } from '@/model/Nhis/NhisXmlRequestExtendedDto';
    import { nhisAllergyService } from '@/service/Nhis/NhisAllergyService';
    import { nhisService } from '@/service/Nhis/NhisService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class NhisAllergy extends Vue {
        @Prop()
        value!: NhisAllergyDto;

        @Prop()
        private disable!: boolean;

        @Prop()
        private id!: number;

        @Prop()
        private patientId!: number;

        private get canCreate() {
            return !this.value?.nrn && this.id;
        }

        private get canUpdate() {
            return this.value?.nrn && this.id;
        }

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private get nrnBackground() {
            return this.value?.nrn ? 'text-field-success' : '';
        }

        private async postCreate() {
            const response = await nhisAllergyService.postCreate(this.requestData());
            if (response) {
                this.change(response);
            }
        }

        private async postUpdate() {
            const response = await nhisAllergyService.postUpdate(this.requestData());
            if (response) {
                this.change(response);
            }
        }

        private async postExtraction() {
            const path = await nhisAllergyService.extractAllergy(
                new NhisAllergyExtractionCommand(
                    currentPatientCache.value.key,
                    userContextCache.current?.doctorUin ?? '',
                    userContextCache.current?.deputyDoctorUin ?? null
                )
            );
            if (path) {
                this.$router.push(path);
            }
        }

        private change(data: NhisAllergyDto) {
            this.$emit('input', data);
        }

        private requestData(): NhisXmlRequestExtendedDto {
            const requestBase = nhisService.requestData(this.id, null);

            const requestExtended = new NhisXmlRequestExtendedDto(
                requestBase.entityId,
                requestBase.doctorEmployeeId,
                requestBase.doctorUin,
                requestBase.deputyDoctorUin
            );
            requestExtended.practiceNumber = userContextCache.current?.practiceNumber ?? '';
            requestExtended.doctorSpecialtyCode = userContextCache.current?.specialtyCode ?? '';
            requestExtended.deputyDoctorIsHired = userContextCache.current?.deputyDoctorIsHired ?? null;
            return requestExtended;
        }
    }
</script>

<style scoped></style>
