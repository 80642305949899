import { NhifProtocolDocumentDto } from '@/model/Exam/DrugProtocol/NhifProtocolDocumentDto';
import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

export class NhifProtocolUploadCommand {
    public doctorUin: string = '';
    public deputyDoctorUin: string | null = null;
    public deputyDoctorIsHired: boolean | null = null;
    public nrnProtocol: string = '';
    public documents: NhifProtocolDocumentDto[] = [];
    public practiceId: number = 0;
    public patientVersionKey: PatientVersionKey = new PatientVersionKey();
    public doctorEmployeeSeqNumber: number = 0;
}
