<template>
    <v-dialog v-model="historyDialogIsVisible" persistent max-width="75%">
        <template #activator="{ on }">
            <btn action="CheckList" :disabled="disabled" v-on="on" @click="loadPrescriptionHistory"
                >Проверка за медикаменти</btn
            >
        </template>

        <data-table
            :headers="tableHeaders"
            :items="prescriptionHistoryItems"
            :loading="$loading.isVisible"
            disable-pagination
        >
            <template #title>
                Предписани медикаменти
                <v-spacer />
                <btn icon action="Close" @click="historyDialogIsVisible = false" />
            </template>
        </data-table>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    // Vuetify bugfix: Глобално регистриран custom компонент (като btn), поставен в activator слота на диалог,
    // не се появява при първоначално зареждане на SPA, а чак след рутиране. Ако се импортира изрично, нещата се оправят.
    import Btn from '@/component/Common/Btn.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhifPrescriptionHistoryDto } from '@/model/Nhif/NhifPrescriptionHistoryDto';
    import { nhifPrescriptionBookService } from '@/service/Nhif/NhifPrescriptionBookService';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { Btn }
    })
    export default class NhifPrescriptionHistory extends Vue {
        @Prop()
        disabled!: boolean;

        private historyDialogIsVisible: boolean = false;
        private prescriptionHistoryItems: NhifPrescriptionHistoryDto[] = [];

        private tableHeaders: IDataTableHeader[] = [
            { text: 'Дата на изписване:', value: 'prescriptionDate', formatter: formatters.date },
            { text: 'Дата на изпъленение:', value: 'fulfilDate', formatter: formatters.date },
            { text: 'МКБ:', value: 'icdCode' },
            { text: 'Код по НЗОК:', value: 'nhifCode' },
            { text: 'Предписано количество:', value: 'quantityPrescribed' },
            { text: 'НРН на рецепта:', value: 'nrnPrescription' },
            { text: 'НРН на отпускане:', value: 'nrnDispense' },
            { text: 'Номер на протокол:', value: 'protocolNumber' },
            { text: 'Предписано количество:', value: 'quantityPrescribed' },
            { text: 'Предписано ЛЗ с номер:', value: 'practiceNumber' },
            { text: 'Лекар УИН:', value: 'doctorUin' }
        ];

        private async loadPrescriptionHistory() {
            this.$loading.show();
            try {
                const items = await nhifPrescriptionBookService.prescriptionHistory();
                if (items) {
                    this.prescriptionHistoryItems = items;
                } else {
                    this.historyDialogIsVisible = false;
                }
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
