import { Type } from 'class-transformer';

import { DoctorDropdownDto } from '@/model/Doctor/DoctorDropdownDto';
import { DispensaryRegisterDto } from '@/model/Register/Dispensary/DispensaryRegisterDto';

export class DispensaryExternalEditDto {
    @Type(() => DispensaryRegisterDto)
    public register: DispensaryRegisterDto = new DispensaryRegisterDto();

    @Type(() => DoctorDropdownDto)
    public doctorInfo: DoctorDropdownDto = new DoctorDropdownDto();

    public doctorSpecialtyCode: string = '';
}
