<template>
    <v-row>
        <v-col cols="12" md="4"><v-card-title class="py-1">Версия на инф. за пациента</v-card-title></v-col>
        <v-col cols="12" md="8">
            <v-card-text class="py-0">
                <dropdown
                    :value="value"
                    :items="versions"
                    return-object
                    item-text="validityText"
                    @input="$emit('input', ...arguments)"
                ></dropdown>
            </v-card-text>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PatientVersionDropdownDto } from '@/model/Patient/PatientVersionDropdownDto';

    @Component
    export default class PatientVersionPicker extends Vue {
        @Prop({ required: true })
        private value!: PatientVersionDropdownDto | null;

        @Prop({ required: true })
        private versions!: PatientVersionDropdownDto[];
    }
</script>
