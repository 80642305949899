import { Type } from 'class-transformer';

import { PrescriptionKey } from './PrescriptionKey';

export class PrescriptionCopyCommand {
    @Type(() => PrescriptionKey)
    public sourcePrescriptionKey: PrescriptionKey;

    constructor(
        sourcePrescriptionKey: PrescriptionKey,
        public targetPrescriptionType: string,
        public targetExamId: number
    ) {
        this.sourcePrescriptionKey = sourcePrescriptionKey;
    }
}
