<template>
    <v-dialog v-model="dialogIsVisible" max-width="75%">
        <v-card>
            <v-card-title>
                <v-spacer />
                <btn icon action="Close" @click="closeDialog" />
            </v-card-title>
            <v-card-text>
                <v-row class="no-gutters">
                    <v-col md="3" sm="6">
                        <text-field v-model="value.nrn" label="НРН" readonly />
                    </v-col>
                    <v-col md="3" sm="6">
                        <text-field v-model="value.lrn" label="ЛРН" readonly />
                    </v-col>
                    <v-col md="3" sm="6">
                        <text-field v-model="value.status" label="Статус" readonly />
                    </v-col>
                    <v-col md="3" sm="6">
                        <text-field v-model="value.nhifVisitReason" label="Тип преглед" readonly />
                    </v-col>
                    <v-col md="3" sm="6">
                        <v-checkbox v-model="value.isSecondary" hide-details label="Вторичен преглед" readonly />
                    </v-col>
                    <v-col md="3" sm="6">
                        <v-checkbox v-model="value.isIncidental" hide-details label="Инцидентно посещение" readonly />
                    </v-col>
                    <v-col md="3" sm="6">
                        <date-field
                            v-model="value.openDate"
                            show-time
                            hide-details
                            label="Дата на започване на прегледа"
                            readonly
                        />
                    </v-col>
                    <v-col md="3" sm="6">
                        <date-field
                            v-model="value.closeDate"
                            show-time
                            hide-details
                            label="Дата на затваряне на прегледа"
                            readonly
                        />
                    </v-col>
                </v-row>
                <v-row class="no-gutters">
                    <v-col md="6" sm="12">
                        <h4 v-if="value.diagnosis" class="mb-3">Основна диагноза</h4>
                        <v-row v-if="value.diagnosis">
                            <v-col cols="6"> <icd-picker v-model="value.diagnosis.icdCode" :disabled="true" /> </v-col>
                            <v-col cols="6">
                                <icd-picker v-model="value.diagnosis.icdCodeAdd" :disabled="true"
                            /></v-col>
                        </v-row>
                        <h4 v-if="value.comorbiditiesNames" class="mb-3">Придружаващи диагнози</h4>
                        <v-row v-for="(comorbidity, index) in value.comorbiditiesNames" :key="index">
                            <v-col cols="6"> <icd-picker v-model="comorbidity.icdCode" :disabled="true" /> </v-col>
                            <v-col cols="6"> <icd-picker v-model="comorbidity.icdCodeAdd" :disabled="true" /></v-col>
                        </v-row>
                    </v-col>
                    <v-col md="6" sm="12">
                        <v-row class="no-gutters">
                            <v-col cols="12">
                                <v-textarea v-model="value.medicalHistory" label="Анамнеза" readonly rows="3" />
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="value.objectiveCondition"
                                    label="Обективно състояние"
                                    readonly
                                    rows="3"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-textarea v-model="value.therapy" label="Терапия" readonly rows="3" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <h4 class="mb-3">Лекар извършил прегледа</h4>
                <v-row class="no-gutters">
                    <v-col md="3" sm="6">
                        <text-field v-model="value.performerInfo.practiceNumber" label="Практика номер" readonly />
                    </v-col>
                    <v-col md="3" sm="6">
                        <text-field v-model="value.performerInfo.doctorUin" label="Уин" readonly />
                    </v-col>
                    <v-col md="3" sm="6">
                        <text-field v-model="value.performerInfo.deputyDoctorUin" label="Уин на заместващ" readonly />
                    </v-col>
                    <v-col md="3" sm="6">
                        <text-field
                            v-model="value.performerInfo.doctorQualificationCode"
                            label="Код специалност"
                            readonly
                        />
                    </v-col>
                    <v-col md="3" sm="6">
                        <v-checkbox
                            v-model="value.performerInfo.doctorIsDeputyDoctor"
                            hide-details
                            label="Заместващ"
                            readonly
                        />
                    </v-col>
                    <v-col md="3" sm="6">
                        <v-checkbox v-model="value.performerInfo.doctorIsHired" hide-details label="Нает" readonly />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import Btn from '@/component/Common/Btn.vue';
    import DateField from '@/component/Date/DateField.vue';
    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import { NhisExamExtractionDto } from '@/model/Nhis/Exam/NhisExamExtractionDto';

    @Component({
        components: { Btn, IcdPicker, DateField }
    })
    export default class NhisExtractedExam extends Vue {
        @Prop()
        value!: NhisExamExtractionDto;

        @Prop({ default: false })
        dialogIsVisible!: boolean;

        private closeDialog() {
            this.$emit('close-dialog');
        }
    }
</script>

<style scoped></style>
