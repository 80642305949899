import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { CertificateDriveDto } from '@/model/Exam/Certificate/CertificateDriveDto';
import { CertificateDriveNhisDto } from '@/model/Exam/Certificate/CertificateDriveNhisDto';
import { CertificateDriveNhisViewDto } from '@/model/Exam/Certificate/CertificateDriveNhisViewDto';
import { CertificateDriveViewDto } from '@/model/Exam/Certificate/CertificateDriveViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const certificateDriveService = {
    getCertificateDrive(id: number): Promise<AxiosResponse<CertificateDriveDto>> {
        return httpService.get(`/CertificateDrive/GetCertificateDrive/${id}`);
    },

    getCertificateInfoByExamId(examId: number): Promise<AxiosResponse<CertificateDriveDto>> {
        return httpService.get(`/CertificateDrive/GetCertificateInfoByExamId/${examId}`);
    },

    createCertificateDrive(data: CertificateDriveDto): Promise<AxiosResponse<CertificateDriveDto>> {
        return httpService.post('/CertificateDrive/CreateCertificateDrive', data);
    },

    updateCertificateDrive(data: CertificateDriveDto): Promise<AxiosResponse<CertificateDriveDto>> {
        return httpService.put('/CertificateDrive/UpdateCertificateDrive/', data);
    },

    deleteCertificateDrive(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/CertificateDrive/DeleteCertificateDrive/${id}`);
    },

    canSaveCertificateDrive(data: CertificateDriveDto): Promise<AxiosResponse<string[]>> {
        return httpService.post('/CertificateDrive/CanSaveCertificateDrive', data);
    },

    getCertificatesByExamId(examId: number): Promise<AxiosResponse<CertificateDriveViewDto[]>> {
        return httpService.get(`/CertificateDrive/GetCertificateDriveViewsByExam`, {
            params: { examId }
        });
    },

    createCertificateDriveNhis(data: CertificateDriveNhisDto): Promise<AxiosResponse<number>> {
        return httpService.post('/CertificateDrive/CreateCertificateDriveNhis', data);
    },

    updateCertificateDriveNhis(data: CertificateDriveNhisDto): Promise<AxiosResponse<CertificateDriveNhisDto>> {
        return httpService.put('/CertificateDrive/UpdateCertificateDriveNhis/', data);
    },

    getCertificateDriveNhis(id: number): Promise<AxiosResponse<CertificateDriveNhisDto>> {
        return httpService.get(`CertificateDrive/GetCertificateDriveNhis/${id}`);
    },

    async getCertificateDriveNhisInfoByExam(examId: number): Promise<CertificateDriveNhisDto> {
        const response = await httpService.get(`CertificateDrive/GetCertificateDriveNhisInfoByExam`, {
            params: {
                examId
            }
        });

        return plainToInstance(CertificateDriveNhisDto, response?.data);
    },

    getCertificatesNhisByExamId(examId: number): Promise<AxiosResponse<CertificateDriveNhisViewDto[]>> {
        return httpService.get(`/CertificateDrive/GetCertificateDriveNhisViewsByExam`, {
            params: { examId }
        });
    }
};
