import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { EventRecurrenceDto } from '@/model/WorkTime/EventRecurrenceDto';

export class EventDto {
    public practiceId: number = 0;
    public id: number = 0;
    public employeeSeqNumber: number | null = null;
    public doctorId: number = 0;
    public doctorName: string = '';
    public specialtyCode: SpecialtyCode | null = null;
    public specialtyName: string = '';
    public workTimeVisitReasonId: number | null = null;
    public workTimeVisitReasonName: string = '';

    public name: string = '';
    public date: Date | null = null;
    public startHour: number | null = null;
    public startMinute: number | null = null;
    public endHour: number | null = null;
    public endMinute: number | null = null;
    public color: string = '';
    public examDurationInMinutes: number | null = null;

    public recurrence: EventRecurrenceDto | null = null;
}
