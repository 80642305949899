import { plainToInstance } from 'class-transformer';

import { NhisDoctorDeputyCheckResultDto } from '@/model/Nhis/DoctorDeputy/NhisDoctorDeputyCheckResultDto';
import { NhisDoctorDeputyResponseDto } from '@/model/Nhis/DoctorDeputy/NhisDoctorDeputyResponseDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { nhisService } from '@/service/Nhis/NhisService';
import { nhisKey } from '@/store/NhisNhifAccessTokenCache';

export const nhisDoctorDeputyService = {
    async getDoctorDeputyCheckXml(practiceId: number, doctorEmployeeSeqNumber: number): Promise<string> {
        const response = await httpService.get<string>(`/${nhisKey}/GetDoctorDeputyCheckXml/`, {
            params: { practiceId, doctorEmployeeSeqNumber }
        });
        return response?.data ?? '';
    },

    async postDoctorDeputyCheck(xmlString: string): Promise<NhisDoctorDeputyCheckResultDto> {
        const result = await nhisService.postXmlToNhis<NhisDoctorDeputyCheckResultDto>(
            'PostDoctorDeputyCheckRequest',
            xmlString
        );
        return plainToInstance(NhisDoctorDeputyCheckResultDto, result);
    },

    async getDoctorDeputyScheduleActionXml(scheduleId: number): Promise<string> {
        const response = await httpService.get<string>(`/${nhisKey}/GetDoctorDeputyScheduleActionXml/${scheduleId}`);
        return response?.data ?? '';
    },

    async postDoctorDeputyScheduleAction(xmlString: string, id: number): Promise<NhisDoctorDeputyResponseDto> {
        const result = await nhisService.postXmlToNhisWithId<NhisDoctorDeputyResponseDto>(
            'PostDoctorDeputyScheduleActionRequest',
            xmlString,
            id
        );
        return plainToInstance(NhisDoctorDeputyResponseDto, result);
    }
};
