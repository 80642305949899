import { plainToInstance } from 'class-transformer';

import { FamilyHistoryStatusDto } from '@/model/Nomenclature/FamilyHistoryStatusDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const familyHistoryStatusService = {
    async getFamilyHistoryStatuses(): Promise<FamilyHistoryStatusDto[]> {
        const response = await httpService.get<FamilyHistoryStatusDto[]>(
            '/FamilyHistoryStatus/GetFamilyHistoryStatuses'
        );
        return plainToInstance(FamilyHistoryStatusDto, response?.data);
    }
};
