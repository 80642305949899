import { Report365RowBase } from '@/model/Report/Rhi/Report365/Report365RowBase';

export class Annex3ChildrenRegisterRowDto extends Report365RowBase {
    public countStartYear: number | null = null;
    public countDuringYear: number | null = null;
    public countDeregistered: number | null = null;
    public countDeregisteredOtherAge: number | null = null;
    public countDead: number | null = null;
    public get countEndYear(): number {
        return (
            (this.countStartYear === null ? 0 : Number(this.countStartYear)) +
            (this.countDuringYear === null ? 0 : Number(this.countDuringYear)) -
            (this.countDeregistered === null ? 0 : Number(this.countDeregistered)) -
            (this.countDeregisteredOtherAge === null ? 0 : Number(this.countDeregisteredOtherAge)) -
            (this.countDead === null ? 0 : Number(this.countDead))
        );
    }
}
