export class SubjectToImmunizationDto {
    public patientName: string = '';
    public patientIdentifier: string = '';
    public patientBirthdate: Date | null = null;
    public immunizationId: number = 0;
    public immunizationName: string = '';
    public nhifCode: string = '';
    public rhiCode: string = '';
    public patientIsInsured: boolean = false;
    public patientId: number | null = null;
}
