export class NhisGroupSummaryDto {
    public examIdsToOpen: number[] = [];
    public referral3Ids: number[] = [];
    public referral3aIds: number[] = [];
    public referral4Ids: number[] = [];
    public referral6Ids: number[] = [];
    public referral7Ids: number[] = [];
    public referralTelkIds: number[] = [];
    public medicalNoteIds: number[] = [];
    public immunizationCount: number = 0;
    public prescriptionCount: number = 0;
    public examIdsToClose: number[] = [];
    public examIdsToEdit: number[] = [];
}
