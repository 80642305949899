<template>
    <v-card flat>
        <v-card-title>Нямате права или входът е неуспешен.</v-card-title>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class Unauthorized extends Vue {}
</script>
