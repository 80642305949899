<template>
    <v-card>
        <v-card-title>НЗОК лекарства</v-card-title>

        <v-card-text disabled>
            <v-row>
                <v-col md="6" cols="12">
                    <dropdown-search
                        v-model="selected"
                        :service="nhifSearchService"
                        return-object
                        item-text="tradeName"
                        item-value="nhifCode"
                        label="Търсене на лекарство"
                    />
                </v-col>
            </v-row>
            <v-row v-if="selected">
                <v-col md="3" cols="6">
                    <text-field v-model="selected.nhifCode" disabled label="Код по НЗОК" />
                </v-col>
                <v-col md="9" cols="12">
                    <text-field v-model="selected.tradeName" disabled label="Наименование" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="selected.medicineFormat" disabled label="Форма" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="selected.producer" disabled label="Производител" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="selected.quantity" disabled label="Количество" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="selected.nhifDrugCategory" disabled label="Категория" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="selected.singlePackage" disabled label="Количество в опаковка" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="selected.finalPackage" disabled label="Окончателна опаковка" />
                </v-col>
                <v-col md="1" cols="6">
                    <v-checkbox v-model="selected.isDivisible" disabled dense label="Делимо" />
                </v-col>
                <v-col md="2" cols="6">
                    <v-checkbox v-model="selected.isFullPayed" disabled dense label="Изцяло платено" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="selected.pdlCode" disabled label="Код по НСЦРЛП" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="selected.atcCode" disabled label="ATC код" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="selected.maxPrice" disabled label="Цена на лекарството" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="selected.nhifPrize" disabled label="Цена заплащана от НЗОК" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="selected.maxPatientPrice" disabled label="Цена заплащана от пациента" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="selected.wayOfPrescribing" disabled label="По протокол" />
                </v-col>
                <v-col md="6" cols="12">
                    <v-textarea v-model="selected.icdCodes" disabled dense label="По мкб" />
                </v-col>
                <v-col md="6" cols="12">
                    <v-textarea v-model="selected.prescriptionForms" disabled dense label="За бланки" />
                </v-col>
                <v-col md="12" cols="12">
                    <v-textarea v-model="selected.notes" disabled dense label="Бележки" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import DropdownSearch from '@/component/Dropdown/DropdownSearch.vue';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { nhifDrugSearchService } from '@/service/Nomenclature/NhifDrugService';

    @Component({
        components: { DropdownSearch }
    })
    export default class NhifDrug extends Vue {
        private selected: NhifDrugDto = new NhifDrugDto();

        private get nhifSearchService() {
            return nhifDrugSearchService;
        }
    }
</script>
