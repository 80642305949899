import { Type } from 'class-transformer';

import { Annex3ChildrenRegisterRowDto } from '@/model/Report/Rhi/Annex3/Annex3ChildrenRegisterRowDto';

export class Annex3ChildrenRegisterDto {
    public name: string = '';

    @Type(() => Annex3ChildrenRegisterRowDto)
    public childrenRegisters: Annex3ChildrenRegisterRowDto[] = [];
}
