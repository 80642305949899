<template>
    <div v-if="!item.options.hidden">
        <template v-if="item.children">
            <v-list>
                <v-list-group v-model="item.options.expanded" :disabled="item.options.disabled">
                    <template #activator>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <menu-item
                        v-for="(child, index) in item.children"
                        :key="`${index}_${_uid}`"
                        :item="child"
                    ></menu-item>
                </v-list-group>
            </v-list>
        </template>
        <v-list-item
            v-else-if="item.link"
            :to="item.link"
            :color="itemColor"
            :class="'px-2 ' + item.options.class"
            :disabled="item.options.disabled"
            @click="itemClicked"
        >
            <!-- mr-3 намалява разстоянието между иконката и текста. -->
            <v-list-item-icon class="mr-3">
                <v-icon :color="itemColor">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-subheader v-else :class="item.options.class">{{ item.title }}</v-subheader>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { MenuItemModel } from '@/model/Menu/MenuItemModel';
    import { menuState } from '@/store/MenuState';
    import { userSettingsState } from '@/store/User/UserSettingsState';

    @Component
    export default class MenuItem extends Vue {
        @Prop({ required: true })
        private item!: MenuItemModel;

        private itemClicked() {
            // Избор на елемент от главното или от второстепенните менюта свива главното меню.
            // Логиката е дублирана и във VisitMenu.vue, защото там не се използва този компонент.
            menuState.mainMenuIsCollapsed = true;
            this.$emit('click');
        }

        // Иконките в менютата изглеждат зле с primary цвят на тъмна тема, затова тогава се използва secondary.
        private get itemColor() {
            return userSettingsState.userSettings.ui.isDarkTheme ? 'secondary' : 'primary';
        }
    }
</script>
