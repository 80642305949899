<template>
    <div>
        <text-field
            :value="value"
            :required="required"
            :rules="[$validator.practiceNumberLength]"
            :disabled="disabled"
            label="Номер на практика"
            @input="$emit('input', ...arguments)"
        ></text-field>
        <practice-type-label :practice-number="value"></practice-type-label>
        <div class="caption text--secondary">{{ districtAndMunicipality }}</div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { practiceService } from '@/service/Practice/PracticeService';

    import PracticeTypeLabel from './PracticeTypeLabel.vue';

    @Component({
        components: { PracticeTypeLabel }
    })
    export default class PracticeNumber extends Vue {
        @Prop()
        value!: string;

        @Prop()
        required!: boolean;

        @Prop()
        disabled!: boolean;

        lastPrefix: string = '';
        districtAndMunicipality: string = '';

        private async showDistrictAndMunicipality(): Promise<void> {
            const municipalityCodeEndPos = 4;
            if (this.value.length >= municipalityCodeEndPos) {
                const prefix = this.value.slice(0, municipalityCodeEndPos);

                if (this.lastPrefix !== prefix) {
                    this.lastPrefix = prefix;
                    const result = await practiceService.getDistrictAndMunicipalityFromPracticeNumber(this.value);

                    this.districtAndMunicipality = result.districtName
                        ? result.municipalityName
                            ? `Област: ${result.districtName}, Община: ${result.municipalityName}`
                            : `Област: ${result.districtName}`
                        : '';
                }
            } else {
                this.lastPrefix = '';
                this.districtAndMunicipality = '';
            }
        }

        @Watch('value')
        async onValueChanged() {
            await this.showDistrictAndMunicipality();
        }
    }
</script>
