import { Type } from 'class-transformer';

import { Annex2PregnancyRegisterRowDto } from '@/model/Report/Rhi/Annex2/Annex2PregnancyRegisterRowDto';

export class Annex2PregnancyRegisterDto {
    public name: string = '';

    @Type(() => Annex2PregnancyRegisterRowDto)
    public pregnancyRegisters: Annex2PregnancyRegisterRowDto[] = [];
}
