import { AxiosResponse } from 'axios';

import { NhisPreventiveExamTypeCommand } from '@/model/Nhis/Exam/NhisPreventiveExamTypeCommand';
import { NhisPreventiveExamTypeDto } from '@/model/Nomenclature/NhisPreventiveExamTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhisPreventiveExamTypeService = {
    getNhisPreventiveExamTypes(
        command: NhisPreventiveExamTypeCommand
    ): Promise<AxiosResponse<NhisPreventiveExamTypeDto[]>> {
        return httpService.post('/NhisPreventiveExamType/GetNhisPreventiveExamTypes', command);
    }
};
