<template>
    <v-row align="stretch" no-gutters>
        <v-col md="4" sm="12" :class="{ 'pr-1': $vuetify.breakpoint.mdAndUp }">
            <v-card :style="`background-color: ${backgroundColor}`" class="fill-height">
                <h4>Издадени документи</h4>
                <v-row no-gutters>
                    <!-- Името на v-model prop-а на v-checkbox е 'input-value', а не стандартното 'value'. При еднопосочно bind-ване се използва 'input-value'. -->
                    <v-col md="12"
                        ><v-checkbox
                            class="no-margin-no-padding"
                            :input-value="examDto.hasReferral3"
                            dense
                            disabled
                            label="медицински направления за консултация или за провеждане на съвместно лечение"
                        ></v-checkbox
                    ></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6">
                        <AmbulatorySheetReferral3Card v-model="examDto.referral3[0]" />
                    </v-col>
                    <v-col cols="6">
                        <AmbulatorySheetReferral3Card v-model="examDto.referral3[1]" />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6">
                        <AmbulatorySheetReferral3Card v-model="examDto.referral3[2]" />
                    </v-col>
                    <v-col cols="6">
                        <AmbulatorySheetReferral3Card v-model="examDto.referral3[3]" />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col md="12"
                        ><v-checkbox
                            :input-value="examDto.hasReferral3a"
                            disabled
                            label="медицински направления за ВСД и назначени ВСД"
                            dense
                        ></v-checkbox
                    ></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12"> <AmbulatorySheetReferral3aCard v-model="examDto.referral3a[0]" /></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12"><AmbulatorySheetReferral3aCard v-model="examDto.referral3a[1]" /></v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col md="8" sm="12" :class="{ 'pl-1': $vuetify.breakpoint.mdAndUp }">
            <v-card :style="`background-color: ${backgroundColor}`" class="fill-height">
                <h4>Придружаващи заболявания и усложнения</h4>
                <v-row v-for="(examDiagnosis, index) in examDto.diagnoses.slice(1)" :key="index" no-gutters>
                    <v-col cols="12" class="mb-2 pa-1 d-flex flex-row">
                        <AmbulatorySheetDiagnosisCard
                            :value="examDto.diagnoses[index + 1]"
                            :exam-date-time="examDto.startDateTime"
                            :exam-visit-type="examDto.nhifVisitReasonCode"
                            :watch-diagnosis="watchDiagnosis"
                            :exam-type-change="examTypeChanged"
                            :is-incidental="examDto.isIncidental"
                            :is-exam-copy="isExamCopy"
                            :last-dispensary-number="lastDispensaryNumber"
                            @register-include="addToRegisterMark"
                            @icd-change="icdChange"
                        />
                        <btn
                            v-if="canRemove && !examDiagnosis.usedForms"
                            icon
                            action="Delete"
                            @click="removeDiagnosis(index)"
                        />
                    </v-col>
                </v-row>
                <v-card-actions>
                    <btn v-if="canAdd" action="New" block @click="addDiagnosis"> Добавяне на диагноза </btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { AmbulatorySheetDiagnosisDto } from '@/model/Exam/AmbulatorySheetDiagnosisDto';
    import { ExamDto } from '@/model/Exam/ExamDto';
    import { arrayUtil } from '@/util/ArrayUtil';
    import AmbulatorySheetDiagnosisCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetDiagnosisCard.vue';
    import AmbulatorySheetReferral3aCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetReferral3aCard.vue';
    import AmbulatorySheetReferral3Card from '@/view/Exam/AmbulatorySheet/AmbulatorySheetReferral3Card.vue';

    @Component({
        components: { AmbulatorySheetReferral3Card, AmbulatorySheetReferral3aCard, AmbulatorySheetDiagnosisCard }
    })
    export default class AmbulatorySheetComorbiditiyDiagnoses extends Vue {
        @Prop()
        examDto!: ExamDto;

        @Prop()
        watchDiagnosis!: boolean;

        @Prop()
        private examTypeChanged!: boolean;

        @Prop({ default: 'transparent' })
        private backgroundColor!: string;

        private get isExamCopy() {
            return Boolean(this.examDto.copiedExamId);
        }

        private get canAdd() {
            const maxDiagnosesCount = 8;
            return this.examDto.diagnoses.length < maxDiagnosesCount;
        }

        private get canRemove() {
            const usualDiagnosesCount = 5;
            return this.examDto.diagnoses.length > usualDiagnosesCount;
        }

        private get lastDispensaryNumber() {
            let lastDispensaryNumber = 0;
            for (const diagnosis of this.examDto.diagnoses) {
                const dossierNumber = diagnosis?.dispensaryRegister?.dossierNumber ?? 0;
                if (dossierNumber > lastDispensaryNumber) {
                    lastDispensaryNumber = dossierNumber;
                }
            }
            return lastDispensaryNumber;
        }

        private addDiagnosis() {
            this.examDto.diagnoses.push(new AmbulatorySheetDiagnosisDto());
        }

        private removeDiagnosis(index: number) {
            arrayUtil.removeAt(this.examDto.diagnoses, index + 1);
        }

        private addToRegisterMark() {
            this.$emit('register-include');
        }

        private icdChange(icdCode: string) {
            this.$emit('icd-change', icdCode);
        }
    }
</script>
