<template>
    <v-card>
        <v-card-title>
            {{ amountWithVatTitle }}
            <template v-if="customerCredit.paidOn">, получен на {{ paidOnText }}</template>
        </v-card-title>
        <v-card-actions>
            <btn action="Back" :to="licensingRoute" />
            <btn action="Save" @click="updateCustomerCredit" />
        </v-card-actions>
        <v-card-text>
            <v-form ref="documentForm" v-model="isValid">
                <v-row v-if="selectedFileName" class="mt-2">
                    <v-col>
                        <text-field label="Избран файл за прикачване" :value="selectedFileName" readonly></text-field>
                    </v-col>
                    <v-col cols="auto">
                        <btn icon action="Delete" @click="selectedFileName = ''" />
                    </v-col>
                </v-row>
                <v-row v-else-if="customerCredit.paymentOrderFileName" class="mt-2">
                    <v-col>
                        <text-field
                            label="Прикачено платежно нареждане"
                            :value="customerCredit.paymentOrderFileName"
                            readonly
                        ></text-field>
                    </v-col>
                    <v-col cols="auto">
                        <btn icon action="Download" @click="downloadPaymentOrderFile" />
                    </v-col>
                    <v-col cols="auto">
                        <btn icon action="Delete" @click="customerCredit.paymentOrderFileName = ''" />
                    </v-col>
                </v-row>
                <v-file-input
                    v-else
                    for="file"
                    accept="image/*,.pdf"
                    chips
                    label="Прикачване на платежно нареждане"
                    @change="onFileSelected"
                />
                <text-field v-model="customerCredit.publicNotes" class="py-4" label="Бележки"></text-field>
            </v-form>

            <v-row v-for="invoice in customerCredit.invoices" :key="invoice.invoiceNumber" class="contrast--text">
                <v-col>{{ invoice.isCredit ? 'Кредитно известие' : 'Фактура' }}</v-col>
                <v-col>№ {{ invoice.invoiceNumberText }}</v-col>
                <v-col cols="auto">
                    <btn
                        icon
                        action="Print"
                        :to="`/Print/Licensing.CustomerInvoice/CustomerInvoice/${customerId}/${invoice.invoiceNumber}`"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { CustomerCreditDto } from '@/model/Licensing/CustomerCreditDto';
    import { customerCreditService } from '@/service/Licensing/CustomerCreditService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class CustomerCreditEdit extends Vue {
        @Prop({ required: true })
        private customerId!: number;

        @Prop({ required: true })
        private seqNumber!: number;

        @Prop({ required: true })
        private practiceId!: number;

        private isValid: boolean = false;
        private customerCredit: CustomerCreditDto = new CustomerCreditDto();
        private selectedFileName: string = '';
        private selectedFileData: ArrayBuffer | null = null;

        private get amountWithVatTitle() {
            return `${
                this.customerCredit.isDue ? 'Моля, преведете' : `${this.customerCredit.customerCreditTypeName} за`
            } ${formatters.currency(this.customerCredit.amountWithVat)}`;
        }

        private get paidOnText() {
            return formatters.date(this.customerCredit.paidOn);
        }

        private async mounted() {
            this.$loading.show();
            try {
                this.customerCredit = await customerCreditService.getCustomerCredit(this.customerId, this.seqNumber);
            } finally {
                this.$loading.hide();
            }
        }

        private async onFileSelected(file: File) {
            if (file !== null) {
                this.selectedFileName = file.name;
                this.selectedFileData = await this.readFile(file);
                console.log('File attached successfully.');
                console.log(file);
            } else {
                console.log('No file attached.');
            }
        }

        private async updateCustomerCredit() {
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();
            if (this.isValid) {
                if (this.selectedFileName) {
                    this.customerCredit.paymentOrderFileName = this.selectedFileName;
                }
                await customerCreditService.updateCustomerCreditAndAdjustPendingPayments(
                    this.customerCredit,
                    this.selectedFileData
                );
                this.$router.push(this.licensingRoute);
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private readFile(file: Blob) {
            return new Promise<ArrayBuffer>((resolve, reject) => {
                // Create file reader
                const reader = new FileReader();

                // Register event listeners
                reader.addEventListener('loadend', (event) => resolve(event.target?.result as ArrayBuffer));
                reader.addEventListener('error', reject);

                // Read file
                reader.readAsArrayBuffer(file);
            });
        }

        private get licensingRoute() {
            return `/Practice/Licensing/${this.practiceId}`;
        }

        private async downloadPaymentOrderFile() {
            // TODO: Как се изтегля файл? Какъв тип е оптимален за byte[], както за download, така и за upload?
            this.$loading.show();
            try {
                await customerCreditService.downloadPaymentOrderFile(this.customerId, this.seqNumber);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
