<template>
    <v-card>
        <v-card-title>Категории</v-card-title>
        <v-card-actions class="py-0 pl-4">
            <btn action="New" @click="addCategory">Добавяне на категория</btn>
            <btn action="Save" @click="saveCategories" />
        </v-card-actions>
        <v-card-text>
            <search-field v-model="searchString" label="Търсене" />
            <v-data-table
                :items="items"
                :headers="headers"
                show-search-box
                :search="searchString"
                single-select
                title="Категории"
                no-data-text="Няма намерени данни"
                loading-text="Зареждане на данни..."
            >
                <template #item="{ item }">
                    <tr>
                        <text-field v-model="item.name" />
                        <td>
                            <div class="float-right">
                                <btn icon action="Delete" @click="deleteCategory(item)" />
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ServiceCategoryDto } from '@/model/Practice/Service/ServiceCategoryDto';
    import { serviceCategoryService } from '@/service/Practice/Service/ServiceCategoryService';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component
    export default class ServiceCategories extends Vue {
        @Prop()
        private practiceId!: number;

        private headers: IDataTableHeader[] = [
            { text: 'Име', value: 'name' },
            { text: '', value: 'actionButtons', sortable: false }
        ];

        private searchString: string = '';
        private items: ServiceCategoryDto[] = [];

        private async mounted() {
            await this.loadItems();
        }

        private async loadItems() {
            this.items = (await serviceCategoryService.getAllServiceCategories(this.practiceId)).data;
        }

        private addCategory() {
            const newCategory = new ServiceCategoryDto();
            newCategory.practiceId = this.practiceId;
            this.items.unshift(newCategory);
        }

        private async deleteCategory(categoryToDelete: ServiceCategoryDto) {
            if (categoryToDelete.seqNumber === 0) {
                arrayUtil.removeFirst(this.items, categoryToDelete);
            } else {
                await serviceCategoryService.deleteServiceCategory(this.practiceId, categoryToDelete.seqNumber);
                this.$notifier.showSuccess('Успех', 'Данните са записани успешно');
                this.loadItems();
            }
        }

        private async saveCategories() {
            const categoriesForSave = this.items.filter((category) => category.name.length > 0);
            if (categoriesForSave.length > 0) {
                await serviceCategoryService.saveServiceCategories(categoriesForSave);

                this.$notifier.showSuccess('Успех', 'Данните са записани успешно');
            }

            this.loadItems();
        }
    }
</script>
