<template>
    <v-card>
        <v-card-title class="d-flex justify-center">Протокол</v-card-title>

        <v-card-text class="text--primary pb-0">
            <v-row class="justify-center mb-2">
                <v-col cols="2" class="pb-0"><text-field v-model="regStandardDto.number" label="№..." /></v-col>
                <v-divider vertical></v-divider>
                <v-col cols="4" class="pb-0">
                    <v-form ref="contractForm" v-model="isContractFormValid">
                        <text-field v-model="regStandardDto.contractNumber" label="Договор №..." required />
                    </v-form>
                </v-col>
            </v-row>

            {{ initialProtocolText }}
        </v-card-text>

        <v-card-title class="justify-center">Регулативните стандарти важат</v-card-title>

        <v-card-text class="text--primary">
            <v-form ref="dateForm" v-model="isDateFormValid">
                <v-row>
                    <v-col cols="4">
                        <v-select
                            v-model="regStandardDto.yearQuarter"
                            item-text="text"
                            dense
                            item-value="numb"
                            :items="typeQuaters"
                            label="Избор на тримесечие"
                        />
                    </v-col>
                    <v-col cols="4">
                        <date-picker v-model="regStandardDto.startDate" label="От Дата" required />
                    </v-col>
                    <v-col cols="4">
                        <date-picker v-model="regStandardDto.endDate" label="До Дата" required />
                    </v-col>
                </v-row>
            </v-form>

            {{ generateTemplateInformation() }}

            <v-row dense class="mt-4">
                <v-col cols="10">
                    1. Брой издавани „Медицинско направление за консултация или за провеждане на съвместно лечение” (бл.
                    МЗ-НЗОК №3)
                </v-col>
                <v-col cols="2">
                    <text-field v-model="regStandardDto.sumCountReferral3Type124" disabled />
                </v-col>
            </v-row>
            <v-row v-if="isGP" dense>
                <v-col cols="10">
                    2. Брой издавани „Медицинско направление за консултация или за провеждане на съвместно лечение” (бл.
                    МЗ-НЗОК №3) за тип 7
                </v-col>
                <v-col cols="2">
                    <text-field v-model="regStandardDto.sumCountReferral3Type7" disabled />
                </v-col>
            </v-row>
            <v-row v-else dense>
                <v-col cols="10">
                    2. Брой издавани „Медицинско направление за високоспециализирани дейности” (бл. МЗ-НЗОК №3A)
                </v-col>
                <v-col cols="2">
                    <text-field v-model="regStandardDto.sumCountReferral3aAllTypes" disabled />
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="10">
                    3. Стойност на назначената с „Направление за медико-диагностична дейност” (бл. МЗ-НЗОК №4)
                </v-col>
                <v-col cols="2">
                    <text-field v-model="regStandardDto.sumWorthReferral4AllTypes" disabled />
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="10"> 4. Стойност на назначената с „Ядрено-магнитен резонанс” (бл. МЗ-НЗОК №4) </v-col>
                <v-col cols="2">
                    <text-field v-model="regStandardDto.sumWorthReferral4Mri" disabled />
                </v-col>
            </v-row>
            <v-row v-if="isGP" dense>
                <v-col cols="10">
                    5. Брой издавани Медицинско направление за високоспециализирани дейности (бл. МЗ-НЗОК №3А) за пакет
                    Анестезиология и интезивно лечение и диспансерно наблюдение
                </v-col>
                <v-col cols="2">
                    <text-field v-model="regStandardDto.sumCountReferral3aType4" disabled />
                </v-col>
            </v-row>
        </v-card-text>

        <RegulatoryStandardDoctorList :practice-id="practiceId" :reg-standard="regStandardDto" />

        <v-card-actions>
            <btn action="Back" :to="`/Practice/RegulatoryStandard/List/${practiceId}`" />
            <btn action="Save" @click="updateRegulatoryStandard" />
            <v-spacer></v-spacer>
            <btn action="Delete" @click="deleteRegulatoryStandard" />
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { RegulatoryStandardDto } from '@/model/Practice/RegulatoryStandard/RegulatoryStandardDto';
    import { regulatoryStandardService } from '@/service/Practice/RegulatoryStandardService';
    import { rhifCache } from '@/store/Nomenclature/RhifCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';

    import RegulatoryStandardDoctorList from './RegulatoryStandardDoctorList.vue';

    const tableConfig = {
        yearPrecision: 4,
        datePresicion: 10,
        january: 1,
        february: 2,
        march: 3,
        april: 4,
        may: 5,
        june: 6,
        july: 7,
        august: 8,
        september: 9,
        october: 10,
        november: 11,
        december: 12,
        milisecondsElapsed: 60000
    };

    @Component({
        components: { RegulatoryStandardDoctorList }
    })
    export default class RegulatoryStandardEdit extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop()
        private seqNumber!: number;

        private regStandardDto: RegulatoryStandardDto = new RegulatoryStandardDto();
        private isContractFormValid: boolean = false;
        private isDateFormValid: boolean = false;

        private typeQuaters: { numb: number; text: string }[] = [
            { numb: 1, text: 'първо' },
            { numb: 2, text: 'второ' },
            { numb: 3, text: 'трето' },
            { numb: 4, text: 'четвърто' }
        ];

        private quarterYear: number | null = null;
        private quarterDate: Date | null = null;

        private get rhifs() {
            return rhifCache.items;
        }

        private get isGP() {
            if (userContextCache.current) {
                return userContextCache.isGP();
            }
            return true;
        }

        private get initialProtocolText() {
            return `За определяне на броя на назначаваните специализирани медицински дейности (СМД) и стойността на
            назначаваните медико-диагностичните дейности (МДД) от изпълнител на ${
                this.isGP
                    ? 'първична извънболнична медицинска помощ (ПИМП)'
                    : 'специализирана извънболнична медицинска помощ (СИМП)'
            } за  ${this.findQuarterValue(this.regStandardDto.yearQuarter)} тримесечие на ${this.quarterYear}`;
        }

        @Watch('regStandardDto.startDate', { immediate: true })
        private onIssueDateChange(value: Date | null) {
            if (value && value instanceof Date) {
                this.quarterYear = value.getFullYear();
                this.quarterDate = value;
            }
        }

        private async mounted() {
            this.$loading.show();
            try {
                if (this.seqNumber) {
                    this.regStandardDto = await regulatoryStandardService.getRegStandard(
                        this.practiceId,
                        this.seqNumber
                    );
                } else {
                    this.regStandardDto = await regulatoryStandardService.initRegStandardForPractice(this.practiceId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private findQuarterValue(numb: number) {
            if (numb) {
                const quarter = this.typeQuaters.find((type) => type.numb === numb);
                return quarter ? quarter.text : '';
            }
            return '';
        }

        private generateTemplateInformation() {
            const rhifId = this.regStandardDto.practiceRhifId;
            if (rhifId) {
                const rhif = rhifCache.getRhifById(rhifId);
                const date = dateUtil.formatBgDate(this.quarterDate);

                return `Днес ${date} между ${userContextCache.current?.practiceName},
                Рег. №${userContextCache.current?.practiceNumber}
                - изпълнител на ${this.isGP ? 'ПИМП' : 'СИМП'}, представляван от
                ${this.regStandardDto.practiceManagerName} от една страна и
                ${rhif?.name} представлявана от Директора ${rhif?.director} от друга страна,
                на основание чл. 3 от Закона на бюджета на НЗОК за ${new Date().getFullYear()} и
                „Правила за условията и реда за утвърждаване от НЗОК за всяка РЗОК и за всяко тримесечие
                към договорите с изпълнителите на извънболнична първична
                и на извънболнична специализирана медицинска помощ
                на броя на назначаваните специализирани медицински дейности
                и стойността на назначаваните медико-диагностични дейности“
                (Правилата) се определят :`;
            }
            return null;
        }

        private async updateRegulatoryStandard() {
            (this.$refs.contractForm as Vue & { validate: () => boolean }).validate();
            (this.$refs.dateForm as Vue & { validate: () => boolean }).validate();

            if (this.isContractFormValid && this.isDateFormValid) {
                await regulatoryStandardService.createOrUpdateRegStandard(this.regStandardDto);
                this.$router.push(`/Practice/RegulatoryStandard/List/${this.practiceId}`);
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        @Watch('regStandardDto.yearQuarter')
        private onChosedQuarterChange(numb: number) {
            const value = this.findQuarterValue(numb);
            const currentYear = new Date(Date.now()).getFullYear();
            if (value === 'първо') {
                const newStartDate = this.convertedData(`01 01 ${currentYear}`);
                const newEndDate = this.convertedData(`${currentYear} 03 31`);
                this.regStandardDto.startDate = newStartDate;
                this.regStandardDto.endDate = newEndDate;
            } else if (value === 'второ') {
                const newStartDate = this.convertedData(`${currentYear} 04 01`);
                const newEndDate = this.convertedData(`${currentYear} 06 30`);
                this.regStandardDto.startDate = newStartDate;
                this.regStandardDto.endDate = newEndDate;
            } else if (value === 'трето') {
                const newStartDate = this.convertedData(`${currentYear} 07 01`);
                const newEndDate = this.convertedData(`${currentYear} 09 30`);
                this.regStandardDto.startDate = newStartDate;
                this.regStandardDto.endDate = newEndDate;
            } else if (value === 'четвърто') {
                const newStartDate = this.convertedData(`${currentYear} 10 01`);
                const newEndDate = this.convertedData(`${currentYear} 12 31`);
                this.regStandardDto.startDate = newStartDate;
                this.regStandardDto.endDate = newEndDate;
            }
        }

        private convertedData(data: string): Date {
            const dateToConverted = new Date(data);
            const isoDateTime = new Date(
                dateToConverted.getTime() - dateToConverted.getTimezoneOffset() * tableConfig.milisecondsElapsed
            );

            return isoDateTime;
        }

        private async deleteRegulatoryStandard() {
            await regulatoryStandardService.deleteRegStandard(this.practiceId, this.seqNumber);
            this.$router.push(`/Practice/RegulatoryStandard/List/${this.practiceId}`);
        }
    }
</script>
