<template>
    <v-row>
        <v-dialog v-model="value" persistent max-width="50%">
            <v-card>
                <v-card-title>
                    {{ title }}
                    <v-spacer />
                    <btn icon action="Close" @click="close" />
                </v-card-title>
                <v-card-text class="font-weight-bold body-1 mt-2">
                    <v-icon size="50" color="accent" class="mr-5">mdi-alert-octagon-outline</v-icon>
                    {{ text }}
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class SuccessDialog extends Vue {
        @Prop({ default: true })
        private value!: boolean;

        @Prop({ required: true })
        private text!: string;

        @Prop({ required: true })
        private title!: string;

        private close() {
            this.$emit('input', false);
        }
    }
</script>
