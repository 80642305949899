<template>
    <div>
        <v-card-title>Имунизация</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="6">
                    <immunization-picker v-model="value.immunization" @input="immunizationInput" />
                </v-col>
                <v-col v-if="value.immunization" cols="6">
                    <text-field v-model="value.immunization.application" disabled label="Приложение" />
                </v-col>
                <v-col v-if="value.immunization" cols="3" md="1">
                    <text-field v-model="value.immunization.rhiCode" disabled label="Код РЗИ" />
                </v-col>
                <v-col v-if="value.immunization" cols="3" md="1">
                    <text-field v-model="value.immunization.nhifCode" disabled label="Код НЗОК" />
                </v-col>
                <v-col cols="12" md="2">
                    <date-picker v-model="value.occurrenceDate" label="Дата на поставяне:" required />
                </v-col>
                <v-col cols="12" md="2">
                    <text-field v-model="value.serialNumber" label="Серия №:" :required="isSerialRequired" />
                </v-col>
                <v-col cols="6" md="3">
                    <text-field v-model="value.resultField" label="Резултат" />
                </v-col>
                <v-col cols="6" md="3">
                    <v-autocomplete
                        v-model="value.examId"
                        class="required"
                        dense
                        :items="pastExams"
                        label="Преглед:"
                        item-value="id"
                        :item-text="formatPastExamResult"
                        no-data-text="Няма данни"
                        :rules="[$validator.required]"
                    />
                </v-col>
                <v-col v-if="showMantuResult" cols="6" md="3">
                    <div>
                        <label>Резултат проба манту:</label>
                        <v-switch
                            v-model="value.mantuResult"
                            :label="value.mantuResult ? 'Положителен' : 'Отрицателен'"
                        ></v-switch>
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <text-field v-model="value.doctorName" label="Лекар име" />
                </v-col>
                <v-col cols="12" md="3">
                    <text-field v-model="value.doctorUin" label="УИН" />
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ImmunizationPicker from '@/component/Immunization/ImmunizationPicker.vue';
    import { PatientImmunizationDto } from '@/model/Patient/Immunization/PatientImmunizationDto';
    import { VisitResultDto } from '@/model/VisitList/VisitSearch/VisitResultDto';
    import { patientVisitSummaryService } from '@/service/VisitList/PatientVisitSummaryService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';

    @Component({
        components: { ImmunizationPicker }
    })
    export default class ImmunizationEditor extends Vue {
        @Prop({ type: Object })
        private value!: PatientImmunizationDto;

        private pastExams: VisitResultDto[] = [];

        private get isSerialRequired() {
            //Код за имунизация против грип по национална програма
            const nationalFluCode = '99';
            return this.value?.immunization?.nhifCode === nationalFluCode;
        }

        private formatPastExamResult(item: VisitResultDto) {
            return `Амб. лист ${item.ambulatorySheetNumber} от ${dateUtil.formatBgDate(item.startDateTime)}`;
        }

        private async getPatientVisitSummary() {
            const { current } = userContextCache;
            if (current) {
                const allVisits = true;
                const patientVisitSummary = await patientVisitSummaryService.getPatientVisitSummary(
                    currentPatientCache.value.key.patientId,
                    current.employeeSeqNumber,
                    allVisits
                );
                if (patientVisitSummary?.visits) {
                    this.pastExams = patientVisitSummary.visits.filter((visit) => visit.id);
                }
            }
        }

        private get showMantuResult() {
            return this.value.immunization?.name?.toLowerCase().startsWith('проба манту') ? true : false;
        }

        private immunizationInput() {
            this.value.mantuResult = this.showMantuResult ? false : null;
        }

        private async mounted() {
            await this.getPatientVisitSummary();
            //
            // if (!this.value.doctorName && !this.value.doctorUin && !this.value.id) {
            //     this.value.doctorName = userContextCache.current?.doctorName ?? '';
            //     this.value.doctorUin = userContextCache.current?.doctorUin ?? '';
            // }
        }
    }
</script>
