import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

export class NhifCommand {
    constructor(
        public patientVersionKey: PatientVersionKey,
        public doctorUin: string | null,
        public deputyDoctorUin: string | null
    ) {}

    public deputyDoctorIsHired: boolean | null = null;
    public doctorEmployeeSeqNumber: number = 0;
    public nrn: string | null = null;
    public entityId: number | null = null;
}
