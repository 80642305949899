import _Vue from 'vue';

import { LoadingState, loadingState } from '@/store/LoadingState';

declare module 'vue/types/vue' {
    interface Vue {
        $loading: LoadingState;
    }
}

export const loadingPlugin = (Vue: typeof _Vue) => {
    Vue.prototype.$loading = loadingState;
};
