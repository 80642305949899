import { plainToInstance } from 'class-transformer';

import { PatientFundDto } from '@/model/Patient/PatientFundDto';
import { PatientFundUpdateDto } from '@/model/Patient/PatientFundUpdateDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientFundService = {
    async getPatientFund(id: number): Promise<PatientFundUpdateDto> {
        const response = await httpService.get<PatientFundUpdateDto>(`PatientFund/GetPatientFund/${id}`);
        return plainToInstance(PatientFundUpdateDto, response.data);
    },
    async getPatientActiveFunds(id: number): Promise<PatientFundDto[]> {
        const response = await httpService.get<PatientFundDto[]>(`PatientFund/GetPatientActiveFunds/${id}`);
        return plainToInstance(PatientFundDto, response.data);
    },
    async updatePatientFund(data: PatientFundUpdateDto): Promise<void> {
        await httpService.post('PatientFund/UpdatePatientFund', data);
    }
};
