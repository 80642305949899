import { AxiosResponse } from 'axios';

import { ExamBlobDto } from '@/model/Exam/ExamBlobDto';
import { TemplateDto } from '@/model/Setting/Template/TemplateDto';
import { TemplateFilterDto } from '@/model/Setting/Template/TemplateFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { userContextCache } from '@/store/User/UserContextCache';

export const templateService = {
    getFilteredTemplates(filter: TemplateFilterDto): Promise<AxiosResponse<TemplateDto[]>> {
        filter.practiceId = userContextCache.currentPracticeId ?? 0;
        filter.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
        return httpService.get('/Template/GetFilteredTemplates/', { params: filter });
    },

    getTemplatesForDoctor(practiceId: number, doctorEmployeeSeqNumber: number): Promise<AxiosResponse<TemplateDto[]>> {
        return httpService.get('/Template/GetTemplatesForDoctor', {
            params: { practiceId, doctorEmployeeSeqNumber }
        });
    },

    uploadToBlob(exam: ExamBlobDto): Promise<AxiosResponse> {
        return httpService.post('/Template/UploadToBlob', exam);
    },

    getFromBlob(id: number): Promise<AxiosResponse<ExamBlobDto>> {
        return httpService.get(`/Template/GetFromBlob/${id}`);
    },

    getFromSql(id: number): Promise<AxiosResponse<ExamBlobDto>> {
        return httpService.get(`/Template/GetFromSql/${id}`);
    },

    getTemplate(practiceId: number, templateId: number): Promise<AxiosResponse<TemplateDto>> {
        return httpService.get('/Template/GetTemplate', { params: { practiceId, templateId } });
    },

    createTemplate(data: TemplateDto): Promise<AxiosResponse<TemplateDto>> {
        return httpService.post('/Template/CreateTemplate', data);
    },

    updateTemplate(data: TemplateDto): Promise<AxiosResponse<TemplateDto>> {
        return httpService.put('/Template/UpdateTemplate/', data);
    },

    deleteTemplate(practiceId: number, templateId: number): Promise<AxiosResponse> {
        return httpService.delete(`/Template/DeleteTemplate/${templateId}`, { params: { practiceId, templateId } });
    }
};
