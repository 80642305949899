import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { ScheduledExamCalendarDto } from '@/model/ExamSchedule/ScheduledExamCalendarDto';
import { CalendarEventModel } from '@/model/WorkTime/CalendarEventModel';

export class ExamScheduleCalendarEventModel extends CalendarEventModel {
    public examDurationInMinutes: number | null = null;
    public doctorId: number | null = null;
    public doctorName: string = '';
    public specialtyCode: SpecialtyCode | null = null;
    public specialtyName: string = '';
    public workTimeVisitReasonId: number | null = null;
    public workTimeVisitReasonName: string = '';
    public scheduledExams: ScheduledExamCalendarDto[] = [];
}
