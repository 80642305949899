<template>
    <v-card-text>
        <v-row v-for="(serviceOrder, $index) of value.serviceOrders" :key="serviceOrder.serviceOfferKey.code">
            <v-col v-if="serviceOrder.paymentMethodCode !== 'Bank' && serviceOrder.id > 0" md="1" class="py-0">
                <v-checkbox
                    v-model="serviceOrder.isPaid"
                    :disabled="serviceOrder.paymentDate != null"
                    label="Платено"
                />
            </v-col>
            <v-col md="3" class="py-0">
                <dropdown
                    v-model="serviceOrder.serviceOfferKey.tariffSeqNumber"
                    label="Тарифа"
                    :items="tariffs"
                    item-value="seqNumber"
                    required
                    :disabled="serviceOrder.id > 0"
                    @input="tariffChanged(serviceOrder)"
                ></dropdown>
            </v-col>
            <v-col v-if="serviceOrder.serviceOffersForDropdown" md="6" class="py-0">
                <dropdown
                    v-model="serviceOrder.serviceOfferKey"
                    label="Услуга"
                    :items="serviceOrder.serviceOffersForDropdown"
                    item-value="key"
                    required
                    :disabled="serviceOrder.id > 0"
                    @input="setPatientPrice(serviceOrder.serviceOfferKey, $index)"
                ></dropdown>
            </v-col>
            <v-col cols="2" class="py-0">
                <text-field
                    v-model="serviceOrder.patientPrice"
                    label="Цена"
                    type="number"
                    :disabled="serviceOrder.paymentDate !== null"
                ></text-field>
            </v-col>
            <v-col v-if="serviceOrder.id == 0 && extractedReferrals && extractedReferrals.length > 0" cols="6">
                <dropdown
                    v-model="serviceOrder.incomingReferral"
                    label="Вх. направление"
                    :items="extractedReferrals"
                    item-text="title"
                    return-object
                    :disabled="serviceOrder.id > 0"
                    @input="onIncomingReferralInput"
                ></dropdown>
            </v-col>
            <v-col v-if="serviceOrder.id > 0 && serviceOrder.incomingReferral" cols="6">
                <text-field
                    label="Вх. направление"
                    :value="`НРН: ${serviceOrder.incomingReferral.incomingReferral.nrn}`"
                    disabled
                />
            </v-col>
            <v-col class="py-0" cols="3">
                <dropdown
                    v-model="serviceOrder.paymentMethodCode"
                    label="Начин на плащане"
                    :items="paymentMethods"
                    item-value="code"
                    required
                    :disabled="serviceOrder.paymentDate !== null"
                ></dropdown>
            </v-col>
            <v-col cols="1" class="pt-5">
                <btn
                    icon
                    action="Delete"
                    :disabled="serviceOrder.paymentDate !== null"
                    @click="removeService($index, serviceOrder)"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="9" class="text-right"> Сума (общо) </v-col>
            <v-col cols="3"> {{ totalPrice }} </v-col>
        </v-row>
        <v-row>
            <v-col cols="9" class="text-right"> Сума за плащане (общо) </v-col>
            <v-col cols="3"> {{ totalPriceToPay }} </v-col>
        </v-row>
        <v-row>
            <v-col>
                <btn action="New" @click="addNewService">Добавяне на услуга</btn>
            </v-col>
            <v-spacer />
            <v-col v-if="ordersToPay.length > 0">
                <dropdown
                    v-model="selectedFiscalMemory"
                    :items="fiscalMemories"
                    return-object
                    item-text="code"
                    label="Фискално устройство"
                    @input="selectFiscalDevice"
                ></dropdown>
            </v-col>
            <v-col v-if="ordersToPay.length > 0">
                <btn action="Pay" @click="onPay">Плащане на услуга</btn>
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { ArticleDto } from '@/model/CashRegister/ArticleDto';
    import { FiscalReceiptDto } from '@/model/CashRegister/FiscalReceiptDto';
    import { PaymentDto } from '@/model/CashRegister/PaymentDto';
    import { NhisExtractedReferralDto } from '@/model/Nhis/Referral/NhisExtractedReferralDto';
    import { PaymentMethodDto } from '@/model/Nomenclature/PaymentMethodDto';
    import { DeviceLoginDto } from '@/model/Practice/Fiscal/DeviceLoginDto';
    import { FiscalMemoryDto } from '@/model/Practice/Fiscal/FiscalMemoryDto';
    import { ServiceOfferKey } from '@/model/Practice/Service/ServiceOfferKey';
    import { TariffDto } from '@/model/Practice/Service/TariffDto';
    import { ReceptionServiceCreateCommand } from '@/model/Reception/Service/ReceptionServiceCreateCommand';
    import { ServiceOfferDto } from '@/model/Reception/Service/ServiceOfferDto';
    import { ServiceOrderCreateDto } from '@/model/Reception/Service/ServiceOrderCreateDto';
    import { cashRegisterService } from '@/service/LocalServer/CashRegisterService';
    import { paymentMethodService } from '@/service/Nomenclature/PaymentMethodService';
    import { fiscalDeviceOperatorService } from '@/service/Practice/Fiscal/FiscalDeviceOperatorService';
    import { fiscalMemoryService } from '@/service/Practice/Fiscal/FiscalMemoryService';
    import { tariffService } from '@/service/Practice/Service/TariffService';
    import { receptionService } from '@/service/Reception/ReceptionService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component
    export default class ServiceOrder extends Vue {
        @Prop()
        value!: ReceptionServiceCreateCommand;

        @Prop()
        extractedReferrals!: NhisExtractedReferralDto[];

        private ordersToPay: number[] = [];
        private tariffs: TariffDto[] = [];
        private serviceOffers: ServiceOfferDto[] = [];
        private paymentMethods: PaymentMethodDto[] = [];
        private fiscalMemories: FiscalMemoryDto[] = [];
        private selectedFiscalMemory: FiscalMemoryDto | null = null;
        private isFiscalPrinterVisible: boolean = false;
        private operator: DeviceLoginDto | undefined;

        private get totalPriceToPay() {
            this.ordersToPay = [];
            const sum = this.value.serviceOrders.reduce((accumulator: number, serviceOrder: ServiceOrderCreateDto) => {
                if (serviceOrder.isPaid && serviceOrder.paymentDate === null) {
                    this.ordersToPay.push(serviceOrder.id);
                    const patientPrice = parseFloat(serviceOrder.patientPrice?.toString() ?? '');
                    return accumulator + (isNaN(patientPrice) ? 0 : patientPrice);
                }
                return accumulator;
            }, 0);

            return sum;
        }

        private async onPay() {
            const response = await receptionService.payOrders(this.ordersToPay);
            await this.printBon();
            if (response?.data) {
                const orders = this.value.serviceOrders;
                for (let orderIndex = 0; orderIndex < orders.length; orderIndex++) {
                    if (response.data.includes(orders[orderIndex].id)) {
                        orders[orderIndex].paymentDate = new Date();
                    }
                }
            }
        }

        private get totalPrice() {
            const sum = this.value.serviceOrders.reduce((accumulator: number, serviceOrder: ServiceOrderCreateDto) => {
                const patientPrice = parseFloat(serviceOrder.patientPrice?.toString() ?? '');
                return accumulator + (isNaN(patientPrice) ? 0 : patientPrice);
            }, 0);

            return sum;
        }

        private tariffChanged(serviceOrder: ServiceOrderCreateDto) {
            serviceOrder.serviceOffersForDropdown = this.serviceOffers.filter(
                (offer) => offer.key.tariffSeqNumber === serviceOrder.serviceOfferKey.tariffSeqNumber
            );
        }

        private addNewService() {
            this.value.serviceOrders.push(new ServiceOrderCreateDto());
        }

        private removeService(index: number, serviceOrder: ServiceOrderCreateDto) {
            if (serviceOrder.id > 0) {
                this.value.ordersToDelete.push(serviceOrder.id);
            }
            if (serviceOrder.incomingReferral) {
                const currentReferral = this.extractedReferrals.find(
                    (referral) => referral.incomingReferral.nrn === serviceOrder.incomingReferral?.incomingReferral.nrn
                );
                if (currentReferral) {
                    currentReferral.disabled = false;
                }
            }
            arrayUtil.removeAt(this.value.serviceOrders, index);
        }

        private async getTariffs() {
            this.tariffs = await tariffService.getPracticeTariffs(userContextCache.currentPracticeId ?? 0);
        }

        private async getPaymentMethod() {
            this.paymentMethods = await paymentMethodService.getPaymentMethods();
        }

        private async getServicesForPractice() {
            this.serviceOffers = await receptionService.getPracticeServiceOffers(
                userContextCache.currentPracticeId ?? 0
            );
        }

        private setPatientPrice(serviceOfferKey: ServiceOfferKey, index: number) {
            const serviceOffer = this.serviceOffers.find((offer) => offer.key.code === serviceOfferKey.code);
            this.value.serviceOrders[index].patientPrice = serviceOffer?.patientPrice ?? 0;
        }

        private onIncomingReferralInput(item: NhisExtractedReferralDto) {
            item.disabled = true;
            item.practiceId = userContextCache.currentPracticeId;
        }

        @Watch('value.serviceOrders')
        onServiceOrdersChange(value: ServiceOrderCreateDto[]) {
            if (value) {
                value.forEach((order) => {
                    this.tariffChanged(order);
                });
            }
        }

        private async load() {
            this.$loading.show();
            try {
                await this.getTariffs();
                await this.getServicesForPractice();
                await this.getPaymentMethod();
                const practiceId = userContextCache.currentPracticeId;
                if (practiceId) {
                    await this.loadFiscalMemory(practiceId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async loadFiscalMemory(practiceId: number) {
            const now = new Date();
            const responsePracticeFiscalMemories = await fiscalMemoryService.getActiveFiscalMemories(
                practiceId,
                now,
                userContextCache.current?.employeeSeqNumber ?? 0
            );
            this.fiscalMemories = responsePracticeFiscalMemories.data;

            //dkaraminov: Към момента (05.06.2024) getListDevices е много бавно действие (трае около 2-3 минути), затова спираме използването му
            // и ще използваме единствено активните фискални памети в програмата. При оптимизация на getListDevices е възможно отново да влезне в употреба
            // и тогава нека се махне горния ред: this.fiscalMemories = responsePracticeFiscalMemories.data;
            // const resultListDevices = await cashRegisterService.getListDevices();
            // if (resultListDevices?.deviceList && resultListDevices?.deviceList.length > 0) {
            //     for (let idxDb = 0; idxDb < responsePracticeFiscalMemories.data.length; idxDb++) {
            //         for (let idxSv = 0; idxSv < resultListDevices?.deviceList.length; idxSv++) {
            //             if (
            //                 resultListDevices.deviceList[idxSv].deviceMemoryNumber ===
            //                 responsePracticeFiscalMemories.data[idxDb].code
            //             ) {
            //                 this.fiscalMemories.push(responsePracticeFiscalMemories.data[idxDb]);
            //                 break;
            //             }
            //         }
            //     }
            // }

            if (this.fiscalMemories.length === 1) {
                [this.selectedFiscalMemory] = this.fiscalMemories;
                await this.selectFiscalDevice();
            }
        }

        private async selectFiscalDevice() {
            if (!this.selectedFiscalMemory) {
                return;
            }
            const response = await fiscalDeviceOperatorService.getDeviceLogin(
                this.selectedFiscalMemory.practiceId,
                this.selectedFiscalMemory.deviceSeqNumber,
                this.selectedFiscalMemory.code
            );
            this.operator = response.data;
            //if (!this.operator) {
            //    this.$notifier.showWarning('Внимание', `Не сте регистриран като оператор за това фискално устройство.`);
            //}
        }

        private async printBon() {
            const fiscalReceipt = await this.createBon();
            if (!fiscalReceipt) {
                return;
            }
            let isNeedToPrint = false;
            for (let idx = 0; idx < fiscalReceipt.payments.length; idx++) {
                isNeedToPrint = isNeedToPrint || fiscalReceipt.payments[idx].amount > 0;
            }
            if (isNeedToPrint) {
                const result = await cashRegisterService.fiscalReceipt(fiscalReceipt);
                if (result.resultCode === 'Ok') {
                    this.$notifier.showSuccess('Съобщение', `Касовата бележка е отпечатана`);
                }
            }
        }

        private async initFiscalReceipt() {
            if (!this.selectedFiscalMemory) {
                return null;
            }
            const response = await fiscalMemoryService.getFiscalMemory(
                this.selectedFiscalMemory.practiceId,
                this.selectedFiscalMemory.deviceSeqNumber,
                this.selectedFiscalMemory.code
            );
            const mem = response.data;
            const fiscalReceipt = new FiscalReceiptDto();
            fiscalReceipt.operatorNumber = String(this.operator?.operator ?? '');
            fiscalReceipt.operatorPassword = String(this.operator?.password ?? '');
            fiscalReceipt.unpNumber = '';
            fiscalReceipt.deviceMemoryNumber = mem.code ?? '';
            fiscalReceipt.deviceMemoryNumber = '';
            fiscalReceipt.items = [];
            return fiscalReceipt;
        }

        private initFiscalReceiptRows(fiscalReceipt: FiscalReceiptDto, paymentMethods: PaymentDto[]) {
            let idx = 0;
            let idxPaid = 0;
            while (idx < this.value.serviceOrders.length) {
                if (
                    this.value.serviceOrders[idx].isPaid &&
                    this.value.serviceOrders[idx].paymentDate === null &&
                    this.value.serviceOrders[idx].patientPrice
                ) {
                    fiscalReceipt.items[idxPaid] = new ArticleDto();
                    fiscalReceipt.items[idxPaid].name = this.serviceOffers[idx].name;
                    fiscalReceipt.items[idxPaid].vatGroup = 'А';
                    fiscalReceipt.items[idxPaid].qty = 1;
                    const patientPrice = Number(this.value.serviceOrders[idx].patientPrice);
                    fiscalReceipt.items[idxPaid].price = patientPrice;
                    switch (this.value.serviceOrders[idx].paymentMethodCode) {
                        case 'Cash':
                            paymentMethods[0].amount += patientPrice;
                            break;
                        case 'Card':
                            paymentMethods[1].amount += patientPrice;
                            break;
                        default:
                            break;
                    }
                    idxPaid++;
                }
                idx++;
            }
            return fiscalReceipt;
        }

        private async createBon() {
            let fiscalReceipt = await this.initFiscalReceipt();
            if (!fiscalReceipt) {
                return null;
            }
            const paymentMethods: PaymentDto[] = [
                { paymentMethod: 'Cash', amount: 0 },
                { paymentMethod: 'Card', amount: 0 }
            ];
            fiscalReceipt = this.initFiscalReceiptRows(fiscalReceipt, paymentMethods);
            fiscalReceipt.payments = [];
            for (let idxP = 0; idxP < paymentMethods.length; idxP++) {
                if (paymentMethods[idxP].amount > 0) {
                    fiscalReceipt.payments[idxP] = new PaymentDto();
                    fiscalReceipt.payments[idxP].amount = paymentMethods[idxP].amount;
                    fiscalReceipt.payments[idxP].paymentMethod = paymentMethods[idxP].paymentMethod;
                }
            }
            return fiscalReceipt;
        }

        private async mounted() {
            await this.load();
        }
    }
</script>
