import { Route, RouteConfig } from 'vue-router';

export const examImmunizationRoutes: RouteConfig[] = [
    {
        path: 'Immunization/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Immunization/ImmunizationExamView.vue')
        }
    },
    {
        path: 'Immunization/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Immunization/Immunization.vue')
        }
    },
    {
        path: 'Immunization/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Immunization/Immunization.vue')
        }
    },
    {
        path: 'EImmunization/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Nhis/Immunization/NhisImmunizationView.vue')
        }
    },
    {
        path: 'EImmunization/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Nhis/Immunization/NhisImmunizationEditor.vue')
        }
    },
    {
        path: 'EImmunization/Edit/:patientId/:immunizationSeqNumber',
        props: {
            exam: (route: Route) => ({
                patientId: Number(route.params.patientId),
                immunizationSeqNumber: Number(route.params.immunizationSeqNumber)
            })
        },
        components: {
            exam: () => import('@/view/Nhis/Immunization/NhisImmunizationEditor.vue')
        }
    },
    {
        path: 'EImmunization/Reaction/Create/:nhisVaccineCode',
        props: {
            exam: (route: Route) => ({ nhisVaccineCode: route.params.nhisVaccineCode })
        },
        components: {
            exam: () => import('@/view/Nhis/Immunization/NhisImmunizationReactionEditor.vue')
        }
    },
    {
        path: 'EImmunization/Reaction/Edit/:id',
        props: {
            exam: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            exam: () => import('@/view/Nhis/Immunization/NhisImmunizationReactionEditor.vue')
        }
    }
];
