import { Type } from 'class-transformer';

import { CustomerInvoiceDto } from './CustomerInvoiceDto';

export class CustomerCreditDto {
    public customerId: number = 0;
    public seqNumber: number = 0;
    public isDue: boolean = false;
    public amountWithVat: number = 0;
    public customerCreditTypeName: string = '';
    public paidOn: Date | null = null;
    public paymentOrderFileName: string = '';
    public paymentOrderETag: string = '';
    public publicNotes: string = '';

    @Type(() => CustomerInvoiceDto)
    public invoices: CustomerInvoiceDto[] = [];

    public get invoiceNumbers() {
        return this.invoices.map((inv) => (inv.isCredit ? 'кр.' : '') + inv.invoiceNumberText).join(', ');
    }
}
