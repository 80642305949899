import { NhisReferralDto } from '@/model/Nhis/Referral/NhisReferralDto';

export class Referral7Dto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();
    public number: number = 0;
    public isEmergency: boolean = false;
    public clinicalPathwayCode: string | null = null;
    public ambulatoryProcedureCode: string | null = null;
    public diagnosis1Id: number | null = null;
    public diagnosis2Id: number | null = null;
    public nhisReferral: NhisReferralDto = new NhisReferralDto();
}
