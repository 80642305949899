export class NhisXmlRequestDto {
    constructor(
        public entityId: number,
        public doctorEmployeeId: number,
        public doctorUin: string,
        public deputyDoctorUin: string | null
    ) {}

    public formType: string | null = null;
    public usePatientSignature: boolean = false;
}
