import { Type } from 'class-transformer';

import { Annex2PregnancyVisitRowDto } from '@/model/Report/Rhi/Annex2/Annex2PregnancyVisitRowDto';

export class Annex2PregnancyVisitDto {
    public name: string = '';

    @Type(() => Annex2PregnancyVisitRowDto)
    public pregnancyVisits: Annex2PregnancyVisitRowDto[] = [];
}
