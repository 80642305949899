import { Type } from 'class-transformer';

import { AddressDto } from '@/model/Common/AddressDto';
import { ContactDto } from '@/model/Common/ContactDto';
import { PersonIdentificationDto } from '@/model/Common/PersonIdentificationDto';

export class ChoiceMakerDto {
    @Type(() => PersonIdentificationDto)
    public personIdentification: PersonIdentificationDto = new PersonIdentificationDto();

    public firstName: string = '';
    public middleName: string = '';
    public lastName: string = '';

    @Type(() => AddressDto)
    public address: AddressDto = new AddressDto();

    @Type(() => ContactDto)
    public contact: ContactDto = new ContactDto();

    // Изглежда се използва само в болничния лист.
    public age: number = 0;

    public get text(): string {
        return `${this.personIdentification.pidTypeCode ? `${this.personIdentification.pidTypeCode}: ` : ''} ${
            this.personIdentification.identifier
        }
             ${this.firstName} ${this.middleName} ${this.lastName}`;
    }
}
