import { Route, RouteConfig } from 'vue-router';

const settingChildRoutes: RouteConfig[] = [
    {
        path: 'UserSettings',
        components: {
            setting: () => import('@/view/Setting/UserSettings.vue')
        }
    },
    {
        path: 'WorkTime/:practiceId',
        props: {
            setting: (route: Route) => ({
                practiceId: Number(route.params.practiceId)
            })
        },
        components: {
            setting: () => import('@/view/WorkTime/WorkTime.vue')
        }
    },
    {
        path: 'SickSheetCounter/List/:practiceId',
        props: {
            setting: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            setting: () => import('@/view/Setting/Counter/SickSheetCounter.vue')
        }
    },
    {
        path: 'SickSheetCounter/Create/:practiceId',
        props: {
            setting: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            setting: () => import('@/view/Setting/Counter/SickSheetCounterEdit.vue')
        }
    },
    {
        path: 'SickSheetCounter/Edit/:id',
        props: {
            setting: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            setting: () => import('@/view/Setting/Counter/SickSheetCounterEdit.vue')
        }
    },
    {
        path: 'PracticeCounter/List/:practiceId',
        props: {
            setting: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            setting: () => import('@/view/Setting/Counter/PracticeCounter.vue')
        }
    },
    {
        path: 'PracticeCounter/Create/:practiceId',
        props: {
            setting: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            setting: () => import('@/view/Setting/Counter/PracticeCounterEdit.vue')
        }
    },
    {
        path: 'PracticeCounter/Edit/:practiceId/:counterTypeCode',
        props: {
            setting: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                counterTypeCode: route.params.counterTypeCode
            })
        },
        components: {
            setting: () => import('@/view/Setting/Counter/PracticeCounterEdit.vue')
        }
    },
    {
        path: 'DoctorEmployeeCounter/List/:practiceId/:employeeSeqNumber',
        props: {
            setting: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            setting: () => import('@/view/Setting/Counter/DoctorEmployeeCounter.vue')
        }
    },
    {
        path: 'DoctorEmployeeCounter/Create/:practiceId/:employeeSeqNumber',
        props: {
            setting: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            setting: () => import('@/view/Setting/Counter/DoctorEmployeeCounterEdit.vue')
        }
    },
    {
        path: 'DoctorEmployeeCounter/Edit/:practiceId/:employeeSeqNumber/:counterTypeCode',
        props: {
            setting: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber),
                counterTypeCode: route.params.counterTypeCode
            })
        },
        components: {
            setting: () => import('@/view/Setting/Counter/DoctorEmployeeCounterEdit.vue')
        }
    },
    {
        path: 'ExamDuration/:practiceId/:employeeSeqNumber',
        props: {
            setting: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            setting: () => import('@/view/Setting/ExamDurationSetting.vue')
        }
    },
    {
        path: 'Template/List/:practiceId/:employeeSeqNumber',
        props: {
            setting: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            setting: () => import('@/view/Setting/Template/TemplateList.vue')
        }
    },
    {
        path: 'Template/Create/:practiceId/:employeeSeqNumber',
        props: {
            setting: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            setting: () => import('@/view/Setting/Template/TemplateEdit.vue')
        }
    },
    {
        path: 'Template/Edit/:practiceId/:id',
        props: {
            setting: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                id: Number(route.params.id)
            })
        },
        components: {
            setting: () => import('@/view/Setting/Template/TemplateEdit.vue')
        }
    },
    {
        path: 'NhisVaccineLots/:practiceId/:employeeSeqNumber',
        props: {
            setting: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            setting: () => import('@/view/Nhis/VaccineLot/NhisVaccineLotView.vue')
        }
    },
    {
        path: 'ExamScheduleNew/:eventId?/:date?',
        components: {
            setting: () => import('@/view/ExamSchedule/ExamScheduleNew.vue')
        }
    }
];

export const settingRoutes: RouteConfig = {
    path: '/Setting',
    props: (route: Route) => ({
        practiceId: route.params.practiceId ? Number(route.params.practiceId) : null,
        employeeSeqNumber: route.params.employeeSeqNumber ? Number(route.params.employeeSeqNumber) : null
    }),
    component: () => import('@/view/Setting/SettingMenu.vue'),
    children: settingChildRoutes
};
