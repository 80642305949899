<template>
    <div>
        <v-row>
            <v-col>
                <data-table :headers="headers" :title="title" :items="items" sort-by="number">
                    <template #actions>
                        <btn action="New" :to="`/Exam/${formTypeRoute}/Create/${examId}`" />
                        <btn action="Send" @click="checkPrescriptions">Проверка на рецепти</btn>
                    </template>
                    <template #rowActions="{ item }">
                        <btn icon action="Edit" :to="`/Exam/${formTypeRoute}/Edit/${examId}/${item.id}`" />
                        <btn
                            v-if="canSendForm && !item.nrn && showNhisBtn"
                            icon
                            action="Send"
                            @click="postPrescription(item)"
                        />
                        <btn icon action="Print" :to="`/Print/${printTemplate}/Prescription/${examId}/${item.id}`" />
                    </template>
                </data-table>
            </v-col>
        </v-row>
        <nhis-prescription-group-check ref="groupCheck" :exam-id="examId" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
    import { PrescriptionNhifV2ViewDto } from '@/model/Exam/Prescription/PrescriptionNhifV2ViewDto';
    import { prescriptionService } from '@/service/Exam/PrescriptionService';
    import { nhisPrescriptionService } from '@/service/Nhis/NhisPrescriptionService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { formatters } from '@/util/Formatters';
    import NhisPrescriptionGroupCheck from '@/view/Nhis/Prescription/NhisPrescriptionGroupCheck.vue';

    @Component({
        components: { NhisPrescriptionGroupCheck }
    })
    export default class PrescriptionNhifV2View extends Vue {
        @Prop({ required: true })
        examId!: number;

        @Prop({ required: true })
        formType!: PrescriptionFormTypeCode;

        private items: PrescriptionNhifV2ViewDto[] = [];
        private title: string = '';
        private formTypeRoute: string = '';
        private printTemplate: string = '';

        private initByFormType() {
            switch (this.formType) {
                case PrescriptionFormTypeCode.Nhif5:
                    this.title = 'Бланка 5';
                    this.formTypeRoute = 'PrescriptionV2Nhif5';
                    this.printTemplate = 'Exam.Prescription.PrescriptionNhif';
                    break;
                case PrescriptionFormTypeCode.Nhif5a:
                    this.title = 'Бланка 5A';
                    this.formTypeRoute = 'PrescriptionV2Nhif5a';
                    this.printTemplate = 'Exam.Prescription.PrescriptionNhifTypeA';
                    break;
                case PrescriptionFormTypeCode.Nhif5b:
                    this.title = 'Бланка 5Б';
                    this.formTypeRoute = 'PrescriptionV2Nhif5b';
                    this.printTemplate = 'Exam.Prescription.PrescriptionNhifTypeB';
                    break;
                case PrescriptionFormTypeCode.Nhif5c:
                    this.title = 'Бланка 5В';
                    this.formTypeRoute = 'PrescriptionV2Nhif5c';
                    this.printTemplate = 'Exam.Prescription.PrescriptionNhifTypeC';
                    break;
                default:
                    break;
            }
        }

        private get showNhisBtn() {
            return (
                this.formType === PrescriptionFormTypeCode.Nhif5 || this.formType === PrescriptionFormTypeCode.Nhif5a
            );
        }

        private get canSendForm() {
            return (
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private async checkPrescriptions() {
            await (this.$refs.groupCheck as NhisPrescriptionGroupCheck).checkPrescriptions();
            await this.loadItems();
        }

        private async postPrescription(item: PrescriptionNhifV2ViewDto) {
            this.$loading.show();
            try {
                if (!item.statusCode) {
                    const response = await nhisPrescriptionService.postPrescription(
                        new PrescriptionKey(this.examId, item.id),
                        this.formType
                    );
                    if (response) {
                        this.$notifier.showSuccess('', `Успешно изпратен документ с НРН: ${response.nrn}`);
                        await this.loadItems();
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private get headers(): IDataTableHeader[] {
            return this.formType === PrescriptionFormTypeCode.Nhif5
                ? [
                      { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                      { text: 'Номер:', value: 'number' },
                      { text: 'МКБ кодове:', value: 'diagnoses' },
                      { text: 'Лекарства:', value: 'drugCodes' },
                      { text: 'НРН:', value: 'nrn' },
                      { text: 'Статус:', value: 'status' },
                      { text: 'Дата на проверка:', value: 'statusTime', formatter: formatters.dateTime },
                      { text: 'Дата на изпълнение:', value: 'executionDate', formatter: formatters.dateTime },
                      { text: '', value: 'actionButtons', sortable: false }
                  ]
                : this.formType === PrescriptionFormTypeCode.Nhif5a
                  ? [
                        { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                        { text: 'Номер:', value: 'number' },
                        { text: 'Отрязък:', value: 'segment' },
                        { text: 'МКБ кодове:', value: 'diagnoses' },
                        { text: 'Лекарства:', value: 'drugCodes' },
                        { text: 'НРН:', value: 'nrn' },
                        { text: 'Статус:', value: 'status' },
                        { text: 'Дата на проверка:', value: 'statusTime', formatter: formatters.dateTime },
                        { text: 'Дата на изпълнение:', value: 'executionDate', formatter: formatters.dateTime },
                        { text: '', value: 'actionButtons', sortable: false }
                    ]
                  : [
                        { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                        { text: 'Номер:', value: 'number' },
                        { text: 'МКБ кодове:', value: 'diagnoses' },
                        { text: 'Лекарства:', value: 'drugCodes' },
                        { text: '', value: 'actionButtons', sortable: false }
                    ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                this.items = await prescriptionService.getPrescriptionV2ByExamId(this.examId, this.formType);
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            this.initByFormType();
            await this.loadItems();
        }
    }
</script>
