import { Type } from 'class-transformer';

import { Annex56AdultDiseaseDto } from '@/model/Report/Rhi/Annex56/Annex56AdultDiseaseDto';
import { Annex56CausesOfMorbidityMortalityAdultDto } from '@/model/Report/Rhi/Annex56/Annex56CausesOfMorbidityMortalityAdultDto';
import { Annex56CausesOfMorbidityMortalityChildrenDto } from '@/model/Report/Rhi/Annex56/Annex56CausesOfMorbidityMortalityChildrenDto';
import { Annex56CausesOfMorbidityMortalityTotalDto } from '@/model/Report/Rhi/Annex56/Annex56CausesOfMorbidityMortalityTotalDto';
import { Annex56ChildrenDiseaseDto } from '@/model/Report/Rhi/Annex56/Annex56ChildrenDiseaseDto';
import { Annex56FactorForHealthStatusAdultDto } from '@/model/Report/Rhi/Annex56/Annex56FactorForHealthStatusAdultDto';
import { Annex56FactorForHealthStatusChildrenDto } from '@/model/Report/Rhi/Annex56/Annex56FactorForHealthStatusChildrenDto';
import { Annex56FactorForHealthStatusTotalDto } from '@/model/Report/Rhi/Annex56/Annex56FactorForHealthStatusTotalDto';
import { Annex56TotalDiseaseDto } from '@/model/Report/Rhi/Annex56/Annex56TotalDiseaseDto';

export class Annex56ItemsDto {
    @Type(() => Annex56ChildrenDiseaseDto)
    public childrenDisease: Annex56ChildrenDiseaseDto = new Annex56ChildrenDiseaseDto();

    @Type(() => Annex56FactorForHealthStatusChildrenDto)
    public factorForHealthStatusChildren: Annex56FactorForHealthStatusChildrenDto =
        new Annex56FactorForHealthStatusChildrenDto();

    @Type(() => Annex56CausesOfMorbidityMortalityChildrenDto)
    public causesOfMorbidityMortalityChildren: Annex56CausesOfMorbidityMortalityChildrenDto =
        new Annex56CausesOfMorbidityMortalityChildrenDto();

    @Type(() => Annex56AdultDiseaseDto)
    public adultDisease: Annex56AdultDiseaseDto = new Annex56AdultDiseaseDto();

    @Type(() => Annex56FactorForHealthStatusAdultDto)
    public factorForHealthStatusAdult: Annex56FactorForHealthStatusAdultDto =
        new Annex56FactorForHealthStatusAdultDto();

    @Type(() => Annex56CausesOfMorbidityMortalityAdultDto)
    public causesOfMorbidityMortalityAdult: Annex56CausesOfMorbidityMortalityAdultDto =
        new Annex56CausesOfMorbidityMortalityAdultDto();

    @Type(() => Annex56TotalDiseaseDto)
    public totalDisease: Annex56TotalDiseaseDto = new Annex56TotalDiseaseDto();

    @Type(() => Annex56FactorForHealthStatusTotalDto)
    public factorForHealthStatusTotal: Annex56FactorForHealthStatusTotalDto =
        new Annex56FactorForHealthStatusTotalDto();

    @Type(() => Annex56CausesOfMorbidityMortalityTotalDto)
    public causesOfMorbidityMortalityTotal: Annex56CausesOfMorbidityMortalityTotalDto =
        new Annex56CausesOfMorbidityMortalityTotalDto();
}
