<template>
    <v-card>
        <v-card-title>Кабинети</v-card-title>
        <v-card-actions class="py-0 pl-4">
            <btn action="New" @click="addRoom">Добавяне на кабинет</btn>
            <btn action="Save" @click="saveRooms" />
        </v-card-actions>
        <v-card-text>
            <search-field v-model="searchString" label="Търсене" />
            <v-data-table
                :items="items"
                :headers="headers"
                show-search-box
                :search="searchString"
                single-select
                title="Кабинети"
                no-data-text="Няма намерени данни"
                loading-text="Зареждане на данни..."
            >
                <template #item="{ item }">
                    <tr>
                        <text-field
                            :value="item.name"
                            color="primary"
                            hide-details
                            type="text"
                            @change="updateItem($event, item)"
                        />
                        <td>
                            <div class="float-right">
                                <btn icon action="Delete" @click="deleteRoom(item)" />
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PracticeRoomDto } from '@/model/Practice/PracticeRoomDto';
    import { practiceRoomService } from '@/service/Practice/PracticeRoomService';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component
    export default class PracticeRooms extends Vue {
        @Prop()
        private practiceId!: number;

        private items: PracticeRoomDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'Име', value: 'name' },
            { text: '', value: 'actionButtons', sortable: false }
        ];

        private searchString: string = '';

        private async mounted() {
            await this.loadItems();
        }

        private async loadItems() {
            this.items = (await practiceRoomService.getAllRooms(this.practiceId)).data;
        }

        private addRoom() {
            const newItem = new PracticeRoomDto();
            newItem.practiceId = this.practiceId;
            this.items.unshift(newItem);
        }

        private async deleteRoom(roomToDelete: PracticeRoomDto) {
            if (roomToDelete.seqNumber === 0) {
                arrayUtil.removeFirst(this.items, roomToDelete);
            } else {
                await practiceRoomService.deleteRoom(roomToDelete.practiceId, roomToDelete.seqNumber);
                this.$notifier.showSuccess('Успех', 'Данните са записани успешно');
                this.loadItems();
            }
        }

        private updateItem(value: string, item: PracticeRoomDto) {
            item.name = value;
        }

        private async saveRooms() {
            const roomsForSave = this.items.filter((room) => room.name.length > 0);
            if (roomsForSave.length > 0) {
                await practiceRoomService.save(roomsForSave);

                this.$notifier.showSuccess('Успех', 'Данните са записани успешно');
            }

            this.loadItems();
        }
    }
</script>
