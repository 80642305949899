import { render, staticRenderFns } from "./PatientPrescriptionDrug.vue?vue&type=template&id=037d779e&scoped=true"
import script from "./PatientPrescriptionDrug.vue?vue&type=script&lang=ts"
export * from "./PatientPrescriptionDrug.vue?vue&type=script&lang=ts"
import style0 from "./PatientPrescriptionDrug.vue?vue&type=style&index=0&id=037d779e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "037d779e",
  null
  
)

export default component.exports