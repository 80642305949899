import { Type } from 'class-transformer';

import { NhisLongtermCareDto } from '@/model/Nhis/LongtermCare/NhisLongtermCareDto';
import { NecessaryConsultationDto } from '@/model/Register/NecessaryConsultationDto';
import { NecessaryNhifLabTestDto } from '@/model/Register/NecessaryNhifLabTestDto';

export class DispensaryRegisterDto {
    public doctorEmployeeSeqNumber: number = 0;
    public patientId: number = 0;
    public seqNumber: number = 0;
    public dispensaryRuleId: number = 0;
    public dispensaryRuleName: string = '';
    public dispensaryOrdinanceText: string = '';
    public ambulatorySheetNumber: number | null = null;
    public examId: number | null = null;
    public registerDate: Date = new Date();
    public deregisterDate: Date | null = null;
    public dispensaryDeregisterReasonId: number | null = null;
    public dispensaryGroup: number | null = null;
    public dossierNumber: number | null = null;
    public notes: string = '';
    public icdCode: string = '';
    public icdCodeAdd: string = '';
    public patientIdentifier: string = '';
    public patientName: string = '';
    public isExternalRegistered: boolean = false;

    @Type(() => NecessaryConsultationDto)
    public dispensaryConsultations: NecessaryConsultationDto[] = [];

    @Type(() => NecessaryNhifLabTestDto)
    public dispensaryLabTests: NecessaryNhifLabTestDto[] = [];

    public suggestedFutureExams: string[] = [];
    public ageBracket: string = '';

    public nhisLongtermCare: NhisLongtermCareDto = new NhisLongtermCareDto();
}
