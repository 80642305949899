<template>
    <v-card flat>
        <v-card-title>Вход в системата...</v-card-title>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { oidcClient } from '@/service/Infrastructure/OidcClient';

    @Component
    export default class SigninRedirectCallback extends Vue {
        private async created() {
            const returnUrl = await oidcClient.signinRedirectCallback();
            this.$router.push(returnUrl);
        }
    }
</script>
