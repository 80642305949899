import { Route, RouteConfig } from 'vue-router';

const receptionChildRoutes: RouteConfig[] = [
    {
        path: 'Dashboard',
        components: {
            reception: () => import('@/view/Reception/ReceptionDashboard.vue')
        }
    },
    {
        path: 'Laboratory',
        components: {
            reception: () => import('@/view/Reception/ReceptionDashboard.vue')
        }
    },
    {
        path: 'Service/Create',
        components: {
            reception: () => import('@/view/Reception/Service/ServiceCreate.vue')
        }
    },
    {
        path: 'Service/List/:practiceId',
        props: {
            reception: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            reception: () => import('@/view/Reception/Service/ServiceList.vue')
        }
    },
    {
        path: 'ExamSchedule/:eventId?/:date?',
        props: {
            reception: (route: Route) => ({
                eventId: Number(route.params.eventId),
                date: Date.parse(route.params.date)
            })
        },
        components: {
            reception: () => import('@/view/ExamSchedule/ExamSchedule.vue')
        }
    },
    {
        path: 'ExamScheduleNew/:eventId?/:date?',
        props: {
            reception: (route: Route) => ({
                eventId: Number(route.params.eventId),
                date: Date.parse(route.params.date)
            })
        },
        components: {
            reception: () => import('@/view/ExamSchedule/ExamScheduleNew.vue')
        }
    },

    {
        path: 'ExamSchedule/Create/:eventId/:date/:examScheduleId?',
        props: {
            reception: (route: Route) => ({
                eventId: Number(route.params.eventId),
                date: Date.parse(route.params.date),
                examScheduleId: Number(route.params.examScheduleId)
            })
        },
        components: {
            reception: () => import('@/view/ExamSchedule/ExamScheduleCreate.vue')
        }
    },
    {
        path: 'Fiscal',
        components: {
            reception: () => import('@/view/Reception/Fiscal/FiscalDashboard.vue')
        }
    },
    {
        path: 'Report/Sales/:practiceId',
        props: {
            reception: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            reception: () => import('@/view/Query/Reception/ServiceOrderSummarizedReport.vue')
        }
    },
    {
        path: 'Report/SalesDetailed/:practiceId',
        props: {
            reception: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            reception: () => import('@/view/Query/Reception/ServiceOrderDetailedReport.vue')
        }
    }
];

export const receptionRoutes: RouteConfig = {
    path: '/Reception',
    component: () => import('@/view/Reception/ReceptionMenu.vue'),
    children: receptionChildRoutes
};
