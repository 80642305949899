import { ArticleDto } from '@/model/CashRegister/ArticleDto';
import { PaymentDto } from '@/model/CashRegister/PaymentDto';

export class FiscalReceiptDto {
    public items: ArticleDto[] = [];
    public payments: PaymentDto[] = [];
    public operatorNumber: string = '';
    public operatorPassword: string = '';
    public unpNumber: string = '';
    public deviceMemoryNumber: string = '';
}
