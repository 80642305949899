import { Type } from 'class-transformer';

import { Report365PsychiatricSickRowDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricSickRowDto';

export class Report365PsychiatricSickDto {
    public name: string = '';

    @Type(() => Report365PsychiatricSickRowDto)
    public psychiatricSick: Report365PsychiatricSickRowDto[] = [];
}
