import { Type } from 'class-transformer';

import { VisitCacheDto } from '@/model/Exam/Cache/VisitCacheDto';

export class ExamCreatedDto {
    public examId: number = 0;
    public warnings: string[] = [];

    @Type(() => VisitCacheDto)
    public visitCache: VisitCacheDto | null = null;
}
