<template>
    <v-card v-show="isAlertActive">
        <alert :value="isAlertActive" type="warning" prominent>
            {{ warningMessage }}
        </alert>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { currentPatientCache } from '@/store/CurrentPatientCache';

    const configValues = {
        maxTotalDays: 180
    };

    @Component
    export default class SickSheetTotalDaysSickWarning extends Vue {
        @Prop()
        private totalDays!: number;

        private isAlertActive: boolean = false;

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get warningMessage() {
            if (this.totalDays > configValues.maxTotalDays) {
                this.isAlertActive = true;
                return `За пациент ${this.currentPatient.firstName} ${this.currentPatient.lastName} са издадени
${this.totalDays} дни болнични за ${new Date(Date.now()).getFullYear()} година`;
            }
            return '';
        }
    }
</script>
