import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { RequestForCytosmearDto } from '@/model/Exam/RequestForCytosmear/RequestForCytosmearDto';
import { RequestForCytosmearViewDto } from '@/model/Exam/RequestForCytosmear/RequestForCytosmearViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const requestForCytosmearService = {
    async getRequestForCytosmear(id: number): Promise<RequestForCytosmearDto> {
        const response = await httpService.get(`/RequestForCytosmear/GetRequestForCytosmear/${id}`);
        return plainToInstance(RequestForCytosmearDto, response.data);
    },

    getRequestForCytosmearByExam(examId: number): Promise<AxiosResponse<RequestForCytosmearViewDto[]>> {
        return httpService.get('/RequestForCytosmear/GetRequestForCytosmearViewsByExam/', {
            params: { examId }
        });
    },

    createRequestForCytosmear(data: RequestForCytosmearDto): Promise<AxiosResponse<number>> {
        return httpService.post('/RequestForCytosmear/CreateRequestForCytosmear/', data);
    },

    updateRequestForCytosmear(data: RequestForCytosmearDto): Promise<AxiosResponse<void>> {
        return httpService.put('/RequestForCytosmear/UpdateRequestForCytosmear/', data);
    },

    deleteRequestForCytosmear(id: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/RequestForCytosmear/DeleteRequestForCytosmear/${id}`);
    }
};
