import { AxiosResponse } from 'axios';

import { InjuredPrescriptionBookDto } from '@/model/Patient/PrescriptionBook/InjuredPrescriptionBookDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const injuredPrescriptionBookService = {
    getInjuredPrescriptionBook(injuredPrescriptionBookId: number): Promise<AxiosResponse<InjuredPrescriptionBookDto>> {
        return httpService.get(`/InjuredPrescriptionBook/GetInjuredPrescriptionBook`, {
            params: { injuredPrescriptionBookId }
        });
    },

    getInjuredTableItems(patientId: number): Promise<AxiosResponse<InjuredPrescriptionBookDto[]>> {
        return httpService.get(`/InjuredPrescriptionBook/GetPatientInjuredPrescriptionBooks`, {
            params: { patientId }
        });
    },

    createInjuredPrescriptionBook(data: InjuredPrescriptionBookDto): Promise<AxiosResponse<number>> {
        return httpService.post('/InjuredPrescriptionBook/CreateInjuredPrescriptionBook', data);
    },

    updateInjuredPrescriptionBook(
        data: InjuredPrescriptionBookDto
    ): Promise<AxiosResponse<InjuredPrescriptionBookDto>> {
        return httpService.post('/InjuredPrescriptionBook/UpdateInjuredPrescriptionBook/', data);
    },

    deleteInjuredPrescriptionBook(injuredPrescriptionBookId: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/InjuredPrescriptionBook/DeleteInjuredPrescriptionBook`, {
            params: { injuredPrescriptionBookId }
        });
    }
};
