import { RiskAssessmentCardDto } from '@/model/Exam/Gp/RiskAssessmentCard/RiskAssessmentCardDto';

export class RiskGroupCheckDto {
    constructor(
        public card: RiskAssessmentCardDto,
        public patientId: number,
        public age: number,
        public gender: string
    ) {}
}
