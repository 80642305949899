<template>
    <div>
        <v-row>
            <v-col cols="12"
                ><v-data-table
                    :headers="headers"
                    :items="value"
                    disable-pagination
                    hide-default-footer
                    disable-filtering
                    class="elevation-1"
                >
                    <template #body="{ items }">
                        <tbody>
                            <tr v-for="(item, idx) in items" :key="idx">
                                <td disabled>
                                    <strong> {{ item.name }} </strong>
                                </td>
                                <td
                                    v-for="(field, key) in item.fields"
                                    :key="key"
                                    :style="width ? `width: ${width}%` : 'width: auto'"
                                >
                                    <div v-if="!field.disabled">
                                        <v-edit-dialog :return-value.sync="field.value" large>
                                            {{ field.value }}
                                            <template #input>
                                                <text-field v-model="field.value" label="Edit"></text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </div>
                                    <div
                                        v-else
                                        style="background-color: var(--v-primary-base); border-radius: 5px"
                                        class="text-center"
                                    >
                                        <span>{{ 'X' }}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table></v-col
            >
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';

    @Component
    export default class PregnancyDataTableComponent extends Vue {
        @Prop({ required: true })
        private value!: unknown[];

        @Prop({ required: true })
        private headers!: IDataTableHeader[];

        @Prop()
        private width!: number;
    }
</script>
