<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Бланка за консултация при ТЕЛК"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <main-title>Бланка за консултация при ТЕЛК</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12"><PatientInfo /></v-col>
                </v-row>

                <v-row>
                    <v-col cols="4"><date-picker v-model="document.issueDate" label="Дата:" /></v-col>
                    <v-col cols="4"><text-field v-model="document.number" label="Номер:" /></v-col>
                    <v-col cols="4">
                        <text-field v-model="document.consultationCabinet" label="Консултативен кабинет по:" />
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col cols="12"><v-textarea v-model="document.symptoms" label="Субективен статус:" /></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12"><v-textarea v-model="document.condition" label="Обективен статус:" /></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12"><v-textarea v-model="document.diagnosis" label="Диагноза:" /></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12"><v-textarea v-model="document.notes" label="Забележка:" /></v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { ConsultationTelkDto } from '@/model/Exam/ConsultationTelk/ConsultationTelkDto';
    import { consultationTelkService } from '@/service/Exam/ConsultationTelkService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: { UnsavedChanges, PatientInfo, ExamLayout },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class ConsultationTelk extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private document: ConsultationTelkDto = new ConsultationTelkDto();
        private initialValue: ConsultationTelkDto = new ConsultationTelkDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private async mounted() {
            this.leaveDialogRef = this.leaveDialog;
            await this.load();
            this.copyObject();
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async load() {
            this.$loading.show();
            try {
                if (!this.documentId) {
                    const responseExam = await consultationTelkService.getConsultationTelkInfoByExamId(this.examId);
                    this.document = responseExam.data;
                } else {
                    const responseReferral = await consultationTelkService.getConsultationTelk(this.documentId);
                    this.document = responseReferral.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                const title = `Дата ${this.document.issueDate}; `;
                if (documentId === 0) {
                    const response = await consultationTelkService.createConsultationTelk(this.document);
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, 'ConsultationTelk', title);

                    this.$router.push(`/Exam/ConsultationTelk/Edit/${documentId}`);
                } else {
                    await consultationTelkService.updateConsultationTelk(this.document);
                    eventBus.$emit('update-referral-event', title);
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            const documentId = this.document.id;
            await consultationTelkService.deleteConsultationTelk(documentId);
            this.copyObject();
            this.$router.push(`/Exam/ConsultationTelk/Create/${currentVisitCache.value.id}`);
            eventBus.$emit('delete-referral-event', documentId, 'ConsultationTelk', 'Бланка за консултация при ТЕЛК');
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.ConsultationTelk/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.ConsultationTelk';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
