<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Искане за хистопатологично изследване" :items="items">
                <template #actions>
                    <btn action="New" :to="`/Exam/RequestForHistopathologicalTest/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/RequestForHistopathologicalTest/Edit/${item.id}`" />
                    <btn icon action="Print" :to="`/Print/Exam.RequestForHistopathologicalTest/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RequestForHistopathologicalTestViewDto } from '@/model/Exam/RequestForHistopathologicalTest/RequestForHistopathologicalTestViewDto';
    import { requestForHistopathologicalTestService } from '@/service/Exam/RequestForHistopathologicalTestService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class RequestForHistopathologicalTestView extends Vue {
        @Prop()
        examId!: number;

        private items: RequestForHistopathologicalTestViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Орган:', value: 'body' },
                { text: 'Метод:', value: 'sampleMethod' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response =
                    await requestForHistopathologicalTestService.getRequestForHistopathologicalTestsByExamId(
                        this.examId
                    );
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
