<template>
    <div>
        <v-card-title>Ехография</v-card-title>
        <v-row>
            <v-col cols="6">
                <h4 class="font-weight-bold">Матка</h4>
            </v-col>
            <v-col cols="6">
                <text-field v-model="value.uterus" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <h4 class="font-weight-bold">Аднекси</h4>
            </v-col>
            <v-col cols="6">
                <text-field v-model="value.adnexa" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <h4 class="font-weight-bold">Плацента</h4>
            </v-col>
            <v-col cols="6">
                <text-field v-model="value.placenta" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <h4 class="font-weight-bold">Предлежание на плода (описание)</h4>
            </v-col>
            <v-col cols="6">
                <text-field v-model="value.fetusPresentation" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <h4 class="font-weight-bold">Бипариетален диаметър (мм)</h4>
            </v-col>
            <v-col cols="6">
                <text-field v-model="value.biparietalDiameter" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <h4 class="font-weight-bold">Сърдечна дейност (да/не)</h4>
            </v-col>
            <v-col cols="6">
                <text-field v-model="value.hearthActivity" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <h4 class="font-weight-bold">Околоплодна течност (норма, +/-)</h4>
            </v-col>
            <v-col cols="6">
                <text-field v-model="value.amnioticFluid" />
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { UltrasoundDataDto } from '@/model/Register/Pregnancy/PregnancyCard/UltrasoundDataDto';

    @Component
    export default class UltrasoundData extends Vue {
        @Prop()
        value!: UltrasoundDataDto;
    }
</script>
