import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { ChildHealthCardDto } from '@/model/Exam/ChildHealthCard/ChildHealthCardDto';
import { ChildHealthCardViewDto } from '@/model/Exam/ChildHealthCard/ChildHealthCardViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const childHealthCardService = {
    getCardsByExamId(examId: number): Promise<AxiosResponse<ChildHealthCardViewDto[]>> {
        return httpService.get(`/ChildHealthCard/GetChildHealthCardViewsByExam`, {
            params: {
                examId
            }
        });
    },

    async getChildHealthCardInfoByExamId(examId: number): Promise<ChildHealthCardDto> {
        const response = await httpService.get<ChildHealthCardDto>(
            `/ChildHealthCard/GetChildHealthCardInfoByExamId/${examId}`
        );

        return plainToInstance(ChildHealthCardDto, response.data);
    },

    async getPreviousChildHealthCard(patientId: number): Promise<ChildHealthCardDto> {
        const response = await httpService.get<ChildHealthCardDto>(`/ChildHealthCard/GetPreviousChildHealthCard`, {
            params: { patientId }
        });

        return plainToInstance(ChildHealthCardDto, response.data);
    },

    async getChildHealthCard(id: number): Promise<ChildHealthCardDto> {
        const response = await httpService.get<ChildHealthCardDto>(`/ChildHealthCard/GetChildHealthCard/${id}`);
        return plainToInstance(ChildHealthCardDto, response.data);
    },

    createChildHealthCard(data: ChildHealthCardDto): Promise<AxiosResponse<ChildHealthCardDto>> {
        return httpService.post('/ChildHealthCard/CreateChildHealthCard', data);
    },

    updateChildHealthCard(data: ChildHealthCardDto): Promise<AxiosResponse<ChildHealthCardDto>> {
        return httpService.put('/ChildHealthCard/UpdateChildHealthCard/', data);
    },

    deleteChildHealthCard(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/ChildHealthCard/DeleteChildHealthCard/${id}`);
    }
};
