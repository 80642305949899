import { LocalServerCertificateCacheDto } from '@/model/LocalServer/LocalServerCertificateCacheDto';
import { LocalServerSignCaptureDto } from '@/model/LocalServer/LocalServerSignCaptureDto';
import { LocalServerSignCheckDto } from '@/model/LocalServer/LocalServerSignCheckDto';
import { LocalServerSignDto } from '@/model/LocalServer/LocalServerSignDto';
import { localServerHttpService } from '@/service/Infrastructure/LocalServerHttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { localServerCertificateCache } from '@/store/LocalServerCertificateCache';

export const localServerSignService = {
    async signXml(xmlString: string): Promise<LocalServerSignDto> {
        let result = new LocalServerSignDto(true);
        let certificate = localServerCertificateCache?.value;

        if (!certificate) {
            await this.selectCertificate();
            certificate = localServerCertificateCache?.value;
        }
        try {
            const response = await localServerHttpService.post('/certificate/sign', {
                xml: xmlString,
                thumbprint: certificate?.thumbprint
            });
            result = response?.data;
        } catch {
            notifierService.showError('Проблем при подписване', 'Моля проверете електронния си подпис!');
        }

        return result;
    },

    async signCapture(who: string, why: string, what: string | null = null): Promise<LocalServerSignCaptureDto> {
        let result = new LocalServerSignCaptureDto(true);
        try {
            const response = await localServerHttpService.post(`/signCapture/capture`, {
                who,
                why,
                what
            });
            result = response?.data;
        } catch {
            notifierService.showError('Проблем при подписване', 'Моля проверете устройството!');
        }
        return result;
    },

    async checkSignedData(currentObjectJson: string, signatureRawData: string): Promise<LocalServerSignCheckDto> {
        let result = new LocalServerSignCheckDto(true);
        try {
            const response = await localServerHttpService.post(`/signCapture/checkSignedData`, {
                signatureRawData,
                currentObjectJson
            });
            result = response?.data;
        } catch {
            notifierService.showError('Проблем при проверка', 'Моля проверете!');
        }
        return result;
    },

    async selectCertificate(): Promise<LocalServerCertificateCacheDto> {
        let result = new LocalServerCertificateCacheDto();
        try {
            const response = await localServerHttpService.get(`/certificate/selectCertificate`);
            if (response?.data) {
                await localServerCertificateCache.set(response.data);
            }
            result = response?.data;
        } catch {
            console.log('Проблем при кеширане на подпис', 'Моля проверете електронния си подпис или локалния сървър!');
        }
        return result;
    }
};
