<template>
    <data-table title="Текущи номера на практиката" :headers="headers" :items="items" :loading="$loading.isVisible">
        <template #actions>
            <btn action="New" :to="`/Setting/PracticeCounter/Create/${practiceId}`">Добавяне на вид документи</btn>
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/Setting/PracticeCounter/Edit/${practiceId}/${item.counterTypeCode}`" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PracticeCounterDto } from '@/model/Setting/Counter/PracticeCounterDto';
    import { practiceCounterService } from '@/service/Setting/Counter/PracticeCounterService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class PracticeCounter extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private items: PracticeCounterDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'Вид документи', value: 'counterTypeName' },
            { text: 'Следващ номер', value: 'nextNumber' },
            { text: 'Последна промяна', value: 'lastChangeTime', formatter: formatters.dateTime }
        ];

        private async mounted() {
            this.$loading.show();
            try {
                const response = await practiceCounterService.getCountersByPracticeId(this.practiceId);
                this.items = response.data;
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
