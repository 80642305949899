<template>
    <v-card>
        <v-card-title>Необходими и извършени дейности</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="6">
                    <h4>Консултации</h4>
                    <data-table :headers="headersConsult" :items="consultations" class="elevation-1"></data-table>
                </v-col>
                <v-col cols="12" md="6">
                    <h4>Изследвания</h4>
                    <data-table :headers="headersLabTests" :items="fundLabTests" class="elevation-1"></data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <h4>Предложения за предстоящи прегледи</h4>
                    <data-table
                        :headers="headersSuggestedFutureExams"
                        :items="suggestedFutureExamsTransformed"
                        class="elevation-1"
                    ></data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NecessaryConsultationDto } from '@/model/Register/NecessaryConsultationDto';
    import { NecessaryNhifLabTestDto } from '@/model/Register/NecessaryNhifLabTestDto';

    @Component
    export default class NecessaryActivities extends Vue {
        @Prop({ required: true })
        private consultations!: NecessaryConsultationDto[];

        @Prop({ required: true })
        private fundLabTests!: NecessaryNhifLabTestDto[];

        @Prop()
        private suggestedFutureExams!: string[];

        private headersConsult: IDataTableHeader[] = [
            { text: 'Вариант', value: 'subset' },
            { text: 'Специалност', value: 'specialtyName' },
            { text: 'Необходим брой', value: 'totalActivitiesForYear' },
            { text: 'Необходим брой - описание', value: 'totalActivitiesForYearDescription' },
            { text: 'Извършен брой', value: 'totalPerformedConsultations' }
        ];

        private headersLabTests: IDataTableHeader[] = [
            { text: 'Код на изследване', value: 'nhifLabTestCode' },
            { text: 'Необходим брой', value: 'totalActivitiesForYear' },
            { text: 'Необходим брой - описание', value: 'totalActivitiesForYearDescription' },
            { text: 'Извършен брой', value: 'totalPerformedLabTests' }
        ];

        private get suggestedFutureExamsTransformed() {
            return this.suggestedFutureExams?.map((item) => ({ item })) ?? [];
        }

        private headersSuggestedFutureExams: IDataTableHeader[] = [{ text: 'Дати', value: 'item' }];
    }
</script>
