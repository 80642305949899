<template>
    <iframe
        :src="`${userGuideBaseUrl}/${path}${hash}`"
        style="
            overflow: hidden;
            overflow-x: hidden;
            overflow-y: hidden;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
        "
        height="100%"
        width="100%"
    ></iframe>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { config } from '@/Config';

    @Component
    export default class UserGuide extends Vue {
        @Prop({})
        private path!: string;

        @Prop()
        private hash!: string;

        private get userGuideBaseUrl() {
            return config.userGuideBaseUrl;
        }
    }
</script>
