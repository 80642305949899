import { AxiosResponse } from 'axios';

import { Referral6Dto } from '@/model/Exam/Referral/Referral6Dto';
import { Referral6ViewDto } from '@/model/Exam/Referral/Referral6ViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referral6Service = {
    getReferral6(id: number): Promise<AxiosResponse<Referral6Dto>> {
        return httpService.get(`/Referral6/GetReferral6/${id}`);
    },

    getReferralInfoByExamId(examId: number): Promise<AxiosResponse<Referral6Dto>> {
        return httpService.get(`/Referral6/GetReferralInfoByExamId/${examId}`);
    },

    createReferral6(data: Referral6Dto): Promise<AxiosResponse<Referral6Dto>> {
        return httpService.post('/Referral6/CreateReferral6', data);
    },

    updateReferral6(data: Referral6Dto): Promise<AxiosResponse<Referral6Dto>> {
        return httpService.put('/Referral6/UpdateReferral6/', data);
    },

    deleteReferral6(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/Referral6/DeleteReferral6/${id}`);
    },

    canSaveReferral6(data: Referral6Dto): Promise<AxiosResponse<string[]>> {
        return httpService.post('/Referral6/CanSaveReferral6', data);
    },

    getReferralsByExamId(examId: number): Promise<AxiosResponse<Referral6ViewDto[]>> {
        return httpService.get(`/Referral6/GetReferral6ViewsByExam`, {
            params: {
                examId
            }
        });
    }
};
