import { Type } from 'class-transformer';

import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';

export class ExamCacheDto {
    public ambulatorySheetNumber: number = 0;
    public deputyDoctorUin: string = '';
    public deputyDoctorName: string = '';
    public deputyDoctorSpecialtyName: string = '';
    public deputyDoctorIsHired: boolean = false;

    public nrn: string | null = null;
    public statusCode: string | null = null;
    public statusName: string | null = null;

    @Type(() => ExamDiagnosisDto)
    public examDiagnoses: ExamDiagnosisDto[] = [];

    public getExamDiagnosis(examDiagnosisId: number | null): ExamDiagnosisDto | null {
        return this.examDiagnoses.find((examDiagnosis) => examDiagnosis.id === examDiagnosisId) ?? null;
    }
}
