import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { OfficialNoticeDto } from '@/model/Exam/OfficialNotice/OfficialNoticeDto';
import { OfficialNoticeViewDto } from '@/model/Exam/OfficialNotice/OfficialNoticeViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const officialNoticeService = {
    async getOfficialNotice(id: number): Promise<OfficialNoticeDto> {
        const response = await httpService.get(`/OfficialNotice/GetOfficialNotice/${id}`);
        return plainToInstance(OfficialNoticeDto, response.data);
    },

    async getOfficialNoticeInfoByExamId(id: number): Promise<OfficialNoticeDto> {
        const response = await httpService.get(`/OfficialNotice/GetInfoByExamId/${id}`);
        return plainToInstance(OfficialNoticeDto, response.data);
    },

    getOfficialNoticeByExam(examId: number): Promise<AxiosResponse<OfficialNoticeViewDto[]>> {
        return httpService.get('/OfficialNotice/GetOfficialNoticeViewsByExam/', {
            params: { examId }
        });
    },

    createOfficialNotice(data: OfficialNoticeDto): Promise<AxiosResponse<number>> {
        return httpService.post('/OfficialNotice/CreateOfficialNotice/', data);
    },

    updateOfficialNotice(data: OfficialNoticeDto): Promise<AxiosResponse<void>> {
        return httpService.put('/OfficialNotice/UpdateOfficialNotice/', data);
    },

    deleteOfficialNotice(id: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/OfficialNotice/DeleteOfficialNotice/${id}`);
    },

    getFamilyMemberInfoByIdentifierAsync(
        identifier: string,
        examId: number
    ): Promise<AxiosResponse<OfficialNoticeDto>> {
        return httpService.get('/OfficialNotice/GetFamilyMemberInfoByIdentifier', { params: { identifier, examId } });
    }
};
