<template>
    <div>
        <v-dialog v-model="dialogIsVisible" persistent max-width="75%">
            <v-form ref="form" v-model="isFormValid">
                <v-card>
                    <v-card-title>Извличане на новородено</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6" md="3">
                                <pid-type-picker v-model="model.pidTypeCode" required />
                            </v-col>
                            <v-col cols="6" md="3">
                                <text-field
                                    v-model="model.identifier"
                                    required
                                    label="Идентификатор на майката"
                                ></text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <btn action="Cancel" text @click="closeDialog" />
                        <btn action="Refresh" text @click="extract">Извличане</btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog v-model="resultDialogIsVisible" persistent max-width="75%">
            <v-card>
                <v-card-title class="mx-2 my-3">
                    Новородени
                    <v-spacer />
                    <div class="red--text pointer" @click="resultDialogIsVisible = false">x</div>
                </v-card-title>
                <data-table title="Новородени" :items="tableItems" :headers="tableHeaders" />
            </v-card>
        </v-dialog>
        <btn v-if="tableItems.length > 0" action="List" @click="resultDialogIsVisible = true">Резултат</btn>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import PidTypePicker from '@/component/Picker/PidTypePicker.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PersonIdentificationDto } from '@/model/Common/PersonIdentificationDto';
    import { NhisNewbornDto } from '@/model/Nhis/Hospitalization/NhisNewbornDto';
    import { NhisNewbornExtractionCommand } from '@/model/Nhis/Hospitalization/NhisNewbornExtractionCommand';
    import { nhisHospitalizationService } from '@/service/Nhis/NhisHospitalizationService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { PidTypePicker }
    })
    export default class NewbornExtraction extends Vue {
        @Prop()
        dialogIsVisible!: boolean;

        @Prop()
        value!: PersonIdentificationDto;

        private isFormValid: boolean = false;
        private model: NhisNewbornExtractionCommand = new NhisNewbornExtractionCommand();
        private tableItems: NhisNewbornDto[] = [];
        private resultDialogIsVisible: boolean = false;

        private closeDialog() {
            this.$emit('close');
            this.model = new NhisNewbornExtractionCommand();
        }

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'НРН на раждането:', value: 'nrnBirth' },
                { text: 'Идентификатор на новороденото:', value: 'newbornIdentifier' },
                { text: 'Име:', value: 'firstName' },
                { text: 'Фамилия:', value: 'lastName' },
                { text: 'Пол:', value: 'gender' },
                { text: 'Дата на раждане:', value: 'birthDate', formatter: formatters.date }
            ];
        }

        private async extract() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isFormValid) {
                const xmlString = await nhisHospitalizationService.getNewbornExtractionXml(this.getRequestData());
                const response = await nhisHospitalizationService.postNewbornExtraction(xmlString);
                if (response) {
                    this.tableItems = response.nhisNewborn;
                    this.resultDialogIsVisible = true;
                }

                this.closeDialog();
            }
        }

        private getRequestData() {
            this.model.doctorUin = userContextCache.current?.doctorUin ?? '';
            this.model.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
            this.model.practiceNumber = userContextCache.current?.practiceNumber ?? '';

            return this.model;
        }
    }
</script>
