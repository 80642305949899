import { Type } from 'class-transformer';

import { ExistingDoctorEmployeeDto } from './ExistingDoctorEmployeeDto';

export class ExistingDoctorEmployeesDto {
    public doctorId: number | null = null;
    public doctorName: string = '';

    @Type(() => ExistingDoctorEmployeeDto)
    public doctorEmployees: ExistingDoctorEmployeeDto[] = [];

    public get anyEmployeeIsPracticeOwner(): boolean {
        return Boolean(this.doctorEmployees.find((de) => de.employeeIsPracticeOwner));
    }
}
