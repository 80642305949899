<template>
    <data-table title="Текущи номера на лекаря" :headers="headers" :items="items" :loading="$loading.isVisible">
        <template #actions>
            <btn action="New" :to="`/Setting/DoctorEmployeeCounter/Create/${practiceId}/${doctorEmployeeSeqNumber}`">
                Добавяне на вид документи
            </btn>
        </template>
        <template #rowActions="{ item }">
            <btn
                icon
                action="Edit"
                :to="`/Setting/DoctorEmployeeCounter/Edit/${practiceId}/${doctorEmployeeSeqNumber}/${item.counterTypeCode}`"
            />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { DoctorEmployeeCounterDto } from '@/model/Setting/Counter/DoctorEmployeeCounterDto';
    import { doctorEmployeeCounterService } from '@/service/Setting/Counter/DoctorEmployeeCounterService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class DoctorEmployeeCounter extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private items: DoctorEmployeeCounterDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'Вид документи', value: 'counterTypeName' },
            { text: 'Следващ номер', value: 'nextNumber' },
            { text: 'Последна промяна', value: 'lastChangeTime', formatter: formatters.dateTime }
        ];

        private async mounted() {
            this.$loading.show();
            try {
                const response = await doctorEmployeeCounterService.getCurrentDoctorEmployeeCounters(
                    this.practiceId,
                    this.doctorEmployeeSeqNumber
                );
                this.items = response.data;
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
