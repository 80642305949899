<template>
    <v-autocomplete
        :value="value"
        :loading="loading"
        :items="items"
        :item-value="itemValue"
        :item-text="itemText"
        :return-object="returnObject"
        :label="label"
        :hint="hint"
        :persistent-hint="!!hint"
        :no-data-text="noDataOrFilterTooShort"
        :class="{ required }"
        :rules="required ? [$validator.required] : []"
        :search-input.sync="filter"
        :name="'dropdown' + _uid"
        :clearable="!readonly"
        :readonly="readonly"
        :disabled="disabled"
        dense
        :error-messages="errorMessages"
        :no-filter="noFilterIsActive"
        append-icon="mdi-magnify"
        @input="input"
    >
        <!--
            Да се знае -dkaraminov 05.01.2024
            Тъй като имаше регистриран проблем, при който когато мястото надолу е недостатъчно резултатите излизат нагоре от менюто,
            но при допълване на филтъра и съответно намаляване броя на резултатите, не слизаха надолу, а оставаха някъде във въздуха,
            след проба грешка разбрах, че това се дължи на свойството no-filter, не се сещам как може да се счупи текущата функционалност,
            затова сега го махам.
            -mpetrov 30.01.2024
            Добавено е динамично свойство no-filter, което е активно, когато филтърът съдържа български букви. Нужно е, защото в такъв случай
            резултатите се филтрират допълнително след като са дошли от сървъра, и това може да доведе до нулев брой резултати. Понеже филтъра
            е на кирилица, а резултатите са на латиница и съответно не съвпадат, и не се показват в дропдауна.
         -->
        <!--
            Пояснения за свойството clearable:
            1. Винаги е включено, дори за задължители полета, защото улеснява започването на ново търсене.
               Ново търсене има смисъл дори при вече избрана стойност на задължително поле.
            2. Read-only компонентите показват clearable иконката и тя дори работи! Това е бъг на Vuetify,
               който те отказват да оправят (било by design), затова clearable се изключва за read-only полета.
        -->
    </v-autocomplete>
</template>

<script lang="ts">
    import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

    import type { DropdownValue } from '@/model/Common/Dropdown/DropdownValue';
    import type { IDropdownSearchService } from '@/service/Dropdown/IDropdownSearchService';

    @Component
    export default class DropdownSearch<ItemType> extends Vue {
        @Prop()
        private value!: DropdownValue<ItemType>;

        @Prop({ required: true })
        private service!: IDropdownSearchService<ItemType>;

        @Prop({ default: 'id' })
        private itemValue!: string;

        @Prop({ default: 'name' })
        private itemText!: string | typeof Function;

        @Prop()
        private returnObject!: boolean;

        @Prop()
        private label!: string;

        @Prop()
        private hint!: string;

        @Prop()
        private required!: boolean;

        @Prop()
        private readonly!: boolean;

        @Prop()
        private disabled!: boolean;

        @Prop()
        private errorMessages!: string | [];

        @Prop({ default: 2 })
        private minimumFilterLength!: number;

        @Prop({ default: () => [] })
        private args!: [];

        @Prop({ default: true })
        private noFilter!: boolean;

        private loading: boolean = false;
        private loadingItemsForCurrentValue: boolean = false;
        private selectingItem: boolean = false;
        private items: ItemType[] = [];
        private filter: string | null = null;
        private noFilterIsActive: boolean = false;

        private mounted() {
            this.loadItemsForCurrentValue();
            // Това е нужно, заради спецификите при които no-filter става активно.
            // 1. Когато се търси МКБ на кирилица, то трябва да е активно
            // 2. Когато се търси МКБ на латиница, то трябва да е неактивно
            // 3. Когато се търси другаде на кирилица, то трябва да е неактивно
            if (!this.noFilter) {
                this.noFilterIsActive = false;
            }
        }

        private async loadItemsForCurrentValue() {
            if (this.value) {
                // Потиска следващите 1-2 onSearch-а, за да не изпратят заявка за търсене на началния елемент.
                // Тази заявка, освен че е излишна, не намира нищо, защото търси по цялото описание на елемента.
                this.loadingItemsForCurrentValue = true;

                // Първоначално в списъка с елементи се добавя само началната стойност.
                // В режим returnObject началната стойност се взима наготово, иначе се зарежда от сървъра по id/код.
                if (this.returnObject) {
                    this.items = [this.value as ItemType];
                } else {
                    // This.value съдържа id-то/кода на елемента.
                    // Service-ът трябва да поддържа зареждане на 1 елемент, освен търсене по филтър.
                    this.loading = true;
                    try {
                        this.items = await this.service.getItemsById(this.value as number | string);
                    } finally {
                        this.loading = false;
                    }
                }
            } else {
                // Зарежда всички елементи, но само ако изрично е зададено minimum-filter-length="0".
                await this.searchItems('');
            }
        }

        @Emit()
        private input(item: unknown) {
            //console.log('input', item, '|');
            // Потиска следващите onValueChanged и onSearch, за да не изпрати заявка за търсене на току що избрания елемент.
            // Първо се изпълнява onValueChanged - този метод НЕ сваля selectingItem флага.
            // След това се изпълнява onSearch - този метод сваля selectingItem флага.
            this.selectingItem = true;
            return item;
        }

        @Watch('value')
        private onValueChanged() {
            //console.log('onValueChanged', this.value, this.selectingItem ? 'skip 1' : '|');
            if (this.selectingItem) {
                // След малко ще се изпълни и onSearch, което ще свали selectingItem флага.
                return;
            }

            this.loadItemsForCurrentValue();
        }

        @Watch('filter')
        private async onSearch() {
            //console.log('onSearch', this.filter, this.selectingItem ? 'skip 2' : this.loadingItemsForCurrentValue ? 'skip init' : '|');
            if (this.loadingItemsForCurrentValue) {
                // При програмна промяна на value, filter става null.
                // След това се създава или зарежда списък с един елемент - самото value - и filter става описанието на елемента.
                // Чак второто извикване на onSearch сваля флага.
                if (this.filter) {
                    this.loadingItemsForCurrentValue = false;
                }
                return;
            }

            if (this.selectingItem) {
                this.selectingItem = false;
                return;
            }
            if (this.filter) {
                if (this.noFilter) {
                    this.noFilterIsActive = false;
                    const bulgarianAlphabet = 'АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЬЮЯабвгдежзийклмнопрстуфхцчшщъьюя';
                    for (const char of this.filter) {
                        if (bulgarianAlphabet.includes(char)) {
                            this.noFilterIsActive = true;
                            break;
                        }
                    }
                }
                await this.searchItems(this.filter);
            } else if (this.value) {
                // Бъг?: Ако при вече избрана стойност потребителят започне ново търсене, но после не избере елемент от резултата,
                // при изход от полето за търсене филтърът става null, а старата избрана стойност изчезва от екрана, въпреки че я има.
                // Ако същата стойност се потърси и избере повторно, onValueChanged не се вдига, а при изход от полето стойността пак изчезва от екрана.
                // Кръпка: Когато филтърът стане null, избраната стойност се изтрива, за да съответства на видимо празното поле.
                // Това вдига onValueChanged; ако същата стойност се избере повторно, отново се вдига onValueChanged, а стойността не изчезва от екрана.
                // Подобни казуси с кръпки са засегнати тук: https://github.com/vuetifyjs/vuetify/issues/12880
                this.$emit('input', null);
            }
        }

        private async searchItems(filter: string) {
            if (this.filterIsLongEnough) {
                this.loading = true;
                try {
                    const filters = [filter.trim(), ...this.args];
                    this.items = await this.service.searchItems(filters as []);
                } finally {
                    this.loading = false;
                }
            } else {
                this.items = [];
            }
        }

        private get noDataOrFilterTooShort() {
            return this.filterIsLongEnough
                ? this.$t('dropdown.noDataFound')
                : `Въведете поне ${this.minimumFilterLength} символа`;
        }

        private get filterIsLongEnough() {
            return this.filter && this.filter.length >= this.minimumFilterLength;
        }
    }
</script>
