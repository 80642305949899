import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { ManipulationDto } from '@/model/Exam/Maipulation/ManipulationDto';
import { ManipulationViewDto } from '@/model/Exam/Maipulation/ManipulationViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const manipulationService = {
    create(data: ManipulationDto[]): Promise<AxiosResponse<void>> {
        return httpService.post('Manipulation/Create', data);
    },

    update(data: ManipulationDto): Promise<AxiosResponse<void>> {
        return httpService.put('Manipulation/Update', data);
    },

    delete(id: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`Manipulation/Delete/${id}`);
    },

    async getById(id: number): Promise<ManipulationDto> {
        const response = await httpService.get<ManipulationDto>(`Manipulation/GetById/${id}`);

        return plainToInstance(ManipulationDto, response.data);
    },

    async getByExamId(examId: number): Promise<ManipulationViewDto[]> {
        const response = await httpService.get<ManipulationViewDto[]>(`Manipulation/GetByExamId`, {
            params: {
                examId
            }
        });

        return plainToInstance(ManipulationViewDto, response.data);
    }
};
