<template>
    <v-card>
        <v-card-title>Избор на КЕП</v-card-title>
        <v-card-text>
            <v-row v-if="certificate">
                <v-col md="12">
                    <text-field v-model="certificate.subject" label="Субект"></text-field>
                </v-col>
                <v-col md="12">
                    <text-field v-model="certificate.issuer" label="Издател"></text-field>
                </v-col>
                <v-col md="3">
                    <text-field v-model="certificate.thumbprint" label="Thumbprint"></text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-row>
                <v-col md="3">
                    <btn action="Download" block @click="selectCertificate">Избор на КЕП</btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { localServerSignService } from '@/service/LocalServer/LocalServerSignService';
    import { localServerCertificateCache } from '@/store/LocalServerCertificateCache';

    @Component
    export default class SelectCertificate extends Vue {
        private mounted() {
            localServerCertificateCache.loadFromLocalStorage();
        }

        private get certificate() {
            return localServerCertificateCache.value;
        }

        private async selectCertificate() {
            const response = await localServerSignService.selectCertificate();
            console.log(response);
        }
    }
</script>

<style scoped></style>
