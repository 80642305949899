import { AxiosResponse } from 'axios';

import { LaboratoryTypeDto } from '@/model/Nomenclature/LaboratoryTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const laboratoryTypeService = {
    getLaboratoryTypes(): Promise<AxiosResponse<LaboratoryTypeDto[]>> {
        return httpService.get('/LaboratoryType/GetLaboratoryTypes');
    }
};
