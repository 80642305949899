import { Type } from 'class-transformer';

import { MessageDto } from '@/model/Notification/MessageDto';

export class InboxDto {
    constructor(
        public practiceId: number,
        public employeeSeqNumber: number,
        public messageId: number
    ) {}

    public receiveDate: Date | null = null;
    public readDate: Date | null = null;
    public deleteDate: Date | null = null;
    public isImportant: boolean = false;

    @Type(() => MessageDto)
    public message: MessageDto | undefined;
}
