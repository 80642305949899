<template>
    <v-progress-circular v-if="value" indeterminate size="20" width="1" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class ValueLoading extends Vue {
        @Prop()
        private value!: unknown;
    }
</script>
