<template>
    <v-card>
        <v-card-title>Подписване на XML документ</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-textarea v-model="value" label="Входни данни" />
                </v-col>
                <v-col cols="12">
                    <v-textarea v-model="valueSigned" label="Подписани данни" />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <btn action="CloseDocument" @click="sign">Подписване</btn>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { signXmlService } from '@/service/Report/SignXmlService';

    @Component
    export default class SignXml extends Vue {
        private value: string = '';
        private valueSigned: string | null = null;

        private async sign() {
            const response = await signXmlService.signXml(this.value);
            if (response.isError) {
                this.$notifier.showWarning('', 'Възникна проблем при подписване на файла');
            } else {
                this.valueSigned = response.contents;
            }
        }
    }
</script>
