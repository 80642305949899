import { Type } from 'class-transformer';

import { BatchTariffDto } from './BatchTariffDto';
import { ServiceTitleDto } from './ServiceTitleDto';

export class BatchDto {
    public practiceId: number = 0;

    @Type(() => ServiceTitleDto)
    public serviceTitle: ServiceTitleDto = new ServiceTitleDto();

    @Type(() => BatchTariffDto)
    public tariffs: BatchTariffDto[] = [];
}
