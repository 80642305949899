<template>
    <data-table :headers="headers" title="Медицинско за шофьор" :items="items">
        <template #actions>
            <btn action="New" :to="`/Exam/CertificateDrive/Create/${examId}`" />
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/Exam/CertificateDrive/Edit/${item.id}`" />
            <btn icon action="Print" :to="`/Print/Exam.Certificate.CertificateDrive/${item.id}`" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { CertificateDriveViewDto } from '@/model/Exam/Certificate/CertificateDriveViewDto';
    import { certificateDriveService } from '@/service/Exam/Certificate/CertificateDriveService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class CertificateDriveView extends Vue {
        @Prop()
        examId!: number;

        private items: CertificateDriveViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Номер:', value: 'number' },
                { text: 'Категория:', value: 'licenseCategory' },
                { text: 'Заключение:', value: 'conclusion' },
                { text: 'Годен:', value: 'isCompliant' },
                { text: 'Мотив:', value: 'motive' }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await certificateDriveService.getCertificatesByExamId(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
