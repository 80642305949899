var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ExamLayout',{attrs:{"can-delete":_vm.document.id > 0,"can-print":_vm.document.id > 0,"title":"Медицинско направление 8А за клинична процедура"},on:{"updated":_vm.updateEventHandler,"deleted":_vm.deleteEventHandler,"printed":_vm.printEventHandler,"printed-without-preview":_vm.printWithoutPreviewHandler}},[_c('v-card',[_c('main-title',[_vm._v("Медицинско направление 8А за клинична процедура")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('PatientInfo')],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DoctorInfo')],1)],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('date-picker',{attrs:{"label":"Дата:"},model:{value:(_vm.document.issueDate),callback:function ($$v) {_vm.$set(_vm.document, "issueDate", $$v)},expression:"document.issueDate"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{attrs:{"label":"Спешен"},model:{value:(_vm.document.isEmergency),callback:function ($$v) {_vm.$set(_vm.document, "isEmergency", $$v)},expression:"document.isEmergency"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dropdown',{attrs:{"items":_vm.algorithms.clinicalAlgorithms,"item-value":"code","item-text":"nhifCodeAndName","clearable":"","rules":[
                            _vm.$validator.exactlyOneIsRequired(
                                _vm.document.clinicalProcedureCode,
                                _vm.document.ambulatoryProcedureCode,
                                'Клинична процедура или Амбулаторна процедура'
                            )
                        ],"label":"КПр:"},model:{value:(_vm.document.clinicalProcedureCode),callback:function ($$v) {_vm.$set(_vm.document, "clinicalProcedureCode", $$v)},expression:"document.clinicalProcedureCode"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dropdown',{attrs:{"items":_vm.algorithms.ambulatoryProcedures,"item-value":"code","item-text":"nhifCodeAndName","clearable":"","rules":[
                            _vm.$validator.exactlyOneIsRequired(
                                _vm.document.clinicalProcedureCode,
                                _vm.document.ambulatoryProcedureCode,
                                'Клинична процедура или Амбулаторна процедура'
                            )
                        ],"label":"АПр:"},model:{value:(_vm.document.ambulatoryProcedureCode),callback:function ($$v) {_vm.$set(_vm.document, "ambulatoryProcedureCode", $$v)},expression:"document.ambulatoryProcedureCode"}})],1)],1)],1)],1),_c('UnsavedChanges',{ref:"leaveDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }