import { SubmitCommand } from '@/model/Nhis/SubmitCommand';

export class NhisPatientImmunizationExtractionSubmitCommand extends SubmitCommand {
    constructor(
        xmlString: string,
        accessTokenValue: string,
        public patientId: number
    ) {
        super(xmlString, accessTokenValue);
    }
}
