import { SubmitCommand } from './SubmitCommand';

export class SubmitCommandWithId extends SubmitCommand {
    constructor(
        xmlString: string,
        accessTokenValue: string,
        public entityId: number
    ) {
        super(xmlString, accessTokenValue);
    }
}
