import { Type } from 'class-transformer';

import { PatientSpecialActivityDto } from '@/model/Patient/PatientSpecialActivityDto';
import { DispensaryRuleDto } from '@/model/Register/Dispensary/DispensaryRuleDto';

export class PatientDispensaryDataDto {
    public patientId: number = 0;

    @Type(() => PatientSpecialActivityDto)
    public activities: PatientSpecialActivityDto[] = [];

    @Type(() => PatientSpecialActivityDto)
    public selectedPerformedActivities: PatientSpecialActivityDto[] = [];

    @Type(() => PatientSpecialActivityDto)
    public selectedRequiredActivities: PatientSpecialActivityDto[] = [];

    @Type(() => DispensaryRuleDto)
    public selectedDispensaryRule: DispensaryRuleDto | null = null;

    public doctorEmployeeSeqNumber: number = 0;
    public deputyDoctorId: number | null = null;
    public deputyDoctorIsHired: boolean | null = null;
    public suggestedFutureExams: string[] = [];
    public examId: number | null = null;
}
