import { ExternalDocumentTypeDto } from '@/model/Nomenclature/ExternalDocumentTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const externalDocumentTypeService = {
    async getExternalDocumentTypes(): Promise<ExternalDocumentTypeDto[]> {
        const response = await httpService.get<ExternalDocumentTypeDto[]>(
            'ExternalDocumentType/GetExternalDocumentTypes'
        );
        return response.data;
    },

    async getExternalVisitDocumentTypes(): Promise<ExternalDocumentTypeDto[]> {
        const response = await httpService.get<ExternalDocumentTypeDto[]>(
            'ExternalDocumentType/GetExternalVisitDocumentTypes'
        );
        return response.data;
    }
};
