<template>
    <data-table :headers="headers" title="Медицинско за встъпване в брак" :items="items">
        <template #actions>
            <btn action="New" @click="newHandler()" />
        </template>
        <template #rowActions>
            <btn icon action="Edit" :to="`/Exam/CertificateMariage/Edit/${examId}`" />
            <btn icon action="Print" :to="`/Print/Exam.Certificate.CertificateMariage/${examId}`" />
        </template>
    </data-table>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { CertificateMariageViewDto } from '@/model/Exam/Certificate/CertificateMariageViewDto';
    import { certificateMariageService } from '@/service/Exam/Certificate/CertificateMariageService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class CertificateMariageView extends Vue {
        @Prop()
        examId!: number;

        private items: CertificateMariageViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Диагноза', value: 'diagnosis' }
            ];
        }

        private async mounted() {
            this.items = await certificateMariageService.getSertificates(this.examId);
        }

        private newHandler() {
            if (this.items.length > 0) {
                this.$notifier.showError(
                    'Грешка',
                    'Към АЛ е разрешено издаването само на 1 бланка Медицинско за встъпване в брак.'
                );
                return;
            }
            this.$router.push(`/Exam/CertificateMariage/Create/${this.examId}`);
        }
    }
</script>
