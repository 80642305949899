import Vue from 'vue';

import { ExamQuestionDto } from '@/model/Nomenclature/ExamQuestionDto';
import { examQuestionService } from '@/service/Nomenclature/ExamQuestionService';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

const loadingItem = new ExamQuestionDto();
loadingItem.name = 'Зареждане...';
const loadErrorItem = new ExamQuestionDto();
loadErrorItem.name = 'Грешка при зареждане';

class ExamQuestionCache extends ArrayCacheBase<ExamQuestionDto> {
    constructor() {
        super(loadingItem, loadErrorItem);
    }

    protected async load() {
        const response = await examQuestionService.getExamQuestions();
        this._items = response.data;
    }
}

export const examQuestionCache = Vue.observable(new ExamQuestionCache());
