export class CertificateSocialServicesDto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();
    public number: number = 0;
    public patientAge: number | null = null;
    public prescriptionBookNumber: string = '';
    public diagnoses: string = '';
    public condition: string = '';
    public recommendations: string = '';
    public medicines: string = '';
    public practiceName: string = '';
    public practiceCity: string = '';
    public practiceAddress: string = '';
}
