import { AxiosResponse } from 'axios';

import { UnpostedFormDto } from '@/model/Query/Nhis/UnpostedFormDto';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const unpostedFormService = {
    getUnpostedForms(filter: ReportIntervalFilterDto): Promise<AxiosResponse<UnpostedFormDto[]>> {
        return httpService.post('UnpostedForm/GetUnpostedForms', filter);
    }
};
