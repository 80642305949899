import { Type } from 'class-transformer';

import { Report365VisitRowDto } from '@/model/Report/Rhi/Report365/Report365VisitRowDto';

export class Report365VisitDto {
    public name: string = '';

    @Type(() => Report365VisitRowDto)
    public visits: Report365VisitRowDto[] = [];
}
