import { Type } from 'class-transformer';

import { Report365PreventivePregnancyActivityRowDto } from '@/model/Report/Rhi/Report365/Report365PreventivePregnancyActivityRowDto';

export class Report365PreventivePregnancyActivityDto {
    public name: string = '';

    @Type(() => Report365PreventivePregnancyActivityRowDto)
    public preventivePregnancyActivities: Report365PreventivePregnancyActivityRowDto[] = [];
}
