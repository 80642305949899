<template>
    <left-menu-layout :menu-items="menuItems" router-name="patient" :view-is-visible="currentPatientIfKnownIsLoaded" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import LeftMenuLayout from '@/component/Menu/LeftMenuLayout.vue';
    import { createPatientMenuItems } from '@/model/Menu/PatientMenuItems';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    @Component({
        components: { LeftMenuLayout }
    })
    export default class PatientMenu extends Vue {
        @Prop()
        private id!: number;

        private currentPatientIfKnownIsLoaded: boolean = false;

        private get menuItems() {
            return currentPatientCache.isLoaded ? createPatientMenuItems() : null;
        }

        private async mounted() {
            if (this.id) {
                await currentPatientCache.loadLastVersion(this.id);
            } else {
                currentPatientCache.clear();
            }
            // Виж коментарите във VisitMenu.vue.
            this.currentPatientIfKnownIsLoaded = true;
        }
    }
</script>
