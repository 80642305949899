export enum JournalDocumentTypeCode {
    AmbulatorySheet = 'AmbulatorySheet',
    Referral3 = 'Referral3',
    Referral3a = 'Referral3a',
    Referral4 = 'Referral4',
    Referral6 = 'Referral6',
    Referral7 = 'Referral7',
    Referral8 = 'Referral8',
    ReferralRequestFor = 'ReferralRequestFor',
    PrescriptionNhif = 'PrescriptionNhif',
    PrescriptionNhifTypeA = 'PrescriptionNhifTypeA',
    PrescriptionNhifTypeB = 'PrescriptionNhifTypeB',
    PrescriptionNhifTypeC = 'PrescriptionNhifTypeC',
    PrescriptionNhifWhite = 'PrescriptionNhifWhite',
    PrescriptionNhifGreen = 'PrescriptionNhifGreen',
    PrescriptionNhifYellow = 'PrescriptionNhifYellow',
    SickSheet = 'SickSheet',
    StagedEpicrisis = 'StagedEpicrisis',
    RiskAssessmentCard = 'RiskAssessmentCard',
    ReferralDispObserv = 'ReferralDispObserv',
    ReferralTelk = 'ReferralTelk',
    ChildHealthCard = 'ChildHealthCard'
}
