export class FeeDisengagementIcdDto {
    public id: number = 0;
    public icdCode: string = '';
    public icdCodeAdd: string = '';
    public name: string = '';
    public isLkkNeeded: boolean = false;
    public isDeleted: boolean = false;

    public get text(): string {
        return `МКБ: ${this.icdCode} ${this.icdCodeAdd ? `Доп.МКБ: ${this.icdCodeAdd}` : ''}  - ${this.name}`;
    }
}
