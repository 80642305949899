import { AxiosResponse } from 'axios';

import { NhisDrugApplicationRouteDto } from '@/model/Nomenclature/NhisDrugApplicationRouteDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhisDrugApplicationRouteService = {
    getNhisDrugApplicationRoutes(): Promise<AxiosResponse<NhisDrugApplicationRouteDto[]>> {
        return httpService.get('/NhisDrugApplicationRoute/GetNhisDrugApplicationRoutes');
    }
};
