<template>
    <div>
        <v-row>
            <v-col cols="12">
                <dropdown-search
                    v-model="value.feeDisengagementIcd"
                    :service="feeDisengagementIcdService"
                    item-text="text"
                    label="Търсене по МКБ:"
                    return-object
                />
            </v-col>
        </v-row>
        <v-row class="flex-row align-end">
            <v-col cols="12" md="3">
                <date-picker v-model="value.startDate" label="Начална дата:" required />
            </v-col>
            <v-col v-if="value.isLifeTime === false" cols="12" md="3">
                <date-picker v-model="value.endDate" label="Крайна дата:" />
            </v-col>
            <v-col v-if="value.endDate === null" cols="12" md="3">
                <v-checkbox v-model="value.isLifeTime" label="Пожизнено" @change="isLifeTimeChanged"></v-checkbox>
            </v-col>
            <v-col cols="12">
                <dropdown
                    v-model="value.disengagementReasonId"
                    :items="disengagementReasonItems"
                    label="Избор на причина за освобождаване:"
                    required
                    no-data-text="Няма данни"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <h4>Медицинска експертиза</h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="3">
                <text-field v-model="value.medicalResolutionNumber" label="Решение номер:" />
            </v-col>
            <v-col cols="12" md="3">
                <date-picker v-model="value.medicalResolutionDate" label="Решение дата:" />
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DropdownSearch from '@/component/Dropdown/DropdownSearch.vue';
    import { FeeDisengagementReasonDto } from '@/model/Nomenclature/FeeDisengagementReasonDto';
    import { FeeDisengagementDto } from '@/model/Patient/FeeDisengagement/FeeDisengagementDto';
    import { feeDisengagementReasonService } from '@/service/Nomenclature/FeeDisengagementReasonService';
    import { feeDisengagementIcdService } from '@/service/Patient/FeeDisengagementIcdService';

    @Component({
        components: { DropdownSearch }
    })
    export default class FeeDisengagementEditor extends Vue {
        @Prop({ type: Object })
        private value!: FeeDisengagementDto;

        private disengagementReasonItems: FeeDisengagementReasonDto[] = [];

        private get feeDisengagementIcdService() {
            return feeDisengagementIcdService;
        }

        private async getFeeDisengagementReasons() {
            const response = await feeDisengagementReasonService.getFeeDisengagementReasons();
            this.disengagementReasonItems = response.data;
        }

        private isLifeTimeChanged() {
            if (!this.value.isLifeTime) {
                this.value.endDate = new Date().toISOString();
            }
        }

        private async mounted() {
            await this.getFeeDisengagementReasons();
        }
    }
</script>
