<template>
    <div :disabled="!$allowWrite">
        <v-card>
            <v-card-title>Месторабота</v-card-title>
            <v-card-text v-for="(workplace, index) in workplaces" :key="index">
                <h4>Месторабота: {{ index + 1 }}</h4>
                <v-row class="mt-1">
                    <v-col>
                        <v-form ref="workplaceForm" v-model="workplace.isValid">
                            <PatientWorkplace :value="workplace" name="workPlace" @check-validation="validate(index)" />
                        </v-form>
                    </v-col>
                    <v-col cols="auto">
                        <btn v-if="$allowWrite" icon action="Delete" @click="removeWorkplace(index)" />
                    </v-col>
                </v-row>
                <alert :value="workplace.isItUse" type="warning">
                    <template #close>
                        <btn icon action="Close" @click="customClose(workplace)" />
                    </template>
                    Избраната месторабота се използва в Болничен лист и поради това изтриването е невъзможно
                </alert>
            </v-card-text>
            <template v-if="$allowWrite">
                <v-card-actions>
                    <btn action="New" @click="addWorkplace" />
                </v-card-actions>
            </template>
        </v-card>
        <btn v-if="$allowWrite" action="Save" :disabled="$loading.isVisible" @click="save" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { PatientUpdateWorkplaceDto } from '@/model/Patient/PatientUpdateWorkplaceDto';
    import { PatientWorkplaceDto } from '@/model/Patient/PatientWorkplaceDto';
    import { patientWorkplaceService } from '@/service/Patient/PatientWorkplaceService';
    import { arrayUtil } from '@/util/ArrayUtil';
    import PatientWorkplace from '@/view/Patient/PatientEdit/PatientWorkplace.vue';

    @Component({
        components: { PatientWorkplace }
    })
    export default class PatientWorkplaceView extends Vue {
        @Prop()
        public id!: number;

        @Prop()
        public closeClicked!: boolean;

        private workplaces: PatientWorkplaceDto[] = [];
        private initialWorkplaces: PatientWorkplaceDto[] = [];
        private isFormValid: boolean = false;
        private isItUseAlertActive: boolean = false;

        private addWorkplace() {
            this.workplaces.push(new PatientWorkplaceDto());
        }

        private async removeWorkplace(index: number) {
            const isItUse = await this.checkWorkplaceIsUse(this.workplaces[index].id);
            if (isItUse) {
                this.workplaces[index].isItUse = true;
            } else {
                arrayUtil.removeAt(this.workplaces, index);
            }
        }

        private checkWorkplaceIsUse(patientWorkplaceId: number | null) {
            if (patientWorkplaceId !== null) {
                return patientWorkplaceService.checkWorkplaceIsUseInSickSheet(patientWorkplaceId);
            }
            return false;
        }

        private customClose(workplace: PatientWorkplaceDto) {
            workplace.isItUse = false;
        }

        private async getPatientWorkplaces() {
            this.$loading.show();
            try {
                const response = await patientWorkplaceService.getAllPatientWorkplaces(this.id);
                if (response) {
                    this.workplaces = response;
                    this.initialWorkplaces = [...this.workplaces];
                }
            } finally {
                this.$loading.hide();
            }
        }

        @Watch('closeClicked')
        private changeClose(value: boolean) {
            if (value) {
                this.workplaces = [...this.initialWorkplaces];
            }
        }

        private get workplaceForms() {
            return this.$refs.workplaceForm as [];
        }

        private validate(index: number) {
            //Валидираме или ресетваме валидация за дадена месторабота
            const workplace = this.workplaces[index];
            if (!workplace.isDeleted || !workplace.id) {
                (this.workplaceForms[index] as Vue & { validate: () => boolean }).validate();
            } else {
                (this.workplaceForms[index] as Vue & { resetValidation: () => boolean }).resetValidation();
            }
        }

        private beforeSave() {
            //Променям валидацията като слагам форма към всяка отделна работа, за да може да се реализира функционалност
            // маркиране за неактивна месторабота с невалидни полета, но същевремнно да се запази текущата валидация
            for (let index = 0; index < this.workplaces.length; index++) {
                this.workplaces[index].isValid = (
                    this.workplaceForms[index] as Vue & { validate: () => boolean }
                ).validate();
            }
            if (
                this.workplaces.some(
                    (workplace) =>
                        (!workplace.isValid && !workplace.isDeleted) || (workplace.isDeleted && !workplace.id)
                )
            ) {
                return false;
            }
            return true;
        }

        private async save() {
            this.$loading.show();
            try {
                if (this.beforeSave()) {
                    await patientWorkplaceService.updatePatientWorkplaces(
                        new PatientUpdateWorkplaceDto(this.id, this.workplaces)
                    );
                    this.$notifier.showSuccess('', 'Успешен запис');
                    await this.getPatientWorkplaces();
                    this.change();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private change() {
            this.$emit('change', this.workplaces);
        }

        private async mounted() {
            await this.getPatientWorkplaces();
        }
    }
</script>
