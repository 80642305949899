<template>
    <v-autocomplete
        :value="value"
        :items="filteredFundServices"
        hide-selected
        item-text="name"
        item-value="code"
        :label="label"
        filled
        solo
        chips
        dense
        hide-no-data
        return-object
        deletable-chips
        @change="change"
    >
    </v-autocomplete>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { FundServiceDto } from '@/model/Nomenclature/FundServiceDto';
    import { fundServiceService } from '@/service/Nomenclature/FundServicesService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class FundServicePicker extends Vue {
        @Prop({ type: Object })
        private value!: FundServiceDto;

        @Prop({ required: true })
        private label!: string;

        @Prop()
        private fundCode!: string;

        @Prop()
        private labTestCategoryCode!: string;

        @Prop()
        private date!: Date | null;

        private fundServices: FundServiceDto[] = [];

        private filteredFundServices: FundServiceDto[] = [];

        @Watch('labTestCategoryCode')
        private onLabTestCategoryCodeChange() {
            this.filterCategory();
        }

        private change(value: FundServiceDto) {
            if (value && value.name && value.code) {
                this.$emit('input', value);
            } else {
                this.$emit('input', new FundServiceDto());
            }
        }

        private get isSpecialist() {
            return userContextCache.current?.specialtyCode !== SpecialtyCode.Gp;
        }

        private filterCategory() {
            this.filteredFundServices = this.fundServices.filter(
                (flt) =>
                    (flt.fundServicePackageCode === this.labTestCategoryCode ||
                        flt.labTestCategoryCode === this.labTestCategoryCode) &&
                    flt.fundCode === this.fundCode
            );
        }

        private async mounted() {
            this.fundServices = await fundServiceService.getFundServices(this.date);
            this.filterCategory();
        }
    }
</script>

<style>
    .v-autocomplete {
        white-space: pre-wrap;
        display: flex;
        height: auto;
    }

    .v-chip__content {
        white-space: pre-wrap;
        display: flex;
        height: auto;
    }

    .v-autocomplete__content .v-list__tile {
        height: auto;
        display: flex;
    }

    .v-input__slot {
        height: auto;
        display: flex;
    }
</style>
