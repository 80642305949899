<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Удостоверение за извършен предсъстезателен физикален медицински преглед"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <main-title> Удостоверение за извършен предсъстезателен физикален медицински преглед </main-title>
            <v-card-text>
                <AmbulatorySheetHeaderInfoCard v-once />
            </v-card-text>
            <v-card-text>
                <v-row class="mt-5">
                    <v-col md="3" cols="6">
                        <text-field v-model="document.sportType" label="Вид спорт" />
                    </v-col>
                    <v-col md="3" cols="6">
                        <text-field v-model="document.competitionName" label="Състезание" />
                    </v-col>
                    <v-col md="3" cols="6">
                        <date-picker v-model="document.competitionDate" label="Дата на провеждане" required />
                    </v-col>
                    <v-col md="3" cols="6">
                        <text-field
                            v-model="document.competitionLocation"
                            label="Място на провеждане"
                            :rules="[$validator.maxLength(100)]"
                        />
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-textarea v-model="document.patientCondition" label="Анамнеза и физикално състояние" />
                    </v-col>
                    <v-col md="3" cols="6">
                        Разрешавам участие на ученика в посоченото състезание
                        <v-radio-group v-model="document.isSuitable" row>
                            <v-radio label="Да" :value="true"></v-radio>
                            <v-radio label="Не" :value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DatePicker from '@/component/Date/DatePicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { CertificatePhysicalExamDto } from '@/model/Exam/Certificate/CertificatePhysicalExamDto';
    import { certificatePhysicalExamService } from '@/service/Exam/Certificate/CertificatePhysicalExamService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import AmbulatorySheetHeaderInfoCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetHeaderInfoCard.vue';

    @Component({
        components: { AmbulatorySheetHeaderInfoCard, DatePicker, ExamLayout }
    })
    export default class CertificatePhysicalExam extends Vue {
        @Prop()
        private examId!: number;

        @Prop()
        private documentId!: number;

        private document: CertificatePhysicalExamDto = new CertificatePhysicalExamDto();

        private async mounted() {
            await this.load();
        }

        private async load() {
            this.$loading.show();
            try {
                if (this.documentId) {
                    this.document = await certificatePhysicalExamService.getById(this.documentId);
                } else {
                    this.document = await certificatePhysicalExamService.getInfoByExamId(this.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                if (!this.documentId) {
                    const id = await certificatePhysicalExamService.create(this.document);
                    eventBus.$emit(
                        'create-referral-event',
                        this.examId,
                        'CertificatePhysicalExam',
                        'Удостоверение за извършен предсъстезателен физикален медицински преглед'
                    );
                    this.$router.push(`/Exam/CertificatePhysicalExam/Edit/${id}`);
                } else {
                    await certificatePhysicalExamService.update(this.document);
                    eventBus.$emit(
                        'update-referral-event',
                        'Удостоверение за извършен предсъстезателен физикален медицински преглед'
                    );
                }
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            this.$router.push(`/Print/Exam.Certificate.CertificatePhysicalExam/${this.documentId}`);
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Certificate.CertificatePhysicalExam';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            await certificatePhysicalExamService.delete(this.documentId);
            this.$router.push(`/Exam/CertificatePhysicalExam/Create/${currentVisitCache.value.id}`);
            eventBus.$emit(
                'delete-referral-event',
                this.documentId,
                'CertificatePhysicalExam',
                'Удостоверение за извършен предсъстезателен физикален медицински преглед'
            );
        }
    }
</script>

<style scoped></style>
