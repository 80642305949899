<template>
    <div>
        <v-row>
            <v-col cols="6">
                <text-field v-model="value.name" label="Име" required />
            </v-col>
            <v-col cols="2">
                <text-field v-model="value.shortCode" label="Кратък код" :rules="[$validator.maxLength(20)]" />
            </v-col>
            <v-col cols="2">
                <dropdown
                    v-model="value.serviceCategorySeqNumber"
                    item-value="seqNumber"
                    label="Категория"
                    :items="categories"
                ></dropdown>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <date-picker v-model="value.validFrom" label="Влиза в сила от:"></date-picker>
            </v-col>
            <v-col cols="2">
                <date-picker v-model="value.validTo" label="До:"></date-picker>
            </v-col>
        </v-row>
        <v-row>
            <alert v-if="alertText" type="warning">
                Има друга услуга със същото име.
                <router-link :to="alertText"> Може да я редактирате тук </router-link>
            </alert>
        </v-row>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ServiceCategoryDto } from '@/model/Practice/Service/ServiceCategoryDto';
    import { ServiceTitleDto } from '@/model/Practice/Service/ServiceTitleDto';

    @Component
    export default class ServiceTitle extends Vue {
        @Prop({ required: true })
        private value!: ServiceTitleDto;

        @Prop()
        private alertText!: string;

        @Prop()
        private categories!: ServiceCategoryDto[];
    }
</script>
