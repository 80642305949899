<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Протокол за лекарства" :items="items">
                <template #actions>
                    <btn action="New" :to="`/Exam/NhifProtocol/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/NhifProtocol/Edit/${item.id}`" />
                    <btn v-if="canSendForm && !item.nrn" icon action="Send" @click="postProtocol(item)" />
                    <btn icon action="Print" :to="`/Print/Exam.NhifProtocol.NhifProtocol/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhifProtocolViewDto } from '@/model/Exam/DrugProtocol/NhifProtocolViewDto';
    import { NhifCommand } from '@/model/Nhif/NhifCommand';
    import { NhifProtocolInfoCommand } from '@/model/Nhif/Protocol/NhifProtocolInfoCommand';
    import { drugProtocolService } from '@/service/Exam/Protocol/DrugProtocolService';
    import { notifierService } from '@/service/Infrastructure/NotifierService';
    import { nhifIntegrationService } from '@/service/Integration/NhifIntegrationService';
    import { nhifProtocolService } from '@/service/Nhif/NhifProtocolService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { loadingState } from '@/store/LoadingState';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class DrugProtocolView extends Vue {
        @Prop()
        examId!: number;

        private items: NhifProtocolViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Номер:', value: 'number' },
                { text: 'Срок дни:', value: 'days' },
                { text: 'МКБ:', value: 'examDiagnosis' },
                { text: 'Лекарства:', value: 'drugs' },
                { text: 'НРН:', value: 'nrn' },
                { text: 'Статус:', value: 'status' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private get canSendForm() {
            return (
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await drugProtocolService.getDrugProtocolByExam(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private createNhifCommand(entityId: number): NhifCommand {
            const command = new NhifCommand(
                currentPatientCache.value.key,
                userContextCache.current?.doctorUin ?? null,
                userContextCache.current?.deputyDoctorUin ?? null
            );
            command.deputyDoctorIsHired = userContextCache.current?.deputyDoctorUin
                ? userContextCache.current?.deputyDoctorIsHired
                : null;
            command.entityId = entityId;

            return command;
        }

        private async postProtocol(item: NhifProtocolViewDto) {
            loadingState.show();
            try {
                const jsonResponse = await nhifProtocolService.getProtocolCreateJson(this.createNhifCommand(item.id));
                if (jsonResponse.data) {
                    const response = await nhifIntegrationService.postProtocolCreate(jsonResponse.data);
                    const result = await nhifProtocolService.getProtocolCreateResult(
                        new NhifProtocolInfoCommand(JSON.stringify(response?.data), item.id)
                    );
                    if (result?.error?.length > 0) {
                        await notifierService.showWarning('Внимание', `${result.error}`);
                    } else if (result) {
                        this.$notifier.showSuccess('', `Успешно изпратен документ с НРН: ${result.nrn}`);
                        await this.loadItems();
                    }
                }
            } finally {
                loadingState.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
