import { FiscalReceiptDto } from '@/model/CashRegister/FiscalReceiptDto';
import { ReportShortDto } from '@/model/CashRegister/ReportShortDto';
import { ServerResponseDto } from '@/model/CashRegister/ServerResponseDto';
import { localServerHttpService } from '@/service/Infrastructure/LocalServerHttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';

export const cashRegisterService = {
    async fiscalReceipt(command: FiscalReceiptDto): Promise<ServerResponseDto> {
        let result = null;
        try {
            const response = await localServerHttpService.post('cashRegister/printBon', command);
            result = response?.data;
        } catch {
            notifierService.showError('Проблем с касовия апарат.', 'Моля, проверете касовия апарат!');
        }

        return result;
    },

    async getListDevices(): Promise<ServerResponseDto> {
        let result = null;
        try {
            const response = await localServerHttpService.post('cashRegister/listDevices');
            result = response?.data;
        } catch {
            result = null;
        }
        return result;
    },

    async setDateTime(command: string): Promise<boolean> {
        let result = true;
        try {
            const data = JSON.stringify(command);
            const response = await localServerHttpService.post('cashRegister/setDateTime', data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    dataType: 'text'
                }
            });
            result = response?.data;
        } catch (error: unknown) {
            notifierService.showError('Проблем с касовия апарат.', 'Моля, проверете касовия апарат!');
            result = false;
        }
        return result;
    },

    async cancelBon(command: string): Promise<boolean> {
        let result = true;
        try {
            const data = JSON.stringify(command);
            const response = await localServerHttpService.post('cashRegister/cancelBon', data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    dataType: 'text'
                }
            });
            result = response?.data;
        } catch {
            notifierService.showError('Проблем с касовия апарат.', 'Моля, проверете касовия апарат!');
            result = false;
        }
        return result;
    },

    async executeXReport(command: string): Promise<boolean> {
        let result = true;
        try {
            const data = JSON.stringify(command);
            const response = await localServerHttpService.post('cashRegister/xreport', data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    dataType: 'text'
                }
            });
            result = response?.data;
        } catch {
            notifierService.showError('Проблем с касовия апарат.', 'Моля, проверете касовия апарат!');
            result = false;
        }
        return result;
    },

    async executeZReport(command: string): Promise<boolean> {
        let result = true;
        try {
            const data = JSON.stringify(command);
            const response = await localServerHttpService.post('cashRegister/zreport', data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    dataType: 'text'
                }
            });
            result = response?.data;
        } catch {
            notifierService.showError('Проблем с касовия апарат.', 'Моля, проверете касовия апарат!');
            result = false;
        }
        return result;
    },

    async reportPeriodShort(command: ReportShortDto): Promise<boolean> {
        let result = true;
        try {
            const response = await localServerHttpService.post('cashRegister/reportPeriodShort', command);
            result = response?.data;
        } catch {
            notifierService.showError('Проблем с касовия апарат.', 'Моля, проверете касовия апарат!');
            result = false;
        }
        return result;
    }
};
