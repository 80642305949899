export class AnamnesisPersonalDto {
    public cigarettesCount: number | null = null;
    public sedentaryLife: boolean = false;
    public adenoma: boolean = false;
    public polyp: boolean = false;
    public colorectalCarcinoma: boolean = false;
    public crohn: boolean = false;
    public colitis: boolean = false;
    public celiacDisease: boolean = false;
    public type1Diabetes: boolean = false;
    public type2Diabetes: boolean = false;
    public dyslipidaemia: boolean = false;
    public fruitsVegetables: boolean = false;
    public antihypertensive: boolean = false;
    public prediabetic: boolean = false;
    public pregnancyChildBirth: boolean = false;
    public prolongedHrt: boolean = false;
    public immunosuppressed: boolean = false;
    public hpvVaccine: boolean = false;
    public womenCancers: boolean = false;
    public benignMammary: boolean = false;
    public menarche: boolean = false;
    public pregnancy: boolean = false;
    public dynamismPsa: boolean = false;
}
