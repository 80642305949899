<template>
    <v-card-text>
        <v-row>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="6" md="4">
                        <text-field v-model="value.childrenBorn" type="number" label="Родени деца (брой):" />
                    </v-col>
                    <v-col cols="6" md="4">
                        <text-field v-model="value.aliveChildrenCount" type="number" label="От тях живи (брой):" />
                    </v-col>
                    <v-col cols="6" md="4">
                        <text-field v-model="value.stillBornChildrenCount" type="number" label="мъртви (брой):" />
                    </v-col>
                    <v-col cols="12">
                        <date-picker v-model="value.lastBirthDate" label="Последно раждане - дата:" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field v-model="value.terminal" label="Срочно (не/да):" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field v-model="value.child" label="Детето (живо/мъртво):" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12" md="6">
                        <text-field v-model="value.abortsCount" type="number" label="Аборти (брой):" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field v-model="value.spontaneousAbortCount" type="number" label="Спонтанни (брой):" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model="value.abortByMedicineNeedCount"
                            type="number"
                            label="По мед. показания (брой):"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field v-model="value.abortByRequestCount" type="number" label="По желание (брой):" />
                    </v-col>
                    <v-col cols="12">
                        <date-picker v-model="value.lastAbortDate" label="Последен аборт - дата:" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-textarea
                    v-model="value.previousPregnancyBehaviour"
                    label="Протичане на предишни бременности и раждания (особености):"
                    dense
                />
            </v-col>
            <v-col cols="12">
                <v-textarea v-model="value.cesareanSection" label="Прекарано Цезарово сечение: не, да - дата:" dense />
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PreviousPregnancyDto } from '@/model/Exam/Referral/PregnancyReferral/PreviousPregnancyDto';

    @Component
    export default class PreviousPregnancy extends Vue {
        @Prop()
        value!: PreviousPregnancyDto;
    }
</script>
