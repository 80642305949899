<template>
    <v-form ref="hiredEmployeeForm" v-model="isFormValid">
        <v-card>
            <v-card-title>Добавяне на нает лекар на {{ primaryDoctorEmployee.employeeTitle }}</v-card-title>
            <v-card-actions>
                <btn action="Back" :to="`/Employee/Edit/${practiceId}/${doctorEmployeeSeqNumber}`" />
                <btn v-if="allowWrite(doctorEmployeeSeqNumber)" action="Next" @click="createDoctorDeputyAsHired" />
            </v-card-actions>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <dropdown
                            v-model="selectedEmployeeSeqNumber"
                            :items="doctorEmployees"
                            item-value="employeeSeqNumber"
                            item-text="uinNameAndSpecialty"
                            label="Изберете нает лекар от персонала в практиката"
                            required
                        />
                    </v-col>
                    <router-link
                        class="align-self-center"
                        :to="`/Employee/Create/${practiceId}/${doctorEmployeeSeqNumber}`"
                    >
                        ...или първо добавете наетия лекар към персонала
                    </router-link>
                </v-row>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Ref } from 'vue-property-decorator';

    import { DoctorDeputyCreateHiredCommand } from '@/model/Employee/Deputy/DoctorDeputyCreateHiredCommand';
    import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
    import { doctorDeputyService } from '@/service/Employee/Deputy/DoctorDeputyService';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { EmployeeBase } from '@/view/Employee/EmployeeBase';

    @Component
    export default class DoctorDeputyCreateHired extends EmployeeBase {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private isFormValid: boolean = false;
        private primaryDoctorEmployee: EmployeeTitleDto = new EmployeeTitleDto();
        private doctorEmployees: EmployeeTitleDto[] = [];
        private selectedEmployeeSeqNumber: number | null = null;
        private validFrom: Date | null = null;
        private validTo: Date | null = null;

        private async mounted() {
            this.primaryDoctorEmployee = await employeeService.getEmployeeTitle(
                this.practiceId,
                this.doctorEmployeeSeqNumber
            );
            this.doctorEmployees = await doctorDeputyService.getDoctorEmployeesWithDifferentUin(
                this.practiceId,
                this.doctorEmployeeSeqNumber
            );
        }

        @Ref()
        private hiredEmployeeForm!: { validate: () => boolean };

        private async createDoctorDeputyAsHired() {
            this.hiredEmployeeForm.validate();
            if (this.isFormValid && this.selectedEmployeeSeqNumber) {
                const command = new DoctorDeputyCreateHiredCommand();
                command.practiceId = this.practiceId;
                command.primaryDoctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
                command.deputyDoctorEmployeeSeqNumber = this.selectedEmployeeSeqNumber;
                const deputyDoctorId = await doctorDeputyService.createDoctorDeputyAsHired(command);
                this.$router.push(
                    `/Employee/DoctorDeputy/Edit/${this.practiceId}/${this.doctorEmployeeSeqNumber}/${deputyDoctorId}/true`
                );
            }
        }
    }
</script>
