import { Type } from 'class-transformer';

import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';

import { EmployeeUserDto } from './EmployeeUserDto';
import { RoleDropdownDto } from './RoleDropdownDto';

export class EmployeeEditDto extends EmployeeTitleDto {
    public practiceId: number = 0;
    public hasVisits: boolean = false;
    public examScheduleIsPublic: boolean = false;

    @Type(() => EmployeeUserDto)
    public employeeUsers: EmployeeUserDto[] = [];

    public invitedEmails: string[] = [];
    public employeeRoleCodes: string[] = [];

    @Type(() => RoleDropdownDto)
    public allRoles: RoleDropdownDto[] = [];
}
