<template>
    <data-table title="Сведения за извършени имунизации" :headers="headers" :items="reports" show-search-box>
        <template #actions>
            <btn action="New" :to="`/Report/Rhi/ImmunizationReport/Create/${practiceId}/${doctorEmployeeSeqNumber}`">
                Генериране на ново
            </btn>
        </template>
        <template #rowActions="{ item }">
            <btn
                icon
                action="Edit"
                :to="`/Report/Rhi/ImmunizationReport/Edit/${practiceId}/${doctorEmployeeSeqNumber}/${item.seqNumber}`"
            />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RhiReportRowDto } from '@/model/Report/Rhi/RhiReportRowDto';
    import { immunizationReportService } from '@/service/Report/Rhi/Immunization/RhiImmunizationReportService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class ImmunizationReport extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private search: string = '';
        private reports: RhiReportRowDto[] = [];
        private headers: IDataTableHeader[] = [
            { text: 'От дата', value: 'startDate', formatter: formatters.date },
            { text: 'До дата', value: 'endDate', formatter: formatters.date }
        ];

        private async mounted() {
            this.$loading.show();
            try {
                this.reports = await immunizationReportService.getRhiImmunizationReports(
                    this.practiceId,
                    this.doctorEmployeeSeqNumber
                );
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
