<template>
    <v-toolbar-title>
        <v-container class="container hidden-xs-only">
            <v-layout row class="py-0 justify-end">
                {{ doctorNameOrEmployeeDescription }}
            </v-layout>
            <v-layout v-if="specialtyTitle" row class="layoutRowMarginTop justify-end">
                <span class="hidden-xs-only smallerFontSize">{{ specialtyTitle }}</span>
            </v-layout>
            <v-layout v-if="nurseTitle" row class="layoutRowMarginTop justify-end">
                <span class="hidden-xs-only smallerFontSize">{{ nurseTitle }}</span>
            </v-layout>
            <v-layout v-if="deputyDoctorTitle" row class="layoutRowMarginTop justify-end">
                <span class="hidden-xs-only smallerFontSize">{{ deputyDoctorTitle }}</span>
            </v-layout>
        </v-container>
    </v-toolbar-title>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class CurrentUserContextTitle extends Vue {
        private get doctorNameOrEmployeeDescription() {
            const { current } = userContextCache;
            return current ? (current.doctorName ?? current.employeeDescription) : 'Не е избрана месторабота';
        }

        private get specialtyTitle() {
            const { current } = userContextCache;
            return current?.specialtyName ? `${current.specialtyName} (${current.specialtyNhifCode})` : null;
        }

        private get nurseTitle() {
            const { current } = userContextCache;
            return current?.nurseSeqNumber ? `мед. сестра ${current.nurseName}` : null;
        }

        private get deputyDoctorTitle() {
            const { current } = userContextCache;
            return current?.deputyDoctorName
                ? `${current.deputyDoctorIsHired ? 'нает' : 'зам.'} ${current.deputyDoctorName}`
                : null;
        }
    }
</script>

<style scoped>
    .layoutRowMarginTop {
        margin-top: 5px !important;
    }

    .container {
        font-size: 90%;
    }

    .smallerFontSize {
        font-size: 80%;
    }
</style>
