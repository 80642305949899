import { LocalServerSignDto } from '@/model/LocalServer/LocalServerSignDto';
import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
import { localServerSignService } from '@/service/LocalServer/LocalServerSignService';

export const signXmlService = {
    async signXml(xmlSting: string): Promise<LocalServerSignDto> {
        const isLocalServerActive = await localServerDiagnosticService.checkIsLocalServerActive();
        if (isLocalServerActive && xmlSting.length > 0) {
            return localServerSignService.signXml(xmlSting);
        }
        return new LocalServerSignDto(true);
    }
};
