export class AnamnesticDataReferralDto {
    public operations: string | null = null;
    public smoking: string | null = null;
    public alcohol: string | null = null;
    public drugAllergy: string | null = null;
    public sterility: string | null = null;
    public sterilityMedicine: string | null = null;
    public bloodTransfusion: string | null = null;
    public contraception: string | null = null;
    public firstMenstruation: string | null = null;
    public menstruationStated: string | null = null;
    public menstruationLasts: number | null = null;
    public menstruationPeriod: number | null = null;
    public features: string | null = null;
}
