<template>
    <v-card>
        <v-card-title>Капитационно заплащане по обслужващ лекар</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" lg="6">
                    <report-date-picker v-model="filter" @input="getPatientServicedByReport" />
                </v-col>
                <v-col cols="12" lg="6">
                    <dropdown
                        v-model="filter.creditToEmployeeSeqNumber"
                        clearable
                        :items="hiredEmployees"
                        item-value="employeeSeqNumber"
                        item-text="uinNameAndSpecialty"
                        label="Филтрирай по лекар"
                        @input="getPatientServicedByReport"
                    />
                </v-col>
            </v-row>
            <data-table
                v-if="patientServicedByReport && patientServicedByReport.lines"
                :headers="tableHeaders"
                :items="patientServicedByReport.lines"
                :child-headers="childHeaders"
                item-key="nhifCode"
                disable-pagination
                show-expand
            >
                <template #actions>
                    <btn action="Export" @click="exportExcel" />
                    &nbsp;&nbsp;Всичко общо: {{ patientServicedByReport.total }} лв
                </template>
            </data-table>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DataTable from '@/component/Common/DataTable.vue';
    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import Dropdown from '@/component/Dropdown/Dropdown.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
    import { PatientServicedByReportDto } from '@/model/Query/Patient/PatientServicedBy/PatientServicedByReportDto';
    import { PatientServicedByCommand } from '@/model/Query/Patient/PatientServicedByCommand';
    import { doctorDeputyService } from '@/service/Employee/Deputy/DoctorDeputyService';
    import { patientServicedByService } from '@/service/Query/Patient/PatientServicedByService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { DataTable, Dropdown, ReportDatePicker }
    })
    export default class PatientServicedByReport extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private filter: PatientServicedByCommand = new PatientServicedByCommand();
        private patientServicedByReport: PatientServicedByReportDto = new PatientServicedByReportDto();
        private hiredEmployees: EmployeeTitleDto[] = [];

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'НЗОК код', value: 'nhifCode' },
                { text: 'Описание', value: 'description' },
                { text: 'Брой', value: 'count' },
                { text: 'Ед. цена', value: 'price' },
                { text: 'Общо', value: 'summary' }
            ];
        }

        private get childHeaders(): IDataTableHeader[] {
            return [
                { text: 'Идентификатор на пациента', value: 'patientIdentifier' },
                { text: 'Име на пациента', value: 'patientName' }
            ];
        }

        private async getPatientServicedByReport() {
            this.$loading.show();
            try {
                this.fillDoctorEmployee();
                const response = await patientServicedByService.generatePatientServicedByReport(this.filter);
                if (response) {
                    this.patientServicedByReport = response;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async exportExcel() {
            this.$loading.show();
            try {
                await patientServicedByService.exportExcelPatientServicedByReport(this.filter);
            } finally {
                this.$loading.hide();
            }
        }

        private fillDoctorEmployee() {
            this.filter.practiceId = this.practiceId;
            this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async loadEmployees() {
            this.hiredEmployees = await doctorDeputyService.getHiredDeputyDoctors(
                userContextCache.currentPracticeId ?? 0,
                userContextCache.currentDoctorEmployeeSeqNumber ?? 0
            );
            if (this.hiredEmployees?.length > 0) {
                this.hiredEmployees.push(this.getOwnerInfo());
            }
        }

        private getOwnerInfo() {
            const ownerEmployee = new EmployeeTitleDto();
            ownerEmployee.employeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
            ownerEmployee.doctorName = userContextCache.current?.doctorName ?? '';
            ownerEmployee.doctorUin = userContextCache.current?.doctorUin ?? '';
            ownerEmployee.specialtyName = userContextCache.current?.specialtyName ?? '';
            return ownerEmployee;
        }

        private async mounted() {
            this.filter.fillPreviousMonth();
            await this.getPatientServicedByReport();
            await this.loadEmployees();
        }
    }
</script>

<style scoped></style>
