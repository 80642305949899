import { plainToInstance } from 'class-transformer';

import { PracticeNhifContractDto } from '@/model/Practice/PracticeNhifContractDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const practiceNhifContractService = {
    async getPracticeNhifContract(id: number): Promise<PracticeNhifContractDto> {
        const response = await httpService.get(`/PracticeNhifContract/GetPracticeNhifContract/${id}`);
        return plainToInstance(PracticeNhifContractDto, response.data);
    },

    async getByPracticeId(id: number): Promise<PracticeNhifContractDto[]> {
        const response = await httpService.get<PracticeNhifContractDto[]>(
            `/PracticeNhifContract/GetByPracticeId/${id}`
        );
        return plainToInstance(PracticeNhifContractDto, response.data);
    },

    async createPracticeNhifContract(data: PracticeNhifContractDto): Promise<number> {
        const response = await httpService.post<number>('/PracticeNhifContract/CreatePracticeNhifContract', data);
        return response.data;
    },

    async updatePracticeNhifContract(data: PracticeNhifContractDto): Promise<void> {
        await httpService.put('/PracticeNhifContract/UpdatePracticeNhifContract/', data);
    }
};
