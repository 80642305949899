import { Report365RowBase } from './Report365RowBase';

export class Report365BedRowDto extends Report365RowBase {
    public countBedsEndYear: number | null = null;
    public countBedsAverage: number | null = null;
    public sickCountStartYear: number | null = null;
    public sickCountIncoming: number | null = null;
    public sickCountCameOut: number | null = null;
    public sickCountDead: number | null = null;
    public get sickCountEndYear(): number | null {
        return (
            (this.sickCountStartYear === null ? 0 : Number(this.sickCountStartYear)) +
            (this.sickCountIncoming === null ? 0 : Number(this.sickCountIncoming)) -
            (this.sickCountCameOut === null ? 0 : Number(this.sickCountCameOut)) -
            (this.sickCountDead === null ? 0 : Number(this.sickCountDead))
        );
    }

    public bedDay: number | null = null;
    public usability: number | null = null;
    public averageStay: number | null = null;
    public turnover: number | null = null;
    public lethality: number | null = null;
    public sickPassed: number | null = null;
}
