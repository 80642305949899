export class IncomingReferralDto {
    public visitId: number = 0;

    public referralTypeCode: string = '';
    public referralType: string = '';
    public referralSubtypeId: number = 0;
    public referralSubType: string = '';
    public referralSubtypeNhisCode: string = '';

    public practiceNumber: string = '';
    // Защо няма public nhifNumber: string = ''; ?
    public uin: string = '';
    public deputyUin: string = '';
    public specialtyNhifCode: string = '';

    public number: number | null = null;
    public issueDate: Date = new Date();
    public nrn: string = '';
    public xmlData: string | null = null;

    public pidTypeCode: string | null = null;
    public pidTypeName: string | null = null;
    public patientIdentifier: string | null = null;
    public patientName: string | null = null;
}
