import { InvoiceLineDto } from '@/model/Finance/Invoice/InvoiceLineDto';
import { InvoiceRecipientDto } from '@/model/Finance/Invoice/InvoiceRecipientDto';
import { PracticeEditDto } from '@/model/Practice/PracticeEditDto';

export class InvoiceDto {
    public id: number = 0;
    public practiceId: number = 0;
    public practice: PracticeEditDto = new PracticeEditDto();
    public invoiceDate: Date | null = null;
    public number: string | null = null;
    public taxDate: Date | null = null;
    public paymentDate: Date | null = null;
    public paymentMethodCode: string | null = null;
    public vatChargeTypeId: number | null = null;
    public invoiceTypeCode: string | null = null;
    public transactionPlace: string | null = null;
    public invoiceRecipient: InvoiceRecipientDto = new InvoiceRecipientDto();
    public invoiceLines: InvoiceLineDto[] = [];
    public linesToDelete: number[] = [];

    //Credit/Debit note
    public parentInvoiceId: number | null = null;
    public parentInvoiceNumber: string | null = null;
    public parentInvoiceDate: Date | null = null;
}
