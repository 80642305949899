<template>
    <v-autocomplete
        :value="value"
        :loading="loading"
        :items="items"
        :item-value="itemValue"
        :item-text="itemText"
        :label="label"
        :no-data-text="noDataOrFilterTooShort"
        :class="{ required }"
        :rules="required ? [$validator.required] : []"
        :search-input.sync="search"
        :name="'multiDropdown' + _uid"
        :clearable="!readonly"
        :readonly="readonly"
        :disabled="disabled"
        dense
        chips
        deletable-chips
        multiple
        no-filter
        append-icon="mdi-magnify"
        @input="onItemSelected"
        v-on="$listeners"
    >
        <!--
            Когато се подаде prop itemDropText, се налага съдържанието на падащия списък да се рендира ръчно.
            Не се получава съвсем като оригиналното (липсват checkbox-овете), но като цяло наподобява оригиналното.
        -->
        <template v-if="itemDropText" #item="{ item }">
            <v-list-item-content>{{ item[itemDropText] }}</v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IMultiSelectDropdownItem } from '@/model/Common/Dropdown/IMultiSelectDropdownItem';
    import type { IDropdownSearchService } from '@/service/Dropdown/IDropdownSearchService';

    @Component
    export default class MultiSelectDropdownSearch extends Vue {
        @Prop()
        private value!: number[] | string[];

        @Prop({ required: true })
        private service!: IDropdownSearchService<IMultiSelectDropdownItem>;

        @Prop({ default: 'id' })
        private itemValue!: string;

        @Prop({ default: 'name' })
        private itemText!: string;

        // Име на property(на елементите), което да се показва в падащия списък.
        // Позволява избраните елементи (itemText) да се показват ралично от падащите елементи (itemDropText).
        @Prop()
        private itemDropText!: string;

        @Prop()
        private label!: string;

        @Prop()
        private required!: boolean;

        @Prop()
        private readonly!: boolean;

        @Prop()
        private disabled!: boolean;

        @Prop({ default: [] })
        private args!: [];

        @Prop({ default: 2 })
        private minimumFilterLength!: number;

        private loading: boolean = false;
        private selectingItem: boolean = false;
        private items: IMultiSelectDropdownItem[] = [];
        private search: string = '';

        private mounted() {
            return this.initItemsForCurrentValues();
        }

        private async initItemsForCurrentValues() {
            if (this.value) {
                // В списъка с елементи се добавят само началните/новоприсвоените стойности.
                // Service-ът трябва да поддържа зареждане на масив от конкретни елементи, освен търсене по филтър.
                this.loading = true;
                try {
                    this.items = await this.service.getItemsById(this.value);
                } finally {
                    this.loading = false;
                }
            }
        }

        private onItemSelected() {
            // Потиска следващия onValueChanged, за да не изпрати заявка за търсене на току що избрания елемент.
            this.selectingItem = true;

            // Cъбитието input се emit-ва автоматично заради v-on="$listeners",
            // което forward-ва всички събития, включително изрично прихванатите, като @input.
            //this.$emit('input', newValues);
        }

        @Watch('value')
        private async onValueChanged() {
            if (this.selectingItem) {
                this.selectingItem = false;
                return;
            }

            await this.initItemsForCurrentValues();
        }

        @Watch('search')
        private async onSearch(filter: string) {
            if (filter) {
                this.loading = true;
                try {
                    const filters = [filter, ...this.args];
                    this.addSelectedToDropdownItems(await this.service.searchItems(filters as []));
                } finally {
                    this.loading = false;
                }
            }
        }

        private addSelectedToDropdownItems(newItems: IMultiSelectDropdownItem[]) {
            const orphanedItems: IMultiSelectDropdownItem[] = [];
            if (this.value) {
                for (const itemId of this.value) {
                    const newSelectedItem = newItems.find((it) => it.id === itemId || it.code === itemId);
                    if (!newSelectedItem) {
                        const oldSelectedItem = this.items.find((it) => it.id === itemId || it.code === itemId);
                        if (oldSelectedItem) {
                            orphanedItems.push(oldSelectedItem);
                        }
                    }
                }
            }
            this.items = orphanedItems.concat(newItems);
        }

        private get noDataOrFilterTooShort() {
            return this.filterIsLongEnough
                ? this.$t('dropdown.noDataFound')
                : `Въведете поне ${this.minimumFilterLength} символа`;
        }

        private get filterIsLongEnough() {
            return this.search && this.search.length >= this.minimumFilterLength;
        }
    }
</script>
