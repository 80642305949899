<template>
    <v-col cols="12">
        <dropdown-search
            :value="value"
            :service="drugService"
            item-text="tradeName"
            item-value="nhisId"
            label="Търсене по лекарство:"
            return-object
            required
            :minimum-filter-length="0"
            :args="args"
            :error-messages="required ? isFilled : ''"
            @input="$emit('input', ...arguments)"
        />
    </v-col>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DropdownSearch from '@/component/Dropdown/DropdownSearch.vue';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { nhisDrugService } from '@/service/Nomenclature/NhisDrugService';

    @Component({
        components: { DropdownSearch }
    })
    export default class NhisDrugPicker extends Vue {
        @Prop()
        value!: NhifDrugDto;

        @Prop()
        private required!: boolean;

        @Prop()
        formType!: string;

        @Prop({ default: () => new Date() })
        issueDate!: Date;

        private get isFilled() {
            return this.value?.nhisId ? '' : 'Задължително поле';
        }

        private get drugService() {
            return nhisDrugService;
        }

        private get args() {
            const filters = [this.formType, this.issueDate];
            return filters as [];
        }
    }
</script>
