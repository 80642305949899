<template>
    <div>
        <v-card>
            <v-card-title>Здравен район</v-card-title>
            <v-card-actions>
                <btn action="Back" :to="`/DevTool/RhifArea/${rhifId}`" />
                <btn action="Save" @click="createUpdateRhifArea" />
            </v-card-actions>
            <v-card-text>
                <v-form>
                    <text-field v-model="rhif.code" label="Код:" />
                    <text-field v-model="rhif.name" label="Наименование:" />
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { RhifAreaDto } from '@/model/Nomenclature/RhifAreaDto';
    import { rhifAreaService } from '@/service/Nomenclature/RhifAreaService';

    @Component
    export default class RhifAreaEdit extends Vue {
        @Prop()
        private id!: number;

        @Prop()
        private rhifId!: number;

        private rhif: RhifAreaDto = new RhifAreaDto();

        private async mounted() {
            this.$loading.show();
            if (this.id) {
                await this.getRhifArea(this.id);
            }
            this.$loading.hide();
        }

        private async getRhifArea(id: number) {
            try {
                const response = await rhifAreaService.getRhifAreaById(id);
                this.rhif = response.data;
            } finally {
                this.$loading.hide();
            }
        }

        private async createUpdateRhifArea() {
            if (!this.id) {
                this.rhif.rhifId = this.rhifId;
                await rhifAreaService.createRhifArea(this.rhif);
            } else {
                this.rhif.id = this.id;
                await rhifAreaService.updateRhifArea(this.rhif);
            }

            this.$router.push(`/DevTool/RhifArea/${this.rhifId}`);
        }
    }
</script>
