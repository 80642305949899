<template>
    <v-card>
        <v-card-title>Бързо добавяне на начални тарифи и услуги</v-card-title>
        <v-card-actions>
            <btn v-if="showSaveButton" action="Save" @click="save"></btn>
        </v-card-actions>
        <v-card-text>
            <alert class="ma-5" type="info" prominent>{{ alertInfoText }}</alert>
            <v-radio-group
                v-if="
                    practiceTariffsAndServicesInfo.practiceHasNhifFund &&
                    !practiceTariffsAndServicesInfo.isAddedNhifExamOffer &&
                    !practiceTariffsAndServicesInfo.isAddedRetiredExamOffer
                "
                v-model="practiceTariffsAndServicesInfo.selectedExamChoice"
                :label="`Варианти за добавяне на услуги за преглед по тарифа &quot;${
                    practiceTariffsAndServicesInfo.nhifTariffName ?? 'НЗОК'
                }&quot;`"
            >
                <v-radio :label="twoServicesExamText" value="twoServices"></v-radio>
                <v-radio :label="threeServicesText" value="threeServices"></v-radio>
                <v-radio :label="fiveServicesText" value="fiveServices"></v-radio>
                <v-radio :label="fourServicesText" value="fourServices"></v-radio>
            </v-radio-group>
        </v-card-text>
    </v-card>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { QuickAddInitialTariffsAndServicesDto } from '@/model/Practice/Service/QuickAddInitialTariffsAndServicesDto';
    import { notifierService } from '@/service/Infrastructure/NotifierService';
    import { quickAddInitialTariffsAndServicesService } from '@/service/Practice/Service/QuickAddInitialTariffsAndServicesService';

    @Component
    export default class QuickAddInitialTariffsAndServices extends Vue {
        @Prop()
        private practiceId!: number;

        private practiceTariffsAndServicesInfo: QuickAddInitialTariffsAndServicesDto =
            new QuickAddInitialTariffsAndServicesDto();

        private showSaveButton: boolean = false;
        private twoServicesExamText: string = `Две единични услуги: 'Преглед на осигурено лице' (2.90 лв.) и 'Преглед на пенсионер' (1.00 лв.)`;
        private threeServicesText: string = `Три единични услуги: 'Преглед' (0 лв.), 'Потр. такса осигурено лице' (2.90 лв.) и 'Потр. такса пенсионер' (1.00 лв.)`;
        private fourServicesText: string = `Четири услуги, две единични и две пакетни: 'Преглед' (0 лв.), 'Потр. такса' (2.90 лв.), 'Преглед ОЛ + такса' (общо 2.90 лв.) и 'Преглед пенсионер + такса' (общо 1.00 лв.)`;
        private fiveServicesText: string = `Пет услуги: горните три, групирани в още две пакетни услуги: 'Преглед + такса ОЛ' (общо 2.90 лв.) и 'Преглед + такса пенсионер' (общо 1.00 лв.)`;

        private get alertInfoText(): string {
            const alertTextStart = 'Ще бъдат добавени следните тарифи или услуги, ако липсват:\n';

            let alertDynamicInfoText = '';
            alertDynamicInfoText = this.generateAlertDynamicInfoText(alertDynamicInfoText);

            if (!this.practiceTariffsAndServicesInfo.isAddedMedicalProcedureOffer) {
                alertDynamicInfoText += `- Ще бъде добавена услуга "ЕКГ" от тип "Процедура" по тарифа "${
                    this.practiceTariffsAndServicesInfo.patientTariffName ?? 'Основна'
                }"\n`;
            }

            if (alertDynamicInfoText) {
                const alertEndText =
                    'Всяка една тарифа или услуга, която е добавена чрез този процес, може да бъде редактирана след това.';
                this.showSaveButton = true;
                return alertTextStart + alertDynamicInfoText + alertEndText;
            }
            this.showSaveButton = false;
            return 'Всички нужни начални тарифи и услуги са добавени.';
        }

        private generateAlertDynamicInfoText(alertDynamicInfoText: string) {
            let result = alertDynamicInfoText;
            if (!this.practiceTariffsAndServicesInfo.patientTariffName) {
                result += '- Ще бъде добавена тарифа "Основна"\n';
            }

            if (this.practiceTariffsAndServicesInfo.missingTariffsNames.length > 0) {
                result += `- Ще бъдат добавени тарифи: ${this.practiceTariffsAndServicesInfo.missingTariffsNames.join(
                    ', '
                )}\n`;
            }

            if (!this.practiceTariffsAndServicesInfo.isAddedPatientExamOffer) {
                const tariffName = this.practiceTariffsAndServicesInfo.patientTariffName
                    ? this.practiceTariffsAndServicesInfo.patientTariffName
                    : 'Основна';
                result += `- Ще бъде добавена услуга "Преглед" към "${tariffName}" тарифа\n`;
            }

            if (this.practiceTariffsAndServicesInfo.missingTariffExamsNames.length > 0) {
                for (const tariffName of this.practiceTariffsAndServicesInfo.missingTariffExamsNames) {
                    result += `- Ще бъде добавена услуга "Преглед" към "${tariffName}" тарифа\n`;
                }
            }

            if (this.practiceTariffsAndServicesInfo.selectedExamChoice) {
                result = this.getSelectedExamChoice(alertDynamicInfoText);
            }
            return result;
        }

        private getSelectedExamChoice(text: string) {
            let result = text;
            switch (this.practiceTariffsAndServicesInfo.selectedExamChoice) {
                case 'twoServices':
                    result += `- ${this.twoServicesExamText}\n`;
                    break;
                case 'threeServices':
                    result += `- ${this.threeServicesText}\n`;
                    break;
                case 'fiveServices':
                    result += `- ${this.fiveServicesText}\n`;
                    break;
                case 'fourServices':
                    result += `- ${this.fourServicesText}\n`;
                    break;
                default:
                    break;
            }
            return result;
        }

        private async mounted() {
            this.practiceTariffsAndServicesInfo =
                await quickAddInitialTariffsAndServicesService.getPracticeTariffsAndServicesInfo(this.practiceId);
        }

        private async save() {
            await quickAddInitialTariffsAndServicesService.saveInisialTariffsAndServices(
                this.practiceTariffsAndServicesInfo
            );
            notifierService.showSuccess('Успех', 'Данните са записани успешно');
            this.$router.push(`/Finance/Service/PriceList/${this.practiceId}`);
        }
    }
</script>
