<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <text-field
                    v-model="doctorUin"
                    required
                    :rules="[$validator.uin]"
                    label="УИН"
                    @input="searchDoctorEmployees"
                ></text-field>
            </v-col>
            <v-col cols="12" md="6">
                <text-field
                    v-if="doctorUinIsValid && !existing.doctorId"
                    v-model="existing.doctorName"
                    required
                    label="Име"
                ></text-field>
                <!-- Disabled вариант на полето за име. -->
                <text-field v-else v-model="existing.doctorName" disabled label="Име"></text-field>
            </v-col>
        </v-row>

        <div v-if="doctorIsRegisteredInPractice" class="mt-6">
            <v-radio-group v-model="selectedOldSpecialtyCode">
                <template #label>Лекарят вече е регистриран в практиката със следните специалности</template>
                <v-radio
                    v-for="de in existing.doctorEmployees"
                    :key="de.specialtyCode"
                    :disabled="de.employeeUserExists"
                    :value="de.specialtyCode"
                >
                    <template #label>
                        {{ de.specialtyName }}
                        <small v-if="de.employeeIsPracticeOwner">&nbsp; &nbsp;(собственик / админ)</small>
                        <span v-if="de.userIsPending">&nbsp;-&nbsp;очаква одобрение</span>
                    </template>
                </v-radio>
                <v-radio label="Добавяне на още една специалност" value=""></v-radio>
            </v-radio-group>
        </div>

        <v-row v-if="selectedOldSpecialtyCode === ''">
            <v-col cols="12" md="6">
                <dropdown
                    v-model="selectedNewSpecialtyCode"
                    :disabled="!doctorUinIsValid"
                    :items="freeSpecialties"
                    item-value="code"
                    label="Изберете специалност"
                    required
                ></dropdown>
            </v-col>
            <v-col cols="12" md="6">
                <v-checkbox
                    v-model="employeeIsPracticeOwner"
                    :disabled="!doctorUinIsValid"
                    label="Собственик / Админ"
                />
            </v-col>
        </v-row>

        <v-row align="center">
            <v-col cols="12" md="3">
                <btn action="Save" :disabled="!canSubmit" block @click="save" />
            </v-col>
            <v-col cols="12" md="9" class="text-right">
                <div v-if="canSubmit">
                    <v-icon color="primary">mdi-information-outline</v-icon> {{ saveExplanation }}
                </div>
                <div v-else class="text--disabled"><v-icon>mdi-information-outline</v-icon> {{ saveExplanation }}</div>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { EmployeeCreateCommand } from '@/model/Employee/EmployeeCreateCommand';
    import { ExistingDoctorEmployeesDto } from '@/model/Employee/ExistingDoctorEmployeesDto';
    import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';
    import { registerDoctorService } from '@/service/Employee/RegisterDoctorService';
    import { currentUser } from '@/store/User/CurrentUser';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class DoctorEmployeeCreate extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private doctorUin: string = '';
        private existing: ExistingDoctorEmployeesDto = new ExistingDoctorEmployeesDto();
        private selectedOldSpecialtyCode: SpecialtyCode | '' = '';
        private selectedNewSpecialtyCode: SpecialtyCode | null = null;
        private employeeIsPracticeOwner: boolean = true;
        private freeSpecialties: SpecialtyDto[] = [];

        private async mounted() {
            await this.loadFreeSpecialties();
        }

        private async searchDoctorEmployees() {
            if (!this.doctorUinIsValid) {
                // Ако преди това УИН-ът е бил валиден и са били заредени данни за лекар, те се почистват.
                this.clearExistingDoctorInfoIfAny();
                return;
            }

            this.$loading.show();
            try {
                const doctorRegistration = await registerDoctorService.getDoctorRegistration(
                    this.practiceId,
                    this.doctorUin
                );
                if (doctorRegistration) {
                    // Съществуващ лекар.
                    this.existing = doctorRegistration;
                    if (this.doctorIsRegisteredInPractice) {
                        // Лекарят вече работи в избраната практика. Ако има специалност, която още не е свързана
                        // с текущия потеребител, тя се избира, т.е. предлага се за свързване.
                        // Ако всички специалности са свързани с текущия потребител, се предлага добавяне на нова специалност.
                        const unlinkedDoctorEmployee = doctorRegistration.doctorEmployees.find(
                            (de) => !de.employeeUserExists
                        );
                        if (unlinkedDoctorEmployee) {
                            this.selectedOldSpecialtyCode = unlinkedDoctorEmployee.specialtyCode ?? '';
                            this.selectedNewSpecialtyCode = null;
                        } else {
                            this.selectedOldSpecialtyCode = '';
                        }

                        // Ако някоя от специалностите е била owner, се предлага и новата да стане owner.
                        this.employeeIsPracticeOwner = doctorRegistration.anyEmployeeIsPracticeOwner;
                    } else {
                        // Лекарят още не работи в избраната практика.
                        this.selectedOldSpecialtyCode = '';

                        // TODO: Предлага се да стане owner. Може би не трябва, ако има друг owner.
                        this.employeeIsPracticeOwner = true;
                    }
                } else {
                    // Нов лекар. Ако са били заредени данни за съществуващ лекар, те се почистват.
                    this.clearExistingDoctorInfoIfAny();
                }
                await this.loadFreeSpecialties();
            } finally {
                this.$loading.hide();
            }
        }

        private clearExistingDoctorInfoIfAny() {
            if (this.existing.doctorId) {
                this.existing = new ExistingDoctorEmployeesDto();
                this.selectedOldSpecialtyCode = '';
            }
        }

        private async loadFreeSpecialties() {
            this.freeSpecialties = await registerDoctorService.getFreeSpecialties(
                this.practiceId,
                this.existing.doctorId
            );
        }

        private get doctorUinIsValid() {
            const doctorUinLength = 10;
            // Засега няма да поддържаме ЛПК на зъболекар, защото затруднява търсенето на УИН на лекар.
            //const dentistLpkLength = 9;
            //return this.doctorUin.length === doctorUinLength || this.doctorUin.length === dentistLpkLength;
            return this.doctorUin.length === doctorUinLength;
        }

        private get doctorIsRegisteredInPractice() {
            return this.existing.doctorEmployees && this.existing.doctorEmployees.length > 0;
        }

        private get canSubmit() {
            return Boolean(
                this.doctorUin &&
                    this.existing.doctorName &&
                    (this.selectedOldSpecialtyCode !== '' || this.selectedNewSpecialtyCode)
            );
        }

        private get saveExplanation() {
            const profile = currentUser.user?.profile;
            const userDescription = profile ? `${profile.name} (${profile.preferred_username})` : 'потребителя';
            return `Свързва ${userDescription} с избрания лекар и специалност`;
        }

        private async save() {
            const selectedSpecialtyCode = this.selectedOldSpecialtyCode || this.selectedNewSpecialtyCode;
            if (!selectedSpecialtyCode) {
                return;
            }
            this.$loading.show();
            try {
                const command = new EmployeeCreateCommand();
                command.practiceId = this.practiceId;
                command.doctorUin = this.doctorUin;
                command.doctorName = this.existing.doctorName;
                command.doctorEmployeeSpecialtyCode = selectedSpecialtyCode;
                command.employeeIsPracticeOwner = this.employeeIsPracticeOwner;
                await registerDoctorService.addDoctorEmployeeAndEmployeeUser(command);

                this.$notifier.showSuccess('', 'Заявката Ви е приета, но очаква одобрение.');
                // UserContext-ите се презареждат, но новосъздаденият не може да се избира - срещу него пише "очаква одобрение".
                // * Ако глобален админ е добавял ad-hoc UserContext-и през търсенето, те изчезват от списъка.
                await userContextCache.loadFromServer();

                // Екранът за регистриране се "затваря".
                this.$router.push('/');
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
