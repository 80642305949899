import { AxiosResponse } from 'axios';

import { UninsuredPatientExamDto } from '@/model/Query/Exam/UninsuredPatientExamDto';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const uninsuredPatientExamService = {
    getUninsuredPatientExams(filter: ReportIntervalFilterDto): Promise<AxiosResponse<UninsuredPatientExamDto[]>> {
        return httpService.post('UninsuredPatientExam/GetUninsuredPatientExams', filter);
    },

    exportUninsuredPatientExams(filter: ReportIntervalFilterDto): Promise<void> {
        return httpService.download('/UninsuredPatientExam/ExportExcel', { params: filter });
    }
};
