import { AxiosResponse } from 'axios';

import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const specialtyService = {
    getSpecialties(): Promise<AxiosResponse<SpecialtyDto[]>> {
        return httpService.get('/Specialty/GetSpecialties');
    }
};
