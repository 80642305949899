import { AxiosResponse } from 'axios';

import { PatientDiagnosisCheckCommand } from '@/model/Exam/PatientDiagnosisCheckCommand';
import { ChildrenHealthRegisterDto } from '@/model/Register/ChildrenHealth/ChildrenHealthRegisterDto';
import { DispensaryRegisterDto } from '@/model/Register/Dispensary/DispensaryRegisterDto';
import { PregnancyRegisterDto } from '@/model/Register/Pregnancy/PregnancyRegisterDto';
import { PreventiveRegisterDto } from '@/model/Register/Preventive/PreventiveRegisterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const examDiagnosisService = {
    isNewFoundDisease(command: PatientDiagnosisCheckCommand): Promise<AxiosResponse<boolean>> {
        return httpService.post('ExamDiagnosis/IsNewFoundDisease', command);
    },

    checkForDispensary(command: PatientDiagnosisCheckCommand): Promise<AxiosResponse<DispensaryRegisterDto>> {
        return httpService.post('ExamDiagnosis/CheckForDispensary', command);
    },

    async checkForAvailableDispensaryRules(command: PatientDiagnosisCheckCommand): Promise<string[]> {
        const response = await httpService.post<string[]>('ExamDiagnosis/CheckForDispensaryRules', command);
        return response?.data ?? [];
    },

    checkForPregnancy(command: PatientDiagnosisCheckCommand): Promise<AxiosResponse<PregnancyRegisterDto>> {
        return httpService.post('ExamDiagnosis/CheckForPregnancy', command);
    },

    checkForChildrenHealth(command: PatientDiagnosisCheckCommand): Promise<AxiosResponse<ChildrenHealthRegisterDto>> {
        return httpService.post('ExamDiagnosis/CheckForChildrenHealth', command);
    },

    checkForPreventive(command: PatientDiagnosisCheckCommand): Promise<AxiosResponse<PreventiveRegisterDto>> {
        return httpService.post('ExamDiagnosis/CheckForPreventive', command);
    },

    checkForPreventionLimitations(command: PatientDiagnosisCheckCommand): Promise<AxiosResponse<string>> {
        return httpService.post(`ExamDiagnosis/CheckExamForPreventionLimitations`, command);
    },

    checkForDispensaryLimitations(command: PatientDiagnosisCheckCommand): Promise<AxiosResponse<string>> {
        return httpService.post(`ExamDiagnosis/CheckExamForDispensaryLimitations`, command);
    }
};
