import { IcdDto } from '@/model/Nomenclature/IcdDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const icd10Service = {
    async getAllIcd10Areas(): Promise<IcdDto[]> {
        const response = await httpService.get<IcdDto[]>('/Icd/GetAllIcd10Areas');
        return response.data;
    },

    async getIcd10SubAreasByAreaRange(areaRange: string): Promise<IcdDto[]> {
        const response = await httpService.get<IcdDto[]>(`/Icd/GetIcd10SubAreasByAreaRange?areaRange=${areaRange}`);
        return response.data;
    },

    async getIcd10sBySubAreaRange(subAreaRange: string): Promise<IcdDto[]> {
        const response = await httpService.get<IcdDto[]>(`/Icd/GetIcd10sBySubAreaRange?subAreaRange=${subAreaRange}`);
        return response.data;
    }
};
