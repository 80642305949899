import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { PhysiotherapyCardDto } from '@/model/Exam/PhysiotherapyCard/PhysiotherapyCardDto';
import { PhysiotherapyCardViewDto } from '@/model/Exam/PhysiotherapyCard/PhysiotherapyCardViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const physiotherapyCardService = {
    async getPhysiotherapyCard(id: number): Promise<PhysiotherapyCardDto> {
        const response = await httpService.get(`/PhysiotherapyCard/GetPhysiotherapyCard/${id}`);
        return plainToInstance(PhysiotherapyCardDto, response.data);
    },

    async getInfoByExamId(id: number): Promise<PhysiotherapyCardDto> {
        const response = await httpService.get(`/PhysiotherapyCard/GetInfoByExam/${id}`);
        return plainToInstance(PhysiotherapyCardDto, response.data);
    },

    getPhysiotherapyCardsByExam(examId: number): Promise<AxiosResponse<PhysiotherapyCardViewDto[]>> {
        return httpService.get('/PhysiotherapyCard/GetByExam/', {
            params: { examId }
        });
    },

    createPhysiotherapyCard(data: PhysiotherapyCardDto): Promise<AxiosResponse<number>> {
        return httpService.post('/PhysiotherapyCard/CreatePhysiotherapyCard/', data);
    },

    updatePhysiotherapyCard(data: PhysiotherapyCardDto): Promise<AxiosResponse<void>> {
        return httpService.put('/PhysiotherapyCard/UpdatePhysiotherapyCard/', data);
    },

    deletePhysiotherapyCard(id: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/PhysiotherapyCard/DeletePhysiotherapyCard/${id}`);
    }
};
