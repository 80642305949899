<template>
    <v-card>
        <v-card-title>Диспансеризации при друг</v-card-title>
        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn v-if="$allowWrite" action="Save" @click="createOrUpdateDispensaryExternal" />
            <v-spacer />
            <btn v-if="$allowWrite && seqNumber" action="Delete" @click="deleteDispensaryExternal" />
        </v-card-actions>
        <v-card-text>
            <doctor-picker v-model="selectedDoctor" class="pb-3" label="Търсене на лекар"></doctor-picker>
            <v-form ref="form" v-model="isFormValid">
                <v-row>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model="dispensaryExternal.doctorInfo.uin"
                            :rules="[$validator.uin]"
                            :disabled="isSelectedDoctor"
                            required
                            label="УИН"
                            @input="searchDoctor"
                        ></text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model="dispensaryExternal.doctorInfo.name"
                            :disabled="isSelectedDoctor"
                            required
                            label="Име"
                        ></text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <dropdown
                            v-model="dispensaryExternal.doctorSpecialtyCode"
                            :items="specialties"
                            item-value="code"
                            label="Изберете специалност"
                            required
                        ></dropdown>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <DispensaryData
            v-if="!dispensaryExternal.register.dispensaryRuleId"
            :value="dispensaryExternal.register"
            :specialty-code="dispensaryExternal.doctorSpecialtyCode"
        />
        <template v-else>
            <v-card-title>Данни за диспансеризация</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <text-field v-model="dispensaryExternal.register.icdCode" disabled label="МКБ" />
                    </v-col>
                    <v-col cols="7">
                        <text-field v-model="dispensaryExternal.register.dispensaryRuleName" disabled label="Правило" />
                    </v-col>
                    <v-col cols="auto">
                        <btn icon action="Edit" @click="dispensaryExternal.register.dispensaryRuleId = 0">
                            Редактиране на диагнозата
                        </btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </template>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="4">
                    <date-picker
                        v-model="dispensaryExternal.register.registerDate"
                        label="Дата на регистрация"
                        required
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <date-picker v-model="dispensaryExternal.register.deregisterDate" label="Дата на отписване" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import DoctorPicker from '@/component/Picker/DoctorPicker.vue';
    import DispensaryData from '@/component/Register/DispensaryData.vue';
    import { DoctorDropdownDto } from '@/model/Doctor/DoctorDropdownDto';
    import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';
    import { DispensaryExternalEditDto } from '@/model/Patient/Dispensary/DispensaryExternalEditDto';
    import { DispensaryRuleDto } from '@/model/Register/Dispensary/DispensaryRuleDto';
    import { doctorService } from '@/service/Doctor/DoctorService';
    import { specialtyService } from '@/service/Nomenclature/SpecialtyService';
    import { dispensaryExternalService } from '@/service/Register/DispensaryExternalService';

    @Component({
        components: { DispensaryData, DoctorPicker }
    })
    export default class DispensaryExternalEdit extends Vue {
        @Prop({ required: true })
        private patientId!: number;

        @Prop()
        private seqNumber!: number;

        private specialties: SpecialtyDto[] = [];
        private dispensaryRules: DispensaryRuleDto[] = [];
        private isFormValid: boolean = true;
        private dispensaryExternal: DispensaryExternalEditDto = new DispensaryExternalEditDto();
        private selectedDoctor: DoctorDropdownDto | null = null;
        private isSelectedDoctor: boolean = false;

        private get backRoute() {
            return `/Patient/Dispensary/${this.patientId}`;
        }

        private async mounted() {
            this.specialties = (await specialtyService.getSpecialties()).data;
            if (this.seqNumber) {
                this.dispensaryExternal = await dispensaryExternalService.getDispensaryExternal(
                    this.patientId,
                    this.seqNumber
                );
            }
        }

        private async searchDoctor() {
            const doctorUinLength = 10;
            if (this.dispensaryExternal.doctorInfo.uin.length === doctorUinLength) {
                this.dispensaryExternal.doctorInfo = await doctorService.getDoctorByUin(
                    this.dispensaryExternal.doctorInfo.uin
                );
            } else {
                this.dispensaryExternal.doctorInfo.name = '';
            }
        }

        private async createOrUpdateDispensaryExternal() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();

            if (this.isFormValid) {
                if (!this.dispensaryExternal.register.dispensaryRuleId) {
                    this.$notifier.showWarning('', 'Моля въведете данни за диспансеризация');
                    return;
                }
                if (this.seqNumber) {
                    dispensaryExternalService.updateDispensaryExternal(this.dispensaryExternal);
                    this.$notifier.showSuccess('Успех', 'Данните са записани успешно');
                } else {
                    this.dispensaryExternal.register.patientId = this.patientId;
                    await dispensaryExternalService.createDispensaryExternal(this.dispensaryExternal);
                    this.$router.push(this.backRoute);
                }
            }
        }

        private async deleteDispensaryExternal() {
            if (this.seqNumber) {
                this.$loading.show();
                try {
                    await dispensaryExternalService.deleteDispensaryExternal(this.patientId, this.seqNumber);
                } finally {
                    this.$loading.hide();
                }
                this.$router.push(this.backRoute);
            }
        }

        @Watch('selectedDoctor')
        private onSelectedDoctorChanged() {
            if (this.selectedDoctor) {
                this.dispensaryExternal.doctorInfo.uin = this.selectedDoctor.uin;
                this.dispensaryExternal.doctorInfo.name = this.selectedDoctor.name;
                this.isSelectedDoctor = true;
            } else {
                this.isSelectedDoctor = false;
            }
        }
    }
</script>
