import { AxiosResponse } from 'axios';

import { AccessTokenDto } from '@/model/Nhis/AccessToken/AccessTokenDto';
import { AccessTokenRequestDto } from '@/model/Nhis/AccessToken/AccessTokenRequestDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhisNhifAccessTokenService = {
    getAccessTokenChallenge(tokenTypeCode: string): Promise<AxiosResponse<string>> {
        return httpService.get(`/${tokenTypeCode}AccessToken/GetAccessTokenChallenge`);
    },

    getAccessToken(tokenTypeCode: string, challengeXml: string): Promise<AxiosResponse<AccessTokenDto>> {
        return httpService.post(
            `/${tokenTypeCode}AccessToken/GetAccessToken/`,
            new AccessTokenRequestDto(challengeXml)
        );
    }
};
