import { Type } from 'class-transformer';

import { Report365PreventiveActivityRowDto } from '@/model/Report/Rhi/Report365/Report365PreventiveActivityRowDto';

export class Report365PreventiveActivityDto {
    public name: string = '';

    @Type(() => Report365PreventiveActivityRowDto)
    public preventiveActivities: Report365PreventiveActivityRowDto[] = [];
}
