<template>
    <v-card>
        <v-card-title>Шаблон</v-card-title>
        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn action="Save" @click="updateTemplate" />
        </v-card-actions>
        <v-card-text>
            <v-form ref="documentForm" v-model="isValid">
                <v-select
                    v-model="template.templateTypeCode"
                    :items="templateTypes"
                    item-text="name"
                    item-value="code"
                    label="Категория"
                    :rules="[$validator.required]"
                />
                <text-field v-model="template.icdFilter" label="МКБ Филтър" />
                <v-textarea v-model="template.templateText" label="Текст" />
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { TemplateTypeDto } from '@/model/Nomenclature/TemplateTypeDto';
    import { TemplateDto } from '@/model/Setting/Template/TemplateDto';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { templateTypeService } from '@/service/Nomenclature/TemplateTypeService';
    import { templateService } from '@/service/Setting/TemplateService';

    @Component
    export default class TemplateEdit extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        // Подава се при създаване на шаблон.
        @Prop()
        private doctorEmployeeSeqNumber!: number;

        // Подава се при редактиране на шаблон.
        @Prop()
        private id!: number;

        private isValid: boolean = false;
        private templateTypes: TemplateTypeDto[] = [];
        private template: TemplateDto = new TemplateDto();

        private async mounted() {
            this.$loading.show();
            try {
                await this.loadTemplateTypes();

                if (this.id) {
                    const response = await templateService.getTemplate(this.practiceId, this.id);
                    this.template = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async loadTemplateTypes() {
            const response = await templateTypeService.getTemplateTypes();
            this.templateTypes = response.data;
        }

        private async updateTemplate() {
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();
            if (this.isValid) {
                if (!this.id) {
                    this.template.practiceId = this.practiceId;
                    this.template.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
                    await templateService.createTemplate(this.template);
                    eventBus.$emit('create-template-event', this.template.id, 'StagedEpicrisis');
                } else {
                    await templateService.updateTemplate(this.template);
                    eventBus.$emit('update-template-event', this.template.id, 'StagedEpicrisis');
                }

                this.$router.push(this.backRoute);
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private get backRoute() {
            const deSeqNumber = this.doctorEmployeeSeqNumber ?? this.template.doctorEmployeeSeqNumber;
            return `/Setting/Template/List/${this.practiceId}/${deSeqNumber}`;
        }
    }
</script>
