<template>
    <data-table :items="items" :headers="headers" title="Справка със суми за изследвания">
        <template #actions>
            <v-row>
                <v-col cols="9">
                    <report-date-picker v-model="command" @input="getReferral4AmountsReport" />
                </v-col>
                <v-col cols="6" md="3">
                    <btn action="Export" block @click="exportExcel" />
                    &nbsp;&nbsp;Всичко общо: {{ totalAmount }} лв
                </v-col>
            </v-row>
        </template>
    </data-table>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { prescriptionNhifConfig as config } from '@/model/Exam/Prescription/PrescriptionNhifConfig';
    import { Referral4AmountsReportDto } from '@/model/Query/Exam/Referral4AmountsReportDto';
    import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
    import { referral4AmountsReportService } from '@/service/Query/Exam/Referral4AmountsReportService';

    @Component({
        components: {
            ReportDatePicker
        }
    })
    export default class Referral4AmountsReport extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private command: ReportIntervalFilterDto = new ReportIntervalFilterDto();
        private items: Referral4AmountsReportDto[] = [];

        private get totalAmount() {
            return this.items.reduce((acc, item) => acc + item.totalAmount, 0).toFixed(config.currencyPrecision);
        }

        private headers: IDataTableHeader[] = [
            { text: 'Код', value: 'code' },
            { text: 'Име', value: 'name' },
            { text: 'Ед. цена', value: 'singlePrice' },
            { text: 'Брой', value: 'count' },
            { text: 'Общо', value: 'totalAmount' }
        ];

        private async mounted() {
            this.command.fillCurrentMonth();
            this.command.practiceId = this.practiceId;
            this.command.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
            await this.getReferral4AmountsReport();
        }

        private async getReferral4AmountsReport() {
            this.$loading.show();
            this.items = await referral4AmountsReportService.getReferral4AmountsReport(this.command);
            this.$loading.hide();
        }

        private exportExcel() {
            this.$loading.show();
            referral4AmountsReportService.exportExcelReferral4AmountsReport(this.command);
            this.$loading.hide();
        }
    }
</script>
