export class DrugProtocolDrug {
    public id: number = 0;
    public position: number = 0;
    public name: string = '';
    public nhifId: number | null = null;
    public nhifCode: string = '';
    public quantityPerDay: string | null = null;
    public quantityPerWeek: string | null = null;
    public quantityPerMonth: string | null = null;
    public sessionDays: number | null = null;
    public protocolTypeCode: string | null = null;
}
