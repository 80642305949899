export class RequestForImagingTestDto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date | null = null;
    public specialInstructions: string = '';
    public probableIcd: string = '';
    public lastMenstruation: Date | null = null;
    public labTestsResult: string = '';
    public requestFor: string = '';
    public department: string = '';
    public departmentCityId: number = 1;
}
