<template>
    <v-card v-if="isGlobalAdmin">
        <v-card-title>Справка от журнала</v-card-title>
        <v-card-text>
            <v-checkbox
                v-model="loadExceptions"
                dense
                label="Показване на сървърните unhandled exceptions"
            ></v-checkbox>
            <btn action="New" class="mb-3" @click="addAuditRecord">Добавяне на демо запис</btn>
            <v-data-table :headers="headers" :items="auditRecords" :loading="$loading.isVisible">
                <template #[`item.dateTime`]="{ item }">
                    <date-label v-model="item.dateTime" show-time />
                </template>
                <template #[`item.stackTrace`]="{ item }">
                    <div class="stack-trace">
                        <small>
                            <pre>{{ item.stackTrace }}</pre>
                        </small>
                    </div>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator';

    import DateLabel from '@/component/Date/DateLabel.vue';
    import { AuditReportDto } from '@/model/Audit/AuditReportDto';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { httpService } from '@/service/Infrastructure/HttpService';
    import { currentUser } from '@/store/User/CurrentUser';

    @Component({ components: { DateLabel } })
    export default class Audit extends Vue {
        private loadExceptions: boolean = false;
        private auditRecords: AuditReportDto[] = [];
        private headers: IDataTableHeader[] = [
            { text: 'Date and time', value: 'dateTime' },
            { text: 'IP Address', value: 'ipAddress' },
            { text: 'Error message', value: 'errorMessage' },
            { text: 'Stack trace', value: 'stackTrace' }
        ];

        private get isGlobalAdmin() {
            return currentUser.isGlobalAdmin;
        }

        private getColor(ipAddress: string) {
            // TypeScript error check test.
            //const n: number = 'text';
            if (ipAddress === '::1') {
                return 'blue';
            }
            if (ipAddress.startsWith('192.168.')) {
                return 'green';
            }
            return 'red';
        }

        private async mounted() {
            await this.fetchAuditReport();
        }

        @Watch('loadExceptions')
        private onLoadExceptionsChanged() {
            this.fetchAuditReport();
        }

        private async addAuditRecord() {
            if (currentUser.isGlobalAdmin) {
                await httpService.get(`AuditDemo/LogDemoMessage?isError=${this.loadExceptions}`);
                this.fetchAuditReport();
            }
        }

        private async fetchAuditReport() {
            if (currentUser.isGlobalAdmin) {
                this.$loading.show();
                try {
                    const response = await httpService.get<AuditReportDto[]>(
                        this.loadExceptions ? 'AuditReport/Errors' : 'AuditReport/Audit'
                    );
                    this.auditRecords = response.data;
                } finally {
                    this.$loading.hide();
                }
            }
        }
    }
</script>

<style scoped>
    .stack-trace {
        max-width: 500px;
        max-height: 200px;
        overflow: auto;
    }
</style>
