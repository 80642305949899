export enum NhifProtocolStatusCode {
    Approved = 'Approved',
    AwaitingDocuments = 'AwaitingDocuments',
    Canceled = 'Canceled',
    Considered = 'Considered',
    WaitingDocuments = 'WaitingDocuments',
    Denied = 'Denied',
    Submitted = 'Submitted',
    Finished = 'Finished',
    Rejected = 'Rejected',
    Terminated = 'Terminated',
    Invalid = 'Invalid'
}
