<template>
    <v-card-text>
        <v-row>
            <v-col cols="12">
                <v-textarea v-model="value.operations" label="Минали заболявания и операции:" dense />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <text-field v-model="value.smoking" label="Тютюнопушене: не, да - колко:" />
            </v-col>
            <v-col cols="12" md="6">
                <text-field v-model="value.alcohol" label="Алкохол: не, да - колко:" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <text-field v-model="value.drugAllergy" label="Непоносимост към лекарства: не, да - какви:" />
            </v-col>
            <v-col cols="12">
                <text-field v-model="value.sterility" label="Лекувана за стерилитет или инфертилитет: не, да - къде" />
            </v-col>
            <v-col cols="12">
                <text-field v-model="value.sterilityDiagnosis" label="с какво:" />
            </v-col>
            <v-col cols="12">
                <text-field v-model="value.bloodTransfusion" label="Кръвопреливания: не, да - кога:" />
            </v-col>
            <v-col cols="12">
                <text-field v-model="value.contraception" label="Предхождаща антиконтрацепция: не, да - с какво:" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="3">
                <text-field v-model="value.firstMenstruation" type="number" label="Първа менструация на (год.):" />
            </v-col>
            <v-col cols="6" md="3">
                <text-field v-model="value.menstruationStated" label="Установила се:" />
            </v-col>
            <v-col cols="6" md="3">
                <text-field v-model="value.menstruationLasts" label="Трае:" />
            </v-col>
            <v-col cols="6" md="3">
                <text-field v-model="value.menstruationPeriod" label="През (дни):" />
            </v-col>
            <v-col cols="12">
                <text-field v-model="value.features" label="Особености:" />
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { AnamnesticDataReferralDto } from '@/model/Exam/Referral/PregnancyReferral/AnamnesticDataReferralDto';

    @Component
    export default class AnamnesticData extends Vue {
        @Prop()
        private value!: AnamnesticDataReferralDto;
    }
</script>
