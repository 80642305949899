<template>
    <v-text-field
        ref="myText"
        v-model="value"
        dense
        :label="label"
        :hint="hint"
        :persistent-hint="!!hint"
        :suffix="suffix"
        :required="required"
        :rules="getRules"
        :class="required ? 'required' : ''"
        append-icon="mdi-magnify"
        clearable
        @input="input"
        @click:append="search"
    >
    </v-text-field>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IValidator } from '@/plugin/Validator/IValidator';

    @Component({
        name: 'search-field'
    })
    export default class TestField extends Vue {
        @Prop()
        public hint!: string;

        @Prop()
        private label!: string;

        @Prop()
        private required!: boolean;

        @Prop()
        private rules!: IValidator[];

        @Prop()
        private suffix!: string;

        @Prop()
        private value!: string;

        private input() {
            this.$emit('input', this.value);
        }

        private search() {
            this.$emit('search', this.value);
        }

        private get getRules() {
            const rls = this.rules ? this.rules : [];
            const requiredValidator = this.required ? [this.$validator.required] : [];

            return [...rls, ...requiredValidator];
        }
    }
</script>
