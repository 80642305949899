import _Vue from 'vue';

import { validatorService } from '@/service/Infrastructure/ValidatorService';

import { IValidator } from './IValidator';

declare module 'vue/types/vue' {
    interface Vue {
        $validator: IValidator;
    }
}

export const validatorPlugin = (Vue: typeof _Vue) => {
    Vue.prototype.$validator = validatorService;
};
