<template>
    <ExamLayout
        :can-save="!disableEdit"
        :can-delete="!disableEdit && document.key.prescriptionId > 0"
        :can-print="document.key.prescriptionId > 0"
        title="Рецептурна бланка - бл.МЗ-НЗОК №5"
        help-action="Form5"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <nhis-prescription
            v-if="document.key.prescriptionId > 0 && document.nhisPrescription"
            v-model="document.nhisPrescription"
            :prescription-key="prescriptionKey"
            @input="copyObject"
            @reload-prescription="load"
        />
        <validation-alert
            :nhif-drugs="[document.nhifDrug, document.nhifDrug2, document.nhifDrug3]"
            :exam-diagnosis="[examDiagnosis1, examDiagnosis2, examDiagnosis3]"
            :protocol-type-code="document.protocolTypeCode"
            :issue-date="document.issueDate"
            :exam-id="document.key.examId"
        />
        <v-card class="mb-md-4" :disabled="disableEdit" :style="`background-color: ${backgroundColor}`">
            <main-title>Рецептурна бланка - бл.МЗ-НЗОК №5</main-title>

            <v-card-text>
                <v-row>
                    <v-col xs="6" md="3" cols="6"><text-field disabled label="Рецепта №(аптека)" /></v-col>
                    <v-col xs="6" md="3" cols="6"><text-field disabled label="Изпълнила аптека №" /></v-col>
                    <v-col xs="12" cols="12" md="3">
                        <dropdown
                            v-model="document.protocolTypeCode"
                            :items="prescriptionProtocolTypes"
                            item-text="code"
                            item-value="code"
                            required
                            label="Начин на предписание"
                            @input="clearAllDrugs"
                        />
                    </v-col>
                </v-row>

                <v-row v-if="document.protocolTypeCode !== 'Без протокол'">
                    <v-col xs="6" md="3" cols="6"
                        ><text-field v-model="document.protocolNumber" required label="Протокол №"
                    /></v-col>
                    <v-col xs="6" md="3" cols="6"
                        ><date-picker v-model="document.protocolDateTime" required label="Протокол Дата:"
                    /></v-col>
                    <v-col xs="6" md="3" cols="6">
                        <text-field v-model="document.protocolDays" required label="Валидност (дни):"
                    /></v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <AmbulatorySheetHeaderInfoCard v-once :background-color="backgroundColor" />
            </v-card-text>
        </v-card>

        <prescription-info
            v-model="document"
            :exam-diagnoses="examDiagnoses"
            :disable-edit="disableEdit"
            :background-color="backgroundColor"
            @reload-diagnoses="getDiagnoses"
        />

        <v-card class="mb-4" :disabled="disableEdit" :style="`background-color: ${backgroundColor}`">
            <v-card-actions v-if="document.nhifDrug && !disableEdit" class="py-0">
                <v-spacer />
                <btn action="Delete" @click="clearAllDrugs">Изчистване</btn>
                <btn action="Refresh" @click="reorderDrugs">Пренареждане</btn>
            </v-card-actions>
            <v-card-text class="no-margin-no-padding">
                <DrugComponent
                    v-model="document.nhifDrug"
                    :exam-diagnosis="examDiagnosis1"
                    :protocol-type-code="document.protocolTypeCode"
                    :exam-id="document.key.examId"
                    :position="1"
                    :form-type="document.formTypeCode"
                    :exam-diagnoses="examDiagnoses"
                    required-drug
                    :days-summit="calculateAccumulation(document.nhifDrug)"
                    :background-color="backgroundColor"
                    :advertisements="advertisements[examDiagnosis1?.icdCode ?? '']"
                    @update-nhif="updateNhif"
                    @exam-diagnosis-selected="onExamDiagnosisSelected"
                />

                <DrugComponent
                    v-model="document.nhifDrug2"
                    :exam-diagnosis="examDiagnosis2"
                    :protocol-type-code="document.protocolTypeCode"
                    :exam-id="document.key.examId"
                    :position="2"
                    :form-type="document.formTypeCode"
                    :exam-diagnoses="examDiagnoses"
                    :days-summit="calculateAccumulation(document.nhifDrug2)"
                    :background-color="backgroundColor"
                    :advertisements="advertisements[examDiagnosis2?.icdCode ?? '']"
                    @update-nhif="updateNhif"
                    @exam-diagnosis-selected="onExamDiagnosisSelected"
                />

                <DrugComponent
                    v-model="document.nhifDrug3"
                    :exam-diagnosis="examDiagnosis3"
                    :protocol-type-code="document.protocolTypeCode"
                    :exam-id="document.key.examId"
                    :position="3"
                    :form-type="document.formTypeCode"
                    :exam-diagnoses="examDiagnoses"
                    :days-summit="calculateAccumulation(document.nhifDrug3)"
                    :background-color="backgroundColor"
                    :advertisements="advertisements[examDiagnosis3?.icdCode ?? '']"
                    @update-nhif="updateNhif"
                    @exam-diagnosis-selected="onExamDiagnosisSelected"
                />
                <!--Всичко, което трябва да плати пациента-->
                <v-col md="3" offset-md="9">
                    <text-field
                        v-model.number="document.totalCostPatient"
                        label="Всичко:"
                        disabled
                        suffix="лв."
                        :value="totalCostPatient(document.nhifDrug, document.nhifDrug2, document.nhifDrug3)"
                    />
                </v-col>
                <v-row>
                    <v-col md="2"><v-checkbox v-model="document.isPregnant" label="Бременна"></v-checkbox></v-col>
                    <v-col md="2"><v-checkbox v-model="document.isNursing" label="Кърмачка"></v-checkbox></v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PrescriptionInfo from '@/component/Exam/PrescriptionInfo.vue';
    import { AdvertisementTypeCode } from '@/enum/AdvertisementTypeCode';
    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { AdvertisementDto } from '@/model/Advertisement/AdvertisementDto';
    import { InfoByExamFilter } from '@/model/Exam/InfoByExamFilter';
    import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
    import { prescriptionNhifConfig as config } from '@/model/Exam/Prescription/PrescriptionNhifConfig';
    import { PrescriptionNhifDto } from '@/model/Exam/Prescription/PrescriptionNhifDto';
    import {
        PrescriptionProtocolType,
        prescriptionProtocolTypes
    } from '@/model/Exam/Prescription/PrescriptionProtocolType';
    import { PrescriptionDiagnosisDto } from '@/model/Exam/PrescriptionDiagnosisDto';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { advertisementService } from '@/service/AdvertisementService';
    import { prescriptionService } from '@/service/Exam/PrescriptionService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';
    import AmbulatorySheetHeaderInfoCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetHeaderInfoCard.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';
    import NhisPrescription from '@/view/Nhis/Prescription/NhisPrescription.vue';

    import DrugComponent from './DrugComponent.vue';
    import ValidationAlert from './PrescriptionValidationAlert.vue';

    @Component({
        components: {
            AmbulatorySheetHeaderInfoCard,
            UnsavedChanges,
            NhisPrescription,
            DrugComponent,
            ValidationAlert,
            ExamLayout,
            PrescriptionInfo
        },
        async beforeRouteLeave(to, from, next) {
            if (this?.$data?.leaveDialogRef) {
                await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
            } else {
                next();
            }
        }
    })
    export default class PrescriptionNhif extends Vue {
        @Ref() readonly leaveDialog!: UnsavedChanges;

        @Prop({ required: true })
        private prescriptionKey!: PrescriptionKey;

        private document: PrescriptionNhifDto = new PrescriptionNhifDto();
        private initialValue: PrescriptionNhifDto = new PrescriptionNhifDto();

        private prescriptionProtocolTypes: PrescriptionProtocolType[] = prescriptionProtocolTypes;
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private examDiagnoses: PrescriptionDiagnosisDto[] = [];
        private advertisements: { [key: string]: AdvertisementDto[] | null } = {};

        private updateNhif(event: { nhifDrug: NhifDrugDto; position: number }) {
            if (event.position === config.position1) {
                this.document.nhifDrug = event.nhifDrug;
            }
            if (event.position === config.position2) {
                this.document.nhifDrug2 = event.nhifDrug;
            }
            if (event.position === config.position3) {
                this.document.nhifDrug3 = event.nhifDrug;
            }
        }

        private get backgroundColor() {
            return !userSettingsState.userSettings.ui.hasReferralsBackground
                ? 'transparent'
                : userSettingsState.userSettings.ui.isDarkTheme
                  ? '#b08ba1'
                  : '#ffe5f4';
        }

        private get disableEdit() {
            return this.document?.nhisPrescription?.nrn?.length > 0;
        }

        private get examDiagnosis1() {
            return this.getPrescriptionDiagnosis(this.document.examDiagnosis1Id, this.document.isDiagnosis1Add);
        }

        private get examDiagnosis2() {
            return this.getPrescriptionDiagnosis(this.document.examDiagnosis2Id, this.document.isDiagnosis2Add);
        }

        private get examDiagnosis3() {
            return this.getPrescriptionDiagnosis(this.document.examDiagnosis3Id, this.document.isDiagnosis3Add);
        }

        private onExamDiagnosisSelected(event: { examDiagnosisId: number | null; position: number; isAdd: boolean }) {
            if (event.examDiagnosisId) {
                if (event.position === config.position1) {
                    this.document.examDiagnosis1Id = event.examDiagnosisId;
                    this.document.isDiagnosis1Add = event.isAdd;
                }
                if (event.position === config.position2) {
                    this.document.examDiagnosis2Id = event.examDiagnosisId;
                    this.document.isDiagnosis2Add = event.isAdd;
                }
                if (event.position === config.position3) {
                    this.document.examDiagnosis3Id = event.examDiagnosisId;
                    this.document.isDiagnosis3Add = event.isAdd;
                }
            }
        }

        private totalCostPatient(value: NhifDrugDto, value2: NhifDrugDto, value3: NhifDrugDto): number {
            const totalCost: number = Number(
                (
                    value?.maxPatientPrice ??
                    0 * value?.packageCount ??
                    0 + value2.maxPatientPrice * value2.packageCount + value3.maxPatientPrice * value3.packageCount
                ).toFixed(config.currencyPrecision)
            );

            this.document.totalCostPatient = totalCost;
            return totalCost;
        }

        private clearAllDrugs() {
            this.document.nhifDrug = null;
            this.document.nhifDrug2 = new NhifDrugDto();
            this.document.nhifDrug3 = new NhifDrugDto();
        }

        private reorderDrugs() {
            if (
                (this.document.nhifDrug === null || this.document.nhifDrug?.tradeName === '') &&
                this.document.nhifDrug3.tradeName !== '' &&
                this.document.nhifDrug2.tradeName === ''
            ) {
                this.document.nhifDrug = new NhifDrugDto();
                this.document.examDiagnosis1Id = this.document.examDiagnosis3Id;
                Object.assign(this.document.nhifDrug, this.document.nhifDrug3);
                this.document.nhifDrug3 = new NhifDrugDto();
            }
            if (
                this.document.nhifDrug2.tradeName !== '' &&
                (this.document.nhifDrug === null || this.document.nhifDrug?.tradeName === '')
            ) {
                this.document.nhifDrug = new NhifDrugDto();
                this.document.examDiagnosis1Id = this.document.examDiagnosis2Id;
                Object.assign(this.document.nhifDrug, this.document.nhifDrug2);
                this.document.nhifDrug2 = new NhifDrugDto();
            }

            if (this.document.nhifDrug3.tradeName !== '' && this.document.nhifDrug2.tradeName === '') {
                this.document.examDiagnosis2Id = this.document.examDiagnosis3Id;
                Object.assign(this.document.nhifDrug2, this.document.nhifDrug3);
                this.document.nhifDrug3 = new NhifDrugDto();
            }
        }

        private calculateAccumulation(nhifDrug: NhifDrugDto): number {
            let result = 0;
            if (this.collectAllNhifDrugs.length && nhifDrug) {
                for (const nd of this.collectAllNhifDrugs) {
                    if (nd && (nd?.nhifId ?? 0) > 1 && nd.nhifId === nhifDrug.nhifId) {
                        result += nd?.therapyDuration ?? 0;
                    }
                }
                return result;
            }
            return 0;
        }

        private get collectAllNhifDrugs(): NhifDrugDto[] {
            return [this.document.nhifDrug ?? new NhifDrugDto(), this.document.nhifDrug2, this.document.nhifDrug3];
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let { prescriptionId } = this.document.key;
                let title = '';
                if (prescriptionId === 0) {
                    this.document.formTypeCode = PrescriptionFormTypeCode.Nhif5;
                    const result = await prescriptionService.create(this.document);
                    prescriptionId = result.id;
                    title = this.createTitle();
                    eventBus.$emit('create-referral-event', prescriptionId, 'PrescriptionNhif', title);
                    this.copyObject();
                    this.$router.push(`/Exam/PrescriptionNhif/Edit/${this.prescriptionKey.examId}/${prescriptionId}`);
                } else {
                    await prescriptionService.update(this.document);
                    title = this.createTitle();
                    eventBus.$emit('update-referral-event', title);
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private createTitle() {
            return `${this.document?.nhifDrug?.nhifCode ?? ''}${this.document?.nhifDrug2?.nhifCode ?? ''}${
                this.document?.nhifDrug3?.nhifCode ?? ''
            }`;
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                await prescriptionService.delete(this.prescriptionKey);
                this.copyObject();
                this.$router.push(`/Exam/PrescriptionNhif/Create/${this.prescriptionKey.examId}`);
                eventBus.$emit(
                    'delete-referral-event',
                    this.prescriptionKey.prescriptionId,
                    'PrescriptionNhif',
                    `Бланка 5 - №${this.document.prescriptionNumber}`
                );
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.prescriptionKey.prescriptionId && this.document.nhisPrescription.nrn === null) {
                this.$router.push(
                    `/Print/Exam.Prescription.PrescriptionNhif/Prescription/${this.prescriptionKey.examId}/${this.prescriptionKey.prescriptionId}`
                );
            } else if (this.document && this.document.nhisPrescription.nrn !== '') {
                this.$router.push(
                    `/Print/Exam.Prescription.PrescriptionNhifTherapeuticCourse/Prescription/${this.prescriptionKey.examId}/${this.prescriptionKey.prescriptionId}`
                );
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                let report = '';
                const parameters: { [key: string]: string } = {};
                parameters.examId = this.prescriptionKey.examId.toString();
                parameters.prescriptionId = this.prescriptionKey.prescriptionId.toString();
                if (this.prescriptionKey.prescriptionId && this.document.nhisPrescription.nrn === null) {
                    report = 'Exam.Prescription.PrescriptionNhif';
                } else if (this.document && this.document.nhisPrescription.nrn !== '') {
                    report = 'Exam.Prescription.PrescriptionNhifTherapeuticCourse';
                }

                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                await currentVisitCache.load(this.prescriptionKey.examId);
                await this.getDiagnoses();
                if (!this.prescriptionKey.prescriptionId) {
                    this.document = await prescriptionService.getPrescriptionInfoByExamId(
                        new InfoByExamFilter(
                            this.prescriptionKey.examId,
                            currentVisitCache.value.startDateTime,
                            PrescriptionFormTypeCode.Nhif5
                        )
                    );
                } else {
                    this.document = await prescriptionService.getPrescription(this.prescriptionKey);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async getDiagnoses() {
            const examCache = currentVisitCache.value?.exam;
            if (examCache) {
                for (const diagnosis of examCache.examDiagnoses) {
                    this.examDiagnoses.push(
                        this.createExamDiagnosis(diagnosis.id ?? 0, diagnosis.icdCode, diagnosis.icdName, false)
                    );
                    if (diagnosis.icdCodeAdd) {
                        this.examDiagnoses.push(
                            this.createExamDiagnosis(
                                diagnosis.id ?? 0,
                                diagnosis.icdCodeAdd,
                                diagnosis.icdNameAdd ?? '',
                                true
                            )
                        );
                    }
                }
            }
            await this.getAdvertisements();
        }

        private async getAdvertisements() {
            const { current } = userContextCache;
            if (current) {
                /* eslint-disable no-await-in-loop */
                for (const diagnosis of this.examDiagnoses) {
                    const { icdCode } = diagnosis;
                    this.advertisements[icdCode] = await advertisementService.getValidAdvertisements({
                        practiceId: current.practiceId,
                        advertisementTypeCode: AdvertisementTypeCode.Prescription,
                        icdCode
                    });
                }
                /* eslint-enable no-await-in-loop */
            }
        }

        @Watch('currentVisitCache')
        async onCurrentVisitCacheChange() {
            await this.getDiagnoses();
        }

        private createExamDiagnosis(id: number, icdCode: string, icdName: string, isAdd: boolean) {
            const diagnosis = new PrescriptionDiagnosisDto();
            diagnosis.id = id;
            diagnosis.icdCode = icdCode;
            diagnosis.icdName = icdName;
            diagnosis.isAdd = isAdd;
            return diagnosis;
        }

        public getPrescriptionDiagnosis(
            examDiagnosisId: number | null,
            isAdd: boolean
        ): PrescriptionDiagnosisDto | null {
            return (
                this.examDiagnoses.find(
                    (examDiagnosis) => examDiagnosis.id === examDiagnosisId && examDiagnosis.isAdd === isAdd
                ) ?? null
            );
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }
    }
</script>
