const numberPrecision = 2;

export class RegulatoryStandardDoctorDto {
    public doctorEmployeeSeqNumber: number = 0;
    public countZol: number = 0;
    public worthReferral4Type1268: number = 0;
    public countReferral3Type12: number = 0;
    public countReferral3Type1Women: number = 0;
    public worthReferral4Type4: number = 0;
    public countReferral3Type4: number = 0;
    public countReferral3aType4: number = 0;
    public worthReferral4Type10: number = 0;
    public worthReferral4Type9: number = 0;
    public countReferral3Type7: number = 0;
    public worthReferral4Type7: number = 0;
    public worthReferral4Mri: number = 0;
    public countReferral3aType126: number = 0;
    public doctorFullName: string = '';
    public doctorUin: string = '';

    public get countReferral3Type124() {
        return this.countReferral3Type12 + this.countReferral3Type1Women + this.countReferral3Type4;
    }

    // Показва се само в МН (направление 3, не 3а).
    public get countReferral3AllTypes() {
        return this.countReferral3Type124 + this.countReferral3Type7;
    }

    public get worthReferral4AllTypes() {
        return parseFloat(
            (
                this.worthReferral4Type1268 +
                this.worthReferral4Type4 +
                this.worthReferral4Type10 +
                this.worthReferral4Type9 +
                this.worthReferral4Type7
            ).toFixed(numberPrecision)
        );
    }

    // Използва се countReferral3Type7, защото в колоната в базата 'countReferral3Type7' пази броя Referral3аType7 за специалисти.
    public get countSpecialistReferral3aAllTypes() {
        return this.countReferral3aType4 + this.countReferral3aType126 + this.countReferral3Type7;
    }
}
