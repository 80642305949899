<template>
    <div>
        <v-row class="mt-1">
            <v-col cols="6" md="3">
                <btn action="List" block @click="value = true">Издадени листове</btn>
            </v-col>
        </v-row>
        <v-dialog v-model="value" persistent max-width="75%">
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <div class="red--text pointer" @click="value = false">x</div>
                </v-card-title>
                <v-col cols="6" md="3">
                    <btn action="Export" block @click="exportPatientSickSheets"></btn>
                </v-col>
                <data-table title="Издавани болнични листове" :items="items" :headers="tableHeaders" />
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { SickSheetPatientReportDto } from '@/model/Exam/SickSheet/SickSheetPatientReportDto';
    import { sickSheetService } from '@/service/Exam/SickSheetService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class SickSheetPatientReport extends Vue {
        private value: boolean = false;
        private items: SickSheetPatientReportDto[] = [];

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Номер:', value: 'number' },
                { text: 'От дата:', value: 'startDate', formatter: formatters.date },
                { text: 'До дата:', value: 'endDate', formatter: formatters.date },
                { text: 'Дни:', value: 'days' },
                { text: 'Статус:', value: 'status' }
            ];
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                if (currentPatientCache.isLoaded) {
                    this.items = await sickSheetService.getPatientSickSheets(currentPatientCache.value.key.patientId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async exportPatientSickSheets() {
            console.log('exportPatientSickSheets');
            this.$loading.show();
            try {
                if (currentPatientCache.isLoaded) {
                    await sickSheetService.exportPatientSickSheets(currentPatientCache.value.key.patientId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        @Watch('value')
        async onValueChange(newVal: boolean) {
            if (newVal) {
                await this.getTableItems();
            }
        }
    }
</script>
