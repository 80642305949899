import { plainToInstance } from 'class-transformer';

import { ExamClaimCommand } from '@/model/Report/Pis/GpExamClaim/ExamClaimCommand';
import { ExamClaimReportDto } from '@/model/Report/Pis/GpExamClaim/ExamClaimReportDto';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const examClaimReportService = {
    async generateGpExamClaimReport(filter: ExamClaimCommand): Promise<ExamClaimReportDto> {
        const response = await httpService.post<ExamClaimReportDto>(
            `/ExamClaimReport/GenerateGpExamClaimReport`,
            filter
        );
        return plainToInstance(ExamClaimReportDto, response.data);
    },

    exportExcelExamClaimReport(filter: ExamClaimCommand): Promise<void> {
        return httpService.download(`/ExamClaimReport/ExportExcelExamClaimReport`, { params: filter });
    },

    simpleExportExcelExamClaimReport(filter: ExamClaimCommand): Promise<void> {
        return httpService.download(`/ExamClaimReport/SimpleExportExcelExamClaimReport`, { params: filter });
    },

    async generateCovidImmunizationReport(filter: ReportIntervalFilterDto): Promise<ExamClaimReportDto> {
        const response = await httpService.post<ExamClaimReportDto>(
            `/ExamClaimReport/GenerateCovidImmunizationsReport`,
            filter
        );
        return plainToInstance(ExamClaimReportDto, response.data);
    }
};
