import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { config } from '@/Config';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { currentUser } from '@/store/User/CurrentUser';

const axiosConfig: AxiosRequestConfig = {
    baseURL: config.printApiBaseUrl
};
const axiosInstance = axios.create(axiosConfig);

const printHttpService = {
    post<T>(url: string, data: unknown, axiosRequestConfig: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        return axiosInstance.post(url, data, axiosRequestConfig);
    }
};

// Преди изпращане на заявката access token-ът, ако има такъв, се добавя в хедърите.
axiosInstance.interceptors.request.use(
    (requestConfig) => {
        const { user } = currentUser;
        const accessToken = user?.access_token;
        if (requestConfig.headers && accessToken) {
            requestConfig.headers.Authorization = `Bearer ${accessToken}`;
        }
        return requestConfig;
    },
    (error) => {
        notifierService.showError('', error);
        return Promise.reject(error);
    }
);

export { printHttpService };
