import { NhisMedicalNoteDto } from '@/model/Nhis/MedicalNote/NhisMedicalNoteDto';
import { NhisMedicalNoteExtractionCommand } from '@/model/Nhis/MedicalNote/NhisMedicalNoteExtractionCommand';
import { NhisXmlRequestDto } from '@/model/Nhis/NhisXmlRequestDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { nhisService } from '@/service/Nhis/NhisService';
import { signXmlService } from '@/service/Report/SignXmlService';
import { loadingState } from '@/store/LoadingState';
import { nhisKey } from '@/store/NhisNhifAccessTokenCache';

const getMedicalNoteXml = async function getMedicalNoteXml(requestData: NhisXmlRequestDto): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetMedicalNote`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getMedicalNoteCancelationXml = async function getMedicalNoteCancelationXml(
    requestData: NhisXmlRequestDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetMedicalNoteCancelation`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getMedicalNoteExtractionXml = async function getMedicalNoteExtractionXml(
    requestData: NhisMedicalNoteExtractionCommand
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetMedicalNoteExtraction`, requestData);
    console.log(response);
    return response?.data ?? '';
};

export const nhisMedicalNoteService = {
    async postMedicalNote(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getMedicalNoteXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const medicalNoteResult = await nhisService.postXmlToNhisWithId<NhisMedicalNoteDto>(
                    'PostMedicalNote',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(medicalNoteResult?.response);
                if (medicalNoteResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${medicalNoteResult.error}`);
                } else {
                    if (medicalNoteResult?.warnings?.length > 0) {
                        nhisService.showWarnings(medicalNoteResult.warnings);
                    }
                    return medicalNoteResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postMedicalNoteCancel(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getMedicalNoteCancelationXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const medicalNoteResult = await nhisService.postXmlToNhisWithId<NhisMedicalNoteDto>(
                    'PostMedicalNoteCancelation',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(medicalNoteResult?.response);
                if (medicalNoteResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${medicalNoteResult.error}`);
                } else {
                    if (medicalNoteResult?.warnings?.length > 0) {
                        nhisService.showWarnings(medicalNoteResult.warnings);
                    }
                    return medicalNoteResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async extractMedicalNote(requestData: NhisMedicalNoteExtractionCommand) {
        loadingState.show();
        try {
            const xmlString = await getMedicalNoteExtractionXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const medicalNoteResult = await nhisService.postXmlToNhisWithId<NhisMedicalNoteDto>(
                    'PostMedicalNoteExtraction',
                    signResponse.contents,
                    requestData.examId
                );
                console.log(medicalNoteResult?.response);
                if (medicalNoteResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${medicalNoteResult.error}`);
                } else {
                    if (medicalNoteResult?.warnings?.length > 0) {
                        nhisService.showWarnings(medicalNoteResult.warnings);
                    }
                    return `/Exam/MedicalNote/${requestData.examId}`;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    }
};
