import { SubmitCommand } from '@/model/Nhis/SubmitCommand';

export class NhisPatientImmunizationSubmitCommand extends SubmitCommand {
    public patientId: number;
    public immunizationSeqNumber: number;

    constructor(xmlString: string, accessTokenValue: string, patientId: number, immunizationSeqNumber: number) {
        super(xmlString, accessTokenValue);
        this.patientId = patientId;
        this.immunizationSeqNumber = immunizationSeqNumber;
    }
}
