import { plainToInstance } from 'class-transformer';

import { PendingUserRegistrationDto } from '@/model/Employee/PendingUserRegistrationDto';
import { InboxDto } from '@/model/Notification/InboxDto';
import { PushNotificationDto } from '@/model/User/PushNotificationDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { currentUser } from '@/store/User/CurrentUser';
import { userContextCache } from '@/store/User/UserContextCache';

const showPendingUserRegistrations = (pendingUserRegistrations: PendingUserRegistrationDto[]) => {
    if (!pendingUserRegistrations.length) {
        return;
    }

    // Вариант 1: Без предупреждание отива на екран "Персонал", където се вижда кой очаква одобрение.
    //import { router } from '@/router';
    //const path = `/Employee/List/${pendingUserRegistrations[0].practiceId}`;
    //if (router.currentRoute.path !== path) {
    //    router.push(path);
    //}

    // Вариант 2: Натяква с toast-ове до безкрай. Заглавието се определя от първата чакаща регистрация.
    const [{ practiceId, practiceNumber }] = pendingUserRegistrations;
    const title =
        userContextCache.currentPracticeId === practiceId
            ? 'Отворете Управление - Персонал'
            : `Изберете практика № ${practiceNumber} и отворете Управление - Персонал`;
    const text = pendingUserRegistrations.map((pu) => pu.notificationText).join('\r\n');
    notifierService.showWarning(title, text);

    // Вариант 3: Списъкът се записва в нов store, който се показва като иконка с tooltip в заглавната лента.
    // TODO...
};

const showUnreadMessages = (unreadMessages: InboxDto[]) => {
    for (const msg of unreadMessages) {
        if (msg.message !== null) {
            switch (msg.message?.messageTypeCode ?? '') {
                case 'Information':
                    notifierService.showSuccess(msg.message?.title ?? '', msg.message?.text ?? '');
                    break;
                case 'Warning':
                    notifierService.showWarning(msg.message?.title ?? '', msg.message?.text ?? '');
                    break;
                case 'Error':
                    notifierService.showError(msg.message?.title ?? '', msg.message?.text ?? '');
                    break;
                default:
                    break;
            }
        }
    }
};

export const pushNotificationService = {
    async showPushNotifications() {
        // Към 2024-07 се наблюдават само събития, които имат смисъл при логнат потребител.
        if (!currentUser.userId) {
            return;
        }

        const { current } = userContextCache;
        const response = await httpService.get<PushNotificationDto>('PushNotification/GetPushNotifications', {
            params: { practiceId: current?.practiceId, employeeSeqNumber: current?.employeeSeqNumber }
        });
        const pushNotifications = plainToInstance(PushNotificationDto, response.data);

        if (pushNotifications.pendingUserRegistrations) {
            showPendingUserRegistrations(pushNotifications.pendingUserRegistrations);
        }

        if (pushNotifications.unreadMessages) {
            showUnreadMessages(pushNotifications.unreadMessages);
        }
    },

    startPolling() {
        // При дебъгване на известията, polling интервалът може да се намали, например на 15 секунди.
        // През останалото време трябва да бъде нарядко, не само за да не натоварва сървъра,
        // но и защото затлачва network tab-а и затруднява дебъгването на останалите неща.
        const seconds = 600;
        const msPerSecond = 1000;
        setInterval(() => this.showPushNotifications(), seconds * msPerSecond);
    }
};
