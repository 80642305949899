import { SubmitCommand } from './SubmitCommand';

export class SubmitCommandDev extends SubmitCommand {
    constructor(
        xmlString: string,
        accessTokenValue: string,
        public path: string,
        public authorizationNeeded: boolean
    ) {
        super(xmlString, accessTokenValue);
    }
}
