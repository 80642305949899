<template>
    <v-dialog v-model="dialogIsVisible">
        <template #activator="{ on }">
            <btn action="Pill" v-on="on">Търсене на лекарство</btn>
        </template>
        <v-card>
            <v-card-title>
                <v-spacer />
                <btn icon action="Close" @click="dialogIsVisible = false" />
            </v-card-title>
            <nhif-drug />
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    // Vuetify bugfix: Глобално регистриран custom компонент (като btn), поставен в activator слота на диалог,
    // не се появява при първоначално зареждане на SPA, а чак след рутиране. Ако се импортира изрично, нещата се оправят.
    import Btn from '@/component/Common/Btn.vue';
    import NhifDrug from '@/view/Nomenclature/NhifDrug.vue';

    @Component({
        components: { Btn, NhifDrug }
    })
    export default class NhifDrugSearchDialog extends Vue {
        private dialogIsVisible: boolean = false;
    }
</script>
