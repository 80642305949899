import { Report365RowBase } from '@/model/Report/Rhi/Report365/Report365RowBase';

export class Annex2PregnancyRegisterRowDto extends Report365RowBase {
    public countStartYear: number | null = null;
    public countDuringYear: number | null = null;
    public countDuringYearThirdMonth: number | null = null;
    public countDuringYearAfterFourMonth: number | null = null;
    public countBirth: number | null = null;
    public countBirthDead: number | null = null;
    public countAbortion: number | null = null;
    public countDeregistered: number | null = null;
    public get countEndYear(): number {
        return (
            (this.countStartYear === null ? 0 : Number(this.countStartYear)) +
            (this.countDuringYear === null ? 0 : Number(this.countDuringYear)) -
            (this.countBirth === null ? 0 : Number(this.countBirth)) -
            (this.countBirthDead === null ? 0 : Number(this.countBirthDead)) -
            (this.countAbortion === null ? 0 : Number(this.countAbortion)) -
            (this.countDeregistered === null ? 0 : Number(this.countDeregistered))
        );
    }
}
