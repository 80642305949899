import { plainToInstance } from 'class-transformer';

import { InvoiceTypeDto } from '@/model/Nomenclature/InvoiceTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const invoiceTypeService = {
    async getInvoiceTypes(): Promise<InvoiceTypeDto[]> {
        const response = await httpService.get<InvoiceTypeDto[]>('/InvoiceType/GetInvoiceTypes');
        return plainToInstance(InvoiceTypeDto, response?.data);
    }
};
