import { Type } from 'class-transformer';

import { Annex2PregnancyRegisterDto } from '@/model/Report/Rhi/Annex2/Annex2PregnancyRegisterDto';
import { Annex2PregnancyVisitDto } from '@/model/Report/Rhi/Annex2/Annex2PregnancyVisitDto';

export class Annex2ItemsDto {
    @Type(() => Annex2PregnancyVisitDto)
    public pregnancyVisit: Annex2PregnancyVisitDto = new Annex2PregnancyVisitDto();

    @Type(() => Annex2PregnancyRegisterDto)
    public pregnancyRegister: Annex2PregnancyRegisterDto = new Annex2PregnancyRegisterDto();
}
