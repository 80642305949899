<template>
    <v-card>
        <v-card-title>{{ deviceOperatorSeqNumber ? 'Редактиране' : 'Добавяне' }} на оператор</v-card-title>

        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn action="Save" @click="save" />
            <v-spacer></v-spacer>
            <btn v-if="deviceOperatorSeqNumber" action="Delete" @click="deleteOperator" />
        </v-card-actions>

        <v-form ref="documentForm" v-model="isValid">
            <v-card-text>
                <v-row>
                    <v-col cols="6"> <text-field v-model="dto.operator" required label="Оператор:" /> </v-col>
                    <v-col cols="6">
                        <text-field v-model="dto.password" required label="Парола:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="employees" :loading="$loading.isVisible">
                            <template #[`item.selected`]="{ item }">
                                <v-icon :color="item.selected ? 'primary' : ''" @click="item.selected = !item.selected">
                                    {{ item.selected ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { SelectableItem } from '@/model/Common/SelectableItem';
    import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
    import { FiscalDeviceOperatorDto } from '@/model/Practice/Fiscal/FiscalDeviceOperatorDto';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { fiscalDeviceOperatorService } from '@/service/Practice/Fiscal/FiscalDeviceOperatorService';

    @Component
    export default class DeviceOperatorEdit extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private fiscalDeviceSeqNumber!: number;

        @Prop()
        private deviceOperatorSeqNumber!: number;

        private isValid: boolean = false;
        private employees: Array<SelectableItem<EmployeeTitleDto>> = [];

        private headers: IDataTableHeader[] = [
            { text: 'No', value: 'item.employeeSeqNumber' },
            { text: 'Персонал', value: 'item.uinNameAndSpecialty' },
            { text: 'Избран', value: 'selected' }
        ];

        private dto: FiscalDeviceOperatorDto = new FiscalDeviceOperatorDto();

        private async save() {
            this.$loading.show();
            try {
                (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

                if (this.isValid) {
                    this.dto.practiceId = this.practiceId;
                    this.dto.employees = this.employees.filter((emp) => emp.selected).map((emp) => emp.item);
                    if (this.deviceOperatorSeqNumber) {
                        await fiscalDeviceOperatorService.updateFiscalDeviceOperator(this.dto);
                    } else {
                        this.dto.deviceSeqNumber = this.fiscalDeviceSeqNumber;
                        await fiscalDeviceOperatorService.createFiscalDeviceOperator(this.dto);
                    }
                    this.$router.push(this.backRoute);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private isSelected(seqNumber: number): boolean {
            return this.dto.employees.some((obj) => obj.employeeSeqNumber === seqNumber);
        }

        private get backRoute() {
            return `/Practice/Fiscal/Operator/List/${this.practiceId}/${this.fiscalDeviceSeqNumber}`;
        }

        private async getDevice() {
            this.$loading.show();
            try {
                if (this.deviceOperatorSeqNumber) {
                    this.dto = (
                        await fiscalDeviceOperatorService.getFiscalDeviceOperator(
                            this.practiceId,
                            this.fiscalDeviceSeqNumber,
                            this.deviceOperatorSeqNumber
                        )
                    ).data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async getEmployeeTitles() {
            this.$loading.show();
            try {
                const emplys = await employeeService.getEmployeeTitles(this.practiceId);
                for (const emp of emplys) {
                    const item = new SelectableItem<EmployeeTitleDto>();
                    item.item = emp;
                    item.selected = this.isSelected(emp.employeeSeqNumber);
                    this.employees.push(item);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteOperator() {
            this.$loading.show();
            try {
                if (this.deviceOperatorSeqNumber) {
                    await fiscalDeviceOperatorService.deleteFiscalDeviceOperator(
                        this.practiceId,
                        this.fiscalDeviceSeqNumber,
                        this.deviceOperatorSeqNumber
                    );
                }
                this.$router.push(this.backRoute);
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.getDevice();
            await this.getEmployeeTitles();
        }
    }
</script>

<style type="text/css">
    ul.no-bullets {
        list-style-type: none;
        padding: 10;
        margin: 10;
    }
</style>
