import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { ImmunizationDto } from '@/model/Nomenclature/ImmunizationDto';
import { MissedImmunizationDto } from '@/model/Patient/Immunization/MissedImmunizationDto';
import { PatientImmunizationCheckDto } from '@/model/Patient/Immunization/PatientImmunizationCheckDto';
import { PatientImmunizationDto } from '@/model/Patient/Immunization/PatientImmunizationDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientImmunizationService = {
    getPatientImmunizations(patiendId: number): Promise<AxiosResponse<PatientImmunizationDto[]>> {
        return httpService.get(`/PatientImmunization/GetPatientImmunizations`, { params: { patiendId } });
    },

    getImmunizationsByExamId(examId: number): Promise<AxiosResponse<PatientImmunizationDto[]>> {
        return httpService.get(`/PatientImmunization/GetPatientImmunizationsByExam`, { params: { examId } });
    },

    getPatientImmunization(id: number): Promise<AxiosResponse<PatientImmunizationDto>> {
        return httpService.get(`/PatientImmunization/GetPatientImmunization/${id}`);
    },

    getInfoByExamId(id: number): Promise<AxiosResponse<PatientImmunizationDto>> {
        return httpService.get(`/PatientImmunization/GetPatientImmunizationsByExam/${id}`);
    },

    exportPatientImmunizations(patiendId: number): Promise<void> {
        return httpService.download(`/PatientImmunization/ExportPatientImmunizations`, { params: { patiendId } });
    },

    createPatientImmunization(data: PatientImmunizationDto): Promise<AxiosResponse<number>> {
        return httpService.post('/PatientImmunization/CreatePatientImmunization', data);
    },

    updatePatientImmunization(data: PatientImmunizationDto): Promise<AxiosResponse<PatientImmunizationDto>> {
        return httpService.put('/PatientImmunization/UpdatePatientImmunization', data);
    },

    async getMissedImmunizationsForPatient(patientId: number): Promise<ImmunizationDto[]> {
        const response = await httpService.get<ImmunizationDto[]>(
            'PatientImmunization/GetMissedImmunizationsForPatient',
            { params: { patientId } }
        );
        return plainToInstance(ImmunizationDto, response.data);
    },

    generateMissedPatientImmunizations(data: MissedImmunizationDto): Promise<AxiosResponse> {
        return httpService.post('/PatientImmunization/GenerateMissedPatientImmunizations', data);
    },

    deletePatientImmunization(id: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/PatientImmunization/DeletePatientImmunization/${id}`);
    },

    async checkForExisting(data: PatientImmunizationCheckDto): Promise<string | null> {
        const response = await httpService.post<string | null>(
            '/PatientImmunization/CheckPatientImmunizationForExisting',
            data
        );
        return response.data;
    }
};
