<!-- Десен панел с натрупаните нотификации. -->
<template>
    <v-navigation-drawer v-custom-click-outside="close" :value="isVisible" temporary fixed right width="500">
        <NotificationList
            style="height: calc(100% - 48px); overflow-y: auto; overflow-x: hidden"
            :items="sortedNotifications"
            :show-grouped="false"
            :show-sort-options="false"
        />
        <v-footer absolute>
            <v-spacer />
            <btn action="Delete" color="error" :disabled="!hasNotifications" @click.stop="clearNotificationsAndClose">
                Изтриване на всички
            </btn>
        </v-footer>
    </v-navigation-drawer>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { notifications } from '@/store/Notifications';

    import NotificationList from './NotificationList.vue';

    @Component({
        components: { NotificationList }
    })
    export default class NotificationsDrawer extends Vue {
        private get sortedNotifications() {
            return notifications.sorted;
        }

        private get isVisible() {
            return notifications.drawerIsVisible;
        }

        private mounted() {
            notifications.markAllAsRead();
        }

        private close() {
            notifications.drawerIsVisible = false;
        }

        private clearNotificationsAndClose() {
            notifications.clear();
            this.close();
        }

        private get hasNotifications(): boolean {
            return this.sortedNotifications?.length > 0;
        }
    }
</script>

<style scoped>
    .card-outter {
        position: relative;
        padding-bottom: 50px;
    }

    .card-actions {
        position: absolute;
        bottom: 0;
    }
</style>
