import { render, staticRenderFns } from "./NhisCertificateDriveExtraction.vue?vue&type=template&id=003b0b9a&scoped=true"
import script from "./NhisCertificateDriveExtraction.vue?vue&type=script&lang=ts"
export * from "./NhisCertificateDriveExtraction.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "003b0b9a",
  null
  
)

export default component.exports