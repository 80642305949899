export class RiskGroupRuleDto {
    public id: number = 0;
    public name: string = '';
    public icdCode: string = '';
    public description: string = '';
    public activities: string = '';
    public validFrom: Date | null = null;
    public validTo: Date | null = null;
    public ordinanceText: string = '';
}
