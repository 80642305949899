<template>
    <v-dialog v-model="isVisible" v-bind="$attrs" max-width="25%" @keydown.esc="cancel">
        <v-card>
            <v-card-title v-show="Boolean(title)" class="info2">{{ title }}</v-card-title>
            <v-card-subtitle v-show="Boolean(message)" class="pt-4 text-h6">{{ message }}</v-card-subtitle>
            <v-card-actions class="pt-3">
                <btn action="Cancel" @click.native="cancel" />
                <v-spacer />
                <btn action="Success" @click.native="agree">Добре</btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class ConfirmDialog extends Vue {
        @Prop({ default: true })
        showConfirmText!: boolean;

        @Prop({ default: '' })
        confirmTextLabel!: string;

        private isVisible: boolean = false;
        private title: string = '';
        private message: string = '';
        private resolve: (val: boolean) => void = () => true;
        private reject: (val: boolean) => void = () => false;

        public open(title: string, message: string) {
            this.isVisible = true;
            this.title = title;
            this.message = message;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        }

        private agree() {
            this.resolve(true);
            this.isVisible = false;
        }

        private cancel() {
            this.resolve(false);
            this.isVisible = false;
        }
    }
</script>
