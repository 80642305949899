<template>
    <dropdown-search
        :value="value"
        :service="doctorSearchService"
        item-text="uinAndName"
        return-object
        :label="label"
        :hint="hint"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        @input="$emit('input', ...arguments)"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DropdownSearch from '@/component/Dropdown/DropdownSearch.vue';
    import { DoctorDropdownDto } from '@/model/Doctor/DoctorDropdownDto';
    import { doctorSearchService } from '@/service/Doctor/DoctorSearchService';

    @Component({ components: { DropdownSearch } })
    export default class DoctorPicker extends Vue {
        @Prop()
        private value!: DoctorDropdownDto;

        @Prop()
        private label!: string;

        @Prop()
        private hint!: string;

        @Prop()
        private required!: boolean;

        @Prop()
        private disabled!: boolean;

        @Prop()
        private readonly!: boolean;

        private get doctorSearchService() {
            return doctorSearchService;
        }
    }
</script>
