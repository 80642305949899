import { plainToInstance } from 'class-transformer';
import Vue from 'vue';

import { ReceptionServiceCreateCommand } from '@/model/Reception/Service/ReceptionServiceCreateCommand';

const serviceOrderKey: string = 'serviceOrder';
const emptyValue = new ReceptionServiceCreateCommand();

class ServiceOrderCache {
    private _value: ReceptionServiceCreateCommand = emptyValue;

    public get value() {
        return this._value;
    }

    public clear() {
        localStorage.removeItem(serviceOrderKey);
        this._value = emptyValue;
    }

    public set(item: ReceptionServiceCreateCommand) {
        if (item) {
            localStorage.setItem(serviceOrderKey, JSON.stringify(item));
            this._value = item;
        }
    }

    public loadFromLocalStorage() {
        const json = localStorage.getItem(serviceOrderKey);
        if (json) {
            const serviceOrder = plainToInstance(ReceptionServiceCreateCommand, JSON.parse(json));
            this._value = serviceOrder;
        }
    }
}

export const serviceOrderCache = Vue.observable(new ServiceOrderCache());
