<template>
    <v-row>
        <!-- Глобален администратор. -->
        <template v-if="currentUser.hasGlobalAdminRole">
            <v-col>
                <v-tooltip bottom>
                    <template #activator="{ attrs, on }">
                        <a
                            class="text-decoration-none global-admin"
                            :href="userProfileUrl"
                            target="_blank"
                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ currentUser.user.profile.name }}
                            <small v-if="currentUser.user.profile.name !== currentUser.user.profile.preferred_username">
                                ({{ currentUser.user.profile.preferred_username }})
                            </small>
                        </a>
                    </template>
                    Глобален администратор, лични настройки
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-checkbox
                    v-model="currentUser.globalAdminIsDisabled"
                    label="Тест с правата на местоработата"
                    class="mt-0"
                    hide-details
                />
            </v-col>
        </template>

        <!-- Обикновен потребител. -->
        <v-col v-else>
            <v-tooltip bottom>
                <template #activator="{ attrs, on }">
                    <a class="text-decoration-none" :href="userProfileUrl" target="_blank" v-bind="attrs" v-on="on">
                        {{ currentUser.user.profile.name }}
                        <small v-if="currentUser.user.profile.name !== currentUser.user.profile.preferred_username">
                            ({{ currentUser.user.profile.preferred_username }})
                        </small>
                    </a>
                </template>
                Лични настройки
            </v-tooltip>
        </v-col>

        <!-- Изход -->
        <v-col cols="auto">
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn color="error" icon v-on="on" @click="signoutRedirect">
                        <v-icon>mdi-logout</v-icon>
                    </v-btn>
                </template>
                Изход
            </v-tooltip>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { config } from '@/Config';
    import { oidcClient } from '@/service/Infrastructure/OidcClient';
    import { currentUser } from '@/store/User/CurrentUser';

    @Component
    export default class UserInfoCard extends Vue {
        private get currentUser() {
            return currentUser;
        }

        private get userProfileUrl() {
            return `${config.identityServerBaseUrl}/MyProfile`;
        }

        private signoutRedirect() {
            return oidcClient.signoutRedirect();
        }
    }
</script>

<style scoped>
    /*
        Anchor tag-овете възприемат само цветове text--primary, text--secondary и text--disabled, но не и text--error,
        затова error цветът се задава чрез допълнителен клас, който използва цвят от Vuetify темата.
    */
    a.global-admin {
        color: var(--v-error-base);
    }
</style>
