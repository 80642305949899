import { Type } from 'class-transformer';

import { Report365AbortionByAgeDto } from '@/model/Report/Rhi/Report365/Report365AbortionByAgeDto';
import { Report365AbortionDto } from '@/model/Report/Rhi/Report365/Report365AbortionDto';
import { Report365AssistedReproductionDto } from '@/model/Report/Rhi/Report365/Report365AssistedReproductionDto';
import { Report365BedDto } from '@/model/Report/Rhi/Report365/Report365BedDto';
import { Report365DispensaryActivityDto } from '@/model/Report/Rhi/Report365/Report365DispensaryActivityDto';
import { Report365DoctorTraineeDto } from '@/model/Report/Rhi/Report365/Report365DoctorTraineeDto';
import { Report365HealthCareTraineeDto } from '@/model/Report/Rhi/Report365/Report365HealthCareTraineeDto';
import { Report365MedicalEquipmentDto } from '@/model/Report/Rhi/Report365/Report365MedicalEquipmentDto';
import { Report365PerformedTestDto } from '@/model/Report/Rhi/Report365/Report365PerformedTestDto';
import { Report365PhysiotherapeuticActivityDto } from '@/model/Report/Rhi/Report365/Report365PhysiotherapeuticActivityDto';
import { Report365PreventiveActivityDto } from '@/model/Report/Rhi/Report365/Report365PreventiveActivityDto';
import { Report365PreventiveGynecologistActivityDto } from '@/model/Report/Rhi/Report365/Report365PreventiveGynecologistActivityDto';
import { Report365PreventivePregnancyActivityDto } from '@/model/Report/Rhi/Report365/Report365PreventivePregnancyActivityDto';
import { Report365PsychiatricAccidentDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricAccidentDto';
import { Report365PsychiatricExpertiseDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricExpertiseDto';
import { Report365PsychiatricPatientDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricPatientDto';
import { Report365PsychiatricSickDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricSickDto';
import { Report365PsychiatricStructureDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricStructureDto';
import { Report365StaffDto } from '@/model/Report/Rhi/Report365/Report365StaffDto';
import { Report365SurgicalActivityDto } from '@/model/Report/Rhi/Report365/Report365SurgicalActivityDto';
import { Report365TransportationDto } from '@/model/Report/Rhi/Report365/Report365TransportationDto';
import { Report365VisitDto } from '@/model/Report/Rhi/Report365/Report365VisitDto';

export class Report365ItemsDto {
    @Type(() => Report365StaffDto)
    public staff: Report365StaffDto = new Report365StaffDto();

    @Type(() => Report365DoctorTraineeDto)
    public doctorTrainee: Report365DoctorTraineeDto = new Report365DoctorTraineeDto();

    @Type(() => Report365HealthCareTraineeDto)
    public healthCareTrainee: Report365DoctorTraineeDto = new Report365DoctorTraineeDto();

    @Type(() => Report365DispensaryActivityDto)
    public dispensaryActivity: Report365DispensaryActivityDto = new Report365DispensaryActivityDto();

    @Type(() => Report365AbortionByAgeDto)
    public abortionByAge: Report365AbortionByAgeDto = new Report365AbortionByAgeDto();

    @Type(() => Report365AbortionDto)
    public abortion: Report365AbortionDto = new Report365AbortionDto();

    @Type(() => Report365AssistedReproductionDto)
    public assistedReproduction: Report365AssistedReproductionDto = new Report365AssistedReproductionDto();

    @Type(() => Report365BedDto)
    public bed: Report365BedDto = new Report365BedDto();

    @Type(() => Report365MedicalEquipmentDto)
    public medicalEquipment: Report365MedicalEquipmentDto = new Report365MedicalEquipmentDto();

    @Type(() => Report365PerformedTestDto)
    public performedTest: Report365PerformedTestDto = new Report365PerformedTestDto();

    @Type(() => Report365PreventiveActivityDto)
    public preventiveActivity: Report365PreventiveActivityDto = new Report365PreventiveActivityDto();

    @Type(() => Report365PreventiveGynecologistActivityDto)
    public preventiveGynecologistActivity: Report365PreventiveGynecologistActivityDto =
        new Report365PreventiveGynecologistActivityDto();

    @Type(() => Report365PhysiotherapeuticActivityDto)
    public physiotherapeuticActivity: Report365PhysiotherapeuticActivityDto =
        new Report365PhysiotherapeuticActivityDto();

    @Type(() => Report365PreventivePregnancyActivityDto)
    public preventivePregnancyActivity: Report365PreventivePregnancyActivityDto =
        new Report365PreventivePregnancyActivityDto();

    @Type(() => Report365SurgicalActivityDto)
    public surgicalActivity: Report365SurgicalActivityDto = new Report365SurgicalActivityDto();

    @Type(() => Report365TransportationDto)
    public transportation: Report365TransportationDto = new Report365TransportationDto();

    @Type(() => Report365PsychiatricStructureDto)
    public psychiatricStructure: Report365PsychiatricStructureDto = new Report365PsychiatricStructureDto();

    @Type(() => Report365PsychiatricPatientDto)
    public psychiatricPatient: Report365PsychiatricPatientDto = new Report365PsychiatricPatientDto();

    @Type(() => Report365PsychiatricAccidentDto)
    public psychiatricAccident: Report365PsychiatricAccidentDto = new Report365PsychiatricAccidentDto();

    @Type(() => Report365PsychiatricExpertiseDto)
    public psychiatricExpertise: Report365PsychiatricExpertiseDto = new Report365PsychiatricExpertiseDto();

    @Type(() => Report365PsychiatricSickDto)
    public psychiatricSick: Report365PsychiatricSickDto = new Report365PsychiatricSickDto();

    @Type(() => Report365VisitDto)
    public visit: Report365VisitDto = new Report365VisitDto();
}
