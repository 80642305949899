<template>
    <div>
        <v-row no-gutters>
            <v-col md="3" cols="6">
                <h4>Дейности</h4>
            </v-col>
            <v-col md="9" cols="6">
                <v-checkbox
                    v-model="filterTemplatesByIcd"
                    class="no-margin-no-padding"
                    dense
                    label="Филтриране на шаблони по диагнози"
                ></v-checkbox>
            </v-col>
        </v-row>

        <v-row v-if="isDevOrTest" class="no-gutters">
            <v-col cols="auto" class="mt-1 text--secondary">(DevTest)</v-col>
            <v-col v-if="value.debugMonthETag && !loadedFromSql" class="success--text">
                Заредено от blob storage
                <btn icon action="XmlReport" @click="getFromSql">Зареждане от базата</btn>
            </v-col>
            <v-col v-else class="warning--text">
                Заредено от базата данни
                <btn icon action="XmlReport" @click="getFromBlob">Зареждане от blob storage</btn>
            </v-col>
            <v-col cols="auto">
                <btn icon action="Upload" @click="uploadToBlob">Качване в blob storage</btn>
            </v-col>
        </v-row>

        <v-row no-gutters class="align-baseline d-flex flex-row">
            <v-col v-if="isDevOrTest || isStaging" cols="12">
                (DevTest) <nhis-medical-history v-model="value.nhisMedicalHistory" />
            </v-col>
            <v-col cols="11">
                <dropdown
                    :items="templatesSymptoms"
                    item-text="templateText"
                    item-value="templateText"
                    label="Избор от шаблон анамнеза"
                    @input="onSymptomsChanged"
                ></dropdown>
            </v-col>
            <v-col cols="1">
                <btn icon action="Close" @click="value.symptoms = ''">Изчистване </btn>
            </v-col>

            <v-col cols="12">
                <v-textarea
                    v-model="value.symptoms"
                    dense
                    style="font-size: 0.9em"
                    label="Анамнеза"
                    :rules="[$validator.required]"
                    rows="3"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="11">
                <dropdown
                    :items="templatesCondition"
                    item-text="templateText"
                    item-value="templateText"
                    label="Избор от шаблон обективно състояние"
                    @input="onConditionChanged"
                ></dropdown>
            </v-col>
            <v-col cols="1">
                <btn icon action="Close" @click="value.condition = ''">Изчистване </btn>
            </v-col>
            <v-col md="12">
                <v-textarea
                    v-model="value.condition"
                    dense
                    style="font-size: 0.9em"
                    label="Обективно състояние"
                    :rules="[$validator.required]"
                    rows="3"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cls="11">
                <dropdown
                    :items="templatesTests"
                    item-text="templateText"
                    item-value="templateText"
                    label="Избор от шаблон изследвания"
                    @input="onTestsChanged"
                ></dropdown>
            </v-col>
            <v-col cols="1">
                <btn icon action="Close" @click="value.tests = ''">Изчистване </btn>
            </v-col>
            <v-col cols="12"
                ><v-textarea v-model="value.tests" dense style="font-size: 0.9em" label="Изследвания" rows="3"
            /></v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="11">
                <dropdown
                    :items="templatesTreatment"
                    item-text="templateText"
                    item-value="templateText"
                    label="Избор от шаблон терапия"
                    @input="onTreatmentChanged"
                ></dropdown>
            </v-col>
            <v-col cols="1">
                <btn icon action="Close" @click="value.treatment = ''">Изчистване </btn>
            </v-col>
            <v-col cols="12"
                ><v-textarea v-model="value.treatment" dense style="font-size: 0.9em" label="Терапия" rows="3"
            /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { config } from '@/Config';
    import { ExamBlobDto } from '@/model/Exam/ExamBlobDto';
    import { ExamDto } from '@/model/Exam/ExamDto';
    import { TemplateDto } from '@/model/Setting/Template/TemplateDto';
    import { TemplateFilterDto } from '@/model/Setting/Template/TemplateFilterDto';
    import { templateService } from '@/service/Setting/TemplateService';
    import NhisMedicalHistory from '@/view/Nhis/Exam/NhisMedicalHistory.vue';

    @Component({
        components: { NhisMedicalHistory }
    })
    export default class AmbulatorySheetPatientCondition extends Vue {
        @Prop({ type: Object })
        value!: ExamDto;

        private filterTemplatesByIcd: boolean = false;
        private templatesSymptoms: TemplateDto[] = [];
        private templatesCondition: TemplateDto[] = [];
        private templatesTests: TemplateDto[] = [];
        private templatesTreatment: TemplateDto[] = [];

        private loadedFromSql: boolean = false;

        private async loadTemplates() {
            let icdFilter: string = this.filterTemplatesByIcd ? this.value.diagnoses[0].icdCode : '';
            if (this.filterTemplatesByIcd) {
                for (let idx = 0; idx < this.value.diagnoses.length; idx++) {
                    icdFilter = `${icdFilter},${this.value.diagnoses[idx].icdCode}`;
                }
            }

            const templateFilterSymptoms: TemplateFilterDto = new TemplateFilterDto(icdFilter, 'Symptoms');
            const responseTemplatesSymp = await templateService.getFilteredTemplates(templateFilterSymptoms);
            this.templatesSymptoms = responseTemplatesSymp.data;

            const templateFilterCondition: TemplateFilterDto = new TemplateFilterDto(icdFilter, 'Condition');
            const responseTemplatesCondition = await templateService.getFilteredTemplates(templateFilterCondition);
            this.templatesCondition = responseTemplatesCondition.data;

            const templateFilterTests: TemplateFilterDto = new TemplateFilterDto(icdFilter, 'Tests');
            const responseTemplatesTests = await templateService.getFilteredTemplates(templateFilterTests);
            this.templatesTests = responseTemplatesTests.data;

            const templateFilterTreatment: TemplateFilterDto = new TemplateFilterDto(icdFilter, 'Treatment');
            const responseTemplatesTreatment = await templateService.getFilteredTemplates(templateFilterTreatment);
            this.templatesTreatment = responseTemplatesTreatment.data;
        }

        @Watch('filterTemplatesByIcd')
        private async onFilterTemplatesByIcd() {
            await this.loadTemplates();
        }

        private get isDevOrTest() {
            return config.isDevOrTest;
        }

        private get isStaging() {
            return config.isStaging;
        }

        private async uploadToBlob() {
            this.$loading.show();
            try {
                const exam = new ExamBlobDto();
                exam.visitId = this.value.id;
                exam.symptoms = this.value.symptoms;
                exam.condition = this.value.condition;
                exam.tests = this.value.tests;
                exam.treatment = this.value.treatment;
                // Демонстрация с дълъг живот на ETag-а на месечния blob (минал е през браузъра, стоял е там неограничено време).
                exam.debugMonthETag = this.value.debugMonthETag;
                await templateService.uploadToBlob(exam);
                this.loadedFromSql = false;
            } finally {
                this.$loading.hide();
            }
        }

        public async getFromBlob() {
            this.$loading.show();
            try {
                const result = (await templateService.getFromBlob(this.value.id)).data;
                this.value.condition = result.condition;
                this.value.symptoms = result.symptoms;
                this.value.tests = result.tests;
                this.value.treatment = result.treatment;
                this.value.debugMonthETag = result.debugMonthETag;
                this.loadedFromSql = false;
            } finally {
                this.$loading.hide();
            }
        }

        private async getFromSql() {
            this.$loading.show();
            try {
                const result = (await templateService.getFromSql(this.value.id)).data;
                this.value.condition = result.condition;
                this.value.symptoms = result.symptoms;
                this.value.tests = result.tests;
                this.value.treatment = result.treatment;
                // Запазва се последно известинят ETag.
                this.loadedFromSql = true;
            } finally {
                this.$loading.hide();
            }
        }

        private onConditionChanged(value: TemplateDto) {
            this.value.condition = `${this.value.condition} ${value}`;
        }

        private onSymptomsChanged(value: TemplateDto) {
            this.value.symptoms = `${this.value.symptoms} ${value}`;
        }

        private onTestsChanged(value: TemplateDto) {
            this.value.tests = `${this.value.tests} ${value}`;
        }

        private onTreatmentChanged(value: TemplateDto) {
            this.value.treatment = `${this.value.treatment} ${value}`;
        }

        private async mounted() {
            await this.loadTemplates();
        }
    }
</script>

<style scoped>
    ::v-deep .v-textarea textarea {
        line-height: 15px !important;
    }
</style>
