import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { DrugProtocolDto } from '@/model/Exam/DrugProtocol/DrugProtocolDto';
import { NhifProtocolDropdownDto } from '@/model/Exam/DrugProtocol/NhifProtocolDropdownDto';
import { NhifProtocolViewDto } from '@/model/Exam/DrugProtocol/NhifProtocolViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const drugProtocolPaperService = {
    async getDrugProtocolPaper(entityId: number, expireDate: Date | null): Promise<DrugProtocolDto> {
        const response = await httpService.get(`/DrugProtocolPaper/GetDrugProtocolPaper/`, {
            params: {
                entityId,
                expireDate
            }
        });
        return plainToInstance(DrugProtocolDto, response.data);
    },

    async getDrugProtocolInfoByExamId(id: number): Promise<DrugProtocolDto> {
        const response = await httpService.get(`/DrugProtocolPaper/GetInfoByExam/${id}`);
        return plainToInstance(DrugProtocolDto, response.data);
    },

    getPreviousProtocolsForPatient(patientId: number): Promise<AxiosResponse<NhifProtocolDropdownDto[]>> {
        return httpService.get('/DrugProtocolPaper/GetPreviousProtocolsForPatient', { params: { patientId } });
    },

    getDrugProtocolByExam(examId: number): Promise<AxiosResponse<NhifProtocolViewDto[]>> {
        return httpService.get('/DrugProtocolPaper/GetMenuViewsByExam/', {
            params: { examId }
        });
    },

    createDrugProtocolPaper(data: DrugProtocolDto): Promise<AxiosResponse<number>> {
        return httpService.post('/DrugProtocolPaper/CreateDrugProtocolPaper/', data);
    },

    updateDrugProtocolPaper(data: DrugProtocolDto): Promise<AxiosResponse<void>> {
        return httpService.put('/DrugProtocolPaper/UpdateDrugProtocolPaper/', data);
    },

    deleteDrugProtocolPaper(id: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/DrugProtocolPaper/DeleteDrugProtocolPaper/${id}`);
    }
};
