<template>
    <v-row>
        <v-col cols="6" md="3"
            ><text-field v-model="value.homePhone" label="Дом. телефон:" :disabled="disabled"
        /></v-col>
        <v-col cols="6" md="3"
            ><text-field v-model="value.workPhone" label="Сл. телефон:" :disabled="disabled"
        /></v-col>
        <v-col cols="6" md="3"
            ><text-field v-if="showMobilePhone" v-model="value.mobilePhone" label="Моб. телефон:" :disabled="disabled"
        /></v-col>
        <v-col cols="6" md="3"
            ><text-field v-if="showFax" v-model="value.fax" label="Факс:" :disabled="disabled"
        /></v-col>
        <v-col cols="12" md="3"
            ><text-field v-model="value.email" label="E-mail:" :disabled="disabled" :rules="[$validator.email]"
        /></v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ContactDto } from '@/model/Common/ContactDto';

    @Component
    export default class Contact extends Vue {
        @Prop({ required: true, type: Object })
        private value!: ContactDto;

        @Prop({ required: true })
        private showFax!: boolean;

        @Prop({ default: true })
        private showMobilePhone!: boolean;

        @Prop()
        private disabled!: boolean;
    }
</script>
