<template>
    <v-card>
        <v-card-title>Създаване на различни видове съобщения</v-card-title>

        <v-card-text>
            <v-btn color="info2" @click="showToastMessage(notificationSeverity.Info, notificationCategory.System)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn
                color="success2"
                @click="showToastMessage(notificationSeverity.Success, notificationCategory.System)"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn color="warning2" @click="showToastMessage(notificationSeverity.Warn, notificationCategory.System)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn color="error2" @click="showToastMessage(notificationSeverity.Error, notificationCategory.System)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-card-text>

        <v-card-text>
            <v-btn
                outlined
                color="info"
                @click="showToastMessage(notificationSeverity.Info, notificationCategory.Validation)"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn
                outlined
                color="success"
                @click="showToastMessage(notificationSeverity.Success, notificationCategory.Validation)"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn
                outlined
                color="warning"
                @click="showToastMessage(notificationSeverity.Warn, notificationCategory.Validation)"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn
                outlined
                color="error"
                @click="showToastMessage(notificationSeverity.Error, notificationCategory.Validation)"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-card-text>

        <v-card-text>
            <v-btn color="info" @click="showModalDialog(notificationSeverity.Info, notificationCategory.User)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn color="success" @click="showModalDialog(notificationSeverity.Success, notificationCategory.User)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn color="warning" @click="showModalDialog(notificationSeverity.Warn, notificationCategory.User)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn color="error" @click="showModalDialog(notificationSeverity.Error, notificationCategory.User)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-card-text>

        <v-card-text>
            <v-btn outlined color="info"></v-btn>

            <v-btn outlined color="success" @click="$notifier.showSuccess('Готово', 'Стана работата')">
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn outlined color="warning" @click="$notifier.showWarning('Внимание', 'Нещо не е попълнено')">
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn outlined color="error" @click="$notifier.showError('Опа', 'Прецака се работата')">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-card-text>

        <v-card-text>
            <btn action="Select" @click.stop="markAllAsRead">Маркиране като прочетени</btn>
            <btn action="Delete" @click.stop="clear">Изтриване на всички</btn>
            <br /><br />
            Преглед на нотификациите на цял екран:
            <v-badge :content="unreadCount" :value="unreadCount" color="info" overlap>
                <v-btn icon @click.stop="openDialog">
                    <v-icon large>mdi-email</v-icon>
                </v-btn>
            </v-badge>

            <!-- Цял екран с натрупаните нотификации. -->
            <NotificationsDialog />
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { NotificationCreateModel } from '@/model/Notification/NotificationCreateModel';
    import { NotificationCategory, NotificationSeverity } from '@/model/Notification/NotificationSeverity';
    import { showModalDialog, showToastMessage } from '@/service/Infrastructure/NotifierService';
    import { notifications } from '@/store/Notifications';

    import NotificationsDialog from './NotificationsDialog.vue';

    @Component({ components: { NotificationsDialog } })
    export default class NotificationTest extends Vue {
        private markAllAsRead() {
            notifications.markAllAsRead();
        }

        private clear() {
            notifications.clear();
        }

        private get unreadCount() {
            return notifications.unreadCount;
        }

        private openDialog() {
            notifications.dialogIsVisible = true;
        }

        private notificationSeverity: typeof NotificationSeverity = NotificationSeverity;
        private notificationCategory: typeof NotificationCategory = NotificationCategory;

        public showToastMessage(severity: NotificationSeverity, category: NotificationCategory): void {
            showToastMessage(new NotificationCreateModel(category, 'Ново съобщение.', severity, category));
        }

        public showModalDialog(severity: NotificationSeverity, category: NotificationCategory): void {
            showModalDialog(
                new NotificationCreateModel(category, `Ново модално съобщение.${new Date()}`, severity, category)
            );
        }
    }
</script>
