<template>
    <div>
        <h3>ФАМИЛНА АНАМНЕЗА</h3>
        <v-checkbox
            v-model="value.diabetesRelativesSecond"
            label="Диабет тип 2 или тип 1 (дядо, баба, леля, вуйчо или първи братовчед)"
        ></v-checkbox>
        <v-checkbox
            v-model="value.diabetesRelatives"
            label="Диабет тип 2 или тип 1 (родител, брат, сестра, собствено дете)"
        ></v-checkbox>
        <v-checkbox
            v-model="value.illnessIbsMsb"
            label="Ранно (под 55 г. за мъже - баща, брат и под 65 г. за жени - майка, сестра) заболяване на сърдечно-съдовата система (ИБС и МСБ)"
        ></v-checkbox>
        <v-checkbox
            v-model="value.neoCervix"
            :disabled="isMale"
            label="Жени, чиито майка или сестра са с РМШ"
        ></v-checkbox>
        <v-checkbox
            v-model="value.neoRektosigmoide"
            label="Рак на ректосигмоидалната област при един от родителите, братя, сестри, деца или двама от баба, дядо, леля, чичо, първи братовчед, племенник"
        ></v-checkbox>
        <v-checkbox
            v-model="value.relativesBreast"
            :disabled="isMale"
            label="Рак на млечната жлеза при майка, сестра или дъщеря"
        ></v-checkbox>
        <v-checkbox
            v-model="value.prostateCarcinoma"
            :disabled="!isMale"
            label="Карцином на простатата (баща, брат) при мъже над 40 г."
        ></v-checkbox>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { GenderCode } from '@/enum/Nomenclature/GenderCode';
    import { AnamnesisFamilyDto } from '@/model/Exam/Gp/RiskAssessmentCard/AnamnesisFamilyDto';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    @Component
    export default class AnamnesisFamilyCard extends Vue {
        // Този компонент може да получи undefined v-model. В този случай се bind-ва default стойността, която е празен обект.
        @Prop({ type: Object })
        private value!: AnamnesisFamilyDto;

        private get isMale() {
            return currentPatientCache.value.personIdentification.genderCode === GenderCode.Male;
        }
    }
</script>
