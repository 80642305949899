import { Type } from 'class-transformer';

import { NhisErrorDto } from '@/model/Nhis/NhisErrorDto';
import { NhisResponseWarningDto } from '@/model/Nhis/NhisResponseWarningDto';
import { NhisDoctorsPrescriptionBaseDto } from '@/model/Nhis/Prescription/NhisDoctorsPrescriptionBaseDto';

export class NhisDoctorsPrescriptionDto {
    public totalPrescriptions: number = 0;
    public totalPages: number = 0;
    @Type(() => NhisDoctorsPrescriptionBaseDto)
    public prescriptions: NhisDoctorsPrescriptionBaseDto[] = [];

    public error: string = '';

    @Type(() => NhisErrorDto)
    public errors: NhisErrorDto[] = [];

    @Type(() => NhisResponseWarningDto)
    public warnings: NhisResponseWarningDto[] = [];

    public response: string = '';
}
