import { Route, RouteConfig } from 'vue-router';

const devToolChildRoutes: RouteConfig[] = [
    {
        path: 'Diagnostic',
        components: {
            devTool: () => import('@/view/DevTool/Diagnostic.vue')
        }
    },
    {
        path: 'ResponseWrapperTest',
        components: {
            devTool: () => import('@/view/DevTool/ResponseWrapperTest.vue')
        }
    },
    {
        path: 'NhisNhifAccessToken',
        components: {
            devTool: () => import('@/view/DevTool/NhisNhifAccessToken.vue')
        }
    },
    {
        path: 'SignXml',
        components: {
            devTool: () => import('@/view/DevTool/SignXml.vue')
        }
    },
    {
        path: 'SignCapture',
        components: {
            devTool: () => import('@/view/DevTool/SingCapture.vue')
        }
    },
    {
        path: 'Rhif',
        components: {
            devTool: () => import('@/view/DevTool/Rhif/RhifList.vue')
        }
    },
    {
        path: 'Rhif/Create',
        components: {
            devTool: () => import('@/view/DevTool/Rhif/RhifEdit.vue')
        }
    },
    {
        path: 'Rhif/Edit/:id',
        props: {
            devTool: (route) => ({ id: Number(route.params.id) })
        },
        components: {
            devTool: () => import('@/view/DevTool/Rhif/RhifEdit.vue')
        }
    },
    {
        path: 'RhifArea/:rhifId',
        props: {
            devTool: (route) => ({ rhifId: Number(route.params.rhifId) })
        },
        components: {
            devTool: () => import('@/view/DevTool/Rhif/RhifAreaList.vue')
        }
    },
    {
        path: 'RhifArea/Create/:rhifId',
        props: {
            devTool: (route) => ({ rhifId: Number(route.params.rhifId) })
        },
        components: {
            devTool: () => import('@/view/DevTool/Rhif/RhifAreaEdit.vue')
        }
    },
    {
        path: 'RhifArea/Edit/:rhifId/:id',
        props: {
            devTool: (route) => ({
                id: Number(route.params.id),
                rhifId: Number(route.params.rhifId)
            })
        },
        components: {
            devTool: () => import('@/view/DevTool/Rhif/RhifAreaEdit.vue')
        }
    },
    {
        path: 'Audit',
        components: {
            // Route level code-splitting
            // This generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            devTool: () => import(/* webpackChunkName: "fetch-data" */ '@/view/DevTool/Audit.vue')
        }
    },
    {
        path: 'MultiSelectDropdownTest',
        components: {
            devTool: () => import('@/view/DevTool/MultiSelectDropdownTest.vue')
        }
    },
    {
        path: 'NotificationTest',
        components: {
            devTool: () => import('@/view/DevTool/NotificationTest.vue')
        }
    },
    {
        // Временен параметър за мигриране на blob-овете на нови места.
        path: 'BlobManager/:practiceId',
        props: {
            devTool: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                // Временен параметър за мигриране на blob-овете на нови места.
                migrate: Boolean(route.params.migrate)
            })
        },
        components: {
            devTool: () => import('@/view/File/BlobManager.vue')
        }
    },
    {
        path: 'ExamExtraction',
        components: {
            devTool: () => import('@/view/DevTool/NhisExamExtraction.vue')
        }
    },
    {
        path: 'DoctorsPrescription',
        components: {
            devTool: () => import('@/view/DevTool/NhisDoctorsPrescription.vue')
        }
    },
    {
        path: 'PostXmlNhis',
        components: {
            devTool: () => import('@/view/DevTool/NhisXmlSender.vue')
        }
    }
];

export const devToolRoutes: RouteConfig = {
    path: '/DevTool',
    component: () => import('@/view/DevTool/DevToolMenu.vue'),
    children: devToolChildRoutes
};
