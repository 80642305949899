import { DocTypeCountDto } from '@/model/Exam/DocTypeCountDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const examDocumentMenuService = {
    async getExamDocumentCount(examId: number): Promise<DocTypeCountDto[]> {
        return (await httpService.get<DocTypeCountDto[]>(`/VisitDocumentMenu/GetExamDocumentCount/${examId}`)).data;
    },

    async getNoExamDocumentCount(visitId: number): Promise<DocTypeCountDto[]> {
        return (await httpService.get<DocTypeCountDto[]>(`/VisitDocumentMenu/GetNoExamDocumentCount/${visitId}`)).data;
    }
};
