import { AnamnesticDataCardDto } from '@/model/Register/Pregnancy/PregnancyCard/AnamnesticDataCardDto';
import { ConsultationDataDto } from '@/model/Register/Pregnancy/PregnancyCard/ConsultationDataDto';
import { ExamDataDto } from '@/model/Register/Pregnancy/PregnancyCard/ExamDataDto';
import { LabTestsDataDto } from '@/model/Register/Pregnancy/PregnancyCard/LabTestsDataDto';
import { PregnancyAtRiskTestsDataDto } from '@/model/Register/Pregnancy/PregnancyCard/PregnancyAtRiskTestsDataDto';
import { PregnancyResultDto } from '@/model/Register/Pregnancy/PregnancyCard/PregnancyResultDto';
import { UltrasoundDataDto } from '@/model/Register/Pregnancy/PregnancyCard/UltrasoundDataDto';

export class PregnancyCardDto {
    public id: number = 0;
    public pregnancyId: number = 0;
    public issueDate: Date = new Date();
    public number: number = 0;
    public birthDoctor: string | null = null;
    public anamnesticData: AnamnesticDataCardDto = new AnamnesticDataCardDto();
    public examData: ExamDataDto = new ExamDataDto();
    public ultrasoundData: UltrasoundDataDto = new UltrasoundDataDto();
    public pregnancyResult: PregnancyResultDto = new PregnancyResultDto();
    public consultationData: ConsultationDataDto = new ConsultationDataDto();
    public labTestsData: LabTestsDataDto = new LabTestsDataDto();
    public pregnancyAtRiskTestsData: PregnancyAtRiskTestsDataDto = new PregnancyAtRiskTestsDataDto();
}
