import { AxiosResponse } from 'axios';

import { CertificateDocumentTypeDto } from '@/model/Nomenclature/CertificateDocumentTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const certificateDocumentTypeService = {
    getCertificateDocumentTypes(): Promise<AxiosResponse<CertificateDocumentTypeDto[]>> {
        return httpService.get('/CertificateDocumentType/GetCertificateDocumentTypes');
    }
};
