<template>
    <div :style="`background-color: ${backgroundColor}`">
        <v-card-title class="no-margin-no-padding" :style="isDrugCanceled ? 'color: red' : null"
            >Лекарство №{{ position }}{{ isDrugCanceled ? ' - AНУЛИРАНО' : '' }}</v-card-title
        >
        <v-row no-gutters class="no-margin-no-padding">
            <v-col md="3" sm="6">
                <PrescriptionDiagnosisPicker
                    v-if="isPrescriptionType5 && !isDrugProtocol"
                    v-model="examDiagnosisInternal"
                    :required="isDiagnosisRequired"
                    :diagnoses="examDiagnoses"
                />
            </v-col>
            <v-col md="9" sm="12">
                <keep-alive>
                    <NhifDrugPicker
                        v-if="examDiagnosisInternal && isPrescriptionType5"
                        v-model="nhifDrug"
                        :icd-code="examDiagnosisInternal.icdCode"
                        :protocol-type-code="protocolTypeCode"
                        :is-generic="nhifDrug.isGeneric"
                        :form-type="formType"
                        label="Код на лекарствен продукт:"
                        :required="requiredDrug"
                        @input="onChangeNhifDrug"
                    />
                    <NhisDrugPicker
                        v-else
                        v-model="nhifDrug"
                        :form-type="formType"
                        required
                        @input="onChangeNhifDrug"
                    />
                </keep-alive>
            </v-col>
        </v-row>

        <v-card class="elevation-0" :style="`background-color: ${backgroundColor}`">
            <v-row no-gutters class="no-margin-no-padding">
                <v-col cols="12" md="3">
                    <v-card-text>
                        Генерично заместване
                        <v-radio-group v-model="nhifDrug.isGeneric" row>
                            <v-radio label="Да" :value="true"></v-radio>
                            <v-radio label="Не" :value="false"></v-radio>
                        </v-radio-group>

                        Само при необходимост
                        <v-radio-group v-model="nhifDrug.isNeeded" row>
                            <v-radio label="Да" :value="true"></v-radio>
                            <v-radio label="Не" :value="false"></v-radio>
                        </v-radio-group>

                        <template v-if="nhifDrug.isDivisible && !isDrugProtocol">
                            Начин на предписване
                            <v-radio-group v-model="isQuantityByForm" row>
                                <v-radio label="На брой" :value="true"></v-radio>
                                <v-radio label="На опаковки" :value="false"></v-radio>
                            </v-radio-group>
                        </template>
                    </v-card-text>
                </v-col>
                <v-col md="6">
                    <div v-if="nhifDrug.isGeneric">
                        <v-tooltip top :disabled="!nhifDrug.internationalName">
                            <template #activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <text-field
                                        v-model="nhifDrug.internationalName"
                                        disabled
                                        label="Международно наименование"
                                    />
                                </span>
                            </template>
                            <span>{{ nhifDrug.internationalName }}</span>
                        </v-tooltip>
                    </div>
                    <div v-else>
                        <v-tooltip top :disabled="!nhifDrug.tradeName">
                            <template #activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <text-field v-model="nhifDrug.tradeName" disabled label="Търговско наименование" />
                                </span>
                            </template>
                            <span>{{ nhifDrug.tradeName }}</span>
                        </v-tooltip>
                    </div>

                    <v-row no-gutters class="no-margin-no-padding">
                        <v-col md="3" cols="6">
                            <text-field v-model="nhifDrug.quantity" disabled label="Количество на лек. продукт" />
                        </v-col>
                        <v-col md="3" cols="6">
                            <text-field v-model="nhifDrug.medicineFormat" disabled label="Формата на лек. продукт" />
                        </v-col>
                        <v-col md="3" cols="6">
                            <text-field
                                v-model.number="nhifDrug.singlePackage"
                                label="Количество в една опаковка"
                                disabled
                                type="number"
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <text-field :value="nhifDrug.isDivisible ? 'Да' : 'Не'" disabled label="Делимо" />
                        </v-col>
                    </v-row>

                    <text-field v-if="!calcDosage" disabled label="Схема на приемане" :value="writeDosage(nhifDrug)" />
                    <text-field
                        v-if="calcDosage"
                        v-model="nhifDrug.dosage"
                        required
                        label="Инсулинова доза"
                        hint="Xmg/kg + Xmg/kg + Xmg/kg"
                        :rules="onlyDigitAndSeparatorAndMaxChars"
                        @input="calculateStripsPackageCount"
                    />
                    <v-textarea
                        v-model="nhifDrug.dosageNote"
                        label="Указания за начина на изпълнение на предписанието"
                        rows="2"
                    />
                    <prescription-advertisement :advertisements="advertisements" />
                </v-col>
                <v-col cols="12" md="3">
                    <v-row no-gutters class="no-margin-no-padding">
                        <v-col md="3" cols="6">
                            <text-field
                                v-model.number="nhifDrug.packageCount"
                                :label="nhifDrug.isQuantityByForm ? 'Ед. брой' : 'Брой опаковки'"
                                :min="0"
                                :max="999"
                                type="number"
                                :value="1"
                                :rules="minAndMaxPackageValue"
                                @input="onCountChange"
                            />
                        </v-col>
                        <v-col v-if="!calcDosage" offset="1" md="3" cols="6">
                            <text-field
                                v-model.number="nhifDrug.medicineTakeFrequency"
                                type="number"
                                :min="0"
                                :max="999"
                                :required="isDrugChoose"
                                label="Пъти"
                                :rules="isDrugChoose ? onlyIntegerNumbers : []"
                                @input="calculateDrug"
                            />
                        </v-col>
                        <v-col v-if="!calcDosage" offset="1" md="3" cols="6">
                            <text-field
                                v-model.number="nhifDrug.medicineTakeQuantity"
                                type="number"
                                :min="0"
                                :required="isDrugChoose"
                                :label="isDrugProtocol && nhifDrug.unitMeasure ? `По ${nhifDrug.unitMeasure}` : 'Броя'"
                                value="1"
                                @input="calculateDrug"
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="no-margin-no-padding">
                        <v-col md="3" cols="6" class="mt-4">
                            <text-field
                                v-model.number="nhifDrug.medicineDosagePeriodCount"
                                type="number"
                                :min="0"
                                :max="999"
                                label="Период:"
                                @input="calculateDrug"
                            />
                        </v-col>
                        <v-col offset="1" md="3" cols="6">
                            <v-select
                                v-model="nhifDrug.medicineDosageUnitCode"
                                :items="nhisPeriodUnits"
                                item-text="name"
                                item-value="code"
                                label="Период за:"
                                @input="calculateDrug"
                            />
                        </v-col>
                        <v-col offset="1" md="3" cols="6" class="mt-4">
                            <v-text-field
                                v-model.number="nhifDrug.therapyDuration"
                                type="number"
                                :min="1"
                                :max="999"
                                :hint="hintMessage"
                                persistent-hint
                                dense
                                :rules="!checkStrips ? minAndMaxDaysTakingDrug : minAndMaxDaysCheckStrips"
                                label="Продължителност на лечението"
                            >
                                <template #message="{ message }">
                                    <span style="color: var(--v-error-base)">{{ message }}</span>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="no-margin-no-padding align-baseline">
                        <v-col md="6" cols="12">
                            <text-field
                                v-if="isPrescriptionType5 && !isDrugProtocol"
                                v-model="daysSummit"
                                label="Дни с натрупване"
                                disabled
                            />
                        </v-col>
                        <v-col md="3" cols="12">
                            <v-checkbox
                                v-if="isPrescriptionType5 && !isDrugProtocol"
                                v-model="nhifDrug.isFullPayed"
                                label="Изцяло заплатено"
                                disabled
                            />
                        </v-col>
                        <v-row no-gutters>
                            <v-col cols="3">
                                <text-field
                                    v-if="isPrescriptionType5"
                                    v-model.number="nhifDrug.maxPrice"
                                    disabled
                                    label="Единична цена"
                                />
                            </v-col>
                            <v-col cols="3" offset="1">
                                <text-field
                                    v-if="isPrescriptionType5"
                                    v-model.number="nhifDrug.maxPatientPrice"
                                    disabled
                                    label="От пациента"
                                />
                            </v-col>
                            <v-col cols="3" offset="1">
                                <text-field
                                    v-if="isPrescriptionType5"
                                    v-model.number="nhifDrug.nhifPrize"
                                    disabled
                                    label="Стойност заплащана от РЗОК"
                                />
                            </v-col>
                        </v-row>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <alert :value="!isItCalculating" type="info">
            Внимание! За даденото лекарство няма достатъчно информация и не e възможно да се изчисли продължителността
            на лечение, моля въведете
            <span style="color: var(--v-success-base)">ръчно продължителността на лечението</span>
        </alert>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import PrescriptionDiagnosisPicker from '@/component/Exam/PrescriptionDiagnosisPicker.vue';
    import { NhifPrescriptionSegmentCode } from '@/enum/Exam/NhifPrescriptionSegmentCode';
    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { NhisPeriodUnitCode, nhisPeriodUnits } from '@/enum/Nhis/NhisPeriodUnitCode';
    import { NhisPrescriptionStatusCode } from '@/enum/Nhis/NhisPrescriptionStatusCode';
    import { AdvertisementDto } from '@/model/Advertisement/AdvertisementDto';
    import { AdvertisementFilterCommand } from '@/model/Advertisement/AdvertisementFilterCommand';
    import { PrescriptionDrugConfig } from '@/model/Exam/Prescription/PrescriptionDrugConfig';
    import { PrescriptionDiagnosisDto } from '@/model/Exam/PrescriptionDiagnosisDto';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { externalVisitService } from '@/service/Exam/ExternalVisitService';
    import { patientPrescriptionDrugService } from '@/service/Query/Patient/PatientPrescriptionDrugService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import NhisDrugPicker from '@/view/Exam/PrescriptionNhif/NhisDrugPicker.vue';
    import PrescriptionAdvertisement from '@/view/Exam/PrescriptionNhif/PrescriptionAdvertisement.vue';

    import NhifDrugPicker from './NhifDrugPicker.vue';

    type ruleType<T> = ((val: T) => void)[] | boolean[] | string[];

    const drugConfig = {
        maxPackageCount: 999,
        maxDays: 30,
        daysInYear: 365,
        daysInMonth: 30,
        daysInWeek: 7,
        hoursPerDay: 24,
        minutesPerHour: 60,
        secondsPerMinute: 60,
        // Средна стойност на една капка в мл (20 капки = 1 мл)
        oneDropInMl: 0.05,
        oneDropOfGel: 1,
        defaultQuantityInMl: 5,
        maxDaysTakingDrug: 30,
        maxDosageChars: 100,
        checkStripsName: 'ТЕСТ ЛЕНТИ'
    };

    const drugRegexConfig = {
        regexForMl: /(?<quantity>[\d+.,?\d*]+\s?(?<type>ml|ML)+)/gu,
        regexForGrams: /(?<quantity>[\d+.,?\d*]+\s?(?<type>g|гр.)+)/gu,
        regexForDoses: /(?<quantity>[\d+.,?\d*]+\s?(?<type>dos|doses)+)/gu,
        regexForDigits: /(?<digit>[\s,+ /]+)/gu,
        regexForMcgToKg: /(?<quantity>[0-9]{1,3}[^IUml]*)/gu
    };

    @Component({
        computed: {
            AdvertisementFilterCommand() {
                return AdvertisementFilterCommand;
            }
        },
        components: { PrescriptionAdvertisement, PrescriptionDiagnosisPicker, NhisDrugPicker, NhifDrugPicker },
        inheritAttrs: true
    })
    export default class DrugComponent extends Vue {
        @Prop()
        private value!: NhifDrugDto;

        @Prop()
        private protocolTypeCode!: string;

        @Prop()
        private examId!: number;

        @Prop({ default: NhifPrescriptionSegmentCode.A })
        private segmentCode!: NhifPrescriptionSegmentCode;

        @Prop()
        private position!: number;

        @Prop()
        private examDiagnosis!: PrescriptionDiagnosisDto;

        @Prop()
        private diag!: number;

        @Prop()
        private formType!: string;

        @Prop()
        private requiredDrug!: boolean;

        @Prop()
        private daysSummit!: number;

        @Prop()
        private examDiagnoses!: PrescriptionDiagnosisDto[];

        @Prop()
        private isDrugProtocol!: boolean;

        @Prop({ default: 'transparent' })
        private backgroundColor!: string;

        @Prop({ default: () => [] })
        private advertisements!: AdvertisementDto[];

        @Prop({ default: () => new Date() })
        private issueDate!: Date | null;

        private nhifDrug: NhifDrugDto = new NhifDrugDto();
        private isGeneric: boolean = false;
        private examDiagnosisInternal: PrescriptionDiagnosisDto = new PrescriptionDiagnosisDto();
        private isItCalculating: boolean = true;
        private nhisPeriodUnits: { code: NhisPeriodUnitCode; name: string }[] = nhisPeriodUnits;
        private checkStrips: boolean = false;
        private calcDosage: boolean = false;
        private initialDosage: string = '';

        private get isDrugCanceled() {
            return this.nhifDrug.statusCode === NhisPrescriptionStatusCode.Canceled;
        }

        private get isPrescriptionType5() {
            return (
                this.formType !== PrescriptionFormTypeCode.White &&
                this.formType !== PrescriptionFormTypeCode.Green &&
                this.formType !== PrescriptionFormTypeCode.Yellow
            );
        }

        private get isPrescriptionTypeWhite() {
            return this.formType === PrescriptionFormTypeCode.White;
        }

        private onlyDigitAndSeparatorAndMaxChars: ruleType<string> = [
            (val: string) =>
                (val && !val.match(/[a-zA-Zа-яА-Я]+/gmu)) ||
                'Допустимият шаблон за описване се състои от число и разделител от тип: "интервал", "+", "/", "запетая"',
            (val: string) =>
                (val && val.length <= drugConfig.maxDosageChars) ||
                `Дължината на текста не може да е повече от ${drugConfig.maxDosageChars} символа`
        ];

        private minAndMaxPackageValue: ruleType<number> = [
            (val: number) => (val && val > 0) || val === 0 || 'Броят на опаковките не може е отрицателно',
            (val: number) =>
                val <= drugConfig.maxPackageCount ||
                `Броят на опаковките не може да е повече от ${drugConfig.maxPackageCount}`
        ];

        private minAndMaxDaysTakingDrug: ruleType<number> = [
            (val: number) =>
                (val && val >= 0) || "'Продължителността на лечението' не може да е отрицателно или равно на 0 число"
        ];

        private minAndMaxDaysCheckStrips: ruleType<number> = [
            (val: number) =>
                (val && val >= 0) || "'Продължителността на лечението' не може е отрицателно или равно на 0 число",
            (val: number) =>
                val <= drugConfig.daysInYear ||
                `Максималният брой дни 'Продължителността на лечението' не може да е повече от ${drugConfig.daysInYear}`
        ];

        private onlyIntegerNumbers: ruleType<number> = [
            (val: number) => Number.isInteger(val) || 'Допуска се въвеждането само на цели числа'
        ];

        private get isDrugChoose() {
            return Boolean(this.nhifDrug.tradeName);
        }

        private get hintMessage() {
            if (
                this.nhifDrug !== null &&
                this.nhifDrug.therapyDuration &&
                this.nhifDrug.therapyDuration > drugConfig.maxDays &&
                !this.isDrugProtocol
            ) {
                if (this.checkStrips) {
                    return '';
                }
                return 'Продължителността на лечение е препоръчително да бъде до 30 дни';
            }

            return '';
        }

        private onChangeNhifDrug(value: NhifDrugDto | null) {
            if (!value) {
                this.nhifDrug = new NhifDrugDto();
            }
            this.nhifDrug.isQuantityByForm = (value?.isDivisible && !this.isPrescriptionTypeWhite) ?? false;
            this.isItCalculating = true;
            this.emitNhifDrug();
        }

        private emitNhifDrug() {
            this.nhifDrug.position = this.position;
            this.nhifDrug.examDiagnosisId = this.examDiagnosis?.id;
            this.nhifDrug.isExamDiagnosisAdd = this.examDiagnosis?.isAdd;
            this.nhifDrug.segmentCode = this.segmentCode;
            this.setDrugType(this.nhifDrug);
            this.calculateDaysTakingDrug(this.nhifDrug);
            this.$emit('input', this.nhifDrug);
            this.$emit('update-nhif', {
                nhifDrug: this.nhifDrug,
                segmentCode: this.segmentCode,
                position: this.position
            });
        }

        private async checkForCoronary(nhifCode: string) {
            if (PrescriptionDrugConfig.coronaryDrugs.indexOf(nhifCode) >= 0) {
                const { patientId } = currentPatientCache.value.key;
                const eventExists = await externalVisitService.patientHasCoronaryEvent(patientId);
                if (!eventExists) {
                    this.$notifier.showWarning(
                        'Внимание',
                        'Не е открит запис в регистъра на пациента за коронарен инциидент. Моля въведете в регистъра или сменете лекарството'
                    );
                } else {
                    const warning = await patientPrescriptionDrugService.checkForCoronaryDrugExceededDuration(
                        patientId,
                        nhifCode,
                        this.issueDate ?? new Date()
                    );
                    if (warning) {
                        this.$notifier.showWarning('Внимание', warning);
                    }
                }
            }
        }

        //При поредното изписване на Spiriva пълнител (RF220) ако са минали поне 6 месеца от последното изписване
        //на пълнител+устройство (RF177), да предложи да се изпише отново на RF177
        //При поредното изписване на Spiolto пълнител (RF219) ако са минали поне 6 месеца от последното изписване
        //на пълнител+устройство (RF202), да предложи да се изпише отново на RF202
        private async checkForSpirivaSpiolto(nhifDrugCode: string) {
            const spirivaFiller = 'RF220';
            const spirivaUnit = 'RF177';
            const spioltoFiller = 'RF219';
            const spioltoUnit = 'RF202';

            if (nhifDrugCode === spioltoFiller || nhifDrugCode === spirivaFiller) {
                const warning = await patientPrescriptionDrugService.checkForSpirivaPrescribing(
                    currentPatientCache.value.key.patientId,
                    nhifDrugCode === spirivaFiller ? spirivaUnit : spioltoUnit
                );
                if (warning) {
                    this.$notifier.showWarning('Внимание', warning);
                }
            }
        }

        @Watch('value', { immediate: true })
        private async onValueChange(drug: NhifDrugDto | null) {
            if (!drug) {
                this.nhifDrug = new NhifDrugDto();
            } else {
                this.nhifDrug = drug;
                await this.checkForSpirivaSpiolto(drug.nhifCode);
                await this.checkForCoronary(drug.nhifCode);
            }
            this.setDrugType(this.nhifDrug);
        }

        private get isDiagnosisRequired() {
            return Boolean(this.requiredDrug || (this.nhifDrug && this.nhifDrug.tradeName));
        }

        @Watch('examDiagnosisInternal')
        private onExamDiagnosisChanged(newExamDiagnosis: PrescriptionDiagnosisDto) {
            if (newExamDiagnosis) {
                this.$emit('exam-diagnosis-selected', {
                    examDiagnosisId: newExamDiagnosis.id,
                    position: this.position,
                    isAdd: newExamDiagnosis.isAdd
                });
            }
        }

        @Watch('examDiagnosis', { immediate: true, deep: true })
        private onExamDiagStart(val: PrescriptionDiagnosisDto) {
            if (val && val.id) {
                this.examDiagnosisInternal = val;
            }
        }

        private get isQuantityByForm() {
            return this.nhifDrug.isQuantityByForm;
        }

        private set isQuantityByForm(value: boolean) {
            if (this.nhifDrug) {
                this.nhifDrug.isQuantityByForm = value;
                this.calculateDrug();
            }
        }

        // Следният метод преобразува мерната единица за време в дни, защото при изпращането на данните
        // е нужно да се пратят дните на прием
        private onPeriodAndUnitCodeDosageChange(period: number, code: string, therapyDuration: number): number {
            if (
                this.nhifDrug &&
                (this.nhifDrug?.nhifId || this.nhifDrug?.nhisId || 0) > 1 &&
                this.nhifDrug.therapyDuration
            ) {
                if (code === NhisPeriodUnitCode.Second) {
                    return Math.ceil(
                        period / (drugConfig.hoursPerDay * drugConfig.minutesPerHour * drugConfig.secondsPerMinute)
                    );
                } else if (code === NhisPeriodUnitCode.Minute) {
                    return Math.ceil(period / (drugConfig.hoursPerDay * drugConfig.minutesPerHour));
                } else if (code === NhisPeriodUnitCode.Hour) {
                    return Math.ceil(period / drugConfig.hoursPerDay);
                } else if (code === NhisPeriodUnitCode.Day) {
                    return therapyDuration * period;
                } else if (code === NhisPeriodUnitCode.Week) {
                    return therapyDuration * period * drugConfig.daysInWeek;
                } else if (code === NhisPeriodUnitCode.Month) {
                    return therapyDuration * period * drugConfig.daysInMonth;
                } else if (code === NhisPeriodUnitCode.Year) {
                    return therapyDuration * period * drugConfig.daysInYear;
                }
            }
            return 0;
        }

        private writeDosage(nhifDrug: NhifDrugDto) {
            let dayValue: string = 'брой';
            let timesValue: string = 'път';

            if (nhifDrug.medicineTakeFrequency && nhifDrug.medicineTakeQuantity) {
                if (nhifDrug.medicineTakeQuantity > 1) {
                    dayValue = 'броя';
                }
                if (nhifDrug.medicineTakeFrequency > 1) {
                    timesValue = 'пъти';
                }
                nhifDrug.dosage = `${nhifDrug.medicineTakeFrequency} ${timesValue} по ${nhifDrug.medicineTakeQuantity} ${dayValue}`;
                return nhifDrug.dosage;
            }
            return "Моля въведете данни за 'Пъти' и 'Броя' на приема лек. продукт";
        }

        // Ако няма да се използва, да се изтрие. Ако pluralName няма да се използва - също.
        private mapDosageUnitCode(code: string, period: number): string {
            const nhisPeriodUnit = nhisPeriodUnits.find((unit) => unit.code === code);
            return nhisPeriodUnit ? (period > 1 ? nhisPeriodUnit.pluralName : nhisPeriodUnit.name) : '';
        }

        private calculateDaysTakingDrug(nhifDrug: NhifDrugDto) {
            const medFormat = nhifDrug.medicineFormat.toUpperCase();
            let daysTaking = 0;
            if (PrescriptionDrugConfig.itemsGivingByQuantityPerDay.includes(medFormat) || this.checkStrips) {
                daysTaking = this.basicCalculation(nhifDrug.singlePackage, 1, this.nhifDrug.packageCount);
            } else if (PrescriptionDrugConfig.earAndEyeDrops.indexOf(medFormat) >= 0) {
                daysTaking = this.calculateEarAndEyeDrops(nhifDrug);
                //Продукти, който са по един брой или се премат наведнъж
            } else if (PrescriptionDrugConfig.itemsForOneTimeUse.indexOf(medFormat) >= 0) {
                daysTaking = this.basicCalculation(nhifDrug.singlePackage, 1, this.nhifDrug.packageCount);
            } else if (PrescriptionDrugConfig.gelAndOintmentItems.indexOf(medFormat) >= 0) {
                daysTaking = this.basicCalculation(
                    this.findQuantityInOnePackage(nhifDrug.quantity),
                    drugConfig.oneDropOfGel,
                    this.nhifDrug.packageCount
                );
            } else if (PrescriptionDrugConfig.itemsWithDoseInName.indexOf(medFormat) >= 0) {
                daysTaking = this.basicCalculation(
                    this.findQuantitySpray(nhifDrug.quantity),
                    1,
                    this.nhifDrug.packageCount
                );
            } else {
                this.drugThatCannotCalculate();
            }
            if (daysTaking > 0) {
                this.nhifDrug.therapyDuration = this.onPeriodAndUnitCodeDosageChange(
                    this.nhifDrug.medicineDosagePeriodCount,
                    this.nhifDrug.medicineDosageUnitCode,
                    daysTaking ?? 0
                );
            }
        }

        private calculateProtocolDaysTakingDrug(nhifDrug: NhifDrugDto) {
            let daysTaking = 0;

            daysTaking = this.basicCalculation(
                this.nhifDrug.singlePackage,
                nhifDrug.unit ?? 0,
                this.nhifDrug.packageCount
            );

            if (daysTaking > 0) {
                this.nhifDrug.therapyDuration = this.onPeriodAndUnitCodeDosageChange(
                    this.nhifDrug.medicineDosagePeriodCount,
                    this.nhifDrug.medicineDosageUnitCode,
                    daysTaking ?? 0
                );
            }
        }

        private setDrugType(nhifDrug: NhifDrugDto) {
            const medFormat = nhifDrug.medicineFormat;
            const { internationalName } = nhifDrug;
            if (medFormat !== drugConfig.checkStripsName) {
                this.checkStrips = false;
                this.calcDosage = false;
            }
            if (medFormat === drugConfig.checkStripsName) {
                // Изключение има за Тест-лентите за глюкомери те се предписват за цяла година или за 4 месеца
                this.configureForCheckStrips();
            } else if (internationalName?.includes(PrescriptionDrugConfig.insulin)) {
                this.calcDosage = true;
            }
        }

        private basicCalculation(
            quantityInOnePackage: number,
            quantityInOneSimple: number,
            packageCount: number
        ): number {
            if (this.nhifDrug.medicineTakeFrequency && this.nhifDrug.medicineTakeQuantity) {
                let days: number = 0;
                if (this.nhifDrug.isDivisible && this.nhifDrug.isQuantityByForm) {
                    //Когато изчисляваме делимо лекарство умножаваме packageCount
                    // (в този случай това са отделните бройки) * количеството в една бройка(флакон или друго)
                    days = Math.floor(
                        (packageCount * quantityInOneSimple) /
                            (this.nhifDrug.medicineTakeFrequency * this.nhifDrug.medicineTakeQuantity)
                    );
                } else {
                    //При неделимите лекарства
                    // умножаваме броя на опаковките * броя единици в една опаковка * по количеството на една единица
                    days = Math.floor(
                        (quantityInOnePackage * packageCount * quantityInOneSimple) /
                            (this.nhifDrug.medicineTakeFrequency * this.nhifDrug.medicineTakeQuantity)
                    );
                }

                return days;
            }

            return 1;
        }

        private findQuantityOfDrugInMl(quantity: string) {
            const found = this.regexFindResult(quantity, drugRegexConfig.regexForMl);
            const quantityOfDrugInMl: number = found
                ? Number(found[0].replace(/[^\d.,]/gu, ''))
                : drugConfig.defaultQuantityInMl;
            return quantityOfDrugInMl;
        }

        private calculateEarAndEyeDrops(nhifDrug: NhifDrugDto) {
            const days = this.basicCalculation(
                this.findQuantityOfDrugInMl(nhifDrug.quantity),
                drugConfig.oneDropInMl,
                nhifDrug.packageCount
            );
            if (days > drugConfig.maxDaysTakingDrug) {
                return drugConfig.maxDaysTakingDrug;
            }
            return days;
        }

        private findQuantityInOnePackage(quantity: string) {
            const foundQuantity = this.regexFindResult(quantity, drugRegexConfig.regexForGrams);
            const quantityInOnePackage: number = foundQuantity ? Number(foundQuantity[0].replace(/\D/gu, '')) : 0;
            return quantityInOnePackage;
        }

        private findQuantitySpray(quantity: string) {
            const foundSpray = this.regexFindResult(quantity, drugRegexConfig.regexForDoses);
            const quantityInOnePackage: number = foundSpray ? Number(foundSpray[0].replace(/\D/gu, '')) : 0;
            return quantityInOnePackage;
        }

        private configureForCheckStrips() {
            this.checkStrips = true;
            this.calcDosage = false;
            this.nhifDrug.isGeneric = true;
        }

        private regexFindResult(quantity: string, regex: RegExp) {
            const found = quantity.match(regex);
            if (found) {
                return found;
            }
            return this.drugThatCannotCalculate();
        }

        // Ето тук ще се събират всички други продукти , които няма данни с който да се сметнат дните
        private drugThatCannotCalculate() {
            if (this.nhifDrug && this.nhifDrug.tradeName) {
                this.isItCalculating = false;
            }
        }

        private calculateStripsPackageCount(value: string) {
            if (value && this.nhifDrug.internationalName?.includes(PrescriptionDrugConfig.insulin)) {
                this.initialDosage = value;
                this.calculateInsulineDaysTaking(value);
            }
        }

        private calculateInsulineDaysTaking(value: string) {
            if (value && this.nhifDrug.packageCount) {
                //IU => international unit
                //Това е предвидено основно за смятане на дните на прием на инсулин и на лекарствата които се приемата mcg/кг
                const dosage = value;
                const splitDosage: number = dosage
                    .split(/[\s,+/]+/u)
                    .map((numb) => Number(numb))
                    .reduce((prevValue, currentVal) => prevValue + currentVal, 0);
                const match = Array.from(this.nhifDrug.quantity.matchAll(drugRegexConfig.regexForMcgToKg), (matchStr) =>
                    Number(matchStr[0])
                );
                const quantityUIperOnePenfil: number =
                    match.length > 0 && match.length === 1 ? match[0] : match[0] * match[1];

                if (splitDosage >= 1) {
                    if (this.nhifDrug.isQuantityByForm) {
                        this.nhifDrug.therapyDuration = Math.floor(
                            (quantityUIperOnePenfil * this.nhifDrug.packageCount) / splitDosage
                        );
                        this.nhifDrug.prescribedDays = Math.floor(
                            (quantityUIperOnePenfil * (this.nhifDrug.totalQuantity ?? 0)) / splitDosage
                        );
                    } else {
                        this.nhifDrug.therapyDuration = Math.floor(
                            (quantityUIperOnePenfil * this.nhifDrug.packageCount * this.nhifDrug.singlePackage) /
                                splitDosage
                        );
                        this.nhifDrug.prescribedDays = Math.floor(
                            (quantityUIperOnePenfil *
                                (this.nhifDrug.totalQuantity ?? 0) *
                                this.nhifDrug.singlePackage) /
                                splitDosage
                        );
                    }
                }

                this.nhifDrug.therapyDuration = this.onPeriodAndUnitCodeDosageChange(
                    this.nhifDrug.medicineDosagePeriodCount,
                    this.nhifDrug.medicineDosageUnitCode,
                    this.nhifDrug.therapyDuration ?? 0
                );
            }
        }

        private calculateDrug() {
            if (this.nhifDrug) {
                if (this.nhifDrug.internationalName?.includes(PrescriptionDrugConfig.insulin)) {
                    this.calculateInsulineDaysTaking(this.initialDosage);
                } else if (this.isDrugProtocol && this.nhifDrug.unit) {
                    this.calculateProtocolDaysTakingDrug(this.nhifDrug);
                } else {
                    this.calculateDaysTakingDrug(this.nhifDrug);
                }
            }
        }

        private onCountChange(value: number) {
            if (this.calcDosage) {
                this.nhifDrug.packageCount = value;
                this.calculateInsulineDaysTaking(this.initialDosage);
            } else {
                this.calculateDrug();
            }
        }
    }
</script>
