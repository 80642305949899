import { Type } from 'class-transformer';

import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';

export class NhisPrescriptionGetXmlCommand {
    @Type(() => PrescriptionKey)
    public key: PrescriptionKey;

    constructor(
        key: PrescriptionKey,
        public doctorUin: string,
        public deputyDoctorUin: string | null
    ) {
        this.key = key;
    }
}
