<template>
    <v-card>
        <v-card-title>Услуга</v-card-title>
        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn action="Save" @click="save"></btn>
            <v-spacer></v-spacer>
            <btn v-if="seqNumber" action="Delete" @click="deleteService"></btn>
        </v-card-actions>
        <v-form ref="form" v-model="isValid">
            <v-card-text>
                <service-title
                    v-model="practiceService.serviceTitle"
                    :alert-text="alertText"
                    :categories="categories"
                />
                <v-row>
                    <v-radio-group
                        v-model="practiceService.coreServiceTypeCode"
                        row
                        class="ml-3"
                        @change="onServiceTypeChange"
                    >
                        <template #label>
                            <div><strong>Вид дейност : </strong></div>
                        </template>
                        <v-radio
                            v-for="cdt in coreServiceTypes"
                            :key="cdt.code"
                            :value="cdt.code"
                            :label="cdt.name"
                        ></v-radio>
                    </v-radio-group>
                </v-row>
                <v-row>
                    <v-col v-for="(item, index) in practiceService.serviceOffers" :key="index" cols="2">
                        <v-row
                            ><text-field
                                v-model="item.patientPrice"
                                :label="'Цена ' + item.tariffName"
                                suffix="лв."
                                type="number"
                                :min="0"
                                class="ml-3"
                                @input="chechForOffer()"
                        /></v-row>
                        <v-row>
                            <dropdown
                                v-if="practiceService.coreServiceTypeCode"
                                :key="seqNumber"
                                v-model="item.selectedFundService"
                                return-object
                                :label="dropdownLabel"
                                :items="
                                    item.selectedFundService
                                        ? filteredFundServices.filter(
                                              (fs) => fs.fundCode === item.tariffDefaultFundCode
                                          )
                                        : filteredFundServices
                                "
                                class="ml-3"
                            ></dropdown>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ServiceTitle from '@/component/Practice/ServiceTitle.vue';
    import { FundServiceDto } from '@/model/Nomenclature/FundServiceDto';
    import { CoreServiceTypeDto } from '@/model/Practice/Service/CoreServiceTypeDto';
    import { PracticeServiceEditDto } from '@/model/Practice/Service/PracticeServiceEditDto';
    import { ServiceCategoryDto } from '@/model/Practice/Service/ServiceCategoryDto';
    import { ServiceOfferEditDto } from '@/model/Practice/Service/ServiceOfferEditDto';
    import { notifierService } from '@/service/Infrastructure/NotifierService';
    import { practiceServiceService } from '@/service/Practice/Service/PracticeServiceService';
    import { tariffService } from '@/service/Practice/Service/TariffService';

    @Component({
        components: {
            ServiceTitle
        }
    })
    export default class Service extends Vue {
        @Prop()
        private practiceId!: number;

        @Prop()
        private seqNumber!: number;

        private isValid: boolean = false;
        private hasOffer: boolean = false;
        private practiceService: PracticeServiceEditDto = new PracticeServiceEditDto();
        private coreServiceTypes: CoreServiceTypeDto[] = [];
        private fundServices: FundServiceDto[] = [];
        private filteredFundServices: FundServiceDto[] = [];
        private dropdownLabel: string = '';
        private alertText: string = '';
        private categories: ServiceCategoryDto[] = [];

        private async mounted() {
            if (this.seqNumber) {
                this.practiceService = await practiceServiceService.getPracticeService(this.practiceId, this.seqNumber);
                this.onServiceTypeChange();
            } else {
                this.practiceService.practiceId = this.practiceId;
                const tariffs = await tariffService.getPracticeTariffs(this.practiceId);
                for (const tariff of tariffs) {
                    const serviceOffer = new ServiceOfferEditDto();
                    serviceOffer.tariffName = tariff.name;
                    serviceOffer.tariffSeqNumber = tariff.seqNumber;
                    serviceOffer.selectedFundService = new FundServiceDto();
                    serviceOffer.selectedFundService.fundCode = tariff.defaultFundCode;
                    serviceOffer.tariffDefaultFundCode = tariff.defaultFundCode ?? '';
                    this.practiceService.serviceOffers.push(serviceOffer);
                }
            }
            this.coreServiceTypes = await practiceServiceService.getCoreServiceTypes();
            const codeServiceTypeNone = new CoreServiceTypeDto();
            codeServiceTypeNone.name = 'Няма';
            this.coreServiceTypes.unshift(codeServiceTypeNone);
            this.categories = await practiceServiceService.getServiceCategories(this.practiceId);
        }

        private async save() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (
                this.practiceService.coreServiceTypeCode &&
                this.practiceService.serviceOffers.every((so) => !so.selectedFundService?.seqNumber)
            ) {
                notifierService.showWarning('Грешка', `Моля изберете '${this.dropdownLabel}'`);
                return;
            }

            if (this.isValid) {
                this.chechForOffer();
                if (!this.hasOffer) {
                    return;
                }
                const result = await practiceServiceService.updatePracticeService(this.practiceService);
                if (!result) {
                    notifierService.showSuccess('Успех', 'Данните са записани успешно');
                    this.$router.push(`/Finance/Service/PriceList/${this.practiceId}`);
                } else {
                    this.alertText = result;
                }
            }
        }

        private showMissingOfferError() {
            if (!this.seqNumber) {
                notifierService.showWarning('Грешка', 'Услугата трябва да се предлага поне с една тарифа.');
            } else {
                notifierService.showWarning(
                    'Грешка',
                    "Ако услугата вече не се предлага по нито една тарифа, използвайте бутона 'Изтриване' за цялата услуга."
                );
            }
        }

        private async deleteService() {
            const response = await practiceServiceService.deletePracticeService(this.practiceId, this.seqNumber);
            if (response) {
                notifierService.showWarning('Грешка', response);
                return;
            }
            notifierService.showSuccess('Успех', 'Услугата е изтрита');
            this.$router.push(`/Finance/Service/PriceList/${this.practiceId}`);
        }

        private chechForOffer() {
            this.hasOffer = this.practiceService.serviceOffers.some((offer) => offer.patientPrice !== null);
            if (!this.hasOffer) {
                this.showMissingOfferError();
            }
        }

        private async onServiceTypeChange() {
            if (!this.fundServices.length && this.practiceService.coreServiceTypeCode) {
                this.fundServices = await practiceServiceService.getFundServices(this.practiceId);
            }
            if (this.practiceService.coreServiceTypeCode) {
                this.filteredFundServices = this.fundServices.filter(
                    (fs) => fs.computedCoreServiceTypeCode === this.practiceService.coreServiceTypeCode
                );
                this.dropdownLabel = this.coreServiceTypes.find(
                    (cst) => cst.code === this.practiceService.coreServiceTypeCode
                )?.name as string;
            } else {
                this.filteredFundServices = this.fundServices;
                this.dropdownLabel = '';
            }
        }

        private get backRoute() {
            return `/Finance/Service/PriceList/${this.practiceId}`;
        }
    }
</script>
