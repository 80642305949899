import Vue, { DirectiveBinding } from 'vue';

import { config } from './Config';

declare global {
    interface HTMLElement {
        clickOutsideEvent: (event: Event) => void;
    }
}

const initVueDirectives = () => {
    // Това е само едно от местата, където е описана click outside директива, но не е изчерпателен пример.
    // https://www.hoseinh.com/vue-custom-directives-how-to-detect-click-outside/
    Vue.directive('custom-click-outside', {
        //bind(el, binding, vnode) {
        bind(el: HTMLElement, binding: DirectiveBinding<(event: Event) => void>) {
            el.clickOutsideEvent = (event) => {
                event.stopPropagation();
                // Подадената стойност на директивата може да се достъпи по два начина:
                // през binding.value или през vnode.context[binding.expression].
                // Например, при v-custom-click-outside="closeMe", binding.value съдържа функцията closeMe,
                // а binding.expression съдържа string-а "closeMe".
                const handler = binding.value;
                //const handler = vnode.context[binding.expression];
                if (!el.contains(event.target as Node)) {
                    handler(event);
                }
            };
            document.addEventListener('click', el.clickOutsideEvent);
            document.addEventListener('touchstart', el.clickOutsideEvent);
        },

        unbind(el) {
            document.removeEventListener('click', el.clickOutsideEvent);
            document.removeEventListener('touchstart', el.clickOutsideEvent);
        }
    });
};

// Проверка за съвпадение между името на компонента и името на файла - отнема 1 секунда.
// Има такова eslint правило (vue/match-component-file-name), но то не работи за class based components.
const validateVueComponentNames = () => {
    if (!config.isDevelopment) {
        return;
    }
    console.log('Проверка на имената на Vue компонентите и файловете им...');

    // Параметрите на require.context() трябва да бъдат литерали, не могат да бъдат променливи.
    // Пътят './' е релативен спрямо папката на този файл, т.е. в случая води до 'src/'.
    const componentFiles = require.context('./', true, /\.vue$/iu);

    const startTime = Date.now();
    const relativePaths = componentFiles.keys();
    for (const relativePath of relativePaths) {
        const fileName = relativePath.split('/').pop();
        // На теория pop() може да върне undefined, но split() винаги връща поне един елемент.
        if (fileName) {
            const fileConfig = componentFiles(relativePath);
            // Look for the component options on `.default`, which will exist if the component was exported with "export default".
            const componentConfig = fileConfig.default;
            if (componentConfig) {
                const [fileNameWoExt] = fileName.split('.');
                if (componentConfig.name !== fileNameWoExt) {
                    console.log(`Файл '${fileName}' експортира компонент с друго име '${componentConfig.name}'.`);
                }
            } else {
                console.log(`Липсва export default във файл '${fileName}'.`);
            }
        }
    }
    console.log(`Проверени ${relativePaths.length} .vue файла за ${Date.now() - startTime} ms.`);
};

// Неуспешен опит бавната проверка на имената на компонентите да се пусне асинхронно.
// Независимо дали се извиква в main.ts, в App.vue или след зареждане на кешовете, рендирането на SPA се забавя с 1 секунда.
const beginValidateVueComponentNames = () =>
    new Promise<void>((resolve, reject) => {
        try {
            validateVueComponentNames();
            resolve();
        } catch (ex: unknown) {
            reject(ex);
        }
    });

export { beginValidateVueComponentNames, initVueDirectives };
