<template>
    <dropdown
        :value="value"
        :items="drugs"
        :item-text="isGeneric ? 'internationalName' : 'tradeName'"
        item-value="nhifCode"
        :label="label"
        :required="required"
        :rules="required ? requiredField : []"
        clearable
        return-object
        is-cyrillic-to-latin
        @input="$emit('input', ...arguments)"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { NhifDrugSearchDto } from '@/model/Nomenclature/NhifDrugSearchDto';
    import { nhifDrugService } from '@/service/Nomenclature/NhifDrugService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class NhifDrugPicker extends Vue {
        @Prop()
        private value!: NhifDrugDto | null;

        @Prop({ required: true })
        private label!: string;

        @Prop({ type: String })
        private icdCode!: string;

        @Prop({ type: String })
        private protocolTypeCode!: string;

        @Prop()
        private required!: boolean;

        @Prop({ type: Boolean })
        private isGeneric!: boolean;

        @Prop()
        private formType!: string;

        @Prop()
        private requirementId!: number | null;

        private requiredField: ((val: NhifDrugDto, required: boolean) => void)[] | boolean[] | string[] = [
            (val: NhifDrugDto, required: boolean) =>
                required || (val && val.tradeName !== '') || `Попълнете задължителните полета`
        ];

        private drugs: NhifDrugDto[] = [];

        private createNhifDrugDto(icdCode: string, formType: string, protocolType: string) {
            const nhifDrugDto = new NhifDrugSearchDto(
                icdCode,
                formType,
                protocolType,
                currentVisitCache.value.startDateTime
            );
            nhifDrugDto.requirementId = this.requirementId;
            return nhifDrugDto;
        }

        @Watch('icdCode', { immediate: true })
        private async loadNhifs(value: string) {
            this.drugs = [];

            if ((this.formType === 'Nhif5b' || this.formType === 'Nhif5c') && value === 'Z00') {
                this.drugs = await nhifDrugService.searchNhifDrugs(
                    this.createNhifDrugDto(value, this.formType, this.protocolTypeCode)
                );
            }
            //Към момента ОПЛ няма право да изписва лекарства по U07.1
            else if (userContextCache.current?.specialtyCode !== SpecialtyCode.Gp || value !== 'U07.1') {
                this.drugs = await nhifDrugService.searchNhifDrugs(
                    this.createNhifDrugDto(value, this.formType, this.protocolTypeCode)
                );
            }
        }

        @Watch('protocolTypeCode')
        private async onProtocolTypeCodeChange(value: string) {
            this.drugs = await nhifDrugService.searchNhifDrugs(
                this.createNhifDrugDto(this.icdCode, this.formType, value)
            );
        }

        @Watch('requirementId')
        private async onRequirementChange(value: number | null) {
            this.requirementId = value;
            this.drugs = await nhifDrugService.searchNhifDrugs(
                this.createNhifDrugDto(this.icdCode, this.formType, this.protocolTypeCode)
            );
        }
    }
</script>
