import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { NhisReferralDto } from '@/model/Nhis/Referral/NhisReferralDto';

export class Referral3Dto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();
    public number: number = 0;
    public fundCode: string | null = null;
    public ambulatorySheetNumber: number = 0;
    public specialtyCode: SpecialtyCode | null = null;
    public diagnosis1Id: number | null = null;
    public diagnosis2Id: number | null = null;
    public diagnosis3Id: number | null = null;
    //По поздразбиране 1. Остро
    public referralSubtypeId: number | null = 1;
    public diagnosesText: string = '';
    public reason: string = '';
    public attachedDocs: string = '';
    public specialistAmbulatorySheetInfo: string = '';
    public medicalProcedureId: number | null = null;
    public nhisReferral: NhisReferralDto = new NhisReferralDto();
}
