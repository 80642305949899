import { AxiosResponse } from 'axios';

import { ExamWithoutFiscalReceiptDto } from '@/model/Query/Exam/ExamWithoutFiscalReceiptDto';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const examWithoutFiscalReceiptService = {
    getExamWithoutFiscalReceipt(
        filter: ReportIntervalFilterDto
    ): Promise<AxiosResponse<ExamWithoutFiscalReceiptDto[]>> {
        return httpService.post('ExamWithoutFiscalReceipt/GetExamWithoutFiscalReceipts', filter);
    },

    async exportExamWithoutFiscalReceipt(filter: ReportIntervalFilterDto): Promise<void> {
        await httpService.download('/ExamWithoutFiscalReceipt/ExportExcel', { params: filter });
    }
};
