import { Report365RowBase } from './Report365RowBase';

export class Report365StaffRowDto extends Report365RowBase {
    public totalWithContract: number | null = null;

    public totalWithSpecialty: number | null = null;
    public male35: number | null = null;
    public female35: number | null = null;
    public get total35(): number | null {
        return (this.male35 === null ? 0 : Number(this.male35)) + (this.female35 === null ? 0 : Number(this.female35));
    }

    public male44: number | null = null;
    public female44: number | null = null;
    public get total44(): number | null {
        return (this.male44 === null ? 0 : Number(this.male44)) + (this.female44 === null ? 0 : Number(this.female44));
    }

    public male54: number | null = null;
    public female54: number | null = null;
    public get total54(): number | null {
        return (this.male54 === null ? 0 : Number(this.male54)) + (this.female54 === null ? 0 : Number(this.female54));
    }

    public male64: number | null = null;
    public female64: number | null = null;
    public get total64(): number | null {
        return (this.male64 === null ? 0 : Number(this.male64)) + (this.female64 === null ? 0 : Number(this.female64));
    }

    public male74: number | null = null;
    public female74: number | null = null;
    public get total74(): number | null {
        return (this.male74 === null ? 0 : Number(this.male74)) + (this.female74 === null ? 0 : Number(this.female74));
    }

    public male75: number | null = null;
    public female75: number | null = null;
    public get total75(): number | null {
        return (this.male75 === null ? 0 : Number(this.male75)) + (this.female75 === null ? 0 : Number(this.female75));
    }

    //public get totalWithContract(): number | null {
    //    return (
    //        (this.total35 === null ? 0 : Number(this.total35)) +
    //        (this.total44 === null ? 0 : Number(this.total44)) +
    //        (this.total54 === null ? 0 : Number(this.total54)) +
    //        (this.total64 === null ? 0 : Number(this.total64)) +
    //        (this.total65 === null ? 0 : Number(this.total65))
    //    );
    //}

    public get total(): number | null {
        return (
            Number(this.total35) +
            Number(this.total44) +
            Number(this.total54) +
            Number(this.total64) +
            Number(this.total74) +
            Number(this.total75)
        );
    }
}
