<template>
    <v-card-text>
        <v-row>
            <v-col cols="12">
                <h3 class="col text--primary">Лекар/Лекар по дентална медицина</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <text-field v-model="doctorPhoneNumber" disabled label="Служ. тел." />
            </v-col>
            <v-col cols="2">
                <text-field v-model="doctorEmployeeCode" disabled label="Код специалист" />
            </v-col>
            <v-col cols="3">
                <text-field v-model="currentVisit.practiceNumber" disabled label="Рег. номер на ЛЗ" />
            </v-col>
            <v-col cols="3">
                <text-field v-model="currentVisit.doctorUin" disabled label="УИН/ЛПК" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <text-field v-model="currentVisit.doctorNames" disabled label="Име и Фамилия" />
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { currentVisitCache } from '@/store/CurrentVisitCache';

    @Component
    export default class PrescriptionDoctorInfo extends Vue {
        @Prop()
        private doctorEmployeeCode!: string;

        @Prop()
        private doctorPhoneNumber!: string;

        private get currentVisit() {
            return currentVisitCache.value;
        }
    }
</script>
