<template>
    <v-card flat>
        <v-card-title>Вие излязохте успешно. Ще се зареди страницата за вход...</v-card-title>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { oidcClient } from '@/service/Infrastructure/OidcClient';

    @Component
    export default class PostLogout extends Vue {
        private created() {
            oidcClient.signinRedirectPostLogout();
        }
    }
</script>
