import { Type } from 'class-transformer';

import { Report365HealthCareTraineeRowDto } from '@/model/Report/Rhi/Report365/Report365HealthCareTraineeRowDto';

export class Report365HealthCareTraineeDto {
    public name: string = '';

    @Type(() => Report365HealthCareTraineeRowDto)
    public healthCareTrainees: Report365HealthCareTraineeRowDto[] = [];
}
