export class QuickAddInitialTariffsAndServicesDto {
    public practiceId: number = 0;
    public patientTariffName: boolean = false;
    public missingTariffsNames: string[] = [];
    public missingTariffExamsNames: string[] = [];
    public isAddedPatientExamOffer: boolean = false;
    public isAddedNhifExamOffer: boolean = false;
    public isAddedRetiredExamOffer: boolean = false;
    public isAddedMedicalProcedureOffer: boolean = false;
    public selectedExamChoice: string = '';
    public practiceHasNhifFund: boolean = false;
}
