import { FileInfoDto } from '@/model/File/FileInfoDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const blobManagerService = {
    async getPracticeItems(practiceId: number): Promise<FileInfoDto[]> {
        const response = await httpService.get<FileInfoDto[]>('BlobManager/GetPracticeItems', {
            params: { practiceId }
        });
        return response.data;
    },

    downloadPracticeItem(practiceId: number, relativePath: string): Promise<void> {
        return httpService.download('BlobManager/DownloadPracticeItem', { params: { practiceId, relativePath } });
    }
};
