<template>
    <div v-if="isGlobalAdmin && bgDoctor.uin">
        <hr />
        <v-card-title>Информация за лекар от БЛС</v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-checkbox v-model="bgDoctor.isDeleted" dense label="Изтрит" :disabled="true"></v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <text-field v-model="bgDoctor.name" label="Име" disabled />
                </v-col>
                <v-col>
                    <text-field v-model="bgDoctor.specialtyNames" label="Специалност" disabled />
                </v-col>
                <v-col>
                    <text-field v-model="bgDoctor.phone" label="Сл. тел." disabled />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <text-field v-model="bgDoctor.address" label="Сл. адрес" disabled />
                </v-col>
                <v-col>
                    <text-field v-model="bgDoctor.workplace" label="Месторабота" disabled />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <date-picker v-model="bgDoctor.updatedOn" label="Обновен" disabled />
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { BgDoctorDto } from '@/model/BgDoctor/BgDoctorDto';
    import { bgDoctorService } from '@/service/BgDoctor/BgDoctorService';
    import { currentUser } from '@/store/User/CurrentUser';

    @Component
    export default class BgDoctor extends Vue {
        @Prop({ required: true })
        private uin!: string;

        private bgDoctor: BgDoctorDto = new BgDoctorDto();
        private isGlobalAdmin: boolean = false;

        private async mounted() {
            this.isGlobalAdmin = currentUser.isGlobalAdmin;
            if (this.uin) {
                this.$loading.show();
                try {
                    this.bgDoctor = await bgDoctorService.getBgDoctorByUin(this.uin);
                } finally {
                    this.$loading.hide();
                }
            }
        }
    }
</script>
