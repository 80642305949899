import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { SubmitCommand } from '@/model/Nhis/SubmitCommand';

export class NhisReferralExtractionSubmitCommand extends SubmitCommand {
    constructor(
        xmlString: string,
        accessTokenValue: string,
        public specialtyCode: SpecialtyCode
    ) {
        super(xmlString, accessTokenValue);
    }
}
