<template>
    <data-table :headers="headers" :items="items" :loading="$loading.isVisible" title="Изтеглени входящи направления">
        <template #actions>
            <v-row>
                <v-col cols="12">
                    <report-date-picker v-model="filter" :disabled="$loading.isVisible" @input="getTableItems" />
                </v-col>
            </v-row>
        </template>
        <template #rowActions="{ item }">
            <btn
                icon
                action="Print"
                :to="`/Print/Exam.Referral.${item.referralTypeCode}/IncomingReferral/${item.visitId}`"
            />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { IncomingReferralExamReportDto } from '@/model/Query/Exam/IncomingReferralExamReportDto';
    import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
    import { incomingReferralReportService } from '@/service/Query/Exam/IncomingReferralReportService';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { ReportDatePicker }
    })
    export default class IncomingReferralExamReport extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private filter: ReportIntervalFilterDto = new ReportIntervalFilterDto();
        private items: IncomingReferralExamReportDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'АЛ номер', value: 'examNumber' },
            { text: 'АЛ НРН', value: 'nrnVisit' },
            { text: 'НРН входящо напр.', value: 'nrnReferral' },
            { text: 'Вид направление', value: 'referralType' },
            { text: 'Тип направление', value: 'referralSubtype' },
            { text: 'Дата', value: 'startDateTime', formatter: formatters.date },
            { text: 'Идентификатор', value: 'patientIdentifier' },
            { text: 'Пациент', value: 'patientName' }
        ];

        private async getTableItems() {
            this.$loading.show();
            try {
                this.items = [];
                this.fillDoctorEmployee();
                const response = await incomingReferralReportService.getIncomingReferralPerExam(this.filter);
                this.items = response.data;
            } finally {
                this.$loading.hide();
            }
        }

        private fillDoctorEmployee() {
            this.filter.practiceId = this.practiceId;
            this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async mounted() {
            this.filter.fillCurrentMonth();
            await this.getTableItems();
        }
    }
</script>
