import { Report365RowBase } from './Report365RowBase';

export class Report365PreventivePregnancyActivityRowDto extends Report365RowBase {
    public total: number | null = null;
    public prenatalScreening: number | null = null;
    public syphilis: number | null = null;
    public positiveSyphilisTest: number | null = null;
    public hiv: number | null = null;
    public positiveHivTest: number | null = null;
    public hepatitisB: number | null = null;
    public positiveHepatitisBTest: number | null = null;
}
