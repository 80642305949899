<template>
    <div>
        <data-table
            title="Освобождаване от потребителска такса"
            :headers="tableHeaders"
            :loading="$loading.isVisible"
            :items="items"
            class="elevation-1 mb-5"
            disable-pagination
        >
            <template v-if="$allowWrite" #rowActions="{ item }">
                <btn icon action="Edit" @click="editDisengagement(item)" />
            </template>
        </data-table>
        <v-dialog v-model="dialogIsVisible" persistent max-width="75%">
            <template v-if="$allowWrite" #activator="{ on, attrs }">
                <btn action="New" v-bind="attrs" v-on="on" />
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline">Освобождаване от потребителска такса</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="feeDisengagementForm" v-model="isFormValid" :disabled="!$allowWrite">
                        <fee-disengagement-editor v-if="dialogIsVisible" v-model="clone" />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <btn v-if="clone.id > 0" action="Delete" @click="deleteEntity" />
                    <v-spacer></v-spacer>
                    <btn action="Cancel" @click="closeDialog" />
                    <btn action="Save" @click="save" />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { FeeDisengagementDto } from '@/model/Patient/FeeDisengagement/FeeDisengagementDto';
    import { feeDisengagementService } from '@/service/Patient/FeeDisengagementService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { formatters } from '@/util/Formatters';

    import FeeDisengagementEditor from './FeeDisengagementEditor.vue';

    @Component({
        components: { FeeDisengagementEditor }
    })
    export default class FeeDisengagement extends Vue {
        @Prop()
        public id!: number;

        private dialogIsVisible: boolean = false;
        private feeDisengagement: FeeDisengagementDto = new FeeDisengagementDto();
        private clone: FeeDisengagementDto = new FeeDisengagementDto();
        private items: FeeDisengagementDto[] = [];
        private isFormValid: boolean = false;

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Начална дата', value: 'startDate', formatter: formatters.date },
                { text: 'Крайна дата', value: 'endDateString' },
                { text: 'Причина', value: 'disengagementReasonName' },
                { text: 'Диагноза', value: 'feeDisengagementIcd.text' },
                { text: 'Решение', value: 'medicalResolutionText' },
                { text: '', value: 'actionButtons' }
            ];
        }

        private async save() {
            this.$loading.show();
            try {
                (this.$refs.feeDisengagementForm as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    this.feeDisengagement = this.clone;
                    this.feeDisengagement.patientId = this.id;
                    if (this.feeDisengagement.id <= 0) {
                        await feeDisengagementService.createFeeDisengagement(this.feeDisengagement);
                    } else {
                        await feeDisengagementService.updateFeeDisengagement(this.feeDisengagement);
                    }
                    this.$notifier.showSuccess('', `Успешен запис`);
                    await currentPatientCache.reload();
                    await this.getTableItems();
                    this.closeDialog();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private editDisengagement(item: FeeDisengagementDto) {
            Object.assign(this.clone, item);
            this.dialogIsVisible = true;
        }

        private closeDialog() {
            this.dialogIsVisible = false;
            this.clone = new FeeDisengagementDto();
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                this.items = await feeDisengagementService.getPatientFeeDisengagements(this.id);
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEntity() {
            this.$loading.show();
            try {
                await feeDisengagementService.deleteFeeDisengagement(this.clone.id);
                await this.getTableItems();
                await currentPatientCache.reload();
                this.$notifier.showSuccess('', `Успешно изтриване`);
            } finally {
                this.closeDialog();
                this.$loading.hide();
            }
        }

        private async mounted() {
            if (this.id) {
                await this.getTableItems();
            }
        }
    }
</script>
