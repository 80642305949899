<template>
    <data-table
        title="Районни здравно-осигурителни каси"
        :headers="headers"
        :items="rhifs"
        :loading="$loading.isVisible"
    >
        <template #actions>
            <btn action="New" to="/DevTool/Rhif/Create">Добавяне на РЗОК</btn>
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/DevTool/Rhif/Edit/${item.id}`" />
            <btn icon action="List" :to="`/DevTool/RhifArea/${item.id}`">Здравни райони</btn>
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RhifDto } from '@/model/Nomenclature/RhifDto';
    import { rhifService } from '@/service/Nomenclature/RhifService';

    @Component
    export default class RhifList extends Vue {
        private rhifs: RhifDto[] = [];
        private headers: IDataTableHeader[] = [
            { text: 'Наименование', value: 'name' },
            { text: 'Код', value: 'code' },
            { text: 'ЕИК', value: 'eik' },
            { text: 'Директор', value: 'director' }
        ];

        private async mounted() {
            this.$loading.show();
            try {
                const response = await rhifService.getRhifs();
                this.rhifs = response.data;
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
