<template>
    <ExamLayout
        :can-delete="!document.isNew"
        :can-print="!document.isNew"
        title="Медицинско свидетелство за встъпване в брак"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-form ref="certificateMariageForm" v-model="isFormValid">
            <v-card class="mb-md-4">
                <main-title>Медицинско свидетелство за встъпване в брак</main-title>
                <hr />
                <v-card-text>
                    <PatientInfo />
                    <v-row dense>
                        <v-radio-group v-model="document.isHealthy">
                            <v-radio label="Клинично здрав" :value="true" />
                            <v-radio label="С диагноза" :value="false" />
                        </v-radio-group>
                    </v-row>
                    <v-row dense>
                        <text-field v-if="document.isHealthy" v-model="document.isHealthyText"></text-field>
                        <ExamDiagnosisPicker v-else v-model="document.examDiagnosis" use-main-icd required />
                    </v-row>
                    <v-row dense>
                        <date-picker v-model="document.issueDate" label="Дата и час:" required></date-picker>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
    </ExamLayout>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ExamDiagnosisPicker from '@/component/Exam/ExamDiagnosisPicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { CertificateMariageDto } from '@/model/Exam/Certificate/CertificateMariageDto';
    import { certificateMariageService } from '@/service/Exam/Certificate/CertificateMariageService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';

    @Component({ components: { ExamLayout, PatientInfo, ExamDiagnosisPicker } })
    export default class CertificateMariage extends Vue {
        @Prop()
        private examId!: number;

        private isFormValid: boolean = false;
        private document: CertificateMariageDto = new CertificateMariageDto();

        private async mounted() {
            this.document = await certificateMariageService.getCertificateMariage(this.examId);
            await currentVisitCache.load(this.examId);
            this.getExamDiagnosisOnLoad();
        }

        private getExamDiagnosisOnLoad() {
            const examDiagnosis = currentVisitCache.value.exam?.examDiagnoses.find(
                (diagnosis) => diagnosis.id === this.document.examDiagnosisId
            );
            this.document.examDiagnosis = examDiagnosis ?? null;
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                (this.$refs.certificateMariageForm as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    this.document.examDiagnosisId = this.document.examDiagnosis?.id ?? 0;
                    await certificateMariageService.saveCertificateMariage(this.document);
                    eventBus.$emit(
                        'create-referral-event',
                        this.examId,
                        'CertificateMariage',
                        'Медицинско за встъпване в брак'
                    );

                    this.$router.push(`/Exam/CertificateMariage/Edit/${this.examId}`);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (!this.document.isNew) {
                this.$router.push(`/Print/Exam.Certificate.CertificateMariage/${this.examId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.examId.toString();
                const report = 'Exam.Certificate.CertificateMariage';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            if (!this.document.isNew) {
                await certificateMariageService.deleteCertificateMariage(this.examId);
                this.$router.push(`/Exam/CertificateMariage/Create/${currentVisitCache.value.id}`);
                eventBus.$emit('delete-referral-event', this.examId, 'document', 'Медицинско за встъпване в брак');
            }
        }
    }
</script>
