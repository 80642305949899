import { Type } from 'class-transformer';

import { Annex56FactorForHealthStatusAdultRowDto } from '@/model/Report/Rhi/Annex56/Annex56FactorForHealthStatusAdultRowDto';

export class Annex56FactorForHealthStatusAdultDto {
    public name: string = '';

    @Type(() => Annex56FactorForHealthStatusAdultRowDto)
    public factorForHealthStatusesAdult: Annex56FactorForHealthStatusAdultRowDto[] = [];
}
