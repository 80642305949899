import { NhisFamilyHistoryDto } from '@/model/Nhis/FamilyHistory/NhisFamilyHistoryDto';
import { NhisFamilyHistoryExtractionCommand } from '@/model/Nhis/FamilyHistory/NhisFamilyHistoryExtractionCommand';
import { NhisXmlRequestExtendedDto } from '@/model/Nhis/NhisXmlRequestExtendedDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { nhisService } from '@/service/Nhis/NhisService';
import { signXmlService } from '@/service/Report/SignXmlService';
import { loadingState } from '@/store/LoadingState';
import { nhisKey } from '@/store/NhisNhifAccessTokenCache';

const getFamilyHistoryCreateXml = async function getFamilyHistoryCreateXml(
    requestData: NhisXmlRequestExtendedDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/CreateFamilyHistory`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getFamilyHistoryUpdateXml = async function getFamilyHistoryUpdateXml(
    requestData: NhisXmlRequestExtendedDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/UpdateFamilyHistory`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getFamilyHistoryExtractionXml = async function getFamilyHistoryExtractionXml(
    requestData: NhisFamilyHistoryExtractionCommand
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/FamilyHistoryExtraction`, requestData);
    console.log(response);
    return response?.data ?? '';
};

export const nhisFamilyHistoryService = {
    async postCreate(requestData: NhisXmlRequestExtendedDto) {
        loadingState.show();
        try {
            const xmlString = await getFamilyHistoryCreateXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const familyHistoryResult = await nhisService.postXmlToNhisWithId<NhisFamilyHistoryDto>(
                    'PostCreateFamilyHistory',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(familyHistoryResult?.response);
                if (familyHistoryResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${familyHistoryResult.error}`);
                } else {
                    if (familyHistoryResult?.warnings?.length > 0) {
                        nhisService.showWarnings(familyHistoryResult.warnings);
                    }
                    return familyHistoryResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postUpdate(requestData: NhisXmlRequestExtendedDto) {
        loadingState.show();
        try {
            const xmlString = await getFamilyHistoryUpdateXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const familyHistoryResult = await nhisService.postXmlToNhisWithId<NhisFamilyHistoryDto>(
                    'PostUpdateFamilyHistory',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(familyHistoryResult?.response);
                if (familyHistoryResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${familyHistoryResult.error}`);
                } else {
                    if (familyHistoryResult?.warnings?.length > 0) {
                        nhisService.showWarnings(familyHistoryResult.warnings);
                    }
                    return familyHistoryResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async extractFamilyHistory(requestData: NhisFamilyHistoryExtractionCommand) {
        loadingState.show();
        try {
            const xmlString = await getFamilyHistoryExtractionXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const familyHistoryResult = await nhisService.postXmlToNhisWithId<NhisFamilyHistoryDto>(
                    'PostFamilyHistoryExtraction',
                    signResponse.contents,
                    requestData.key.patientId
                );
                console.log(familyHistoryResult?.response);
                if (familyHistoryResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${familyHistoryResult.error}`);
                } else {
                    if (familyHistoryResult?.warnings?.length > 0) {
                        nhisService.showWarnings(familyHistoryResult.warnings);
                    }
                    return `/Patient/FamilyHistory/${requestData.key.patientId}`;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    }
};
