export enum WorkTimeVisitReasonCode {
    Realtime = 'Realtime',
    Ambulatory = 'Ambulatory',
    Domestic = 'Domestic',
    AdultRiskPrevention = 'AdultRiskPrevention',
    Checkup = 'Checkup',
    Hospitalization = 'Hospitalization',
    LKK = 'LKK',
    ChildPrevention = 'ChildPrevention',
    MotherPrevention = 'MotherPrevention',
    HospHighSpecialized = 'HospHighSpecialized',
    Surgery = 'Surgery',
    EmergencyDuty = 'EmergencyDuty',
    Consultation = 'Consultation',
    Dispensary = 'Dispensary',
    AdultPrevention = 'AdultPrevention'
}
