export class StagedEpicrisisViewDto {
    public id: number = 0;
    public ambulatorySheetNumber: number | null = null;
    public diagnosis: string = '';
    public symptoms: string = '';
    public condition: string = '';
    public tests: string = '';
    public treatment: string = '';
    public consultation: string = '';
    public conclusion: string = '';
}
