import { GenderCode } from '@/enum/Nomenclature/GenderCode';
import { NhisFamilyHistoryDto } from '@/model/Nhis/FamilyHistory/NhisFamilyHistoryDto';

export class FamilyHistoryDto {
    public id: number = 0;
    public patientId: number = 0;
    public icdCode: string | null = null;
    public icdCodeAdd: string | null = null;
    public statusCode: string | null = null;
    public relationshipCode: string | null = null;
    public issueDate: Date | null = new Date();
    public relativeName: string | null = null;
    public relativeGenderCode: GenderCode | null = null;
    public relativeBirthdate: Date | null = null;
    public description: string | null = null;
    public isDeceased: boolean = false;
    public nhisFamilyHistory: NhisFamilyHistoryDto = new NhisFamilyHistoryDto();
}
