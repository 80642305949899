<template>
    <v-row>
        <v-col>
            <data-table
                :headers="headers"
                title="Декларация за информирано съгласие с изискванията за започване/продължаване на лечението с лекарствения продукт"
                :items="items"
            >
                <template #actions>
                    <btn action="New" :to="`/Exam/InformedConsentTreatment/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/InformedConsentTreatment/Edit/${item.id}`" />
                    <btn icon action="Print" :to="`/Print/Exam.InformedConsent.InformedConsentTreatment/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { InformedConsentTreatmentViewDto } from '@/model/Exam/InformedConsentTreatment/InformedConsentTreatmentViewDto';
    import { informedConsentTreatmentService } from '@/service/Exam/InformedConsentTreatmentService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class InformedConsentTreatmentView extends Vue {
        @Prop()
        examId!: number;

        private items: InformedConsentTreatmentViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Заболяване:', value: 'diagnosis' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await informedConsentTreatmentService.getInformedConsentTreatmentsByExamId(
                    this.examId
                );
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
