import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { userContextCache } from '@/store/User/UserContextCache';

import { MenuItemModel } from './MenuItemModel';

export const createReceptionMenuItems = () => {
    const isSpecialist = userContextCache.current?.specialtyCode !== SpecialtyCode.Gp;
    const menuItems: MenuItemModel[] = [];

    menuItems.push(
        new MenuItemModel('Регистратура', '', ''),
        new MenuItemModel('Табло', 'mdi-view-dashboard-outline', '/Reception/Dashboard'),
        new MenuItemModel('Нова услуга', 'mdi-face-agent', `/Reception/Service/Create`),
        new MenuItemModel(
            'Услуги',
            'mdi-view-list-outline',
            `/Reception/Service/List/${userContextCache.currentPracticeId}`
        ),
        new MenuItemModel('Заявка за преглед', 'mdi-stethoscope', `/Reception/ExamScheduleNew`)
    );
    // Скрива се за ОПЛ
    if (isSpecialist) {
        menuItems.push(
            new MenuItemModel('Входящо направление', 'mdi-clipboard-list-outline', '/IncomingReferralDownload')
        );
    }
    //new MenuItemModel('Лаборатория', 'mdi-flask', '/Reception/Laboratory'),
    //new MenuItemModel('COVID Заявка', 'mdi-virus', '/Reception/Covid/Create'),
    menuItems.push(
        // New MenuItemModel('Амбулаторна процедура', 'mdi-account-injury-outline', '/Reception/AmbulatoryProcedure/Create'),
        new MenuItemModel('Фактури', 'mdi-receipt', `/Finance/Invoice/${userContextCache.currentPracticeId}`),
        new MenuItemModel(
            'Справка продажби-детайлна',
            'mdi-chart-bar',
            `/Reception/Report/SalesDetailed/${userContextCache.currentPracticeId}`
        ),
        new MenuItemModel(
            'Справка продажби-обобщена',
            'mdi-chart-bar',
            `/Reception/Report/Sales/${userContextCache.currentPracticeId}`
        ),
        new MenuItemModel('Фискални у-ва', 'mdi-printer-check', '/Reception/Fiscal')
    );
    //new MenuItemModel('Дневен отчет', 'mdi-chart-timeline-variant', '/Reception/Report/Daily'),
    //new MenuItemModel('X/Z отчет', 'mdi-printer-check', '/Reception/Report/XZReport')
    return menuItems;
};
