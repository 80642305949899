import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { FeeDisengagementDto } from '@/model/Patient/FeeDisengagement/FeeDisengagementDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const feeDisengagementService = {
    createFeeDisengagement(data: FeeDisengagementDto): Promise<AxiosResponse<FeeDisengagementDto>> {
        return httpService.post('/FeeDisengagement/CreateFeeDisengagement', data);
    },

    updateFeeDisengagement(data: FeeDisengagementDto): Promise<AxiosResponse<FeeDisengagementDto>> {
        return httpService.put('/FeeDisengagement/UpdateFeeDisengagement', data);
    },

    getFeeDisengagement(id: number): Promise<AxiosResponse<FeeDisengagementDto>> {
        return httpService.get(`/FeeDisengagement/GetFeeDisengagement/${id}`);
    },

    deleteFeeDisengagement(id: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/FeeDisengagement/DeleteFeeDisengagement/${id}`);
    },

    async getPatientFeeDisengagements(patientId: number): Promise<FeeDisengagementDto[]> {
        const response = await httpService.get<FeeDisengagementDto[]>(
            `/FeeDisengagement/GetPatientFeeDisengagements/${patientId}`
        );
        return plainToInstance(FeeDisengagementDto, response.data);
    }
};
