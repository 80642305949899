import { AxiosResponse } from 'axios';

import { PieChartDto } from '@/model/Dashboard/PieChartDto';
import { BarChartExamDto } from '@/model/Exam/BarChartExamDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const dashboardService = {
    getBarChartExamData(practiceId: number): Promise<AxiosResponse<BarChartExamDto>> {
        return httpService.get('Dashboard/GetBarChartExamData', { params: { practiceId } });
    },
    getReferral3PieChartData(practiceId: number, employeeSeqNumber: number): Promise<AxiosResponse<PieChartDto>> {
        return httpService.get('Dashboard/GetReferral3PieChartData', { params: { practiceId, employeeSeqNumber } });
    },
    getReferral4PieChartData(practiceId: number, employeeSeqNumber: number): Promise<AxiosResponse<PieChartDto>> {
        return httpService.get('Dashboard/GetReferral4PieChartData', { params: { practiceId, employeeSeqNumber } });
    }
};
