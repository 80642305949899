<template>
    <v-row>
        <v-col cols="12" md="3" xl="2">
            <dropdown
                v-model="invoiceRecipientSearchTypeCode"
                item-value="code"
                :items="invoiceRecipientTypes"
                label="Търсене в"
            />
        </v-col>
        <v-col cols="12" md="9" xl="10">
            <dropdown-search
                :service="invoiceRecipientService"
                item-text="title"
                return-object
                label="Търсене на получател"
                :args="args"
                @input="onRecipientChange"
            ></dropdown-search>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DropdownSearch from '@/component/Dropdown/DropdownSearch.vue';
    import { InvoiceRecipientDto } from '@/model/Finance/Invoice/InvoiceRecipientDto';
    import {
        InvoiceRecipientSearchType,
        invoiceRecipientSearchTypes
    } from '@/model/Finance/Invoice/InvoiceRecipientSearchType';
    import { invoiceRecipientService } from '@/service/Invoice/InvoiceRecipientService';

    @Component({
        components: { DropdownSearch }
    })
    export default class InvoiceRecipientSearch extends Vue {
        @Prop()
        value!: InvoiceRecipientDto;

        @Prop()
        practiceId!: number;

        private invoiceRecipientTypes: InvoiceRecipientSearchType[] = invoiceRecipientSearchTypes;
        private invoiceRecipientSearchTypeCode: InvoiceRecipientSearchType = invoiceRecipientSearchTypes[0];

        private get invoiceRecipientService() {
            return invoiceRecipientService;
        }

        private get args() {
            const filters = [this.invoiceRecipientSearchTypeCode, this.practiceId];
            return filters as [];
        }

        private onRecipientChange(value: InvoiceRecipientDto) {
            this.$emit('input', value);
        }
    }
</script>

<style scoped></style>
