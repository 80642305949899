<template>
    <dropdown
        :value="value"
        :items="specialties"
        item-value="code"
        label="Специалност"
        :disabled="disabled"
        :required="required"
        :clearable="!required"
        @input="$emit('input', ...arguments)"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { specialtyCache } from '@/store/Nomenclature/SpecialtyCache';

    @Component
    export default class SpecialtyPicker extends Vue {
        @Prop()
        private value!: string;

        @Prop()
        private disabled!: boolean;

        @Prop()
        private required!: boolean;

        private get specialties() {
            return specialtyCache.items;
        }
    }
</script>
