export class NhisHospitalizationExtractionCommand {
    public doctorUin: string = '';
    public deputyDoctorUin: string | null = null;
    public practiceNumber: string = '';
    public nrn: string | null = null;
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
    public pidTypeCode: string = '';
    public identifier: string = '';
}
