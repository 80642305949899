import { Route, RouteConfig } from 'vue-router';

const financeChildRoutes: RouteConfig[] = [
    {
        path: 'Service',
        props: {
            finance: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            finance: () => import('@/view/Practice/Service/ServicePriceList.vue')
        },
        children: [
            {
                path: 'Category/:practiceId',
                props: {
                    service: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
                },
                components: {
                    service: () => import('@/view/Practice/Service/ServiceCategories.vue')
                }
            },
            {
                path: 'Tariff/:practiceId',
                props: {
                    service: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
                },
                components: {
                    service: () => import('@/view/Practice/Service/Tariffs.vue')
                }
            },
            {
                path: 'PriceList/:practiceId',
                props: {
                    service: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
                },
                components: {
                    service: () => import('@/view/Practice/Service/PriceList.vue')
                }
            },
            {
                path: 'Create/:practiceId',
                props: {
                    service: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
                },
                components: {
                    service: () => import('@/view/Practice/Service/Service.vue')
                }
            },
            {
                path: 'Edit/:practiceId/:seqNumber',
                props: {
                    service: (route: Route) => ({
                        practiceId: Number(route.params.practiceId),
                        seqNumber: Number(route.params.seqNumber)
                    })
                },
                components: {
                    service: () => import('@/view/Practice/Service/Service.vue')
                }
            },
            {
                path: 'Batch/Create/:practiceId',
                props: {
                    service: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
                },
                components: {
                    service: () => import('@/view/Practice/Service/Batch.vue')
                }
            },
            {
                path: 'Batch/Edit/:practiceId/:seqNumber',
                props: {
                    service: (route: Route) => ({
                        practiceId: Number(route.params.practiceId),
                        seqNumber: Number(route.params.seqNumber)
                    })
                },
                components: {
                    service: () => import('@/view/Practice/Service/Batch.vue')
                }
            },
            {
                path: 'QuickAddLabTest/:practiceId',
                props: {
                    service: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
                },
                components: {
                    service: () => import('@/view/Practice/Service/QuickAddLabTest.vue')
                }
            },
            {
                path: 'QuickAddTariffsAndService/:practiceId',
                props: {
                    service: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
                },
                components: {
                    service: () => import('@/view/Practice/Service/QuickAddInitialTariffsAndServices.vue')
                }
            }
        ]
    },
    {
        path: 'Invoice/Create/:practiceId',
        props: {
            finance: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            finance: () => import('@/view/Finance/Invoice.vue')
        }
    },
    {
        path: 'Invoice/Edit/:id',
        props: {
            finance: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            finance: () => import('@/view/Finance/Invoice.vue')
        }
    },
    {
        path: 'Invoice/CreditNote/:id',
        props: {
            finance: (route: Route) => ({ id: Number(route.params.id), documentType: 'CreditNote' })
        },
        components: {
            finance: () => import('@/view/Finance/Invoice.vue')
        }
    },
    {
        path: 'Invoice/DebitNote/:id',
        props: {
            finance: (route: Route) => ({ id: Number(route.params.id), documentType: 'DebitNote' })
        },
        components: {
            finance: () => import('@/view/Finance/Invoice.vue')
        }
    },
    {
        path: 'Invoice/:practiceId',
        props: {
            finance: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            finance: () => import('@/view/Finance/InvoiceView.vue')
        }
    }
];

export const financeRoutes: RouteConfig = {
    path: '/Finance',
    component: () => import('@/view/Finance/FinanceMenu.vue'),
    children: financeChildRoutes
};
