<template>
    <v-row align="stretch" no-gutters>
        <v-col md="4" sm="12" :class="{ 'pr-1': $vuetify.breakpoint.mdAndUp }">
            <v-card :style="`background-color: ${backgroundColor}`" class="fill-height">
                <AmbulatorySheetType
                    v-model="examDto"
                    class="pl-2"
                    @visit-type-change="visitTypeChange"
                    @visit-reasons-loaded="visitReasonsLoaded"
                ></AmbulatorySheetType>
            </v-card>
        </v-col>
        <v-col md="8" sm="12" :class="{ 'pl-1': $vuetify.breakpoint.mdAndUp }">
            <v-card :style="`background-color: ${backgroundColor}`" class="fill-height">
                <h4>Основна диагноза</h4>
                <AmbulatorySheetDiagnosisCard
                    :value="examDto.diagnoses[0]"
                    :exam-date-time="examDto.startDateTime"
                    :exam-visit-type="examDto.nhifVisitReasonCode"
                    :exam-type-change="examTypeChanged"
                    :is-secondary-exam="examDto.isSecondary"
                    :is-copy-exam="isExamCopy"
                    is-main-icd
                    class="required"
                    required
                    :is-incidental="examDto.isIncidental"
                    :watch-diagnosis="watchDiagnosis"
                    :last-dispensary-number="lastDispensaryNumber"
                    @visit-type-change="visitTypeChange"
                    @icd-change="icdChange"
                    @register-include="registerInclude"
                    @change-procedure="changeProcedure"
                />
            </v-card>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhifVisitReasonCode } from '@/enum/Exam/NhifVisitReasonCode';
    import { ExamDto } from '@/model/Exam/ExamDto';
    import { NhifVisitReasonDto } from '@/model/Nomenclature/NhifVisitReasonDto';
    import AmbulatorySheetDiagnosisCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetDiagnosisCard.vue';
    import AmbulatorySheetType from '@/view/Exam/AmbulatorySheet/AmbulatorySheetType.vue';

    @Component({
        components: { AmbulatorySheetDiagnosisCard, AmbulatorySheetType }
    })
    export default class AmbulatorySheetTypeMainIcdCard extends Vue {
        @Prop()
        examDto!: ExamDto;

        @Prop()
        watchDiagnosis!: boolean;

        @Prop()
        examTypeChanged!: boolean;

        @Prop({ default: 'transparent' })
        private backgroundColor!: string;

        private get lastDispensaryNumber() {
            let lastDispensaryNumber = 0;
            for (const diagnosis of this.examDto.diagnoses) {
                const dossierNumber = diagnosis?.dispensaryRegister?.dossierNumber ?? 0;
                if (dossierNumber > lastDispensaryNumber) {
                    lastDispensaryNumber = dossierNumber;
                }
            }
            return lastDispensaryNumber;
        }

        private get isExamCopy() {
            return Boolean(this.examDto.copiedExamId);
        }

        private visitReasonsLoaded(data: NhifVisitReasonDto[]) {
            this.$emit('visit-reasons-loaded', data);
        }

        private visitTypeChange(nhifVisitReasonCode: NhifVisitReasonCode) {
            this.$emit('visit-type-change', nhifVisitReasonCode);
        }

        private registerInclude() {
            this.$emit('register-include');
        }

        private icdChange(icdCode: string) {
            this.$emit('icd-change', icdCode);
        }

        private changeProcedure(procedureId: number) {
            this.examDto.medicalProcedures[0].procedureId = procedureId;
        }
    }
</script>
