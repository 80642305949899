<template>
    <v-form ref="form" v-model="isFormValid">
        <v-card>
            <v-card-title>Лаборатория</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <text-field
                            v-model="practiceUnitLaboratory.name"
                            required
                            :rules="[$validator.maxLength(100)]"
                            label="Име:"
                        />
                    </v-col>
                    <v-col cols="8">
                        <text-field
                            v-model="practiceUnitLaboratory.location"
                            :rules="[$validator.maxLength(100)]"
                            label="Адрес:"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <dropdown
                            v-model="practiceUnitLaboratory.laboratoryTypeCode"
                            :items="laboratoryTypes"
                            required
                            item-text="name"
                            item-value="code"
                            outlined
                            label="Тип лаборатория"
                        ></dropdown>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col>
                    <btn action="Save" @click="save" />
                </v-col>
                <v-col>
                    <v-row class="float-right">
                        <btn action="Delete" @click="deleteLaboratory" />
                    </v-row>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { LaboratoryTypeDto } from '@/model/Nomenclature/LaboratoryTypeDto';
    import { PracticeUnitLaboratoryDto } from '@/model/Practice/PracticeUnit/PracticeUnitLaboratoryDto';
    import { laboratoryTypeService } from '@/service/Nomenclature/LaboratoryTypeService';
    import { practiceUnitService } from '@/service/Practice/PracticeUnit/PracticeUnitService';

    @Component
    export default class PracticeUnitLaboratory extends Vue {
        @Prop({ type: Number })
        private practiceId!: number;

        @Prop({ type: Number })
        private parentSeqNumber!: number | null;

        @Prop({ type: Boolean })
        private isEdit!: boolean;

        private isFormValid: boolean = false;
        private laboratoryTypes: LaboratoryTypeDto[] = [];
        private practiceUnitLaboratory: PracticeUnitLaboratoryDto = new PracticeUnitLaboratoryDto();

        private mounted() {
            this.loadInisialData();
            this.load();
        }

        protected async load() {
            this.laboratoryTypes = (await laboratoryTypeService.getLaboratoryTypes()).data;
        }

        private async save() {
            this.$loading.show();
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isFormValid) {
                if (this.isEdit) {
                    await practiceUnitService.updateLaboratory(this.practiceUnitLaboratory);
                } else {
                    await practiceUnitService.createLaboratory(this.practiceUnitLaboratory);
                }

                this.$emit('reload');
                this.practiceUnitLaboratory = new PracticeUnitLaboratoryDto();
                this.loadInisialData();
                this.$notifier.showSuccess('Успех', 'Данните са записани успешно');
            }
            this.$loading.hide();
        }

        private async loadInisialData(): Promise<void> {
            if (this.isEdit) {
                const result = this.parentSeqNumber
                    ? (await practiceUnitService.getLaboratory(this.practiceId, this.parentSeqNumber)).data
                    : null;
                if (result) {
                    this.practiceUnitLaboratory = result;
                }
            } else {
                this.practiceUnitLaboratory.practiceId = this.practiceId;
                this.practiceUnitLaboratory.parentSeqNumber = this.parentSeqNumber;
            }
        }

        private deleteLaboratory() {
            this.$emit('deleteUnit');
        }
    }
</script>
