<template>
    <data-table
        :headers="headers"
        :items="items"
        :loading="$loading.isVisible"
        title="Изтеглени входящи направления по пациент"
    >
        <template #rowActions="{ item }">
            <btn
                icon
                action="Print"
                :to="`/Print/Exam.Referral.${item.referralTypeCode}/IncomingReferral/${item.visitId}`"
            />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { IncomingReferralExamReportDto } from '@/model/Query/Exam/IncomingReferralExamReportDto';
    import { incomingReferralReportService } from '@/service/Query/Exam/IncomingReferralReportService';
    import { formatters } from '@/util/Formatters';

    @Component({})
    export default class IncomingReferralPatientReport extends Vue {
        @Prop()
        private id!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private items: IncomingReferralExamReportDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'АЛ номер', value: 'examNumber' },
            { text: 'АЛ НРН', value: 'nrnVisit' },
            { text: 'НРН входящо напр.', value: 'nrnReferral' },
            { text: 'Вид направление', value: 'referralType' },
            { text: 'Тип направление', value: 'referralSubtype' },
            { text: 'Дата', value: 'startDateTime', formatter: formatters.date },
            { text: 'Идентификатор', value: 'patientIdentifier' },
            { text: 'Пациент', value: 'patientName' }
        ];

        private async getTableItems() {
            this.$loading.show();
            try {
                this.items = [];
                const response = await incomingReferralReportService.getIncomingReferralPerPatient(
                    this.id,
                    this.doctorEmployeeSeqNumber
                );
                this.items = response.data;
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.getTableItems();
        }
    }
</script>
