<template>
    <v-card>
        <alert :value="isAlertActive" type="info" prominent>
            <v-list v-for="(value, index) in validationErrorMessage" :key="index">
                <v-list-item
                    ><span style="color: var(--v-success-base)"
                        >{{ validationErrorMessage.size > 1 ? `${index + 1}.` : '' }} {{ value }}</span
                    ></v-list-item
                >
            </v-list>
            <v-list v-for="(icd, index) in countOfUseIcds" :key="index">
                <span style="color: var(--v-success-base)">
                    {{ countOfUseIcds.length > 1 ? `${index + 1}.)` : '' }} За един и същи период на лечение НЗОК
                    заплаща до три лекарствени продукта, предписани на ЗОЛ за едно заболяване, независимо от реда и
                    начина на извършване на предписанията, вида и броя на рецептурните бланки, на които те са
                    предписани.
                </span>
            </v-list>
            <v-list>
                <span style="color: var(--v-success-base)">{{ protocolExistiningIcdMessage }}</span>
            </v-list>
        </alert>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { GenderCode } from '@/enum/Nomenclature/GenderCode';
    import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { prescriptionService } from '@/service/Exam/PrescriptionService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    const validConst = {
        maxAllowIcds: 3
    };

    type overlapIcdType = { countPromise: number; code: string };

    @Component
    export default class PrescriptionValidationAlert extends Vue {
        @Prop()
        private nhifDrugs!: NhifDrugDto[];

        @Prop()
        private examDiagnosis!: ExamDiagnosisDto[];

        @Prop()
        private protocolTypeCode!: string;

        @Prop()
        private issueDate!: Date;

        @Prop()
        private examId!: number;

        private validationErrorMessage: Set<string> = new Set();
        private isAlertActive: boolean = false;
        private countOfUseIcds: string[] = [];
        private overlapIcdMessages: string = '';
        private protocolExistiningIcdMessage: string = '';

        private get icdCodes(): string[] {
            return this.examDiagnosis.filter((ed) => ed?.icdCode).map((ed) => ed.icdCode);
        }

        private checkForValidationErrors(nhifDrugs: NhifDrugDto[]) {
            if (
                this.protocolTypeCode &&
                this.protocolTypeCode !== 'Без протокол' &&
                this.icdCodes.length &&
                !this.checkIcdCodesEqual(this.icdCodes)
            ) {
                this.alertConfiguration('Рецепта по протокол може да съдържа само едно МКБ');
            }
            if (!this.checkDrugsAllFullPaid(nhifDrugs)) {
                this.$notifier.showError(
                    'Внимание',
                    'На една рецептурна бланка не може да има напълно платени и частично платени лекарствени продукти към датата на отпускането им'
                );
                this.alertConfiguration(
                    `На една рецептурна бланка не може да има напълно платени и частично платени лекарствени продукти към датата на отпускането им;`
                );
            }
            if (this.validationCheckForInsulin()) {
                this
                    .alertConfiguration(`На един пациент не могат да се отпускат лекарствени продукти и медицински изделия
                едновременно по Е10 и Е11. - на един пациент могат да се отпускат лекарствени продукти за диагнози,
                изискващи рецептурна книжка при спазване на приложение 6 към НРД (Допълнителни уточнения по вписване на МКБ-кодове в
                рецептурната книжка - несъвместими диагнози)`);
            }
            if (this.checkMaleToIcd()) {
                this.alertConfiguration(`На МКБ кодове E89.4 и рубрика N80 не могат да бъдат отпускани
                лекарствени продукти на лица от мъжки пол.`);
            }
            if (this.checkFemaleToIcd()) {
                this.alertConfiguration(`На МКБ код N40 не могат да бъдат
                отпускани лекарствени продукти на лица от женски пол.`);
            }
            if (!this.checkAllDrusAreSameCategory(this.nhifDrugs)) {
                this.alertConfiguration(`На една рецептурна бланка не могат да бъдат предписвани едновременно
                лекарствени продукти, медицински изделия и диетични храни за специални медицински цели.
                За всеки вид продукт (лекарство, медицинско изделие или диетична храна за специални медицински цели)
                се издава отделна рецепта, независимо от образеца 5 или 5А.`);
            }
            if (this.checkEqualDrugDiffrentIcd(nhifDrugs)) {
                this.alertConfiguration(`Не се допуска за лечението на едно Здравноосигурено лице едновременно
                предписване на лекарствени продукти, принадлежащи към едно и също международно непатентно наименование,
                за повече от едно заболяване (един МКБ - код), независимо от вида и броя на издадените рецептурни бланки`);
            }
        }

        private alertConfiguration(message: string) {
            this.isAlertActive = true;
            this.validationErrorMessage.add(message);
        }

        private checkIcdCodesEqual(icdCodes: string[]): boolean {
            const [icdCodeOne] = icdCodes;
            return icdCodes.every((icdCode) => icdCode === icdCodeOne);
        }

        private checkDrugsAllFullPaid(drugs: NhifDrugDto[]) {
            const noEmptyDrugs = drugs.filter((drug) => drug?.tradeName !== null && drug?.tradeName !== '');
            return (
                noEmptyDrugs.every((drug) => drug?.isFullPayed === false) ||
                noEmptyDrugs.every((drug) => drug?.isFullPayed === true)
            );
        }

        // Проверка дали има лекарства, изписани за един пациент по МКБ Е10 и Е11 едновременно.
        private validationCheckForInsulin() {
            return (
                this.icdCodes.some((icdCode) => icdCode.match(/(?<mkb>E10\.*)/gmu)) &&
                this.icdCodes.some((icdCode) => icdCode.match(/(?<mkb>E11\.*)/gmu))
            );
        }

        private checkMaleToIcd() {
            return (
                (currentPatientCache.value.personIdentification.genderCode === GenderCode.Male &&
                    this.icdCodes.some((icdCode) => icdCode === 'E89.4')) ||
                this.icdCodes.some((icdCode) => icdCode.match(/(?<mkb>N80\.*)/gmu))
            );
        }

        private checkFemaleToIcd() {
            return (
                currentPatientCache.value.personIdentification.genderCode === GenderCode.Female &&
                this.icdCodes.some((icdCode) => icdCode.match(/(?<mkb>N40\.*)/gmu))
            );
        }

        private checkAllDrusAreSameCategory(drugs: NhifDrugDto[]) {
            const drugOne = drugs[0]?.nhifDrugCategoryCode;
            return drugs
                .filter((drug) => drug?.nhifDrugCategoryCode)
                .every((drug) => drug?.nhifDrugCategoryCode === drugOne);
        }

        private checkEqualDrugDiffrentIcd(drugs: NhifDrugDto[]) {
            const valueAlreadySeen: string[] = [];
            for (let index = 0; index < drugs.length; index++) {
                const value = drugs[index]?.internationalName;
                if (value) {
                    if (valueAlreadySeen.indexOf(value) >= 0) {
                        return true;
                    }
                    valueAlreadySeen.push(value);
                }
            }
            return false;
        }

        @Watch('nhifDrugs', { deep: true, immediate: true })
        private onNhifDrugsChange(value: NhifDrugDto[]) {
            if (value.length > 0 && value[0]?.tradeName) {
                this.checkForValidationErrors(value);
            }
        }

        @Watch('icdCodes', { deep: true })
        private async onIcdCodeChange(icdCodes: string[]) {
            if (icdCodes.some((icdCode) => icdCode)) {
                const overlaps = await this.populateIcdsUses();
                await this.loadIcdUses(await overlaps);
            }
        }

        private async populateIcdsUses() {
            const overlapingIcds: overlapIcdType[] = [];
            for (const diag of this.examDiagnosis) {
                if (diag && diag.id && this.examId && this.issueDate) {
                    if (!overlapingIcds.some((icd) => icd.code === diag.icdCode)) {
                        // TODO: Иван: Да се пренапише с една заявка към сървъра/базата.
                        // Н. Дойчев потискам warning-а, защото мисля че не създавам някакъв проблем
                        // eslint-disable-next-line no-await-in-loop
                        const countOfUseIcds = await prescriptionService.checkPrescrOverlapIcdUse(
                            this.examId,
                            diag.id,
                            this.issueDate
                        );
                        overlapingIcds.push({ countPromise: countOfUseIcds, code: diag.icdCode });
                    }
                }
            }

            return overlapingIcds;
        }

        private loadIcdUses(overlapingIcds: overlapIcdType[]) {
            if (overlapingIcds.length > 0) {
                overlapingIcds.map((icd) => {
                    if (icd.countPromise >= validConst.maxAllowIcds && this.countOfUseIcds.indexOf(icd.code) < 0) {
                        this.countOfUseIcds.push(icd.code);
                    }
                    return 0;
                });
            }
        }

        @Watch('countOfUseIcds', { deep: true, immediate: true })
        private onCountChange(icdCodes: string[]) {
            if (icdCodes.length > 0) {
                this.isAlertActive = true;
            }
        }

        @Watch('protocolTypeCode')
        private async onProtocolTypeCodeChange(value: string) {
            if (value !== 'Без протокол') {
                await this.checkExistingIcdProtocol();
            }
        }

        private async checkExistingIcdProtocol() {
            // Има проверка която казва, че ако се изписва по протокол се взима само една МКБ
            if (this.checkIcdCodesEqual(this.icdCodes)) {
                const check = await prescriptionService.checkPrescrExistingIcdProtocol(
                    this.protocolTypeCode,
                    this.icdCodes[0],
                    new Date(this.issueDate)
                );

                if (check) {
                    this.isAlertActive = true;
                    this.protocolExistiningIcdMessage = `На пациент на който се отпускат лекарствени продукти по протокол IA
                    не могат да се отпускат други лекарствени продукти за същото заболяване(${this.icdCodes[0]})`;
                }
            }
        }
    }
</script>
