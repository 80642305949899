import { PhysiotherapyCardProcedureDto } from '@/model/Exam/PhysiotherapyCard/PhysiotherapyCardProcedureDto';

export class PhysiotherapyCardDto {
    public id: number = 0;
    public examId: number = 0;
    public treatment: string = '';
    public symptoms: string = '';
    public diagnosis: string = '';
    public number: string = '';
    public procedures: PhysiotherapyCardProcedureDto[] = [];
}
