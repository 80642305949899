import { Type } from 'class-transformer';

import { ReferralTelkDiagnosisDto } from '@/model/Exam/Referral/ReferralTelkDiagnosisDto';
import { ReferralTelkDocumentCardDto } from '@/model/Exam/Referral/ReferralTelkDocumentCardDto';
import { NhisReferralDto } from '@/model/Nhis/Referral/NhisReferralDto';

export class ReferralTelkDto {
    public id: number = 0;
    public number: number = 0;
    public IsDeleted: boolean = false;
    public examId: number = 0;
    public issueDate: Date | null = null;
    public diagnoses: ReferralTelkDiagnosisDto[] = [];
    public referralTelkId: number = 1;
    public referralTelkSubtypeId: number = 1;
    public addressTypeCode: string | null = null;
    public isHomeVisitNecessary: string | null = null;
    public nhisReferral: NhisReferralDto = new NhisReferralDto();

    //Данни за лекаря
    public nameOfHospital: string = '';
    public addressOfHospital: string = '';
    public phoneNumber: string = '';
    public nhifCode: string = '';
    public specialistIdNumber: string = '';

    public currentAddressCityId: number | null = null;
    public currentAddress: string = '';
    public workplaceId: number | null = null;
    public workingPhone: string = '';

    // Анамнеза
    public symptoms: string = '';

    // Обективно състояние
    public condition: string = '';

    @Type(() => ReferralTelkDocumentCardDto)
    public documentCards: ReferralTelkDocumentCardDto[] = [];
}
