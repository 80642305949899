<template>
    <text-field :value="text" readonly :label="label" :disabled="disabled"></text-field>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { dateUtil } from '@/util/DateUtil';

    @Component
    export default class DateField extends Vue {
        @Prop()
        private value!: string | null;

        @Prop()
        private showTime!: boolean;

        @Prop()
        private disabled!: boolean;

        @Prop({ default: '' })
        private label!: string;

        private get text(): string | null {
            if (this.value) {
                return this.showTime ? dateUtil.formatBgDateTime(this.value) : dateUtil.formatBgDate(this.value);
            }
            return null;
        }
    }
</script>
