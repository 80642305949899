import { NhifPrescriptionBookDiagnosisDto } from '@/model/Nhif/NhifPrescriptionBookDiagnosisDto';
import { NhisErrorDto } from '@/model/Nhis/NhisErrorDto';

export class NhifPrescriptionBookDto {
    public prescriptionBookNumber: string = '';
    public error: string = '';
    public response: string = '';
    public errors: NhisErrorDto[] = [];
    public diagnoses: NhifPrescriptionBookDiagnosisDto[] = [];
}
