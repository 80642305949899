import { AxiosResponse } from 'axios';

import { NhifProtocolRequirementDto } from '@/model/Nomenclature/NhifProtocolRequirementDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhifProtocolRequirementService = {
    getNhifProtocolRequirements(icdCode: string | null): Promise<AxiosResponse<NhifProtocolRequirementDto[]>> {
        return httpService.get('/NhifProtocolRequirement/GetNhifProtocolRequirementsByIcd', {
            params: {
                icdCode
            }
        });
    }
};
