import { AxiosResponse } from 'axios';

import { DoctorEmployeeCounterDto } from '@/model/Setting/Counter/DoctorEmployeeCounterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const doctorEmployeeCounterService = {
    getCurrentDoctorEmployeeCounters(
        practiceId: number,
        doctorEmployeeSeqNumber: number
    ): Promise<AxiosResponse<DoctorEmployeeCounterDto[]>> {
        return httpService.get('/DoctorEmployeeCounter/GetDoctorEmployeeCounters', {
            params: { practiceId, doctorEmployeeSeqNumber }
        });
    },

    getCurrentDoctorEmployeeCounter(
        practiceId: number,
        doctorEmployeeSeqNumber: number,
        counterTypeCode: string
    ): Promise<AxiosResponse<DoctorEmployeeCounterDto>> {
        return httpService.get('/DoctorEmployeeCounter/GetDoctorEmployeeCounter', {
            params: { practiceId, doctorEmployeeSeqNumber, counterTypeCode }
        });
    },

    createCurrentDoctorEmployeeCounter(data: DoctorEmployeeCounterDto): Promise<AxiosResponse> {
        return httpService.post('/DoctorEmployeeCounter/CreateDoctorEmployeeCounter', data);
    },

    updateCurrentDoctorEmployeeCounter(data: DoctorEmployeeCounterDto): Promise<AxiosResponse> {
        return httpService.post('/DoctorEmployeeCounter/UpdateDoctorEmployeeCounter', data);
    }
};
