<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" :title="isSpecialized ? 'Направление 3А' : 'Направление 3'" :items="items">
                <template #actions>
                    <btn
                        v-if="!disableNewBtn"
                        action="New"
                        :to="`/Exam/${isSpecialized ? 'Referral3a' : 'Referral3'}/Create/${examId}`"
                    />
                </template>
                <template #rowActions="{ item }">
                    <btn
                        icon
                        action="Edit"
                        :to="`/Exam/${isSpecialized ? 'Referral3a' : 'Referral3'}/Edit/${item.id}`"
                    />
                    <btn v-if="canSendForm && !item.nrn" icon action="Send" @click="postReferral(item)" />
                    <btn
                        icon
                        action="Print"
                        :to="`${isSpecialized ? '/Print/Exam.Referral.Referral3a' : '/Print/Exam.Referral.Referral3'}/${
                            item.id
                        }`"
                    />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { NhisReferralStatusCode } from '@/enum/Nhis/NhisReferralStatusCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Referral3ViewDto } from '@/model/Exam/Referral/Referral3ViewDto';
    import { referral3Service } from '@/service/Exam/Referral/Referral3Service';
    import { nhisReferralService } from '@/service/Nhis/NhisReferralService';
    import { nhisService } from '@/service/Nhis/NhisService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class Referral3View extends Vue {
        @Prop()
        examId!: number;

        @Prop()
        isSpecialized!: boolean;

        private items: Referral3ViewDto[] = [];
        private disableNewBtn: boolean = false;

        private get headers(): IDataTableHeader[] {
            return this.isSpecialized
                ? [
                      { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                      { text: 'Номер:', value: 'number' },
                      { text: 'Специалност:', value: 'specialty' },
                      { text: 'ВСД:', value: 'medicalProcedure' },
                      { text: 'МКБ 1:', value: 'diagnosis1Code' },
                      { text: 'МКБ 2:', value: 'diagnosis2Code' },
                      { text: 'НРН:', value: 'nrn' },
                      { text: 'Статус:', value: 'status' },
                      { text: 'Дата на проверка:', value: 'statusTime', formatter: formatters.dateTime },
                      { text: '', value: 'actionButtons', sortable: false }
                  ]
                : [
                      { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                      { text: 'Номер:', value: 'number' },
                      { text: 'Специалност:', value: 'specialty' },
                      { text: 'Тип направление:', value: 'referralSubtype' },
                      { text: 'МКБ 1:', value: 'diagnosis1Code' },
                      { text: 'МКБ 2:', value: 'diagnosis2Code' },
                      { text: 'НРН:', value: 'nrn' },
                      { text: 'Статус:', value: 'status' },
                      { text: 'Дата на проверка:', value: 'statusTime', formatter: formatters.dateTime },
                      { text: '', value: 'actionButtons', sortable: false }
                  ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await referral3Service.getReferralsByExamId(this.examId, this.isSpecialized);
                if (response?.data) {
                    this.items = response.data;
                    this.checkItemsCount();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private get canSendForm() {
            return (
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private async postReferral(item: Referral3ViewDto) {
            this.$loading.show();
            try {
                if (!item.statusCode) {
                    const requestData = nhisService.requestData(
                        item.id,
                        this.isSpecialized ? 'Referral3a' : 'Referral3'
                    );
                    const response = await nhisReferralService.postReferral(requestData);
                    if (response) {
                        this.$notifier.showSuccess('', `Успешно изпратен документ с НРН: ${response.nrn}`);
                        await this.loadItems();
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private checkItemsCount() {
            const maxReferral3aItems = 2;
            const maxReferral3Items = 4;
            const itemsCount = this.items.filter(
                (items) => (items.nrn && items?.statusCode !== NhisReferralStatusCode.Canceled) || items.nrn === null
            ).length;
            if (
                (this.isSpecialized && itemsCount >= maxReferral3aItems) ||
                (!this.isSpecialized && itemsCount >= maxReferral3Items)
            ) {
                this.disableNewBtn = true;
            } else {
                this.disableNewBtn = false;
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
