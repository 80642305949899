import { plainToInstance } from 'class-transformer';

import { LabTestDto } from '@/model/Nomenclature/LabTestDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const labTestService = {
    async searchItems(filters: []): Promise<LabTestDto[]> {
        const response = await httpService.get<LabTestDto[]>('/LabTest/SearchLabTests', {
            params: { filter: filters.at(0) }
        });
        return plainToInstance(LabTestDto, response.data);
    },
    async getItemsById(labTestCodes: string | string[]): Promise<LabTestDto[]> {
        const labTestCodesText = typeof labTestCodes === 'string' ? labTestCodes : labTestCodes.join();
        const response = await httpService.get<LabTestDto[]>('/LabTest/GetLabTestsByCodes', {
            params: { labTestCodes: labTestCodesText }
        });
        return plainToInstance(LabTestDto, response.data);
    }
};
