<template>
    <v-row dense>
        <v-col cols="8">
            <dropdown v-model="value.procedureId" :items="procedureDropdownItems" label="Процедура"></dropdown>
        </v-col>
        <v-col :cols="isRehabilitation ? 2 : 4">
            <dropdown
                v-model="value.procedureId"
                :items="procedureDropdownItems"
                item-text="code"
                label="Код"
            ></dropdown
        ></v-col>
        <v-col v-if="isRehabilitation" cols="2">
            <text-field v-model="value.count" type="number" label="Брой" :required="Boolean(value.procedureId)" />
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { AmbulatorySheetMedicalProcedureDto } from '@/model/Exam/AmbulatorySheetMedicalProcedureDto';
    import { MedicalProcedureDto } from '@/model/Nomenclature/MedicalProcedureDto';
    import { medicalProcedureService } from '@/service/Nomenclature/MedicalProcedureService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class AmbulatorySheetMedicalProcedureCard extends Vue {
        // Този компонент може да получи undefined v-model. В този случай се bind-ва default стойността, която е празен обект.
        @Prop({ default: () => new AmbulatorySheetMedicalProcedureDto(), type: Object })
        private value!: AmbulatorySheetMedicalProcedureDto;

        private get isRehabilitation() {
            return userContextCache.current?.specialtyCode === SpecialtyCode.Rehabilitation;
        }

        private procedureDropdownItems: MedicalProcedureDto[] = [];

        private async mounted() {
            if (userContextCache.current?.specialtyCode) {
                const response = await medicalProcedureService.getMedicalProceduresBySpecialty(
                    userContextCache.current.specialtyCode
                );
                if (response?.data) {
                    this.procedureDropdownItems = response?.data;
                }
            }
        }
    }
</script>
