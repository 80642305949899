<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Бързо известие - Бл. МЗ-300"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card class="mb-md-4">
            <main-title>Бързо известие - Бл. МЗ-300</main-title>
            <v-card-subtitle class="mt-1">
                за случай на болен (възможен; вероятен; потвърден), заразоносител или починал от заразно заболяване
            </v-card-subtitle>
            <hr />
            <v-card-text>
                <v-textarea v-model="document.diagnosis1" label="Диагноза" />
                <v-select
                    v-model="document.sickStatusCode"
                    :items="sickStatuses"
                    item-text="name"
                    dense
                    item-value="code"
                    label="Статус"
                    :rules="[$validator.required]"
                />
            </v-card-text>
            <hr />
            <v-card-text>
                <PatientInfo />
            </v-card-text>
            <hr />
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="4"><h3>Професия</h3></v-col>
                    <v-col cols="12" md="8">
                        <text-field v-model="document.profession" label="Професия:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4"><h3>Месторабота</h3></v-col>
                    <v-col cols="12" md="8">
                        <text-field v-model="document.workAddress" label="Месторабота:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4"><h3>Дата на последно посещение в колектива</h3></v-col>
                    <v-col cols="8">
                        <date-picker v-model="document.lastVisitWorkDate" label="Дата:" />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
            <v-card-text>
                <v-row>
                    <v-col cols="12"><h3>Заболял:</h3></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <CityPicker v-model="document.sicknessCityId" label="Населено място:" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <date-picker v-model="document.sicknessDate" label="Дата:" />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
            <v-card-text>
                <h3>Преглед за първи път по повод заболяването</h3>
                <v-row class="mt-4">
                    <v-col cols="12" md="4">
                        <CityPicker v-model="document.initialExamCityId" label="А. Населено място:" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.initialExamFacility" label="Лечебно заведение:" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.initialExamDate" label="Б. Дата:" />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="document.initialExamDiagnosis" dense label="В. Първоначална диагноза:" />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-radio-group v-model="document.discoveredActively">
                            <template #label>
                                <div>Болният е открит:</div>
                            </template>
                            <v-radio label="активно" :value="true"></v-radio>
                            <v-radio label="пасивно" :value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="9">
                        <v-textarea v-model="document.discoveredNotes" label="Бележки:" />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="8">
                        <text-field v-model="document.specificDiagnosisFacility" label="Лечебно заведение:" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.specificDiagnosisDate" label="Дата:" />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="document.specificDiagnosis" dense label="Уточнена диагноза:" />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <h3>Потвърждаване на диагнозата - резултати</h3>
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="document.resultExam" label="А. Изследване:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="document.resultParaclinic" label="Б. Параклиника:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="document.resultPathology" label="В. Патологоанатомия:" />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-radio-group v-model="document.isTreatedAtHome">
                            <template #label>
                                <div>Болният е открит:</div>
                            </template>
                            <v-radio label="оставен в дома си" :value="true"></v-radio>
                            <v-radio label="хоспитализиран в" :value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="9">
                        <v-textarea v-model="document.assignedTreatmentNotes" label="Бележки:" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.assignedTreatmentDate" label="Дата:" />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
            <v-card-text>
                <v-row>
                    <v-col cols="12"><h3>Умрял</h3></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <CityPicker v-model="document.deceasedCityId" label="А. Населено място:" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.deceasedDate" label="Б. Дата" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.deceasedFacility" label="В. Лечебно заведение" />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
            <v-card-text>
                <v-row>
                    <v-col cols="12"><h3>Проведена имунизация срещу заболяването</h3></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="3">
                        <h3>А. Имунизация:</h3>
                    </v-col>
                    <v-col cols="12" md="3">
                        <date-picker v-model="document.immunization1Date" label="" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <date-picker v-model="document.immunization2Date" label="" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <date-picker v-model="document.immunization3Date" label="" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="3">
                        <h3>Б. Реимунизация</h3>
                    </v-col>
                    <v-col cols="12" md="3">
                        <date-picker v-model="document.reimmunization1Date" label="" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <date-picker v-model="document.reimmunization2Date" label="" />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
            <v-card-text>
                <v-row>
                    <v-col cols="12"><h3>Контакт с болен със същата диагноза:</h3></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="8">
                        <text-field v-model="document.contactPersonName" label="Име на болния:" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.contactDate" label="Дата на контакта:" />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />

            <v-card-text>
                <v-row>
                    <v-col cols="12"><h3>Контактни на този болен:</h3></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="document.contactInfoHome" label="В дома:" />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="document.contactInfoCollective" label="В колектива:"></text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />

            <v-card-text>
                <v-row>
                    <v-col cols="12" md="8">
                        <v-col cols="12"><h3>ИЗПРАЩА:</h3></v-col>
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.noticeSendTo" label="ДО:"></text-field>
                    </v-col>
                </v-row>
                <v-row> </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field
                            :value="noticeSenderNameAndSpecialty"
                            disabled
                            label="открил заболяването:"
                        ></text-field>
                    </v-col>
                </v-row>
                <!--<v-row>
                <v-col cols="4" offset="8">
                    <v-text-field :value="noticeSenderInformation" disabled label="Лечебно заведение:"></v-text-field>
                </v-col>
            </v-row>-->
                <v-row>
                    <v-col cols="12">
                        <text-field :value="noticeSenderAddress" disabled label="Адрес:"></text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.issueDate" label="Дата:" />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import CityPicker from '@/component/Picker/CityPicker.vue';
    import { QuickNoticeDto } from '@/model/Exam/QuickNotice/QuickNoticeDto';
    import { SickStatusDto } from '@/model/Nomenclature/SickStatusDto';
    import { quickNoticeService } from '@/service/Exam/QuickNoticeService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { practiceNhifContractService } from '@/service/Practice/PracticeNhifContractService';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: {
            UnsavedChanges,
            PatientInfo,
            ExamLayout,
            CityPicker
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class QuickNotice extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private document: QuickNoticeDto = new QuickNoticeDto();
        private initialValue: QuickNoticeDto = new QuickNoticeDto();
        private sickStatuses: SickStatusDto[] = [];
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        public noticeSenderNameAndSpecialty: string = '';
        public noticeSenderInformation: string = '';
        public noticeSenderAddress: string = '';

        private get currentVisit() {
            return currentVisitCache.value;
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                await this.getSickSatuses();

                this.noticeSenderNameAndSpecialty = `${this.currentVisit.doctorNames} (${this.currentVisit.doctorSpecialtyName})`;
                //this.noticeSenderInformation = `${this.currentVisit.practiceNumber}`;

                const contracts = await practiceNhifContractService.getByPracticeId(
                    this.currentVisit.patientVersionKey.practiceId
                );
                if (contracts.length > 0) {
                    this.noticeSenderAddress = `${contracts[0].addressByContract}`;
                }

                if (!this.documentId) {
                    const responseExam = await quickNoticeService.getQuickNoticeInfoByExamId(this.examId);
                    this.document = responseExam.data;
                } else {
                    const response = await quickNoticeService.getQuickNotice(this.documentId);
                    this.document = response.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        //private async save() {

        //}

        private async getSickSatuses() {
            const response = await quickNoticeService.getSickSatuses();
            this.sickStatuses = response.data;
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let refId = this.document.id;
                if (refId === 0) {
                    const response = await quickNoticeService.createQuickNotice(this.document);
                    refId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', refId, 'QuickNotice', 'Бързо известие');
                    this.$router.push(`/Exam/QuickNotice/Edit/${refId}`);
                } else {
                    await quickNoticeService.updateQuickNotice(this.document);
                    this.copyObject();
                    eventBus.$emit('update-referral-event', 'Бързо известие');
                }
            } finally {
                this.$loading.hide();
            }
            //await this.save();
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const refId = this.document.id;
                await quickNoticeService.deleteQuickNotice(refId);
                this.copyObject();
                this.$router.push(`/Exam/QuickNotice/Create/${currentVisitCache.value.id}`);
                eventBus.$emit('delete-referral-event', refId, 'QuickNotice', 'Бързо известие');
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.document.id) {
                this.$router.push(`/Print/Exam.QuickNotice/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.QuickNotice';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
