import { ExamTypeDropdownModel } from '@/model/Nomenclature/ExamTypeDropdownModel';

export const examTypeService = {
    getExamTypes() {
        return [
            new ExamTypeDropdownModel(false, false, 'Амбулаторен - първичен'),
            new ExamTypeDropdownModel(true, false, 'Амбулаторен - вторичен'),
            new ExamTypeDropdownModel(false, true, 'Домашен - първичен'),
            new ExamTypeDropdownModel(true, true, 'Домашен - вторичен')
        ];
    }
};
