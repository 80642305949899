export class DeathNoticeDto {
    public exists: boolean = false;
    public patientId: number = 0;
    public doctorEmployeeSeqNumber: number = 0;
    public deputyDoctorId: number | null = null;
    public deputyDoctorIsHired: boolean | null = null;
    public deputyDoctorName: string | null = null;
    public number: number = 1;
    public issueDate: Date = new Date();
    public deathDate: Date = new Date();
    public placeOfDeathId: number = 1;
    public deathAddress: string = '';
    public hasAutopsy: boolean = false;
    public reasonForDeath: string = '';
    public diseaseDirectlyCausedDeath: string = '';
    public directlyDiseasePeriod: string = '';
    public diseaseIcdCode: string = '';
    public previousDisease: string = '';
    public previousDiseasePeriod: string = '';
    public mainDisease: string = '';
    public mainDiseasePeriod: string = '';
    public otherDisease: string = '';
    public otherDiseasePeriod: string = '';
    public pathologyDisease: string = '';
    public pathologyDiseasePeriod: string = '';
    public mainIcdCode: string = '';
    public otherIcdCode: string = '';
    public deathEstablishedById: number = 0;
    public deathReasonId: number = 0;
}
