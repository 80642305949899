import { NhisMedicalNoteDto } from '@/model/Nhis/MedicalNote/NhisMedicalNoteDto';

export class MedicalNoteDto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date | null = new Date();
    public examDiagnosisId: number | null = null;
    public startDate: Date | null = new Date();
    public endDate: Date | null = null;
    public treatmentLocationCode: string | null = null;
    public reasonCode: string | null = null;
    public diagnosis: string = '';
    public purpose: string = '';
    public necessity: string = '';
    public practiceName: string = '';
    public practiceCity: string = '';
    public nhisMedicalNote: NhisMedicalNoteDto = new NhisMedicalNoteDto();
}
