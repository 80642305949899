<template>
    <ExamLayout
        :can-delete="document.key.prescriptionId > 0"
        :can-print="document.key.prescriptionId > 0"
        title="Рецептурна бланка - бл.МЗ-НЗОК №5В"
        help-action="Form5A"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <validation-alert
            :nhif-drugs="[document.nhifDrug, document.nhifDrug2, document.nhifDrug3]"
            :exam-diagnosis="[examDiagnosis1, examDiagnosis2, examDiagnosis3]"
            :protocol-type-code="document.protocolTypeCode"
            :issue-date="document.issueDate"
            :exam-id="document.key.examId"
        />
        <div v-if="!document.certificateNumber">
            <v-row>
                <alert :value="!document.certificateNumber" type="info" prominent>
                    Внимание! За посочения пациент няма данни за въведена рецептурна книжка за ветерани!
                </alert>
            </v-row>
            <v-card-text class="text-h5 font-weight-meduim">
                Моля, попълнете данните за Ветеран, като последвате
                <router-link :to="redirectToPrescriptionBook">този линк</router-link>.
            </v-card-text>
        </div>
        <div v-else-if="document.certificateNumber">
            <v-card class="mb-md-4">
                <main-title>Рецептурна бланка - бл.МЗ-НЗОК №5В</main-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="4" md="3"><text-field disabled label="Рецепта №(аптека)" /></v-col>
                        <v-col cols="4" md="3"><text-field disabled label="Изпълнила аптека №" /></v-col>
                        <v-col cols="4" md="3">
                            <dropdown
                                v-model="document.protocolTypeCode"
                                :items="prescriptionProtocolTypes"
                                item-text="code"
                                item-value="code"
                                label="Начин на предписание"
                                required
                                @input="clearAllDrugs"
                            />
                        </v-col>
                    </v-row>

                    <v-row v-if="document.protocolTypeCode !== 'Без протокол'">
                        <v-col cols="6" md="3">
                            <text-field v-model="document.protocolNumber" required label="Протокол №" />
                        </v-col>
                        <v-col cols="6" md="3">
                            <date-picker v-model="document.protocolDateTime" required label="Протокол Дата:" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text>
                    <AmbulatorySheetHeaderInfoCard v-once />
                </v-card-text>
            </v-card>

            <v-card class="mb-md-4">
                <v-card-text>
                    <v-row>
                        <v-col xs="12" md="3" cols="12">
                            <date-picker v-model="document.issueDate" label="Дата на изпъл. на рецептата:" required />
                        </v-col>

                        <v-col>
                            <text-field
                                id="prescriptionBookNumber"
                                v-model="document.prescriptionBookNumber"
                                label="№ на рецептурна книжка"
                                readonly
                            />
                        </v-col>
                        <v-col>
                            <text-field
                                v-model.number="document.ambulatorySheetNumber"
                                disabled
                                label="Амбулаторен лист №"
                            />
                        </v-col>

                        <v-col>
                            <text-field v-model.number="document.prescriptionNumber" disabled label="Рецепта №" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="col mb-4">
                <v-card-actions v-if="document.nhifDrug" class="justify-end ma-0 pa-0">
                    <btn action="Delete" @click="clearAllDrugs" />
                </v-card-actions>
                <DrugComponent
                    v-model="document.nhifDrug"
                    :exam-diagnosis="examDiagnosis1"
                    :protocol-type-code="document.protocolTypeCode"
                    :exam-id="document.key.examId"
                    :position="1"
                    :form-type="document.formTypeCode"
                    :exam-diagnoses="examDiagnoses"
                    required-drug
                    @update-nhif="updateNhif"
                    @exam-diagnosis-selected="onExamDiagnosisSelected"
                />

                <DrugComponent
                    v-model="document.nhifDrug2"
                    :exam-diagnosis="examDiagnosis2"
                    :protocol-type-code="document.protocolTypeCode"
                    :exam-id="document.key.examId"
                    :position="2"
                    :form-type="document.formTypeCode"
                    :exam-diagnoses="examDiagnoses"
                    @update-nhif="updateNhif"
                    @exam-diagnosis-selected="onExamDiagnosisSelected"
                />

                <DrugComponent
                    v-model="document.nhifDrug3"
                    :exam-diagnosis="examDiagnosis3"
                    :protocol-type-code="document.protocolTypeCode"
                    :exam-id="document.key.examId"
                    :position="3"
                    :form-type="document.formTypeCode"
                    :exam-diagnoses="examDiagnoses"
                    @update-nhif="updateNhif"
                    @exam-diagnosis-selected="onExamDiagnosisSelected"
                />

                <v-row>
                    <!--Всичко, което трябва да плати пациента-->
                    <v-col md="3" offset-md="9">
                        <text-field
                            v-model.number="document.totalCostPatient"
                            label="Всичко:"
                            disabled
                            :value="totalCostPatient(document.nhifDrug, document.nhifDrug2, document.nhifDrug3)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2"><v-checkbox v-model="document.isPregnant" label="Бременна"></v-checkbox></v-col>
                    <v-col md="2"><v-checkbox v-model="document.isNursing" label="Кърмачка"></v-checkbox></v-col>
                </v-row>
            </v-card>
        </div>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { InfoByExamFilter } from '@/model/Exam/InfoByExamFilter';
    import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
    import { prescriptionNhifConfig as config } from '@/model/Exam/Prescription/PrescriptionNhifConfig';
    import { PrescriptionNhifTypeCDto } from '@/model/Exam/Prescription/PrescriptionNhifTypeCDto';
    import {
        PrescriptionProtocolType,
        prescriptionProtocolTypes
    } from '@/model/Exam/Prescription/PrescriptionProtocolType';
    import { PrescriptionDiagnosisDto } from '@/model/Exam/PrescriptionDiagnosisDto';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { prescriptionService } from '@/service/Exam/PrescriptionService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import AmbulatorySheetHeaderInfoCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetHeaderInfoCard.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    import DrugComponent from './DrugComponent.vue';
    import ValidationAlert from './PrescriptionValidationAlert.vue';

    @Component({
        components: {
            AmbulatorySheetHeaderInfoCard,
            UnsavedChanges,
            DrugComponent,
            ValidationAlert,
            ExamLayout
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class PrescriptionNhifTypeC extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop({ required: true })
        private prescriptionKey!: PrescriptionKey;

        private document: PrescriptionNhifTypeCDto = new PrescriptionNhifTypeCDto();
        private initialValue: PrescriptionNhifTypeCDto = new PrescriptionNhifTypeCDto();
        private requiredIcdTypes: string[] = [];
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private prescriptionProtocolTypes: PrescriptionProtocolType[] = prescriptionProtocolTypes;
        private examDiagnoses: PrescriptionDiagnosisDto[] = [];

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                await currentVisitCache.load(this.prescriptionKey.examId);
                this.getDiagnoses();
                if (!this.prescriptionKey.prescriptionId) {
                    this.document = (await prescriptionService.getPrescriptionInfoByExamId(
                        new InfoByExamFilter(
                            this.prescriptionKey.examId,
                            currentVisitCache.value.startDateTime,
                            PrescriptionFormTypeCode.Nhif5c
                        )
                    )) as PrescriptionNhifTypeCDto;
                } else {
                    this.document = (await prescriptionService.getPrescription(
                        this.prescriptionKey
                    )) as PrescriptionNhifTypeCDto;
                }
                await this.getRequiredTypes();
            } finally {
                this.$loading.hide();
            }
        }

        private async getRequiredTypes() {
            this.requiredIcdTypes = await prescriptionService.getRequiredIcdPrescriptionBook();
        }

        private get redirectToPrescriptionBook(): string {
            if (!this.document.certificateNumber) {
                return `/Patient/PrescriptionBook/${currentPatientCache.value.key.patientId}`;
            }
            return 'Няма линк';
        }

        private clearAllDrugs() {
            this.document.nhifDrug = new NhifDrugDto();
            this.document.nhifDrug2 = new NhifDrugDto();
            this.document.nhifDrug3 = new NhifDrugDto();
        }

        private totalCostPatient(value: NhifDrugDto, value2: NhifDrugDto, value3: NhifDrugDto): number {
            const totalCost: number = Number(
                (
                    value?.maxPatientPrice ??
                    0 * value?.packageCount ??
                    0 + value2.maxPatientPrice * value2.packageCount + value3.maxPatientPrice * value3.packageCount
                ).toFixed(config.currencyPrecision)
            );
            this.document.totalCostPatient = totalCost;
            return totalCost;
        }

        private updateNhif(event: { nhifDrug: NhifDrugDto; position: number }) {
            if (event.position === config.position1) {
                this.document.nhifDrug = event.nhifDrug;
            }
            if (event.position === config.position2) {
                this.document.nhifDrug2 = event.nhifDrug;
            }
            if (event.position === config.position3) {
                this.document.nhifDrug3 = event.nhifDrug;
            }
        }

        private get currentVisitCache() {
            return currentVisitCache.value;
        }

        private get examDiagnosis1() {
            return this.getPrescriptionDiagnosis(this.document.examDiagnosis1Id, this.document.isDiagnosis1Add);
        }

        private get examDiagnosis2() {
            return this.getPrescriptionDiagnosis(this.document.examDiagnosis2Id, this.document.isDiagnosis2Add);
        }

        private get examDiagnosis3() {
            return this.getPrescriptionDiagnosis(this.document.examDiagnosis3Id, this.document.isDiagnosis3Add);
        }

        private onExamDiagnosisSelected(event: { examDiagnosisId: number | null; position: number; isAdd: boolean }) {
            if (event.examDiagnosisId) {
                if (event.position === config.position1) {
                    this.document.examDiagnosis1Id = event.examDiagnosisId;
                    this.document.isDiagnosis1Add = event.isAdd;
                }
                if (event.position === config.position2) {
                    this.document.examDiagnosis2Id = event.examDiagnosisId;
                    this.document.isDiagnosis2Add = event.isAdd;
                }
                if (event.position === config.position3) {
                    this.document.examDiagnosis3Id = event.examDiagnosisId;
                    this.document.isDiagnosis3Add = event.isAdd;
                }
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                if (this.document.certificateNumber) {
                    let { prescriptionId } = this.document.key;
                    let title = '';
                    if (prescriptionId === 0) {
                        this.document.formTypeCode = PrescriptionFormTypeCode.Nhif5c;
                        const result = await prescriptionService.createTypeC(this.document);
                        prescriptionId = result.id;
                        title = this.createTitle(result.number);
                        eventBus.$emit('create-referral-event', prescriptionId, 'PrescriptionNhifTypeC', title);
                        this.copyObject();
                        this.$router.push(
                            `/Exam/PrescriptionNhifTypeC/Edit/${this.prescriptionKey.examId}/${prescriptionId}`
                        );
                    } else {
                        await prescriptionService.updateTypeC(this.document);
                        this.copyObject();
                        title = this.createTitle(this.document.prescriptionNumber);
                        eventBus.$emit('update-referral-event', title);
                    }
                } else {
                    this.$notifier.showWarning(
                        this.$t('notifications.validationErrors').toString(),
                        this.$t('notifications.pleaseFixValidationErrors').toString()
                    );
                }
            } finally {
                this.$loading.hide();
            }
        }

        private createTitle(refnumber: number | null) {
            return `№ ${refnumber}; ${this.document?.nhifDrug?.nhifCode ?? ''}${
                this.document.nhifDrug2.nhifCode !== null ? `;${this.document.nhifDrug2.nhifCode}` : ''
            }${this.document.nhifDrug3.nhifCode !== null ? `;${this.document.nhifDrug3.nhifCode}` : ''}`;
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                if (this.document.certificateNumber) {
                    await prescriptionService.delete(this.prescriptionKey);
                    this.copyObject();
                    this.$router.push(`/Exam/PrescriptionNhifTypeC/Create/${this.prescriptionKey.examId}`);
                    eventBus.$emit(
                        'delete-referral-event',
                        this.prescriptionKey.prescriptionId,
                        'PrescriptionNhifTypeC',
                        `Бланка 5В - №${this.document.prescriptionNumber}`
                    );
                }
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.prescriptionKey.prescriptionId) {
                this.$router.push(
                    `/Print/Exam.Prescription.PrescriptionNhifTypeC/Prescription/${this.prescriptionKey.examId}/${this.prescriptionKey.prescriptionId}`
                );
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const report = 'Exam.Prescription.PrescriptionNhifTypeC';
                const parameters: { [key: string]: string } = {};
                parameters.examId = this.prescriptionKey.examId.toString();
                parameters.prescriptionId = this.prescriptionKey.prescriptionId.toString();
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private getDiagnoses() {
            const examCache = this.currentVisitCache.exam;
            if (examCache) {
                for (const diagnosis of examCache.examDiagnoses) {
                    this.examDiagnoses.push(
                        this.createExamDiagnosis(diagnosis.id ?? 0, diagnosis.icdCode, diagnosis.icdName, false)
                    );
                    if (diagnosis.icdCodeAdd) {
                        this.examDiagnoses.push(
                            this.createExamDiagnosis(
                                diagnosis.id ?? 0,
                                diagnosis.icdCodeAdd,
                                diagnosis.icdNameAdd ?? '',
                                true
                            )
                        );
                    }
                }
            }
        }

        @Watch('currentVisitCache')
        onCurrentVisitCacheChange() {
            this.getDiagnoses();
        }

        private createExamDiagnosis(id: number, icdCode: string, icdName: string, isAdd: boolean) {
            const diagnosis = new PrescriptionDiagnosisDto();
            diagnosis.id = id;
            diagnosis.icdCode = icdCode;
            diagnosis.icdName = icdName;
            diagnosis.isAdd = isAdd;
            return diagnosis;
        }

        public getPrescriptionDiagnosis(
            examDiagnosisId: number | null,
            isAdd: boolean
        ): PrescriptionDiagnosisDto | null {
            return (
                this.examDiagnoses.find(
                    (examDiagnosis) => examDiagnosis.id === examDiagnosisId && examDiagnosis.isAdd === isAdd
                ) ?? null
            );
        }
    }
</script>
