<template>
    <div>
        <data-table
            v-model="selected"
            title="Пропуснати имунизации"
            :headers="tableHeaders"
            :loading="$loading.isVisible"
            :items="items"
            sort-by="occurrenceDate"
            show-search-box
            class="elevation-1 mb-5"
            disable-pagination
            show-select
        >
            <template v-if="selected.length > 0" #actions>
                <btn action="New" @click="openDialog" />
            </template>
        </data-table>
        <v-row justify="center" class="mx-5">
            <v-dialog v-model="dialogIsVisible" persistent max-width="75%">
                <v-card>
                    <v-card-title>
                        <span class="headline">Данни на лекаря извършил пропуснатите имунизации:</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <text-field v-model="model.doctorName" label="Лекар име:" />
                            </v-col>
                            <v-col cols="3">
                                <text-field v-model="model.doctorUin" label="УИН:" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <btn action="Cancel" @click="closeDialog" />
                        <btn action="Save" text @click="generate" />
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ImmunizationDto } from '@/model/Nomenclature/ImmunizationDto';
    import { MissedImmunizationDto } from '@/model/Patient/Immunization/MissedImmunizationDto';
    import { patientImmunizationService } from '@/service/Patient/PatientImmunizationService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class MissedImmunizationTab extends Vue {
        @Prop()
        private shouldGetItems!: boolean;

        private dialogIsVisible: boolean = false;
        private items: ImmunizationDto[] = [];
        private selected: ImmunizationDto[] = [];
        private model: MissedImmunizationDto = new MissedImmunizationDto();

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Код РЗИ:', value: 'rhiCode' },

                {
                    text: 'Код НЗОК:',
                    value: 'nhifCode'
                },
                {
                    text: 'Наименование:',
                    value: 'name'
                },
                { text: 'Валидна от:', value: 'validFrom', formatter: formatters.date },
                { text: 'Валидна до:', value: 'validTo', formatter: formatters.date },
                { text: 'Валидна:', value: 'isValid' }
            ];
        }

        private openDialog() {
            this.dialogIsVisible = true;
        }

        private async generate() {
            this.$loading.show();
            try {
                this.model.immunizations = this.selected;
                this.model.patientId = currentPatientCache.value.key.patientId;
                //TODO Да се взема тази дата от датата, на която е трябвало да се постави имунизацията.
                this.model.occurrenceDate = new Date();

                await patientImmunizationService.generateMissedPatientImmunizations(this.model);
                this.$emit('generated-immunizations');
                await this.getTableItems();
                this.$notifier.showSuccess('', `Успешен запис`);
            } finally {
                this.closeDialog();
                this.$loading.hide();
            }
        }

        private closeDialog() {
            this.dialogIsVisible = false;
            this.selected = [];
            this.model = new MissedImmunizationDto();
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                if (currentPatientCache.isLoaded) {
                    const response = await patientImmunizationService.getMissedImmunizationsForPatient(
                        currentPatientCache.value.key.patientId
                    );
                    if (response) {
                        this.items = response;
                        this.$emit('missed-loaded');
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.getTableItems();
        }

        @Watch('shouldGetItems', { immediate: true })
        onShouldGetItems(newVal: boolean): void {
            if (newVal) {
                this.getTableItems();
            }
        }
    }
</script>
