import { Type } from 'class-transformer';

import { ServiceOfferKey } from '@/model/Practice/Service/ServiceOfferKey';

export class ServiceOfferDto {
    @Type(() => ServiceOfferKey)
    public key: ServiceOfferKey = new ServiceOfferKey();

    public tariffName: string = '';
    public name: string = '';
    public description: string = '';
    public patientPrice: number | null = null;
    public coreServiceTypeCode: string | null = null;
    public labTestCategoryName: string = '';
    public algorithmType: string = '';
}
