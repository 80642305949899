import { Type } from 'class-transformer';

import { AnamnesisFamilyDto } from '@/model/Exam/Gp/RiskAssessmentCard/AnamnesisFamilyDto';
import { AnamnesisPersonalDto } from '@/model/Exam/Gp/RiskAssessmentCard/AnamnesisPersonalDto';
import { BiometricDataDto } from '@/model/Exam/Gp/RiskAssessmentCard/BiometricDataDto';
import { MedicalDiagnosticTestDto } from '@/model/Exam/Gp/RiskAssessmentCard/MedicalDiagnosticTestDto';
import { RiskGroupRuleCardDto } from '@/model/Exam/Gp/RiskAssessmentCard/RiskGroupRuleCardDto';

export class RiskAssessmentCardDto {
    public id: number = 0;
    public examId: number = 0;
    public patientId: number = 0;
    public issueDate: Date = new Date();
    public isPrinted: boolean = false;
    public score: number | null = null;
    public isFull: boolean = false;
    public findRisk: number | null = null;
    public registerInclude: boolean = false;
    public isPreventiveExam: boolean = false;
    public riskGroups: RiskGroupRuleCardDto[] = [];

    @Type(() => AnamnesisPersonalDto)
    public anamnesisPersonal: AnamnesisPersonalDto = new AnamnesisPersonalDto();

    @Type(() => AnamnesisFamilyDto)
    public anamnesisFamily: AnamnesisFamilyDto = new AnamnesisFamilyDto();

    @Type(() => BiometricDataDto)
    public biometricData: BiometricDataDto = new BiometricDataDto();

    @Type(() => MedicalDiagnosticTestDto)
    public medicalDiagnosticTest: MedicalDiagnosticTestDto = new MedicalDiagnosticTestDto();
}
