<template>
    <v-card>
        <v-card-title>Номерация на документите на лекаря</v-card-title>
        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn action="Save" @click="updateDoctorEmployeeCounter" />
        </v-card-actions>
        <v-card-text>
            <v-form ref="documentForm" v-model="isValid">
                <dropdown
                    v-model="doctorEmployeeCounter.counterTypeCode"
                    :items="counterTypes"
                    item-value="code"
                    label="Вид документи"
                    required
                ></dropdown>
                <text-field
                    v-model.number="doctorEmployeeCounter.nextNumber"
                    label="Следващ номер"
                    type="number"
                    required
                />
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { CounterTypeDto } from '@/model/Nomenclature/CounterTypeDto';
    import { DoctorEmployeeCounterDto } from '@/model/Setting/Counter/DoctorEmployeeCounterDto';
    import { counterTypeService } from '@/service/Nomenclature/CounterTypeService';
    import { doctorEmployeeCounterService } from '@/service/Setting/Counter/DoctorEmployeeCounterService';

    @Component
    export default class DoctorEmployeeCounterEdit extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        @Prop()
        private counterTypeCode!: string;

        private counterTypes: CounterTypeDto[] = [];
        private doctorEmployeeCounter: DoctorEmployeeCounterDto = new DoctorEmployeeCounterDto();

        private isValid: boolean = false;

        private async mounted() {
            this.$loading.show();
            try {
                await this.loadCounterTypes();

                if (this.counterTypeCode) {
                    const response = await doctorEmployeeCounterService.getCurrentDoctorEmployeeCounter(
                        this.practiceId,
                        this.doctorEmployeeSeqNumber,
                        this.counterTypeCode
                    );
                    this.doctorEmployeeCounter = response.data;
                } else {
                    this.doctorEmployeeCounter.practiceId = this.practiceId;
                    this.doctorEmployeeCounter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async loadCounterTypes() {
            const response = await counterTypeService.getDoctorCounterTypes();
            this.counterTypes = response.data;
        }

        private async updateDoctorEmployeeCounter() {
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

            if (this.isValid) {
                if (!this.counterTypeCode) {
                    await doctorEmployeeCounterService.createCurrentDoctorEmployeeCounter(this.doctorEmployeeCounter);
                } else {
                    await doctorEmployeeCounterService.updateCurrentDoctorEmployeeCounter(this.doctorEmployeeCounter);
                }
                this.$router.push(this.backRoute);
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private get backRoute() {
            return `/Setting/DoctorEmployeeCounter/List/${this.practiceId}/${this.doctorEmployeeSeqNumber}`;
        }
    }
</script>
