<template>
    <v-row>
        <v-col>
            <data-table
                :headers="headers"
                :title="is8a ? 'Бланка 8А - Направление за процедура' : 'Бланка 8 - Направление за процедура'"
                :items="items"
            >
                <template #actions>
                    <btn action="New" :to="`/Exam/${is8a ? 'Referral8a' : 'Referral8'}/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/${is8a ? 'Referral8a' : 'Referral8'}/Edit/${item.id}`" />
                    <btn
                        icon
                        action="Print"
                        :to="`${is8a ? '/Print/Exam.Referral.Referral8a' : '/Print/Exam.Referral.Referral8'}/${
                            item.id
                        }`"
                    />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Referral8ViewDto } from '@/model/Exam/Referral/Referral8ViewDto';
    import { referral8Service } from '@/service/Exam/Referral/Referral8Service';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class Referral8View extends Vue {
        @Prop()
        examId!: number;

        @Prop()
        is8a!: boolean;

        private items: Referral8ViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Спешен:', value: 'isEmergency' },
                { text: 'АПр/КПр:', value: 'algorithmName' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await referral8Service.getReferralsByExamId(this.examId, this.is8a);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
