import { RouteConfig } from 'vue-router';

const visitListChildRoutes: RouteConfig[] = [
    {
        path: 'VisitSearch',
        components: {
            visitList: () => import('@/view/VisitList/VisitSearch.vue')
        }
    },
    {
        path: 'SelectCertificate',
        components: {
            visitList: () => import('@/view/VisitList/SelectCertificate.vue')
        }
    },
    {
        path: 'Queue',
        components: {
            visitList: () => import('@/view/VisitList/Queue.vue')
        }
    },
    {
        path: 'Journal',
        components: {
            visitList: () => import('@/view/VisitList/Journal.vue')
        }
    }
];

export const visitListRoutes: RouteConfig = {
    path: '/VisitList',
    component: () => import('@/view/VisitList/VisitListMenu.vue'),
    children: visitListChildRoutes
};
