<template>
    <left-menu-layout :menu-items="menuItems" router-name="finance" />
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import LeftMenuLayout from '@/component/Menu/LeftMenuLayout.vue';
    import { createFinanceMenuItems } from '@/model/Menu/FinanceMenuItems';

    @Component({
        components: { LeftMenuLayout }
    })
    export default class FinanceMenu extends Vue {
        private get menuItems() {
            return createFinanceMenuItems();
        }
    }
</script>
