<template>
    <div>
        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <choice-maker-dropdown-search
                        v-model="selected"
                        :disabled="disabled"
                        @input="populateChoiceMaker"
                    />
                </v-col>

                <v-spacer></v-spacer>
                <v-col cols="">
                    <btn v-if="showClearBtn" action="Delete" @click="clearChoiceMaker">Изчисти</btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <v-row>
                <v-col md="2">
                    <h4>Основни данни</h4>
                </v-col>
            </v-row>
            <person-identification
                :show-certificate-data="false"
                :person-identification="value.personIdentification"
                :disabled="disabled"
            ></person-identification>
            <v-row>
                <v-col md="3">
                    <text-field v-model="value.firstName" required label="Име:" :disabled="disabled" />
                </v-col>
                <v-col md="3">
                    <text-field
                        v-model="value.middleName"
                        label="Презиме:"
                        :disabled="disabled"
                        :rules="[$validator.personName]"
                    />
                </v-col>
                <v-col md="3">
                    <text-field v-model="value.lastName" required label="Фамилия:" :disabled="disabled" />
                </v-col>
            </v-row>
            <v-row>
                <h4 class="mb-2">Адресна информация</h4>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <Address v-model="value.address" :show-mailbox="false" :show-post-code="false" :disabled="disabled"
                /></v-col>
            </v-row>
            <v-row>
                <h4 class="mb-2">Контактна информация</h4>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <Contact v-model="value.contact" :disabled="disabled" :show-fax="false" />
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import Contact from '@/component/Common/Contact.vue';
    import { ChoiceMakerDto } from '@/model/Patient/ChoiceMaker/ChoiceMakerDto';
    import { formatters } from '@/util/Formatters';
    import PersonIdentification from '@/view/Patient/PatientEdit/PersonIdentification.vue';

    import Address from './Address.vue';
    import ChoiceMakerDropdownSearch from './ChoiceMakerPicker.vue';

    @Component({
        components: { ChoiceMakerDropdownSearch, PersonIdentification, Address, Contact }
    })
    export default class ChoiceMaker extends Vue {
        @Prop({ type: Object })
        private value!: ChoiceMakerDto;

        @Prop()
        private disabled!: boolean;

        @Prop({ default: true })
        private showClearBtn!: boolean;

        private selected: ChoiceMakerDto = new ChoiceMakerDto();

        @Watch('value.firstName')
        private onFirstNameChanged() {
            this.value.firstName = formatters.capitalizeFirstLetter(this.value.firstName);
        }

        @Watch('value.middleName')
        private onMiddleNameChanged() {
            this.value.middleName = formatters.capitalizeFirstLetter(this.value.middleName);
        }

        @Watch('value.lastName')
        private onLastNameChanged() {
            this.value.lastName = formatters.capitalizeFirstLetter(this.value.lastName);
        }

        private populateChoiceMaker() {
            if (this.selected) {
                this.value.firstName = this.selected.firstName;
                this.value.middleName = this.selected.middleName;
                this.value.lastName = this.selected.lastName;
                this.populateChoiceMakerPerson();
                this.populateChoiceMakerAddress();
                this.populateChoiceMakerContact();
            }
        }

        private populateChoiceMakerAddress() {
            this.value.address.cityId = this.selected.address.cityId;
            this.value.address.street = this.selected.address.street;
            this.value.address.streetNumber = this.selected.address.streetNumber;
            this.value.address.neighborhood = this.selected.address.neighborhood;
            this.value.address.block = this.selected.address.block;
            this.value.address.entrance = this.selected.address.entrance;
            this.value.address.floor = this.selected.address.floor;
            this.value.address.apartment = this.selected.address.apartment;
        }

        private populateChoiceMakerPerson() {
            this.value.personIdentification.pidTypeCode = this.selected.personIdentification.pidTypeCode;
            this.value.personIdentification.birthDate = this.selected.personIdentification.birthDate;
            this.value.personIdentification.identifier = this.selected.personIdentification.identifier;
            this.value.personIdentification.genderCode = this.selected.personIdentification.genderCode;
            this.value.personIdentification.certificateDocumentValidTo =
                this.selected.personIdentification.certificateDocumentValidTo;
            this.value.personIdentification.certificateDocumentValidFrom =
                this.selected.personIdentification.certificateDocumentValidFrom;
            this.value.personIdentification.certificateDocumentIssueDate =
                this.selected.personIdentification.certificateDocumentIssueDate;
            this.value.personIdentification.insuranceInstitutionNumber =
                this.selected.personIdentification.insuranceInstitutionNumber;
            this.value.personIdentification.insuranceInstitutionName =
                this.selected.personIdentification.insuranceInstitutionName;
            this.value.personIdentification.certificateDocumentNumber =
                this.selected.personIdentification.certificateDocumentNumber;
            this.value.personIdentification.certificateDocumentTypeId =
                this.selected.personIdentification.certificateDocumentTypeId;
            this.value.personIdentification.countryId = this.selected.personIdentification.countryId;
            this.value.personIdentification.id = this.selected.personIdentification.id;
        }

        private populateChoiceMakerContact() {
            this.value.contact.homePhone = this.selected.contact.homePhone;
            this.value.contact.workPhone = this.selected.contact.workPhone;
            this.value.contact.mobilePhone = this.selected.contact.mobilePhone;
            this.value.contact.email = this.selected.contact.email;
        }

        private clearChoiceMaker() {
            this.selected = new ChoiceMakerDto();
            this.populateChoiceMaker();
        }
    }
</script>
