import { plainToInstance } from 'class-transformer';

import { FundServiceDto } from '@/model/Nomenclature/FundServiceDto';
import { LabTestCategoryDto } from '@/model/Nomenclature/LabTestCategoryDto';
import { httpService } from '@/service/Infrastructure/HttpService';

const fundServiceService = {
    async getFundServicePackages(): Promise<LabTestCategoryDto[]> {
        const response = await httpService.get<LabTestCategoryDto[]>('FundService/GetFundServicePackages');
        return plainToInstance(LabTestCategoryDto, response.data);
    },

    async getFundServices(date: Date | null): Promise<FundServiceDto[]> {
        const response = await httpService.get<FundServiceDto[]>('FundService/GetFundServices', { params: { date } });
        return plainToInstance(FundServiceDto, response.data);
    },

    async getLabTestCategories(): Promise<LabTestCategoryDto[]> {
        const response = await httpService.get<LabTestCategoryDto[]>('FundService/GetLabTestCategories');
        return plainToInstance(LabTestCategoryDto, response.data);
    }
};

export { fundServiceService };
