<template>
    <v-alert
        :type="type"
        :prominent="prominent"
        :value="value"
        outlined
        text
        dense
        :class="(dense ? 'pa-0' : '') + ' multi-line'"
        @input="$emit('input', ...arguments)"
    >
        <slot>TODO: Не е указано съдържанието на alert-а.</slot>
    </v-alert>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class Alert extends Vue {
        @Prop({ default: true })
        private value!: boolean;

        @Prop()
        private type!: string;

        @Prop()
        private prominent!: boolean;

        @Prop()
        private dense!: boolean;
    }
</script>

<style scoped>
    .multi-line {
        white-space: pre-line;
    }
</style>
