export class InjuredPrescriptionBookDto {
    public id: number = 0;
    public patientId: number = 0;
    public number: string = '';
    public workIncapabilityPercent: number | null = null;
    public releaseDate: Date | null = null;
    public certificateNumber: string = '';
    public certificateDate: Date | null = null;
    public telkResultNumber: string = '';
    public telkResultDate: Date | null = null;
    public cancelationDate: Date | null = null;
    public cancelationReason: string = '';
}
