<template>
    <v-card>
        <v-card-title>Номера на електронни болнични листове</v-card-title>
        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn action="Save" @click="updateSickSheetCounter" />
        </v-card-actions>
        <v-card-text>
            <v-form ref="documentForm" v-model="isValid">
                <text-field
                    v-model="sickSheetCounter.startNumber"
                    label="Начален номер"
                    required
                    :rules="[$validator.minLength(12)]"
                />
                <text-field
                    v-model="sickSheetCounter.endNumber"
                    label="Краен номер"
                    required
                    :rules="[$validator.minLength(12)]"
                />
                <v-checkbox v-model="sickSheetCounter.isDeleted" label="Неактивен брояч" />
                <v-checkbox
                    v-model="showPracticeSearch"
                    label="Използване на данни за друга практика в БЛ"
                    @change="onShowPracticeSearch"
                />
                <practice-select
                    v-show="showPracticeSearch"
                    :practice-number="sickSheetCounter.alternativePracticeNumber"
                    @ready="onPracticeReady"
                />
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PracticeSelectDto } from '@/model/Practice/PracticeSelectDto';
    import { SickSheetCounterDto } from '@/model/Setting/Counter/SickSheetCounterDto';
    import { sickSheetCounterService } from '@/service/Setting/Counter/SickSheetCounterService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import PracticeSelect from '@/view/Practice/PracticeSelect.vue';

    @Component({
        components: { PracticeSelect }
    })
    export default class SickSheetCounterEdit extends Vue {
        @Prop()
        private practiceId!: number;

        @Prop()
        private id!: number;

        private isValid: boolean = false;
        private sickSheetCounter: SickSheetCounterDto = new SickSheetCounterDto();
        private showPracticeSearch: boolean = false;
        private sickSheetCounters: SickSheetCounterDto[] = [];
        private hasOverlap: boolean = false;

        private checkForOverlap() {
            const currentCounterStart = this.sickSheetCounter.startNumber.substring(
                1,
                this.sickSheetCounter.startNumber.length - 1
            );
            const currentCounterEnd = this.sickSheetCounter.endNumber.substring(
                1,
                this.sickSheetCounter.endNumber.length - 1
            );
            this.hasOverlap = this.sickSheetCounters.some((counter) => {
                const iterationCounterStart = counter.startNumber.substring(1, counter.startNumber.length - 1);
                const iterationCounterEnd = counter.endNumber.substring(1, counter.endNumber.length - 1);
                return (
                    ((currentCounterStart >= iterationCounterStart && currentCounterStart <= iterationCounterEnd) ||
                        (currentCounterEnd >= iterationCounterStart && currentCounterEnd <= iterationCounterEnd)) &&
                    counter.id !== this.sickSheetCounter.id
                );
            });
        }

        private async mounted() {
            this.$loading.show();
            try {
                this.sickSheetCounters = await sickSheetCounterService.getSickSheetCountersByPractiteId(
                    userContextCache.currentPracticeId ?? 0
                );
                if (this.id) {
                    await this.getSickSheetCounter(this.id);
                } else {
                    this.sickSheetCounter.practiceId = this.practiceId;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private onShowPracticeSearch(value: boolean) {
            if (!value) {
                this.sickSheetCounter.alternativePracticeId = null;
                this.sickSheetCounter.alternativePracticeNumber = null;
            }
        }

        private onPracticeReady(practice: PracticeSelectDto): void {
            if (practice) {
                this.sickSheetCounter.alternativePracticeId = practice.id;
            } else {
                this.sickSheetCounter.alternativePracticeId = null;
            }
        }

        private async getSickSheetCounter(id: number) {
            this.sickSheetCounter = await sickSheetCounterService.getSickSheetCounterById(id);
            if (this.sickSheetCounter.alternativePracticeId) {
                this.showPracticeSearch = true;
            }
        }

        private async updateSickSheetCounter() {
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();
            this.checkForOverlap();
            if (this.hasOverlap) {
                this.$notifier.showError('Внимание', 'Има припокриване на номера с вече съществуващ брояч');
            }
            if (this.isValid && !this.hasOverlap) {
                this.getCounterYear();
                if (!this.id) {
                    await sickSheetCounterService.createSickSheetCounter(this.sickSheetCounter);
                } else {
                    this.sickSheetCounter.id = this.id;
                    await sickSheetCounterService.updateSickSheetCounter(this.sickSheetCounter);
                }
                this.$router.push(this.backRoute);
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private getCounterYear() {
            const startYearIndex = 1;
            const endYearIndex = 5;
            this.sickSheetCounter.year = parseInt(
                this.sickSheetCounter.startNumber.substring(startYearIndex, endYearIndex),
                10
            );
        }

        private get backRoute() {
            return `/Setting/SickSheetCounter/List/${this.sickSheetCounter.practiceId}`;
        }
    }
</script>
