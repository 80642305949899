export class DispensaryRuleDto {
    public id: number = 0;
    public name: string = '';
    public icdCode: string = '';
    public icdCodeAdd: string = '';
    public ageBracketName: string = '';
    public totalDuration: string = '';
    public activities: string = '';
    public totalActivitiesForYear: number = 0;
    public totalActivitiesForYearDescription: string = '';
    public validFrom: Date | null = null;
    public validTo: Date | null = null;
    public dispensaryOrdinanceText: string = '';

    public registerPatientId: number = 0;
    public registerSeqNumber: number = 0;
}
