import { plainToInstance } from 'class-transformer';

import { PracticeFundDto } from '@/model/Practice/PracticeFundDto';
import { TariffDto } from '@/model/Practice/Service/TariffDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const tariffService = {
    async getPracticeTariffs(practiceId: number): Promise<TariffDto[]> {
        const response = await httpService.get<TariffDto[]>('Tariff/GetPracticeTariffs', {
            params: { practiceId }
        });
        return plainToInstance(TariffDto, response.data);
    },

    async getPracticeFunds(practiceId: number): Promise<PracticeFundDto[]> {
        const response = await httpService.get<PracticeFundDto[]>('Tariff/GetPracticeFunds', {
            params: { practiceId }
        });
        return plainToInstance(PracticeFundDto, response.data);
    },

    async saveTariffs(data: TariffDto[]): Promise<void> {
        await httpService.post('Tariff/SaveTariffs', data);
    },

    async deleteTariff(practiceId: number, seqNumber: number): Promise<string> {
        const response = await httpService.delete<string>('Tariff/DeleteTariff', { params: { practiceId, seqNumber } });
        return response.data;
    }
};
