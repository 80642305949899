import { Type } from 'class-transformer';

import { PaymentMethodCode } from '@/enum/Nomenclature/PaymentMethodCode';
import { NhisExtractedReferralDto } from '@/model/Nhis/Referral/NhisExtractedReferralDto';
import { ServiceOfferKey } from '@/model/Practice/Service/ServiceOfferKey';
import { ServiceOfferDto } from '@/model/Reception/Service/ServiceOfferDto';

export class ServiceOrderCreateDto {
    public id: number = 0;

    @Type(() => ServiceOfferKey)
    public serviceOfferKey: ServiceOfferKey = new ServiceOfferKey();

    public patientPrice: number | null = null;
    public isPaid: boolean = false;
    public incomingReferral: NhisExtractedReferralDto | null = null;
    public paymentMethodCode: string = PaymentMethodCode.Cash;
    public paymentDate: Date | null = null;

    // Допълнителни client-side полета.
    @Type(() => ServiceOfferDto)
    public serviceOffersForDropdown: ServiceOfferDto[] = [];
}
