import { Type } from 'class-transformer';

import { DoctorDeputyKey } from '@/model/Employee/Deputy/DoctorDeputyKey';
import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
import { EmployeeUserDto } from '@/model/Employee/EmployeeUserDto';

import { DoctorDeputyScheduleDto } from './Schedule/DoctorDeputyScheduleDto';

export class DoctorDeputyEditDto {
    @Type(() => DoctorDeputyKey)
    public doctorDeputyKey: DoctorDeputyKey = new DoctorDeputyKey();

    @Type(() => EmployeeTitleDto)
    public primaryDoctorEmployee: EmployeeTitleDto = new EmployeeTitleDto();

    public deputyDoctorIsEmployee: boolean = false;
    public deputyDoctorName: string = '';
    public deputyDoctorUin: string = '';
    public deputyUserId: number | null = null;
    public deputyUserIsPending: boolean = false;
    public visitCountAsDeputy: number = 0;

    @Type(() => DoctorDeputyScheduleDto)
    public schedules: DoctorDeputyScheduleDto[] = [];

    @Type(() => EmployeeUserDto)
    public usersRelatedToDoctor: EmployeeUserDto[] = [];

    public invitedEmails: string[] = [];
}
