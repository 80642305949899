import { DoctorDeputySelfRegisterCommand } from '@/model/Employee/Deputy/DoctorDeputySelfRegisterCommand';
import { httpService } from '@/service/Infrastructure/HttpService';

export const registerDoctorDeputyService = {
    async createDoctorDeputy(dto: DoctorDeputySelfRegisterCommand): Promise<string> {
        return (await httpService.post<string>(`/RegisterDoctorDeputy/CreateDoctorDeputy`, dto)).data;
    },
    async checkExistingDoctorDeputy(dto: DoctorDeputySelfRegisterCommand): Promise<string> {
        return (await httpService.post<string>(`/RegisterDoctorDeputy/CheckExistingDoctorDeputy`, dto)).data;
    }
};
