import { plainToInstance } from 'class-transformer';

import { VatChargeTypeDto } from '@/model/Nomenclature/VatChargeTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const vatChargeTypeService = {
    async getVatChargeTypes(): Promise<VatChargeTypeDto[]> {
        const response = await httpService.get<VatChargeTypeDto[]>('/VatChargeType/GetVatChargeTypes');
        return plainToInstance(VatChargeTypeDto, response?.data);
    }
};
