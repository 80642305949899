<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">Раздел VІ. Дейност на психиатричните структури</h3></v-col>
            <v-col cols="12"><h3 class="mb-2">2. Болни под наблюдение</h3></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><DataTableComponent v-model="value.psychiatricPatients" :headers="headers" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Report365PsychiatricPatientDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricPatientDto';
    import { Report365PsychiatricPatientRowDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricPatientRowDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class PsychiatricPatient extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Report365PsychiatricPatientDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: '', value: 'name' },
            { text: 'Шифър', value: 'code' },
            { text: 'Брой в нач. на год.', value: 'totalStartYear' },
            { text: 'Брой през годината', value: 'totalDuringYear' },
            { text: 'Деца до 17г.', value: 'totalChildren' },
            { text: 'За първи път в живота', value: 'totalFirstTime' },
            { text: 'Смяна на диагноза', value: 'totalDiagnoseChanged' },
            { text: 'Снети', value: 'totalDeregistered' },
            { text: 'Умрели', value: 'totalDied' },
            { text: 'Самоубили', value: 'totalKilled' },
            { text: 'Приведени в друга група', value: 'totalOtherGroup' },
            { text: 'В края на год.', value: 'totalEndYear' },
            { text: 'Обществено опасни', value: 'totalDangerous' }
        ];

        @Watch('value.psychiatricPatients', { deep: true })
        private onValueChanged() {
            const timeout = 3000;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => this.calculateSectionRow(), timeout);
        }

        private setZero(row: Report365PsychiatricPatientRowDto) {
            row.totalStartYear = 0;
            row.totalDuringYear = 0;
            row.totalChildren = 0;
            row.totalFirstTime = 0;
            row.totalDiagnoseChanged = 0;
            row.totalDeregistered = 0;
            row.totalDied = 0;
            row.totalKilled = 0;
            row.totalOtherGroup = 0;
            row.totalEndYear = 0;
            row.totalDangerous = 0;
        }

        private calculateYearTotals(
            rowIdx: Report365PsychiatricPatientRowDto,
            rowKidx: Report365PsychiatricPatientRowDto
        ) {
            rowIdx.totalStartYear = (rowIdx.totalStartYear ?? 0) + (rowKidx.totalStartYear ?? 0);
            rowIdx.totalDuringYear = (rowIdx.totalDuringYear ?? 0) + (rowKidx.totalDuringYear ?? 0);
            rowIdx.totalDeregistered = (rowIdx.totalDeregistered ?? 0) + (rowKidx.totalDeregistered ?? 0);
            rowIdx.totalEndYear = (rowIdx.totalEndYear ?? 0) + (rowKidx.totalEndYear ?? 0);
            rowIdx.totalDangerous = (rowIdx.totalDangerous ?? 0) + (rowKidx.totalDangerous ?? 0);
        }

        private calculateSectionRow() {
            for (let idx = 0; idx < this.value.psychiatricPatients.length; idx++) {
                if (this.value.psychiatricPatients[idx].isSection) {
                    this.setZero(this.value.psychiatricPatients[idx]);
                    for (let kidx = 0; kidx < this.value.psychiatricPatients.length; kidx++) {
                        if (
                            this.value.psychiatricPatients[kidx].parentCode === this.value.psychiatricPatients[idx].code
                        ) {
                            this.value.psychiatricPatients[idx].totalChildren =
                                (this.value.psychiatricPatients[idx].totalChildren ?? 0) +
                                (this.value.psychiatricPatients[kidx].totalChildren ?? 0);
                            this.value.psychiatricPatients[idx].totalFirstTime =
                                (this.value.psychiatricPatients[idx].totalFirstTime ?? 0) +
                                (this.value.psychiatricPatients[kidx].totalFirstTime ?? 0);
                            this.value.psychiatricPatients[idx].totalDiagnoseChanged =
                                (this.value.psychiatricPatients[idx].totalDiagnoseChanged ?? 0) +
                                (this.value.psychiatricPatients[kidx].totalDiagnoseChanged ?? 0);
                            this.value.psychiatricPatients[idx].totalDied =
                                (this.value.psychiatricPatients[idx].totalDied ?? 0) +
                                (this.value.psychiatricPatients[kidx].totalDied ?? 0);
                            this.value.psychiatricPatients[idx].totalKilled =
                                (this.value.psychiatricPatients[idx].totalKilled ?? 0) +
                                (this.value.psychiatricPatients[kidx].totalKilled ?? 0);
                            this.value.psychiatricPatients[idx].totalOtherGroup =
                                (this.value.psychiatricPatients[idx].totalOtherGroup ?? 0) +
                                (this.value.psychiatricPatients[kidx].totalOtherGroup ?? 0);
                            this.calculateYearTotals(
                                this.value.psychiatricPatients[idx],
                                this.value.psychiatricPatients[kidx]
                            );
                        }
                    }
                }
            }
        }
    }
</script>
