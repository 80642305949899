import { plainToInstance } from 'class-transformer';

import { PatientServicedByReportDto } from '@/model/Query/Patient/PatientServicedBy/PatientServicedByReportDto';
import { PatientServicedByCommand } from '@/model/Query/Patient/PatientServicedByCommand';
import { ExamClaimReportDto } from '@/model/Report/Pis/GpExamClaim/ExamClaimReportDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientServicedByService = {
    async generatePatientServicedByReport(filter: PatientServicedByCommand): Promise<PatientServicedByReportDto> {
        const response = await httpService.post<PatientServicedByReportDto>(
            `/PatientListReport/GeneratePatientServicedByReport`,
            filter
        );
        return plainToInstance(ExamClaimReportDto, response.data);
    },

    exportExcelPatientServicedByReport(filter: PatientServicedByCommand): Promise<void> {
        return httpService.download(`/PatientListReport/ExportExcelPatientServicedByReport`, { params: filter });
    }
};
