<template>
    <div>
        <v-card-title>Данни от прегледа</v-card-title>
        <v-row>
            <v-col cols="6" md="3">
                <text-field v-model="value.height" type="number" label="Ръст, см" />
            </v-col>
            <v-col cols="6" md="3">
                <text-field v-model="value.weight" type="number" label="Тегло, кг" />
            </v-col>
            <v-col cols="4" md="2">
                <h4>Артериално налягане, mm/Hg</h4>
            </v-col>
            <v-col cols="4" md="2">
                <text-field v-model="value.systolic" type="number" label="Систолично" />
            </v-col>
            <v-col cols="4" md="2">
                <text-field v-model="value.diastolic" type="number" label="Диастолично" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="3">
                <v-row>
                    <v-col cols="12">
                        <v-checkbox
                            v-model="value.musculoskeletalNormal"
                            label="Опорно-двигателна система нормален статус"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="value.pathologicDeviations" label="Патологични отклонения:" dense />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="6" md="3">
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="value.dSp" type="number" label="D. sp." />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.dTr" type="number" label="D. tr." />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.cExt" type="number" label="C. ext." />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.cDiag" type="number" label="C. diag." />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.cVera" type="number" label="C. vera" />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="6" md="3">
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="value.externalGenitalia" label="Външни полови органи:" />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.vagina" label="Влагалище:" />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.exUtero" label="ex. utero:" />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.pvcu" label="PVCU:" />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="6" md="3">
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="value.oecc" label="OECC:" />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.cc" label="CC:" />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.uterus" label="uterus:" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6">
                <text-field v-model="value.otherDeviations" label="Други отклонения от нормата" />
            </v-col>
            <v-col cols="4" md="2">
                <text-field v-model="value.abo" label="Кръвна група АВО" />
            </v-col>
            <v-col cols="4" md="2">
                <text-field v-model="value.rh" label="Rh" />
            </v-col>
            <v-col cols="4" md="2">
                <v-checkbox v-model="value.obstetricRisk" label="Акушерски риск"></v-checkbox>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ExamDataDto } from '@/model/Register/Pregnancy/PregnancyCard/ExamDataDto';

    @Component
    export default class ExamData extends Vue {
        @Prop()
        value!: ExamDataDto;
    }
</script>
