<template>
    <v-autocomplete
        :value="value"
        :items="drugs"
        :loading="loadingDrugs"
        hide-selected
        item-text="name"
        :label="label"
        no-data-text="Няма лекарствени продукти за посоченото МКБ"
        filled
        dense
        clearable
        append-icon="mdi-magnify"
        return-object
        :rules="required ? requiredField : []"
        :class="required ? 'required' : ''"
        @input="$emit('input', ...arguments)"
        @click:clear="$emit('clear', ...arguments)"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { DrugProtocolDrug } from '@/model/Nomenclature/DrugProtocolDrug';
    import { NhifDrugSearchDto } from '@/model/Nomenclature/NhifDrugSearchDto';
    import { nhifDrugService } from '@/service/Nomenclature/NhifDrugService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';

    @Component
    export default class ProtocolDrugPicker extends Vue {
        @Prop()
        private value!: DrugProtocolDrug;

        @Prop({ required: true })
        private label!: string;

        @Prop({ type: String })
        private icdCode!: string;

        @Prop()
        private required!: boolean;

        @Prop()
        private position!: number;

        private requiredField: ((val: DrugProtocolDrug, required: boolean) => void)[] | boolean[] | string[] = [
            (val: DrugProtocolDrug, required: boolean) =>
                required || (val && val.name !== '') || `Попълнете задължителните полета`
        ];

        private drugs: DrugProtocolDrug[] = [];
        private loadingDrugs: boolean = false;

        @Watch('icdCode', { immediate: true })
        private async loadDrugs(value: string) {
            this.drugs = await nhifDrugService.searchNhifProtocolDrugs(
                new NhifDrugSearchDto(value, '', '', currentVisitCache.value.startDateTime)
            );
        }
    }
</script>

<style>
    .v-autocomplete {
        white-space: pre-wrap;
        display: flex;
        height: auto;
    }

    .v-chip__content {
        white-space: pre-wrap;
        display: flex;
        height: auto;
    }

    .v-autocomplete__content .v-list__tile {
        height: auto;
        display: flex;
    }

    .v-input__slot {
        height: auto;
        display: flex;
    }
</style>
