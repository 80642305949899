import { plainToInstance } from 'class-transformer';

import { NhisVaccineDoseDto } from '@/model/Nomenclature/NhisVaccineDoseDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhisVaccineDoseService = {
    async getVaccineDoses(nhisVaccineCode: string): Promise<NhisVaccineDoseDto[]> {
        const response = await httpService.get<NhisVaccineDoseDto[]>('NhisVaccineDose/GetVaccineDoses', {
            params: { nhisVaccineCode }
        });
        return plainToInstance(NhisVaccineDoseDto, response.data);
    }
};
