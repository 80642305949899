import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { MedicalProcedureDto } from '@/model/Nomenclature/MedicalProcedureDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const medicalProcedureService = {
    async getMedicalProcedureByName(name: string): Promise<MedicalProcedureDto> {
        const response = await httpService.get<MedicalProcedureDto>('/MedicalProcedure/GetMedicalProcedureByName', {
            params: { name }
        });
        return plainToInstance(MedicalProcedureDto, response.data);
    },

    getMedicalProcedures(): Promise<AxiosResponse<MedicalProcedureDto[]>> {
        return httpService.get('/MedicalProcedure/GetMedicalProcedures');
    },

    getSpecializedMedicalProceduresBySpecialty(
        specialtyCode: SpecialtyCode
    ): Promise<AxiosResponse<MedicalProcedureDto[]>> {
        return httpService.get('/MedicalProcedure/GetSpecializedMedicalProceduresBySpecialty', {
            params: { specialtyCode }
        });
    },

    getMedicalProceduresBySpecialty(specialtyCode: SpecialtyCode): Promise<AxiosResponse<MedicalProcedureDto[]>> {
        return httpService.get('/MedicalProcedure/GetMedicalProceduresBySpecialty/', {
            params: { specialtyCode }
        });
    }
};
