import { Type } from 'class-transformer';

import { FeeDisengagementIcdDto } from '@/model/Patient/FeeDisengagement/FeeDisengagementIcdDto';
import { dateUtil } from '@/util/DateUtil';

export class FeeDisengagementDto {
    public id: number = 0;
    public patientId: number = 0;
    public startDate: Date | null = null;
    public endDate: Date | string | null = null;
    public isLifeTime: boolean = true;
    public disengagementReasonId: number = 0;
    public disengagementReasonName: string = '';
    public medicalResolutionNumber: string = '';
    public medicalResolutionDate: Date | null = null;

    public get medicalResolutionText(): string {
        return this.medicalResolutionDate
            ? `${this.medicalResolutionNumber} / ${dateUtil.formatBgDate(this.medicalResolutionDate)}`
            : this.medicalResolutionNumber;
    }

    public get endDateString(): string {
        return this.endDate ? dateUtil.formatBgDate(this.endDate) : 'ПОЖИЗНЕНО';
    }

    @Type(() => FeeDisengagementIcdDto)
    public feeDisengagementIcd: FeeDisengagementIcdDto | null = null;
}
