<template>
    <v-card>
        <v-card-title>Договор с НЗОК</v-card-title>
        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn action="Save" @click="createUpdateContract" />
        </v-card-actions>
        <v-card-text>
            <v-form ref="documentForm" v-model="isValid">
                <v-row no-gutters>
                    <v-col cols="12" md="4">
                        <text-field
                            v-model="practiceNhifContract.contractNumber"
                            label="Номер на договора:"
                            required
                            :rules="[$validator.digitsOnly]"
                        />
                    </v-col>
                    <v-col xs="12" md="8" cols="12">
                        <date-picker
                            v-model="practiceNhifContract.contractDate"
                            label="Дата на договора:"
                            class="pt-3 pl-3"
                        ></date-picker>
                    </v-col>
                    <v-col xs="12" md="4" cols="12">
                        <v-select
                            v-model="practiceNhifContract.nhifContractTypeCode"
                            item-text="name"
                            item-value="code"
                            :items="nhifContractTypeList"
                            label="Тип на договор:"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                        <text-field
                            v-model="practiceNhifContract.practiceLegalName"
                            label="Наименование по договор:"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                        <text-field
                            v-model="practiceNhifContract.addressByContract"
                            label="Адрес по договор:"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                        <text-field
                            v-model="practiceNhifContract.addressByActivity"
                            label="Адрес по място на изпълнение на дейността:"
                            required
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhifContractTypeDto } from '@/model/Nomenclature/NhifContractTypeDto';
    import { PracticeNhifContractDto } from '@/model/Practice/PracticeNhifContractDto';
    import { nhifContractTypeService } from '@/service/Nomenclature/NhifContractTypeService';
    import { practiceNhifContractService } from '@/service/Practice/PracticeNhifContractService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class PracticeNhifContractEdit extends Vue {
        @Prop()
        private practiceId!: number;

        @Prop()
        private id!: number;

        private isValid: boolean = true;
        private practiceNhifContract: PracticeNhifContractDto = new PracticeNhifContractDto();
        private nhifContractTypeList: NhifContractTypeDto[] = [];

        private async mounted() {
            await this.getNhifContractTypes();

            if (this.id) {
                await this.getPracticeNhifContract(this.id);
            } else if (this.practiceId) {
                this.practiceNhifContract.practiceId = this.practiceId;
            } else {
                throw Error('Не е подадено, нито id, нито practiceId. Трябва да се подаде точно едно от двете.');
            }
        }

        private async getNhifContractTypes() {
            const response = await nhifContractTypeService.getNhifContractTypes();
            this.nhifContractTypeList = response.data;
        }

        private async getPracticeNhifContract(id: number) {
            this.practiceNhifContract = await practiceNhifContractService.getPracticeNhifContract(id);
            // Ако SPA-то е зредено директно на това view, превключва към най-адекватния UserContext.
            userContextCache.trySwitchToPractice(this.practiceNhifContract.practiceId);
        }

        private async createUpdateContract() {
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();
            if (this.isValid) {
                this.practiceNhifContract.contractNumber = String(this.practiceNhifContract.contractNumber);
                if (!this.id) {
                    await practiceNhifContractService.createPracticeNhifContract(this.practiceNhifContract);
                } else {
                    this.practiceNhifContract.id = this.id;
                    await practiceNhifContractService.updatePracticeNhifContract(this.practiceNhifContract);
                }

                this.$router.push(this.backRoute);
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private get backRoute() {
            return `/Practice/NhifContract/List/${this.practiceNhifContract.practiceId}`;
        }
    }
</script>
