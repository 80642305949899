import { BloodTestRowDto } from '@/model/Exam/Referral/PregnancyReferral/BloodTestRowDto';
import { LastTestRowDto } from '@/model/Exam/Referral/PregnancyReferral/LastTestRowDto';
import { PregnancyConditionRowDto } from '@/model/Exam/Referral/PregnancyReferral/PregnancyConditionRowDto';

export class CurrentPregnancyDto {
    public diseaseStated: string | null = null;
    public treatment: string | null = null;
    public specializedTests: string | null = null;
    public pregnancyCondition: PregnancyConditionRowDto[] = [];
    public skeleton: string | null = null;
    public breasts: string | null = null;
    public nipples: string | null = null;
    public psychoprofile: string | null = null;
    public fruitPosition: string | null = null;
    public presentation: string | null = null;
    public childHearthTone: string | null = null;
    public notes: string | null = null;
    public bloodTests: BloodTestRowDto[] = [];
    public antibodies: string | null = null;
    public birthPrognosis: string | null = null;
    public additionalTests: string | null = null;
    public lastTests: string | null = null;
    public lastTestsResult: LastTestRowDto[] = [];
    public otherNotes: string | null = null;
    public date: Date | null = null;
}
