import { plainToInstance } from 'class-transformer';

import { DrugProtocolDrug } from '@/model/Nomenclature/DrugProtocolDrug';
import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
import { NhifDrugSearchDto } from '@/model/Nomenclature/NhifDrugSearchDto';
import { IDropdownSearchService } from '@/service/Dropdown/IDropdownSearchService';
import { httpService } from '@/service/Infrastructure/HttpService';

const nhifDrugService = {
    async searchNhifDrugs(filter: NhifDrugSearchDto): Promise<NhifDrugDto[]> {
        const response = await httpService.post<NhifDrugDto[]>('/NhifDrug/SearchNhifDrugs/', filter);

        return plainToInstance(NhifDrugDto, response.data);
    },

    async searchNhifProtocolDrugs(filter: NhifDrugSearchDto): Promise<DrugProtocolDrug[]> {
        const response = await httpService.post<NhifDrugDto[]>('/NhifDrug/SearchNhifProtocolDrugs/', filter);

        return plainToInstance(DrugProtocolDrug, response.data);
    }
};
const nhifDrugSearchService: IDropdownSearchService<NhifDrugDto> = {
    // Не се използва
    getItemsById(): Promise<NhifDrugDto[]> {
        return Promise.resolve([]);
    },

    async searchItems(filters: []): Promise<NhifDrugDto[]> {
        const response = await httpService.get<NhifDrugDto[]>('/NhifDrug/SearchNhifDrugsByNameOrCode', {
            params: { filter: filters.at(0) }
        });
        return plainToInstance(NhifDrugDto, response.data);
    }
};

export { nhifDrugSearchService, nhifDrugService };
