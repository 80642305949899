import { Route, RouteConfig } from 'vue-router';

const queryChildRoutes: RouteConfig[] = [
    //Справка неизпратени документи НЗИС
    {
        path: 'UnpostedForms/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Nhis/UnpostedForms.vue')
        }
    },
    {
        path: 'ImmunizationReport/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Patient/ImmunizationReport.vue')
        }
    },
    {
        path: 'ExamNotPrinted/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/ExamNotPrinted.vue')
        }
    },
    {
        path: 'ExamNotSigned/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/ExamNotSigned.vue')
        }
    },
    {
        path: 'CanceledExams/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/CanceledExam.vue')
        }
    },
    {
        path: 'UninsuredPatientExams/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/UninsuredPatientExam.vue')
        }
    },
    {
        path: 'SubjectToImmunization/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Patient/SubjectToImmunization.vue')
        }
    },
    {
        path: 'ExamsWithoutFiscalReceipt/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/ExamWithoutFiscalReceipt.vue')
        }
    },
    {
        path: 'SubjectToDispensaryExam/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/SubjectToDispensaryExam.vue')
        }
    },
    {
        path: 'SubjectToPrevention/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/SubjectToPrevention.vue')
        }
    },
    {
        path: 'SubjectToPreventiveExam/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/SubjectToPreventiveExam.vue')
        }
    },
    {
        path: 'IncomingReferralExamReport/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/IncomingReferralExamReport.vue')
        }
    },
    {
        path: 'PreventiveExamNotRegisterIncluded/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/PreventiveExamNotRegisterIncluded.vue')
        }
    },
    {
        path: 'ServiceOrderDetailedReport/:practiceId',
        props: {
            query: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            query: () => import('@/view/Query/Reception/ServiceOrderDetailedReport.vue')
        }
    },
    {
        path: 'ServiceOrderSummarizedReport/:practiceId',
        props: {
            query: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            query: () => import('@/view/Query/Reception/ServiceOrderSummarizedReport.vue')
        }
    },
    {
        path: 'TimeBetweenExams/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/TimeBetweenExams.vue')
        }
    },
    {
        path: 'ExamClaimReportExtended/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/ExamClaimReportExtended.vue')
        }
    },
    {
        path: 'PatientServicedByReport/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Patient/PatientServicedByReport.vue')
        }
    },
    {
        path: 'NhisGroupUpload',
        components: {
            query: () => import('@/view/Query/Nhis/NhisGroupUpload.vue')
        }
    },
    {
        path: 'InsuranceStatus/:practiceId/:employeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Patient/InsuranceStatusGroupCheck.vue')
        }
    },
    {
        path: 'PensionStatus/:practiceId/:employeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Patient/PensionStatusGroupCheck.vue')
        }
    },
    {
        path: 'SickSheetUpload/:practiceId/:employeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/SickSheetGroupUpload.vue')
        }
    },
    {
        path: 'Referral4AmountsReport/:practiceId/:employeeSeqNumber',
        props: {
            query: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            query: () => import('@/view/Query/Exam/Referral4AmountsReport.vue')
        }
    }
];
export const queryRoutes: RouteConfig = {
    path: '/Query',
    component: () => import('@/view/Query/QueryMenu.vue'),
    children: queryChildRoutes
};
