<template>
    <exam-layout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        :hide-toolbar="!isFemale"
        title="Искане за цитонамазка"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <alert :value="!isFemale" type="warning">Тази бланка не може да бъде издадена на мъж.</alert>
        <v-card :disabled="!isFemale">
            <main-title>Искане за цитонамазка</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6"><PatientInfo /></v-col>
                    <v-col cols="12" md="6"><DoctorInfo /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3">
                        <date-picker v-model="document.issueDate" required label="Дата" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <text-field v-model="document.code" label="Код" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <text-field v-model="document.ourCode" label="Наш код" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <text-field v-model="document.yourCode" label="Ваш код" />
                    </v-col>
                </v-row>
                <v-row class="flex-row align-baseline">
                    <v-col cols="6" md="3">
                        <text-field v-model.number="document.preparationCount" type="number" label="Брой препарати" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <date-picker v-model="document.lastRegularMenstruation" label="ПРМ" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <date-picker v-model="document.menopauseFrom" label="В менопауза от" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-radio-group v-model="document.isRiskRaised" row dense label="Риск">
                            <v-radio label="Обичаен" :value="false"></v-radio>
                            <v-radio label="Повишен" :value="true"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea v-model="document.note" label="Други данни" dense />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </exam-layout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { GenderCode } from '@/enum/Nomenclature/GenderCode';
    import { RequestForCytosmearDto } from '@/model/Exam/RequestForCytosmear/RequestForCytosmearDto';
    import { requestForCytosmearService } from '@/service/Exam/RequestForCytosmearService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: { UnsavedChanges, DoctorInfo, PatientInfo, ExamLayout },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class RequestForCytosmear extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        documentId!: number;

        @Prop()
        examId!: number;

        private document: RequestForCytosmearDto = new RequestForCytosmearDto();
        private initialValue: RequestForCytosmearDto = new RequestForCytosmearDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private get isFemale() {
            return currentPatientCache?.value?.personIdentification?.genderCode === GenderCode.Female;
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (!this.documentId) {
                    this.document.examId = this.examId;
                    const response = await requestForCytosmearService.createRequestForCytosmear(this.document);
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, 'RequestForCytosmear', 'Цитонамазка');
                    this.$router.push(`/Exam/RequestForCytosmear/Edit/${documentId}`);
                } else {
                    await requestForCytosmearService.updateRequestForCytosmear(this.document);
                    eventBus.$emit('update-referral-event', 'Цитонамазка');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                await requestForCytosmearService.deleteRequestForCytosmear(this.documentId);
                this.copyObject();
                this.$router.push(`/Exam/RequestForCytosmear/Create/${this.document.examId}`);
                eventBus.$emit('delete-referral-event', this.documentId, 'RequestForCytosmear', 'Цитонамазка');
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.RequestForCytosmear/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.RequestForCytosmear';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                if (this.documentId) {
                    this.document = await requestForCytosmearService.getRequestForCytosmear(this.documentId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }
    }
</script>
