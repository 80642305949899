import { AxiosResponse } from 'axios';

import { MedicalNoteDto } from '@/model/Exam/Certificate/MedicalNoteDto';
import { MedicalNoteViewDto } from '@/model/Exam/Certificate/MedicalNoteViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const medicalNoteService = {
    getMedicalNoteInfoByExamId(examId: number): Promise<AxiosResponse<MedicalNoteDto>> {
        return httpService.get(`/MedicalNote/GetMedicalNoteInfoByExamId/${examId}`);
    },

    getMedicalNote(id: number): Promise<AxiosResponse<MedicalNoteDto>> {
        return httpService.get(`/MedicalNote/GetMedicalNote/${id}`);
    },

    createMedicalNote(data: MedicalNoteDto): Promise<AxiosResponse<MedicalNoteDto>> {
        return httpService.post('/MedicalNote/CreateMedicalNote', data);
    },

    updateMedicalNote(data: MedicalNoteDto): Promise<AxiosResponse<MedicalNoteDto>> {
        return httpService.put('/MedicalNote/UpdateMedicalNote/', data);
    },

    deleteMedicalNote(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/MedicalNote/DeleteMedicalNote/${id}`);
    },

    getMedicalNotesByExamId(examId: number): Promise<AxiosResponse<MedicalNoteViewDto[]>> {
        return httpService.get<MedicalNoteViewDto[]>(`/MedicalNote/GetMedicalNoteViewsByExam`, {
            params: { examId }
        });
    }
};
