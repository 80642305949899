<template>
    <div>
        <v-tooltip v-if="isActive" bottom>
            <template #activator="{ on, attrs }">
                <btn
                    action="Edit"
                    :disabled="disabled"
                    class="ml-md-3"
                    v-bind="attrs"
                    v-on="on"
                    @click="copySickSheet()"
                >
                    Редактиране на изпратен БЛ
                </btn>
            </template>
            <span>Подготвя БЛ за редaктиране и записване на нов, анулиране на първоначалния и изпращане на двата</span>
        </v-tooltip>
        <v-tooltip v-if="isActive" bottom>
            <template #activator="{ on, attrs }">
                <btn
                    action="Delete"
                    :disabled="disabled"
                    class="ml-md-3"
                    v-bind="attrs"
                    v-on="on"
                    @click="canceledSickSheet()"
                >
                    Анулиране на изпратен БЛ
                </btn>
            </template>
            <span>Подготвя БЛ за анулиране и изпращане</span>
        </v-tooltip>

        <btn
            v-if="!isActive && !isCanceled && !value.canceledSickSheetId"
            action="Success"
            class="ml-md-3"
            outlined
            @click="toggleCanceling()"
        >
            Отказ от анулиране
        </btn>
        <btn v-if="canPrint" action="Print" class="ml-md-3 mr-md-3" @click="print()"> Печат </btn>
        <btn v-if="linkCanceledSickSheet !== '#'" action="Next" :to="linkCanceledSickSheet">
            Навигиране към връзката на БЛ
        </btn>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { SickSheetStatusCode } from '@/enum/Exam/SickSheetStatusCode';
    import { SickSheetDto } from '@/model/Exam/SickSheet/SickSheetDto';
    import { sickSheetService } from '@/service/Exam/SickSheetService';

    @Component
    export default class SickSheetCustomHeader extends Vue {
        @Prop()
        private disabled!: boolean;

        @Prop()
        private canPrint!: boolean;

        @Prop()
        private value!: SickSheetDto;

        private get isActive() {
            return this.value.statusCodeOfSend === SickSheetStatusCode.Active;
        }

        private get isCanceled() {
            return this.value.statusCodeOfSend === SickSheetStatusCode.Canceled;
        }

        private async toggleCanceling() {
            await this.updateToActive();
            this.value.statusCodeOfSend = SickSheetStatusCode.Active;
        }

        private copySickSheet() {
            this.$emit('copy');
        }

        private print() {
            this.$emit('printed');
        }

        private async canceledSickSheet() {
            await this.updateToActiveToBeCanceled();
            this.$emit('canceled');
        }

        private async updateToActiveToBeCanceled() {
            if (this.value.id) {
                await sickSheetService.changeSickSheetToActualDeleteById(this.value.id);
            }
        }

        private async updateToActive() {
            if (this.value.id) {
                await sickSheetService.changeSickSheetToActiveById(this.value.id);
            }
        }

        private get linkCanceledSickSheet(): string {
            if (this.value && this.value.canceledSickSheetId) {
                return `/Exam/SickSheet/Edit/${this.value.canceledSickSheetId}`;
            } else if (this.value && this.value.canceledContectionId) {
                return `/Exam/SickSheet/Edit/${this.value.canceledContectionId}`;
            }
            return '#';
        }
    }
</script>
