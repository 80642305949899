import { Type } from 'class-transformer';

import { DoctorDeputyKey } from '@/model/Employee/Deputy/DoctorDeputyKey';
import { EmployeeUserDto } from '@/model/Employee/EmployeeUserDto';
import { formatters } from '@/util/Formatters';

export class DoctorDeputyWithLastScheduleDto {
    @Type(() => DoctorDeputyKey)
    public doctorDeputyKey: DoctorDeputyKey = new DoctorDeputyKey();

    public deputyDoctorName: string = '';
    public deputyDoctorUin: string = '';

    @Type(() => EmployeeUserDto)
    public deputyUserLock: EmployeeUserDto = new EmployeeUserDto();

    public lastValidFrom: string | null = null;
    public lastValidTo: string | null = null;
    public lastStatusName: string | null = null;

    public get lastInterval() {
        return `${formatters.date(this.lastValidFrom)} - ${formatters.date(this.lastValidTo)}`;
    }
}
