export class ServiceTitleDto {
    public seqNumber: number | null = null;
    public name: string = '';
    public shortCode: string = '';
    public validFrom: Date | null = null;
    public validTo: Date | null = null;
    public serviceCategorySeqNumber: number | null = null;
    public serviceCategoryName: string = '';
    public isActive: boolean = false;
}
