import { NhisResponseWarningDto } from '@/model/Nhis/NhisResponseWarningDto';
import { NhisXmlRequestDto } from '@/model/Nhis/NhisXmlRequestDto';
import { NhisReferralSubmitCommand } from '@/model/Nhis/Referral/NhisReferralSubmitCommand';
import { SubmitCommand } from '@/model/Nhis/SubmitCommand';
import { SubmitCommandDev } from '@/model/Nhis/SubmitCommandDev';
import { SubmitCommandWithId } from '@/model/Nhis/SubmitCommandWithId';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { accessTokenCache, nhisKey } from '@/store/NhisNhifAccessTokenCache';
import { userContextCache } from '@/store/User/UserContextCache';

export const nhisService = {
    async postXmlToNhis<TResponse>(action: string, xmlString: string): Promise<TResponse> {
        const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
        const response = await httpService.post<TResponse>(
            `${nhisKey}/${action}`,
            new SubmitCommand(xmlString, tokenInfo?.accessToken ?? '')
        );
        return response.data;
    },

    async postXmlToNhisWithId<TResponse>(action: string, xmlString: string, entityId: number): Promise<TResponse> {
        const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
        const response = await httpService.post<TResponse>(
            `${nhisKey}/${action}`,
            new SubmitCommandWithId(xmlString, tokenInfo?.accessToken ?? '', entityId)
        );
        return response.data;
    },

    async postReferralToNhis<TResponse>(
        action: string,
        xmlString: string,
        entityId: number,
        formType: string
    ): Promise<TResponse> {
        const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
        const response = await httpService.post<TResponse>(
            `${nhisKey}/${action}`,
            new NhisReferralSubmitCommand(xmlString, tokenInfo?.accessToken ?? '', entityId, formType)
        );
        return response.data;
    },

    async postDevXmlToNhis<TResponse>(
        action: string,
        xmlString: string,
        path: string,
        authorizationNeeded: boolean
    ): Promise<TResponse> {
        const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
        const response = await httpService.post<TResponse>(
            `${nhisKey}/${action}`,
            new SubmitCommandDev(xmlString, tokenInfo?.accessToken ?? '', path, authorizationNeeded)
        );
        return response.data;
    },

    // Warnings
    showWarnings(warnings: NhisResponseWarningDto[]) {
        for (const warning of warnings) {
            notifierService.showWarning(
                `Код предупреждение: ${warning.code}`,
                `Описание: ${warning.description}; От: ${warning.source}`
            );
        }
    },

    // RequestDto
    requestData(entityId: number, formType: string | null): NhisXmlRequestDto {
        const request = new NhisXmlRequestDto(
            entityId,
            userContextCache.currentDoctorEmployeeId ?? 0,
            userContextCache.current?.doctorUin ?? '',
            userContextCache.current?.deputyDoctorUin ?? null
        );

        request.formType = formType;
        return request;
    }
};
