import { plainToInstance } from 'class-transformer';

import { DoctorDropdownDto } from '@/model/Doctor/DoctorDropdownDto';
import { IDropdownSearchService } from '@/service/Dropdown/IDropdownSearchService';
import { httpService } from '@/service/Infrastructure/HttpService';

export const doctorSearchService: IDropdownSearchService<DoctorDropdownDto> = {
    async getItemsById(doctorId: number): Promise<DoctorDropdownDto[]> {
        const response = await httpService.get<DoctorDropdownDto[]>(`/DoctorSearch/GetDoctor/${doctorId}`);
        return plainToInstance(DoctorDropdownDto, response.data);
    },

    async searchItems(filters: []): Promise<DoctorDropdownDto[]> {
        const response = await httpService.get<DoctorDropdownDto[]>('/DoctorSearch/SearchDoctors', {
            params: { filter: filters.at(0) }
        });
        return plainToInstance(DoctorDropdownDto, response.data);
    }
};
