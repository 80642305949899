import { Route, RouteConfig } from 'vue-router';

export const examInformedConsentRoutes: RouteConfig[] = [
    {
        path: 'InformedConsentTreatment/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/InformedConsentTreatment/InformedConsentTreatment.vue')
        }
    },
    {
        path: 'InformedConsentTreatment/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/InformedConsentTreatment/InformedConsentTreatment.vue')
        }
    },
    {
        path: 'InformedConsentTreatment/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/InformedConsentTreatment/InformedConsentTreatmentView.vue')
        }
    },
    {
        path: 'InformedConsentDiagnostics/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/InformedConsentDiagnostics/InformedConsentDiagnostics.vue')
        }
    },
    {
        path: 'InformedConsentDiagnostics/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/InformedConsentDiagnostics/InformedConsentDiagnostics.vue')
        }
    },
    {
        path: 'InformedConsentDiagnostics/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/InformedConsentDiagnostics/InformedConsentDiagnosticsView.vue')
        }
    }
];
