import { plainToInstance } from 'class-transformer';

import { FamilyHistoryRelationshipDto } from '@/model/Nomenclature/FamilyHistoryRelationshipDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const familyHistoryRelationshipService = {
    async getFamilyHistoryRelationships(): Promise<FamilyHistoryRelationshipDto[]> {
        const response = await httpService.get<FamilyHistoryRelationshipDto[]>(
            '/FamilyHistoryRelationship/GetFamilyHistoryRelationships'
        );
        return plainToInstance(FamilyHistoryRelationshipDto, response?.data);
    }
};
