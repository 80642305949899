<template>
    <div>
        <v-row>
            <v-col cols="9">
                <dropdown-search
                    v-model="value.code"
                    :service="labTestService"
                    item-text="name"
                    item-value="code"
                    label="МДД"
                />
            </v-col>
            <v-col cols="3" class="pt-0">
                <v-text-field v-model="value.customCode" label="...или друг вид/код"></v-text-field>
            </v-col>
        </v-row>
        <v-text-field ref="txtResults" v-model="value.result" label="Резултат"></v-text-field>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DropdownSearch from '@/component/Dropdown/DropdownSearch.vue';
    import { ExternalVisitLabTestDto } from '@/model/Exam/ExternalVisit/ExternalVisitLabTestDto';
    import { labTestService } from '@/service/Nomenclature/LabTestService';

    @Component({ components: { DropdownSearch } })
    export default class ExternalVisitLabTest extends Vue {
        @Prop()
        private value!: ExternalVisitLabTestDto;

        private get labTestService() {
            return labTestService;
        }

        mounted() {
            const txtResults = this.$refs.txtResults as HTMLElement;
            this.$nextTick(() => {
                if (txtResults) {
                    txtResults.focus();
                }
            });
        }
    }
</script>
