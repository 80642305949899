<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Медицинско направление за клинична процедура"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <main-title>Медицинско направление за клинична процедура</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6"><PatientInfo /></v-col>
                    <v-col cols="12" md="6"><DoctorInfo /></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" md="6"><date-picker v-model="document.issueDate" label="Дата:" /></v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="4"
                        ><v-checkbox v-model="document.isEmergency" label="Спешен"></v-checkbox
                    ></v-col>
                    <v-col cols="12" md="4">
                        <dropdown
                            v-model="document.clinicalProcedureCode"
                            :items="algorithms.clinicalAlgorithms"
                            item-value="code"
                            item-text="nhifCodeAndName"
                            clearable
                            :rules="[
                                $validator.exactlyOneIsRequired(
                                    document.clinicalProcedureCode,
                                    document.ambulatoryProcedureCode,
                                    'Клинична процедура или Амбулаторна процедура'
                                )
                            ]"
                            label="КПр:"
                        ></dropdown>
                    </v-col>
                    <v-col cols="12" md="4">
                        <dropdown
                            v-model="document.ambulatoryProcedureCode"
                            :items="algorithms.ambulatoryProcedures"
                            item-value="code"
                            item-text="nhifCodeAndName"
                            clearable
                            :rules="[
                                $validator.exactlyOneIsRequired(
                                    document.clinicalProcedureCode,
                                    document.ambulatoryProcedureCode,
                                    'Клинична процедура или Амбулаторна процедура'
                                )
                            ]"
                            label="АПр:"
                        ></dropdown>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { AlgorithmFormTypeCode } from '@/enum/Exam/AlgorithmFormTypeCode';
    import { ReferralTypeCode } from '@/enum/Exam/ReferralTypeCode';
    import { Referral8Dto } from '@/model/Exam/Referral/Referral8Dto';
    import { ReferralAlgorithmListsModel } from '@/model/Exam/Referral/ReferralAlgorithmListsModel';
    import { referral8Service } from '@/service/Exam/Referral/Referral8Service';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { algorithmService } from '@/service/Nomenclature/AlgorithmService';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: { UnsavedChanges, PatientInfo, DoctorInfo, ExamLayout },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class Referral8 extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private document: Referral8Dto = new Referral8Dto();
        private initialValue: Referral8Dto = new Referral8Dto();
        private algorithms: ReferralAlgorithmListsModel = new ReferralAlgorithmListsModel();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private async mounted() {
            this.leaveDialogRef = this.leaveDialog;
            await this.load();
            this.copyObject();
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                this.algorithms = await algorithmService.getActiveNhifAlgorithmsByFormType(
                    AlgorithmFormTypeCode.Referral8
                );

                if (!this.documentId) {
                    const responseExam = await referral8Service.getReferral8InfoByExamId(this.examId);
                    this.document = responseExam.data;
                } else {
                    const responseReferral = await referral8Service.getReferral8(this.documentId);
                    this.document = responseReferral.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                let title = `Дата ${this.document.issueDate.toLocaleDateString()}`;

                if (this.document.clinicalProcedureCode) {
                    title = `${title} ${
                        this.algorithms.clinicalAlgorithms.find(
                            (alg) => alg.code === this.document.clinicalProcedureCode
                        )?.name
                    }`;
                } else if (this.document.ambulatoryProcedureCode) {
                    title = `${title} ${
                        this.algorithms.ambulatoryProcedures.find(
                            (alg) => alg.code === this.document.ambulatoryProcedureCode
                        )?.name
                    }`;
                }

                if (documentId === 0) {
                    const refResponse = await referral8Service.createReferral8(this.document);
                    documentId = Number(refResponse.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, ReferralTypeCode.Referral8, title);
                    this.$router.push(`/Exam/Referral8/Edit/${documentId}`);
                } else {
                    await referral8Service.updateReferral8(this.document);
                    eventBus.$emit('update-referral-event', title);
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            const documentId = this.document.id;
            await referral8Service.deleteReferral8(documentId);
            this.copyObject();
            this.$router.push(`/Exam/Referral8/Create/${currentVisitCache.value.id}`);
            eventBus.$emit(
                'delete-referral-event',
                documentId,
                ReferralTypeCode.Referral8,
                'Бланка 8 - Направление за клинична процедура'
            );
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Referral.Referral8/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Referral.Referral8';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
