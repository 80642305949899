import { plainToInstance } from 'class-transformer';

import { ReferralRequestForTypeDto } from '@/model/Nomenclature/ReferralRequestForTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referralRequestForTypeService = {
    async getTypes(): Promise<ReferralRequestForTypeDto[]> {
        const response = await httpService.get<ReferralRequestForTypeDto[]>('/ReferralRequestForType/GetTypes');
        return plainToInstance(ReferralRequestForTypeDto, response.data);
    }
};
