import { InsuranceCheckRequestDto } from '@/model/Patient/InsuranceStatus/InsuranceCheckRequestDto';
import { InsuranceStatusDto } from '@/model/Patient/InsuranceStatus/InsuranceStatusDto';
import { PatientInsuranceCheckRequestDto } from '@/model/Patient/InsuranceStatus/PatientInsuranceCheckRequestDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { userContextCache } from '@/store/User/UserContextCache';
import { dateUtil } from '@/util/DateUtil';

export const insuranceStatusService = {
    async checkStatus(
        patientId: number,
        pidTypeCode: string,
        identifier: string,
        // TODO: Да се махне. При проверка на осиг. статус не би следвало да се подава осиг. статус.
        insuranceStatus: InsuranceStatusDto
    ): Promise<InsuranceStatusDto | null> {
        const patientData = new PatientInsuranceCheckRequestDto(patientId, pidTypeCode, identifier, insuranceStatus);

        const request = new InsuranceCheckRequestDto();
        request.practiceId = userContextCache?.currentPracticeId ?? 0;
        request.patient = patientData;
        request.fillCurrentMonth();

        const { data } = await httpService.post<InsuranceStatusDto>(
            '/InsuranceStatus/CheckAndUpdateNhifInsuranceStatus',
            request
        );
        if (data) {
            if (data.isInsured) {
                notifierService.showSuccess(
                    '',
                    `Към дата: ${dateUtil.formatBgDate(new Date())} пациентът е със статус "Осигурен"`
                );
            } else {
                notifierService.showWarning(
                    '',
                    data.exceptionMessage ??
                        `Към дата: ${dateUtil.formatBgDate(new Date())} пациентът е със статус "Неосигурен"`
                );
            }
        }
        return data;
    },

    async insuranceStatusGroupCheck(data: InsuranceCheckRequestDto): Promise<number> {
        const response = await httpService.post<number>('/InsuranceStatus/InsuranceStatusGroupCheck', data);
        return response.data;
    },

    async getPatientCountToCheck(data: InsuranceCheckRequestDto): Promise<number> {
        const response = await httpService.post<number>('/InsuranceStatus/GetPatientCountToCheck', data);
        return response.data;
    }
};
