import { AxiosResponse } from 'axios';

import { CertificateSocialServicesDto } from '@/model/Exam/Certificate/CertificateSocialServicesDto';
import { CertificateSocialServicesViewDto } from '@/model/Exam/Certificate/CertificateSocialServicesViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const certificateSocialServicesService = {
    getInfoByExamId(examId: number): Promise<AxiosResponse<CertificateSocialServicesDto>> {
        return httpService.get(`/CertificateSocialServices/GetInfoByExamId/${examId}`);
    },

    getCertificateSocialServices(certificateId: number): Promise<AxiosResponse<CertificateSocialServicesDto>> {
        return httpService.get(`/CertificateSocialServices/GetCertificateSocialServices/${certificateId}`);
    },

    createCertificateSocialServices(
        data: CertificateSocialServicesDto
    ): Promise<AxiosResponse<CertificateSocialServicesDto>> {
        return httpService.post('/CertificateSocialServices/CreateCertificateSocialServices', data);
    },

    updateCertificateSocialServices(
        data: CertificateSocialServicesDto
    ): Promise<AxiosResponse<CertificateSocialServicesDto>> {
        return httpService.put('/CertificateSocialServices/UpdateCertificateSocialServices/', data);
    },

    deleteCertificateSocialServices(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/CertificateSocialServices/DeleteCertificateSocialServices/${id}`);
    },

    getCertificatesByExamId(examId: number): Promise<AxiosResponse<CertificateSocialServicesViewDto[]>> {
        return httpService.get(`/CertificateSocialServices/GetCertificateSocialServicesViewsByExam`, {
            params: { examId }
        });
    }
};
