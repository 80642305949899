import { Type } from 'class-transformer';

import { Report365TransportationRowDto } from '@/model/Report/Rhi/Report365/Report365TransportationRowDto';

export class Report365TransportationDto {
    public name: string = '';

    @Type(() => Report365TransportationRowDto)
    public transportations: Report365TransportationRowDto[] = [];
}
