import { AxiosResponse } from 'axios';

import { nhifHttpService } from '@/service/Infrastructure/NhifHttpService';

export const nhifIntegrationService = {
    getNomenclature(nomenclatureCode: string): Promise<AxiosResponse<string>> {
        return nhifHttpService.get(`/api/nomenclature/v1.0.1/${nomenclatureCode}`);
    },

    postStatusProtocolSearch(data: string): Promise<AxiosResponse<string>> {
        return nhifHttpService.post('/api/preq/v1.0.1/ppr001', data);
    },

    postProtocolCreate(data: string): Promise<AxiosResponse<string>> {
        return nhifHttpService.post('/api/preq/v1.0.1/ppr002', data);
    },

    postProtocolUploadDocuments(data: string): Promise<AxiosResponse<string>> {
        return nhifHttpService.post('/api/preq/v1.0.1/ppr003', data);
    },

    postProtocolFinish(data: string): Promise<AxiosResponse<string>> {
        return nhifHttpService.post('/api/preq/v1.0.1/ppr004', data);
    },

    postProtocolFetch(data: string): Promise<AxiosResponse<string>> {
        return nhifHttpService.post('/api/preq/v1.0.1/ppr005', data);
    },

    postProtocolCancelation(data: string): Promise<AxiosResponse<string>> {
        return nhifHttpService.post('/api/preq/v1.0.1/ppr006', data);
    },

    postProtocolDocumentCheck(data: string): Promise<AxiosResponse<string>> {
        return nhifHttpService.post('/api/preq/v1.0.1/ppr007', data);
    }
};
