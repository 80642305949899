class PrescriptionProtocolType {
    public code: string = '';
}

const prescriptionProtocolTypes: PrescriptionProtocolType[] = [
    { code: 'Без протокол' },
    { code: 'IA' },
    { code: 'IB' },
    { code: 'IC' }
];

export { PrescriptionProtocolType, prescriptionProtocolTypes };
