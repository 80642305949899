<template>
    <div>
        <exam-layout :can-delete="value.id > 0" :can-print="false" @updated="save" @deleted="deleteEntity">
            <nhis-allergy :id="documentId" v-model="value.nhisAllergy" :patient-id="patientId" />
            <v-card>
                <main-title>Алергии</main-title>
                <v-card-text>
                    <v-row class="flex-row align-end">
                        <v-col cols="12" md="9"
                            ><text-field v-model="value.name" label="Наименование" required
                        /></v-col>
                        <v-col cols="6" md="3">
                            <dropdown
                                v-model="value.allergyTypeId"
                                :items="types"
                                label="Тип на алергията"
                                no-data-text="Няма данни"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="flex-row align-end">
                        <v-col cols="6" md="3">
                            <date-picker v-model="value.startDate" label="Дата на откриване" />
                        </v-col>
                        <v-col cols="6" md="3">
                            <date-picker v-model="value.lastOccurrence" label="Дата на последно възникване" />
                        </v-col>
                        <v-col cols="6" md="3">
                            <text-field v-model="value.medicationCode" label="Код на лекарство" />
                        </v-col>
                        <v-col cols="6" md="3">
                            <text-field v-model="value.nrnExecution" label="Нрн за доказване на статуса" />
                        </v-col>
                        <v-col cols="6" md="3">
                            <dropdown
                                v-model="value.likeCode"
                                :items="likes"
                                item-value="code"
                                label="Вид"
                                required
                            ></dropdown>
                        </v-col>
                        <v-col cols="6" md="3">
                            <dropdown
                                v-model="value.categoryCode"
                                :items="categories"
                                item-value="code"
                                label="Категория"
                                required
                            ></dropdown>
                        </v-col>
                        <v-col cols="6" md="3">
                            <dropdown
                                v-model="value.criticalityCode"
                                :items="criticalities"
                                item-value="code"
                                label="Критичност"
                                required
                            ></dropdown>
                        </v-col>
                        <v-col cols="6" md="3">
                            <dropdown
                                v-model="value.clinicalStatusCode"
                                :items="clinicalStatuses"
                                item-value="code"
                                label="Клиничен статус"
                                required
                            ></dropdown>
                        </v-col>
                        <v-col cols="6" md="3">
                            <dropdown
                                v-model="value.verificationStatusCode"
                                :items="verificationStatuses"
                                item-value="code"
                                label="Верификационен статус"
                                required
                            ></dropdown>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-textarea
                                v-model="value.description"
                                label="Описание"
                                class="required"
                                :rules="[$validator.required]"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </exam-layout>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import MainTitle from '@/component/Common/MainTitle.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { AllergyCategoryDto } from '@/model/Nomenclature/AllergyCategoryDto';
    import { AllergyClinicalStatusDto } from '@/model/Nomenclature/AllergyClinicalStatusDto';
    import { AllergyCriticalityDto } from '@/model/Nomenclature/AllergyCriticalityDto';
    import { AllergyLikeDto } from '@/model/Nomenclature/AllergyLikeDto';
    import { AllergyTypeDto } from '@/model/Nomenclature/AllergyTypeDto';
    import { AllergyVerificationStatusDto } from '@/model/Nomenclature/AllergyVerificationStatusDto';
    import { PatientAllergyDto } from '@/model/Patient/Allergy/PatientAllergyDto';
    import { allergyCategoryService } from '@/service/Nomenclature/AllergyCategoryService';
    import { allergyClinicalStatusService } from '@/service/Nomenclature/AllergyClinicalStatusService';
    import { allergyCriticalityService } from '@/service/Nomenclature/AllergyCriticalityService';
    import { allergyLikeService } from '@/service/Nomenclature/AllergyLikeService';
    import { allergyTypeService } from '@/service/Nomenclature/AllergyTypeService';
    import { allergyVerificationStatusService } from '@/service/Nomenclature/AllergyVerificationStatusService';
    import { patientAllergyService } from '@/service/Patient/PatientAllergyService';
    import NhisAllergy from '@/view/Nhis/Allergy/NhisAllergy.vue';

    @Component({
        components: { NhisAllergy, MainTitle, ExamLayout }
    })
    export default class PatientAllergyEditor extends Vue {
        @Prop()
        private patientId!: number;

        @Prop()
        private documentId!: number;

        private value: PatientAllergyDto = new PatientAllergyDto();
        private types: AllergyTypeDto[] = [];
        private likes: AllergyLikeDto[] = [];
        private categories: AllergyCategoryDto[] = [];
        private criticalities: AllergyCriticalityDto[] = [];
        private clinicalStatuses: AllergyClinicalStatusDto[] = [];
        private verificationStatuses: AllergyVerificationStatusDto[] = [];

        private async getAllergyTypes() {
            this.types = await allergyTypeService.getAllergyTypes();
        }

        private async getAllergyLikes() {
            this.likes = await allergyLikeService.getAllergyLikes();
        }

        private async getAllergyCategories() {
            this.categories = await allergyCategoryService.getAllergyCategories();
        }

        private async getAllergyCriticalities() {
            this.criticalities = await allergyCriticalityService.getAllergyCriticalities();
        }

        private async getAllergyClinicalStatuses() {
            this.clinicalStatuses = await allergyClinicalStatusService.getAllergyClinicalStatuses();
        }

        private async getAllergyVerificationStatuses() {
            this.verificationStatuses = await allergyVerificationStatusService.getAllergyVerificationStatuses();
        }

        private async mounted() {
            await this.getAllergyTypes();
            await this.getAllergyLikes();
            await this.getAllergyCategories();
            await this.getAllergyCriticalities();
            await this.getAllergyClinicalStatuses();
            await this.getAllergyVerificationStatuses();
            if (this.documentId) {
                await this.getById();
            }
        }

        private async save() {
            try {
                this.value.patientId = this.patientId;
                if (this.value.id <= 0) {
                    const documentId = await patientAllergyService.createPatientAllergy(this.value);
                    this.$router.push(`/Patient/Allergy/Edit/${documentId}/${this.patientId}`);
                } else {
                    await patientAllergyService.updatePatientAllergy(this.value);
                }
                this.$notifier.showSuccess('', `Успешен запис`);
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEntity() {
            this.$loading.show();
            try {
                await patientAllergyService.deletePatientAllergy(this.documentId);
                this.$router.push(`/Patient/Allergy/Create/${this.patientId}`);
            } finally {
                this.$loading.hide();
            }
        }

        private async getById() {
            this.$loading.show();
            try {
                this.value = await patientAllergyService.getById(this.documentId);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
