<template>
    <v-card>
        <v-card-text
            ><v-row>
                <v-btn-toggle dense mandatory group>
                    <v-btn :to="`/Finance/Service/PriceList/${practiceId}`">
                        <v-icon>mdi-cash</v-icon>Ценоразпис
                    </v-btn>
                    <v-btn :to="`/Finance/Service/QuickAddLabTest/${practiceId}`">
                        <v-icon>mdi-beaker-plus-outline</v-icon>Бързо добавяне на изследвания
                    </v-btn>
                    <v-btn :to="`/Finance/Service/Tariff/${practiceId}`">
                        <v-icon>mdi-cash-multiple</v-icon>Тарифи
                    </v-btn>
                    <v-btn :to="`/Finance/Service/Category/${practiceId}`">
                        <v-icon>mdi-file-tree</v-icon>Категории
                    </v-btn>
                    <v-btn :to="`/Finance/Service/QuickAddTariffsAndService/${practiceId}`">
                        <v-icon>mdi-fast-forward</v-icon>Начална настройка
                    </v-btn>
                </v-btn-toggle>
            </v-row>
            <v-row
                ><v-col cols="12">
                    <router-view :key="$route.fullPath" name="service" />
                </v-col> </v-row
        ></v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class ServicePriceList extends Vue {
        @Prop()
        private practiceId!: number;
    }
</script>
