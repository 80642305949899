import { Route, RouteConfig } from 'vue-router';

export const examRequestForRoutes: RouteConfig[] = [
    {
        path: 'RequestForCytosmear/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/RequestForCytosmear/RequestForCytosmear.vue')
        }
    },
    {
        path: 'RequestForCytosmear/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/RequestForCytosmear/RequestForCytosmear.vue')
        }
    },
    {
        path: 'RequestForCytosmear/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/RequestForCytosmear/RequestFromCytosmearView.vue')
        }
    },
    {
        path: 'RequestForHistopathologicalTest/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/RequestForHistopathologicalTest/RequestForHistopathologicalTest.vue')
        }
    },
    {
        path: 'RequestForHistopathologicalTest/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/RequestForHistopathologicalTest/RequestForHistopathologicalTest.vue')
        }
    },
    {
        path: 'RequestForHistopathologicalTest/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/RequestForHistopathologicalTest/RequestForHistopathologicalTestView.vue')
        }
    },
    {
        path: 'RequestForImagingTest/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/RequestForImagingTest/RequestForImagingTest.vue')
        }
    },
    {
        path: 'RequestForImagingTest/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/RequestForImagingTest/RequestForImagingTest.vue')
        }
    },
    {
        path: 'RequestForImagingTest/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/RequestForImagingTest/RequestForImagingTestView.vue')
        }
    }
];
