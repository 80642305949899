import { Type } from 'class-transformer';

import { DoctorDeputyKey } from '@/model/Employee/Deputy/DoctorDeputyKey';

export class DoctorDeputyUpdateCommand {
    @Type(() => DoctorDeputyKey)
    public doctorDeputyKey: DoctorDeputyKey = new DoctorDeputyKey();

    public deputyUserId: number | null = null;
    public otherEmailOrUserName: string | null = null;
}
