export class FileUploadCommand {
    constructor(
        public practiceId: number | null,
        public patientId: number | null,
        public visitId: number | null
    ) {}

    public fileName: string = '';
    public description: string = '';
    public content: number[] | null = null;
}
