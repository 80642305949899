var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dropdown',{attrs:{"items":_vm.getPossibleResultsByProcedure,"item-value":"nhisCode","required":"","label":"Резултат тип"},model:{value:(_vm.value.resultEnumCode),callback:function ($$v) {_vm.$set(_vm.value, "resultEnumCode", $$v)},expression:"value.resultEnumCode"}})],1),(
            _vm.value.resultEnumCode && _vm.resultAnswerEnum && _vm.resultAnswerEnum.answerTypeCode == _vm.ExamAnswerTypeCode.Bool
        )?_c('v-col',{attrs:{"md":"3","cols":"6"}},[_c('v-checkbox',{attrs:{"dense":"","label":`Резултат процедура ${_vm.position}`},model:{value:(_vm.value.resultBoolean),callback:function ($$v) {_vm.$set(_vm.value, "resultBoolean", $$v)},expression:"value.resultBoolean"}})],1):(
            _vm.value.resultEnumCode && _vm.resultAnswerEnum && _vm.resultAnswerEnum.answerTypeCode == _vm.ExamAnswerTypeCode.Number
        )?_c('v-col',{attrs:{"md":"3","cols":"6"}},[_c('text-field',{attrs:{"type":"number","label":`Резултат процедура ${_vm.position}`,"required":""},model:{value:(_vm.value.resultQuantity),callback:function ($$v) {_vm.$set(_vm.value, "resultQuantity", $$v)},expression:"value.resultQuantity"}})],1):(
            _vm.value.resultEnumCode && _vm.resultAnswerEnum && _vm.resultAnswerEnum.answerTypeCode == _vm.ExamAnswerTypeCode.Text
        )?_c('v-col',{attrs:{"md":"3","cols":"6"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","label":`Резултат процедура ${_vm.position}`,"rows":"2","rules":[_vm.$validator.required]},model:{value:(_vm.value.resultText),callback:function ($$v) {_vm.$set(_vm.value, "resultText", $$v)},expression:"value.resultText"}})],1):(
            _vm.value.resultEnumCode && _vm.resultAnswerEnum && _vm.resultAnswerEnum.answerTypeCode == _vm.ExamAnswerTypeCode.Date
        )?_c('v-col',{attrs:{"md":"3","cols":"6"}},[_c('date-picker',{attrs:{"label":`Резултат процедура ${_vm.position}`,"required":""},model:{value:(_vm.value.resultDate),callback:function ($$v) {_vm.$set(_vm.value, "resultDate", $$v)},expression:"value.resultDate"}})],1):_c('v-col',{attrs:{"md":"3","cols":"6"}},[(_vm.value.resultEnumCode && _vm.resultAnswerEnum)?_c('dropdown',{attrs:{"items":_vm.getAnswersByType(_vm.resultAnswerEnum.answerTypeCode),"item-value":"nhisCode","required":""},model:{value:(_vm.value.resultCode),callback:function ($$v) {_vm.$set(_vm.value, "resultCode", $$v)},expression:"value.resultCode"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }