import { AxiosResponse } from 'axios';

import { PracticeUnitDto } from '@/model/Practice/PracticeUnit/PracticeUnitDto';
import { PracticeUnitLaboratoryDto } from '@/model/Practice/PracticeUnit/PracticeUnitLaboratoryDto';
import { PracticeUnitWardDto } from '@/model/Practice/PracticeUnit/PracticeUnitWardDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const practiceUnitService = {
    getAllPracticeUnits(id: number): Promise<AxiosResponse<PracticeUnitDto[]>> {
        return httpService.get(`/PracticeUnit/GetAllPracticeUnits/${id}`);
    },

    createWard(data: PracticeUnitWardDto): Promise<AxiosResponse<PracticeUnitWardDto>> {
        return httpService.post('/PracticeUnit/CreateWard', data);
    },

    createLaboratory(data: PracticeUnitLaboratoryDto): Promise<AxiosResponse<PracticeUnitLaboratoryDto>> {
        return httpService.post('/PracticeUnit/CreateLaboratory', data);
    },

    createAdmissionUnit(data: PracticeUnitDto): Promise<AxiosResponse<PracticeUnitDto>> {
        return httpService.post('/PracticeUnit/CreateAdmissionUnit', data);
    },

    updateWard(data: PracticeUnitWardDto): Promise<AxiosResponse<PracticeUnitWardDto>> {
        return httpService.post('/PracticeUnit/UpdateWard', data);
    },

    updateLaboratory(data: PracticeUnitLaboratoryDto): Promise<AxiosResponse<PracticeUnitLaboratoryDto>> {
        return httpService.post('/PracticeUnit/UpdateLaboratory', data);
    },

    updateAdmissionUnit(data: PracticeUnitDto): Promise<AxiosResponse<PracticeUnitDto>> {
        return httpService.post('/PracticeUnit/UpdateAdmissionUnit', data);
    },

    getWard(practiceId: number, seqNumber: number): Promise<AxiosResponse<PracticeUnitWardDto>> {
        return httpService.get('PracticeUnit/GetWard', { params: { practiceId, seqNumber } });
    },

    getLaboratory(practiceId: number, seqNumber: number): Promise<AxiosResponse<PracticeUnitLaboratoryDto>> {
        return httpService.get('PracticeUnit/GetLaboratory', { params: { practiceId, seqNumber } });
    },

    getAdmissionUnit(practiceId: number, seqNumber: number): Promise<AxiosResponse<PracticeUnitDto>> {
        return httpService.get('PracticeUnit/GetAdmissionUnit', { params: { practiceId, seqNumber } });
    },

    deletePracticeUnit(practiceId: number, seqNumber: number): Promise<AxiosResponse<void>> {
        return httpService.delete('PracticeUnit/DeletePracticeUnit', { params: { practiceId, seqNumber } });
    }
};
