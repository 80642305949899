<template>
    <v-tabs class="mt-5">
        <v-tab>Протокол лекарства</v-tab>
        <v-tab v-if="canUpload">Прикачване на документи</v-tab>
        <v-tab v-if="canCheck">Проверка на документи</v-tab>
        <v-tab-item>
            <nhif-protocol-editor :document-id="documentId" :exam-id="examId" @input="onNrnChange" />
        </v-tab-item>
        <v-tab-item>
            <nhif-protocol-upload-document v-if="canUpload" :nrn-protocol="protocolInfo.nrn" />
        </v-tab-item>
        <v-tab-item>
            <nhif-protocol-document-check v-if="canCheck" :nrn-protocol="protocolInfo.nrn" :document-id="documentId" />
        </v-tab-item>
    </v-tabs>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhifProtocolStatusCode } from '@/enum/Nhif/NhifProtocolStatusCode';
    import { NhifProtocolInfoDto } from '@/model/Exam/DrugProtocol/NhifProtocolInfoDto';
    import NhifProtocolDocumentCheck from '@/view/Exam/DrugProtocol/NhifProtocolDocumentCheck.vue';
    import NhifProtocolEditor from '@/view/Exam/DrugProtocol/NhifProtocolEditor.vue';
    import NhifProtocolUploadDocument from '@/view/Exam/DrugProtocol/NhifProtocolUploadDocument.vue';

    @Component({
        components: { NhifProtocolDocumentCheck, NhifProtocolUploadDocument, NhifProtocolEditor }
    })
    export default class NhifProtocol extends Vue {
        @Prop()
        private examId!: number;

        @Prop()
        private documentId!: number;

        private protocolInfo: NhifProtocolInfoDto | null = null;

        private onNrnChange(data: NhifProtocolInfoDto | null) {
            this.protocolInfo = data;
        }

        private get canUpload() {
            return (
                this.protocolInfo?.nrn &&
                (this.protocolInfo?.statusCode === NhifProtocolStatusCode.AwaitingDocuments ||
                    this.protocolInfo?.statusCode === NhifProtocolStatusCode.WaitingDocuments)
            );
        }

        private get canCheck() {
            return this.protocolInfo?.nrn;
        }
    }
</script>
