import { Type } from 'class-transformer';

import { NhisErrorDto } from '@/model/Nhis/NhisErrorDto';
import { NhisResponseWarningDto } from '@/model/Nhis/NhisResponseWarningDto';

export class NhisResponseDto {
    public nrn: string = '';
    public lrn: string = '';
    public statusCode: string = '';
    public statusName: string = '';
    public statusDate: Date | null = null;
    public error: string = '';

    @Type(() => NhisErrorDto)
    public errors: NhisErrorDto[] = [];

    @Type(() => NhisResponseWarningDto)
    public warnings: NhisResponseWarningDto[] = [];

    public response: string = '';
}
