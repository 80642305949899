import { StagedEpicrisisExamFilterTypeCode } from './StagedEpicrisisExamFilterType';

export class StagedEpicrisisBuildCommand {
    public currentExamId: number | null = null;
    public patientId: number = 0;
    public examFilterTypeCode: StagedEpicrisisExamFilterTypeCode | null = null;
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
    public examIds: number[] = [];
}
