<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">ДЕЙНОСТИ, СВЪРЗАНИ С ДЕТСКОТО ЗДРАВЕОПАЗВАНЕ</h3></v-col>
            <v-col cols="12"><h3 class="mb-2">2. Деца до 7 години под наблюдение на консултацията</h3></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><DataTableComponent v-model="value.childrenRegisters" :headers="headers" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Annex3ChildrenRegisterDto } from '@/model/Report/Rhi/Annex3/Annex3ChildrenRegisterDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class ChildrenRegister extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Annex3ChildrenRegisterDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: '', value: 'name' },
            { text: 'Шифър', value: 'code' },
            { text: 'В началото на годината', value: 'countStartYear' },
            { text: 'Постъпили', value: 'countDuringYear' },
            { text: 'Напуснали', value: 'countDeregistered' },
            { text: 'Преминали в следваща възраст', value: 'countDeregisteredOtherAge' },
            { text: 'Умрели', value: 'countDead' },
            { text: 'В края на годината', value: 'countEndYear' }
        ];
    }
</script>
