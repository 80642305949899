<template>
    <v-dialog transition="dialog-bottom-transition" dense max-width="1200">
        <template #activator="{ on, attrs }">
            <v-row class="float-right">
                <v-col cols="5">
                    <btn action="Details" block v-bind="attrs" v-on="on">Дейности</btn>
                </v-col>
            </v-row>
        </template>
        <template #default="dialog">
            <v-card v-if="dispensaryRegister && dispensaryRegister.dispensaryRuleId > 0">
                <v-card-title class="info2">
                    Наредба за диспансеризация - необходими и извършени дейности
                    <v-spacer />
                    <btn icon action="Close" @click="dialog.value = false" />
                </v-card-title>
                <v-card-text>
                    <NecessaryActivities
                        :fund-lab-tests="dispensaryRegister.dispensaryLabTests"
                        :consultations="dispensaryRegister.dispensaryConsultations"
                        :suggested-future-exams="dispensaryRegister.suggestedFutureExams"
                    />
                </v-card-text>
            </v-card>
            <v-card v-if="pregnancyRegister && pregnancyRegister.pregnancyRuleId > 0">
                <v-card-title class="info2">
                    Наредба за Майчино здравеопазване - необходими и извършени дейности
                    <v-spacer />
                    <btn icon action="Close" @click="dialog.value = false" />
                </v-card-title>
                <v-card-text>
                    <NecessaryActivities
                        :fund-lab-tests="pregnancyRegister.pregnancyLabTests"
                        :consultations="pregnancyRegister.pregnancyConsultations"
                    />
                </v-card-text>
            </v-card>
            <v-card v-if="childrenRegister && childrenRegister.childrenHealthRuleId > 0">
                <v-card-title class="info2">
                    Наредба за Детско здравеопазване - необходими и извършени дейности
                    <v-spacer />
                    <btn icon action="Close" @click="dialog.value = false" />
                </v-card-title>
                <v-card-text>
                    <NecessaryActivities
                        :fund-lab-tests="childrenRegister.childrenHealthLabTests"
                        :consultations="childrenRegister.childrenHealthConsultations"
                    />
                </v-card-text>
            </v-card>
            <v-card v-if="preventiveRegister && preventiveRegister.hasPreventiveRule > 0">
                <v-card-title class="info2">
                    Наредба за Общ медецински преглед на ЗОЛ над 18-годишна възраст - необходими и извършени дейности
                    <v-spacer />
                    <btn icon action="Close" @click="dialog.value = false" />
                </v-card-title>
                <v-card-text>
                    <v-card>
                        <v-card-title>Необходими и извършени дейности</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <div v-if="preventiveRegister.preventiveConsultations.length > 0">
                                        <h4>Консултации</h4>
                                        <data-table
                                            :headers="headersConsult"
                                            :items="preventiveRegister.preventiveConsultations"
                                            :disable-pagination="true"
                                            class="elevation-1"
                                        >
                                        </data-table>
                                    </div>
                                    <h4>Изследвания</h4>
                                    <data-table
                                        :headers="headersLabTests"
                                        :items="preventiveRegister.preventiveNhifLabTests"
                                        :disable-pagination="true"
                                        class="elevation-1"
                                    >
                                    </data-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ChildrenHealthRegisterDto } from '@/model/Register/ChildrenHealth/ChildrenHealthRegisterDto';
    import { DispensaryRegisterDto } from '@/model/Register/Dispensary/DispensaryRegisterDto';
    import { PregnancyRegisterDto } from '@/model/Register/Pregnancy/PregnancyRegisterDto';
    import { PreventiveRegisterDto } from '@/model/Register/Preventive/PreventiveRegisterDto';
    import NecessaryActivities from '@/view/Register/NecessaryActivities.vue';

    @Component({
        components: { NecessaryActivities }
    })
    export default class RegulationActivitiesDialog extends Vue {
        @Prop({ type: Object })
        private dispensaryRegister!: DispensaryRegisterDto;

        @Prop({ type: Object })
        private pregnancyRegister!: PregnancyRegisterDto;

        @Prop({ type: Object })
        private childrenRegister!: ChildrenHealthRegisterDto;

        @Prop({ type: Object })
        private preventiveRegister!: PreventiveRegisterDto;

        private headersConsult: IDataTableHeader[] = [
            { text: 'Специалност', value: 'specialtyName' },
            { text: 'Възраст', value: 'ageDescription' },
            { text: 'Необходим брой - описание', value: 'frequencyInYearsDescription' },
            { text: 'Извършен брой', value: 'totalPerformedConsultations' }
        ];

        private headersLabTests: IDataTableHeader[] = [
            { text: 'Код на изследване', value: 'nhifLabTestCode' },
            { text: 'Възраст', value: 'ageDescription' },
            { text: 'Необходим брой - описание', value: 'frequencyInYearsDescription' },
            { text: 'Извършен брой', value: 'totalPerformedLabTests' }
        ];
    }
</script>
