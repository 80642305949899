import { plainToInstance } from 'class-transformer';

import { AccessTokenDto } from '@/model/Nhis/AccessToken/AccessTokenDto';
import { AccessTokenMap } from '@/model/Nhis/AccessToken/AccessTokenMap';
import { nhisNhifAccessTokenService } from '@/service/Integration/NhisNhifAccessTokenService';
import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
import { localServerSignService } from '@/service/LocalServer/LocalServerSignService';

// Име на елемента в local storage, който съхранява всички token-и накуп.
const nhisNhifAccessTokensKey: string = 'nhisNhifAccessTokens';

// Име на наш api-контролер и ключ на token-а в map-а от token-и.
const nhisKey: string = 'Nhis';
const nhifKey: string = 'Nhif';

class NhisNhifAccessTokens {
    private _tokenMap: AccessTokenMap | null = null;

    public getCachedToken(key: string) {
        // Всички token-и се зарежат накуп от local storage при първото поискване на кой да е от тях.
        if (!this._tokenMap) {
            this._tokenMap = NhisNhifAccessTokens.loadFromLocalStorage();
        }
        return this._tokenMap[key];
    }

    private static loadFromLocalStorage() {
        const json = localStorage.getItem(nhisNhifAccessTokensKey);
        const map = json ? plainToInstance(AccessTokenMap, JSON.parse(json)) : new AccessTokenMap();

        // Клас AccessTokenMap съдържа index signature, но това не се поддържа от class-transformer.
        // https://github.com/typestack/class-transformer/issues/148
        // Десериализираните елементи са от тип Object. Налага се да се upgrade-нат един по един до AccessTokenDto.
        for (const key in map) {
            if (Object.prototype.hasOwnProperty.call(map, key)) {
                map[key] = plainToInstance(AccessTokenDto, map[key]);
            }
        }
        return map;
    }

    public async ensureValidToken(key: string) {
        let tokenInfo = this.getCachedToken(key);
        if (!tokenInfo || tokenInfo.isExpired) {
            await this.requestTokenAndSaveToLocalStoage(key);
        }
        tokenInfo = this.getCachedToken(key);
        //console.log(tokenInfo);
        return tokenInfo;
    }

    public clear() {
        localStorage.removeItem(nhisNhifAccessTokensKey);
        this._tokenMap = null;
    }

    private async requestTokenAndSaveToLocalStoage(key: string) {
        const isLocalServerActive = await localServerDiagnosticService.checkIsLocalServerActive();
        if (isLocalServerActive) {
            const challengeResponse = await nhisNhifAccessTokenService.getAccessTokenChallenge(key);
            if (challengeResponse?.data) {
                const signedChallenge = await localServerSignService.signXml(challengeResponse.data);
                if (!signedChallenge.isError && signedChallenge.contents) {
                    const accessTokenResponse = await nhisNhifAccessTokenService.getAccessToken(
                        key,
                        signedChallenge.contents
                    );

                    // На този етап е невъзможно _tokenMap да е null. Това презастраховане всъщност избягва warning.
                    if (!this._tokenMap) {
                        this._tokenMap = new AccessTokenMap();
                    }
                    this._tokenMap[key] = plainToInstance(AccessTokenDto, accessTokenResponse.data);

                    // Всички token-и се записват накуп в local storage.
                    localStorage.setItem(nhisNhifAccessTokensKey, JSON.stringify(this._tokenMap));
                }
            }
        }
    }
}

// Този кеш НЕ е реактивен, за разлика от повечето други. За обръщенията към НЗИС/НЗОК не е нужна реактивност.
// Освен това, обект с динамични property-та (виж index signature-а в AccessTokenMap) е трудно да се направи реактивен.
const accessTokenCache = new NhisNhifAccessTokens();

export { accessTokenCache, nhifKey, nhisKey };
