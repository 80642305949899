// 'core-js/stable' и 'regenerator-runtime/runtime' касаят поддръжката на стари браузъри чрез polyfills.
// Освен да се импортират, трябва също в babel.config.js да е настроено useBuiltIns: 'entry'.
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// 'reflect-metadata' е нужен в комбинация с "emitDecoratorMetadata": true в tsconfig.json, за да може
// типът на даден prop на Vue комонента да се извлече от типа на TypeScript променливата.
// Така вместо @Prop({ type: Boolean, default: true }) може да остане само @Prop({ default: true }).
// 'reflect-metadata' трябва да се импортира само веднъж преди всички импортирания на 'vue-property-decorator'.
// https://github.com/kaorun343/vue-property-decorator
import 'reflect-metadata';
// Иконки.
import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import vueNotificationPlugin from 'vue-notification';

import { globalComponents } from '@/component/GlobalComponents';

import { allowWritePlugin } from './plugin/AllowWrite';
import { i18n } from './plugin/I18n';
import { loadingPlugin } from './plugin/Loading';
import { lodashPlugin } from './plugin/Lodash';
import { notifierPlugin } from './plugin/Notifier';
import { validatorPlugin } from './plugin/Validator';
import { router } from './router';
import { restoreState } from './store/StateRestorer';
import App from './view/App/App.vue';
import { beginValidateVueComponentNames, initVueDirectives } from './VueExtensions';

// Vuetify.ts се импортира след App.vue, само защото е нужно vuetify.min.css да се импортира след App.vue. Виж коментара във Vuetify.ts.
// console.log() служи само за разделител между import-ите, за да не се сортира Vuetify.ts по-нагоре. Дори не отпечатва празен ред.
console.log();
import { vuetify } from './plugin/Vuetify';

// Тази проверка да се пуска от време на време, за да си виждаме пропуските в export default ComponentName.
// * Условието е изкуствено false, за да не се пуска проверката, но да не изглежда като неизползвана.
if (Date === null) {
    beginValidateVueComponentNames();
}

initVueDirectives();

Vue.config.productionTip = false;

Vue.use(vueNotificationPlugin);
Vue.use(allowWritePlugin);
Vue.use(loadingPlugin);
Vue.use(notifierPlugin);
Vue.use(validatorPlugin);
Vue.use(lodashPlugin);

// Ограничен брой масово използвани компоненти се регистрират глобално, за удобство.
for (const [name, component] of Object.entries(globalComponents)) {
    Vue.component(name, component);
}

const app = new Vue({
    i18n,
    router,
    vuetify,
    render: (hyperscript) => hyperscript(App)
});

// Mount-ването на приложението се отлага, докато се поднови сесията и докато се заредят от local storage
// последно използваните потребител, тема, user context, пациент, преглед, нотификации и др.
restoreState().then(() => app.$mount('#app'));
