import { AxiosResponse } from 'axios';

import { DeathNoticeDto } from '@/model/Patient/Gp/DeathNoticeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const deathNoticeService = {
    getDeathNotice(patientId: number, doctorEmployeeSeqNumber: number): Promise<AxiosResponse<DeathNoticeDto>> {
        return httpService.get('/DeathNotice/GetDeathNotice', { params: { patientId, doctorEmployeeSeqNumber } });
    },

    canSaveDeathNotice(data: DeathNoticeDto): Promise<AxiosResponse<string[]>> {
        return httpService.post('/DeathNotice/CanSaveDeathNotice', data);
    },

    createDeathNotice(data: DeathNoticeDto): Promise<AxiosResponse<DeathNoticeDto>> {
        return httpService.post('/DeathNotice/CreateDeathNotice', data);
    },

    updateDeathNotice(data: DeathNoticeDto): Promise<AxiosResponse<DeathNoticeDto>> {
        return httpService.put('/DeathNotice/UpdateDeathNotice', data);
    },

    deleteDeathNotice(patientId: number): Promise<AxiosResponse<number>> {
        return httpService.delete('/DeathNotice/DeleteDeathNotice', { params: { patientId } });
    }
};
