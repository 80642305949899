import { formatters } from '@/util/Formatters';

export class SickSheetMenuViewDto {
    public id: number = 1;
    public number: string = '';
    public issueDate: Date = new Date();
    public icdCode: string = '';
    public status: string = '';
    public sickLeaveStartDate: Date = new Date();
    public sickLeaveEndDate: Date = new Date();
    public sickLeaveLegalDays: number = 1;
    public reasonOfDisease: string = '';

    // Следните полета са съчетани в общ текст, защото по-рядко са попълнени.
    public isContinuation: boolean = false;
    public lkkDecisionNumber: number | null = null;
    public lkkDecisionDate: Date | null = null;
    public telkDecisionNumber: number | null = null;
    public telkDecisionDate: Date | null = null;

    public get notes() {
        const continuationText = this.isContinuation ? 'продължение' : '';
        const lkkText =
            this.lkkDecisionNumber || this.lkkDecisionDate
                ? ` ЛКК решение ${this.lkkDecisionNumber} / ${formatters.date(this.lkkDecisionDate)}`
                : '';
        const telkText =
            this.telkDecisionNumber || this.telkDecisionDate
                ? ` ТЕЛК решение ${this.telkDecisionNumber} / ${formatters.date(this.telkDecisionDate)}`
                : '';
        return `${continuationText}${lkkText}${telkText}`;
    }
}
