import { NhifPrescriptionSegmentCode } from '@/enum/Exam/NhifPrescriptionSegmentCode';

export class NhifDrugDto {
    // Данни от базата
    public id: number = 0;
    public position: number = 1;
    public nhifId: number | null = null;
    public nhifCode: string = '';
    public nhisId: number | null = null;
    public isGeneric: boolean = false;
    public isNeeded: boolean = false;
    public tradeName: string = '';
    public internationalName: string | null = '';
    public quantity: string = '';
    public finalPackage: string = '';
    public medicineFormat: string = '';
    public producer: string = '';
    public notes: string = '';
    public nhifPrize: number = 0;
    public maxPatientPrice: number = 0;
    public maxPrice: number = 0;
    public singlePackage: number = 0;
    public isFullPayed: boolean = false;
    public isDivisible: boolean = false;
    public isQuantityByForm: boolean = false;
    public nhifDrugCategoryCode: string = '';
    public pdlCode: string = '';
    public statusCode: string | null = null;
    // Данни, попълвани от клиента
    public medicineTakeFrequency: number = 0;
    public medicineTakeQuantity: number = 0;
    public medicineDosagePeriodCount: number = 1;
    public medicineDosageUnitCode: string = 'd';
    public therapyDuration: number | null = 1;
    public packageCount: number = 1;
    public dosage: string = '';
    public dosageNote: string = '';
    public atcCode: string = '';

    //За протокола
    public prescribedDays: number | null = null;
    public totalQuantity: number | null = null;
    public unit: number | null = null;
    public unitMeasure: string | null = null;

    //Данни необходими при разглеждане на номенклатурата
    public icdCodes: string[] = [];
    public wayOfPrescribing: string = '';
    public nhifDrugCategory: string = '';
    public prescriptionForms: string[] = [];

    //Необходими за новата мега рецепта
    public examDiagnosisId: number | null = null;
    public isExamDiagnosisAdd: boolean = false;
    public segmentCode: string = NhifPrescriptionSegmentCode.A;
}
