<template>
    <v-dialog transition="dialog-bottom-transition" max-width="900">
        <template #activator="{ on, attrs }">
            <v-row class="float-right">
                <v-col cols="5">
                    <btn action="Details" block v-bind="attrs" v-on="on">Наредба</btn>
                </v-col>
            </v-row>
        </template>
        <template #default="dialog">
            <v-card v-if="dispensaryRegister && dispensaryRegister.dispensaryRuleId > 0">
                <v-card-title class="info2">
                    Наредба за диспансеризация
                    <v-spacer />
                    <btn icon action="Close" @click="dialog.value = false" />
                </v-card-title>
                <v-card-text>
                    <div class="text-h6 pa-08">
                        {{ dispensaryRegister.dispensaryOrdinanceText }}
                    </div>
                </v-card-text>
            </v-card>
            <v-card v-if="pregnancyRegister && pregnancyRegister.pregnancyRuleId > 0">
                <v-card-title class="info2">
                    Наредба за Майчино здравеопазване
                    <v-spacer />
                    <btn icon action="Close" @click="dialog.value = false" />
                </v-card-title>
                <v-card-text>
                    <div class="text-h6 pa-08">
                        {{ pregnancyRegister.ordinanceText }}
                    </div>
                </v-card-text>
            </v-card>
            <v-card v-if="childrenRegister && childrenRegister.childrenHealthRuleId > 0">
                <v-card-title class="info2">
                    Наредба за Детско здравеопазване
                    <v-spacer />
                    <btn icon action="Close" @click="dialog.value = false" />
                </v-card-title>
                <v-card-text>
                    <div class="text-h6 pa-08">
                        {{ childrenRegister.ordinanceText }}
                    </div>
                </v-card-text>
            </v-card>
            <v-card v-if="preventiveRegister && preventiveRegister.hasPreventiveRule">
                <v-card-title class="info2">
                    Наредба за Профилактични прегледи
                    <v-spacer />
                    <btn icon action="Close" @click="dialog.value = false" />
                </v-card-title>
                <v-card-text>
                    <div class="text-h6 pa-08">
                        {{ preventiveRegister.ordinanceText }}
                    </div>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ChildrenHealthRegisterDto } from '@/model/Register/ChildrenHealth/ChildrenHealthRegisterDto';
    import { DispensaryRegisterDto } from '@/model/Register/Dispensary/DispensaryRegisterDto';
    import { PregnancyRegisterDto } from '@/model/Register/Pregnancy/PregnancyRegisterDto';
    import { PreventiveRegisterDto } from '@/model/Register/Preventive/PreventiveRegisterDto';

    @Component
    export default class RegulationDialog extends Vue {
        @Prop({ type: Object })
        private dispensaryRegister!: DispensaryRegisterDto;

        @Prop({ type: Object })
        private pregnancyRegister!: PregnancyRegisterDto;

        @Prop({ type: Object })
        private childrenRegister!: ChildrenHealthRegisterDto;

        @Prop({ type: Object })
        private preventiveRegister!: PreventiveRegisterDto;
    }
</script>
