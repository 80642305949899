import { plainToInstance } from 'class-transformer';

import { ImmunizationReportCommand } from '@/model/Patient/Immunization/Report/ImmunizationReportCommand';
import { ImmunizationReportDto } from '@/model/Patient/Immunization/Report/ImmunizationReportDto';
import { SubjectToImmunizationDto } from '@/model/Query/Patient/SubjectToImmunization/SubjectToImmunizationDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const immunizationReportService = {
    async getImmunizationReport(command: ImmunizationReportCommand): Promise<ImmunizationReportDto[]> {
        const response = await httpService.post<ImmunizationReportDto[]>(
            '/ImmunizationReport/GetImmunizationReport',
            command
        );
        return plainToInstance(ImmunizationReportDto, response?.data);
    },

    exportImmunizationReport(command: ImmunizationReportCommand): Promise<void> {
        return httpService.download('/ImmunizationReport/ExportExcel', { params: command });
    },

    async getSubjectToImmunization(command: ImmunizationReportCommand): Promise<SubjectToImmunizationDto[]> {
        const response = await httpService.post<SubjectToImmunizationDto[]>(
            '/ImmunizationReport/GetSubjectToImmunization',
            command
        );
        return plainToInstance(SubjectToImmunizationDto, response?.data);
    },

    exportSubjectToImmunization(command: ImmunizationReportCommand): Promise<void> {
        return httpService.download('/ImmunizationReport/GetSubjectToImmunizationExportExcel', { params: command });
    }
};
