import { plainToInstance } from 'class-transformer';

import { PrimaryExamDropdownDto } from '@/model/Exam/PrimaryExam/PrimaryExamDropdownDto';
import { PrimaryExamFilterCommand } from '@/model/Exam/PrimaryExam/PrimaryExamFilterCommand';
import { httpService } from '@/service/Infrastructure/HttpService';
import { userContextCache } from '@/store/User/UserContextCache';

export const primaryExamService = {
    async getPrimaryExams(patientId: number, examDate: Date): Promise<PrimaryExamDropdownDto[]> {
        const filter = new PrimaryExamFilterCommand(patientId, examDate);
        filter.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
        const response = await httpService.post<PrimaryExamDropdownDto[]>('/PrimaryExam/GetPrimaryExams', filter);
        return plainToInstance(PrimaryExamDropdownDto, response.data);
    }
};
