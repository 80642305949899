<template>
    <v-form ref="form" v-model="isFormValid">
        <v-card>
            <v-card-title>{{ bookDiagnosisId ? 'Промяна' : 'Добавяне' }} на диагноза</v-card-title>
            <v-card-actions>
                <btn action="Back" :to="backRoute" />
                <btn v-if="bookDiagnosisId" action="Delete" @click="updateDiagnosis">Отписване</btn>
                <btn v-else action="Save" @click="updateDiagnosis" />
            </v-card-actions>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3"><icd-picker v-model="value.icd" required /></v-col>
                    <v-col cols="12" md="3">
                        <date-picker v-model="value.validFrom" label="Дата на записване" required />
                    </v-col>
                    <v-col v-if="bookDiagnosisId" cols="12" md="3">
                        <date-picker v-model="value.validTo" label="Дата на отписване" required />
                    </v-col>
                </v-row>
                <v-col cols="12">
                    <v-textarea v-model="value.changeReason" dense label="Бележка" />
                </v-col>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import { NhifDiagnosisRequestDto } from '@/model/Nhif/NhifDiagnosisRequestDto';
    import { NhifPrescriptionBookDiagnosisDto } from '@/model/Nhif/NhifPrescriptionBookDiagnosisDto';
    import { prescriptionBookService } from '@/service/Nhif/PrescriptionBookService';
    import { signXmlService } from '@/service/Report/SignXmlService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { IcdPicker }
    })
    export default class NhifPrescriptionBookDiagnosisEditor extends Vue {
        @Prop()
        private bookDiagnosisId!: number;

        @Prop()
        private id!: number;

        private value: NhifPrescriptionBookDiagnosisDto = new NhifPrescriptionBookDiagnosisDto();
        private isFormValid: boolean = false;

        private get backRoute() {
            return `/Patient/NhifPrescriptionBook/${currentPatientCache.value.key.patientId}`;
        }

        private async getDiagnosisChangeXml(): Promise<string> {
            let result = '';
            const request = new NhifDiagnosisRequestDto(
                currentPatientCache.value.key,
                userContextCache.current?.doctorUin ?? null,
                userContextCache.current?.deputyDoctorUin ?? null,
                this.value
            );
            const response = await prescriptionBookService.getDiagnosisUpdateRequest(request);
            if (response?.data) {
                result = response.data;
            }
            console.log(response);
            return result;
        }

        private async updateDiagnosis() {
            this.$loading.show();
            try {
                (this.$refs.form as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    const xmlString = await this.getDiagnosisChangeXml();
                    const signResponse = await signXmlService.signXml(xmlString);
                    if (!signResponse.isError && signResponse.contents) {
                        const result = await prescriptionBookService.postDiagnosisUpdate(
                            signResponse.contents,
                            currentPatientCache.value.key.patientId
                        );
                        if (result?.error?.length > 0) {
                            this.$notifier.showWarning('Внимание', `${result.error}`);
                        } else {
                            //Този метод е добавен, защото при деактивиране на МКБ от ИО не връщат никаква инфорция освен дали е било успешно действието или не
                            //Понеже няма как да разберем коя диагноза е деактивирана от отговора, за да деактивираме мкб-то в базата използваме този метод
                            await this.deactivateIcd();
                            await this.$router.push(
                                `/Patient/NhifPrescriptionBook/${currentPatientCache.value.key.patientId}`
                            );
                        }
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deactivateIcd() {
            if (this.bookDiagnosisId) {
                await prescriptionBookService.deactivateIcd(this.bookDiagnosisId);
            }
        }

        private async load() {
            this.$loading.show();
            try {
                const response = await prescriptionBookService.getDiagnosisById(this.bookDiagnosisId);
                if (response?.data) {
                    this.value = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            if (this.bookDiagnosisId) {
                await this.load();
                await currentPatientCache.loadLastVersion(this.value.patientId);
            }
        }
    }
</script>
