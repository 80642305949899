import { AxiosResponse } from 'axios';

import { NhifContractTypeDto } from '@/model/Nomenclature/NhifContractTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhifContractTypeService = {
    getNhifContractTypes(): Promise<AxiosResponse<NhifContractTypeDto[]>> {
        return httpService.get('/NhifContractType/GetNhifContractTypes');
    }
};
