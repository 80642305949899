<template>
    <v-card-text>
        <v-row v-for="(invoiceLine, $index) of value.invoiceLines" :key="$index">
            <v-col cols="1">
                <span>{{ `${$index + 1}` }}</span>
            </v-col>
            <v-col v-if="!invoiceLine.description" md="2" class="py-0">
                <dropdown
                    v-model="invoiceLine.serviceOfferKey.tariffSeqNumber"
                    label="Тарифа"
                    :items="tariffs"
                    item-value="seqNumber"
                    required
                    @input="tariffChanged(invoiceLine)"
                ></dropdown>
            </v-col>
            <v-col v-if="invoiceLine.serviceOffersForDropdown && !invoiceLine.description" md="4" class="py-0">
                <dropdown
                    v-model="invoiceLine.serviceOfferKey"
                    label="Услуга"
                    :items="invoiceLine.serviceOffersForDropdown"
                    item-value="key"
                    required
                    @input="setPatientPrice(invoiceLine.serviceOfferKey, $index)"
                ></dropdown>
            </v-col>
            <v-col v-if="invoiceLine.description" cols="6" class="py-0">
                <text-field v-model="invoiceLine.description" label="Услуга" required></text-field>
            </v-col>
            <v-col cols="1" class="py-0">
                <text-field
                    v-model="invoiceLine.unitPrice"
                    label="Ед. цена"
                    type="number"
                    required
                    @input="calculateLineTotalPrice($index)"
                ></text-field>
            </v-col>
            <v-col cols="1" class="py-0">
                <text-field
                    v-model="invoiceLine.quantity"
                    label="Количество"
                    type="number"
                    required
                    @input="calculateLineTotalPrice($index)"
                ></text-field>
            </v-col>
            <v-col cols="1" class="py-0">
                <dropdown
                    v-model="invoiceLine.invoiceUnitCode"
                    label="М.е."
                    :items="invoiceUnits"
                    item-value="code"
                    required
                ></dropdown>
            </v-col>
            <v-col cols="1" class="py-0">
                <text-field v-model="invoiceLine.totalPrice" label="Цена" type="number" readonly></text-field>
            </v-col>
            <v-col cols="1" class="py-0">
                <btn icon action="Delete" @click="removeLine($index, invoiceLine)" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="9" class="text-right"> Обща сума </v-col>
            <v-col cols="3"> {{ totalPrice }} </v-col>
            <v-col cols="9" class="text-right"> ДДС {{ isVatRegister ? 20 : 0 }} % </v-col>
            <v-col cols="3"> {{ vatCharge }} </v-col>
            <v-col cols="1" class="text-left"> Словом</v-col>
            <v-col cols="5" class="text-left"> {{ priceToWord }} </v-col>
            <v-col cols="3" class="text-right"> Обща дължима сума (Обща сума + ДДС)</v-col>
            <v-col cols="3"> {{ vatCharge + totalPrice }} </v-col>
        </v-row>
        <v-row> </v-row>
        <v-row>
            <v-col>
                <btn action="New" @click="addNewInvoiceLine">Добавяне на ред</btn>
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { InvoiceUnitTypeCode } from '@/enum/Nomenclature/InvoiceUnitTypeCode';
    import { InvoiceDto } from '@/model/Finance/Invoice/InvoiceDto';
    import { InvoiceLineDto } from '@/model/Finance/Invoice/InvoiceLineDto';
    import { InvoiceUnitDto } from '@/model/Nomenclature/InvoiceUnitDto';
    import { ServiceOfferKey } from '@/model/Practice/Service/ServiceOfferKey';
    import { TariffDto } from '@/model/Practice/Service/TariffDto';
    import { ServiceOfferDto } from '@/model/Reception/Service/ServiceOfferDto';
    import { invoiceUnitService } from '@/service/Nomenclature/InvoiceUnitService';
    import { tariffService } from '@/service/Practice/Service/TariffService';
    import { receptionService } from '@/service/Reception/ReceptionService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import { financeUtil } from '@/util/FinanceUtil';

    @Component
    export default class InvoiceLines extends Vue {
        @Prop()
        value!: InvoiceDto;

        @Prop({ default: false })
        isVatRegister!: boolean;

        private tariffs: TariffDto[] = [];
        private serviceOffers: ServiceOfferDto[] = [];
        private invoiceUnits: InvoiceUnitDto[] = [];

        private async mounted() {
            await this.load();
        }

        private get totalPrice() {
            const sum = this.value.invoiceLines?.reduce((accumulator: number, invoiceLine: InvoiceLineDto) => {
                const totalPrice = parseFloat(invoiceLine.totalPrice?.toString() ?? '');
                return accumulator + (isNaN(totalPrice) ? 0 : totalPrice);
            }, 0);

            return sum;
        }

        private get vatCharge() {
            const round = 100;
            const vatValue = 0.2;
            const priceRounded = Math.round((this.totalPrice ?? 0) * vatValue * round) / round;
            return this.isVatRegister ? priceRounded : 0;
        }

        private get priceToWord() {
            const price = this.vatCharge + this.totalPrice;
            return financeUtil.numberToWordWithDecimal(price.toString());
        }

        private addNewInvoiceLine() {
            const line = new InvoiceLineDto();
            line.quantity = 1;
            line.invoiceUnitCode = InvoiceUnitTypeCode.Piece;
            this.value.invoiceLines.push(line);
        }

        private removeLine(index: number, invoiceLine: InvoiceLineDto) {
            if (invoiceLine.id > 0) {
                this.value.linesToDelete.push(invoiceLine.id);
            }

            arrayUtil.removeAt(this.value.invoiceLines, index);
        }

        private calculateLineTotalPrice(index: number) {
            this.value.invoiceLines[index].totalPrice =
                (this.value.invoiceLines[index].unitPrice ?? 0) * (this.value.invoiceLines[index].quantity ?? 0);
        }

        private setPatientPrice(serviceOfferKey: ServiceOfferKey, index: number) {
            const serviceOffer = this.serviceOffers.find((offer) => offer.key.code === serviceOfferKey.code);
            this.value.invoiceLines[index].unitPrice = serviceOffer?.patientPrice ?? 0;
            this.value.invoiceLines[index].description = serviceOffer?.description ?? '';
            this.calculateLineTotalPrice(index);
        }

        private tariffChanged(invoiceLine: InvoiceLineDto) {
            invoiceLine.serviceOffersForDropdown = this.serviceOffers.filter(
                (offer) => offer.key.tariffSeqNumber === invoiceLine.serviceOfferKey.tariffSeqNumber
            );
        }

        private async getTariffs() {
            this.tariffs = await tariffService.getPracticeTariffs(userContextCache.currentPracticeId ?? 0);
        }

        private async getServicesForPractice() {
            this.serviceOffers = await receptionService.getPracticeServiceOffers(
                userContextCache.currentPracticeId ?? 0
            );
        }

        private async getInvoiceUnits() {
            this.invoiceUnits = await invoiceUnitService.getInvoiceUnits();
        }

        private async load() {
            this.$loading.show();
            try {
                await this.getTariffs();
                await this.getServicesForPractice();
                await this.getInvoiceUnits();
            } finally {
                this.$loading.hide();
            }
        }

        @Watch('value')
        onValueChange(val: InvoiceDto) {
            if (val?.invoiceLines?.length === 0) {
                this.addNewInvoiceLine();
            }
        }
    }
</script>

<style scoped></style>
