import { plainToInstance } from 'class-transformer';

import { InvoiceUnitDto } from '@/model/Nomenclature/InvoiceUnitDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const invoiceUnitService = {
    async getInvoiceUnits(): Promise<InvoiceUnitDto[]> {
        const response = await httpService.get<InvoiceUnitDto[]>('/InvoiceUnit/GetInvoiceUnits');
        return plainToInstance(InvoiceUnitDto, response?.data);
    }
};
