import { IcdDto } from '@/model/Nomenclature/IcdDto';

export class ReferralDispensaryObservationDto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();
    public number: number = 1;
    public ambulatorySheetNumber: number = 1;
    public diagnosis1Id: number | null = null;
    public diagnosis2Id: number | null = null;
    public diagnosis3Id: number | null = null;
    public referralSubtypeId: number | null = null;
    public diagnosesText: string = '';
    public attachedDocs: string = '';
    public icd1: IcdDto = new IcdDto();
    public icd1Add: IcdDto = new IcdDto();
    public icd2: IcdDto = new IcdDto();
    public icd2Add: IcdDto = new IcdDto();
    public icd3: IcdDto = new IcdDto();
    public icd3Add: IcdDto = new IcdDto();
}
