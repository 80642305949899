import { Vue } from 'vue-property-decorator';

import { userContextCache } from '@/store/User/UserContextCache';

// Всички view-та за администриране на служители наследяват този базов клас.
export class EmployeeBase extends Vue {
    // 1. Потребител към собственик/админ може да редактира всичко за всички служители, дори практиката да няма лиценз.
    //    Така, админ може да де/активира лиценза чрез смяна на броя и вида на служителите.
    // 2. Потребител към обикновен служител (не-админ) може да редактира служителя си и да одобрява други потребители,
    //    ако практиката има лиценз, и ако е избран* UserContext директно за служителя (без заместващ/нает или мед. сестра).
    protected allowWrite(employeeSeqNumber: number) {
        const { current } = userContextCache;
        return (
            this.isGlobalAdminOrPracticeOwner ||
            // По принцип, не е нужно UserContext-ът за редактирания служител да е *избран*, достатъчно е да *съществува*,
            // но така по-лесно се тества client-side security-то в екраните на тема "служители".
            (this.$allowWrite && current && current.isPrimary && current.employeeSeqNumber === employeeSeqNumber)
        );
    }

    // Този getter може да бъде static, но не трябва, защото се bind-ва в template-ите на Vue компонените, които наследяват този клас.
    // eslint-disable-next-line class-methods-use-this
    protected get isGlobalAdminOrPracticeOwner() {
        return userContextCache.currentIsGlobalAdminOrPracticeOwner;
    }
}
