<template>
    <ExamLayout
        :can-delete="document.id > 0 && !disableEdit"
        :can-save="!disableEdit && !disableSave"
        :can-print="document.id > 0"
        title="Медицинско направление за ТЕЛК - Приложение № 8 към чл. 23, ап. 2"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <alert :value="document.workplaceId === null" type="warning"> Моля попълнете данни за месторабота </alert>
        <nhis-referral
            v-if="showNhisReferral"
            v-model="document.nhisReferral"
            class="my-2"
            :referral-id="document.id"
            :form-type="'ReferralTelk'"
            @input="copyObject"
        />
        <v-card class="mb-md-4">
            <main-title>Медицинско направление за ТЕЛК - Приложение № 8 към чл. 23, ап. 2</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="3"><text-field v-model="document.number" label="Номер" /></v-col>

                    <v-col cols="12" md="3">
                        <date-picker v-model="document.issueDate" label="Дата" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-col cols="12" md="8">
                <v-select
                    v-model="document.referralTelkSubtypeId"
                    class="required"
                    :rules="[$validator.required]"
                    :items="referralSubtypes"
                    item-text="name"
                    item-value="id"
                    label="Изпраща се на ТЕЛК за"
                />
            </v-col>
        </v-card>

        <v-card class="mb-md-4">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <h3>
                            Личен лекар (лекар по дентална медицина) , а за лицата, които нямат личен лекар - лекуващият
                            лекар (лекар по дентална медицина)
                        </h3>
                    </v-col>

                    <v-col cols="12" md="6">
                        <text-field
                            v-model="currentVisit.doctorUin"
                            disabled
                            label="УИН на лекар/ЛПК на лекаря по дентална медицина"
                        />

                        <text-field
                            v-model="currentPracticeNumber"
                            disabled
                            label="Рег. номер на лечебното заведение"
                        />
                    </v-col>
                </v-row>
                <text-field v-model="currentVisit.doctorNames" disabled label="Име и фамилия на лекаря" />
                <text-field v-model="document.nameOfHospital" disabled label="Име на лечебното заведение" />
                <v-row>
                    <v-col cols="12" md="9">
                        <text-field
                            v-model="document.addressOfHospital"
                            disabled
                            label="Адрес на лечебното заведение (област, община, град, ул., №)"
                        />
                    </v-col>

                    <v-col cols="6" md="3">
                        <text-field v-model="document.phoneNumber" disabled label="телефон" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mb-md-4">
            <v-card-title>
                <v-row>
                    <v-col cols="12" md="8">Освидетелствано лице</v-col>
                    <v-col cols="4" offset="3">
                        <dropdown
                            v-model="currentPatient.personIdentification.genderCode"
                            :items="genders"
                            item-value="code"
                            disabled
                            label="Пол м/ж"
                        />
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3"><text-field v-model="egn" disabled label="ЕГН(ЛНЧ) на пациента" /></v-col>

                    <v-col cols="12" md="6">
                        <text-field v-model="foreignIdentifier" disabled label="Идентификационен номер" />
                    </v-col>

                    <!-- TODO: Да се изписва кодът на държавата вместо id-то.
                        Може с dropdown върху кеширана номенкл, но по-добре в кеширания пациент да ги има тези неща.
                    <v-col cols="12" md="3">
                        <dropdown
                            v-model="personIdentification.countryId"
                            class="required"
                            :items="countries"
                            label="Код държава"
                            :disabled="disabled"
                            required
                        />
                    </v-col>-->
                    <v-col cols="12" md="3">
                        <dropdown
                            v-model="currentPatient.personIdentification.countryId"
                            :items="countries"
                            item-value="id"
                            label="Държава"
                            disabled
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="1">
                        <v-checkbox v-model="isLnch" label="ЛНЧ" disabled></v-checkbox>
                    </v-col>

                    <v-col cols="6" md="3">
                        <date-picker
                            v-model="currentPatient.personIdentification.birthDate"
                            disabled
                            label="дата на раждане"
                        />
                    </v-col>

                    <v-col cols="6" md="2">
                        <v-checkbox v-model="isSsn" label="ССН" disabled></v-checkbox>
                    </v-col>

                    <v-col cols="6">
                        <v-checkbox
                            v-model="isForeigner"
                            label="гражданин на ЕС (или по двустранни спогодби)"
                            disabled
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field
                            disabled
                            label="име, презиме и фамилия по лична карта"
                            :value="`${currentPatient.firstName} ${currentPatient.middleName} ${currentPatient.lastName}`"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <!-- TODO: Да пазим ли данните за лична карта в localStorage? -->
                    <v-col cols="6" md="4">
                        <text-field v-model="currentPatient.idCardNumber" disabled label="Лична карта №" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <date-picker v-model="currentPatient.idCardIssueDate" disabled label="дата" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <text-field v-model="currentPatient.contact.email" disabled label="Електронен адрес (e-mail)" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3"><CityPicker v-model="document.currentAddressCityId" required /></v-col>
                    <v-col cols="12" md="9"><text-field v-model="document.currentAddress" label="Адрес" /></v-col>
                </v-row>
                <v-row>
                    <v-col md="3" cols="12">
                        <dropdown
                            v-model="document.addressTypeCode"
                            :items="addressTypes"
                            item-value="code"
                            item-text="name"
                            required
                            label="Tип адрес на пациента за насочване към РКМЕ"
                        ></dropdown>
                    </v-col>
                    <v-col md="3" cols="12">
                        <v-checkbox
                            v-model="document.isHomeVisitNecessary"
                            label="Нужда от домашно посещение"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-checkbox v-model="isItWorking" label="Лицето е в трудовоправни отношения" disabled></v-checkbox>
            </v-card-text>
            <v-card-title>Месторабота (за лица на и над 16 годишна възраст)</v-card-title>
            <v-card-text>
                <v-row class="d-flex justify-space-between">
                    <v-col>
                        <dropdown
                            v-model="document.workplaceId"
                            :items="patientWorkplaces"
                            :item-text="workplacePosition"
                            item-value="id"
                            label="Избор на месторабота"
                            clearable
                            @input="checkWorkplaceValidity"
                        />
                    </v-col>
                    <v-col cols="auto">
                        <btn icon action="Edit" @click="openDialog" />
                    </v-col>
                    <v-dialog v-model="workplaceDialogIsVisible" persistent>
                        <v-card>
                            <v-card-title class="pa-0">
                                <v-toolbar flat>
                                    <v-toolbar-title>Избор на Месторабота</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <btn icon action="Close" @click="closeDialog" />
                                </v-toolbar>
                            </v-card-title>
                            <patient-workplace :id="currentPatient.key.patientId" @change="updateWorkplaces" />
                        </v-card>
                    </v-dialog>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field
                            disabled
                            label="Месторабота (за лица на и над 16 годишна възраст)"
                            :value="workingAddress"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mb-md-4">
            <v-card-title>Диагнози на водещото заболяване</v-card-title>
            <v-card-text>
                <v-row v-for="(diagnosis, index) in diagnoses" :key="index">
                    <v-col cols="12" class="d-flex flex-row">
                        <exam-diagnosis-picker v-model="diagnoses[index]" required />
                        <btn v-if="canRemove" icon action="Delete" @click="removeDiagnosis(index)" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <btn v-if="canAdd" action="New" block @click="addDiagnosis"> Добавяне на диагноза </btn>
            </v-card-actions>
        </v-card>

        <v-card class="mb-md-4">
            <v-card-text>
                <v-row>
                    <v-col cols="12"><v-textarea v-model="document.symptoms" label="Анамнеза" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"><v-textarea v-model="document.condition" label="Обективно състояние" /></v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!-- TODO: Защо тези еднакви 5 реда са copy-paste а не се направи 1 компонент и 1 v-for? -->
        <DocumentCard :value="document.documentCards" />
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import Dropdown from '@/component/Dropdown/Dropdown.vue';
    import ExamDiagnosisPicker from '@/component/Exam/ExamDiagnosisPicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import CityPicker from '@/component/Picker/CityPicker.vue';
    import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';
    import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';
    import { ReferralTelkDiagnosisDto } from '@/model/Exam/Referral/ReferralTelkDiagnosisDto';
    import { ReferralTelkDto } from '@/model/Exam/Referral/ReferralTelkDto';
    import { AddressTypeDto } from '@/model/Nomenclature/AddressTypeDto';
    import { CountryDto } from '@/model/Nomenclature/CountryDto';
    import { ReferralSubtypeDto } from '@/model/Nomenclature/ReferralSubtypeDto';
    import { PatientWorkplaceDto } from '@/model/Patient/PatientWorkplaceDto';
    import { referralTelkService } from '@/service/Exam/Referral/ReferralTelkService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { addressTypeService } from '@/service/Nomenclature/AddressTypeService';
    import { countryService } from '@/service/Nomenclature/CountryService';
    import { referralSubtypeService } from '@/service/Nomenclature/ReferralSubtypeService';
    import { patientWorkplaceService } from '@/service/Patient/PatientWorkplaceService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { genderCache } from '@/store/Nomenclature/GenderCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import NhisReferral from '@/view/Exam/Referral/NhisReferral.vue';
    import DocumentCard from '@/view/Exam/Referral/ReferralTelk/ReferralTelkDocumentCards.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';
    import PatientWorkplace from '@/view/Patient/PatientEdit/PatientWorkplaceView.vue';

    @Component({
        components: {
            CityPicker,
            Dropdown,
            PatientWorkplace,
            NhisReferral,
            UnsavedChanges,
            ExamDiagnosisPicker,
            ExamLayout,
            DocumentCard
        },
        async beforeRouteLeave(to, from, next) {
            if (this.$data.leaveDialogRef) {
                await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
            } else {
                next();
            }
        }
    })
    export default class ReferralTelk extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private referralSubtypes: ReferralSubtypeDto[] = [];
        private document: ReferralTelkDto = new ReferralTelkDto();
        private initialValue: ReferralTelkDto = new ReferralTelkDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private patientWorkplaces: PatientWorkplaceDto[] = [];
        private addressTypes: AddressTypeDto[] = [];
        private disableSave: boolean = false;
        private workplaceDialogIsVisible: boolean = false;
        private countries: CountryDto[] = [];
        private diagnoses: ExamDiagnosisDto[] = [];

        private addDiagnosis() {
            this.diagnoses.push(new ExamDiagnosisDto());
        }

        private removeDiagnosis(index: number) {
            arrayUtil.removeAt(this.diagnoses, index);
        }

        private get canAdd() {
            const maxDiagnosesCount = 8;
            return this.diagnoses.length < maxDiagnosesCount;
        }

        private get canRemove() {
            return this.diagnoses.length > 1;
        }

        private getExamDiagnoses() {
            if (this.document.diagnoses.length > 0) {
                for (const diag of this.document.diagnoses) {
                    this.diagnoses.push(
                        currentVisitCache.value.exam?.getExamDiagnosis(diag.examDiagnosisId ?? 0) ??
                            new ExamDiagnosisDto()
                    );
                }
            } else {
                this.diagnoses.push(new ExamDiagnosisDto());
            }
        }

        private convertToReferralDiagnoses() {
            this.document.diagnoses = [];
            let position = 1;
            for (const diag of this.diagnoses) {
                const referralDiagnosis = new ReferralTelkDiagnosisDto();
                referralDiagnosis.referralTelkId = this.document.id;
                referralDiagnosis.examDiagnosisId = diag.id;
                referralDiagnosis.examId = this.document.examId;
                referralDiagnosis.position = position;
                this.document.diagnoses.push(referralDiagnosis);
                position++;
            }
        }

        private async updateWorkplaces(workplaces: PatientWorkplaceDto[]) {
            this.patientWorkplaces = workplaces.filter((workplace) => workplace.isDeleted === false);
            this.workplaceDialogIsVisible = false;
            if (this.document.workplaceId) {
                await this.checkWorkplaceValidity();
            }
        }

        private get showNhisReferral() {
            return this.document.id > 0 && this.document.nhisReferral;
        }

        private get disableEdit() {
            return this.document?.nhisReferral?.nrn?.length > 0;
        }

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get currentVisit() {
            return currentVisitCache.value;
        }

        private get currentPracticeNumber() {
            return userContextCache.current?.practiceNumber;
        }

        private get genders() {
            return genderCache.items;
        }

        private get egn() {
            return this.isEgn ? currentPatientCache.value.personIdentification.identifier : '';
        }

        private get foreignIdentifier() {
            return !this.isEgn ? currentPatientCache.value.personIdentification.identifier : '';
        }

        private get isEgn() {
            return currentPatientCache.value.personIdentification.pidTypeCode === PidTypeCode.Egn;
        }

        private get isLnch() {
            return currentPatientCache.value.personIdentification.pidTypeCode === PidTypeCode.Lnch;
        }

        private get isSsn() {
            return currentPatientCache.value.personIdentification.pidTypeCode === PidTypeCode.Ssn;
        }

        private get isForeigner() {
            return currentPatientCache.value.personIdentification.pidTypeCode === PidTypeCode.Foreigner;
        }

        private get isItWorking() {
            return Boolean(this.document.workplaceId);
        }

        private closeDialog() {
            this.workplaceDialogIsVisible = false;
        }

        private openDialog() {
            this.workplaceDialogIsVisible = true;
        }

        private async getCountries() {
            const response = await countryService.getCountries();
            this.countries = response.data;
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                await this.getreferralTelkSubtypes();
                await this.getCountries();
                await this.getPatientWorkplaces();
                await this.getAddressTypes();

                if (!this.documentId) {
                    this.document = await referralTelkService.getReferralInfoByExamId(this.examId);
                    this.createAddress();
                } else {
                    const responseReferral = await referralTelkService.getReferralTelk(this.documentId);
                    this.document = responseReferral.data;
                    await currentVisitCache.load(this.document.examId);
                }
                this.getExamDiagnoses();
            } finally {
                this.$loading.hide();
            }
        }

        private async checkWorkplaceValidity() {
            if (this.document.workplaceId) {
                const response = await patientWorkplaceService.checkWorkplaceValidity(this.document.workplaceId);
                if (response && response !== '') {
                    this.$notifier.showWarning('Внимание', response);
                    return (this.disableSave = true);
                }
                return (this.disableSave = false);
            }
            return (this.disableSave = false);
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async getreferralTelkSubtypes() {
            const response = await referralSubtypeService.getReferralTelkSubtypes();
            this.referralSubtypes = response.data;
        }

        private async getAddressTypes() {
            this.addressTypes = await addressTypeService.getAddressTypes();
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                this.convertToReferralDiagnoses();
                if (documentId === 0) {
                    const refResponse = await referralTelkService.createReferralTelk(this.document);
                    documentId = Number(refResponse.data);
                    this.copyObject();
                    eventBus.$emit(
                        'create-referral-event',
                        documentId,
                        'ReferralTelk',
                        `Направление Телк - №${this.document.number}`
                    );
                    this.$router.push(`/Exam/referralTelk/Edit/${documentId}`);
                } else {
                    await referralTelkService.updateReferralTelk(this.document);
                    eventBus.$emit('update-referral-event', `Направление Телк - №${this.document.number}`);
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                await referralTelkService.deleteReferralTelk(documentId);
                this.copyObject();
                eventBus.$emit(
                    'delete-referral-event',
                    documentId,
                    'ReferralTelk',
                    `Направление Телк - №${this.document.number}`
                );
                this.$router.push(`/Exam/referralTelk/Create/${currentVisitCache.value.id}`);
            } finally {
                this.$loading.hide();
            }
        }

        private createAddress(): void {
            if (!currentPatientCache.isLoaded) {
                return;
            }
            const patient = currentPatientCache.value;
            this.document.currentAddressCityId = patient.address.cityId;

            const formedAddress: string = `${
                patient.address?.neighborhood ? `кв. ${patient.address.neighborhood}, ` : ''
            }${patient.address?.postalCode ? `пк. ${patient.address.postalCode}, ` : ''}${
                patient.address?.street
                    ? `ул. ${patient.address.street}${
                          patient.address?.streetNumber ? ` №. ${patient.address.streetNumber}` : ''
                      }, `
                    : ''
            }${patient.address?.block ? `бл. ${patient.address.block}, ` : ''}${
                patient.address?.entrance ? `вход: ${patient.address.entrance}, ` : ''
            }${patient.address?.floor ? `етаж: ${patient.address.floor}, ` : ''}${
                patient.address?.apartment ? `апартамент: ${patient.address.apartment}` : ''
            }`;

            this.document.currentAddress = formedAddress.trim().replace(/,\s*$/u, '');
        }

        private get workingAddress(): string {
            let workingAddress = '';
            if (this.document.workplaceId) {
                const workplace = this.patientWorkplaces.find((place) => place.id === this.document.workplaceId);
                workingAddress = `${workplace?.company ? `Фирма: ${workplace.company} ` : ''}${
                    workplace?.neighborhood ? `кв. ${workplace.neighborhood} ` : ''
                }${workplace?.street ? `ул. ${workplace.street} ` : ''}${
                    workplace?.streetNumber ? `№ ${workplace.streetNumber} ` : ''
                }${workplace?.profession ? `Професия ${workplace.profession} ` : ''}${
                    workplace?.position ? `Длъжност: ${workplace.position}` : ''
                }`;
            }

            return workingAddress;
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Referral.ReferralTelk/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Referral.ReferralTelk';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async getPatientWorkplaces() {
            this.patientWorkplaces = await patientWorkplaceService.getActivePatientWorkplaces(
                this.currentPatient.key.patientId
            );
        }

        private workplacePosition(workplace: PatientWorkplaceDto) {
            return `${workplace.company} - ${workplace.position}`;
        }
    }
</script>
