<template>
    <div>
        <span v-for="visitReason in workTimeVisitReasons" :key="visitReason.id" :class="visitReason.color">
            {{ visitReason.name }}
        </span>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { WorkTimeVisitReasonDto } from '@/model/Nomenclature/WorkTimeVisitReasonDto';

    @Component
    export default class WorkTimeVisitReasonColorsLegend extends Vue {
        @Prop({ required: true })
        private workTimeVisitReasons!: WorkTimeVisitReasonDto[];
    }
</script>
