import { plainToInstance } from 'class-transformer';

import { ImmunizationDto } from '@/model/Nomenclature/ImmunizationDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const immunizationService = {
    async getActiveImmunizations(filterByNhis: boolean): Promise<ImmunizationDto[]> {
        const response = await httpService.get<ImmunizationDto[]>(`Immunization/GetActiveImmunizations`, {
            params: {
                filterByNhis
            }
        });
        return plainToInstance(ImmunizationDto, response.data);
    }
};
