<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Искане за хистопатологично изследване"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <main-title>Искане за хистопатологично изследване</main-title>
            <v-card-text>
                <PatientInfo />
                <v-row class="mt-4">
                    <v-col cols="6" md="4">
                        <date-picker v-model="document.issueDate" label="Дата на издаване:" />
                    </v-col>
                    <v-col cols="12" md="8">
                        <text-field v-model="document.body" label="Орган:" />
                    </v-col>
                </v-row>
                <text-field v-model="document.sampleMethod" label="Метод на взимане на материала:" />
                <v-row>
                    <v-col cols="6" md="4">
                        <date-picker v-model="document.sampleDate" label="Дата на вземане на материал:" />
                    </v-col>
                    <v-col cols="12"><text-field v-model="document.fixedIn" label="Фиксиран в:" /></v-col>
                </v-row>
                <v-textarea v-model="document.clinicalData" label="Кратки клинични данни:" />
                <v-textarea v-model="document.probableDiagnose" label="Вероятна клинична диагноза:" />
                <v-row>
                    <v-col cols="6" md="4">
                        <date-picker v-model="document.previousTestDate" label="Предишни биопсични изследвания:" />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="document.previousTestMedicalCenter" label="Лечебно заведение:" />
                    </v-col>
                </v-row>
                <v-textarea v-model="document.previousTestResult" label="Резултат:" />
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { RequestForHistopathologicalTestDto } from '@/model/Exam/RequestForHistopathologicalTest/RequestForHistopathologicalTestDto';
    import { requestForHistopathologicalTestService } from '@/service/Exam/RequestForHistopathologicalTestService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: { UnsavedChanges, PatientInfo, ExamLayout },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class RequestForHistopathologicalTest extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private document: RequestForHistopathologicalTestDto = new RequestForHistopathologicalTestDto();
        private initialValue: RequestForHistopathologicalTestDto = new RequestForHistopathologicalTestDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private async mounted() {
            this.leaveDialogRef = this.leaveDialog;
            await this.load();
            this.copyObject();
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async load() {
            this.$loading.show();
            try {
                if (!this.documentId) {
                    const responseExam =
                        await requestForHistopathologicalTestService.getRequestForHistopathologicalTestInfoByExamId(
                            this.examId
                        );
                    this.document = responseExam.data;
                } else {
                    const responseReferral =
                        await requestForHistopathologicalTestService.getRequestForHistopathologicalTest(
                            this.documentId
                        );
                    this.document = responseReferral.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                const title = `Дата ${this.document.issueDate}; `;
                if (documentId === 0) {
                    const response = await requestForHistopathologicalTestService.createRequestForHistopathologicalTest(
                        this.document
                    );
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, 'RequestForHistopathologicalTest', title);

                    this.$router.push(`/Exam/RequestForHistopathologicalTest/Edit/${documentId}`);
                } else {
                    await requestForHistopathologicalTestService.updateRequestForHistopathologicalTest(this.document);
                    this.copyObject();
                    eventBus.$emit('update-referral-event', title);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            const documentId = this.document.id;
            await requestForHistopathologicalTestService.deleteRequestForHistopathologicalTest(documentId);
            this.copyObject();
            this.$router.push(`/Exam/RequestForHistopathologicalTest/Create/${currentVisitCache.value.id}`);
            eventBus.$emit(
                'delete-referral-event',
                documentId,
                'RequestForHistopathologicalTest',
                'Искане за хистопатологично изследване'
            );
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.RequestForHistopathologicalTest/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.RequestForHistopathologicalTest';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
