<template>
    <v-form ref="form" v-model="isFormValid">
        <v-card>
            <v-card-title>Приемен кабинет</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <text-field
                            v-model="practiceUnitDto.name"
                            required
                            :rules="[$validator.maxLength(100)]"
                            label="Име:"
                        />
                    </v-col>
                    <v-col cols="8">
                        <text-field
                            v-model="practiceUnitDto.location"
                            :rules="[$validator.maxLength(100)]"
                            label="Адрес:"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col>
                    <btn action="Save" @click="save" />
                </v-col>
                <v-col>
                    <v-row class="float-right">
                        <btn action="Delete" @click="deleteAdmissionUnit" />
                    </v-row>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PracticeUnitDto } from '@/model/Practice/PracticeUnit/PracticeUnitDto';
    import { practiceUnitService } from '@/service/Practice/PracticeUnit/PracticeUnitService';

    @Component
    export default class PracticeUnitAdmissionUnit extends Vue {
        @Prop({ type: Number })
        private practiceId!: number;

        @Prop({ type: Number })
        private parentSeqNumber!: number | null;

        @Prop({ type: Boolean })
        private isEdit!: boolean;

        private isFormValid: boolean = false;
        private practiceUnitDto: PracticeUnitDto = new PracticeUnitDto();

        private mounted() {
            this.loadInisialData();
        }

        private async save() {
            this.$loading.show();
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isFormValid) {
                if (this.isEdit) {
                    await practiceUnitService.updateAdmissionUnit(this.practiceUnitDto);
                } else {
                    await practiceUnitService.createAdmissionUnit(this.practiceUnitDto);
                }
                this.$emit('reload');
                this.practiceUnitDto = new PracticeUnitDto();
                this.loadInisialData();
                this.$notifier.showSuccess('Успех', 'Данните са записани успешно');
            }
            this.$loading.hide();
        }

        private async loadInisialData(): Promise<void> {
            if (this.isEdit) {
                const result = this.parentSeqNumber
                    ? (await practiceUnitService.getAdmissionUnit(this.practiceId, this.parentSeqNumber)).data
                    : null;
                if (result) {
                    this.practiceUnitDto = result;
                }
            } else {
                this.practiceUnitDto.practiceId = this.practiceId;
                this.practiceUnitDto.parentSeqNumber = this.parentSeqNumber;
            }
        }

        private deleteAdmissionUnit() {
            this.$emit('deleteUnit');
        }
    }
</script>
