export abstract class Report365RowBase implements Record<string, unknown> {
    public code: string = '';
    public name: string = '';
    public parentCode: string = '';
    public isSection: boolean = false;

    // Report365\DataTableComponent.vue чете по име произволни property-та от всички наследници на този клас.
    // Единият вариант е в самия компонент да се ползва (item as Record<string, unknown>)[...],
    // другият вариант е тази "функционалност" на item да се заложи в самия (този) базов клас.
    [key: string]: unknown;
}
