import { AxiosResponse } from 'axios';

import { ChildrenHealthDeregisterReasonDto } from '@/model/Nomenclature/ChildrenHealthDeregisterReasonDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const childrenHealthDeregisterReasonService = {
    getChildrenHealthDeregisterReasons(): Promise<AxiosResponse<ChildrenHealthDeregisterReasonDto[]>> {
        return httpService.get('/ChildrenHealthDeregisterReason/GetChildrenHealthDeregisterReasons');
    }
};
