import { validatorService } from '@/service/Infrastructure/ValidatorService';

import { UserExistanceDto } from './UserExistanceDto';

export class UserSearchModel {
    constructor(public searchFunction: () => Promise<UserExistanceDto>) {}

    public emailOrUserName: string = '';
    public result: UserExistanceDto | null = null;

    public get isValidEmail() {
        return validatorService.email(this.emailOrUserName) === true;
    }
}
