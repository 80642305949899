<template>
    <v-card>
        <v-card-title>Звена на лечебното заведение</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="4">
                    <search-field v-model="searchString" label="Търсене" />
                    <hr />
                    <v-treeview
                        :items="newItems"
                        :search="searchString"
                        :open="filteredKeys"
                        activatable
                        item-text="name"
                        item-key="seqNumber"
                        dense
                        hoverable
                        style="padding-bottom: 50px; padding-top: 20px; cursor: pointer"
                        @update:active="getActiveValue"
                    >
                        <template #prepend="{ item }">
                            <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                        </template>
                    </v-treeview>
                </v-col>
                <v-col cols="8" style="padding-top: 25px">
                    <v-row>
                        <v-col cols="4">
                            <v-menu offset-y>
                                <template #activator="{ on, attrs }">
                                    <btn action="New" v-bind="attrs" v-on="on">Добавяне на звено</btn>
                                </template>
                                <v-list dense>
                                    <v-list-item-group color="primary" :value="selectedType" @change="changedUnitType">
                                        <v-list-item v-for="(btnItem, index) in btnItems" :key="index">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ btnItem }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-container fluid>
                            <practice-unit-laboratory
                                v-if="(selectedType == 'Лаборатория') | selectedPracticeNode.isLaboratory"
                                :key="selectedUnitSeqNumber"
                                :selected-unit-type="selectedType"
                                :practice-id="practiceId"
                                :parent-seq-number="parentUnitSeqNumber"
                                :is-edit="isEdit"
                                style="width: 100%"
                                @reload="load"
                                @deleteUnit="deletePracticeUnit"
                            ></practice-unit-laboratory>
                            <practice-unit-ward
                                v-if="(selectedType == 'Болнично звено') | selectedPracticeNode.isWard"
                                :key="selectedUnitSeqNumber"
                                :selected-unit-type="selectedType"
                                :practice-id="practiceId"
                                :parent-seq-number="parentUnitSeqNumber"
                                :is-edit="isEdit"
                                style="width: 100%"
                                @reload="load"
                                @deleteUnit="deletePracticeUnit"
                            ></practice-unit-ward>
                            <practice-unit-admission-unit
                                v-if="(selectedType == 'Приемен кабинет') | selectedPracticeNode.isAdmissionUnit"
                                :key="selectedUnitSeqNumber"
                                :selected-unit-type="selectedType"
                                :practice-id="practiceId"
                                :parent-seq-number="parentUnitSeqNumber"
                                :is-edit="isEdit"
                                style="width: 100%"
                                @reload="load"
                                @deleteUnit="deletePracticeUnit"
                            ></practice-unit-admission-unit>
                        </v-container>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PracticeUnitDto } from '@/model/Practice/PracticeUnit/PracticeUnitDto';
    import { practiceUnitService } from '@/service/Practice/PracticeUnit/PracticeUnitService';
    import PracticeUnitAdmissionUnit from '@/view/Practice/PracticeUnit/PracticeUnitAdmissionUnit.vue';
    import PracticeUnitLaboratory from '@/view/Practice/PracticeUnit/PracticeUnitLaboratory.vue';
    import PracticeUnitWard from '@/view/Practice/PracticeUnit/PracticeUnitWard.vue';

    @Component({
        components: { PracticeUnitLaboratory, PracticeUnitWard, PracticeUnitAdmissionUnit }
    })
    export default class PracticeUnits extends Vue {
        @Prop({ type: Number })
        private practiceId!: number;

        private newItems: PracticeUnitDto[] = [];
        private selectedUnitSeqNumber: number | null = null;
        private parentUnitSeqNumber: number | null = null;
        private selectedPracticeNode: PracticeUnitDto = new PracticeUnitDto();
        private searchString: string = '';
        private selectedType: string = '';
        private isEdit: boolean = false;

        private btnItems: string[] = [
            'Лаборатория',
            'Приемен кабинет',
            'Болнично звено',
            'Стая/Кабинет',
            'Друг вид звено'
        ];

        private get filteredElements() {
            return this.newItems.reduce((acc: PracticeUnitDto[], curr) => {
                const childrenContain = curr.children
                    ? // eslint-disable-next-line max-nested-callbacks
                      curr.children.filter((child) => {
                          const index = child.name.toLowerCase().indexOf(this.searchString) >= 0;
                          return index;
                      })
                    : [];
                if (childrenContain.length) {
                    acc.push({
                        ...curr,
                        children: [...childrenContain]
                    });
                }
                return acc;
            }, []);
        }

        private get filteredKeys() {
            return this.filteredElements.map((top) => top.name);
        }

        private mounted() {
            this.load();
        }

        private async load() {
            this.newItems = (await practiceUnitService.getAllPracticeUnits(this.practiceId)).data;
        }

        private changedUnitType(value: number) {
            this.clearSelections();
            this.isEdit = false;
            this.selectedUnitSeqNumber = 0;
            this.selectedType = this.btnItems[value];
        }

        private getActiveValue(value: number[]) {
            this.clearSelections();
            this.isEdit = value ? true : false;
            this.selectedUnitSeqNumber = value ? value[0] : null;
            this.parentUnitSeqNumber = this.selectedUnitSeqNumber;
            let selectedNode = null;

            for (const item of this.newItems) {
                selectedNode = this.getSelectedPracticeNode(item);
                if (selectedNode) {
                    break;
                }
            }

            this.selectedPracticeNode = selectedNode ?? new PracticeUnitDto();
        }

        private clearSelections() {
            this.selectedType = '';
            this.selectedPracticeNode = new PracticeUnitDto();
        }

        private getSelectedPracticeNode(practiceUnit: PracticeUnitDto): PracticeUnitDto | null {
            let result = null;

            if (practiceUnit.seqNumber === this.selectedUnitSeqNumber) {
                return practiceUnit;
            }

            if (practiceUnit.children) {
                for (let idx = 0; idx < practiceUnit.children.length; idx++) {
                    result = this.getSelectedPracticeNode(practiceUnit.children[idx]);
                    if (result !== null) {
                        break;
                    }
                }
            }

            return result;
        }

        private async deletePracticeUnit() {
            if (this.selectedPracticeNode.practiceId && this.selectedPracticeNode.seqNumber) {
                await practiceUnitService.deletePracticeUnit(
                    this.selectedPracticeNode.practiceId,
                    this.selectedPracticeNode.seqNumber
                );

                this.selectedPracticeNode = new PracticeUnitDto();
                this.parentUnitSeqNumber = null;
                this.$notifier.showSuccess('Успех', 'Данните са записани успешно');
                this.load();
            }
        }
    }
</script>
