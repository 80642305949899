<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header :style="`background-color: ${backgroundColor}`"
                >Показване на пациента и лекаря</v-expansion-panel-header
            >
            <v-expansion-panel-content :style="`background-color: ${backgroundColor}`">
                <v-row dense>
                    <v-col cols="12" md="6"><PatientInfo /></v-col>
                    <v-col cols="12" md="6"><DoctorInfo /></v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';

    @Component({
        components: {
            DoctorInfo,
            PatientInfo
        }
    })
    export default class AmbulatorySheetHeaderInfoCard extends Vue {
        @Prop({ default: 'transparent' })
        private backgroundColor!: string;
    }
</script>
