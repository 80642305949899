import { Type } from 'class-transformer';

import { SubmitCommand } from '@/model/Nhis/SubmitCommand';
import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

export class NhifPrescriptionBookSearchCommand extends SubmitCommand {
    @Type(() => PatientVersionKey)
    public patientVersionKey: PatientVersionKey;

    constructor(xmlString: string, accessTokenValue: string, patientVersionKey: PatientVersionKey) {
        super(xmlString, accessTokenValue);
        this.patientVersionKey = patientVersionKey;
    }
}
