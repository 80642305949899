export class PreviousPregnancyDto {
    public childrenBorn: number | null = null;
    public aliveChildrenCount: number | null = null;
    public stillBornChildrenCount: number | null = null;
    public lastBirthDate: Date | null = null;
    public terminal: string | null = null;
    public child: string | null = null;
    public abortsCount: number | null = null;
    public abortByRequestCount: number | null = null;
    public spontaneousAbortCount: number | null = null;
    public abortByMedicineNeedCount: number | null = null;
    public lastAbortDate: Date | null = null;
    public previousPregnancyBehaviour: string | null = null;
    public cesareanSection: string | null = null;
}
