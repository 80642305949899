import { plainToInstance } from 'class-transformer';

import { IcdDto } from '@/model/Nomenclature/IcdDto';
import { IDropdownSearchService } from '@/service/Dropdown/IDropdownSearchService';
import { httpService } from '@/service/Infrastructure/HttpService';

export const icdService: IDropdownSearchService<IcdDto> = {
    async getItemsById(icdCodes: string | string[]): Promise<IcdDto[]> {
        const response = await httpService.get<IcdDto[]>('/Icd/GetIcdsByCodes', {
            params: { icdCodes: icdCodes.toString() }
        });
        return plainToInstance(IcdDto, response.data);
    },

    async searchItems(filters: []): Promise<IcdDto[]> {
        const response = await httpService.get<IcdDto[]>('/Icd/SearchIcds', { params: { filter: filters.at(0) } });
        return plainToInstance(IcdDto, response.data);
    }
};
