<template>
    <div>
        <v-form ref="form" v-model="isFormValid">
            <v-card>
                <v-card-title>Финансов отчет по чл. 37</v-card-title>
                <v-card-text>
                    <report-date-picker v-model="filter" @input="getTableItems" />
                    <v-row>
                        <v-col cols="12" md="6">
                            <practice-nhif-contract-picker v-model="nhifContract" :practice-id="practiceId" />
                        </v-col>
                        <v-col cols="12" md="3">
                            <text-field v-model="filter.invoiceNumber" label="Към фактура номер:" />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-checkbox
                                v-model="filter.reportByPractice"
                                dense
                                label="Генериране на отчет за практика"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="canGenerate" cols="12" md="4">
                            <btn action="Next" block :disabled="isGenerating" @click="generateFiscalReport">
                                Генериране на отчет
                            </btn>
                        </v-col>
                        <v-col v-if="hasResult" cols="12" md="4">
                            <base64-to-file
                                v-model="response.report"
                                btn-name="отчет"
                                show-open-btn
                                file-name="FiscalReport"
                            ></base64-to-file>
                        </v-col>
                        <v-col v-if="hasResult" cols="12" md="4">
                            <btn action="Send" block :disabled="isGenerating" @click="sendFiscalReceiptReport">
                                Изпращане на отчет
                            </btn>
                        </v-col>
                    </v-row>
                    <template v-if="hasResult">
                        <div class="mt-4 font-weight-bold">Брой бележки в отчета: {{ response.itemsCount }}</div>
                        <v-row>
                            <v-col cols="auto">
                                <v-checkbox
                                    v-model="allowManualCountInput"
                                    dense
                                    label="Ръчно въвеждане броя пенсионери"
                                />
                            </v-col>
                            <v-col v-if="allowManualCountInput" cols="auto">
                                <text-field v-model="filter.manualPatientCount" type="number" label="Брой пенсионери" />
                            </v-col>
                        </v-row>
                    </template>
                    <div v-if="items.length > 0" class="mt-5">
                        <pis-file v-model="items"></pis-file>
                    </div>
                </v-card-text>
            </v-card>
            <SuccessDialog v-model="resultDialogIsVisible" :text="dialogText" title="Резултат от изпращане" />
        </v-form>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import Base64ToFile from '@/component/File/Base64ToFile.vue';
    import { PisUploadType } from '@/enum/LocalServer/PisUploadType';
    import { PisFileTypeCode } from '@/enum/Report/Pis/PisFileTypeCode';
    import { PracticeNhifContractDto } from '@/model/Practice/PracticeNhifContractDto';
    import { FiscalReportCommand } from '@/model/Report/Pis/FiscalReportCommand';
    import { PisFileCreateDto } from '@/model/Report/Pis/PisFile/PisFileCreateDto';
    import { PisFileFilterDto } from '@/model/Report/Pis/PisFile/PisFileFilterDto';
    import { PisFileRowDto } from '@/model/Report/Pis/PisFile/PisFileRowDto';
    import { PisFileUploadRequestDto } from '@/model/Report/Pis/PisFileUploadRequestDto';
    import { ReportResponseDto } from '@/model/Report/Pis/ReportResponseDto';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { practiceService } from '@/service/Practice/PracticeService';
    import { localServerPisService } from '@/service/Report/LocalServerPisService';
    import { pisFileService } from '@/service/Report/PisFileService';
    import { pisService } from '@/service/Report/PisService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';

    import PisFile from './PisFile.vue';
    import PracticeNhifContractPicker from './PracticeNhifContractPicker.vue';
    import SuccessDialog from './SuccessDialog.vue';

    @Component({
        components: { ReportDatePicker, PracticeNhifContractPicker, Base64ToFile, SuccessDialog, PisFile }
    })
    export default class FiscalReport extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private isFormValid: boolean = false;
        private filter: FiscalReportCommand = new FiscalReportCommand();
        private isLocalServerActive: boolean = false;
        private resultDialogIsVisible: boolean = false;
        private dialogText: string = '';
        private items: PisFileRowDto[] = [];
        private nhifContract: PracticeNhifContractDto = new PracticeNhifContractDto();
        private canGenerate: boolean = false;
        private response: ReportResponseDto = new ReportResponseDto();
        private allowManualCountInput: boolean = false;
        private isGenerating: boolean = false;
        private managerIdentifier: string | null = null;

        private get hasResult() {
            return this.response?.report?.length > 0;
        }

        private async mounted() {
            this.filter.fillPreviousMonth();
            this.filter.reportByPractice = true;
            await this.getManagerIdentifier();
            await this.getTableItems();
            await this.isFilledPracticeAndDoctor();
        }

        private async getManagerIdentifier() {
            this.managerIdentifier = await practiceService.getPracticeManagerIdentifier(this.practiceId);
        }

        private async generateFiscalReport() {
            this.isGenerating = true;
            try {
                this.$loading.hide();
                this.response = new ReportResponseDto();
                this.fillDoctorEmployee();
                const response = await pisService.generateFiscalReport(this.filter);
                if (response?.data?.report) {
                    this.response.report = response.data.report;
                    this.response.itemsCount = response.data.itemsCount;
                }
            } finally {
                this.isGenerating = false;
                this.$loading.hide();
            }
        }

        private fillDoctorEmployee() {
            this.filter.practiceId = this.practiceId;
            this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async checkServerStatus(): Promise<void> {
            const response = await localServerDiagnosticService.checkIsLocalServerActive();
            this.isLocalServerActive = response;
        }

        private async sendFiscalReceiptReport() {
            await this.checkServerStatus();
            if (this.isLocalServerActive) {
                this.$loading.show();
                const uploadRequest = new PisFileUploadRequestDto(
                    this.response.report,
                    userContextCache.current?.practiceNumber ?? '',
                    this.managerIdentifier?.toString() ?? ''
                );
                this.fillRequestData(uploadRequest);
                const response = await localServerPisService.uploadReport(
                    PisFileTypeCode.FiscalReport,
                    uploadRequest,
                    PisUploadType.FinancialReport
                );
                if (!response.isError) {
                    this.dialogText = `${response.message} Идентификаторът на документа е: ${response.additionalInformation}`;
                    this.resultDialogIsVisible = true;
                    await this.savePisFile(response.additionalInformation);
                }

                this.$loading.hide();
            }
        }

        private fillRequestData(requestData: PisFileUploadRequestDto) {
            requestData.startDate = dateUtil.formatXmlDate(this.filter.startDate);
            requestData.endDate = dateUtil.formatXmlDate(this.filter.endDate);
            // RequestData.uin = userContextCache.current?.doctorUin ?? '';
            requestData.docDate = dateUtil.formatXmlDate(this.filter.endDate);
            // RequestData.specCode = userContextCache.current?.specialtyNhifCode ?? '';
            requestData.contractNumber = this.nhifContract.contractNumber;
            requestData.contractDate = dateUtil.formatXmlDate(this.nhifContract.contractDate);
            requestData.contractType = parseInt(this.nhifContract.nhifContractTypeCode, 10);
        }

        private async savePisFile(fileId: string) {
            const file = new PisFileCreateDto();
            file.practiceId = this.practiceId;
            file.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
            file.pisFileTypeCode = PisFileTypeCode.FiscalReport;
            file.pisFileId = fileId;
            file.startDate = this.filter.startDate;
            file.endDate = this.filter.endDate;

            const item = await pisFileService.createPisFile(file);
            this.items.push(item);
            this.$loading.hide();
        }

        private async getTableItems() {
            const filter = new PisFileFilterDto();
            filter.practiceId = this.practiceId;
            filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
            filter.pisFileTypeCode = PisFileTypeCode.FiscalReport;
            filter.startDate = this.filter.startDate;
            filter.endDate = this.filter.endDate;
            if (filter.startDate && filter.endDate) {
                this.items = await pisFileService.getPisFiles(filter);
            }
        }

        private async isFilledPracticeAndDoctor() {
            const response = await pisService.isFilledPracticeAndDoctor(this.practiceId, this.doctorEmployeeSeqNumber);

            if (response?.data.length > 0) {
                this.canGenerate = false;
                this.$notifier.showWarning('Внимание', response.data);
            } else {
                this.canGenerate = true;
            }
        }

        @Watch('allowManualCountInput')
        onAllowManualCountInput(newVal: boolean) {
            if (!newVal) {
                this.filter.manualPatientCount = null;
            }
        }
    }
</script>
