import { userContextCache } from '@/store/User/UserContextCache';

import { MenuItemModel } from './MenuItemModel';

export const createVisitListMenuItems = () => {
    const { current } = userContextCache;
    if (!current) {
        return [];
    }
    const menuItems = [
        new MenuItemModel('Търсене', '', ''),
        new MenuItemModel('Търсене на посещения', 'mdi-stethoscope', '/VisitList/VisitSearch'),
        new MenuItemModel('Избор на КЕП', 'mdi-draw', `/VisitList/SelectCertificate`),
        new MenuItemModel('Регистри', '', '')
    ];

    if (current.hasReception) {
        menuItems.push(new MenuItemModel('Чакащи пациенти', 'mdi-timer-sand', '/VisitList/Queue'));
    }

    menuItems.push(new MenuItemModel('Амбулаторен журнал', 'mdi-book-open-outline', '/VisitList/Journal'));
    return menuItems;
};
