import { plainToInstance } from 'class-transformer';

import { ExternalVisitDto } from '@/model/Exam/ExternalVisit/ExternalVisitDto';
import { ExternalVisitRowDto } from '@/model/Exam/ExternalVisit/ExternalVisitRowDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const externalVisitService = {
    async getExternalVisits(patientId: number, visitId: number | null): Promise<ExternalVisitRowDto[]> {
        const response = await httpService.get<ExternalVisitRowDto[]>('ExternalVisit/GetExternalVisits', {
            params: { patientId, visitId }
        });
        return plainToInstance(ExternalVisitRowDto, response.data);
    },

    async getExternalVisit(patientId: number, seqNumber: number): Promise<ExternalVisitDto> {
        const response = await httpService.get<ExternalVisitDto>('ExternalVisit/GetExternalVisit', {
            params: { patientId, seqNumber }
        });
        return plainToInstance(ExternalVisitDto, response.data);
    },

    async patientHasCoronaryEvent(patientId: number): Promise<boolean> {
        const response = await httpService.get<boolean>('ExternalVisit/PatientHasCoronaryEvent', {
            params: { patientId }
        });
        return response.data;
    },

    async createVisitWithExternalVisit(doctorEmployeeSeqNumber: number, dto: ExternalVisitDto): Promise<number> {
        const response = await httpService.post<number>('ExternalVisit/CreateVisitWithExternalVisit', dto, {
            params: { doctorEmployeeSeqNumber }
        });
        return response.data;
    },

    async createExternalVisit(dto: ExternalVisitDto): Promise<void> {
        await httpService.post('ExternalVisit/CreateExternalVisit', dto);
    },

    async updateExternalVisit(dto: ExternalVisitDto): Promise<void> {
        await httpService.post('ExternalVisit/UpdateExternalVisit', dto);
    },

    async deleteExternalVisit(patientId: number, seqNumber: number): Promise<boolean> {
        const response = await httpService.delete<boolean>('ExternalVisit/DeleteExternalVisit', {
            params: { patientId, seqNumber }
        });
        return response.data;
    }
};
