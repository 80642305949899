export class CertificateDriveDto {
    public id: number = 0;
    public number: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();
    public licenseCategory: string = '';
    public symptoms: string = '';
    public diseases: string = '';
    public colorPerception: string = '';
    public visualAcuityL: string = '';
    public visualAcuityR: string = '';
    public ophthalmologistName: string = '';
    public ophthalmologistUin: string = '';
    public ophthalmologistDate: Date | null = null;
    public normalSpeechL: string = '';
    public normalSpeechR: string = '';
    public whisperSpeechL: string = '';
    public whisperSpeechR: string = '';
    public ungName: string = '';
    public ungUin: string = '';
    public ungDate: Date | null = null;
    public internalStatus: string = '';
    public internistName: string = '';
    public internistUin: string = '';
    public internistDate: Date | null = null;
    public neurologicalStatus: string = '';
    public neurologistName: string = '';
    public neurologistUin: string = '';
    public neurologistDate: Date | null = null;
    public surgeryStatus: string = '';
    public surgeonName: string = '';
    public surgeonUin: string = '';
    public surgeonDate: Date | null = null;
    public other1Name: string = '';
    public other1Uin: string = '';
    public other1Status: string = '';
    public other1Date: Date | null = null;
    public other2Name: string = '';
    public other2Uin: string = '';
    public other2Status: string = '';
    public other2Date: Date | null = null;
    public other3Name: string = '';
    public other3Uin: string = '';
    public other3Status: string = '';
    public other3Date: Date | null = null;
    public isTolek: boolean = false;
    public tolekDate: Date | null = null;
    public tolekUin: string = '';
    public tolekName: string = '';
    public conclusion: string = '';
    public isCompliant: boolean = true;
    public motive: string = '';
}
