<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Карта за оценка на физическата годност на водач"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card class="mb-md-4">
            <v-card-title>
                <v-row>
                    <v-col cols="6">
                        УДОСТОВЕРЕНИЕ за здравословното състояние на водач / кандидат за придобиване на правоспособност
                        за управление на МПС
                    </v-col>
                    <v-col cols="6">
                        Карта за оценка на физическата годност на водач / кандидат за придобиване на правоспособност за
                        управление на МПС
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3">
                        <text-field v-model="document.number" label="ТАЛОН ЗА МЕ №" disabled />
                    </v-col>
                    <v-col cols="6" md="3" offset-md="3">
                        <text-field
                            v-model="currentVisit.exam.ambulatorySheetNumber"
                            label="амбулаторен лист №"
                            disabled
                        />
                    </v-col>
                </v-row>
            </v-card-title>
            <hr />
            <v-card-text>
                <PatientInfo />
            </v-card-text>
            <hr />
            <v-card-text>
                <v-radio-group v-model="choice">
                    <v-radio label="ОПЛ" value="general"></v-radio>
                    <v-radio label="а) ОЧИ" value="ophtalmologist"></v-radio>
                    <v-radio label="б) УНГ" value="ung"></v-radio>
                    <v-radio label="в) Вътрешен" value="internist"></v-radio>
                    <v-radio label="г) Невролог" value="neurologist"></v-radio>
                    <v-radio label="д) Хирург" value="surgeon"></v-radio>
                    <v-radio label="Други" value="other"></v-radio>
                    <v-radio label="Карта" value="license"></v-radio>
                </v-radio-group>
                <v-row v-show="choice !== 'general' && choice !== 'other'">
                    <btn action="Fill" @click="autofillExamData">От шаблон</btn>
                </v-row>
            </v-card-text>
            <!-- Временен блок и подредба  -->
            <v-card-text v-show="choice === 'general'">
                <v-row>
                    <v-col cols="12">
                        за управление на МПС от
                        <text-field :value="document.licenseCategory" disabled label="категория" />
                    </v-col>
                </v-row>
                <v-card-title>Списък с категории за управление на МПС</v-card-title>
                <v-data-table
                    v-model="categoryChoice"
                    :headers="headers"
                    show-select
                    :single-select="false"
                    :items="categories"
                    :items-per-page="18"
                    hide-default-footer
                    item-key="value"
                    class="elevation-1"
                    @input="updateLicenseCategory"
                />
                <v-row>
                    <v-col cols="12">
                        <br />
                        <DoctorInfo row />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea v-model="document.symptoms" rows="2" label="Анамнестични данни" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea v-model="document.diseases" rows="2" label="Регистрирани заболявания" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-radio-group v-model="document.isTolek">
                            <v-radio label="НЕ подлежи" :value="false"></v-radio>
                            <v-radio label="Подлежи" :value="true"></v-radio>
                        </v-radio-group>
                        <span>на освидетелстване от ТОЛЕК</span>
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model="document.tolekName" label="Общопрактикуващ лекар" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model="document.tolekUin" label="УИН" :rules="[$validator.uin]" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <date-picker v-model="document.tolekDate" label="Дата и час" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-show="choice === 'ophtalmologist'">
                <v-row>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.visualAcuityL" label="Острота на зрението (ЛО)" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.visualAcuityR" label="Острота на зрението (ДО)" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.colorPerception" label="Цветоусещане" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" md="4">
                        <h3>Офталмолог</h3>
                    </v-col>
                    <v-col cols="3" md="4">
                        <btn action="Fill" @click="autofillSpecialistData">Попълни лекар</btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.ophthalmologistName" label="Лекар име" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.ophthalmologistUin" label="УИН" :rules="[$validator.uin]" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.ophthalmologistDate" label="Дата и час"></date-picker>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-show="choice === 'ung'">
                <v-row>
                    <v-col cols="12">
                        <h3>Разговорна реч</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <text-field v-model="document.normalSpeechL" label="Ляво ухо" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field v-model="document.normalSpeechR" label="Дясно ухо" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <h3>Шепотна реч</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <text-field v-model="document.whisperSpeechL" label="Ляво ухо" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field v-model="document.whisperSpeechR" label="Дясно ухо" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" md="4">
                        <h3>УНГ</h3>
                    </v-col>
                    <v-col cols="3" md="4">
                        <btn action="Fill" @click="autofillSpecialistData">Попълни лекар</btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.ungName" label="Лекар име" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.ungUin" label="УИН" :rules="[$validator.uin]" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.ungDate" label="Дата и час" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-show="choice === 'internist'" class="mb-md-4">
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="document.internalStatus" label="Вътрешен статус" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" md="4">
                        <h3>Интернист</h3>
                    </v-col>
                    <v-col cols="3" md="4">
                        <btn action="Fill" @click="autofillSpecialistData">Попълни лекар</btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.internistName" label="Лекар име" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.internistUin" label="УИН" :rules="[$validator.uin]" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.internistDate" label="Дата и час" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-show="choice === 'neurologist'">
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="document.neurologicalStatus" label="Неврологичен статус" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" md="4">
                        <h3>Невролог</h3>
                    </v-col>
                    <v-col cols="3" md="4">
                        <btn action="Fill" @click="autofillSpecialistData">Попълни лекар</btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.neurologistName" label="Лекар име" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.neurologistUin" label="УИН" :rules="[$validator.uin]" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.neurologistDate" label="Дата и час" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-show="choice === 'surgeon'">
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="document.surgeryStatus" label="Хирургичен статус" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" md="4">
                        <h3>Хирург</h3>
                    </v-col>
                    <v-col cols="3" md="4">
                        <btn action="Fill" @click="autofillSpecialistData">Попълни лекар</btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.surgeonName" label="Лекар име" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.surgeonUin" label="УИН" :rules="[$validator.uin]" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.surgeonDate" label="Дата и час" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-show="choice === 'other'">
                <v-row>
                    <v-col cols="4" md="4">
                        <h3>Други</h3>
                    </v-col>
                    <v-col cols="3" md="4">
                        <btn action="Fill" @click="autofillOthersData">Попълни лекар</btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <h3>е)</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="document.other1Status" label="Статус" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.other1Name" label="Лекар име" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.other1Uin" label="УИН" :rules="[$validator.uin]" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.other1Date" label="Дата и час" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <h3>ж)</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="document.other2Status" label="Статус" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.other2Name" label="Лекар име" />
                    </v-col>
                    <v-col cols="12" md="4" dense>
                        <text-field v-model="document.other2Uin" label="УИН" :rules="[$validator.uin]" />
                    </v-col>
                    <v-col cols="12" md="4" dense>
                        <date-picker v-model="document.other2Date" label="Дата и час" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <h3>з)</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="document.other3Status" label="Статус" />
                    </v-col>
                    <v-col cols="12" md="4" dense>
                        <text-field v-model="document.other3Name" label="Лекар име" />
                    </v-col>
                    <v-col cols="12" md="4" dense>
                        <text-field v-model.trim="document.other3Uin" label="УИН" :rules="[$validator.uin]" />
                    </v-col>
                    <v-col cols="6" md="4" dense>
                        <date-picker v-model="document.other3Date" label="Дата и час" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-show="choice === 'license'">
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="document.conclusion" label="Заключение" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-radio-group v-model="document.isCompliant">
                            <v-radio label="НЕ отговаря на изискванията" :value="false"></v-radio>
                            <v-radio label="Отговаря на изискванията" :value="true"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="8">
                        <text-field :value="document.licenseCategory" disabled label="от категория" />
                    </v-col>
                    <!--<v-col>
                        <btn action="Primary" @click="buildStagedEpicrisisBody">Зареждане епикриза за избрани амбулаторни листове</btn>
                    </v-col>-->
                    <v-col cols="12">
                        <text-field v-model="document.motive" label="Мотив" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <date-picker v-model="document.issueDate" label="Дата и час" required />
                    </v-col>
                </v-row>
            </v-card-text>
            <hr />
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { CertificateDriveDto } from '@/model/Exam/Certificate/CertificateDriveDto';
    import { VehicleCategory } from '@/model/Exam/Certificate/VehicleCategory';
    import { certificateDriveService } from '@/service/Exam/Certificate/CertificateDriveService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: {
            UnsavedChanges,
            PatientInfo,
            DoctorInfo,
            ExamLayout
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class CertificateDrive extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private examId!: number;

        @Prop()
        private documentId!: number;

        private choice: string = '';

        private categoryChoice: Array<VehicleCategory> = [];

        private headers: IDataTableHeader[] = [{ text: 'Категория', value: 'value', align: 'start', sortable: false }];

        private document: CertificateDriveDto = new CertificateDriveDto();
        private initialValue: CertificateDriveDto = new CertificateDriveDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private selected: string = '';
        private categories: Array<VehicleCategory> = [
            { text: 'A', value: 'A' },
            { text: 'A1', value: 'A1' },
            { text: 'A2', value: 'A2' },
            { text: 'AM', value: 'AM' },
            { text: 'B', value: 'B' },
            { text: 'B1', value: 'B1' },
            { text: 'BE', value: 'BE' },
            { text: 'C', value: 'C' },
            { text: 'C1', value: 'C1' },
            { text: 'C1E', value: 'C1E' },
            { text: 'CE', value: 'CE' },
            { text: 'D', value: 'D' },
            { text: 'D1', value: 'D1' },
            { text: 'D1E', value: 'D1E' },
            { text: 'DE', value: 'DE' },
            { text: 'Ткт', value: 'Ткт' },
            { text: 'Ттб', value: 'Ттб' },
            { text: 'Ттм', value: 'Ттм' }
        ];

        private get currentVisit() {
            return currentVisitCache.value;
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                this.choice = 'general';
                if (!this.documentId) {
                    const response = await certificateDriveService.getCertificateInfoByExamId(this.examId);
                    this.document = response.data;
                } else {
                    const response = await certificateDriveService.getCertificateDrive(this.documentId);
                    this.document = response.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async save() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (documentId === 0) {
                    const response = await certificateDriveService.createCertificateDrive(this.document);
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, 'CertificateDrive', 'Медицинско за шофьор');
                } else {
                    await certificateDriveService.updateCertificateDrive(this.document);
                    eventBus.$emit('update-referral-event', 'Медицинско за шофьор');
                    this.copyObject();
                }

                this.$router.push(`/Exam/CertificateDrive/Edit/${documentId}`);
            } finally {
                this.$loading.hide();
            }
        }

        private autofillExamData() {
            if (this.choice === 'ophtalmologist') {
                this.document.colorPerception = 'нормално';
                this.document.visualAcuityL = '1.0';
                this.document.visualAcuityR = '1.0';
            }
            if (this.choice === 'ung') {
                this.document.normalSpeechL = '> 6M.';
                this.document.normalSpeechR = '> 6M.';
                this.document.whisperSpeechL = '> 6M.';
                this.document.whisperSpeechR = '> 6M.';
            }
            if (this.choice === 'internist') {
                this.document.internalStatus = 'Нормален соматичен статус.';
            }
            if (this.choice === 'neurologist') {
                this.document.neurologicalStatus =
                    'Без отпадна огнищна неврологична симптоматика. Без епилептични припадъци.';
            }
            if (this.choice === 'surgeon') {
                this.document.surgeryStatus = 'Хирургично здрав.';
            }
            if (this.choice === 'license') {
                this.document.motive = `Зрение: ДО:=${this.document.visualAcuityR} ЛО:=${this.document.visualAcuityL} цветоусещане: ${this.document.colorPerception} Слух: разговорна реч: ДУ: ${this.document.normalSpeechR}ЛУ: ${this.document.normalSpeechL} шепотна реч: ДУ:${this.document.whisperSpeechR}ЛУ:${this.document.whisperSpeechL}`;
            }
        }

        private autofillSpecialistData() {
            if (this.choice === 'ophtalmologist') {
                this.document.ophthalmologistName = this.currentVisit.doctorNames;
                this.document.ophthalmologistUin = this.currentVisit.doctorUin;
                //this.document.ophthalmologistDate = this.currentVisit.startDateTime;
            }
            if (this.choice === 'ung') {
                this.document.ungName = this.currentVisit.doctorNames;
                this.document.ungUin = this.currentVisit.doctorUin;
                //this.document.ungDate = this.currentVisit.startDateTime;
            }
            if (this.choice === 'internist') {
                this.document.internistName = this.currentVisit.doctorNames;
                this.document.internistUin = this.currentVisit.doctorUin;
                //this.document.internistDate = this.currentVisit.startDateTime;
            }
            if (this.choice === 'neurologist') {
                this.document.neurologistName = this.currentVisit.doctorNames;
                this.document.neurologistUin = this.currentVisit.doctorUin;
                //this.document.neurologistDate = this.currentVisit.startDateTime;
            }

            if (this.choice === 'surgeon') {
                this.document.surgeonName = this.currentVisit.doctorNames;
                this.document.surgeonUin = this.currentVisit.doctorUin;
                //this.document.surgeonDate = this.currentVisit.startDateTime;
            }
        }

        private autofillOthersData() {
            if (this.choice === 'other') {
                this.document.other1Name = this.currentVisit.doctorNames;
                this.document.other1Uin = this.currentVisit.doctorUin;
                this.document.other2Name = this.currentVisit.doctorNames;
                this.document.other2Uin = this.currentVisit.doctorUin;
                this.document.other3Name = this.currentVisit.doctorNames;
                this.document.other3Uin = this.currentVisit.doctorUin;
            }
        }

        private updateLicenseCategory(input: Array<VehicleCategory>) {
            let str = '';
            for (let item = 0; item < input.length; item++) {
                str += `${input[item].value},`;
            }
            this.document.licenseCategory = str.substring(0, str.length - 1);
        }

        private chosen(event: string) {
            this.choice = event;
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                const errors = (await certificateDriveService.canSaveCertificateDrive(this.document)).data;
                if (errors.length <= 0) {
                    await this.save();
                } else {
                    const errorMessages: string[] = [];
                    for (let errorPos: number = 0; errorPos < errors.length; errorPos++) {
                        errorMessages.push(errors[errorPos]);
                    }
                    if (errors.length > 0) {
                        this.$notifier.showError('Грешка', errorMessages.join(', '));
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const refId = this.document.id;
                await certificateDriveService.deleteCertificateDrive(refId);
                this.copyObject();
                this.$router.push(`/Exam/CertificateDrive/Create/${currentVisitCache.value.id}`);
                eventBus.$emit('delete-referral-event', refId, 'CertificateDrive', 'Медицинско за шофьор');
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.document.id) {
                this.$router.push(`/Print/Exam.Certificate.CertificateDrive/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Certificate.CertificateDrive';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
