import { Route, RouteConfig } from 'vue-router';

export const examOtherSpecialistRoutes: RouteConfig[] = [
    {
        path: 'ConsultationTelk/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/ConsultationTelk/ConsultationTelk.vue')
        }
    },
    {
        path: 'ConsultationTelk/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/ConsultationTelk/ConsultationTelk.vue')
        }
    },
    {
        path: 'ConsultationTelk/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/ConsultationTelk/ConsultationTelkView.vue')
        }
    },
    {
        path: 'PhysiotherapyCard/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/PhysiotherapyCard/PhysiotherapyCard.vue')
        }
    },
    {
        path: 'PhysiotherapyCard/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/PhysiotherapyCard/PhysiotherapyCard.vue')
        }
    },
    {
        path: 'PhysiotherapyCard/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/PhysiotherapyCard/PhysiotherapyCardView.vue')
        }
    }
];
