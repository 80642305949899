<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Манипулации" :items="items">
                <template #actions>
                    <btn action="New" :to="`/Exam/Manipulation/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/Manipulation/Edit/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ManipulationViewDto } from '@/model/Exam/Maipulation/ManipulationViewDto';
    import { manipulationService } from '@/service/Exam/ManipulationService';

    @Component({})
    export default class ManipulationView extends Vue {
        @Prop()
        examId!: number;

        private items: ManipulationViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Тарифа:', value: 'tariffTitle' },
                { text: 'Услуга:', value: 'serviceTitle' },
                { text: 'Цена:', value: 'patientPrice' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await manipulationService.getByExamId(this.examId);
                if (response) {
                    this.items = response;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>

<style scoped></style>
