import Vue from 'vue';

class MenuState {
    public mainMenuIsCollapsed: boolean = false;

    public showSecondaryMenu: boolean = false;

    public showSecondaryMenuAppBarIcon: boolean = false;

    public secondaryMenuAppBarIcon: string = 'mdi-menu-open';
}

export const menuState = Vue.observable(new MenuState());
