<template>
    <div>
        <v-card-title>Консултации при необходимост</v-card-title>
        <PregnancyDataTableComponent v-model="value.rows" :headers="headers" :width="8"></PregnancyDataTableComponent>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ConsultationDataDto } from '@/model/Register/Pregnancy/PregnancyCard/ConsultationDataDto';
    import PregnancyDataTableComponent from '@/view/Register/Pregnancy/PregnancyCard/PregnancyDataTableComponent.vue';

    @Component({
        components: { PregnancyDataTableComponent }
    })
    export default class ConsultationData extends Vue {
        @Prop()
        value!: ConsultationDataDto;

        private get headers(): IDataTableHeader[] {
            return [
                { text: '', value: 'name', filterable: false },
                { text: 'до 3', value: 'fields[0]', filterable: false },
                { text: '4', value: 'fields[1]', filterable: false },
                { text: '5', value: 'fields[2]', filterable: false },
                { text: '6', value: 'fields[3]', filterable: false },
                { text: '7', value: 'fields[4]', filterable: false },
                { text: '8', value: 'fields[5]', filterable: false },
                { text: '9', value: 'fields[6]', filterable: false },
                { text: '9', value: 'fields[7]', filterable: false },
                { text: '10', value: 'fields[8]', filterable: false },
                { text: '10', value: 'fields[9]', filterable: false }
            ];
        }
    }
</script>
