<template>
    <div>
        <!-- Бутон за печат на фактура или меню за печат на няколко документа, най-често фактура и кредитно известие. -->
        <btn
            v-if="value.invoices.length === 1"
            icon
            action="Print"
            :to="`/Print/Licensing.CustomerInvoice/CustomerInvoice/${value.customerId}/${value.invoices[0].invoiceNumber}`"
        />
        <v-menu v-else-if="value.invoices.length > 0" open-on-hover bottom offset-y pointer>
            <template #activator="{ on }">
                <v-btn icon v-on="on"><v-icon>mdi-printer</v-icon></v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="invoice in value.invoices"
                    :key="invoice.invoiceNumber"
                    :to="`/Print/Licensing.CustomerInvoice/CustomerInvoice/${value.customerId}/${invoice.invoiceNumber}`"
                >
                    <v-list-item-title class="pointer">
                        {{ invoice.isCredit ? 'Кредитно известие' : 'Фактура' }}:
                        {{ invoice.invoiceNumberText }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <btn
            icon
            action="Details"
            :to="`/Customer/CustomerCredit/Edit/${value.customerId}/${value.seqNumber}/${practiceId}`"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { CustomerCreditDto } from '@/model/Licensing/CustomerCreditDto';

    @Component
    export default class CustomerCreditRowActions extends Vue {
        @Prop({ required: true })
        private value!: CustomerCreditDto;

        @Prop({ required: true })
        private practiceId!: number;
    }
</script>
