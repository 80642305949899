import { DoctorDeputyScheduleCreateCommand } from '@/model/Employee/Deputy/Schedule/DoctorDeputyScheduleCreateCommand';
import { httpService } from '@/service/Infrastructure/HttpService';

export const doctorDeputyScheduleService = {
    async createDoctorDeputySchedule(command: DoctorDeputyScheduleCreateCommand): Promise<number> {
        const response = await httpService.post<number>('DoctorDeputySchedule/CreateDoctorDeputySchedule', command);
        return response.data;
    },

    async deleteDoctorDeputySchedule(doctorDeputyScheduleId: number): Promise<void> {
        await httpService.delete(`DoctorDeputySchedule/DeleteDoctorDeputySchedule/${doctorDeputyScheduleId}`);
    }
};
