import { NecessaryPreventiveConsultationDto } from './NecessaryPreventiveConsultationDto';
import { NecessaryPreventiveNhifLabTestDto } from './NecessaryPreventiveNhifLabTestDto';

export class PreventiveRegisterDto {
    public hasPreventiveRule: boolean = false;
    public ordinanceText: string = '';
    public preventiveType: string = '';
    public preventiveNhifLabTests: NecessaryPreventiveNhifLabTestDto[] = [];
    public preventiveConsultations: NecessaryPreventiveConsultationDto[] = [];
}
