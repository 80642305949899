export class DoctorEmployeeDropdownDto {
    public employeeSeqNumber: number = 0;
    public doctorUin: string = '';
    public doctorName: string = '';
    public specialtyName: string = '';

    public get uinNameAndSpecialty() {
        return `${this.doctorUin}, ${this.doctorName}, ${this.specialtyName}`;
    }
}
