<template>
    <v-form ref="form" v-model="isFormValid">
        <v-card>
            <v-card-title>Отчет/иск - амбулаторни листове</v-card-title>
            <v-card-text>
                <report-date-picker v-model="filter" @input="getTableItems" />
                <v-row>
                    <v-col cols="12" md="6">
                        <practice-nhif-contract-picker v-model="filter.contract" :practice-id="practiceId" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="canGenerate" cols="12" md="4">
                        <btn action="XmlReport" block :disabled="isGenerating" @click="generateGpExamClaim" />
                    </v-col>
                    <v-col v-if="canGenerate && isAdmin" cols="12" md="4">
                        <btn action="List" block :disabled="isGenerating" @click="generateGpExamClaimReport">Опис</btn>
                    </v-col>
                    <v-col v-if="canGenerate" cols="12" md="4">
                        <btn action="List" block :disabled="isGenerating" @click="generateCovidImmunizationReport">
                            Опис - Covid 19
                        </btn>
                    </v-col>
                    <v-col v-if="hasResult" cols="12" md="4">
                        <btn action="Send" block :disabled="isGenerating" @click="sendExamClaim">
                            Изпращане на отчет
                        </btn>
                    </v-col>
                    <v-col v-if="hasResult" cols="12" md="4">
                        <btn action="Download" block :disabled="isGenerating" @click="downloadXml">
                            Запис на отчет
                        </btn>
                    </v-col>
                </v-row>
                <v-row v-if="hasResult">
                    <p class="font-weight-bold">Брой амбулаторни листове в отчета: {{ response.itemsCount }}</p>
                </v-row>
                <div v-if="items.length > 0" class="mt-5">
                    <pis-file v-model="items"></pis-file>
                </div>
                <div v-if="hasResult" class="mt-5">
                    <h3>XML Отчет</h3>
                    <v-row class="mt-3">
                        <v-col cols="12">
                            <v-textarea v-model="response.report"></v-textarea>
                        </v-col>
                    </v-row>
                    Време за изпълнение: {{ response.executionSeconds }}
                </div>
                <v-dialog v-model="examReportDialogIsVisible">
                    <gp-exam-claim-report
                        :items="gpClaimReport"
                        :filter="filter"
                        title="Опис към месечния отчет"
                        @close-dialog="examReportDialogIsVisible = false"
                    />
                </v-dialog>
                <v-dialog v-model="covidImmunizationReportDialogIsVisible">
                    <gp-exam-claim-report
                        :items="covidImmunizationReport"
                        title="Профилактична ваксинация срещу Covid-19"
                        @close-dialog="covidImmunizationReportDialogIsVisible = false"
                    />
                </v-dialog>
            </v-card-text>
        </v-card>
        <SuccessDialog v-model="resultDialogIsVisible" :text="dialogText" title="Резултат от изпращане" />
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import format from 'xml-formatter';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { PisUploadType } from '@/enum/LocalServer/PisUploadType';
    import { PisFileTypeCode } from '@/enum/Report/Pis/PisFileTypeCode';
    import { ExamClaimCommand } from '@/model/Report/Pis/GpExamClaim/ExamClaimCommand';
    import { ExamClaimReportDto } from '@/model/Report/Pis/GpExamClaim/ExamClaimReportDto';
    import { PisFileCreateDto } from '@/model/Report/Pis/PisFile/PisFileCreateDto';
    import { PisFileFilterDto } from '@/model/Report/Pis/PisFile/PisFileFilterDto';
    import { PisFileRowDto } from '@/model/Report/Pis/PisFile/PisFileRowDto';
    import { PisFileUploadRequestDto } from '@/model/Report/Pis/PisFileUploadRequestDto';
    import { ReportResponseDto } from '@/model/Report/Pis/ReportResponseDto';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { examClaimReportService } from '@/service/Query/Exam/ExamClaimReportService';
    import { localServerPisService } from '@/service/Report/LocalServerPisService';
    import { pisFileService } from '@/service/Report/PisFileService';
    import { pisService } from '@/service/Report/PisService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';
    import GpExamClaimReport from '@/view/Query/Exam/GpExamClaimReport.vue';
    import PisFile from '@/view/Report/Pis/PisFile.vue';
    import PracticeNhifContractPicker from '@/view/Report/Pis/PracticeNhifContractPicker.vue';
    import SuccessDialog from '@/view/Report/Pis/SuccessDialog.vue';

    @Component({
        components: { PracticeNhifContractPicker, GpExamClaimReport, ReportDatePicker, PisFile, SuccessDialog }
    })
    export default class GpExamClaim extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private isFormValid: boolean = false;
        private filter: ExamClaimCommand = new ExamClaimCommand();
        private resultDialogIsVisible: boolean = false;
        private dialogText: string = '';
        private isLocalServerActive: boolean = false;
        private items: PisFileRowDto[] = [];
        private canGenerate: boolean = false;
        private examReportDialogIsVisible: boolean = false;
        private covidImmunizationReportDialogIsVisible: boolean = false;
        private gpClaimReport: ExamClaimReportDto = new ExamClaimReportDto();
        private covidImmunizationReport: ExamClaimReportDto = new ExamClaimReportDto();
        private response: ReportResponseDto = new ReportResponseDto();
        private isGenerating: boolean = false;

        private get hasResult() {
            return this.response?.report?.length > 0;
        }

        private get isAdmin() {
            return userContextCache.currentIsGlobalAdminOrPracticeOwner;
        }

        private async mounted() {
            this.filter.fillPreviousMonth();
            await this.getTableItems();
            await this.isFilledPracticeAndDoctor();
        }

        private async generateGpExamClaim() {
            this.$loading.show();
            try {
                (this.$refs.form as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    this.isGenerating = true;
                    this.fillDoctorEmployee();
                    const response = await pisService.generateGpExamClaim(this.filter);
                    if (response?.data?.report) {
                        this.response.itemsCount = response.data.itemsCount;
                        this.response.report = format(response.data.report, {
                            indentation: '  ',
                            filter: (node) => node.type !== 'Comment',
                            collapseContent: true,
                            lineSeparator: '\n'
                        });
                        this.response.executionSeconds = response.data.executionSeconds;
                    }
                }
            } catch {
                this.$notifier.showWarning(
                    'Проблем при генериране на отчет',
                    'Възможно е проблемът да се дължи на прекалено дълъг период, за който се опитвате да генерирате отчет. Моля проверете'
                );
            } finally {
                this.isGenerating = false;
                this.$loading.hide();
            }
        }

        private async generateCovidImmunizationReport() {
            this.$loading.show();
            try {
                this.fillDoctorEmployee();
                const response = await examClaimReportService.generateCovidImmunizationReport(this.filter);
                if (response) {
                    this.covidImmunizationReportDialogIsVisible = true;
                    this.covidImmunizationReport = response;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async generateGpExamClaimReport() {
            this.$loading.show();
            try {
                this.fillDoctorEmployee();
                const response = await examClaimReportService.generateGpExamClaimReport(this.filter);
                if (response) {
                    this.examReportDialogIsVisible = true;
                    this.gpClaimReport = response;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private downloadXml() {
            const element = document.createElement('a');
            const blob = new Blob([this.response.report ?? ''], { type: 'application/xml' });
            const url = URL.createObjectURL(blob);
            element.setAttribute('href', url);
            element.setAttribute(
                'download',
                `AmbList_${userContextCache.current?.practiceNumber}_${dateUtil.formatBgDate(
                    this.filter?.startDate
                )}.xml`
            );
            element.click();
        }

        private fillDoctorEmployee() {
            this.filter.practiceId = this.practiceId;
            this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async sendExamClaim() {
            await this.checkServerStatus();
            if (this.isLocalServerActive) {
                this.$loading.show();
                const uploadRequest = new PisFileUploadRequestDto(
                    this.response.report,
                    userContextCache.current?.practiceNumber ?? '',
                    userContextCache.current?.doctorIdentifier ?? ''
                );
                const response = await localServerPisService.uploadReport(
                    PisFileTypeCode.ExamClaim,
                    uploadRequest,
                    PisUploadType.XmlReport
                );
                if (!response.isError) {
                    this.dialogText = `${response.message} Идентификаторът на документа е: ${response.additionalInformation}`;
                    this.resultDialogIsVisible = true;
                    await this.savePisFile(response.additionalInformation);
                }
                this.$loading.hide();
            }
        }

        private async savePisFile(fileId: string) {
            const file = new PisFileCreateDto();
            file.practiceId = this.practiceId;
            file.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
            file.pisFileTypeCode = PisFileTypeCode.ExamClaim;
            file.pisFileId = fileId;
            file.startDate = this.filter.startDate;
            file.endDate = this.filter.endDate;

            const item = await pisFileService.createPisFile(file);
            this.items.push(item);
            this.$loading.hide();
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                const filter = new PisFileFilterDto();
                filter.practiceId = this.practiceId;
                filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
                filter.pisFileTypeCode = PisFileTypeCode.ExamClaim;
                filter.startDate = this.filter.startDate;
                filter.endDate = this.filter.endDate;
                if (filter.startDate && filter.endDate) {
                    this.items = await pisFileService.getPisFiles(filter);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async isFilledPracticeAndDoctor() {
            const response = await pisService.isFilledPracticeAndDoctor(this.practiceId, this.doctorEmployeeSeqNumber);

            if (response?.data.length > 0) {
                this.canGenerate = false;
                this.$notifier.showWarning('Внимание', response.data);
            } else {
                this.canGenerate = true;
            }
        }

        private async checkServerStatus(): Promise<void> {
            const response = await localServerDiagnosticService.checkIsLocalServerActive();
            this.isLocalServerActive = response;
        }

        @Watch('doctorEmployeeId')
        private onDoctorEmployeeChange(): void {
            this.response = new ReportResponseDto();
        }
    }
</script>
