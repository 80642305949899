import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
import { NhisDoctorsPrescriptionDto } from '@/model/Nhis/Prescription/NhisDoctorsPrescriptionDto';
import { NhisDoctorsPrescriptionGetXmlCommand } from '@/model/Nhis/Prescription/NhisDoctorsPrescriptionGetXmlCommand';
import { NhisPrescriptionDto } from '@/model/Nhis/Prescription/NhisPrescriptionDto';
import { NhisPrescriptionGetXmlCommand } from '@/model/Nhis/Prescription/NhisPrescriptionGetXmlCommand';
import { NhisPrescriptionSubmitCommand } from '@/model/Nhis/Prescription/NhisPrescriptionSubmitCommand';
import { prescriptionService } from '@/service/Exam/PrescriptionService';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { nhisService } from '@/service/Nhis/NhisService';
import { signXmlService } from '@/service/Report/SignXmlService';
import { loadingState } from '@/store/LoadingState';
import { accessTokenCache, nhisKey } from '@/store/NhisNhifAccessTokenCache';
import { userContextCache } from '@/store/User/UserContextCache';

const createGetXmlCommand = (key: PrescriptionKey) => {
    const { current } = userContextCache;
    if (!current || !current.doctorUin) {
        throw Error('Не е избрана месторабота.');
    }
    return new NhisPrescriptionGetXmlCommand(key, current.doctorUin, current.deputyDoctorUin);
};

const createFetchXmlCommand = (nrn: string | null, pageNumber: number | null) => {
    const { current } = userContextCache;
    if (!current || !current.doctorUin) {
        throw Error('Не е избрана месторабота.');
    }
    const command = new NhisDoctorsPrescriptionGetXmlCommand(current.doctorUin, current.deputyDoctorUin);
    command.pageNumber = pageNumber;
    command.nrn = nrn;
    return command;
};

const getPrescriptionSubmitXml = async (key: PrescriptionKey): Promise<string> => {
    const command = createGetXmlCommand(key);
    const response = await httpService.post<string>(`/${nhisKey}/GetPrescription`, command);
    console.log(response);
    return response?.data ?? '';
};

const getPrescriptionCancellationXml = async (key: PrescriptionKey): Promise<string> => {
    const command = createGetXmlCommand(key);
    const response = await httpService.post<string>(`/${nhisKey}/GetCancellationPrescription`, command);
    console.log(response);
    return response?.data ?? '';
};

const getPrescriptionExecutionCheckXml = async (key: PrescriptionKey): Promise<string> => {
    const command = createGetXmlCommand(key);
    const response = await httpService.post<string>(`/${nhisKey}/GetPrescriptionExecutionCheck`, command);
    console.log(response);
    return response?.data ?? '';
};

const getPrescriptionFetchXml = async (nrn: string): Promise<string> => {
    const command = createFetchXmlCommand(nrn, null);
    const response = await httpService.post<string>(`/${nhisKey}/GetPrescriptionFetch`, command);
    console.log(response);
    return response?.data ?? '';
};

const getDoctorsPrescriptionFetchXml = async (pageNumber: number): Promise<string> => {
    const command = createFetchXmlCommand(null, pageNumber);
    const response = await httpService.post<string>(`/${nhisKey}/GetDoctorsPrescriptionFetch`, command);
    console.log(response);
    return response?.data ?? '';
};

const postPrescriptionToNhis = async (action: string, xmlString: string, key: PrescriptionKey) => {
    const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
    const response = await httpService.post<NhisPrescriptionDto>(
        `${nhisKey}/${action}`,
        new NhisPrescriptionSubmitCommand(xmlString, tokenInfo?.accessToken ?? '', key)
    );
    return response.data;
};

export const nhisPrescriptionService = {
    async postPrescription(key: PrescriptionKey, formType: PrescriptionFormTypeCode) {
        loadingState.show();
        try {
            await this.checkPrescriptionBeforeUpload(key, formType);
            const xmlString = await getPrescriptionSubmitXml(key);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const prescriptionResult = await postPrescriptionToNhis('PostPrescription', signResponse.contents, key);
                console.log(prescriptionResult?.response);
                if (prescriptionResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${prescriptionResult.error}`);
                } else {
                    if (prescriptionResult?.warnings?.length > 0) {
                        nhisService.showWarnings(prescriptionResult.warnings);
                    }
                    return prescriptionResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async cancelPrescription(key: PrescriptionKey) {
        loadingState.show();
        try {
            const xmlString = await getPrescriptionCancellationXml(key);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const prescriptionResult = await postPrescriptionToNhis(
                    'PostCancellationPrescription',
                    signResponse.contents,
                    key
                );
                console.log(prescriptionResult?.response);
                if (prescriptionResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${prescriptionResult.error}`);
                } else {
                    await this.checkPrescription(key);
                    return prescriptionResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async checkPrescription(key: PrescriptionKey) {
        loadingState.show();
        try {
            const xmlString = await getPrescriptionExecutionCheckXml(key);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const prescriptionResult = await postPrescriptionToNhis(
                    'PostPrescriptionExecutionCheck',
                    signResponse.contents,
                    key
                );
                console.log(prescriptionResult?.response);
                if (prescriptionResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${prescriptionResult.error}`);
                } else {
                    await notifierService.showSuccess('', `${prescriptionResult.executionStatusMessage}`);
                    if (prescriptionResult?.warnings?.length > 0) {
                        nhisService.showWarnings(prescriptionResult.warnings);
                    }
                    return prescriptionResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async checkPrescriptionBeforeUpload(key: PrescriptionKey, formType: PrescriptionFormTypeCode) {
        if (formType === PrescriptionFormTypeCode.Nhif5a) {
            const warning = await prescriptionService.check5ABeforeUpload(key);
            if (warning) {
                notifierService.showWarning('', warning);
            }
        }
    },

    async fetchPrescription(nrn: string) {
        loadingState.show();
        try {
            const xmlString = await getPrescriptionFetchXml(nrn);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
                const response = await httpService.post<NhisDoctorsPrescriptionDto>(
                    `${nhisKey}/PostPrescriptionFetch`,
                    new NhisPrescriptionSubmitCommand(
                        signResponse.contents,
                        tokenInfo?.accessToken ?? '',
                        new PrescriptionKey(0, 0)
                    )
                );
                const prescriptionResult = response.data;

                console.log(prescriptionResult?.response);
                if (prescriptionResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${prescriptionResult.error}`);
                } else {
                    if (prescriptionResult?.warnings?.length > 0) {
                        nhisService.showWarnings(prescriptionResult.warnings);
                    }
                    return prescriptionResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async fetchDoctorsPrescription(pageNumber: number) {
        loadingState.show();
        try {
            const xmlString = await getDoctorsPrescriptionFetchXml(pageNumber);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
                const response = await httpService.post<NhisDoctorsPrescriptionDto>(
                    `${nhisKey}/PostDoctorsPrescriptionsFetch`,
                    new NhisPrescriptionSubmitCommand(
                        signResponse.contents,
                        tokenInfo?.accessToken ?? '',
                        new PrescriptionKey(0, 0)
                    )
                );
                const result = response.data;
                console.log(result?.response);
                if (result?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${result.error}`);
                } else {
                    if (result?.warnings?.length > 0) {
                        nhisService.showWarnings(result.warnings);
                    }
                    return result;
                }
            }
            return new NhisDoctorsPrescriptionDto();
        } finally {
            loadingState.hide();
        }
    }
};
