import { AxiosResponse } from 'axios';

import { FeeDisengagementReasonDto } from '@/model/Nomenclature/FeeDisengagementReasonDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const feeDisengagementReasonService = {
    getFeeDisengagementReasons(): Promise<AxiosResponse<FeeDisengagementReasonDto[]>> {
        return httpService.get('/FeeDisengagementReason/GetFeeDisengagementReasons');
    }
};
