<template>
    <v-form ref="form" v-model="isFormValid">
        <v-row>
            <v-col md="4">
                <dropdown
                    v-model="doctorDeputy.primaryDoctorEmployeeSeqNumber"
                    required
                    item-value="employeeSeqNumber"
                    item-text="uinNameAndSpecialty"
                    :items="doctorEmployees"
                    label="Титуляр"
                ></dropdown>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="4">
                <text-field
                    v-model="doctorDeputy.deputyDoctorUin"
                    required
                    :rules="[$validator.uin]"
                    label="УИН заместващ"
                ></text-field>
            </v-col>
            <v-col>
                <text-field
                    v-model="doctorDeputy.deputyDoctorName"
                    :disabled="isExistingDoctor"
                    :required="!isExistingDoctor"
                    label="Име заместващ"
                ></text-field>
            </v-col>
        </v-row>
        <alert v-if="alertMessage" type="info">{{ alertMessage }}</alert>
        <v-row align="center">
            <v-col cols="12" md="3">
                <btn action="Save" :disabled="!canSubmit" block @click="save" />
            </v-col>
        </v-row>
    </v-form>
</template>
<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { DoctorDeputySelfRegisterCommand } from '@/model/Employee/Deputy/DoctorDeputySelfRegisterCommand';
    import { DoctorEmployeeDropdownDto } from '@/model/Employee/Deputy/DoctorEmployeeDropdownDto';
    import { registerDoctorDeputyService } from '@/service/Employee/Deputy/RegisterDoctorDeputyService';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { registerDoctorService } from '@/service/Employee/RegisterDoctorService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class DoctorDeputyCreate extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private doctorDeputy: DoctorDeputySelfRegisterCommand = new DoctorDeputySelfRegisterCommand();
        private doctorEmployees: DoctorEmployeeDropdownDto[] = [];
        private isExistingDoctor: boolean = false;
        private isFormValid: boolean = false;
        private alertMessage: string = '';

        private async mounted() {
            this.doctorDeputy.practiceId = this.practiceId;
            this.doctorEmployees = await employeeService.getPublicActiveDoctorEmployees(this.practiceId);
        }

        private get canSubmit(): boolean {
            return Boolean(
                this.doctorDeputy.primaryDoctorEmployeeSeqNumber &&
                    this.doctorDeputy.deputyDoctorUin &&
                    this.doctorDeputy.deputyDoctorName &&
                    this.alertMessage === ''
            );
        }

        private async save() {
            this.isFormValid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isFormValid) {
                this.$loading.show();
                try {
                    this.alertMessage = await registerDoctorDeputyService.createDoctorDeputy(this.doctorDeputy);
                } finally {
                    this.$loading.hide();
                }
                if (!this.alertMessage) {
                    this.$notifier.showSuccess('', 'Заявката Ви е приета, но очаква одобрение.');
                    // UserContext-ите се презареждат, но новосъздаденият не може да се избира - срещу него пише "очаква одобрение".
                    // * Ако глобален админ е добавял ad-hoc UserContext-и през търсенето, те изчезват от списъка.
                    await userContextCache.loadFromServer();

                    // Екранът за регистриране се "затваря".
                    this.$router.push('/');
                }
            }
        }

        private async checkExistingDoctorDeputy() {
            this.alertMessage = await registerDoctorDeputyService.checkExistingDoctorDeputy(this.doctorDeputy);
        }

        @Watch('doctorDeputy.deputyDoctorUin')
        private async onDeputyDoctorUinChanged() {
            const uinLength = 10;
            if (this.doctorDeputy.deputyDoctorUin.length === uinLength) {
                const doctorRegistration = await registerDoctorService.getDoctorRegistration(
                    this.practiceId,
                    this.doctorDeputy.deputyDoctorUin
                );

                if (doctorRegistration) {
                    this.doctorDeputy.deputyDoctorName = doctorRegistration.doctorName;
                    this.isExistingDoctor = true;

                    if (this.doctorDeputy.primaryDoctorEmployeeSeqNumber) {
                        this.checkExistingDoctorDeputy();
                    }
                } else {
                    this.alertMessage = '';
                }
            } else {
                this.doctorDeputy.deputyDoctorName = '';
                this.isExistingDoctor = false;
            }
        }

        @Watch('doctorDeputy.primaryDoctorEmployeeSeqNumber')
        private onPrimaryDoctorEmployeeChanged() {
            if (this.doctorDeputy.primaryDoctorEmployeeSeqNumber && this.isExistingDoctor) {
                this.checkExistingDoctorDeputy();
            }
        }
    }
</script>
