import { AxiosResponse } from 'axios';

import { NhifProtocolCommunicationDto } from '@/model/Nomenclature/NhifProtocolCommunicationDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhifProtocolCommunicationService = {
    getNhifProtocolCommunications(): Promise<AxiosResponse<NhifProtocolCommunicationDto[]>> {
        return httpService.get('/NhifProtocolCommunication/GetNhifProtocolCommunications');
    }
};
