<template>
    <data-table title="Шаблони" :headers="headers" :items="items" :loading="$loading.isVisible">
        <template #actions>
            <btn action="New" :to="`/Setting/Template/Create/${practiceId}/${doctorEmployeeSeqNumber}`">
                Добавяне на шаблон
            </btn>
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/Setting/Template/Edit/${practiceId}/${item.id}`" />
            <btn icon action="Delete" @click="deleteTemplate(item)" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { TemplateDto } from '@/model/Setting/Template/TemplateDto';
    import { templateService } from '@/service/Setting/TemplateService';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component
    export default class TemplateList extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private items: TemplateDto[] = [];
        private headers: IDataTableHeader[] = [
            { text: 'Категория', value: 'templateType' },
            { text: 'Филтър по МКБ', value: 'icdFilter' },
            { text: 'Текст', value: 'templateText' }
        ];

        private async load() {
            this.$loading.show();
            try {
                const response = await templateService.getTemplatesForDoctor(
                    this.practiceId,
                    this.doctorEmployeeSeqNumber
                );
                this.items = response.data;
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteTemplate(itemToDelete: TemplateDto): Promise<void> {
            await templateService.deleteTemplate(this.practiceId, itemToDelete.id);
            arrayUtil.removeFirst(this.items, itemToDelete);
        }

        private mounted() {
            return this.load();
        }
    }
</script>
