import { plainToInstance } from 'class-transformer';

import { Report365Dto } from '@/model/Report/Rhi/Report365/Report365Dto';
import { RhiReportKey } from '@/model/Report/Rhi/RhiReportKey';
import { RhiReportRowDto } from '@/model/Report/Rhi/RhiReportRowDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const report365Service = {
    async getReport365s(practiceId: number, doctorEmployeeSeqNumber: number): Promise<RhiReportRowDto[]> {
        const response = await httpService.get<RhiReportRowDto[]>('/Report365/GetReport365s', {
            params: { practiceId, doctorEmployeeSeqNumber }
        });
        return plainToInstance(RhiReportRowDto, response.data);
    },

    async getReport365(key: RhiReportKey): Promise<Report365Dto> {
        const response = await httpService.get<Report365Dto>('/Report365/GetReport365', { params: key });
        return plainToInstance(Report365Dto, response.data);
    },

    async getReport365InitialItems(
        practiceId: number,
        doctorEmployeeSeqNumber: number,
        startDate: Date,
        endDate: Date
    ): Promise<Report365Dto> {
        const response = await httpService.get<Report365Dto>('/Report365/GetReport365InitialItems', {
            params: { practiceId, doctorEmployeeSeqNumber, startDate, endDate }
        });
        return plainToInstance(Report365Dto, response.data);
    },

    async createReport365(practiceId: number, doctorEmployeeSeqNumber: number, report: Report365Dto): Promise<number> {
        const response = await httpService.post<number>('/Report365/CreateReport365', report, {
            params: { practiceId, doctorEmployeeSeqNumber }
        });
        return response.data;
    },

    async updateReport365(key: RhiReportKey, data: Report365Dto): Promise<void> {
        await httpService.put('/Report365/UpdateReport365/', data, { params: key });
    },

    async exportReport365(key: RhiReportKey): Promise<void> {
        await httpService.download('/Report365/ExportReport365', { params: key });
    },

    async exportAnnex2(key: RhiReportKey): Promise<void> {
        await httpService.download('/Report365/ExportAnnex2', { params: key });
    },

    async exportAnnex3(key: RhiReportKey): Promise<void> {
        await httpService.download('/Report365/ExportAnnex3', { params: key });
    },

    async exportAnnex4(key: RhiReportKey): Promise<void> {
        await httpService.download('/Report365/ExportAnnex4', { params: key });
    },

    async exportAnnex56(key: RhiReportKey): Promise<void> {
        await httpService.download('/Report365/ExportAnnex56', { params: key });
    },

    async deleteReport365(key: RhiReportKey): Promise<void> {
        await httpService.delete('/Report365/DeleteReport365', { params: key });
    }
};
