<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Етапна епикриза" :items="items">
                <template #actions>
                    <btn action="New" :to="`/Exam/StagedEpicrisis/Create/${visitId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/StagedEpicrisis/Edit/${item.id}`" />
                    <btn icon action="Print" :to="`/Print/Exam.StagedEpicrisis/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { StagedEpicrisisViewDto } from '@/model/Exam/StagedEpicrisis/StagedEpicrisisViewDto';
    import { stagedEpicrisisService } from '@/service/Exam/StagedEpicrisisService';

    @Component
    export default class StagedEpicrisisView extends Vue {
        @Prop()
        visitId!: number;

        private items: StagedEpicrisisViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'АЛ', value: 'ambulatorySheetNumber' },
                { text: 'Водеща диагноза', value: 'diagnosis' },
                { text: 'Анамнеза', value: 'symptoms' },
                { text: 'Обективно състояние', value: 'condition' },
                { text: 'Извършени изследвания', value: 'tests' },
                { text: 'Назначена терапия', value: 'treatment' },
                { text: 'Назначена консултация', value: 'consultation' },
                { text: 'Заключение', value: 'conclusion' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                this.items = await stagedEpicrisisService.getStagedEpicrisesForVisit(this.visitId);
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
