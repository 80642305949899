export class LocalServerSignDto {
    constructor(
        public isError: boolean,
        public contents: string | null = null,
        public message: string | null = null,
        public additionalInformation: string | null = null
    ) {}

    public thumbprint: string | null = null;
}
