import { plainToInstance } from 'class-transformer';

import { CertificateMariageDto } from '@/model/Exam/Certificate/CertificateMariageDto';
import { CertificateMariageViewDto } from '@/model/Exam/Certificate/CertificateMariageViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const certificateMariageService = {
    async getCertificateMariage(examId: number): Promise<CertificateMariageDto> {
        const response = await httpService.get<CertificateMariageDto>('CertificateMariage/GetCertificateMariage', {
            params: { examId }
        });

        return plainToInstance(CertificateMariageDto, response.data);
    },
    async saveCertificateMariage(data: CertificateMariageDto): Promise<void> {
        await httpService.post('CertificateMariage/SaveCertificateMariage', data);
    },
    async getSertificates(examId: number): Promise<CertificateMariageViewDto[]> {
        const response = await httpService.get<CertificateMariageViewDto[]>('CertificateMariage/GetCertificates', {
            params: { examId }
        });

        return plainToInstance(CertificateMariageViewDto, response.data);
    },
    async deleteCertificateMariage(examId: number): Promise<void> {
        await httpService.delete('CertificateMariage/DeleteCertificate', {
            params: { examId }
        });
    }
};
