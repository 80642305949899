import { AxiosResponse } from 'axios';

import { InformedConsentDiagnosticsDto } from '@/model/Exam/InformedConsentDiagnostics/InformedConsentDiagnosticsDto';
import { InformedConsentDiagnosticsViewDto } from '@/model/Exam/InformedConsentDiagnostics/InformedConsentDiagnosticsViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const informedConsentDiagnosticsService = {
    getInformedConsentDiagnostics(patientId: number): Promise<AxiosResponse<InformedConsentDiagnosticsDto>> {
        return httpService.get(`/InformedConsentDiagnostics/GetInformedConsentDiagnostics/${patientId}`);
    },

    createInformedConsentDiagnostics(
        data: InformedConsentDiagnosticsDto
    ): Promise<AxiosResponse<InformedConsentDiagnosticsDto>> {
        return httpService.post('/InformedConsentDiagnostics/CreateInformedConsentDiagnostics', data);
    },

    updateInformedConsentDiagnostics(
        data: InformedConsentDiagnosticsDto
    ): Promise<AxiosResponse<InformedConsentDiagnosticsDto>> {
        return httpService.put('/InformedConsentDiagnostics/UpdateInformedConsentDiagnostics/', data);
    },

    deleteInformedConsentDiagnostics(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/InformedConsentDiagnostics/DeleteInformedConsentDiagnostics/${id}`);
    },

    getInformedConsentDiagnosticsInfoByExamId(examId: number): Promise<AxiosResponse<InformedConsentDiagnosticsDto>> {
        return httpService.get(`/InformedConsentDiagnostics/GetInformedConsentDiagnosticsInfoByExamId/${examId}`);
    },

    getInformedConsentDiagnosticsByExamId(examId: number): Promise<AxiosResponse<InformedConsentDiagnosticsViewDto[]>> {
        return httpService.get(`/InformedConsentDiagnostics/GetInformedConsentDiagnosticsViewsByExam`, {
            params: { examId }
        });
    }
};
