import { Type } from 'class-transformer';

import { ChildrenHealthRegisterDto } from '@/model/Register/ChildrenHealth/ChildrenHealthRegisterDto';
import { DispensaryRegisterDto } from '@/model/Register/Dispensary/DispensaryRegisterDto';
import { PregnancyRegisterDto } from '@/model/Register/Pregnancy/PregnancyRegisterDto';
import { PreventiveRegisterDto } from '@/model/Register/Preventive/PreventiveRegisterDto';

export class AmbulatorySheetDiagnosisDto {
    public id: number = 0;
    public icdCode: string = '';
    public icdCodeAdd: string | null = null;

    @Type(() => DispensaryRegisterDto)
    public dispensaryRegister: DispensaryRegisterDto = new DispensaryRegisterDto();

    @Type(() => PregnancyRegisterDto)
    public pregnancyRegister: PregnancyRegisterDto = new PregnancyRegisterDto();

    @Type(() => ChildrenHealthRegisterDto)
    public childrenHealthRegister: ChildrenHealthRegisterDto = new ChildrenHealthRegisterDto();

    @Type(() => PreventiveRegisterDto)
    public preventiveRegister: PreventiveRegisterDto = new PreventiveRegisterDto();

    public createDispensary: boolean = false;
    public createPregnancy: boolean = false;
    public createChildrenRegister: boolean = false;
    public usedForms: string | null = null;

    // Флаговете от всички диагнози формират общ флаг за прегледа, който вече се записва в базата.
    public isNewFound: boolean = false;
}
