<template>
    <div>
        <v-row>
            <v-col>
                <text-field v-model="value.emailOrUserName" label="Въведете точния имейл адрес (потребителско име)" />
            </v-col>
            <v-col>
                <text-field
                    v-if="value.result && value.result.userExists"
                    v-model="value.result.personName"
                    :label="'Потребителят съществува' + (value.result.personName ? '. Име и фамилия:' : '')"
                    disabled
                />
                <value-loading :value="!value.result && value.emailOrUserName.trim()"></value-loading>
            </v-col>
        </v-row>

        <template v-if="value.result">
            <template v-if="!value.result.userExists">
                <alert :value="value.isValidEmail" type="info">
                    Не съществува потребител {{ value.emailOrUserName }}, но може да го поканите.
                </alert>
                <alert :value="value.isValidEmail && Boolean(value.result.sendInvitationWarning)" type="warning">
                    {{ value.result.sendInvitationWarning }}
                </alert>
                <alert :value="!value.isValidEmail" type="warning">
                    Не съществува потребител {{ value.emailOrUserName }} и не може да го поканите, защото това не е
                    имейл адрес.
                </alert>
            </template>

            <alert :value="value.result.hasInvitation" type="info">
                Вече е изпращана покана до потребител {{ value.emailOrUserName }}.
            </alert>
        </template>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Watch } from 'vue-property-decorator';

    import ValueLoading from '@/component/Common/ValueLoading.vue';
    import { UserSearchModel } from '@/model/Employee/Invitation/UserSearchModel';

    import { EmployeeBase } from './EmployeeBase';

    let checkTimeoutId: number = 0;

    @Component({
        components: { ValueLoading }
    })
    export default class UserSearch extends EmployeeBase {
        @Prop({ required: true })
        private value!: UserSearchModel;

        @Watch('value.emailOrUserName')
        private onEmailOrUserNameChanged() {
            this.value.result = null;

            clearTimeout(checkTimeoutId);
            const checkDelay = 2000;
            checkTimeoutId = this.value.emailOrUserName.trim()
                ? setTimeout(this.checkEmployeeUserExistance, checkDelay)
                : 0;
        }

        private async checkEmployeeUserExistance() {
            this.value.result = await this.value.searchFunction();
        }
    }
</script>
