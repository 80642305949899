import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { FiscalReceiptReportDto } from '@/model/Report/Pis/FiscalReceipt/FiscalReceiptReportDto';
import { FiscalReportCommand } from '@/model/Report/Pis/FiscalReportCommand';
import { RiskAssessmentCardReportDto } from '@/model/Report/Pis/Gp/RiskAssessmentCard/RiskAssessmentCardReportDto';
import { ExamClaimCommand } from '@/model/Report/Pis/GpExamClaim/ExamClaimCommand';
import { PatientListReportDto } from '@/model/Report/Pis/PatientList/PatientListReportDto';
import { ReportResponseDto } from '@/model/Report/Pis/ReportResponseDto';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const pisService = {
    isFilledPracticeAndDoctor(practiceId: number, doctorEmployeeSeqNumber: number): Promise<AxiosResponse<string>> {
        return httpService.get('/Pis/IsFilledPracticeAndDoctor', { params: { practiceId, doctorEmployeeSeqNumber } });
    },

    generateFiscalReceiptReport(filter: ReportIntervalFilterDto): Promise<AxiosResponse<ReportResponseDto>> {
        return httpService.post('/Pis/GenerateFiscalReceiptReport', filter);
    },

    async generateFiscalReceiptReportLines(filter: ReportIntervalFilterDto): Promise<FiscalReceiptReportDto> {
        const response = await httpService.post<RiskAssessmentCardReportDto>(
            `/Pis/GenerateFiscalReceiptReportLines`,
            filter
        );
        return plainToInstance(FiscalReceiptReportDto, response.data);
    },

    generateFiscalReport(filter: FiscalReportCommand): Promise<AxiosResponse<ReportResponseDto>> {
        return httpService.post('/Pis/GenerateFiscalReport', filter);
    },

    generateGpExamClaim(filter: ExamClaimCommand): Promise<AxiosResponse<ReportResponseDto>> {
        return httpService.post(`/Pis/GenerateGpExamClaim`, filter);
    },

    generatePatientListReport(filter: ExamClaimCommand): Promise<AxiosResponse<ReportResponseDto>> {
        return httpService.post(`/Pis/GeneratePatientListReport`, filter);
    },

    generateRiskAssessmentCardReport(filter: ReportIntervalFilterDto): Promise<AxiosResponse<ReportResponseDto>> {
        return httpService.post('/Pis/GenerateRiskAssessmentCardReport', filter);
    },

    exportFiscalReceiptReport(filter: ReportIntervalFilterDto): Promise<void> {
        return httpService.download('/Pis/ExportFiscalReceiptReport', { params: filter });
    },

    async generateRiskAssessmentCardReportLines(filter: ReportIntervalFilterDto): Promise<RiskAssessmentCardReportDto> {
        const response = await httpService.post<RiskAssessmentCardReportDto>(
            `/Pis/GenerateRiskAssessmentCardReportLines`,
            filter
        );
        return plainToInstance(RiskAssessmentCardReportDto, response.data);
    },

    async generatePatientListReportLines(filter: ExamClaimCommand): Promise<PatientListReportDto> {
        const response = await httpService.post<PatientListReportDto>(`/Pis/GeneratePatientListReportLines`, filter);
        return plainToInstance(PatientListReportDto, response.data);
    },

    async exportExcelPatientListReport(filter: ExamClaimCommand): Promise<void> {
        await httpService.download(`/Pis/ExportExcelPatientListReport`, { params: filter });
    }
};
