<template>
    <div>
        <data-table
            v-if="showDeputizedBy"
            caption="Заместван от"
            :headers="headers"
            :items="value.deputizedBy"
            class="my-5"
        />
        <data-table
            v-if="showDeputizing"
            caption="Замества"
            :headers="headers"
            :items="value.deputizing"
            class="my-5"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhisDoctorDeputyCheckResultDto } from '@/model/Nhis/DoctorDeputy/NhisDoctorDeputyCheckResultDto';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class NhisDoctorDeputyResult extends Vue {
        @Prop()
        value!: NhisDoctorDeputyCheckResultDto;

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'УИН на лекаря', value: 'doctorUin' },
                { text: 'Специалност', value: 'specialty' },
                { text: 'Роля', value: 'role' },
                { text: 'Практика номер', value: 'practiceNumber' },
                { text: 'Заместване от:', value: 'startDate', formatter: formatters.date },
                { text: 'Заместване до:', value: 'endDate', formatter: formatters.date }
            ];
        }

        private get showDeputizedBy(): boolean {
            return this.value?.deputizedBy?.length > 0;
        }

        private get showDeputizing(): boolean {
            return this.value?.deputizing?.length > 0;
        }
    }
</script>
