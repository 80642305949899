<template>
    <div>
        <v-dialog v-model="protocolDialogIsVisible">
            <template #activator="{ on }">
                <btn action="Refresh" :disabled="disabled" v-on="on">По статус на протокол</btn>
            </template>
            <v-card>
                <v-card-title>
                    Справка по статус на протокол
                    <v-spacer />
                    <btn icon action="Close" @click="protocolDialogIsVisible = false" />
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col md="3">
                            <date-picker v-model="fromDate" label="От дата" />
                        </v-col>
                        <v-col md="3">
                            <text-field v-model="nrn" label="НРН" />
                        </v-col>
                        <v-col md="3">
                            <text-field v-model="code" label="МКБ код" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <btn action="Refresh" block @click="statusCheck">Проверка на статус</btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="protocols && protocols.length > 0">
                        <v-col md="12">
                            <data-table :headers="protocolHeaders" title="Резултати от търсенето" :items="protocols">
                                <template #rowActions="{ item }">
                                    <btn icon action="Fill" @click="protocolFetch(item.nrn)" />
                                    <btn icon action="Search" @click="getProtocolPreview(item.nrn)" />
                                </template>
                            </data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="drugDialogIsVisible">
            <v-card>
                <v-card-title>
                    Лекарства по протокол <v-spacer />
                    <btn icon action="Close" @click="drugDialogIsVisible = false" />
                </v-card-title>
                <v-card-text>
                    <v-row v-if="prescription && prescription.protocolDrugs && prescription.protocolDrugs.length > 0">
                        <v-col md="12">
                            <data-table
                                :headers="drugHeaders"
                                :items="prescription.protocolDrugs"
                                group-by="nhifCode"
                                item-key="dosage"
                            >
                                <template #rowActions="{ item }">
                                    <btn icon action="Fill" @click="fillDrug(item)" />
                                </template>
                            </data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="previewDialogIsVisible">
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <btn icon action="Fill" @click="protocolFetch(protocolPreview.nrn)" />
                    <btn icon action="Close" @click="previewDialogIsVisible = false" />
                </v-card-title>
                <protocol-preview v-model="protocolPreview" />
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
    import { plainToInstance } from 'class-transformer';
    import { Component, Prop, Vue } from 'vue-property-decorator';

    // Vuetify bugfix: Глобално регистриран custom компонент (като btn), поставен в activator слота на диалог,
    // не се появява при първоначално зареждане на SPA, а чак след рутиране. Ако се импортира изрично, нещата се оправят.
    import Btn from '@/component/Common/Btn.vue';
    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { JsonWrapperDto } from '@/model/Common/JsonWrapperDto';
    import { ProtocolPreviewDto } from '@/model/Exam/DrugProtocol/Preview/ProtocolPreviewDto';
    import { PrescriptionNhifDto } from '@/model/Exam/Prescription/PrescriptionNhifDto';
    import { PrescriptionNhifTypeADto } from '@/model/Exam/Prescription/PrescriptionNhifTypeADto';
    import { NhifCommand } from '@/model/Nhif/NhifCommand';
    import { NhifProtocolSearchCommand } from '@/model/Nhif/Protocol/NhifProtocolSearchCommand';
    import { NhifStatusProtocolResultDto } from '@/model/Nhif/Protocol/NhifStatusProtocolResultDto';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { visitCacheService } from '@/service/Exam/VisitCacheService';
    import { notifierService } from '@/service/Infrastructure/NotifierService';
    import { nhifIntegrationService } from '@/service/Integration/NhifIntegrationService';
    import { nhifProtocolService } from '@/service/Nhif/NhifProtocolService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';
    import ProtocolPreview from '@/view/Exam/DrugProtocol/ProtocolPreview.vue';

    @Component({
        components: { ProtocolPreview, Btn }
    })
    export default class PatientProtocolStatus extends Vue {
        @Prop()
        private document!: PrescriptionNhifDto | PrescriptionNhifTypeADto;

        @Prop()
        disabled!: boolean;

        private protocolDialogIsVisible: boolean = false;
        private drugDialogIsVisible: boolean = false;
        private previewDialogIsVisible: boolean = false;
        private nrn: string = '';
        private fromDate: Date | null = null;
        private code: string = '';
        private protocols: NhifStatusProtocolResultDto[] = [];
        private prescription: PrescriptionNhifDto | null = null;
        private protocolPreview: ProtocolPreviewDto = new ProtocolPreviewDto();

        private get protocolHeaders(): IDataTableHeader[] {
            return [
                { text: 'НРН', value: 'nrn' },
                { text: 'Дата на заявяване', value: 'requestDate', formatter: formatters.date },
                { text: 'Статус', value: 'status' },
                { text: 'Начална дата на валидност', value: 'fromDate', formatter: formatters.date },
                { text: 'Крайна дата на валидност', value: 'toDate', formatter: formatters.date },
                { text: 'Период на валидност', value: 'period' },
                { text: 'МКБ', value: 'mkb' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private get drugHeaders(): IDataTableHeader[] {
            return [
                { text: 'НЗОК код', value: 'nhifCode' },
                { text: 'Количество акт. в-во', value: 'quantity' },
                { text: 'Дозировка', value: 'dosage' },
                { text: 'ATC код', value: 'atcCode' },
                { text: 'МКБ', value: 'mkb' },
                { text: 'Международно наименование', value: 'internationalName' },
                { text: 'Търговско наименование', value: 'tradeName' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async statusCheck() {
            const requestDto = this.createNhifProtocolSearchCommand();
            const jsonWrapper = await nhifProtocolService.getStatusProtocolJson(requestDto);
            if (jsonWrapper.data) {
                const response = await nhifIntegrationService.postStatusProtocolSearch(jsonWrapper.data);
                if (response?.data) {
                    const responseJson = new JsonWrapperDto();
                    responseJson.data = JSON.stringify(response.data);
                    const result = await nhifProtocolService.getProtocolSearchResult(responseJson);
                    if (result?.error?.length > 0) {
                        await notifierService.showWarning('Внимание', `${result.error}`);
                    } else if (result?.protocolStatusResults) {
                        this.protocols = result.protocolStatusResults;
                    }
                }
            }
        }

        private createNhifProtocolSearchCommand(): NhifProtocolSearchCommand {
            const requestDto = new NhifProtocolSearchCommand(
                currentPatientCache.value.key,
                userContextCache.current?.doctorUin ?? null,
                userContextCache.current?.deputyDoctorUin ?? null,
                userContextCache.current?.deputyDoctorUin ? userContextCache.current?.deputyDoctorIsHired : null
            );
            requestDto.nrn = this.nrn;
            requestDto.fromDate = this.fromDate;
            requestDto.code = this.code;
            requestDto.doctorEmployeeSeqNumber = userContextCache.current?.employeeSeqNumber ?? 0;
            return requestDto;
        }

        private async protocolFetch(protocolNrn: string) {
            const fetchJsonCommand = this.createProtocolFetchJsonCommand(protocolNrn);
            const jsonResponse = await nhifProtocolService.getProtocolFetchJson(fetchJsonCommand);
            if (jsonResponse?.data) {
                const response = await nhifIntegrationService.postProtocolFetch(jsonResponse.data);
                console.log(response);
                if (response?.data) {
                    const result = await nhifProtocolService.getProtocolFetchResult(
                        JSON.stringify(response.data),
                        this.document.key.examId,
                        this.document.formTypeCode
                    );
                    if (result?.nhisPrescription?.error?.length > 0) {
                        await notifierService.showWarning('Внимание', `${result.nhisPrescription.error}`);
                    } else if (result) {
                        if (
                            !currentVisitCache.value.exam?.examDiagnoses.some(
                                (diagnosis) => diagnosis.id === result.examDiagnosis1Id
                            )
                        ) {
                            await this.reloadVisitCache();
                        }
                        const prescription = plainToInstance(
                            this.document.formTypeCode === PrescriptionFormTypeCode.Nhif5a
                                ? PrescriptionNhifTypeADto
                                : PrescriptionNhifDto,
                            result
                        );
                        this.$emit('input', prescription);
                        this.protocolDialogIsVisible = false;
                        this.previewDialogIsVisible = false;
                        this.prescription = result;
                        this.drugDialogIsVisible = true;
                    }
                }
            }
        }

        private async reloadVisitCache() {
            const visitCache = await visitCacheService.getVisitCacheById(this.document.key.examId);
            await currentVisitCache.set(visitCache);
            this.$emit('reload-diagnoses');
        }

        private async getProtocolPreview(protocolNrn: string) {
            const fetchJsonCommand = this.createProtocolFetchJsonCommand(protocolNrn);
            const jsonResponse = await nhifProtocolService.getProtocolFetchJson(fetchJsonCommand);
            if (jsonResponse?.data) {
                const response = await nhifIntegrationService.postProtocolFetch(jsonResponse.data);
                console.log(response);
                if (response?.data) {
                    const result = await nhifProtocolService.getProtocolPreviewResult(
                        JSON.stringify(response.data),
                        this.document.key.examId,
                        this.document.formTypeCode
                    );
                    if (result?.error) {
                        await notifierService.showWarning('Внимание', `${result.error}`);
                    } else if (result) {
                        this.protocolDialogIsVisible = false;
                        this.drugDialogIsVisible = false;
                        this.previewDialogIsVisible = true;
                        this.protocolPreview = result;
                    }
                }
            }
        }

        private fillDrug(drug: NhifDrugDto) {
            this.$emit('input-drug', drug);
        }

        private createProtocolFetchJsonCommand(protocolNrn: string): NhifCommand {
            const request = new NhifCommand(
                currentPatientCache.value.key,
                userContextCache.current?.doctorUin ?? null,
                userContextCache.current?.deputyDoctorUin ?? null
            );
            request.deputyDoctorIsHired = userContextCache.current?.deputyDoctorUin
                ? userContextCache.current?.deputyDoctorIsHired
                : null;
            request.doctorEmployeeSeqNumber = userContextCache.current?.employeeSeqNumber ?? 0;
            request.nrn = protocolNrn;
            return request;
        }
    }
</script>
