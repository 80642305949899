export class MessageDto {
    constructor(
        public id: number,
        public messageTypeCode: string,
        public createDate: Date
    ) {}

    public title: string | null = null;
    public text: string | null = null;
    public expiryDate: Date | null = null;
    public sendDate: Date | null = null;
}
