<template>
    <div>
        <v-card-title class="mb-3 pt-0">Данни за пациент</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="6" md="2" class="pa-1">
                    <PidTypePicker v-model="currentPatient.personIdentification.pidTypeCode" disabled />
                </v-col>
                <v-col cols="6" md="4" class="pa-1">
                    <text-field
                        v-model="currentPatient.personIdentification.identifier"
                        disabled
                        label="Идентификатор"
                    />
                </v-col>
                <v-col cols="4" md="2" class="pa-1">
                    <text-field v-model="currentPatient.age" disabled label="Възраст" />
                </v-col>
                <v-col cols="4" md="2" class="pa-1">
                    <text-field v-model="currentPatient.rhif" disabled label="РЗОК" />
                </v-col>
                <v-col cols="4" md="2" class="pa-1">
                    <text-field v-model="currentPatient.rhifArea" disabled label="Здр. район" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pa-1">
                    <text-field v-model="currentPatient.firstName" disabled label="Име" />
                </v-col>
                <v-col cols="4" class="pa-1">
                    <text-field v-model="currentPatient.middleName" disabled label="Презиме" />
                </v-col>
                <v-col cols="4" class="pa-1">
                    <text-field v-model="currentPatient.lastName" disabled label="Фамилия" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pa-1">
                    <text-field v-model="currentPatient.address.cityName" disabled label="Нас. място" />
                </v-col>
                <v-col cols="4" class="pa-1">
                    <text-field v-model="currentPatient.address.street" disabled label="Улица" />
                </v-col>
                <v-col cols="4" class="pa-1">
                    <text-field v-model="currentPatient.address.streetNumber" disabled label="Номер" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pa-1">
                    <text-field v-model="currentPatient.address.neighborhood" disabled label="Ж.к." />
                </v-col>
                <v-col cols="2" class="pa-1">
                    <text-field v-model="currentPatient.address.block" disabled label="Блок" />
                </v-col>
                <v-col cols="2" class="pa-1">
                    <text-field v-model="currentPatient.address.entrance" disabled label="Вход" />
                </v-col>
                <v-col cols="2" class="pa-1">
                    <text-field v-model="currentPatient.address.floor" disabled label="Етаж" />
                </v-col>
                <v-col cols="2" class="pa-1">
                    <text-field v-model="currentPatient.address.apartment" disabled label="Ап." />
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import PidTypePicker from '@/component/Picker/PidTypePicker.vue';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    @Component({ components: { PidTypePicker } })
    export default class PatientInfo extends Vue {
        private get currentPatient() {
            return currentPatientCache.value;
        }
    }
</script>
