import { Transform, Type } from 'class-transformer';

import { PrescriptionBaseDto } from '@/model/Exam/Prescription/PrescriptionBaseDto';
import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';

export class PrescriptionNhifDto extends PrescriptionBaseDto {
    public examDiagnosis1Id: number | null = null;
    public isDiagnosis1Add: boolean = false;
    public examDiagnosis2Id: number | null = null;
    public isDiagnosis2Add: boolean = false;
    public examDiagnosis3Id: number | null = null;
    public isDiagnosis3Add: boolean = false;

    // Данни попълвани от лекаря
    public protocolTypeCode: string = '';
    public protocolNumber: string = '';
    public protocolDays: number | null = null;
    public protocolDateTime: Date | null = null;

    // Ном. на рецептурна книжка
    public prescriptionBookNumber: string = '';
    public isPregnant: boolean = false;
    public isNursing: boolean = false;

    // Код на лекарствен продукт
    @Transform(({ value }) => value ?? null)
    @Type(() => NhifDrugDto)
    public nhifDrug: NhifDrugDto | null = null;

    // Код на лекарствен продукт
    @Transform(({ value }) => value ?? new NhifDrugDto())
    @Type(() => NhifDrugDto)
    public nhifDrug2: NhifDrugDto = new NhifDrugDto();

    // Код на лекарствен продукт
    @Transform(({ value }) => value ?? new NhifDrugDto())
    @Type(() => NhifDrugDto)
    public nhifDrug3: NhifDrugDto = new NhifDrugDto();

    public supplements: string | null = null;
    public ambulatorySheetNumberNumber: string = '';
    public totalCostPatient: number = 0;

    //Данни за лекаря
    public doctorPhoneNumber: string = '';

    //Данни от е-протокола
    public protocolDrugs: NhifDrugDto[] | null = null;
    // Към 2023-03 изглежда не се използва, но се връща от API-то.
    public doctorSpecialtyNhifCode: string = '';
}
