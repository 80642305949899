import { NhifContractTypeCode } from '@/enum/Practice/NhifContractType';
import { dateUtil } from '@/util/DateUtil';

export class PracticeNhifContractDto {
    public id: number = 0;
    public practiceId: number = 0;
    // МВасилев: слагам по подразбиране ПИМП.
    public nhifContractTypeCode: string = NhifContractTypeCode.Pimp;
    public contractNumber: string = '';
    public contractDate: Date = new Date();
    public practiceLegalName: string = '';
    public addressByContract: string = '';
    public addressByActivity: string = '';

    public get title() {
        return `${this.contractNumber} / ${dateUtil.formatBgDate(this.contractDate)}, тип: ${
            this.nhifContractTypeCode
        }`;
    }
}
