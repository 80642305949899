<template>
    <div>
        <v-menu v-model="pickerIsVisible" :close-on-content-click="false" transition="scale-transition" offset-y>
            <template #activator="{ on }">
                <v-text-field
                    v-model="time"
                    dense
                    :label="label"
                    :name="'timePicker' + _uid"
                    :class="required ? 'pa-0 required' : 'pa-0'"
                    :readonly="readonly"
                    :disabled="disabled"
                    :rules="required ? [$validator.required] : []"
                    @keyup="$emit('keyup', ...arguments)"
                >
                    <template slot="append">
                        <v-icon :disabled="disabled" v-on="on" @click="timePickerIsVisible = true">
                            mdi-clock-outline
                        </v-icon>
                    </template>
                </v-text-field>
            </template>
            <v-time-picker
                v-model="time"
                dense
                :readonly="readonly"
                :disabled="disabled"
                :locale="locale"
                :format="format"
                @click:minute="pickerIsVisible = false"
                @change="$emit('input', time)"
            ></v-time-picker>
        </v-menu>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    @Component
    export default class TimePicker extends Vue {
        @Prop()
        private value!: Date | null;

        @Prop()
        private readonly!: boolean;

        @Prop()
        private disabled!: boolean;

        @Prop()
        private required!: boolean;

        @Prop({ default: 'bg-BG' })
        private locale!: string;

        @Prop({ default: 'Час' })
        private label!: string;

        @Prop({ default: '24hr' })
        private format!: string;

        private time: Date | null = null;

        private pickerIsVisible: boolean = false;

        mounted() {
            this.time = this.value;
        }

        @Watch('value')
        private onValueChanged() {
            this.time = this.value;
        }
    }
</script>
