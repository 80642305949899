<template>
    <div>
        <v-card-title class="mb-5">
            <v-spacer></v-spacer>
            <div class="ml-3">
                <v-menu transition="slide-y-transition" bottom>
                    <template #activator="{ on, attrs }">
                        <btn action="Print" v-bind="attrs" v-on="on" />
                    </template>
                    <v-list>
                        <v-list-item @click="printImmunWithoutPreview">
                            <v-list-item-title>Печат имунизации</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="printRotaWithoutPreview">
                            <v-list-item-title>Инф. съгласие ротавируси</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="printHpvWithoutPreview">
                            <v-list-item-title>Инф. съгласие папилома вирус</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="printImmun">
                            <v-list-item-title>Печат имунизации</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="printRota">
                            <v-list-item-title>Инф. съгласие ротавируси</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="printHpv">
                            <v-list-item-title>Инф. съгласие папилома вирус</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-card-title>
        <data-table
            title="Имунизации"
            :headers="tableHeaders"
            :items="items"
            :loading="$loading.isVisible"
            class="elevation-1 mb-5"
            disable-pagination
            sort-by="occurrenceDate"
        >
            <template #actions>
                <btn action="New" :to="`/Exam/Immunization/Create/${examId}`" />
                <btn action="Export" @click="exportExcel" />
            </template>
            <template #rowActions="{ item }">
                <btn icon action="Edit" @click="editImmunization(item)" />
            </template>
        </data-table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PatientImmunizationDto } from '@/model/Patient/Immunization/PatientImmunizationDto';
    import { patientImmunizationService } from '@/service/Patient/PatientImmunizationService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class ImmunizationExamView extends Vue {
        @Prop()
        private examId!: number;

        private items: PatientImmunizationDto[] = [];

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Код РЗИ:', value: 'immunization.rhiCode' },

                {
                    text: 'Код НЗОК:',
                    value: 'immunization.nhifCode'
                },
                {
                    text: 'Наименование:',
                    value: 'immunization.name'
                },
                { text: 'Дата на поставяне:', value: 'occurrenceDate', formatter: formatters.date },
                { text: 'Серия:', value: 'serialNumber' },
                { text: 'Резултат:', value: 'resultField' },
                { text: '', value: 'actionButtons' }
            ];
        }

        private editImmunization(item: PatientImmunizationDto) {
            this.$router.push(`/Exam/Immunization/Edit/${item.id}`);
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                const response = await patientImmunizationService.getImmunizationsByExamId(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private printImmun() {
            if (currentPatientCache.value.key.patientId) {
                const { patientId } = currentPatientCache.value.key;
                this.$router.push(`/Print/Patient.PatientImmunization/${patientId}`);
            } else {
                // Todo показване на грешка
            }
        }

        private async printImmunWithoutPreview() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = currentPatientCache.value.key.patientId.toString();
                const report = 'Patient.PatientImmunization';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private printHpv() {
            if (currentPatientCache.value.key.patientId) {
                const { patientId } = currentPatientCache.value.key;
                this.$router.push(`/Print/Patient.PatientInformedConsentHpv/${patientId}`);
            } else {
                // Todo показване на грешка
            }
        }

        private async printHpvWithoutPreview() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = currentPatientCache.value.key.patientId.toString();
                const report = 'Patient.PatientInformedConsentHpv';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private printRota() {
            if (currentPatientCache.value.key.patientId) {
                const { patientId } = currentPatientCache.value.key;
                this.$router.push(`/Print/Patient.PatientInformedConsentRota/${patientId}`);
            } else {
                // Todo показване на грешка
            }
        }

        private async printRotaWithoutPreview() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = currentPatientCache.value.key.patientId.toString();
                const report = 'Patient.PatientInformedConsentRota';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async exportExcel() {
            this.$loading.show();
            try {
                await patientImmunizationService.exportPatientImmunizations(currentPatientCache.value.key.patientId);
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.getTableItems();
        }

        @Watch('shouldGetItems', { immediate: true })
        onShouldGetItems(newVal: boolean): void {
            if (newVal) {
                this.getTableItems();
            }
        }
    }
</script>
