import { plainToInstance } from 'class-transformer';

import { PatientUpdateWorkplaceDto } from '@/model/Patient/PatientUpdateWorkplaceDto';
import { PatientWorkplaceDto } from '@/model/Patient/PatientWorkplaceDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientWorkplaceService = {
    async getAllPatientWorkplaces(patientId: number): Promise<PatientWorkplaceDto[]> {
        const response = await httpService.get<PatientWorkplaceDto[]>(
            `/PatientWorkplace/GetAllPatientWorkplacesQuery/${patientId}`,
            {
                params: { patientId }
            }
        );
        return plainToInstance(PatientWorkplaceDto, response.data);
    },

    async getActivePatientWorkplaces(patientId: number): Promise<PatientWorkplaceDto[]> {
        const response = await httpService.get<PatientWorkplaceDto[]>(
            `/PatientWorkplace/GetActivePatientWorkplacesQuery/${patientId}`,
            {
                params: { patientId }
            }
        );
        return plainToInstance(PatientWorkplaceDto, response.data);
    },

    async updatePatientWorkplaces(data: PatientUpdateWorkplaceDto): Promise<void> {
        await httpService.post('/PatientWorkplace/UpdatePatientWorkplaces/', data);
    },

    async checkWorkplaceValidity(patientWorkplaceId: number): Promise<string> {
        const response = await httpService.get<string>(
            `/PatientWorkplace/CheckWorkplaceValidity/${patientWorkplaceId}`
        );
        return response.data;
    },

    async checkWorkplaceIsUseInSickSheet(patientWorkplaceId: number): Promise<boolean> {
        const response = await httpService.get<boolean>(
            `/PatientWorkplace/CheckWorkplaceIsUseInSickSheet/${patientWorkplaceId}`
        );
        return response.data;
    }
};
