import { Type } from 'class-transformer';

import { Report365DispensaryActivityRowDto } from '@/model/Report/Rhi/Report365/Report365DispensaryActivityRowDto';

export class Report365DispensaryActivityDto {
    public name: string = '';

    @Type(() => Report365DispensaryActivityRowDto)
    public dispensaryActivities: Report365DispensaryActivityRowDto[] = [];
}
