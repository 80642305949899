<template>
    <ExamLayout :can-delete="false" :title="'Бързо добавяне'" can-save @updated="create">
        <v-card>
            <v-col md="8">
                <v-select
                    v-model="referral4SubtypeId"
                    class="required"
                    :rules="[$validator.required]"
                    :items="referralSubtypes"
                    item-text="name"
                    item-value="id"
                    label="Тип посещение"
                />
            </v-col>
            <v-card-text>
                <search-field v-model="search" label="Търсене" hide-details />
                <v-treeview
                    v-model="selection"
                    :search="search"
                    item-key="code"
                    selectable
                    return-object
                    open-on-click
                    :items="items"
                    selection-type="independent"
                    @input="onSelection"
                >
                </v-treeview>
            </v-card-text>
        </v-card>
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { NhifVisitReasonCode } from '@/enum/Exam/NhifVisitReasonCode';
    import { ReferralTypeCode } from '@/enum/Exam/ReferralTypeCode';
    import { Referral4QuickAddDto } from '@/model/Exam/Referral/Referral4QuickAddDto';
    import { FundServiceDto } from '@/model/Nomenclature/FundServiceDto';
    import { ReferralSubtypeDto } from '@/model/Nomenclature/ReferralSubtypeDto';
    import { examService } from '@/service/Exam/ExamService';
    import { referral4QuickAddService } from '@/service/Exam/Referral/Referral4QuickAddService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { referralSubtypeService } from '@/service/Nomenclature/ReferralSubtypeService';

    @Component({ components: { ExamLayout } })
    export default class Referral4QuickAdd extends Vue {
        @Prop()
        private examId!: number;

        private items: Referral4QuickAddDto[] = [];
        private selection: FundServiceDto[] = [];
        private referralSubtypes: ReferralSubtypeDto[] = [];
        private referral4SubtypeId: number = 0;
        private search: string = '';

        private async mounted() {
            await this.getServices();
            await this.getReferralSubtypes();
            await this.getCorrectReferralSubtype();
        }

        private async getServices() {
            this.items = [];
            const response = await referral4QuickAddService.getAllServices();
            if (response?.data) {
                this.items = response.data;
            }
        }

        private async getReferralSubtypes() {
            this.referralSubtypes = [];
            const response = await referralSubtypeService.getReferral4Subtypes();
            if (response?.data) {
                this.referralSubtypes = response.data;
            }
        }

        private async getCorrectReferralSubtype() {
            const visitReasonCode = await examService.getExamVisitReason(this.examId);
            let subtypeName = '';
            switch (visitReasonCode) {
                case NhifVisitReasonCode.Dispensary: {
                    subtypeName = 'Диспансерно интердисциплинарно наблюдение';
                    break;
                }
                case NhifVisitReasonCode.ChildPrevention: {
                    subtypeName = 'Детско здравеопазване';
                    break;
                }
                case NhifVisitReasonCode.MotherPrevention: {
                    subtypeName = 'Майчино здравеопазване';
                    break;
                }
                case NhifVisitReasonCode.AdultPrevention: {
                    subtypeName = 'Профилактика на ЗОЛ над 18';
                    break;
                }
                default: {
                    subtypeName = 'Остро';
                }
            }
            this.referral4SubtypeId =
                this.referralSubtypes.find((type) => type.name.includes(subtypeName))?.id ??
                this.referralSubtypes[0].id;
        }

        private async create() {
            this.$loading.show();
            if (this.selection.length > 0) {
                const selectedServices = new Referral4QuickAddDto();
                selectedServices.children = this.selection;
                selectedServices.examId = this.examId;
                selectedServices.referral4SubtypeId = this.referral4SubtypeId;
                const addedDocumentsCount = (await referral4QuickAddService.create(selectedServices)).data;
                if (addedDocumentsCount) {
                    eventBus.$emit(
                        'create-referral-event',
                        addedDocumentsCount,
                        ReferralTypeCode.Referral4,
                        'Бързо добавяне',
                        addedDocumentsCount
                    );
                    this.$router.push(`/Exam/Referral4/${this.examId}`);
                }
            }
            this.$loading.hide();
        }

        private onSelection() {
            const lastSelected = this.selection[this.selection.length - 1];
            if (lastSelected && !lastSelected.seqNumber) {
                this.selection.pop();
            }
        }
    }
</script>
