import moment from 'moment';

// Датите идват от сървъра в локалната за сървъра зона без информация за зоната, например идва '2021-11-26T13:13:00'.
// По тези string-ове се създават Date обекти. Те не поддържат зона и съхраняват UTC стойност. Стойността в Date обекта
// е отместена с локалната за browser-а зона. При български зимни настройки, Date обектът ще съдържа 11:13 ч., а не 13:13 ч.
// date.toISOString() и date.toJSON() форматират датата с нулева зона така: '2021-11-26T11:13:00Z'. По подразбиране това се изпраща към сървъра.
// Този формат е ок, когато сървърът и browser-ът са в една зона, защото сървърът вижда Z и превръща часа в локалната за сървъра зона.
// Проблемът е, че често се изпраща .net DateTime.MinValue, което от сървъра идва като '0001-01-01Т00:00:00',
// но към сървъра се връща като '0000-12-31Т22:00:00Z', което е вярно, но не може да се десериализира като .net DateTime.
// С moment.format() текстът се подменя с '2021-11-26T13:13:00', т.е. часът е в локалната за browser-а зона без информация за зоната.
// Така към сървъра се подава същото, каквото той е изпратил първоначално. Сървърът приема, че часът е в локалната за сървъра зона.
// Това работи в каквато и зона да се постави browser-ът, защото new Date() отмества точно колкото moment.format() наобратно.
const serializeDateWithoutZone = (dateTime: Date) => moment(dateTime).format('YYYY-MM-DDTHH:mm:ss');

// Тази рекурсивна фукнция е AxiosRequestTransformer и не може да се постави вътре в axiosDateUtil.
// Кодът е заимстван от https://stackoverflow.com/questions/70689305/customizing-date-serialization-in-axios
const serializeDatesWithoutZone = (data: unknown): unknown => {
    if (data instanceof Date) {
        return serializeDateWithoutZone(data);
    }
    if (Array.isArray(data)) {
        return data.map(serializeDatesWithoutZone);
    }
    if (data && typeof data === 'object') {
        return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, serializeDatesWithoutZone(value)]));
    }
    return data;
};

// Проверява дали текстът е от вида '2023-03-13Т10:14:53', евентуално с добавени милисекунди (.672) и/или часова зона (Z или +02:00).
const isIsoDateString = (value: string) => {
    const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?<ms>\.\d*)?(?<zone>Z|\+\d{2}:\d{2})?$/u;
    return isoDateFormat.test(value);
};

// Тази рекурсивна фукнция е AxiosResponseTransformer и не може да се постави вътре в axiosDateUtil.
const replaceIsoDateStringsWithDates = (data: Record<string, unknown>) => {
    if (typeof data === 'string' && isIsoDateString(data)) {
        return new Date(data);
    }
    if (data && typeof data === 'object') {
        for (const key of Object.keys(data)) {
            const value = data[key];
            if (typeof value === 'string' && isIsoDateString(value)) {
                data[key] = new Date(value);
            } else {
                replaceIsoDateStringsWithDates(value as Record<string, unknown>);
            }
        }
    }
    return data;
};

const axiosDateUtil = {
    // От 2023-04 вместо replacer функция се използва transformRequest: [serializeDatesWithoutZone, ...
    // Called recursively, where this is the current node of the object, key the property being converted,
    // and value a pre-transformed property value, being a string when original was a Date.
    jsonStringifyDateReplacer(this: Record<string, unknown>, key: string, value: unknown): unknown {
        const rawValue = this[key];
        if (rawValue instanceof Date) {
            return serializeDateWithoutZone(rawValue);
        }
        return value;
    }
};

export { axiosDateUtil, replaceIsoDateStringsWithDates, serializeDatesWithoutZone, serializeDateWithoutZone };
