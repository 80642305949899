import { Route, RouteConfig } from 'vue-router';

const helpChildRoutes: RouteConfig[] = [
    {
        path: 'Contact',
        components: {
            help: () => import('@/view/Help/Contact.vue')
        }
    },
    {
        path: 'Contact/:practiceId',
        props: {
            help: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            help: () => import('@/view/Help/Contact.vue')
        }
    },
    {
        path: 'LocalServer',
        components: {
            help: () => import('@/view/Help/LocalServer.vue')
        }
    },
    {
        path: 'UserGuide',
        props: {
            help: (route) => ({
                path: route.query?.path?.toString() ?? '',
                hash: route.hash
            })
        },
        components: {
            help: () => import('@/view/Help/UserGuide.vue')
        }
    },
    {
        path: 'Video',
        components: {
            help: () => import('@/view/Help/Video.vue')
        }
    }
];

export const helpRoutes: RouteConfig = {
    path: '/Help',
    component: () => import('@/view/Help/HelpMenu.vue'),
    children: helpChildRoutes
};
