<template>
    <v-form ref="form" v-model="isFormValid">
        <v-card class="pb-2">
            <v-card-title>
                Извличане на Е-Направление
                <v-spacer />
                <div v-if="showClosePointer" class="red--text pointer" @click="close">x</div>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col v-if="!isPatientLoaded" md="3" cols="6">
                        <PidTypePicker
                            v-model="value.pidTypeCode"
                            :required="isNrnEmpty"
                            @blur="$emit('check-identifier')"
                        />
                    </v-col>
                    <v-col v-if="!isPatientLoaded" md="3" cols="6">
                        <text-field
                            v-model="value.identifier"
                            :required="isNrnEmpty"
                            label="Идентификатор"
                            :rules="isNrnEmpty ? [$validator.identifier(value.pidTypeCode, value.identifier)] : []"
                        ></text-field>
                    </v-col>
                    <v-col md="3" cols="6">
                        <text-field
                            v-model="value.nrn"
                            :required="isNrnRequired"
                            label="НРН номер"
                            :rules="isNrnRequired ? [$validator.nrn] : []"
                            @blur="checkForExistingIncomingReferralExam"
                        ></text-field>
                    </v-col>
                    <v-col md="3" cols="6">
                        <date-picker v-model="value.occurrenceDate" label="Дата на направлението" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <btn action="Refresh" class="ml-2" :disabled="isLoading" @click="postExtractionRequest">
                    Извличане
                </btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import PidTypePicker from '@/component/Picker/PidTypePicker.vue';
    import { NhisReferralExtractionCommand } from '@/model/Nhis/Referral/NhisReferralExtractionCommand';
    import { incomingReferralExamService } from '@/service/Exam/IncomingReferralExamService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { PidTypePicker }
    })
    export default class NhisReferralExtractionEditor extends Vue {
        @Prop()
        private value!: NhisReferralExtractionCommand;

        @Prop()
        private showClosePointer!: boolean;

        private isFormValid: boolean = false;

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private postExtractionRequest() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isFormValid) {
                this.populateModel();
                this.$emit('post-request', this.value);
                this.resetValidation();
            }
        }

        private populateModel() {
            if (this.isPatientLoaded) {
                this.value.patientVersionKey = currentPatientCache.value.key;
            }

            this.value.practiceNumber = userContextCache.current?.practiceNumber ?? '';
            this.value.doctorUin = userContextCache.current?.doctorUin ?? '';
            this.value.doctorDeputyUin = userContextCache.current?.deputyDoctorUin ?? null;
        }

        private get isPatientLoaded() {
            return currentPatientCache.isLoaded;
        }

        private get isNrnEmpty() {
            return this.value?.nrn === null || this.value?.nrn === '';
        }

        private get isNrnRequired() {
            return (
                !this.isPatientLoaded &&
                (this.value.identifier === null ||
                    this.value.identifier === '' ||
                    this.value.pidTypeCode === null ||
                    this.value.pidTypeCode === '')
            );
        }

        private resetValidation() {
            (this.$refs.form as Vue & { resetValidation: () => boolean }).resetValidation();
        }

        private async checkForExistingIncomingReferralExam() {
            if (this.value.nrn !== null && this.value.nrn !== '') {
                const result = await incomingReferralExamService.checkForExistingIncomingReferralExam(
                    userContextCache.currentPracticeId ?? 0,
                    this.value.nrn
                );

                if (result) {
                    this.$notifier.showWarning('Внимание', result);
                }
            }
        }

        private close() {
            this.$emit('close-dialog');
            this.resetValidation();
        }
    }
</script>
