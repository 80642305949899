<template>
    <v-card>
        <v-card-title>
            Придружаващи документи
            <btn icon action="New" @click="addDocumentCards" />
        </v-card-title>
        <alert :value="isAlertActive" type="warning" prominent>
            Препоръчително е да има поне един записан Придружаващ документ!
        </alert>
        <v-card-text v-for="(docCard, i) in value" :key="i">
            <v-row>
                <v-col cols="2">
                    <text-field :value="assignIndex(docCard, i)" :label="`${i + 1}`" disabled />
                </v-col>
                <v-col cols="6">
                    <text-field v-model="value[i].documentName" required label="Описание на документа" />
                </v-col>
                <v-col cols="10" md="3">
                    <date-picker v-model="value[i].documentDateTime" label="Дата:" required />
                </v-col>
                <btn icon action="Delete" @click="removeDocCard(i)" />
            </v-row>
            <v-row>
                <v-col cols="4">
                    <dropdown
                        v-model="value[i].eDocumentTypeCode"
                        required
                        :items="eDocumentTypes"
                        :rules="[$validator.required]"
                        item-value="code"
                        label="Вид Е-документ"
                    >
                    </dropdown>
                </v-col>
                <v-col cols="4">
                    <text-field v-model="value[i].number" label="Номер" @input="onNumberChange(value[i])" />
                </v-col>
                <v-col cols="2">
                    <v-checkbox v-model="value[i].isNumberNrn" label="Номерът е НРН" disabled></v-checkbox>
                </v-col>
                <v-col v-if="!value[i].eDocumentTypeCode || !value[i].number" cols="2">
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-icon color="red" v-bind="attrs" v-on="on">mdi-alert</v-icon>
                        </template>
                        <span>Документа не е пълен</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card-text>
        <v-row class="ma-3">
            <alert type="info">
                Документите придружаващи Направление за ТЕЛК се описват в бланката, но не се прикачват към нея от
                програмата. Придружаващи документи се прикачват и изпращат в НЗИС през "Електронна система Медицинска
                експертиза" (ibd.mh.government.bg). Ако искате да запазите копие от тези документи в програмата, можете
                да ги съхраните в
                <router-link :to="`/Exam/FileUpload/Create/${visitId}`">меню Прикачени документи</router-link>.
            </alert>
        </v-row>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { ReferralTelkDocumentCardDto } from '@/model/Exam/Referral/ReferralTelkDocumentCardDto';
    import { NhisEdocumentTypeDto } from '@/model/Nomenclature/NhisEdocumentTypeDto';
    import { nhisEdocumentTypeService } from '@/service/Nomenclature/NhisEdocumentTypeService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component
    export default class ReferralTelkDocumentCards extends Vue {
        @Prop()
        private value!: ReferralTelkDocumentCardDto[];

        private index: number = 1;
        private isAlertActive: boolean = false;
        private eDocumentTypes: NhisEdocumentTypeDto[] = [];

        private get visitId() {
            return currentVisitCache.value.id;
        }

        private async mounted() {
            const response = await nhisEdocumentTypeService.getEdocumentTypes();
            this.eDocumentTypes = response.data;
        }

        // FileUpload/Create/:visitId

        private addDocumentCards() {
            this.value.push(new ReferralTelkDocumentCardDto());
            this.index++;
        }

        private removeDocCard(indexDocumentCards: number) {
            arrayUtil.removeAt(this.value, indexDocumentCards);
            this.index--;
        }

        private assignIndex(docCard: ReferralTelkDocumentCardDto, index: number) {
            docCard.position = index + 1;
        }

        private onNumberChange(docCard: ReferralTelkDocumentCardDto) {
            if (docCard.number.length > 0) {
                const pattern = /^[0-9]{5}[A-F0-9]{7}/u;
                const nrnLength = 12;
                docCard.isNumberNrn = docCard.number.length === nrnLength && pattern.test(docCard.number);
            } else {
                docCard.isNumberNrn = false;
            }
        }

        @Watch('value', { deep: true })
        private onDocumentCardsLengthChange(docs: ReferralTelkDocumentCardDto[]) {
            if (docs && docs.length > 0) {
                this.isAlertActive = docs.length === 0 ? true : false;
                this.index = this.value[this.value.length - 1].position;
            }
        }
    }
</script>
