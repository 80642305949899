import { FiscalDeviceOperatorDto } from './FiscalDeviceOperatorDto';
import { FiscalMemoryDto } from './FiscalMemoryDto';

export class FiscalDeviceDto {
    public practiceId: number = 0;
    public seqNumber: number = 0;
    public code: string | null = null;
    public displayName: string = '';
    public isDeleted: boolean = false;
    public FiscalDeviceOperators: FiscalDeviceOperatorDto[] = [];
    public FiscalMemories: FiscalMemoryDto[] = [];

    public get isDeletedString() {
        return this.isDeleted ? 'Да' : 'Не';
    }
}
