import { Report365RowBase } from './Report365RowBase';

export class Report365AbortionByAgeRowDto extends Report365RowBase {
    public spontaneous: number | null = null;
    public other: number | null = null;
    public byRequest: number | null = null;
    public byMedicalReason: number | null = null;

    public get therapeutic(): number | null {
        return (
            (this.byRequest === null ? 0 : Number(this.byRequest)) +
            (this.byMedicalReason === null ? 0 : Number(this.byMedicalReason))
        );
    }

    public get abortions(): number | null {
        return (
            (this.spontaneous === null ? 0 : Number(this.spontaneous)) +
            (this.therapeutic === null ? 0 : Number(this.therapeutic)) +
            (this.other === null ? 0 : Number(this.other))
        );
    }
}
