import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { userContextCache } from '@/store/User/UserContextCache';

import { MenuItemModel } from './MenuItemModel';

const addNhifReportItems = (
    menuItems: MenuItemModel[],
    practiceId: number,
    doctorEmployeeSeqNumber: number,
    isGp: boolean
) => {
    menuItems.push(new MenuItemModel('Отчети - НЗОК', '', ''));

    if (isGp) {
        menuItems.push(
            new MenuItemModel(
                'Пациентска листа',
                'mdi-book-account-outline',
                `/Report/Pis/PatientList/${practiceId}/${doctorEmployeeSeqNumber}`
            ),
            new MenuItemModel(
                'Карти рискови фактори',
                'mdi-book-outline',
                `/Report/Pis/RiskAssessmentCard/${practiceId}/${doctorEmployeeSeqNumber}`
            )
        );
    }

    menuItems.push(
        new MenuItemModel(
            'Амбулаторен отчет',
            'mdi-book-outline',
            `/Report/Pis/GpExamClaim/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Касови бележки чл. 37',
            'mdi-book-outline',
            `/Report/Pis/FiscalReceipt/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Прикачване на файл - ПИС',
            'mdi-book-outline',
            `/Report/Pis/SpecificationReport/${practiceId}`
        )
    );
};

const addFinancialItemsIfAdmin = (menuItems: MenuItemModel[], practiceId: number, doctorEmployeeSeqNumber: number) => {
    // Когато лекарят не иска медицинската сестра да вижда фактурите към НЗОК, той трябва да я добави в "Персонал",
    // като отделен служител не-админ, който работи с отделен потребител. Меню "Електронни фактури" се скрива за този служител.
    // TODO: Ако двамата работят на един компютър (и очевидно нямат време да се логват и разлогват),
    // ще трябва паролата на лекаря да се изиска повторно, за да се зареди модул "Електронни фактури".
    menuItems.push(
        new MenuItemModel(
            'Отчет по чл. 37',
            'mdi-book-outline',
            `/Report/Pis/FiscalReport/${practiceId}/${doctorEmployeeSeqNumber}`
        )
    );
    menuItems.push(new MenuItemModel('Електронни фактури', 'mdi-cash', `/Report/Pis/Invoice/${practiceId}`));
};

const addRhiReportItems = (menuItems: MenuItemModel[], practiceId: number, doctorEmployeeSeqNumber: number) => {
    menuItems.push(
        new MenuItemModel('Отчети - РЗИ', '', ''),
        new MenuItemModel(
            'Сведение имунизации',
            'mdi-book-account-outline',
            `/Report/Rhi/RhiImmunizationReport/${practiceId}/${doctorEmployeeSeqNumber}`
        ),
        new MenuItemModel(
            'Формуляр 365',
            'mdi-book-outline',
            `/Report/Rhi/Report365/${practiceId}/${doctorEmployeeSeqNumber}`
        )
    );
};

export const createReportMenuItems = () => {
    const { current } = userContextCache;
    if (!current) {
        return [];
    }
    const { doctorId, practiceId, employeeSeqNumber } = current;
    const isGp = current.specialtyCode === SpecialtyCode.Gp;
    const menuItems: MenuItemModel[] = [];

    addNhifReportItems(menuItems, practiceId, employeeSeqNumber, isGp);
    if (userContextCache.currentIsGlobalAdminOrPracticeOwner) {
        addFinancialItemsIfAdmin(menuItems, practiceId, employeeSeqNumber);
    }
    if (doctorId) {
        addRhiReportItems(menuItems, practiceId, employeeSeqNumber);
    }

    return menuItems;
};
