import { Route, RouteConfig } from 'vue-router';

export const regulatoryStandardChildRoutes: RouteConfig[] = [
    {
        path: 'RegulatoryStandard/List/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Practice/RegulatoryStandard/RegulatoryStandardList.vue')
        }
    },
    {
        path: 'RegulatoryStandard/Create/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Practice/RegulatoryStandard/RegulatoryStandardEdit.vue')
        }
    },
    {
        path: 'RegulatoryStandard/Edit/:practiceId/:seqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                seqNumber: Number(route.params.seqNumber)
            })
        },
        components: {
            practice: () => import('@/view/Practice/RegulatoryStandard/RegulatoryStandardEdit.vue')
        }
    },
    {
        path: 'RegulatoryStandard/Reference/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Practice/RegulatoryStandard/RegulatoryStandardReference.vue')
        }
    }
];
