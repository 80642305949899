import { Transform, Type } from 'class-transformer';

import { ExamSignerDto } from './ExamSignerDto';

export class ExamSignatureEditDto {
    public imageBase64: string | null = null;
    public rawBase64: string | null = null;
    public examXml: string = '';

    // Ако в blob storage няма данни за подписалия, в template-а се bind-ва празен обект.
    @Transform(({ value }) => value ?? new ExamSignerDto())
    @Type(() => ExamSignerDto)
    public signer: ExamSignerDto = new ExamSignerDto();

    public eTag: string | null = null;
}
