<template>
    <div v-if="isVisible">
        <v-card class="mb-md-2">
            <v-card-text>
                {{ title }}
                <v-row class="mt-1">
                    <v-col cols="6" md="2" class="pb-0">
                        <text-field v-model="maxRegStandards" disabled label="Лимит" />
                    </v-col>
                    <v-col cols="6" md="2" class="pb-0">
                        <text-field v-model="usedRegStandards" disabled label="Издадени" />
                    </v-col>
                    <v-col cols="6" md="2" class="pb-0">
                        <text-field
                            :value="Number(maxRegStandards - usedRegStandards).toFixed(2)"
                            disabled
                            label="Остават"
                        />
                    </v-col>
                    <v-col cols="6" class="pb-0">
                        <v-progress-linear
                            :value="calcPercent(maxRegStandards, usedRegStandards)"
                            class="border"
                            color="info"
                            :buffer-value="100"
                            height="25"
                            striped
                        >
                            <template #default="{ value }">
                                <strong> {{ value }}% използвани до момента </strong>
                            </template>
                        </v-progress-linear>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <alert :value="isOverLimit && usedRegStandards > maxRegStandards" type="warning" prominent>
            Превишени са регулативните стандарти. Необходимо е да бъдат компенсирани
            {{ Number((maxRegStandards - usedRegStandards) * -1).toFixed(2) }}</alert
        >
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    const valueTableConfig = {
        percent: 100
    };
    @Component
    export default class RegulatoryStandardProgress extends Vue {
        @Prop()
        private title!: string;

        @Prop()
        private usedRegStandards!: number;

        @Prop()
        private maxRegStandards!: number;

        @Prop()
        private timeOut!: number;

        private isVisible: boolean = true;
        private isOverLimit: boolean = false;

        private calcPercent(limit: number, current: number): number {
            if ((limit ?? 0) && (current ?? 0)) {
                const result = Math.round((current / limit) * valueTableConfig.percent);
                if (result > valueTableConfig.percent) {
                    this.isOverLimit = true;
                } else {
                    this.isOverLimit = false;
                }
                return result;
            }
            return 0;
        }

        @Watch('timeOut', { immediate: true })
        private onLoadingChange(val: number) {
            if (val) {
                setTimeout(() => (this.isVisible = false), this.timeOut);
            }
        }
    }
</script>

<style scoped>
    .border {
        border-style: double;
    }
</style>
