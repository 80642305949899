import Vue from 'vue';

import { PracticeTypeDto } from '@/model/Nomenclature/PracticeTypeDto';
import { practiceTypeService } from '@/service/Nomenclature/PracticeTypeService';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

const practiceTypeStartPos = 4;
const practiceTypeEndPos = 7;

const loadingItem = new PracticeTypeDto();
loadingItem.name = 'Зареждане...';
const loadErrorItem = new PracticeTypeDto();
loadErrorItem.name = 'Грешка при зареждане';

class PracticeTypeCache extends ArrayCacheBase<PracticeTypeDto> {
    constructor() {
        super(loadingItem, loadErrorItem);
    }

    protected async load() {
        const response = await practiceTypeService.getPracticeTypes();
        this._items = response.data;
    }

    public getPracticeTypeName(practiceNumber: string | null): string {
        this.loadOnce();
        if (practiceNumber === null || practiceNumber.length === 0 || practiceNumber.length < practiceTypeEndPos) {
            return '';
        }

        const code = practiceNumber.slice(practiceTypeStartPos, practiceTypeEndPos);
        const practiceType = this._items?.find((pt) => pt.code === code);
        return practiceType?.name ?? `Невалиден тип практика ${code} (цифри 5-7)`;
    }
}

export const practiceTypeCache = Vue.observable(new PracticeTypeCache());
