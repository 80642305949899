import { Report365RowBase } from './Report365RowBase';

export class Report365PsychiatricExpertiseRowDto extends Report365RowBase {
    public total: number | null = null;
    public totalPunitiveProcess: number | null = null;
    public totalInsane: number | null = null;
    public totalCivilProcess: number | null = null;
    public totalIncompetent: number | null = null;
    public totalHealthLaw: number | null = null;
    public totalHealthLawStopped: number | null = null;
}
