import { plainToInstance } from 'class-transformer';

import { FamilyHistoryDto } from '@/model/Patient/FamilyHistory/FamilyHistoryDto';
import { FamilyHistoryViewDto } from '@/model/Patient/FamilyHistory/FamilyHistoryViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const familyHistoryService = {
    async getFamilyHistories(patientId: number): Promise<FamilyHistoryViewDto[]> {
        const response = await httpService.get<FamilyHistoryViewDto[]>(
            `/FamilyHistory/GetPatientFamilyHistories/${patientId}`
        );
        return plainToInstance(FamilyHistoryViewDto, response?.data);
    },

    async getById(id: number): Promise<FamilyHistoryDto> {
        const response = await httpService.get<FamilyHistoryDto>(`/FamilyHistory/GetById/${id}`);
        return plainToInstance(FamilyHistoryDto, response?.data);
    },

    async create(data: FamilyHistoryDto): Promise<number> {
        const response = await httpService.post<number>('/FamilyHistory/Create', data);
        return response?.data;
    },

    async update(data: FamilyHistoryDto): Promise<void> {
        await httpService.put('/FamilyHistory/Update', data);
    },

    async delete(id: number): Promise<void> {
        await httpService.delete(`/FamilyHistory/Delete/${id}`);
    }
};
