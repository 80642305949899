import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';

export class PatientExistenceDto {
    constructor(
        public practiceId: number,
        public pidTypeCode: PidTypeCode | null,
        public identifier: string,
        public patientId: number
    ) {}
}
