import { AxiosResponse } from 'axios';

import { PidTypeDto } from '@/model/Nomenclature/PidTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const pidTypeService = {
    getPidTypes(): Promise<AxiosResponse<PidTypeDto[]>> {
        return httpService.get('/PidType/GetPidTypes');
    }
};
