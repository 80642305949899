<template>
    <v-card>
        <v-card-title>Необходими и извършени диспансерни дейности</v-card-title>
        <v-card-text>
            <v-row no-gutters>
                <dropdown
                    v-if="!icdCode"
                    v-model="selectedDispensaryRule"
                    :items="patientDispensaryRules"
                    label="Избор на водещо МКБ"
                    return-object
                    class="col-6"
                ></dropdown
            ></v-row>
            <v-card-actions>
                <btn
                    v-if="!icdCode || currentVisit.id"
                    action="Save"
                    :disabled="!selectedDispensaryRule || saveIsDisabled"
                    @click="save"
                ></btn>
            </v-card-actions>
            <v-card-title v-if="icdCode && patientDispensaryData?.selectedDispensaryRule?.name">{{
                patientDispensaryData?.selectedDispensaryRule?.name
            }}</v-card-title>
            <v-card v-if="selectedDispensaryRule" class="mb-3">
                <v-card-title>Извършени дейности</v-card-title>
                <v-data-table
                    v-model="patientDispensaryData.selectedPerformedActivities"
                    :items="performedActivities"
                    :headers="performedHeaders"
                    :footer-props="{ itemsPerPageText: 'Редове на страница', itemsPerPageAllText: 'Всички' }"
                    show-select
                    :item-key="`code`"
                    item-selectable="isSelectable"
                ></v-data-table>
            </v-card>
            <v-card v-if="selectedDispensaryRule" class="mb-3">
                <v-card-title>Необходими дейности</v-card-title>
                <v-data-table
                    v-model="patientDispensaryData.selectedRequiredActivities"
                    :items="requiredActivities"
                    :headers="requiredHeaders"
                    :footer-props="{ itemsPerPageText: 'Редове на страница', itemsPerPageAllText: 'Всички' }"
                    show-select
                    :item-key="`code`"
                    item-selectable="isSelectable"
                ></v-data-table>
            </v-card>
            <v-card v-if="icdCode" class="col-4">
                <v-card-title>Предложения за предстоящи прегледи</v-card-title>
                <data-table
                    :headers="headersSuggestedFutureExams"
                    :items="suggestedFutureExamsTransformed"
                    class="elevation-1"
                ></data-table>
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PatientDiagnosisCheckCommand } from '@/model/Exam/PatientDiagnosisCheckCommand';
    import { DispensaryActivitiesExamCommand } from '@/model/Patient/Dispensary/DispensaryActivitiesExamCommand';
    import { PatientDispensaryDataDto } from '@/model/Patient/Dispensary/PatientDispensaryDataDto';
    import { PatientSpecialActivityDto } from '@/model/Patient/PatientSpecialActivityDto';
    import { DispensaryRuleDto } from '@/model/Register/Dispensary/DispensaryRuleDto';
    import { examDiagnosisService } from '@/service/Exam/ExamDiagnosisService';
    import { patientDispensaryVisitService } from '@/service/Patient/PatientDispensaryVisitService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class PatientDispensaryVisit extends Vue {
        @Prop({ required: true })
        private patientId!: number;

        @Prop()
        private icdCode!: string;

        private patientDispensaryRules: DispensaryRuleDto[] = [];
        private selectedDispensaryRule: DispensaryRuleDto | null = null;
        private performedActivities: PatientSpecialActivityDto[] = [];
        private requiredActivities: PatientSpecialActivityDto[] = [];
        private patientDispensaryData: PatientDispensaryDataDto = new PatientDispensaryDataDto();
        private saveIsDisabled: boolean = false;
        private isLimitationsChecked: boolean = false;

        private get performedHeaders(): IDataTableHeader[] {
            return [
                {
                    text: 'Тип',
                    value: 'activityType'
                },
                {
                    text: 'Код/Специалист',
                    value: 'activityName'
                },
                {
                    text: 'Име на изследване',
                    value: 'labTestName'
                },
                {
                    text: 'Дата на последно извършване',
                    value: 'lastPerformedActivityDate'
                },
                {
                    text: 'Описание',
                    value: 'activityDescription'
                }
            ];
        }

        private get requiredHeaders(): IDataTableHeader[] {
            return [
                {
                    text: 'Тип',
                    value: 'activityType'
                },
                {
                    text: 'Код/Специалист',
                    value: 'activityName'
                },
                {
                    text: 'Име на изследване',
                    value: 'labTestName'
                },
                {
                    text: 'Извършени',
                    value: 'totalPerformedActivities'
                },
                {
                    text: 'Необходими',
                    value: 'totalActivities'
                },
                {
                    text: 'Описание',
                    value: 'activityDescription'
                }
            ];
        }

        private headersSuggestedFutureExams: IDataTableHeader[] = [{ text: 'Дати', value: 'item' }];

        private get suggestedFutureExamsTransformed() {
            return this.patientDispensaryData?.suggestedFutureExams.map((item) => ({ item })) ?? [];
        }

        private get currentVisit() {
            return currentVisitCache.value;
        }

        private async mounted() {
            if (this.icdCode) {
                const command = new DispensaryActivitiesExamCommand();
                command.patientId = this.patientId ?? currentPatientCache.value.key.patientId;
                command.examId = this.currentVisit.id;
                command.icdCode = this.icdCode;
                command.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
                const response = await patientDispensaryVisitService.getDispensaryActivitiesForExam(command);
                this.selectedDispensaryRule = response.selectedDispensaryRule;
                this.processDispensaryActivities(response);
            } else {
                this.patientDispensaryRules = await patientDispensaryVisitService.getPatientDispensaryRules(
                    this.patientId,
                    userContextCache.currentDoctorEmployeeSeqNumber ?? 0
                );
            }
        }

        private async save() {
            try {
                this.saveIsDisabled = true;
                this.$loading.show();
                this.patientDispensaryData.selectedDispensaryRule = this.selectedDispensaryRule;
                this.patientDispensaryData.deputyDoctorId = userContextCache.current?.deputyDoctorId ?? null;
                this.patientDispensaryData.deputyDoctorIsHired = userContextCache.current?.deputyDoctorIsHired ?? null;
                this.patientDispensaryData.examId = this.icdCode ? this.currentVisit.id : null;
                this.patientDispensaryData.selectedRequiredActivities =
                    this.patientDispensaryData.selectedRequiredActivities.filter(
                        (activity) => !activity.isAddedToCurrentExam
                    );
                const response = await patientDispensaryVisitService.saveDispensaryVisit(this.patientDispensaryData);
                if (response) {
                    if (this.icdCode) {
                        this.$router.go(0);
                    } else {
                        this.$router.push(`/Exam/AmbulatorySheet/Edit/${response}`);
                    }
                } else {
                    this.$notifier.showError('', 'Неуспешно създаване на амбулаторен лист.');
                }
            } finally {
                this.$loading.hide();
                this.saveIsDisabled = false;
                this.$emit('close');
            }
        }

        private async checkDispensaryLimit() {
            if (this.selectedDispensaryRule?.icdCode) {
                const command = new PatientDiagnosisCheckCommand();
                command.patientId = this.patientId;
                command.icdCode = this.selectedDispensaryRule.icdCode;
                command.examDate = new Date();
                const response = (await examDiagnosisService.checkForDispensaryLimitations(command)).data;
                if (response) {
                    this.$notifier.showWarning('Внимание', response);
                }
            }
        }

        @Watch('selectedDispensaryRule')
        private async onSelectedDispansaryRuleChanged() {
            if (this.icdCode) {
                return;
            }
            this.performedActivities = [];
            this.requiredActivities = [];
            this.patientDispensaryData = new PatientDispensaryDataDto();
            const rule = this.selectedDispensaryRule;
            if (rule) {
                const result = await patientDispensaryVisitService.getDispensaryActivities(
                    rule.registerPatientId,
                    rule.registerSeqNumber,
                    this.currentVisit.id
                );
                this.processDispensaryActivities(result);

                if (!this.isLimitationsChecked) {
                    this.checkDispensaryLimit();
                    this.isLimitationsChecked = true;
                }
            }
        }

        private processDispensaryActivities(data: PatientDispensaryDataDto) {
            this.patientDispensaryData = data;

            for (const activity of data.activities) {
                if (activity.isPerformed && !activity.isAddedToCurrentExam) {
                    activity.isSelectable = activity.activityType === 'Изследване';
                    this.performedActivities.push(activity);
                } else {
                    activity.isSelectable = !activity.isAddedToCurrentExam;
                    this.requiredActivities.push(activity);

                    if (activity.isAddedToCurrentExam) {
                        this.patientDispensaryData.selectedRequiredActivities.push(activity);
                    }
                }
            }
        }
    }
</script>
