import { Report365RowBase } from './Report365RowBase';

export class Report365DispensaryActivityRowDto extends Report365RowBase {
    public removedRecovered: number | null = null;
    public removedDead: number | null = null;
    public removedChanged: number | null = null;
    public get removedTotal(): number {
        return (
            (this.removedRecovered === null ? 0 : Number(this.removedRecovered)) +
            (this.removedDead === null ? 0 : Number(this.removedDead)) +
            (this.removedChanged === null ? 0 : Number(this.removedChanged))
        );
    }

    public countStartYear: number | null = null;
    public countDuringYear: number | null = null;
    public countChildren: number | null = null;
    public get countEndYear(): number {
        return (
            (this.countStartYear === null ? 0 : Number(this.countStartYear)) +
            (this.countDuringYear === null ? 0 : Number(this.countDuringYear)) -
            (this.removedTotal === null ? 0 : Number(this.removedTotal))
        );
    }
}
