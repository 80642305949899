import { VuetifyCalendarType } from '@/enum/Calendar/VuetifyCalendarType';

import { CalendarTypeModel } from './CalendarTypeModel';

const day = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 0 };
const offsets = { day: 1, week: 7, month: 30 };

const calendarConfig = {
    locale: 'bg',
    defaultType: VuetifyCalendarType.week,
    weekDaysOrder: [day.mon, day.tue, day.wed, day.thu, day.fri, day.sat, day.sun],
    intervalHeight: 30,
    intervalMinutes: 30,
    types: [
        new CalendarTypeModel(VuetifyCalendarType.day, 'Ден', offsets.day),
        new CalendarTypeModel(VuetifyCalendarType.week, 'Седмица', offsets.week),
        new CalendarTypeModel(VuetifyCalendarType.month, 'Месец', offsets.month)
    ],

    getCalendarOffsetByType(calendarType: VuetifyCalendarType): number {
        return this.types.find((ct) => ct.id === calendarType)?.offset ?? 0;
    }
};

export { calendarConfig, VuetifyCalendarType };
