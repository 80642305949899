<template>
    <div>
        <v-row justify="center">
            <v-col md="4" class="px-1">
                <dropdown
                    v-model="selectedFiscalMemory"
                    :items="fiscalMemories"
                    return-object
                    item-text="code"
                    label="Фискално устройство"
                ></dropdown>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12" class="px-1">
                <btn action="Primary" @click="cancelBon">Отказване на отворен бон</btn>
                <btn action="Primary" @click="setDateTime">Сверяване на часовник</btn>
                <btn action="Success" @click="executeXReport">X отчет</btn>
                <btn action="Success" @click="executeZReport">Z отчет</btn>
            </v-col>
            <v-col md="8" class="px-1"> </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col md="4" class="px-1">
                <btn action="Success" @click="reportPeriodShort">Съкратен отчет за период</btn>
            </v-col>
            <v-col md="2" class="px-1">
                <date-picker v-model="dateFrom" label="Начална дата" />
            </v-col>
            <v-col md="2" class="px-1">
                <date-picker v-model="dateTo" label="Крайна дата" />
            </v-col>
            <v-col md="4" class="px-1"> </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { ReportShortDto } from '@/model/CashRegister/ReportShortDto';
    import { FiscalMemoryDto } from '@/model/Practice/Fiscal/FiscalMemoryDto';
    import { cashRegisterService } from '@/service/LocalServer/CashRegisterService';
    import { fiscalMemoryService } from '@/service/Practice/Fiscal/FiscalMemoryService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class FiscalDashboard extends Vue {
        async mounted() {
            const pId = userContextCache.current?.practiceId ?? 0;
            await this.loadFiscalMemory(pId);
        }

        private dateFrom: string = '';
        private dateTo: string = '';
        private fiscalMemories: FiscalMemoryDto[] = [];
        private selectedFiscalMemory: FiscalMemoryDto | null = null;
        private async loadFiscalMemory(practiceId: number) {
            const dateTime = new Date();
            const responsePracticeFiscalMemories = await fiscalMemoryService.getActiveFiscalMemories(
                practiceId,
                dateTime,
                userContextCache.current?.employeeSeqNumber ?? 0
            );
            const resultListDevices = await cashRegisterService.getListDevices();
            if (resultListDevices?.deviceList && resultListDevices?.deviceList.length > 0) {
                for (let idxDb = 0; idxDb < responsePracticeFiscalMemories.data.length; idxDb++) {
                    for (let idxSv = 0; idxSv < resultListDevices?.deviceList.length; idxSv++) {
                        if (
                            resultListDevices.deviceList[idxSv].deviceMemoryNumber ===
                            responsePracticeFiscalMemories.data[idxDb].code
                        ) {
                            this.fiscalMemories.push(responsePracticeFiscalMemories.data[idxDb]);
                            break;
                        }
                    }
                }
                if (this.fiscalMemories.length === 1) {
                    [this.selectedFiscalMemory] = this.fiscalMemories;
                }
            } else {
                this.fiscalMemories = responsePracticeFiscalMemories.data;
            }
        }

        private async cancelBon() {
            if (this.selectedFiscalMemory) {
                const mem = this.selectedFiscalMemory.code ?? '';
                await cashRegisterService.cancelBon(mem);
            }
        }

        private async executeXReport() {
            if (this.selectedFiscalMemory) {
                const mem = this.selectedFiscalMemory.code ?? '';
                await cashRegisterService.executeXReport(mem);
            }
        }

        private async executeZReport() {
            if (this.selectedFiscalMemory) {
                const mem = this.selectedFiscalMemory.code ?? '';
                await cashRegisterService.executeZReport(mem);
            }
        }

        private async setDateTime() {
            if (this.selectedFiscalMemory) {
                const mem = this.selectedFiscalMemory.code ?? '';
                await cashRegisterService.setDateTime(mem);
            }
        }

        private async reportPeriodShort() {
            if (this.selectedFiscalMemory && this.dateFrom && this.dateTo) {
                const reportShortDto = new ReportShortDto();
                reportShortDto.deviceMemoryNumber = this.selectedFiscalMemory.code ?? '';
                reportShortDto.dateFrom = this.dateFrom;
                reportShortDto.dateTo = this.dateTo;

                await cashRegisterService.reportPeriodShort(reportShortDto);
            }
        }
    }
</script>
