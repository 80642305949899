<template>
    <v-card>
        <main-title>Чакащи пациенти</main-title>
        <v-card-text>
            <report-date-picker v-model="filter" by-day @input="getItems" />
        </v-card-text>

        <data-table title="Чакащи пациенти" :items="items" :headers="headers" :item-class="itemRowBackground">
            <template #rowActions="{ item }">
                <btn icon action="Visit" :to="`/Exam/AmbulatorySheet/Edit/${item.visitId}`" />
            </template>
        </data-table>
        <v-card-text>
            <v-row>
                <v-col>Брой чакащи: {{ items.length }}</v-col>
                <v-col>Брой прегледани: {{ doneVisitsCount }}</v-col>
                <v-spacer></v-spacer>
                <v-col>Заплаща се от:</v-col>
                <v-col><label class="px-5 py-1 style-nhif">НЗОК</label></v-col>
                <v-col><label class="px-5 py-1 style-patient">Пациент</label></v-col>
                <v-col><label class="px-5 py-1 style-mh">МЗ</label></v-col>
                <v-col><label class="px-5 py-1 style-private-fund">Частен фонд</label></v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import DataTable from '@/component/Common/DataTable.vue';
    import MainTitle from '@/component/Common/MainTitle.vue';
    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { FundCode } from '@/enum/Nomenclature/FundCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { QueuedVisitListDto } from '@/model/VisitList/Queue/QueuedVisitListDto';
    import { QueueIntervalFilterDto } from '@/model/VisitList/Queue/QueueIntervalFilterDto';
    import { queueService } from '@/service/VisitList/QueueService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { MainTitle, ReportDatePicker, DataTable }
    })
    export default class Queue extends Vue {
        private items: QueuedVisitListDto[] = [];
        private filter: QueueIntervalFilterDto = new QueueIntervalFilterDto();
        private doneVisitsCount: number = 0;

        private headers: IDataTableHeader[] = [
            { text: 'Име', value: 'patientName' },
            { text: 'Дата и час', value: 'startDateTime', formatter: formatters.dateTime },
            { text: 'Услуга', value: 'serviceName' },
            { text: 'Заплаща се от', value: 'fund' },
            { text: '', value: 'actionButtons', sortable: false }
        ];

        private async getQueueItems() {
            this.$loading.show();
            try {
                this.filter.practiceId = userContextCache.currentPracticeId ?? 0;
                this.filter.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
                this.items = await queueService.getQueuedVisits(this.filter);
            } finally {
                this.$loading.hide();
            }
        }

        private async getDoneVisits() {
            this.$loading.show();
            try {
                this.filter.practiceId = userContextCache.currentPracticeId ?? 0;
                this.filter.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
                this.doneVisitsCount = await queueService.getDoneVisits(this.filter);
            } finally {
                this.$loading.hide();
            }
        }

        private itemRowBackground(item: QueuedVisitListDto) {
            if (item.fundCode === FundCode.Nhif) {
                return 'style-nhif';
            }
            if (item.fundCode === FundCode.Patient) {
                return 'style-patient';
            }
            if (item.fundCode === FundCode.Mh) {
                return 'style-mh';
            }
            if (item.fundCode === FundCode.Private) {
                return 'style-private-fund';
            }
            return 'style-main';
        }

        private async getItems() {
            await this.getQueueItems();
            await this.getDoneVisits();
        }

        private async mounted() {
            this.filter.startDate = dateUtil.today();
            this.filter.endDate = dateUtil.today();
            await this.getItems();
        }
    }
</script>

<style scoped>
    ::v-deep .style-nhif {
        background-color: var(--v-accent2-base) !important;
    }

    ::v-deep .style-patient {
        background-color: var(--v-success2-base) !important;
    }

    ::v-deep .style-mh {
        background-color: var(--v-info2-base) !important;
    }

    ::v-deep .style-private-fund {
        background-color: var(--v-warning2-base) !important;
    }

    ::v-deep .style-main {
        background-color: transparent;
    }
</style>
