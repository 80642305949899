<template>
    <v-dialog v-if="src" v-model="dialogIsVisible" activator="parent" width="85%" height="85%">
        <template #activator="{ on, attrs }">
            <btn action="Help" v-bind="attrs" v-on="on" />
        </template>

        <v-card>
            <v-card-title>
                <h1>Помощ</h1>
                <v-spacer></v-spacer>
                <btn icon action="Close" @click="dialogIsVisible = false" />
            </v-card-title>
            <v-card-text style="height: 100%; min-height: 70vh">
                <embed type="text/html" :src="src" style="width: 100%; height: 70vh" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { config } from '@/Config';
    import { HelpActionCode, helpActions } from '@/model/Help/HelpAction';

    @Component({
        name: 'help-popup'
    })
    export default class HelpPopup extends Vue {
        @Prop({ required: true })
        private action!: HelpActionCode;

        private dialogIsVisible: boolean = false;

        private get src() {
            const actionObj = helpActions[this.action];
            return actionObj ? `${config.userGuideBaseUrl}/${actionObj.src}` : null;
        }
    }
</script>
