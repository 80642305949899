import { PisFileTypeCode } from '@/enum/Report/Pis/PisFileTypeCode';
import { InvoiceLineDto } from '@/model/Finance/Invoice/InvoiceLineDto';

export class PisInvoiceDto {
    public id: number = 0;
    public invoiceFileData: string = '';
    public monthlyNotificationFileId: string = '';
    public monthlyNotificationId: number = 0;
    public invoiceId: number = 0;
    public number: string = '';
    public invoiceTypeName: string = '';
    public pisFileTypeCode: PisFileTypeCode = PisFileTypeCode.Invoice;
    public relatedInvoiceNumber: string | null = null;
    public invoiceLines: InvoiceLineDto[] = [];
    public startDate: Date | null = null;
    public endDate: Date | null = null;
    public taxDate: Date | null = null;
    public invoiceDate: Date | null = null;
    public relatedInvoiceDate: Date | null = null;
    public practiceNhifContractId: number | null = null;
}
