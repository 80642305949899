<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">Раздел X. Медицинска апаратура и изследвания</h3></v-col>
            <v-col cols="12"><h3 class="mb-2">1. Медицинска апаратура</h3></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><DataTableComponent v-model="value.medicalEquipments" :headers="headers" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Report365MedicalEquipmentDto } from '@/model/Report/Rhi/Report365/Report365MedicalEquipmentDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class MedicalEquipment extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Report365MedicalEquipmentDto;

        private headers: IDataTableHeader[] = [
            { text: '', value: 'name' },
            { text: 'Шифър', value: 'code' },
            { text: 'Брой', value: 'total' }
        ];
    }
</script>
