<template>
    <v-card v-show="isAlertActive">
        <alert :value="isAlertActive" type="warning" prominent>
            {{ warningMessage }}
        </alert>
        <v-card-actions class="justify-center">
            <btn action="List" :to="`/Setting/SickSheetCounter/List/${currentPracticeId}`">Списък номера</btn>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { userContextCache } from '@/store/User/UserContextCache';

    const config = {
        minCounters: 5
    };

    @Component
    export default class SickSheetCounterWarning extends Vue {
        @Prop()
        private leftCounters!: number;

        private isAlertActive: boolean = false;

        private get currentPracticeId() {
            return userContextCache.currentPracticeId;
        }

        private get warningMessage(): string {
            if (this.leftCounters && this.leftCounters <= config.minCounters) {
                this.isAlertActive = true;
                return `Остават ${this.leftCounters} броя номера за текущата практика за болничен лист.
Моля подсигурете нов списък с номера`;
            } else if (this.leftCounters === 0 || this.leftCounters === null) {
                this.isAlertActive = true;
                return `Броят номера за текущата практика за болничен лист е изчерпан.
Моля подсигурете нов списък с номера`;
            }
            this.isAlertActive = false;

            return '';
        }
    }
</script>
