<template>
    <v-card>
        <v-card-title>{{ seqNumber ? 'Редактиране' : 'Създаване' }} на ЛКК комисия</v-card-title>
        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn action="Save" @click="save" />
        </v-card-actions>
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="6" md="3" class="pa-1">
                    <text-field v-model="model.number" label="Номер" :rules="[$validator.digitsOnly]" />
                </v-col>
                <v-col cols="6" md="3" class="pa-1">
                    <text-field v-model="model.name" label="Име" required />
                </v-col>
                <v-col cols="6" md="3" class="pa-1">
                    <v-checkbox v-model="model.isSpecialized" dense label="Специализирана" />
                </v-col>
                <v-col cols="6" md="3" class="pa-1">
                    <v-checkbox v-model="model.isDeleted" dense label="Неактивна" />
                </v-col>
            </v-row>
            <v-row
                v-for="(member, index) in model.lkkCommitteeDoctorEmployees"
                :key="index"
                class="flex-row align-baseline"
            >
                <v-col cols="1">
                    <text-field :label="`${index + 1}`" readonly />
                </v-col>
                <v-col cols="6">
                    <dropdown
                        v-model="member.doctorEmployeeSeqNumber"
                        :items="employees"
                        item-text="uinNameAndSpecialty"
                        item-value="employeeSeqNumber"
                        required
                        :disabled="member.lkkCommitteeSeqNumber > 0"
                    />
                </v-col>
                <v-col cols="2">
                    <v-checkbox
                        v-model="member.isPresident"
                        dense
                        label="Председател:"
                        @click="checkboxUpdate(member)"
                    />
                </v-col>
                <btn icon action="Delete" @click="removeMember(index)" />
            </v-row>
            <v-row>
                <btn
                    v-if="model.lkkCommitteeDoctorEmployees && model.lkkCommitteeDoctorEmployees.length < 5"
                    icon
                    action="New"
                    @click="addMember"
                />
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
    import { LkkCommitteeDoctorEmployeeDto } from '@/model/Practice/LkkCommittee/LkkCommitteeDoctorEmployeeDto';
    import { LkkCommitteeDto } from '@/model/Practice/LkkCommittee/LkkCommitteeDto';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { lkkCommitteeService } from '@/service/Practice/LkkCommittee/LkkCommitteeService';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component
    export default class LkkCommitteeEdit extends Vue {
        @Prop({ required: true })
        practiceId!: number;

        @Prop()
        seqNumber!: number;

        private model: LkkCommitteeDto = new LkkCommitteeDto();
        private employees: EmployeeTitleDto[] = [];

        private async save() {
            if (this.hasPresident) {
                this.$loading.show();
                try {
                    if (!this.seqNumber) {
                        this.model.seqNumber = await lkkCommitteeService.createLkkCommittee(this.model);
                        this.$notifier.showSuccess('', 'Успешно записана комисия');
                    } else {
                        await lkkCommitteeService.updateLkkCommittee(this.model);
                        this.$notifier.showSuccess('', 'Успешно редактирана комисия');
                    }
                    this.$router.push(this.backRoute);
                } finally {
                    this.$loading.hide();
                }
            } else {
                this.$notifier.showWarning('', 'Трябва да изберете председател на комисията!');
            }
        }

        private addMember() {
            this.model.lkkCommitteeDoctorEmployees.push(new LkkCommitteeDoctorEmployeeDto());
        }

        private removeMember(index: number) {
            arrayUtil.removeAt(this.model.lkkCommitteeDoctorEmployees, index);
        }

        private get hasPresident() {
            return this.model.lkkCommitteeDoctorEmployees.some((member) => member.isPresident);
        }

        private checkboxUpdate(item: LkkCommitteeDoctorEmployeeDto) {
            if (item.isPresident) {
                for (const member of this.model.lkkCommitteeDoctorEmployees) {
                    member.isPresident = false;
                }
                item.isPresident = true;
            } else {
                item.isPresident = false;
            }
        }

        private get backRoute() {
            return `/Practice/LkkCommittee/List/${this.model.practiceId}`;
        }

        private async mounted() {
            this.$loading.show();
            try {
                if (this.seqNumber) {
                    this.model = await lkkCommitteeService.getLkkCommittee(this.practiceId, this.seqNumber);
                } else {
                    this.model.practiceId = this.practiceId;
                    this.addMember();
                }
                this.employees = await employeeService.getDoctorEmployees(this.practiceId);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
