<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">Раздел VІ. Дейност на психиатричните структури</h3></v-col>
            <v-col cols="12"><h3 class="mb-2">3. Произшествия</h3></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><DataTableComponent v-model="value.psychiatricAccidents" :headers="headers" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Report365PsychiatricAccidentDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricAccidentDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class PsychiatricAccident extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Report365PsychiatricAccidentDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: '', value: 'name' },
            { text: 'Шифър', value: 'code' },
            { text: 'Болни, извършили убийства', value: 'totalKills' },
            { text: 'Болни, направили опит за самоубийство', value: 'totalSelfKills' },
            { text: 'Болни, извършили бягство', value: 'totalEscape' },
            { text: 'Нападения, извършени от болни срещу персонала', value: 'totalAttackStaff' },
            { text: 'Нападения, извършени от болни срещу болни', value: 'totalAttackSick' },
            { text: 'Агресия срещу инвентар', value: 'totalAttackInventory' }
        ];
    }
</script>
