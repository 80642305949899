import { AxiosResponse } from 'axios';

import { QuickNoticeDto } from '@/model/Exam/QuickNotice/QuickNoticeDto';
import { QuickNoticeViewDto } from '@/model/Exam/QuickNotice/QuickNoticeViewDto';
import { SickStatusDto } from '@/model/Nomenclature/SickStatusDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const quickNoticeService = {
    getQuickNotice(id: number): Promise<AxiosResponse<QuickNoticeDto>> {
        return httpService.get(`/QuickNotice/GetQuickNotice/${id}`);
    },

    createQuickNotice(data: QuickNoticeDto): Promise<AxiosResponse<QuickNoticeDto>> {
        return httpService.post('/QuickNotice/CreateQuickNotice', data);
    },

    updateQuickNotice(data: QuickNoticeDto): Promise<AxiosResponse<QuickNoticeDto>> {
        return httpService.put('/QuickNotice/UpdateQuickNotice/', data);
    },

    deleteQuickNotice(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/QuickNotice/DeleteQuickNotice/${id}`);
    },

    getQuickNoticeInfoByExamId(examId: number): Promise<AxiosResponse<QuickNoticeDto>> {
        return httpService.get(`/QuickNotice/GetQuickNoticeInfoByExamId/${examId}`);
    },

    //haveExistingQuickNotice(examId: number): Promise<AxiosResponse<boolean>> {
    //    return httpService.get(`/QuickNotice/HaveExistingQuickNotice/${examId}`);
    //},

    getSickSatuses(): Promise<AxiosResponse<SickStatusDto[]>> {
        return httpService.get(`/QuickNotice/GetSickStatuses`);
    },

    getQuickNoticesByExamId(examId: number): Promise<AxiosResponse<QuickNoticeViewDto[]>> {
        return httpService.get(`/QuickNotice/GetQuickNoticeViews`, {
            params: {
                examId
            }
        });
    }
};
