import { AxiosResponse } from 'axios';

import { DeathEstablishedByDto } from '@/model/Nomenclature/DeathEstablishedByDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const deathEstablishedByService = {
    getAllDeathEstablishedBy(): Promise<AxiosResponse<DeathEstablishedByDto[]>> {
        return httpService.get('/DeathEstablishedBy/GetAllDeathEstablishedBy');
    }
};
