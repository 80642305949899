<template>
    <v-dialog v-model="dialogIsVisible" max-width="75%">
        <template #activator="{ on }">
            <btn action="List" :disabled="disabled" v-on="on" @click="getItems">Предписани лекарства</btn>
        </template>
        <data-table
            v-model="selectedDrugs"
            :headers="tableHeaders"
            :items="items"
            :item-class="itemRowBackground"
            :show-select="isMegaPrescription"
            :custom-select="isMegaPrescription"
            item-key="prescriptionDrugId"
            :sort-by="['prescriptionKey.prescriptionId']"
            :sort-desc="['issueDate']"
        >
            <template #title>
                Предписани лекарства
                <v-spacer />
                <btn icon action="Close" @click="dialogIsVisible = false" />
            </template>
            <template #actions>
                <btn v-if="selectedDrugs && selectedDrugs.length > 0" action="Save" @click="createMegaPrescription" />
            </template>
            <template
                v-if="(isNhifPrescription || isMegaPrescription) && selectedDrugs.length === 0"
                #rowActions="{ item }"
            >
                <btn icon action="Copy" @click="copyPrescription(item)" />
            </template>
            <template #selectable="{ item, select, isSelected }">
                <v-checkbox
                    v-if="item.segment !== 'B' && item.segment !== 'C'"
                    :value="isSelected"
                    @click="onSelectClick(item, isSelected, select)"
                />
            </template>
        </data-table>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    // Vuetify bugfix: Глобално регистриран custom компонент (като btn), поставен в activator слота на диалог,
    // не се появява при първоначално зареждане на SPA, а чак след рутиране. Ако се импортира изрично, нещата се оправят.
    import Btn from '@/component/Common/Btn.vue';
    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PatientPrescriptionDrugDto } from '@/model/Query/Patient/PatientPrescriptionDrugDto';
    import { prescriptionService } from '@/service/Exam/PrescriptionService';
    import { visitCacheService } from '@/service/Exam/VisitCacheService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { patientPrescriptionDrugService } from '@/service/Query/Patient/PatientPrescriptionDrugService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { Btn }
    })
    export default class PatientPrescriptionDrug extends Vue {
        @Prop()
        formType!: string;

        @Prop()
        disabled!: boolean;

        @Prop({ default: false })
        isMegaPrescription!: boolean;

        private dialogIsVisible: boolean = false;
        private items: PatientPrescriptionDrugDto[] = [];
        private selectedDrugs: PatientPrescriptionDrugDto[] = [];

        private onSelectClick(item: PatientPrescriptionDrugDto, isSelected: boolean, select: (value: boolean) => void) {
            select(!isSelected);
        }

        private get tableHeaders(): IDataTableHeader[] {
            return this.isNhifPrescription
                ? [
                      { text: 'Лекарство:', value: 'drug' },
                      { text: 'Предписание:', value: 'dosage' },
                      { text: 'Напълно платено:', value: 'isFullPayedText' },
                      { text: 'Дни:', value: 'days' },
                      { text: 'Брой:', value: 'packageCount' },
                      { text: 'Мкб:', value: 'icdCode' },
                      { text: 'Дата на предписване:', value: 'issueDate', formatter: formatters.date },
                      { text: 'Следваща рецепта:', value: 'nextPrescriptionDate', formatter: formatters.date },
                      { text: 'Отрязък:', value: 'segment' },
                      { text: 'Рецепта тип:', value: 'formType' },
                      { text: 'Рецепта НРН:', value: 'nrn' },
                      { text: 'НРН Протокол:', value: 'nrnProtocol' },
                      { text: 'Протокол начало:', value: 'protocolStartDate', formatter: formatters.date },
                      { text: 'Протокол край:', value: 'protocolEndDate', formatter: formatters.date },
                      { text: '', value: 'actionButtons' }
                  ]
                : [
                      { text: 'Лекарство:', value: 'drug' },
                      { text: 'Предписание:', value: 'dosage' },
                      { text: 'Дни:', value: 'days' },
                      { text: 'Брой:', value: 'packageCount' },
                      { text: 'Мкб:', value: 'icdCode' },
                      { text: 'Дата на предписване:', value: 'issueDate', formatter: formatters.date },
                      { text: 'Следваща рецепта:', value: 'nextPrescriptionDate', formatter: formatters.date },
                      { text: 'Отрязък:', value: 'segment' },
                      { text: 'Рецепта тип:', value: 'formType' },
                      { text: 'Рецепта НРН:', value: 'nrn' },
                      { text: '', value: 'actionButtons' }
                  ];
        }

        private get isPrescription5() {
            return (
                this.formType === PrescriptionFormTypeCode.Nhif5a || this.formType === PrescriptionFormTypeCode.Nhif5
            );
        }

        private get isNhifPrescription() {
            return (
                this.formType === PrescriptionFormTypeCode.Nhif5 ||
                this.formType === PrescriptionFormTypeCode.Nhif5a ||
                this.formType === PrescriptionFormTypeCode.Nhif5b ||
                this.formType === PrescriptionFormTypeCode.Nhif5c
            );
        }

        private async reloadVisitCache() {
            const visitCache = await visitCacheService.getVisitCacheById(currentVisitCache.value.id);
            await currentVisitCache.set(visitCache);
            this.$emit('reload-diagnoses');
        }

        private async createMegaPrescription() {
            this.$loading.show();
            try {
                await prescriptionService.createComplexPrescriptionV2(
                    this.selectedDrugs,
                    this.formType,
                    currentVisitCache.value.id
                );
                await this.reloadVisitCache();
                eventBus.$emit(
                    'create-referral-event',
                    currentVisitCache.value.id,
                    `${
                        this.formType === PrescriptionFormTypeCode.Nhif5
                            ? 'PrescriptionV2Nhif5'
                            : 'PrescriptionV2Nhif5a'
                    }`,
                    'Рецепта'
                );
                this.$router.push(
                    `/Exam/${
                        this.formType === PrescriptionFormTypeCode.Nhif5
                            ? 'PrescriptionV2Nhif5'
                            : 'PrescriptionV2Nhif5a'
                    }/${currentVisitCache.value.id}`
                );
            } finally {
                this.$loading.hide();
            }
        }

        private async getItems() {
            this.$loading.show();
            try {
                if (!this.items || this.items.length === 0) {
                    const response = this.isNhifPrescription
                        ? await patientPrescriptionDrugService.getPatientNhifPrescriptionDrugs(
                              currentPatientCache.value.key.patientId
                          )
                        : await patientPrescriptionDrugService.getPatientNhisPrescriptionDrugs(
                              currentPatientCache.value.key.patientId
                          );
                    //За рецепти от тип 5 или 5а показваме лекарства и от двата вида рецепти,
                    // при останалите филтрираме според типа на текущата рецепата
                    this.items = this.isPrescription5
                        ? response.filter(
                              (drug) =>
                                  drug.formTypeCode === PrescriptionFormTypeCode.Nhif5 ||
                                  drug.formTypeCode === PrescriptionFormTypeCode.Nhif5a
                          )
                        : response.filter((drug) => drug.formTypeCode === this.formType);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private itemRowBackground(item: PatientPrescriptionDrugDto): string {
            if (item.drug.includes('RF177') || item.drug.includes('RF202')) {
                return 'style-spiriva-spiolto-unit';
            } else if (item.drug.includes('RF220') || item.drug.includes('RF219')) {
                return 'style-spiriva-spiolto-filler';
            }
            return 'style-main';
        }

        private async copyPrescription(item: PatientPrescriptionDrugDto) {
            this.$loading.show();
            try {
                const response = this.isNhifPrescription
                    ? !this.isMegaPrescription
                        ? await prescriptionService.copyPrescription(
                              item.prescriptionKey,
                              this.formType,
                              currentVisitCache.value.id
                          )
                        : await prescriptionService.copyPrescriptionV2(
                              item.prescriptionKey,
                              this.formType,
                              currentVisitCache.value.id
                          )
                    : await prescriptionService.copyNhisPrescription(
                          item.prescriptionKey,
                          this.formType,
                          currentVisitCache.value.id
                      );
                await this.reloadVisitCache();
                this.$emit('input', response);
            } finally {
                this.dialogIsVisible = false;
                this.$loading.hide();
            }
        }
    }
</script>

<style scoped>
    ::v-deep .style-spiriva-spiolto-unit {
        background-color: var(--v-success2-base) !important;
    }

    ::v-deep .style-spiriva-spiolto-filler {
        background-color: var(--v-info2-base) !important;
    }
</style>
