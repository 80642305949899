import { AxiosResponse } from 'axios';

import { TimeBetweenExamsCommand } from '@/model/Query/Exam/TimeBetweenExamsCommand';
import { TimeBetweenExamsDto } from '@/model/Query/Exam/TimeBetweenExamsDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const timeBetweenExamsService = {
    getExams(filter: TimeBetweenExamsCommand): Promise<AxiosResponse<TimeBetweenExamsDto[]>> {
        return httpService.post('TimeBetweenExams/GetExams', filter);
    },

    exportExams(filter: TimeBetweenExamsCommand): Promise<void> {
        return httpService.download('/TimeBetweenExams/ExportExcel', { params: filter });
    }
};
