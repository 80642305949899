import { Type } from 'class-transformer';

import { Report365PreventiveGynecologistActivityRowDto } from '@/model/Report/Rhi/Report365/Report365PreventiveGynecologistActivityRowDto';

export class Report365PreventiveGynecologistActivityDto {
    public name: string = '';

    @Type(() => Report365PreventiveGynecologistActivityRowDto)
    public preventiveGynecologistActivities: Report365PreventiveGynecologistActivityRowDto[] = [];
}
