<template>
    <v-card>
        <v-card-title>Необходими и извършени профилактични дейности</v-card-title>
        <v-card-actions>
            <btn action="Save" :disabled="saveIsDisabled" @click="save"></btn>
        </v-card-actions>
        <v-card-text>
            <v-card class="mb-3">
                <v-card-title>Извършени дейности</v-card-title>
                <v-data-table
                    v-model="preventiveData.selectedPerformedActivities"
                    :items="performedActivities"
                    :headers="performedHeaders"
                    :footer-props="{ itemsPerPageText: 'Редове на страница', itemsPerPageAllText: 'Всички' }"
                    :items-per-page="-1"
                    show-select
                    item-key="code"
                    item-selectable="isSelectable"
                ></v-data-table>
            </v-card>
            <v-card>
                <v-card-title>Необходими дейности</v-card-title>
                <v-data-table
                    v-model="preventiveData.selectedRequiredActivities"
                    :items="requiredActivities"
                    :headers="requiredHeaders"
                    :footer-props="{ itemsPerPageText: 'Редове на страница', itemsPerPageAllText: 'Всички' }"
                    :items-per-page="-1"
                    show-select
                    item-key="code"
                    item-selectable="isSelectable"
                ></v-data-table>
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PatientDiagnosisCheckCommand } from '@/model/Exam/PatientDiagnosisCheckCommand';
    import { PatientPreventiveDataDto } from '@/model/Patient/PatientPreventiveDataDto';
    import { PatientSpecialActivityDto } from '@/model/Patient/PatientSpecialActivityDto';
    import { examDiagnosisService } from '@/service/Exam/ExamDiagnosisService';
    import { insuranceStatusService } from '@/service/Patient/InsuranceStatusService';
    import { patientPreventiveVisitService } from '@/service/Patient/PatientPreventiveVisitService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class PatientPreventiveVisit extends Vue {
        @Prop({ required: true })
        private patientId!: number;

        private preventiveData: PatientPreventiveDataDto = new PatientPreventiveDataDto();
        private performedActivities: PatientSpecialActivityDto[] = [];
        private requiredActivities: PatientSpecialActivityDto[] = [];
        private saveIsDisabled: boolean = false;

        private get performedHeaders(): IDataTableHeader[] {
            return [
                {
                    text: 'Тип',
                    value: 'activityType'
                },
                {
                    text: 'Код/Специалист/Имунизация',
                    value: 'activityName'
                },
                {
                    text: 'Име на изследване',
                    value: 'labTestName'
                },
                {
                    text: 'Дата на последно извършване',
                    value: 'lastPerformedActivityDate'
                },
                {
                    text: 'Описание',
                    value: 'activityDescription'
                }
            ];
        }

        private get requiredHeaders(): IDataTableHeader[] {
            return [
                {
                    text: 'Тип',
                    value: 'activityType'
                },
                {
                    text: 'Код/Специалист/Имунизация',
                    value: 'activityName'
                },
                {
                    text: 'Име на изследване',
                    value: 'labTestName'
                },
                {
                    text: 'Извършени',
                    value: 'totalPerformedActivities'
                },
                {
                    text: 'Необходими',
                    value: 'totalActivities'
                },
                {
                    text: 'Описание',
                    value: 'activityDescription'
                }
            ];
        }

        private async mounted() {
            const response = await patientPreventiveVisitService.getPreventiveActivities(
                this.patientId,
                userContextCache.currentDoctorEmployeeSeqNumber ?? 0
            );
            this.preventiveData = response;

            for (const activity of this.preventiveData.activities) {
                if (activity.isPerformed) {
                    activity.isSelectable = activity.activityType === 'Изследване';
                    this.performedActivities.push(activity);
                } else {
                    activity.isSelectable = true;
                    this.requiredActivities.push(activity);
                }
            }

            this.checkPatientRegistrationStatus();
            this.checkInsuranceStatus();
            this.checkPreventionLimit();
        }

        private checkPatientRegistrationStatus() {
            if (currentPatientCache.value.isDeregistered()) {
                this.$notifier.showWarning('Внимание', 'Пациентът е отписан!');
            }

            if (currentPatientCache.value.isDeceased()) {
                this.$notifier.showWarning('Внимание', 'Пациентът е починал!');
            }
        }

        private checkInsuranceStatus() {
            if (currentPatientCache.isLoaded) {
                insuranceStatusService
                    .checkStatus(
                        currentPatientCache.value.key.patientId,
                        currentPatientCache.value.personIdentification.pidTypeCode ?? '',
                        currentPatientCache.value.personIdentification.identifier,
                        currentPatientCache.value.insuranceStatus
                    )
                    .then((response) => {
                        if (response) {
                            currentPatientCache.value.insuranceStatus = response;
                        }
                    });
            }
        }

        private async checkPreventionLimit() {
            const command = new PatientDiagnosisCheckCommand();
            command.patientId = this.patientId;
            const adultAge = 18;
            const babyAge = 1;
            const childAge = 6;
            command.icdCode =
                this.preventiveData.patientAge >= adultAge
                    ? 'Z00.0'
                    : this.preventiveData.patientAge >= 0 && this.preventiveData.patientAge <= babyAge
                      ? 'Z00.1'
                      : this.preventiveData.patientAge > babyAge && this.preventiveData.patientAge <= childAge
                        ? 'Z00.2'
                        : 'Z00.3';
            command.examDate = new Date();
            const response = (await examDiagnosisService.checkForPreventionLimitations(command)).data;
            if (response) {
                this.$notifier.showWarning('Внимание', response);
            }
        }

        private async save() {
            try {
                this.saveIsDisabled = true;
                this.$loading.show();
                this.preventiveData.deputyDoctorId = userContextCache.current?.deputyDoctorId ?? null;
                this.preventiveData.deputyDoctorIsHired = userContextCache.current?.deputyDoctorIsHired ?? null;
                const response = await patientPreventiveVisitService.savePreventiveVisit(this.preventiveData);
                if (response) {
                    this.$router.push(`/Exam/AmbulatorySheet/Edit/${response}`);
                } else {
                    this.$notifier.showError('', 'Неуспешно създаване на амбулаторен лист.');
                }
            } finally {
                this.$loading.hide();
                this.saveIsDisabled = false;
            }
        }
    }
</script>
