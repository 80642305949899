import { NhisAllergyDto } from '@/model/Nhis/Allergy/NhisAllergyDto';
import { NhisAllergyExtractionCommand } from '@/model/Nhis/Allergy/NhisAllergyExtractionCommand';
import { NhisXmlRequestExtendedDto } from '@/model/Nhis/NhisXmlRequestExtendedDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { nhisService } from '@/service/Nhis/NhisService';
import { signXmlService } from '@/service/Report/SignXmlService';
import { loadingState } from '@/store/LoadingState';
import { nhisKey } from '@/store/NhisNhifAccessTokenCache';

const getAllergyCreateXml = async function getAllergyCreateXml(
    requestData: NhisXmlRequestExtendedDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/CreateAllergy`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getAllergyUpdateXml = async function getAllergyUpdateXml(
    requestData: NhisXmlRequestExtendedDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/UpdateAllergy`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getAllergyExtractionXml = async function getAllergyExtractionXml(
    requestData: NhisAllergyExtractionCommand
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/AllergyExtraction`, requestData);
    console.log(response);
    return response?.data ?? '';
};

export const nhisAllergyService = {
    async postCreate(requestData: NhisXmlRequestExtendedDto) {
        loadingState.show();
        try {
            const xmlString = await getAllergyCreateXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const allergyResult = await nhisService.postXmlToNhisWithId<NhisAllergyDto>(
                    'PostCreateAllergy',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(allergyResult?.response);
                if (allergyResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${allergyResult.error}`);
                } else {
                    if (allergyResult?.warnings?.length > 0) {
                        nhisService.showWarnings(allergyResult.warnings);
                    }
                    return allergyResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postUpdate(requestData: NhisXmlRequestExtendedDto) {
        loadingState.show();
        try {
            const xmlString = await getAllergyUpdateXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const allergyResult = await nhisService.postXmlToNhisWithId<NhisAllergyDto>(
                    'PostUpdateAllergy',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(allergyResult?.response);
                if (allergyResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${allergyResult.error}`);
                } else {
                    if (allergyResult?.warnings?.length > 0) {
                        nhisService.showWarnings(allergyResult.warnings);
                    }
                    return allergyResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async extractAllergy(requestData: NhisAllergyExtractionCommand) {
        loadingState.show();
        try {
            const xmlString = await getAllergyExtractionXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const allergyResult = await nhisService.postXmlToNhisWithId<NhisAllergyDto>(
                    'PostAllergyExtraction',
                    signResponse.contents,
                    requestData.key.patientId
                );
                console.log(allergyResult?.response);
                if (allergyResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${allergyResult.error}`);
                } else {
                    if (allergyResult?.warnings?.length > 0) {
                        nhisService.showWarnings(allergyResult.warnings);
                    }
                    return `/Patient/Allergy/${requestData.key.patientId}`;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    }
};
