export class PregnancyResultDto {
    public birthDate: Date | null = null;
    public birthTerm: string | null = null;
    public birthSequence: number | null = null;
    public alive: string | null = null;
    public childWeight: number | null = null;
    public childHeight: number | null = null;
    public gender: string | null = null;
    public birthDescription: string | null = null;
    public pathologicDeviations: string | null = null;
    public parturientCondition: string | null = null;
    public childCondition: string | null = null;
    public otherData: string | null = null;
}
