<template>
    <v-card v-if="currentUser">
        <v-card-title>
            <div>
                Access token-ът е издаден на <date-label v-model="issuedAt" show-time /> {{ expirationText }}
                <!-- Този надпис се вижда само за 1-2 секунди. -->
                <span v-if="isExecutingSigninSilent">Подновяване на сесията...</span>
            </div>
        </v-card-title>
        <v-card-actions>
            <btn action="Success" :disabled="isExecutingSigninSilent" @click="signinSilent()">
                Подновяване на сесията (удължаване срока на access token-а)
            </btn>
        </v-card-actions>
        <v-card-text>
            <pre>{{ currentUserJson }}</pre>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import DateLabel from '@/component/Date/DateLabel.vue';
    import { oidcClient } from '@/service/Infrastructure/OidcClient';
    import { currentUser } from '@/store/User/CurrentUser';
    import { signinProgress } from '@/store/User/SigninProgress';

    @Component({ components: { DateLabel } })
    export default class CurrentUserDebugInfo extends Vue {
        private expirationText: string = '';

        private get isExecutingSigninSilent() {
            return signinProgress.isExecutingSigninSilent;
        }

        private mounted() {
            this.calcExpirationText();
        }

        private calcExpirationText() {
            const slowRefreshInMs = 30000;
            const fastRefreshInMs = 1000;
            const fewSeconds = 120;
            const secondsInMinute = 60;

            let nextRefreshInMs = signinProgress.isExecutingSigninSilent ? fastRefreshInMs : slowRefreshInMs;

            let expirationText = '';
            if (currentUser.user?.expires_in) {
                expirationText = currentUser.user.expired ? 'и е изтекъл преди' : 'и изтича след';

                const expiresIn = Math.abs(currentUser.user.expires_in);
                if (expiresIn <= fewSeconds) {
                    expirationText += ` ${expiresIn} секунди.`;
                    nextRefreshInMs = fastRefreshInMs;
                } else {
                    expirationText += ` ${Math.round(expiresIn / secondsInMinute)} минути.`;
                }
            }
            this.expirationText = expirationText;

            setTimeout(this.calcExpirationText, nextRefreshInMs);
        }

        private get currentUser() {
            return currentUser.user;
        }

        private get currentUserJson() {
            return JSON.stringify(currentUser.user, null, ' ');
        }

        private get issuedAt() {
            const msInSecond = 1000;
            const timeInSeconds = currentUser.user?.profile.iat;
            return timeInSeconds ? new Date(timeInSeconds * msInSecond) : null;
        }

        private signinSilent() {
            // Стартира подновяването асинхронно.
            oidcClient.signinSilent();
            this.calcExpirationText();
        }
    }
</script>
