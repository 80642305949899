import { plainToInstance } from 'class-transformer';

import { BatchChildDto } from '@/model/Practice/Service/BatchChildDto';
import { BatchDto } from '@/model/Practice/Service/BatchDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const batchService = {
    async getAllOffers(id: number): Promise<BatchChildDto[]> {
        const response = await httpService.get<BatchChildDto[]>(`Batch/GetAllOffers/${id}`);
        return plainToInstance(BatchChildDto, response.data);
    },

    async createBatch(dto: BatchDto): Promise<string> {
        const response = await httpService.post<string>('Batch/CreateBatch', dto);
        return response.data;
    },

    async updateBatch(dto: BatchDto): Promise<string> {
        const response = await httpService.post<string>('Batch/UpdateBatch', dto);
        return response.data;
    },

    async getBatch(practiceId: number, serviceTitleSeqNumber: number): Promise<BatchDto> {
        const response = await httpService.get('Batch/GetBatch', {
            params: { practiceId, serviceTitleSeqNumber }
        });
        return plainToInstance(BatchDto, response.data);
    },

    async deleteBatch(practiceId: number, serviceTitleSeqNumber: number): Promise<void> {
        await httpService.delete('Batch/DeleteBatch', { params: { practiceId, serviceTitleSeqNumber } });
    }
};
