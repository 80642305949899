import Vue from 'vue';

import { LicensingStatusDto } from '@/model/Licensing/LicensingStatusDto';
import { licensingStatusService } from '@/service/Licensing/LicensingStatusService';

const emptyStatus = new LicensingStatusDto();

class LicensingCache {
    private _status: LicensingStatusDto = emptyStatus;

    public get status() {
        return this._status;
    }

    public async load(practiceId: number) {
        this._status = await licensingStatusService.getLicensingStatus(practiceId);
    }

    public clear() {
        this._status = emptyStatus;
    }
}

export const licensingCache = Vue.observable(new LicensingCache());
