<template>
    <v-card>
        <v-card-title>Време за преглед</v-card-title>
        <v-card-text class="mt-6">
            <v-row v-for="examDurationSetting in examDurationSettings" :key="examDurationSetting.id">
                <v-col class="py-0">
                    <span class="colorBox" :class="examDurationSetting.color"></span>
                    <span class="ml-3">{{ examDurationSetting.name }}</span>
                </v-col>
                <v-col class="py-0">
                    <text-field
                        v-model="examDurationSetting.examDurationInMinutes"
                        type="number"
                        label="Време в минути"
                    ></text-field>
                </v-col>
            </v-row>
            <v-card-actions>
                <btn action="Save" @click="update" />
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ExamDurationSettingDto } from '@/model/Setting/ExamDuration/ExamDurationSettingDto';
    import { examDurationSettingService } from '@/service/Setting/ExamDurationSettingService';
    import { userSettingsState } from '@/store/User/UserSettingsState';

    @Component
    export default class ExamDurationSetting extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private examDurationSettings: ExamDurationSettingDto[] = [];

        private async mounted() {
            this.$loading.show();
            try {
                this.examDurationSettings = await examDurationSettingService.getExamDurationSetting(
                    this.practiceId,
                    this.doctorEmployeeSeqNumber
                );
            } finally {
                this.$loading.hide();
            }
        }

        private async update() {
            this.$loading.show();
            try {
                await examDurationSettingService.updateExamDurationSetting(
                    this.practiceId,
                    this.doctorEmployeeSeqNumber,
                    this.examDurationSettings
                );
                //След запис на промените зареждаме новите настройките и ги кешираме в localStorage
                await userSettingsState.loadFromServer();
                this.$notifier.showSuccess('', 'Успешно записани настройки');
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>

<style scoped>
    .colorBox {
        height: 15px;
        width: 30px;
        display: inline-block;
    }
</style>
