import { Type } from 'class-transformer';

import { PendingUserRegistrationDto } from '@/model/Employee/PendingUserRegistrationDto';
import { InboxDto } from '@/model/Notification/InboxDto';

export class PushNotificationDto {
    @Type(() => PendingUserRegistrationDto)
    public pendingUserRegistrations: PendingUserRegistrationDto[] = [];

    @Type(() => InboxDto)
    public unreadMessages: InboxDto[] = [];
}
