import { AxiosResponse } from 'axios';

import { NhisDrugApplicationBodyPartDto } from '@/model/Nomenclature/NhisDrugApplicationBodyPartDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhisDrugApplicationBodyPartService = {
    getNhisDrugApplicationBodyParts(): Promise<AxiosResponse<NhisDrugApplicationBodyPartDto[]>> {
        return httpService.get('/NhisDrugApplicationBodyPart/GetNhisDrugApplicationBodyParts');
    }
};
