export class InvoiceRecipientDto {
    public id: number = 0;
    public name: string | null = null;
    public vat: string | null = null;
    public eik: string | null = null;
    public managerName: string | null = null;
    public address: string | null = null;
    public cityName: string | null = null;
    public practiceId: number | null = null;

    public get title() {
        return `${this.name ?? ''}, ЕИК: ${this.eik ?? ''}, МОЛ: ${this.managerName ?? ''}, ИН по ДДС: ${
            this.vat ?? ''
        }`;
    }
}
