import { render, staticRenderFns } from "./SickSheetPatientInfo.vue?vue&type=template&id=4e52ff2c"
import script from "./SickSheetPatientInfo.vue?vue&type=script&lang=ts"
export * from "./SickSheetPatientInfo.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports