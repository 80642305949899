export class CalendarEventModel {
    public id: number | null = null;
    public name: string = '';
    public start: Date | null = null;
    public end: Date | null = null;
    public color: string | null = null;
    public timed: boolean = true;
    public isRecurrence: boolean = false;
    public category: string = '';
    public disabled: boolean = false;
}
