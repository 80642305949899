<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">Раздел ІII. Консултативно-амбулаторна дейност</h3></v-col>
            <v-col cols="12"><h3 class="mb-2">4. Диспансерно наблюдение</h3></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><DataTableComponent v-model="value.dispensaryActivities" :headers="headers" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Report365DispensaryActivityDto } from '@/model/Report/Rhi/Report365/Report365DispensaryActivityDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class DispensaryActivity extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Report365DispensaryActivityDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: 'Наименование на болестите ', value: 'name' },
            { text: 'Шифър', value: 'code' },
            { text: 'Под наблюдение в началото на годината', value: 'countStartYear' },
            { text: 'Взети под наблюдение през годината', value: 'countDuringYear' },
            { text: 'Снети от наблюдение - всичко', value: 'removedTotal' },
            { text: 'Снети от наблюдение - оздравяване', value: 'removedRecovered' },
            { text: 'Снети от наблюдение - умиране', value: 'removedDead' },
            { text: 'Снети от наблюдение - промяна в местож., работа и др.причини', value: 'removedChanged' },
            { text: 'Остават под наблюдение в края на годината', value: 'countEndYear' },
            { text: 'В т.ч. деца до 17 г.', value: 'countChildren' }
        ];

        @Watch('value.dispensaryActivities', { deep: true })
        private onValueChanged() {
            const timeout = 3000;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => this.calculateSectionRow(), timeout);
        }

        private calculateSectionRow() {
            for (let idx = 0; idx < this.value.dispensaryActivities.length; idx++) {
                if (this.value.dispensaryActivities[idx].isSection) {
                    this.value.dispensaryActivities[idx].countChildren = 0;
                    this.value.dispensaryActivities[idx].countDuringYear = 0;
                    this.value.dispensaryActivities[idx].countStartYear = 0;
                    this.value.dispensaryActivities[idx].removedChanged = 0;
                    this.value.dispensaryActivities[idx].removedDead = 0;
                    this.value.dispensaryActivities[idx].removedRecovered = 0;

                    for (let kidx = 0; kidx < this.value.dispensaryActivities.length; kidx++) {
                        if (
                            this.value.dispensaryActivities[kidx].parentCode ===
                            this.value.dispensaryActivities[idx].code
                        ) {
                            this.value.dispensaryActivities[idx].countChildren =
                                (this.value.dispensaryActivities[idx].countChildren ?? 0) +
                                (this.value.dispensaryActivities[kidx].countChildren ?? 0);
                            this.value.dispensaryActivities[idx].countDuringYear =
                                (this.value.dispensaryActivities[idx].countDuringYear ?? 0) +
                                (this.value.dispensaryActivities[kidx].countDuringYear ?? 0);
                            this.value.dispensaryActivities[idx].countStartYear =
                                (this.value.dispensaryActivities[idx].countStartYear ?? 0) +
                                (this.value.dispensaryActivities[kidx].countStartYear ?? 0);
                            this.value.dispensaryActivities[idx].removedChanged =
                                (this.value.dispensaryActivities[idx].removedChanged ?? 0) +
                                (this.value.dispensaryActivities[kidx].removedChanged ?? 0);
                            this.value.dispensaryActivities[idx].removedDead =
                                (this.value.dispensaryActivities[idx].removedDead ?? 0) +
                                (this.value.dispensaryActivities[kidx].removedDead ?? 0);
                            this.value.dispensaryActivities[idx].removedRecovered =
                                (this.value.dispensaryActivities[idx].removedRecovered ?? 0) +
                                (this.value.dispensaryActivities[kidx].removedRecovered ?? 0);
                        }
                    }
                }
            }
        }

        private mounted() {
            return this.calculateSectionRow();
        }
    }
</script>
