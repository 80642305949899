export class NhisLongtermCareBaseCommand {
    public registerId: number | null = null;
    public patientId: number = 0;
    public registerEntitySeqNumber: number | null = null;
    public practiceId: number = 0;
    public registerType: string = '';
    public doctorEmployeeSeqNumber: number | null = null;
    public doctorEmployeeId: number | null = null;
    public xmlString: string | null = null;
    public accessTokenValue: string | null = null;
}
