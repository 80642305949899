import { Type } from 'class-transformer';

import { Report365AssistedReproductionRowDto } from '@/model/Report/Rhi/Report365/Report365AssistedReproductionRowDto';

export class Report365AssistedReproductionDto {
    public name: string = '';

    @Type(() => Report365AssistedReproductionRowDto)
    public assistedReproductions: Report365AssistedReproductionRowDto[] = [];
}
