<template>
    <ExamLayout
        :can-delete="documentId > 0"
        :can-print="documentId > 0"
        title="Имунизация"
        @updated="createOrUpdate"
        @printed="printEventHandler"
        @deleted="deleteEntity"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <immunization-editor v-model="document"></immunization-editor>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import ImmunizationEditor from '@/component/Immunization/ImmunizationEditor.vue';
    import { PatientImmunizationDto } from '@/model/Patient/Immunization/PatientImmunizationDto';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { patientImmunizationService } from '@/service/Patient/PatientImmunizationService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: { UnsavedChanges, ImmunizationEditor, ExamLayout },

        //За инфо AmbulatorySheet.vue
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class Immunization extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private examId!: number;

        @Prop()
        private documentId!: number;

        private document: PatientImmunizationDto = new PatientImmunizationDto();
        private initialValue: PatientImmunizationDto = new PatientImmunizationDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private copyObject() {
            Object.assign(this.initialValue, this.document);
        }

        private async load() {
            this.$loading.show();
            this.leaveDialogRef = this.leaveDialog;
            try {
                if (!this.documentId) {
                    this.document.patientId = currentPatientCache.value.key.patientId ?? 0;
                    this.document.occurrenceDate = new Date();
                    this.document.examId = this.examId;
                } else {
                    const response = await patientImmunizationService.getPatientImmunization(this.documentId);
                    this.document = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async createOrUpdate() {
            this.$loading.show();
            try {
                if (this.document.id <= 0) {
                    const result = await patientImmunizationService.createPatientImmunization(this.document);
                    this.document.id = result.data;
                    this.copyObject();
                    eventBus.$emit('create-referral-event', this.document.id, 'Immunization', 'Имунизация');
                    this.$router.push(`/Exam/Immunization/Edit/${this.document.id}`);
                } else {
                    await patientImmunizationService.updatePatientImmunization(this.document);
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEntity() {
            this.$loading.show();
            try {
                await patientImmunizationService.deletePatientImmunization(this.documentId);
                eventBus.$emit('delete-referral-event', this.documentId, 'Immunization', 'Имунизация');
                this.copyObject();
                this.$router.push(`/Exam/Immunization/${this.document.examId}`);
            } finally {
                this.$loading.hide();
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.document.patientId.toString();
                await printService.printReport('Patient.PatientImmunization', parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.document.patientId) {
                this.$router.push(`/Print/Patient.PatientImmunization/${this.document.patientId}`);
            } else {
                // Todo показване на грешка
            }
        }
    }
</script>
