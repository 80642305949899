<template>
    <div>
        <v-card-title>Анамнестични данни</v-card-title>
        <v-row>
            <v-col cols="6" md="3" class="d-flex align-stretch">
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="value.age" type="number" label="Менархе, възраст" />
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="value.nomrDeviations" label="Отклонения от нормата"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <h4 class="font-weight-bold">Менструален цикъл</h4>
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.periodDuration" type="number" label="Продължителност дни" />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.periodFrequency" type="number" label="Периодичност дни" />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.otherDeviations" label="Отклонения" />
                    </v-col>
                    <v-col cols="12">
                        <date-picker v-model="value.lastMenstruation" label="Последна менструация дата:" />
                    </v-col>
                    <v-col cols="12">
                        <text-field
                            v-model="value.infectiousDiseases"
                            label="Прекарани инфекциозни болести, диагноза:"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="value.familyBurden" label="Фамилна обремененост, заболявания:" dense />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="6" md="3" class="d-flex align-stretch">
                <v-row>
                    <v-col cols="12">
                        <date-picker v-model="value.probableBirthTerm" label="Вероятен термин на раждане:" />
                    </v-col>
                    <v-col cols="12">
                        <h4 class="font-weight-bold">Рискови фактори</h4>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="value.smoking" label="Тютюнопушене"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="value.alcohol" label="Злоупотреба с алкохол"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="value.overweight" label="Надномермено тегло"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="value.overAge" label="Възраст"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="value.malnutrition" label="Недохранване"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="value.foodAlergy" label="Алергии към храни"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="value.medicineAlergy" label="Алергии към лекарства"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.otherAlergy" />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.operationDiagnosis" label="Прекарани операции, диагноза" />
                    </v-col>
                    <v-col cols="12">
                        <text-field
                            v-model="value.previousPregnancyProblems"
                            label="Особености и усложнения при пред. бременности и раждания"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="6" md="3" class="d-flex align-stretch">
                <v-row>
                    <v-col cols="12">
                        <text-field
                            v-model="value.gestWeek"
                            type="number"
                            label="Срок на бременността - гестационна седмица"
                        />
                    </v-col>
                    <v-col cols="12">
                        <h4 class="font-weight-bold">Аборти</h4>
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.abortByRequestCount" type="number" label="По желание, бр." />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.spontaneousAbortCount" type="number" label="Спонтанни, бр." />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.abortGestWeek" type="number" label="Гестационна седмица" />
                    </v-col>
                    <v-col cols="12">
                        <text-field
                            v-model="value.abortByMedicineNeedCount"
                            type="number"
                            label="По мед. показания, бр."
                        />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.abortDiagnosis" label="Диагноза:" />
                    </v-col>
                    <v-col cols="12">
                        <date-picker v-model="value.lastAbortDate" label="Последен аборт дата:" />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="value.otherDiseases" label="Други заболявания:" dense />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            v-model="value.takingDrugs"
                            label="Приемани лекарства преди, по време на бременността:"
                            dense
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="6" md="3" class="d-flex align-stretch">
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="value.pregnancySequence" type="number" label="Поредност на бременността" />
                    </v-col>
                    <v-col cols="12">
                        <date-picker v-model="value.lastBirthDate" label="Последен раждане на дата:" />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.aliveChildrenCount" type="number" label="Живородени деца бр." />
                    </v-col>
                    <v-col cols="12">
                        <text-field
                            v-model="value.stillBornChildrenCount"
                            type="number"
                            label="Мъртвородени деца бр."
                        />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.prematureChildrenCount" type="number" label="Недоносени деца бр." />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="value.transferredChildrenCount" type="number" label="Преносени деца бр." />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="value.sterilityDiagnosis" label="Лекуван стерилитет диагноза:" dense />
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="value.unwantedPregnancy" label="Нежелана бременност"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                            v-model="value.parentsIncompatibility"
                            label="Кръвногрупова несъвместимост м/д родителите"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { AnamnesticDataCardDto } from '@/model/Register/Pregnancy/PregnancyCard/AnamnesticDataCardDto';

    @Component
    export default class AnamnesticData extends Vue {
        @Prop()
        value!: AnamnesticDataCardDto;
    }
</script>
