<template>
    <v-select
        v-if="currentVisitIsLoaded"
        label="Диагноза"
        :value="value"
        :items="diagnoses"
        item-text="icdName"
        :rules="required ? autocompleteRule.concat([$validator.required]) : []"
        :class="required ? 'required' : ''"
        return-object
        dense
        @change="$emit('input', ...arguments)"
    />
    <span v-else>Не е избран преглед</span>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PrescriptionDiagnosisDto } from '@/model/Exam/PrescriptionDiagnosisDto';
    import { currentVisitCache } from '@/store/CurrentVisitCache';

    @Component
    export default class PrescriptionDiagnosisPicker extends Vue {
        @Prop({ type: Object })
        private value!: PrescriptionDiagnosisDto;

        @Prop()
        private required!: boolean;

        @Prop()
        private diagnoses!: PrescriptionDiagnosisDto[];

        private autocompleteRule: ((val: PrescriptionDiagnosisDto) => void)[] | boolean[] | string[] = [
            (val: PrescriptionDiagnosisDto) => val.icdName !== '' || `Попълнете задължителните полета`
        ];

        private get currentVisitIsLoaded() {
            return currentVisitCache.isLoaded;
        }
    }
</script>
