<template>
    <left-menu-layout :menu-items="menuItems" router-name="setting" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import LeftMenuLayout from '@/component/Menu/LeftMenuLayout.vue';
    import { createSettingMenuItems } from '@/model/Menu/SettingMenuItems';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { LeftMenuLayout }
    })
    export default class SettingMenu extends Vue {
        @Prop()
        private practiceId!: number | null;

        @Prop()
        private employeeSeqNumber!: number | null;

        private get menuItems() {
            return createSettingMenuItems();
        }

        private mounted() {
            // Виж коментара в PracticeMenu.vue.
            if (this.practiceId) {
                if (this.employeeSeqNumber) {
                    userContextCache.trySwitchToEmployee(this.practiceId, this.employeeSeqNumber);
                } else {
                    userContextCache.trySwitchToPractice(this.practiceId);
                }
            }
        }
    }
</script>
