export class PendingUserRegistrationDto {
    public userName: string = '';
    public personName: string = '';
    public practiceId: number = 0;
    public practiceNumber: string = '';
    public employeeSeqNumber: number = 0;
    public employeeName: string = '';
    public specialtyName: string = '';
    public deputyName: string = '';
    public deputyDoctorIsHired: string = '';

    public get notificationText() {
        return `• Потребител ${
            this.personName ? `${this.personName} (${this.userName})` : this.userName
        } очаква одобрение да работи като ${
            this.deputyName
                ? `${this.deputyName} в ролята на ${this.deputyDoctorIsHired ? 'нает' : 'заместващ'} лекар на титуляр`
                : 'служител'
        } ${this.employeeName}${this.specialtyName ? `, ${this.specialtyName}` : ''}${
            this.practiceNumber ? ` в практика № ${this.practiceNumber}` : ''
        }.`;
    }
}
