import { AxiosResponse } from 'axios';

import { DeviceLoginDto } from '@/model/Practice/Fiscal/DeviceLoginDto';
import { FiscalDeviceOperatorDto } from '@/model/Practice/Fiscal/FiscalDeviceOperatorDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const fiscalDeviceOperatorService = {
    getDeviceLogin(
        practiceId: number,
        fiscalDeviceSeqNumber: number,
        fiscalMemoryCode: string
    ): Promise<AxiosResponse<DeviceLoginDto>> {
        return httpService.get('FiscalDeviceOperator/GetDeviceLogin', {
            params: { practiceId, fiscalDeviceSeqNumber, fiscalMemoryCode }
        });
    },

    getFiscalDeviceOperators(
        practiceId: number,
        fiscalDeviceSeqNumber: number
    ): Promise<AxiosResponse<FiscalDeviceOperatorDto[]>> {
        return httpService.get('FiscalDeviceOperator/GetFiscalDeviceOperators', {
            params: { practiceId, fiscalDeviceSeqNumber }
        });
    },

    getFiscalDeviceOperator(
        practiceId: number,
        fiscalDeviceSeqNumber: number,
        deviceOperatorSeqNumber: number
    ): Promise<AxiosResponse<FiscalDeviceOperatorDto>> {
        return httpService.get('FiscalDeviceOperator/GetFiscalDeviceOperator', {
            params: { practiceId, fiscalDeviceSeqNumber, deviceOperatorSeqNumber }
        });
    },

    createFiscalDeviceOperator(data: FiscalDeviceOperatorDto): Promise<AxiosResponse<FiscalDeviceOperatorDto>> {
        return httpService.post('FiscalDeviceOperator/CreateFiscalDeviceOperator', data);
    },

    updateFiscalDeviceOperator(data: FiscalDeviceOperatorDto): Promise<AxiosResponse<FiscalDeviceOperatorDto>> {
        return httpService.post('FiscalDeviceOperator/UpdateFiscalDeviceOperator', data);
    },

    deleteFiscalDeviceOperator(
        practiceId: number,
        fiscalDeviceSeqNumber: number,
        deviceOperatorSeqNumber: number
    ): Promise<AxiosResponse> {
        return httpService.delete('FiscalDeviceOperator/DeleteFiscalDeviceOperator', {
            params: { practiceId, fiscalDeviceSeqNumber, deviceOperatorSeqNumber }
        });
    }
};
