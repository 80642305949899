<template>
    <v-form ref="form" v-model="isFormValid">
        <v-card>
            <v-card-title>
                <h3>{{ value.lotExists ? 'Редактиране' : 'Добавяне' }} на партида</h3>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="3">
                        <v-text-field v-model="value.lot.lotNumber" dense label="Номер на партида" required />
                    </v-col>
                    <v-col cols="6" md="3">
                        <text-field
                            v-model="value.lot.dosesInContainer"
                            type="number"
                            label="Брой дози в опаковката"
                            required
                    /></v-col>
                    <v-col cols="6" md="3">
                        <date-picker v-model="value.lot.expirationDate" required label="Валидна до" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <template v-if="value.lotExists">
                    <btn v-if="!value.lotIsInUse" action="Delete" text @click="deleteVaccineLot" />
                    <div v-else><v-icon color="info">mdi-information-outline</v-icon> Партидата е в употреба</div>
                </template>
                <v-spacer />
                <btn action="Cancel" text @click="closeDialog" />
                <btn action="Save" color="primary" text @click="createOrUpdateVaccineLot" />
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import { NhisVaccineLotDialogModel } from '@/model/Nhis/VaccineLot/NhisVaccineLotDialogModel';
    import { nhisVaccineLotService } from '@/service/Nhis/NhisVaccineLotService';

    @Component
    export default class NhisVaccineLotEditor extends Vue {
        @Prop()
        private value!: NhisVaccineLotDialogModel;

        @Ref()
        private form!: { validate: () => boolean; resetValidation: () => boolean };

        private isFormValid: boolean = false;

        private async createOrUpdateVaccineLot() {
            this.form.validate();
            this.$loading.show();
            if (this.isFormValid) {
                try {
                    await nhisVaccineLotService.createOrUpdateVaccineLot(this.value.lot);
                    this.$notifier.showSuccess('', `Успешен запис`);
                    this.$emit('saved');
                    this.closeDialog();
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private async deleteVaccineLot() {
            this.$loading.show();
            try {
                await nhisVaccineLotService.deleteVaccineLot(this.value.lot);
                this.$emit('deleted');
                this.closeDialog();
            } finally {
                this.$loading.hide();
            }
        }

        private closeDialog() {
            this.value.dialogIsVisible = false;
            this.form.resetValidation();
        }
    }
</script>
