import { plainToInstance } from 'class-transformer';

import { AdvertisementDto } from '@/model/Advertisement/AdvertisementDto';
import { AdvertisementFilterCommand } from '@/model/Advertisement/AdvertisementFilterCommand';
import { httpService } from '@/service/Infrastructure/HttpService';

export const advertisementService = {
    async getValidAdvertisements(command: AdvertisementFilterCommand): Promise<AdvertisementDto[]> {
        const response = await httpService.get<AdvertisementFilterCommand[]>('/Advertisement/GetValidAdvertisements', {
            params: command
        });
        return plainToInstance(AdvertisementDto, response.data);
    }
};
