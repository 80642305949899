import { MedicationPreviewDto } from '@/model/Exam/DrugProtocol/Preview/MedicationPreviewDto';

export class ProtocolPreviewDto {
    public nrn: string = '';
    public status: string = '';
    public protocolType: string = '';
    public mkb: string = '';
    public validityPeriod: number | null = null;
    public startDate: Date | null = null;
    public endDate: Date | null = null;
    public error: string | null = null;
    public medications: MedicationPreviewDto[] = [];
}
