<template>
    <div>
        <v-row>
            <v-col cols="12" md="2">
                <h3>ПРИЛОЖЕНИЕ 2 -ДЕЙНОСТИ СВЪРЗАНИ С МАЙЧИНОТО ЗДРАВЕОПАЗВАНЕ</h3>
            </v-col>
            <v-col cols="12" md="2">
                <h3>2. Бременни под наблюдение на консултацията</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <DataTableComponent v-model="value.pregnancyRegisters" :headers="headers" />
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Annex2PregnancyRegisterDto } from '@/model/Report/Rhi/Annex2/Annex2PregnancyRegisterDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class PregnancyRegister extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Annex2PregnancyRegisterDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: 'Шифър', value: 'code' },
            { text: 'В началото на годината', value: 'countStartYear' },
            { text: 'Постъпили през годината - всичко', value: 'countDuringYear' },
            { text: 'Постъпили през годината - до ІІІ лунарен месец', value: 'countDuringYearThirdMonth' },
            { text: 'Постъпили през годината - след VII лунарен месец', value: 'countDuringYearAfterFourMonth' },
            { text: 'Завършили бременност - раждане на живо дете', value: 'countBirth' },
            { text: 'Завършили бременност - мъртво раждане', value: 'countBirthDead' },
            { text: 'Завършили бременност - аборт', value: 'countAbortion' },
            { text: 'Отпаднали преди завършване на бременност', value: 'countDeregistered' },
            { text: 'В края на годината', value: 'countEndYear' }
        ];
    }
</script>
