<template>
    <v-text-field
        :value="value"
        dense
        :label="label"
        :disabled="disabled"
        :readonly="readonly"
        :required="required"
        :class="required ? 'required' : ''"
        :rules="getRules"
        :hint="hint"
        :persistent-hint="!!hint"
        :suffix="suffix"
        :type="type"
        :min="min"
        :max="max"
        :clearable="clearable"
        :autofocus="autofocus"
        @input="onValueChange"
        @keyup="$emit('keyup', ...arguments)"
        @blur="$emit('blur', ...arguments)"
    >
    </v-text-field>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class TextField extends Vue {
        @Prop()
        private hint!: string;

        @Prop()
        private label!: string;

        @Prop()
        private disabled!: boolean;

        @Prop()
        private readonly!: boolean;

        @Prop()
        private required!: boolean;

        @Prop()
        private rules!: [];

        @Prop()
        private suffix!: string;

        @Prop()
        private type!: string;

        @Prop()
        private value!: unknown;

        @Prop()
        private min!: number;

        @Prop()
        private max!: number;

        @Prop()
        private clearable!: boolean;

        @Prop({ default: false })
        private autofocus!: boolean;

        private get getRules() {
            const requiredValidator = this.required ? [this.$validator.required] : [];
            const maxValidator =
                Boolean(this.max) && Number(this.value) > this.max ? [this.$validator.maxValue(this.max)] : [];
            const minValidator =
                Boolean(this.min) && Number(this.value) < this.min ? [this.$validator.minValue(this.min)] : [];

            return [...(this.rules ?? []), ...minValidator, ...maxValidator, ...requiredValidator];
        }

        private onValueChange(newValue: unknown) {
            if (this.type === 'number' && !newValue) {
                this.$emit('input', null);
            } else if (this.type === 'number' && newValue) {
                this.$emit('input', parseFloat(newValue.toString()));
            } else {
                this.$emit('input', newValue);
            }
        }
    }
</script>
