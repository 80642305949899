<template>
    <div>
        <v-row>
            <v-col cols="12">
                <text-field
                    v-model="value.procedureName"
                    required
                    label="Процедура:"
                    :rules="[$validator.maxLength(1000)]"
            /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { InformedConsentDiagnosticsProcedureDto } from '@/model/Exam/InformedConsentDiagnostics/InformedConsentDiagnosticsProcedureDto';

    @Component
    export default class InformedConsentDiagnosticsProcedure extends Vue {
        @Prop({ type: Object })
        private value!: InformedConsentDiagnosticsProcedureDto;
    }
</script>
