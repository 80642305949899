import { AxiosResponse } from 'axios';

import { PatientRegistrationTypeDto } from '@/model/Nomenclature/PatientRegistrationTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientRegistrationTypeService = {
    getPatientRegistrationTypes(): Promise<AxiosResponse<PatientRegistrationTypeDto[]>> {
        return httpService.get('/PatientRegistrationType/GetPatientRegistrationTypes');
    }
};
