<template>
    <v-row>
        <v-col cols="12">
            <dropdown
                v-model="value.examQuestionTypeCode"
                :items="questionTypes"
                item-value="code"
                label="Избор на въпросник"
                required
                readonly
                @input="onQuestionTypeChange"
            />
        </v-col>
        <v-col v-if="value.note && value.note.length > 0" cols="12">
            <v-textarea v-model="value.note" rows="=2" readonly />
        </v-col>
        <v-col cols="12">
            <v-row v-for="(question, index) in value.questions" :key="index" no-gutters>
                <v-col cols="12">
                    <nhis-question v-model="value.questions[index]" :position="index + 1"></nhis-question>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhisQuestionDto } from '@/model/Nhis/Exam/PreventiveActivities/Questionnaire/NhisQuestionDto';
    import { NhisQuestionnaireDto } from '@/model/Nhis/Exam/PreventiveActivities/Questionnaire/NhisQuestionnaireDto';
    import { ExamQuestionTypeDto } from '@/model/Nomenclature/ExamQuestionTypeDto';
    import { examQuestionService } from '@/service/Nomenclature/ExamQuestionService';
    import { examQuestionTypeService } from '@/service/Nomenclature/ExamQuestionTypeService';
    import NhisQuestion from '@/view/Nhis/Exam/NhisQuestion.vue';

    @Component({
        components: { NhisQuestion }
    })
    export default class NhisQuestionnaire extends Vue {
        @Prop()
        value!: NhisQuestionnaireDto;

        private questionTypes: ExamQuestionTypeDto[] = [];

        private async onQuestionTypeChange() {
            this.value.questions = [];
            const response = await examQuestionService.getExamQuestionByType(this.value.examQuestionTypeCode ?? '');
            response.data.forEach((question) => {
                const item = new NhisQuestionDto();
                item.examQuestionCode = question.nhisCode;
                this.value.questions.push(item);
            });
        }

        private async loadQuestionTypes() {
            const response = await examQuestionTypeService.getExamQuestionTypes();
            this.questionTypes = response.data;
        }

        private async mounted() {
            await this.loadQuestionTypes();
        }
    }
</script>

<style scoped></style>
