import { plainToInstance } from 'class-transformer';

import { Referral4AmountsReportDto } from '@/model/Query/Exam/Referral4AmountsReportDto';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referral4AmountsReportService = {
    async getReferral4AmountsReport(filter: ReportIntervalFilterDto): Promise<Referral4AmountsReportDto[]> {
        const response = await httpService.get<Referral4AmountsReportDto[]>(
            `/Referral4AmountsReport/GetReferral4AmountsReport`,
            { params: filter }
        );
        return plainToInstance(Referral4AmountsReportDto, response.data);
    },

    async exportExcelReferral4AmountsReport(filter: ReportIntervalFilterDto): Promise<void> {
        await httpService.download(`/Referral4AmountsReport/ExportExcelReferral4AmountsReport`, {
            params: filter
        });
    }
};
