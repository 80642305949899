<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Декларация за информирано съгласие с изискванията за диагностични и лечебни процедури"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-row>
            <v-col cols="12">
                <v-card>
                    <main-title>
                        Декларация за информирано съгласие с изискванията за диагностични и лечебни процедури
                    </main-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <PatientInfo />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <h4>
                                    С подписа си декларирам, че бях надлежно информиран/а от лекуващия си лекар за
                                    всички диагностични, лечебни и рехабилитационни процедури, които ще ми бъдат
                                    приложени за уточняване на диагнозата и провеждане на лечението ми и
                                </h4>
                                <h4>
                                    <strong>че съм съгласен/на с осъществяването им!</strong>
                                </h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <h4>
                                    Заедно с това бях информиран/а за възможните негативни странични ефекти от
                                    манипулациите и лечението. За всички възникващи въпроси получих обяснение и отговор
                                    от лекуващия лекар.
                                </h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <h4>
                                    В случай на безсъзнателно състояние или при помрачено съзнание, както и при деца
                                    информираното съгласие се подписва от най - близки роднини.
                                </h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <h4>
                                    Ако има несъгласие за някои диагностични и лечебни процедури, те се вписват и
                                    подписват допълнително:
                                </h4>
                            </v-col>
                        </v-row>
                        <v-card-text v-for="(procedure, index) in document.procedures" :key="index">
                            <v-row>
                                <v-col cols="11">
                                    <h4>Процедури: {{ index + 1 }}</h4>
                                    <InformedConsentDiagnosticsProcedure :value="procedure" name="procedure" />
                                </v-col>
                                <v-col cols="1">
                                    <btn icon action="Delete" @click="removeProcedure(index)" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-row>
                            <v-col cols="2">
                                <btn icon action="New" @click="addProcedure" />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <h4>
                                    Не давам съгласието си за извършване на горепосочените диагностични и лечебни
                                    манипулации.
                                </h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <date-picker v-model="document.issueDate" label="Дата:" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { InformedConsentDiagnosticsDto } from '@/model/Exam/InformedConsentDiagnostics/InformedConsentDiagnosticsDto';
    import { InformedConsentDiagnosticsProcedureDto } from '@/model/Exam/InformedConsentDiagnostics/InformedConsentDiagnosticsProcedureDto';
    import { informedConsentDiagnosticsService } from '@/service/Exam/InformedConsentDiagnosticsService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    import InformedConsentDiagnosticsProcedure from './InformedConsentDiagnosticsProcedure.vue';

    @Component({
        components: {
            UnsavedChanges,
            PatientInfo,
            ExamLayout,
            InformedConsentDiagnosticsProcedure
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class InformedConsentDiagnostics extends Vue {
        @Ref()
        private readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private document: InformedConsentDiagnosticsDto = new InformedConsentDiagnosticsDto();
        private initialValue: InformedConsentDiagnosticsDto = new InformedConsentDiagnosticsDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private async mounted() {
            this.leaveDialogRef = this.leaveDialog;
            await this.load();
            this.copyObject();
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private addProcedure() {
            this.document.procedures.push(new InformedConsentDiagnosticsProcedureDto());
        }

        private removeProcedure(index: number) {
            arrayUtil.removeAt(this.document.procedures, index);
        }

        private async load() {
            this.$loading.show();
            try {
                if (!this.documentId) {
                    const responseExam =
                        await informedConsentDiagnosticsService.getInformedConsentDiagnosticsInfoByExamId(this.examId);
                    this.document = responseExam.data;
                    this.document.procedures = [];
                } else {
                    const responseReferral = await informedConsentDiagnosticsService.getInformedConsentDiagnostics(
                        this.documentId
                    );
                    this.document = responseReferral.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                const title = `Дата ${this.document.issueDate}; `;
                if (documentId === 0) {
                    const response = await informedConsentDiagnosticsService.createInformedConsentDiagnostics(
                        this.document
                    );
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, 'InformedConsentDiagnostics', title);

                    this.$router.push(`/Exam/InformedConsentDiagnostics/Edit/${documentId}`);
                } else {
                    await informedConsentDiagnosticsService.updateInformedConsentDiagnostics(this.document);
                    eventBus.$emit('update-referral-event', title);
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            const documentId = this.document.id;
            await informedConsentDiagnosticsService.deleteInformedConsentDiagnostics(documentId);
            this.copyObject();
            this.$router.push(`/Exam/InformedConsentDiagnostics/Create/${currentVisitCache.value.id}`);
            eventBus.$emit(
                'delete-referral-event',
                documentId,
                'InformedConsentDiagnostics',
                'Информирано съгласие за диагностика'
            );
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.InformedConsent.InformedConsentDiagnostics/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.InformedConsent.InformedConsentDiagnostics';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
