import { Route, RouteConfig } from 'vue-router';

import { examPrescriptionV2Routes } from '@/router/Exam/ExamPrescriptionV2Routes';

import { examCertificateRoutes } from './ExamCertificateRoutes';
import { examImmunizationRoutes } from './ExamImmunizationRoutes';
import { examInformedConsentRoutes } from './ExamInformedConsentRoutes';
import { examOtherSpecialistRoutes } from './ExamOtherSpecialistRoutes';
import { examPrescriptionRoutes } from './ExamPrescriptionRoutes';
import { examProtocolRoutes } from './ExamProtocolRoutes';
import { examReferralRoutes } from './ExamReferralRoutes';
import { examRequestForRoutes } from './ExamRequestForRoutes';
import { examResultRoutes } from './ExamResultRoutes';
import { optionalExamRoutes } from './OptionalExamRoutes';

const examUncategorizedRoutes: RouteConfig[] = [
    {
        path: 'AmbulatorySheet/Create',
        components: {
            exam: () => import('@/view/Exam/AmbulatorySheet/AmbulatorySheet.vue')
        }
    },
    {
        path: 'AmbulatorySheet/Edit/:visitId',
        props: {
            exam: (route: Route) => ({ visitId: Number(route.params.visitId) })
        },
        components: {
            exam: () => import('@/view/Exam/AmbulatorySheet/AmbulatorySheet.vue')
        }
    },
    {
        path: 'AmbulatorySheet/Copy/:copyExamId',
        props: {
            exam: (route: Route) => ({ copyExamId: Number(route.params.copyExamId) })
        },
        components: {
            exam: () => import('@/view/Exam/AmbulatorySheet/AmbulatorySheet.vue')
        }
    },
    {
        path: 'RiskAssessmentCard/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Gp/RiskAssessmentCard/RiskAssessmentCard.vue')
        }
    },
    {
        path: 'RiskAssessmentCard/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Gp/RiskAssessmentCard/RiskAssessmentCard.vue')
        }
    },
    {
        path: 'RiskAssessmentCard/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Gp/RiskAssessmentCard/RiskAssessmentCardView.vue')
        }
    },
    {
        path: 'QuickNotice/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/QuickNotice/QuickNotice.vue')
        }
    },
    {
        path: 'QuickNotice/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/QuickNotice/QuickNotice.vue')
        }
    },
    {
        path: 'QuickNotice/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/QuickNotice/QuickNoticeView.vue')
        }
    },
    {
        path: 'ChildHealthCard/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/ChildHealthCard/ChildHealthCard.vue')
        }
    },
    {
        path: 'ChildHealthCard/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/ChildHealthCard/ChildHealthCard.vue')
        }
    },
    {
        path: 'ChildHealthCard/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/ChildHealthCard/ChildHealthCardView.vue')
        }
    },
    {
        path: 'Manipulation/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Manipulation/ManipulationView.vue')
        }
    },
    {
        path: 'Manipulation/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Manipulation/Manipulation.vue')
        }
    },
    {
        path: 'Manipulation/Edit/:id',
        props: {
            exam: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            exam: () => import('@/view/Exam/Manipulation/Manipulation.vue')
        }
    }
];

export const examRoutes: RouteConfig = {
    path: '/Exam',
    // ExamMenu зарежда текущото посещението възможно най-рано и, доколкото е възможно, на централно място.
    // Така при добавяне на документ не е нужно всеки компонент да се грижи за зареждането на текущото посещение.
    // При редактиране на документ обаче visitId/examId става известно едва след зареждането на документа,
    // така че всички компоненти за документ трябва да зареждат текущото посещение при редактиране.
    props: (route: Route) => ({
        // Параметърът, които указва посещението, може да се казва visitId или examId.
        visitId: route.params.visitId ? Number(route.params.visitId) : null,
        examId: route.params.examId ? Number(route.params.examId) : null
    }),
    component: () => import('@/view/Exam/VisitMenu.vue'),
    children: [
        ...examUncategorizedRoutes,
        ...examCertificateRoutes,
        ...examImmunizationRoutes,
        ...examInformedConsentRoutes,
        ...examOtherSpecialistRoutes,
        ...examPrescriptionRoutes,
        ...examProtocolRoutes,
        ...examReferralRoutes,
        ...examRequestForRoutes,
        ...examResultRoutes,
        ...optionalExamRoutes,
        ...examPrescriptionV2Routes
    ]
};
