import { AxiosResponse } from 'axios';

import { NhisVaccineDto } from '@/model/Nomenclature/NhisVaccineDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhisVaccineService = {
    getNhisVaccines(): Promise<AxiosResponse<NhisVaccineDto[]>> {
        return httpService.get(`NhisVaccine/GetNhisVaccines/`);
    },

    getNhisVaccinesByImmunization(
        immunizationId: number,
        occurrenceDate: Date
    ): Promise<AxiosResponse<NhisVaccineDto[]>> {
        return httpService.get(`NhisVaccine/GetNhisVaccinesByImmunization/${immunizationId}`, {
            params: {
                occurrenceDate
            }
        });
    }
};
