import { Type } from 'class-transformer';

import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';

export class CertificateMariageDto {
    public isHealthy: boolean = true;
    public isHealthyText: string = '';
    public isNew: boolean = true;
    public issueDate: Date = new Date();
    public examDiagnosisId: number = 0;

    @Type(() => ExamDiagnosisDto)
    public examDiagnosis: ExamDiagnosisDto | null = null;

    public ExamId: number = 0;
}
