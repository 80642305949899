<template>
    <div>
        <h3>{{ title }}</h3>
        <video v-if="!isYouTube" width="100%" height="600" :src="url" :title="title" controls></video>
        <iframe
            v-else
            width="100%"
            height="600"
            :src="url"
            :title="title"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class VideoPlayer extends Vue {
        @Prop()
        title!: string;

        @Prop()
        url!: string;

        @Prop({ default: false })
        isYouTube!: boolean;
    }
</script>
