<template>
    <div>
        <v-row class="flex-row align-end">
            <v-col cols="12" md="6">
                <dropdown
                    v-model="value.riskGroupRule"
                    :items="riskGroupItems"
                    label="Избор на рискова група:"
                    no-data-text="Няма данни"
                    required
                    return-object
                />
            </v-col>
            <v-col cols="12" md="3">
                <date-picker v-model="value.startDate" label="Дата на записване" required />
            </v-col>
            <v-col cols="12" md="3"><text-field v-model="value.ambulatorySheetNumber" label="Към амб. лист" /></v-col>
        </v-row>
        <v-row class="flex-row align-end">
            <v-col cols="12" md="6">
                <icd-picker-multi-select v-model="value.icdCodes" />
            </v-col>
            <v-col cols="12" md="3"><date-picker v-model="value.endDate" label="Дата на отписване" /></v-col>
            <v-col cols="12" md="3">
                <dropdown
                    v-model="value.riskGroupDeregisterReason"
                    :items="riskGroupCancellationItems"
                    label="Избор на причина за отписване:"
                    return-object
                    no-data-text="Няма данни"
                    clearable
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-textarea v-model="value.description" label="Описание"></v-textarea>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import IcdPickerMultiSelect from '@/component/Picker/IcdPickerMultiSelect.vue';
    import { RiskGroupDeregisterReasonDto } from '@/model/Nomenclature/RiskGroupDeregisterReasonDto';
    import { RiskGroupRegisterDto } from '@/model/Register/RiskGroup/RiskGroupRegisterDto';
    import { RiskGroupRuleDto } from '@/model/Register/RiskGroup/RiskGroupRuleDto';
    import { riskGroupDeregisterReasonService } from '@/service/Nomenclature/RiskGroupDeregisterReasonService';
    import { riskGroupRuleService } from '@/service/Nomenclature/RiskGroupRuleService';

    @Component({
        components: { IcdPickerMultiSelect }
    })
    export default class RiskGroupEditor extends Vue {
        @Prop({ type: Object })
        private value!: RiskGroupRegisterDto;

        private riskGroupItems: RiskGroupRuleDto[] = [];
        private riskGroupCancellationItems: RiskGroupDeregisterReasonDto[] = [];

        private async getRiskTypes() {
            const response = await riskGroupRuleService.getRiskGroupRules();
            this.riskGroupItems = response.data;
        }

        private async getCancellationReasons() {
            const response = await riskGroupDeregisterReasonService.getRiskGroupDeregisterReasons();
            this.riskGroupCancellationItems = response.data;
        }

        private async mounted() {
            await this.getRiskTypes();
            await this.getCancellationReasons();
        }
    }
</script>
