import { AxiosResponse } from 'axios';

import { ReferralSubtypeDto } from '@/model/Nomenclature/ReferralSubtypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referralSubtypeService = {
    getReferral3Subtypes(): Promise<AxiosResponse<ReferralSubtypeDto[]>> {
        return httpService.get('/ReferralSubtype/GetReferralSubtypes?typeCode=Referral3');
    },

    getReferral3aSubtypes(): Promise<AxiosResponse<ReferralSubtypeDto[]>> {
        return httpService.get('/ReferralSubtype/GetReferralSubtypes?typeCode=Referral3a');
    },

    getReferral4Subtypes(): Promise<AxiosResponse<ReferralSubtypeDto[]>> {
        return httpService.get('/ReferralSubtype/GetReferralSubtypes?typeCode=Referral4');
    },

    getReferral6Subtypes(): Promise<AxiosResponse<ReferralSubtypeDto[]>> {
        return httpService.get('/ReferralSubtype/GetReferralSubtypes?typeCode=Referral6');
    },

    getReferralDispObservSubtypes(): Promise<AxiosResponse<ReferralSubtypeDto[]>> {
        return httpService.get('/ReferralSubtype/GetReferralSubtypes?typeCode=ReferralDispObserv');
    },

    getReferralTelkSubtypes(): Promise<AxiosResponse<ReferralSubtypeDto[]>> {
        return httpService.get('/ReferralSubtype/GetReferralSubtypes?typeCode=ReferralTelk');
    },

    getIncomingReferralSubTypes(): Promise<AxiosResponse<ReferralSubtypeDto[]>> {
        return httpService.get('/ReferralSubtype/GetIncomingReferralSubtypes');
    }
};
