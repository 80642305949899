<template>
    <div>
        <data-table
            title="Алергии на пациента"
            :headers="tableHeaders"
            :loading="$loading.isVisible"
            :items="items"
            class="elevation-1 mb-5"
            disable-pagination
        >
            <template v-if="$allowWrite" #actions>
                <btn action="New" :to="`/Patient/Allergy/Create/${id}`" />
            </template>
            <template v-if="$allowWrite" #rowActions="{ item }">
                <btn icon action="Edit" :to="`/Patient/Allergy/Edit/${item.id}/${id}`" />
            </template>
        </data-table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PatientAllergyDto } from '@/model/Patient/Allergy/PatientAllergyDto';
    import { patientAllergyService } from '@/service/Patient/PatientAllergyService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class PatientAllergy extends Vue {
        @Prop()
        public id!: number;

        private items: PatientAllergyDto[] = [];

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Дата на откриване', value: 'startDate', formatter: formatters.date },
                { text: 'Тип на алергията', value: 'allergyTypeName' },
                { text: 'Наименование', value: 'name' },
                { text: 'Код на лекарство', value: 'medicationCode' },
                { text: 'НРН', value: 'nhisAllergy.nrn' },
                { text: '', value: 'actionButtons' }
            ];
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                this.items = [];
                const response = await patientAllergyService.getPatientAllergies(this.id);
                if (response) {
                    this.items = response;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            if (this.id) {
                await this.getTableItems();
            }
        }
    }
</script>
