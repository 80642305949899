import { Type } from 'class-transformer';

import { Report365PsychiatricExpertiseRowDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricExpertiseRowDto';

export class Report365PsychiatricExpertiseDto {
    public name: string = '';

    @Type(() => Report365PsychiatricExpertiseRowDto)
    public psychiatricExpertises: Report365PsychiatricExpertiseRowDto[] = [];
}
