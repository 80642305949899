class IsPaidType {
    public name: string = '';
    public value: boolean | null = null;
}

const isPaidTypes: IsPaidType[] = [
    { name: 'Всички', value: null },
    { name: 'Платени', value: true },
    { name: 'Неплатени', value: false }
];

export { IsPaidType, isPaidTypes };
