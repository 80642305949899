<template>
    <v-row>
        <v-dialog v-model="dialogIsVisible" persistent max-width="50%">
            <v-card>
                <v-card-title>Пациентът подлежи на включване в следните рискови групи:</v-card-title>
                <v-card-text class="font-weight-bold body-1 mt-2">
                    <v-row v-for="(group, index) in riskGroups" :key="index">
                        <p>{{ group.icdCode }}</p>
                        -
                        <p>{{ group.name }}</p>
                    </v-row>
                    <v-row>
                        <v-checkbox v-model="includeRiskGroups" class="mt-3" label="Включи в регистър"></v-checkbox>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <btn action="Next" @click="close" />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { RiskGroupRuleCardDto } from '@/model/Exam/Gp/RiskAssessmentCard/RiskGroupRuleCardDto';

    @Component
    export default class RiskAssessmentIncludeRegisterDialog extends Vue {
        @Prop()
        dialogIsVisible!: boolean;

        @Prop()
        riskGroups!: RiskGroupRuleCardDto[];

        private includeRiskGroups: boolean = false;

        private close() {
            this.$emit('closed', this.includeRiskGroups);
        }
    }
</script>
