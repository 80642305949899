<template>
    <div>
        <v-form ref="form" v-model="isFormValid">
            <v-card>
                <v-card-title>Отчет - пациентска листа</v-card-title>
                <v-card-text>
                    <report-date-picker v-model="filter" @input="getTableItems" />
                    <v-row>
                        <v-col cols="12" md="6">
                            <practice-nhif-contract-picker v-model="filter.contract" :practice-id="practiceId" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="canGenerate" cols="12" md="4">
                            <btn action="XmlReport" block :disabled="isGenerating" @click="generatePatientListReport" />
                        </v-col>
                        <v-col v-if="canGenerate" cols="12" md="4">
                            <btn action="List" block :disabled="isGenerating" @click="generatePatientListReportLines">
                                Опис
                            </btn>
                        </v-col>
                        <v-col v-if="hasResult" cols="12" md="4">
                            <btn action="Send" block :disabled="isGenerating" @click="sendPatientListReport">
                                Изпращане на отчет
                            </btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="hasResult">
                        <p class="font-weight-bold">Брой новозаписани пациенти в отчета: {{ response.itemsCount }}</p>
                    </v-row>
                    <div v-if="items.length > 0" class="mt-5">
                        <pis-file v-model="items"></pis-file>
                    </div>
                    <div v-if="hasResult" class="mt-5">
                        <h3>XML Отчет</h3>
                        <v-row class="mt-3">
                            <v-col md="12"><v-textarea v-model="response.report" dense></v-textarea></v-col>
                        </v-row>
                    </div>
                    <v-dialog v-model="reportDialogIsVisible">
                        <PatientListReportLines
                            :items="patientListReport"
                            :filter="filter"
                            @close-dialog="reportDialogIsVisible = false"
                        />
                    </v-dialog>
                </v-card-text>
            </v-card>
            <SuccessDialog v-model="resultDialogIsVisible" :text="dialogText" title="Резултат от изпращане" />
        </v-form>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import format from 'xml-formatter';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { PisUploadType } from '@/enum/LocalServer/PisUploadType';
    import { PisFileTypeCode } from '@/enum/Report/Pis/PisFileTypeCode';
    import { ExamClaimCommand } from '@/model/Report/Pis/GpExamClaim/ExamClaimCommand';
    import { PatientListReportDto } from '@/model/Report/Pis/PatientList/PatientListReportDto';
    import { PisFileCreateDto } from '@/model/Report/Pis/PisFile/PisFileCreateDto';
    import { PisFileFilterDto } from '@/model/Report/Pis/PisFile/PisFileFilterDto';
    import { PisFileRowDto } from '@/model/Report/Pis/PisFile/PisFileRowDto';
    import { PisFileUploadRequestDto } from '@/model/Report/Pis/PisFileUploadRequestDto';
    import { ReportResponseDto } from '@/model/Report/Pis/ReportResponseDto';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { localServerPisService } from '@/service/Report/LocalServerPisService';
    import { pisFileService } from '@/service/Report/PisFileService';
    import { pisService } from '@/service/Report/PisService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import PatientListReportLines from '@/view/Report/Pis/PatientListReportLines.vue';
    import PracticeNhifContractPicker from '@/view/Report/Pis/PracticeNhifContractPicker.vue';

    import PisFile from './PisFile.vue';
    import SuccessDialog from './SuccessDialog.vue';

    @Component({
        components: { PracticeNhifContractPicker, ReportDatePicker, PisFile, SuccessDialog, PatientListReportLines }
    })
    export default class PatientListReport extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private isFormValid: boolean = false;
        private filter: ExamClaimCommand = new ExamClaimCommand();
        private resultDialogIsVisible: boolean = false;
        private dialogText: string = '';
        private isLocalServerActive: boolean = false;
        private items: PisFileRowDto[] = [];
        private canGenerate: boolean = false;
        private response: ReportResponseDto = new ReportResponseDto();
        private isGenerating: boolean = false;
        private reportDialogIsVisible: boolean = false;
        private patientListReport: PatientListReportDto = new PatientListReportDto();

        private async mounted() {
            this.filter.fillPreviousMonth();
            await this.getTableItems();
            await this.isFilledPracticeAndDoctor();
        }

        private get hasResult() {
            return this.response?.report?.length > 0;
        }

        private async generatePatientListReportLines() {
            this.$loading.show();
            try {
                (this.$refs.form as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    this.fillDoctorEmployee();
                    const response = await pisService.generatePatientListReportLines(this.filter);
                    if (response) {
                        this.reportDialogIsVisible = true;
                        this.patientListReport = response;
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async generatePatientListReport() {
            this.$loading.show();
            try {
                (this.$refs.form as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    this.isGenerating = true;
                    this.fillDoctorEmployee();
                    const response = await pisService.generatePatientListReport(this.filter);
                    if (response?.data?.report) {
                        this.response.itemsCount = response.data.itemsCount;
                        this.response.report = format(response.data.report, {
                            indentation: '  ',
                            filter: (node) => node.type !== 'Comment',
                            collapseContent: true,
                            lineSeparator: '\n'
                        });
                    }
                }
            } finally {
                this.isGenerating = false;
                this.$loading.hide();
            }
        }

        private fillDoctorEmployee() {
            this.filter.practiceId = this.practiceId;
            this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async sendPatientListReport() {
            await this.checkServerStatus();
            if (this.isLocalServerActive) {
                this.$loading.show();
                const uploadRequest = new PisFileUploadRequestDto(
                    this.response.report,
                    userContextCache.current?.practiceNumber ?? '',
                    userContextCache.current?.doctorIdentifier ?? ''
                );
                const response = await localServerPisService.uploadReport(
                    PisFileTypeCode.PatientList,
                    uploadRequest,
                    PisUploadType.XmlReport
                );
                if (!response.isError) {
                    this.dialogText = `${response.message} Идентификаторът на документа е: ${response.additionalInformation}`;
                    this.resultDialogIsVisible = true;
                    await this.savePisFile(response.additionalInformation);
                }
                this.$loading.hide();
            }
        }

        private async savePisFile(fileId: string) {
            const file = new PisFileCreateDto();
            file.practiceId = this.practiceId;
            file.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
            file.pisFileTypeCode = PisFileTypeCode.PatientList;
            file.pisFileId = fileId;
            file.startDate = this.filter.startDate;
            file.endDate = this.filter.endDate;
            file.practiceId = this.practiceId;

            const item = await pisFileService.createPisFile(file);
            this.items.push(item);
            this.$loading.hide();
        }

        private async checkServerStatus(): Promise<void> {
            const response = await localServerDiagnosticService.checkIsLocalServerActive();
            this.isLocalServerActive = response;
        }

        private async isFilledPracticeAndDoctor() {
            const response = await pisService.isFilledPracticeAndDoctor(this.practiceId, this.doctorEmployeeSeqNumber);

            if (response?.data.length > 0) {
                this.canGenerate = false;
                this.$notifier.showWarning('Внимание', response.data);
            } else {
                this.canGenerate = true;
            }
        }

        private async getTableItems() {
            const filter = new PisFileFilterDto();
            filter.practiceId = this.practiceId;
            filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
            filter.pisFileTypeCode = PisFileTypeCode.PatientList;
            filter.startDate = this.filter.startDate;
            filter.endDate = this.filter.endDate;
            if (filter.startDate && filter.endDate) {
                this.items = await pisFileService.getPisFiles(filter);
            }
        }

        @Watch('doctorEmployeeId')
        private onDoctorEmployeeChange(): void {
            this.response = new ReportResponseDto();
        }
    }
</script>
