import { Type } from 'class-transformer';

import { Annex56ChildrenDiseaseRowDto } from '@/model/Report/Rhi/Annex56/Annex56ChildrenDiseaseRowDto';

export class Annex56ChildrenDiseaseDto {
    public name: string = '';

    @Type(() => Annex56ChildrenDiseaseRowDto)
    public childrenDiseases: Annex56ChildrenDiseaseRowDto[] = [];
}
