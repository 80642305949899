import { AxiosResponse } from 'axios';

import { NhisEdocumentTypeDto } from '@/model/Nomenclature/NhisEdocumentTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhisEdocumentTypeService = {
    getEdocumentTypes(): Promise<AxiosResponse<NhisEdocumentTypeDto[]>> {
        return httpService.get('nhisEdocumentType/GetNhisEdocumentTypes');
    }
};
