import { Route, RouteConfig } from 'vue-router';

export const fiscalChildRoutes: RouteConfig[] = [
    {
        path: 'Fiscal/Device/List/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: { practice: () => import('@/view/Practice/Fiscal/DeviceList.vue') }
    },
    {
        path: 'Fiscal/Device/Create/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Practice/Fiscal/DeviceEdit.vue')
        }
    },
    {
        path: 'Fiscal/Device/Edit/:practiceId/:seqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                seqNumber: Number(route.params.seqNumber)
            })
        },
        components: { practice: () => import('@/view/Practice/Fiscal/DeviceEdit.vue') }
    },
    {
        path: 'Fiscal/Memory/List/:practiceId/:fiscalDeviceSeqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                fiscalDeviceSeqNumber: Number(route.params.fiscalDeviceSeqNumber)
            })
        },
        components: {
            practice: () => import('@/view/Practice/Fiscal/MemoryList.vue')
        }
    },
    {
        path: 'Fiscal/Memory/Create/:practiceId/:fiscalDeviceSeqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                fiscalDeviceSeqNumber: Number(route.params.fiscalDeviceSeqNumber)
            })
        },
        components: {
            practice: () => import('@/view/Practice/Fiscal/MemoryEdit.vue')
        }
    },
    {
        path: 'Fiscal/Memory/Edit/:practiceId/:fiscalDeviceSeqNumber/:fiscalMemoryCode',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                fiscalDeviceSeqNumber: Number(route.params.fiscalDeviceSeqNumber),
                fiscalMemoryCode: route.params.fiscalMemoryCode
            })
        },
        components: {
            practice: () => import('@/view/Practice/Fiscal/MemoryEdit.vue')
        }
    },
    {
        path: 'Fiscal/Operator/List/:practiceId/:fiscalDeviceSeqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                fiscalDeviceSeqNumber: Number(route.params.fiscalDeviceSeqNumber)
            })
        },
        components: {
            practice: () => import('@/view/Practice/Fiscal/DeviceOperatorList.vue')
        }
    },
    {
        path: 'Fiscal/Operator/Create/:practiceId/:fiscalDeviceSeqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                fiscalDeviceSeqNumber: Number(route.params.fiscalDeviceSeqNumber)
            })
        },
        components: {
            practice: () => import('@/view/Practice/Fiscal/DeviceOperatorEdit.vue')
        }
    },
    {
        path: 'Fiscal/Operator/Edit/:practiceId/:fiscalDeviceSeqNumber/:deviceOperatorSeqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                fiscalDeviceSeqNumber: Number(route.params.fiscalDeviceSeqNumber),
                deviceOperatorSeqNumber: Number(route.params.deviceOperatorSeqNumber)
            })
        },
        components: {
            practice: () => import('@/view/Practice/Fiscal/DeviceOperatorEdit.vue')
        }
    }
];
