<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">Раздел V. Акушеро-гинекологична дейност</h3></v-col>
            <v-col cols="12"><h3 class="mb-2">1а. Аборти по възраст на жената</h3></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><DataTableComponent v-model="value.abortionsByAge" :headers="headers" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Report365AbortionByAgeDto } from '@/model/Report/Rhi/Report365/Report365AbortionByAgeDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class AbortionByAge extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Report365AbortionByAgeDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: 'Възраст (в навършени години)', value: 'name' },
            { text: 'Шифър', value: 'code' },
            { text: 'Аборти', value: 'abortions' },
            { text: 'Спонтанни', value: 'spontaneous' },
            { text: 'Терапевтични', value: 'therapeutic' },
            { text: 'По желание', value: 'byRequest' },
            { text: 'По медицински показания', value: 'byMedicalReason' },
            { text: 'Други видове', value: 'other' }
        ];

        @Watch('value.abortionsByAge', { deep: true })
        private onValueChanged() {
            const timeout = 3000;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => this.calculateSectionRow(), timeout);
        }

        private calculateSectionRow() {
            for (let idx = 0; idx < this.value.abortionsByAge.length; idx++) {
                if (this.value.abortionsByAge[idx].isSection) {
                    this.value.abortionsByAge[idx].byMedicalReason = 0;
                    this.value.abortionsByAge[idx].byRequest = 0;
                    this.value.abortionsByAge[idx].other = 0;
                    this.value.abortionsByAge[idx].spontaneous = 0;
                    for (let kidx = 0; kidx < this.value.abortionsByAge.length; kidx++) {
                        if (this.value.abortionsByAge[kidx].parentCode === this.value.abortionsByAge[idx].code) {
                            this.value.abortionsByAge[idx].byMedicalReason =
                                (this.value.abortionsByAge[idx].byMedicalReason ?? 0) +
                                (this.value.abortionsByAge[kidx].byMedicalReason ?? 0);
                            this.value.abortionsByAge[idx].byRequest =
                                (this.value.abortionsByAge[idx].byRequest ?? 0) +
                                (this.value.abortionsByAge[kidx].byRequest ?? 0);
                            this.value.abortionsByAge[idx].other =
                                (this.value.abortionsByAge[idx].other ?? 0) +
                                (this.value.abortionsByAge[kidx].other ?? 0);
                            this.value.abortionsByAge[idx].spontaneous =
                                (this.value.abortionsByAge[idx].spontaneous ?? 0) +
                                (this.value.abortionsByAge[kidx].spontaneous ?? 0);
                        }
                    }
                }
            }
        }
    }
</script>
