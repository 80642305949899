import axios, { AxiosRequestConfig } from 'axios';
import { stringify } from 'qs';

import { config } from '@/Config';
import {
    replaceIsoDateStringsWithDates,
    serializeDatesWithoutZone,
    serializeDateWithoutZone
} from '@/util/AxiosDateUtil';

const axiosConfig: AxiosRequestConfig = {
    baseURL: config.localServerApiBaseUrl,
    // Настройки за работа с дати. Виж коментара на serializeDateWithoutZone.
    // Форматиране на датите в query параметерите.
    paramsSerializer: {
        serialize: (params) => stringify(params, { serializeDate: serializeDateWithoutZone })
    },
    // Форматиране на датите в тялото на заявките.
    transformRequest: [serializeDatesWithoutZone, ...(axios.defaults.transformRequest as [])],
    // Parse-ване на датите от response-ите.
    transformResponse: [...(axios.defaults.transformResponse as []), replaceIsoDateStringsWithDates]

    //timeout: 60 * 1000,
    // Check cross-site Access-Control
    //withCredentials: true,
};

export const localServerHttpService = axios.create(axiosConfig);
