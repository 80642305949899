import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { PregnancyCardDto } from '@/model/Register/Pregnancy/PregnancyCard/PregnancyCardDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const pregnancyCardService = {
    async getPregnancyCard(id: number): Promise<PregnancyCardDto> {
        const response = await httpService.get(`/PregnancyCard/GetPregnancyCard/${id}`);
        return plainToInstance(PregnancyCardDto, response.data);
    },

    async getInfoByPregnancyId(id: number): Promise<PregnancyCardDto> {
        const response = await httpService.get(`/PregnancyCard/GetByPregnancyId/${id}`);
        return plainToInstance(PregnancyCardDto, response.data);
    },

    createPregnancyCard(data: PregnancyCardDto): Promise<AxiosResponse<number>> {
        return httpService.post('/PregnancyCard/CreatePregnancyCard/', data);
    },

    updatePregnancyCard(data: PregnancyCardDto): Promise<AxiosResponse<void>> {
        return httpService.put('/PregnancyCard/UpdatePregnancyCard/', data);
    },

    deletePregnancyCard(id: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/PregnancyCard/DeletePregnancyCard/${id}`);
    },

    getPregnancyCardId(registerId: number): Promise<AxiosResponse<number>> {
        return httpService.get(`/PregnancyCard/GetByPregnancyCardIdByRegisterId/${registerId}`);
    }
};
