<template>
    <div v-if="isVisible" class="loading-animation"></div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { loadingState } from '@/store/LoadingState';

    @Component
    export default class LoadingAnimation extends Vue {
        // Prop size - sm, md, lg

        private get isVisible() {
            return loadingState.isVisible;
        }
    }
</script>

<style scoped>
    .loading-animation {
        border: 8px solid lightgrey;
        border-top: 8px solid #2196f3;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-top: -50px;
        margin-left: auto;
        margin-right: auto;
        position: sticky;
        animation: spin 1s linear infinite;
        left: 50%;
        top: 50%;
        z-index: 1;
        opacity: 0.2;
    }

    /*
        .smallLoader {
        border: 3px solid lightgrey;
        border-top: 3px solid #2196f3;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin: auto;
        animation: spin 1s linear infinite;
    }*/
</style>
