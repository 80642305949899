<template>
    <v-form ref="form" v-model="isFormValid">
        <v-card class="pb-2">
            <v-card-title>
                Извличане на Е-Имунизации
                <v-spacer />
                <div class="red--text pointer" @click="close">x</div>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <dropdown v-model="value.nhisVaccineCode" :items="vaccines" item-value="code" label="Ваксина" />
                    </v-col>
                    <v-col v-if="examId" cols="3">
                        <date-field v-model="value.occurrenceDate" label="Дата на прегледа" />
                    </v-col>
                    <v-col v-else cols="3">
                        <date-picker v-model="value.occurrenceDate" label="Дата на имунизацията" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <text-field v-model="value.nrn" :rules="[$validator.nrn]" label="НРН номер"></text-field>
                    </v-col>
                    <v-col cols="6">
                        <text-field v-model="value.doseNumber" label="Номер на доза"></text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <btn action="Refresh" :disabled="isLoading" @click="postExtractionRequest"> Извличане </btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { NhisPatientImmunizationExtractionCommand } from '@/model/Nhis/Immunization/NhisPatientImmunizationExtractionCommand';
    import { NhisVaccineDto } from '@/model/Nomenclature/NhisVaccineDto';
    import { examService } from '@/service/Exam/ExamService';
    import { nhisImmunizationService } from '@/service/Nhis/NhisImmunizationService';
    import { nhisVaccineService } from '@/service/Nomenclature/NhisVaccineService';
    import { signXmlService } from '@/service/Report/SignXmlService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class NhisImmunizationExtraction extends Vue {
        @Prop()
        examId!: number | null;

        @Prop()
        dialogIsVisible!: boolean;

        private value: NhisPatientImmunizationExtractionCommand = new NhisPatientImmunizationExtractionCommand();
        private vaccines: NhisVaccineDto[] = [];
        private isFormValid: boolean = false;
        private examDate: Date | null = null;

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private async getNhisVaccines() {
            const response = await nhisVaccineService.getNhisVaccines();
            if (response?.data) {
                this.vaccines = response.data;
            }
        }

        private async getExamDate() {
            if (this.examId) {
                this.examDate = await examService.getExamDate(this.examId);
            }
        }

        private async getExtractionRequestXml(): Promise<string> {
            let result = '';
            const userContext = userContextCache.current;
            if (currentPatientCache.value && userContext?.doctorUin) {
                this.value.patientVersionKey = currentPatientCache.value.key;
                this.value.doctorUin = userContext.doctorUin;
                this.value.deputyDoctorUin = userContext.deputyDoctorUin;
                const response = await nhisImmunizationService.getExtractionRequestXml(this.value);
                if (response?.data) {
                    result = response.data;
                }
                console.log(response);
            }
            return result;
        }

        private async postExtractionRequest() {
            this.$loading.show();
            try {
                const xmlString = await this.getExtractionRequestXml();
                const signResponse = await signXmlService.signXml(xmlString);
                if (!signResponse.isError && signResponse.contents) {
                    const extractionResult = await nhisImmunizationService.postExtractionRequest(
                        signResponse.contents,
                        this.currentPatient.key.patientId
                    );
                    console.log(extractionResult?.response);
                    if (extractionResult?.error?.length > 0) {
                        this.$emit('error', extractionResult);
                    } else {
                        this.update();
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private close() {
            this.$emit('close');
            this.value = new NhisPatientImmunizationExtractionCommand();
        }

        private update() {
            this.$emit('update');
            this.value = new NhisPatientImmunizationExtractionCommand();
        }

        private async mounted() {
            await this.getNhisVaccines();
            if (this.examId) {
                await this.getExamDate();
            }
        }

        @Watch('dialogIsVisible', { immediate: true })
        async onDialogVisibilityChange(isVisible: boolean): Promise<void> {
            if (isVisible) {
                if (!this.examDate) {
                    await this.getExamDate();
                }
                this.value.occurrenceDate = this.examDate;
            }
        }
    }
</script>
