import { dateUtil } from '@/util/DateUtil';

export class ReportIntervalFilterDto {
    public practiceId: number = 0;
    public doctorEmployeeSeqNumber: number | null = null;
    public startDate: Date | null = null;
    public endDate: Date | null = null;
    public reportByPractice: boolean = false;

    public fillPreviousMonth(): void {
        this.startDate = dateUtil.startOfLastMonth();
        this.endDate = dateUtil.endOfLastMonth();
    }

    public fillCurrentMonth(): void {
        this.startDate = dateUtil.startOfThisMonth();
        this.endDate = dateUtil.endOfThisMonth();
    }

    public fillCurrentYear(): void {
        this.startDate = dateUtil.startOfThisYear();
        this.endDate = dateUtil.endOfThisYear();
    }
}
