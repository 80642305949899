import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { SickSheetCounterDto } from '@/model/Setting/Counter/SickSheetCounterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const sickSheetCounterService = {
    async getSickSheetCountersByPractiteId(id: number): Promise<SickSheetCounterDto[]> {
        const response = await httpService.get<SickSheetCounterDto[]>(
            `/SickSheetCounter/GetSickSheetCountersByPracticeId/${id}`
        );
        return plainToInstance(SickSheetCounterDto, response?.data);
    },

    async getSickSheetCounterById(id: number): Promise<SickSheetCounterDto> {
        const response = await httpService.get<SickSheetCounterDto>(`/SickSheetCounter/GetSickSheetCounterById/${id}`);
        return plainToInstance(SickSheetCounterDto, response?.data);
    },

    createSickSheetCounter(data: SickSheetCounterDto): Promise<AxiosResponse<number>> {
        return httpService.post('/SickSheetCounter/CreateSickSheetCounter', data);
    },

    updateSickSheetCounter(data: SickSheetCounterDto): Promise<AxiosResponse<SickSheetCounterDto>> {
        return httpService.post('/SickSheetCounter/UpdateSickSheetCounter/', data);
    }
};
