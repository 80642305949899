<template>
    <data-table
        title="Персонал"
        :headers="headers"
        :items="employees"
        :loading="$loading.isVisible"
        item-class="employee.itemClass"
        disable-pagination
    >
        <template #actions>
            <btn v-if="isGlobalAdminOrPracticeOwner" action="NewAccount" :to="`/Employee/Create/${practiceId}`">
                Добавяне на лекар или друг служител
            </btn>
        </template>
        <template #rowActions="{ item }">
            <v-row>
                <v-col>
                    <alert
                        v-if="item.pendingEmployeeUsers && item.pendingEmployeeUsers.length"
                        type="warning"
                        dense
                        class="mb-0"
                    >
                        {{ item.pendingEmployeeUsers.join(', ') }} очаква одобрение
                    </alert>
                    <alert
                        v-if="item.pendingDeputyUsers && item.pendingDeputyUsers.length"
                        type="warning"
                        dense
                        class="mb-0"
                    >
                        зам. {{ item.pendingDeputyUsers.join(', ') }} очаква одобрение
                    </alert>
                </v-col>
                <v-col cols="auto">
                    <btn
                        icon
                        :action="allowWrite(item.employee.employeeSeqNumber) ? 'Edit' : 'Details'"
                        :to="`/Employee/Edit/${practiceId}/${item.employee.employeeSeqNumber}`"
                    />
                </v-col>
            </v-row>
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { EmployeeRowDto } from '@/model/Employee/EmployeeRowDto';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { licensingCache } from '@/store/LicensingCache';

    import { EmployeeBase } from './EmployeeBase';

    @Component
    export default class EmployeeList extends EmployeeBase {
        @Prop({ required: true })
        private practiceId!: number;

        private headers: IDataTableHeader[] = [
            { text: 'Име', value: 'employee.doctorNameOrEmployeeDescription' },
            { text: 'УИН', value: 'employee.doctorUin' },
            { text: 'Специалност', value: 'employee.specialtyName' },
            { text: 'Роли', value: 'employee.rolesText' },
            { text: '', value: 'employee.isDeletedText' }
        ];

        private employees: EmployeeRowDto[] = [];

        private async mounted() {
            this.$loading.show();
            try {
                // При всяко навигиране към екрана със служителите, лицензионният статус се опреснява асинхронно.
                // Това се случва например при запис на служител, заместващ/нает, свъразан потребител.
                licensingCache.load(this.practiceId);

                this.employees = await employeeService.getEmployeeGrid(this.practiceId);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
