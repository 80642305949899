import { JournalDto } from '@/model/VisitList/Journal/JournalDto';
import { JournalFilterCommand } from '@/model/VisitList/Journal/JournalFilterCommand';
import { httpService } from '@/service/Infrastructure/HttpService';

export const journalService = {
    async getDocumentsByType(command: JournalFilterCommand): Promise<JournalDto[]> {
        const response = await httpService.post<JournalDto[]>('/Journal/GetDocumentsJournal', command);
        return response.data;
    },

    exportExcel(command: JournalFilterCommand): Promise<void> {
        return httpService.download('/Journal/ExportExcel', { params: command });
    }
};
