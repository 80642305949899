import { FileInfoDto } from '@/model/File/FileInfoDto';
import { FileKey } from '@/model/File/FileKey';
import { FileUploadCommand } from '@/model/File/FileUploadCommand';
import { httpService } from '@/service/Infrastructure/HttpService';

export const externalFileService = {
    async loadFilesForPatient(patientId: number): Promise<FileInfoDto[]> {
        const response = await httpService.get<FileInfoDto[]>('ExternalFile/GetFilesForPatient', {
            params: { patientId }
        });
        return response.data;
    },

    async loadFilesForVisit(visitId: number): Promise<FileInfoDto[]> {
        const response = await httpService.get<FileInfoDto[]>('ExternalFile/GetFilesForVisit', {
            params: { visitId }
        });
        return response.data;
    },

    downloadFile(fileKey: FileKey): Promise<void> {
        return httpService.download('ExternalFile/DownloadFile', { params: fileKey });
    },

    async createVisitWithFiles(
        patientId: number,
        doctorEmployeeSeqNumber: number,
        data: FileUploadCommand[]
    ): Promise<number> {
        const response = await httpService.post<number>('ExternalFile/CreateVisitWithFiles', data, {
            params: { patientId, doctorEmployeeSeqNumber }
        });
        return response.data;
    },

    async uploadFiles(data: FileUploadCommand[]): Promise<void> {
        await httpService.post('ExternalFile/UploadFiles', data);
    },

    async deletePatientFile(fileKey: FileKey): Promise<void> {
        await httpService.delete('ExternalFile/DeletePatientFile', { params: fileKey });
    },

    async deleteVisitFile(fileKey: FileKey): Promise<boolean> {
        const response = await httpService.delete<boolean>('ExternalFile/DeleteVisitFile', { params: fileKey });
        return response.data;
    }
};
