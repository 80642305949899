import { Type } from 'class-transformer';

import { ServiceOfferKey } from '@/model/Practice/Service/ServiceOfferKey';
import { ServiceOfferDto } from '@/model/Reception/Service/ServiceOfferDto';

export class InvoiceLineDto {
    public id: number = 0;
    public invoiceId: number = 0;
    public quantity: number | null = null;
    public totalPrice: number = 0;
    public unitPrice: number | null = null;
    public invoiceUnitCode: string | null = null;
    public description: string | null = null;
    public itemCode: string = '';

    //Необходими само за ПИС фактурите
    public invoiceUnitName: string = '';
    public vatRate: number = 0;

    // Допълнителни client-side полета.
    @Type(() => ServiceOfferKey)
    public serviceOfferKey: ServiceOfferKey = new ServiceOfferKey();

    @Type(() => ServiceOfferDto)
    public serviceOffersForDropdown: ServiceOfferDto[] = [];
}
