<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">Раздел ІV. Легла за наблюдение и лечение до 48 часа</h3></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><DataTableComponent v-model="value.beds" :headers="headers" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Report365BedDto } from '@/model/Report/Rhi/Report365/Report365BedDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class Bed extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Report365BedDto;

        private headers: IDataTableHeader[] = [
            { text: '', value: 'name' },
            { text: 'Шифър', value: 'code' },
            { text: 'Легла - в края на годината', value: 'countBedsEndYear' },
            { text: 'Cредно-годишен брой легла', value: 'countBedsAverage' },
            { text: 'Болни в началото на годината', value: 'sickCountStartYear' },
            { text: 'През годината - постъпили', value: 'sickCountIncoming' },
            { text: 'През годината - изписани', value: 'sickCountCameOut' },
            { text: 'През годината - умрели', value: 'sickCountDead' },
            { text: 'Болни в края на годината', value: 'sickCountEndYear' },
            { text: 'Проведени леглодни през годината', value: 'bedDay' }
        ];
    }
</script>
