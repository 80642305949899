import { NhisAllergyDto } from '@/model/Nhis/Allergy/NhisAllergyDto';

export class PatientAllergyDto {
    public id: number = 0;
    public patientId: number = 0;
    public name: string = '';
    public allergyTypeId: number | null = null;
    public allergyTypeName: string | null = null;
    public startDate: Date | null = null;
    public medicationCode: string | null = null;

    public lastOccurrence: Date | null = null;
    public likeCode: string | null = null;
    public categoryCode: string | null = null;
    public criticalityCode: string | null = null;
    public clinicalStatusCode: string | null = null;
    public verificationStatusCode: string | null = null;
    public description: string | null = null;
    public nrnExecution: string | null = null;
    public nhisAllergy: NhisAllergyDto = new NhisAllergyDto();
}
