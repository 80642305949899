<template>
    <v-autocomplete
        :value="value"
        :items="dropdownItems"
        :item-value="itemValue"
        :item-text="itemText"
        :label="label"
        :no-data-text="$t('dropdown.noDataFound')"
        :class="{ required }"
        :rules="required ? [$validator.required] : []"
        :clearable="!readonly"
        :name="'multiDropdown' + _uid"
        :readonly="readonly"
        :disabled="disabled"
        dense
        chips
        deletable-chips
        multiple
        @input="$emit('input', ...arguments)"
    ></v-autocomplete>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IMultiSelectDropdownItem } from '@/model/Common/Dropdown/IMultiSelectDropdownItem';

    @Component
    export default class MultiSelectDropdown extends Vue {
        @Prop()
        private value!: number[] | string[];

        @Prop({ required: true })
        private items!: IMultiSelectDropdownItem[];

        private dropdownItems: IMultiSelectDropdownItem[] = [];

        @Prop({ default: 'id' })
        private itemValue!: string;

        @Prop({ default: 'name' })
        private itemText!: string;

        @Prop()
        private label!: string;

        @Prop()
        private required!: boolean;

        @Prop()
        private readonly!: boolean;

        @Prop()
        private disabled!: boolean;

        private mounted() {
            this.dropdownItems = this.items;
        }

        @Watch('items')
        private itemsPropChanged() {
            const orphanedItems: IMultiSelectDropdownItem[] = [];
            if (this.value) {
                for (const itemId of this.value) {
                    const newSelectedItem = this.items.find((it) => it.id === itemId || it.code === itemId);
                    if (!newSelectedItem) {
                        const oldSelectedItem = this.dropdownItems.find((it) => it.id === itemId || it.code === itemId);
                        if (oldSelectedItem) {
                            orphanedItems.push(oldSelectedItem);
                        }
                    }
                }
            }
            this.dropdownItems = orphanedItems.concat(this.items);
        }
    }
</script>
