import { Type } from 'class-transformer';

import { VisitResultDto } from '@/model/VisitList/VisitSearch/VisitResultDto';

export class PatientVisitSummaryDto {
    @Type(() => VisitResultDto)
    public visits: VisitResultDto[] = [];

    public totalCount: number = 0;
}
