<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Медицинско за работа" :items="items">
                <template #actions>
                    <btn action="New" :to="`/Exam/CertificateWork/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/CertificateWork/Edit/${item.id}`" />
                    <btn icon action="Print" :to="`/Print/Exam.Certificate.CertificateWork/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { CertificateWorkViewDto } from '@/model/Exam/Certificate/CertificateWorkViewDto';
    import { certificateWorkService } from '@/service/Exam/Certificate/CertificateWorkService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class CertificateWorkView extends Vue {
        @Prop()
        examId!: number;

        private items: CertificateWorkViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Номер:', value: 'number' },
                { text: 'Работодател:', value: 'employer' },
                { text: 'Професия:', value: 'position' },
                { text: 'Работил/а в предприятието:', value: 'isPastEmployee' },
                { text: 'Необходими прегледи при специалисти:', value: 'requiresSpecialistExam' },
                { text: 'Минали заболявания:', value: 'previousDisease' },
                { text: 'Инвалидност:', value: 'disability' },
                { text: 'Данни от прегледа:', value: 'examResults' },
                { text: 'Годен :', value: 'isMedicallyApproved' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await certificateWorkService.getCertificatesByExamId(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
