import { NhifCommand } from '@/model/Nhif/NhifCommand';
import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

export class NhifProtocolSearchCommand extends NhifCommand {
    constructor(
        public patientVersionKey: PatientVersionKey,
        public doctorUin: string | null,
        public deputyDoctorUin: string | null,
        public deputyDoctorIsHired: boolean | null
    ) {
        super(patientVersionKey, doctorUin, deputyDoctorUin);
    }

    public fromDate: Date | null = null;
    public code: string = '';
}
