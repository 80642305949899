import { Route, RouteConfig } from 'vue-router';

export const examReferralRoutes: RouteConfig[] = [
    {
        path: 'Referral3/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral3/Referral3.vue')
        }
    },
    {
        path: 'Referral3/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral3/Referral3.vue')
        }
    },
    {
        path: 'Referral3/:examId',
        props: {
            exam: (route: Route) => ({
                examId: Number(route.params.examId),
                isSpecialized: false
            })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral3/Referral3View.vue')
        }
    },
    {
        path: 'Referral3a/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral3a/Referral3a.vue')
        }
    },
    {
        path: 'Referral3a/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral3a/Referral3a.vue')
        }
    },
    {
        path: 'Referral3a/:examId',
        props: {
            exam: (route: Route) => ({
                examId: Number(route.params.examId),
                isSpecialized: true
            })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral3/Referral3View.vue')
        }
    },
    {
        path: 'Referral4/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral4/Referral4.vue')
        }
    },
    {
        path: 'Referral4/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral4/Referral4.vue')
        }
    },
    {
        path: 'Referral4/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral4/Referral4View.vue')
        }
    },
    {
        path: 'Referral4/Referral4QuickAdd/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral4/Referral4QuickAdd.vue')
        }
    },
    {
        path: 'Referral6/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral6/Referral6.vue')
        }
    },
    {
        path: 'Referral6/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral6/Referral6.vue')
        }
    },
    {
        path: 'Referral6/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral6/Referral6View.vue')
        }
    },
    {
        path: 'Referral7/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral7/Referral7.vue')
        }
    },
    {
        path: 'Referral7/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral7/Referral7.vue')
        }
    },
    {
        path: 'Referral7/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral7/Referral7View.vue')
        }
    },
    {
        path: 'Referral8/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral8/Referral8.vue')
        }
    },
    {
        path: 'Referral8/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral8/Referral8.vue')
        }
    },
    {
        path: 'Referral8/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral8/Referral8View.vue')
        }
    },
    {
        path: 'Referral8a/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral8a/Referral8a.vue')
        }
    },
    {
        path: 'Referral8a/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral8a/Referral8a.vue')
        }
    },
    {
        path: 'Referral8a/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId), is8a: true })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/Referral8/Referral8View.vue')
        }
    },
    {
        path: 'ReferralTelk/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/ReferralTelk/ReferralTelk.vue')
        }
    },
    {
        path: 'ReferralTelk/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/ReferralTelk/ReferralTelk.vue')
        }
    },
    {
        path: 'ReferralTelk/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/ReferralTelk/ReferralTelkView.vue')
        }
    },
    {
        path: 'ReferralDispObserv/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/ReferralDispensaryObservation/ReferralDispensaryObservation.vue')
        }
    },
    {
        path: 'ReferralDispObserv/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/ReferralDispensaryObservation/ReferralDispensaryObservation.vue')
        }
    },
    {
        path: 'ReferralDispObserv/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () =>
                import('@/view/Exam/Referral/ReferralDispensaryObservation/ReferralDispensaryObservationView.vue')
        }
    },
    {
        path: 'ReferralRequestFor/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/ReferralRequestFor/ReferralRequestFor.vue')
        }
    },
    {
        path: 'ReferralRequestFor/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/ReferralRequestFor/ReferralRequestFor.vue')
        }
    },
    {
        path: 'ReferralRequestFor/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/ReferralRequestFor/ReferralRequestForView.vue')
        }
    }
];
