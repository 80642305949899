import { AxiosResponse } from 'axios';

import { NhifCommand } from '@/model/Nhif/NhifCommand';
import { NhifDiagnosisRequestDto } from '@/model/Nhif/NhifDiagnosisRequestDto';
import { NhifPrescriptionBookDiagnosisDto } from '@/model/Nhif/NhifPrescriptionBookDiagnosisDto';
import { NhifPrescriptionBookDto } from '@/model/Nhif/NhifPrescriptionBookDto';
import { NhifPrescriptionBookMedicationCheckDto } from '@/model/Nhif/NhifPrescriptionBookMedicationCheckDto';
import { NhifPrescriptionBookSearchCommand } from '@/model/Nhif/NhifPrescriptionBookSearchCommand';
import { SubmitCommand } from '@/model/Nhis/SubmitCommand';
import { SubmitCommandWithId } from '@/model/Nhis/SubmitCommandWithId';
import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';
import { httpService } from '@/service/Infrastructure/HttpService';
import { accessTokenCache, nhifKey } from '@/store/NhisNhifAccessTokenCache';

export const prescriptionBookService = {
    getXmlPrescriptionBookSearch(data: NhifCommand): Promise<AxiosResponse<string>> {
        return httpService.post(`/${nhifKey}/GetPrescriptionBookSearch/`, data);
    },

    async postPrescriptionBookSearch(
        xmlString: string,
        patientVersionKey: PatientVersionKey
    ): Promise<NhifPrescriptionBookDto> {
        const tokenInfo = await accessTokenCache.ensureValidToken(nhifKey);
        const response = await httpService.post<NhifPrescriptionBookDto>(
            `${nhifKey}/PostPrescriptionBookSearch`,
            new NhifPrescriptionBookSearchCommand(xmlString, tokenInfo?.accessToken ?? '', patientVersionKey)
        );
        return response.data;
    },

    getPrescriptionBookByPatientId(patientId: number): Promise<AxiosResponse<NhifPrescriptionBookDto>> {
        return httpService.get(`${nhifKey}/GetByPatientId/${patientId}`);
    },

    getDiagnosisById(id: number): Promise<AxiosResponse<NhifPrescriptionBookDiagnosisDto>> {
        return httpService.get(`${nhifKey}/GetDiagnosisById/${id}`);
    },

    getDiagnosisUpdateRequest(data: NhifDiagnosisRequestDto): Promise<AxiosResponse<string>> {
        return httpService.post(`${nhifKey}/GetDiagnosisRequestXml`, data);
    },

    async postDiagnosisUpdate(xmlString: string, patientId: number): Promise<NhifPrescriptionBookDto> {
        const tokenInfo = await accessTokenCache.ensureValidToken(nhifKey);
        const response = await httpService.post<NhifPrescriptionBookDto>(
            `${nhifKey}/PostDiagnosisUpdate`,
            new SubmitCommandWithId(xmlString, tokenInfo?.accessToken ?? '', patientId)
        );
        return response.data;
    },

    getPrescriptionHistory(data: NhifCommand): Promise<AxiosResponse<string>> {
        return httpService.post(`${nhifKey}/GetPrescriptionHistoryRequest/`, data);
    },

    async postPrescriptionHistory(xmlString: string): Promise<NhifPrescriptionBookMedicationCheckDto> {
        const tokenInfo = await accessTokenCache.ensureValidToken(nhifKey);
        const response = await httpService.post<NhifPrescriptionBookMedicationCheckDto>(
            `${nhifKey}/PostPrescriptionHistory`,
            new SubmitCommand(xmlString, tokenInfo?.accessToken ?? '')
        );
        return response.data;
    },

    deactivateIcd(id: number): Promise<AxiosResponse<void>> {
        return httpService.get(`${nhifKey}/DeactivateIcd/${id}`);
    },

    async getActiveDiagnosesForPatientAsync(patientId: number, toDate: Date): Promise<string[]> {
        const response = await httpService.get<string[]>(`${nhifKey}/GetActiveIcdForPatient`, {
            params: {
                patientId,
                toDate
            }
        });

        return response.data;
    }
};
