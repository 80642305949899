import { plainToInstance } from 'class-transformer';

import { DispensaryExternalEditDto } from '@/model/Patient/Dispensary/DispensaryExternalEditDto';
import { DispensaryExternalRowDto } from '@/model/Patient/Dispensary/DispensaryExternalRowDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const dispensaryExternalService = {
    async getDispensaryExternals(patientId: number): Promise<DispensaryExternalRowDto[]> {
        const response = await httpService.get<DispensaryExternalRowDto[]>(
            '/DispensaryExternal/GetDispensaryExternals',
            { params: { patientId } }
        );
        return plainToInstance(DispensaryExternalRowDto, response.data);
    },

    async getDispensaryExternal(patientId: number, seqNumber: number): Promise<DispensaryExternalEditDto> {
        const response = await httpService.get<DispensaryExternalEditDto>('/DispensaryExternal/GetDispensaryExternal', {
            params: { patientId, seqNumber }
        });
        return plainToInstance(DispensaryExternalEditDto, response.data);
    },

    async createDispensaryExternal(data: DispensaryExternalEditDto): Promise<number> {
        const response = await httpService.post<number>('/DispensaryExternal/CreateDispensaryExternal', data);
        return response.data;
    },

    async updateDispensaryExternal(data: DispensaryExternalEditDto): Promise<void> {
        await httpService.put('/DispensaryExternal/UpdateDispensaryExternal', data);
    },

    async deleteDispensaryExternal(patientId: number, seqNumber: number): Promise<void> {
        await httpService.delete('/DispensaryExternal/DeleteDispensaryExternal', {
            params: { patientId, seqNumber }
        });
    }
};
