<template>
    <div>
        <v-row justify="center" class="mx-5">
            <v-dialog v-model="extractionDialogIsVisible" persistent max-width="75%">
                <NhisReferralExtractionEditor
                    v-model="filter"
                    show-close-pointer
                    @post-request="postRequest"
                    @close-dialog="closeEditorDialog"
                />
            </v-dialog>
        </v-row>
        <v-row justify="center" class="mx-5">
            <v-dialog v-model="resultDialogIsVisible" persistent max-width="75%">
                <NhisReferralExtractionResult
                    :items="extractedReferrals"
                    show-close-pointer
                    @input="onReferralSelect"
                    @close-table="closeResultDialog()"
                />
            </v-dialog>
        </v-row>
        <v-card-actions>
            <btn action="Download" class="ml-2" @click="openEditorDialog">Извличане на направление</btn>
            <btn
                v-if="extractedReferrals && extractedReferrals.length > 0"
                action="List"
                class="ml-2"
                @click="openResultDialog"
            >
                Извлечени направления
            </btn>
        </v-card-actions>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { NhisResponseWarningDto } from '@/model/Nhis/NhisResponseWarningDto';
    import { NhisExtractedReferralDto } from '@/model/Nhis/Referral/NhisExtractedReferralDto';
    import { NhisReferralExtractionCommand } from '@/model/Nhis/Referral/NhisReferralExtractionCommand';
    import { nhisReferralService } from '@/service/Nhis/NhisReferralService';
    import { signXmlService } from '@/service/Report/SignXmlService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import NhisReferralExtractionEditor from '@/view/Exam/Referral/ReferralExtraction/NhisReferralExtractionEditor.vue';
    import NhisReferralExtractionResult from '@/view/Exam/Referral/ReferralExtraction/NhisReferralExtractionResult.vue';

    @Component({
        components: { NhisReferralExtractionResult, NhisReferralExtractionEditor }
    })
    export default class NhisReferralExtraction extends Vue {
        private extractionDialogIsVisible: boolean = false;
        private resultDialogIsVisible: boolean = false;
        private filter: NhisReferralExtractionCommand = new NhisReferralExtractionCommand();
        private extractedReferrals: NhisExtractedReferralDto[] = [];

        private openEditorDialog() {
            this.extractionDialogIsVisible = true;
        }

        private closeEditorDialog() {
            this.extractionDialogIsVisible = false;
            this.filter = new NhisReferralExtractionCommand();
        }

        private async getExtractionRequestXml(): Promise<string> {
            const result = await nhisReferralService.getXmlExtractionReferral(this.filter);
            console.log(result);
            return result ?? '';
        }

        private async postRequest() {
            this.$loading.show();
            try {
                this.extractedReferrals = [];
                const xmlString = await this.getExtractionRequestXml();
                const signResponse = await signXmlService.signXml(xmlString);
                if (!signResponse.isError && signResponse.contents && userContextCache.current?.specialtyCode) {
                    const extractionResult = await nhisReferralService.postReferralExtraction(
                        signResponse.contents,
                        userContextCache.current?.specialtyCode
                    );
                    console.log(extractionResult?.response);
                    if (extractionResult?.error?.length > 0) {
                        this.$notifier.showWarning('', extractionResult.error);
                    } else {
                        if (extractionResult?.referrals?.length > 0) {
                            this.extractedReferrals = extractionResult.referrals;
                            this.openResultDialog();
                        } else {
                            this.$notifier.showWarning('', 'Не е намерено активно направление по посочените данни');
                        }
                        if (extractionResult?.warnings?.length > 0) {
                            this.showWarnings(extractionResult.warnings);
                        }
                    }
                }
            } finally {
                this.$loading.hide();
                this.closeEditorDialog();
            }
        }

        private showWarnings(warnings: NhisResponseWarningDto[]) {
            for (const warning of warnings) {
                this.$notifier.showWarning(
                    `Код предупреждение: ${warning.code}`,
                    `Описание: ${warning.description}; От: ${warning.source}`
                );
            }
        }

        private onReferralSelect(item: NhisExtractedReferralDto) {
            this.closeResultDialog();
            this.$emit('referral-selected', item);
        }

        private openResultDialog() {
            this.resultDialogIsVisible = true;
        }

        private closeResultDialog() {
            this.resultDialogIsVisible = false;
        }
    }
</script>
