import { Type } from 'class-transformer';

import { Annex56CausesOfMorbidityMortalityAdultRowDto } from '@/model/Report/Rhi/Annex56/Annex56CausesOfMorbidityMortalityAdultRowDto';

export class Annex56CausesOfMorbidityMortalityAdultDto {
    public name: string = '';

    @Type(() => Annex56CausesOfMorbidityMortalityAdultRowDto)
    public causesOfMorbiditiesMortalitiesAdult: Annex56CausesOfMorbidityMortalityAdultRowDto[] = [];
}
