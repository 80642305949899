export class CertificatePhysicalExamDto {
    public id: number = 0;
    public examId: number = 0;
    public sportType: string | null = null;
    public competitionName: string | null = null;
    public competitionLocation: string | null = null;
    public competitionDate: Date | null = new Date();
    public patientCondition: string | null = null;
    public isSuitable: boolean = false;
}
