<template>
    <div v-if="value">
        <v-row>
            <v-col cols="3" md="1">
                <text-field v-model="value.code" label="Код по МКБ10" readonly />
            </v-col>
            <v-col cols="3" md="1">
                <text-field v-model="value.nhisCode" label="Код по НЗИС" readonly />
            </v-col>
            <v-col cols="6" md="3">
                <text-field v-model="value.description" label="Описание" readonly />
            </v-col>
            <v-col cols="6" md="3">
                <text-field v-model="value.nrn" label="НРН" readonly />
            </v-col>
            <v-col cols="3" md="1">
                <text-field v-model="value.executionCount" label="Брой изпълнения" />
            </v-col>
            <v-col v-if="value.note && value.note.length > 0" cols="6" md="3">
                <v-textarea v-model="value.note" readonly rows="2" label="Бележки" />
            </v-col>
        </v-row>
        <v-row v-for="(result, index) in value.results" :key="index" no-gutters class="align-baseline">
            <btn action="Minus" icon @click="removeResult(index)">Изтриване на резултат</btn>
            <nhis-exam-medical-procedure-result
                v-model="value.results[index]"
                :position="position"
                :procedure-nhis-code="value.nhisCode"
            />
        </v-row>
        <btn v-if="canAddMoreResult" action="New" @click="addResult" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import Btn from '@/component/Common/Btn.vue';
    import TextField from '@/component/Common/TextField.vue';
    import { ExamAnswerTypeCode } from '@/enum/Exam/ExamAnswerTypeCode';
    import { NhisMedicalProcedureDto } from '@/model/Nhis/Exam/PreventiveActivities/NhisMedicalProcedureDto';
    import { NhisMedicalProcedureResultDto } from '@/model/Nhis/Exam/PreventiveActivities/NhisMedicalProcedureResultDto';
    import { medicalProcedureAnswerEnumCache } from '@/store/Nomenclature/MedicalProcedureAnswerEnumCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import NhisExamMedicalProcedureResult from '@/view/Nhis/Exam/NhisExamMedicalProcedureResult.vue';

    @Component({
        computed: {
            ExamAnswerTypeCode() {
                return ExamAnswerTypeCode;
            }
        },
        components: { NhisExamMedicalProcedureResult, Btn, TextField }
    })
    export default class NhisExamMedicalProcedure extends Vue {
        @Prop()
        value!: NhisMedicalProcedureDto;

        @Prop()
        position!: number;

        addResult() {
            this.value.results.push(new NhisMedicalProcedureResultDto());
        }

        removeResult(index: number) {
            arrayUtil.removeAt(this.value.results, index);
        }

        get canAddMoreResult() {
            return (
                this.value?.results?.length === 0 ||
                this.value?.results?.length < this.getPossibleResultsByProcedure?.length
            );
        }

        get getPossibleResultsByProcedure() {
            return medicalProcedureAnswerEnumCache.items.filter(
                (answer) => answer.medicalProcedureNhisCode === this.value.nhisCode
            );
        }

        mounted() {
            if (this.value?.results?.length === 0) {
                this.addResult();
            }
        }
    }
</script>

<style scoped></style>
