export class ImmunizationReportDto {
    public pidType: string = '';
    public identifier: string = '';
    public patientName: string = '';
    public age: number | null = null;
    public immunizationNhifCode: string | null = null;
    public immunizationRhiCode: string | null = null;
    public immunizationName: string = '';
    public occuranceDate: Date | null = null;
    public ambulatorySheetNumber: number | null = null;
    public patientCity: string | null = null;
    public lotNumber: string | null = null;
    public lotNumberValidTo: Date | null = null;
    public vaccineName: string | null = null;
}
