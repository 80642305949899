<template>
    <v-row row>
        <v-col cols="12">
            <v-card-title>Детайли</v-card-title>

            <v-col cols="12">
                <v-tabs dark background-color="primary" show-arrows align-with-title>
                    <v-tab>I.</v-tab>
                    <v-tab>II.</v-tab>
                    <v-tab>III.</v-tab>
                    <v-tab>IV.</v-tab>
                    <v-tab>V.</v-tab>
                    <v-tab-item>
                        <v-col cols="12"><ChildrenVisit v-model="value.annex3Items.childrenVisit" /></v-col>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12"> <ChildrenRegister v-model="value.annex3Items.childrenRegister" /></v-col>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12"><NewbornRegister v-model="value.annex3Items.newbornRegister" /></v-col>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12"><OneYearRegister v-model="value.annex3Items.oneYearRegister" /></v-col>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12"
                            ><ChildrenWithPneumonia v-model="value.annex3Items.childrenWithPneumonia"
                        /></v-col>
                    </v-tab-item>
                </v-tabs>
            </v-col>
            <v-col cols="12"
                ><v-card-actions>
                    <v-col v-if="reportKey.seqNumber" cols="12" md="2">
                        <btn action="Export" @click="exportAnnex3"> </btn>
                    </v-col> </v-card-actions
            ></v-col>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { Report365Dto } from '@/model/Report/Rhi/Report365/Report365Dto';
    import { RhiReportKey } from '@/model/Report/Rhi/RhiReportKey';
    import { report365Service } from '@/service/Report/Rhi/Report365/Report365Service';
    import ChildrenRegister from '@/view/Report/Rhi/Report365/Annex3/ChildrenRegister.vue';
    import ChildrenVisit from '@/view/Report/Rhi/Report365/Annex3/ChildrenVisit.vue';
    import ChildrenWithPneumonia from '@/view/Report/Rhi/Report365/Annex3/ChildrenWithPneumonia.vue';
    import NewbornRegister from '@/view/Report/Rhi/Report365/Annex3/NewbornRegister.vue';
    import OneYearRegister from '@/view/Report/Rhi/Report365/Annex3/OneYearRegister.vue';

    @Component({
        components: {
            ChildrenRegister,
            ChildrenVisit,
            ChildrenWithPneumonia,
            NewbornRegister,
            OneYearRegister
        }
    })
    export default class Annex3Component extends Vue {
        @Prop({ required: true })
        private reportKey!: RhiReportKey;

        @Prop({ required: true })
        private value!: Report365Dto;

        private timer: number = 0;

        private async exportAnnex3() {
            if (this.reportKey.seqNumber) {
                this.$loading.show();
                try {
                    await report365Service.exportAnnex3(this.reportKey);
                } finally {
                    this.$loading.hide();
                }
            }
        }
    }
</script>
