// Ограничен брой масово използвани компоненти се регистрират глобално в main.ts, за удобство.
const globalComponents = {
    Alert: () => import('@/component/Common/Alert.vue'),
    Btn: () => import('@/component/Common/Btn.vue'),
    DataTable: () => import('@/component/Common/DataTable.vue'),
    DateField: () => import('@/component/Date/DateField.vue'),
    DatePicker: () => import('@/component/Date/DatePicker.vue'),
    Dropdown: () => import('@/component/Dropdown/Dropdown.vue'),
    MainTitle: () => import('@/component/Common/MainTitle.vue'),
    SearchField: () => import('@/component/Common/SearchField.vue'),
    TextField: () => import('@/component/Common/TextField.vue'),
    HelpPopup: () => import('@/component/Help/HelpPopup.vue')
};

export { globalComponents };

// Вариант за регистриране на глобалните компоненти по конвенция, т.е. с поставянето им в дадена папка.
//import Vue from 'vue';
//
//// Глобално регистриране на всички компоненти от дадена папка.
//// https://v2.vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components
//const registerGloballyComponentFolder = () => {
//    // Параметрите на require.context() трябва да бъдат литерали, не могат да бъдат променливи.
//    // По тази причина './global' не може да се подава като параметър.
//    // Пътят './global' е релативен спрямо папката на този файл, т.е. в случая води до 'src/component/global'.
//    const globalComponentFiles = require.context('./global', true, /\.vue$/iu);

//    for (const relativePath of globalComponentFiles.keys()) {
//        const fileName = relativePath.split('/').pop();
//        // На теория pop() може да върне undefined, но split() винаги връща поне един елемент.
//        if (fileName) {
//            const fileConfig = globalComponentFiles(relativePath);
//            // Look for the component options on `.default`, which will exist if the component was exported with "export default",
//            // otherwise fall back to module's root.
//            const componentConfig = fileConfig.default ?? fileConfig;
//            const [fileNameWoExt] = fileName.split('.');
//            Vue.component(fileNameWoExt, componentConfig);
//        }
//    }
//};
//
//export { registerGloballyComponentFolder };
