import { AxiosResponse } from 'axios';

import { NssiUploadRequestDto } from '@/model/Integration/Nssi/NssiUploadRequestDto';
import { NssiUploadResponseDto } from '@/model/Integration/Nssi/NssiUploadResponseDto';
import { localServerHttpService } from '@/service/Infrastructure/LocalServerHttpService';

export const localServerNssiService = {
    uploadSickSheet(sickSheetXml: string): Promise<AxiosResponse<NssiUploadResponseDto>> {
        const data = new NssiUploadRequestDto(sickSheetXml);
        return localServerHttpService.post('/nssi/upload', data);
    },

    validateSickSheet(sickSheetXml: string): Promise<AxiosResponse<NssiUploadResponseDto>> {
        const data = new NssiUploadRequestDto(sickSheetXml);
        return localServerHttpService.post('/nssi/validate', data);
    }
};
