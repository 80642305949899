import Vue from 'vue';

import { NotificationCreateModel } from '@/model/Notification/NotificationCreateModel';
import { NotificationModel } from '@/model/Notification/NotificationModel';

const notificationsKey: string = 'notifications';

const firstIsLarger = 1;
const firstIsSmaller = -1;

const compareNotifications = function (first: NotificationModel, second: NotificationModel) {
    return first.time > second.time ? firstIsLarger : second.time > first.time ? firstIsSmaller : 0;
};

class Notifications {
    private _items: NotificationModel[] = [];

    public displayedModalNotification: NotificationModel | null = null;

    public drawerIsVisible: boolean = false;
    public dialogIsVisible: boolean = false;

    // Сортирани по възходящ ред.
    public get sorted(): NotificationModel[] {
        // Преди .sort() се използва .slice() за да се създаде нов списък инак влиза в безкраен цикъл.
        return this._items.slice().sort(compareNotifications);
    }

    public get groupedByCategory(): Record<string, NotificationModel[]> {
        const grouped = this.sorted.reduce(
            (previous: Record<string, NotificationModel[]>, current: NotificationModel) => {
                previous[current.category] = [...(previous[current.category] || []), current];
                return previous;
            },
            {}
        );
        return grouped;
    }

    public get unreadCount() {
        return this._items.filter((notif) => !notif.isRead).length;
    }

    public markAllAsRead() {
        let hasChanges = false;
        for (const notification of this._items) {
            if (!notification.isRead) {
                notification.isRead = true;
                hasChanges = true;
            }
        }
        if (hasChanges) {
            this.beginSaveToLocalStorage();
        }
    }

    public add(notification: NotificationModel) {
        // Не допуска натрупване на една и съща нотификация в края на съхранявания списък.
        const last = this._items.length > 0 ? this._items[this._items.length - 1] : null;
        if (
            last === null ||
            notification.title !== last.title ||
            notification.text !== last.text ||
            notification.severity !== last.severity ||
            notification.category !== last.category
        ) {
            this._items.push(notification);
            this.beginSaveToLocalStorage();
        }
    }

    public remove(notification: NotificationModel) {
        const index = this._items.indexOf(notification);
        if (index >= 0) {
            Vue.delete(this._items, index);
            this.beginSaveToLocalStorage();
        }
    }

    public clear() {
        // За да сработи реактивността, трябва да се използва splice(), а не length.
        //this._items.length = 0;
        this._items.splice(0);
        localStorage.removeItem(notificationsKey);
    }

    public loadFromLocalStorage(): void {
        const json = localStorage.getItem(notificationsKey);
        if (json) {
            const untypedArray: NotificationModel[] = JSON.parse(json);
            this._items = untypedArray.map<NotificationModel>((untyped) => {
                const model = new NotificationModel(
                    new NotificationCreateModel(untyped.title, untyped.text, untyped.severity, untyped.category)
                );
                model.time = untyped.time;
                model.isRead = untyped.isRead;
                model.id = untyped.id;
                return model;
            });
        } else {
            this.clear();
        }
    }

    private beginSaveToLocalStorage(): Promise<void> {
        return new Promise((resolve) => {
            localStorage.setItem(notificationsKey, JSON.stringify(this._items));
            resolve();
        });
    }
}

export const notifications = Vue.observable(new Notifications());
