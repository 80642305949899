import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';

import { DoctorDropdownDto } from './DoctorDropdownDto';

export class DoctorEditDto extends DoctorDropdownDto {
    public pidTypeCode: PidTypeCode | null = null;
    public identifier: string = '';
    public phone: string = '';
    public email: string = '';
    public biographyHtml: string = '';
    public pictureContent: number[] | null = null;
    public photoSmall: string | null = '';
    public profilePictureIsDeleted: boolean = false;
    public errorMessage: string | null = null;
}
