<template>
    <div>
        <v-row>
            <v-col cols="6" md="3">
                <text-field v-model="referral6Dto.number" disabled label="талон за МЕ  №." />
            </v-col>
            <v-col cols="12" md="3">
                <date-field v-model="referral6Dto.issueDate" disabled label="дата:  " />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <text-field disabled label="регистрационен номер на лечебното заведение:" />
            </v-col>
            <v-col cols="3">
                <text-field disabled label="НЗОК номер на лечебното заведение: " />
            </v-col>
            <v-col cols="3">
                <text-field disabled label="УИН лекаря/ЛПК на лекар по дентална медицина:" />
            </v-col>
            <v-col cols="3">
                <text-field disabled label="Код на специалност: " />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <text-field disabled label="Специалист:" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <!--<v-text-field v-model="currentVisit.exam.ambulatorySheetNumber" disabled label="амб. лист №.:" />-->
            </v-col>
            <v-col cols="12" md="3">
                <text-field disabled label="дата: " />
            </v-col>
            <v-col cols="12" md="3">
                <text-field disabled label=" МКБ :" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <text-field disabled label="дата:" />
            </v-col>
            <v-col cols="6">
                <text-field disabled label="лекар/лекар по дентална медицина: " />
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { Referral6Dto } from '@/model/Exam/Referral/Referral6Dto';
    import { currentVisitCache } from '@/store/CurrentVisitCache';

    @Component
    export default class Referral6Section extends Vue {
        @Prop()
        private value!: number;

        @Prop({ type: Object })
        private referral6Dto!: Referral6Dto;

        private get currentVisit() {
            return currentVisitCache.value;
        }
    }
</script>
