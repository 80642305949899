<template>
    <dropdown
        :value="value"
        :items="immunizations"
        item-text="title"
        return-object
        required
        label="Избор на имунизация"
        @input="immunizationInput"
    >
    </dropdown>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { ImmunizationDto } from '@/model/Nomenclature/ImmunizationDto';
    import { PatientImmunizationCheckDto } from '@/model/Patient/Immunization/PatientImmunizationCheckDto';
    import { immunizationService } from '@/service/Nomenclature/ImmunizationService';
    import { patientImmunizationService } from '@/service/Patient/PatientImmunizationService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component
    export default class ImmunizationPicker extends Vue {
        @Prop()
        private value!: ImmunizationDto;

        @Prop()
        private documentId!: number;

        @Prop()
        private filterByNhis!: boolean;

        private immunizations: ImmunizationDto[] = [];

        private async immunizationInput(immunization: ImmunizationDto) {
            if (immunization) {
                await this.checkExistingImmunization(immunization.id, immunization.name);
            }
            this.$emit('input', immunization);
        }

        private async checkExistingImmunization(immunizationId: number, immunizationName: string) {
            const dto = new PatientImmunizationCheckDto(
                this.documentId,
                currentPatientCache.value.key.patientId,
                immunizationId,
                immunizationName
            );

            const result = await patientImmunizationService.checkForExisting(dto);

            if (result) {
                this.$notifier.showWarning('', result);
            }
        }

        private nationalProgramImmunizationCheck() {
            // Това е възрастта необходима, за да може да се имунизира пациент по национална програма.
            const oldEnough = 64;
            if ((currentPatientCache.value?.age ?? 0) < oldEnough) {
                arrayUtil.removeFirst(this.immunizations, (item) => item.rhiCode === '99');
            }
        }

        private async getImmunizations() {
            this.immunizations = await immunizationService.getActiveImmunizations(this.filterByNhis);
        }

        private async mounted() {
            await this.getImmunizations();
            this.nationalProgramImmunizationCheck();
        }

        @Watch('value')
        private onValueChanged() {
            // Ако се зареди отпаднала имунизация, тя липсва в списъка за избор и не се вижда - трябва да се добави.
            // Забележка: Във Vuetify3 текущата стойност се вижда, дори да я няма в списъка за избор.
            if (this.value && !this.immunizations.some((im) => im.id === this.value.id)) {
                this.immunizations.push(this.value);
            }
        }
    }
</script>
