<template>
    <div>
        <v-card>
            <v-card-title>Болнични листове за период</v-card-title>
            <v-card-actions>
                <v-row>
                    <v-col cols="3">
                        <btn v-if="selected && selected.length > 0" action="Send" @click="sendFiles()">Изпращане</btn>
                    </v-col>
                    <v-col cols="3">
                        <btn
                            v-if="uploadResult && uploadResult.length > 0"
                            action="Details"
                            @click="resultDialogIsVisible = true"
                        >
                            Резултат от изпращане
                        </btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <report-date-picker
                            v-model="filter"
                            :disabled="$loading.isVisible"
                            @input="getItemsPerPeriod"
                        />
                    </v-col>
                </v-row>
                <v-data-table
                    v-if="filteredItems"
                    v-model="selected"
                    :headers="headers"
                    :loading="$loading.isVisible"
                    :items="filteredItems"
                    class="elevation-1 mb-5"
                    no-data-text="Няма записи"
                    disable-pagination
                    hide-default-footer
                    show-select
                    sort-by="issueDate"
                >
                    <template #[`item.issueDate`]="{ item }">
                        <date-label v-model="item.issueDate" />
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="3">
                        <btn v-if="selected && selected.length > 0" action="Send" @click="sendFiles()">Изпращане</btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="resultDialogIsVisible">
            <v-data-table :headers="resultHeaders" :items="resultItems" hide-default-footer />
        </v-dialog>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DateLabel from '@/component/Date/DateLabel.vue';
    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { SickSheetStatusCode } from '@/enum/Exam/SickSheetStatusCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { SickSheetViewDto } from '@/model/Exam/SickSheet/SickSheetViewDto';
    import { NssiUploadResponseDto } from '@/model/Integration/Nssi/NssiUploadResponseDto';
    import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
    import { sickSheetService } from '@/service/Exam/SickSheetService';
    import { localServerNssiService } from '@/service/Integration/LocalServerNssiService';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';

    @Component({
        components: { ReportDatePicker, DateLabel }
    })
    export default class SickSheetGroupUpload extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private filter: ReportIntervalFilterDto = new ReportIntervalFilterDto();
        private items: SickSheetViewDto[] = [];
        private selected: SickSheetViewDto[] = [];
        private resultDialogIsVisible: boolean = false;
        private uploadResult: string[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Лист номер', value: 'number' },
                { text: 'Анулиран номер', value: 'canceledNumber' },
                {
                    text: 'Дата на издаване',
                    value: 'issueDate'
                },
                { text: 'Пациент - идентификатор', value: 'patientIdentifier' },
                { text: 'Пациент - имена', value: 'patientName' },
                {
                    text: 'Статус',
                    value: 'statusName'
                },
                {
                    text: 'Резултат от изпращането',
                    value: 'uploadResult'
                }
            ];
        }

        private get resultHeaders(): IDataTableHeader[] {
            return [{ text: 'Резултат от изпращане', value: 'item' }];
        }

        private get filteredItems() {
            if (this.items) {
                return this.items.map((item) => ({
                    ...item,
                    isSelectable:
                        item.statusCode !== SickSheetStatusCode.Active &&
                        item.statusCode !== SickSheetStatusCode.Canceled
                }));
            }
            return [];
        }

        private get resultItems() {
            if (this.uploadResult) {
                return this.uploadResult.map((item) => ({ item }));
            }
            return [];
        }

        private async sendFiles() {
            this.$loading.show();
            try {
                const xml = await sickSheetService.generateGroupSickSheet(this.selected);
                const uploadResponse = await this.upload(xml);
                await this.getUploadResult(uploadResponse);
                this.selected = [];
                await this.getItemsPerPeriod();
                this.resultDialogIsVisible = true;
            } finally {
                this.$loading.hide();
            }
        }

        private async upload(xml: string): Promise<NssiUploadResponseDto | null> {
            if (await localServerDiagnosticService.checkIsLocalServerActive()) {
                const uploadResponse = await localServerNssiService.uploadSickSheet(xml);
                if (uploadResponse?.data) {
                    return uploadResponse.data;
                }
            }
            return null;
        }

        private async getUploadResult(response: NssiUploadResponseDto | null) {
            if (response) {
                this.uploadResult = await sickSheetService.updateSickSheetStatus(this.practiceId, response);
            }
        }

        private async getItemsPerPeriod() {
            this.$loading.show();
            try {
                if (this.filter.startDate && this.filter.endDate) {
                    this.filter.practiceId = this.practiceId;
                    this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
                    this.items = await sickSheetService.getSickSheetsPerPeriod(this.filter);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            this.filter.fillCurrentMonth();
            await this.getItemsPerPeriod();
        }
    }
</script>
