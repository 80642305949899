import { NhisCertificateDriveDto } from '@/model/Nhis/CertificateDrive/NhisCertificateDriveDto';
import { NhisCertificateDriveExtractionCommand } from '@/model/Nhis/CertificateDrive/NhisCertificateDriveExtractionCommand';
import { NhisXmlRequestDto } from '@/model/Nhis/NhisXmlRequestDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { nhisService } from '@/service/Nhis/NhisService';
import { signXmlService } from '@/service/Report/SignXmlService';
import { loadingState } from '@/store/LoadingState';
import { nhisKey } from '@/store/NhisNhifAccessTokenCache';

const getOpenCertificateDriveXml = async function getOpenCertificateDriveXml(
    requestData: NhisXmlRequestDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/CreateOpenCetificateDrive`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getCloseCertificateDriveXml = async function getCloseCertificateDriveXml(
    requestData: NhisXmlRequestDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/CloseCertificateDrive`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getCertificateDriveExtractionXml = async function getCertificateDriveExtractionXml(
    requestData: NhisCertificateDriveExtractionCommand
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/CreateCertificateDriveExtraction`, requestData);
    console.log(response);
    return response?.data ?? '';
};
export const nhisCertificateDriveService = {
    async postOpenCertificateDrive(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getOpenCertificateDriveXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const certificateDriveResult = await nhisService.postXmlToNhisWithId<NhisCertificateDriveDto>(
                    'PostOpenCertificateDrive',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(certificateDriveResult?.response);
                if (certificateDriveResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${certificateDriveResult.error}`);
                } else {
                    if (certificateDriveResult?.warnings?.length > 0) {
                        nhisService.showWarnings(certificateDriveResult.warnings);
                    }
                    return certificateDriveResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postCloseCertificateDrive(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getCloseCertificateDriveXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const certificateDriveResult = await nhisService.postXmlToNhisWithId<NhisCertificateDriveDto>(
                    'PostCloseCertificateDrive',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(certificateDriveResult?.response);
                if (certificateDriveResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${certificateDriveResult.error}`);
                } else {
                    if (certificateDriveResult?.warnings?.length > 0) {
                        nhisService.showWarnings(certificateDriveResult.warnings);
                    }
                    return certificateDriveResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async extractCertificateDrive(requestData: NhisCertificateDriveExtractionCommand) {
        loadingState.show();
        try {
            const xmlString = await getCertificateDriveExtractionXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const certificateDriveResult = await nhisService.postXmlToNhisWithId<NhisCertificateDriveDto>(
                    'PostCertificateDriveExtraction',
                    signResponse.contents,
                    requestData.examId
                );
                console.log(certificateDriveResult?.response);
                if (certificateDriveResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${certificateDriveResult.error}`);
                } else {
                    if (certificateDriveResult?.warnings?.length > 0) {
                        nhisService.showWarnings(certificateDriveResult.warnings);
                    }
                    return `/Exam/CertificateDrive/${requestData.examId}`;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    }
};
