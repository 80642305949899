import { plainToInstance } from 'class-transformer';

import { CityDto } from '@/model/Nomenclature/CityDto';
import { RhifIdRhifAreaIdDto } from '@/model/Nomenclature/RhifIdRhifAreaIdDto';
import { IDropdownSearchService } from '@/service/Dropdown/IDropdownSearchService';
import { httpService } from '@/service/Infrastructure/HttpService';

const cityService: IDropdownSearchService<CityDto> = {
    async getItemsById(cityIds: number | number[]): Promise<CityDto[]> {
        const cityIdsText = typeof cityIds === 'number' ? cityIds : cityIds.join();
        const response = await httpService.get<CityDto[]>('/City/GetCitiesByIds', { params: { cityIds: cityIdsText } });
        return plainToInstance(CityDto, response.data);
    },

    async searchItems(filters: []): Promise<CityDto[]> {
        const response = await httpService.get<CityDto[]>('/City/SearchCities', { params: { filter: filters.at(0) } });
        return plainToInstance(CityDto, response.data);
    }
};

const getRhifData = {
    async getRhifDataByCityId(cityId: number): Promise<RhifIdRhifAreaIdDto> {
        const response = await httpService.get<RhifIdRhifAreaIdDto>('/City/GetRhifIdRhifAreaId', {
            params: {
                cityId
            }
        });
        return plainToInstance(RhifIdRhifAreaIdDto, response.data);
    }
};

const getPostCode = {
    async getPostCodeByCityId(cityId: number): Promise<string> {
        const response = await httpService.get<string>('/City/GetPostCode', {
            params: {
                cityId
            }
        });
        return response?.data;
    }
};

export { cityService, getPostCode, getRhifData };
