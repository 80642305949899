<template>
    <v-card>
        <main-title>НЗИС рецепти за лекар</main-title>
        <v-card-text class="mt-3">
            <v-row>
                <v-col md="3" cols="6">
                    <text-field v-model="pageNumber" type="number" label="Номер на страница" />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <btn action="Send" @click="fetchPrescriptionList">Зареди рецепти</btn>
        </v-card-actions>
        <v-card-text>
            <v-row>
                <v-col md="3" cols="6">
                    <text-field v-model="result.totalPrescriptions" label="Общ брой рецепти" readonly />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="result.totalPages" label="Общ брой страници" readonly />
                </v-col>
            </v-row>
        </v-card-text>
        <data-table
            v-if="result.prescriptions"
            :headers="headers"
            :items="result.prescriptions"
            title="Рецепти"
            disable-pagination
        >
            <template #rowActions="{ item }">
                <btn v-if="!item.alreadyExists" icon action="Fill" @click="fetchPrescription(item.nrn)" />
            </template>
        </data-table>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import Btn from '@/component/Common/Btn.vue';
    import DataTable from '@/component/Common/DataTable.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhisDoctorsPrescriptionDto } from '@/model/Nhis/Prescription/NhisDoctorsPrescriptionDto';
    import { nhisPrescriptionService } from '@/service/Nhis/NhisPrescriptionService';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { Btn, DataTable }
    })
    export default class NhisDoctorsPrescription extends Vue {
        private pageNumber: number = 1;
        private result: NhisDoctorsPrescriptionDto = new NhisDoctorsPrescriptionDto();

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'НРН:', value: 'nrn' },
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Статус:', value: 'status' },
                { text: 'Вид:', value: 'formType' },
                { text: 'Финансиране:', value: 'fund' },
                { text: 'Пациент:', value: 'patient' }
            ];
        }

        private async fetchPrescriptionList() {
            try {
                this.$loading.show();
                this.result = await nhisPrescriptionService.fetchDoctorsPrescription(this.pageNumber);
            } finally {
                this.$loading.hide();
            }
        }

        private async fetchPrescription(nrn: string) {
            await nhisPrescriptionService.fetchPrescription(nrn);
        }
    }
</script>

<style scoped></style>
