import { Type } from 'class-transformer';

import { PreviousGpDto } from './PreviousGpDto';

export class PreviousGpCreateResultDto {
    @Type(() => PreviousGpDto)
    public previousGp: PreviousGpDto = new PreviousGpDto();

    public isExisting: boolean = false;
    public errorMessage: string = '';
}
