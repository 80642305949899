<template>
    <div>
        <v-row>
            <v-col>
                <data-table :headers="headers" title="Бързо известие [Бл. МЗ - 300]" :items="items">
                    <template #actions>
                        <btn action="New" :to="`/Exam/QuickNotice/Create/${examId}`" />
                    </template>
                    <template #rowActions="{ item }">
                        <btn icon action="Edit" :to="`/Exam/QuickNotice/Edit/${item.id}`" />
                        <btn icon action="Print" :to="`/Print/Exam.QuickNotice/${item.id}`" />
                    </template>
                </data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { QuickNoticeViewDto } from '@/model/Exam/QuickNotice/QuickNoticeViewDto';
    import { quickNoticeService } from '@/service/Exam/QuickNoticeService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class QuickNoticeView extends Vue {
        @Prop()
        examId!: number;

        private items: QuickNoticeViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Номер:', value: 'number' },
                { text: 'Тип направление:', value: 'referralSubtype' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await quickNoticeService.getQuickNoticesByExamId(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
