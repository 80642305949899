import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';

export class ServiceOrderFinancialReportCommand extends ReportIntervalFilterDto {
    public createdByEmployeeSeqNumber: number | null = null;
    public creditToEmployeeSeqNumber: number | null = null;
    public paymentMethodCode: string | null = null;
    public isPaid: boolean | null = null;
    public tariffSeqNumber: number | null = null;
    public groupBy: string = 'none';
}
