<template>
    <v-form ref="documentForm" v-model="isValid" :disabled="!$allowWrite">
        <v-card>
            <v-card-title>Прикачване на файлове към посещение</v-card-title>
            <v-card-actions>
                <btn action="Back" :to="backRoute" />
                <btn action="Upload" @click="uploadFile" />
            </v-card-actions>
            <v-card-text>
                <file-input v-model="uploadItems" :visit-id="visitId" :files-total-max-size="100" />
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { FileUploadCommand } from '@/model/File/FileUploadCommand';
    import { externalFileService } from '@/service/File/ExternalFileService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    import FileInput from './FileInput.vue';

    @Component({
        components: { FileInput }
    })
    export default class VisitFileUpload extends Vue {
        @Prop({ required: true })
        private visitId!: number;

        private uploadItems: FileUploadCommand[] = [];
        private isValid: boolean = false;

        private async uploadFile() {
            this.$loading.show();
            try {
                (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

                if (this.isValid) {
                    await externalFileService.uploadFiles(this.uploadItems);
                    // Параметърът documentId само се проверява дали е truthy (във VisitMenu.vue), така че не трябва да бъде нула.
                    const documentId = 123;
                    eventBus.$emit(
                        'create-referral-event',
                        documentId,
                        'FileUpload',
                        'Прикачен документ',
                        this.uploadItems.length
                    );
                    currentPatientCache.value.hasFiles = true;
                    this.$router.push(this.backRoute);
                } else {
                    this.$notifier.showWarning(
                        this.$t('notifications.validationErrors').toString(),
                        this.$t('notifications.pleaseFixValidationErrors').toString()
                    );
                }
            } finally {
                this.$loading.hide();
            }
        }

        private get backRoute() {
            return `/Exam/FileUpload/${this.visitId}`;
        }
    }
</script>
