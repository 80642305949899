import { plainToInstance } from 'class-transformer';

import { ExamCreatedDto } from '@/model/Exam/ExamCreatedDto';
import { DispensaryActivitiesExamCommand } from '@/model/Patient/Dispensary/DispensaryActivitiesExamCommand';
import { PatientDispensaryDataDto } from '@/model/Patient/Dispensary/PatientDispensaryDataDto';
import { DispensaryRuleDto } from '@/model/Register/Dispensary/DispensaryRuleDto';
import { examService } from '@/service/Exam/ExamService';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientDispensaryVisitService = {
    async getPatientDispensaryRules(patientId: number, doctorEmployeeSeqNumber: number): Promise<DispensaryRuleDto[]> {
        const response = await httpService.get<DispensaryRuleDto[]>(
            `/PatientDispensaryVisit/GetPatientDispensaryRules`,
            {
                params: { patientId, doctorEmployeeSeqNumber }
            }
        );

        return plainToInstance(DispensaryRuleDto, response.data);
    },

    async getDispensaryActivities(
        patientId: number,
        seqNumber: number,
        examId: number | null
    ): Promise<PatientDispensaryDataDto> {
        const response = await httpService.get<PatientDispensaryDataDto>(
            `/PatientDispensaryVisit/GetDispensaryActivities`,
            { params: { patientId, seqNumber, examId } }
        );

        return plainToInstance(PatientDispensaryDataDto, response.data);
    },

    async getDispensaryActivitiesForExam(command: DispensaryActivitiesExamCommand): Promise<PatientDispensaryDataDto> {
        const response = await httpService.get<PatientDispensaryDataDto>(
            `/PatientDispensaryVisit/GetDispensaryActivitiesForExam`,
            {
                params: command
            }
        );
        return plainToInstance(PatientDispensaryDataDto, response.data);
    },

    async saveDispensaryVisit(patientData: PatientDispensaryDataDto): Promise<number> {
        const response = await httpService.post<ExamCreatedDto>(
            `/PatientDispensaryVisit/SaveDispensaryVisit`,
            patientData
        );
        const result = plainToInstance(ExamCreatedDto, response.data);
        examService.showExamCreatedWarnings(result);
        return result.examId;
    }
};
