import { Type } from 'class-transformer';

import { Report365DoctorTraineeRowDto } from '@/model/Report/Rhi/Report365/Report365DoctorTraineeRowDto';

export class Report365DoctorTraineeDto {
    public name: string = '';

    @Type(() => Report365DoctorTraineeRowDto)
    public doctorTrainees: Report365DoctorTraineeRowDto[] = [];
}
