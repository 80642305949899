<template>
    <v-row dense>
        <v-col md="2"><text-field v-model="value.specialty" disabled label="код спец." /></v-col>
        <v-col md="4"><text-field v-model="value.number" disabled label="напр. номер" /></v-col>
        <v-col md="3"><text-field v-model="value.medicalProcedureCode" disabled label="код ВСД" /></v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { AmbulatorySheetReferral3aDto } from '@/model/Exam/AmbulatorySheetReferral3aDto';

    @Component
    export default class AmbulatorySheetReferral3aCard extends Vue {
        // Този компонент може да получи undefined v-model. В този случай се bind-ва default стойността, която е празен обект.
        @Prop({ default: () => new AmbulatorySheetReferral3aDto() })
        private value!: AmbulatorySheetReferral3aDto;
    }
</script>
