import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { DealerContactDto } from '@/model/Licensing/DealerContactDto';
import { DistrictAndMunicipalityDto } from '@/model/Practice/DistrictAndMunicipalityDto';
import { PracticeEditDto } from '@/model/Practice/PracticeEditDto';
import { PracticeSelectDto } from '@/model/Practice/PracticeSelectDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const practiceService = {
    getPractice(practiceId: number): Promise<AxiosResponse<PracticeEditDto>> {
        return httpService.get(`Practice/GetPractice/${practiceId}`);
    },

    async getPracticeManagerIdentifier(practiceId: number): Promise<string> {
        const response = await httpService.get<string>('Practice/GetPracticeManagerIdentifier', {
            params: { practiceId }
        });
        return response.data;
    },

    getPracticeDealerContact(practiceId: number): Promise<AxiosResponse<DealerContactDto>> {
        return httpService.get('Practice/GetPracticeDealerContact', {
            params: { practiceId }
        });
    },

    async getDistrictAndMunicipalityFromPracticeNumber(practiceNumber: string): Promise<DistrictAndMunicipalityDto> {
        const response = await httpService.get<DistrictAndMunicipalityDto>(
            'Practice/GetDistrictAndMunicipalityFromPracticeNumber',
            { params: { practiceNumber } }
        );
        return response.data;
    },

    async getPracticeByNumber(practiceNumber: string): Promise<PracticeSelectDto> {
        const response = await httpService.get<PracticeSelectDto>('Practice/GetPracticeByNumber', {
            params: { practiceNumber }
        });
        return plainToInstance(PracticeSelectDto, response?.data);
    },

    async createOrUpdatePractice(practice: PracticeSelectDto): Promise<number> {
        const response = await httpService.post<number>('Practice/CreateOrUpdatePractice', practice);
        return response.data;
    },

    async updatePractice(data: PracticeEditDto): Promise<string> {
        const response = await httpService.put<string>('Practice/UpdatePractice', data);
        return response.data;
    }
};
