export class ChildHealthCardDto {
    public id: number = 0;
    public issueDate: Date | null = null;
    public number: number | null = null;
    public examId: number = 0;
    public schoolName: string | null = null;
    public schoolAddress: string | null = null;
    public motherName: string | null = null;
    public motherAddress: string | null = null;
    public motherPhone: string | null = null;
    public motherWorkplace: string | null = null;
    public motherWorkPhone: string | null = null;
    public fatherName: string | null = null;
    public fatherAddress: string | null = null;
    public fatherPhone: string | null = null;
    public fatherWorkplace: string | null = null;
    public fatherWorkPhone: string | null = null;
    public familySymptoms: string | null = null;
    public pastDisease: string | null = null;
    public bloodType: string | null = null;
    public rh: string | null = null;
    public alergy: string | null = null;
    public examDate: Date | null = null;
    public diagnose: string | null = null;
    public practice: string | null = null;
    public treatmentResult: string | null = null;
    public dispensary: string | null = null;
    public otherActivity: string | null = null;
    public immunization: string | null = null;
    public preventiveExam: string | null = null;
    public height: number | null = null;
    public weight: number | null = null;
    public bodySize: string = '';
    public muscleStrength: string | null = null;
    public run: string | null = null;
    public jump: string | null = null;
    public throw: string | null = null;
    public crouch: string | null = null;
    public examSummary: string | null = null;
    public paraclinicalTest: string | null = null;
    public mentalHealth: string | null = null;
    public newFoundDisease: string | null = null;
    public badHabit: string | null = null;
    public workAvoidBadHabit: string | null = null;
}
