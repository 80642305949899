import { Type } from 'class-transformer';

import { Annex56CausesOfMorbidityMortalityTotalRowDto } from '@/model/Report/Rhi/Annex56/Annex56CausesOfMorbidityMortalityTotalRowDto';

export class Annex56CausesOfMorbidityMortalityTotalDto {
    public name: string = '';

    @Type(() => Annex56CausesOfMorbidityMortalityTotalRowDto)
    public causesOfMorbiditiesMortalitiesTotal: Annex56CausesOfMorbidityMortalityTotalRowDto[] = [];
}
