<template>
    <div>
        <h3>ОЦЕНКА НА 10-ГОДИШЕН РИСК ОТ РАЗВИТИЕ НА ЗАХАРЕН ДИАБЕТ ТИП 2 (FINDRISK)</h3>

        <v-row>
            <v-col v-if="value.findRisk < 7" cols="8"><label>Нисък (1 на 100 ще развие диабет)</label></v-col>

            <v-col v-if="value.findRisk >= 7 && value.findRisk <= 11" cols="8">
                <label>Леко повишен (1 на 25 ще развие диабет)</label>
            </v-col>
            <v-col v-if="value.findRisk >= 12 && value.findRisk <= 14" cols="8">
                <label>Умерен (1 на 6 ще развие диабет)</label>
            </v-col>
            <v-col v-if="value.findRisk >= 15 && value.findRisk <= 20" cols="8">
                <label>Висок (1 на 3 ще развие диабет)</label>
            </v-col>
            <v-col v-if="value.findRisk > 20" cols="8"><label>Много висок (1 на 2 ще развие диабет)</label></v-col>
            <v-col cols="4"><text-field v-model="value.findRisk" disabled label="Резултат" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { RiskAssessmentCardDto } from '@/model/Exam/Gp/RiskAssessmentCard/RiskAssessmentCardDto';

    @Component
    export default class FindRiskCard extends Vue {
        @Prop({ type: Object })
        private value!: RiskAssessmentCardDto;
    }
</script>
