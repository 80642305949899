<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Медицинско направление за комплексно дисп. наблюдение" :items="items">
                <template #actions>
                    <btn action="New" :to="`/Exam/ReferralDispObserv/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/ReferralDispObserv/Edit/${item.id}`" />
                    <btn icon action="Print" :to="`/Print/Exam.Referral.ReferralDispensaryObservation/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ReferralDispensaryObservationViewDto } from '@/model/Exam/Referral/ReferralDispensaryObservationViewDto';
    import { referralDispensaryObservationService } from '@/service/Exam/Referral/ReferralDispensaryObservationService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class ReferralDispensaryObservationView extends Vue {
        @Prop()
        examId!: number;

        private items: ReferralDispensaryObservationViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Номер:', value: 'number' },
                { text: 'Тип направление:', value: 'referralSubtype' },
                { text: 'МКБ 1:', value: 'diagnosis1Code' },
                { text: 'МКБ 2:', value: 'diagnosis2Code' },
                { text: 'МКБ 3:', value: 'diagnosis3Code' },
                { text: 'Приложени мед. документи:', value: 'attachedDocs' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await referralDispensaryObservationService.getReferralsByExamId(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
