<template>
    <div>
        <v-card>
            <v-card-title>Демо на MultiSelectDropdown с масив</v-card-title>
            <v-card-text>
                <multi-select-dropdown v-model="selectedIds1" :items="items" />
                {{ selectedIds1 }}
                <btn action="Secondary" @click="selectedIds1 = [1, 3]">Set</btn>
                <btn action="Secondary" @click="items = items.reverse()">Reverse</btn>
                <btn action="Secondary" @click="items = [{ id: 1, name: 'A' }]">A only</btn>
                <btn action="Secondary" @click="items = [{ id: 2, name: 'B' }]">B only</btn>
            </v-card-text>
        </v-card>
        <v-card>
            <v-card-title>Демо на MultiSelectDropdown с търсене</v-card-title>
            <v-card-text>
                <multi-select-dropdown-search v-model="selectedIds2" :service="cityService" />
                {{ selectedIds2 }}
                <btn action="Secondary" @click="selectedIds2 = [8, 10]">Set</btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import MultiSelectDropdown from '@/component/Dropdown/MultiSelectDropdown.vue';
    import MultiSelectDropdownSearch from '@/component/Dropdown/MultiSelectDropdownSearch.vue';
    import { cityService } from '@/service/Nomenclature/CityService';

    interface IIdName {
        id: number;
        name: string;
    }
    const id2 = 2;
    const id4 = 4;

    @Component({
        components: {
            MultiSelectDropdown,
            MultiSelectDropdownSearch
        }
    })
    export default class MultiSelectDropdownTest extends Vue {
        private selectedIds1: number[] = [id2, id4];
        private selectedIds2: number[] = [id2, id4];
        private items: IIdName[] = [
            { id: 1, name: 'A' },
            { id: 2, name: 'B' },
            { id: 3, name: 'C' },
            { id: 4, name: 'D' }
        ];

        private get cityService() {
            return cityService;
        }
    }
</script>
