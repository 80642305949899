<template>
    <div class="py-0">
        <v-autocomplete
            :value="value"
            :items="items"
            :item-text="itemText"
            :item-value="itemValue"
            :return-object="returnObject"
            :label="label"
            :hint="hint"
            :persistent-hint="!!hint"
            :no-data-text="$t('dropdown.noDataFound')"
            :class="{ required }"
            :rules="rules.concat(required ? [$validator.required] : [])"
            :clearable="clearable && !readonly"
            :name="'dropdown' + _uid"
            :readonly="readonly"
            :disabled="disabled"
            dense
            :filter="customFilter"
            @input="$emit('input', ...arguments)"
            @click:clear="$emit('clear', ...arguments)"
            @blur="$emit('blur')"
        >
            <!--
                Виж по-горе ^^^
                При v-autocomplete няма разлика между input и change събитията - винаги се emit-ват и двете.
                Този wrapper компонент прихваща само input събитието и го forward-ва нагоре,
                така че компонентът да може да се bind-ва пълноценно чрез v-model.
            -->
        </v-autocomplete>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import type { DropdownValue } from '@/model/Common/Dropdown/DropdownValue';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class Dropdown<ItemType> extends Vue {
        @Prop()
        private value!: DropdownValue<ItemType>;

        @Prop({ required: true })
        private items!: ItemType[];

        @Prop({ default: 'id' })
        private itemValue!: string;

        @Prop({ default: 'name' })
        private itemText!: string | typeof Function;

        @Prop()
        private returnObject!: boolean;

        @Prop()
        private label!: string;

        @Prop()
        private hint!: string;

        @Prop()
        private required!: boolean;

        @Prop()
        private clearable!: boolean;

        @Prop()
        private readonly!: boolean;

        @Prop()
        private disabled!: boolean;

        @Prop({ default: () => [] })
        private rules!: [];

        @Prop()
        private isCyrillicToLatin!: boolean;

        private customFilter(item: unknown, queryText: string, itemText: string) {
            if (this.isCyrillicToLatin && queryText) {
                const exactLatin = formatters.cyrillicToLatin(queryText) ?? '';
                const upperLatin = formatters.cyrillicToLatin(queryText.toLocaleUpperCase()) ?? '';
                return itemText.includes(exactLatin) || itemText.toLocaleUpperCase().includes(upperLatin);
            }

            return itemText.toUpperCase().includes(queryText.toUpperCase());
        }
    }
</script>
