import { AddressDto } from '@/model/Common/AddressDto';
import { OfficialNoticeExamViewDto } from '@/model/Exam/OfficialNotice/OfficialNoticeExamViewDto';
import { OfficialNoticeImmunizationViewDto } from '@/model/Exam/OfficialNotice/OfficialNoticeImmunizationViewDto';

export class OfficialNoticeDto {
    public id: number = 0;
    public examId: number = 0;
    public number: number = 0;
    public issueDate: Date = new Date();
    public familyMemberFirstName: string = '';
    public familyMemberMiddleName: string = '';
    public familyMemberLastName: string = '';
    public familyMemberIdentifier: string | null = null;
    public patientAddress: AddressDto = new AddressDto();
    public immunizations: OfficialNoticeImmunizationViewDto[] = [];
    public exams: OfficialNoticeExamViewDto[] = [];
}
