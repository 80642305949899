<template>
    <data-table :headers="headers" title="Е-Медицинско за шофьор" :items="items">
        <template #actions>
            <btn action="New" :to="`/Exam/ECertificateDrive/Create/${examId}`" />
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/Exam/ECertificateDrive/Edit/${item.id}`" />
            <btn icon action="Print" :to="`/Print/Exam.Certificate.CertificateDrive/${item.id}`" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { CertificateDriveNhisViewDto } from '@/model/Exam/Certificate/CertificateDriveNhisViewDto';
    import { certificateDriveService } from '@/service/Exam/Certificate/CertificateDriveService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class CertificateDriveNhisView extends Vue {
        @Prop()
        examId!: number;

        private items: CertificateDriveNhisViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'НРН:', value: 'nrn' },
                { text: 'Категория:', value: 'licenseCategory' },
                { text: 'Статус:', value: 'status' },
                { text: 'Дата на изпращане:', value: 'statusTime' }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await certificateDriveService.getCertificatesNhisByExamId(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
