<template>
    <v-row justify="center">
        <tile-link v-for="link of links" :key="link.text" :icon="link.icon" :to="link.routePath" class="mx-3 my-5">
            {{ link.text }}
        </tile-link>
    </v-row>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import TileLink from '@/component/Common/TileLink.vue';
    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { userContextCache } from '@/store/User/UserContextCache';

    interface ILinkModel {
        text: string;
        icon: string;
        routePath: string;
    }

    @Component({
        components: { TileLink }
    })
    export default class ReceptionDashboard extends Vue {
        private get links(): ILinkModel[] {
            const menuItems: ILinkModel[] = [];

            const isSpecialist = userContextCache.current?.specialtyCode !== SpecialtyCode.Gp;

            menuItems.push(
                { text: 'Нова услуга', icon: 'mdi-face-agent', routePath: `/Reception/Service/Create` },
                {
                    text: 'Услуги',
                    icon: 'mdi-view-list-outline',
                    routePath: `/Reception/Service/List/${userContextCache.currentPracticeId}`
                },
                { text: 'Заявка за преглед', icon: 'mdi-stethoscope', routePath: 'ExamScheduleNew' }
                // { text: 'Лаборатория', icon: 'mdi-flask', routePath: '/Reception/Laboratory' },
                // { text: 'COVID Заявка', icon: 'mdi-virus', routePath: '/Reception/Covid/Create' },
                // {
                //     text: 'Амбулаторна процедура',
                //     icon: 'mdi-account-injury-outline',
                //     routePath: '/Reception/AmbulatoryProcedure/Create'
                // },
            );
            // Скрива се за ОПЛ
            if (isSpecialist) {
                menuItems.push({
                    text: 'Входящо направление',
                    icon: 'mdi-clipboard-list-outline',
                    routePath: '/IncomingReferralDownload'
                });
            }
            menuItems.push(
                {
                    text: 'Справка продажби-детайлна',
                    icon: 'mdi-chart-bar',
                    routePath: `/Reception/Report/SalesDetailed/${userContextCache.currentPracticeId}`
                },
                {
                    text: 'Справка продажби-обобщена',
                    icon: 'mdi-chart-bar',
                    routePath: `/Reception/Report/Sales/${userContextCache.currentPracticeId}`
                },
                // { text: 'Дневен отчет', icon: 'mdi-chart-timeline-variant', routePath: '/Reception/Report/Daily' },
                { text: 'Фискални у-ва', icon: 'mdi-printer-check', routePath: '/Reception/Fiscal' }
            );

            return menuItems;
        }
    }
</script>
