import { Log, User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

import { config } from '@/Config';
import { SigninProgressModel } from '@/model/Infrastructure/SigninProgressModel';

const { spaBaseUrlAbsolute } = config;

const oidcRoute = {
    postLogout: '/PostLogout',
    // Не се използва при вход през този проект. Само на началната страница на IdentityServer има линк към /SigninRedirect.
    signinRedirect: '/SigninRedirect',
    signinRedirectCallback: '/SigninRedirectCallback',
    unauthorized: '/Unauthorized'
};

const userManagerSettings: UserManagerSettings = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: config.identityServerBaseUrl,

    // Полетата в интерфейса UserManagerSettings са именувани с подчертавки - защо предупреждава за външен код?!
    /* eslint-disable camelcase */
    client_id: 'HippocratesMainSpaClient',
    scope: 'openid email profile role HippocratesMainApiScope HippocratesPrintApiScope',

    // Следните route-ове са изрично разрешени в appsettings.json на IdentityServer, в секции "RedirectUris" и "PostLogoutRedirectUris".
    redirect_uri: `${spaBaseUrlAbsolute}${oidcRoute.signinRedirectCallback.slice(1)}`,
    // Води към статична html страница - виж коментарите в нея. Така се избягва повторно зареждане на целия SPA в невидим iframe.
    silent_redirect_uri: `${spaBaseUrlAbsolute}SigninSilentCallback.html`,
    post_logout_redirect_uri: `${spaBaseUrlAbsolute}${oidcRoute.postLogout.slice(1)}`,
    /* eslint-enable camelcase */

    // Не изпълнява signinSilent() автоматично, когато наближи време access token-ът да изтече.
    // Вместо това в addAccessTokenExpiring() ръчно се изпълнява подобрен вариант на signinSilent().
    automaticSilentRenew: false,
    // Зарежда името, имейла и ролите на потребителя с допълнителна заявка към /userinfo endpoint-а на IdentityServer.
    loadUserInfo: true,
    // Осигурява single signout. През 2 секунди следи session cookie-то от IdentityServer и изпълнява изход, когато то изчезне.
    monitorSession: true

    // Колко секунди преди access token-ът да изтече да бъде поискан нов. По подразбиране е 60 секунди.
    // Препоръчителни насторйки на IdentityServer при тестване на подновяването на сесия:
    // "CookieLifespan": "0.00:03:00", "AccessTokenLifetime": 90. Така сесията ще се подновява през 30 секунди.
    //accessTokenExpiringNotificationTimeInSeconds: 60
};

// По подразбиране oidc-client-ts не логва в конзолата.
Log.setLogger(console);
// По подразбиране е ниво INFO, но логовете стават прекалено много, затова се ползва ниво WARN.
Log.setLevel(Log.WARN);

const userManager = new UserManager(userManagerSettings);

// Кеш на последно върнатия от userManager обект.
let lastKnownUser: User | null = null;

const signinProgress = new SigninProgressModel();

const emptyUserFunction = (user: User) =>
    console.log('Тази празна функция се подменя в StateRestorer.ts. Ако четете това, нещо не е наред.', user);

const asReturnUrl = (state: unknown) => (state && typeof state === 'string' ? state : '/');

// След посещение на служебен route, например /unauthorized, не трябва да се връща обратно на него.
const signinRedirectSafe = (returnUrl: string) => {
    if (Object.values(oidcRoute).includes(returnUrl)) {
        return userManager.signinRedirect();
    }
    return userManager.signinRedirect({ state: returnUrl });
};

// Обект с публичните функции и събития. Под него е private кодът.
const oidcClient = {
    onInitialSignin: emptyUserFunction,
    onSigninSilent: emptyUserFunction,
    onReturnUrlNeeded: () => '',

    // Зарежда последно използвания access token от local storage.
    async loadUserFromLocalStorage() {
        try {
            // Функцията getUser() по подразбиране чете от session storage или local storage, което е синхронна операция,
            // но е направена асинхронна, защото на теория би могла да чете от друга база данни, например indexedDB.
            // Това усложнява началното зареждане на SPA и достъпа до user-а в събитията на самия userManager, затова има и кеш.
            const user = await userManager.getUser();
            lastKnownUser = user;
            if (user) {
                console.log(`Зареден потребител ${user.profile.name} от local storage.`);
            } else {
                // UserManager си казва, ако не намери нищо в local storage.
            }
            return user;
        } catch {
            // Ако например json-ът в local storage е счупен и exception-ът не се прихване, SPA не се зарежда изобщо.
            // UserManager ще си логне грешката.
            return null;
        }
    },

    // Пренасочва към страницата за вход на IdentityServer, откъдето се връща обратно в този SPA, по възможност на същия route.
    async signinRedirectPostLogout() {
        signinProgress.showSigninRedirect();
        // Декодира returnUrl-а, подаден в signoutRedirect(). За целта ползва параметъра /PostLogout?state=идентификатор.
        const signoutResponse = await userManager.signoutRedirectCallback();
        return signinRedirectSafe(asReturnUrl(signoutResponse.userState));
    },

    // Пренасочва към страницата за вход на IdentityServer, откъдето се връща обратно в този SPA, по възможност на същия route.
    signinRedirect() {
        signinProgress.showSigninRedirect();
        // За returnUrl се подава текущият route.
        return signinRedirectSafe(this.onReturnUrlNeeded());
    },

    // След успешен вход връща потребителя на адреса, който е заявил първоначално.
    async signinRedirectCallback() {
        try {
            const user = await userManager.signinRedirectCallback();
            return asReturnUrl(user.state);
        } catch (error) {
            console.log(error);
            return oidcRoute.unauthorized;
        }
    },

    // Създава невидим iframe и извиква /authorize endpoint-а на IdentityServer.
    // Той redirect-ва обратно към това приложение, вътре в iframe-а, към статичен файл /SigninSilentCallback/index.html.
    // В html файла се изивква signinSilentCallback(), което кара parent прозореца да поднови сесията, като извика
    // /token + /userinfo endpoint-ите на IdentityServer или да прекрати сесията, като извика /endsession endpoint-а.
    // При успешно подновяване се вдига събитието UserLoaded в parent прозореца, т.е. в този код.
    // Тук са обяснени някои процеси около signinSilent, signinSilentCallback, addAccessTokenExpiring и automaticSilentRenew:
    // https://stackoverflow.com/questions/50416649/no-user-in-signinsilentcallback-using-identityserver-and-oidc-client-of-javascri
    // През цялото време флагът isExecutingSigninSilent е вдигнат.
    async signinSilent() {
        if (!signinProgress.isExecutingSigninSilent) {
            signinProgress.beginSigninSilent();
            try {
                await userManager.signinSilent();
            } catch (error) {
                if ((error as Error).message === 'Frame window timed out') {
                    // Ако IdentityServer е спрян, след известно време се появява грешка 'Frame window timed out'.
                    // Няма смисъл да се правят още обръщения към IdentityServer - не се прави опит за изход.
                    console.log('IdentityServer не отговаря. Сесията не е подновена, нито прекратена.');
                } else {
                    // Самият userManager изписва конкретната грешка, обикновено(винаги?) 'login_required'.
                    // Някои възможни причини: изтекло auth cookie към IdentityServer; блокиран потребител.
                    console.log('Неуспешно подновяване на сесията. Следва изход от системата...');
                    this.signoutRedirect();
                }
            } finally {
                signinProgress.endSigninSilent();
            }
        } else {
            console.log('Предотвратен опит за няколко едновременни подновявания на сесията.');
        }
    },

    // Пренасочва към точката за изход на IdentityServer. Подава текущия uri, за да се зареди той след повторен вход.
    signoutRedirect() {
        // За returnUrl се подава текущият route.
        const returnUrl = this.onReturnUrlNeeded();
        // Ако тук се подаде returnUrl, след това /PostLogout се извиква с параметър ?state=идентификатор.
        // По този идентификатор signoutRedirectCallback() декодира оригиналния returnUrl, подаден тук.
        return returnUrl ? userManager.signoutRedirect({ state: returnUrl }) : userManager.signoutRedirect();
    }
};

// Този handler се изпълнява, когато се извикат /token + /userinfo endpoint-ите на IdentityServer.
// Те се извикват в два случая - след вход и след подновяване на сесията. Във втория случай не трябва да се зареджа нищо за потребителя.
userManager.events.addUserLoaded((user) => {
    lastKnownUser = user;
    const { name } = user.profile;
    if (signinProgress.isExecutingSigninSilent) {
        console.log(`Сесията за потребител ${name} е подновена.`);
        oidcClient.onSigninSilent(user);
    } else {
        console.log(`Зареден потребител ${name} от сървъра.`);
        oidcClient.onInitialSignin(user);
    }
});

userManager.events.addAccessTokenExpiring(() => {
    // Тук не може да се използва getUser(), защото предизвиква повторно вдигане на този event.
    //const user = await userManager.getUser();
    const when = lastKnownUser
        ? `след ${lastKnownUser?.expires_in}`
        : `до ${userManager.settings.accessTokenExpiringNotificationTimeInSeconds}`;
    console.log(`Сесията ще изтече ${when} секунди. Следва подновяване...`);
    oidcClient.signinSilent();
});

userManager.events.addAccessTokenExpired(() => {
    console.log('Сесията е изтекла. Следва опит за подновяване...');
    oidcClient.signinSilent();
});

userManager.events.addUserSignedOut(() => {
    console.log('Известие за изход от IdentityServer. Следва изход от системата...');
    oidcClient.signoutRedirect();
});

// Това никога не се случва.
userManager.events.addUserSignedIn(() => console.log('Известие за вход.'));

// Не е ясно кога се случва. Не се случва при изтекло auth cookie или блокиран потребител, нито при спрян IdentityServer,
// независимо дали в oidcClient.signinSilent() има catch или не.
userManager.events.addSilentRenewError((error) => console.error('Грешка при подновяване на сесията:', error));

userManager.events.addUserSessionChanged(() => console.error('Настъпи промяна в сесията.'));

userManager.events.addUserUnloaded(() => console.log('Сесията е прекратена.'));

export { oidcClient, oidcRoute, signinProgress };
