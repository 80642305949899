import { plainToInstance } from 'class-transformer';

import { QuickAddLabTestDto } from '@/model/Practice/Service/QuickAddLabTestDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const quickAddLabTestService = {
    async getLabTests(practiceId: number): Promise<QuickAddLabTestDto> {
        const response = await httpService.get<QuickAddLabTestDto>('QuickAddLabTest/GetLabTests', {
            params: { practiceId }
        });

        return plainToInstance(QuickAddLabTestDto, response.data);
    },

    async save(dto: QuickAddLabTestDto): Promise<string> {
        const response = await httpService.post<string>('QuickAddLabTest/Save', dto);
        return response.data;
    }
};
