import { RhiReportKey } from '@/model/Report/Rhi/RhiReportKey';

export class RhiReportRowDto extends RhiReportKey {
    public startDate: Date = new Date();
    public endDate: Date = new Date();

    constructor(practiceId: number = 0, doctorEmployeeSeqNumber: number = 0, seqNumber: number = 0) {
        super(practiceId, doctorEmployeeSeqNumber, seqNumber);
    }
}
