import { AxiosResponse } from 'axios';

import { DeathReasonDto } from '@/model/Nomenclature/DeathReasonDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const deathReasonService = {
    getDeathReasons(): Promise<AxiosResponse<DeathReasonDto[]>> {
        return httpService.get('/DeathReason/GetDeathReasons');
    }
};
