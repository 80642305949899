import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';

export class ExamScheduleDto {
    public practiceId: number = 0;
    public id: number = 0;
    public patientId: number | null = null;
    public doctorId: number | null = null;
    public notes: string | null = null;
    public specialtyCode: SpecialtyCode | null = null;
    public workTimeVisitReasonId: number | null = null;
    public examDateTime: Date | null = null;
    public fundCode: string | null = null;
}
