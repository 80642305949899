<template>
    <v-row>
        <v-dialog v-model="value.dialogIsVisible" persistent max-width="50%">
            <v-card>
                <v-card-title>Бланки, които да бъдат копирани</v-card-title>
                <v-card-text class="font-weight-bold body-1 mt-2">
                    <v-checkbox v-model="value.copyFreePrescriptions" label="Рецепти по НЗОК"></v-checkbox>
                    <v-checkbox v-model="value.copyGreenPrescriptions" label="Зелени рецепти"></v-checkbox>
                    <v-checkbox v-model="value.copyYellowPrescriptions" label="Жълти рецепти"></v-checkbox>
                    <v-checkbox v-model="value.copyWhitePrescriptions" label="Бели рецепти"></v-checkbox>
                    <v-checkbox v-model="markAll" label="Всички" @click="allPrescriptions"></v-checkbox>
                </v-card-text>
                <v-card-actions>
                    <btn action="Next" @click="close" />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { AmbulatorySheetCopyDialogModel } from '@/model/Exam/AmbulatorySheetCopyDialogModel';

    @Component
    export default class AmbulatorySheetCopyDialog extends Vue {
        @Prop({ required: true })
        private value!: AmbulatorySheetCopyDialogModel;

        private markAll: boolean = false;

        private allPrescriptions() {
            if (this.markAll) {
                this.value.copyFreePrescriptions = true;
                this.value.copyGreenPrescriptions = true;
                this.value.copyYellowPrescriptions = true;
                this.value.copyWhitePrescriptions = true;
            } else {
                this.value.copyFreePrescriptions = false;
                this.value.copyGreenPrescriptions = false;
                this.value.copyYellowPrescriptions = false;
                this.value.copyWhitePrescriptions = false;
            }
        }

        private close() {
            this.value.dialogIsVisible = false;
            this.$emit('closed');
        }
    }
</script>
