<template>
    <v-form ref="form" v-model="isFormValid" :disabled="!$allowWrite">
        <v-card>
            <patient-version-picker
                v-if="id"
                v-model="selectedVersion"
                :versions="versions"
                @input="navigateToSelectedVersion"
            />
            <v-card-text v-if="!hasPreviousGp">
                <dropdown-search
                    item-text="text"
                    :service="gpSearchService"
                    label="Търсене на личен лекар в тази система"
                    return-object
                    @input="onPreviousGpSelected"
                />
            </v-card-text>
            <v-card-actions>
                <v-row align="center">
                    <v-col>
                        <btn action="Refresh" @click="getCurrentGpCaptcha">Проверка на личен лекар в НЗОК</btn>
                    </v-col>
                    <v-col v-if="currentGp.captchaImgString" cols="auto">
                        <v-img max-width="200" :src="currentGp.captchaImgString" />
                    </v-col>
                    <v-col v-if="currentGp.captchaImgString">
                        <text-field v-model="currentGp.captchaInput" label="Въведете изписания код" />
                    </v-col>
                    <v-col v-if="currentGp.captchaImgString" cols="auto">
                        <btn action="Search" @click="getCurrentGp" />
                    </v-col>
                    <v-col>
                        <alert class="mb-0" :value="Boolean(nhifErrorMessage)" type="warning">
                            {{ nhifErrorMessage }}
                        </alert>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-card-title>Предишен лекар</v-card-title>
            <v-card-text>
                <v-row>
                    <!--
                        Дори в базата да няма ЕГН, миграцията записва PidTypeCode = 'Egn' вместо NULL (което е грешно, но факт).
                        По тази причина двете полета се заключват само ако са попълнени и двете,
                        а ЕГН-то не е задължително, дори ако има избран PidTypeCode.
                    -->
                    <v-col cols="12" md="1">
                        <PidTypePicker v-model="model.doctor.pidTypeCode" :disabled="hasPreviousGpWithIdentifier" />
                    </v-col>
                    <v-col cols="12" md="2">
                        <text-field
                            v-model.trim="model.doctor.identifier"
                            :disabled="hasPreviousGpWithIdentifier"
                            label="Идентификатор"
                            :rules="
                                model.doctor.identifier
                                    ? [$validator.identifier(model.doctor.pidTypeCode, model.doctor.identifier)]
                                    : []
                            "
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model="model.doctor.name"
                            :disabled="hasPreviousGpWith(initialModel.doctor.name)"
                            required
                            label="Име"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field
                            v-model="model.doctor.uin"
                            :disabled="hasPreviousGp"
                            required
                            :rules="[$validator.uin]"
                            label="УИН"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model="model.doctor.phone"
                            :disabled="hasPreviousGpWith(initialModel.doctor.phone)"
                            label="Телефон"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model="model.doctor.email"
                            :disabled="hasPreviousGpWith(initialModel.doctor.email)"
                            label="E-mail"
                            :rules="[$validator.email]"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-title>Информация за практиката</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12">
                        <text-field
                            v-model="model.practiceName"
                            :disabled="hasPreviousGpWith(initialModel.practiceName)"
                            label="Наименование на лечебното заведение"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <practice-number
                            v-model="model.practiceNumber"
                            :disabled="hasPreviousGp"
                            required
                            label="Рег. номер"
                        ></practice-number>
                    </v-col>
                    <v-col cols="12" md="3">
                        <dropdown
                            v-model="model.lookupRhifId"
                            :disabled="hasPreviousGpWith(initialModel.rhifAreaId)"
                            :items="rhifs"
                            no-data-text="Няма данни"
                            label="РЗОК"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <dropdown
                            v-model="model.rhifAreaId"
                            :disabled="hasPreviousGpWith(initialModel.rhifAreaId)"
                            :items="rhifAreas"
                            no-data-text="Няма данни"
                            label="Здравен район"
                        />
                    </v-col>
                    <!-- Приемаме, че ако практиката не е клиент е безопасно да редактираме адреса. -->
                    <v-col cols="12" md="4">
                        <CityPicker
                            v-model="model.practiceCityId"
                            :disabled="hasPreviousGpWith(initialModel.practiceCityId) && model.practiceIsCustomer"
                        />
                    </v-col>
                    <v-col cols="12" md="8">
                        <text-field
                            v-model="model.practiceAddress"
                            :disabled="hasPreviousGpWith(initialModel.practiceAddress) && model.practiceIsCustomer"
                            label="Адрес"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions v-if="$allowWrite">
                <btn action="Save" :disabled="$loading.isVisible" @click="createPatientPreviousGp" />
                <btn
                    v-if="hasPreviousGp"
                    action="Delete"
                    :disabled="$loading.isVisible"
                    @click="removePatientPreviousGp"
                >
                    Премахване от досието на пациента
                </btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import DropdownSearch from '@/component/Dropdown/DropdownSearch.vue';
    import CityPicker from '@/component/Picker/CityPicker.vue';
    import PidTypePicker from '@/component/Picker/PidTypePicker.vue';
    import PracticeNumber from '@/component/Practice/PracticeNumber.vue';
    import { RhifAreaDto } from '@/model/Nomenclature/RhifAreaDto';
    import { PatientVersionDropdownDto } from '@/model/Patient/PatientVersionDropdownDto';
    import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';
    import { CurrentGpDto } from '@/model/Patient/PreviousGp/CurrentGpDto';
    import { PreviousGpCreateResultDto } from '@/model/Patient/PreviousGp/PreviousGpCreateResultDto';
    import { PreviousGpDropdownDto } from '@/model/Patient/PreviousGp/PreviousGpDropdownDto';
    import { PreviousGpDto } from '@/model/Patient/PreviousGp/PreviousGpDto';
    import { SetPreviousGpCommand } from '@/model/Patient/PreviousGp/SetPreviousGpCommand';
    import { rhifAreaService } from '@/service/Nomenclature/RhifAreaService';
    import { patientService } from '@/service/Patient/PatientService';
    import { gpSearchService, previousGpService } from '@/service/Patient/PreviousGpService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { rhifCache } from '@/store/Nomenclature/RhifCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    import PatientVersionPicker from './PatientEdit/PatientVersionPicker.vue';

    @Component({
        components: {
            PatientVersionPicker,
            PracticeNumber,
            CityPicker,
            DropdownSearch,
            PidTypePicker
        }
    })
    export default class PreviousGp extends Vue {
        @Prop()
        private id!: number;

        @Prop()
        public patientVersionSeqNumber!: number | null;

        private selectedVersion: PatientVersionDropdownDto = new PatientVersionDropdownDto();
        private versions: PatientVersionDropdownDto[] = [];
        private rhifAreas: RhifAreaDto[] = [];
        private search: string = '';
        private isFormValid: boolean = false;
        private currentDoctorDialog: boolean = false;
        private currentGp: CurrentGpDto = new CurrentGpDto();
        private model: PreviousGpDto = new PreviousGpDto();
        private initialModel: PreviousGpDto = new PreviousGpDto();
        private nhifErrorMessage: string = '';

        private navigateToSelectedVersion(selectedVersion: PatientVersionDropdownDto) {
            this.$router.push(`/Patient/PreviousGp/${this.id}/${selectedVersion.seqNumber}`);
        }

        private get hasPreviousGpWithIdentifier() {
            return (
                this.hasPreviousGp &&
                Boolean(this.initialModel.doctor.pidTypeCode) &&
                Boolean(this.initialModel.doctor.identifier)
            );
        }

        private hasPreviousGpWith(value: unknown) {
            return this.hasPreviousGp && Boolean(value);
        }

        private get hasPreviousGp() {
            return Boolean(this.initialModel.doctor.id);
        }

        private get rhifs() {
            return rhifCache.items;
        }

        private get gpSearchService() {
            return gpSearchService;
        }

        private async mounted() {
            this.$loading.show();
            try {
                await this.loadPatientVersions();
                await this.loadPatientCache();
                this.setModel(await previousGpService.getPatientPreviousGp(this.id, this.selectedVersion.seqNumber));
            } finally {
                this.$loading.hide();
            }
        }

        private async loadPatientVersions() {
            this.versions = await patientService.getPatientVersions(this.id);

            if (this.patientVersionSeqNumber) {
                this.selectedVersion =
                    this.versions.find((ver) => ver.seqNumber === this.patientVersionSeqNumber) ??
                    new PatientVersionDropdownDto();
            } else {
                this.selectedVersion =
                    this.versions.find(
                        (ver) => ver.gpEmployeeSeqNumber === userContextCache.currentDoctorEmployeeSeqNumber
                    ) ??
                    this.versions.find((ver) => ver.isLast) ??
                    new PatientVersionDropdownDto();
            }
        }

        // Не е важно - показва в заглавната лента точната версия на имената, за пълнота.
        private async loadPatientCache() {
            if (this.selectedVersion.seqNumber) {
                const pvKey = new PatientVersionKey();
                pvKey.practiceId = userContextCache.currentPracticeId ?? 0;
                pvKey.patientId = this.id;
                pvKey.seqNumber = this.selectedVersion.seqNumber;
                await currentPatientCache.loadSpecificVersion(pvKey);
            } else {
                await currentPatientCache.loadLastVersion(this.id);
            }
        }

        private setModel(value: PreviousGpDto) {
            this.model = value;
            this.initialModel = this.$lodash.cloneDeep(value);
        }

        private async createPatientPreviousGp() {
            this.$loading.show();
            try {
                (this.$refs.form as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    const result = await previousGpService.createPatientPreviousGp(
                        this.id,
                        this.selectedVersion.seqNumber,
                        this.model
                    );
                    this.setPatientPreviousGpEmployee(result);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private setPatientPreviousGpEmployee(result: PreviousGpCreateResultDto) {
            this.setModel(result.previousGp);
            if (result.isExisting) {
                this.$notifier.showWarning(
                    '',
                    'Лекарят и/или практиката вече съществуваха в базата данни. Данните им са заредени на екрана. Моля, сверете ги внимателно.'
                );
            } else {
                this.$notifier.showSuccess(
                    '',
                    'Добавени са лекар и практика в базата данни. Лекарят е зададен като предишен лекар за пациента.'
                );
            }
        }

        private async onPreviousGpSelected(selected: PreviousGpDropdownDto) {
            if (selected) {
                this.$loading.show();
                try {
                    const model = await previousGpService.setPatientPreviousGp(
                        new SetPreviousGpCommand(
                            this.id,
                            this.selectedVersion.seqNumber,
                            selected.practiceId,
                            selected.doctorEmployeeSeqNumber
                        )
                    );
                    this.setModel(model);
                    this.$notifier.showSuccess('', 'Предишният лекар е зададен успешно.');
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private async removePatientPreviousGp() {
            this.$loading.show();
            try {
                await previousGpService.setPatientPreviousGp(
                    new SetPreviousGpCommand(this.id, this.selectedVersion.seqNumber, null, null)
                );
                this.$notifier.showSuccess(
                    '',
                    'Предишният лекар е премахнат от досието на пациента, но остава в базата данни.'
                );
                this.setModel(new PreviousGpDto());
            } finally {
                this.$loading.hide();
            }
        }

        private async getCurrentGpCaptcha() {
            this.currentGp = await previousGpService.getCurrentGpCaptcha();
        }

        private async getCurrentGp() {
            this.$loading.show();
            try {
                if (this.currentGp.captchaInput) {
                    const result = await previousGpService.setGpFromNhifAsPreviousGp(
                        this.id,
                        this.selectedVersion.seqNumber,
                        this.currentGp
                    );
                    this.nhifErrorMessage = result.errorMessage;
                    if (this.nhifErrorMessage) {
                        this.getCurrentGpCaptcha();
                    } else {
                        this.setPatientPreviousGpEmployee(result);
                        this.currentGp = new CurrentGpDto();
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        @Watch('model.lookupRhifId')
        private async getRhifAreasByRhifId(rhifId: number | null) {
            if (rhifId) {
                const response = await rhifAreaService.getRhifAreasByRhifId(rhifId);
                this.rhifAreas = response?.data ?? [];
            } else {
                this.rhifAreas = [];
            }
        }

        @Watch('model.doctor.name')
        private onDoctorNameChanged(newValue: string) {
            if (newValue) {
                const splitedValue = newValue.split(' ');
                let doctorName = '';

                for (const element of splitedValue) {
                    doctorName += `${formatters.capitalizeFirstLetter(element)} `;
                }

                this.model.doctor.name = doctorName.trim();
            } else {
                this.model.doctor.name = newValue;
            }
        }
    }
</script>
