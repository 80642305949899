<template>
    <v-card>
        <v-card-title>Данни за електронната рецепта</v-card-title>
        <v-card-text>
            <v-row v-if="value" dense>
                <v-col md="3">
                    <text-field v-model="value.nrn" :class="nrnBackground" label="НРН номер" readonly></text-field>
                </v-col>
                <v-col md="3">
                    <text-field v-model="value.statusName" label="Статус на рецептата" readonly></text-field>
                </v-col>
                <v-col md="3">
                    <date-field v-model="value.statusDate" label="Дата на последна проверка" />
                </v-col>
                <v-col md="3">
                    <date-field v-model="value.executionDate" label="Дата на изпълнение" />
                </v-col>
            </v-row>
            <v-card-actions>
                <v-row dense>
                    <v-col v-if="canPost" md="3">
                        <btn action="Send" block :disabled="disable || isLoading" @click="postPrescription" />
                    </v-col>
                    <v-col v-if="canCancel" md="3">
                        <btn action="Delete" block :disabled="disable || isLoading" @click="cancelPrescription">
                            Анулиране
                        </btn>
                    </v-col>
                    <v-col v-if="value.nrn && value.nrn.length > 0" md="4" xl="3">
                        <btn action="CheckDocument" block :disabled="disable || isLoading" @click="checkPrescription">
                            Проверка на статус
                        </btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { NhisPrescriptionStatusCode } from '@/enum/Nhis/NhisPrescriptionStatusCode';
    import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
    import { NhisPrescriptionDto } from '@/model/Nhis/Prescription/NhisPrescriptionDto';
    import { nhisPrescriptionService } from '@/service/Nhis/NhisPrescriptionService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { loadingState } from '@/store/LoadingState';

    @Component
    export default class NhisPrescription extends Vue {
        @Prop({ type: Object })
        private value!: NhisPrescriptionDto;

        @Prop({ required: true })
        private prescriptionKey!: PrescriptionKey;

        @Prop()
        private formType!: PrescriptionFormTypeCode;

        @Prop()
        private disable!: boolean;

        private get isLoading() {
            return loadingState.isVisible;
        }

        private async postPrescription() {
            const response = await nhisPrescriptionService.postPrescription(this.prescriptionKey, this.formType);
            if (response) {
                this.change(response);
            }
        }

        private async cancelPrescription() {
            const response = await nhisPrescriptionService.cancelPrescription(this.prescriptionKey);
            if (response) {
                this.change(response);
                this.$emit('reload-prescription');
            }
        }

        private async checkPrescription() {
            const response = await nhisPrescriptionService.checkPrescription(this.prescriptionKey);
            if (response) {
                this.change(response);
            }
        }

        private get canPost() {
            return (
                ((this.prescriptionKey.prescriptionId > 0 && !this.value.statusCode) ||
                    (this.value.statusCode?.length > 0 &&
                        this.value?.statusCode === NhisPrescriptionStatusCode.Rejected)) &&
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private get canCancel() {
            return (
                (this.value?.statusCode?.length > 0 && this.value?.statusCode === NhisPrescriptionStatusCode.Active) ||
                this.value?.statusCode === NhisPrescriptionStatusCode.PartiallyComplete
            );
        }

        private change(data: NhisPrescriptionDto) {
            this.$emit('input', data);
        }

        private get nrnBackground() {
            return this.value.statusCode === NhisPrescriptionStatusCode.Active ||
                this.value.statusCode === NhisPrescriptionStatusCode.PartiallyComplete ||
                this.value.statusCode === NhisPrescriptionStatusCode.Complete
                ? 'text-field-success'
                : this.value.statusCode === NhisPrescriptionStatusCode.Canceled ||
                    this.value.statusCode === NhisPrescriptionStatusCode.PartiallyCanceled ||
                    this.value.statusCode === NhisPrescriptionStatusCode.Rejected ||
                    this.value.statusCode === NhisPrescriptionStatusCode.Expired
                  ? 'text-field-error'
                  : '';
        }
    }
</script>
