import { AxiosResponse } from 'axios';

import { ExamAnswerEnumDto } from '@/model/Nomenclature/ExamAnswerEnumDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const examAnswerEnumService = {
    getExamAnswerEnum(): Promise<AxiosResponse<ExamAnswerEnumDto[]>> {
        return httpService.get('/ExamAnswerEnum/GetExamAnswerEnumsByType');
    }
};
