import { Transform, Type } from 'class-transformer';

import { PrescriptionNhifDto } from '@/model/Exam/Prescription/PrescriptionNhifDto';
import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';

export class PrescriptionNhifTypeADto extends PrescriptionNhifDto {
    @Transform(({ value }) => value ?? null)
    @Type(() => NhifDrugDto)
    public nhifDrugB: NhifDrugDto | null = null;

    @Transform(({ value }) => value ?? new NhifDrugDto())
    @Type(() => NhifDrugDto)
    public nhifDrugB2: NhifDrugDto = new NhifDrugDto();

    @Transform(({ value }) => value ?? new NhifDrugDto())
    @Type(() => NhifDrugDto)
    public nhifDrugB3: NhifDrugDto = new NhifDrugDto();

    public totalCostPatientB: number = 0;

    @Transform(({ value }) => value ?? null)
    @Type(() => NhifDrugDto)
    public nhifDrugC: NhifDrugDto | null = null;

    @Transform(({ value }) => value ?? new NhifDrugDto())
    @Type(() => NhifDrugDto)
    public nhifDrugC2: NhifDrugDto = new NhifDrugDto();

    @Transform(({ value }) => value ?? new NhifDrugDto())
    @Type(() => NhifDrugDto)
    public nhifDrugC3: NhifDrugDto = new NhifDrugDto();

    public totalCostPatientC: number = 0;
}
