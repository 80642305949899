import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { FiscalDeviceDto } from '@/model/Practice/Fiscal/FiscalDeviceDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const fiscalDeviceService = {
    async getAllByPractice(practiceId: number): Promise<FiscalDeviceDto[]> {
        const response = await httpService.get<FiscalDeviceDto[]>('FiscalDevice/GetFiscalDevicesForPractice', {
            params: { practiceId }
        });
        return plainToInstance(FiscalDeviceDto, response.data);
    },

    getFiscalDevice(practiceId: number, seqNumber: number): Promise<AxiosResponse<FiscalDeviceDto>> {
        return httpService.get('FiscalDevice/GetFiscalDevice', {
            params: { practiceId, seqNumber }
        });
    },

    createFiscalDevice(data: FiscalDeviceDto): Promise<AxiosResponse<FiscalDeviceDto>> {
        return httpService.post('FiscalDevice/CreateFiscalDevice', data);
    },

    updateFiscalDevice(data: FiscalDeviceDto): Promise<AxiosResponse<FiscalDeviceDto>> {
        return httpService.post('FiscalDevice/UpdateFiscalDevice', data);
    },

    deleteFiscalDevice(practiceId: number, seqNumber: number): Promise<AxiosResponse> {
        return httpService.delete('FiscalDevice/DeleteFiscalDevice', {
            params: { practiceId, seqNumber }
        });
    }
};
