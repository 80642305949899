import { currentUser } from '@/store/User/CurrentUser';
import { userContextCache } from '@/store/User/UserContextCache';

import { MenuItemModel } from './MenuItemModel';

export const createDevToolMenuItems = () => {
    const menuItems = [
        new MenuItemModel('Debug, анализ на проблеми', '', ''),
        new MenuItemModel('Диагностика', 'mdi-puzzle-outline', '/DevTool/Diagnostic'),
        new MenuItemModel('Response wrapper', 'mdi-format-wrap-square', '/DevTool/ResponseWrapperTest'),
        new MenuItemModel('Токен за НЗИС/НЗОК', 'mdi-bookmark-outline', `/DevTool/NhisNhifAccessToken`),
        new MenuItemModel('Подписване на XML', 'mdi-draw', `/DevTool/SignXml`),
        new MenuItemModel('Извличане на подпис', 'mdi-draw', `/DevTool/SignCapture`),
        new MenuItemModel('Тестове, експерименти', '', ''),
        new MenuItemModel('РЗОК', 'mdi-bank-outline', '/DevTool/Rhif'),
        new MenuItemModel('MultiSelectDropdown', 'mdi-format-list-checks', '/DevTool/MultiSelectDropdownTest'),
        new MenuItemModel('Нотификации', 'mdi-email-outline', '/DevTool/NotificationTest'),
        new MenuItemModel('Извличане на преглед', 'mdi-view-list', `/DevTool/ExamExtraction`),
        new MenuItemModel('Извличане на рецепти', 'mdi-view-list', `/DevTool/DoctorsPrescription`),
        new MenuItemModel('XML към НЗИС', 'mdi-view-list', `/DevTool/PostXmlNhis`)
    ];

    const { current } = userContextCache;
    if (current) {
        const { practiceId } = current;
        menuItems.push(
            new MenuItemModel('Blob manager', 'mdi-file-upload-outline', `/DevTool/BlobManager/${practiceId}`)
        );
    }

    if (currentUser.isGlobalAdmin) {
        menuItems.push(
            new MenuItemModel('Глобален админ', '', ''),
            new MenuItemModel('Журнал', 'mdi-math-log', '/DevTool/Audit')
        );
    }

    return menuItems;
};
