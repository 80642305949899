import { Type } from 'class-transformer';

import { Annex3ChildrenWithPneumoniaRowDto } from '@/model/Report/Rhi/Annex3/Annex3ChildrenWithPneumoniaRowDto';

export class Annex3ChildrenWithPneumoniaDto {
    public name: string = '';

    @Type(() => Annex3ChildrenWithPneumoniaRowDto)
    public childrensWithPneumonia: Annex3ChildrenWithPneumoniaRowDto[] = [];
}
