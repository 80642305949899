import { AxiosResponse } from 'axios';

import { AbortionTypeDto } from '@/model/Nomenclature/AbortionTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const abortionTypeService = {
    getAbortionTypes(): Promise<AxiosResponse<AbortionTypeDto[]>> {
        return httpService.get('/AbortionType/GetAbortionTypes');
    }
};
