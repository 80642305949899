import { plainToInstance } from 'class-transformer';

import { NhisGroupSummaryDto } from '@/model/Nhis/GroupUpload/NhisGroupSummaryDto';
import { NhisGroupUploadDto } from '@/model/Nhis/GroupUpload/NhisGroupUploadDto';
import { NhisGroupUploadFilterDto } from '@/model/Nhis/GroupUpload/NhisGroupUploadFilterDto';
import { NhisGroupUploadResultDto } from '@/model/Nhis/GroupUpload/NhisGroupUploadResultDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { accessTokenCache, nhisKey } from '@/store/NhisNhifAccessTokenCache';

export const nhisGroupUploadService = {
    async getExamOpenXmls(filter: NhisGroupUploadFilterDto): Promise<NhisGroupUploadDto> {
        const response = await httpService.post(`/${nhisKey}/ExamOpen`, filter);
        return plainToInstance(NhisGroupUploadDto, response.data);
    },
    async getNonExamXmls(filter: NhisGroupUploadFilterDto): Promise<NhisGroupUploadDto> {
        const response = await httpService.post(`/${nhisKey}/NonExamBlanks`, filter);
        return plainToInstance(NhisGroupUploadDto, response.data);
    },
    async getExamCloseEditXmls(filter: NhisGroupUploadFilterDto): Promise<NhisGroupUploadDto> {
        const response = await httpService.post(`/${nhisKey}/ExamCloseEdit`, filter);
        return plainToInstance(NhisGroupUploadDto, response.data);
    },
    async getUploadSummary(filter: NhisGroupUploadFilterDto): Promise<NhisGroupSummaryDto> {
        const response = await httpService.post(`/${nhisKey}/UploadSummary`, filter);
        return plainToInstance(NhisGroupSummaryDto, response.data);
    },

    async postToNhis(command: NhisGroupUploadDto, action: string): Promise<NhisGroupUploadResultDto> {
        const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
        command.accessToken = tokenInfo?.accessToken ?? '';
        const response = await httpService.post<NhisGroupUploadResultDto>(`${nhisKey}/${action}`, command);
        return response.data;
    }
};
