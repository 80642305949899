<template>
    <v-form ref="form" v-model="isFormValid" class="container pt-5">
        <v-card>
            <v-card-title>
                <h5>Пациент</h5>
            </v-card-title>
            <patient-reception
                v-model="command"
                :patient-id="currentPatient.key.patientId"
                @on-extracted-referrals-change="onExtractedReferralsChange"
            />
        </v-card>
        <v-card class="mt-5">
            <v-card-title>
                <h5>Услуги</h5>
            </v-card-title>
            <service-order v-model="command" :extracted-referrals="extractedReferrals" />
        </v-card>

        <v-card class="mt-5">
            <v-card-title>
                <h5>Насочване</h5>
            </v-card-title>
            <credit-to-employee v-model="command" />
        </v-card>
        <v-row class="mt-5">
            <v-col cols="2">
                <btn action="Save" :disabled="$loading.isVisible" @click="onSave">Запис</btn>
            </v-col>
            <v-col cols="2">
                <btn v-if="canCreateInvoice" action="List" :disabled="$loading.isVisible" @click="createInvoice"
                    >Фактура</btn
                >
            </v-col>
        </v-row>
        <reception-visit ref="receptionVisit" class="mt-5" :command="command" @fill="fillForm" />
    </v-form>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import Btn from '@/component/Common/Btn.vue';
    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { PatientRegistrationTypeCode } from '@/enum/Patient/PatientRegistrationTypeCode';
    import { NhisExtractedReferralDto } from '@/model/Nhis/Referral/NhisExtractedReferralDto';
    import { ReceptionServiceCreateCommand } from '@/model/Reception/Service/ReceptionServiceCreateCommand';
    import { ReceptionVisitDto } from '@/model/Reception/Service/ReceptionVisitDto';
    import { patientService } from '@/service/Patient/PatientService';
    import { receptionService } from '@/service/Reception/ReceptionService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { serviceOrderCache } from '@/store/ServiceOrderCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import ReceptionVisit from '@/view/Reception/ReceptionVisit.vue';
    import CreditToEmployee from '@/view/Reception/Service/CreditToEmployee.vue';
    import PatientReception from '@/view/Reception/Service/PatientReception.vue';
    import ServiceOrder from '@/view/Reception/Service/ServiceOrder.vue';

    @Component({
        components: {
            ServiceOrder,
            CreditToEmployee,
            PatientReception,
            Btn,
            ReceptionVisit
        }
    })
    export default class ServiceCreate extends Vue {
        private command: ReceptionServiceCreateCommand = new ReceptionServiceCreateCommand();
        private isFormValid: boolean = false;
        private extractedReferrals: NhisExtractedReferralDto[] = [];

        private onExtractedReferralsChange(items: NhisExtractedReferralDto[]) {
            this.extractedReferrals = items;
        }

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get canCreateInvoice() {
            return this.command.serviceOrders.every((order) => order.id > 0);
        }

        private fillForm(item: ReceptionVisitDto) {
            this.command.serviceOrders = [];
            this.command.creditToRoomSeqNumber = item.creditToRoomSeqNumber;
            this.command.serviceOrders = item.serviceOrders;
            this.command.specialtyCode = item.specialtyCode;
            this.command.doctorId = item.doctorId;
            for (let index = 0; index < item.serviceOrders.length; index++) {
                this.command.serviceOrders[index] = item.serviceOrders[index];
            }
        }

        private async createPatientVersionIfItIsNew() {
            if (this.command.patient.key.patientId <= 0) {
                this.command.patient.key.practiceId = userContextCache.currentPracticeId ?? 0;
                const patientId = await patientService.createPatient(this.command.patient);
                await currentPatientCache.loadLastVersion(patientId);
                this.command.patient = this.currentPatient;
            }
        }

        private async onSave() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isFormValid) {
                this.$loading.show();
                try {
                    await this.createPatientVersionIfItIsNew();
                    this.command.patient.registrationDate = new Date();
                    this.command.patient.patientRegistrationTypeCode = PatientRegistrationTypeCode.Registered;
                    await receptionService.create(this.command);
                    this.$notifier.showSuccess('Услуги', 'Успешно записана услуга');
                    await (this.$refs.receptionVisit as ReceptionVisit).getReceptionVisits(
                        this.command.patient.key.patientId
                    );
                    this.setCommandData();
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private createInvoice() {
            try {
                this.$loading.show();
                serviceOrderCache.set(this.command);
                this.$router.push(`/Finance/Invoice/Create/${userContextCache.currentPracticeId ?? 0}`);
            } finally {
                this.$loading.hide();
            }
        }

        private setCommandData() {
            this.command = new ReceptionServiceCreateCommand();
            this.command.createdByEmployeeSeqNumber = userContextCache.current?.employeeSeqNumber ?? 0;
            this.command.patient = this.currentPatient;
        }

        private mounted() {
            this.setCommandData();
            // TODO: Началните стойности на всички параметри да се четат от URL-а в ReceptionRouter.ts и да се подават като props.
            if (this.$route.params.doctorId) {
                // TODO: roomId
                this.command.doctorId = parseInt(this.$route.params.doctorId, 10);
                this.command.specialtyCode = this.$route.params.specialtyCode as SpecialtyCode;
                this.command.patient.key.patientId = currentPatientCache?.value?.key?.patientId ?? 0;
            }
            // Май не е необходимо защото CreatedOn е логично да е кога е направен записа,
            // а не кога е по график const examDateTime = new Date(this.$route.params.examStartDateTime);
        }
    }
</script>
