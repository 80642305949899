import { Type } from 'class-transformer';

import { DiagnosisDto } from '@/model/Common/DiagnosisDto';
import { IncomingReferralDto } from '@/model/Exam/IncomingReferralDto';
import { FundDto } from '@/model/Nomenclature/FundDto';
import { MedicalProcedureDto } from '@/model/Nomenclature/MedicalProcedureDto';
import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';

export class NhisExtractedReferralDto {
    @Type(() => IncomingReferralDto)
    public incomingReferral: IncomingReferralDto = new IncomingReferralDto();

    @Type(() => DiagnosisDto)
    public diagnoses: DiagnosisDto[] = [];

    @Type(() => SpecialtyDto)
    public specialties: SpecialtyDto[] = [];

    @Type(() => FundDto)
    public funding: FundDto | null = null;

    @Type(() => MedicalProcedureDto)
    public medicalProcedure: MedicalProcedureDto | null = null;

    public practiceId: number | null = null;
    public doctorSeqEmployeeNumber: number | null = null;
    public deputyDoctorId: number | null = null;
    public deputyDoctorIsHired: boolean | null = null;
    public disabled: boolean = false;
    public examDateTime: Date | null = null;

    public get title() {
        return `НРН ${this.incomingReferral.nrn}, Тип ${
            this.incomingReferral.referralType
        }, Специалност: ${this.specialties.map((specialty) => specialty.name)}`;
    }
}
