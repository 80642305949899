import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { EventDto } from '@/model/WorkTime/EventDto';

export class EventCreateCommand {
    public practiceId: number | null = null;
    public doctorId: number | null = null;
    public specialtyCode: SpecialtyCode | null = null;

    public event: EventDto = new EventDto();
}
