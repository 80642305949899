interface IMenuItemOptions {
    disabled?: boolean;
    expanded?: boolean;
    hidden?: boolean;
    class?: string;
}

export class MenuItemModel {
    public title: string = '';
    public icon: string = '';
    public link: string | null = null;
    public children: MenuItemModel[] | null = null;
    public options: IMenuItemOptions = {};

    constructor(title: string, icon: string, linkOrChildren: string | MenuItemModel[], options?: IMenuItemOptions) {
        this.title = title;
        this.icon = icon;

        if (typeof linkOrChildren === 'string') {
            this.link = linkOrChildren as string;
        } else {
            this.children = linkOrChildren as MenuItemModel[];
        }

        if (options) {
            this.options = options;
        }
    }
}
