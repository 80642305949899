<template>
    <div>
        <exam-layout :can-delete="value.id > 0" :can-print="false" @updated="save" @deleted="deleteEntity">
            <nhis-family-history :id="documentId" v-model="value.nhisFamilyHistory" />
            <v-card>
                <main-title>Фамилна обремененост</main-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6" md="3">
                            <date-picker v-model="value.issueDate" label="Дата на откриване:" required />
                        </v-col>
                        <v-col cols="6" md="3">
                            <dropdown
                                v-model="value.statusCode"
                                :items="familyHistoryStatuses"
                                item-value="code"
                                label="Статус на записа"
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <text-field v-model="value.relativeName" label="Име на роднина" />
                        </v-col>
                        <v-col cols="6" md="3">
                            <dropdown
                                v-model="value.relativeGenderCode"
                                :items="genders"
                                item-value="code"
                                label="Пол на роднината"
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <date-picker v-model="value.relativeBirthdate" label="Дата на раждане на роднитата:" />
                        </v-col>
                        <v-col cols="6" md="3">
                            <dropdown
                                v-model="value.relationshipCode"
                                :items="familyHistoryRelationships"
                                item-value="code"
                                label="Родствена връзка"
                                required
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-checkbox v-model="value.isDeceased" label="Починал:" />
                        </v-col>
                        <v-col cols="12" md="6"><icd-picker v-model="value.icdCode" required /></v-col>
                        <v-col cols="12" md="6">
                            <icd-picker v-model="value.icdCodeAdd" label="Допълнително МКБ" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-textarea v-model="value.description" label="Описание" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </exam-layout>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import { FamilyHistoryRelationshipDto } from '@/model/Nomenclature/FamilyHistoryRelationshipDto';
    import { FamilyHistoryStatusDto } from '@/model/Nomenclature/FamilyHistoryStatusDto';
    import { FamilyHistoryDto } from '@/model/Patient/FamilyHistory/FamilyHistoryDto';
    import { familyHistoryRelationshipService } from '@/service/Nomenclature/FamilyHistoryRelationshipService';
    import { familyHistoryStatusService } from '@/service/Nomenclature/FamilyHistoryStatusService';
    import { familyHistoryService } from '@/service/Patient/FamilyHistoryService';
    import { genderCache } from '@/store/Nomenclature/GenderCache';
    import NhisFamilyHistory from '@/view/Nhis/FamilyHistory/NhisFamilyHistory.vue';

    @Component({
        components: { IcdPicker, NhisFamilyHistory, ExamLayout }
    })
    export default class FamilyHistoryEditor extends Vue {
        @Prop()
        private patientId!: number;

        @Prop()
        private documentId!: number;

        private value: FamilyHistoryDto = new FamilyHistoryDto();
        private familyHistoryStatuses: FamilyHistoryStatusDto[] = [];
        private familyHistoryRelationships: FamilyHistoryRelationshipDto[] = [];

        private get genders() {
            return genderCache.items;
        }

        private async getRelationships() {
            this.familyHistoryRelationships = await familyHistoryRelationshipService.getFamilyHistoryRelationships();
        }

        private async getStatuses() {
            this.familyHistoryStatuses = await familyHistoryStatusService.getFamilyHistoryStatuses();
        }

        private async save() {
            try {
                this.value.patientId = this.patientId;
                if (this.value.id <= 0) {
                    const documentId = await familyHistoryService.create(this.value);
                    this.$router.push(`/Patient/FamilyHistory/Edit/${documentId}/${this.patientId}`);
                } else {
                    await familyHistoryService.update(this.value);
                }
                this.$notifier.showSuccess('', `Успешен запис`);
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEntity() {
            this.$loading.show();
            try {
                await familyHistoryService.delete(this.documentId);
                this.$router.push(`/Patient/FamilyHistory/Create/${this.patientId}`);
            } finally {
                this.$loading.hide();
            }
        }

        private async getById() {
            this.$loading.show();
            try {
                this.value = await familyHistoryService.getById(this.documentId);
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.getRelationships();
            await this.getStatuses();
            if (this.documentId) {
                await this.getById();
            }
        }
    }
</script>

<style scoped></style>
