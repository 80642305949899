<template>
    <dropdown-search
        :value="value"
        :service="cityService"
        :label="label"
        :disabled="disabled"
        :required="required"
        :no-filter="false"
        @input="$emit('input', ...arguments)"
    ></dropdown-search>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DropdownSearch from '@/component/Dropdown/DropdownSearch.vue';
    import { cityService } from '@/service/Nomenclature/CityService';

    @Component({ components: { DropdownSearch } })
    export default class CityPicker extends Vue {
        @Prop()
        private value!: number;

        @Prop()
        private required!: boolean;

        @Prop()
        private disabled!: boolean;

        @Prop({ default: 'Населено място' })
        private label!: string;

        private get cityService() {
            return cityService;
        }
    }
</script>
