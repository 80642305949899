import { plainToInstance } from 'class-transformer';

import { NhisPrescriptionGroupCheckDto } from '@/model/Nhis/Prescription/GroupCheck/NhisPrescriptionGroupCheckDto';
import { NhisPrescriptionGroupCheckFilterDto } from '@/model/Nhis/Prescription/GroupCheck/NhisPrescriptionGroupCheckFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { accessTokenCache, nhisKey } from '@/store/NhisNhifAccessTokenCache';

export const nhisPrescriptionGroupCheckService = {
    async getPrescriptionCheckXmls(
        filter: NhisPrescriptionGroupCheckFilterDto
    ): Promise<NhisPrescriptionGroupCheckDto> {
        const response = await httpService.post(`/${nhisKey}/PrescriptionGroupCheck`, filter);
        return plainToInstance(NhisPrescriptionGroupCheckDto, response.data);
    },

    async postToNhis(command: NhisPrescriptionGroupCheckDto, action: string): Promise<void> {
        const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
        command.accessToken = tokenInfo?.accessToken ?? '';
        await httpService.post(`${nhisKey}/${action}`, command);
    }
};
