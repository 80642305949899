<template>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" dense max-width="1500">
        <template #activator="{ on, attrs }">
            <v-row class="float-right">
                <v-col cols="5">
                    <btn action="Details" block v-bind="attrs" v-on="on">Дейности</btn>
                </v-col>
            </v-row>
        </template>
        <template #default="dialog">
            <v-card-title class="info2">
                Наредба за диспансеризация - необходими и извършени дейности
                <v-spacer />
                <btn icon action="Close" @click="dialog.value = false" />
            </v-card-title>
            <PatientDispensaryVisit :patient-id="patientId" :icd-code="icdCode" @close="close"></PatientDispensaryVisit>
        </template>
    </v-dialog>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import PatientDispensaryVisit from './PatientDispensaryVisit.vue';

    @Component({
        components: {
            PatientDispensaryVisit
        }
    })
    export default class PatientDispensaryVisitDialog extends Vue {
        @Prop({ required: true })
        private patientId!: number;

        @Prop()
        private icdCode!: string;

        private dialog: boolean = false;

        close() {
            this.dialog = false;
        }
    }
</script>
