<template>
    <div>
        <v-row>
            <v-col cols="12" md="3"> <text-field v-model="value.number" required label="Номер" /></v-col>
            <v-col cols="12" md="3"
                ><date-picker v-model="value.releaseDate" label="Дата на издаване" required
            /></v-col>
            <v-col cols="12" md="3">
                <text-field v-model="value.certificateNumber" required label="Удостоверение - номер" />
            </v-col>
            <v-col cols="12" md="3">
                <date-picker v-model="value.certificateDate" label="Удостоверение - дата" required />
            </v-col>
            <v-col cols="12" md="3">
                <text-field
                    v-model="value.pagesCount"
                    type="number"
                    required
                    label="Брой страници"
                    :rules="[minPageCountRule]"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="3">
                <date-picker v-model="value.cancelationDate" label="Анулирана на" />
            </v-col>
            <v-col cols="12" md="9">
                <text-field v-model="value.cancelationReason" label="Причина за анулиране" />
            </v-col>
        </v-row>
        <v-row v-if="hasErrors">
            <alert v-for="errorMessage in errors" :key="errorMessage" v-model="hasErrors" type="error">
                {{ errorMessage }}
            </alert>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { VeteranPrescriptionBookDto } from '@/model/Patient/PrescriptionBook/VeteranPrescriptionBookDto';

    @Component
    export default class VeteranPrescriptionBook extends Vue {
        @Prop({ type: Object })
        private value!: VeteranPrescriptionBookDto;

        @Prop()
        private errors!: [];

        private get hasErrors() {
            return this.errors?.length > 0;
        }

        minPageCountRule(value: number) {
            const minPageCount = 20;
            return value >= minPageCount || `Страниците трябва да бъдат поне ${minPageCount}.`;
        }
    }
</script>
