<template>
    <div>
        <v-card>
            <v-card-title>Районна каса</v-card-title>
            <v-card-actions>
                <btn action="Back" to="/DevTool/Rhif" />
                <btn action="Save" @click="updateRhif" />
            </v-card-actions>
            <v-card-text>
                <v-form>
                    <text-field v-model="rhif.code" label="Код:" />
                    <text-field v-model="rhif.name" label="Наименование:" />
                    <text-field v-model="rhif.eik" label="ЕИК:" />
                    <text-field v-model="rhif.director" label="Управител:" />
                    <text-field v-model="rhif.city" label="Град:" />
                    <text-field v-model="rhif.address" label="Адрес:" />
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { RhifDto } from '@/model/Nomenclature/RhifDto';
    import { rhifService } from '@/service/Nomenclature/RhifService';

    @Component
    export default class RhifEdit extends Vue {
        @Prop()
        private id!: number;

        private rhif: RhifDto = new RhifDto();

        private async mounted() {
            this.$loading.show();
            if (this.id) {
                await this.getRhif(this.id);
            }
            this.$loading.hide();
        }

        private async getRhif(id: number) {
            const response = await rhifService.getRhifById(id);
            this.rhif = response.data;
        }

        private async updateRhif() {
            if (!this.id) {
                await rhifService.createRhif(this.rhif);
            } else {
                this.rhif.id = this.id;
                await rhifService.updateRhif(this.rhif);
            }

            this.$router.push('/DevTool/Rhif');
        }
    }
</script>
