<template>
    <exam-layout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Служебна бележка"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <v-col v-if="documentId" cols="1" md="1">
                <v-menu open-on-hover bottom offset-y pointer>
                    <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <btn action="Print" :disabled="disable" block>Опростен печат </btn>
                        </div>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title class="pointer" @click="printBasicWithoutPreviewHandler"
                                >Печат</v-list-item-title
                            >
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title class="pointer" @click="printBasic">Печат - преглед</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title class="pointer" @click="printSinglePageWithoutPreviewHandler"
                                >Пълен печат - 1 копие</v-list-item-title
                            >
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title class="pointer" @click="printSinglePage"
                                >Пълен печат - 1 копие - преглед</v-list-item-title
                            >
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
            <main-title>Служебна бележка</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-row>
                            <v-col cols="12" md="6">
                                <text-field v-model.number="document.number" required label="Изх. номер" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <date-picker v-model="document.issueDate" required label="Дата:" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <DoctorInfo />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-title>Настоящата служебна бележка се издава на</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <text-field
                            v-model.trim="document.familyMemberFirstName"
                            required
                            label="Име:"
                            :rules="[$validator.personName]"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field
                            v-model.trim="document.familyMemberMiddleName"
                            label="Презиме:"
                            :rules="[$validator.personName]"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field
                            v-model.trim="document.familyMemberLastName"
                            required
                            label="Фамилия:"
                            :rules="[$validator.personName]"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field
                            v-model.trim="document.familyMemberIdentifier"
                            required
                            label="ЕГН/ЕНЧ:"
                            @input="getFamilyMemberInfoByIdentifier()"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-title>Настоящата служебна бележка се издава за</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="2">
                        <PidTypePicker v-model="currentPatient.personIdentification.pidTypeCode" disabled />
                    </v-col>
                    <v-col cols="6" md="4">
                        <text-field
                            v-model="currentPatient.personIdentification.identifier"
                            disabled
                            label="Идентификатор"
                        />
                    </v-col>
                    <v-col cols="4" md="2">
                        <text-field v-model="currentPatient.age" disabled label="Възраст" />
                    </v-col>
                    <v-col cols="4" md="2">
                        <text-field v-model="currentPatient.rhif" disabled label="РЗОК" />
                    </v-col>
                    <v-col cols="4" md="2">
                        <text-field v-model="currentPatient.rhifArea" disabled label="Здр. район" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <text-field v-model="currentPatient.firstName" disabled label="Име" />
                    </v-col>
                    <v-col cols="4">
                        <text-field v-model="currentPatient.middleName" disabled label="Презиме" />
                    </v-col>
                    <v-col cols="4">
                        <text-field v-model="currentPatient.lastName" disabled label="Фамилия" />
                    </v-col>
                </v-row>
                <v-row>
                    <h3>Адрес:</h3>
                </v-row>
                <Address v-model="document.patientAddress" :show-mailbox="false" :show-post-code="false" />
                <alert type="info">
                    В уверение на това, че на детето са извършени всички задължителни планови имунизации по
                    имунизационен календар на РБ и са проведени всички профилактични прегледи по наредба №39 на МЗ и
                    Приложение 12 от НРД 2020-2022. Настоящата бележка се издава, за да послужи пред дир. "Социално
                    подпомагане"
                </alert>
                <data-table
                    :headers="immunizationHeaders"
                    title="Извършени имунизации"
                    :items="document.immunizations"
                ></data-table>
                <data-table
                    class="mt-3"
                    :headers="examHeaders"
                    title="Профилактични прегледи"
                    :items="document.exams"
                ></data-table>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </exam-layout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PidTypePicker from '@/component/Picker/PidTypePicker.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { OfficialNoticeDto } from '@/model/Exam/OfficialNotice/OfficialNoticeDto';
    import { officialNoticeService } from '@/service/Exam/Certificate/OfficialNoticeService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { formatters } from '@/util/Formatters';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';
    import Address from '@/view/Patient/PatientEdit/Address.vue';

    @Component({
        components: {
            PidTypePicker,
            UnsavedChanges,
            Address,
            DoctorInfo,
            ExamLayout
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class OfficialNotice extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private examId!: number;

        @Prop()
        private documentId!: number;

        private document: OfficialNoticeDto = new OfficialNoticeDto();
        private initialValue: OfficialNoticeDto = new OfficialNoticeDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get disable() {
            return this.$loading.isVisible;
        }

        @Watch('document.familyMemberFirstName')
        private onFamilyMemberFirstNameChanged() {
            this.document.familyMemberFirstName = formatters.capitalizeFirstLetter(this.document.familyMemberFirstName);
        }

        @Watch('document.familyMemberMiddleName')
        private onFamilyMemberMiddleNameChanged() {
            this.document.familyMemberMiddleName = formatters.capitalizeFirstLetter(
                this.document.familyMemberMiddleName
            );
        }

        @Watch('document.familyMemberLastName')
        private onFamilyMemberLastNameChanged() {
            this.document.familyMemberLastName = formatters.capitalizeFirstLetter(this.document.familyMemberLastName);
        }

        private get immunizationHeaders(): IDataTableHeader[] {
            return [
                { text: 'Дата на поставяне:', value: 'issueDate', formatter: formatters.date },
                { text: 'Наименование:', value: 'immunizationName' },
                { text: 'Код НЗОК:', value: 'nhifCode' },
                { text: 'Код РЗИ:', value: 'rhiCode' }
            ];
        }

        private get examHeaders(): IDataTableHeader[] {
            return [
                { text: 'Дата на прегледа:', value: 'examDate', formatter: formatters.date },
                { text: 'Номер на АЛ:', value: 'number' },
                { text: 'МКБ:', value: 'icdCode' },
                { text: 'Наименование:', value: 'icdName' }
            ];
        }

        private printSinglePage() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Certificate.OfficialNoticeSinglePage/${this.documentId}`);
            }
        }

        private async printSinglePageWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Certificate.OfficialNoticeSinglePage';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private printBasic() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Certificate.OfficialNoticeBasic/${this.documentId}`);
            }
        }

        private async printBasicWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Certificate.OfficialNoticeBasic';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (this.document.id === 0) {
                    const response = await officialNoticeService.createOfficialNotice(this.document);
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, 'OfficialNotice', 'Служебна бележка');
                    this.$router.push(`/Exam/OfficialNotice/Edit/${documentId}`);
                } else {
                    await officialNoticeService.updateOfficialNotice(this.document);
                    eventBus.$emit('update-referral-event', 'Служебна бележка');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                await officialNoticeService.deleteOfficialNotice(documentId);
                this.copyObject();
                this.$router.push(`/Exam/MedicalNote/Create/${this.document.examId}`);
                eventBus.$emit('delete-referral-event', this.documentId, 'OfficialNotice', 'Служебна бележка');
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Certificate.OfficialNotice/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Certificate.OfficialNotice';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                if (this.documentId) {
                    this.document = await officialNoticeService.getOfficialNotice(this.documentId);
                } else {
                    this.document = await officialNoticeService.getOfficialNoticeInfoByExamId(this.examId);
                    this.document.patientAddress = this.currentPatient.address;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async getFamilyMemberInfoByIdentifier() {
            if (this.document.familyMemberIdentifier) {
                const familyMemberInfo = (
                    await officialNoticeService.getFamilyMemberInfoByIdentifierAsync(
                        this.document.familyMemberIdentifier,
                        this.examId
                    )
                ).data;

                this.document.familyMemberFirstName = familyMemberInfo.familyMemberFirstName;
                this.document.familyMemberMiddleName = familyMemberInfo.familyMemberMiddleName;
                this.document.familyMemberLastName = familyMemberInfo.familyMemberLastName;
            }
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }
    }
</script>
