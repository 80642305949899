import { Type } from 'class-transformer';

import { DoctorDeputyKey } from '@/model/Employee/Deputy/DoctorDeputyKey';

export class DoctorDeputyScheduleCreateCommand {
    @Type(() => DoctorDeputyKey)
    public doctorDeputyKey: DoctorDeputyKey = new DoctorDeputyKey();

    public validFrom: Date = new Date();
    public validTo: Date = new Date();
}
