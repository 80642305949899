<template>
    <div>
        <v-dialog v-model="extractionDialogIsVisible" persistent max-width="75%">
            <v-form ref="form" v-model="isFormValid">
                <v-card>
                    <v-card-title>Проверка за хоспитализация</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6" md="3">
                                <text-field v-model="model.nrn" label="Нрн на хоспитализация"></text-field>
                            </v-col>
                            <v-col cols="6" md="3">
                                <date-picker v-model="model.fromDate" label="Oт дата:" />
                            </v-col>
                            <v-col cols="6" md="3">
                                <date-picker v-model="model.toDate" label="До дата:" />
                            </v-col>
                            <v-col cols="6" md="3"> </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <btn action="Cancel" text @click="closeExtractionDialog" />
                        <btn action="Download" text @click="extract">Извличане</btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog v-model="resultDialogIsVisible" persistent max-width="75%">
            <v-card>
                <v-card-title class="mx-2 my-3">
                    <v-spacer />
                    <div class="red--text pointer" @click="resultDialogIsVisible = false">x</div>
                </v-card-title>
                <data-table title="Хоспитализации" :items="tableItems" :headers="tableHeaders" />
            </v-card>
        </v-dialog>
        <v-card-actions>
            <btn v-if="tableItems.length > 0" action="List" @click="resultDialogIsVisible = true">Хоспитализации</btn>
        </v-card-actions>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhisHospitalizationDto } from '@/model/Nhis/Hospitalization/NhisHospitalizationDto';
    import { NhisHospitalizationExtractionCommand } from '@/model/Nhis/Hospitalization/NhisHospitalizationExtractionCommand';
    import { nhisHospitalizationService } from '@/service/Nhis/NhisHospitalizationService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: {}
    })
    export default class HospitalizationExtraction extends Vue {
        @Prop()
        extractionDialogIsVisible!: boolean;

        private isFormValid: boolean = false;
        private model: NhisHospitalizationExtractionCommand = new NhisHospitalizationExtractionCommand();
        private tableItems: NhisHospitalizationDto[] = [];
        private resultDialogIsVisible: boolean = false;

        private closeExtractionDialog() {
            this.$emit('close');
            this.model = new NhisHospitalizationExtractionCommand();
        }

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'НРН на хоспитализация:', value: 'nrn' },
                { text: 'Статус:', value: 'status' },
                { text: 'Дата на създаване:', value: 'issueDate', formatter: formatters.date },
                { text: 'Дата на постъпване в лечебно заведение:', value: 'admissionDate', formatter: formatters.date },
                { text: 'Дата на изписване:', value: 'dischargeDate', formatter: formatters.date }
            ];
        }

        private async extract() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isFormValid) {
                const xmlString = await nhisHospitalizationService.getHospitalizationExtractionXml(
                    this.getRequestData()
                );
                const response = await nhisHospitalizationService.postHospitalizationExtraction(xmlString);
                console.log(response);
                if (response) {
                    this.tableItems = response.hospitalizations;
                    this.resultDialogIsVisible = true;
                }

                this.closeExtractionDialog();
            }
        }

        private getRequestData() {
            this.model.doctorUin = userContextCache.current?.doctorUin ?? '';
            this.model.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
            this.model.practiceNumber = userContextCache.current?.practiceNumber ?? '';
            this.model.identifier = currentPatientCache?.value?.personIdentification?.identifier;
            this.model.pidTypeCode = currentPatientCache?.value?.personIdentification?.pidTypeCode ?? PidTypeCode.Egn;
            return this.model;
        }

        @Watch('extractionDialogIsVisible')
        onDialogVisibilityChange(isVisible: boolean) {
            if (isVisible) {
                this.fillPeriod();
            }
        }

        private fillPeriod() {
            this.model.fromDate = dateUtil.startOfThisYear();
            this.model.toDate = dateUtil.endOfThisYear();
        }
    }
</script>
