import { Type } from 'class-transformer';

import { ServiceOfferKey } from '@/model/Practice/Service/ServiceOfferKey';

export class BatchChildDto {
    @Type(() => ServiceOfferKey)
    public serviceOfferKey: ServiceOfferKey = new ServiceOfferKey();

    public tariffName: string = '';
    public name: string = '';
    public patientPrice: number = 0;
    public quantity: number = 0;
    public isSelected: boolean = false;
}
