import { AxiosResponse } from 'axios';

import { IncomingReferralExamReportDto } from '@/model/Query/Exam/IncomingReferralExamReportDto';
import { IncomingReferralPatientReportCommand } from '@/model/Query/Patient/IncomingReferral/IncomingReferralPatientReportCommand';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const incomingReferralReportService = {
    getIncomingReferralPerExam(
        filter: ReportIntervalFilterDto
    ): Promise<AxiosResponse<IncomingReferralExamReportDto[]>> {
        return httpService.post('IncomingReferralReport/GetIncomingReferralExamReport', filter);
    },

    getIncomingReferralPerPatient(
        patientId: number,
        doctorEmployeeSeqNumber: number
    ): Promise<AxiosResponse<IncomingReferralExamReportDto[]>> {
        return httpService.post(
            'IncomingReferralReport/GetIncomingReferralPatientReport',
            new IncomingReferralPatientReportCommand(patientId, doctorEmployeeSeqNumber)
        );
    }
};
