import { Type } from 'class-transformer';

import { Annex56AdultDiseaseRowDto } from '@/model/Report/Rhi/Annex56/Annex56AdultDiseaseRowDto';

export class Annex56AdultDiseaseDto {
    public name: string = '';

    @Type(() => Annex56AdultDiseaseRowDto)
    public adultDiseases: Annex56AdultDiseaseRowDto[] = [];
}
