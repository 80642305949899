import { AxiosResponse } from 'axios';

import { RhifAreaDto } from '@/model/Nomenclature/RhifAreaDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const rhifAreaService = {
    getRhifAreasByRhifId(rhifId: number | null): Promise<AxiosResponse<RhifAreaDto[]>> {
        return httpService.get(`/RhifArea/GetRhifAreasByRhifId/${rhifId}`);
    },

    getRhifAreaById(id: number): Promise<AxiosResponse<RhifAreaDto>> {
        return httpService.get(`/RhifArea/GetRhifAreaById/${id}`);
    },

    createRhifArea(data: RhifAreaDto): Promise<AxiosResponse<RhifAreaDto>> {
        return httpService.post('/RhifArea/CreateRhifArea', data);
    },

    updateRhifArea(data: RhifAreaDto): Promise<AxiosResponse<RhifAreaDto>> {
        return httpService.put('/RhifArea/UpdateRhifArea/', data);
    }
};
