import { NotificationCategory, NotificationSeverity } from '@/model/Notification/NotificationSeverity';

// Опакова в обект параметрите за методите showToastMessage() и showModalDialog(). Служи за основа на клас NotificationModel.
export class NotificationCreateModel {
    constructor(
        public title: string,
        public text: string,
        public severity: NotificationSeverity,
        public category: NotificationCategory
    ) {}
}
