import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

export class NhisReferralExtractionCommand {
    public patientVersionKey: PatientVersionKey | null = new PatientVersionKey();
    public pidTypeCode: string | null = null;
    public identifier: string | null = null;
    public practiceNumber: string | null = null;
    public doctorUin: string = '';
    public doctorDeputyUin: string | null = null;
    public nrn: string | null = null;
    public occurrenceDate: Date | null = null;
    public formType: string | null = null;
}
