<template>
    <data-table
        title="Периоди"
        :headers="scheduleHeaders"
        :items="value.schedules"
        :loading="$loading.isVisible"
        disable-pagination
    >
        <template v-if="allowWriteSelf" #actions>
            <v-form ref="createScheduleForm">
                <v-row>
                    <v-col>
                        <date-picker v-model="newSchedule.validFrom" label="От" :required="newScheduleHasValue" />
                    </v-col>
                    <v-col>
                        <date-picker v-model="newSchedule.validTo" label="До" :required="newScheduleHasValue" />
                    </v-col>
                    <v-col>
                        <btn action="New" :disabled="!newScheduleHasValue" @click="createDoctorDeputySchedule"></btn>
                    </v-col>
                </v-row>
            </v-form>
        </template>

        <template v-if="allowWriteSelf" #rowActions="{ item }">
            <template v-if="!item.statusCode">
                <btn icon action="Send" @click="postDoctorDeputyScheduleAction(item)">Изпращане към НЗИС</btn>
                <btn icon action="Delete" @click="deleteDoctorDeputySchedule(item)" />
            </template>
            <btn v-if="scheduleIsActive(item)" icon action="Delete" @click="postDoctorDeputyScheduleAction(item)">
                Премахване от НЗИС
            </btn>
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Ref } from 'vue-property-decorator';

    import { DoctorDeputyScheduleStatusCode } from '@/enum/Nomenclature/DoctorDeputyScheduleStatusCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { DoctorDeputyEditDto } from '@/model/Employee/Deputy/DoctorDeputyEditDto';
    import { DoctorDeputyScheduleCreateCommand } from '@/model/Employee/Deputy/Schedule/DoctorDeputyScheduleCreateCommand';
    import { DoctorDeputyScheduleDto } from '@/model/Employee/Deputy/Schedule/DoctorDeputyScheduleDto';
    import { NhisDoctorDeputyResponseDto } from '@/model/Nhis/DoctorDeputy/NhisDoctorDeputyResponseDto';
    import { doctorDeputyScheduleService } from '@/service/Employee/Deputy/DoctorDeputyScheduleService';
    import { nhisDoctorDeputyService } from '@/service/Nhis/NhisDoctorDeputyService';
    import { signXmlService } from '@/service/Report/SignXmlService';
    import { arrayUtil } from '@/util/ArrayUtil';
    import { formatters } from '@/util/Formatters';
    import { EmployeeBase } from '@/view/Employee/EmployeeBase';

    @Component
    export default class DoctorDeputySchedule extends EmployeeBase {
        @Prop({ required: true })
        private value!: DoctorDeputyEditDto;

        private newSchedule: DoctorDeputyScheduleDto = new DoctorDeputyScheduleDto();

        private scheduleHeaders: IDataTableHeader[] = [
            { text: 'От', value: 'validFrom', formatter: formatters.date },
            { text: 'До', value: 'validTo', formatter: formatters.date },
            { text: 'НЗИС статус', value: 'statusName' },
            { text: 'Изпратено на', value: 'statusTime', formatter: formatters.dateTime }
        ];

        private get allowWriteSelf() {
            return this.allowWrite(this.value.primaryDoctorEmployee.employeeSeqNumber);
        }

        private get newScheduleHasValue() {
            return Boolean(this.newSchedule.validFrom) || Boolean(this.newSchedule.validTo);
        }

        @Ref()
        private createScheduleForm!: { validate: () => boolean };

        private async createDoctorDeputySchedule() {
            this.createScheduleForm.validate();
            if (this.newSchedule.validFrom && this.newSchedule.validTo) {
                this.$loading.show();
                try {
                    const command = new DoctorDeputyScheduleCreateCommand();
                    command.doctorDeputyKey = this.value.doctorDeputyKey;
                    command.validFrom = this.newSchedule.validFrom;
                    command.validTo = this.newSchedule.validTo;

                    this.newSchedule.id = await doctorDeputyScheduleService.createDoctorDeputySchedule(command);
                    this.value.schedules.unshift(this.newSchedule);
                    // Полетата за нов график се почистват. Когато са празни, не са required и не светят в червено.
                    this.newSchedule = new DoctorDeputyScheduleDto();
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private scheduleIsActive(schedule: DoctorDeputyScheduleDto) {
            return schedule.statusCode === DoctorDeputyScheduleStatusCode.Active;
        }

        private async postDoctorDeputyScheduleAction(schedule: DoctorDeputyScheduleDto) {
            this.$loading.show();
            try {
                const scheduleId = schedule.id;
                const xmlString = await nhisDoctorDeputyService.getDoctorDeputyScheduleActionXml(scheduleId);
                const signResponse = await signXmlService.signXml(xmlString);
                if (!signResponse.isError && signResponse?.contents) {
                    const response = await nhisDoctorDeputyService.postDoctorDeputyScheduleAction(
                        signResponse.contents,
                        scheduleId
                    );
                    if (response.error?.length > 0) {
                        this.$notifier.showWarning('', `${response.error}`);
                    } else {
                        this.$notifier.showSuccess('', 'Успешна промяна');
                        this.updateScheduleStatus(schedule, response);
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private updateScheduleStatus(schedule: DoctorDeputyScheduleDto, response: NhisDoctorDeputyResponseDto) {
            schedule.statusCode = response.statusCode as DoctorDeputyScheduleStatusCode;
            schedule.statusName = response.statusName;
            schedule.statusTime = response.statusDate;
        }

        private async deleteDoctorDeputySchedule(schedule: DoctorDeputyScheduleDto) {
            this.$loading.show();
            try {
                await doctorDeputyScheduleService.deleteDoctorDeputySchedule(schedule.id);
                arrayUtil.removeFirst(this.value.schedules, schedule);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
