export enum NhisReferralStatusCode {
    Active = 'Active',
    Canceled = 'Canceled',
    Executed = 'Executed',
    Fetched = 'Fetched',
    Invalid = 'Invalid',
    PartiallyExecuted = 'PartiallyExecuted',
    Processing = 'Processing',
    Expired = 'Expired',
    ExpiredPartCompleted = 'ExpiredPartCompleted',
    Rejected = 'Rejected'
}
