export class InvoiceViewDto {
    public id: number = 0;
    public practiceId: number = 0;
    public invoiceNumber: string = '';
    public documentType: string = '';
    public documentTypeCode: string = '';
    public invoiceDate: Date | null = null;
    public totalPrice: number = 0;
    public eik: string | null = null;
    public recipient: string | null = null;
}
