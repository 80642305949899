<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">Раздел VІ. Дейност на психиатричните структури</h3></v-col>
            <v-col cols="12"><h3 class="mb-2">4. Съдебно-психиатрична експертиза</h3></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><DataTableComponent v-model="value.psychiatricExpertises" :headers="headers" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Report365PsychiatricExpertiseDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricExpertiseDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class PsychiatricExpertise extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Report365PsychiatricExpertiseDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: '', value: 'name' },
            { text: 'Шифър', value: 'code' },
            { text: 'Общо', value: 'total' },
            { text: 'В наказателен процес - всичко', value: 'totalPunitiveProcess' },
            { text: 'В наказателен процес - невменяеми', value: 'totalInsane' },
            { text: 'В граждански процес - всичко', value: 'totalCivilProcess' },
            { text: 'В граждански процес - недееспособни', value: 'totalIncompetent' },
            { text: 'По чл. 156 от зак. за здравето - всичко', value: 'totalHealthLaw' },
            { text: 'По чл. 156 от зак. за здравето - за прекратяване', value: 'totalHealthLawStopped' }
        ];
    }
</script>
