<template>
    <v-card>
        <v-card-title>Данни за електронен протокол</v-card-title>
        <v-card-text>
            <v-row v-if="value" dense>
                <v-col md="3">
                    <text-field
                        v-model="value.nrn"
                        :class="nrnBackground"
                        :rules="[$validator.nrn]"
                        label="НРН номер"
                        readonly
                    ></text-field>
                </v-col>
                <v-col md="3">
                    <text-field v-model="value.statusName" label="Статус на протокол" readonly></text-field>
                </v-col>
                <v-col md="3">
                    <date-field v-model="value.statusDate" label="Дата на последна проверка" />
                </v-col>
            </v-row>
            <v-card-actions>
                <v-row dense>
                    <v-col v-if="canPost" md="3">
                        <btn action="Send" block :disabled="disable || isLoading" @click="postProtocol" />
                    </v-col>
                    <v-col v-if="canCheck" md="3">
                        <btn
                            action="CheckDocument"
                            block
                            :disabled="disable || isLoading"
                            @click="checkProtocolStatus"
                        />
                    </v-col>
                    <v-col v-if="canCancel" md="3">
                        <btn action="Delete" block :disabled="disable || isLoading" @click="cancelProtocol">
                            Анулиране
                        </btn>
                    </v-col>
                    <v-col v-if="canFinish" md="3">
                        <btn action="CloseDocument" block :disabled="disable || isLoading" @click="finishProtocol">
                            Завършване
                        </btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhifProtocolStatusCode } from '@/enum/Nhif/NhifProtocolStatusCode';
    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { NhifProtocolInfoDto } from '@/model/Exam/DrugProtocol/NhifProtocolInfoDto';
    import { NhifCommand } from '@/model/Nhif/NhifCommand';
    import { NhifProtocolInfoCommand } from '@/model/Nhif/Protocol/NhifProtocolInfoCommand';
    import { NhifProtocolSearchCommand } from '@/model/Nhif/Protocol/NhifProtocolSearchCommand';
    import { notifierService } from '@/service/Infrastructure/NotifierService';
    import { nhifIntegrationService } from '@/service/Integration/NhifIntegrationService';
    import { nhifProtocolService } from '@/service/Nhif/NhifProtocolService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { loadingState } from '@/store/LoadingState';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class NhifProtocolInfo extends Vue {
        @Prop({ type: Object })
        private value!: NhifProtocolInfoDto;

        @Prop()
        private protocolId!: number;

        @Prop()
        private disable!: boolean;

        private get isLoading() {
            return loadingState.isVisible;
        }

        private get canPost() {
            return (
                this.protocolId > 0 &&
                !this.value.nrn &&
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private get canCheck() {
            return this.protocolId > 0 && this.value.nrn;
        }

        private get canCancel() {
            return (
                this.protocolId > 0 &&
                this.value.nrn &&
                this.value.statusCode !== NhifProtocolStatusCode.Canceled.toString()
            );
        }

        private get canFinish() {
            return (
                this.protocolId > 0 &&
                this.value.nrn &&
                this.value.statusCode !== NhifProtocolStatusCode.Canceled.toString() &&
                this.value.statusCode !== NhifProtocolStatusCode.Finished.toString()
            );
        }

        private get nrnBackground() {
            return this.value.statusCode === NhifProtocolStatusCode.AwaitingDocuments ||
                this.value.statusCode === NhifProtocolStatusCode.Approved ||
                this.value.statusCode === NhifProtocolStatusCode.Submitted ||
                this.value.statusCode === NhifProtocolStatusCode.Considered
                ? 'text-field-success'
                : this.value.statusCode === NhifProtocolStatusCode.WaitingDocuments ||
                    this.value.statusCode === NhifProtocolStatusCode.Canceled ||
                    this.value.statusCode === NhifProtocolStatusCode.Denied
                  ? 'text-field-error'
                  : '';
        }

        private async checkProtocolStatus() {
            loadingState.show();
            try {
                const command = new NhifProtocolSearchCommand(
                    currentPatientCache.value.key,
                    userContextCache.current?.doctorUin ?? '',
                    userContextCache.current?.deputyDoctorUin ?? null,
                    userContextCache.current?.deputyDoctorUin ? userContextCache.current?.deputyDoctorIsHired : null
                );
                command.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
                command.nrn = this.value.nrn;
                const jsonResponse = await nhifProtocolService.getStatusProtocolJson(command);
                if (jsonResponse.data) {
                    const response = await nhifIntegrationService.postStatusProtocolSearch(jsonResponse.data);
                    const result = await nhifProtocolService.setProtocolStatus(JSON.stringify(response?.data));
                    if (result?.error?.length > 0) {
                        await notifierService.showWarning('Внимание', `${result.error}`);
                    } else if (result) {
                        this.change(result);
                    }
                }
            } finally {
                loadingState.hide();
            }
        }

        private async postProtocol() {
            loadingState.show();
            try {
                const jsonResponse = await nhifProtocolService.getProtocolCreateJson(this.createNhifCommand());
                if (jsonResponse.data) {
                    const response = await nhifIntegrationService.postProtocolCreate(jsonResponse.data);
                    const result = await nhifProtocolService.getProtocolCreateResult(
                        new NhifProtocolInfoCommand(JSON.stringify(response?.data), this.protocolId)
                    );
                    if (result?.error?.length > 0) {
                        await notifierService.showWarning('Внимание', `${result.error}`);
                    } else if (result) {
                        this.value.nrn = result.nrn;
                        await this.checkProtocolStatus();
                    }
                }
            } finally {
                loadingState.hide();
            }
        }

        private async cancelProtocol() {
            loadingState.show();
            try {
                const jsonResponse = await nhifProtocolService.getProtocolCancelationJson(this.createNhifCommand());
                if (jsonResponse.data) {
                    const response = await nhifIntegrationService.postProtocolCancelation(jsonResponse.data);
                    const result = await nhifProtocolService.getProtocolCancelationResult(
                        new NhifProtocolInfoCommand(JSON.stringify(response?.data), this.protocolId)
                    );
                    if (result?.error?.length > 0) {
                        await notifierService.showWarning('Внимание', `${result.error}`);
                    } else if (result) {
                        await this.checkProtocolStatus();
                    }
                }
            } finally {
                loadingState.hide();
            }
        }

        private async finishProtocol() {
            loadingState.show();
            try {
                const jsonResponse = await nhifProtocolService.getProtocolFinishJson(this.createNhifCommand());
                if (jsonResponse.data) {
                    const response = await nhifIntegrationService.postProtocolFinish(jsonResponse.data);
                    const result = await nhifProtocolService.getProtocolFinishResult(
                        new NhifProtocolInfoCommand(JSON.stringify(response?.data), this.protocolId)
                    );
                    if (result?.error?.length > 0) {
                        await notifierService.showWarning('Внимание', `${result.error}`);
                    } else if (result) {
                        await this.checkProtocolStatus();
                    }
                }
            } finally {
                loadingState.hide();
            }
        }

        private change(data: NhifProtocolInfoDto) {
            this.$emit('input', data);
        }

        private createNhifCommand(): NhifCommand {
            const command = new NhifCommand(
                currentPatientCache.value.key,
                userContextCache.current?.doctorUin ?? null,
                userContextCache.current?.deputyDoctorUin ?? null
            );
            command.deputyDoctorIsHired = userContextCache.current?.deputyDoctorUin
                ? userContextCache.current?.deputyDoctorIsHired
                : null;
            command.entityId = this.protocolId;

            return command;
        }
    }
</script>
