export class ImmunizationDto {
    public id: number = 0;
    public immunizationDiseaseId: number = 0;
    public nhifCode: string = '';
    public rhiCode: string = '';
    public name: string = '';
    public vaccine: string = '';
    public application: string = '';
    public validFrom: Date | null = null;
    public validTo: Date | null = null;

    public get isValid(): string {
        if (this.validTo) {
            const today = new Date();
            return new Date(this.validTo).getTime() > today.getTime() ? 'Да' : 'Не';
        }
        return 'Да';
    }

    public get title() {
        return `${this.rhiCode ? `Код РЗИ: ${this.rhiCode},` : ''} ${
            this.nhifCode ? `Код НЗОК: ${this.nhifCode}, ` : ''
        }Име: ${this.name}`;
    }
}
