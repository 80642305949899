<template>
    <v-form ref="form" v-model="isFormValid">
        <v-card>
            <v-card-title>Болнично звено</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <text-field
                            v-model="practiceUnitWard.name"
                            :rules="[$validator.maxLength(100)]"
                            required
                            label="Име:"
                        />
                    </v-col>
                    <v-col cols="8">
                        <text-field
                            v-model="practiceUnitWard.location"
                            :rules="[$validator.maxLength(100)]"
                            label="Адрес:"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <text-field
                            v-model="practiceUnitWard.declaredBedCount"
                            label="Декларирани легла:"
                            :rules="[$validator.digitsOnly, $validator.maxValue(32767)]"
                        />
                    </v-col>
                    <v-col cols="8">
                        <text-field
                            v-model="practiceUnitWard.totalBedCount"
                            label="Общо легла:"
                            :rules="[$validator.digitsOnly, $validator.maxValue(32767)]"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col>
                    <btn action="Save" @click="save" />
                </v-col>
                <v-col>
                    <v-row class="float-right">
                        <btn action="Delete" @click="deleteWard" />
                    </v-row>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PracticeUnitWardDto } from '@/model/Practice/PracticeUnit/PracticeUnitWardDto';
    import { practiceUnitService } from '@/service/Practice/PracticeUnit/PracticeUnitService';

    @Component
    export default class PracticeUnitWard extends Vue {
        @Prop({ type: Number })
        private practiceId!: number;

        @Prop({ type: Number })
        private parentSeqNumber!: number | null;

        @Prop({ type: Boolean })
        private isEdit!: boolean;

        private isFormValid: boolean = false;
        private practiceUnitWard: PracticeUnitWardDto = new PracticeUnitWardDto();

        private mounted() {
            this.loadInisialData();
        }

        private async save() {
            this.$loading.show();
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isFormValid) {
                if (this.isEdit) {
                    await practiceUnitService.updateWard(this.practiceUnitWard);
                } else {
                    await practiceUnitService.createWard(this.practiceUnitWard);
                }
                this.$emit('reload');
                this.practiceUnitWard = new PracticeUnitWardDto();
                this.loadInisialData();
                this.$notifier.showSuccess('Успех', 'Данните са записани успешно');
            }
            this.$loading.hide();
        }

        private async loadInisialData(): Promise<void> {
            if (this.isEdit) {
                const result = this.parentSeqNumber
                    ? (await practiceUnitService.getWard(this.practiceId, this.parentSeqNumber)).data
                    : null;
                if (result) {
                    this.practiceUnitWard = result;
                }
            } else {
                this.practiceUnitWard.practiceId = this.practiceId;
                this.practiceUnitWard.parentSeqNumber = this.parentSeqNumber;
            }
        }

        private deleteWard() {
            this.$emit('deleteUnit');
        }
    }
</script>
