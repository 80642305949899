import { Type } from 'class-transformer';

import { Report365PsychiatricStructureRowDto } from '@/model/Report/Rhi/Report365/Report365PsychiatricStructureRowDto';

export class Report365PsychiatricStructureDto {
    public name: string = '';

    @Type(() => Report365PsychiatricStructureRowDto)
    public psychiatricStructures: Report365PsychiatricStructureRowDto[] = [];
}
