import { AxiosResponse } from 'axios';

import { CompanyTypeDto } from '@/model/Nomenclature/CompanyTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const companyTypeService = {
    getCompanyTypes(): Promise<AxiosResponse<CompanyTypeDto[]>> {
        return httpService.get('/CompanyType/GetCompanyTypes');
    }
};
