import { Type } from 'class-transformer';

import { Annex3ChildrenVisitRowDto } from '@/model/Report/Rhi/Annex3/Annex3ChildrenVisitRowDto';

export class Annex3ChildrenVisitDto {
    public name: string = '';

    @Type(() => Annex3ChildrenVisitRowDto)
    public childrenVisits: Annex3ChildrenVisitRowDto[] = [];
}
