<template>
    <v-toolbar-title>
        <v-tooltip v-if="currentPatientIsLoaded && allowWrite" bottom>
            <template #activator="{ on, attrs }">
                <v-btn icon small class="mr-2" v-bind="attrs" v-on="on" @click="edit">
                    <v-icon>mdi-badge-account-outline</v-icon>
                </v-btn>
            </template>
            <span>Текущо избран пациент</span>
        </v-tooltip>

        <span class="hidden-xs-only">{{ fullName }}</span>
        <span v-if="patientRegistrationType" class="feeDisengagement">{{ patientRegistrationType }}</span>
        <span :class="patientStatusStyle">{{ patientStatus }}</span>
        <CurrentPatientNotes v-if="patientNotes"></CurrentPatientNotes>
    </v-toolbar-title>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { PatientRegistrationTypeCode } from '@/enum/Patient/PatientRegistrationTypeCode';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { dateUtil } from '@/util/DateUtil';

    import CurrentPatientNotes from './CurrentPatientNotes.vue';

    @Component({ components: { CurrentPatientNotes } })
    export default class CurrentPatientTitle extends Vue {
        private get currentPatientIsLoaded() {
            return currentPatientCache.isLoaded;
        }

        private edit(): void {
            if (currentPatientCache.isLoaded) {
                const path = `/Patient/PersonalData/${currentPatientCache.value.key.patientId}`;
                if (this.$route.path !== path) {
                    this.$router.push(path);
                }
            }
        }

        private get allowWrite() {
            // Todo:
            return Boolean(this);
        }

        private get birthDate() {
            return currentPatientCache.value.personIdentification.birthDate;
        }

        private get patientNotes() {
            return currentPatientCache.value.notes;
        }

        private get patientStatus() {
            return this.currentPatientIsLoaded
                ? currentPatientCache.isFeeFree
                    ? ' (Освободен)'
                    : currentPatientCache.value.isRetired
                      ? ' (Пенсионер)'
                      : currentPatientCache.value.insuranceStatus.isInsured
                        ? '(Осигурен)'
                        : '(Неосигурен)'
                : '';
        }

        private get patientRegistrationType() {
            if (this.currentPatientIsLoaded) {
                switch (currentPatientCache.value.patientRegistrationTypeCode) {
                    case PatientRegistrationTypeCode.Deceased:
                        return ' (Починал)';
                    case PatientRegistrationTypeCode.Deregistered:
                        return ' (Отписан)';
                    case PatientRegistrationTypeCode.OneTimeVisit:
                        return ' (Инцидентно посещение)';
                    case PatientRegistrationTypeCode.PaidByPatient:
                        return ' (Свободен прием)';
                    case PatientRegistrationTypeCode.TemporaryRegistered:
                        return ' (Временно регистриран)';
                    default:
                        return '';
                }
            }

            return '';
        }

        private get patientAgeText() {
            if (currentPatientCache.value.age === 0 && this.birthDate) {
                const months = dateUtil.monthDifferenceBetweenTwoDates(this.birthDate ?? new Date(), new Date());
                return `${months} м.`;
            }
            const maxYearsToShowMonths = 7;
            const maxMonths = 12;
            if (
                currentPatientCache.value.age &&
                currentPatientCache.value.age < maxYearsToShowMonths &&
                this.birthDate
            ) {
                const currentDate = new Date();
                const birthDate = new Date(this.birthDate);
                let years = currentDate.getFullYear() - birthDate.getFullYear();
                let months = 0;
                if (currentDate.getMonth() > birthDate.getMonth()) {
                    months = this.calcMonthsNewerCurrentDate(currentDate, birthDate);
                } else if (currentDate.getMonth() === birthDate.getMonth()) {
                    if (currentDate.getDate() >= birthDate.getDate()) {
                        months = currentDate.getMonth() - birthDate.getMonth();
                    } else {
                        months = maxMonths - 1;
                        years = years - 1;
                    }
                } else {
                    months = this.calcMonths(currentDate, birthDate);
                    years = years - 1;
                }
                return `${years} г. и ${months} мес.`;
            }
            return `${currentPatientCache.value.age} г.`;
        }

        private calcMonthsNewerCurrentDate(currentDate: Date, birthDate: Date): number {
            let months = 0;
            if (currentDate.getDate() > birthDate.getDate()) {
                months = currentDate.getMonth() - birthDate.getMonth();
            } else {
                months = currentDate.getMonth() - birthDate.getMonth() - 1;
                //months = maxMonths - currentDate.getMonth() + 1;
                //years = years - 1;
            }
            return months;
        }

        private calcMonths(currentDate: Date, birthDate: Date): number {
            let months = 0;
            const maxMonths = 12;
            if (currentDate.getDate() >= birthDate.getDate()) {
                months = maxMonths - birthDate.getMonth() + currentDate.getMonth();
            } else {
                months = maxMonths - birthDate.getMonth() + currentDate.getMonth() - 1;
            }
            return months;
        }

        private get patientStatusStyle() {
            return this.patientStatus === '(Неосигурен)' ? 'feeDisengagement error' : 'feeDisengagement';
        }

        private get fullName(): string {
            return currentPatientCache.isLoaded
                ? `${currentPatientCache.value.firstName} ${currentPatientCache.value.lastName} - ${this.patientAgeText}`
                : 'Не е избран пациент';
        }
    }
</script>

<style>
    .feeDisengagement {
        font-size: 70%;
    }
</style>
