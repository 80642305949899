<template>
    <v-card-text>
        <v-card-title>
            <v-col cols="6" md="3" class="pa-1"> Кодове за назначени МДД: </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" md="3" class="pa-1">
                <fund-selection v-model="ref4.fundCode" @input="changeFund" />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" md="3" class="pa-1">
                <dropdown
                    v-model="labTestCategoryCode"
                    label="Категория лабораторни изследвания"
                    :items="labTestCategoryFiltered"
                    item-value="code"
                    required
                />
            </v-col>
            <btn v-if="value.length < 6 || ref4.fundCode !== 'Nhif'" icon action="New" @click="addLabTests" />
        </v-card-title>
        <alert :value="isThereDuplicateLabTest" type="warning" prominent>
            Не е препоръчително да се повтарят няколко еднакви изследвания
        </alert>
        <v-dialog v-model="patientFundsDialogIsVisible" persistent>
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat>
                        <v-toolbar-title>Избор на фонд</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <btn icon action="Close" @click="closeDialog" />
                    </v-toolbar>
                </v-card-title>
                <patient-fund :patient-id="currentPatient.key.patientId" @change="updateFunds" />
            </v-card>
        </v-dialog>
        <v-row v-if="showFundWarning">
            <v-col>
                <alert type="warning"> Пациентът не е осигурен по избраният фонд. </alert>
            </v-col>
            <v-col>
                <btn icon action="Edit" @click="openPatientFundsDialog"></btn>
            </v-col>
        </v-row>
        <v-card-text v-for="(fundService, i) in value" :key="i" class="no-padding">
            <v-layout align-end>
                <v-row class="flex-row align-center">
                    <v-col cols="1">
                        <span>{{ `${i + 1}` }}</span>
                    </v-col>
                    <v-col cols="9">
                        <FundServicePicker
                            v-model="value[i]"
                            label="МДД"
                            :required="i === 0"
                            :fund-code="ref4.fundCode"
                            :lab-test-category-code="labTestCategoryCode"
                            :date="date"
                        />
                    </v-col>
                    <v-col cols="1">
                        <text-field v-model="value[i].fundPrice" label="Цена" disabled />
                    </v-col>
                    <v-col cols="1">
                        <btn icon action="Delete" @click="removeLabTest(i)" />
                    </v-col>
                </v-row>
            </v-layout>
        </v-card-text>
        <v-row>
            <v-col cols="9" class="text-right"> Сума (общо) </v-col>
            <v-col cols="3"> {{ totalPrice }} </v-col>
        </v-row>
    </v-card-text>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import FundSelection from '@/component/Picker/FundPicker.vue';
    import FundServicePicker from '@/component/Picker/FundServicePicker.vue';
    import { Referral4Dto } from '@/model/Exam/Referral/Referral4Dto';
    import { FundDto } from '@/model/Nomenclature/FundDto';
    import { FundServiceDto } from '@/model/Nomenclature/FundServiceDto';
    import { LabTestCategoryDto } from '@/model/Nomenclature/LabTestCategoryDto';
    import { fundService } from '@/service/Nomenclature/FundService';
    import { fundServiceService } from '@/service/Nomenclature/FundServicesService';
    import { patientFundService } from '@/service/Patient/PatientFundService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import PatientFund from '@/view/Patient/PatientEdit/PatientFund.vue';

    @Component({
        components: {
            FundServicePicker,
            FundSelection,
            PatientFund
        }
    })
    export default class Referral4FundService extends Vue {
        @Prop({ required: true })
        public value!: FundServiceDto[];

        @Prop({ required: true })
        public date!: Date | null;

        @Prop({ required: true })
        public ref4!: Referral4Dto;

        private labTestCategoryCode: string | null = null;
        private labTestCategories: LabTestCategoryDto[] = [];
        private labTestCategoryFiltered: LabTestCategoryDto[] = [];
        private funds: FundDto[] = [];
        private showFundWarning: boolean = false;
        private patientFundsDialogIsVisible: boolean = false;

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get totalPrice() {
            const sum = this.value.reduce((accumulator: number, service: FundServiceDto) => {
                const fundServicePrice = parseFloat(service.fundPrice?.toString() ?? '');
                return accumulator + (isNaN(fundServicePrice) ? 0 : fundServicePrice);
            }, 0);
            const mathRoundConst = 100;
            return Math.round((sum + Number.EPSILON) * mathRoundConst) / mathRoundConst;
        }

        private addLabTests() {
            const service = new FundServiceDto();
            service.fundCode = this.ref4.fundCode;
            this.value.push(service);
        }

        private removeLabTest(index: number) {
            arrayUtil.removeAt(this.value, index);
        }

        private changeFund() {
            this.value.fill(new FundServiceDto());

            if (this.ref4.fundCode === 'Nhif') {
                this.labTestCategoryFiltered = this.labTestCategories.filter((fundsrv) => fundsrv.fundCode === 'Nhif');
            } else {
                this.labTestCategoryFiltered = this.labTestCategories.filter(
                    (fundsrv) => fundsrv.fundCode === 'Patient'
                );
            }

            // Винаги имаме пакет/категория, защото ползваме само НЗОК и Пациент ( за платени и за фондове )
            this.labTestCategoryCode = this.labTestCategoryFiltered[0].code;

            if (this.ref4.fundCode === 'Nhif') {
                // eslint-disable-next-line
                this.value.splice(6, this.value.length - 6);
            }
        }

        private get isThereDuplicateLabTest() {
            const valueAlreadySeen: string[] = [];
            for (let index = 0; index < this.value.length; index++) {
                const value = this.value[index].code;
                if (value) {
                    if (valueAlreadySeen.indexOf(value) >= 0) {
                        return true;
                    }
                    valueAlreadySeen.push(value);
                }
            }
            return false;
        }

        private labTestCategoryCodeOfAnyFundService() {
            if (this.ref4.fundCode === 'Nhif') {
                return this.value.find((fs) => fs.fundCode === 'Nhif')?.labTestCategoryCode ?? null;
            }
            return this.value.find((fs) => fs.fundCode === 'Patient')?.labTestCategoryCode ?? null;
        }

        @Watch('value', { deep: true })
        private async onInitialStart() {
            await this.loadLabTestCategories();
            this.filterCategory();
            this.setLabTestCategoryCodeInit();
        }

        private setLabTestCategoryCodeInit() {
            if (this.value[0].code !== null) {
                if (this.ref4.fundCode === 'Nhif') {
                    this.labTestCategoryCode = this.value[0].fundServicePackageCode;
                } else {
                    this.labTestCategoryCode = this.value[0].labTestCategoryCode;
                }
            } else {
                this.labTestCategoryCode = this.labTestCategoryFiltered[0].code;
            }
        }

        private filterCategory() {
            if (this.ref4.fundCode === 'Nhif') {
                this.labTestCategoryFiltered = this.labTestCategories.filter((fundsrv) => fundsrv.fundCode === 'Nhif');
            } else {
                this.labTestCategoryFiltered = this.labTestCategories.filter(
                    (fundsrv) => fundsrv.fundCode === 'Patient'
                );
            }
        }

        private async loadLabTestCategories() {
            this.labTestCategories = await fundServiceService.getFundServicePackages();
        }

        async checkPatientFunds() {
            this.showFundWarning = false;
            if (this.funds.length === 0) {
                const fundsResponse = (await fundService.getFunds()).data;
                this.funds = fundsResponse ?? [];
            }

            const selectedFund = this.funds.find((fund) => fund.code === this.ref4.fundCode);
            if (selectedFund?.nhisCode === '3') {
                const response = await patientFundService.getPatientActiveFunds(
                    currentPatientCache.value.key.patientId
                );
                if (response) {
                    this.showFundWarning = !response.some((fund) => fund.fundCode === this.ref4.fundCode);
                }
            }
        }

        private openPatientFundsDialog() {
            this.patientFundsDialogIsVisible = true;
        }

        private async updateFunds() {
            this.patientFundsDialogIsVisible = false;
            await this.checkPatientFunds();
        }

        private async closeDialog() {
            this.patientFundsDialogIsVisible = false;
            await this.checkPatientFunds();
        }

        @Watch('ref4.fundCode')
        async onFundCodeChange() {
            await this.checkPatientFunds();
        }
    }
</script>
