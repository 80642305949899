<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Лична здравно-профилактична карта"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <main-title>Лична здравно-профилактична карта</main-title>
            <v-card-text>
                <v-row v-if="document.id <= 0">
                    <v-col cols="6" md="5">
                        <btn action="Edit" @click="fillFromPrevious()">Зареждане на данни от предишна карта</btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6"
                        ><text-field v-model="document.schoolName" label="Детско или учебно заведение:"
                    /></v-col>
                    <v-col cols="12" md="6"><text-field v-model="document.schoolAddress" label="Адрес:" /></v-col>
                    <v-spacer />
                </v-row>
                <v-row>
                    <v-col cols="12" md="3"><text-field v-model="document.number" label="Номер:" /></v-col>
                    <v-col cols="12" md="3"
                        ><date-picker v-model="document.issueDate" disabled label="Издадена на:" />
                    </v-col>
                    <v-col cols="12" md="3"><text-field v-model="doctorName" disabled label="Издадена от:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6"
                        ><text-field
                            :value="`${currentPatient.firstName} ${currentPatient.middleName} ${currentPatient.lastName}`"
                            disabled
                            label="Имена:"
                    /></v-col>
                    <v-col cols="12" md="3"
                        ><text-field v-model="currentPatient.personIdentification.identifier" disabled label="ЕГН:"
                    /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="9"
                        ><text-field :value="currentPatientAddress" disabled label="Адрес:"
                    /></v-col>
                    <v-col cols="12" md="3"
                        ><text-field v-model="currentPatient.contact.mobilePhone" disabled label="Телефон:"
                    /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="5"><text-field v-model="document.motherName" label="Майка:" /></v-col>
                    <v-col cols="12" md="5"><text-field v-model="document.motherAddress" label="Адрес:" /></v-col>
                    <v-col cols="12" md="2"><text-field v-model="document.motherPhone" label="Тел.:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="9"
                        ><text-field v-model="document.motherWorkplace" label="Месторабота:"
                    /></v-col>
                    <v-col cols="12" md="3"
                        ><text-field v-model="document.motherWorkPhone" label="Сл. телефон:"
                    /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="5"><text-field v-model="document.fatherName" label="Баща:" /></v-col>
                    <v-col cols="12" md="5"><text-field v-model="document.fatherAddress" label="Адрес:" /></v-col>
                    <v-col cols="12" md="2"><text-field v-model="document.fatherPhone" label="Тел.:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="9"
                        ><text-field v-model="document.fatherWorkplace" label="Месторабота:"
                    /></v-col>
                    <v-col cols="12" md="3"
                        ><text-field v-model="document.fatherWorkPhone" label="Сл. телефон:"
                    /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><text-field v-model="document.familySymptoms" label="Фамилна обремененост:"
                    /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-textarea v-model="document.pastDisease" label="Минали заболявания и функционални смущения:"
                    /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6"><text-field v-model="document.bloodType" label="Кръвна група:" /></v-col>
                    <v-col cols="12" md="6"><text-field v-model="document.rh" label="RH:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"><v-textarea v-model="document.alergy" label="Алергии:" /></v-col>
                </v-row>
                <v-row>
                    <h4>Здравословно състояние</h4>
                </v-row>
                <v-row>
                    <v-col cols="12" md="3"
                        ><date-picker v-model="document.examDate" label="Дата на преглед:" />
                    </v-col>
                    <v-col cols="12" md="9"><text-field v-model="document.diagnose" label="Диагноза:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"><text-field v-model="document.practice" label="Лечебно заведение:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><text-field v-model="document.treatmentResult" label="Резултат от лечението:"
                    /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"><text-field v-model="document.dispensary" label="Диспансеризация:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><text-field
                            v-model="document.otherActivity"
                            label="Необходимост от провеждане на доп. мероприятия:"
                    /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea v-model="document.immunization" label="Проведени имунизации:"
                    /></v-col>
                </v-row>
                <v-row>
                    <h4>Проведени профилактични прегледи</h4>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-textarea v-model="document.preventiveExam" label="Проведени проф. прегледи:"
                    /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4"><text-field v-model="document.height" type="number" label="Ръст:" /></v-col>
                    <v-col cols="12" md="4"
                        ><text-field v-model="document.weight" type="number" label="Тегло:"
                    /></v-col>
                    <v-col cols="12" md="4"
                        ><text-field v-model="document.bodySize" label="Обиколка(вдишв., спокойно, издишв.):"
                    /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"><text-field v-model="document.muscleStrength" label="Мускулна сила:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6"><text-field v-model="document.run" label="Гладко бягане:" /></v-col>
                    <v-col cols="12" md="6"><text-field v-model="document.jump" label="Скок на дължина:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6"
                        ><text-field v-model="document.throw" label="Хвърляне на плътна топка:"
                    /></v-col>
                    <v-col cols="12" md="6"><text-field v-model="document.crouch" label="Брой клякания:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"><text-field v-model="document.examSummary" label="Данни от преглед:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><text-field v-model="document.paraclinicalTest" label="Параклинични изследвания:"
                    /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"><text-field v-model="document.mentalHealth" label="Психично развитие:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><text-field v-model="document.newFoundDisease" label="Новооткрити заболявания:"
                    /></v-col>
                </v-row>
                <v-row>
                    <h4>Вредни навици и пристрастявания</h4>
                </v-row>
                <v-row>
                    <v-col cols="12"><v-textarea v-model="document.badHabit" label="Вредни навици:" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-textarea
                            v-model="document.workAvoidBadHabit"
                            label="Индивидуална работа за предотвратяването им:"
                    /></v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { ChildHealthCardDto } from '@/model/Exam/ChildHealthCard/ChildHealthCardDto';
    import { childHealthCardService } from '@/service/Exam/ChildHealthCardService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: { UnsavedChanges, ExamLayout },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class ChildHealthCard extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private examId!: number;

        @Prop()
        private documentId!: number;

        private document: ChildHealthCardDto = new ChildHealthCardDto();
        private initialValue: ChildHealthCardDto = new ChildHealthCardDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get currentPatientAddress() {
            return `${this.currentPatient.address.cityName} ${
                this.currentPatient.address.street !== null ? this.currentPatient.address.street : ' '
            } ${this.currentPatient.address.streetNumber !== null ? this.currentPatient.address.streetNumber : ' '} ${
                this.currentPatient.address.neighborhood !== null ? this.currentPatient.address.neighborhood : ' '
            }`;
        }

        private get isDeputyDoctor() {
            return Boolean(
                currentVisitCache.isLoaded
                    ? currentVisitCache.value.exam?.deputyDoctorUin
                    : userContextCache.current?.deputyDoctorId
            );
        }

        private get deputyDoctorName() {
            return currentVisitCache.isLoaded
                ? currentVisitCache.value.exam?.deputyDoctorName
                : userContextCache.current?.deputyDoctorName;
        }

        private get doctorName() {
            if (this.isDeputyDoctor) {
                return this.deputyDoctorName;
            }

            return currentVisitCache.isLoaded
                ? currentVisitCache.value.doctorNames
                : userContextCache.current?.doctorName;
        }

        private get currentVisit() {
            return currentVisitCache.value;
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                if (!this.documentId) {
                    const responseExam = await childHealthCardService.getChildHealthCardInfoByExamId(this.examId);
                    this.document = responseExam;
                } else {
                    const response = await childHealthCardService.getChildHealthCard(this.documentId);
                    this.document = response;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async fillFromPrevious() {
            if (currentPatientCache.isLoaded && currentVisitCache.isLoaded) {
                const response = await childHealthCardService.getPreviousChildHealthCard(
                    currentPatientCache.value.key.patientId
                );
                if (response) {
                    for (let keyIndex = 0; keyIndex < Object.keys(this.document)?.length; keyIndex++) {
                        const property = Object.keys(this.document)[keyIndex] as keyof ChildHealthCardDto;
                        if (response[property] !== null && response[property] !== 0) {
                            this.document[property] = response[property] as never;
                        }
                    }
                }
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (documentId === 0) {
                    const response = await childHealthCardService.createChildHealthCard(this.document);
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit(
                        'create-referral-event',
                        documentId,
                        'ChildHealthCard',
                        'Лична здравно-профилактична карта'
                    );
                } else {
                    await childHealthCardService.updateChildHealthCard(this.document);
                    this.copyObject();
                    eventBus.$emit('update-referral-event', 'Лична здравно-профилактична карта');
                }

                this.$router.push(`/Exam/ChildHealthCard/Edit/${documentId}`);
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                await childHealthCardService.deleteChildHealthCard(documentId);
                this.copyObject();
                this.$router.push(`/Exam/ChildHealthCard/Create/${currentVisitCache.value.id}`);
                eventBus.$emit(
                    'delete-referral-event',
                    documentId,
                    'ChildHealthCard',
                    'Лична здравно-профилактична карта'
                );
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.ChildHealthCard/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.ChildHealthCard';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
