import { plainToInstance } from 'class-transformer';

import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
import { IDropdownSearchService } from '@/service/Dropdown/IDropdownSearchService';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhisDrugService: IDropdownSearchService<NhifDrugDto> = {
    getItemsById(): Promise<NhifDrugDto[]> {
        return Promise.resolve([]);
    },

    async searchItems(filters: []): Promise<NhifDrugDto[]> {
        const filterIndex = 0;
        const formTypeIndex = 1;
        const issueDateIndex = 2;
        const response = await httpService.get<NhifDrugDto[]>('/NhisDrug/SearchNhisDrugs', {
            params: {
                filter: filters.at(filterIndex),
                formType: filters.at(formTypeIndex),
                issueDate: filters.at(issueDateIndex)
            }
        });
        return plainToInstance(NhifDrugDto, response.data);
    }
};
