enum StagedEpicrisisExamFilterTypeCode {
    Current = 'Current',
    Last = 'Last',
    Period = 'Period',
    Selected = 'Selected',
    All = 'All'
}

class StagedEpicrisisExamFilterType {
    constructor(
        public code: StagedEpicrisisExamFilterTypeCode,
        public name: string,
        public supportsExam: boolean,
        public supportsNoExam: boolean
    ) {}
}

const examFilterTypes: StagedEpicrisisExamFilterType[] = [
    new StagedEpicrisisExamFilterType(StagedEpicrisisExamFilterTypeCode.Current, 'За текущ АЛ', true, false),
    new StagedEpicrisisExamFilterType(StagedEpicrisisExamFilterTypeCode.Last, 'За последен АЛ', false, true),
    new StagedEpicrisisExamFilterType(StagedEpicrisisExamFilterTypeCode.Period, 'За избран период', true, true),
    new StagedEpicrisisExamFilterType(StagedEpicrisisExamFilterTypeCode.Selected, 'За избрани АЛ', true, true),
    new StagedEpicrisisExamFilterType(StagedEpicrisisExamFilterTypeCode.All, 'За всички АЛ', true, true)
];

export { examFilterTypes, StagedEpicrisisExamFilterTypeCode };
