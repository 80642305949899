<template>
    <v-row>
        <v-col cols="12" class="pt-10">
            <v-form ref="form" v-model="isFormValid">
                <patient-reception v-model="command" :patient-id="dto.patientId" />
            </v-form>
        </v-col>
        <v-col cols="12" class="pt-10">
            <v-form ref="examForm" v-model="examFormIsFormValid">
                <v-row>
                    <v-col cols="4">
                        <dropdown
                            v-model="dto.fundCode"
                            :items="fundTypes"
                            item-text="name"
                            item-value="code"
                            label="Финансиране"
                            required
                            clearable
                        ></dropdown>
                    </v-col>

                    <v-col cols="4">
                        <text-field v-if="examScheduleId" readonly label="Лекар" :value="dto.doctorName"></text-field>
                        <text-field v-else readonly label="Лекар" :value="eventDto.doctorName"></text-field>
                    </v-col>
                    <v-col cols="4">
                        <text-field readonly label="Дата и час" :value="selectedDateTimeFormatted"></text-field>
                    </v-col>
                    <v-col cols="4">
                        <text-field
                            v-if="examScheduleId"
                            readonly
                            label="Специалност"
                            :value="dto.specialtyName"
                        ></text-field>
                        <text-field v-else readonly label="Специалност" :value="eventDto.specialtyName"></text-field>
                    </v-col>
                    <v-col>
                        <text-field
                            v-if="examScheduleId"
                            readonly
                            label="Тип преглед"
                            :value="dto.workTimeVisitReasonName"
                        ></text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea v-model="dto.notes" label="Бележки" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <btn action="Back" @click="goBack" />
                        <btn v-if="examScheduleId" action="Save" @click="update" />
                        <btn v-else action="Save" @click="save" />
                        <btn v-if="examScheduleId && command.patient.key.patientId" action="Visit" @click="openExam" />
                        <btn v-if="examScheduleId && command.patient.key.patientId" action="Pay" @click="openReception"
                            >Регистратура</btn
                        >
                        <btn v-if="examScheduleId" style="float: right" action="Delete" @click="deleteDto" />
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { ExamScheduleDto } from '@/model/ExamSchedule/ExamScheduleDto';
    import { ScheduledExamCalendarDto } from '@/model/ExamSchedule/ScheduledExamCalendarDto';
    import { FundDto } from '@/model/Nomenclature/FundDto';
    import { ReceptionServiceCreateCommand } from '@/model/Reception/Service/ReceptionServiceCreateCommand';
    import { EventEditDto } from '@/model/WorkTime/EventEditDto';
    import { examScheduleService } from '@/service/ExamSchedule/ExamScheduleService';
    import { fundService } from '@/service/Nomenclature/FundService';
    import { patientService } from '@/service/Patient/PatientService';
    import { workTimeService } from '@/service/WorkTime/WorkTimeService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';
    import PatientReception from '@/view/Reception/Service/PatientReception.vue';

    @Component({
        components: {
            PatientReception
        }
    })
    export default class ExamScheduleCreate extends Vue {
        @Prop({ required: true })
        private eventId!: number | null;

        @Prop({ required: true })
        private date!: Date | null;

        @Prop()
        private examScheduleId!: number | null;

        private command: ReceptionServiceCreateCommand = new ReceptionServiceCreateCommand();
        private dto: ScheduledExamCalendarDto = new ScheduledExamCalendarDto();
        private eventDto: EventEditDto = new EventEditDto();
        private fundTypes: FundDto[] = [];
        private selectedDateTimeFormatted: string | null = null;
        private isFormValid: boolean = false;
        private examFormIsFormValid: boolean = false;
        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get hasReception() {
            return userContextCache.current?.hasReception;
        }

        private async mounted() {
            await this.load();
            if (this.dto.patientId) {
                await currentPatientCache.loadLastVersion(this.dto.patientId as number);
                this.command.patient = currentPatientCache.value;
            }
        }

        private async load() {
            this.$loading.show();
            try {
                this.fundTypes = (await fundService.getFunds()).data;
                if (this.examScheduleId) {
                    this.dto = (await examScheduleService.getExamSchedule(this.examScheduleId)).data;
                } else {
                    await this.loadEvent();
                }
                this.selectedDateTimeFormatted = dateUtil.formatBgDateTime(this.date);
            } finally {
                this.$loading.hide();
            }
        }

        private async loadEvent() {
            this.$loading.show();
            try {
                if (this.eventId) {
                    this.eventDto = await workTimeService.getEventById(this.eventId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private isPatientPopulated() {
            return Boolean(
                (this.command.patient.personIdentification.identifier ||
                    this.command.patient.firstName ||
                    this.command.patient.lastName ||
                    this.command.patient.address.cityId) &&
                    this.command.patient.personIdentification.pidTypeCode
            );
        }

        private async save() {
            this.$loading.show();
            try {
                if (this.isPatientPopulated()) {
                    (this.$refs.form as Vue & { validate: () => boolean }).validate();
                    if (this.isFormValid) {
                        if (this.command.patient.key.patientId <= 0) {
                            this.command.patient.key.practiceId =
                                this.eventDto.practiceId <= 0
                                    ? (userContextCache.currentPracticeId ?? 0)
                                    : this.eventDto.practiceId;
                            const patientId = await patientService.createPatient(this.command.patient);
                            currentPatientCache.loadLastVersion(patientId);
                            this.command.patient.key.patientId = patientId;
                        }
                    }
                }
                (this.$refs.examForm as Vue & { validate: () => boolean }).validate();
                if (this.examFormIsFormValid) {
                    await examScheduleService.createExamSchedule(this.setData());
                    this.$notifier.showSuccess('Успех', 'Успешно записана заявка за преглед');
                    this.goBack();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private setData() {
            const model = new ExamScheduleDto();
            model.id = (this.dto.id as number) ?? 0;
            model.practiceId = (this.dto.practiceId as number) ?? userContextCache.current?.practiceId;
            model.doctorId = this.eventDto.doctorId ?? userContextCache.current?.doctorId;
            model.examDateTime = new Date(this.date as Date);
            model.specialtyCode = this.dto.specialtyCode ?? (this.eventDto.specialtyCode as SpecialtyCode);
            model.workTimeVisitReasonId = this.dto.workTimeVisitReasonId ?? this.eventDto.workTimeVisitReasonId;
            model.patientId = this.command.patient.key.patientId ?? null;
            model.notes = this.dto.notes;
            model.fundCode = this.dto.fundCode;

            return model;
        }

        private async update() {
            this.$loading.show();
            try {
                if (this.isPatientPopulated()) {
                    (this.$refs.form as Vue & { validate: () => boolean }).validate();
                    (this.$refs.examForm as Vue & { validate: () => boolean }).validate();
                    if (this.isFormValid) {
                        if (this.command.patient.key.patientId <= 0) {
                            this.command.patient.key.practiceId =
                                this.eventDto.practiceId <= 0
                                    ? (userContextCache.currentPracticeId ?? 0)
                                    : this.eventDto.practiceId;
                            const patientId = await patientService.createPatient(this.command.patient);
                            currentPatientCache.loadLastVersion(patientId);
                            this.command.patient.key.patientId = patientId;
                        }
                    } else {
                        return;
                    }
                }
                await examScheduleService.updateExamSchedule(this.setData());
                this.$notifier.showSuccess('Успех', 'Успешно редактиране на заявка за преглед');
            } finally {
                this.$loading.hide();
                this.goBack();
            }
        }

        private goBack() {
            this.$router.push(
                `/Reception/ExamScheduleNew/${this.eventId}/${new Date(this.date as Date).toISOString()}`
            );
        }

        private async openReception() {
            await currentPatientCache.loadLastVersion(this.dto.patientId as number);
            this.$router.push('/Reception/Service/Create');
        }

        private async openExam() {
            await currentPatientCache.loadLastVersion(this.dto.patientId as number);
            this.$router.push('/Exam/AmbulatorySheet/Create');
        }

        private async deleteDto() {
            this.$loading.show();
            try {
                await examScheduleService.deleteExamSchedule(this.examScheduleId as number);
                this.$notifier.showSuccess('Успех', 'Успешно изтриване на заявка за преглед');
            } finally {
                this.$loading.hide();
                this.goBack();
            }
        }
    }
</script>
