import { Type } from 'class-transformer';

import { Report365StaffRowDto } from '@/model/Report/Rhi/Report365/Report365StaffRowDto';

export class Report365StaffDto {
    public name: string = '';

    @Type(() => Report365StaffRowDto)
    public report365Staff: Report365StaffRowDto[] = [];
}
