<template>
    <div>
        <v-row no-gutters>
            <v-col md="4" class="no-margin-no-padding">
                <v-checkbox
                    v-model="value.hasMedicalExpertise"
                    class="no-margin-no-padding"
                    disabled
                    label="Талон за МЕ"
                    hide-details
                ></v-checkbox>
            </v-col>
            <v-col md="4" class="no-margin-no-padding">
                <text-field
                    v-model="value.medicalExpertiseNumber"
                    class="no-margin-no-padding shrink"
                    disabled
                    label="Номер"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col md="4" class="no-margin-no-padding">
                <v-checkbox
                    v-model="value.hasHospitalization"
                    class="no-margin-no-padding"
                    disabled
                    label="направление за хоспитализация"
                    hide-details
                ></v-checkbox>
            </v-col>
            <v-col md="4" class="no-margin-no-padding">
                <v-checkbox
                    v-model="value.hasTelk"
                    class="no-margin-no-padding"
                    disabled
                    label="талон за ТЕЛК"
                    hide-details
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col md="4" class="no-margin-no-padding">
                <v-checkbox
                    class="no-margin-no-padding"
                    :input-value="value.hasSickSheet"
                    disabled
                    label="болн. лист"
                    hide-details
                ></v-checkbox>
            </v-col>
            <v-col md="4" class="no-margin-no-padding">
                <text-field v-model="value.sickSheetNumber" class="no-margin-no-padding" disabled label="Номер" />
            </v-col>
            <v-col md="2" class="no-margin-no-padding">
                <text-field v-model="value.sickSheetDays" class="no-margin-no-padding" disabled label="Дни" />
            </v-col>
            <v-col md="2" class="no-margin-no-padding">
                <text-field v-model="value.sickSheetIcdCode" class="no-margin-no-padding" disabled label="МКБ" />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col md="2" class="no-margin-no-padding">
                <date-field v-model="value.sickSheetStartDate" class="no-margin-no-padding" disabled label="от" />
            </v-col>
            <v-col md="2" class="no-margin-no-padding">
                <date-field v-model="value.sickSheetEndDate" class="no-margin-no-padding" disabled label="до" />
            </v-col>
            <v-col md="4" class="no-margin-no-padding">
                <v-checkbox
                    class="no-margin-no-padding"
                    :input-value="value.sickSheetIsContinuation != null && !value.sickSheetIsContinuation"
                    dense
                    disabled
                    label="първичен"
                    hide-details
                ></v-checkbox>
            </v-col>
            <v-col md="4" class="no-margin-no-padding">
                <v-checkbox
                    class="no-margin-no-padding"
                    :input-value="value.sickSheetIsContinuation != null && value.sickSheetIsContinuation"
                    dense
                    disabled
                    label="продължение"
                    hide-details
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="no-margin-no-padding" md="4">
                <v-checkbox
                    v-model="value.hasPrescription"
                    class="no-margin-no-padding"
                    dense
                    disabled
                    label="Рецепта"
                ></v-checkbox>
            </v-col>
            <v-col class="no-margin-no-padding" md="4">
                <v-checkbox
                    class="no-margin-no-padding"
                    :input-value="value.hasPrescriptionBook"
                    dense
                    disabled
                    label="Рецептурна книжка"
                    hide-details
                ></v-checkbox>
            </v-col>
            <v-col cols="12" md="4" class="no-margin-no-padding">
                <text-field
                    v-model="value.prescriptionBookNumber"
                    class="no-margin-no-padding"
                    disabled
                    label="Номер"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col md="4" class="no-margin-no-padding">
                <v-checkbox
                    v-model="value.hasQuickNotification"
                    class="no-margin-no-padding"
                    dense
                    disabled
                    label="бързо известие"
                    hide-details
                ></v-checkbox>
            </v-col>
            <v-col md="4" class="no-margin-no-padding">
                <v-checkbox
                    v-model="value.hasStagedEpicrisis"
                    class="no-margin-no-padding"
                    dense
                    disabled
                    label="етапна епикриза"
                    hide-details
                ></v-checkbox>
            </v-col>
            <v-col md="4" class="no-margin-no-padding">
                <v-checkbox
                    v-model="value.hasMedicalNote"
                    class="no-margin-no-padding"
                    dense
                    disabled
                    label="мед.бележка"
                    hide-details
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-row v-if="isFiscalPrinterVisible" no-gutters class="align-baseline">
            <v-col md="4" class="no-margin-no-padding">
                <text-field
                    v-model="value.fiscalReceiptNumber"
                    class="no-margin-no-padding"
                    label="Номер на касова бележка"
                />
            </v-col>
            <v-col md="4" class="no-margin-no-padding">
                <dropdown
                    v-model="selectedFiscalMemory"
                    class="no-margin-no-padding"
                    :items="fiscalMemories"
                    return-object
                    item-text="code"
                    label="Фискално устройство"
                ></dropdown>
            </v-col>
            <v-card-actions>
                <v-col md="4" class="no-margin-no-padding">
                    <btn v-if="isFiscalPrinterButtonEnable()" action="Print" @click="printBon">Касова бележка</btn>
                </v-col>
            </v-card-actions>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ArticleDto } from '@/model/CashRegister/ArticleDto';
    import { FiscalReceiptDto } from '@/model/CashRegister/FiscalReceiptDto';
    import { PaymentDto } from '@/model/CashRegister/PaymentDto';
    import { ExamDto } from '@/model/Exam/ExamDto';
    import { FiscalMemoryDto } from '@/model/Practice/Fiscal/FiscalMemoryDto';
    import { cashRegisterService } from '@/service/LocalServer/CashRegisterService';
    import { fiscalDeviceOperatorService } from '@/service/Practice/Fiscal/FiscalDeviceOperatorService';
    import { fiscalMemoryService } from '@/service/Practice/Fiscal/FiscalMemoryService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    const priceRetired: number = 1.0;
    const price: number = 2.9;
    const articulName = 'Потребителска такса';
    const paymentMethod = 'Cash';

    @Component
    export default class AmbulatorySheetIssuedForm extends Vue {
        @Prop({ type: Object })
        value!: ExamDto;

        private fiscalMemories: FiscalMemoryDto[] = [];

        private get selectedFiscalMemory() {
            return (
                this.fiscalMemories.find(
                    (fm) =>
                        fm.deviceSeqNumber === this.value.fiscalDeviceSeqNumber &&
                        fm.code === this.value.fiscalMemoryCode
                ) ?? null
            );
        }

        private set selectedFiscalMemory(item: FiscalMemoryDto | null) {
            if (item) {
                this.value.fiscalDeviceSeqNumber = item.deviceSeqNumber;
                this.value.fiscalMemoryCode = item.code;
            } else {
                this.value.fiscalDeviceSeqNumber = null;
                this.value.fiscalMemoryCode = null;
            }
        }

        private isFiscalPrinterButtonEnable() {
            ///dkaraminov - Добавям и втората проверка, защото при копиране на лист полето е null и проверката не работи
            return this.value.fiscalReceiptNumber === '' || this.value.fiscalReceiptNumber === null;
        }

        private isFiscalPrinterVisible: boolean = false;

        private async printBon() {
            if (
                !userContextCache.currentPracticeId ||
                !this.value.fiscalDeviceSeqNumber ||
                !this.value.fiscalMemoryCode
            ) {
                return;
            }
            const response = await fiscalDeviceOperatorService.getDeviceLogin(
                userContextCache.currentPracticeId ?? 0,
                this.value.fiscalDeviceSeqNumber,
                this.value.fiscalMemoryCode
            );
            const operator = response.data;
            if (!operator) {
                this.$notifier.showWarning('Внимание', `Не сте регистриран като оператор за това фискално устройство.`);
                return;
            }
            const fiscalReceipt = await this.createBon(operator.operator, operator.password);
            if (fiscalReceipt) {
                const result = await cashRegisterService.fiscalReceipt(fiscalReceipt);
                if (result.resultCode === 'Ok') {
                    this.value.fiscalReceiptNumber = result.bonNo;
                    if (this.value.id) {
                        this.$emit('save-fiscal-receipt');
                    }
                } else if (result.resultCode === 'Error') {
                    this.$notifier.showWarning('Внимание', result.errorMessage);
                }
            }
        }

        private async createBon(operatorNumber: string, operatorPassword: string) {
            const mem = await this.getFiscalMemory();
            const fiscalReceipt = new FiscalReceiptDto();
            fiscalReceipt.operatorNumber = operatorNumber;
            fiscalReceipt.operatorPassword = operatorPassword;
            fiscalReceipt.unpNumber = '';
            fiscalReceipt.deviceMemoryNumber = mem.code;
            fiscalReceipt.deviceMemoryNumber = '';
            fiscalReceipt.items = [];
            fiscalReceipt.items[0] = new ArticleDto();
            fiscalReceipt.items[0].name = articulName;
            fiscalReceipt.items[0].vatGroup = 'А';
            fiscalReceipt.items[0].qty = 1;
            fiscalReceipt.items[0].price = currentPatientCache.value.isRetired ? priceRetired : price;
            fiscalReceipt.payments = [];
            fiscalReceipt.payments[0] = new PaymentDto();
            fiscalReceipt.payments[0].amount = currentPatientCache.value.isRetired ? priceRetired : price;
            fiscalReceipt.payments[0].paymentMethod = paymentMethod;
            return fiscalReceipt;
        }

        private async getFiscalMemory() {
            if (userContextCache.currentPracticeId && this.value.fiscalDeviceSeqNumber && this.value.fiscalMemoryCode) {
                const response = await fiscalMemoryService.getFiscalMemory(
                    userContextCache.currentPracticeId,
                    this.value.fiscalDeviceSeqNumber,
                    this.value.fiscalMemoryCode
                );
                return response.data;
            }
            return new FiscalMemoryDto();
        }

        private async loadFiscalMemory(practiceId: number) {
            const responsePracticeFiscalMemories = await fiscalMemoryService.getActiveFiscalMemories(
                practiceId,
                this.value.startDateTime,
                userContextCache.current?.employeeSeqNumber ?? 0
            );
            this.fiscalMemories = responsePracticeFiscalMemories.data;
            [this.selectedFiscalMemory] = this.fiscalMemories;

            const resultListDevices = await cashRegisterService.getListDevices();
            if (resultListDevices?.deviceList && resultListDevices?.deviceList.length > 0) {
                this.fiscalMemories = [];
                for (let idxDb = 0; idxDb < responsePracticeFiscalMemories.data.length; idxDb++) {
                    for (let idxSv = 0; idxSv < resultListDevices?.deviceList.length; idxSv++) {
                        if (
                            resultListDevices.deviceList[idxSv].deviceMemoryNumber ===
                            responsePracticeFiscalMemories.data[idxDb].code
                        ) {
                            this.fiscalMemories.push(responsePracticeFiscalMemories.data[idxDb]);
                            break;
                        }
                    }
                }
                if (this.fiscalMemories.length === 1) {
                    [this.selectedFiscalMemory] = this.fiscalMemories;
                } else if (!this.fiscalMemories.length) {
                    this.fiscalMemories = responsePracticeFiscalMemories.data;
                }
            }
        }

        private async mounted() {
            const age = 18;
            const practiceId = userContextCache.currentPracticeId;
            this.isFiscalPrinterVisible =
                !currentPatientCache.isFeeFree &&
                currentPatientCache.value?.age !== null &&
                currentPatientCache.value.age >= age &&
                !currentPatientCache.haveActiveFeeDisengagement;
            if (practiceId) {
                await this.loadFiscalMemory(practiceId);
                if (this.fiscalMemories.length > 0 && !this.value.id) {
                    [this.selectedFiscalMemory] = this.fiscalMemories;
                }
            }
        }
    }
</script>
