<template>
    <v-card>
        <v-card-title>{{ id ? 'Редактиране' : 'Добавяне' }} на фискална памет</v-card-title>

        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn action="Save" @click="save" />
            <v-spacer></v-spacer>
            <btn v-if="fiscalMemoryCode" action="Delete" @click="deleteFiscalMemory" />
        </v-card-actions>

        <v-form ref="documentForm" v-model="isValid">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <text-field
                            v-model="dto.code"
                            required
                            label="Код на фискална памет"
                            :disabled="fiscalMemoryCode"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <date-picker v-model="dto.validFrom" label="Валидна от" required />
                    </v-col>
                    <v-col cols="6">
                        <date-picker v-model="dto.validTo" label="Валидна до" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { FiscalMemoryDto } from '@/model/Practice/Fiscal/FiscalMemoryDto';
    import { fiscalMemoryService } from '@/service/Practice/Fiscal/FiscalMemoryService';

    @Component
    export default class MemoryEdit extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private fiscalDeviceSeqNumber!: number;

        @Prop()
        private fiscalMemoryCode!: string;

        private isValid: boolean = false;
        private dto: FiscalMemoryDto = new FiscalMemoryDto();

        private async mounted() {
            if (this.fiscalMemoryCode) {
                try {
                    this.$loading.show();
                    const response = await fiscalMemoryService.getFiscalMemory(
                        this.practiceId,
                        this.fiscalDeviceSeqNumber,
                        this.fiscalMemoryCode
                    );
                    this.dto = response.data;
                } finally {
                    this.$loading.hide();
                }
            } else {
                this.dto.practiceId = this.practiceId;
                this.dto.deviceSeqNumber = this.fiscalDeviceSeqNumber;
            }
        }

        private async save() {
            this.$loading.show();
            try {
                (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

                if (this.isValid) {
                    if (this.fiscalMemoryCode) {
                        await fiscalMemoryService.updateFiscalMemory(this.dto);
                    } else {
                        await fiscalMemoryService.createFiscalMemory(this.dto);
                    }
                    this.$router.push(this.backRoute);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private get backRoute() {
            return `/Practice/Fiscal/Memory/List/${this.practiceId}/${this.fiscalDeviceSeqNumber}`;
        }

        private async deleteFiscalMemory() {
            this.$loading.show();
            try {
                await fiscalMemoryService.deleteFiscalMemory(
                    this.practiceId,
                    this.fiscalDeviceSeqNumber,
                    this.fiscalMemoryCode
                );
                this.$router.push(this.backRoute);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
