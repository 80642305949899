import { Type } from 'class-transformer';

import { NhisPatientImmunizationReactionKey } from '@/model/Nhis/Immunization/NhisPatientImmunizationReactionKey';
import { SubmitCommand } from '@/model/Nhis/SubmitCommand';

export class NhisPatientImmunizationReactionSubmitCommand extends SubmitCommand {
    @Type(() => NhisPatientImmunizationReactionKey)
    public immunizationReactionKey: NhisPatientImmunizationReactionKey;

    constructor(
        xmlString: string,
        accessTokenValue: string,
        immunizationReactionKey: NhisPatientImmunizationReactionKey
    ) {
        super(xmlString, accessTokenValue);
        this.immunizationReactionKey = immunizationReactionKey;
    }
}
