<template>
    <v-dialog v-model="dialogIsVisible" persistent :max-width="600">
        <v-form ref="addWorkTimeDialogForm" v-model="isFormValid">
            <v-card>
                <v-card-title>Добавяне на работно време</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col class="py-1">
                            <text-field v-model="dto.event.name" required label="Заглавие"></text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-1">
                            <dropdown v-model="selectedDoctorId" :items="doctors" required label="Лекар"></dropdown>
                        </v-col>
                    </v-row>
                    <!-- Специалност -->
                    <v-row>
                        <v-col class="py-1">
                            <dropdown
                                v-model="dto.specialtyCode"
                                item-value="code"
                                :items="selectedDoctorSpecialties"
                                required
                                label="Специалност"
                            ></dropdown>
                        </v-col>
                    </v-row>
                    <!-- Тип -->
                    <v-row>
                        <v-col class="py-1">
                            <dropdown
                                v-model="dto.event.workTimeVisitReasonId"
                                :items="workTimeVisitReasons"
                                label="Тип"
                                required
                            >
                            </dropdown>
                        </v-col>
                    </v-row>
                    <v-row v-if="!hasRecurrence">
                        <v-col class="py-1">
                            <date-picker v-model="dto.event.date" :required="!hasRecurrence" label="Дата" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <time-picker v-model="timeFrom" required label="От" />
                        </v-col>
                        <v-col>
                            <time-picker v-model="timeTo" required label="До" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-checkbox v-model="hasRecurrence" label="Повторение"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row v-if="dto.event.recurrence">
                        <v-col class="py-1">
                            <dropdown
                                v-model="dto.event.recurrence.rRuleRecurrenceType"
                                :items="recurrenceTypes"
                                :required="hasRecurrence"
                                label="Тип повторение"
                            ></dropdown>
                        </v-col>
                        <v-col class="py-1">
                            <dropdown
                                v-model="dto.event.recurrence.interval"
                                :items="intervals"
                                :required="hasRecurrence"
                                label="Интервал на повторение"
                            ></dropdown>
                        </v-col>
                        <v-col class="py-1">
                            <text-field
                                v-model="dto.event.recurrence.count"
                                type="number"
                                label="Брой повторения"
                            ></text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="dto.event.recurrence">
                        <v-col class="py-1">
                            <date-picker
                                v-model="dto.event.recurrence.startDate"
                                :required="hasRecurrence"
                                label="Начална дата"
                            />
                        </v-col>
                        <v-col class="py-1">
                            <date-picker v-model="dto.event.recurrence.endDate" label="Крайна дата" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <btn action="Cancel" @click="closeDialog" />
                    <btn action="Save" @click="saveEvent" />
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import TimePicker from '@/component/Date/TimePicker.vue';
    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { DoctorDropdownDto } from '@/model/Doctor/DoctorDropdownDto';
    import { RecurrenceTypeDto } from '@/model/Nomenclature/RecurrenceTypeDto';
    import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';
    import { WorkTimeVisitReasonDto } from '@/model/Nomenclature/WorkTimeVisitReasonDto';
    import { EventCreateCommand } from '@/model/WorkTime/EventCreateCommand';
    import { EventRecurrenceDto } from '@/model/WorkTime/EventRecurrenceDto';
    import { recurrenceTypeService } from '@/service/Nomenclature/RecurrenceTypeService';
    import { workTimeVisitReasonService } from '@/service/Nomenclature/WorkTimeVisitReasonService';
    import { workTimeService } from '@/service/WorkTime/WorkTimeService';

    @Component({
        components: { TimePicker }
    })
    export default class CreateEventDialog extends Vue {
        @Prop({ required: true })
        private show!: boolean;

        @Prop({ required: true })
        private practiceId!: number;

        @Prop()
        private doctorId!: number;

        @Prop()
        private doctors!: DoctorDropdownDto[];

        @Prop()
        private selectedDoctorIdProp!: number | null;

        @Prop()
        private selectedVisitReasonId!: number | null;

        @Prop()
        private selectedDoctorSpecialtiesProp!: SpecialtyDto[] | null;

        @Prop()
        private selectedDoctorSpecialtyCode!: SpecialtyCode | null;

        private dialogIsVisible: boolean = false;
        private isFormValid: boolean = false;
        private hasRecurrence: boolean = false;

        private dto: EventCreateCommand = new EventCreateCommand();
        private timeFrom: string = '';
        private timeTo: string = '';

        private selectedDoctorId: number | null = null;

        private workTimeVisitReasons: WorkTimeVisitReasonDto[] = [];
        private recurrenceTypes: RecurrenceTypeDto[] = [];
        private selectedDoctorSpecialties: SpecialtyDto[] = [];

        private intervals: { id: number; name: string }[] = [
            {
                id: 1,
                name: 'Всички'
            },
            {
                id: 2,
                name: 'През 1'
            },
            {
                id: 3,
                name: 'През 2 '
            },
            {
                id: 4,
                name: 'През 3'
            },
            {
                id: 5,
                name: 'През 4'
            }
        ];

        private async saveEvent(): Promise<void> {
            (this.$refs.addWorkTimeDialogForm as Vue & { validate: () => boolean }).validate();

            if (this.isFormValid) {
                const createdEvent = await workTimeService.addEvent(this.dto);
                this.$emit('onWorkTimeEventAdded', createdEvent.data);
                this.closeDialog();
                this.$notifier.showSuccess('Успех', 'Успешно добавено работно време');
            }
        }

        private closeDialog() {
            this.dialogIsVisible = false;
            this.$emit('onClose');
        }

        private mounted() {
            this.dto.practiceId = this.practiceId;
            this.getWorkTimeVisitReasonTypes();
            this.getRecurrenceTypes();
        }

        private async getWorkTimeVisitReasonTypes() {
            this.workTimeVisitReasons = (await workTimeVisitReasonService.getWorkTimeVisitReasons()).data;
        }

        private async getRecurrenceTypes() {
            this.recurrenceTypes = (await recurrenceTypeService.getRecurrenceTypes()).data;
        }

        private async getSelectedDoctorSpecialties() {
            if (this.selectedDoctorId) {
                this.selectedDoctorSpecialties = (
                    await workTimeService.getRegisteredSpecialtiesForDoctorAndPractice(
                        this.practiceId,
                        this.selectedDoctorId ?? 0
                    )
                ).data;
                this.dto.specialtyCode = null;
            } else {
                this.selectedDoctorSpecialties = [];
            }
        }

        @Watch('selectedDoctorIdProp')
        private onSelectedDoctorIdPropChanged() {
            this.selectedDoctorId = this.selectedDoctorIdProp;
        }

        @Watch('selectedDoctorId')
        private onSelectedDoctorIdChanged() {
            this.getSelectedDoctorSpecialties();
            this.dto.doctorId = this.selectedDoctorId;
        }

        @Watch('selectedVisitReasonId')
        private onSelectedVisitReasonIdChanged() {
            this.dto.event.workTimeVisitReasonId = this.selectedVisitReasonId;
        }

        @Watch('show')
        private onShowChanged() {
            this.dialogIsVisible = this.show;
        }

        @Watch('hasRecurrence')
        private onHasRecurrenceChanged() {
            if (this.hasRecurrence) {
                this.dto.event.recurrence = new EventRecurrenceDto();
            } else {
                this.dto.event.recurrence = null;
            }
        }

        @Watch('doctorSpecialties')
        private onDoctorSpecialtiesChanged() {
            this.selectedDoctorSpecialties = this.selectedDoctorSpecialtiesProp || [];
        }

        @Watch('selectedDoctorSpecialtyCode')
        private onSelectedDoctorSpecialtyCodeChanged() {
            this.dto.specialtyCode = this.selectedDoctorSpecialtyCode;
        }

        @Watch('timeFrom')
        private onTImeFromChanged() {
            const [startHour, startMinute] = this.timeFrom.split(':');
            const radix = 10;
            this.dto.event.startHour = parseInt(startHour, radix);
            this.dto.event.startMinute = parseInt(startMinute, radix);
        }

        @Watch('timeTo')
        private onTimeToChanged() {
            const [endHour, endMinute] = this.timeTo.split(':');
            const radix = 10;
            this.dto.event.endHour = parseInt(endHour, radix);
            this.dto.event.endMinute = parseInt(endMinute, radix);
        }
    }
</script>
