<template>
    <v-card>
        <v-card-title class="pb-0">
            <v-row>
                <v-col>
                    Посещения на
                    {{ currentPatient.firstName }}
                    {{ currentPatient.middleName }}
                    {{ currentPatient.lastName }},
                    {{ currentPatient.personIdentification.identifier }}
                </v-col>
                <v-card-actions class="pt-0">
                    <btn action="New" to="/Exam/AmbulatorySheet/Create">Ново посещение</btn>
                    <btn class="ml-3" action="New" :to="`/Patient/PreventiveVisit/${patientId}`">Профилактично</btn>
                    <btn v-if="isDispensaryRegistered" action="New" :to="`/Patient/DispensaryVisit/${patientId}`">
                        Диспансерно
                    </btn>
                    <btn v-if="result.visits.length" action="Export" @click="exportPatientVisitSummaryToExcel" />
                </v-card-actions>
            </v-row>
        </v-card-title>

        <v-card-text v-if="result.visits.length < result.totalCount" class="pb-0">
            <v-row>
                <v-col>
                    <alert type="warning" class="mb-0">
                        Заредени са {{ result.visits.length }} от общо {{ result.totalCount }} посещения за пациента.
                    </alert>
                </v-col>
                <v-col cols="auto">
                    <btn action="Refresh" :icon="!$vuetify.breakpoint.lgAndUp" class="mt-1" @click="loadAllVisits">
                        Зареждане на всички посещения
                    </btn>
                </v-col>
            </v-row>
        </v-card-text>

        <VisitDataTable ref="visitDataTable" :items="result.visits" :show-patient-data="false" :bottom-anchor="72" />
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import { PatientVisitSummaryDto } from '@/model/VisitList/PatientVisitSummary/PatientVisitSummaryDto';
    import { dispensaryRegisterService } from '@/service/Register/DispensaryRegisterService';
    import { patientVisitSummaryService } from '@/service/VisitList/PatientVisitSummaryService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    import VisitDataTable from './VisitDataTable.vue';

    @Component({
        components: { VisitDataTable }
    })
    export default class PatientVisitSummary extends Vue {
        @Prop({ required: true })
        private patientId!: number;

        private isDispensaryRegistered: boolean = false;

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private result: PatientVisitSummaryDto = new PatientVisitSummaryDto();

        @Ref()
        private visitDataTable!: VisitDataTable;

        private async mounted() {
            await this.loadVisits(false);
            await this.loadIsDispensaryRegistered();
        }

        private async loadAllVisits() {
            await this.loadVisits(true);
            // Панелът с бутона за зареждане на всички посещения се скрива. Таблицата трябва да стане по-висока.
            this.visitDataTable.calcDataTableHeight();
        }

        // Освен в mounted, се извиква и в template-а при вкл/изкл. на отметката "Всички посещения".
        private async loadVisits(allVisits: boolean) {
            const { current } = userContextCache;
            if (current) {
                this.$loading.show();
                try {
                    // Зарежда данните за пациент и ги кешира.
                    await currentPatientCache.loadLastVersion(this.patientId);

                    this.result = await patientVisitSummaryService.getPatientVisitSummary(
                        currentPatientCache.value.key.patientId,
                        current.employeeSeqNumber,
                        allVisits
                    );
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private async loadIsDispensaryRegistered() {
            const { currentDoctorEmployeeSeqNumber } = userContextCache;
            const dispensaryRegisters = await dispensaryRegisterService.getByPatient(
                this.patientId,
                currentDoctorEmployeeSeqNumber ?? 0
            );
            // TODO: Да се връща директно boolean, а не целият списък с диспансеризации.
            this.isDispensaryRegistered = Boolean(
                dispensaryRegisters.find(
                    (dr) => dr.doctorEmployeeSeqNumber === currentDoctorEmployeeSeqNumber && !dr.deregisterDate
                )
            );
        }

        private async exportPatientVisitSummaryToExcel() {
            const { current } = userContextCache;
            if (current && this.result.visits.length) {
                this.$loading.show();
                try {
                    await currentPatientCache.loadLastVersion(this.patientId);
                    await patientVisitSummaryService.exportPatientVisitSummaryToExcel(
                        currentPatientCache.value.key.patientId,
                        current.employeeSeqNumber
                    );
                } finally {
                    this.$loading.hide();
                }
            }
        }
    }
</script>
