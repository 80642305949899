import { Transform, Type } from 'class-transformer';

import { FamilyMemberDto } from '@/model/Exam/SickSheet/FamilyMemberDto';

export class SickSheetDto {
    public id: number = 0;
    public visitId: number = 0;
    public number: string = '';
    public issueDate: Date = new Date();
    // Дали болничният лист е продължение = ''; а не първоначален.
    public isContinuation: boolean = false;
    public statusCodeOfSend: string | null = null;
    public ambulatorySheetNumber: number | null = null;
    public historyOfSickNumber: number = 0;
    public icdCode: string = '';
    public lakNumber: number = 0;

    public canceledSickSheetId: number | null = null;
    public canceledContectionId: number | null = null;

    public leftSickCounterNumbers: number | null = null;
    public sickSheetTotalDaysPatient: number | null = null;

    // Месторабота
    public patientWorkplaceId: number = 0;

    public workCityName: string = '';

    // Данни за Лечебното заведение
    public typeOfHospital: string = '';
    public nameOfHospital: string = '';
    public cityOfHospital: string = '';
    public addressOfHospital: string = '';
    public nhifCode: string = '';

    // Останалите данни
    public hospitalRegimenDays: number = 0;
    public homeRegimenDays: number = 0;
    public sanatoriumRegimenDays: number = 0;
    public unrestrictedRegimenDays: number = 0;
    public sickLeaveStartDate: Date | null = null;
    public sickLeaveEndDate: Date | null = null;
    public sickLeaveLegalDays: number = 1;
    public reasonOfDiseaseCode: string = '';
    public reasonOfDiseaseId: number | null = null;
    public regimenDaysSubtypeCodeArray: string[] | null = null;
    public regimenDaysWord: string = '';
    public pregnancyTermDate: Date | null = null;
    public pregnancyDeliveryDate: Date | null = null;

    @Transform(({ value }) => value ?? new FamilyMemberDto())
    @Type(() => FamilyMemberDto)
    public familyMember: FamilyMemberDto = new FamilyMemberDto();

    // Да се яви на преглед на: ....
    public dateMedicalCheck: Date | null = null;

    // Решение ЛКК №
    public lkkDecisionNumber: number | null = null;
    // Дата ЛКК
    public lkkDecisionDate: Date | null = null;

    // Решение ТЕЛК №
    public telkDecisionNumber: number | null = null;
    // Дата ТЕЛК
    public telkDecisionDate: Date | null = null;

    // Бележките в xsd-то на НОИ се състоят от 3 полета:
    // 1. Поредица от кодове на видове бележки, разделени с ';'. Изпраща се към НОИ точно в този вид.
    // 2. Текст, генериран от имената на видовете бележки, изредени в NoteNssiCodes, и от техните параметри.
    // Форматът е конкретен, така че параметрите да може да се parse-нат. Различните видове бележки имат от 0 до 3 параметъра.
    // Параметрите НЕ се пазят в отделни колони, защото не се изпращат поотделно към НОИ.Изпраща се само този текст.
    // 3. Когато е избран вид бележки с НОИ-код '20', потребителят може да въведе още свободен текст.
    // Този текст се пази в отделно поле, защото се изпраща към НОИ в отделен tag в xml-а.
    public noteNssiCodes: string[] = [];
    public generatedNotes: string = '';
    public additionalNotes: string = '';

    // Избор между "Лекар" или "Членове на ЛКК"
    public isIssuedByLkkCommission: boolean = false;

    public familyNameDoctor1: string = '';
    public uin1: string = '';

    public familyNameDoctor2: string = '';
    public uin2: string = '';

    public familyNameDoctor3: string = '';
    public uin3: string = '';
}
