<template>
    <v-menu v-model="menuIsVisible" :close-on-content-click="false" offset-y :nudge-width="1000">
        <template #activator="{ on: menuOn }">
            <v-tooltip bottom>
                <template #activator="{ on: tooltipOn }">
                    <v-btn icon v-on="{ ...menuOn, ...tooltipOn }">
                        <v-btn icon small class="ml-2" @click="loadNote">
                            <v-icon>mdi-note-edit</v-icon>
                        </v-btn>
                    </v-btn>
                </template>
                <span>{{ patientNotes }}</span>
            </v-tooltip>
        </template>

        <v-card v-if="menuIsVisible">
            <v-card-title class="pb-0">Бележки към пациент</v-card-title>
            <v-textarea v-model="patientNotes" class="ma-3" dense></v-textarea>
            <v-card-actions>
                <v-btn color="primary" @click="save">Запис</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { patientService } from '@/service/Patient/PatientService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    @Component
    export default class CurrentPatientNotes extends Vue {
        private patientNotes: string = '';

        private menuIsVisible: boolean = false;

        private async mounted() {
            await this.loadNote();
        }

        private async save() {
            if (currentPatientCache.isLoaded) {
                const patientLastVersion = currentPatientCache.value;
                patientLastVersion.notes = this.patientNotes;
                await patientService.savePatientNotes(patientLastVersion);
                this.$notifier.showSuccess('', `Успешен запис.`);
            }
        }

        private async loadNote() {
            // Иван 2024-08: Защо е разтурен оригиналният вариант, при който се bind-ва currentPatientCache.value.notes?
            // Ако кешът не е актуален, този проблем трябва да се пребори, а не пациентът да се чете наново само заради едните бележки.
            if (currentPatientCache.isLoaded) {
                const patientLastVersion = await patientService.getLastPatientVersion(
                    currentPatientCache.value.key.patientId,
                    // TODO: Да се показват бележките на ОПЛ-то от текущия UserContext, а не последните.
                    null
                );
                this.patientNotes = patientLastVersion?.notes ?? '';
            }
        }
    }
</script>
