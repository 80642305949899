import { Type } from 'class-transformer';

import { Annex2ItemsDto } from '@/model/Report/Rhi/Annex2/Annex2ItemsDto';
import { Annex3ItemsDto } from '@/model/Report/Rhi/Annex3/Annex3ItemsDto';
import { Annex4ItemsDto } from '@/model/Report/Rhi/Annex4/Annex4ItemsDto';
import { Annex56ItemsDto } from '@/model/Report/Rhi/Annex56/Annex56ItemsDto';
import { Report365ItemsDto } from '@/model/Report/Rhi/Report365/Report365ItemsDto';

export class Report365Dto {
    public startDate: Date = new Date();
    public endDate: Date = new Date();
    public jsonItems: string = '';
    public annex2Json: string = '';
    public annex3Json: string = '';
    public annex4Json: string = '';
    public annex56Json: string = '';

    @Type(() => Report365ItemsDto)
    public items: Report365ItemsDto = new Report365ItemsDto();

    @Type(() => Annex2ItemsDto)
    public annex2Items: Annex2ItemsDto = new Annex2ItemsDto();

    @Type(() => Annex3ItemsDto)
    public annex3Items: Annex3ItemsDto = new Annex3ItemsDto();

    @Type(() => Annex4ItemsDto)
    public annex4Items: Annex4ItemsDto = new Annex4ItemsDto();

    @Type(() => Annex56ItemsDto)
    public annex56Items: Annex56ItemsDto = new Annex56ItemsDto();
}
