<template>
    <v-card-text>
        <v-row>
            <v-col>
                <dropdown-search
                    v-if="isDisabled"
                    :service="patientSearchService"
                    return-object
                    :item-text="dropdownSearchResult"
                    label="Търсене на пациент"
                    @input="onPatientChange"
                ></dropdown-search>
            </v-col>
            <btn action="Search" @click="searchPatient">Нов пациент</btn>
        </v-row>

        <v-row>
            <v-col>
                <person-identification
                    :person-identification="patientVersion.personIdentification"
                    :disabled="isDisabled"
                    @check-identifier="doesPatientExist"
                ></person-identification>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <text-field v-model="patientVersion.firstName" :disabled="isDisabled" required label="Име"></text-field>
            </v-col>
            <v-col>
                <text-field v-model="patientVersion.middleName" :disabled="isDisabled" label="Презиме"></text-field>
            </v-col>
            <v-col>
                <text-field
                    v-model="patientVersion.lastName"
                    :disabled="isDisabled"
                    required
                    label="Фамилия"
                ></text-field>
            </v-col>
            <v-col>
                <CityPicker
                    v-model="patientVersion.address.cityId"
                    :disabled="isDisabled"
                    required
                    @input="onCityIdChange"
                />
            </v-col>
        </v-row>
        <v-row>
            <btn action="CheckDocument" @click="checkInsuranceStatus"> Проверка на осигурителен статус </btn>
            <div v-if="insuranceStatus">
                <div :class="insuranceStatus.isInsured ? 'info--text' : 'error--text'">
                    Статус: {{ insuranceStatus.isInsured ? 'Осигурен' : 'Неосигурен' }}
                </div>
                <div v-if="insuranceStatus.periodWithoutInsurance">
                    Месеци без данни за здравно осигуряване:
                    {{ insuranceStatus.periodWithoutInsurance }}
                </div>
            </div>
            <v-menu v-if="insuranceStatus && insuranceStatus.id" open-on-hover bottom offset-y pointer>
                <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <btn action="Print" block>Печат осиг. статус</btn>
                    </div>
                </template>

                <v-list>
                    <v-list-item>
                        <v-list-item-title class="pointer" @click="printInsuranceStatusWithoutPreview"
                            >Печат</v-list-item-title
                        >
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title class="pointer" @click="printInsuranceStatus"
                            >Печат - преглед</v-list-item-title
                        >
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-spacer />
            <btn action="Fill" @click.once="openFullPatientData"> Пълни данни на пациента</btn>
        </v-row>
        <v-row class="mt-5">
            <v-dialog v-model="incomingReferralDialogVisible" max-width="75%">
                <template #activator="{ on }">
                    <btn v-if="isSpecialist" action="List" v-on="on" @click="incomingReferralDialogVisible = true"
                        >Входящи направления
                    </btn>
                </template>
                <incoming-referral-extraction
                    v-if="currentPatientId"
                    :patient-id="currentPatientId"
                    :credit-to-employee-seq-number="1"
                    is-reception
                    @referral-extracted="onExtractedReferralsChange"
                />
            </v-dialog>
            <v-col cols="12">
                <span v-if="extractedReferrals && extractedReferrals.length > 0"
                    >Изтеглени са {{ extractedReferrals.length }}
                    {{ extractedReferrals.length > 1 ? 'броя' : 'брой' }} направления</span
                ></v-col
            >
        </v-row>
    </v-card-text>
</template>
<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import Btn from '@/component/Common/Btn.vue';
    import DropdownSearch from '@/component/Dropdown/DropdownSearch.vue';
    import CityPicker from '@/component/Picker/CityPicker.vue';
    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { PersonIdentificationDto } from '@/model/Common/PersonIdentificationDto';
    import { NhisExtractedReferralDto } from '@/model/Nhis/Referral/NhisExtractedReferralDto';
    import { InsuranceStatusDto } from '@/model/Patient/InsuranceStatus/InsuranceStatusDto';
    import { PatientExistenceDto } from '@/model/Patient/PatientExistenceDto';
    import { PatientVersionDto } from '@/model/Patient/PatientVersionDto';
    import { ReceptionServiceCreateCommand } from '@/model/Reception/Service/ReceptionServiceCreateCommand';
    import { getPostCode } from '@/service/Nomenclature/CityService';
    import { insuranceStatusService } from '@/service/Patient/InsuranceStatusService';
    import { patientSearchService } from '@/service/Patient/PatientSearchService';
    import { patientService } from '@/service/Patient/PatientService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';
    import IncomingReferralExtraction from '@/view/Exam/Referral/IncomingReferral/IncomingReferralExtraction.vue';
    import PersonIdentification from '@/view/Patient/PatientEdit/PersonIdentification.vue';

    @Component({
        components: { Btn, CityPicker, DropdownSearch, IncomingReferralExtraction, PersonIdentification }
    })
    export default class PatientReception extends Vue {
        @Prop()
        value!: ReceptionServiceCreateCommand;

        @Prop()
        patientId!: number;

        private extractedReferrals: NhisExtractedReferralDto[] = [];
        private insuranceStatus: InsuranceStatusDto | null = null;
        private incomingReferralDialogVisible: boolean = false;
        private isDisabled: boolean = false;
        private selectedPatientId: number = 0;
        private patientVersion: PatientVersionDto = new PatientVersionDto();

        private get currentPatientId() {
            return currentPatientCache?.value?.key?.patientId;
        }

        private get patientSearchService() {
            return patientSearchService;
        }

        private get isSpecialist() {
            return userContextCache.current?.specialtyCode !== SpecialtyCode.Gp;
        }

        private async load() {
            this.patientVersion = new PatientVersionDto();
            this.patientVersion.personIdentification = new PersonIdentificationDto();
            if (this.patientId > 0) {
                this.isDisabled = true;
                await currentPatientCache.loadLastVersion(this.patientId);
                this.patientVersion = currentPatientCache.value;
            } else {
                currentPatientCache.clear();
                this.isDisabled = false;
            }
            this.selectedPatientId = this.patientId ?? 0;
        }

        private async mounted() {
            await this.load();
        }

        private onPatientChange(patient: PatientVersionDto | null) {
            if (patient) {
                this.patientVersion = patient;
                this.isDisabled = true;
                currentPatientCache.loadLastVersion(patient.key.patientId);
                this.updateCommandPatient();
            }
        }

        private onExtractedReferralsChange(items: NhisExtractedReferralDto[]) {
            this.$emit('on-extracted-referrals-change', items);
        }

        private isPatientPopulated() {
            return Boolean(
                this.patientVersion.personIdentification.identifier &&
                    this.patientVersion.personIdentification.pidTypeCode &&
                    this.patientVersion.firstName &&
                    this.patientVersion.lastName &&
                    this.patientVersion.address.cityId
            );
        }

        private async createPatientVersionIfItIsNew() {
            this.patientVersion.key = this.value.patient.key;
            if (this.patientVersion.key.patientId <= 0) {
                this.patientVersion.key.practiceId = userContextCache.currentPracticeId ?? 0;
                this.patientVersion.isNewVersion = null;
                if (this.isSpecialist) {
                    this.patientVersion.gpEmployeeSeqNumber = null;
                } else {
                    this.patientVersion.gpEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber;
                }
                this.patientVersion.key.patientId = await patientService.createPatient(this.patientVersion);
                currentPatientCache.loadLastVersion(this.patientVersion.key.patientId);
            }
        }

        private async openFullPatientData() {
            if (this.isPatientPopulated()) {
                await this.createPatientVersionIfItIsNew();
                const route = this.$router.resolve({
                    path: `/Patient/PersonalData/${this.patientVersion.key.patientId}`
                });
                window.open(route.href);
            } else {
                this.$notifier.showWarning('Липсващи данни', 'Трябва да се попълнят задължителните полета за пациент');
            }
        }

        private async checkInsuranceStatus() {
            if (this.isPatientPopulated()) {
                await this.createPatientVersionIfItIsNew();
                if (this.insuranceStatus === null) {
                    this.insuranceStatus = await patientService.getLastPatientInsuranceStatus(
                        this.patientVersion.key.patientId
                    );
                    this.patientVersion.insuranceStatus = this.insuranceStatus;
                }
                this.insuranceStatus = await insuranceStatusService.checkStatus(
                    this.patientVersion.key.patientId,
                    this.patientVersion.personIdentification.pidTypeCode ?? '',
                    this.patientVersion.personIdentification.identifier,
                    this.insuranceStatus !== null ? this.insuranceStatus : new InsuranceStatusDto()
                );
                if (this.insuranceStatus) {
                    this.patientVersion.insuranceStatus = this.insuranceStatus;
                }
            } else {
                this.$notifier.showWarning('Липсващи данни', 'Трябва да се попълнят задължителните полета за пациент');
            }
        }

        private printInsuranceStatus() {
            this.$router.push(`/Print/Patient.InsuranceStatus/${this.insuranceStatus?.id ?? 0}`);
        }

        private async printInsuranceStatusWithoutPreview() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = `${this.insuranceStatus?.id ?? 0}`;
                const report = 'Patient.InsuranceStatus';

                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private dropdownSearchResult(patient: PatientVersionDto | null) {
            return patient && patient.personIdentification?.identifier
                ? `Идентификатор: ${patient.personIdentification.identifier}; Пациент: ${patient.fullName}`
                : '';
        }

        private async doesPatientExist() {
            if (
                this.patientVersion?.personIdentification?.pidTypeCode &&
                this.patientVersion?.personIdentification?.identifier
            ) {
                const dto = new PatientExistenceDto(
                    userContextCache.currentPracticeId ?? 0,
                    this.patientVersion.personIdentification.pidTypeCode,
                    this.patientVersion.personIdentification.identifier,
                    this.patientVersion.key.patientId
                );
                const patientId = await patientService.doesPatientExist(dto);
                if (patientId) {
                    await currentPatientCache.loadLastVersion(patientId);
                    this.patientVersion = currentPatientCache.value;
                    this.selectedPatientId = patientId;
                }
            }
            this.updateCommandPatient();
        }

        private searchPatient() {
            this.selectedPatientId = 0;
            currentPatientCache.clear();
            this.patientVersion = new PatientVersionDto();
            this.patientVersion.personIdentification = new PersonIdentificationDto();
            this.updateCommandPatient();
        }

        private async onCityIdChange(value: number | null) {
            if (value) {
                const response = await getPostCode.getPostCodeByCityId(value);
                this.patientVersion.address.postalCode = response.toString();
            } else {
                this.patientVersion.address.postalCode = '';
            }
            this.updateCommandPatient();
        }

        private updateCommandPatient() {
            this.value.patient = this.patientVersion;
        }

        @Watch('command.patient.firstName')
        private onFirstNameChanged() {
            this.patientVersion.firstName = formatters.capitalizeFirstLetter(this.patientVersion.firstName);
            this.updateCommandPatient();
        }

        @Watch('command.patient.lastName')
        private onLastNameChanged() {
            this.patientVersion.lastName = formatters.capitalizeFirstLetter(this.patientVersion.lastName);
            this.updateCommandPatient();
        }

        @Watch('selectedPatientId')
        private onSelectedPatientIdIdChanged() {
            this.isDisabled = this.selectedPatientId > 0;
        }

        @Watch('patientId')
        private async onPatientIdChanged() {
            await this.load();
        }
    }
</script>
