import { RouteConfig } from 'vue-router';

const nomenclatureChildRoutes: RouteConfig[] = [
    {
        path: 'NhifDrug',
        components: {
            nomenclature: () => import('@/view/Nomenclature/NhifDrug.vue')
        }
    }
];

export const nomenclatureRoutes: RouteConfig = {
    path: '/Nomenclature',
    component: () => import('@/view/Nomenclature/NomenclatureMenu.vue'),
    children: nomenclatureChildRoutes
};
