<template>
    <div>
        <v-card>
            <v-card-title>Търсене на пациент</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <text-field v-model="patientFilter.identifier" label="Идентификатор:" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model="patientFilter.firstName" label="Име:" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model="patientFilter.middleName" label="Презиме:" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model="patientFilter.lastName" label="Фамилия:" />
                    </v-col>
                    <v-spacer />
                </v-row>
                <v-col cols="6" md="2"><btn action="Search" @click="search" /></v-col>
            </v-card-text>
        </v-card>
        <data-table v-if="patients.length > 0" title="Пациенти" :headers="headers" :items="patients">
            <template #rowActions="{ item }">
                <btn icon action="Select" @click="selectPatient(item.patientId)" />
            </template>
        </data-table>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PatientSearchCommand } from '@/model/Patient/PatientSearchCommand';
    import { PatientSearchResultDto } from '@/model/Patient/PatientSearchResultDto';
    import { patientService } from '@/service/Patient/PatientService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class PatientSearch extends Vue {
        private patients: PatientSearchResultDto[] = [];
        private patientFilter: PatientSearchCommand = new PatientSearchCommand();
        private headers: IDataTableHeader[] = [
            { text: 'Идентификатор', value: 'identifier' },
            { text: 'Име', value: 'firstName' },
            { text: 'Презиме', value: 'middleName' },
            { text: 'Фамилия', value: 'lastName' }
        ];

        private selectPatient(patientId: number) {
            currentPatientCache.loadLastVersion(patientId);
            this.patients = [];
        }

        private async search() {
            this.$loading.show();
            try {
                this.patientFilter.doctorSpecialtyCode = userContextCache.current?.specialtyCode ?? null;
                this.patientFilter.practiceId = userContextCache.currentPracticeId;
                this.patientFilter.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber;
                this.patients = await patientService.search(this.patientFilter);

                // В този момент на екрана се вижда търсеният пациент (търсените пациенти), но лекарят още не е избрал пациент.
                // Ако в заглавната лента има текущ пациент, той трябва да се почисти, за да се задължи лекарят да избере
                // нов пациент от търсените. В противен случай има опасност по невнимание да продължи да работи със стар пациент.
                currentPatientCache.clear();
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>

<style>
    .v-autocomplete {
        white-space: pre-wrap;
        display: flex;
        height: auto;
    }

    .v-chip__content {
        white-space: pre-wrap;
        display: flex;
        height: auto;
    }

    .v-autocomplete__content .v-list__tile {
        height: auto;
        display: flex;
    }

    .v-input__slot {
        height: auto;
        display: flex;
    }
</style>
