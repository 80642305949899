<template>
    <v-card>
        <v-card-title>Тарифи</v-card-title>
        <v-card-actions class="py-0 pl-4">
            <btn action="New" @click="addTariff">Добавяне на тарифа</btn>
            <btn action="Save" @click="saveTariffs" />
        </v-card-actions>
        <v-card-text>
            <text-field v-model="searchString" append-icon="mdi-magnify" label="Търсене" />
            <v-data-table
                :items="items"
                :headers="headers"
                show-search-box
                :search="searchString"
                single-select
                title="Тарифи"
                no-data-text="Няма намерени данни"
                loading-text="Зареждане на данни..."
            >
                <template #item="{ item }">
                    <tr>
                        <text-field v-model="item.name" required />
                        <td>
                            <dropdown
                                :value="item.defaultFundCode"
                                :items="practiceFunds"
                                item-value="code"
                                required
                                @input="updateCode($event, item)"
                            />
                        </td>
                        <td>
                            <div class="float-right">
                                <btn icon action="Delete" @click="deleteTariff(item)" />
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
        <alert :value="showError" type="error">{{ errorMessage }}</alert>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { FundCode } from '@/enum/Nomenclature/FundCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PracticeFundDto } from '@/model/Practice/PracticeFundDto';
    import { TariffDto } from '@/model/Practice/Service/TariffDto';
    import { notifierService } from '@/service/Infrastructure/NotifierService';
    import { tariffService } from '@/service/Practice/Service/TariffService';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component
    export default class Tariffs extends Vue {
        @Prop()
        private practiceId!: number;

        private headers: IDataTableHeader[] = [
            { text: 'Име', value: 'name' },
            { text: 'Препоръчан фонд', value: 'defaultFundCode' },
            { text: '', value: 'actionButtons', sortable: false }
        ];

        private searchString: string = '';
        private items: TariffDto[] = [];
        private practiceFunds: PracticeFundDto[] = [];
        private showError: boolean = false;
        private errorMessage: string = '';

        private mounted() {
            this.loadItems();
        }

        private async loadItems() {
            this.items = await tariffService.getPracticeTariffs(this.practiceId);
            this.practiceFunds = await tariffService.getPracticeFunds(this.practiceId);
        }

        private addTariff() {
            const newTariff = new TariffDto();
            newTariff.practiceId = this.practiceId;
            newTariff.defaultFundCode = FundCode.Patient;
            this.items.unshift(newTariff);
        }

        private async deleteTariff(tariffToDelete: TariffDto) {
            if (tariffToDelete.seqNumber === 0) {
                arrayUtil.removeFirst(this.items, tariffToDelete);
            } else {
                const response = await tariffService.deleteTariff(this.practiceId, tariffToDelete.seqNumber);
                if (!response) {
                    notifierService.showSuccess('Успех', 'Данните са записани успешно');
                    this.loadItems();
                } else {
                    notifierService.showWarning('Грешка', response);
                }
            }
        }

        private updateCode(value: FundCode | null, item: TariffDto) {
            item.defaultFundCode = value;
        }

        private async saveTariffs() {
            if (this.items.length > 0) {
                if (this.items.some((tariff) => tariff.name.length <= 0 || tariff.defaultFundCode === null)) {
                    this.showError = true;
                    this.errorMessage = `Моля попълнете празните полета на 'Име' или 'Препоръчан фонд'`;
                } else {
                    await tariffService.saveTariffs(this.items);
                    notifierService.showSuccess('Успех', 'Данните са записани успешно');
                    this.loadItems();
                    this.showError = false;
                }
            }
        }
    }
</script>
