<template>
    <v-dialog v-model="dialogIsVisible" persistent :max-width="600">
        <v-card>
            <v-card-title>
                <span class="text-h5">Изтриване на работно време</span>
            </v-card-title>
            <v-card-text>
                <v-row v-if="workTimeEventEditDto">
                    <v-col>
                        <text-field readonly label="Заглавие" :value="workTimeEventEditDto.name"></text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <text-field :value="startTimeFormattedString" readonly label="Начало"></text-field>
                    </v-col>
                    <v-col>
                        <text-field :value="endTimeFormattedString" readonly label="Край"></text-field>
                    </v-col>
                </v-row>
                <v-row v-if="workTimeEventEditDto && workTimeEventEditDto.recurrence">
                    <v-col cols="12">
                        <dropdown v-model="dto.deleteType" :items="eventDeleteTypes"></dropdown>
                    </v-col>
                    <v-col v-if="isDeleteSequenceFromDate" cols="12">
                        <date-picker v-model="dto.deleteFromDate" label="Дата след която да се изтрие поредицата" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <btn action="Cancel" @click="closeDialog" />
                <btn action="Delete" @click="deleteEvent" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { EventDeleteType } from '@/enum/WorkTime/EventDeleteType';
    import { EventDeleteCommand } from '@/model/WorkTime/EventDeleteCommand';
    import { EventEditDto } from '@/model/WorkTime/EventEditDto';
    import { workTimeService } from '@/service/WorkTime/WorkTimeService';
    import { dateUtil } from '@/util/DateUtil';

    @Component
    export default class DeleteEventDialog extends Vue {
        @Prop()
        private dialogIsVisible!: boolean;

        @Prop({ required: true, default: () => new EventEditDto() })
        private workTimeEventEditDto!: EventEditDto;

        private dto: EventDeleteCommand = new EventDeleteCommand();

        private get isDeleteSequenceFromDate() {
            return this.dto.deleteType === EventDeleteType.DeleteSequenceFromDate;
        }

        private eventDeleteTypes: { id: EventDeleteType; name: string }[] = [
            {
                id: EventDeleteType.DeleteSequence,
                name: 'Изтриване на цялата поредица'
            },
            {
                id: EventDeleteType.DeleteOne,
                name: 'Изтриване само на избрания'
            },
            {
                id: EventDeleteType.DeleteSequenceFromDate,
                name: 'Изтриване на проедица след дата'
            }
        ];

        private get startTimeFormattedString() {
            return dateUtil.getFormattedTimeString(
                this.workTimeEventEditDto?.startHour,
                this.workTimeEventEditDto?.startMinute
            );
        }

        private get endTimeFormattedString() {
            return dateUtil.getFormattedTimeString(
                this.workTimeEventEditDto?.endHour,
                this.workTimeEventEditDto?.endMinute
            );
        }

        private closeDialog() {
            this.$emit('onClose');
        }

        private async deleteEvent() {
            this.$loading.show();
            try {
                this.dto.eventId = this.workTimeEventEditDto.id;
                this.dto.eventDate = this.workTimeEventEditDto.date;
                await workTimeService.deleteEvent(this.dto);
                this.$emit('onDeleteFinish');
            } finally {
                this.$loading.hide();
                this.closeDialog();
            }
        }
    }
</script>
