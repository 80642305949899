import { AxiosResponse } from 'axios';

import { WorkTimeVisitReasonDto } from '@/model/Nomenclature/WorkTimeVisitReasonDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const workTimeVisitReasonService = {
    getWorkTimeVisitReasons(): Promise<AxiosResponse<WorkTimeVisitReasonDto[]>> {
        return httpService.get('/WorkTimeVisitReason/GetWorkTimeVisitReasons');
    }
};
