import { NhisPatientImmunizationSubmitCommand } from '@/model/Nhis/Immunization/NhisPatientImmunizationSubmitCommand';
import { NhisPrescriptionSubmitCommand } from '@/model/Nhis/Prescription/NhisPrescriptionSubmitCommand';
import { NhisReferralSubmitCommand } from '@/model/Nhis/Referral/NhisReferralSubmitCommand';
import { SubmitCommandWithId } from '@/model/Nhis/SubmitCommandWithId';

export class NhisGroupUploadDto {
    public examToOpenCommands: SubmitCommandWithId[] = [];
    public referral3Commands: NhisReferralSubmitCommand[] = [];
    public referral3aCommands: NhisReferralSubmitCommand[] = [];
    public referral4Commands: NhisReferralSubmitCommand[] = [];
    public referral6Commands: NhisReferralSubmitCommand[] = [];
    public referral7Commands: NhisReferralSubmitCommand[] = [];
    public referralTelkCommands: NhisReferralSubmitCommand[] = [];
    public medicalNoteCommands: SubmitCommandWithId[] = [];
    public prescriptionCommands: NhisPrescriptionSubmitCommand[] = [];
    public immunizationCommands: NhisPatientImmunizationSubmitCommand[] = [];
    public examToCloseCommands: SubmitCommandWithId[] = [];
    public examToEditCommands: SubmitCommandWithId[] = [];
    public accessToken: string = '';
}
