<template>
    <v-card class="mb-md-4">
        <v-card-title>
            Упълномощаване на потребител(имейл адрес) да работи като служителя {{ employee.employeeTitle }}
        </v-card-title>
        <v-card-actions>
            <btn action="Back" :to="backRoute" />

            <template v-if="allowWrite(employeeSeqNumber)">
                <!-- По време на търсенето се показва неактивен бутон "Добавяне". -->
                <btn v-if="!userSearch.result" action="NewAccount" disabled></btn>
                <!-- Ако потребителят очаква одобрение, надписът на бутона е по-конкретен, но извиква същото API за запис. -->
                <btn
                    v-else-if="userSearch.result.employeeUserExists && userSearch.result.employeeUserIsPending"
                    action="Success"
                    @click="createOrInviteEmployeeUser"
                >
                    Одобряване на потребителя
                </btn>
                <!-- Ако работата вече е свършена, вместо бутон "Добавяне" се показва пояснение. -->
                <alert v-else-if="userSearch.result.employeeUserExists" type="success" class="mb-0">
                    Потребителят вече е упълномощен.
                </alert>
                <!-- Ако е открит неизползван потребител, "Добавяне", дори да е с кратко потребителско име. -->
                <btn
                    v-else-if="userSearch.result.userExists"
                    action="NewAccount"
                    @click="createOrInviteEmployeeUser"
                ></btn>
                <!-- Ако няма до кого да се изпрати покана, се показва неактивен бутон "Добавяне". -->
                <btn v-else-if="!userSearch.isValidEmail" action="NewAccount" disabled></btn>
                <!-- Трябва да се изпрати покана, но ако е надвишен лимитът за изпращане, бутонът е неактивен. -->
                <btn
                    v-else
                    action="Send"
                    :disabled="Boolean(userSearch.result.sendInvitationWarning)"
                    @click="createOrInviteEmployeeUser"
                >
                    Изпращане на покана за регистриране в Хипократ
                </btn>
            </template>
        </v-card-actions>

        <v-card-text>
            <user-search v-if="allowWrite(employeeSeqNumber)" v-model="userSearch"></user-search>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop } from 'vue-property-decorator';

    import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
    import { UserSearchModel } from '@/model/Employee/Invitation/UserSearchModel';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { employeeUserService } from '@/service/Employee/EmployeeUserService';

    import { EmployeeBase } from './EmployeeBase';
    import UserSearch from './UserSearch.vue';

    @Component({
        components: { UserSearch }
    })
    export default class EmployeeUserCreate extends EmployeeBase {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private employeeSeqNumber!: number;

        private employee: EmployeeTitleDto = new EmployeeTitleDto();
        private userSearch: UserSearchModel = new UserSearchModel(this.checkEmployeeUserExistance);

        private async mounted() {
            this.employee = await employeeService.getEmployeeTitle(this.practiceId, this.employeeSeqNumber);
        }

        private checkEmployeeUserExistance() {
            return employeeUserService.checkEmployeeUserExistance(
                this.practiceId,
                this.employeeSeqNumber,
                this.userSearch.emailOrUserName.trim()
            );
        }

        private async createOrInviteEmployeeUser() {
            await employeeUserService.createOrInviteEmployeeUser(
                this.practiceId,
                this.employeeSeqNumber,
                this.userSearch.emailOrUserName.trim()
            );
            this.$router.push(this.backRoute);
        }

        private get backRoute() {
            return `/Employee/Edit/${this.practiceId}/${this.employeeSeqNumber}`;
        }
    }
</script>
