<template>
    <div>
        <v-card-title class="mb-5">
            <v-row>
                <v-spacer></v-spacer>
                <div class="ml-3">
                    <v-menu transition="slide-y-transition" bottom>
                        <template #activator="{ on, attrs }">
                            <btn action="Print" v-bind="attrs" v-on="on" />
                        </template>
                        <v-list>
                            <v-list-item @click="printImmunWithoutPreview">
                                <v-list-item-title>Печат имунизации</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printRotaWithoutPreview">
                                <v-list-item-title>Инф. съгласие ротавируси</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printHpvWithoutPreview">
                                <v-list-item-title>Инф. съгласие папилома вирус</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printImmun">
                                <v-list-item-title>Печат имунизации</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printRota">
                                <v-list-item-title>Инф. съгласие ротавируси</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printHpv">
                                <v-list-item-title>Инф. съгласие папилома вирус</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                <!--<v-btn class="ml-3" color="primary" dark @click="printRota">Инф. съгласие ротавируси</v-btn>-->
                <!--<v-btn class="ml-3" color="primary" dark @click="printHpv">Инф. съгласие папилома вирус</v-btn>-->
            </v-row>
        </v-card-title>
        <data-table
            title="Имунизации"
            :headers="tableHeaders"
            :loading="$loading.isVisible"
            :items="items"
            show-search-box
            class="elevation-1 mb-5"
            disable-pagination
            sort-by="occurrenceDate"
        >
            <template #actions>
                <btn v-if="$allowWrite" action="New" @click="createImmunization" />
                <btn action="Export" @click="exportExcel" />
            </template>
            <template #rowActions="{ item }">
                <btn v-if="$allowWrite" icon action="Edit" @click="editImmunization(item)" />
            </template>
        </data-table>
        <v-row justify="center" class="mx-5">
            <v-dialog v-model="dialogIsVisible" persistent max-width="75%">
                <v-card>
                    <v-card-title>
                        <span class="headline">Имунизация</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form" v-model="isFormValid">
                            <immunization-editor v-if="dialogIsVisible" v-model="clone"></immunization-editor>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <btn v-if="$allowWrite && clone.id > 0" action="Delete" text @click="deleteEntity" />
                        <v-spacer></v-spacer>
                        <btn action="Cancel" @click="closeDialog" />
                        <btn v-if="$allowWrite" action="Save" @click="save" />
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import ImmunizationEditor from '@/component/Immunization/ImmunizationEditor.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PatientImmunizationDto } from '@/model/Patient/Immunization/PatientImmunizationDto';
    import { patientImmunizationService } from '@/service/Patient/PatientImmunizationService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { ImmunizationEditor }
    })
    export default class CreatedImmunizationTab extends Vue {
        @Prop()
        private patientId!: number;

        @Prop()
        private shouldGetItems!: boolean;

        private dialogIsVisible: boolean = false;
        private model: PatientImmunizationDto = new PatientImmunizationDto();
        private clone: PatientImmunizationDto = new PatientImmunizationDto();
        private items: PatientImmunizationDto[] = [];
        private search: string = '';
        private isFormValid: boolean = false;

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Код РЗИ:', value: 'immunization.rhiCode' },

                {
                    text: 'Код НЗОК:',
                    value: 'immunization.nhifCode'
                },
                {
                    text: 'Наименование:',
                    value: 'immunization.name'
                },
                { text: 'Дата на поставяне:', value: 'occurrenceDate', formatter: formatters.date },
                { text: 'Серия:', value: 'serialNumber' },
                { text: 'Резултат:', value: 'resultField' },
                { text: '', value: 'actionButtons' }
            ];
        }

        private createImmunization() {
            this.dialogIsVisible = true;
        }

        private editImmunization(item: PatientImmunizationDto) {
            Object.assign(this.clone, item);
            this.dialogIsVisible = true;
        }

        private closeDialog() {
            this.dialogIsVisible = false;
            this.clone = new PatientImmunizationDto();
            (this.$refs.form as Vue & { resetValidation: () => boolean }).resetValidation();
        }

        private async save() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isFormValid) {
                await this.createOrUpdate();
            }
        }

        private async deleteEntity() {
            this.$loading.show();
            try {
                await patientImmunizationService.deletePatientImmunization(this.clone.id);
                await this.getTableItems();
                this.$notifier.showSuccess('', `Успешно изтриване`);
            } finally {
                this.$loading.hide();
                this.closeDialog();
            }
        }

        private async createOrUpdate() {
            this.$loading.show();
            try {
                this.model = this.clone;
                this.model.patientId = this.patientId;
                if (this.model.id <= 0) {
                    const result = await patientImmunizationService.createPatientImmunization(this.model);
                    this.model.id = result.data;
                    this.items.push(this.model);
                    this.$emit('added-immunization');
                } else {
                    await patientImmunizationService.updatePatientImmunization(this.model);
                    this.updateGrid();
                }
                this.$notifier.showSuccess('', `Успешен запис`);
            } finally {
                this.$loading.hide();
                this.closeDialog();
            }
        }

        private updateGrid() {
            const immunization = this.items.find((item) => item.id === this.model.id);
            if (immunization) {
                Object.assign(immunization, this.model);
            }
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                const response = await patientImmunizationService.getPatientImmunizations(this.patientId);
                if (response?.data) {
                    this.items = response.data;
                    this.$emit('created-loaded');
                }
            } finally {
                this.$loading.hide();
            }
        }

        private printImmun() {
            if (this.patientId) {
                this.$router.push(`/Print/Patient.PatientImmunization/${this.patientId}`);
            } else {
                // Todo показване на грешка
            }
        }

        private async printImmunWithoutPreview() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.patientId.toString();
                const report = 'Patient.PatientImmunization';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private printHpv() {
            if (this.patientId) {
                this.$router.push(`/Print/Patient.PatientInformedConsentHpv/${this.patientId}`);
            } else {
                // Todo показване на грешка
            }
        }

        private async printHpvWithoutPreview() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.patientId.toString();
                const report = 'Patient.PatientInformedConsentHpv';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private printRota() {
            if (this.patientId) {
                this.$router.push(`/Print/Patient.PatientInformedConsentRota/${this.patientId}`);
            } else {
                // Todo показване на грешка
            }
        }

        private async printRotaWithoutPreview() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.patientId.toString();
                const report = 'Patient.PatientInformedConsentRota';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async exportExcel() {
            this.$loading.show();
            try {
                await patientImmunizationService.exportPatientImmunizations(this.patientId);
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.getTableItems();
        }

        @Watch('shouldGetItems', { immediate: true })
        onShouldGetItems(newVal: boolean): void {
            if (newVal) {
                this.getTableItems();
            }
        }
    }
</script>
