<template>
    <exam-layout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Направление на бременна до родилно заведение"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <v-card-title>Направление на бременна до родилно заведение</v-card-title>
            <v-tabs>
                <v-tab>Стр. 1</v-tab>
                <v-tab>Стр. 2</v-tab>
                <v-tab-item>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col cols="12">
                                        <text-field v-model="document.sectionNumber" label="АГ-участък №:" />
                                    </v-col>
                                    <v-col cols="12">
                                        <text-field v-model="document.city" label="гр./с./:" />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col cols="12">
                                        <text-field v-model="document.otherCity" label="гр./с./:" />
                                    </v-col>
                                    <v-col cols="12">
                                        <text-field v-model="document.municipality" label="Община, област:" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <personal-info v-model="document.personalInfo" />
                    <anamnestic-data v-model="document.anamnesticData" />
                    <previous-pregnancy v-model="document.previousPregnancy" />
                </v-tab-item>
                <v-tab-item>
                    <current-pregnancy-data v-model="document.currentPregnancy" />
                </v-tab-item>
            </v-tabs>
        </v-card>
    </exam-layout>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { PregnancyReferralDto } from '@/model/Exam/Referral/PregnancyReferral/PregnancyReferralDto';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { pregnancyReferralService } from '@/service/Register/PregnancyReferralService';
    import AnamnesticData from '@/view/Register/Pregnancy/PregnancyReferral/AnamnesticData.vue';
    import CurrentPregnancyData from '@/view/Register/Pregnancy/PregnancyReferral/CurrentPregnancyData.vue';
    import PersonalInfo from '@/view/Register/Pregnancy/PregnancyReferral/PersonalInfo.vue';
    import PreviousPregnancy from '@/view/Register/Pregnancy/PregnancyReferral/PreviousPregnancy.vue';

    @Component({
        components: { CurrentPregnancyData, PreviousPregnancy, AnamnesticData, PersonalInfo, ExamLayout }
    })
    export default class PregnancyReferral extends Vue {
        @Prop()
        private pregnancyId!: number;

        @Prop()
        private documentId!: number;

        private document: PregnancyReferralDto = new PregnancyReferralDto();

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (!this.documentId) {
                    this.document.pregnancyId = this.pregnancyId;
                    const response = await pregnancyReferralService.createPregnancyReferral(this.document);
                    documentId = Number(response.data);
                    eventBus.$emit(
                        'create-referral-event',
                        documentId,
                        'PregnancyReferral',
                        'Направление на бременна до родилно заведение'
                    );
                    this.$router.push(`/Register/PregnancyRegister/PregnancyReferral/Edit/${documentId}`);
                } else {
                    await pregnancyReferralService.updatePregnancyReferral(this.document);
                    eventBus.$emit('update-referral-event', 'Направление на бременна до родилно заведение');
                }
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Register.PregnancyReferral.PregnancyReferral/${this.documentId}`);
            }
        }

        private async printEventHandlerWithoutPreview() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Register.PregnancyReferral.PregnancyReferral';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                await pregnancyReferralService.deletePregnancyReferral(this.documentId);
                this.$router.push(`/Register/PregnancyRegister/PregnancyReferral/Create/${this.document.pregnancyId}`);
                eventBus.$emit(
                    'delete-referral-event',
                    this.documentId,
                    'PregnancyReferral',
                    'Направление на бременна до родилно заведение'
                );
            } finally {
                this.$loading.hide();
            }
        }

        private async getPregnancyReferral() {
            this.$loading.show();
            try {
                this.document = await pregnancyReferralService.getPregnancyReferral(this.documentId);
            } finally {
                this.$loading.hide();
            }
        }

        private async getInfoByPregnancyId() {
            this.$loading.show();
            try {
                this.document = await pregnancyReferralService.getInfoByPregnancyId(this.pregnancyId);
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            if (this.documentId) {
                await this.getPregnancyReferral();
            } else {
                await this.getInfoByPregnancyId();
            }
        }
    }
</script>
