import { plainToInstance } from 'class-transformer';

import { InvoiceDto } from '@/model/Finance/Invoice/InvoiceDto';
import { InvoiceSearchCommand } from '@/model/Finance/Invoice/InvoiceSearchCommand';
import { InvoiceViewDto } from '@/model/Finance/Invoice/InvoiceViewDto';
import { InvoiceNumberAndDateUpdateCommand } from '@/model/Invoice/Pis/InvoiceNumberAndDateUpdateCommand';
import { httpService } from '@/service/Infrastructure/HttpService';

export const invoiceService = {
    async getInvoicesByFilter(filter: InvoiceSearchCommand): Promise<InvoiceViewDto[]> {
        const response = await httpService.post<InvoiceViewDto[]>('/Invoice/GetInvoicesByFilter', filter);
        return plainToInstance(InvoiceViewDto, response?.data);
    },

    async create(data: InvoiceDto): Promise<number> {
        const response = await httpService.post<number>('/Invoice/CreateInvoice', data);
        return response?.data ?? 0;
    },

    async update(data: InvoiceDto): Promise<void> {
        await httpService.put<void>('/Invoice/UpdateInvoice', data);
    },

    async getInvoiceInfoByPractice(practiceId: number): Promise<InvoiceDto> {
        const response = await httpService.get<InvoiceDto>(`/Invoice/GetInvoiceInfoByPractice`, {
            params: {
                practiceId
            }
        });

        return plainToInstance(InvoiceDto, response?.data);
    },

    async getById(id: number): Promise<InvoiceDto> {
        const response = await httpService.get<InvoiceDto>(`/Invoice/GetById/${id}`);
        return plainToInstance(InvoiceDto, response?.data);
    },

    async getCreditDebitNoteById(documentType: string, invoiceId: number): Promise<InvoiceDto> {
        const response = await httpService.get<InvoiceDto>(`/Invoice/GetCreditDebitNoteInfo`, {
            params: {
                documentType,
                invoiceId
            }
        });
        return plainToInstance(InvoiceDto, response?.data);
    },

    async updateInvoiceNumberAndDate(
        invoiceId: number,
        invoiceNumber: string,
        invoiceDate: Date | null
    ): Promise<void> {
        const command = new InvoiceNumberAndDateUpdateCommand(invoiceId, invoiceNumber, invoiceDate);
        await httpService.put('/Invoice/UpdateInvoiceNumberAndDate', command);
    },

    async delete(id: number): Promise<void> {
        await httpService.delete(`/Invoice/Delete/${id}`);
    }
};
