<template>
    <v-form ref="documentForm" v-model="isValid" :disabled="!$allowWrite">
        <v-card>
            <v-card-title>Прикачване на файлове към пациент</v-card-title>
            <v-card-text>
                <file-input v-model="uploadItems" :patient-id="patientId" :files-total-max-size="100" />
            </v-card-text>
            <v-card-actions>
                <btn action="Upload" @click="uploadFile" />
                <btn action="Cancel" @click="close" />
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { FileUploadCommand } from '@/model/File/FileUploadCommand';
    import { externalFileService } from '@/service/File/ExternalFileService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    import FileInput from './FileInput.vue';

    @Component({
        components: { FileInput }
    })
    export default class PatientFileUpload extends Vue {
        @Prop({ required: true })
        private patientId!: number | null;

        private uploadItems: FileUploadCommand[] = [];
        private isValid: boolean = false;

        private close() {
            this.$emit('close');
            this.uploadItems = [];
        }

        private async uploadFile() {
            this.$loading.show();
            try {
                (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

                if (this.isValid) {
                    await externalFileService.uploadFiles(this.uploadItems);
                    currentPatientCache.value.hasFiles = true;
                    this.close();
                    this.$notifier.showSuccess('', 'Файловете са записани.');
                } else {
                    this.$notifier.showWarning(
                        this.$t('notifications.validationErrors').toString(),
                        this.$t('notifications.pleaseFixValidationErrors').toString()
                    );
                }
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
