import { Transform, Type } from 'class-transformer';

import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';
import { DrugProtocolDrug } from '@/model/Nomenclature/DrugProtocolDrug';

export class DrugProtocolDto {
    public id: number = 0;
    public examId: number = 0;
    public number: number = 0;
    public issueDate: Date = new Date();
    public days: number | null = null;

    @Type(() => ExamDiagnosisDto)
    public examDiagnosis: ExamDiagnosisDto | null = null;

    public statementNumber: number | null = null;
    public totalQuantity: string = '';
    public controlVisits: string = '';

    @Transform(({ value }) => value ?? new DrugProtocolDrug())
    @Type(() => DrugProtocolDrug)
    public drugs: DrugProtocolDrug[] = [];
}
