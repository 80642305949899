import { plainToInstance } from 'class-transformer';

import { ChoiceMakerDto } from '@/model/Patient/ChoiceMaker/ChoiceMakerDto';
import { ChoiceMakerSearchCommand } from '@/model/Patient/ChoiceMaker/ChoiceMakerSearchCommand';
import { httpService } from '@/service/Infrastructure/HttpService';

export const choiceMakerService = {
    async searchChoiceMaker(filter: string, practiceId: number): Promise<ChoiceMakerDto[]> {
        const command = new ChoiceMakerSearchCommand(filter, practiceId);
        const response = await httpService.post<ChoiceMakerDto[]>('/ChoiceMaker/SearchChoiceMaker', command);
        return plainToInstance(ChoiceMakerDto, response.data);
    }
};
