<template>
    <div>
        <v-row>
            <v-col>
                <data-table :headers="headers" title="Бланка 5A" :items="items" sort-by="number">
                    <template #actions>
                        <btn action="New" :to="`/Exam/PrescriptionNhifTypeA/Create/${examId}`" />
                        <btn action="Send" @click="checkPrescriptions">Проверка на рецепти</btn>
                    </template>
                    <template #rowActions="{ item }">
                        <btn icon action="Edit" :to="`/Exam/PrescriptionNhifTypeA/Edit/${examId}/${item.id}`" />
                        <btn v-if="canSendForm && !item.nrn" icon action="Send" @click="postPrescription(item)" />
                        <btn
                            icon
                            action="Print"
                            :to="`/Print/Exam.Prescription.PrescriptionNhifTypeA/Prescription/${examId}/${item.id}`"
                        />
                    </template>
                </data-table>
            </v-col>
        </v-row>
        <nhis-prescription-group-check ref="groupCheck" :exam-id="examId" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
    import { PrescriptionNhifViewDto } from '@/model/Exam/Prescription/PrescriptionNhifViewDto';
    import { prescriptionService } from '@/service/Exam/PrescriptionService';
    import { nhisPrescriptionService } from '@/service/Nhis/NhisPrescriptionService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { formatters } from '@/util/Formatters';
    import NhisPrescriptionGroupCheck from '@/view/Nhis/Prescription/NhisPrescriptionGroupCheck.vue';

    @Component({
        components: { NhisPrescriptionGroupCheck }
    })
    export default class PrescriptionNhif5aView extends Vue {
        @Prop({ required: true })
        examId!: number;

        private items: PrescriptionNhifViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Номер:', value: 'number' },
                { text: 'Отрязък:', value: 'segment' },
                { text: 'МКБ 1:', value: 'diagnosis1Code' },
                { text: 'Лекарство 1:', value: 'drug1Code' },
                { text: 'МКБ 2:', value: 'diagnosis2Code' },
                { text: 'Лекарство 2:', value: 'drug2Code' },
                { text: 'МКБ 3:', value: 'diagnosis3Code' },
                { text: 'Лекарство 3:', value: 'drug3Code' },
                { text: 'НРН:', value: 'nrn' },
                { text: 'Статус:', value: 'status' },
                { text: 'Дата на проверка:', value: 'statusTime', formatter: formatters.dateTime },
                { text: 'Дата на изпълнение:', value: 'executionDate', formatter: formatters.dateTime },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private get canSendForm() {
            return (
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private async postPrescription(item: PrescriptionNhifViewDto) {
            this.$loading.show();
            try {
                if (!item.statusCode) {
                    const response = await nhisPrescriptionService.postPrescription(
                        new PrescriptionKey(this.examId, item.id),
                        PrescriptionFormTypeCode.Nhif5
                    );
                    if (response) {
                        this.$notifier.showSuccess('', `Успешно изпратен документ с НРН: ${response.nrn}`);
                        await this.loadItems();
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async checkPrescriptions() {
            await (this.$refs.groupCheck as NhisPrescriptionGroupCheck).checkPrescriptions();
            await this.loadItems();
        }

        private async loadItems() {
            this.$loading.show();
            try {
                this.items = await prescriptionService.getPrescriptionTypeAByExamId(
                    this.examId,
                    PrescriptionFormTypeCode.Nhif5a
                );
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
