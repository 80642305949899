import { plainToInstance } from 'class-transformer';

import { AnthropometricNormDto } from '@/model/Nomenclature/AnthropometricNormDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const anthropometricNormService = {
    async getNorms(): Promise<AnthropometricNormDto[]> {
        const response = await httpService.get<AnthropometricNormDto[]>('/AnthropometricNorm/GetNorms');
        return plainToInstance(AnthropometricNormDto, response.data);
    }
};
