<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Бланка 6 - Талон за МЕ" :items="items">
                <template #actions>
                    <btn
                        v-if="items.every((i) => i.status === 'Анулирано')"
                        action="New"
                        :to="`/Exam/Referral6/Create/${examId}`"
                    />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/Referral6/Edit/${item.id}`" />
                    <btn v-if="canSendForm && !item.nrn" icon action="Send" @click="postReferral(item)" />
                    <btn icon action="Print" :to="`/Print/Exam.Referral.Referral6/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Referral6ViewDto } from '@/model/Exam/Referral/Referral6ViewDto';
    import { referral6Service } from '@/service/Exam/Referral/Referral6Service';
    import { nhisReferralService } from '@/service/Nhis/NhisReferralService';
    import { nhisService } from '@/service/Nhis/NhisService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class Referral6View extends Vue {
        @Prop()
        examId!: number;

        private items: Referral6ViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Номер:', value: 'number' },
                { text: 'Тип направление:', value: 'referralSubtype' },
                { text: 'МКБ 1:', value: 'diagnosis1Code' },
                { text: 'МКБ 2:', value: 'diagnosis2Code' },
                { text: 'Специалност 1:', value: 'specialty1Code' },
                { text: 'Специалност 2:', value: 'specialty2Code' },
                { text: 'Специалност 3:', value: 'specialty3Code' },
                { text: 'Специалност 4:', value: 'specialty4Code' },
                { text: 'Специалност 5:', value: 'specialty5Code' },
                { text: 'НРН:', value: 'nrn' },
                { text: 'Статус:', value: 'status' },
                { text: 'Дата на проверка:', value: 'statusTime', formatter: formatters.dateTime },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private get canSendForm() {
            return (
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private async postReferral(item: Referral6ViewDto) {
            this.$loading.show();
            try {
                if (!item.statusCode) {
                    const requestData = nhisService.requestData(item.id, 'Referral6');
                    const response = await nhisReferralService.postReferral(requestData);
                    if (response) {
                        this.$notifier.showSuccess('', `Успешно изпратен документ с НРН: ${response.nrn}`);
                        await this.loadItems();
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await referral6Service.getReferralsByExamId(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
s
