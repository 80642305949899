import { NhisLongtermCareDto } from '@/model/Nhis/LongtermCare/NhisLongtermCareDto';
import { NecessaryConsultationDto } from '@/model/Register/NecessaryConsultationDto';
import { NecessaryNhifLabTestDto } from '@/model/Register/NecessaryNhifLabTestDto';

export class PregnancyRegisterDto {
    public id: number = 0;
    public doctorEmployeeId: number = 1;
    public patientId: number = 1;
    public pregnancyRuleId: number = 0;
    public pregnancyRuleName: string = '';
    public ambulatorySheetNumber: number | null = null;
    public examId: number | null = null;
    public registerDate: Date = new Date();
    public deregisterDate: Date | null = null;
    public pregnancyDeregisterReasonId: number | null = null;
    public pregnancyPeriodTypeId: number | null = null;
    public lastRegularMenstruation: Date = new Date();
    public probableBirthTerm: Date | null = null;
    public gestationalWeek: number | null = null;
    public abortionTypeId: number | null = null;
    public notes: string = '';
    public icdCode: string = '';
    public icdCodeAdd: string = '';
    public patientIdentifier: string = '';
    public patientName: string = '';
    public ordinanceText: string = '';
    public pregnancyConsultations: NecessaryConsultationDto[] = [];
    public pregnancyLabTests: NecessaryNhifLabTestDto[] = [];
    public nhisLongtermCare: NhisLongtermCareDto = new NhisLongtermCareDto();
    public pregnancyEndDate: Date | null = null;
}
