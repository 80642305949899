<template>
    <v-card>
        <v-card-title>Съобщение за смърт</v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="isFormValid" :disabled="!$allowWrite">
                <v-row>
                    <v-col cols="6" md="2">
                        <text-field v-model="document.number" required label="Номер"></text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                        <date-picker v-model="document.issueDate" label="Дата на издаване" required />
                    </v-col>
                    <v-col v-if="document.deputyDoctorName" cols="12" md="8" class="pt-5">
                        издадено от {{ document.deputyDoctorIsHired ? 'нает' : 'заместващ' }} лекар
                        {{ document.deputyDoctorName }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <text-field v-model="currentPatient.firstName" disabled label="Област" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="currentPatient.middleName" disabled label="Община" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="currentPatient.lastName" disabled label="Населено място" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="currentPatient.personIdentification.identifier" disabled label="ЕГН" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <date-picker
                            v-model="currentPatient.personIdentification.birthDate"
                            disabled
                            label="Дата на раждане"
                            required
                        />
                    </v-col>
                    <!--<v-col cols="12" md="4"><v-text-field v-model="currentPatient.personIdentification.genderCode" disabled label="Пол" /></v-col>-->
                    <!-- TODO: От къде се взима националност?  v-model="currentPatient.nationality" -->
                    <!--<v-col cols="12" md="4"><v-text-field disabled label="Гражданство" /></v-col>-->
                    <v-col cols="12" md="4">
                        <dropdown
                            v-model="currentPatient.personIdentification.genderCode"
                            :items="genders"
                            item-value="code"
                            label="Пол"
                            disabled="disabled"
                            required
                        />
                    </v-col>
                    <v-col cols="12" md="4">
                        <dropdown
                            v-model="currentPatient.personIdentification.countryId"
                            :items="countries"
                            label="Код държава"
                            disabled="disabled"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <date-picker v-model="document.deathDate" show-time required label="Дата и час на смърт" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <dropdown
                            v-model="document.placeOfDeathId"
                            :items="placeOfDeath"
                            label="Място на смъртта"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.deathAddress" required label="Населено място/държава на смърт" />
                    </v-col>
                    <v-col cols="12" md="2" offset="1"
                        ><v-checkbox v-model="document.hasAutopsy" label="Аутопсия" dense></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            v-model="document.reasonForDeath"
                            dense
                            label="Причина за смъртта"
                            class="required"
                            :rules="[$validator.required]"
                        />
                    </v-col>
                </v-row>
                <v-row class="align-end">
                    <v-col cols="12" md="4">
                        <v-textarea
                            v-model="document.diseaseDirectlyCausedDeath"
                            dense
                            label="Болест, довела до смъртта"
                        />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.directlyDiseasePeriod" label="Период от време" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <icd-picker v-model="document.diseaseIcdCode" label="заболяване" />
                    </v-col>
                </v-row>
                <v-row class="align-end">
                    <v-col cols="12" md="4">
                        <v-textarea v-model="document.previousDisease" dense label="Предшестваща причина" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.previousDiseasePeriod" label="Период от време" />
                    </v-col>
                </v-row>
                <v-row class="align-end">
                    <v-col cols="12" md="4">
                        <v-textarea v-model="document.mainDisease" dense label="Основна причина" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.mainDiseasePeriod" label="Период от време" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <icd-picker v-model="document.mainIcdCode" label="заболяване" />
                    </v-col>
                </v-row>
                <v-row class="align-end">
                    <v-col cols="12" md="4">
                        <v-textarea v-model="document.otherDisease" dense label="Друга причина" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.otherDiseasePeriod" label="Период от време" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <icd-picker v-model="document.otherIcdCode" label="заболяване" />
                    </v-col>
                </v-row>
                <v-row class="align-end">
                    <v-col cols="12" md="4">
                        <v-textarea v-model="document.pathologyDisease" dense label="Други важни състояния" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field v-model="document.pathologyDiseasePeriod" label="Период от време" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <dropdown
                            v-model="document.deathReasonId"
                            required
                            :items="deathReason"
                            label="Смъртта е настъпила от"
                        >
                        </dropdown>
                    </v-col>
                    <v-col cols="12" md="4">
                        <dropdown
                            v-model="document.deathEstablishedById"
                            :items="deathEstablishedBy"
                            label="Смъртта е установена от"
                            required
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions v-if="$allowWrite">
            <v-menu v-if="document.exists" open-on-hover bottom offset-y pointer>
                <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <btn action="Print" :disabled="isLoading" />
                    </div>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="pointer" @click="printWithoutPreview">Печат</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title class="pointer" @click="print">Печат - преглед</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <btn action="Save" :disabled="$loading.isVisible" @click="save" />
            <v-spacer />
            <btn
                v-if="document.exists"
                action="Delete"
                color="error"
                :disabled="$loading.isVisible"
                @click="deleteDeathNotice()"
            />
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import { CountryDto } from '@/model/Nomenclature/CountryDto';
    import { DeathEstablishedByDto } from '@/model/Nomenclature/DeathEstablishedByDto';
    import { DeathReasonDto } from '@/model/Nomenclature/DeathReasonDto';
    import { PlaceOfDeathDto } from '@/model/Nomenclature/PlaceOfDeathDto';
    import { DeathNoticeDto } from '@/model/Patient/Gp/DeathNoticeDto';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { countryService } from '@/service/Nomenclature/CountryService';
    import { deathEstablishedByService } from '@/service/Nomenclature/DeathEstablishedByService';
    import { deathReasonService } from '@/service/Nomenclature/DeathReasonService';
    import { placeOfDeathService } from '@/service/Nomenclature/PlaceOfDeathService';
    import { deathNoticeService } from '@/service/Patient/Gp/DeathNoticeService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { genderCache } from '@/store/Nomenclature/GenderCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { IcdPicker }
    })
    export default class DeathNotice extends Vue {
        private get currentPatient() {
            return currentPatientCache.value;
        }

        private placeOfDeath: PlaceOfDeathDto[] = [];
        private deathEstablishedBy: DeathEstablishedByDto[] = [];
        private deathReason: DeathReasonDto[] = [];
        private document: DeathNoticeDto = new DeathNoticeDto();
        private isFormValid: boolean = false;

        private get genders() {
            return genderCache.items;
        }

        private countries: CountryDto[] = [];

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private async mounted() {
            await this.getCountries();
            const doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber;
            if (doctorEmployeeSeqNumber) {
                this.$loading.show();
                try {
                    await this.getPlacesOfDeath();
                    await this.getAllDeathEstablishedBy();
                    await this.getDeathReasons();
                    await this.getDeathNotice(doctorEmployeeSeqNumber);
                } finally {
                    this.$loading.hide();
                }
            } else {
                await this.$router.push(`/Patient/PersonalData/${currentPatientCache.value.key.patientId}`);
            }
        }

        private async getCountries() {
            const response = await countryService.getCountries();
            this.countries = response.data;
        }

        private async getPlacesOfDeath() {
            const responsePlaces = await placeOfDeathService.getPlacesOfDeath();
            this.placeOfDeath = responsePlaces.data;
        }

        private async getDeathReasons() {
            const responseReason = await deathReasonService.getDeathReasons();
            this.deathReason = responseReason.data;
        }

        private async getAllDeathEstablishedBy() {
            const responseEstablishedBy = await deathEstablishedByService.getAllDeathEstablishedBy();
            this.deathEstablishedBy = responseEstablishedBy.data;
        }

        private async getDeathNotice(doctorEmployeeSeqNumber: number) {
            const responseDeathNotice = await deathNoticeService.getDeathNotice(
                currentPatientCache.value.key.patientId,
                doctorEmployeeSeqNumber
            );
            this.document = responseDeathNotice.data;
            if (!this.document.exists) {
                this.fillCurrentPatientAndDoctors();
            }
        }

        private fillCurrentPatientAndDoctors() {
            this.document.patientId = currentPatientCache.value.key.patientId;
            const { current } = userContextCache;
            if (current) {
                this.document.doctorEmployeeSeqNumber = current.employeeSeqNumber;
                this.document.deputyDoctorId = current.deputyDoctorId;
                this.document.deputyDoctorIsHired = current.deputyDoctorIsHired;
                this.document.deputyDoctorName = current.deputyDoctorName;
            }
        }

        private async update() {
            const errors = (await deathNoticeService.canSaveDeathNotice(this.document)).data;
            this.$loading.show();
            try {
                if (!errors.length) {
                    this.fillCurrentPatientAndDoctors();
                    await this.callCreateOrUpdateDeathNotice();
                    this.$notifier.showSuccess('', `Успешен запис`);
                } else {
                    for (let errorPos: number = 0; errorPos < errors.length; errorPos++) {
                        this.$notifier.showError('Внимание', `Грешка при запис: ${errors[errorPos]}.`);
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async callCreateOrUpdateDeathNotice() {
            if (!this.document.exists) {
                await deathNoticeService.createDeathNotice(this.document);
                this.document.exists = true;
                eventBus.$emit('create-referral-event', true, 'DeathNotice');
            } else {
                await deathNoticeService.updateDeathNotice(this.document);
            }
        }

        private save() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isFormValid) {
                this.update();
            }
        }

        private async deleteDeathNotice() {
            this.$loading.show();
            try {
                const response = await deathNoticeService.deleteDeathNotice(currentPatientCache.value.key.patientId);
                this.document = new DeathNoticeDto();
                this.document.number = response?.data ?? 0;
                this.fillCurrentPatientAndDoctors();
                this.$notifier.showSuccess('', `Успешно изтриване`);
                eventBus.$emit('delete-referral-event', false, 'DeathNotice');
            } finally {
                this.$loading.hide();
            }
        }

        private print() {
            if (this.document.exists) {
                this.$router.push(`/Print/Patient.Gp.DeathNotice/${currentPatientCache.value.key.patientId}`);
            } else {
                // Todo: notification
            }
        }

        private async printWithoutPreview() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = currentPatientCache.value.key.patientId.toString();
                const report = 'Patient.Gp.DeathNotice';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
