<template>
    <v-form ref="deputyDoctorForm" v-model="isFormValid">
        <v-card>
            <v-card-title>
                Добавяне на заместващ лекар за титуляр {{ primaryDoctorEmployee.employeeTitle }}
            </v-card-title>
            <v-card-actions>
                <btn action="Back" :to="`/Employee/Edit/${practiceId}/${doctorEmployeeSeqNumber}`" />
                <btn v-if="allowWrite(doctorEmployeeSeqNumber)" action="Next" @click="createDoctorDeputyAsDeputy" />
            </v-card-actions>
            <v-card-text>
                <doctor-picker
                    v-model="selectedDoctor"
                    required
                    label="Търсене на заместващ лекар"
                    :hint="!selectedDoctorIsExternal ? 'Избраният лекар работи в практиката на титуляра.' : ''"
                />
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Ref } from 'vue-property-decorator';

    import DoctorPicker from '@/component/Picker/DoctorPicker.vue';
    import { DoctorDropdownDto } from '@/model/Doctor/DoctorDropdownDto';
    import { DoctorDeputyCreateDeputyCommand } from '@/model/Employee/Deputy/DoctorDeputyCreateDeputyCommand';
    import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
    import { doctorDeputyService } from '@/service/Employee/Deputy/DoctorDeputyService';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { EmployeeBase } from '@/view/Employee/EmployeeBase';

    @Component({ components: { DoctorPicker } })
    export default class DoctorDeputyCreateDeputy extends EmployeeBase {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private isFormValid: boolean = false;
        private uinsOfEmployees: string[] = [];
        private primaryDoctorEmployee: EmployeeTitleDto = new EmployeeTitleDto();
        private selectedDoctor: DoctorDropdownDto | null = null;

        private async mounted() {
            this.$loading.show();
            try {
                const doctorEmployees = await employeeService.getDoctorEmployees(this.practiceId);
                this.uinsOfEmployees = doctorEmployees.map((de) => de.doctorUin);
                this.primaryDoctorEmployee =
                    doctorEmployees.find((de) => de.employeeSeqNumber === this.doctorEmployeeSeqNumber) ??
                    new EmployeeTitleDto();
            } finally {
                this.$loading.hide();
            }
        }

        @Ref()
        private deputyDoctorForm!: { validate: () => boolean };

        private get selectedDoctorIsExternal() {
            return this.selectedDoctor && !this.uinsOfEmployees.includes(this.selectedDoctor.uin);
        }

        private async createDoctorDeputyAsDeputy() {
            this.deputyDoctorForm.validate();
            if (this.isFormValid && this.selectedDoctor) {
                this.$loading.show();
                try {
                    const command = new DoctorDeputyCreateDeputyCommand();
                    command.practiceId = this.practiceId;
                    command.primaryDoctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
                    command.deputyDoctorId = this.selectedDoctor.id;
                    await doctorDeputyService.createDoctorDeputyAsDeputy(command);
                    this.$router.push(
                        `/Employee/DoctorDeputy/Edit/${this.practiceId}/${this.doctorEmployeeSeqNumber}/${this.selectedDoctor.id}/false`
                    );
                } finally {
                    this.$loading.hide();
                }
            }
        }
    }
</script>
