<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Удостоверение"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <v-card-text>
                <v-row class="mt-2">
                    <v-col cols="6" md="3">
                        <text-field v-model="document.number" label="Изх. номер:" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <date-picker v-model="document.issueDate" label="Дата:" />
                    </v-col>
                    <v-col cols="6">
                        <text-field v-model="document.practiceName" disabled label="Лечебно заведение:" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field v-model="document.practiceCity" disabled label="Нас. място:"
                    /></v-col>
                    <v-col cols="12" md="6">
                        <text-field v-model="document.practiceAddress" disabled label="Адрес на леч. заведение:" />
                    </v-col>
                    <v-col offset="6" cols="6" md="6">
                        <text-field v-model="currentVisit.practiceNumber" disabled label="РЦЗ номер:"
                    /></v-col>
                    <v-col offset="6" cols="6" md="6">
                        <text-field v-model="currentVisit.doctorNames" disabled label="Лекар имена:"
                    /></v-col>
                    <main-title offset="5">Удостоверение</main-title>
                    <v-col cols="12" md="10">
                        <text-field
                            :value="`${currentPatient.firstName} ${currentPatient.middleName} ${currentPatient.lastName}`"
                            disabled
                            label="Пациент имена:"
                    /></v-col>
                    <v-col cols="3" md="2">
                        <text-field v-model="currentPatient.age" disabled label="Възраст:"
                    /></v-col>
                </v-row>
                <text-field :value="currentPatientAddress" disabled label="Пациент адрес:" />
                <text-field v-model="document.prescriptionBookNumber" disabled label="Номер на рецептурна книжка:" />
                <v-textarea v-model="document.condition" rows="2" label="Обективно състояние:" />
                <v-textarea v-model="document.diagnoses" rows="2" label="Диагноза:" />
                <v-textarea v-model="document.medicines" rows="2" label="Лекарствени продукти:" />
                <v-textarea v-model="document.recommendations" rows="2" label="Препоръки:" />
            </v-card-text>
        </v-card>
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { CertificateSocialServicesDto } from '@/model/Exam/Certificate/CertificateSocialServicesDto';
    import { certificateSocialServicesService } from '@/service/Exam/Certificate/CertificateSocialServicesService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';

    @Component({
        components: { ExamLayout }
    })
    export default class CertificateSocialServices extends Vue {
        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get currentVisit() {
            return currentVisitCache.value;
        }

        private get currentPatientAddress() {
            return `${this.currentPatient.address.cityName} ${
                this.currentPatient.address.street !== null ? this.currentPatient.address.street : ' '
            } ${this.currentPatient.address.streetNumber !== null ? this.currentPatient.address.streetNumber : ' '} ${
                this.currentPatient.address.neighborhood !== null ? this.currentPatient.address.neighborhood : ' '
            }`;
        }

        private document: CertificateSocialServicesDto = new CertificateSocialServicesDto();

        private async mounted() {
            await this.load();
        }

        private async load() {
            this.$loading.show();
            try {
                if (!this.documentId) {
                    const responseExam = await certificateSocialServicesService.getInfoByExamId(this.examId);
                    this.document = responseExam.data;
                } else {
                    const response = await certificateSocialServicesService.getCertificateSocialServices(
                        this.documentId
                    );
                    this.document = response.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (documentId === 0) {
                    const response = await certificateSocialServicesService.createCertificateSocialServices(
                        this.document
                    );
                    documentId = Number(response.data);
                    eventBus.$emit('create-referral-event', documentId, 'CertificateSocialServices', 'Удостоверение');
                } else {
                    await certificateSocialServicesService.updateCertificateSocialServices(this.document);
                    eventBus.$emit('update-referral-event', 'Удостоверение');
                }

                this.$router.push(`/Exam/CertificateSocialServices/Edit/${documentId}`);
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                await certificateSocialServicesService.deleteCertificateSocialServices(documentId);
                this.$router.push(`/Exam/CertificateSocialServices/Create/${currentVisitCache.value.id}`);
                eventBus.$emit('delete-referral-event', documentId, 'CertificateSocialServices', 'Удостоверение');
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Certificate.CertificateSocialServices/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Certificate.CertificateSocialServices';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
