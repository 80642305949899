import { Report365RowBase } from './Report365RowBase';

export class Report365VisitRowDto extends Report365RowBase {
    public total: number | null = null;
    public children: number | null = null;
    public prevention: number | null = null;
    public preventionChildren: number | null = null;
    public domestic: number | null = null;
    public telemedicine: number | null = null;
}
