import { userContextCache } from '@/store/User/UserContextCache';

import { MenuItemModel } from './MenuItemModel';

export const createHelpMenuItems = () => {
    const { currentPracticeId } = userContextCache;

    return [
        new MenuItemModel('Поддръжка', '', ''),
        new MenuItemModel(
            'Връзка с Контракс',
            'mdi-lifebuoy',
            currentPracticeId ? `/Help/Contact/${currentPracticeId}` : '/Help/Contact'
        ),
        new MenuItemModel('Локален сървър', 'mdi-server', '/Help/LocalServer'),
        new MenuItemModel('Информация', '', ''),
        new MenuItemModel('Новости', 'mdi-information-outline', `/Help/UserGuide?path=whats-new/`),
        new MenuItemModel('Как се работи?', 'mdi-book-open-page-variant-outline', `/Help/UserGuide?path=guide/`),
        new MenuItemModel('Видео уроци', 'mdi-youtube', '/Help/Video'),
        new MenuItemModel('Общи условия', 'mdi-scale-balance', `/Help/UserGuide?path=user-agreement/`)
    ];
};
