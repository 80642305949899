import { AxiosResponse } from 'axios';

import { SubjectToDispensaryExamDto } from '@/model/Query/Exam/SubjectToDispensaryExamDto';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const subjectToDispensaryExamService = {
    getSubjectToDispensaryExam(filter: ReportIntervalFilterDto): Promise<AxiosResponse<SubjectToDispensaryExamDto[]>> {
        return httpService.post('SubjectToDispensaryExam/GetSubjectToDispensaryExam', filter);
    },

    async exportSubjectToDispensaryExam(filter: ReportIntervalFilterDto): Promise<void> {
        await httpService.download('/SubjectToDispensaryExam/ExportExcel', { params: filter });
    }
};
