<template>
    <v-toolbar-title>
        <span v-if="!currentUser.user" class="pointer" @click="signinRedirect">Вход</span>

        <!-- Следният надпис никога не се вижда, защото този компонент е скрит ако има валиден access token. -->
        <span v-else-if="currentUser.isAuthenticated">Добре дошли</span>

        <!--
            Ако браузърът се стартира след като access token-ът е изтекъл, OidcClient.ts прави опит за подновяване на сесията,
            който завършва с нов access token или с изход от системата. И в двата случая надписът се вижда закратко.
        -->
        <span v-else>Проверка на сесията...</span>

        <!-- Независимо от надписа, винаги има спасителна иконка за вход. -->
        <v-tooltip bottom>
            <template #activator="{ on }">
                <v-btn icon v-on="on" @click="signinRedirect">
                    <value-loading v-model="isExecutingSigninRedirect" />
                    <v-icon v-if="!isExecutingSigninRedirect">mdi-login</v-icon>
                </v-btn>
            </template>
            Вход
        </v-tooltip>
    </v-toolbar-title>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import ValueLoading from '@/component/Common/ValueLoading.vue';
    import { oidcClient } from '@/service/Infrastructure/OidcClient';
    import { currentUser } from '@/store/User/CurrentUser';
    import { signinProgress } from '@/store/User/SigninProgress';

    @Component({
        components: { ValueLoading }
    })
    export default class SigninIcon extends Vue {
        private get currentUser() {
            return currentUser;
        }

        private get isExecutingSigninRedirect() {
            return signinProgress.isExecutingSigninRedirect;
        }

        private signinRedirect() {
            return oidcClient.signinRedirect();
        }
    }
</script>
