<template>
    <v-form ref="form" v-model="isFormValid">
        <v-card>
            <v-card-title>Групова проверка на осигурителен статус</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col v-if="isGp" cols="12" md="4">
                        <v-switch v-model="value.allPatients" label="Всички пациенти от пациентската листа"></v-switch>
                    </v-col>
                    <v-col v-if="!value.allPatients" cols="12" md="8">
                        Прегледани пациента за период
                        <report-date-picker
                            v-model="value"
                            class="pt-2"
                            :disabled="$loading.isVisible"
                            @input="checkStatus"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <btn action="CheckDocument" @click="checkStatus" />
            </v-card-actions>
            <v-card-text v-if="patientCount">
                <v-progress-linear :value="progressPercentage" color="info" height="25">
                    <template #default="{ value }">
                        <strong>{{ value }}% проверени от общо {{ patientCount }} пациента</strong>
                    </template>
                </v-progress-linear>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { InsuranceCheckRequestDto } from '@/model/Patient/InsuranceStatus/InsuranceCheckRequestDto';
    import { insuranceStatusService } from '@/service/Patient/InsuranceStatusService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { ReportDatePicker }
    })
    export default class InsuranceStatusGroupCheck extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private isFormValid: boolean = false;
        private value: InsuranceCheckRequestDto = new InsuranceCheckRequestDto();
        private patientCount: number | null = null;

        private mounted() {
            this.value.fillPreviousMonth();
        }

        private get isGp() {
            return (
                userContextCache.userContexts.find(
                    (uc) => uc.practiceId === this.practiceId && uc.employeeSeqNumber === this.doctorEmployeeSeqNumber
                )?.specialtyCode === SpecialtyCode.Gp
            );
        }

        private async checkStatus() {
            this.$loading.show();
            try {
                (this.$refs.form as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    this.value.practiceId = this.practiceId;
                    this.value.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
                    await this.checkStatusByPortions();
                    // Статусът на текущия пациент се обновява в заглавната лента, в случай че пациентът е бил сред проверените.
                    await currentPatientCache.reload();
                    this.$notifier.showSuccess('', 'Проверката завърши успешно!');
                }
            } finally {
                this.$loading.hide();
            }
        }

        private get progressPercentage() {
            const maxPercentage = 100;
            return this.patientCount ? Math.round((this.value.skipCount * maxPercentage) / this.patientCount) : 0;
        }

        // Пациентите се проверяват на порции. Размерът на порцията се определя от сървъра.
        private async checkStatusByPortions() {
            this.patientCount = await insuranceStatusService.getPatientCountToCheck(this.value);

            this.value.skipCount = 0;
            let checkedCount = await insuranceStatusService.insuranceStatusGroupCheck(this.value);
            while (checkedCount > 0) {
                this.value.skipCount += checkedCount;
                /* eslint-disable no-await-in-loop */
                checkedCount = await insuranceStatusService.insuranceStatusGroupCheck(this.value);
                /* eslint-enable no-await-in-loop */
            }
        }
    }
</script>
