import { AxiosResponse } from 'axios';

import { ReferralDispensaryObservationDto } from '@/model/Exam/Referral/ReferralDispensaryObservationDto';
import { ReferralDispensaryObservationViewDto } from '@/model/Exam/Referral/ReferralDispensaryObservationViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referralDispensaryObservationService = {
    getReferralInfoByExamId(examId: number): Promise<AxiosResponse<ReferralDispensaryObservationDto>> {
        return httpService.get(`/ReferralDispensaryObservation/GetReferralInfoByExamId/${examId}`);
    },

    getReferralDispensaryObservation(id: number): Promise<AxiosResponse<ReferralDispensaryObservationDto>> {
        return httpService.get(`/ReferralDispensaryObservation/GetReferralDispensaryObservation/${id}`);
    },

    createReferralDispensaryObservation(
        data: ReferralDispensaryObservationDto
    ): Promise<AxiosResponse<ReferralDispensaryObservationDto>> {
        return httpService.post('/ReferralDispensaryObservation/CreateReferralDispensaryObservation', data);
    },

    updateReferralDispensaryObservation(
        data: ReferralDispensaryObservationDto
    ): Promise<AxiosResponse<ReferralDispensaryObservationDto>> {
        return httpService.put('/ReferralDispensaryObservation/UpdateReferralDispensaryObservation/', data);
    },

    deleteReferralDispensaryObservation(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/ReferralDispensaryObservation/DeleteReferralDispensaryObservation/${id}`);
    },

    getReferralsByExamId(examId: number): Promise<AxiosResponse<ReferralDispensaryObservationViewDto[]>> {
        return httpService.get(`/ReferralDispensaryObservation/GetReferralDispensaryObservationViewsByExam`, {
            params: {
                examId
            }
        });
    }
};
