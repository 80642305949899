<template>
    <data-table title="Здравни райони" :headers="headers" :items="rhifs" :loading="$loading.isVisible">
        <template #actions>
            <btn action="Back" to="/DevTool/Rhif" />
            <btn action="New" :to="`/DevTool/RhifArea/Create/${rhifId}`">Добавяне на здравен район</btn>
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/DevTool/RhifArea/Edit/${rhifId}/${item.id}`" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RhifAreaDto } from '@/model/Nomenclature/RhifAreaDto';
    import { rhifAreaService } from '@/service/Nomenclature/RhifAreaService';

    @Component
    export default class RhifAreaList extends Vue {
        @Prop()
        private rhifId!: number;

        private rhifs: RhifAreaDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'Наименование', value: 'name' },
            { text: 'Код', value: 'code' }
        ];

        private async mounted() {
            this.$loading.show();
            try {
                const response = await rhifAreaService.getRhifAreasByRhifId(this.rhifId);
                this.rhifs = response.data;
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
