import { Type } from 'class-transformer';

import { Annex56TotalDiseaseRowDto } from '@/model/Report/Rhi/Annex56/Annex56TotalDiseaseRowDto';

export class Annex56TotalDiseaseDto {
    public name: string = '';

    @Type(() => Annex56TotalDiseaseRowDto)
    public totalDiseases: Annex56TotalDiseaseRowDto[] = [];
}
