<!-- Водещо заглавие в екран с няколко v-card-title компонента, например заглавие на бланка към преглед. -->
<template>
    <v-card-title class="no-margin-no-padding">
        <h3>
            <slot></slot>
        </h3>
    </v-card-title>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class MainTitle extends Vue {}
</script>
