<template>
    <v-card>
        <v-card-title>Извличане на подпис</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-textarea v-model="signatureBase64" label="Подпис base64" />
                </v-col>
            </v-row>
            <img :src="imgSrc" />
        </v-card-text>
        <v-card-actions>
            <btn action="Sign" @click="captureSign">Изличане на подпис</btn>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { localServerSignService } from '@/service/LocalServer/LocalServerSignService';

    @Component
    export default class SingCapture extends Vue {
        private signatureBase64: string | null = null;
        private imgSrc: string | null = null;

        private async captureSign() {
            this.imgSrc = null;
            const response = await localServerSignService.signCapture('Тест подпис', 'Подписване');
            if (response.isError) {
                this.$notifier.showWarning('', 'Възникна проблем при подписване на файла');
            } else {
                console.log(response);
                this.signatureBase64 = response.contents;
                this.imgSrc = `data:image/png;base64,${response.data}`;
            }
        }
    }
</script>
