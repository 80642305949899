<template>
    <alert :value="drugDoesNotExistInSegmentA" type="info">
        <span v-if="segmentADrugs && segmentADrugs.length > 0">
            Избраният лекарствен продукт липсва в 'Отрязък А'
            <span v-if="segmentADrugs.some((drug) => drug && drug.tradeName)">
                , моля посочете някой от следните продукти:
            </span>
        </span>
        <span
            v-for="drug in segmentADrugs.filter((drug) => drug && drug.tradeName)"
            :key="drug.nhifId"
            style="color: var(--v-success-base)"
        >
            <br />
            {{ drug.tradeName }}/{{ drug.internationalName }}
        </span>
    </alert>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';

    @Component
    export default class PrescriptionNhifAlert extends Vue {
        @Prop()
        private selectedDrug!: NhifDrugDto;

        @Prop()
        private segmentADrugs!: NhifDrugDto[];

        private get drugDoesNotExistInSegmentA() {
            return (
                Boolean(this.selectedDrug?.tradeName) &&
                !this.segmentADrugs.some((drug) => drug?.nhifId === this.selectedDrug.nhifId)
            );
        }
    }
</script>
