import { Report365RowBase } from './Report365RowBase';

export class Report365PsychiatricAccidentRowDto extends Report365RowBase {
    public totalKills: number | null = null;
    public totalSelfKills: number | null = null;
    public totalEscape: number | null = null;
    public totalAttackStaff: number | null = null;
    public totalAttackSick: number | null = null;
    public totalAttackInventory: number | null = null;
}
