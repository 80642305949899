import { dateUtil } from '@/util/DateUtil';

// How to format numbers as currency strings:
// https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
const bgnFormatter = new Intl.NumberFormat('bg-BG', {
    style: 'currency',
    currency: 'BGN'
});

export const formatters = {
    boolean(flag: boolean | null): string {
        return flag ? 'Да' : 'Не';
    },

    date(date: Date | string | null): string {
        return dateUtil.formatBgDate(date);
    },

    dateTime(date: Date | string | null): string {
        return dateUtil.formatBgDateTime(date);
    },

    currency(amount: number | null | undefined): string {
        return typeof amount === 'number' ? bgnFormatter.format(amount) : '';
    },

    capitalizeFirstLetter(value: string | null): string {
        return value ? value[0].toUpperCase() + value.slice(1) : '';
    },

    remainingDays(days: number | null) {
        const yesterday = -1;
        return days === null
            ? ''
            : days > 1
              ? `след ${days} дни`
              : days === 1
                ? 'УТРЕ'
                : days === 0
                  ? 'ДНЕС'
                  : days === yesterday
                    ? 'вчера'
                    : `преди ${-days} дни`;
    },

    cyrillicToLatin(val: string | null): string | null {
        if (!val) {
            return val;
        }
        let result = val;
        /* eslint-disable prettier/prettier */
        const convertedLetters = new Map<string, string>([
            ['А', 'A'], ['Б', 'B'], ['В', 'V'], ['Г', 'G'], ['Д', 'D'], ['Е', 'E'],
            ['Ж', 'X'], ['З', 'Z'], ['И', 'I'], ['Й', 'J'], ['К', 'K'], ['Л', 'L'],
            ['М', 'M'], ['Н', 'H'], ['О', 'O'], ['П', 'P'], ['Р', 'P'], ['С', 'S'],
            ['Т', 'T'], ['У', 'U'], ['Ф', 'F'], ['Х', 'X'], ['Ц', 'C'], ['Ч', 'Q'],
            ['Ш', 'W'], ['Щ', 'W'], ['Ъ', 'Y'], ['Ь', 'J'], ['Ю', 'Y'], ['Я', 'R'],

            ['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'r'], ['д', 'd'], ['е', 'e'],
            ['ж', 'x'], ['з', 'z'], ['и', 'i'], ['й', 'j'], ['к', 'k'], ['л', 'l'],
            ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'n'], ['р', 'p'], ['с', 's'],
            ['т', 't'], ['у', 'u'], ['ф', 'f'], ['х', 'x'], ['ц', 'c'], ['ч', 'q'],
            ['ш', 'w'], ['щ', 'w'], ['ъ', 'y'], ['ь', 'j'], ['ю', 'y'], ['я', 'r']
        ]);
        /* eslint-enable prettier/prettier */

        for (const pair of convertedLetters) {
            result = result?.replaceAll(pair[0], pair[1]);
        }
        return result;
    }
};
