const config = {
    removeOne: 1
};

// Идеите за removeFirst и removeAll са взети от следните две месета:
// https://stackoverflow.com/questions/5767325/how-can-i-remove-a-specific-item-from-an-array?answertab=scoredesc#tab-top
// https://stackoverflow.com/questions/55972897/typescript-array-remove-item-if-condition-matches
export const arrayUtil = {
    // Може да се използва по два начина:
    // removeFirst(array, item) или
    // removeFirst(array, (item) => item.x === y)
    removeFirst<T>(array: T[], itemOrPredicate: T | ((item: T) => boolean)): T | null {
        if (typeof itemOrPredicate === 'function') {
            const predicate = itemOrPredicate as (item: T) => boolean;
            for (let index = 0; index < array.length; index++) {
                if (predicate(array[index])) {
                    return array.splice(index, config.removeOne)[0];
                }
            }
        } else {
            const index = array.indexOf(itemOrPredicate);
            if (index >= 0) {
                return array.splice(index, config.removeOne)[0];
            }
        }
        return null;
    },

    removeAll<T>(array: T[], predicate: (item: T) => boolean): number {
        let removedCount = 0;
        for (let index = array.length - config.removeOne; index >= 0; index--) {
            if (predicate(array[index])) {
                array.splice(index, config.removeOne);
                ++removedCount;
            }
        }

        // Внимание: Следният вариант може да е по-бърз, но промените не се засичат от Vue.
        //let newIndex = 0;
        //for (let index = 0; index < array.length; index++) {
        //    const item = array[index];
        //    if (!predicate(item)) {
        //        if (newIndex !== index) {
        //            array[newIndex] = item;
        //        }
        //        newIndex++;
        //    }
        //}
        //const removedCount = array.length - newIndex;
        //array.length = newIndex;

        return removedCount;
    },

    removeAt<T>(array: T[], index: number): void {
        array.splice(index, config.removeOne);
    },

    groupByToMap<T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) {
        return array.reduce((map, value, index, items) => {
            const key = predicate(value, index, items);
            if (map.get(key)) {
                map.get(key)?.push(value);
            } else {
                map.set(key, [value]);
            }
            return map;
        }, new Map<Q, T[]>());
    }
};
