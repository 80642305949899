import { AxiosResponse } from 'axios';

import { PlaceOfDeathDto } from '@/model/Nomenclature/PlaceOfDeathDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const placeOfDeathService = {
    getPlacesOfDeath(): Promise<AxiosResponse<PlaceOfDeathDto[]>> {
        return httpService.get('/PlaceOfDeath/GetPlacesOfDeath');
    }
};
