import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { RequestForImagingTestDto } from '@/model/Exam/RequestForImagingTest/RequestForImagingTestDto';
import { RequestForImagingTestViewDto } from '@/model/Exam/RequestForImagingTest/RequestForImagingTestViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const requestForImagingTestService = {
    async getRequestForImagingTest(id: number): Promise<RequestForImagingTestDto> {
        const response = await httpService.get(`/RequestForImagingTest/GetRequestForImagingTest/${id}`);
        return plainToInstance(RequestForImagingTestDto, response.data);
    },

    getRequestForImagingTestByExam(examId: number): Promise<AxiosResponse<RequestForImagingTestViewDto[]>> {
        return httpService.get('/RequestForImagingTest/GetByExam/', {
            params: { examId }
        });
    },

    createRequestForImagingTest(data: RequestForImagingTestDto): Promise<AxiosResponse<number>> {
        return httpService.post('/RequestForImagingTest/CreateRequestForImagingTest/', data);
    },

    updateRequestForImagingTest(data: RequestForImagingTestDto): Promise<AxiosResponse<void>> {
        return httpService.put('/RequestForImagingTest/UpdateRequestForImagingTest/', data);
    },

    deleteRequestForImagingTest(id: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/RequestForImagingTest/DeleteRequestForImagingTest/${id}`);
    }
};
