<template>
    <data-table :items="items" :headers="tableHeaders" title="ЛКК комисии">
        <template #actions>
            <btn action="New" :to="`/Practice/LkkCommittee/Create/${practiceId}`">Добавяне на ЛКК комисия</btn>
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/Practice/LkkCommittee/Edit/${practiceId}/${item.seqNumber}`" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { LkkCommitteeViewDto } from '@/model/Practice/LkkCommittee/LkkCommitteeViewDto';
    import { lkkCommitteeService } from '@/service/Practice/LkkCommittee/LkkCommitteeService';

    @Component
    export default class LkkCommitteeList extends Vue {
        @Prop()
        private practiceId!: number;

        private items: LkkCommitteeViewDto[] = [];
        private loading: boolean = false;

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Номер:', value: 'number' },
                { text: 'Име:', value: 'name' },
                { text: 'Специализирана:', value: 'isSpecializedString' },
                { text: 'Неактивна:', value: 'isDeletedString' }
            ];
        }

        private async mounted() {
            this.$loading.show();
            try {
                this.items = await lkkCommitteeService.getLkkCommitteesForPractice(this.practiceId);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
