<template>
    <v-card>
        <v-card-text>
            <v-progress-linear :value="queryDto.percentageCovered" color="info" :buffer-value="100" height="25" striped>
                <template #default="{ value }">
                    <strong> {{ value }}% обхванати пациенти </strong>
                </template>
            </v-progress-linear>
            <data-table
                :items="queryDto.patients"
                :headers="headers"
                :loading="$loading.isVisible"
                title="Подлежащи на профилактика"
                :item-class="itemRowBackground"
            >
                <template #rowActions="{ item }">
                    <btn icon action="Visit" :to="`/Patient/VisitSummary/${item.patientId}`" />
                </template>
                <template #actions>
                    <date-picker
                        v-model="command.referenceDate"
                        label="Към дата"
                        class="mt-2"
                        required
                        :disabled="$loading.isVisible"
                        @input="getTableItems"
                    />
                    <v-spacer></v-spacer>
                    <btn action="Export" :disabled="!command.referenceDate" @click="exportExcel" />
                </template>
            </data-table>
            <v-row>
                <v-col cols="auto">Легенда:</v-col>
                <v-col cols="auto"><label class="py-1 style-uninsured">Неосигурен</label></v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PreventionQueryCommand } from '@/model/Query/Exam/PreventionQueryCommand';
    import { PreventionQueryDto } from '@/model/Query/Exam/PreventionQueryDto';
    import { PreventionQueryPatientDto } from '@/model/Query/Exam/PreventionQueryPatientDto';
    import { subjectToPreventiveExamService } from '@/service/Query/Exam/SubjectToPreventiveExamService';
    import { loadingState } from '@/store/LoadingState';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class SubjectToPreventiveExam extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private command: PreventionQueryCommand = new PreventionQueryCommand();
        private queryDto: PreventionQueryDto = new PreventionQueryDto();
        private percentageCovered: number = 0;

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Идентификатор', value: 'identifier' },
                { text: 'Име', value: 'name' },
                { text: 'Дата на раждане', value: 'birthDate', formatter: formatters.date },
                { text: `Възраст към ${formatters.date(this.command.referenceDate)}`, value: 'ageAtReferenceDate' },
                { text: 'Адрес', value: 'address' },
                { text: 'Телефон', value: 'mobilePhone' },
                { text: 'Email', value: 'email' },
                { text: 'Липсващ преглед/изследване', value: 'missingActivities' }
            ];
        }

        private fillDoctorEmployee() {
            this.command.practiceId = this.practiceId;
            this.command.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async mounted() {
            await this.getTableItems();
        }

        private async getTableItems() {
            if (this.command.referenceDate) {
                this.$loading.show();
                try {
                    this.queryDto = new PreventionQueryDto();
                    this.fillDoctorEmployee();
                    this.queryDto = await subjectToPreventiveExamService.getSubjectToPrevention(this.command);
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private async exportExcel() {
            if (this.command.referenceDate) {
                loadingState.show();
                try {
                    this.fillDoctorEmployee();
                    await subjectToPreventiveExamService.exportSubjectToPrevention(this.command);
                } finally {
                    loadingState.hide();
                }
            }
        }

        private itemRowBackground(patient: PreventionQueryPatientDto) {
            if (!patient?.patientIsInsured) {
                return 'style-uninsured';
            }
            return 'style-main';
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../asset/Css/Register.css';
</style>
