<template>
    <router-link :to="to" class="text-decoration-none">
        <button v-ripple class="fastLinkButton text-center secondary--text">
            <div>
                <v-icon x-large>{{ icon }}</v-icon>
            </div>
            <slot></slot>
        </button>
    </router-link>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class TileLink extends Vue {
        @Prop({ required: true })
        private icon!: string;

        @Prop({ required: true })
        private to!: string;
    }
</script>

<style>
    .fastLinkButton {
        border: thin solid;
        width: 120px;
        height: 120px;
    }

    .fastLinkButton:hover {
        background-color: var(--v-secondary2-base);
    }
</style>
