import { AxiosResponse } from 'axios';

import { PracticeCounterDto } from '@/model/Setting/Counter/PracticeCounterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const practiceCounterService = {
    getCountersByPracticeId(id: number): Promise<AxiosResponse<PracticeCounterDto[]>> {
        return httpService.get(`/PracticeCounter/GetCountersByPracticeId/${id}`);
    },

    getPracticeCounter(practiceId: number, counterTypeCode: string): Promise<AxiosResponse<PracticeCounterDto>> {
        return httpService.get('/PracticeCounter/GetPracticeCounter', {
            params: { practiceId, counterTypeCode }
        });
    },

    createPracticeCounter(data: PracticeCounterDto): Promise<AxiosResponse> {
        return httpService.post('/PracticeCounter/CreatePracticeCounter', data);
    },

    updatePracticeCounter(data: PracticeCounterDto): Promise<AxiosResponse> {
        return httpService.post('/PracticeCounter/UpdatePracticeCounter', data);
    }
};
