import { AxiosResponse } from 'axios';

import { ResultReferralDto } from '@/model/Exam/Referral/ResultReferral/ResultReferralDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const ReferralResultService = {
    getResultData(examId: number, nrn: string): Promise<AxiosResponse<ResultReferralDto>> {
        return httpService.get('ReferralResult/GetResultData', { params: { examId, nrn } });
    }
};
