<template>
    <div>
        <alert :value="examDto.isDeleted" type="warning">Изтрит амбулаторен лист</alert>
        <v-row no-gutters>
            <v-col md="3" cols="6">
                <v-row class="flex-row d-flex">
                    <v-col cols="auto" class="pb-0">
                        <main-title v-if="!examDto.isDeleted">Амбулаторен лист</main-title>
                    </v-col>
                    <v-col cols="auto" class="pb-0">
                        <btn icon action="Show" @click="showPatientDoctorInfo = !showPatientDoctorInfo">
                            Показване пациент и лекар
                        </btn>
                    </v-col>
                    <v-col md="4" cols="auto" class="no-margin py-0">
                        <v-checkbox v-model="examDto.isSigned" readonly dense label="Подписан"></v-checkbox>
                    </v-col>
                    <v-col md="4" cols="auto" class="no-margin py-0">
                        <v-checkbox v-model="examDto.isPrinted" readonly dense label="Отпечатан"></v-checkbox>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md="9" cols="12">
                <AmbulatorySheetNumberDatePaidBy class="mt-2" :visit-id="visitId" :exam-dto="examDto" />
            </v-col>
        </v-row>
        <v-card v-if="showPatientDoctorInfo" :style="`background-color: ${backgroundColor}`">
            <v-row dense>
                <v-col cols="12" md="6"><PatientInfo /></v-col>
                <v-col cols="12" md="6"><DoctorInfo /></v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { ExamDto } from '@/model/Exam/ExamDto';
    import AmbulatorySheetNumberDatePaidBy from '@/view/Exam/AmbulatorySheet/AmbulatorySheetNumberDatePaidBy.vue';

    @Component({
        components: { DoctorInfo, AmbulatorySheetNumberDatePaidBy, PatientInfo }
    })
    export default class AmbulatorySheetTitleDoctorPatient extends Vue {
        @Prop({ required: true })
        private visitId!: number | null;

        @Prop()
        private examDto!: ExamDto;

        @Prop({ default: 'transparent' })
        private backgroundColor!: string;

        private showPatientDoctorInfo: boolean = false;
    }
</script>
