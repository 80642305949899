import { SubmitCommand } from '@/model/Nhis/SubmitCommand';

export class NhisReferralSubmitCommand extends SubmitCommand {
    constructor(
        xmlString: string,
        accessTokenValue: string,
        public entityId: number,
        public formType: string
    ) {
        super(xmlString, accessTokenValue);
    }
}
