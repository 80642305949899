import { Report365RowBase } from './Report365RowBase';

export class Report365PsychiatricPatientRowDto extends Report365RowBase {
    public totalStartYear: number | null = null;
    public totalDuringYear: number | null = null;
    public totalChildren: number | null = null;
    public totalFirstTime: number | null = null;
    public totalDiagnoseChanged: number | null = null;
    public totalDeregistered: number | null = null;
    public totalDied: number | null = null;
    public totalKilled: number | null = null;
    public totalOtherGroup: number | null = null;
    public totalEndYear: number | null = null;
    public totalDangerous: number | null = null;
}
