<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Физиопроцедурна карта" :items="items">
                <template v-if="items.length < 1" #actions>
                    <btn action="New" :to="`/Exam/PhysiotherapyCard/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/PhysiotherapyCard/Edit/${item.id}`" />
                    <btn icon action="Print" :to="`/Print/Exam.PhysiotherapyCard.PhysiotherapyCard/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PhysiotherapyCardViewDto } from '@/model/Exam/PhysiotherapyCard/PhysiotherapyCardViewDto';
    import { physiotherapyCardService } from '@/service/Exam/PhysiotherapyCardService';

    @Component
    export default class PhysiotherapyCardView extends Vue {
        @Prop()
        examId!: number;

        private items: PhysiotherapyCardViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Изходящ номер', value: 'number' },
                { text: `Диагноза`, value: 'diagnosis' },
                { text: 'Обективни данни', value: 'symptoms' },
                { text: 'Терапия', value: 'treatment' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await physiotherapyCardService.getPhysiotherapyCardsByExam(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
