import { Type } from 'class-transformer';

import { ServiceOfferDto } from '@/model/Reception/Service/ServiceOfferDto';

import { ServiceTitleDto } from './ServiceTitleDto';

export class PracticeServiceViewDto {
    [key: number]: number | null;

    @Type(() => ServiceTitleDto)
    public serviceTitle: ServiceTitleDto = new ServiceTitleDto();

    @Type(() => ServiceOfferDto)
    public serviceOffers: ServiceOfferDto[] = [];

    batchCoreServiceTypes: string[] = [];
    public hasBatchServiceOffers: boolean = false;
    public coreServiceType: string = '';
}
