import { NotificationCreateModel } from '@/model/Notification/NotificationCreateModel';
import { NotificationSeverity } from '@/model/Notification/NotificationSeverity';
import { dateUtil } from '@/util/DateUtil';

export class NotificationModel extends NotificationCreateModel {
    constructor(createModel: NotificationCreateModel) {
        super(createModel.title, createModel.text, createModel.severity, createModel.category);
    }

    public time: Date = new Date();
    public isRead: boolean = false;
    public id: number = Date.now();

    public get icon(): string {
        switch (this.severity) {
            case NotificationSeverity.Success: {
                return 'mdi-check-circle-outline';
            }
            case NotificationSeverity.Warn: {
                return 'mdi-alert-outline';
            }
            case NotificationSeverity.Error: {
                return 'mdi-alert-circle-outline';
            }
            case NotificationSeverity.Info: {
                return 'mdi-information-outline';
            }
            default: {
                return '';
            }
        }
    }

    public get color(): string {
        switch (this.severity) {
            case NotificationSeverity.Success: {
                return 'success';
            }
            case NotificationSeverity.Warn: {
                return 'warning';
            }
            case NotificationSeverity.Error: {
                return 'error';
            }
            case NotificationSeverity.Info: {
                return 'info';
            }
            default: {
                return '';
            }
        }
    }

    public get timeText(): string {
        return dateUtil.formatBgDateTime(this.time);
    }
}
