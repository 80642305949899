import { AllergyClinicalStatusDto } from '@/model/Nomenclature/AllergyClinicalStatusDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const allergyClinicalStatusService = {
    async getAllergyClinicalStatuses(): Promise<AllergyClinicalStatusDto[]> {
        const response = await httpService.get<AllergyClinicalStatusDto[]>(
            '/AllergyClinicalStatus/GetAllergyClinicalStatuses'
        );
        return response.data;
    }
};
