import { Type } from 'class-transformer';

import { ExamDurationSettingDto } from '@/model/Setting/ExamDuration/ExamDurationSettingDto';

import { UserSettingsUiDto } from './UserSettingsUiDto';

export class UserSettingsDto {
    @Type(() => UserSettingsUiDto)
    public ui: UserSettingsUiDto = new UserSettingsUiDto();

    @Type(() => ExamDurationSettingDto)
    public examDurationSettings: ExamDurationSettingDto[] = [];
}
