<template>
    <data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        :loading="$loading.isVisible"
        show-select
        title="Неотпечатани амбулаторни листове"
    >
        <template #actions>
            <report-date-picker v-model="filter" :disabled="$loading.isVisible" @input="getTableItems" />
            <btn v-if="selectedItems && selectedItems.length > 0" action="Print" @click="groupPrint()">
                Групово отпечатване
            </btn>
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/Exam/AmbulatorySheet/Edit/${item.id}`" />
            <btn icon action="Print" @click="printAction(item)" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { GroupPrintExamCommand } from '@/model/Exam/GroupPrintExamCommand';
    import { ExamNotPrintedDto } from '@/model/Query/Exam/ExamNotPrintedDto';
    import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
    import { examService } from '@/service/Exam/ExamService';
    import { printService } from '@/service/PrintService';
    import { examNotPrintedService } from '@/service/Query/Exam/ExamNotPrintedService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { ReportDatePicker }
    })
    export default class ExamNotPrinted extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private items: ExamNotPrintedDto[] = [];
        private filter: ReportIntervalFilterDto = new ReportIntervalFilterDto();
        private selectedItems: ExamNotPrintedDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'АЛ номер', value: 'number' },
            { text: 'АЛ НРН', value: 'nrn' },
            { text: 'Дата', value: 'startDateTime', formatter: formatters.date },
            { text: 'Идентификатор', value: 'patientIdentifier' },
            { text: 'Пациент', value: 'patientName' },
            { text: 'Извършил прегледа', value: 'doctorName' }
        ];

        private async getTableItems() {
            // Маркираните редове не се нулират автоматично при презареждане на items. Няма ефект и selectedItems = [];
            this.selectedItems.splice(0);

            this.$loading.show();
            try {
                this.items = [];
                this.fillDoctorEmployee();
                const response = await examNotPrintedService.getExamNotPrinted(this.filter);
                this.items = response.data;
            } finally {
                this.$loading.hide();
            }
        }

        private fillDoctorEmployee() {
            this.filter.practiceId = this.practiceId;
            this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async printAction(item: ExamNotPrintedDto) {
            this.$loading.show();
            try {
                // TODO: Да се печата 'Exam.AmbulatorySheetPatient' за прегледи, платени от пациента.
                await printService.printReport('Exam.AmbulatorySheet', {
                    visitId: item.id,
                    page1Only: true
                });

                await examService.setExamIsPrinted(
                    new GroupPrintExamCommand(userContextCache.currentPracticeId ?? 0, [item.id])
                );
                await this.getTableItems();
            } finally {
                this.$loading.hide();
            }
        }

        private async groupPrint() {
            this.$loading.show();
            try {
                const models = this.selectedItems.map((item) => ({
                    // TODO: Да се печата 'Exam.AmbulatorySheetPatient' за прегледи, платени от пациента.
                    report: 'Exam.AmbulatorySheet',
                    parameters: {
                        visitId: item.id,
                        page1Only: true
                    }
                }));
                await printService.printGroupReport(models);

                const examIds = this.selectedItems.map((item) => item.id);
                await this.setExamIsPrinted(
                    new GroupPrintExamCommand(userContextCache.currentPracticeId ?? 0, examIds)
                );
                await this.getTableItems();
            } finally {
                this.$loading.hide();
            }
        }

        private async setExamIsPrinted(command: GroupPrintExamCommand) {
            await examService.setExamIsPrinted(command);
        }

        private async mounted() {
            if (!this.filter?.startDate || !this.filter?.endDate) {
                this.filter.fillCurrentMonth();
            }
            await this.getTableItems();
        }
    }
</script>
