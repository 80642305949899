import { Type } from 'class-transformer';

import { Annex56FactorForHealthStatusTotalRowDto } from '@/model/Report/Rhi/Annex56/Annex56FactorForHealthStatusTotalRowDto';

export class Annex56FactorForHealthStatusTotalDto {
    public name: string = '';

    @Type(() => Annex56FactorForHealthStatusTotalRowDto)
    public factorForHealthStatusesTotal: Annex56FactorForHealthStatusTotalRowDto[] = [];
}
