<template>
    <div>
        <v-card-title>Изход от бременността</v-card-title>
        <v-row class="align-end">
            <v-col cols="6" md="2">
                <date-picker v-model="value.birthDate" label="Раждане на дата:" />
            </v-col>
            <v-col cols="6" md="4">
                <v-radio-group v-model="value.birthTerm" label="Раждане" row dense>
                    <v-radio value="before" label="Преди термина" />
                    <v-radio value="after" label="След термина" />
                </v-radio-group>
            </v-col>
            <v-col cols="6" md="3">
                <text-field v-model="value.childWeight" type="number" label="Тегло на детето" />
            </v-col>
            <v-col cols="6" md="3">
                <text-field v-model="value.childHeight" type="number" label="Височина на детето (см)" />
            </v-col>
        </v-row>
        <v-row class="align-end">
            <v-col cols="6" md="2">
                <text-field v-model="value.birthSequence" type="number" label="Пореден номер на раждане" />
            </v-col>
            <v-col cols="6" md="4">
                <v-radio-group v-model="value.gender" label="Пол" row dense>
                    <v-radio value="male" label="М" />
                    <v-radio value="female" label="Ж" />
                </v-radio-group>
            </v-col>
            <v-col cols="6" md="4">
                <v-radio-group v-model="value.alive" row dense>
                    <v-radio value="alive" label="Живородено" />
                    <v-radio value="dead" label="Мъртвородено" />
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <h4 class="font-weight-bold">Кратко описние на раждането:</h4>
            </v-col>
            <v-col cols="6">
                <text-field v-model="value.birthDescription" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <h4 class="font-weight-bold">Патологични отклонения при раждането:</h4>
            </v-col>
            <v-col cols="6">
                <text-field v-model="value.pathologicDeviations" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <h4 class="font-weight-bold">Състояние на родилката:</h4>
            </v-col>
            <v-col cols="6">
                <text-field v-model="value.parturientCondition" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <h4 class="font-weight-bold">Състояние на детето:</h4>
            </v-col>
            <v-col cols="6">
                <text-field v-model="value.childCondition" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <h4 class="font-weight-bold">Други данни:</h4>
            </v-col>
            <v-col cols="6">
                <text-field v-model="value.otherData" />
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PregnancyResultDto } from '@/model/Register/Pregnancy/PregnancyCard/PregnancyResultDto';

    @Component
    export default class PregnancyResult extends Vue {
        @Prop()
        value!: PregnancyResultDto;
    }
</script>
