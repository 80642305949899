<template>
    <!--
        nudge-width указва минимална ширина на падащото меню, но то не става по-широко от browser-а.
        UserContext-ите показват много данни, така че таблицата е широка над 1000px така или иначе,
        но ако още не са заредени, таблицата се инициализира с малка ширина и остава така, или ако търсенето от Глобален админ
        не намери нищо, таблицата се свива наляво, а не надясно, затова е заложен и голям nudge-width.
    -->
    <v-menu v-model="menuIsVisible" :close-on-content-click="false" offset-y :nudge-width="1000">
        <!-- Комбиниране на v-menu с v-tooltip: https://vuetifyjs.com/en/components/menus/#activator-and-tooltip -->
        <template #activator="{ on: menuOn }">
            <v-tooltip bottom>
                <template #activator="{ on: tooltipOn }">
                    <v-btn icon v-on="{ ...menuOn, ...tooltipOn }">
                        <!-- По време на зареждане на UserContext-ите се показва loading вместо иконка. -->
                        <value-loading :value="userContextCache.isLoading" />
                        <template v-if="!userContextCache.isLoading">
                            <v-icon v-if="userContextCache.current">mdi-account-circle</v-icon>
                            <v-icon v-else color="error">mdi-alert</v-icon>
                        </template>
                    </v-btn>
                </template>
                <span v-if="userContextCache.current">Местоработи, Изход</span>
                <span v-else>Моля, изберете месторабота</span>
            </v-tooltip>
        </template>

        <v-list v-if="currentUser.user" class="py-0">
            <v-card>
                <v-card-title class="pb-0">
                    <user-info-card />
                </v-card-title>
                <user-context-picker @navigated="menuIsVisible = false" />
            </v-card>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import ValueLoading from '@/component/Common/ValueLoading.vue';
    import { currentUser } from '@/store/User/CurrentUser';
    import { userContextCache } from '@/store/User/UserContextCache';

    import UserContextPicker from './UserContextPicker.vue';
    import UserInfoCard from './UserInfoCard.vue';

    @Component({
        components: { UserContextPicker, UserInfoCard, ValueLoading }
    })
    export default class UserMenuItem extends Vue {
        private get currentUser() {
            return currentUser;
        }

        private get userContextCache() {
            return userContextCache;
        }

        private menuIsVisible: boolean = false;
    }
</script>
