import { plainToInstance } from 'class-transformer';

import { CustomerLicenseDto } from '@/model/Licensing/CustomerLicenseDto';
import { LicensePriceModel } from '@/model/Licensing/LicensePriceModel';
import { PracticeLicenseDto } from '@/model/Licensing/PracticeLicenseDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const practiceLicenseService = {
    async getCustomerLicenses(currentPracticeId: number): Promise<CustomerLicenseDto[]> {
        const response = await httpService.get<CustomerLicenseDto[]>('PracticeLicense/GetCustomerLicenses', {
            params: { currentPracticeId }
        });
        return plainToInstance(CustomerLicenseDto, response.data);
    },

    async calculateLicensePrice(months: number, employeeCount: number, isAdFree: boolean): Promise<LicensePriceModel> {
        const response = await httpService.get<LicensePriceModel>('PracticeLicense/CalculateLicensePrice', {
            params: { months, employeeCount, isAdFree }
        });
        return plainToInstance(LicensePriceModel, response.data);
    },

    async suggestNextPracticeLicense(practiceId: number): Promise<PracticeLicenseDto> {
        const response = await httpService.get<PracticeLicenseDto>('/PracticeLicense/SuggestNextPracticeLicense', {
            params: { practiceId }
        });
        return plainToInstance(PracticeLicenseDto, response.data);
    },

    async createPracticeLicenseAndAdjustPendingPayments(data: PracticeLicenseDto): Promise<void> {
        await httpService.post('PracticeLicense/CreatePracticeLicenseAndAdjustPendingPayments', data);
    },

    async adjustPendingPaymentsToLicense(practiceId: number, seqNumber: number): Promise<void> {
        await httpService.put('PracticeLicense/AdjustPendingPaymentsToLicense', null, {
            params: { practiceId, seqNumber }
        });
    },

    async deletePracticeLicenseAndAdjustPendingPayments(practiceId: number, seqNumber: number): Promise<void> {
        await httpService.delete('PracticeLicense/DeletePracticeLicenseAndAdjustPendingPayments', {
            params: { practiceId, seqNumber }
        });
    }
};
