import { AxiosResponse } from 'axios';

import { ConsultationTelkDto } from '@/model/Exam/ConsultationTelk/ConsultationTelkDto';
import { ConsultationTelkViewDto } from '@/model/Exam/ConsultationTelk/ConsultationTelkViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const consultationTelkService = {
    getConsultationTelk(patientId: number): Promise<AxiosResponse<ConsultationTelkDto>> {
        return httpService.get(`/ConsultationTelk/GetConsultationTelk/${patientId}`);
    },

    createConsultationTelk(data: ConsultationTelkDto): Promise<AxiosResponse<ConsultationTelkDto>> {
        return httpService.post('/ConsultationTelk/CreateConsultationTelk', data);
    },

    updateConsultationTelk(data: ConsultationTelkDto): Promise<AxiosResponse<ConsultationTelkDto>> {
        return httpService.put('/ConsultationTelk/UpdateConsultationTelk/', data);
    },

    deleteConsultationTelk(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/ConsultationTelk/DeleteConsultationTelk/${id}`);
    },

    getConsultationTelkInfoByExamId(examId: number): Promise<AxiosResponse<ConsultationTelkDto>> {
        return httpService.get(`/ConsultationTelk/GetConsultationTelkInfoByExamId/${examId}`);
    },

    getConsultationTelksByExamId(examId: number): Promise<AxiosResponse<ConsultationTelkViewDto[]>> {
        return httpService.get(`/ConsultationTelk/GetConsultationTelkViewsByExam`, {
            params: { examId }
        });
    }
};
