import { plainToInstance } from 'class-transformer';

import { FeeDisengagementIcdDto } from '@/model/Patient/FeeDisengagement/FeeDisengagementIcdDto';
import { IDropdownSearchService } from '@/service/Dropdown/IDropdownSearchService';
import { httpService } from '@/service/Infrastructure/HttpService';

export const feeDisengagementIcdService: IDropdownSearchService<FeeDisengagementIcdDto> = {
    // Не се използва, защото dropdown-ът е в режим return-object и получава началната стойност наготово.
    //async getItemsById(id: number): Promise<FeeDisengagementIcdDto[]> {
    //    const response = await httpService.get<FeeDisengagementIcdDto[]>(
    //        `/FeeDisengagement/GetFeeDisengagementIcdById/${id}`
    //    );
    //    return plainToInstance(FeeDisengagementIcdDto, response.data);
    //},
    getItemsById(): Promise<FeeDisengagementIcdDto[]> {
        return Promise.resolve([]);
    },

    async searchItems(filters: []): Promise<FeeDisengagementIcdDto[]> {
        const response = await httpService.get<FeeDisengagementIcdDto[]>(
            '/FeeDisengagement/SearchFeeDisengagementIcds',
            { params: { filter: filters.at(0) } }
        );
        return plainToInstance(FeeDisengagementIcdDto, response.data);
    }
};
