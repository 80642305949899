export class FindRiskCheckDto {
    public genderCode: string | null = null;
    public age: number = 0;
    public itm: number = 0;
    public girtWaist: number = 0;
    public sedentaryLife: boolean = false;
    public fruit: boolean = false;
    public antihypertensive: boolean = false;
    public prediabetic: boolean = false;
    public diabetesRelatives: boolean = false;
    public diabetesRelativesSecond: boolean = false;
}
