import { plainToInstance } from 'class-transformer';

import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { NhisXmlRequestDto } from '@/model/Nhis/NhisXmlRequestDto';
import { NhisReferralDto } from '@/model/Nhis/Referral/NhisReferralDto';
import { NhisReferralExtractionCommand } from '@/model/Nhis/Referral/NhisReferralExtractionCommand';
import { NhisReferralExtractionResultDto } from '@/model/Nhis/Referral/NhisReferralExtractionResultDto';
import { NhisReferralExtractionSubmitCommand } from '@/model/Nhis/Referral/NhisReferralExtractionSubmitCommand';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { nhisService } from '@/service/Nhis/NhisService';
import { signXmlService } from '@/service/Report/SignXmlService';
import { loadingState } from '@/store/LoadingState';
import { accessTokenCache, nhisKey } from '@/store/NhisNhifAccessTokenCache';

const getXmlReferral = async function getXmlReferral(requestData: NhisXmlRequestDto): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetReferral`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getXmlCancellationReferral = async function getXmlCancellationReferral(
    requestData: NhisXmlRequestDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetCancellationReferral`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getExecutionCheckReferral = async function getExecutionCheckReferral(
    requestData: NhisXmlRequestDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetExecutionCheckReferral`, requestData);
    console.log(response);
    return response?.data ?? '';
};

export const nhisReferralService = {
    async getXmlExtractionReferral(command: NhisReferralExtractionCommand): Promise<string> {
        const response = await httpService.post<string>(`/${nhisKey}/GetReferralExtraction`, command);
        return response.data;
    },

    async postReferral(requestData: NhisXmlRequestDto): Promise<NhisReferralDto | null> {
        loadingState.show();
        try {
            const xmlString = await getXmlReferral(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const response = await nhisService.postReferralToNhis<NhisReferralDto>(
                    'PostReferral',
                    signResponse.contents,
                    requestData.entityId,
                    requestData.formType ?? ''
                );
                console.log(response?.response);
                if (response?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${response.error}`);
                } else {
                    if (response?.warnings?.length > 0) {
                        nhisService.showWarnings(response.warnings);
                    }
                    return plainToInstance(NhisReferralDto, response);
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async cancelReferral(requestData: NhisXmlRequestDto): Promise<NhisReferralDto | null> {
        loadingState.show();
        try {
            const xmlString = await getXmlCancellationReferral(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const response = await nhisService.postReferralToNhis<NhisReferralDto>(
                    'PostCancellationReferral',
                    signResponse.contents,
                    requestData.entityId,
                    requestData.formType ?? ''
                );
                console.log(response?.response);
                if (response?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${response.error}`);
                } else {
                    if (response?.warnings?.length > 0) {
                        nhisService.showWarnings(response.warnings);
                    }
                    return plainToInstance(NhisReferralDto, response);
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async checkReferral(requestData: NhisXmlRequestDto): Promise<NhisReferralDto | null> {
        loadingState.show();
        try {
            const xmlString = await getExecutionCheckReferral(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const response = await nhisService.postReferralToNhis<NhisReferralDto>(
                    'PostExecutionCheckReferral',
                    signResponse.contents,
                    requestData.entityId,
                    requestData.formType ?? ''
                );
                console.log(response?.response);
                if (response?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${response.error}`);
                } else {
                    if (response?.executionStatusMessage) {
                        await notifierService.showSuccess('', `${response.executionStatusMessage}`);
                    }
                    if (response?.warnings?.length > 0) {
                        nhisService.showWarnings(response.warnings);
                    }
                    return plainToInstance(NhisReferralDto, response);
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postReferralExtraction(
        xmlString: string,
        specialtyCode: SpecialtyCode
    ): Promise<NhisReferralExtractionResultDto> {
        const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
        const tokenValue = tokenInfo?.accessToken ?? '';
        const response = await httpService.post<NhisReferralExtractionResultDto>(
            `${nhisKey}/PostReferralExtraction`,
            new NhisReferralExtractionSubmitCommand(xmlString, tokenValue, specialtyCode)
        );
        return plainToInstance(NhisReferralExtractionResultDto, response.data);
    }
};
