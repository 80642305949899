import { plainToInstance } from 'class-transformer';

import { UserSettingsDto } from '@/model/User/UserSettings/UserSettingsDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const userSettingsService = {
    async getCurrentUserSettings(): Promise<UserSettingsDto> {
        const response = await httpService.get<UserSettingsDto>('UserSettings/GetCurrentUserSettings');
        return plainToInstance(UserSettingsDto, response.data);
    },

    async updateCurrentUserSettings(dto: UserSettingsDto): Promise<void> {
        await httpService.put('UserSettings/UpdateCurrentUserSettings', dto);
    }
};
