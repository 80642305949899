<template>
    <div>
        <v-row>
            <v-col cols="12" md="3">
                <text-field
                    v-model="value.number"
                    counter="6"
                    required
                    label="Номер:"
                    :rules="[$validator.maxLength(6)]"
            /></v-col>
            <v-col cols="12" md="3">
                <date-picker v-model="value.releaseDate" label="Дата на издаване" required />
            </v-col>
            <v-col cols="12" md="3">
                <text-field v-model="value.certificateNumber" required label="Удостоверение - номер:"
            /></v-col>
            <v-col cols="12" md="3">
                <date-picker v-model="value.certificateDate" label="Удостоверение - дата:" required />
            </v-col>
            <v-col cols="12" md="3">
                <text-field v-model="value.workIncapabilityPercent" label="Трайна неработоспособност %:" />
            </v-col>
            <v-col cols="12" md="3">
                <text-field v-model="value.telkResultNumber" label="Решение ТЕЛК - номер:" />
            </v-col>
            <v-col cols="12" md="3">
                <date-picker v-model="value.telkResultDate" label="Решение ТЕЛК - дата:" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="3">
                <date-picker v-model="value.cancelationDate" label="Анулирана на:" />
            </v-col>
            <v-col cols="12" md="9">
                <text-field v-model="value.cancelationReason" label="Причина за анулиране:" />
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { InjuredPrescriptionBookDto } from '@/model/Patient/PrescriptionBook/InjuredPrescriptionBookDto';

    @Component
    export default class InjuredPrescriptionBook extends Vue {
        @Prop({ type: Object })
        private value!: InjuredPrescriptionBookDto;
    }
</script>
