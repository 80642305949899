export class QuickNoticeDto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();
    public number: number = 0;
    public diagnosis1: string = '';
    public sickStatusCode: string = 'Confirmed';
    public sicknessCityId: number = 1;
    public sicknessDate: Date = new Date();
    public profession: string = '';
    public workAddress: string = '';
    public lastVisitWorkDate: Date = new Date();
    public initialExamCityId: number = 1;
    public initialExamDate: Date = new Date();
    public initialExamDiagnosis: string = '';
    public initialExamFacility: string = '';
    public discoveredActively: boolean = false;
    public discoveredNotes: string = '';
    public specificDiagnosisDate: Date | null = null;
    public specificDiagnosis: string = '';
    public specificDiagnosisFacility: string = '';
    public resultExam: string = '';
    public resultParaclinic: string = '';
    public resultPathology: string = '';
    public isTreatedAtHome: boolean = false;
    public assignedTreatmentNotes: string = '';
    public assignedTreatmentDate: Date = new Date();
    public deceasedCityId: number = 1;
    public deceasedDate: Date = new Date();
    public deceasedFacility: string = '';
    public immunization1Date: Date = new Date();
    public immunization2Date: Date = new Date();
    public immunization3Date: Date = new Date();
    public reimmunization1Date: Date = new Date();
    public reimmunization2Date: Date = new Date();
    public contactPersonName: string = '';
    public contactDate: Date = new Date();
    public contactInfoHome: string = '';
    public contactInfoCollective: string = '';
    public noticeSendTo: string = '';
    //public noticeSenderInformation: string = '';
    //public noticeSenderAddress: string = '';
}
