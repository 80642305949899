import Vue from 'vue';

import { PidTypeDto } from '@/model/Nomenclature/PidTypeDto';
import { pidTypeService } from '@/service/Nomenclature/PidTypeService';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

const loadingItem = new PidTypeDto();
loadingItem.name = 'Зареждане...';
const loadErrorItem = new PidTypeDto();
loadErrorItem.name = 'Грешка при зареждане';

class PidTypeCache extends ArrayCacheBase<PidTypeDto> {
    constructor() {
        super(loadingItem, loadErrorItem);
    }

    protected async load() {
        const response = await pidTypeService.getPidTypes();
        this._items = response.data;
    }
}

export const pidTypeCache = Vue.observable(new PidTypeCache());
