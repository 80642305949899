<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Декларация за информирано съгласие с изискванията за започване/продължаване на лечението с лекарствения продукт"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <main-title>
                Декларация за информирано съгласие с изискванията за започване/продължаване на лечението с лекарствения
                продукт
            </main-title>
            <v-card-text>
                <PatientInfo />

                <v-row>
                    <v-col md="6">
                        <dropdown
                            v-model="document.diagnosis"
                            :items="diagnosis"
                            label="Заболяване"
                            required
                        ></dropdown>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field v-model="document.treatment" label="Лекарствен продукт" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h4>Декларирам, че:</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h4>1. Ще спазвам препоръчаната ми схема на лечение и периодичност на контролните прегледи.</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h4>
                            2. Редовно ще се явявам на контролни прегледи и няма да променям самоволно или под друго
                            внушение назначената ми терапия.
                        </h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h4>
                            3. При преустановяване на лечението по причини, непроизтичащи от решението на Комисията за
                            експертизи в РЗОК и/или Комисията по чл. 78, т.2 от ЗЗО, ще уведомя незабавно личния си
                            лекар и РЗОК и ще върна в РЗОК последния протокол, по който съм получавал/а лекарствения
                            продукт.
                        </h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h4>
                            4. При неспазване на посочените условия лечението ми с упоменатия лекарствен продукт ще бъде
                            прекратено и няма да имам претенции към НЗОК.
                        </h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <date-picker v-model="document.issueDate" label="Дата:" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { InformedConsentDiagnosisDto } from '@/model/Exam/InformedConsentTreatment/InformedConsentDiagnosisDto';
    import { InformedConsentTreatmentDto } from '@/model/Exam/InformedConsentTreatment/InformedConsentTreatmentDto';
    import { informedConsentTreatmentService } from '@/service/Exam/InformedConsentTreatmentService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: { UnsavedChanges, PatientInfo, ExamLayout },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class InformedConsentTreatment extends Vue {
        @Ref()
        private readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private document: InformedConsentTreatmentDto = new InformedConsentTreatmentDto();
        private initialValue: InformedConsentTreatmentDto = new InformedConsentTreatmentDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private diagnosis: InformedConsentDiagnosisDto[] = [];

        private async mounted() {
            this.leaveDialogRef = this.leaveDialog;
            await this.load();
            this.copyObject();
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private loadDiagnosis() {
            //Заболявания
            const diagnosis1: InformedConsentDiagnosisDto = new InformedConsentDiagnosisDto();
            diagnosis1.code = 'Шизофрения';
            diagnosis1.name = 'Шизофрения';
            this.diagnosis.push(diagnosis1);

            const diagnosis2: InformedConsentDiagnosisDto = new InformedConsentDiagnosisDto();
            diagnosis2.code = 'Болест на Паркинсон';
            diagnosis2.name = 'Болест на Паркинсон';
            this.diagnosis.push(diagnosis2);

            const diagnosis3: InformedConsentDiagnosisDto = new InformedConsentDiagnosisDto();
            diagnosis3.code = 'Афективни разстройства';
            diagnosis3.name = 'Афективни разстройства';
            this.diagnosis.push(diagnosis3);
        }

        private async load() {
            this.$loading.show();
            try {
                this.loadDiagnosis();

                if (!this.documentId) {
                    const responseExam = await informedConsentTreatmentService.getInformedConsentTreatmentInfoByExamId(
                        this.examId
                    );
                    this.document = responseExam.data;
                } else {
                    const responseReferral = await informedConsentTreatmentService.getInformedConsentTreatment(
                        this.documentId
                    );
                    this.document = responseReferral.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                const title = `Дата ${this.document.issueDate}; `;
                if (documentId === 0) {
                    const response = await informedConsentTreatmentService.createInformedConsentTreatment(
                        this.document
                    );
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, 'InformedConsentTreatment', title);

                    this.$router.push(`/Exam/InformedConsentTreatment/Edit/${documentId}`);
                } else {
                    await informedConsentTreatmentService.updateInformedConsentTreatment(this.document);
                    eventBus.$emit('update-referral-event', title);
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            const documentId = this.document.id;
            await informedConsentTreatmentService.deleteInformedConsentTreatment(documentId);
            this.copyObject();
            this.$router.push(`/Exam/InformedConsentTreatment/Create/${currentVisitCache.value.id}`);
            eventBus.$emit(
                'delete-referral-event',
                documentId,
                'InformedConsentTreatment',
                'Информирано съгласие за провеждане на лечение'
            );
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.InformedConsent.InformedConsentTreatment/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.InformedConsent.InformedConsentTreatment';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
