import { plainToInstance } from 'class-transformer';

import { SickSheetNoteTypeDto } from '@/model/Nomenclature/SickSheetNoteTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

// Експеримент с просто кеширане.
// Данните се зареждат и await-ват изрично и масивът не е observable. За разлика от този модул, класовете в папка
// /src/store/Nomenclature зареждат кеша скрито и асинхронно, след което разчитат на реакивност заредените данни да се покажат.

const sickSheetNoteTypeCache: { items: SickSheetNoteTypeDto[]; isLoaded: boolean } = {
    items: [],
    isLoaded: false
};

const sickSheetNoteTypeService = {
    async loadOnceSickSheetNoteTypes(): Promise<void> {
        if (!sickSheetNoteTypeCache.isLoaded) {
            const response = await httpService.get<SickSheetNoteTypeDto[]>('/SickSheetNoteType/GetSickSheetNoteTypes');
            if (!sickSheetNoteTypeCache.isLoaded) {
                sickSheetNoteTypeCache.items = response.data ?? [];
                sickSheetNoteTypeCache.isLoaded = true;
            }
        }
    },

    getNoteType(nssiCode: string): SickSheetNoteTypeDto | undefined {
        return sickSheetNoteTypeCache.items.find((type) => type.nssiCode === nssiCode);
    },

    async loadNoteTypes(): Promise<SickSheetNoteTypeDto[]> {
        const response = await httpService.get<SickSheetNoteTypeDto[]>('/SickSheetNoteType/GetSickSheetNoteTypes');
        return plainToInstance(SickSheetNoteTypeDto, response.data);
    }
};

export { sickSheetNoteTypeCache, sickSheetNoteTypeService };
