<template>
    <confirm-dialog ref="confirm" />
</template>

<script lang="ts">
    import { Component, Ref, Vue } from 'vue-property-decorator';
    import { NavigationGuardNext } from 'vue-router';

    import ConfirmDialog from '@/component/Exam/ConfirmDialog.vue';
    import { objectUtil } from '@/util/ObjectUtil';

    @Component({
        components: { ConfirmDialog }
    })
    export default class UnsavedChanges extends Vue {
        @Ref() readonly confirm!: ConfirmDialog;

        public async check(initialObject: unknown, currentObject: unknown, next: NavigationGuardNext<Vue>) {
            if (!objectUtil.deepEqual(currentObject, initialObject)) {
                try {
                    const answer = await this.confirm.open(
                        'Внимание',
                        'Има незаписани промени. Желаете ли да напуснете страницата?'
                    );

                    if (answer) {
                        next();
                    } else {
                        next(false);
                    }
                } catch {
                    next();
                }
            } else {
                next();
            }
        }
    }
</script>
