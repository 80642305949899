<template>
    <v-card>
        <v-card-title>Локален сървър</v-card-title>
        <v-card-actions>
            <btn action="Refresh" @click="checkLocalServer">Проверка</btn>
            <btn
                action="Download"
                to="https://download.kontrax.bg/hippocrates/download/Tools/LocalServer_HIPPOCRATES_1.0.11.exe"
            />
        </v-card-actions>
        <v-card-text>
            <v-card-title class="py-0">Версия</v-card-title>
            <v-row>
                <v-col cols="6" md="3">
                    <text-field v-model="version.major" readonly label="Major" />
                </v-col>
                <v-col cols="6" md="3">
                    <text-field v-model="version.minor" readonly label="Minor" />
                </v-col>
                <v-col cols="6" md="3">
                    <text-field v-model="version.build" readonly label="Build" />
                </v-col>
                <v-col cols="6" md="3">
                    <text-field v-model="version.revision" readonly label="Revision" />
                </v-col>
                <v-col cols="6" md="3">
                    <text-field v-model="version.majorRevision" readonly label="Major Revision" />
                </v-col>
                <v-col cols="6" md="3">
                    <text-field v-model="version.minorRevision" readonly label="Minor Revision" />
                </v-col>
            </v-row>
            <v-card-title class="py-0">Настройки</v-card-title>
            <v-row>
                <v-col cols="6" md="3">
                    <text-field v-model="settings.autoStart" readonly label="AutoStart" />
                </v-col>
                <v-col cols="6" md="3">
                    <text-field v-model="settings.upgradeRequired" readonly label="UpgradeRequired" />
                </v-col>
                <v-col cols="6" md="3">
                    <text-field v-model="settings.demoMode" readonly label="DemoMode" />
                </v-col>
                <v-col cols="6" md="3">
                    <text-field v-model="settings.showConfirmationDialog" readonly label="ShowConfirmationDialog" />
                </v-col>
            </v-row>
            <v-card-title class="py-0">Възможности</v-card-title>
            <h2></h2>
            <v-row>
                <v-col cols="12">
                    <text-field v-model="capabilities" readonly label="Capabilities" />
                </v-col>
            </v-row>
            <v-card-title class="py-0">Издание</v-card-title>
            <v-row>
                <v-col cols="12">
                    <text-field v-model="edition" readonly label="Edition" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { LocalServerConfigDto } from '@/model/LocalServer/LocalServerConfigDto';
    import { LocalServerVersionDto } from '@/model/LocalServer/LocalServerVersionDto';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';

    @Component
    export default class LocalServer extends Vue {
        private version: LocalServerVersionDto = new LocalServerVersionDto();
        private settings: LocalServerConfigDto = new LocalServerConfigDto();
        private edition: string | null = null;
        private capabilities: string[] | null = null;

        private async checkLocalServer() {
            this.$loading.show();
            try {
                const isAlive = await localServerDiagnosticService.checkIsLocalServerActive();
                if (isAlive) {
                    this.version = await localServerDiagnosticService.getVersion();
                    this.settings = await localServerDiagnosticService.checkSettings();
                    this.edition = await localServerDiagnosticService.checkEdition();
                    this.capabilities = await localServerDiagnosticService.checkCapability();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.checkLocalServer();
        }
    }
</script>
