<template>
    <v-card v-if="doctor">
        <v-card-title>Лекар</v-card-title>
        <v-card-actions>
            <btn action="Save" :disabled="$loading.isVisible" @click="createUpdateDoctor" />
        </v-card-actions>
        <v-card-text>
            <v-form ref="documentForm" v-model="isValid">
                <v-row>
                    <v-col>
                        <text-field v-model="doctor.uin" required label="УИН" />
                    </v-col>
                    <v-col>
                        <text-field v-model="doctor.name" required label="Име" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <PidTypePicker v-model="doctor.pidTypeCode" />
                    </v-col>
                    <v-col>
                        <text-field v-model="doctor.identifier" label="Идентификатор" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <text-field v-model="doctor.phone" label="Телефон" />
                    </v-col>
                    <v-col>
                        <text-field
                            v-model="doctor.email"
                            label="E-mail за контакти (не за вход в Хипократ)"
                            :rules="[$validator.email]"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6"><RichText v-model="doctor.biographyHtml" /></v-col>
                    <v-col cols="5">
                        <v-file-input
                            ref="photoField"
                            label="Профилна снимка:"
                            dense
                            @change="onFileInput($event)"
                            @click:clear="onFileInput($event)"
                        />
                        <img
                            v-if="photoInputFieldSrc && !doctor.profilePictureIsDeleted"
                            max-width="320"
                            max-height="426"
                            :src="photoInputFieldSrc"
                        />
                    </v-col>
                    <btn
                        v-if="doctor.photoSmall && !doctor.profilePictureIsDeleted"
                        action="Delete"
                        icon
                        @click="
                            (doctor.profilePictureIsDeleted = true),
                                (photoInputFieldSrc = null),
                                $refs.photoField.reset()
                        "
                    />
                </v-row>
            </v-form>
        </v-card-text>
        <bg-doctor :uin="doctor.uin"></bg-doctor>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import PidTypePicker from '@/component/Picker/PidTypePicker.vue';
    import RichText from '@/component/RichText/RichText.vue';
    import { DoctorEditDto } from '@/model/Doctor/DoctorEditDto';
    import { doctorService } from '@/service/Doctor/DoctorService';
    import { currentUser } from '@/store/User/CurrentUser';
    import { userContextCache } from '@/store/User/UserContextCache';
    import BgDoctor from '@/view/Practice/BgDoctor.vue';

    @Component({
        components: { PidTypePicker, RichText, BgDoctor }
    })
    export default class DoctorEdit extends Vue {
        @Prop({ required: true })
        private doctorId!: number;

        private isValid: boolean = false;
        private doctor: DoctorEditDto | null = null;
        private photoInputFieldSrc: string | null = null;

        private async mounted() {
            if (this.doctorId) {
                this.$loading.show();
                try {
                    this.doctor = await doctorService.getDoctor(this.doctorId);
                    if (this.doctor.photoSmall !== '') {
                        this.photoInputFieldSrc = `data:image/jpeg;base64,${this.doctor.photoSmall}`;
                    }
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private onFileInput(file: File) {
            if (file?.size) {
                this.getFileContent(file).then((resolve) => {
                    if (this.doctor) {
                        this.doctor.pictureContent = [...resolve];
                        this.doctor.profilePictureIsDeleted = false;
                    }
                });
                this.photoInputFieldSrc = window.URL.createObjectURL(file);
            } else {
                this.photoInputFieldSrc = null;
            }
        }

        private getFileContent(fileContent: File) {
            return new Promise<number[]>((resolve) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(fileContent);
                fileReader.onload = () => {
                    const array = new Uint8Array(fileReader.result as ArrayBuffer);
                    const byteArray: number[] = [];

                    for (let index = 0; index < array.length; index++) {
                        byteArray.push(array[index]);
                    }
                    resolve(byteArray);
                };
            });
        }

        private async createUpdateDoctor() {
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

            if (this.doctor && this.isValid) {
                this.$loading.show();
                try {
                    this.doctor.id = this.doctorId;

                    this.doctor = await doctorService.updateDoctor(this.doctor);
                    if (this.doctor.photoSmall !== '') {
                        this.photoInputFieldSrc = `data:image/jpeg;base64,${this.doctor.photoSmall}`;
                    }

                    await this.updateUserContexts();
                    if (this.doctor.errorMessage) {
                        this.$notifier.showWarning(this.doctor.errorMessage as string, '');
                        this.doctor.pictureContent = null;
                    } else {
                        this.$notifier.showSuccess('Данните са променени успешно.', '');
                    }
                } finally {
                    this.$loading.hide();
                }
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        // Презарежда UserContext-ите от сървъра, което ги обновяват в local storage.
        // Преди това обновява текущия UserContext локално, в случай че е бил избран след търсене от глобален админ.
        private async updateUserContexts() {
            if (this.doctor && currentUser.isGlobalAdmin) {
                const { current } = userContextCache;
                if (current?.doctorId === this.doctorId) {
                    current.doctorUin = this.doctor.uin;
                    current.doctorName = this.doctor.name;
                    current.doctorIdentifier = this.doctor.identifier;
                    // Текущият UserContext се обновява в local storage.
                    userContextCache.current = current;
                }
            }
            await userContextCache.loadFromServer();
        }
    }
</script>
