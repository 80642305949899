import { AxiosResponse } from 'axios';

import { ExamAnswerSourceTypeDto } from '@/model/Nomenclature/ExamAnswerSourceTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const examAnswerSourceTypeService = {
    getExamAnswerSourceTypes(): Promise<AxiosResponse<ExamAnswerSourceTypeDto[]>> {
        return httpService.get('/ExamAnswerSourceType/GetExamAnswerSourceTypes');
    }
};
