import { Type } from 'class-transformer';

import { Report365BedRowDto } from '@/model/Report/Rhi/Report365/Report365BedRowDto';

export class Report365BedDto {
    public name: string = '';

    @Type(() => Report365BedRowDto)
    public beds: Report365BedRowDto[] = [];
}
