import { AxiosResponse } from 'axios';

import { NhisSocialGroupDto } from '@/model/Nomenclature/NhisSocialGroupDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhisSocialGroupService = {
    getSocialGroups(): Promise<AxiosResponse<NhisSocialGroupDto[]>> {
        return httpService.get('nhisSocialGroup/GetNhisSocialGroups');
    }
};
