import { plainToInstance } from 'class-transformer';

import { ExamCreatedDto } from '@/model/Exam/ExamCreatedDto';
import { NhisExtractedReferralDto } from '@/model/Nhis/Referral/NhisExtractedReferralDto';
import { examService } from '@/service/Exam/ExamService';
import { httpService } from '@/service/Infrastructure/HttpService';

export const incomingReferralExamService = {
    async createVisitOrUpdateReferral(data: NhisExtractedReferralDto): Promise<number> {
        const response = await httpService.post<ExamCreatedDto>(
            '/IncomingReferralExam/CreateVisitFromIncomingReferral',
            data
        );
        const result = plainToInstance(ExamCreatedDto, response.data);
        examService.showExamCreatedWarnings(result);
        return result.examId;
    },

    async checkForExistingIncomingReferralExam(practiceId: number, nrnReferral: string): Promise<string> {
        const response = await httpService.get<string>('/IncomingReferralExam/CheckForExistingIncomingReferralExam', {
            params: {
                practiceId,
                nrnReferral
            }
        });
        return response.data;
    }
};
