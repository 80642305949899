import { AxiosResponse } from 'axios';

import { calendarConfig } from '@/model/Calendar/CalendarConfig';
import { ExamScheduleCalendarEventModel } from '@/model/ExamSchedule/ExamScheduleCalendarEventModel';
import { ExamScheduleDto } from '@/model/ExamSchedule/ExamScheduleDto';
import { ScheduledExamCalendarDto } from '@/model/ExamSchedule/ScheduledExamCalendarDto';
import { ScheduledExamFilterCommand } from '@/model/ExamSchedule/ScheduledExamFilterCommand';
import { EventDto } from '@/model/WorkTime/EventDto';
import { httpService } from '@/service/Infrastructure/HttpService';

const defaultExamDuration = 30;
const twelve = 12;
const two = 2;
const minutesInHour = 60;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const ap = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

export const examScheduleService = {
    createExamScheduleEvent(rawEvent: EventDto, schedulesExams: ScheduledExamCalendarDto[], date: Date | null) {
        const event = new ExamScheduleCalendarEventModel();
        event.id = rawEvent.id;
        event.name = `${rawEvent.name} - ${rawEvent.doctorName ?? ''}`;
        event.start = date || rawEvent.date;
        event.start?.setHours(rawEvent.startHour ?? 0);
        event.start?.setMinutes(rawEvent.startMinute ?? 0);
        event.end = date ? new Date(date) : new Date(rawEvent.date ?? new Date());
        event.end?.setHours(rawEvent.endHour ?? 0);
        event.end?.setMinutes(rawEvent.endMinute ?? 0);
        event.color = rawEvent.color;
        event.doctorId = rawEvent.doctorId;
        event.doctorName = rawEvent.doctorName;
        event.specialtyCode = rawEvent.specialtyCode;
        event.specialtyName = rawEvent.specialtyName;
        event.workTimeVisitReasonId = rawEvent.workTimeVisitReasonId;
        event.workTimeVisitReasonName = rawEvent.workTimeVisitReasonName;
        event.examDurationInMinutes = rawEvent.examDurationInMinutes ?? defaultExamDuration;

        for (const scheduledExam of schedulesExams) {
            event.scheduledExams.push(scheduledExam);
        }
        return event;
    },

    getScheduledExamsForDates(filter: ScheduledExamFilterCommand): Promise<AxiosResponse<ScheduledExamCalendarDto[]>> {
        return httpService.get(`/ExamSchedule/GetScheduledExamsForDates`, { params: filter });
    },

    createExamSchedule(data: ExamScheduleDto): Promise<AxiosResponse<ExamScheduleDto>> {
        return httpService.post('/ExamSchedule/CreateExamSchedule', data);
    },

    updateExamSchedule(data: ExamScheduleDto): Promise<AxiosResponse<ExamScheduleDto>> {
        return httpService.put('/ExamSchedule/UpdateExamSchedule', data);
    },

    deleteExamSchedule(id: number): Promise<AxiosResponse> {
        return httpService.delete('/ExamSchedule/DeleteExamSchedule', { params: { id } });
    },

    getExamSchedule(id: number): Promise<AxiosResponse<ScheduledExamCalendarDto>> {
        return httpService.get('/ExamSchedule/GetExamSchedule', { params: { id } });
    },

    createWorkTimeEventItem(
        element: ExamScheduleCalendarEventModel,
        iterationDiapazon: number,
        hh: number,
        mm: number
    ) {
        const newItem = new ExamScheduleCalendarEventModel();

        newItem.start = new Date(element.start as Date);
        newItem.start?.setHours(
            ap[Math.floor(hh - twelve)] > 0
                ? Number(`${ap[Math.floor(hh - twelve)]}`)
                : Number(`${`0${hh % twelve}`.slice(-two)}`)
        );
        newItem.start?.setMinutes(
            ap[Math.floor(hh - twelve)] > 0 ? Number(`${`0${mm}`.slice(-two)}`) : Number(`${`0${mm}`.slice(-two)}`)
        );
        newItem.id = element.id;
        newItem.end = new Date(element.start as Date);
        newItem.end?.setHours(hh);
        //ТоДо да се вземе края на работния график
        newItem.end?.setMinutes(mm + iterationDiapazon);
        newItem.color = element.color;
        newItem.category = element.category;
        newItem.doctorName = element.doctorName;
        newItem.specialtyCode = element.specialtyCode;
        newItem.specialtyName = element.specialtyName;
        newItem.workTimeVisitReasonId = element.workTimeVisitReasonId;
        newItem.workTimeVisitReasonName = element.workTimeVisitReasonName;
        newItem.examDurationInMinutes = element.examDurationInMinutes ?? defaultExamDuration;
        newItem.name =
            ap[Math.floor(hh - twelve)] > 0
                ? `${ap[Math.floor(hh - twelve)]}:${`0${mm}`.slice(-two)}`
                : `${`0${hh % twelve}`.slice(-two)}:${`0${mm}`.slice(-two)}`;

        return newItem;
    },

    createWorkTimeEvents(element: ExamScheduleCalendarEventModel) {
        const iterationDiapazon = element.examDurationInMinutes
            ? element.examDurationInMinutes
            : calendarConfig.intervalHeight;

        const newEvents: ExamScheduleCalendarEventModel[] = [];
        let startPoint = (element.start as Date).getHours() * minutesInHour + (element.start as Date).getMinutes();
        const endPoint = (element.end as Date).getHours() * minutesInHour + (element.end as Date).getMinutes();

        for (let iterator = 0; startPoint < endPoint; iterator++) {
            const hours = Math.floor(startPoint / minutesInHour);
            const minutes = startPoint % minutesInHour;
            if (hours * minutesInHour + minutes + iterationDiapazon < endPoint) {
                newEvents.push(this.createWorkTimeEventItem(element, iterationDiapazon, hours, minutes));
            }
            startPoint = startPoint + iterationDiapazon;
        }
        return newEvents;
    },

    createWorkTimeEventItemByExamScheduleDto(element: ScheduledExamCalendarDto) {
        const newItem = new ExamScheduleCalendarEventModel();
        newItem.start = new Date(element.examDateTime as Date);
        newItem.start?.setHours(element.examDateTime?.getHours() ?? 0);
        newItem.start?.setMinutes(element.examDateTime?.getMinutes() ?? 0);
        newItem.end = new Date(element.examDateTime as Date);
        newItem.end?.setHours(element.examDateTime?.getHours() ?? 0);
        newItem.end?.setMinutes(
            (element.examDateTime?.getMinutes() ?? 0) + (element.examDurationInMinutes ?? defaultExamDuration)
        );
        newItem.color = element.color;
        newItem.doctorName = element.doctorName;
        newItem.specialtyCode = element.specialtyCode;
        newItem.specialtyName = element.specialtyName;
        newItem.workTimeVisitReasonId = element.workTimeVisitReasonId;
        newItem.workTimeVisitReasonName = element.workTimeVisitReasonName;
        newItem.examDurationInMinutes = element.examDurationInMinutes ?? defaultExamDuration;
        newItem.name =
            ap[Math.floor((element.examDateTime?.getHours() ?? 0) - twelve)] > 0
                ? `${
                      ap[Math.floor((element.examDateTime?.getHours() ?? 0) - twelve)]
                  }:${`0${element.examDateTime?.getMinutes()}`.slice(-two)}`
                : `${`0${(element.examDateTime?.getHours() ?? 0) % twelve}`.slice(
                      -two
                  )}:${`0${element.examDateTime?.getMinutes()}`.slice(-two)}`;

        newItem.scheduledExams.push(element);

        return newItem;
    },

    getTimeInMinutes(val: Date) {
        return val.getHours() * minutesInHour + val.getMinutes();
    }
};
