import { Transform, Type } from 'class-transformer';

import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';
import { PrescriptionBaseDto } from '@/model/Exam/Prescription/PrescriptionBaseDto';
import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';

export class PrescriptionNhifWhiteDto extends PrescriptionBaseDto {
    public isDeleted: boolean = false;
    public formTypeCode: string = 'White';
    public repeatNumber: number = 1;

    @Type(() => ExamDiagnosisDto)
    public examDiagnosis: ExamDiagnosisDto | null = null;

    public supplements: string | null = null;

    @Transform(({ value }) => value ?? new NhifDrugDto())
    @Type(() => NhifDrugDto)
    public nhisDrugs: NhifDrugDto[] = [];
}
