<template>
    <data-table
        :headers="headers"
        :items="items"
        :loading="$loading.isVisible"
        title="Профилактични прегледи без вкл. в регистър"
    >
        <template #actions>
            <report-date-picker v-model="filter" :disabled="$loading.isVisible" @input="getTableItems" />
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/Exam/AmbulatorySheet/Edit/${item.id}`" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PreventiveExamNotRegisterIncludedDto } from '@/model/Query/Exam/PreventiveExamNotRegisterIncludedDto';
    import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
    import { preventiveExamNotRegisterIncludedService } from '@/service/Query/Exam/PreventiveExamNotRegisterIncludedService';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { ReportDatePicker }
    })
    export default class PreventiveExamNotRegisterIncluded extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private items: PreventiveExamNotRegisterIncludedDto[] = [];
        private filter: ReportIntervalFilterDto = new ReportIntervalFilterDto();

        private headers: IDataTableHeader[] = [
            { text: 'АЛ номер', value: 'number' },
            { text: 'АЛ НРН', value: 'nrn' },
            { text: 'МКБ', value: 'icdCode' },
            { text: 'Дата', value: 'startDateTime', formatter: formatters.date },
            { text: 'Идентификатор', value: 'patientIdentifier' },
            { text: 'Пациент', value: 'patientName' },
            { text: 'Извършил прегледа', value: 'doctorName' }
        ];

        private async getTableItems() {
            this.$loading.show();
            try {
                this.items = [];
                this.fillDoctorEmployee();
                const response = await preventiveExamNotRegisterIncludedService.getExamNotRegisterIncluded(this.filter);
                this.items = response.data;
            } finally {
                this.$loading.hide();
            }
        }

        private fillDoctorEmployee() {
            this.filter.practiceId = this.practiceId;
            this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async mounted() {
            if (!this.filter?.startDate || !this.filter?.endDate) {
                this.filter.fillCurrentMonth();
            }
            await this.getTableItems();
        }
    }
</script>
