<template>
    <exam-layout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Физиопроцедурна карта"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <main-title>Физиопроцедурна карта</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6"><PatientInfo /></v-col>
                    <v-col cols="12" md="6"><DoctorInfo /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3">
                        <text-field v-model="document.number" label="Изх. номер" />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="document.symptoms" label="Обективни данни" dense disabled />
                    </v-col>
                    <v-col cols="12">
                        <text-field v-model="document.diagnosis" label="Диагноза" disabled />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="document.treatment" label="Терапия" dense />
                    </v-col>
                </v-row>
                <v-row v-for="(procedure, index) in document.procedures" :key="index">
                    <v-col cols="8" md="4">
                        <text-field v-model="procedure.name" label="Процедура" disabled />
                    </v-col>
                    <v-col cols="4" md="2">
                        <text-field v-model="procedure.count" label="Брой" disabled />
                    </v-col>
                    <v-col cols="4" md="2">
                        <text-field v-model="procedure.time" label="Времетраене" />
                    </v-col>
                    <v-col cols="8" md="4">
                        <text-field v-model="procedure.dosage" label="Дозировка" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </exam-layout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { PhysiotherapyCardDto } from '@/model/Exam/PhysiotherapyCard/PhysiotherapyCardDto';
    import { physiotherapyCardService } from '@/service/Exam/PhysiotherapyCardService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: { UnsavedChanges, ExamLayout, DoctorInfo, PatientInfo },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class PhysiotherapyCard extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        documentId!: number;

        @Prop()
        examId!: number;

        private document: PhysiotherapyCardDto = new PhysiotherapyCardDto();
        private initialValue: PhysiotherapyCardDto = new PhysiotherapyCardDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                await physiotherapyCardService.deletePhysiotherapyCard(this.documentId);
                this.copyObject();
                this.$router.push(`/Exam/PhysiotherapyCard/Create/${this.document.examId}`);
                eventBus.$emit('delete-referral-event', this.documentId, 'PhysiotherapyCard', 'Физиопроцедурна карта');
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (!this.documentId) {
                    this.document.examId = this.examId;
                    const response = await physiotherapyCardService.createPhysiotherapyCard(this.document);
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, 'PhysiotherapyCard', 'Физиопроцедурна карта');
                    this.$router.push(`/Exam/PhysiotherapyCard/Edit/${documentId}`);
                } else {
                    await physiotherapyCardService.updatePhysiotherapyCard(this.document);
                    eventBus.$emit('update-referral-event', documentId, 'Физиопроцедурна карта');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.PhysiotherapyCard.PhysiotherapyCard/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.PhysiotherapyCard.PhysiotherapyCard';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async load() {
            this.$loading.show();
            try {
                if (this.documentId) {
                    this.document = await physiotherapyCardService.getPhysiotherapyCard(this.documentId);
                } else {
                    this.document = await physiotherapyCardService.getInfoByExamId(this.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async mounted() {
            this.leaveDialogRef = this.leaveDialog;
            await this.load();
            this.copyObject();
        }
    }
</script>
