export class PatientSearchCommand {
    public practiceId: number | null = null;
    public doctorEmployeeSeqNumber: number | null = null;
    public doctorSpecialtyCode: string | null = null;
    public patientId: number | null = null;

    public identifier: string = '';
    public firstName: string = '';
    public middleName: string = '';
    public lastName: string = '';
    public birthDateFrom: string | null = null;
    public birthDateTo: string | null = null;
    public phone: string = '';
    public address: string = '';
    public email: string = '';
    public patientStatus: string = '';
}
