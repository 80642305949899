<template>
    <div v-if="isGlobalAdminOrPracticeOwner">
        <v-card>
            <v-card-title>Детайли на фактурата</v-card-title>

            <v-card-actions v-if="isEdit">
                <btn action="Back" @click="backRoute" />
                <btn action="Cancel" @click="previewMode" />
                <btn action="Save" @click="update" />
            </v-card-actions>
            <v-card-actions v-else>
                <!-- Бутоните са много и, за да се wrap-ват на тесен дисплей, се ползва v-row. -->
                <v-row dense>
                    <v-col cols="auto">
                        <btn action="Back" @click="backRoute" />
                    </v-col>
                    <v-col cols="auto">
                        <btn action="Edit" @click="editMode" />
                    </v-col>
                    <v-col cols="auto">
                        <btn action="Send" @click="pisUpload" />
                    </v-col>
                    <v-col cols="auto">
                        <v-menu open-on-hover bottom offset-y pointer>
                            <template #activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <btn action="Print" block />
                                </div>
                            </template>

                            <v-list>
                                <v-list-item>
                                    <v-list-item-title class="pointer" @click="printWithoutPreview('Original')">
                                        Печат, оригинал
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title class="pointer" @click="printWithoutPreview('Copy')">
                                        Печат, копие
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title class="pointer" @click="print('Original')">
                                        Печат, оригинал - преглед
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title class="pointer" @click="print('Copy')">
                                        Печат, копие - преглед
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <btn action="Delete" @click="deleteInvoice" />
                    </v-col>
                </v-row>
            </v-card-actions>

            <v-card-text>
                <v-row class="flex-row align-end">
                    <v-col cols="3">
                        <text-field
                            v-model="value.number"
                            :disabled="!isEdit"
                            label="Номер на фактура"
                            :rules="[$validator.maxLength(10), $validator.digitsOnly]"
                        />
                    </v-col>
                    <v-col cols="3">
                        <date-picker v-model="value.invoiceDate" :disabled="!isEdit" label="Дата на издаване" />
                    </v-col>
                    <v-col cols="3">
                        <text-field v-model="value.invoiceTypeName" disabled label="Тип документ:" />
                    </v-col>
                    <v-col cols="3">
                        <date-field v-model="value.taxDate" label="Дата на данъчно събитие" disabled />
                    </v-col>
                    <v-col v-if="value.relatedInvoiceNumber" cols="3">
                        <text-field v-model="value.relatedInvoiceNumber" disabled label="Към фактура:" />
                    </v-col>
                    <v-col v-if="value.relatedInvoiceDate" cols="3">
                        <date-field v-model="value.relatedInvoiceDate" label="От дата" disabled />
                    </v-col>
                    <v-col cols="3">
                        <date-field v-model="value.startDate" disabled label="Период от" />
                    </v-col>
                    <v-col cols="3">
                        <date-field v-model="value.endDate" disabled label="Период до" />
                    </v-col>
                </v-row>
                <v-col cols="12" md="6">
                    <practice-nhif-contract-picker v-model="nhifContract" :practice-id="practiceId" />
                </v-col>
                <v-data-table
                    v-if="value && value.invoiceLines.length > 0"
                    :headers="tableHeaders"
                    :loading="$loading.isVisible"
                    :items="value.invoiceLines"
                    class="elevation-1 my-5"
                    no-data-text="Няма записи"
                    :sort-by="'id'"
                    item-key="nhifActivityCode"
                    disable-pagination
                    hide-default-footer
                >
                    <template slot="body.append">
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Общо</th>
                            <th>{{ total }}</th>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <pis-file v-model="items" />
        <SuccessDialog v-model="dialogIsVisible" :text="dialogText" title="Резултат от изпращане" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Route } from 'vue-router';

    import DateField from '@/component/Date/DateField.vue';
    import { PisUploadType } from '@/enum/LocalServer/PisUploadType';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PisInvoiceDto } from '@/model/Invoice/Pis/PisInvoiceDto';
    import { PracticeNhifContractDto } from '@/model/Practice/PracticeNhifContractDto';
    import { PisFileCreateDto } from '@/model/Report/Pis/PisFile/PisFileCreateDto';
    import { PisFileFilterDto } from '@/model/Report/Pis/PisFile/PisFileFilterDto';
    import { PisFileRowDto } from '@/model/Report/Pis/PisFile/PisFileRowDto';
    import { PisFileUploadRequestDto } from '@/model/Report/Pis/PisFileUploadRequestDto';
    import { invoiceService } from '@/service/Invoice/InvoiceService';
    import { pisInvoiceService } from '@/service/Invoice/PisInvoiceService';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { practiceService } from '@/service/Practice/PracticeService';
    import { printService } from '@/service/PrintService';
    import { localServerPisService } from '@/service/Report/LocalServerPisService';
    import { pisFileService } from '@/service/Report/PisFileService';
    import { currentInvoiceCache } from '@/store/CurrentInvoiceCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import PisFile from '@/view/Report/Pis/PisFile.vue';
    import PracticeNhifContractPicker from '@/view/Report/Pis/PracticeNhifContractPicker.vue';
    import SuccessDialog from '@/view/Report/Pis/SuccessDialog.vue';

    @Component({
        components: { PracticeNhifContractPicker, DateField, PisFile, SuccessDialog },
        beforeRouteLeave(to: Route, from: Route, next) {
            if (to !== from) {
                currentInvoiceCache.clear();
            }
            next();
        }
    })
    export default class PisInvoiceLines extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private value: PisInvoiceDto = new PisInvoiceDto();
        private currentInvoiceNumber: string = '';
        private currentInvoiceDate: Date | null = null;
        private isEdit: boolean = false;
        private dialogIsVisible: boolean = false;
        private dialogText: string = '';
        private items: PisFileRowDto[] = [];
        private managerIdentifier: string | null = null;
        private nhifContract: PracticeNhifContractDto = new PracticeNhifContractDto();

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Код на дейност', value: 'itemCode' },
                { text: 'Наименование', value: 'description' },
                { text: 'Количество', value: 'quantity' },
                { text: 'М.е.', value: 'invoiceUnitName' },
                { text: 'Ед. цена', value: 'unitPrice' },
                { text: 'ДДС', value: 'vatRate' },
                { text: 'Крайна цена', value: 'totalPrice' }
            ];
        }

        private get isGlobalAdminOrPracticeOwner() {
            return userContextCache.currentIsGlobalAdminOrPracticeOwner;
        }

        private get currentInvoice() {
            return currentInvoiceCache.value;
        }

        private loadCurrentInvoiceFromLocalStorage() {
            currentInvoiceCache.loadFromLocalStorage();
            this.fixInvoiceNumberLength();
        }

        private fixInvoiceNumberLength() {
            const invoiceNumberLength = 10;
            this.value.number = String(this.value.number).padStart(invoiceNumberLength, '0');
        }

        private editMode() {
            this.currentInvoiceNumber = this.value.number;
            this.currentInvoiceDate = this.value.invoiceDate;
            this.isEdit = true;
        }

        private backRoute() {
            history.back();
        }

        private async deleteInvoice() {
            this.$loading.show();
            try {
                await invoiceService.delete(this.value.invoiceId);
                this.backRoute();
            } finally {
                this.$loading.hide();
            }
        }

        private previewMode() {
            this.value.number = this.currentInvoiceNumber;
            this.value.invoiceDate = this.currentInvoiceDate;
            this.isEdit = false;
        }

        private async update() {
            this.$loading.show();
            this.isEdit = false;
            try {
                await invoiceService.updateInvoiceNumberAndDate(
                    this.value.invoiceId,
                    this.value.number,
                    this.value.invoiceDate
                );
                currentInvoiceCache.set(this.value);
            } finally {
                this.$loading.hide();
            }
        }

        private print(invoiceType: string) {
            if (this.value.invoiceId) {
                this.$router.push(`/Print/Invoice.Invoice/Invoice/${invoiceType}/${this.value.invoiceId}`);
            }
        }

        private async printWithoutPreview(invoiceType: string) {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.value.invoiceId.toString();
                parameters.invoiceType = invoiceType;
                const report = 'Invoice.Invoice';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async pisUpload() {
            this.$loading.show();
            try {
                const xmlInvoice = await this.generatePisInvoice();
                if (this.managerIdentifier && userContextCache.current && xmlInvoice) {
                    console.log(xmlInvoice);
                    const isActive = await localServerDiagnosticService.checkIsLocalServerActive();
                    if (isActive) {
                        const uploadRequest = new PisFileUploadRequestDto(
                            xmlInvoice,
                            userContextCache.current.practiceNumber,
                            this.managerIdentifier.toString()
                        );
                        const response = await localServerPisService.uploadReport(
                            this.value.pisFileTypeCode,
                            uploadRequest,
                            PisUploadType.Invoice
                        );
                        if (!response?.isError) {
                            this.dialogText = `${response.message} Идентификаторът на документа е: ${response.additionalInformation}`;
                            this.dialogIsVisible = true;
                            await this.savePisFile(response.additionalInformation, xmlInvoice);
                        }
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async getManagerIdentifier() {
            this.managerIdentifier = await practiceService.getPracticeManagerIdentifier(this.practiceId);
        }

        private async savePisFile(fileId: string, xmlInvoice: string) {
            const file = new PisFileCreateDto();
            file.practiceId = this.practiceId;
            file.pisFileTypeCode = this.value.pisFileTypeCode;
            file.pisFileId = fileId;
            file.startDate = this.value.startDate;
            file.endDate = this.value.endDate;
            file.invoiceId = this.value.invoiceId ?? null;
            file.xmlContent = xmlInvoice;

            const item = await pisFileService.createPisFile(file);
            this.items.push(item);
        }

        private async generatePisInvoice(): Promise<string | null> {
            if (this.nhifContract.id === 0) {
                this.$notifier.showWarning('Внимание', 'Изберете договор, по който да се отчете дейността');
            } else {
                this.value.practiceNhifContractId = this.nhifContract.id;
                const result = await pisInvoiceService.generatePisInvoice(this.value);
                return result ?? '';
            }
            return null;
        }

        private get total() {
            if (this.value?.invoiceLines?.length > 0) {
                const percent = 100;
                const sum = this.value.invoiceLines.reduce((ax, bx) => ax + bx.totalPrice, 0);
                return Math.round((sum + Number.EPSILON) * percent) / percent;
            }
            return 0;
        }

        private async getPisFileItems() {
            const filter = new PisFileFilterDto();
            filter.practiceId = this.practiceId;
            filter.pisFileTypeCode = null;
            filter.startDate = this.value.startDate;
            filter.endDate = this.value.endDate;
            filter.invoiceId = this.value.invoiceId ?? null;
            if (filter.startDate && filter.endDate) {
                this.items = await pisFileService.getPisFiles(filter);
            }
        }

        private async mounted() {
            await this.getManagerIdentifier();
            this.loadCurrentInvoiceFromLocalStorage();
            this.value = this.currentInvoice;
            await this.getPisFileItems();
        }
    }
</script>
