<template>
    <v-row>
        <v-col cols="12">
            <v-card-title>Детайли</v-card-title>

            <v-col cols="12">
                <v-tabs dark background-color="primary" show-arrows align-with-title>
                    <v-tab>5 I</v-tab>
                    <v-tab>5 II</v-tab>
                    <v-tab>5 III</v-tab>
                    <v-tab>6 I</v-tab>
                    <v-tab>6 II</v-tab>
                    <v-tab>6 III</v-tab>
                    <v-tab>Общо I</v-tab>
                    <v-tab>Общо II</v-tab>
                    <v-tab>Общо III</v-tab>
                    <v-tab-item>
                        <v-col cols="12"><ChildrenDisease v-model="value.annex56Items.childrenDisease" /></v-col>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12"
                            ><FactorForHealthStatusChildren v-model="value.annex56Items.factorForHealthStatusChildren"
                        /></v-col>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12"
                            ><CausesOfMorbidityMortalityChildren
                                v-model="value.annex56Items.causesOfMorbidityMortalityChildren"
                        /></v-col>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12"><AdultDisease v-model="value.annex56Items.adultDisease" /></v-col>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12"
                            ><FactorForHealthStatusAdult v-model="value.annex56Items.factorForHealthStatusAdult"
                        /></v-col>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12"
                            ><CausesOfMorbidityMortalityAdult
                                v-model="value.annex56Items.causesOfMorbidityMortalityAdult"
                        /></v-col>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12"><TotalDisease v-model="value.annex56Items.totalDisease" /></v-col>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12"
                            ><FactorForHealthStatusTotal v-model="value.annex56Items.factorForHealthStatusTotal"
                        /></v-col>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12"
                            ><CausesOfMorbidityMortalityTotal
                                v-model="value.annex56Items.causesOfMorbidityMortalityTotal"
                        /></v-col>
                    </v-tab-item>
                </v-tabs>
            </v-col>
            <v-card-actions>
                <v-col v-if="reportKey.seqNumber" cols="12" md="2">
                    <btn action="Export" @click="exportAnnex56"> </btn>
                </v-col>
            </v-card-actions>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { Report365Dto } from '@/model/Report/Rhi/Report365/Report365Dto';
    import { RhiReportKey } from '@/model/Report/Rhi/RhiReportKey';
    import { report365Service } from '@/service/Report/Rhi/Report365/Report365Service';
    import AdultDisease from '@/view/Report/Rhi/Report365/Annex56/AdultDisease.vue';
    import CausesOfMorbidityMortalityAdult from '@/view/Report/Rhi/Report365/Annex56/CausesOfMorbidityMortalityAdult.vue';
    import CausesOfMorbidityMortalityChildren from '@/view/Report/Rhi/Report365/Annex56/CausesOfMorbidityMortalityChildren.vue';
    import CausesOfMorbidityMortalityTotal from '@/view/Report/Rhi/Report365/Annex56/CausesOfMorbidityMortalityTotal.vue';
    import ChildrenDisease from '@/view/Report/Rhi/Report365/Annex56/ChildrenDisease.vue';
    import FactorForHealthStatusAdult from '@/view/Report/Rhi/Report365/Annex56/FactorForHealthStatusAdult.vue';
    import FactorForHealthStatusChildren from '@/view/Report/Rhi/Report365/Annex56/FactorForHealthStatusChildren.vue';
    import FactorForHealthStatusTotal from '@/view/Report/Rhi/Report365/Annex56/FactorForHealthStatusTotal.vue';
    import TotalDisease from '@/view/Report/Rhi/Report365/Annex56/TotalDisease.vue';

    @Component({
        components: {
            ChildrenDisease,
            AdultDisease,
            TotalDisease,
            FactorForHealthStatusChildren,
            FactorForHealthStatusAdult,
            FactorForHealthStatusTotal,
            CausesOfMorbidityMortalityChildren,
            CausesOfMorbidityMortalityAdult,
            CausesOfMorbidityMortalityTotal
        }
    })
    export default class Annex56Component extends Vue {
        @Prop({ required: true })
        private reportKey!: RhiReportKey;

        @Prop({ required: true })
        private value!: Report365Dto;

        private timer: number = 0;

        private async exportAnnex56() {
            if (this.reportKey.seqNumber) {
                this.$loading.show();
                try {
                    await report365Service.exportAnnex56(this.reportKey);
                } finally {
                    this.$loading.hide();
                }
            }
        }
    }
</script>
