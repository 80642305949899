import { plainToInstance } from 'class-transformer';

import { EmployeeCreateCommand } from '@/model/Employee/EmployeeCreateCommand';
import { ExistingDoctorEmployeesDto } from '@/model/Employee/ExistingDoctorEmployeesDto';
import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const registerDoctorService = {
    async getDoctorRegistration(practiceId: number, doctorUin: string): Promise<ExistingDoctorEmployeesDto> {
        const response = await httpService.get<ExistingDoctorEmployeesDto>('/RegisterDoctor/GetDoctorRegistration', {
            params: { practiceId, doctorUin }
        });
        return plainToInstance(ExistingDoctorEmployeesDto, response.data);
    },

    async getFreeSpecialties(practiceId: number, doctorId: number | null): Promise<SpecialtyDto[]> {
        const response = await httpService.get<SpecialtyDto[]>('/RegisterDoctor/GetFreeSpecialties', {
            params: { practiceId, doctorId }
        });
        return plainToInstance(SpecialtyDto, response.data ?? []);
    },

    async addDoctorEmployeeAndEmployeeUser(command: EmployeeCreateCommand): Promise<void> {
        await httpService.post(`/RegisterDoctor/AddDoctorEmployeeAndEmployeeUser`, command);
    }
};
