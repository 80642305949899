import { AxiosResponse } from 'axios';

import { RiskGroupDeregisterReasonDto } from '@/model/Nomenclature/RiskGroupDeregisterReasonDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const riskGroupDeregisterReasonService = {
    getRiskGroupDeregisterReasons(): Promise<AxiosResponse<RiskGroupDeregisterReasonDto[]>> {
        return httpService.get('/RiskGroupDeregisterReason/GetRiskGroupDeregisterReasons');
    }
};
