import moment from 'moment';

const config = {
    firstDayOfMonth: 1,
    addMonths: 1,
    lastMonth: 11,
    lastDayOfMonth: 31
};

export const dateUtil = {
    bgDateFormat: 'DD.MM.YYYY',
    bgDateTimeFormat: 'DD.MM.YYYY HH:mm',
    xmlDateFormat: 'YYYY-MM-DD',
    fiscalReceiptFormat: 'MMYYYY',

    formatFiscalReceiptReportDate(dateTime: Date | string | null): string {
        return dateTime ? moment(dateTime).format(this.fiscalReceiptFormat) : '';
    },

    formatBgDate(dateTime: Date | string | null): string {
        return dateTime ? moment(dateTime).format(this.bgDateFormat) : '';
    },

    formatBgDateTime(dateTime: Date | string | null): string {
        return dateTime ? moment(dateTime).format(this.bgDateTimeFormat) : '';
    },

    formatXmlDate(dateTime: Date | string | null): string {
        return dateTime ? moment(dateTime).format(this.xmlDateFormat) : '';
    },

    startOfLastMonth(): Date {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth() - 1, config.firstDayOfMonth, 0, 0, 0);
    },

    endOfLastMonth(): Date {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth() - 1 + config.addMonths, 0, 0, 0, 0);
    },

    startOfThisMonth(): Date {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), config.firstDayOfMonth, 0, 0, 0);
    },

    endOfThisMonth(): Date {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth() + config.addMonths, 0, 0, 0, 0);
    },

    today(): Date {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    },

    monthDifferenceBetweenTwoDates(fromDate: Date, toDate: Date): number {
        const yearMonths = 12;
        let months =
            toDate.getMonth() - fromDate.getMonth() + yearMonths * (toDate.getFullYear() - fromDate.getFullYear());

        if (toDate.getDate() < fromDate.getDate()) {
            months--;
        }
        return months;
    },

    startOfThisYear(): Date {
        const now = new Date();
        return new Date(now.getFullYear(), 0, config.firstDayOfMonth, 0, 0, 0);
    },

    endOfThisYear(): Date {
        const now = new Date();
        return new Date(now.getFullYear(), config.lastMonth, config.lastDayOfMonth, 0, 0, 0);
    },

    startOfLastYear(): Date {
        const now = new Date();
        return new Date(now.getFullYear() - 1, 0, config.firstDayOfMonth, 0, 0, 0);
    },

    endOfLastYear(): Date {
        const now = new Date();
        return new Date(now.getFullYear() - 1, config.lastMonth, config.lastDayOfMonth, 0, 0, 0);
    },

    getFormattedTimeString(hour: number | null, minute: number | null) {
        const padLeftSize = 2;
        return `${hour?.toString().padStart(padLeftSize, '0')}:${minute?.toString().padStart(padLeftSize, '0')}`;
    }
};
