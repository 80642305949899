<template>
    <v-tabs class="mt-5">
        <v-tab>Извършени имунизации</v-tab>
        <v-tab>Пропуснати имунизации</v-tab>
        <v-tab-item>
            <created-immunization-tab
                :patient-id="id"
                :should-get-items="createdNeedReload"
                @added-immunization="missedNeedReload = true"
                @created-loaded="createdNeedReload = false"
            ></created-immunization-tab>
        </v-tab-item>
        <v-tab-item>
            <missed-immunization-tab
                :should-get-items="missedNeedReload"
                @generated-immunizations="createdNeedReload = true"
                @missed-loaded="missedNeedReload = false"
            ></missed-immunization-tab>
        </v-tab-item>
    </v-tabs>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import CreatedImmunizationTab from './CreatedImmunizationTab.vue';
    import MissedImmunizationTab from './MissedImmunizationTab.vue';

    @Component({
        components: {
            CreatedImmunizationTab,
            MissedImmunizationTab
        }
    })
    export default class Immunization extends Vue {
        @Prop()
        private id!: number;

        private createdNeedReload: boolean = false;
        private missedNeedReload: boolean = false;
    }
</script>
