<template>
    <div>
        <v-row>
            <v-col md="1"
                ><PidTypePicker
                    v-model="personIdentification.pidTypeCode"
                    :disabled="disabled"
                    required
                    @input="onPidTypeChange"
                    @blur="checkIdentifier"
            /></v-col>
            <v-col cols="6" md="2">
                <text-field
                    v-model.trim="personIdentification.identifier"
                    required
                    label="Идентификатор:"
                    :disabled="disabled"
                    :clearable="true"
                    :rules="[$validator.identifier(personIdentification.pidTypeCode, personIdentification.identifier)]"
                    :hint="
                        isEgn ? 'При попълване и напускане на поле идентификатор се попълват Дата на раждане и Пол' : ''
                    "
                    @blur="parseBirthDateAndGender"
                    @input="checkIdentifier"
            /></v-col>
            <v-col cols="6" md="3">
                <date-picker
                    v-model="personIdentification.birthDate"
                    required
                    label="Дата на раждане:"
                    :disabled="disabled"
                    :readonly="isEgn"
                    :hint="isEgn ? 'Попълва се автоматично от поле идентификатор' : ''"
                    @input="$emit('birth-date-change')"
            /></v-col>
            <v-col cols="6" md="3">
                <dropdown
                    v-model="personIdentification.genderCode"
                    :items="genders"
                    item-value="code"
                    label="Пол"
                    :disabled="disabled"
                    :readonly="isEgn"
                    :hint="isEgn ? 'Попълва се автоматично от поле идентификатор' : ''"
                    required
            /></v-col>
            <v-col cols="6" md="3">
                <dropdown
                    v-model="personIdentification.countryId"
                    :items="countries"
                    label="Код държава"
                    :disabled="disabled"
                    required
            /></v-col>

            <v-col v-if="isForeigner && showCertificateData" cols="6" md="3"
                ><dropdown
                    v-model="personIdentification.certificateDocumentTypeId"
                    class="required"
                    :items="certificateDocumentTypes"
                    label="Тип удостоверителен документ"
                    required
                    :disabled="disabled"
                    @input="clearCertificateDocumentNumberOnTypeChange"
            /></v-col>
            <v-col v-if="isForeigner && isEzok && showCertificateData" cols="6" md="3">
                <text-field
                    v-model="personIdentification.certificateDocumentNumber"
                    required
                    label="Удостоверителен документ номер:"
                    :disabled="disabled"
            /></v-col>
            <v-col v-if="isForeigner && showCertificateData" cols="6" md="3">
                <text-field
                    v-model="personIdentification.insuranceInstitutionName"
                    required
                    label="Име на осигурителната институция:"
                    :disabled="disabled"
            /></v-col>
            <v-col v-if="isForeigner && showCertificateData" cols="6" md="3">
                <text-field
                    v-model="personIdentification.insuranceInstitutionNumber"
                    required
                    label="Номер на осигурителната институция:"
                    :disabled="disabled"
            /></v-col>
            <v-col v-if="isForeigner && showCertificateData" cols="6" md="3">
                <date-picker
                    v-model="personIdentification.certificateDocumentIssueDate"
                    required
                    label="Дата на издаване:"
                    :disabled="disabled"
            /></v-col>
            <v-col v-if="isForeigner && showCertificateData" cols="6" md="3">
                <date-picker
                    v-model="personIdentification.certificateDocumentValidFrom"
                    required
                    label="Дата на валидност от:"
                    :disabled="disabled"
            /></v-col>
            <v-col v-if="isForeigner && !isCertificateLifeTime && showCertificateData" cols="6" md="3">
                <date-picker
                    v-model="personIdentification.certificateDocumentValidTo"
                    required
                    label="Дата на валидност до:"
                    :disabled="disabled"
            /></v-col>
            <v-checkbox
                v-if="isForeigner && showCertificateData"
                v-model="isCertificateLifeTime"
                label="До отмяна"
                :disabled="disabled"
            ></v-checkbox>
            <v-col v-if="isBaby" cols="6" md="3">
                <text-field
                    v-model.trim="personIdentification.newbornIdentifier"
                    label="Идентификатор на новороденото:"
                    :disabled="disabled"
            /></v-col>
            <v-col v-if="isBaby && !disabled" cols="6" md="3">
                <btn action="Refresh" @click="newbornExtractionDialogIsVisible = true">
                    Извличане идентификатор новородено
                </btn>
            </v-col>
        </v-row>
        <newborn-extraction
            v-model="personIdentification"
            :dialog-is-visible="newbornExtractionDialogIsVisible"
            @close="newbornExtractionDialogIsVisible = false"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import PidTypePicker from '@/component/Picker/PidTypePicker.vue';
    import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';
    import { CertificateDocumentTypeCode } from '@/enum/Patient/CertificateDocumentTypeCode';
    import { PersonIdentificationDto } from '@/model/Common/PersonIdentificationDto';
    import { CertificateDocumentTypeDto } from '@/model/Nomenclature/CertificateDocumentTypeDto';
    import { CountryDto } from '@/model/Nomenclature/CountryDto';
    import { certificateDocumentTypeService } from '@/service/Nomenclature/CertificateDocumentTypeService';
    import { countryService } from '@/service/Nomenclature/CountryService';
    import { genderCache } from '@/store/Nomenclature/GenderCache';
    import { identifierUtil } from '@/util/IdentifierUtil';
    import NewbornExtraction from '@/view/Nhis/Hospitalization/NewbornExtraction.vue';

    @Component({
        components: { NewbornExtraction, PidTypePicker }
    })
    export default class PersonIdentification extends Vue {
        @Prop({ type: Object })
        private personIdentification!: PersonIdentificationDto;

        @Prop({ default: true })
        private showCertificateData!: boolean;

        @Prop()
        private disabled!: boolean;

        private countries: CountryDto[] = [];
        private certificateDocumentTypes: CertificateDocumentTypeDto[] = [];
        private newbornExtractionDialogIsVisible: boolean = false;

        private get genders() {
            return genderCache.items;
        }

        private async getCountries() {
            const response = await countryService.getCountries();
            this.countries = response.data;
        }

        private async getCertificateDocumentType() {
            const response = await certificateDocumentTypeService.getCertificateDocumentTypes();
            this.certificateDocumentTypes = response.data;
        }

        private parseBirthDateAndGender() {
            this.personIdentification.genderCode = identifierUtil.parseGender(
                this.personIdentification?.pidTypeCode,
                this.personIdentification.identifier
            );

            this.personIdentification.birthDate = identifierUtil.parseBirthDate(
                this.personIdentification?.pidTypeCode,
                this.personIdentification.identifier
            );

            this.$emit('reset-statuses');
        }

        private checkIdentifier() {
            const identifierMinLength = 5;
            if (this.personIdentification.identifier?.length >= identifierMinLength) {
                this.$emit('check-identifier');
            }
        }

        private clearForeignerDataOnPidTypeChange() {
            const countryCodeBG = 23;
            this.personIdentification.countryId = countryCodeBG;
            this.personIdentification.certificateDocumentTypeId = null;
            this.personIdentification.certificateDocumentNumber = '';
            this.personIdentification.insuranceInstitutionName = '';
            this.personIdentification.insuranceInstitutionNumber = '';
            this.personIdentification.certificateDocumentIssueDate = null;
            this.personIdentification.certificateDocumentValidFrom = null;
            this.personIdentification.certificateDocumentValidTo = null;
            this.personIdentification.newbornIdentifier = null;
        }

        private clearCertificateDocumentNumberOnTypeChange() {
            this.personIdentification.certificateDocumentNumber = '';
        }

        private get isBaby() {
            return this.personIdentification?.pidTypeCode === PidTypeCode.Baby;
        }

        private get isForeigner() {
            return this.personIdentification?.pidTypeCode === PidTypeCode.Foreigner;
        }

        private get isEgn() {
            return this.personIdentification?.pidTypeCode === PidTypeCode.Egn;
        }

        private get isEzok() {
            // TODO: Не трябва да се сравнява с число. От базата трябва да се зареди активното id, което стои зад код 'Ehic'.
            return this.personIdentification.certificateDocumentTypeId === CertificateDocumentTypeCode.Ehic;
        }

        private get isCertificateLifeTime() {
            return Boolean(!this.personIdentification.certificateDocumentValidTo);
        }

        private set isCertificateLifeTime(value: boolean) {
            if (!value) {
                this.personIdentification.certificateDocumentValidTo = new Date();
            } else {
                this.personIdentification.certificateDocumentValidTo = null;
            }
        }

        private onPidTypeChange(): void {
            this.clearForeignerDataOnPidTypeChange();
            this.parseBirthDateAndGender();
        }

        private async mounted() {
            await this.getCountries();
            await this.getCertificateDocumentType();
        }
    }
</script>
