<template>
    <PriceList :practice-id="practiceId"></PriceList>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import PriceList from '@/view/Practice/Service/PriceList.vue';

    @Component({
        components: {
            PriceList
        }
    })
    export default class ServiceList extends Vue {
        @Prop()
        private practiceId!: number;
    }
</script>
