export class ReferralRequestForDto {
    public id: number = 0;
    public number: number = 0;
    public issueDate: Date | null = new Date();
    public mainDiagnosis: string = '';
    public comorbidityDiagnoses: string | null = null;
    public examId: number = 0;
    public patientWorkplaceId: number | null = null;
    public medicalCentre: string | null = null;
    public referralRequestForTypeCode: string | null = null;
    public requestFor: string | null = null;
    public attachedDocuments: string | null = null;
}
