import { AxiosResponse } from 'axios';

import { ResultReferral7Dto } from '@/model/Exam/Referral/ResultReferral/ResultReferral7Dto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { accessTokenCache, nhisKey } from '@/store/NhisNhifAccessTokenCache';

export const ReferralResult7Service = {
    async getResultData(examId: number, nrn: string): Promise<AxiosResponse<ResultReferral7Dto>> {
        const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
        const token = tokenInfo?.accessToken ?? '';
        return httpService.get('ReferralResult7/GetResultData', { params: { examId, nrn, token } });
    },

    getEpicrisisFile(path: string, token: string): Promise<void> {
        return httpService.download('ReferralResult7/GetEpicrisisFile', { params: { path, token } });
    }
};
