<template>
    <v-card>
        <v-card-subtitle>Филтър</v-card-subtitle>
        <v-card-text>
            <v-row class="pa-3">
                <dropdown
                    v-model="selectedCoreServiceType"
                    :items="coreServiceTypes"
                    return-object
                    label="Вид дейност"
                    @input="checkSelectedCoreServiceType()"
                ></dropdown>
                <dropdown
                    v-model="selectedServiceActivity"
                    :items="serviceActivities"
                    class="ml-3"
                    label="Активни/неактивни"
                ></dropdown>
                <dropdown
                    v-model="selectedServiceType"
                    class="ml-3"
                    :items="serviceTypes"
                    label="Единични/пакетни"
                ></dropdown>
                <dropdown
                    v-if="showServiceCategoryDropdown"
                    v-model="selectedServiceCategory"
                    class="ml-3"
                    :items="serviceCategories"
                    label="Категория"
                    return-object
                ></dropdown>
                <text-field v-model="search" class="ml-3" label="Търсене по име"></text-field>
            </v-row>
            <v-row class="pa-3">
                <dropdown
                    v-if="showFundDropdown"
                    v-model="selectedFund"
                    :items="fundItems"
                    label="Фонд"
                    return-object
                ></dropdown>
                <dropdown
                    v-if="showLabTestCategoryDropdown"
                    v-model="selectedLabTestCategory"
                    class="ml-3"
                    :items="labTestCategoryNames"
                    label="Вид изследване"
                ></dropdown>
                <dropdown
                    v-if="showAlgorithmTypeDropdown"
                    v-model="selectedAlgorithmType"
                    class="ml-3"
                    :items="algorithmTypes"
                    label="Пътека/процедура"
                ></dropdown>
            </v-row>
            <btn action="Search" @click="filter()"></btn>
        </v-card-text>
        <data-table title="Ценоразпис на услуги" :headers="headers" :items="filteredItems">
            <template #actions>
                <btn class="mb-3" action="New" :to="`/Finance/Service/Create/${practiceId}`">Добавяне на услуга</btn>
                <btn class="mb-3" action="New" :to="`/Finance/Service/Batch/Create/${practiceId}`">
                    Добавяне на пакетна услуга
                </btn>
            </template>
            <template #rowActions="{ item }">
                <btn icon action="Edit" :to="edit(item)" />
            </template>
        </data-table>
    </v-card>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import Dropdown from '@/component/Dropdown/Dropdown.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PracticeFundDto } from '@/model/Practice/PracticeFundDto';
    import { CoreServiceTypeDto } from '@/model/Practice/Service/CoreServiceTypeDto';
    import { PracticeServiceViewDto } from '@/model/Practice/Service/PracticeServiceViewDto';
    import { ServiceCategoryDto } from '@/model/Practice/Service/ServiceCategoryDto';
    import { practiceFundService } from '@/service/Practice/PracticeFundService';
    import { practiceServiceService } from '@/service/Practice/Service/PracticeServiceService';
    import { formatters } from '@/util/Formatters';

    @Component({ components: { Dropdown } })
    export default class PriceList extends Vue {
        @Prop()
        private practiceId!: number;

        private items: PracticeServiceViewDto[] = [];
        private filteredItems: PracticeServiceViewDto[] = [];
        private coreServiceTypes: CoreServiceTypeDto[] = [];
        private selectedCoreServiceType: CoreServiceTypeDto | null = null;
        private allText: string = 'Всички';
        private serviceTypeNone: string = 'Без свързана дейност';
        private serviceTypes: string[] = [this.allText, 'Пакетни', 'Непакетни'];
        private selectedServiceType: string = this.allText;
        private serviceActivities: string[] = [this.allText, 'Активни', 'Неактивни'];
        private selectedServiceActivity: string = this.allText;
        private search: string = '';
        private fundItems: PracticeFundDto[] = [];
        private practiceFunds: PracticeFundDto[] = [];
        private showFundDropdown: boolean = false;
        private showLabTestCategoryDropdown: boolean = false;
        private showAlgorithmTypeDropdown: boolean = false;
        private selectedFund: PracticeFundDto | null = null;
        private labTestCategoryNames: string[] = [];
        private selectedLabTestCategory: string | null = null;
        private algorithmTypes: string[] = [];
        private selectedAlgorithmType: string | null = null;
        private serviceCategories: ServiceCategoryDto[] = [];
        private showServiceCategoryDropdown: boolean = false;
        private selectedServiceCategory: ServiceCategoryDto | null = null;

        private headers: IDataTableHeader[] = [];

        private async mounted() {
            const practiceServices = await practiceServiceService.getPracticeServices(this.practiceId);
            for (const practiceService of practiceServices) {
                const { serviceOffers } = practiceService;
                for (const serviceOffer of serviceOffers) {
                    const { tariffSeqNumber } = serviceOffer.key;
                    if (!this.headers.find((header) => header.value === tariffSeqNumber.toString())) {
                        this.headers.push({
                            text: serviceOffer.tariffName,
                            value: tariffSeqNumber.toString(),
                            formatter: formatters.currency
                        });
                    }
                    practiceService[tariffSeqNumber] = serviceOffer.patientPrice;
                }
                if (practiceService.batchCoreServiceTypes) {
                    practiceService.batchCoreServiceTypes = practiceService.batchCoreServiceTypes.filter(
                        (type, index) => practiceService.batchCoreServiceTypes.indexOf(type) === index
                    );
                } else {
                    practiceService.batchCoreServiceTypes = [];
                }
            }
            this.items = practiceServices;
            this.filteredItems = this.items;
            this.addHeaders();
            await this.addCustomServiceTypes();
            this.practiceFunds = (await practiceFundService.getActiveFunds(this.practiceId, null, false)).data;
            this.getLabTestCategoryNames();
            this.getAlgorithmTypes();
            this.getPracticeServiceCategories();
        }

        private addHeaders() {
            this.headers = this.headers.sort((h1, h2) => h1.text.localeCompare(h2.text));
            this.headers.splice(0, 0, { text: 'Услуга / Цена за пациента', value: 'serviceTitle.name' });
            this.headers.push(
                { text: 'Вид дейност', value: 'coreServiceType' },
                { text: 'Действа от', value: 'serviceTitle.validFrom', formatter: formatters.date },
                { text: 'Действа до', value: 'serviceTitle.validTo', formatter: formatters.date },
                {
                    text: 'Пакетна услуга',
                    value: 'hasBatchServiceOffers',
                    formatter: formatters.boolean
                },
                {
                    text: 'Категория',
                    value: 'serviceTitle.serviceCategoryName'
                }
            );
        }

        async getPracticeServiceCategories() {
            const response = await practiceServiceService.getServiceCategories(this.practiceId);

            if (response.length > 0) {
                const withoutServiceCategory = new ServiceCategoryDto();
                withoutServiceCategory.name = 'Без категория';
                withoutServiceCategory.seqNumber = 0;
                withoutServiceCategory.practiceId = this.practiceId;
                response.unshift(withoutServiceCategory);
                const allServiceCategory = new ServiceCategoryDto();
                allServiceCategory.name = this.allText;
                allServiceCategory.seqNumber = -1;
                allServiceCategory.practiceId = this.practiceId;
                response.unshift(allServiceCategory);
                this.serviceCategories = response;
                this.showServiceCategoryDropdown = true;
            } else {
                this.showServiceCategoryDropdown = false;
                this.serviceCategories = [];
            }
        }

        getAlgorithmTypes() {
            const algorithmTypes = this.items
                // eslint-disable-next-line max-nested-callbacks
                .filter((item) => item.serviceOffers.some((offer) => offer.algorithmType))
                // eslint-disable-next-line max-nested-callbacks
                .flatMap((item) => item.serviceOffers.map((offer) => offer.algorithmType))
                .sort();

            const filteredAndSortedAlgorithmTypes = algorithmTypes
                .filter((name, index) => algorithmTypes.indexOf(name) === index && name !== null)
                .sort();
            filteredAndSortedAlgorithmTypes.unshift(this.allText);
            this.algorithmTypes = filteredAndSortedAlgorithmTypes;
        }

        getLabTestCategoryNames() {
            const labTestCategoryNames = this.items
                .filter((item) =>
                    // eslint-disable-next-line max-nested-callbacks
                    item.serviceOffers.some((offer) => offer.labTestCategoryName)
                )
                // eslint-disable-next-line max-nested-callbacks
                .flatMap((item) => item.serviceOffers.map((offer) => offer.labTestCategoryName));
            const filteredAndSortedLabTestCategoryNames = labTestCategoryNames
                .filter((name, index) => labTestCategoryNames.indexOf(name) === index && name !== null)
                .sort();
            filteredAndSortedLabTestCategoryNames.unshift(this.allText);
            this.labTestCategoryNames = filteredAndSortedLabTestCategoryNames;
        }

        private async addCustomServiceTypes() {
            this.coreServiceTypes = await practiceServiceService.getCoreServiceTypes();
            const coreServiceTypeNone = new CoreServiceTypeDto();
            coreServiceTypeNone.name = this.serviceTypeNone;
            this.coreServiceTypes.unshift(coreServiceTypeNone);
            const coreServiceTypeAll = new CoreServiceTypeDto();
            coreServiceTypeAll.name = this.allText;
            this.coreServiceTypes.unshift(coreServiceTypeAll);
            this.selectedCoreServiceType = coreServiceTypeAll;
        }

        private edit(item: PracticeServiceViewDto) {
            return item.hasBatchServiceOffers
                ? `/Finance/Service/Batch/Edit/${this.practiceId}/${item.serviceTitle.seqNumber}`
                : `/Finance/Service/Edit/${this.practiceId}/${item.serviceTitle.seqNumber}`;
        }

        private filter() {
            if (
                this.selectedCoreServiceType ||
                this.selectedServiceType !== this.allText ||
                this.selectedServiceActivity !== this.allText ||
                this.search
            ) {
                let filteredResult = this.items;
                filteredResult = this.filterByCoreServiceType(filteredResult);
                filteredResult = this.filterByServiceActivity(filteredResult);
                filteredResult = this.filterByServiceType(filteredResult);
                filteredResult = this.filterBySearchField(filteredResult);
                filteredResult = this.filterByFund(filteredResult);
                filteredResult = this.filterByLabTestCategory(filteredResult);
                filteredResult = this.filterByAlgorithmType(filteredResult);
                filteredResult = this.filterByServiceCategory(filteredResult);

                this.filteredItems = filteredResult;
            } else {
                this.filteredItems = this.items;
            }
        }

        private filterByServiceCategory(filteredResult: PracticeServiceViewDto[]) {
            const result = filteredResult;
            if (
                this.showServiceCategoryDropdown &&
                this.selectedServiceCategory &&
                this.selectedServiceCategory.name !== this.allText
            ) {
                if (this.selectedServiceCategory.seqNumber === 0) {
                    return result.filter((item) => !item.serviceTitle.serviceCategoryName);
                }
                return result.filter(
                    (item) => item.serviceTitle.serviceCategoryName === this.selectedServiceCategory?.name
                );
            }
            return result;
        }

        private filterByAlgorithmType(filteredResult: PracticeServiceViewDto[]) {
            let result = filteredResult;
            if (
                this.showAlgorithmTypeDropdown &&
                this.selectedAlgorithmType &&
                this.selectedAlgorithmType !== this.allText
            ) {
                result = filteredResult.filter((item) =>
                    item.serviceOffers.some(
                        // eslint-disable-next-line max-nested-callbacks
                        (offer) => offer.algorithmType === this.selectedAlgorithmType
                    )
                );
            }
            return result;
        }

        private filterByLabTestCategory(filteredResult: PracticeServiceViewDto[]) {
            let result = filteredResult;
            if (
                this.showLabTestCategoryDropdown &&
                this.selectedLabTestCategory &&
                this.selectedLabTestCategory !== this.allText
            ) {
                result = filteredResult.filter((item) =>
                    item.serviceOffers.some(
                        // eslint-disable-next-line max-nested-callbacks
                        (offer) => offer.labTestCategoryName === this.selectedLabTestCategory
                    )
                );
            }
            return result;
        }

        private filterByFund(filteredResult: PracticeServiceViewDto[]) {
            let result = filteredResult;
            if (this.showFundDropdown && this.selectedFund && this.selectedFund.name !== this.allText) {
                result = filteredResult.filter((item) =>
                    item.serviceOffers.some(
                        // eslint-disable-next-line max-nested-callbacks
                        (offer) => offer.tariffName === this.selectedFund?.name && offer.patientPrice
                    )
                );
            }
            return result;
        }

        private filterBySearchField(filteredResult: PracticeServiceViewDto[]) {
            let result = filteredResult;
            if (this.search) {
                result = filteredResult.filter((item) =>
                    item.serviceTitle.name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
            return result;
        }

        private filterByServiceType(filteredResult: PracticeServiceViewDto[]) {
            let result = filteredResult;
            if (this.selectedServiceType !== this.allText) {
                if (this.selectedServiceType === 'Пакетни') {
                    result = filteredResult.filter((item) => item.hasBatchServiceOffers);
                } else {
                    result = filteredResult.filter((item) => !item.hasBatchServiceOffers);
                }
            }
            return result;
        }

        private filterByServiceActivity(filteredResult: PracticeServiceViewDto[]) {
            let result = filteredResult;
            if (this.selectedServiceActivity !== this.allText) {
                if (this.selectedServiceActivity === 'Активни') {
                    result = filteredResult.filter((item) => item.serviceTitle.isActive);
                } else {
                    result = filteredResult.filter((item) => !item.serviceTitle.isActive);
                }
            }
            return result;
        }

        private filterByCoreServiceType(filteredResult: PracticeServiceViewDto[]) {
            let result = filteredResult;
            if (this.selectedCoreServiceType && this.selectedCoreServiceType.name !== this.allText) {
                if (this.selectedCoreServiceType.name === this.serviceTypeNone) {
                    result = filteredResult.filter(
                        (item) =>
                            // eslint-disable-next-line max-nested-callbacks
                            item.coreServiceType === '' || item.batchCoreServiceTypes.some((type) => type === '')
                    );
                } else {
                    result = filteredResult.filter(
                        (item) =>
                            this.selectedCoreServiceType !== null &&
                            (item.coreServiceType === this.selectedCoreServiceType.name ||
                                item.batchCoreServiceTypes.some(
                                    // eslint-disable-next-line max-nested-callbacks
                                    (type) => type === this.selectedCoreServiceType?.name
                                ))
                    );
                }
            }
            return result;
        }

        private checkSelectedCoreServiceType() {
            const specialCoreServiceTypesCodes = ['Al', 'Ex', 'Lt', 'Mp'];
            this.fundItems = [];
            if (
                this.selectedCoreServiceType &&
                specialCoreServiceTypesCodes.includes(this.selectedCoreServiceType.code)
            ) {
                for (const fund of this.practiceFunds) {
                    if (
                        this.items.some(
                            (item) =>
                                // TODO: Проверката да се изнесе във функция с говорещо име и без suppressions.
                                (item.coreServiceType === this.selectedCoreServiceType?.name ||
                                    item.batchCoreServiceTypes.some(
                                        // eslint-disable-next-line max-nested-callbacks
                                        (type) => type === this.selectedCoreServiceType?.name
                                    )) &&
                                // eslint-disable-next-line max-nested-callbacks
                                item.serviceOffers.some((offer) => offer.tariffName === fund.name && offer.patientPrice)
                        )
                    ) {
                        this.fundItems.push(fund);
                    }
                }
                const fundAll = new PracticeFundDto();
                fundAll.name = this.allText;
                this.fundItems.unshift(fundAll);
                this.selectedFund = fundAll;
                this.showFundDropdown = true;

                this.showAdditionalFilters();
            } else {
                this.showFundDropdown = false;
                this.selectedFund = null;
                this.showLabTestCategoryDropdown = false;
                this.selectedLabTestCategory = null;
                this.showAlgorithmTypeDropdown = false;
                this.selectedAlgorithmType = null;
            }
        }

        private showAdditionalFilters() {
            if (this.selectedCoreServiceType) {
                if (this.selectedCoreServiceType.code === 'Lt') {
                    this.showLabTestCategoryDropdown = true;
                } else {
                    this.showLabTestCategoryDropdown = false;
                    this.selectedLabTestCategory = null;
                }

                if (this.selectedCoreServiceType.code === 'Al') {
                    this.showAlgorithmTypeDropdown = true;
                } else {
                    this.showAlgorithmTypeDropdown = false;
                    this.selectedAlgorithmType = null;
                }
            }
        }
    }
</script>
