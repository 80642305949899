<template>
    <div>
        <data-table
            title="Фамилна обремененост на пациента"
            :headers="tableHeaders"
            :loading="$loading.isVisible"
            :items="items"
            class="elevation-1 mb-5"
            disable-pagination
        >
            <template v-if="$allowWrite" #actions>
                <btn action="New" :to="`/Patient/FamilyHistory/Create/${id}`" />
            </template>
            <template v-if="$allowWrite" #rowActions="{ item }">
                <btn icon action="Edit" :to="`/Patient/FamilyHistory/Edit/${item.id}/${id}`" />
            </template>
        </data-table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { FamilyHistoryViewDto } from '@/model/Patient/FamilyHistory/FamilyHistoryViewDto';
    import { familyHistoryService } from '@/service/Patient/FamilyHistoryService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class FamilyHistory extends Vue {
        @Prop()
        public id!: number;

        private items: FamilyHistoryViewDto[] = [];

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Дата на откриване', value: 'issueDate', formatter: formatters.date },
                { text: 'Име на роднината', value: 'relativeName' },
                { text: 'Роднинска връзка', value: 'relationship' },
                { text: 'Пол на роднината', value: 'relativeGender' },
                { text: 'Статус на записа', value: 'status' },
                { text: 'НРН', value: 'nrn' },
                { text: '', value: 'actionButtons' }
            ];
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                this.items = [];
                const response = await familyHistoryService.getFamilyHistories(this.id);
                if (response) {
                    this.items = response;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            if (this.id) {
                await this.getTableItems();
            }
        }
    }
</script>

<style scoped></style>
