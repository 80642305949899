import Vue from 'vue';

import { MedicalProcedureAnswerEnumDto } from '@/model/Nomenclature/MedicalProcedureAnswerEnumDto';
import { medicalProcedureAnswerEnumService } from '@/service/Nomenclature/MedicalProcedureAnswerEnumService';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

const loadingItem = new MedicalProcedureAnswerEnumDto();
loadingItem.name = 'Зареждане...';
const loadErrorItem = new MedicalProcedureAnswerEnumDto();
loadErrorItem.name = 'Грешка при зареждане';

class MedicalProcedureAnswerEnumCache extends ArrayCacheBase<MedicalProcedureAnswerEnumDto> {
    constructor() {
        super(loadingItem, loadErrorItem);
    }

    protected async load() {
        const response = await medicalProcedureAnswerEnumService.getMedicalProcedureAnswerEnum();
        this._items = response.data;
    }
}

export const medicalProcedureAnswerEnumCache = Vue.observable(new MedicalProcedureAnswerEnumCache());
