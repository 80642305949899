import { plainToInstance } from 'class-transformer';

import { QueuedVisitListDto } from '@/model/VisitList/Queue/QueuedVisitListDto';
import { QueueIntervalFilterDto } from '@/model/VisitList/Queue/QueueIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const queueService = {
    async getQueuedVisits(filter: QueueIntervalFilterDto): Promise<QueuedVisitListDto[]> {
        const response = await httpService.post<QueuedVisitListDto[]>(`/Queue/GetQueuedVisits`, filter);
        return plainToInstance(QueuedVisitListDto, response.data);
    },

    async getDoneVisits(filter: QueueIntervalFilterDto): Promise<number> {
        const response = await httpService.post<number>(`/Queue/GetDoneVisits`, filter);
        return response.data;
    }
};
