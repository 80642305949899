export class PrescriptionNhifWhiteViewDto {
    public id: number = 0;
    public issueDate: Date | null = null;
    public repeatsNumber: number = 0;
    public drugs: string[] = [];
    public nrn: string | null = null;
    public status: string | null = null;
    public statusTime: string | null = null;
    public executionDate: string | null = null;
}
