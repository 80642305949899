<template>
    <data-table
        title="Регистър рискови групи"
        :headers="headers"
        :items="registers"
        show-search-box
        :item-class="itemRowBackground"
    >
        <template #actions>
            <btn action="New" :to="`/Register/RiskGroupRegister/Create`"> Добавяне на запис </btn>
            <btn action="Export" @click="exportExcel()">Експорт</btn>
            <btn v-if="isDevOrTest" action="Refresh" @click="fetchLongtermCare()">Извличане НЗИС</btn>
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/Register/RiskGroupRegister/Edit/${item.id}`" />
            <btn icon action="Delete" @click="deleteRegister(item)" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { config } from '@/Config';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhisLongtermCareXmlRequestDto } from '@/model/Nhis/LongtermCare/NhisLongtermCareXmlRequestDto';
    import { RiskGroupRegisterDto } from '@/model/Register/RiskGroup/RiskGroupRegisterDto';
    import { nhisLongtermCareService } from '@/service/Nhis/NhisLongtermCareService';
    import { riskGroupRegisterService } from '@/service/Register/RiskGroupRegisterService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class RiskGroupRegister extends Vue {
        private registers: RiskGroupRegisterDto[] = [];
        private headers: IDataTableHeader[] = [
            { text: 'Пациент - Идентификатор', value: 'patientIdentifier' },
            { text: 'Пациент - Имена', value: 'patientName' },
            { text: 'Дата на регистрация', value: 'startDate', filterable: false, formatter: formatters.date },
            { text: 'МКБ', value: 'riskGroupRule.icdCode' },
            { text: 'Дата на отписване', value: 'endDate', filterable: false, formatter: formatters.date },
            { text: '', value: 'actionButtons', sortable: false }
        ];

        private get isDevOrTest() {
            return config.isDevOrTest;
        }

        private async load() {
            const response = await riskGroupRegisterService.getCurrentEmployeeRiskGroupRegisters();
            this.registers = response.data;
        }

        private async mounted() {
            await this.load();
        }

        private async exportExcel() {
            this.$loading.show();
            try {
                await riskGroupRegisterService.exportToExcel();
            } finally {
                this.$loading.hide();
            }
        }

        private itemRowBackground(item: RiskGroupRegisterDto) {
            if (item.endDate !== null) {
                return 'style-deregistered';
            }
            return 'style-main';
        }

        private deleteRegister(itemToDelete: RiskGroupRegisterDto): void {
            riskGroupRegisterService.delete(itemToDelete.id);
            arrayUtil.removeFirst(this.registers, itemToDelete);
        }

        private async fetchLongtermCare() {
            const response = await nhisLongtermCareService.postFetchLongtermCare(this.createCommand());
            if (response) {
                await this.load();
            }
        }

        private createCommand() {
            const command = new NhisLongtermCareXmlRequestDto();
            command.deputyDoctorIsHired = userContextCache.current?.deputyDoctorIsHired ?? null;
            command.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
            command.doctorUin = userContextCache.current?.doctorUin ?? '';
            command.doctorSpecialtyCode = userContextCache.current?.specialtyCode ?? null;
            command.doctorEmployeeId = userContextCache.currentDoctorEmployeeId;
            command.doctorEmployeeSeqNumber = userContextCache.current?.employeeSeqNumber ?? 0;
            command.patientId = currentPatientCache.value.key.patientId;
            command.practiceId = userContextCache.currentPracticeId ?? 0;

            return command;
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../asset/Css/Register.css';
    /*По някаква причина долният импорт на по-новия формат css не работи. Трябва да търсим причината.*/
    /*@import '../../asset/Css/Register.scss'; */
</style>
