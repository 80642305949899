import { plainToInstance } from 'class-transformer';

import { CertificateDriveTypeDto } from '@/model/Nomenclature/CertificateDriveTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const certificateDriveTypeService = {
    async getCertificateDriveTypes(): Promise<CertificateDriveTypeDto[]> {
        const response = await httpService.get<CertificateDriveTypeDto[]>(
            'CertificateDriveType/GetCertificateDriveTypes'
        );

        return plainToInstance(CertificateDriveTypeDto, response?.data);
    }
};
