import { plainToInstance } from 'class-transformer';

import { MonthlyNotificationDto } from '@/model/Invoice/Pis/MonthlyNotificationDto';
import { PisInvoiceCreateCommand } from '@/model/Invoice/Pis/PisInvoiceCreateCommand';
import { PisInvoiceDto } from '@/model/Invoice/Pis/PisInvoiceDto';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { userContextCache } from '@/store/User/UserContextCache';

export const pisInvoiceService = {
    async getPisInvoicesByPeriod(filter: ReportIntervalFilterDto): Promise<PisInvoiceDto[]> {
        const response = await httpService.post<PisInvoiceDto[]>('/PisInvoice/GetPisInvoicesByPeriod', filter);
        return plainToInstance(PisInvoiceDto, response.data);
    },

    async monthlyNotificationToPisInvoice(monthlyNotifications: MonthlyNotificationDto[]): Promise<void> {
        const command = new PisInvoiceCreateCommand(
            userContextCache.currentDoctorEmployeeId ?? 0,
            monthlyNotifications
        );
        await httpService.post('/PisInvoice/MonthlyNotificationToPisInvoice', command);
    },

    async generatePisInvoice(data: PisInvoiceDto): Promise<string> {
        const response = await httpService.post<string>(
            `/PisInvoice/GeneratePisInvoiceXml?doctorEmployeeId=${userContextCache.currentDoctorEmployeeId}`,
            data
        );
        return response.data;
    }
};
