<template>
    <div>
        <h3>ОЦЕНКА НА СС РИСК ПО SCORE СИСТЕМАТА</h3>
        общ холестерол, пол, тютюнопушене, възраст и систолно артериално налягане за лица без сърдечно-съдови
        заболявания, захарен диабет, ХБН
        <v-row>
            <v-col v-if="value.score < 1" cols="7"><label>Нисък риск по SCORE система</label></v-col>
            <v-col v-if="value.score >= 1 && value.score < 5" cols="7">
                <label>Умерен риск по SCORE система</label>
            </v-col>
            <v-col v-if="value.score >= 5 && value.score < 10" cols="7">
                <label>Висок риск по SCORE система</label>
            </v-col>
            <v-col v-if="value.score >= 10" cols="7"><label>Много висок риск по SCORE система</label></v-col>
            <v-text-field v-model="value.score" disabled hide-details dense :class="itemBackground" label="Резултат" />
        </v-row>
        <v-row class="mt-8">Легенда:</v-row>
        <v-row>
            <v-col class="style-dark-red" cols="4"><label>15% +</label></v-col>
            <v-col class="style-red" cols="4"><label>10% - 14%</label></v-col>
            <v-col class="style-light-red" cols="4"><label>5% - 9%</label></v-col>
        </v-row>
        <v-row class="my-4">
            <v-col class="style-orange" cols="3"><label>3% - 4%</label></v-col>
            <v-col class="style-yellow" cols="3"><label>2%</label></v-col>
            <v-col class="style-light-green" cols="3"><label>1%</label></v-col>
            <v-col class="style-dark-green" cols="3"><label>&lt;1%</label></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { RiskAssessmentCardDto } from '@/model/Exam/Gp/RiskAssessmentCard/RiskAssessmentCardDto';

    @Component
    export default class RiskByScoreCard extends Vue {
        @Prop({ type: Object })
        private value!: RiskAssessmentCardDto;

        private get itemBackground() {
            const two = 2;
            const three = 3;
            const four = 4;
            const five = 5;
            const nine = 9;
            const ten = 10;
            const fourteen = 14;
            const fiveteen = 15;
            if (this.value.score !== null) {
                return {
                    'style-dark-green': this.value.score < 1,
                    'style-light-green': this.value.score === 1,
                    'style-yellow': this.value.score === two,
                    'style-orange': this.value.score >= three && this.value.score <= four,
                    'style-light-red': this.value.score >= five && this.value.score <= nine,
                    'style-red': this.value.score >= ten && this.value.score <= fourteen,
                    'style-dark-red': this.value.score >= fiveteen
                };
            }
            return 'style-main';
        }
    }
</script>

<style>
    /*
        Приемаме, че тези цветове изглеждат прилично и на тъмна тема.
        Фиксира се и цветът на текста, защото иначе се инвертира на тъмна тема.
    */
    .style-dark-green {
        background-color: #4d9900;
        color: white;
    }

    .style-light-green {
        background-color: #80e033;
        color: black;
    }

    .style-yellow {
        background-color: #f0f000;
        color: black;
    }

    .style-orange {
        background-color: #ff9900;
        color: black;
    }

    .style-light-red {
        background-color: #fc0000;
        color: white;
    }

    .style-red {
        background-color: #cb0000;
        color: white;
    }

    .style-dark-red {
        background-color: #800000;
        color: white;
    }

    .style-main {
        background-color: transparent;
    }
</style>
