import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import {
    ExamDocumentType,
    VisitMenuSpecialtyAdditions,
    VisitMenuSpecialtyExclusions
} from '@/model/Exam/ExamDocumentType';
import { arrayUtil } from '@/util/ArrayUtil';

export const visitMenuService = {
    getAvailableMenuItemsForSpecialty(specialtyCode: SpecialtyCode | null): [string, string][] {
        const menuItems: [string, string][] = Object.entries(ExamDocumentType);
        if (specialtyCode) {
            const exclusions = VisitMenuSpecialtyExclusions[specialtyCode];
            if (exclusions) {
                for (const excludedDocumentTitle of exclusions) {
                    arrayUtil.removeFirst(menuItems, (item) => item[1] === excludedDocumentTitle);
                }
            }

            const additions = VisitMenuSpecialtyAdditions[specialtyCode];
            if (additions) {
                menuItems.push(...Object.entries(additions));
            }
        }

        return menuItems;
    }
};
