<template>
    <v-card>
        <v-card-title>Електронна рецептурна книжка</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="6" md="3">
                    <text-field v-model="model.prescriptionBookNumber" label="Рецептурна книжка номер" readonly />
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <btn v-if="canAddDiagnosis" action="New" :to="`/Patient/NhifPrescriptionBookDiagnosis/Create/${id}`">
                Добавяне на диагноза
            </btn>
            <nhif-prescription-book-check-button @input="loadPrescriptionBook" />
            <div class="ml-2"><nhif-prescription-history /></div>
        </v-card-actions>

        <data-table :headers="tableHeaders" :items="model.diagnoses" disable-pagination>
            <template #rowActions="{ item }">
                <btn
                    v-if="$allowWrite"
                    icon
                    action="Edit"
                    :to="`/Patient/NhifPrescriptionBookDiagnosis/Edit/${item.id}`"
                />
            </template>
        </data-table>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhifPrescriptionBookDto } from '@/model/Nhif/NhifPrescriptionBookDto';
    import { prescriptionBookService } from '@/service/Nhif/PrescriptionBookService';
    import { formatters } from '@/util/Formatters';

    import NhifPrescriptionBookCheckButton from './NhifPrescriptionBookCheckButton.vue';
    import NhifPrescriptionHistory from './NhifPrescriptionHistory.vue';

    @Component({
        components: { NhifPrescriptionBookCheckButton, NhifPrescriptionHistory }
    })
    export default class NhifPrescriptionBook extends Vue {
        @Prop()
        private id!: number;

        private model: NhifPrescriptionBookDto = new NhifPrescriptionBookDto();

        private tableHeaders: IDataTableHeader[] = [
            { text: 'МКБ:', value: 'icd' },
            {
                text: 'Дата на записване:',
                value: 'validFrom',
                formatter: formatters.date
            },
            {
                text: 'Дата на отписване:',
                value: 'validTo',
                formatter: formatters.date
            },
            {
                text: 'Бележка:',
                value: 'changeReason'
            }
        ];

        private get canAddDiagnosis() {
            const nhisBookLength = 8;
            return (
                this.$allowWrite &&
                this.model?.prescriptionBookNumber &&
                this.model?.prescriptionBookNumber?.length === nhisBookLength
            );
        }

        private mounted() {
            if (this.id) {
                this.loadPrescriptionBook();
            }
        }

        // Извиква се и в template-а след проверка на ЕРК в НЗОК.
        private async loadPrescriptionBook() {
            this.$loading.show();
            try {
                const response = await prescriptionBookService.getPrescriptionBookByPatientId(this.id);
                if (response?.data) {
                    this.model = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
