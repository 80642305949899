import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { stringify } from 'qs';

import { config } from '@/Config';
import { ExceptionDetailModel } from '@/model/Infrastructure/ExceptionDetailModel';
import { ServerErrorModel } from '@/model/Infrastructure/ServerErrorModel';
import { ValidationDetailModel } from '@/model/Infrastructure/ValidationDetailModel';
import { currentUser } from '@/store/User/CurrentUser';
import {
    replaceIsoDateStringsWithDates,
    serializeDatesWithoutZone,
    serializeDateWithoutZone
} from '@/util/AxiosDateUtil';

import { notifierService } from './NotifierService';

const axiosConfig: AxiosRequestConfig = {
    baseURL: config.mainApiBaseUrl,

    // Настройки за работа с дати. Виж коментара на serializeDateWithoutZone.
    // Форматиране на датите в query параметерите.
    paramsSerializer: {
        serialize: (params) => stringify(params, { serializeDate: serializeDateWithoutZone })
    },
    // Форматиране на датите в тялото на заявките.
    transformRequest: [serializeDatesWithoutZone, ...(axios.defaults.transformRequest as [])],
    // Parse-ване на датите от response-ите.
    transformResponse: [...(axios.defaults.transformResponse as []), replaceIsoDateStringsWithDates]

    //timeout: 60 * 1000,
    // Check cross-site Access-Control
    //withCredentials: true,
};

const axiosInstance = axios.create(axiosConfig);

const httpService = {
    get<T>(url: string, axiosRequestConfig?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axiosInstance.get(url, axiosRequestConfig);
    },

    delete<T>(url: string, axiosRequestConfig?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axiosInstance.delete(url, axiosRequestConfig);
    },

    post<T>(url: string, data: unknown, axiosRequestConfig: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        // Вариант 1 с вграденото сериализиране на axois.
        return axiosInstance.post(url, data, axiosRequestConfig);
        // Вариант 2 с custom сериализиране на датите чрез replacer функция. През 2023-04 беше заменено с transformRequest по-горе.
        //Object.assign(axiosRequestConfig, { headers: { 'Content-Type': 'application/json' } });
        //return axiosInstance.post<T>(
        //    url,
        //    JSON.stringify(data, axiosDateUtil.jsonStringifyDateReplacer),
        //    axiosRequestConfig
        //);
    },

    put<T>(url: string, data: unknown, axiosRequestConfig: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        return axiosInstance.put(url, data, axiosRequestConfig);
    },

    // Изтегляне на asp.net FileContentResult:  https://stackoverflow.com/questions/55138275/return-download-file-in-asp-net-core-api-from-axios-request
    // Извличане на името на файла: https://stackoverflow.com/questions/59274629/get-file-name-from-filestreamresult-when-using-ajax-axios
    // Декодиране на име на файл с кирилица: https://stackoverflow.com/questions/49412232/how-to-parse-content-disposition-headers-that-contain-utf-8-characters
    async download(url: string, axiosRequestConfig: AxiosRequestConfig = {}): Promise<void> {
        Object.assign(axiosRequestConfig, { responseType: 'blob' });
        const response = await axiosInstance.get(url, axiosRequestConfig);

        // Извлича името на файла и декодира кирилицата.
        let fileName = 'file.txt';
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
            const match = contentDisposition.match(/filename\*\s*=\s*UTF-8''(?<encodedFileName>.+)/iu);
            if (match?.groups) {
                const { encodedFileName } = match.groups;
                fileName = decodeURIComponent(encodedFileName);
            }
        }

        // Създава и щраква download link, който води към Blob обект.
        const contentType = response.headers['content-type'] ?? 'application/octet-stream';
        const href = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }
};

// Преди изпращане на заявката access token-ът, ако има такъв, се добавя в хедърите.
axiosInstance.interceptors.request.use(
    (requestConfig) => {
        // TODO: Изчакването на подновяването на сесията да се премести от StateRestorer тук,
        // но след като се маркират публичните action-и (за които не трябва потребител).
        //await currentUser.waitUntilAuthenticated();

        const { user } = currentUser;
        const accessToken = user?.access_token;
        if (requestConfig.headers && accessToken) {
            requestConfig.headers.Authorization = `Bearer ${accessToken}`;
        }
        return requestConfig;
    },
    (error) => {
        notifierService.showError('', error);
        return Promise.reject(error);
    }
);

// Error handling.

const statusCodeBadRequest = 400;
const statusCodeUnauthorized = 401;
const statusCodeServerError = 500;

const formatObject = (obj: unknown) => {
    const indent = 4;
    return JSON.stringify(obj, null, indent).replaceAll('\\n', '\r\n    ');
};

const parseAndShowServerError = (statusCode: number, data: ServerErrorModel) => {
    let errorTitle = '';
    let errorDetails = '';
    // По подразбиране asp.net валидира моделите по атрибутите им още преди да извика action-а
    // и връща 400 Bad Request ако има валидационни грешки. Вътре в data също има status: 400.
    // https://docs.microsoft.com/en-us/aspnet/core/web-api/?view=aspnetcore-6.0#default-badrequest-response
    if (statusCode === statusCodeBadRequest) {
        // Отговор от автоматичната валидация на asp.net. Полето errors има следния общ вид:
        // { FieldA: ['Проблем'], FieldB: ['Проблем 1', 'Проблем 2'] }
        const { errors } = data;
        errorTitle = Object.entries(errors as ValidationDetailModel)
            .map((field) => `${field[0]}: ${field[1].join('\r\n освен това ')}`)
            .join('\r\n');
    } else {
        // TODO: Симулация на отговора от AutoWrapper, който вече е махнат от проекта. Да се замени с ProblemDetails.
        // Detail съдържа съобщението за грешка; instance съдържа извикания URI.
        // errors съдържа съобщение, вид и стек на exception-а.
        const { detail, errors, instance } = data;
        if (detail || errors || instance) {
            errorTitle = detail;
            errorDetails = `Хипократ API: ${instance ?? 'няма'}`;
            if (errors) {
                const { message, type, source, raw } = errors as ExceptionDetailModel;
                // Иван: Тук добавих информация за inner exception-а.
                if (message) {
                    errorTitle = message;
                }
                errorDetails += `\r\n${type} от ${source}\r\n${raw}`;
            }
        } else {
            errorTitle = statusCode === statusCodeServerError ? 'Грешка' : `Статус ${statusCode}`;
            errorDetails = formatObject(data);
        }
    }
    notifierService.showError(errorTitle, errorDetails);
    return errorTitle;
};

const showUnauthorized = (cfg?: AxiosRequestConfig) => {
    const errorTitle = currentUser.isAuthenticated ? 'Страница с ограничен достъп' : 'Моля, влезте в системата.';
    if (cfg) {
        const apiUrl = `${cfg.baseURL}${cfg.url}`;
        notifierService.showWarning(errorTitle, `Нямате достъп до ${apiUrl}`);
    } else {
        notifierService.showWarning(errorTitle, '');
    }
    return errorTitle;
};

// Тук няма трансформации на response-а, а само error handling. Трансформациите са по-горе в transformResponse: [].
axiosInstance.interceptors.response.use(null, async (error: AxiosError<ServerErrorModel>) => {
    if (error.response?.data) {
        let { data } = error.response;
        // При изтегляне на файл, по-горе задаваме responseType: 'blob', но така Axois представя дори грешките като blob.
        // Авторите отказват да решат проблема, има workaround-и: https://github.com/axios/axios/issues/815
        // Blob-ът се прочита ръчно като json: https://stackoverflow.com/questions/56286368/how-can-i-read-http-errors-when-responsetype-is-blob-in-axios-with-vuejs
        if (error.config?.responseType === 'blob' && data instanceof Blob) {
            const text = await data.text();
            data = data.type?.toLowerCase().includes('json') ? JSON.parse(text) : text;
        }

        // При отговор с грешка, тя се показва в модален диалог (само 401 в toast) и се добавя в списъка с нотификации.
        // Оригиналният message се подменя със заглавието на диалога, за да се логне и в конзолата на браузъра.
        error.message = parseAndShowServerError(error.response.status, data);
    } else if (error.response?.status === statusCodeUnauthorized) {
        error.message = showUnauthorized(error.config);
    } else {
        // Exception-ите от Axios имат message, name, stack и config.
        notifierService.showError(error.message, formatObject(error));
    }

    return Promise.reject(error);
});

export { httpService };
