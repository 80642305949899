import { AxiosResponse } from 'axios';

import { RuleFilterDto } from '@/model/Exam/RuleFilterDto';
import { RiskGroupRegisterDto } from '@/model/Register/RiskGroup/RiskGroupRegisterDto';
import { RiskGroupRuleDto } from '@/model/Register/RiskGroup/RiskGroupRuleDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { userContextCache } from '@/store/User/UserContextCache';

export const riskGroupRegisterService = {
    getPatientRiskGroups(patientId: number): Promise<AxiosResponse<RiskGroupRegisterDto[]>> {
        return httpService.get(`/RiskGroupRegister/GetPatientRiskGroups/${patientId}`);
    },

    get(id: number): Promise<AxiosResponse<RiskGroupRegisterDto>> {
        return httpService.get(`/RiskGroupRegister/GetRiskGroupRegister/${id}`);
    },

    create(data: RiskGroupRegisterDto): Promise<AxiosResponse> {
        return httpService.post('/RiskGroupRegister/CreateRiskGroupRegister', data);
    },

    update(data: RiskGroupRegisterDto): Promise<AxiosResponse> {
        return httpService.put('/RiskGroupRegister/UpdateRiskGroupRegister', data);
    },

    getCurrentEmployeeRiskGroupRegisters(): Promise<AxiosResponse<RiskGroupRegisterDto[]>> {
        return httpService.get('RiskGroupRegister/GetRiskGroupRegistersByEmployee', {
            params: {
                practiceId: userContextCache.currentPracticeId,
                doctorEmployeeSeqNumber: userContextCache.currentDoctorEmployeeSeqNumber
            }
        });
    },

    exportToExcel(): Promise<void> {
        return httpService.download(`/RiskGroupRegister/ExportToExcel`, {
            params: {
                practiceId: userContextCache.currentPracticeId,
                doctorEmployeeSeqNumber: userContextCache.currentDoctorEmployeeSeqNumber
            }
        });
    },

    delete(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/RiskGroupRegister/DeleteRiskGroupRegister/${id}`);
    },

    searchRule(data: RuleFilterDto): Promise<AxiosResponse<RiskGroupRuleDto[]>> {
        return httpService.post('/RiskGroupRegister/SearchRule', data);
    }
};
