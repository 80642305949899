import { Type } from 'class-transformer';

import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

import { ExamCacheDto } from './ExamCacheDto';

export class VisitCacheDto {
    public id: number = 0;
    public patientVersionKey: PatientVersionKey = new PatientVersionKey();
    public doctorEmployeeSeqNumber: number = 0;
    public startDateTime: Date | null = null;

    public doctorId: number = 0;
    public doctorUin: string = '';
    public doctorNames: string = '';
    public doctorSpecialtyName: string = '';
    public doctorPhone: string = '';

    // Exam е null за посещение без преглед (само с БЛ или само с етапна епикриза).
    @Type(() => ExamCacheDto)
    public exam: ExamCacheDto | null = null;
}
