import { Type } from 'class-transformer';

import { Report365AbortionByAgeRowDto } from '@/model/Report/Rhi/Report365/Report365AbortionByAgeRowDto';

export class Report365AbortionByAgeDto {
    public name: string = '';

    @Type(() => Report365AbortionByAgeRowDto)
    public abortionsByAge: Report365AbortionByAgeRowDto[] = [];
}
