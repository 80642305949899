export enum AlgorithmTypeCode {
    // Амбулаторна процедура.
    AmbulatoryProcedure = 'A',
    // Клинична процедура преди НРД 2016.
    ClinicalProcedure2016 = 'J',
    // Клинична процедура.
    ClinicalProcedure = 'K',
    // Клинична пътека преди НРД 2016.
    ClinicalPathway2016 = 'O',
    // Клинична пътека.
    ClinicalPathway = 'P'
}
