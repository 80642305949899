import { Type } from 'class-transformer';

import { InformedConsentDiagnosticsProcedureDto } from './InformedConsentDiagnosticsProcedureDto';

export class InformedConsentDiagnosticsDto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();

    @Type(() => InformedConsentDiagnosticsProcedureDto)
    public procedures: InformedConsentDiagnosticsProcedureDto[] = [];
}
