<template>
    <v-card>
        <main-title>НЗИС изпращане на XML</main-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <text-field v-model="path" label="Релативен адрес на услугата" />
                </v-col>
                <v-col cols="12">
                    <v-checkbox v-model="authorizationNeeded" dense label="Подписване" />
                </v-col>
                <v-col cols="12">
                    <btn action="Send" :disabled="isLoading" @click="post">Изпращане</btn>
                </v-col>
                <v-col cols="12">
                    <v-textarea v-model="xml" label="Xml за изпращане" />
                </v-col>
                <v-col v-if="result" cols="12">
                    <v-textarea v-model="result" label="Резултат" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { nhisService } from '@/service/Nhis/NhisService';
    import { signXmlService } from '@/service/Report/SignXmlService';
    import { loadingState } from '@/store/LoadingState';

    @Component
    export default class NhisXmlSender extends Vue {
        private authorizationNeeded: boolean = false;
        private path: string = '';
        private xml: string = '';
        private result: string | null = null;

        private get isLoading() {
            return loadingState.isVisible;
        }

        private async post() {
            loadingState.show();
            try {
                const signXml = await signXmlService.signXml(this.xml);
                this.result = await nhisService.postDevXmlToNhis(
                    'PostXml',
                    this.authorizationNeeded ? (signXml.contents ?? '') : this.xml,
                    this.path,
                    this.authorizationNeeded
                );
            } finally {
                loadingState.hide();
            }
        }
    }
</script>
