import { plainToInstance } from 'class-transformer';

import { LicensingStatusDto } from '@/model/Licensing/LicensingStatusDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const licensingStatusService = {
    async getLicensingStatus(practiceId: number): Promise<LicensingStatusDto> {
        const response = await httpService.get<LicensingStatusDto>('/LicensingStatus/GetLicensingStatus', {
            params: { practiceId }
        });
        return plainToInstance(LicensingStatusDto, response.data);
    }
};
