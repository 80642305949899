<template>
    <multi-select-dropdown-search
        :value="value"
        :service="icdService"
        item-value="code"
        item-text="code"
        item-drop-text="name"
        label="МКБ кодове"
        @input="$emit('input', ...arguments)"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import MultiSelectDropdownSearch from '@/component/Dropdown/MultiSelectDropdownSearch.vue';
    import { icdService } from '@/service/Nomenclature/IcdService';

    @Component({ components: { MultiSelectDropdownSearch } })
    export default class IcdPickerMultiSelect extends Vue {
        @Prop()
        private value!: string[];

        private get icdService() {
            return icdService;
        }
    }
</script>
