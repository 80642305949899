import { AxiosResponse } from 'axios';

import { PregnancyDeregisterReasonDto } from '@/model/Nomenclature/PregnancyDeregisterReasonDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const pregnancyDeregisterReasonService = {
    getPregnancyDeregisterReasons(): Promise<AxiosResponse<PregnancyDeregisterReasonDto[]>> {
        return httpService.get('/PregnancyDeregisterReason/GetPregnancyDeregisterReasons');
    }
};
