<template>
    <v-form ref="documentForm" v-model="isValid" :disabled="!$allowWrite">
        <v-card>
            <v-card-title>Прикачване на файлове, без създаване на АЛ</v-card-title>
            <v-card-actions>
                <btn action="Upload" @click="createVisitWithFiles" />
            </v-card-actions>
            <v-card-text>
                <file-input v-model="uploadItems" :files-total-max-size="100" />
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { FileUploadCommand } from '@/model/File/FileUploadCommand';
    import { externalFileService } from '@/service/File/ExternalFileService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    import FileInput from './FileInput.vue';

    @Component({
        components: { FileInput }
    })
    export default class CreateVisitWithFiles extends Vue {
        private uploadItems: FileUploadCommand[] = [];
        private isValid: boolean = false;

        private async createVisitWithFiles() {
            this.$loading.show();
            try {
                const doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber;
                (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

                if (doctorEmployeeSeqNumber && this.isValid) {
                    const patient = currentPatientCache.value;
                    const visitId = await externalFileService.createVisitWithFiles(
                        patient.key.patientId,
                        doctorEmployeeSeqNumber,
                        this.uploadItems
                    );
                    // Параметърът documentId само се проверява дали е truthy (във VisitMenu.vue), така че не трябва да бъде нула.
                    const documentId = 123;
                    eventBus.$emit(
                        'create-referral-event',
                        documentId,
                        'FileUpload',
                        'Прикачен документ',
                        this.uploadItems.length
                    );
                    patient.hasFiles = true;
                    // Зареждането на новосъздаденото посещение подменя и link-овете в менюто с такива с visitId.
                    await currentVisitCache.load(visitId);
                    this.$router.push(`/Exam/FileUpload/${visitId}`);
                } else {
                    this.$notifier.showWarning(
                        this.$t('notifications.validationErrors').toString(),
                        this.$t('notifications.pleaseFixValidationErrors').toString()
                    );
                }
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
