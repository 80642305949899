<template>
    <span>{{ text }}</span>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { dateUtil } from '@/util/DateUtil';

    @Component
    export default class DateLabel extends Vue {
        @Prop()
        private value!: string | null;

        @Prop()
        private showTime!: boolean;

        private get text(): string | null {
            if (this.value) {
                return this.showTime ? dateUtil.formatBgDateTime(this.value) : dateUtil.formatBgDate(this.value);
            }
            return null;
        }
    }
</script>
