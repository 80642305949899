<template>
    <Editor :value="value" :init="init" @input="$emit('input', ...arguments)" />
</template>

<script lang="ts">
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'tinymce/tinymce';
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'tinymce/icons/default/icons';
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'tinymce/themes/silver/theme';
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'tinymce/models/dom/model';
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'tinymce/skins/ui/oxide/skin.css';
    // TinyMCE plugins
    // https://www.tiny.cloud/docs/tinymce/6/plugins/
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'tinymce/plugins/lists/plugin';
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'tinymce/plugins/link/plugin';
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'tinymce/plugins/image/plugin';
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'tinymce/plugins/table/plugin';
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'tinymce/plugins/code/plugin';
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'tinymce/plugins/help/plugin';
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'tinymce/plugins/wordcount/plugin';

    import Editor from '@tinymce/tinymce-vue';
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component({
        components: { Editor }
    })
    export default class RichText extends Vue {
        @Prop()
        private value!: string;

        // eslint-disable-next-line @typescript-eslint/typedef
        private init = {
            skin: false,
            plugins: 'lists link image table code help wordcount'
        };
    }
</script>
