import { DiagnosisDto } from '@/model/Common/DiagnosisDto';
import { ResultReferralPerformerDto } from '@/model/Exam/Referral/ResultReferral/ResultReferralPerformerDto';
import { ResultReferralProcedureDto } from '@/model/Exam/Referral/ResultReferral/ResultReferralProcedureDto';

export class NhisExamExtractionDto {
    public nrn: string = '';
    public lrn: string = '';
    public status: string = '';
    public isIncidental: boolean = false;
    public isSecondary: boolean = false;
    public nhifVisitReason: string = '';
    public diagnosis: DiagnosisDto = new DiagnosisDto();
    public comorbiditiesNames: DiagnosisDto[] = [];
    public medicalHistory: string = '';
    public objectiveCondition: string = '';
    public assessment: string = '';
    public therapy: string = '';
    public performerInfo: ResultReferralPerformerDto = new ResultReferralPerformerDto();
    public sickLeaveNumber: string = '';
    public sickLeaveDays: string = '';
    public sickLeaveFrom: Date = new Date();
    public sickLeaveTo: Date = new Date();
    public openDate: Date | null = null;
    public closeDate: Date | null = null;
    public procedures: ResultReferralProcedureDto[] = [];
}
