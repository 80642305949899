import { plainToInstance } from 'class-transformer';

import { VisitCacheDto } from '@/model/Exam/Cache/VisitCacheDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const visitCacheService = {
    async getVisitCacheById(visitId: number): Promise<VisitCacheDto> {
        const response = await httpService.get<VisitCacheDto>(`/VisitCache/GetVisitCacheById/${visitId}`);
        return plainToInstance(VisitCacheDto, response.data);
    }
};
