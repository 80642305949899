import { plainToInstance } from 'class-transformer';

import { BgDoctorDto } from '@/model/BgDoctor/BgDoctorDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const bgDoctorService = {
    async getBgDoctorByUin(uin: string): Promise<BgDoctorDto> {
        const response = await httpService.get<BgDoctorDto>(`/BgDoctor/GetBgDoctorByUin/${uin}`, {
            params: { uin }
        });
        return plainToInstance(BgDoctorDto, response.data);
    }
};
