import { Type } from 'class-transformer';

import { RegulatoryStandardDoctorDto } from './RegulatoryStandardDoctorDto';

export class RegulatoryStandardDto {
    public practiceId: number = 0;
    public seqNumber: number = 0;
    public practiceRhifId: number = 0;
    public practiceManagerName: string = '';
    public number: number = 0;
    public contractNumber: string = '';
    public startDate: Date | null = null;
    public endDate: Date | null = null;
    public yearQuarter: number = 0;

    @Type(() => RegulatoryStandardDoctorDto)
    public regStandardDoctors: RegulatoryStandardDoctorDto[] = [];

    public sumField(propertyName: keyof RegulatoryStandardDoctorDto) {
        let sum: number = 0;
        for (const regStandardDoctor of this.regStandardDoctors) {
            sum += Number(regStandardDoctor[propertyName]);
        }
        return sum;
    }

    public get sumCountReferral3Type124() {
        return this.sumField('countReferral3Type124');
    }

    public get sumCountReferral3Type7() {
        return this.sumField('countReferral3Type7');
    }

    public get sumWorthReferral4AllTypes() {
        return this.sumField('worthReferral4AllTypes');
    }

    public get sumWorthReferral4Mri() {
        return this.sumField('worthReferral4Mri');
    }

    public get sumCountReferral3aType4() {
        return this.sumField('countReferral3aType4');
    }

    public get sumCountReferral3aAllTypes() {
        return this.sumField('countSpecialistReferral3aAllTypes');
    }
}
