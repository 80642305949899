import { UserAgreementDto } from '@/model/User/UserAgreementDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const userAgreementService = {
    async checkForUserAgreement(data: UserAgreementDto): Promise<boolean> {
        const response = await httpService.post<boolean>('UserAgreement/CheckForUserAgreement', data);
        return response?.data;
    },

    async createUserAgreement(data: UserAgreementDto): Promise<void> {
        await httpService.post('UserAgreement/CreateUserAgreement', data);
    }
};
