import { AxiosResponse } from 'axios';

import { GenderDto } from '@/model/Nomenclature/GenderDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const genderService = {
    getGenders(): Promise<AxiosResponse<GenderDto[]>> {
        return httpService.get('/Gender/GetGenders');
    }
};
