<template>
    <data-table
        :headers="headers"
        title="Удостоверение за извършен предсъстезателен физикален медицински преглед"
        :items="items"
    >
        <template #actions>
            <btn action="New" :to="`/Exam/CertificatePhysicalExam/Create/${examId}`" />
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/Exam/CertificatePhysicalExam/Edit/${item.id}`" />
            <btn icon action="Print" :to="`/Print/Exam.Certificate.CertificatePhysicalExam/${item.id}`" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { CertificatePhysicalExamViewDto } from '@/model/Exam/Certificate/CertificatePhysicalExamViewDto';
    import { certificatePhysicalExamService } from '@/service/Exam/Certificate/CertificatePhysicalExamService';
    import { formatters } from '@/util/Formatters';

    @Component({})
    export default class CertificatePhysicalExamView extends Vue {
        @Prop()
        examId!: number;

        private items: CertificatePhysicalExamViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата на състезанието:', value: 'competitionDate', formatter: formatters.date },
                { text: 'Състезание:', value: 'competitionName' },
                { text: 'Място на провеждане:', value: 'competitionLocation' },
                { text: 'Вид спорт:', value: 'sportType' },
                { text: 'Разрешено участие:', value: 'isSuitable' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                this.items = await certificatePhysicalExamService.getByExam(this.examId);
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>

<style scoped></style>
