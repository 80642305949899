<template>
    <div>
        <v-card-title class="pb-0">
            <v-row>
                <v-col cols="auto">
                    {{ userContextCache.userContexts.length ? 'Изберете' : 'Добавете' }} месторабота
                </v-col>

                <!-- Двата странични v-col-а са с минимална ширина чрез cols="auto", а централният v-col заема цялто място по средата. -->
                <v-col>
                    <text-field
                        v-if="isGlobalAdmin"
                        v-model="filter.containsText"
                        label="Търсене"
                        :rules="[$validator.minLength(minimumFilterLength)]"
                        @input="onFilterChanged"
                    />
                </v-col>

                <v-col v-if="hasSearchResult" cols="auto">
                    <value-loading :value="searchTimeoutId" class="mr-4" />
                    <small>{{ searchResultCountTitle }}</small>
                </v-col>
                <v-col v-else cols="auto">
                    <!-- По време на търсене или презареждане на UserContext-ите се показва loading вместо иконка. Бутонът е само за подравняване. -->
                    <v-btn v-if="searchTimeoutId || userContextCache.isLoading" icon>
                        <value-loading :value="true" />
                    </v-btn>
                    <v-tooltip v-else bottom>
                        <template #activator="{ on }">
                            <v-btn icon v-on="on" @click="reloadUserSettingsAndContextsFromServer">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        Презареждане на списъка от сървъра
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <btn
                                icon
                                action="New"
                                to="/Registration"
                                color="primary"
                                v-on="on"
                                @click="$emit('navigated')"
                            />
                        </template>
                        Добавяне на месторабота
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-text>
            <!-- Резултат от търсене, изпълнено от глобален администратор. -->
            <v-data-table
                v-if="isGlobalAdmin && hasSearchResult"
                v-model="selectedFoundUserContext"
                :headers="headers"
                :items="searchResult.userContexts"
                item-key="index"
                single-select
                disable-pagination
                hide-default-footer
                fixed-header
                :height="searchResult.userContexts.length > 6 ? '340px' : null"
            >
                <!--
                    disable-pagination и hide-default-footer показват всички редове.
                    fixed-header и height скролират данните ако са твърде много.
                -->
                <template #item="{ item, isSelected, select }">
                    <tr @click="toggle(item, isSelected, select)">
                        <td>{{ item.gridPractice }}</td>
                        <td>{{ item.gridEmployee }}</td>
                        <td>{{ item.gridSpecialty }}</td>
                        <td>{{ item.gridDeputy }}</td>
                        <td><v-icon v-if="item.isPracticeOwner">mdi-check</v-icon></td>
                        <td>{{ item.gridUsers }}</td>
                    </tr>
                </template>
            </v-data-table>

            <!-- UserContext-и на текущия потребител. -->
            <v-data-table
                v-else
                v-model="selectedUserContext"
                :headers="headers"
                :items="userContextCache.userContexts"
                item-key="index"
                single-select
                disable-pagination
                hide-default-footer
                fixed-header
                :height="userContextCache.userContexts.length > 6 ? '340px' : null"
            >
                <template #item="{ item, isSelected, select }">
                    <tr :class="isSelected ? 'success2' : ''" @click="toggle(item, isSelected, select)">
                        <td>{{ item.gridPractice }}</td>
                        <td>{{ item.gridEmployee }}</td>
                        <td>{{ item.gridSpecialty }}</td>
                        <td>{{ item.gridDeputy }}</td>
                        <td><v-icon v-if="item.isPracticeOwner">mdi-check</v-icon></td>
                        <td>{{ item.gridUsers }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import ValueLoading from '@/component/Common/ValueLoading.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { UserContextDto } from '@/model/User/UserContext/UserContextDto';
    import { UserContextFilterCommand } from '@/model/User/UserContext/UserContextFilterCommand';
    import { UserContextSearchResultDto } from '@/model/User/UserContext/UserContextSearchResultDto';
    import { userContextService } from '@/service/User/UserContextService';
    import { currentUser } from '@/store/User/CurrentUser';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';

    const minimumFilterLength: number = 2;
    const searchDelay = 1000;

    @Component({
        components: { ValueLoading }
    })
    export default class UserContextPicker extends Vue {
        private get isGlobalAdmin() {
            return currentUser.isGlobalAdmin;
        }

        private get userContextCache() {
            return userContextCache;
        }

        private headers: IDataTableHeader[] = [
            { text: 'Практика', value: 'gridPractice' },
            { text: 'Лекар', value: 'gridEmployee' },
            { text: 'Специалност', value: 'gridSpecialty' },
            { text: 'Зам. или нает', value: 'gridDeputy' },
            // Ширината е голяма, за да не се wrap-ва стрелката за сортиране.
            { text: 'Админ', value: 'isPracticeOwner', width: 92 },
            { text: 'Други потребители', value: 'gridUsers' }
        ];

        private toggle(item: UserContextDto, isSelected: boolean, select: (value: boolean) => void) {
            if (!item.currentUserIsPending) {
                select(!isSelected);
            }
        }

        private get selectedUserContext() {
            return [userContextCache.current];
        }

        private set selectedUserContext(selection) {
            if (this.isGlobalAdmin) {
                // Отказва последното търсене, ако има такова, за да не се появи резултат от търсене след като
                // е бил избран UserContext (от текущия потребител или от резултат от предишно търсене).
                this.exitSearch();
                // Почиства филтъра за търсене, за да не заблуждава, че UserContext-ите на текущия потребител отговарят на филтъра.
                this.filter.containsText = '';
            }

            if (selection.length) {
                const [selected] = selection;
                if (selected) {
                    userContextCache.current = selected;
                    userSettingsState.loadFromServer();
                    // При всяка промяна на userContext-а пренасочва към началната страница.
                    // Ако тя вече е заредена, не пренасочва, за да не излиза грешка в конзолата 'NavigationDuplicated'.
                    const path = '/';
                    if (this.$route.path !== path) {
                        this.$router.push(path);
                    }
                } else {
                    userContextCache.current = null;
                }
            } else {
                userContextCache.current = null;
            }
        }

        // Зарежда паралелно настройките и UserContext-ите на потребителя.
        // Този компонент е само за UserContext-и, но бутонът е удобен за презареждане на всичко, свързано с потребителя.
        private reloadUserSettingsAndContextsFromServer() {
            userSettingsState.loadFromServer();
            userContextCache.loadFromServer();
        }

        // Търсене на user context-и.
        private filter: UserContextFilterCommand = new UserContextFilterCommand();
        private minimumFilterLength: number = minimumFilterLength;
        private searchTimeoutId: number = 0;
        private searchResult: UserContextSearchResultDto = new UserContextSearchResultDto();
        private _selectedFoundUserContext: UserContextDto | null = null;

        private onFilterChanged() {
            // Подава заявка за търсене само ако поне една от търсените думи е дълга поне 2 знака.
            const hasFilter = this.filter.containsText
                .trim()
                .split(/\s+/u)
                .some((word) => word.length >= minimumFilterLength);

            if (hasFilter) {
                // Отказва последното насрочено търсене и насрочва ново 1 секунда след последния въведен символ.
                clearTimeout(this.searchTimeoutId);

                const searchTimeoutId = setTimeout(async () => {
                    try {
                        const searchResult = await userContextService.searchUserContexts(this.filter);
                        // Пристигнал е резултат, но той се игнорира, ако междувременно е било насрочено друго търсене.
                        if (this.searchTimeoutId === searchTimeoutId) {
                            this.searchResult = searchResult;
                        }
                    } finally {
                        if (this.searchTimeoutId === searchTimeoutId) {
                            this.searchTimeoutId = 0;
                        }
                    }
                }, searchDelay);
                this.searchTimeoutId = searchTimeoutId;
            } else {
                this.exitSearch();
            }
        }

        private exitSearch() {
            // Отказва последното насрочено търсене, ако има такова.
            clearTimeout(this.searchTimeoutId);
            // Скрива loading анимацията и игнорира евентуалния резултат от някое започнало търсене.
            this.searchTimeoutId = 0;
            // Скрива резултата от търсене.
            this.searchResult = new UserContextSearchResultDto();
        }

        private get hasSearchResult() {
            return this.searchResult.totalCount !== null;
        }

        private get searchResultCountTitle() {
            const actualCount = this.searchResult.userContexts.length ?? 0;
            return this.hasSearchResult
                ? actualCount < (this.searchResult.totalCount ?? 0)
                    ? `Показани ${actualCount} от общо ${this.searchResult.totalCount} бр.`
                    : `Общо ${actualCount} бр.`
                : '';
        }

        private get selectedFoundUserContext() {
            return [this._selectedFoundUserContext];
        }

        private set selectedFoundUserContext(selection) {
            if (selection.length) {
                const [selected] = selection;
                if (selected) {
                    this.selectedUserContext = [userContextCache.findOrPrependUserContext(selected)];
                }
            }
        }
    }
</script>
