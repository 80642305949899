import { Route, RouteConfig } from 'vue-router';

export const examCertificateRoutes: RouteConfig[] = [
    {
        path: 'ECertificateDrive/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateDriveNhis.vue')
        }
    },
    {
        path: 'ECertificateDrive/Edit/:id',
        props: {
            exam: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateDriveNhis.vue')
        }
    },
    {
        path: 'ECertificateDrive/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateDriveNhisView.vue')
        }
    },
    {
        path: 'CertificateDrive/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateDrive.vue')
        }
    },
    {
        path: 'CertificateDrive/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateDrive.vue')
        }
    },
    {
        path: 'CertificateDrive/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateDriveView.vue')
        }
    },
    {
        path: 'CertificateSocialServices/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateSocialServices.vue')
        }
    },
    {
        path: 'CertificateSocialServices/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateSocialServices.vue')
        }
    },
    {
        path: 'CertificateSocialServices/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateSocialServicesView.vue')
        }
    },
    {
        path: 'CertificatePhysicalExam/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificatePhysicalExam.vue')
        }
    },
    {
        path: 'CertificatePhysicalExam/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificatePhysicalExam.vue')
        }
    },
    {
        path: 'CertificatePhysicalExam/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificatePhysicalExamView.vue')
        }
    },
    {
        path: 'CertificateWork/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateWork.vue')
        }
    },
    {
        path: 'CertificateWork/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateWork.vue')
        }
    },
    {
        path: 'CertificateWork/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateWorkView.vue')
        }
    },
    {
        path: 'CertificateMariage/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateMariage.vue')
        }
    },
    {
        path: 'CertificateMariage/Edit/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateMariage.vue')
        }
    },
    {
        path: 'CertificateMariage/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/CertificateMariageView.vue')
        }
    },
    {
        path: 'MedicalNote/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/MedicalNote.vue')
        }
    },
    {
        path: 'MedicalNote/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/MedicalNote.vue')
        }
    },
    {
        path: 'MedicalNote/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Certificate/MedicalNoteView.vue')
        }
    },
    {
        path: 'OfficialNotice/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId), documentId: null })
        },
        components: {
            exam: () => import('@/view/Exam/OfficialNotice/OfficialNotice.vue')
        }
    },
    {
        path: 'OfficialNotice/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId), examId: null })
        },
        components: {
            exam: () => import('@/view/Exam/OfficialNotice/OfficialNotice.vue')
        }
    },
    {
        path: 'OfficialNotice/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/OfficialNotice/OfficialNoticeView.vue')
        }
    },
    {
        path: 'Anthropometry/Create/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId), documentId: null })
        },
        components: {
            exam: () => import('@/view/Exam/Anthropometry/Anthropometry.vue')
        }
    },
    {
        path: 'Anthropometry/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId), examId: null })
        },
        components: {
            exam: () => import('@/view/Exam/Anthropometry/Anthropometry.vue')
        }
    },
    {
        path: 'Anthropometry/:examId',
        props: {
            exam: (route: Route) => ({ examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Anthropometry/AnthropometryView.vue')
        }
    }
];
