import { plainToInstance } from 'class-transformer';

import { MedicalNoteReasonDto } from '@/model/Nomenclature/MedicalNoteReasonDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const medicalNoteReasonService = {
    async getMedicalNoteReasons(): Promise<MedicalNoteReasonDto[]> {
        const response = await httpService.get<MedicalNoteReasonDto[]>('/MedicalNoteReason/GetMedicalNoteReasons');
        return plainToInstance(MedicalNoteReasonDto, response?.data);
    }
};
