import { render, staticRenderFns } from "./ExamDurationSetting.vue?vue&type=template&id=bee3e528&scoped=true"
import script from "./ExamDurationSetting.vue?vue&type=script&lang=ts"
export * from "./ExamDurationSetting.vue?vue&type=script&lang=ts"
import style0 from "./ExamDurationSetting.vue?vue&type=style&index=0&id=bee3e528&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bee3e528",
  null
  
)

export default component.exports