<template>
    <div>
        <v-row v-for="(file, index) in value" :key="index">
            <v-col class="d-flex" cols="12">
                <v-col cols="6">
                    <v-file-input
                        show-size
                        label="Файл"
                        dense
                        :error="isOverSize"
                        :rules="[$validator.required]"
                        @change="onFileInput($event, index)"
                        @click:clear="clearFile(index)"
                    />
                </v-col>
                <v-col cols="6"><text-field v-model="file.description" label="Описание"></text-field></v-col>
                <btn v-if="value.length > 1" icon action="Delete" @click="removeFile(index)" />
            </v-col>
        </v-row>
        <btn icon action="New" @click="addFileInput" />
        <alert :value="isOverSize" class="mt-3" type="warning">
            Общата големина на файловете е по-голяма от максимално допустимата ({{ filesTotalMaxSize }} мв).
        </alert>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { FileUploadCommand } from '@/model/File/FileUploadCommand';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component
    export default class FileInput extends Vue {
        @Prop({ default: [] })
        private value!: FileUploadCommand[];

        @Prop()
        private practiceId!: number | null;

        @Prop()
        private patientId!: number | null;

        @Prop()
        private visitId!: number | null;

        //Залагаме по подразбиране максималната обща големи на файловете да бъде 50 мб
        @Prop({ default: 50 })
        private filesTotalMaxSize!: number;

        private totalSize: number = 0;

        private get isOverSize() {
            const mbToByte = 1048576;
            return this.totalSize > this.filesTotalMaxSize * mbToByte;
        }

        private addFileInput(): void {
            const file = new FileUploadCommand(this.practiceId, this.patientId, this.visitId);
            this.value.push(file);
        }

        private removeFile(index: number) {
            this.totalSize -= this.value[index]?.content?.length ?? 0;
            arrayUtil.removeAt(this.value, index);
        }

        private onFileInput(file: File, index: number) {
            if (file) {
                this.$loading.show();

                this.totalSize += file.size;

                const currentFile = this.value[index];
                currentFile.fileName = file.name;

                this.getFileContent(file).then((resolve) => {
                    currentFile.content = resolve;
                    this.$loading.hide();
                });
            }
        }

        private getFileContent(fileContent: File) {
            return new Promise<number[]>((resolve) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(fileContent);

                fileReader.onload = () => {
                    const array = new Uint8Array(fileReader.result as ArrayBuffer);
                    const byteArray: number[] = [];

                    for (let index = 0; index < array.length; index++) {
                        byteArray.push(array[index]);
                    }
                    resolve(byteArray);
                };
            });
        }

        private clearFile(index: number) {
            this.value[index] = new FileUploadCommand(this.practiceId, this.patientId, this.visitId);
        }

        private mounted() {
            if (this.value.length === 0) {
                this.addFileInput();
            }
        }
    }
</script>
