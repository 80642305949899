<!-- Цял екран с натрупаните нотификации. -->
<template>
    <v-dialog v-model="isVisible" fullscreen hide-overlay transition="dialog-bottom-transition" click:outside="close">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>Съобщения</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <btn v-if="hasNotifications" icon action="Delete" @click.stop="clearNotificationsAndClose">
                        Изтриване на всички
                    </btn>
                    <btn icon action="Close" @click.stop="close" />
                </v-toolbar-items>
            </v-toolbar>

            <div v-for="(notifications, category) in groupedByCategory" :key="category">
                <h2 class="ma-3 text-center">
                    {{ category }}
                </h2>

                <NotificationList :items="notifications" show-grouped show-sort-options />
            </div>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { notifications } from '@/store/Notifications';
    import NotificationList from '@/view/Notification/NotificationList.vue';

    @Component({
        components: { NotificationList }
    })
    export default class NotificationsDialog extends Vue {
        private get isVisible() {
            return notifications.dialogIsVisible;
        }

        private set isVisible(isVisible: boolean) {
            notifications.dialogIsVisible = isVisible;
        }

        private close() {
            notifications.dialogIsVisible = false;
        }

        private get groupedByCategory() {
            return notifications.groupedByCategory;
        }

        private mounted() {
            notifications.markAllAsRead();
        }

        private clearNotificationsAndClose() {
            notifications.clear();
            this.close();
        }

        private get hasNotifications(): boolean {
            return this.groupedByCategory && Object.keys(this.groupedByCategory).length > 0;
        }
    }
</script>
