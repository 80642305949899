import { AxiosResponse } from 'axios';

import { ExamQuestionDto } from '@/model/Nomenclature/ExamQuestionDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const examQuestionService = {
    getExamQuestionByType(examQuestionTypeCode: string): Promise<AxiosResponse<ExamQuestionDto[]>> {
        return httpService.get('/ExamQuestion/GetExamQuestionsByType', {
            params: {
                examQuestionTypeCode
            }
        });
    },

    getExamQuestions(): Promise<AxiosResponse<ExamQuestionDto[]>> {
        return httpService.get('/ExamQuestion/GetExamQuestions');
    }
};
