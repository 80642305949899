import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

export class NhisPatientImmunizationExtractionCommand {
    public patientVersionKey: PatientVersionKey = new PatientVersionKey();
    public doctorUin: string = '';
    public deputyDoctorUin: string | null = null;
    public occurrenceDate: Date | null = new Date();
    public nhisVaccineCode: string | null = null;
    public nrn: string | null = null;
    public doseNumber: number | null = null;
}
