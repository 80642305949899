export class PracticeUnitDto {
    public practiceId: number = 0;
    public seqNumber: number = 0;
    public parentSeqNumber: number | null = null;
    public name: string = '';
    public location: string = '';
    public icon: string = '';
    public children: PracticeUnitDto[] = [];
    public isLaboratory: boolean = false;
    public isWard: boolean = false;
    public isRoom: boolean = false;
    public isAdmissionUnit: boolean = false;
    public isOther: boolean = false;
}
