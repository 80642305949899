export class ConsultationTelkDto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date = new Date();
    public number: number | null = null;
    public consultationCabinet: string = '';
    public symptoms: string = '';
    public condition: string = '';
    public diagnosis: string = '';
    public notes: string = '';
}
