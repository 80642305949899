export class ExamDiagnosisDto {
    public id: number | null = null;
    public icdCode: string = '';
    public icdCodeAdd: string | null = null;
    public icdName: string = '';
    public icdNameAdd: string | null = null;

    public get bothIcdCodes(): string {
        if (this.icdCode) {
            return this.icdCodeAdd ? `${this.icdCode}; ${this.icdCodeAdd}` : this.icdCode;
        }
        return '';
    }
}
