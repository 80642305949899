import { Type } from 'class-transformer';

import { Report365AbortionRowDto } from '@/model/Report/Rhi/Report365/Report365AbortionRowDto';

export class Report365AbortionDto {
    public name: string = '';

    @Type(() => Report365AbortionRowDto)
    public abortions: Report365AbortionRowDto[] = [];
}
