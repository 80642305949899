<template>
    <data-table
        title="Опис към месечния отчет на платежни документи"
        :headers="tableHeaders"
        :items="items.lines"
        disable-pagination
    >
        <template #actions>
            Всичко общо: {{ items.total }} бр.
            <v-spacer />
            <btn icon action="Close" @click="$emit('close-dialog')" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { FiscalReceiptReportDto } from '@/model/Report/Pis/FiscalReceipt/FiscalReceiptReportDto';

    @Component({
        components: {}
    })
    export default class FiscalReceiptReportLines extends Vue {
        @Prop()
        items!: FiscalReceiptReportDto;

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Номер на касова бележка:', value: 'fiscalNumber' },
                { text: 'Идентификатор:', value: 'identifier' },
                { text: 'Имена:', value: 'name' },
                { text: 'Амбулаторен лист:', value: 'ambulatorySheetNumber' },
                { text: 'НРН:', value: 'nrn' }
            ];
        }
    }
</script>
