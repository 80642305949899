import { plainToInstance } from 'class-transformer';

import { ExamCreatedDto } from '@/model/Exam/ExamCreatedDto';
import { PatientPreventiveDataDto } from '@/model/Patient/PatientPreventiveDataDto';
import { examService } from '@/service/Exam/ExamService';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientPreventiveVisitService = {
    async getPreventiveActivities(
        patientId: number,
        doctorEmployeeSeqNumber: number
    ): Promise<PatientPreventiveDataDto> {
        const response = await httpService.get<PatientPreventiveDataDto>(
            `/PatientPreventiveVisit/GetPreventiveActivities`,
            {
                params: { patientId, doctorEmployeeSeqNumber }
            }
        );
        return plainToInstance(PatientPreventiveDataDto, response.data);
    },

    async savePreventiveVisit(patientData: PatientPreventiveDataDto): Promise<number> {
        const response = await httpService.post<ExamCreatedDto>(
            `/PatientPreventiveVisit/SavePreventiveVisit`,
            patientData
        );
        const result = plainToInstance(ExamCreatedDto, response.data);
        examService.showExamCreatedWarnings(result);
        return result.examId;
    }
};
