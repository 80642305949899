<template>
    <div>
        <nhis-nhif-access-token-card :token-type-code="nhisKey" external-system-name="НЗИС" />
        <nhis-nhif-access-token-card class="mt-3" :token-type-code="nhifKey" external-system-name="НЗОК" />
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { nhifKey, nhisKey } from '@/store/NhisNhifAccessTokenCache';

    import NhisNhifAccessTokenCard from './NhisNhifAccessTokenCard.vue';

    @Component({
        components: { NhisNhifAccessTokenCard }
    })
    export default class NhisNhifAccessToken extends Vue {
        private get nhisKey() {
            return nhisKey;
        }

        private get nhifKey() {
            return nhifKey;
        }
    }
</script>
