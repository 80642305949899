import { plainToInstance } from 'class-transformer';

import { ServiceOrderFinancialReportCommand } from '@/model/Query/Reception/ServiceOrderFinancialReportCommand';
import { ServiceOrderSummarizedFinancialReportDto } from '@/model/Query/Reception/ServiceOrderSummarizedFinancialReportDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const serviceOrderSummarizedReportService = {
    async getSummarizedFinancialReport(
        command: ServiceOrderFinancialReportCommand
    ): Promise<ServiceOrderSummarizedFinancialReportDto[]> {
        const response = await httpService.post<ServiceOrderSummarizedFinancialReportDto[]>(
            'ServiceOrderSummarizedReport/GetSummarizedFinancialReport',
            command
        );
        return plainToInstance(ServiceOrderSummarizedFinancialReportDto, response?.data);
    },

    exportDataSummarizedReport(command: ServiceOrderFinancialReportCommand): Promise<void> {
        return httpService.download('/ServiceOrderSummarizedReport/ExportExcelSummarizedReport', { params: command });
    }
};
