<template>
    <div>
        <v-card>
            <v-card-title>Изпращане на Pdf файл към ПИС (Спецификация)</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <report-date-picker v-model="filter" />
                    </v-col>
                    <v-col cols="6">
                        <practice-nhif-contract-picker v-model="nhifContract" :practice-id="practiceId" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-file-input
                            show-size
                            accept="application/pdf"
                            label="Файл"
                            dense
                            :rules="[$validator.required]"
                            @change="onFileInput($event)"
                            @click:clear="clearFile"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="hasFile" cols="12" md="4">
                        <btn action="Send" block :disabled="isLoading" @click="sendFile"> Изпращане на файл </btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <Dialog
                v-model="resultDialogIsVisible"
                :text="dialogText"
                color="primary"
                :title="'Резултат от изпращане'"
            ></Dialog>
        </v-card>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { PisUploadType } from '@/enum/LocalServer/PisUploadType';
    import { PisFileTypeCode } from '@/enum/Report/Pis/PisFileTypeCode';
    import { PracticeNhifContractDto } from '@/model/Practice/PracticeNhifContractDto';
    import { PisFileUploadRequestDto } from '@/model/Report/Pis/PisFileUploadRequestDto';
    import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { localServerPisService } from '@/service/Report/LocalServerPisService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';
    import PracticeNhifContractPicker from '@/view/Report/Pis/PracticeNhifContractPicker.vue';

    @Component({
        components: { PracticeNhifContractPicker, ReportDatePicker }
    })
    export default class SpecificationReport extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private filter: ReportIntervalFilterDto = new ReportIntervalFilterDto();
        private nhifContract: PracticeNhifContractDto = new PracticeNhifContractDto();
        private resultDialogIsVisible: boolean = false;
        private dialogText: string = '';
        private fileName: string | null = null;
        private fileContent: string | null = null;

        private mounted() {
            this.filter.fillPreviousMonth();
        }

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private get hasFile() {
            return this.fileContent && this.fileName;
        }

        private clearFile() {
            this.fileContent = null;
            this.fileName = null;
        }

        private onFileInput(file: File) {
            if (file) {
                this.$loading.show();
                this.fileName = file.name;
                const fileReader = new FileReader();
                fileReader.onload = (fileLoadedEvent) => {
                    const result = fileLoadedEvent?.target?.result as string;
                    this.fileContent = result.split(',')[1] ?? '';
                    // Print data in console
                    console.log(this.fileContent);
                };
                fileReader.readAsDataURL(file);
                this.$loading.hide();
            }
        }

        private async sendFile() {
            if (await localServerDiagnosticService.checkIsLocalServerActive()) {
                this.$loading.show();
                try {
                    const uploadRequest = new PisFileUploadRequestDto(
                        this.fileContent ?? '',
                        userContextCache.current?.practiceNumber ?? '',
                        userContextCache.current?.doctorIdentifier ?? ''
                    );
                    this.fillRequestData(uploadRequest);
                    uploadRequest.fileName = this.fileName;

                    //Изпращаме файла като FiscalReceiptReport или FiscalReport, защото двата вида са от тип спецификация,
                    // а чрез PisUploadType определяме кой метод от локалния сървър да използваме. Понеже всички финансови документи
                    // се изпращат като спецификация, използваме FinancialReport
                    const response = await localServerPisService.uploadReport(
                        PisFileTypeCode.FiscalReceiptReport,
                        uploadRequest,
                        PisUploadType.FinancialReport
                    );
                    if (!response.isError) {
                        this.dialogText = `${response.message} Идентификаторът на документа е: ${response.additionalInformation}`;
                        this.resultDialogIsVisible = true;
                    } else {
                        this.$notifier.showError('Грешка при изпращане', `${response.message}`);
                    }
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private fillRequestData(requestData: PisFileUploadRequestDto) {
            requestData.startDate = dateUtil.formatXmlDate(this.filter.startDate);
            requestData.endDate = dateUtil.formatXmlDate(this.filter.endDate);
            requestData.docDate = dateUtil.formatXmlDate(this.filter.endDate);
            requestData.contractNumber = this.nhifContract.contractNumber;
            requestData.contractDate = dateUtil.formatXmlDate(this.nhifContract.contractDate);
            requestData.contractType = parseInt(this.nhifContract.nhifContractTypeCode, 10);
        }
    }
</script>

<style scoped></style>
