import { Type } from 'class-transformer';

import { UserContextDto } from './UserContextDto';

export class UserContextSearchResultDto {
    @Type(() => UserContextDto)
    public userContexts: UserContextDto[] = [];

    // Стойност null показва, че не сме в режим на търсене или резултатът от търсене още не е пристигнал,
    // съответно се виждат UserContext-ите на текущия потребител.
    public totalCount: number | null = null;
}
