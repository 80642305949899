<template>
    <v-card-text>
        <v-row>
            <v-col class="py-0">
                <dropdown
                    v-model="value.creditToRoomSeqNumber"
                    :items="practiceRooms"
                    item-value="seqNumber"
                    clearable
                    label="Кабинет"
                ></dropdown>
            </v-col>
            <v-col class="py-0">
                <dropdown v-model="value.doctorId" :items="practiceDoctors" clearable required label="Лекар"></dropdown>
            </v-col>
            <v-col class="py-0">
                <dropdown
                    v-model="value.specialtyCode"
                    item-value="code"
                    :items="practiceSpecialties"
                    clearable
                    required
                    label="Специалност"
                ></dropdown>
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { DoctorDropdownDto } from '@/model/Doctor/DoctorDropdownDto';
    import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';
    import { PracticeRoomDto } from '@/model/Practice/PracticeRoomDto';
    import { ReceptionServiceCreateCommand } from '@/model/Reception/Service/ReceptionServiceCreateCommand';
    import { doctorService } from '@/service/Doctor/DoctorService';
    import { practiceRoomService } from '@/service/Practice/PracticeRoomService';
    import { workTimeService } from '@/service/WorkTime/WorkTimeService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class CreditToEmployee extends Vue {
        @Prop()
        value!: ReceptionServiceCreateCommand;

        private practiceRooms: PracticeRoomDto[] = [];
        private practiceDoctors: DoctorDropdownDto[] = [];
        private practiceSpecialties: SpecialtyDto[] = [];

        private async getAllDoctorsForPractice() {
            this.practiceDoctors = await doctorService.getDoctorsByPracticeId(
                userContextCache.current?.practiceId ?? 0
            );
        }

        private async getAllSpecialtiesForPractice() {
            this.practiceSpecialties = (
                await workTimeService.getAllSpecialtiesForPractice(userContextCache.currentPracticeId ?? 0)
            ).data;
        }

        private async gePracticeRooms() {
            this.practiceRooms = [];
            const response = await practiceRoomService.getAllRooms(userContextCache.currentPracticeId ?? 0);
            if (response?.data) {
                this.practiceRooms = response.data;
            }
        }

        private async getSelectedDoctorSpecialties() {
            if (this.value.doctorId) {
                this.practiceSpecialties = (
                    await workTimeService.getRegisteredSpecialtiesForDoctorAndPractice(
                        userContextCache.currentPracticeId ?? 0,
                        this.value.doctorId ?? 0
                    )
                ).data;
            } else {
                this.getAllSpecialtiesForPractice();
            }
        }

        @Watch('value.doctorId')
        private onDoctorIdChanged() {
            this.getSelectedDoctorSpecialties();
        }

        @Watch('value.specialtyCode')
        private async onSpecialtyCodeChanged() {
            if (!this.value.doctorId && this.value.specialtyCode) {
                this.practiceDoctors = await doctorService.getDoctorsByPracticeAndSpecialty(
                    userContextCache.currentPracticeId ?? 0,
                    this.value.specialtyCode ?? 0
                );
            } else {
                this.practiceDoctors = await doctorService.getDoctorsByPracticeId(
                    userContextCache.current?.practiceId ?? 0
                );
            }
        }

        private async mounted() {
            await this.gePracticeRooms();
            await this.getAllSpecialtiesForPractice();
            await this.getAllDoctorsForPractice();
        }
    }
</script>
