import { Report365RowBase } from '@/model/Report/Rhi/Report365/Report365RowBase';

export class Annex4ChildrenPreventiveResultRowDto extends Report365RowBase {
    public countUnder1Year: number | null = null;
    public count2Years: number | null = null;
    public count6Years: number | null = null;
    public count17Years: number | null = null;
    public get total(): number {
        return (
            (this.countUnder1Year === null ? 0 : Number(this.countUnder1Year)) +
            (this.count2Years === null ? 0 : Number(this.count2Years)) +
            (this.count6Years === null ? 0 : Number(this.count6Years)) +
            (this.count17Years === null ? 0 : Number(this.count17Years))
        );
    }
}
