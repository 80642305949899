import { plainToInstance } from 'class-transformer';

import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { DoctorDropdownDto } from '@/model/Doctor/DoctorDropdownDto';
import { DoctorEditDto } from '@/model/Doctor/DoctorEditDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const doctorService = {
    // TODO: Следните два метода да се изместят към employeeService. Да не се ползва doctorId за ключ, a служител.
    async getDoctorsByPracticeId(practiceId: number): Promise<DoctorDropdownDto[]> {
        const response = await httpService.get<DoctorDropdownDto[]>(`Doctor/GetDoctorsByPracticeId/${practiceId}`, {
            params: { practiceId }
        });
        return plainToInstance(DoctorDropdownDto, response.data);
    },

    async getDoctorsByPracticeAndSpecialty(
        practiceId: number,
        specialtyCode: SpecialtyCode
    ): Promise<DoctorDropdownDto[]> {
        const response = await httpService.get<DoctorDropdownDto[]>(`Doctor/GetDoctorsByPracticeAndSpecialty`, {
            params: { practiceId, specialtyCode }
        });
        return plainToInstance(DoctorDropdownDto, response.data);
    },

    async getDoctor(id: number): Promise<DoctorEditDto> {
        const response = await httpService.get(`/Doctor/GetDoctor/${id}`);
        return plainToInstance(DoctorEditDto, response.data);
    },

    async getDoctorByUin(uin: string): Promise<DoctorDropdownDto> {
        const response = await httpService.get<DoctorDropdownDto>(`/Doctor/GetDoctorByUin/${uin}`, {
            params: { uin }
        });
        return plainToInstance(DoctorDropdownDto, response.data);
    },

    async updateDoctor(data: DoctorEditDto): Promise<DoctorEditDto> {
        const response = await httpService.put('/Doctor/UpdateDoctor', data);
        return plainToInstance(DoctorEditDto, response.data);
    }
};
