export class AddressDto {
    public cityId: number = 0;
    public cityName: string = '';
    public municipalityName: string = '';
    public countryCode: string = '';
    public street: string = '';
    public streetNumber: string = '';
    public neighborhood: string = '';
    public block: string = '';
    public entrance: string = '';
    public floor: string = '';
    public apartment: string = '';
    public postalCode: string = '';
    public mailbox: string = '';
}
