import { ExamDurationSettingDto } from '@/model/Setting/ExamDuration/ExamDurationSettingDto';
import { ExamDurationSettingUpdateCommand } from '@/model/Setting/ExamDuration/ExamDurationSettingUpdateCommand';
import { httpService } from '@/service/Infrastructure/HttpService';

export const examDurationSettingService = {
    async getExamDurationSetting(
        practiceId: number,
        doctorEmployeeSeqNumber: number
    ): Promise<ExamDurationSettingDto[]> {
        const response = await httpService.get<ExamDurationSettingDto[]>(
            'ExamDurationSetting/GetExamDurationSettings',
            { params: { practiceId, doctorEmployeeSeqNumber } }
        );
        return response.data;
    },

    async updateExamDurationSetting(
        practiceId: number,
        doctorEmployeeSeqNumber: number,
        examDurationSettings: ExamDurationSettingDto[]
    ): Promise<void> {
        const command = new ExamDurationSettingUpdateCommand(practiceId, doctorEmployeeSeqNumber, examDurationSettings);
        await httpService.post('ExamDurationSetting/UpdateExamDurationSetting', command);
    }
};
