<template>
    <v-row>
        <v-col md="3">
            <CityPicker v-model="value.cityId" required :disabled="disabled" @input="onCityIdChange" />
        </v-col>
        <v-col v-if="showPostCode" cols="12" md="3">
            <text-field
                v-model="value.postalCode"
                required
                :rules="[$validator.postalCode]"
                label="Пощенски код"
                :disabled="disabled"
            />
        </v-col>
        <v-col md="3">
            <text-field v-model="value.street" :required="!isNeighborhoodFilled" label="Улица" :disabled="disabled"
        /></v-col>
        <v-col cols="6" md="3"
            ><text-field v-model="value.streetNumber" label="Улица номер" :disabled="disabled"
        /></v-col>
        <v-col cols="6" md="3">
            <text-field v-model="value.neighborhood" :required="!isStreetFilled" label="Квартал" :disabled="disabled"
        /></v-col>
        <v-col cols="4" md="3">
            <text-field v-model="value.block" label="Блок" :disabled="disabled" />
        </v-col>
        <v-col cols="4" md="3">
            <text-field v-model="value.entrance" label="Вход" :disabled="disabled" />
        </v-col>
        <v-col cols="4" md="3">
            <text-field v-model="value.floor" label="Етаж" :disabled="disabled" />
        </v-col>
        <v-col cols="4" md="3">
            <text-field v-model="value.apartment" label="Апартамент" :disabled="disabled" />
        </v-col>
        <v-col v-if="showMailbox" cols="4" md="3">
            <text-field v-model="value.mailbox" label="Пощенска кутия" :disabled="disabled" />
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import CityPicker from '@/component/Picker/CityPicker.vue';
    import { AddressDto } from '@/model/Common/AddressDto';
    import { getPostCode } from '@/service/Nomenclature/CityService';

    @Component({ components: { CityPicker } })
    export default class Address extends Vue {
        @Prop({ type: Object })
        private value!: AddressDto;

        @Prop({ required: true })
        private showPostCode!: boolean;

        @Prop({ required: true })
        private showMailbox!: boolean;

        @Prop()
        private disabled!: boolean;

        private get isNeighborhoodFilled() {
            return this.value?.neighborhood;
        }

        private get isStreetFilled() {
            return this.value?.street;
        }

        private async onCityIdChange(value: number | null) {
            if (value) {
                const response = await getPostCode.getPostCodeByCityId(value);
                //Правя toString защото валидатора не го приема за string и не сработва коректно
                this.value.postalCode = response.toString();
            }
        }
    }
</script>
