<template>
    <div>
        <data-table
            title="Номера на електронни болнични листове"
            :headers="headers"
            :items="sickSheetCounters"
            :loading="$loading.isVisible"
        >
            <template #actions>
                <btn action="New" :to="`/Setting/SickSheetCounter/Create/${practiceId}`">
                    Добавяне на интервал от номера
                </btn>
            </template>
            <template #rowActions="{ item }">
                <btn icon action="Edit" :to="`/Setting/SickSheetCounter/Edit/${item.id}`" />
            </template>
        </data-table>

        <v-card class="mt-4">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <text-field v-model="totalCount" label="Общ брой номера" />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <text-field v-model="totalUsed" label="Изчерпани" />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <text-field v-model="totalRemaining" label="Оставащи" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { SickSheetCounterDto } from '@/model/Setting/Counter/SickSheetCounterDto';
    import { sickSheetCounterService } from '@/service/Setting/Counter/SickSheetCounterService';

    @Component
    export default class SickSheetCounter extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private sickSheetCounters: SickSheetCounterDto[] = [];
        private totalCount: number = 0;
        private totalUsed: number = 0;
        private totalRemaining: number = 0;
        private headers: IDataTableHeader[] = [
            { text: 'Начален номер', value: 'startNumber' },
            { text: 'Краен номер', value: 'endNumber' },
            { text: 'Брой номера', value: 'count' },
            { text: 'Изчерпани', value: 'usedCount' },
            { text: 'Неактивен брояч', value: 'isDeletedString' }
        ];

        private async mounted() {
            this.$loading.show();
            try {
                this.sickSheetCounters = await sickSheetCounterService.getSickSheetCountersByPractiteId(
                    this.practiceId
                );
                for (const counter of this.sickSheetCounters) {
                    counter.usedCount = counter.nextNumber;
                    if (counter.isDeleted === false) {
                        this.totalCount = this.totalCount + counter.count;
                        this.totalUsed = this.totalUsed + counter.usedCount;
                    }
                }
                this.totalRemaining = this.totalCount - this.totalUsed;
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
