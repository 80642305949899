<template>
    <v-navigation-drawer
        v-model="showSecondaryMenu"
        :permanent="!smAndDownResolution"
        :fixed="smAndDownResolution"
        :width="smAndDownResolution ? null : '100%'"
    >
        <v-list>
            <menu-item v-for="(item, index) in items" :key="index" :item="item"></menu-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import MenuItem from '@/component/Menu/MenuItem.vue';
    import { MenuItemModel } from '@/model/Menu/MenuItemModel';
    import { menuState } from '@/store/MenuState';

    @Component({
        components: { MenuItem }
    })
    export default class Menu extends Vue {
        @Prop({ required: true })
        private items!: MenuItemModel[];

        private get smAndDownResolution() {
            return this.$vuetify.breakpoint.smAndDown;
        }

        @Watch('smAndDownResolution')
        private smAndDownResolutionChanged() {
            menuState.showSecondaryMenuAppBarIcon = this.smAndDownResolution;
            this.showSecondaryMenu = !this.smAndDownResolution;
        }

        private get showSecondaryMenu() {
            return menuState.showSecondaryMenu;
        }

        private set showSecondaryMenu(value) {
            menuState.showSecondaryMenu = value;
        }

        private mounted() {
            // Преход от един към друг модул свива главното меню.
            // Пример: Връщане към "Посещения" от бланка(преход от модул Прегледи към модул Пациент) свива главното меню.
            // Логиката е дублирана и във VisitMenu.vue, защото там не се използва този компонент.
            menuState.mainMenuIsCollapsed = true;

            if (!this.smAndDownResolution) {
                this.showSecondaryMenu = true;
            } else {
                menuState.showSecondaryMenuAppBarIcon = true;
            }
        }

        private destroyed() {
            menuState.showSecondaryMenuAppBarIcon = false;
        }
    }
</script>
