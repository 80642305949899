<template>
    <div>
        <v-card-title>
            <div v-if="!seqNumber">Добавяне на ново Сведение за извършени имунизации</div>
            <div v-else>Преглед/редактиране на Сведение за извършени имунизации</div>
        </v-card-title>
        <v-card>
            <v-card-title>Данни за отчет</v-card-title>
            <v-card-text>
                <v-row>
                    <!--<v-col cols="12" md="4">
                        <date-picker v-model="report.startDate" label="От дата" required></date-picker>
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="report.endDate" label="До дата" required></date-picker>
                    </v-col>-->
                    <v-col cols="6">
                        <report-date-picker v-model="report" col="4" />
                    </v-col>
                    <v-col cols="6">
                        <btn action="Refresh" col="2" @click="recreate"> Обновяване </btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-title>Имунизации за периода</v-card-title>
            <v-card-text>
                <v-row>
                    <v-data-table
                        :headers="headers"
                        :items="report.immunizations ? report.immunizations : []"
                        disable-pagination
                        hide-default-footer
                        class="elevation-1"
                    >
                        <template #body="{ items, headers }">
                            <tbody>
                                <tr v-for="(item, idx) in items" :key="idx">
                                    <td v-for="(header, key) in headers" :key="key">
                                        <v-edit-dialog :return-value.sync="item[header.value]" large>
                                            {{ item[header.value] }}
                                            <template #input>
                                                <text-field v-model="item[header.value]" label="Edit"></text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <btn action="Back" :to="backRoute" />
                <btn
                    v-if="seqNumber"
                    action="Print"
                    :to="`/Print/Report.Rhi.RhiImmunization/RhiImmunization/${practiceId}/${doctorEmployeeSeqNumber}/${seqNumber}`"
                />
                <btn action="Save" @click="updateReport" />
                <v-spacer />
                <btn v-if="seqNumber" action="Delete" @click="deleteRhiImmunizationReport" />
            </v-card-actions>
        </v-card>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RhiImmunizationReportDto } from '@/model/Report/Rhi/Immunization/RhiImmunizationReportDto';
    import { RhiReportKey } from '@/model/Report/Rhi/RhiReportKey';
    import { immunizationReportService } from '@/service/Report/Rhi/Immunization/RhiImmunizationReportService';

    @Component({
        components: {
            ReportDatePicker
        }
    })
    export default class ImmunizationReportEdit extends Vue {
        @Prop()
        private practiceId!: number;

        @Prop()
        private doctorEmployeeSeqNumber!: number;

        @Prop()
        private seqNumber!: number;

        private report: RhiImmunizationReportDto = new RhiImmunizationReportDto();

        private get reportKey() {
            return new RhiReportKey(this.practiceId, this.doctorEmployeeSeqNumber, this.seqNumber);
        }

        private headers: IDataTableHeader[] = [
            { text: 'Код', value: 'immunizationCode' },
            { text: 'Имунизация', value: 'immunizationName' },
            { text: 'Подлежащи през периода', value: 'liableCount' },
            { text: 'Обхванати през периода', value: 'coveredCount' },
            { text: 'Необхванати - мед. противопоказания', value: 'notCoveredMedical' },
            { text: 'Необхванати - отказ', value: 'notCoveredRefusal' },
            { text: 'Необхванати - други', value: 'notCoveredOther' }
        ];

        private async mounted() {
            this.$loading.show();
            try {
                if (this.seqNumber) {
                    this.report = await immunizationReportService.getRhiImmunizationReport(this.reportKey);
                    this.report.immunizations = await immunizationReportService.getStoredImmunizationsForRhiReport(
                        this.reportKey
                    );
                } else {
                    this.report.fillThisMonth();
                    this.getInitialImmunizationsForRhiReport();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private recreate() {
            this.getInitialImmunizationsForRhiReport();
        }

        private async getInitialImmunizationsForRhiReport() {
            this.report.immunizations = await immunizationReportService.getInitialImmunizationsForRhiReport(
                this.practiceId,
                this.doctorEmployeeSeqNumber,
                this.report.startDate,
                this.report.endDate
            );
        }

        private async updateReport() {
            if (!this.seqNumber) {
                await immunizationReportService.createRhiImmunizationReport(
                    this.practiceId,
                    this.doctorEmployeeSeqNumber,
                    this.report
                );
            } else {
                await immunizationReportService.updateRhiImmunizationReport(this.reportKey, this.report);
            }
            this.$router.push(this.backRoute);
        }

        private get backRoute() {
            return `/Report/Rhi/RhiImmunizationReport/${this.practiceId}/${this.doctorEmployeeSeqNumber}`;
        }

        private async deleteRhiImmunizationReport() {
            await immunizationReportService.deleteRhiImmunizationReport(this.reportKey);
            this.$router.push(this.backRoute);
        }
    }
</script>
