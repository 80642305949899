import { plainToInstance } from 'class-transformer';

import { PisFileCreateDto } from '@/model/Report/Pis/PisFile/PisFileCreateDto';
import { PisFileFilterDto } from '@/model/Report/Pis/PisFile/PisFileFilterDto';
import { PisFileRowDto } from '@/model/Report/Pis/PisFile/PisFileRowDto';
import { PisFileUpdateDto } from '@/model/Report/Pis/PisFile/PisFileUpdateDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const pisFileService = {
    async getPisFiles(data: PisFileFilterDto): Promise<PisFileRowDto[]> {
        const response = await httpService.post<PisFileRowDto[]>('PisFile/GetPisFiles', data);
        return plainToInstance(PisFileRowDto, response.data);
    },

    async createPisFile(data: PisFileCreateDto): Promise<PisFileRowDto> {
        const response = await httpService.post<PisFileRowDto>('PisFile/CreatePisFile', data);
        return plainToInstance(PisFileRowDto, response.data);
    },

    async updatePisFile(
        practiceId: number,
        seqNumber: number,
        status: string,
        errorMessage: string | null
    ): Promise<void> {
        const data = new PisFileUpdateDto(practiceId, seqNumber, status, errorMessage);
        await httpService.put('PisFile/UpdatePisFile', data);
    }
};
