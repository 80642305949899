import { render, staticRenderFns } from "./CertificatePhysicalExam.vue?vue&type=template&id=3af4c35c&scoped=true"
import script from "./CertificatePhysicalExam.vue?vue&type=script&lang=ts"
export * from "./CertificatePhysicalExam.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3af4c35c",
  null
  
)

export default component.exports