import { plainToInstance } from 'class-transformer';

import { CertificatePhysicalExamDto } from '@/model/Exam/Certificate/CertificatePhysicalExamDto';
import { CertificatePhysicalExamViewDto } from '@/model/Exam/Certificate/CertificatePhysicalExamViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const certificatePhysicalExamService = {
    async create(data: CertificatePhysicalExamDto): Promise<number> {
        const response = await httpService.post<number>('CertificatePhysicalExam/Create', data);
        return response.data;
    },

    async update(data: CertificatePhysicalExamDto): Promise<void> {
        await httpService.put('CertificatePhysicalExam/Update', data);
    },

    async delete(id: number): Promise<void> {
        await httpService.delete(`CertificatePhysicalExam/Delete/${id}`);
    },

    async getById(id: number): Promise<CertificatePhysicalExamDto> {
        const response = await httpService.get<CertificatePhysicalExamDto>(`CertificatePhysicalExam/GetById/${id}`);
        return plainToInstance(CertificatePhysicalExamDto, response.data);
    },

    async getInfoByExamId(id: number): Promise<CertificatePhysicalExamDto> {
        const response = await httpService.get<CertificatePhysicalExamDto>(
            `CertificatePhysicalExam/GetInfoByExamId/${id}`
        );
        return plainToInstance(CertificatePhysicalExamDto, response.data);
    },

    async getByExam(id: number): Promise<CertificatePhysicalExamViewDto[]> {
        const response = await httpService.get<CertificatePhysicalExamViewDto[]>(
            `CertificatePhysicalExam/GetByExam/${id}`
        );
        return plainToInstance(CertificatePhysicalExamViewDto, response.data);
    }
};
