import { Type } from 'class-transformer';

import { PatientSpecialActivityDto } from '@/model/Patient/PatientSpecialActivityDto';

export class PatientPreventiveDataDto {
    public patientId: number = 0;

    @Type(() => PatientSpecialActivityDto)
    public activities: PatientSpecialActivityDto[] = [];

    @Type(() => PatientSpecialActivityDto)
    public selectedPerformedActivities: PatientSpecialActivityDto[] = [];

    @Type(() => PatientSpecialActivityDto)
    public selectedRequiredActivities: PatientSpecialActivityDto[] = [];

    public doctorEmployeeSeqNumber: number = 0;
    public patientAge: number = 0;
    public deputyDoctorId: number | null = null;
    public deputyDoctorIsHired: boolean | null = null;
}
