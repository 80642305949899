<template>
    <v-row no-gutters>
        <v-col md="4"><text-field v-model="value.specialty" disabled label="код спец." /></v-col>
        <v-col md="8"><text-field v-model="value.number" disabled label="напр. номер" /></v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { AmbulatorySheetReferral3Dto } from '@/model/Exam/AmbulatorySheetReferral3Dto';

    @Component
    export default class AmbulatorySheetReferral3Card extends Vue {
        // Този компонент може да получи undefined v-model. В този случай се bind-ва default стойността, която е празен обект.
        @Prop({ default: () => new AmbulatorySheetReferral3Dto() })
        private value!: AmbulatorySheetReferral3Dto;
    }
</script>
