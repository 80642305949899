<template>
    <v-toolbar-title>
        <v-tooltip v-if="currentVisitIsLoaded && allowWrite" bottom>
            <template #activator="{ on, attrs }">
                <v-btn icon small class="mr-2" v-bind="attrs" v-on="on" @click="edit">
                    <v-icon>{{ visitIcon }}</v-icon>
                </v-btn>
            </template>
            <span>Текущ избран преглед</span>
        </v-tooltip>

        <span class="hidden-xs-only">{{ visitInfo }}</span>
    </v-toolbar-title>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { actions } from '@/model/Common/Action';
    import { currentVisitCache } from '@/store/CurrentVisitCache';

    @Component
    export default class CurrentVisitTitle extends Vue {
        private get currentVisitIsLoaded() {
            return currentVisitCache.isLoaded;
        }

        private get visitIcon() {
            return actions.Visit.icon;
        }

        private edit(): void {
            if (currentVisitCache.isLoaded) {
                const path = `/Exam/AmbulatorySheet/Edit/${currentVisitCache.value.id}`;
                if (this.$route.path !== path) {
                    this.$router.push(path);
                }
            }
        }

        private get allowWrite() {
            // Todo:
            return Boolean(this);
        }

        private get visitInfo(): string {
            return currentVisitCache.isLoaded
                ? `Посещение № ${currentVisitCache.value.exam?.ambulatorySheetNumber}`
                : 'Не е избрано посещение';
        }
    }
</script>
