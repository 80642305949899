export class PatientSearchResultDto {
    public patientId: number = 0;
    public identifier: string = '';
    public age: number | null = null;
    public firstName: string = '';
    public middleName: string = '';
    public lastName: string = '';
    public isInsured: boolean = false;
    public patientRegistrationTypeName: string | null = null;
    public deregistrationDate: Date | null = null;
    // Показва се само при търсене на пациенти в цялата база, в тестова среда.
    public parcticeNumber: string = '';
}
