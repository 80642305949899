import { AxiosResponse } from 'axios';

import { ExamQuestionTypeDto } from '@/model/Nomenclature/ExamQuestionTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const examQuestionTypeService = {
    getExamQuestionTypes(): Promise<AxiosResponse<ExamQuestionTypeDto[]>> {
        return httpService.get('/ExamQuestionType/GetExamQuestionTypes');
    }
};
