<template>
    <div>
        <v-card-title>Търсене за диспансеризация</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="3"><text-field v-model="dispensaryRuleFilter.Icd" label="МКБ" /></v-col>
                <v-col cols="12" md="7"><text-field v-model="dispensaryRuleFilter.IcdName" label="Диагноза" /></v-col>
                <v-col cols="12" md="2"><btn action="Search" @click="search" /></v-col>
            </v-row>
            <div v-if="dispensaryRules.length > 0">
                <v-row>
                    <v-col>
                        <h3>Правила за диспансеризация</h3>
                        <data-table :headers="headers" :items="dispensaryRules" class="elevation-1">
                            <template #rowActions="{ item }">
                                <btn icon action="Select" @click="selectDispensaryRule(item.id)" />
                                <btn icon action="Regulation" @click="openDialog(item.id)" />
                            </template>
                        </data-table>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
        <v-dialog v-model="dialogVisible" transition="dialog-bottom-transition" max-width="900">
            <v-card>
                <v-card-title>
                    Наредба за диспансеризация
                    <v-spacer />
                    <btn icon action="Close" @click="dialogVisible = false" />
                </v-card-title>
                <v-card-text>
                    <pre class="text-h6 pt-8">{{ selectedDispensaryOrdinanceText }}</pre>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RuleFilterDto } from '@/model/Exam/RuleFilterDto';
    import { DispensaryRegisterDto } from '@/model/Register/Dispensary/DispensaryRegisterDto';
    import { DispensaryRuleDto } from '@/model/Register/Dispensary/DispensaryRuleDto';
    import { dispensaryRegisterService } from '@/service/Register/DispensaryRegisterService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    @Component
    export default class DispensaryData extends Vue {
        @Prop({ required: true })
        private value!: DispensaryRegisterDto;

        @Prop()
        private specialtyCode!: string;

        private selectedDispensaryOrdinanceText: string = '';
        private dispensaryRuleFilter: RuleFilterDto = new RuleFilterDto();
        private dispensaryRules: DispensaryRuleDto[] = [];
        private dialogVisible: boolean = false;

        private headers: IDataTableHeader[] = [
            { text: 'Диагноза', value: 'name' },
            { text: 'МКБ', value: 'icdCode' },
            { text: 'Допълнително МКБ', value: 'icdCodeAdd' },
            { text: 'Възрастова група', value: 'ageBracketName' },
            { text: '', value: 'id' }
        ];

        private async search() {
            this.$loading.show();
            try {
                this.dispensaryRuleFilter.specialtyCode = this.specialtyCode;
                if (currentPatientCache.value) {
                    this.dispensaryRuleFilter.patientAge = currentPatientCache.value.age;
                }
                this.dispensaryRules = await dispensaryRegisterService.searchDispensaryRule(this.dispensaryRuleFilter);
            } finally {
                this.$loading.hide();
            }
        }

        private selectDispensaryRule(id: number) {
            this.value.dispensaryRuleId = id;
            const dispensaryRule = this.dispensaryRules.find((rule) => rule.id === id);
            if (dispensaryRule) {
                this.value.icdCode = dispensaryRule.icdCode;
                this.value.icdCodeAdd = dispensaryRule.icdCodeAdd;
                this.value.dispensaryRuleName = dispensaryRule.name;
                this.selectedDispensaryOrdinanceText = dispensaryRule.dispensaryOrdinanceText;
                this.dispensaryRules = [];
            }
        }

        private openDialog(id: number) {
            const dispensaryRule = this.dispensaryRules.find((rule) => rule.id === id);
            if (dispensaryRule) {
                this.selectedDispensaryOrdinanceText = dispensaryRule.dispensaryOrdinanceText;
                this.dialogVisible = true;
            }
        }
    }
</script>
