const base64ToArrayBuffer = function base64ToArrayBuffer(base64: string, type: string) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let el = 0; el < binaryLen; el++) {
        const ascii = binaryString.charCodeAt(el);
        bytes[el] = ascii;
    }
    const file = new Blob([bytes], { type });
    return URL.createObjectURL(file);
};

export const base64Service = {
    printFile(base64: string, type: string = 'application/pdf') {
        const fileUrl = base64ToArrayBuffer(base64, type);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = fileUrl;
        iframe.onload = function onload() {
            iframe.contentWindow?.print();
        };
        document.body.appendChild(iframe);
    },

    downloadFile(base64: string, type: string = 'application/pdf', fileName: string = 'file') {
        const fileUrl = base64ToArrayBuffer(base64, type);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    },

    openFile(base64: string, type: string = 'application/pdf') {
        const fileUrl = base64ToArrayBuffer(base64, type);
        window.open(fileUrl);
    }
};
