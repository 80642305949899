<template>
    <v-card>
        <v-card-title>Пакетна услуга</v-card-title>
        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn action="Save" @click="save"></btn>
            <v-spacer></v-spacer>
            <btn v-if="seqNumber" action="Delete" @click="deleteBatch"></btn>
        </v-card-actions>
        <v-form ref="form" v-model="isValid">
            <v-card-text>
                <service-title v-model="batch.serviceTitle" :alert-text="alertText" :categories="categories" />
                <v-row dense>
                    <v-col v-for="(item, index) in batch.tariffs" :key="index" cols="2">
                        <v-row>
                            <v-col cols="1">
                                <v-checkbox
                                    v-model="item.isSelected"
                                    @change="checkboxClick($event, item)"
                                ></v-checkbox>
                            </v-col>
                            <v-col>
                                <text-field
                                    v-model.number="item.patientPrice"
                                    :label="'Цена ' + item.tariffName"
                                    suffix="лв."
                                    type="number"
                                    :min="0"
                                    :disabled="!item.isSelected"
                                    :hint="`Обща цена на избраните услуги ${
                                        getSelectedOffersSumPrice(item.selectedOffers) ?? 0
                                    }лв.`"
                                    @input="chechForOffer()"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-data-table
                    v-if="selectedTariff.tariffSeqNumber"
                    v-model="selectedTariff.selectedOffers"
                    title="Услуги"
                    :items="selectedTariff.offers"
                    :headers="headers"
                    group-by="tariffName"
                    :search="search"
                    item-key="serviceOfferKey.code"
                    show-select
                    class="pa-5"
                    @item-selected="onItemSelected"
                >
                    <template #top>
                        <text-field v-model="search" label="Търсене"></text-field>
                    </template>
                    <template #[`item.patientPrice`]="{ item }">
                        <span> {{ `${item.patientPrice ?? ''} лв.` }} </span>
                    </template>
                    <template #[`item.quantity`]="{ item }">
                        <tr>
                            <text-field
                                v-model="item.quantity"
                                :disabled="!item.isSelected"
                                :required="item.isSelected"
                                type="number"
                            ></text-field>
                        </tr>
                    </template>
                </v-data-table>
                <alert v-else class="ma-5" type="info" prominent>Моля, изберете тарифа.</alert>
            </v-card-text>
        </v-form>
    </v-card>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ServiceTitle from '@/component/Practice/ServiceTitle.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { BatchChildDto } from '@/model/Practice/Service/BatchChildDto';
    import { BatchDto } from '@/model/Practice/Service/BatchDto';
    import { BatchTariffDto } from '@/model/Practice/Service/BatchTariffDto';
    import { ServiceCategoryDto } from '@/model/Practice/Service/ServiceCategoryDto';
    import { batchService } from '@/service/Practice/Service/BatchService';
    import { practiceServiceService } from '@/service/Practice/Service/PracticeServiceService';
    import { tariffService } from '@/service/Practice/Service/TariffService';

    @Component({
        components: {
            ServiceTitle
        }
    })
    export default class Batch extends Vue {
        @Prop()
        private practiceId!: number;

        @Prop()
        private seqNumber!: number;

        private isValid: boolean = false;
        private search: string = '';
        private batch: BatchDto = new BatchDto();
        private selectedTariff: BatchTariffDto = new BatchTariffDto();
        private selectedOffers: BatchChildDto[] = [];
        private hasOffer: boolean = false;
        private alertText: string = '';
        private categories: ServiceCategoryDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'Услуга', value: 'name' },
            { text: 'Тарифа', value: 'tariffName' },
            { text: 'Цена', value: 'patientPrice' },
            { text: 'Брой', value: 'quantity' }
        ];

        private async mounted() {
            if (this.seqNumber) {
                const result = await batchService.getBatch(this.practiceId, this.seqNumber);
                if (result) {
                    this.batch = result;
                }
            } else {
                this.batch.practiceId = this.practiceId;
                const tariffs = await tariffService.getPracticeTariffs(this.practiceId);
                const offers = await batchService.getAllOffers(this.practiceId);
                for (const tariff of tariffs) {
                    const batchTariff = new BatchTariffDto();
                    batchTariff.tariffName = tariff.name;
                    batchTariff.tariffSeqNumber = tariff.seqNumber;
                    batchTariff.offers = offers;
                    this.batch.tariffs.push(batchTariff);
                }
            }
            this.categories = await practiceServiceService.getServiceCategories(this.practiceId);
        }

        private async save() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isValid) {
                this.chechForOffer();
                if (!this.hasOffer) {
                    return;
                }
                const result = !this.seqNumber
                    ? await batchService.createBatch(this.batch)
                    : await batchService.updateBatch(this.batch);

                if (result) {
                    this.alertText = result;
                } else {
                    this.$notifier.showSuccess('Успех', 'Данните са записани успешно');
                    this.$router.push(`/Finance/Service/PriceList/${this.practiceId}`);
                }
            }
        }

        private onItemSelected(item: { item: BatchChildDto; value: boolean }) {
            const selectedItem = item.item;
            const isSelected = item.value;
            if (isSelected) {
                selectedItem.quantity = selectedItem.quantity ? selectedItem.quantity : 1;
                selectedItem.isSelected = true;
            } else {
                selectedItem.quantity = 0;
                selectedItem.isSelected = false;
            }
        }

        private checkboxClick(isChecked: boolean, item: BatchTariffDto) {
            if (isChecked) {
                for (const tariff of this.batch.tariffs.filter((tar) => tar.tariffName !== item.tariffName)) {
                    tariff.isSelected = false;
                }
                this.selectedTariff = item;
                this.selectedOffers = item.selectedOffers;
            } else {
                this.selectedTariff = new BatchTariffDto();
            }
        }

        private getSelectedOffersSumPrice(item: BatchChildDto[]) {
            return item.map((child) => child.patientPrice).reduce((price1, price2) => price1 + price2, 0);
        }

        private async deleteBatch() {
            await batchService.deleteBatch(this.practiceId, this.seqNumber);
            this.$notifier.showSuccess('Успех', 'Пакетната услуга е изтрита');
            this.$router.push(`/Finance/Service/PriceList/${this.practiceId}`);
        }

        private chechForOffer() {
            this.hasOffer = this.batch.tariffs.some(
                (tariff) => tariff.selectedOffers.length > 0 && tariff.patientPrice !== null
            );

            if (!this.hasOffer) {
                this.showMissingOfferError();
            }
        }

        private showMissingOfferError() {
            if (this.seqNumber) {
                this.$notifier.showWarning(
                    'Грешка',
                    "Ако пакетната услуга вече не се предлага по нито една тарифа, използвайте бутона 'Изтриване' за цялата услуга."
                );
            } else {
                this.$notifier.showWarning('Грешка', 'Пакетната услугата трябва да се предлага поне с една тарифа.');
            }
        }

        private get backRoute() {
            return `/Finance/Service/PriceList/${this.practiceId}`;
        }
    }
</script>
