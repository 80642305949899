import { GenderCode } from '@/enum/Nomenclature/GenderCode';
import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';

export class FamilyMemberDto {
    // Родствена връзка
    public familyMemberTypeId: number | null = null;
    public familyMemberFirstName: string | null = null;
    public familyMemberMiddleName: string | null = null;
    public familyMemberLastName: string | null = null;
    public familyMemberAge: number | null = null;

    // ЛНЧ на Придружаване и гледане на болен член от семейството
    public familyMemberPidTypeCode: PidTypeCode | null = null;
    public familyMemberIdentifier: string | null = null;
    public familyMemberGenderCode: GenderCode | null = null;
    public familyMemberRhifId: number | null = null;
    public familyMemberRhifAreaId: number | null = null;
    public familyMemberCityId: number | null = null;
    public familyMemberStreet: string | null = null;
    public familyMemberStreetNumber: string | null = null;
    public familyMemberNeighborhood: string | null = null;
    public familyMemberBlock: string | null = null;
    public familyMemberEntrance: string | null = null;
    public familyMemberFloor: string | null = null;
    public familyMemberApartment: string | null = null;
    public familyMemberCompany: string | null = null;
    public familyMemberWorkCityId: number | null = null;
    public familyMemberWorkNeighborhood: string | null = null;
    public familyMemberWorkStreet: string | null = null;
    public familyMemberWorkStreetNumb: string | null = null;
    public familyMemberProfession: string | null = null;
    public familyMemberPosition: string | null = null;
    public familyMemberWorkForeignCity: string | null = null;

    private get title() {
        const pidTypeName =
            this.familyMemberPidTypeCode === PidTypeCode.Egn
                ? 'ЕГН'
                : this.familyMemberPidTypeCode === PidTypeCode.Lnch
                  ? 'ЛНЧ'
                  : this.familyMemberPidTypeCode;
        return `${pidTypeName} ${this.familyMemberIdentifier}, ${this.familyMemberFirstName} ${this.familyMemberLastName}, Месторабота: ${this.familyMemberCompany}`;
    }
}
