import { Type } from 'class-transformer';

import { Annex3NewbornRegisterRowDto } from '@/model/Report/Rhi/Annex3/Annex3NewbornRegisterRowDto';

export class Annex3NewbornRegisterDto {
    public name: string = '';

    @Type(() => Annex3NewbornRegisterRowDto)
    public newbornRegisters: Annex3NewbornRegisterRowDto[] = [];
}
