<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Медицинско за работа - Карта за предварителен медицински преглед"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card class="mb-md-4">
            <main-title>Медицинско за работа - Карта за предварителен медицински преглед</main-title>
            <hr />
            <v-card-text>
                <PatientInfo />

                <v-row>
                    <v-col cols="12" md="2">Лицето кандидатства за работа в</v-col>
                    <v-col cols="12" md="5">
                        <text-field v-model="document.employer" label="завод, предприятие" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <text-field v-model="document.department" label="отдел, цех" />
                    </v-col>
                    <v-col cols="4" md="2">като</v-col>
                    <v-col cols="10">
                        <text-field v-model="document.position" label="точна професия" />
                    </v-col>
                    <v-col cols="12">
                        <v-radio-group v-model="document.isPastEmployee">
                            <v-radio label="Кандидатът НЕ е работил в същото предприятие" :value="false"></v-radio>
                            <v-radio label="Кандидатът е работил в същото предприятие" :value="true"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row v-if="document.isPastEmployee">
                    <v-col cols="10">на длъжност</v-col>
                    <v-col cols="5">
                        <date-picker v-model="document.fromDate1" label="от:" />
                    </v-col>
                    <v-col cols="5">
                        <date-picker v-model="document.toDate1" label="до:" />
                    </v-col>
                    <v-col cols="10">
                        <text-field v-model="document.position1" label="като" />
                    </v-col>
                    <v-col cols="10">и на длъжност</v-col>
                    <v-col cols="5">
                        <date-picker v-model="document.fromDate2" label="от:" />
                    </v-col>
                    <v-col cols="5">
                        <date-picker v-model="document.toDate2" label="до:" />
                    </v-col>
                    <v-col cols="10"><text-field v-model="document.position2" label="като" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h3 class="text--primary">Данни от анамнезата:</h3>
                        <text-field
                            v-model="document.previousDisease"
                            label="Минали заболявания /без детски инфекции/"
                        />

                        <h3 class="text--primary">Отпуски по болест - честота:</h3>
                        <text-field v-model="document.sickLeave" />

                        <h3 class="text--primary">Инвалидност: не/да, причини, групи</h3>
                        <text-field v-model="document.disability" />

                        <h3 class="text--primary">Данни от прегледа:</h3>
                        <text-field v-model="document.examResults" label="описват се само патологичните отклонения" />

                        <h3 class="text--primary">Необходими прегледи при специалисти:</h3>
                        <v-radio-group v-model="document.requiresSpecialistExam" row>
                            <v-radio label="ДА" :value="true"></v-radio>/
                            <v-radio label="НЕ" :value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row v-if="document.requiresSpecialistExam">
                    <v-col cols="12">
                        <h3 class="text--primary">РЕЗУЛТАТИ ОТ ПРЕГЛЕДИТЕ НА СПЕЦИАЛИСТИТЕ:</h3>
                        <v-textarea v-model="document.specialistExamResult" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h3 class="text--primary">Забележка:</h3>
                        <text-field v-model="document.notes" />

                        <h3 class="text--primary">ЗАКЛЮЧЕНИЕ:</h3>
                        <v-radio-group v-model="document.isMedicallyApproved">
                            <v-radio label="Кандидатът НЕ може да заеме длъжността" :value="false"></v-radio>
                            <v-radio label="Кандидатът може да заеме длъжността" :value="true"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="10" md="5">
                        <date-picker v-model="document.issueDate" label="Дата и час:" required></date-picker>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { SelectListBooleanValueItem } from '@/model/Common/SelectListBooleanValueItem';
    import { CertificateWorkDto } from '@/model/Exam/Certificate/CertificateWorkDto';
    import { certificateWorkService } from '@/service/Exam/Certificate/CertificateWorkService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: {
            UnsavedChanges,
            PatientInfo,
            ExamLayout
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class CertificateWork extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private examId!: number;

        @Prop()
        private documentId!: number;

        private document: CertificateWorkDto = new CertificateWorkDto();
        private initialValue: CertificateWorkDto = new CertificateWorkDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private pastEmployment: SelectListBooleanValueItem[] = [
            { text: 'Кандидатът не е работил в същото предприятие', value: false },
            { text: 'Кандидатът е работил в същото предприятие', value: true }
        ];

        private medicalApproval: SelectListBooleanValueItem[] = [
            { text: 'Кандидатът НЕ може да заеме длъжността', value: false },
            { text: 'Кандидатът може да заеме длъжността', value: true }
        ];

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                if (this.documentId) {
                    const response = await certificateWorkService.getCertificateWork(this.documentId);
                    this.document = response.data;
                    await currentVisitCache.load(this.document.examId);
                } else {
                    const responseExam = await certificateWorkService.getCertificateWorkInfoByExamId(this.examId);
                    this.document = responseExam.data;
                    this.document.examId = this.examId;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async save() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (documentId === 0) {
                    const response = await certificateWorkService.createCertificateWork(this.document);
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, 'CertificateWork', 'Медицинско за работа');
                    this.$router.push(`/Exam/CertificateWork/Edit/${documentId}`);
                } else {
                    await certificateWorkService.updateCertificateWork(this.document);
                    eventBus.$emit('update-referral-event', 'Медицинско за работа');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        @Watch('document.requiresSpecialistExam')
        private fillSpecialistsText() {
            if (this.document.requiresSpecialistExam === true && !this.document.specialistExamResult) {
                this.document.specialistExamResult =
                    '1.Офталмологичен преглед: Visus oc.dex=1 Visus oc.sin=1 Корекция-д, цветоусещане-б.о.\n' +
                    '2.Оторинологичен преглед: Шепотна реч: ДУ - до 6м, ЛУ - до 6м, Говорна реч:над 6 м двустранно\n' +
                    '3.Хирургичен преглед: б.о.Заключение: хирурич.здрав\n' +
                    '4.Неврологичен преглед: Припадъци / Epi - отрича, Неврологичен статус - б.о.Заключение - невролог.здрав\n' +
                    '5.Дерматологичен статус - б.о.Заключение: кл.здрав\n' +
                    '6.Психичен статус - б.о.Заключение:психично здрав\n' +
                    '7.Личен лекар: Заключение кл.здрав.Не страда от заболявания, включени в наредба 5\n';
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                const errors = (await certificateWorkService.canSaveCertificateWork(this.document)).data;
                if (errors.length <= 0) {
                    await this.save();
                } else {
                    const errorMessages: string[] = [];
                    for (let errorPos: number = 0; errorPos < errors.length; errorPos++) {
                        errorMessages.push(errors[errorPos]);
                    }
                    if (errors.length > 0) {
                        this.$notifier.showError('Грешка', errorMessages.join(', '));
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                await certificateWorkService.deleteCertificateWork(documentId);
                this.$router.push(`/Exam/CertificateWork/Create/${currentVisitCache.value.id}`);
                eventBus.$emit('delete-referral-event', documentId, 'document', 'Медицинско за работа');
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.document.id) {
                this.$router.push(`/Print/Exam.Certificate.CertificateWork/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Certificate.CertificateWork';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
