export class DosageInstructionDto {
    public medicineTakeFrequency: number | null = null;
    public medicineTakeQuantity: number[] = [1];
    public medicineDosagePeriodCount: number = 1;
    public medicineDosageUnitCode: string = 'd';
    public therapyDuration: number | null = 1;
    public packageCount: number = 1;
    public dosage: string = '';
    public useCustomQuantity: boolean = false;
}
