<template>
    <div>
        <v-row>
            <v-col cols="12"
                ><v-data-table
                    :headers="headers"
                    :items="value"
                    disable-pagination
                    hide-default-footer
                    class="elevation-1"
                >
                    <template #body="{ items, headers }">
                        <tbody>
                            <tr v-for="(item, idx) in items" :key="idx">
                                <template v-if="!item.isSection">
                                    <td v-for="(header, key) in headers" :key="key" class="report-border">
                                        <v-edit-dialog
                                            :return-value.sync="item[header.value]"
                                            cancel-text="Затваряне"
                                            save-text="Запис"
                                            large
                                        >
                                            {{ item[header.value] }}
                                            <template #input>
                                                <text-field
                                                    v-model.number="item[header.value]"
                                                    label="Редактиране"
                                                ></text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </td>
                                </template>
                                <template v-else>
                                    <td v-for="(header, key) in headers" :key="key" class="">
                                        <strong> {{ item[header.value] }} </strong>
                                        <!--<v-text-field v-model.number="item[header.value]" readonly></v-text-field>-->
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table></v-col
            >
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';

    @Component
    export default class DataTableComponent extends Vue {
        @Prop({ required: true })
        private value!: unknown[];

        @Prop({ required: true })
        private headers!: IDataTableHeader[];
    }
</script>
<style>
    .report-border {
        border: 1px solid;
    }
</style>
