<template>
    <dropdown
        :value="value"
        :items="pidTypes"
        item-value="code"
        label="Вид идентификатор"
        :disabled="disabled"
        :required="required"
        :clearable="!required"
        @input="$emit('input', ...arguments)"
        @blur="$emit('blur')"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';
    import { pidTypeCache } from '@/store/Nomenclature/PidTypeCache';

    @Component
    export default class PidTypePicker extends Vue {
        @Prop()
        private value!: PidTypeCode;

        @Prop()
        private disabled!: boolean;

        @Prop()
        private required!: boolean;

        private get pidTypes() {
            return pidTypeCache.items;
        }
    }
</script>
