<template>
    <data-table title="Фискални устройства" :headers="headers" :items="items" :loading="$loading.isVisible">
        <template #actions>
            <btn action="New" :to="`/Practice/Fiscal/Device/Create/${practiceId}`">
                Добавяне на фискално устройство
            </btn>
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/Practice/Fiscal/Device/Edit/${practiceId}/${item.seqNumber}`" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { FiscalDeviceDto } from '@/model/Practice/Fiscal/FiscalDeviceDto';
    import { fiscalDeviceService } from '@/service/Practice/Fiscal/FiscalDeviceService';

    @Component
    export default class DeviceList extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private items: FiscalDeviceDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'Пореден номер', value: 'seqNumber' },
            { text: 'Код', value: 'code' },
            { text: 'Име', value: 'displayName' },
            { text: 'Неактивно', value: 'isDeletedString' }
        ];

        private async mounted() {
            await this.loadFiscalDeviceItems();
        }

        private async loadFiscalDeviceItems() {
            this.$loading.show();
            try {
                if (this.practiceId) {
                    this.items = await fiscalDeviceService.getAllByPractice(this.practiceId);
                }
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
