export class PatientDiagnosisCheckCommand {
    public patientId: number = 0;
    public doctorEmployeeSeqNumber: number = 0;
    public doctorSpecialtyCode: string = '';
    public icdCode: string = '';
    public icdCodeAdd: string | null = null;
    public examDate: Date = new Date();
    public isAdult: boolean = false;
    public examId: number = 0;
}
