<template>
    <v-form ref="employeeEditForm" v-model="isFormValid">
        <v-card>
            <template v-if="allowWriteSelf">
                <v-card-title>Редактиране на служител {{ model.employeeTitle }}</v-card-title>
                <v-card-actions>
                    <btn action="Back" :to="backRoute" />
                    <btn action="Save" @click="updateEmployee" />
                    <v-spacer></v-spacer>
                    <btn
                        v-if="model.employeeSeqNumber && !model.hasVisits"
                        action="Delete"
                        @click="openDeleteDialog(null, deleteEmployee)"
                    />
                    <alert v-model="model.hasVisits" type="info" class="mb-0">
                        Лекарят е правил прегледи, затова не може да бъде изтрит
                    </alert>
                </v-card-actions>
            </template>
            <template v-else>
                <v-card-title>Служител {{ model.employeeTitle }}</v-card-title>
                <v-card-actions>
                    <btn action="Back" :to="backRoute" />
                </v-card-actions>
            </template>

            <v-card-text>
                <v-row v-if="!employeeIsDoctor">
                    <v-col>
                        <text-field
                            v-model="model.employeeDescription"
                            label="Име или длъжност на служител, който не е лекар"
                            required
                            :disabled="!allowWriteSelf"
                        ></text-field>
                    </v-col>
                </v-row>
                <!-- Правата на служител могат да се редактират само от собственик/админ. -->
                <v-row>
                    <v-col cols="12" md="4">
                        <v-checkbox
                            v-model="employeeIsPracticeOwner"
                            dense
                            label="Собственик / Админ"
                            :disabled="!isGlobalAdminOrPracticeOwner"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4">
                        <multi-select-dropdown
                            v-if="!employeeIsPracticeOwner"
                            v-model="model.employeeRoleCodes"
                            :items="model.allRoles"
                            item-value="code"
                            :disabled="!isGlobalAdminOrPracticeOwner"
                            label="Роли и права"
                        />
                    </v-col>
                    <v-col v-if="employeeIsDoctor" cols="12" md="4">
                        <v-checkbox
                            v-model="model.examScheduleIsPublic"
                            dense
                            label="Online записване на час"
                            :disabled="!isGlobalAdminOrPracticeOwner"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <div v-if="employeeIsDoctor" class="mt-4">
            <v-card v-if="allowWriteSelf">
                <v-card-actions>
                    <btn action="NewAccount" :to="`/Employee/Deputy/Create/${practiceId}/${employeeSeqNumber}`">
                        Добавяне на заместващ
                    </btn>
                    <btn action="NewAccount" :to="`/Employee/Hired/Create/${practiceId}/${employeeSeqNumber}`">
                        Добавяне на нает лекар
                    </btn>
                    <btn action="CheckList" @click="postDoctorDeputyCheck">Проверка в НЗИС</btn>
                </v-card-actions>
            </v-card>

            <data-table
                title="Заместващи лекари"
                :headers="deputyHeaders"
                :items="deputyDoctors"
                :loading="$loading.isVisible"
                disable-pagination
            >
                <template #rowActions="{ item }">
                    <btn
                        v-if="allowWriteSelf && item.deputyUserLock.userIsPending"
                        action="Success"
                        @click="approveDeputyUser(item)"
                    >
                        Одобряване на потребителя
                    </btn>
                    <btn v-if="allowWriteSelf" icon action="Edit" :to="getDoctorDeputyRoute(item)">
                        График, НЗИС, потребител
                    </btn>
                </template>
            </data-table>

            <data-table
                title="Наети лекари"
                :headers="hiredHeaders"
                :items="hiredEmployees"
                :loading="$loading.isVisible"
                disable-pagination
            >
                <template #rowActions="{ item }">
                    <!-- Не би трябвало да е възможно нает лекар да е заключен към неодобрен потребител, но за пълнота одобряването се поддържа и тук. -->
                    <btn
                        v-if="allowWriteSelf && item.deputyUserLock.userIsPending"
                        action="Success"
                        @click="approveDeputyUser(item)"
                    >
                        Одобряване на потребителя
                    </btn>
                    <btn v-if="allowWriteSelf" icon action="Edit" :to="getDoctorDeputyRoute(item)">
                        График, НЗИС, потребител
                    </btn>
                </template>
            </data-table>
        </div>

        <alert :value="!$loading.isVisible" type="info" class="mt-4 mb-0">
            По-долу <b>не</b> се добавят потребители на заместващи и наети лекари, а само други потребители(имейл
            адреси), които имат правото да работят като служителя {{ model.doctorNameOrEmployeeDescription }}, например
            мед. сестра или представител на Контракс. Никога не споделяйте паролата си, а временно упълномощавайте
            съответния потребител!
        </alert>
        <data-table
            title="Потребител на този служител и упълномощени потребители"
            :headers="employeeUserHeaders"
            :items="employeeUsersAndInvitations"
            :loading="$loading.isVisible"
            disable-pagination
        >
            <template #actions>
                <btn
                    v-if="allowWriteSelf"
                    action="NewAccount"
                    :to="`/Employee/EmployeeUser/Create/${practiceId}/${employeeSeqNumber}`"
                >
                    <template v-if="employeeUsersAndInvitations.length">Упълномощаване на потребител</template>
                    <template v-else>Свързване на този служител с потребител</template>
                </btn>
            </template>
            <template #rowActions="{ item }">
                <btn v-if="allowWriteSelf && item.userIsPending" action="Success" @click="approveEmployeeUser(item)">
                    Одобряване на потребителя
                </btn>
                <btn
                    v-if="allowWriteSelf"
                    icon
                    action="Delete"
                    @click="openDeleteDialog(item, deleteEmployeeUserOrInvitation)"
                >
                    Изтриване (отнема правата на потребителя)
                </btn>
            </template>
        </data-table>

        <v-dialog v-model="deleteDialogIsVisible" width="auto">
            <v-card>
                <v-card-title>Сигурни ли сте, че искате да изтриете този запис?</v-card-title>
                <v-card-actions>
                    <btn action="Cancel" @click="closeDeleteDialog()">Отказ</btn>
                    <v-spacer></v-spacer>
                    <btn action="Success" @click="confirmDelete()">OK</btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deputyCheckDialogIsVisible">
            <nhis-doctor-deputy-result v-model="deputyCheckResult" />
            <v-card-actions>
                <v-spacer></v-spacer>
                <btn icon action="Close" @click="deputyCheckDialogIsVisible = false" />
            </v-card-actions>
        </v-dialog>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Ref } from 'vue-property-decorator';

    import MultiSelectDropdown from '@/component/Dropdown/MultiSelectDropdown.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { DoctorDeputyUpdateCommand } from '@/model/Employee/Deputy/DoctorDeputyUpdateCommand';
    import { DoctorDeputyWithLastScheduleDto } from '@/model/Employee/Deputy/DoctorDeputyWithLastScheduleDto';
    import { EmployeeEditDto } from '@/model/Employee/EmployeeEditDto';
    import { EmployeeUserDto } from '@/model/Employee/EmployeeUserDto';
    import { NhisDoctorDeputyCheckResultDto } from '@/model/Nhis/DoctorDeputy/NhisDoctorDeputyCheckResultDto';
    import { doctorDeputyService } from '@/service/Employee/Deputy/DoctorDeputyService';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { employeeUserService } from '@/service/Employee/EmployeeUserService';
    import { nhisDoctorDeputyService } from '@/service/Nhis/NhisDoctorDeputyService';
    import { signXmlService } from '@/service/Report/SignXmlService';
    import { arrayUtil } from '@/util/ArrayUtil';
    import NhisDoctorDeputyResult from '@/view/Employee/Deputy/NhisDoctorDeputyResult.vue';

    import { EmployeeBase } from './EmployeeBase';

    @Component({ components: { MultiSelectDropdown, NhisDoctorDeputyResult } })
    export default class EmployeeEdit extends EmployeeBase {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private employeeSeqNumber!: number;

        private isFormValid: boolean = true;
        private model: EmployeeEditDto = new EmployeeEditDto();
        // Свежда числото 0, 1, 2, 3 до булева променлива, за да може да се редактира с checkbox.
        private employeeIsPracticeOwner: boolean = false;
        private deputyDoctors: DoctorDeputyWithLastScheduleDto[] = [];
        private hiredEmployees: DoctorDeputyWithLastScheduleDto[] = [];
        private employeeUsersAndInvitations: EmployeeUserDto[] = [];

        private deleteDialogIsVisible: boolean = false;
        private deleteMethod: () => void = this.deleteEmployeeUserOrInvitation;
        private employeeUserToDelete: EmployeeUserDto | null = null;

        private deputyCheckDialogIsVisible: boolean = false;
        private deputyCheckResult: NhisDoctorDeputyCheckResultDto = new NhisDoctorDeputyCheckResultDto();

        private deputyHeaders: IDataTableHeader[] = [
            { text: 'Име на заместващия лекар', value: 'deputyDoctorName' },
            { text: 'УИН', value: 'deputyDoctorUin' },
            { text: 'Потребител', value: 'deputyUserLock.personAndUserName' },
            { text: 'Последно заместване', value: 'lastInterval' },
            { text: 'НЗИС статус', value: 'lastStatusName' }
        ];

        private hiredHeaders: IDataTableHeader[] = [
            { text: 'Име на наетия лекар', value: 'deputyDoctorName' },
            { text: 'УИН', value: 'deputyDoctorUin' },
            { text: 'Само за потребител', value: 'deputyUserLock.personAndUserName' },
            { text: 'Последно нает', value: 'lastInterval' },
            { text: 'НЗИС статус', value: 'lastStatusName' }
        ];

        private employeeUserHeaders: IDataTableHeader[] = [
            { text: 'Потребител (имейл адрес за вход в системата)', value: 'personAndUserName' }
        ];

        private get allowWriteSelf() {
            return this.allowWrite(this.employeeSeqNumber);
        }

        private get employeeIsDoctor() {
            return Boolean(this.model.doctorUin);
        }

        private async mounted() {
            this.$loading.show();
            try {
                this.model = await employeeService.getEmployeeForEdit(this.practiceId, this.employeeSeqNumber);
                this.employeeIsPracticeOwner = this.model.isPracticeOwner > 0;

                // Настоящите и бъдещите(поканените) потребители се добавят в общ grid.
                this.employeeUsersAndInvitations.push(...this.model.employeeUsers);
                this.employeeUsersAndInvitations.push(
                    ...this.model.invitedEmails.map((userName) => {
                        const user = new EmployeeUserDto();
                        user.userName = userName;
                        user.personName = 'Поканен/а. Трябва да се регистрира.';
                        return user;
                    })
                );

                const deputies = await doctorDeputyService.getDoctorDeputiesWithLastSchedule(
                    this.practiceId,
                    this.employeeSeqNumber
                );
                this.deputyDoctors = deputies.filter((deputy) => !deputy.doctorDeputyKey.deputyDoctorIsHired);
                this.hiredEmployees = deputies.filter((deputy) => deputy.doctorDeputyKey.deputyDoctorIsHired);
            } finally {
                this.$loading.hide();
            }
        }

        @Ref()
        private employeeEditForm!: { validate: () => boolean };

        private async updateEmployee() {
            this.employeeEditForm.validate();
            if (this.isFormValid) {
                this.$loading.show();
                try {
                    // Доверен/доказан собственик не се понижава до самозван, т.е. 2 и 3 не стават 1.
                    if (this.employeeIsPracticeOwner) {
                        if (this.model.isPracticeOwner === 0) {
                            this.model.isPracticeOwner = 1;
                        }
                    } else {
                        this.model.isPracticeOwner = 0;
                    }

                    await employeeService.updateEmployee(this.model);
                } finally {
                    this.$loading.hide();
                }
                this.$router.push(this.backRoute);
            }
        }

        private openDeleteDialog(item: EmployeeUserDto, deleteMethod: () => void) {
            this.deleteMethod = deleteMethod;
            this.employeeUserToDelete = item;
            this.deleteDialogIsVisible = true;
        }

        private closeDeleteDialog() {
            this.employeeUserToDelete = null;
            this.deleteDialogIsVisible = false;
        }

        private async confirmDelete() {
            this.$loading.show();
            try {
                await this.deleteMethod();
            } finally {
                this.closeDeleteDialog();
                this.$loading.hide();
            }
        }

        private async deleteEmployee() {
            await employeeService.deleteEmployee(this.practiceId, this.employeeSeqNumber);
            this.$router.push(this.backRoute);
        }

        private async approveDeputyUser(deputy: DoctorDeputyWithLastScheduleDto) {
            const command = new DoctorDeputyUpdateCommand();
            command.doctorDeputyKey = deputy.doctorDeputyKey;
            command.deputyUserId = deputy.deputyUserLock.userId;

            // Няма отделен метод за одобряване на потребител на заместващ лекар, защото
            // към 2024-05 updateDoctorDeputy() единствено задава свързаният потребител, което го одобрява като страничен ефект.
            await doctorDeputyService.updateDoctorDeputy(command);
            // Следната безсмислена проверка потиска предупреждение за race condition.
            if (deputy) {
                deputy.deputyUserLock.userIsPending = false;
            }
        }

        private async approveEmployeeUser(employeeUser: EmployeeUserDto) {
            await employeeUserService.approveEmployeeUser(this.practiceId, this.employeeSeqNumber, employeeUser.userId);
            // Следната безсмислена проверка потиска предупреждение за race condition.
            if (employeeUser) {
                employeeUser.userIsPending = false;
            }
        }

        private async deleteEmployeeUserOrInvitation() {
            const employeeUser = this.employeeUserToDelete;
            if (employeeUser) {
                if (employeeUser.userId) {
                    await employeeUserService.deleteEmployeeUser(
                        this.practiceId,
                        this.employeeSeqNumber,
                        employeeUser.userId
                    );
                    arrayUtil.removeAll(
                        this.employeeUsersAndInvitations,
                        (user) => user.userId === employeeUser.userId
                    );
                } else {
                    await employeeUserService.deleteEmployeeUserInvitation(
                        this.practiceId,
                        this.employeeSeqNumber,
                        employeeUser.userName
                    );
                    arrayUtil.removeAll(
                        this.employeeUsersAndInvitations,
                        (user) => user.userName === employeeUser.userName
                    );
                }
            }
        }

        private get backRoute() {
            return `/Employee/List/${this.practiceId}`;
        }

        private getDoctorDeputyRoute(doctorDeputy: DoctorDeputyWithLastScheduleDto) {
            const ddKey = doctorDeputy.doctorDeputyKey;
            return `/Employee/DoctorDeputy/Edit/${ddKey.practiceId}/${ddKey.primaryDoctorEmployeeSeqNumber}/${ddKey.deputyDoctorId}/${ddKey.deputyDoctorIsHired}`;
        }

        private async postDoctorDeputyCheck() {
            this.$loading.show();
            try {
                const xmlString = await nhisDoctorDeputyService.getDoctorDeputyCheckXml(
                    this.practiceId,
                    this.employeeSeqNumber
                );
                const signResponse = await signXmlService.signXml(xmlString);
                if (!signResponse.isError && signResponse?.contents) {
                    const checkResult = await nhisDoctorDeputyService.postDoctorDeputyCheck(signResponse.contents);
                    if (checkResult?.errorReason?.length > 0) {
                        this.$notifier.showWarning('', `${checkResult.errorReason}`);
                    } else {
                        this.deputyCheckResult = checkResult;
                        this.deputyCheckDialogIsVisible = true;
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
