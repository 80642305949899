import { MenuItemModel } from '@/model/Menu/MenuItemModel';
import { userContextCache } from '@/store/User/UserContextCache';

export const createFinanceMenuItems = () => {
    const { currentPracticeId } = userContextCache;

    const menuItems: MenuItemModel[] = [
        new MenuItemModel('Фактури', 'mdi-receipt-text-outline', `/Finance/Invoice/${currentPracticeId}`),
        new MenuItemModel('Стоки и услуги', 'mdi-store-edit-outline', `/Finance/Service/PriceList/${currentPracticeId}`)
    ];

    return menuItems;
};
