import { DoctorDeputyScheduleStatusCode } from '@/enum/Nomenclature/DoctorDeputyScheduleStatusCode';

export class DoctorDeputyScheduleDto {
    public id: number = 0;
    public validFrom: Date | null = null;
    public validTo: Date | null = null;
    public statusCode: DoctorDeputyScheduleStatusCode | null = null;
    public statusName: string | null = null;
    public statusTime: Date | null = null;
}
