<template>
    <div>
        <v-data-iterator
            :items="items"
            no-data-text=""
            :search="search"
            no-results-text="Няма намерени резултати"
            :sort-by="sortBy.toLowerCase()"
            :sort-desc="Boolean(sortDesc)"
            :footer-props="{ itemsPerPageText: 'Брой на страница' }"
            :disable-pagination="!showGrouped"
            :hide-default-footer="!showGrouped"
        >
            <template #header>
                <v-toolbar color="primary" dark class="mb-1">
                    <search-field v-if="items && items.length" v-model="search" label="Търсене" />
                    <span v-else>Няма съобщения</span>

                    <template v-if="showSortOptions && $vuetify.breakpoint.mdAndUp">
                        <v-spacer />
                        <v-select
                            v-model="sortBy"
                            flat
                            solo-inverted
                            hide-details
                            :items="keys"
                            prepend-inner-icon="mdi-sort"
                            label="Сортиране по"
                        />
                        <v-spacer />
                        <v-btn-toggle v-model="sortDesc" mandatory>
                            <v-btn large depressed color="primary" :value="false">
                                <v-icon>mdi-arrow-up</v-icon>
                            </v-btn>
                            <v-btn large depressed color="primary" :value="true">
                                <v-icon>mdi-arrow-down</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </template>
                </v-toolbar>
            </template>

            <template #default="{ items }">
                <v-row>
                    <v-col
                        v-for="notification in items"
                        :key="notification.id"
                        cols="12"
                        :md="showGrouped ? '4' : '12'"
                        :lg="showGrouped ? '3' : '12'"
                    >
                        <div class="overflow-auto">
                            <v-card-actions class="py-0">
                                <v-avatar>
                                    <v-btn icon @click="selectedNotification = notification">
                                        <v-icon large :color="notification.color">{{ notification.icon }}</v-icon>
                                    </v-btn>
                                </v-avatar>
                                <v-spacer />
                                <btn icon action="Close" color="error" @click.stop="removeNotification(notification)">
                                    Изтриване
                                </btn>
                            </v-card-actions>
                            <v-card-title v-if="notification.title" class="pt-0">
                                <div v-for="(line, index) in notification.title.split('\r\n')" :key="index">
                                    {{ line }}
                                </div>
                            </v-card-title>
                            <v-card-subtitle>{{ notification.timeText }}</v-card-subtitle>
                            <v-card-text class="py-0">
                                <pre class="overflow-y-scroll expandable-text">{{ notification.text }}</pre>
                            </v-card-text>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>

        <!-- Модален диалог за по-широко показване на избрано съобщение от списъка. -->
        <v-dialog
            v-if="selectedNotification"
            ref="notificationDetailsDialog"
            v-model="selectedNotification"
            max-width="80%"
        >
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="auto">
                            <v-icon x-large class="mt-n2" :color="selectedNotification.color">
                                {{ selectedNotification.icon }}
                            </v-icon>
                        </v-col>
                        <v-col>
                            <div v-if="selectedNotification.title">
                                <div v-for="(line, index) in selectedNotification.title.split('\r\n')" :key="index">
                                    {{ line }}
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-subtitle>{{ selectedNotification.timeText }}</v-card-subtitle>
                <v-card-text>
                    <p class="overflow-y-scroll">{{ selectedNotification.text }}</p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NotificationModel } from '@/model/Notification/NotificationModel';
    import { notifications } from '@/store/Notifications';

    @Component
    export default class NotificationList extends Vue {
        @Prop({ required: true })
        private items!: NotificationModel[];

        @Prop({ required: true })
        private showGrouped!: boolean;

        @Prop({ required: true })
        private showSortOptions!: boolean;

        private removeNotification(notification: NotificationModel) {
            notifications.remove(notification);
        }

        private selectedNotification: NotificationModel | null = null;

        private search: string = '';
        private sortBy: string = 'time';
        private sortDesc: boolean = true;
        private keys: string[] = ['title', 'text', 'sort', 'type', 'time'];

        get filteredKeys(): string[] {
            return this.keys.filter((key) => key !== 'title');
        }
    }
</script>
