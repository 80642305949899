import { plainToInstance } from 'class-transformer';

import { CustomerCreditDto } from '@/model/Licensing/CustomerCreditDto';
import { CustomerCreditUpdateCommand } from '@/model/Licensing/CustomerCreditUpdateCommand';
import { httpService } from '@/service/Infrastructure/HttpService';

export const customerCreditService = {
    async getCustomerCredits(practiceId: number): Promise<CustomerCreditDto[]> {
        const response = await httpService.get<CustomerCreditDto[]>('CustomerCredit/GetCustomerCredits', {
            params: { practiceId }
        });
        return plainToInstance(CustomerCreditDto, response.data);
    },

    async getCustomerCredit(customerId: number, seqNumber: number): Promise<CustomerCreditDto> {
        const response = await httpService.get<CustomerCreditDto>('CustomerCredit/GetCustomerCredit', {
            params: { customerId, seqNumber }
        });
        return plainToInstance(CustomerCreditDto, response.data);
    },

    downloadPaymentOrderFile(customerId: number, seqNumber: number): Promise<void> {
        return httpService.download('CustomerCredit/DownloadPaymentOrderFile', { params: { customerId, seqNumber } });
    },

    async updateCustomerCreditAndAdjustPendingPayments(
        dto: CustomerCreditDto,
        fileData: ArrayBuffer | null
    ): Promise<void> {
        // Лекарят може само да качва платежно и да променя собствените си бележки.
        const command = new CustomerCreditUpdateCommand();
        command.customerId = dto.customerId;
        command.seqNumber = dto.seqNumber;
        command.paymentOrderFileName = dto.paymentOrderFileName;
        command.paymentOrderETag = dto.paymentOrderETag;
        command.paymentOrderFileContent = fileData ? Array.from(new Uint8Array(fileData)) : null;
        command.publicNotes = dto.publicNotes;
        await httpService.put('CustomerCredit/UpdateCustomerCreditAndAdjustPendingPayments', command);
    }
};
