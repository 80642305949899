import { AxiosResponse } from 'axios';

import { ExamNotSignedDto } from '@/model/Query/Exam/ExamNotSignedDto';
import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const examNotSignedService = {
    getExamNotSigned(filter: ReportIntervalFilterDto): Promise<AxiosResponse<ExamNotSignedDto[]>> {
        return httpService.post('ExamNotSigned/GetNotSignedExams', filter);
    }
};
