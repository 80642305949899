<template>
    <div>
        <v-row>
            <v-col cols="12"><h3 class="mb-2">ДЕЙНОСТИ, СВЪРЗАНИ С ДЕТСКОТО ЗДРАВЕОПАЗВАНЕ</h3></v-col>
            <v-col cols="12"><h3 class="mb-2">1. Посещения на деца от 0 до 7 години</h3></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><DataTableComponent v-model="value.childrenVisits" :headers="headers" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Annex3ChildrenVisitDto } from '@/model/Report/Rhi/Annex3/Annex3ChildrenVisitDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class ChildrenVisit extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Annex3ChildrenVisitDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: 'Шифър', value: 'code' },
            { text: 'В консултацията', value: 'totalAmbulatory' },
            { text: 'По домовете', value: 'totalDomestic' }
        ];
    }
</script>
