import { plainToInstance } from 'class-transformer';

import { PaymentMethodDto } from '@/model/Nomenclature/PaymentMethodDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const paymentMethodService = {
    async getPaymentMethods(): Promise<PaymentMethodDto[]> {
        const response = await httpService.get<PaymentMethodDto[]>('PaymentMethod/GetPaymentMethods');
        return plainToInstance(PaymentMethodDto, response?.data);
    }
};
