import { AxiosResponse } from 'axios';

import { RuleFilterDto } from '@/model/Exam/RuleFilterDto';
import { ChildrenHealthRegisterDto } from '@/model/Register/ChildrenHealth/ChildrenHealthRegisterDto';
import { ChildrenHealthRuleDto } from '@/model/Register/ChildrenHealth/ChildrenHealthRuleDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const childrenHealthRegisterService = {
    getAll(id: number): Promise<AxiosResponse<ChildrenHealthRegisterDto[]>> {
        return httpService.get(`/ChildrenHealthRegister/GetAll/${id}`);
    },

    get(id: number): Promise<AxiosResponse<ChildrenHealthRegisterDto>> {
        return httpService.get(`/ChildrenHealthRegister/GetChildrenHealthRegister/${id}`);
    },

    exportToExcel(id: number): Promise<void> {
        return httpService.download(`/ChildrenHealthRegister/ExportToExcel/${id}`);
    },

    create(data: ChildrenHealthRegisterDto): Promise<AxiosResponse<ChildrenHealthRegisterDto>> {
        return httpService.post('/ChildrenHealthRegister/CreateChildrenHealthRegister', data);
    },

    update(data: ChildrenHealthRegisterDto): Promise<AxiosResponse<ChildrenHealthRegisterDto>> {
        return httpService.put('/ChildrenHealthRegister/UpdateChildrenHealthRegister/', data);
    },

    delete(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/ChildrenHealthRegister/DeleteChildrenHealthRegister/${id}`);
    },

    searchChildrenHealthRule(data: RuleFilterDto): Promise<AxiosResponse<ChildrenHealthRuleDto[]>> {
        return httpService.post('/ChildrenHealthRegister/SearchChildrenHealthRule', data);
    }
};
