<template>
    <v-row>
        <v-col cols="10" class="p-0" style="padding-right: 0">
            <dropdown-search
                :value="selectedIcdValue"
                :service="icdService"
                item-value="code"
                :label="label"
                :required="required"
                :disabled="disabled"
                :readonly="readonly"
                @input="$emit('input', ...arguments)"
            />
        </v-col>
        <v-col cols="2" style="padding-left: 0">
            <btn action="Details" :disabled="disabled" icon @click="detailedDialogIsVisible = true" />
        </v-col>

        <icd-detailed-picker-dialog
            :dialog-is-visible="detailedDialogIsVisible"
            @cancel="detailedDialogIsVisible = false"
            @icd10Picked="icd10PickedFromDialog"
        ></icd-detailed-picker-dialog>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import DropdownSearch from '@/component/Dropdown/DropdownSearch.vue';
    import IcdDetailedPickerDialog from '@/component/Picker/IcdDetailedPickerDialog.vue';
    import { icdService } from '@/service/Nomenclature/IcdService';

    @Component({ components: { IcdDetailedPickerDialog, DropdownSearch } })
    export default class IcdPicker extends Vue {
        @Prop()
        private value!: string;

        @Prop({ default: 'МКБ' })
        private label!: string;

        @Prop()
        private required!: boolean;

        @Prop()
        private disabled!: boolean;

        @Prop()
        private readonly!: boolean;

        private selectedIcdValue: string = '';

        private detailedDialogIsVisible: boolean = false;

        private get icdService() {
            return icdService;
        }

        private icd10PickedFromDialog(pickedIcd10Code: string) {
            this.selectedIcdValue = pickedIcd10Code;
            this.detailedDialogIsVisible = false;
            this.$emit('input', this.selectedIcdValue);
        }

        private mounted() {
            this.selectedIcdValue = this.value;
        }

        @Watch('value')
        private onValueChanged() {
            this.selectedIcdValue = this.value;
        }
    }
</script>
