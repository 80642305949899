import { AxiosResponse } from 'axios';

import { NhifVisitReasonDto } from '@/model/Nomenclature/NhifVisitReasonDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nhifVisitReasonService = {
    getNhifVisitReasons(): Promise<AxiosResponse<NhifVisitReasonDto[]>> {
        return httpService.get('/NhifVisitReason/GetNhifVisitReasons');
    }
};
