<template>
    <div>
        <v-card-title>
            <div v-if="!seqNumber">Добавяне на нов Формуляр 365</div>
            <div v-else>Преглед/редактиране на Формуляр 365</div>
        </v-card-title>
        <v-card>
            <v-card-title>Данни за формуляр</v-card-title>
            <v-card-text>
                <v-row>
                    <v-icon @click="decrease">mdi-arrow-left-thick</v-icon>
                    <v-col cols="12" md="4">
                        <date-picker v-model="report.startDate" label="От дата" required />
                    </v-col>
                    <v-col cols="12" md="4">
                        <date-picker v-model="report.endDate" label="До дата" required />
                    </v-col>
                    <v-icon @click="increase">mdi-arrow-right-thick</v-icon>
                    <v-col cols="12" md="3">
                        <btn action="Refresh" class="ma-2" @click="recreate"> Обновяване </btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-tabs dark background-color="primary" show-arrows align-with-title>
                            <v-tab>Формуляр 365</v-tab>
                            <v-tab>Приложение 2</v-tab>
                            <v-tab>Приложение 3</v-tab>
                            <v-tab>Приложение 4</v-tab>
                            <v-tab>Приложение 5,6</v-tab>

                            <v-tab-item>
                                <v-card-title>Детайли</v-card-title>

                                <v-col cols="12">
                                    <v-tabs dark background-color="primary" show-arrows align-with-title>
                                        <v-tab>II.</v-tab>
                                        <!--<v-tab>IIA.1.</v-tab>
                                        <v-tab>IIA.2.</v-tab>-->
                                        <v-tab>III.1.</v-tab>
                                        <v-tab>III.2.</v-tab>
                                        <v-tab>III.3.</v-tab>
                                        <v-tab>III.4.</v-tab>
                                        <v-tab>IV.</v-tab>
                                        <v-tab>V.1.</v-tab>
                                        <v-tab>V.1a.</v-tab>
                                        <v-tab>V.2.</v-tab>
                                        <v-tab>V.2a.</v-tab>
                                        <v-tab>V.3.</v-tab>
                                        <v-tab>VI.1.</v-tab>
                                        <v-tab>VI.2.</v-tab>
                                        <v-tab>VI.3.</v-tab>
                                        <v-tab>VI.4.</v-tab>
                                        <v-tab>VI.5.</v-tab>
                                        <v-tab>X.1.</v-tab>
                                        <v-tab>X.2.</v-tab>
                                        <v-tab>XI.</v-tab>
                                        <v-tab>XII.</v-tab>
                                        <v-tab-item>
                                            <v-col cols="12"><Staff v-model="report.items.staff" /></v-col>
                                        </v-tab-item>
                                        <!--<v-tab-item>
                                            <v-col cols="12"
                                                ><DoctorTrainee v-model="report.items.doctorTrainee"
                                            /></v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <HealthCareTrainee v-model="report.items.healthCareTrainee" />
                                            </v-col>
                                        </v-tab-item>-->
                                        <v-tab-item>
                                            <v-col cols="12"><Visit v-model="report.items.visit" /></v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <PreventiveActivity v-model="report.items.preventiveActivity" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <SurgicalActivity v-model="report.items.surgicalActivity" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <DispensaryActivity v-model="report.items.dispensaryActivity" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12"><Bed v-model="report.items.bed" /></v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <Abortion v-model="report.items.abortion" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <AbortionByAge v-model="report.items.abortionByAge" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <PreventiveGynecologistActivity
                                                    v-model="report.items.preventiveGynecologistActivity"
                                                />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <PreventivePregnancyActivity
                                                    v-model="report.items.preventivePregnancyActivity"
                                                />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <AssistedReproduction v-model="report.items.assistedReproduction" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <PsychiatricStructure v-model="report.items.psychiatricStructure" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <PsychiatricPatient v-model="report.items.psychiatricPatient" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <PsychiatricAccident v-model="report.items.psychiatricAccident" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <PsychiatricExpertise v-model="report.items.psychiatricExpertise" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <PsychiatricSick v-model="report.items.psychiatricSick" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <MedicalEquipment v-model="report.items.medicalEquipment" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <PerformedTest v-model="report.items.performedTest" />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <PhysiotherapeuticActivity
                                                    v-model="report.items.physiotherapeuticActivity"
                                                />
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <Transportation v-model="report.items.transportation" />
                                            </v-col>
                                        </v-tab-item>
                                    </v-tabs>
                                </v-col>
                                <v-card-actions>
                                    <v-col v-if="seqNumber" cols="12" md="2">
                                        <btn action="Export" @click="exportReport365"> </btn>
                                    </v-col>
                                </v-card-actions>
                            </v-tab-item>
                            <v-tab-item>
                                <v-col cols="12">
                                    <Annex2Component v-model="report" :report-key="reportKey" />
                                </v-col>
                            </v-tab-item>
                            <v-tab-item>
                                <v-col cols="12">
                                    <Annex3Component v-model="report" :report-key="reportKey" />
                                </v-col>
                            </v-tab-item>

                            <v-tab-item>
                                <v-card-title>Детайли</v-card-title>

                                <v-col cols="12">
                                    <v-tabs dark background-color="primary" show-arrows align-with-title>
                                        <v-tab>I.</v-tab>
                                        <v-tab-item>
                                            <v-col cols="12">
                                                <ChildrenPreventiveResult
                                                    v-model="report.annex4Items.childrenPreventiveResult"
                                                />
                                            </v-col>
                                        </v-tab-item>
                                    </v-tabs>
                                </v-col>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-actions>
                                            <v-col v-if="seqNumber" cols="6" md="2">
                                                <btn action="Export" block @click="exportAnnex4"> </btn>
                                            </v-col>
                                        </v-card-actions>
                                    </v-col>
                                </v-row>
                            </v-tab-item>

                            <v-tab-item>
                                <v-col cols="12">
                                    <Annex56Component v-model="report" :report-key="reportKey" />
                                </v-col>
                            </v-tab-item>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <btn action="Back" :to="backRoute" />
                <btn action="Save" @click="updateReport" />
                <v-spacer />
                <btn v-if="seqNumber" action="Delete" @click="deleteReport365" />
            </v-card-actions>
        </v-card>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { Report365Dto } from '@/model/Report/Rhi/Report365/Report365Dto';
    import { RhiReportKey } from '@/model/Report/Rhi/RhiReportKey';
    import { report365Service } from '@/service/Report/Rhi/Report365/Report365Service';
    import { dateUtil } from '@/util/DateUtil';
    import Abortion from '@/view/Report/Rhi/Report365/Abortion.vue';
    import AbortionByAge from '@/view/Report/Rhi/Report365/AbortionByAge.vue';
    import Annex2Component from '@/view/Report/Rhi/Report365/Annex2/Annex2Component.vue';
    import Annex3Component from '@/view/Report/Rhi/Report365/Annex3/Annex3Component.vue';
    import ChildrenPreventiveResult from '@/view/Report/Rhi/Report365/Annex4/ChildrenPreventiveResult.vue';
    import Annex56Component from '@/view/Report/Rhi/Report365/Annex56/Annex56Component.vue';
    import AssistedReproduction from '@/view/Report/Rhi/Report365/AssistedReproduction.vue';
    import Bed from '@/view/Report/Rhi/Report365/Bed.vue';
    import DispensaryActivity from '@/view/Report/Rhi/Report365/DispensaryActivity.vue';
    //import DoctorTrainee from '@/view/Report/Rhi/Report365/DoctorTrainee.vue';
    //import HealthCareTrainee from '@/view/Report/Rhi/Report365/HealthCareTrainee.vue';
    import MedicalEquipment from '@/view/Report/Rhi/Report365/MedicalEquipment.vue';
    import PerformedTest from '@/view/Report/Rhi/Report365/PerformedTest.vue';
    import PhysiotherapeuticActivity from '@/view/Report/Rhi/Report365/PhysiotherapeuticActivity.vue';
    import PreventiveActivity from '@/view/Report/Rhi/Report365/PreventiveActivity.vue';
    import PreventiveGynecologistActivity from '@/view/Report/Rhi/Report365/PreventiveGynecologistActivity.vue';
    import PreventivePregnancyActivity from '@/view/Report/Rhi/Report365/PreventivePregnancyActivity.vue';
    import PsychiatricAccident from '@/view/Report/Rhi/Report365/PsychiatricAccident.vue';
    import PsychiatricExpertise from '@/view/Report/Rhi/Report365/PsychiatricExpertise.vue';
    import PsychiatricPatient from '@/view/Report/Rhi/Report365/PsychiatricPatient.vue';
    import PsychiatricSick from '@/view/Report/Rhi/Report365/PsychiatricSick.vue';
    import PsychiatricStructure from '@/view/Report/Rhi/Report365/PsychiatricStructure.vue';
    import Staff from '@/view/Report/Rhi/Report365/Staff.vue';
    import SurgicalActivity from '@/view/Report/Rhi/Report365/SurgicalActivity.vue';
    import Transportation from '@/view/Report/Rhi/Report365/Transportation.vue';
    import Visit from '@/view/Report/Rhi/Report365/Visit.vue';

    const defaultHours: number = 12;

    @Component({
        components: {
            //DoctorTrainee,
            //HealthCareTrainee,
            Visit,
            PreventiveActivity,
            SurgicalActivity,
            DispensaryActivity,
            Bed,
            Abortion,
            AbortionByAge,
            PreventiveGynecologistActivity,
            PreventivePregnancyActivity,
            AssistedReproduction,
            MedicalEquipment,
            PerformedTest,
            PhysiotherapeuticActivity,
            Transportation,
            PsychiatricStructure,
            PsychiatricPatient,
            PsychiatricAccident,
            PsychiatricExpertise,
            PsychiatricSick,
            Staff,
            ChildrenPreventiveResult,
            Annex3Component,
            Annex2Component,
            Annex56Component
        }
    })
    export default class Report365Edit extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        @Prop()
        private seqNumber!: number;

        private report: Report365Dto = new Report365Dto();

        private get reportKey() {
            return new RhiReportKey(this.practiceId, this.doctorEmployeeSeqNumber, this.seqNumber);
        }

        private async mounted() {
            this.$loading.show();
            try {
                if (this.seqNumber) {
                    this.report = await report365Service.getReport365(this.reportKey);
                    //await this.loadImmunizations();
                } else {
                    this.report.startDate = dateUtil.startOfLastYear();
                    this.report.endDate = dateUtil.endOfLastYear();
                    await this.getReport365InitialItems();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private recreate() {
            this.getReport365InitialItems();
        }

        private async getReport365InitialItems() {
            this.report = await report365Service.getReport365InitialItems(
                this.practiceId,
                this.doctorEmployeeSeqNumber,
                this.report.startDate,
                this.report.endDate
            );
        }

        private increase() {
            if (this.report.startDate) {
                this.report.startDate = this.increaseDate(this.report.startDate);
            }
            if (this.report.endDate) {
                this.report.endDate = this.increaseDate(this.report.endDate);
            }
        }

        private decrease() {
            if (this.report.startDate) {
                this.report.startDate = this.decreaseDate(this.report.startDate);
            }
            if (this.report.endDate) {
                this.report.endDate = this.decreaseDate(this.report.endDate);
            }
        }

        private decreaseDate(date: Date) {
            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            return new Date(year - 1, month, day, defaultHours);
        }

        private increaseDate(date: Date) {
            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            return new Date(year + 1, month, day, defaultHours);
        }

        private async updateReport() {
            if (!this.seqNumber) {
                const newSeqNumber = await report365Service.createReport365(
                    this.practiceId,
                    this.doctorEmployeeSeqNumber,
                    this.report
                );
                this.$router.push(
                    `/Report/Rhi/Report365/Edit/${this.practiceId}/${this.doctorEmployeeSeqNumber}/${newSeqNumber}`
                );
            } else {
                await report365Service.updateReport365(this.reportKey, this.report);
            }
            //this.$router.push(this.backRoute);
        }

        private async exportReport365() {
            if (this.seqNumber) {
                this.$loading.show();
                try {
                    await report365Service.exportReport365(this.reportKey);
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private async exportAnnex4() {
            if (this.seqNumber) {
                this.$loading.show();
                try {
                    await report365Service.exportAnnex4(this.reportKey);
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private get backRoute() {
            return `/Report/Rhi/Report365/${this.practiceId}/${this.doctorEmployeeSeqNumber}`;
        }

        private async deleteReport365() {
            await report365Service.deleteReport365(this.reportKey);
            this.$router.push(this.backRoute);
        }
    }
</script>
