import { config } from '@/Config';
import { PracticeCategoryCode } from '@/enum/Practice/PracticeCategoryCode';
import { UserContextDto } from '@/model/User/UserContext/UserContextDto';
import { userContextCache } from '@/store/User/UserContextCache';

import { MenuItemModel } from './MenuItemModel';

// Редактиране на основните данни на лекаря и на заместници/наети/права, ако служителят е лекар.
// Редактиране на правата на служител, който не е лекар.
const addEmployeeMenuItems = (menuItems: MenuItemModel[], currentUserContext: UserContextDto) => {
    const { practiceId, employeeSeqNumber, employeeDescription, doctorId, doctorName } = currentUserContext;

    if (doctorId) {
        menuItems.push(
            new MenuItemModel('Лекар', '', ''),
            new MenuItemModel(doctorName ?? `Лекар с id ${doctorId}`, 'mdi-doctor', `/Practice/DoctorEdit/${doctorId}`),
            new MenuItemModel(
                'Настройки на служителя',
                'mdi-pencil',
                `/Employee/Edit/${practiceId}/${employeeSeqNumber}`
            )
        );
    } else {
        menuItems.push(
            new MenuItemModel('Служител', '', ''),
            new MenuItemModel(
                employeeDescription ?? `Служител № ${employeeSeqNumber}`,
                'mdi-pencil',
                `/Employee/Edit/${practiceId}/${employeeSeqNumber}`
            )
        );
    }
};

const addLicensingMenuItems = (menuItems: MenuItemModel[], practiceId: number) => {
    menuItems.push(
        new MenuItemModel('Контракс', '', ''),
        new MenuItemModel('Лицензи', 'mdi-lock-open-outline', `/Practice/Licensing/${practiceId}`)
    );
};

export const createPracticeMenuItems = () => {
    const { current } = userContextCache;
    if (!current) {
        return [];
    }
    const { practiceId, practiceName, practiceCategoryCode } = current;
    const menuItems: MenuItemModel[] = [];

    // Управление на практиката.
    const practiceTitle = practiceName ?? `Практика с id ${practiceId}`;
    menuItems.push(
        new MenuItemModel(practiceTitle, '', ''),
        new MenuItemModel('Основни данни', 'mdi-hospital-box-outline', `/Practice/Edit/${practiceId}`),
        new MenuItemModel('Персонал', 'mdi-account-multiple-outline', `/Employee/List/${practiceId}`),

        /*new MenuItemModel('Фискални у-ва', 'mdi-cash-register', `/Practice/FiscalMemory/List/${practiceId}`),*/
        new MenuItemModel('Фискални у-ва', 'mdi-cash-register', `/Practice/Fiscal/Device/List/${practiceId}`),
        new MenuItemModel(
            'Договори с НЗОК',
            'mdi-file-document-edit-outline',
            `/Practice/NhifContract/List/${practiceId}`
        ),
        new MenuItemModel('Регулативен стандарт', 'mdi-gauge', `/Practice/RegulatoryStandard/List/${practiceId}`)
    );

    if (config.isDevOrTest) {
        if (practiceCategoryCode !== PracticeCategoryCode.Gp) {
            menuItems.push(new MenuItemModel('Звена (DevTest)', 'mdi-domain', `/Practice/PracticeUnit/${practiceId}`));
        }

        menuItems.push(new MenuItemModel('Кабинети (DevTest)', 'mdi-door', `/Practice/Rooms/${practiceId}`));
    }

    if (practiceCategoryCode === PracticeCategoryCode.Special) {
        menuItems.push(
            new MenuItemModel('ЛКК комисии', 'mdi-account-group', `/Practice/LkkCommittee/List/${practiceId}`)
        );
    }

    addEmployeeMenuItems(menuItems, current);
    addLicensingMenuItems(menuItems, practiceId);
    return menuItems;
};
