import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';

export class EmployeeCreateCommand {
    public practiceId: number = 0;
    public doctorUin: string = '';
    // За не-лекар съдържа employee.Description.
    public doctorName: string = '';
    public doctorEmployeeSpecialtyCode: SpecialtyCode | null = null;
    // Не се използва при добавяне през "Персонал", а само при саморегистриране.
    public employeeIsPracticeOwner: boolean = false;
}
