import { plainToInstance } from 'class-transformer';

import { RhiImmunizationReportDetailDto } from '@/model/Report/Rhi/Immunization/RhiImmunizationReportDetailDto';
import { RhiImmunizationReportDto } from '@/model/Report/Rhi/Immunization/RhiImmunizationReportDto';
import { RhiReportKey } from '@/model/Report/Rhi/RhiReportKey';
import { RhiReportRowDto } from '@/model/Report/Rhi/RhiReportRowDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const immunizationReportService = {
    async getRhiImmunizationReports(practiceId: number, doctorEmployeeSeqNumber: number): Promise<RhiReportRowDto[]> {
        const response = await httpService.get<RhiReportRowDto[]>('RhiImmunizationReport/GetRhiImmunizationReports', {
            params: { practiceId, doctorEmployeeSeqNumber }
        });
        return plainToInstance(RhiReportRowDto, response.data);
    },

    async getRhiImmunizationReport(key: RhiReportKey): Promise<RhiImmunizationReportDto> {
        const response = await httpService.get<RhiImmunizationReportDto>(
            'RhiImmunizationReport/GetRhiImmunizationReport',
            { params: key }
        );
        return plainToInstance(RhiImmunizationReportDto, response.data);
    },

    async createRhiImmunizationReport(
        practiceId: number,
        doctorEmployeeSeqNumber: number,
        data: RhiImmunizationReportDto
    ): Promise<RhiImmunizationReportDto> {
        const response = await httpService.post<RhiImmunizationReportDto>(
            'RhiImmunizationReport/CreateRhiImmunizationReport',
            data,
            { params: { practiceId, doctorEmployeeSeqNumber } }
        );
        return plainToInstance(RhiImmunizationReportDto, response.data);
    },

    async updateRhiImmunizationReport(key: RhiReportKey, data: RhiImmunizationReportDto): Promise<void> {
        await httpService.put('RhiImmunizationReport/UpdateRhiImmunizationReport', data, { params: key });
    },

    async getInitialImmunizationsForRhiReport(
        practiceId: number,
        doctorEmployeeSeqNumber: number,
        startDate: Date,
        endDate: Date
    ): Promise<RhiImmunizationReportDetailDto[]> {
        const response = await httpService.get<RhiImmunizationReportDetailDto[]>(
            'RhiImmunizationReport/GetInitialImmunizationsForRhiReport',
            { params: { practiceId, doctorEmployeeSeqNumber, startDate, endDate } }
        );
        return plainToInstance(RhiImmunizationReportDetailDto, response.data);
    },

    async getStoredImmunizationsForRhiReport(key: RhiReportKey): Promise<RhiImmunizationReportDetailDto[]> {
        const response = await httpService.get<RhiImmunizationReportDetailDto[]>(
            'RhiImmunizationReport/GetStoredImmunizationsForRhiReport',
            { params: key }
        );
        return plainToInstance(RhiImmunizationReportDetailDto, response.data);
    },

    async deleteRhiImmunizationReport(key: RhiReportKey): Promise<void> {
        await httpService.delete('RhiImmunizationReport/DeleteRhiImmunizationReport', { params: key });
    }
};
