import { AxiosResponse } from 'axios';

import { Referral3Dto } from '@/model/Exam/Referral/Referral3Dto';
import { Referral3ViewDto } from '@/model/Exam/Referral/Referral3ViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const referral3Service = {
    getReferral3InfoByExamId(examId: number): Promise<AxiosResponse<Referral3Dto>> {
        return httpService.get(`/Referral3/GetReferral3InfoByExamId/${examId}`);
    },

    getReferral3aInfoByExamId(examId: number): Promise<AxiosResponse<Referral3Dto>> {
        return httpService.get(`/Referral3/GetReferral3aInfoByExamId/${examId}`);
    },

    canSaveReferral3(data: Referral3Dto): Promise<AxiosResponse<string[]>> {
        return httpService.post('/Referral3/CanSaveReferral3', data);
    },

    canSaveReferral3a(data: Referral3Dto): Promise<AxiosResponse<string[]>> {
        return httpService.post('/Referral3/CanSaveReferral3a', data);
    },

    getReferral3(id: number): Promise<AxiosResponse<Referral3Dto>> {
        return httpService.get(`/Referral3/GetReferral3/${id}`);
    },

    createReferral3a(data: Referral3Dto): Promise<AxiosResponse<Referral3Dto>> {
        return httpService.post('/Referral3/CreateReferral3a', data);
    },

    createReferral3(data: Referral3Dto): Promise<AxiosResponse<Referral3Dto>> {
        return httpService.post('/Referral3/CreateReferral3', data);
    },

    updateReferral3(data: Referral3Dto): Promise<AxiosResponse<Referral3Dto>> {
        return httpService.put('/Referral3/UpdateReferral3/', data);
    },

    deleteReferral3(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/Referral3/DeleteReferral3/${id}`);
    },

    getReferralsByExamId(examId: number, isSpecialized: boolean): Promise<AxiosResponse<Referral3ViewDto[]>> {
        return httpService.get(`/Referral3/GetReferral3Views`, {
            params: {
                examId,
                isSpecialized
            }
        });
    },

    checkPreviousReferralBySpecialtyCode(
        referralId: number,
        examId: number,
        specialtyCode: string,
        isSpecialized: boolean
    ): Promise<AxiosResponse<string>> {
        return httpService.get(`/Referral3/CheckPreviousReferralBySpecialtyCode`, {
            params: {
                referralId,
                examId,
                specialtyCode,
                isSpecialized
            }
        });
    }
};
