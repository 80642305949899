<template>
    <div>
        <v-card>
            <main-title>Проверка на изпратени документи към НЗОК</main-title>
            <v-card-text>
                <v-row v-for="(document, index) in documents" :key="index">
                    <v-col md="3" cols="6">
                        <text-field
                            v-model="document.sequenceId"
                            type="number"
                            required
                            label="Пореден номер на документа:"
                        />
                    </v-col>
                    <v-col md="3" cols="6">
                        <text-field v-model="document.documentType" disabled label="Тип на документа:" />
                    </v-col>
                    <v-col md="3" cols="6">
                        <text-field v-model="document.documentName" disabled label="Име на документа:" />
                    </v-col>
                    <v-col md="3" cols="6">
                        <text-field v-model="document.documentFormat" disabled label="Формат на документа:" />
                    </v-col>
                    <v-col md="3" cols="6">
                        <text-field v-model="document.documentBytes" disabled label="Брой байтове на файла:" />
                    </v-col>
                    <v-col v-if="document && document.documentContent" md="3" cols="6">
                        <base64-to-file
                            v-if="document && document.documentContent"
                            v-model="document.documentContent"
                            show-open-btn
                            :type="fileType(index)"
                        />
                    </v-col>
                    <v-col cols="2" class="px-1">
                        <btn
                            action="CheckDocument"
                            :disabled="isLoading"
                            @click="checkFiles(document.sequenceId, index)"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card-actions> </v-card-actions>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import Base64ToFile from '@/component/File/Base64ToFile.vue';
    import { NhifProtocolDocumentCheckDto } from '@/model/Exam/DrugProtocol/NhifProtocolDocumentCheckDto';
    import { NhifProtocolDocumentCheckGetJsonCommand } from '@/model/Exam/DrugProtocol/NhifProtocolDocumentCheckGetJsonCommand';
    import { drugProtocolService } from '@/service/Exam/Protocol/DrugProtocolService';
    import { notifierService } from '@/service/Infrastructure/NotifierService';
    import { nhifIntegrationService } from '@/service/Integration/NhifIntegrationService';
    import { nhifProtocolService } from '@/service/Nhif/NhifProtocolService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { loadingState } from '@/store/LoadingState';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { Base64ToFile }
    })
    export default class NhifProtocolDocumentCheck extends Vue {
        @Prop()
        private nrn!: string;

        @Prop()
        private documentId!: number;

        private documents: NhifProtocolDocumentCheckDto[] = [];

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private fileType(index: number) {
            return this.documents[index].documentFormat === 'pdf' ? 'application/pdf' : 'application/x-zip-compressed';
        }

        private async checkFiles(sequenceId: number, index: number) {
            loadingState.show();
            try {
                const jsonResponse = await nhifProtocolService.getProtocolDocumentCheckJson(
                    this.createNhifCommand(sequenceId)
                );
                if (jsonResponse.data) {
                    const response = await nhifIntegrationService.postProtocolDocumentCheck(jsonResponse.data);
                    const result = await nhifProtocolService.getProtocolDocumentCheckResult(
                        JSON.stringify(response?.data)
                    );
                    if (result?.error?.length > 0) {
                        await notifierService.showWarning('Внимание', `${result.error}`);
                    } else if (result) {
                        this.documents[index].sequenceId = result.sequenceId;
                        this.documents[index].documentType = result.documentType;
                        this.documents[index].documentFormat = result.documentFormat;
                        this.documents[index].documentBytes = result.documentBytes;
                        this.documents[index].documentContent = result.documentContent;
                    }
                }
            } finally {
                loadingState.hide();
            }
        }

        private async getUploadedProtocolInfo() {
            this.documents = await drugProtocolService.getUploadedDocumentsInfo(this.documentId);
        }

        private createNhifCommand(sequenceId: number) {
            const command = new NhifProtocolDocumentCheckGetJsonCommand(
                currentPatientCache.value.key,
                userContextCache.current?.doctorUin ?? null,
                userContextCache.current?.deputyDoctorUin ?? null
            );
            command.deputyDoctorIsHired = userContextCache.current?.deputyDoctorUin
                ? userContextCache.current?.deputyDoctorIsHired
                : null;
            command.sequenceId = sequenceId;
            command.entityId = this.documentId;

            return command;
        }

        private async mounted() {
            await this.getUploadedProtocolInfo();
        }
    }
</script>
