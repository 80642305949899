<template>
    <v-card>
        <v-card-title>Видео уроци</v-card-title>
        <v-card-text>
            <VideoPlayer
                is-you-tube
                title="Заместващ лекар (Регистриране в програмата)"
                url="https://www.youtube.com/embed/CDzaq0f7VzQ"
            />
            <VideoPlayer
                is-you-tube
                title="Заместващ лекар (Подаване към НЗИС)"
                url="https://www.youtube.com/embed/KgliJEX_WX4"
            />
            <VideoPlayer
                is-you-tube
                title="Работа с пациенти (Добавяне и търсене)"
                url="https://www.youtube.com/embed/pqouZmOhdKA"
            />
            <VideoPlayer
                is-you-tube
                title="Работа с пациенти (Редактиране и добавяне на допълнителни данни)"
                url="https://www.youtube.com/embed/aqdUQsh3FqM"
            />
            <VideoPlayer
                is-you-tube
                title="Създаване преглед на пациент"
                url="https://www.youtube.com/embed/NoMH35o1JQw"
            />
            <VideoPlayer
                title="Създаване на НЗОК рецепта"
                url="https://download.kontrax.bg/hippocrates/download/Video/Cloud%20%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE/%D0%98%D0%B7%D0%BF%D0%B8%D1%81%D0%B2%D0%B0%D0%BD%D0%B5%20%D0%BD%D0%B0%20%D0%A0%D0%B5%D1%86%D0%B5%D0%BF%D1%82%D0%B8%20(%D0%91%D0%BB%D0%B0%D0%BD%D0%BA%D0%B8%205%20%D0%B8%205%D0%90).mp4"
            />
            <VideoPlayer
                title="Създаване на болничен лист"
                url="https://download.kontrax.bg/hippocrates/download/Video/Cloud%20%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE/%D0%98%D0%B7%D0%B4%D0%B0%D0%B2%D0%B0%D0%BD%D0%B5%20%D0%BD%D0%B0%20%D0%91%D0%BE%D0%BB%D0%BD%D0%B8%D1%87%D0%B5%D0%BD%20%D0%BB%D0%B8%D1%81%D1%82.mp4"
            />
            <VideoPlayer
                title="Работа с отчети"
                url="https://download.kontrax.bg/hippocrates/download/Video/Cloud%20%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE/%D0%9F%D0%BE%D0%B4%D0%B0%D0%B2%D0%B0%D0%BD%D0%B5%20%D0%BD%D0%B0%20%D0%BE%D1%82%D1%87%D0%B5%D1%82%D0%B8%20%D0%BA%D1%8A%D0%BC%20%D0%9D%D0%97%D0%9E%D0%9A.mp4"
            />
            <VideoPlayer
                title="Формуляр 365 (Справка за РЗИ)"
                url="https://download.kontrax.bg/hippocrates/download/Video/Cloud%20%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE/%D0%A4%D0%BE%D1%80%D0%BC%D1%83%D0%BB%D1%8F%D1%80%20365%20(%D0%A1%D0%BF%D1%80%D0%B0%D0%B2%D0%BA%D0%B0%20%D0%B7%D0%B0%20%D0%A0%D0%97%D0%98).mp4"
            />
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import VideoPlayer from '@/component/Video/VideoPlayer.vue';

    @Component({
        components: { VideoPlayer }
    })
    export default class Video extends Vue {}
</script>
