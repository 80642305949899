import { NhisMedicalProcedureResultDto } from '@/model/Nhis/Exam/PreventiveActivities/NhisMedicalProcedureResultDto';

export class NhisMedicalProcedureDto {
    public code: string = '';
    public nhisCode: string = '';
    public description: string = '';
    public note: string = '';
    public nrn: string = '';
    public procedureId: number = 0;
    public executionCount: number = 0;
    public results: NhisMedicalProcedureResultDto[] = [];
}
