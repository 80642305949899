import { Type } from 'class-transformer';

import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
import { SubmitCommand } from '@/model/Nhis/SubmitCommand';

export class NhisPrescriptionSubmitCommand extends SubmitCommand {
    @Type(() => PrescriptionKey)
    public key: PrescriptionKey;

    constructor(xmlString: string, accessTokenValue: string, key: PrescriptionKey) {
        super(xmlString, accessTokenValue);
        this.key = key;
    }
}
