<template>
    <left-menu-layout :menu-items="menuItems" router-name="nomenclature" />
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import LeftMenuLayout from '@/component/Menu/LeftMenuLayout.vue';
    import { MenuItemModel } from '@/model/Menu/MenuItemModel';
    import { nomenclatureMenuItems } from '@/model/Menu/NomenclatureMenuItems';

    @Component({
        components: { LeftMenuLayout }
    })
    export default class NomenclatureMenu extends Vue {
        private menuItems: MenuItemModel[] = nomenclatureMenuItems;
    }
</script>
