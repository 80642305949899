import { AxiosResponse } from 'axios';

import { CountryDto } from '@/model/Nomenclature/CountryDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const countryService = {
    getCountries(): Promise<AxiosResponse<CountryDto[]>> {
        return httpService.get('/Country/GetCountries');
    }
};
