import { plainToInstance } from 'class-transformer';

import { AddressTypeDto } from '@/model/Nomenclature/AddressTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const addressTypeService = {
    async getAddressTypes(): Promise<AddressTypeDto[]> {
        const response = await httpService.get<AddressTypeDto[]>('/AddressType/GetAddressTypes');
        return plainToInstance(AddressTypeDto, response.data);
    }
};
