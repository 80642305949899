import { plainToInstance } from 'class-transformer';

import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
import { InfoByExamFilter } from '@/model/Exam/InfoByExamFilter';
import { MegaPrescriptionCopyCommand } from '@/model/Exam/Prescription/MegaPrescriptionCopyCommand';
import { PrescriptionCopyCommand } from '@/model/Exam/Prescription/PrescriptionCopyCommand';
import { PrescriptionCreateResultDto } from '@/model/Exam/Prescription/PrescriptionCreateResultDto';
import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
import { PrescriptionNhifDto } from '@/model/Exam/Prescription/PrescriptionNhifDto';
import { PrescriptionNhifTypeADto } from '@/model/Exam/Prescription/PrescriptionNhifTypeADto';
import { PrescriptionNhifTypeBDto } from '@/model/Exam/Prescription/PrescriptionNhifTypeBDto';
import { PrescriptionNhifTypeCDto } from '@/model/Exam/Prescription/PrescriptionNhifTypeCDto';
import { PrescriptionNhifV2Dto } from '@/model/Exam/Prescription/PrescriptionNhifV2Dto';
import { PrescriptionNhifV2ViewDto } from '@/model/Exam/Prescription/PrescriptionNhifV2ViewDto';
import { PrescriptionNhifViewDto } from '@/model/Exam/Prescription/PrescriptionNhifViewDto';
import { PrescriptionNhifWhiteDto } from '@/model/Exam/Prescription/PrescriptionNhifWhiteDto';
import { PrescriptionNhifWhiteViewDto } from '@/model/Exam/Prescription/PrescriptionNhifWhiteViewDto';
import { PatientPrescriptionDrugDto } from '@/model/Query/Patient/PatientPrescriptionDrugDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const prescriptionService = {
    async getPrescriptionInfoByExamId(filter: InfoByExamFilter): Promise<PrescriptionNhifDto> {
        const response = await httpService.post<PrescriptionNhifDto>(
            'Prescription/GetPrescriptionInfoByExamId',
            filter
        );
        if (filter.formType === PrescriptionFormTypeCode.Nhif5a) {
            return plainToInstance(PrescriptionNhifTypeADto, response.data);
        } else if (filter.formType === PrescriptionFormTypeCode.Nhif5b) {
            return plainToInstance(PrescriptionNhifTypeBDto, response.data);
        } else if (filter.formType === PrescriptionFormTypeCode.Nhif5c) {
            return plainToInstance(PrescriptionNhifTypeCDto, response.data);
        }
        return plainToInstance(PrescriptionNhifDto, response.data);
    },

    async getPrescriptionWhiteInfoByExam(examId: number): Promise<PrescriptionNhifWhiteDto> {
        const response = await httpService.get<PrescriptionNhifWhiteDto>(
            `Prescription/GetPrescriptionWhiteInfoByExamId/${examId}`
        );
        return plainToInstance(PrescriptionNhifWhiteDto, response.data);
    },

    async getPrescription(key: PrescriptionKey): Promise<PrescriptionNhifDto> {
        const response = await httpService.get<PrescriptionNhifDto>('Prescription/GetPrescription', {
            params: key
        });
        return plainToInstance(PrescriptionNhifDto, response.data);
    },

    async getPrescriptionWhite(key: PrescriptionKey): Promise<PrescriptionNhifWhiteDto> {
        const response = await httpService.get<PrescriptionNhifWhiteDto>('Prescription/GetPrescriptionWhite', {
            params: key
        });
        return plainToInstance(PrescriptionNhifWhiteDto, response.data);
    },

    async getPrescriptionTypeA(key: PrescriptionKey): Promise<PrescriptionNhifTypeADto> {
        const response = await httpService.get<PrescriptionNhifTypeADto>('Prescription/GetPrescription', {
            params: key
        });
        return plainToInstance(PrescriptionNhifTypeADto, response.data);
    },

    async createWhite(data: PrescriptionNhifWhiteDto): Promise<number> {
        const response = await httpService.post<number>('Prescription/CreateWhite', data);
        return response.data;
    },

    async create(data: PrescriptionNhifDto): Promise<PrescriptionCreateResultDto> {
        const response = await httpService.post<PrescriptionCreateResultDto>('Prescription/Create', data);
        return plainToInstance(PrescriptionCreateResultDto, response.data);
    },

    async createTypeA(data: PrescriptionNhifTypeADto): Promise<PrescriptionCreateResultDto> {
        const response = await httpService.post<PrescriptionCreateResultDto>('Prescription/CreateTypeA', data);
        return plainToInstance(PrescriptionCreateResultDto, response.data);
    },

    async createTypeB(data: PrescriptionNhifTypeBDto): Promise<PrescriptionCreateResultDto> {
        const response = await httpService.post<PrescriptionCreateResultDto>('Prescription/CreateTypeB', data);
        return plainToInstance(PrescriptionCreateResultDto, response.data);
    },

    async createTypeC(data: PrescriptionNhifTypeCDto): Promise<PrescriptionCreateResultDto> {
        const response = await httpService.post<PrescriptionCreateResultDto>('Prescription/CreateTypeC', data);
        return plainToInstance(PrescriptionCreateResultDto, response.data);
    },

    async update(data: PrescriptionNhifDto): Promise<void> {
        await httpService.post('Prescription/Update', data);
    },

    async updateWhite(data: PrescriptionNhifWhiteDto): Promise<void> {
        await httpService.put('Prescription/UpdateWhite', data);
    },

    async updateTypeA(data: PrescriptionNhifTypeADto): Promise<void> {
        await httpService.post('Prescription/UpdateTypeA', data);
    },

    async updateTypeB(data: PrescriptionNhifTypeBDto): Promise<void> {
        await httpService.post('Prescription/UpdateTypeB', data);
    },

    async updateTypeC(data: PrescriptionNhifTypeCDto): Promise<void> {
        await httpService.post('Prescription/UpdateTypeC', data);
    },

    async delete(key: PrescriptionKey): Promise<void> {
        await httpService.delete('Prescription/DeletePrescription', { data: key });
    },

    async checkPrescrOverlapIcdUse(examId: number, diagId: number, issueDate: Date | null): Promise<number> {
        const response = await httpService.get<number>('Prescription/CheckPrescrOverlapIcdUse', {
            params: { examId, diagId, issueDate }
        });
        return response.data;
    },

    async checkPrescrExistingIcdProtocol(protocolTypeCode: string, icdCode: string, issueDate: Date): Promise<boolean> {
        const response = await httpService.get<boolean>('Prescription/CheckPrescriptionProtocolExistingIcd', {
            params: { protocolTypeCode, icdCode, issueDate }
        });
        return response.data;
    },

    async check5ABeforeUpload(key: PrescriptionKey): Promise<string> {
        const response = await httpService.get<string>('Prescription/Check5ABeforeUpload', { params: key });
        return response.data;
    },

    async getRequiredIcdPrescriptionBook(): Promise<string[]> {
        const response = await httpService.get<string[]>('Prescription/RequiredIcdPrescriptionBookQuery');
        return response.data;
    },

    async getPrescriptionByExamId(examId: number, formTypeCode: string): Promise<PrescriptionNhifViewDto[]> {
        const response = await httpService.get<PrescriptionNhifViewDto[]>('Prescription/GetPrescriptionsByExam', {
            params: {
                examId,
                formTypeCode
            }
        });
        return plainToInstance(PrescriptionNhifViewDto, response.data);
    },

    async getPrescriptionTypeAByExamId(examId: number, formTypeCode: string): Promise<PrescriptionNhifViewDto[]> {
        const response = await httpService.get<PrescriptionNhifViewDto[]>('Prescription/GetPrescriptionsTypeAByExam', {
            params: {
                examId,
                formTypeCode
            }
        });
        return plainToInstance(PrescriptionNhifViewDto, response.data);
    },

    async getPrescriptionWhiteByExamId(examId: number, formTypeCode: string): Promise<PrescriptionNhifWhiteViewDto[]> {
        const response = await httpService.get<PrescriptionNhifWhiteViewDto[]>(
            'Prescription/GetPrescriptionsWhiteByExam',
            {
                params: {
                    examId,
                    formTypeCode
                }
            }
        );
        return plainToInstance(PrescriptionNhifWhiteViewDto, response.data);
    },

    async copyNhisPrescription(
        sourcePrescriptionKey: PrescriptionKey,
        targetPrescriptionType: string,
        targetExamId: number
    ): Promise<PrescriptionNhifWhiteDto> {
        const response = await httpService.post<PrescriptionNhifWhiteDto>(
            'Prescription/CopyNhisPrescriptionFromAnother',
            new PrescriptionCopyCommand(sourcePrescriptionKey, targetPrescriptionType, targetExamId)
        );
        return plainToInstance(PrescriptionNhifWhiteDto, response.data);
    },

    async copyPrescription(
        sourcePrescriptionKey: PrescriptionKey,
        targetPrescriptionType: string,
        targetExamId: number
    ): Promise<PrescriptionNhifDto | PrescriptionNhifTypeADto> {
        const response = await httpService.post<PrescriptionNhifDto | PrescriptionNhifTypeADto>(
            'Prescription/CopyPrescriptionFromAnother',
            new PrescriptionCopyCommand(sourcePrescriptionKey, targetPrescriptionType, targetExamId)
        );
        return plainToInstance(
            targetPrescriptionType === PrescriptionFormTypeCode.Nhif5 ? PrescriptionNhifDto : PrescriptionNhifTypeADto,
            response.data
        );
    },

    //V2 на рецептата - Мега рецепта
    async getPrescriptionV2InfoByExamId(filter: InfoByExamFilter): Promise<PrescriptionNhifV2Dto> {
        const response = await httpService.post<PrescriptionNhifV2Dto>(
            'Prescription/GetPrescriptionV2InfoByExamId',
            filter
        );
        return plainToInstance(PrescriptionNhifV2Dto, response.data);
    },

    async getPrescriptionV2(key: PrescriptionKey): Promise<PrescriptionNhifV2Dto> {
        const response = await httpService.get<PrescriptionNhifV2Dto>('Prescription/GetPrescriptionV2', {
            params: key
        });
        return plainToInstance(PrescriptionNhifV2Dto, response.data);
    },

    async createV2(data: PrescriptionNhifV2Dto): Promise<PrescriptionCreateResultDto> {
        const response = await httpService.post<PrescriptionCreateResultDto>('Prescription/CreateV2', data);
        return plainToInstance(PrescriptionCreateResultDto, response.data);
    },

    async updateV2(data: PrescriptionNhifV2Dto): Promise<void> {
        await httpService.post('Prescription/UpdateV2', data);
    },

    async copyPrescriptionV2(
        sourcePrescriptionKey: PrescriptionKey,
        targetPrescriptionType: string,
        targetExamId: number
    ): Promise<PrescriptionNhifV2Dto> {
        const response = await httpService.post<PrescriptionNhifV2Dto>(
            'Prescription/CopyPrescriptionFromAnotherV2',
            new PrescriptionCopyCommand(sourcePrescriptionKey, targetPrescriptionType, targetExamId)
        );
        return plainToInstance(PrescriptionNhifV2Dto, response.data);
    },

    async createComplexPrescriptionV2(
        selectedDrugs: PatientPrescriptionDrugDto[],
        targetPrescriptionType: string,
        targetExamId: number
    ): Promise<void> {
        await httpService.post<void>(
            'Prescription/CreateComplexPrescriptionV2',
            new MegaPrescriptionCopyCommand(targetPrescriptionType, targetExamId, selectedDrugs)
        );
    },

    async getPrescriptionV2ByExamId(examId: number, formTypeCode: string): Promise<PrescriptionNhifV2ViewDto[]> {
        const response = await httpService.get<PrescriptionNhifV2ViewDto[]>('Prescription/GetPrescriptionsV2ByExam', {
            params: {
                examId,
                formTypeCode
            }
        });
        return plainToInstance(PrescriptionNhifV2ViewDto, response.data);
    }
};
