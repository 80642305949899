<template>
    <v-card>
        <v-card-title>
            Резултат от направление
            <v-spacer />
            <btn class="ma-1" action="Next" :to="`/Exam/Referral4/Edit/${referralResult.documentId}`">
                Към направлението...
            </btn>
            <btn
                v-if="referralResult && referralResult.status"
                :disabled="referralResult.status === 'Active'"
                class="ma-1"
                action="Print"
                @click="print"
                >Печат изследвания</btn
            >
            <btn action="Download" class="ma-1" @click="checkReferral">Изтегляне на резултати</btn>
        </v-card-title>
        <v-col>
            <referral-result-main-info
                :nrn="referralResult.resultNrn"
                :open-date="referralResult.openDate"
                :result-referral-performer-data="referralResult.performerInfo"
            >
            </referral-result-main-info>
            <data-table
                v-if="referralResult.procedures"
                title="Резултати от изследвания"
                :headers="tableHeaders"
                :items="referralResult.procedures"
                item-key="code"
                disable-pagination
            ></data-table>
        </v-col>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReferralResultMainInfo from '@/component/Exam/ReferralResultMainInfo.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ResultReferral4Dto } from '@/model/Exam/Referral/ResultReferral/ResultReferral4Dto';
    import { ReferralResult4Service } from '@/service/Exam/Referral/ReferralResult4Service';
    import { nhisReferralService } from '@/service/Nhis/NhisReferralService';
    import { nhisService } from '@/service/Nhis/NhisService';
    import { formatters } from '@/util/Formatters';

    @Component({ components: { ReferralResultMainInfo } })
    export default class ReferralResult4 extends Vue {
        @Prop()
        private nrn!: string;

        @Prop()
        private examId!: number;

        private referralResult: ResultReferral4Dto = new ResultReferral4Dto();

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Код:', value: 'code' },
                { text: 'Име:', value: 'name' },
                { text: 'Резултат:', value: 'result' },
                { text: 'Мерна единица:', value: 'resultUnit' },
                { text: 'Дата:', value: 'resultDate', formatter: formatters.date },
                { text: 'Долна референтна стойност:', value: 'referenceLowRange' },
                { text: 'Горна референтна стойност:', value: 'referenceHighRange' },
                { text: 'Текстова референтна стойност:', value: 'referenceTextRange' }
            ];
        }

        private async mounted() {
            await this.load();
        }

        private async load() {
            this.referralResult = (await ReferralResult4Service.getResultData(this.examId, this.nrn)).data;
        }

        private print() {
            if (this.examId) {
                this.$router.push(`/Print/Exam.Referral.ReferralResult4/ReferralResult4/${this.examId}/${this.nrn}`);
            }
        }

        private async checkReferral() {
            this.$loading.show();
            try {
                const response = await nhisReferralService.checkReferral(
                    nhisService.requestData(this.referralResult.documentId, 'Referral4')
                );
                if (response) {
                    await this.load();
                }
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
