import _Vue from 'vue';

import { notifierService } from '@/service/Infrastructure/NotifierService';

import { INotifier } from './INotifier';

declare module 'vue/types/vue' {
    interface Vue {
        $notifier: INotifier;
    }
}

export const notifierPlugin = (Vue: typeof _Vue) => {
    Vue.prototype.$notifier = notifierService;
};
