import { Type } from 'class-transformer';

import { GenderCode } from '@/enum/Nomenclature/GenderCode';
import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';

//23 - ид на Б-я да бъде по подразбиране
const defaultCountryCode = 23;

export class PersonIdentificationDto {
    public id: number = 0;
    public pidTypeCode: PidTypeCode | null = PidTypeCode.Egn;
    public identifier: string = '';
    public newbornIdentifier: string | null = null;

    // Обикновено не е нужно датите да се анотират, защото се parse-ват с axios interceptor, когато идват от сървъра.
    // Този клас обаче се зарежда и от local storage (покрай текущия пациент), а при четене оттам няма друг механизъм
    // за parse-ване на датите, освен да се ползва class-transformer.
    @Type(() => Date)
    public birthDate: Date | null = null;

    public genderCode: GenderCode | null = null;
    public countryId: number | null = defaultCountryCode;
    public certificateDocumentTypeId: number | null = null;
    public certificateDocumentNumber: string = '';
    public insuranceInstitutionName: string = '';
    public insuranceInstitutionNumber: string = '';

    @Type(() => Date)
    public certificateDocumentIssueDate: Date | null = null;

    @Type(() => Date)
    public certificateDocumentValidFrom: Date | null = null;

    @Type(() => Date)
    public certificateDocumentValidTo: Date | null = null;
}
