import Vue from 'vue';

class LoadingState {
    public isVisible: boolean = false;

    public show() {
        this.isVisible = true;
    }

    public hide() {
        this.isVisible = false;
    }
}

const loadingState = Vue.observable(new LoadingState());

export { LoadingState, loadingState };
