import { AxiosResponse } from 'axios';

import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';
import { CalendarEventModel } from '@/model/WorkTime/CalendarEventModel';
import { EventCreateCommand } from '@/model/WorkTime/EventCreateCommand';
import { EventDeleteCommand } from '@/model/WorkTime/EventDeleteCommand';
import { EventDto } from '@/model/WorkTime/EventDto';
import { EventEditDto } from '@/model/WorkTime/EventEditDto';
import { EventFilterCommand } from '@/model/WorkTime/EventFilterCommand';
import { httpService } from '@/service/Infrastructure/HttpService';

export const workTimeService = {
    createWorkTimeEvent(rawEvent: EventDto, date: Date | null, recurrence: boolean) {
        const event = new CalendarEventModel();
        event.id = rawEvent.id;
        event.name = `${rawEvent.name} - ${rawEvent.doctorName ?? ''}`;
        event.start = date || rawEvent.date;
        // TODO: Този ред (и следващият със setHours) предизвиква TypeError: Converting circular structure to JSON
        event.start?.setHours(rawEvent.startHour ?? 0);
        event.start?.setMinutes(rawEvent.startMinute ?? 0);
        event.end = date ? new Date(date) : new Date(rawEvent.date ?? new Date());
        event.end?.setHours(rawEvent.endHour ?? 0);
        event.end?.setMinutes(rawEvent.endMinute ?? 0);
        event.color = rawEvent.color;
        event.category = rawEvent.workTimeVisitReasonName;
        event.isRecurrence = recurrence;

        return event;
    },

    getFiltered(
        practiceId: number,
        doctorId: number | null,
        specialtyCode: SpecialtyCode | null,
        workTimeVisitReasonId: number | null
    ): Promise<AxiosResponse<EventDto[]>> {
        return httpService.post(
            `/WorkTime/GetFiltered`,
            new EventFilterCommand(practiceId, doctorId, specialtyCode, workTimeVisitReasonId)
        );
    },

    getExamScheduleEventsFiltered(
        practiceId: number,
        doctorId: number | null,
        specialtyCode: SpecialtyCode | null,
        workTimeVisitReasonId: number | null
    ): Promise<AxiosResponse<EventDto[]>> {
        return httpService.post(
            `/WorkTime/GetExamScheduleEventsFiltered`,
            new EventFilterCommand(practiceId, doctorId, specialtyCode, workTimeVisitReasonId)
        );
    },

    async getEventById(eventId: number | null): Promise<EventEditDto> {
        const result = await httpService.get<EventEditDto>(`/WorkTime/GetEventById/${eventId}`);
        return result.data;
    },

    addEvent(dto: EventCreateCommand): Promise<AxiosResponse<EventDto>> {
        return httpService.post('/WorkTime/CreateEvent', dto);
    },

    editEvent(dto: EventEditDto): Promise<AxiosResponse<void>> {
        return httpService.put('/WorkTime/UpdateEvent', dto);
    },

    getRegisteredSpecialtiesForDoctorAndPractice(
        practiceId: number,
        doctorId: number
    ): Promise<AxiosResponse<SpecialtyDto[]>> {
        return httpService.get('/WorkTime/GetRegisteredSpecialtiesForDoctorAndPractice', {
            params: { practiceId, doctorId }
        });
    },

    getAllSpecialtiesForPractice(practiceId: number): Promise<AxiosResponse<SpecialtyDto[]>> {
        return httpService.get('/Specialty/GetAllSpecialtiesForPractice', {
            params: { practiceId }
        });
    },

    deleteEvent(dto: EventDeleteCommand) {
        return httpService.delete('/WorkTime/DeleteEvent', {
            data: dto
        });
    }
};
