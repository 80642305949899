<template>
    <data-table
        title="Опис към месечния отчет на карти за оценка на рискови фактори на ПИМП"
        :headers="tableHeaders"
        :items="items.lines"
        disable-pagination
    >
        <template #actions>
            Всичко общо: {{ items.total }} бр.
            <v-spacer />
            <btn icon action="Close" @click="$emit('close-dialog')" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RiskAssessmentCardReportDto } from '@/model/Report/Pis/Gp/RiskAssessmentCard/RiskAssessmentCardReportDto';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class RiskAssessmentCardReportLines extends Vue {
        @Prop()
        items!: RiskAssessmentCardReportDto;

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Идентификатор:', value: 'identifier' },
                { text: 'Имена:', value: 'name' },
                { text: 'Дата на раждане:', value: 'birthDate', formatter: formatters.date },
                { text: 'Амбулаторен лист:', value: 'ambulatorySheetNumber' },
                { text: 'Дата на преглед:', value: 'examDate', formatter: formatters.date }
            ];
        }
    }
</script>
