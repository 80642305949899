import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';

export class FiscalDeviceOperatorDto {
    public practiceId: number = 0;
    public deviceSeqNumber: number = 0;
    public deviceOperatorSeqNumber: number = 0;
    public operator: string | null = null;
    public password: string | null = null;
    public employees: EmployeeTitleDto[] = [];
}
