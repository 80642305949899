import { plainToInstance } from 'class-transformer';
import Vue from 'vue';

import { PisInvoiceDto } from '@/model/Invoice/Pis/PisInvoiceDto';

const currentInvoiceKey: string = 'currentInvoice';
const emptyValue = new PisInvoiceDto();

class CurrentInvoiceCache {
    private _value: PisInvoiceDto = emptyValue;

    public get value() {
        return this._value;
    }

    public clear() {
        localStorage.removeItem(currentInvoiceKey);
        this._value = emptyValue;
    }

    public set(item: PisInvoiceDto) {
        if (item) {
            localStorage.setItem(currentInvoiceKey, JSON.stringify(item));
            this._value = item;
        }
    }

    public loadFromLocalStorage() {
        const json = localStorage.getItem(currentInvoiceKey);
        if (json) {
            const invoice = plainToInstance(PisInvoiceDto, JSON.parse(json));
            invoice.invoiceDate = invoice.invoiceDate ? new Date(invoice.invoiceDate) : null;
            this._value = invoice;
        }
    }
}

export const currentInvoiceCache = Vue.observable(new CurrentInvoiceCache());
