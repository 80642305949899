<template>
    <div>
        <data-table
            v-if="visitHasExam"
            :items="items"
            :headers="headers"
            title="Резултати от Е-направления"
            class="mb-4"
        >
            <template #actions>
                <v-row>
                    <btn action="Download" @click="checkReferralResults()">Изтегляне на резултати</btn>
                    <alert type="info" class="ml-3">
                        Обновяване на вече изтеглен резултат става от екрана с детайли
                    </alert>
                </v-row>
            </template>
            <template #rowActions="{ item }">
                <btn icon action="Details" :to="clickPreview(item)" />
            </template>
        </data-table>

        <data-table
            :items="externalVisits"
            :headers="externalVisitsHeaders"
            title="Други резултати и медицински документи"
        >
            <template #actions>
                <btn action="New" :to="`/Exam/ExternalVisit/Create/${visitId}`"></btn>
            </template>
            <template #rowActions="{ item }">
                <btn icon action="Edit" :to="`/Exam/ExternalVisit/Edit/${item.patientId}/${item.seqNumber}`" />
            </template>
        </data-table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ReferralTypeCode } from '@/enum/Exam/ReferralTypeCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ExternalVisitRowDto } from '@/model/Exam/ExternalVisit/ExternalVisitRowDto';
    import { ResultReferralsDto } from '@/model/Exam/Referral/ResultReferral/ResultReferralsDto';
    import { ResultReferralSubmitCommand } from '@/model/Exam/Referral/ResultReferral/ResultReferralSubmitCommand';
    import { externalVisitService } from '@/service/Exam/ExternalVisitService';
    import { referralResultsService } from '@/service/Exam/Referral/ReferralResultsService';
    import { signXmlService } from '@/service/Report/SignXmlService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { accessTokenCache, nhisKey } from '@/store/NhisNhifAccessTokenCache';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class ResultsView extends Vue {
        @Prop()
        private visitId!: number;

        private items: ResultReferralsDto[] = [];
        private externalVisits: ExternalVisitRowDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата', value: 'issueDate', formatter: formatters.date },
                { text: 'НРН', value: 'nrn' },
                { text: 'Вид направление', value: 'referralTypeName' },
                { text: 'Тип направление', value: 'referralSubTypeName' },
                { text: 'Статус', value: 'status' },
                { text: 'Дата на проверка:', value: 'statusTime', formatter: formatters.dateTime }
            ];
        }

        private get externalVisitsHeaders(): IDataTableHeader[] {
            return [
                { text: 'Дата', value: 'date', formatter: formatters.date },
                { text: 'Вид на документа', value: 'documentType' },
                { text: 'Номер на документа', value: 'documentNumber' },
                { text: 'Лечебно заведение', value: 'practiceName' },
                { text: 'Лекар', value: 'doctorName' },
                { text: 'Специалност/Отделение', value: 'specialty' }
            ];
        }

        private get visitHasExam() {
            return Boolean(currentVisitCache.value.exam);
        }

        private async mounted() {
            if (this.visitId) {
                await this.load();
            }

            this.externalVisits = await externalVisitService.getExternalVisits(
                currentPatientCache.value.key.patientId,
                this.visitId
            );
        }

        private async load() {
            this.items = [];
            this.items = (await referralResultsService.getAllResultReferrals(this.visitId)).data;
        }

        private clickPreview(item: ResultReferralsDto) {
            switch (item.referralTypeCode) {
                case ReferralTypeCode.Referral3:
                case ReferralTypeCode.Referral3a:
                case ReferralTypeCode.Referral6:
                    return `/Exam/ReferralResult/${item.nrn}/${this.visitId}`;
                case ReferralTypeCode.Referral4:
                    return `/Exam/ReferralResult4/${item.nrn}/${this.visitId}`;
                case ReferralTypeCode.Referral7:
                    return `/Exam/ReferralResult7/${item.nrn}/${this.visitId}`;
                default:
                    return '';
            }
        }

        private async checkReferralResults() {
            this.$loading.show();
            try {
                const response = (await referralResultsService.getReferralsXmls(this.visitId)).data;
                if (response.length === 0) {
                    return;
                }
                for (const element of response) {
                    // eslint-disable-next-line no-await-in-loop
                    const signResponse = await signXmlService.signXml(element.xml);
                    if (!signResponse.isError && signResponse.contents) {
                        element.xml = signResponse.contents;
                    }
                }

                const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
                const command = new ResultReferralSubmitCommand();
                command.accessToken = tokenInfo?.accessToken ?? '';
                command.referralsXmls = response;

                const result = (await referralResultsService.processReferralsXmls(command)).data;
                if (result) {
                    this.$notifier.showWarning('', result);
                }
                await this.load();
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
