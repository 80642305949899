<template>
    <v-row>
        <v-col v-if="documentId > 0 && canPostActive" cols="3">
            <btn action="Send" block @click="sendSickSheet()"> Изпращане </btn>
        </v-col>
        <v-col v-if="documentId > 0 && canCancel" cols="3">
            <btn action="Send" @click="sendCancellationSheet()"> Изпращане на Анулиран </btn>
        </v-col>
        <v-col v-if="documentId > 0" cols="3">
            <btn action="Send" block @click="validateSickSheet()"> Проверка </btn>
        </v-col>
        <v-col v-if="documentId > 0" cols="3">
            <btn action="Send" block @click="downloadXml()"> Сваляне на файл </btn>
        </v-col>
        <v-col v-if="uploadResult && uploadResult.length > 0" cols="3">
            <btn action="Send" @click="resultDialogIsVisible = true">Резултат от изпращане</btn>
        </v-col>
        <v-dialog v-model="resultDialogIsVisible">
            <data-table v-if="tableItems" :headers="headers" :items="tableItems" disable-pagination />
        </v-dialog>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { SickSheetStatusCode } from '@/enum/Exam/SickSheetStatusCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NssiUploadResponseDto } from '@/model/Integration/Nssi/NssiUploadResponseDto';
    import { sickSheetService } from '@/service/Exam/SickSheetService';
    import { localServerNssiService } from '@/service/Integration/LocalServerNssiService';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class SickSheetUpload extends Vue {
        @Prop()
        private documentId!: number;

        @Prop()
        private canceledSickSheetId!: number;

        @Prop()
        status!: string | null;

        private resultDialogIsVisible: boolean = false;
        private uploadResult: string[] = [];
        private xmlString: string | null = null;

        private get headers(): IDataTableHeader[] {
            return [
                {
                    text: 'Резултат от изпращане',
                    value: 'item'
                }
            ];
        }

        private async downloadXml() {
            const xml = await this.generateXmlFile();
            const element = document.createElement('a');
            const blob = new Blob([xml ?? ''], { type: 'application/xml' });
            const url = URL.createObjectURL(blob);
            element.setAttribute('href', url);
            element.setAttribute('download', `SickSheet-${this.documentId}`);
            element.click();
        }

        private get canPostActive() {
            return !this.status || this.status === SickSheetStatusCode.None;
        }

        private get canCancel() {
            return this.status && this.status === SickSheetStatusCode.ActiveToBeCanceled && !this.canceledSickSheetId;
        }

        private get tableItems() {
            return this.uploadResult.map((item) => ({ item }));
        }

        private async sendSickSheet() {
            await this.generateXml();
            const response = await this.uploadFile(this.xmlString ?? '');
            await this.getUploadResult(response);
        }

        private async sendCancellationSheet() {
            await this.generateXml();
            const response = await this.uploadFile(this.xmlString ?? '');
            await this.getUploadResult(response);
        }

        private async validateSickSheet() {
            await this.generateXml();
            const response = await this.validateFile(this.xmlString ?? '');
            if (response) {
                response.isTest = true;
                await this.getUploadResult(response);
            }
        }

        private async generateXml() {
            this.xmlString = this.canCancel
                ? await this.generateActualDeleteSickSheet()
                : this.canPostActive && this.canceledSickSheetId
                  ? await this.generateCanceledSickSheet()
                  : await this.generateActiveSickSheet();
            console.log(this.xmlString);
        }

        private generateXmlFile() {
            const isFileCreate = true;
            return this.canCancel
                ? this.generateActualDeleteSickSheet(isFileCreate)
                : this.canPostActive && this.canceledSickSheetId
                  ? this.generateCanceledSickSheet(isFileCreate)
                  : this.generateActiveSickSheet(isFileCreate);
        }

        private async generateActiveSickSheet(isFileCreate: boolean = false): Promise<string> {
            if (this.documentId) {
                const xmlString = await sickSheetService.generateActiveSickSheetXml(this.documentId, isFileCreate);
                return xmlString;
            }
            return '';
        }

        private async generateCanceledSickSheet(isFileCreate: boolean = false): Promise<string> {
            if (this.documentId && this.canceledSickSheetId) {
                const xmlString = await sickSheetService.generateCanceledXml(
                    this.documentId,
                    this.canceledSickSheetId,
                    isFileCreate
                );
                return xmlString;
            }
            return '';
        }

        private async generateActualDeleteSickSheet(isFileCreate: boolean = false) {
            if (this.documentId) {
                const xmlString = await sickSheetService.generateActualCanceledXml(this.documentId, isFileCreate);
                return xmlString;
            }
            return '';
        }

        private async uploadFile(xmlString: string): Promise<NssiUploadResponseDto | null> {
            const isLocalServerActive = await localServerDiagnosticService.checkIsLocalServerActive();
            if (isLocalServerActive) {
                try {
                    const uploadResponse = await localServerNssiService.uploadSickSheet(xmlString);
                    return uploadResponse?.data;
                } catch {
                    this.$notifier.showWarning('', 'Възникна проблем при изпращане на болничен лист');
                }
            }
            return null;
        }

        private async validateFile(xmlString: string): Promise<NssiUploadResponseDto | null> {
            const isLocalServerActive = await localServerDiagnosticService.checkIsLocalServerActive();
            if (isLocalServerActive) {
                try {
                    const uploadResponse = await localServerNssiService.validateSickSheet(xmlString);
                    return uploadResponse?.data;
                } catch {
                    this.$notifier.showWarning('', 'Възникна проблем при изпращане на болничен лист');
                }
            }
            return null;
        }

        private async getUploadResult(response: NssiUploadResponseDto | null) {
            const { current } = userContextCache;
            if (current && response) {
                this.uploadResult = [];
                response.sickSheetId = this.documentId;
                console.log(response);
                const result = await sickSheetService.updateSickSheetStatus(current.practiceId, response);
                if (result) {
                    this.uploadResult = result;
                    this.resultDialogIsVisible = true;
                    await this.getCurrentStatus();
                }
            }
        }

        private async getCurrentStatus() {
            const status = await sickSheetService.getSickSheetCurrentStatus(this.documentId);
            if (status) {
                this.changeStatus(status);
            }
        }

        private changeStatus(status: string) {
            this.$emit('status-change', status);
        }
    }
</script>
