import { AxiosResponse } from 'axios';

import { MedicalProcedureAnswerEnumDto } from '@/model/Nomenclature/MedicalProcedureAnswerEnumDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const medicalProcedureAnswerEnumService = {
    getMedicalProcedureAnswerEnum(): Promise<AxiosResponse<MedicalProcedureAnswerEnumDto[]>> {
        return httpService.get('/MedicalProcedureAnswerEnum/GetMedicalProcedureAnswerEnums');
    }
};
