import { plainToInstance } from 'class-transformer';

import { FundServiceDto } from '@/model/Nomenclature/FundServiceDto';
import { CoreServiceTypeDto } from '@/model/Practice/Service/CoreServiceTypeDto';
import { PracticeServiceEditDto } from '@/model/Practice/Service/PracticeServiceEditDto';
import { PracticeServiceViewDto } from '@/model/Practice/Service/PracticeServiceViewDto';
import { ServiceCategoryDto } from '@/model/Practice/Service/ServiceCategoryDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const practiceServiceService = {
    async getPracticeServices(practiceId: number): Promise<PracticeServiceViewDto[]> {
        const response = await httpService.get<PracticeServiceViewDto[]>('PracticeService/GetPracticeServices', {
            params: { practiceId }
        });
        return plainToInstance(PracticeServiceViewDto, response.data);
    },

    async getPracticeService(practiceId: number, seqNumber: number): Promise<PracticeServiceEditDto> {
        const response = await httpService.get('PracticeService/GetPracticeService', {
            params: { practiceId, seqNumber }
        });
        return plainToInstance(PracticeServiceEditDto, response.data);
    },

    async updatePracticeService(dto: PracticeServiceEditDto): Promise<string> {
        const response = await httpService.post<string>('PracticeService/UpdatePracticeService', dto);
        return response.data;
    },

    async deletePracticeService(practiceId: number, seqNumber: number): Promise<string> {
        const response = await httpService.delete<string>('PracticeService/DeletePracticeService', {
            params: { practiceId, seqNumber }
        });
        return response.data;
    },

    async getCoreServiceTypes(): Promise<CoreServiceTypeDto[]> {
        const response = await httpService.get<CoreServiceTypeDto[]>('PracticeService/GetCoreServiceTypes');
        return plainToInstance(CoreServiceTypeDto, response.data);
    },

    async getFundServices(practiceId: number): Promise<FundServiceDto[]> {
        const response = await httpService.get<FundServiceDto[]>('PracticeService/GetFundServices', {
            params: { practiceId }
        });
        return plainToInstance(FundServiceDto, response.data);
    },

    async getServiceCategories(practiceId: number): Promise<ServiceCategoryDto[]> {
        const response = await httpService.get<ServiceCategoryDto[]>('PracticeService/GetServiceCategories', {
            params: { practiceId }
        });
        return plainToInstance(ServiceCategoryDto, response.data);
    }
};
