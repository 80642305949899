import { Type } from 'class-transformer';

import { Report365MedicalEquipmentRowDto } from '@/model/Report/Rhi/Report365/Report365MedicalEquipmentRowDto';

export class Report365MedicalEquipmentDto {
    public name: string = '';

    @Type(() => Report365MedicalEquipmentRowDto)
    public medicalEquipments: Report365MedicalEquipmentRowDto[] = [];
}
