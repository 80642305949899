<template>
    <v-card>
        <v-card-title>File and blob manager test</v-card-title>
        <v-card-text>
            <file-input v-model="uploadItems" :practice-id="practiceId" />
            <v-card-actions>
                <btn action="Upload" @click="uploadFiles" />
            </v-card-actions>
            <div v-for="(item, index) in downloadItems" :key="index">
                <v-row>
                    <v-col cols="auto">
                        <btn icon action="Download" @click="downloadItem(item)" />
                    </v-col>
                    <v-col>
                        <text-field v-model="item.key.relativePath" readonly />
                    </v-col>
                    <v-col>
                        <text-field v-model="item.fileName" readonly />
                    </v-col>
                    <v-col>
                        <text-field v-model="item.description" readonly label="Описание"></text-field>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { FileInfoDto } from '@/model/File/FileInfoDto';
    import { FileUploadCommand } from '@/model/File/FileUploadCommand';
    import { blobManagerService } from '@/service/File/BlobManagerService';
    import { externalFileService } from '@/service/File/ExternalFileService';

    import FileInput from './FileInput.vue';

    @Component({
        components: { FileInput }
    })
    export default class BlobManager extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private uploadItems: FileUploadCommand[] = [];
        private downloadItems: FileInfoDto[] = [];

        private async uploadFiles() {
            await externalFileService.uploadFiles(this.uploadItems);
            this.uploadItems = [];
            await this.loadFiles();
            this.$notifier.showSuccess('', 'Файловете са записани.');
        }

        private async loadFiles() {
            this.$loading.show();
            try {
                this.downloadItems = await blobManagerService.getPracticeItems(this.practiceId);
            } finally {
                this.$loading.hide();
            }
        }

        private async downloadItem(file: FileInfoDto) {
            this.$loading.show();
            try {
                await blobManagerService.downloadPracticeItem(file.key.practiceId, file.key.relativePath);
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadFiles();
        }
    }
</script>
