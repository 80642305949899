<template>
    <v-row>
        <v-col cols="6">
            <h3>ОБЕКТИВЕН СТАТУС</h3>
            <div class="mt-3">
                <text-field v-model="value.bdHeight" label="Ръст" required />
                <text-field v-model="value.bdWeight" label="Тегло" required />
                <text-field v-model="value.bdItm" disabled label="ИТМ" />
            </div>
        </v-col>
        <v-col cols="6">
            <h3>Артериално налягане:</h3>
            <div class="mt-3">
                <text-field v-model="value.bdSystolic" label="Систолно" required />
                <text-field v-model="value.bdDiastolic" label="Диастолно" required />
                <text-field v-model="value.bdGirtWaist" label="Обиколка на талия" required />
            </div>
        </v-col>
        <v-col cols="12">
            <btn action="Save" @click="fillInAmbulatorySheet()">Попълване на данните в АЛ</btn>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { BiometricDataDto } from '@/model/Exam/Gp/RiskAssessmentCard/BiometricDataDto';
    import { examService } from '@/service/Exam/ExamService';

    @Component
    export default class BiometricCard extends Vue {
        // Този компонент може да получи undefined v-model. В този случай се bind-ва default стойността, която е празен обект.
        @Prop({ type: Object })
        private value!: BiometricDataDto;

        private async fillInAmbulatorySheet() {
            await examService.updateExamConditionFromRiskAssessmentCard(
                ` Измерване: Систолно: ${this.value.bdSystolic} Диастолно: ${this.value.bdDiastolic} Ръст: ${this.value.bdHeight} Тегло: ${this.value.bdWeight} Обиколка на талия: ${this.value.bdGirtWaist} ИТМ: ${this.value.bdItm}`
            );
        }

        @Watch('value.bdHeight')
        private onBdHeightChanged() {
            this.calculateItm();
        }

        @Watch('value.bdWeight')
        private onBdWeightChanged() {
            this.calculateItm();
        }

        private calculateItm() {
            const hundred: number = 100;
            if (this.value.bdHeight > 0 && this.value.bdWeight > 0) {
                const heightMeters = this.value.bdHeight / hundred;
                this.value.bdItm =
                    Math.round((this.value.bdWeight / (heightMeters * heightMeters)) * hundred) / hundred;
            }
        }
    }
</script>
