import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

export class NhisExamExtractionCommand {
    public nrn: string | null = null;
    public lrn: string | null = null;
    public startDateTime: Date | null = null;
    public patientVersionKey: PatientVersionKey = new PatientVersionKey();
    public deputyDoctorUin: string | null = null;
    public doctorUin: string = '';
}
