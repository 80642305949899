import { Type } from 'class-transformer';

import { Report365SurgicalActivityRowDto } from '@/model/Report/Rhi/Report365/Report365SurgicalActivityRowDto';

export class Report365SurgicalActivityDto {
    public name: string = '';

    @Type(() => Report365SurgicalActivityRowDto)
    public surgicalActivities: Report365SurgicalActivityRowDto[] = [];
}
