<template>
    <v-card>
        <v-card-title>Данни за е-медицинска бележка</v-card-title>
        <v-card-text>
            <v-row v-if="value" no-gutters>
                <v-col md="3" class="px-1">
                    <text-field v-model="value.nrn" :class="nrnBackground" label="НРН номер" readonly></text-field>
                </v-col>
                <v-col md="3" class="px-1">
                    <text-field v-model="value.statusName" label="Статус" readonly></text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-row dense>
                <v-col v-if="canPost" md="3">
                    <btn action="Send" block :disabled="disable || isLoading" @click="postMedicalNote()" />
                </v-col>
                <v-col v-if="canCancel" md="3">
                    <btn
                        action="Delete"
                        block
                        :disabled="!canCancel || isLoading"
                        @click="postMedicalNoteCancelation()"
                    />
                </v-col>
                <v-col v-if="!id" md="3">
                    <btn action="Refresh" block :disabled="disable || isLoading" @click="dialogIsVisible = true">
                        Извличане на бележки
                    </btn>
                </v-col>
            </v-row>
        </v-card-actions>
        <nhis-medical-note-extraction
            :extraction-dialog-is-visible="dialogIsVisible"
            :exam-id="examId"
            @close="dialogIsVisible = false"
        />
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { NhisMedicalNoteStatusCode } from '@/enum/Nhis/NhisMedicalNoteStatusCode';
    import { NhisMedicalNoteDto } from '@/model/Nhis/MedicalNote/NhisMedicalNoteDto';
    import { NhisXmlRequestDto } from '@/model/Nhis/NhisXmlRequestDto';
    import { nhisMedicalNoteService } from '@/service/Nhis/NhisMedicalNoteService';
    import { nhisService } from '@/service/Nhis/NhisService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import NhisMedicalNoteExtraction from '@/view/Nhis/MedicalNote/NhisMedicalNoteExtraction.vue';

    @Component({
        components: { NhisMedicalNoteExtraction }
    })
    export default class NhisMedicalNote extends Vue {
        @Prop()
        value!: NhisMedicalNoteDto;

        @Prop()
        private disable!: boolean;

        @Prop()
        private id!: number;

        @Prop()
        private examId!: number;

        private dialogIsVisible: boolean = false;

        private get canPost() {
            return (
                !this.value?.nrn &&
                this.id &&
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private get canCancel() {
            return this.value?.nrn && this.id && this.value?.statusCode === NhisMedicalNoteStatusCode.Active;
        }

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private get nrnBackground() {
            return this.value?.nrn && this.value?.statusCode === NhisMedicalNoteStatusCode.Active
                ? 'text-field-success'
                : this.value?.nrn && this.value?.statusCode === NhisMedicalNoteStatusCode.Canceled
                  ? 'text-field-error'
                  : '';
        }

        private async postMedicalNote() {
            const response = await nhisMedicalNoteService.postMedicalNote(this.requestData());
            if (response) {
                this.change(response);
            }
        }

        private async postMedicalNoteCancelation() {
            const response = await nhisMedicalNoteService.postMedicalNoteCancel(this.requestData());
            if (response) {
                this.change(response);
            }
        }

        private change(data: NhisMedicalNoteDto) {
            this.$emit('input', data);
        }

        private requestData(): NhisXmlRequestDto {
            return nhisService.requestData(this.id, null);
        }
    }
</script>

<style scoped></style>
