import { RouteConfig } from 'vue-router';

import { oidcRoute } from '@/service/Infrastructure/OidcClient';

export const authRoutes: RouteConfig[] = [
    {
        path: oidcRoute.postLogout,
        component: () => import('@/view/Auth/PostLogout.vue')
    },
    // Не се използва при вход през този проект. Само на началната страница на IdentityServer има линк към /SigninRedirect.
    {
        path: oidcRoute.signinRedirect,
        component: () => import('@/view/Auth/SigninRedirect.vue')
    },
    {
        path: oidcRoute.signinRedirectCallback,
        component: () => import('@/view/Auth/SigninRedirectCallback.vue')
    },
    {
        path: oidcRoute.unauthorized,
        component: () => import('@/view/Auth/Unauthorized.vue')
    }
];
