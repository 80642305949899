import { AxiosResponse } from 'axios';

import { DispensaryDeregisterReasonDto } from '@/model/Nomenclature/DispensaryDeregisterReasonDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const dispensaryDeregisterReasonService = {
    getDispensaryDeregisterReasons(): Promise<AxiosResponse<DispensaryDeregisterReasonDto[]>> {
        return httpService.get('/DispensaryDeregisterReason/GetDispensaryDeregisterReasons');
    }
};
