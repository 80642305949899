<template>
    <v-form v-if="isGlobalAdminOrPracticeOwner" ref="form" v-model="isFormValid">
        <v-card>
            <v-card-title>Електронни фактури</v-card-title>
            <v-card-text>
                <report-date-picker v-model="filter" @input="getTableItems" />
                <v-row>
                    <v-col v-if="canGenerate" cols="12" md="4">
                        <btn action="Refresh" block @click="createPisInvoice">Проверка за известия</btn>
                    </v-col>
                </v-row>
                <data-table
                    v-if="items && items.length > 0"
                    :headers="tableHeaders"
                    :items="items"
                    item-key="invoiceId"
                    class="elevation-1 my-5"
                    sort-by="id"
                    sort-desc
                >
                    <template #rowActions="{ item }">
                        <btn icon action="Details" @click="invoiceDetailsPreview(item)" />
                    </template>
                </data-table>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { MonthlyNotificationDto } from '@/model/Invoice/Pis/MonthlyNotificationDto';
    import { PisInvoiceDto } from '@/model/Invoice/Pis/PisInvoiceDto';
    import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
    import { pisInvoiceService } from '@/service/Invoice/PisInvoiceService';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { practiceService } from '@/service/Practice/PracticeService';
    import { localServerPisService } from '@/service/Report/LocalServerPisService';
    import { pisService } from '@/service/Report/PisService';
    import { currentInvoiceCache } from '@/store/CurrentInvoiceCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { ReportDatePicker }
    })
    export default class PisInvoice extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private isFormValid: boolean = false;
        private filter: ReportIntervalFilterDto = new ReportIntervalFilterDto();
        private monthlyNotificationItems: MonthlyNotificationDto[] = [];
        private items: PisInvoiceDto[] = [];
        private canGenerate: boolean = false;
        private managerIdentifier: string | null = null;

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Номер на документа', value: 'number' },
                { text: 'Дата на документа', value: 'invoiceDate', formatter: formatters.date },
                { text: 'Тип документ', value: 'invoiceTypeName' },
                { text: 'Дата на данъчно събитие', value: 'taxDate', formatter: formatters.date },
                { text: 'Период от', value: 'startDate', formatter: formatters.date },
                { text: 'Период до', value: 'endDate', formatter: formatters.date },
                { text: 'Месечно известие номер', value: 'monthlyNotificationFileId' }
            ];
        }

        private get isGlobalAdminOrPracticeOwner() {
            return userContextCache.currentIsGlobalAdminOrPracticeOwner;
        }

        private async createPisInvoice() {
            this.$loading.show();
            try {
                this.monthlyNotificationItems = [];
                await this.getMonthlyNotification();

                if (this.monthlyNotificationItems.length > 0) {
                    await pisInvoiceService.monthlyNotificationToPisInvoice(this.monthlyNotificationItems);
                    await this.getTableItems();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async getMonthlyNotification() {
            if (await localServerDiagnosticService.checkIsLocalServerActive()) {
                const response = await localServerPisService.getMonthlyNotification(
                    this.managerIdentifier?.toString() ?? '',
                    this.filter.endDate ?? new Date(),
                    userContextCache.current?.practiceNumber ?? ''
                );
                if (response?.data) {
                    const items = response.data;
                    for (const notification of items.notifications) {
                        this.monthlyNotificationItems.push(notification);
                    }
                }
            }
        }

        private invoiceDetailsPreview(item: PisInvoiceDto) {
            currentInvoiceCache.set(item);
            this.$router.push(`/Report/Pis/Invoice/Details/${this.practiceId}`);
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                if (this.filter.startDate && this.filter.endDate) {
                    this.filter.practiceId = this.practiceId;
                    this.items = await pisInvoiceService.getPisInvoicesByPeriod(this.filter);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async isFilledPracticeAndDoctor() {
            const response = await pisService.isFilledPracticeAndDoctor(
                this.practiceId,
                userContextCache.currentDoctorEmployeeSeqNumber ?? 0
            );

            if (response?.data.length > 0) {
                this.canGenerate = false;
                this.$notifier.showWarning('Внимание', response.data);
            } else {
                this.canGenerate = true;
            }
        }

        private async getManagerIdentifier() {
            this.managerIdentifier = await practiceService.getPracticeManagerIdentifier(this.practiceId);
        }

        private async mounted() {
            if (!this.filter?.startDate || !this.filter?.endDate) {
                this.filter.fillPreviousMonth();
            }
            await this.getTableItems();
            await this.isFilledPracticeAndDoctor();
            await this.getManagerIdentifier();
        }
    }
</script>
