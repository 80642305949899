export class NhifDrugSearchDto {
    constructor(
        public icdCode: string,
        public formType: string,
        public protocolTypeCode: string,
        public examDate: Date | null
    ) {}

    public requirementId: number | null = null;
}
