import { plainToInstance } from 'class-transformer';

import { QuickAddInitialTariffsAndServicesDto } from '@/model/Practice/Service/QuickAddInitialTariffsAndServicesDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const quickAddInitialTariffsAndServicesService = {
    async getPracticeTariffsAndServicesInfo(practiceId: number): Promise<QuickAddInitialTariffsAndServicesDto> {
        const response = await httpService.get<QuickAddInitialTariffsAndServicesDto>(
            'QuickAddInitialTariffsAndServices/GetPracticeTariffsAndServicesInfo',
            {
                params: { practiceId }
            }
        );

        return plainToInstance(QuickAddInitialTariffsAndServicesDto, response.data);
    },
    async saveInisialTariffsAndServices(dto: QuickAddInitialTariffsAndServicesDto): Promise<void> {
        await httpService.post('QuickAddInitialTariffsAndServices/SaveInitialsTariffsAndServices', dto);
    }
};
