import { Type } from 'class-transformer';

import { NhisErrorDto } from '@/model/Nhis/NhisErrorDto';
import { NhisResponseWarningDto } from '@/model/Nhis/NhisResponseWarningDto';
import { NhisExtractedReferralDto } from '@/model/Nhis/Referral/NhisExtractedReferralDto';

export class NhisReferralExtractionResultDto {
    @Type(() => NhisExtractedReferralDto)
    public referrals: NhisExtractedReferralDto[] = [];

    @Type(() => NhisErrorDto)
    public errors: NhisErrorDto[] = [];

    @Type(() => NhisResponseWarningDto)
    public warnings: NhisResponseWarningDto[] = [];

    public error: string = '';
    public response: string = '';
}
