<template>
    <v-badge :content="unreadNotificationsCount" :value="unreadNotificationsCount" color="info" overlap>
        <btn icon action="Email" @click.stop="toggleNotificationsDrawer" />
    </v-badge>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { notifications } from '@/store/Notifications';

    @Component
    export default class NotificationsBadge extends Vue {
        private get unreadNotificationsCount() {
            return notifications.unreadCount;
        }

        private toggleNotificationsDrawer() {
            notifications.drawerIsVisible = !notifications.drawerIsVisible;
        }
    }
</script>
