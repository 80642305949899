<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Искане за образно изследване" :items="items">
                <template #actions>
                    <btn action="New" :to="`/Exam/RequestForImagingTest/Create/${examId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/RequestForImagingTest/Edit/${item.id}`" />
                    <btn icon action="Print" :to="`/Print/Exam.RequestForImagingTest/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RequestForImagingTestViewDto } from '@/model/Exam/RequestForImagingTest/RequestForImagingTestViewDto';
    import { requestForImagingTestService } from '@/service/Exam/RequestForImagingTestService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class RequestForImagingTestView extends Vue {
        @Prop()
        examId!: number;

        private items: RequestForImagingTestViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: `Специални указания`, value: 'specialInstructions' },
                { text: 'Вероятна диагноза', value: 'probableIcd' },
                { text: 'С молба за', value: 'requestFor' },
                { text: 'Данни от статуса и проведените изследвания', value: 'labTestsResult' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                const response = await requestForImagingTestService.getRequestForImagingTestByExam(this.examId);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
