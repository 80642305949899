<template>
    <v-card>
        <v-card-title>Номерация на документите на практиката</v-card-title>
        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn action="Save" @click="updatePracticeCounter" />
        </v-card-actions>
        <v-card-text>
            <v-form ref="documentForm" v-model="isValid">
                <dropdown
                    v-model="practiceCounter.counterTypeCode"
                    :items="counterTypes"
                    item-value="code"
                    label="Вид документи"
                    required
                ></dropdown>
                <text-field v-model.number="practiceCounter.nextNumber" label="Следващ номер" type="number" required />
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { CounterTypeDto } from '@/model/Nomenclature/CounterTypeDto';
    import { PracticeCounterDto } from '@/model/Setting/Counter/PracticeCounterDto';
    import { counterTypeService } from '@/service/Nomenclature/CounterTypeService';
    import { practiceCounterService } from '@/service/Setting/Counter/PracticeCounterService';

    @Component
    export default class PracticeCounterEdit extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop()
        private counterTypeCode!: string;

        private counterTypes: CounterTypeDto[] = [];
        private practiceCounter: PracticeCounterDto = new PracticeCounterDto();

        private isValid: boolean = false;

        private async mounted() {
            this.$loading.show();
            try {
                await this.loadCounterTypes();

                if (this.counterTypeCode) {
                    const response = await practiceCounterService.getPracticeCounter(
                        this.practiceId,
                        this.counterTypeCode
                    );
                    this.practiceCounter = response.data;
                } else {
                    this.practiceCounter.practiceId = this.practiceId;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async loadCounterTypes() {
            const response = await counterTypeService.getPracticeCounterTypes();
            this.counterTypes = response.data;
        }

        private async updatePracticeCounter() {
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

            if (this.isValid) {
                if (!this.counterTypeCode) {
                    await practiceCounterService.createPracticeCounter(this.practiceCounter);
                } else {
                    await practiceCounterService.updatePracticeCounter(this.practiceCounter);
                }
                this.$router.push(this.backRoute);
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private get backRoute() {
            return `/Setting/PracticeCounter/List/${this.practiceCounter.practiceId}`;
        }
    }
</script>
