export class AntrhropometryDto {
    public id: number = 0;
    public examId: number = 0;
    public issueDate: Date | null = null;
    public height: number | null = null;
    public weight: number | null = null;
    public chestRelax: number | null = null;
    public chestInhalation: number | null = null;
    public chestExhalation: number | null = null;
    public indexTm: number | null = null;
    public fontanelle1: number | null = null;
    public fontanelle2: number | null = null;
    public waist: number | null = null;
    public age: number = 0;
    public headCircumference: number | null = null;
}
