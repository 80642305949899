import { plainToInstance } from 'class-transformer';

import { CurrentGpDto } from '@/model/Patient/PreviousGp/CurrentGpDto';
import { PreviousGpCreateResultDto } from '@/model/Patient/PreviousGp/PreviousGpCreateResultDto';
import { PreviousGpDropdownDto } from '@/model/Patient/PreviousGp/PreviousGpDropdownDto';
import { PreviousGpDto } from '@/model/Patient/PreviousGp/PreviousGpDto';
import { SetPreviousGpCommand } from '@/model/Patient/PreviousGp/SetPreviousGpCommand';
import { IDropdownSearchService } from '@/service/Dropdown/IDropdownSearchService';
import { httpService } from '@/service/Infrastructure/HttpService';

const previousGpService = {
    async getPatientPreviousGp(patientId: number, pvSeqNumber: number): Promise<PreviousGpDto> {
        const response = await httpService.get<PreviousGpDto>('PreviousGp/GetPatientPreviousGp', {
            params: { patientId, pvSeqNumber }
        });
        return plainToInstance(PreviousGpDto, response.data);
    },

    async createPatientPreviousGp(
        patientId: number,
        pvSeqNumber: number,
        dto: PreviousGpDto
    ): Promise<PreviousGpCreateResultDto> {
        const response = await httpService.post<PreviousGpCreateResultDto>('/PreviousGp/CreatePatientPreviousGp', dto, {
            params: { patientId, pvSeqNumber }
        });
        return plainToInstance(PreviousGpCreateResultDto, response.data);
    },

    async setPatientPreviousGp(command: SetPreviousGpCommand): Promise<PreviousGpDto> {
        const response = await httpService.put('/PreviousGp/SetPatientPreviousGp', command);
        return plainToInstance(PreviousGpDto, response.data);
    },

    async getCurrentGpCaptcha(): Promise<CurrentGpDto> {
        return (await httpService.get<CurrentGpDto>('/PreviousGp/GetCurrentGpCaptcha')).data;
    },

    async setGpFromNhifAsPreviousGp(
        patientId: number,
        pvSeqNumber: number,
        dto: CurrentGpDto
    ): Promise<PreviousGpCreateResultDto> {
        return (
            await httpService.post<PreviousGpCreateResultDto>('/PreviousGp/SetGpFromNhifAsPreviousGp', dto, {
                params: { patientId, pvSeqNumber }
            })
        ).data;
    }
};

const gpSearchService: IDropdownSearchService<PreviousGpDropdownDto> = {
    // Не се използва, защото dropdown-ът е в режим return-object и получава началната стойност наготово.
    getItemsById(): Promise<PreviousGpDropdownDto[]> {
        return Promise.resolve([]);
    },

    async searchItems(filters: []): Promise<PreviousGpDropdownDto[]> {
        const response = await httpService.get<PreviousGpDropdownDto[]>('/PreviousGp/SearchGp', {
            params: { filter: filters.at(0) }
        });
        return plainToInstance(PreviousGpDropdownDto, response.data);
    }
};

export { gpSearchService, previousGpService };
