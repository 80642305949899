<template>
    <v-tooltip v-if="licensingStatus.message" bottom>
        <template #activator="{ on }">
            <v-btn icon class="mr-2" :style="buttonStyle" :to="licensingRoute" v-on="on">
                <v-icon :color="licensingStatus.severity">mdi-lock-open</v-icon>
            </v-btn>
        </template>
        {{ licensingStatus.message }}
    </v-tooltip>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { licensingCache } from '@/store/LicensingCache';

    @Component
    export default class LicensingStatusIcon extends Vue {
        private get licensingStatus() {
            return licensingCache.status;
        }

        // За фон на бутона се използва custom цвят warning2 или error2 от Vuetify темата.
        private get buttonStyle() {
            const { status } = licensingCache;
            return status.severity
                ? {
                      backgroundColor: `var(--v-${status.severity}2-base)`
                  }
                : null;
        }

        private get licensingRoute() {
            const { practiceId } = licensingCache.status.practiceFeatures;
            return practiceId ? `/Practice/Licensing/${practiceId}` : null;
        }
    }
</script>
