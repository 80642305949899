import { Type } from 'class-transformer';

import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

export class PrevNextExamDto {
    @Type(() => PatientVersionKey)
    public prevPatientVersionKey: PatientVersionKey | null = null;

    public prevVisitId: number | null = null;
    public prevVisitStartDateTime: Date | null = null;
    public prevExamNhifVisitReasonCode: string | null = null;

    @Type(() => PatientVersionKey)
    public nextPatientVersionKey: PatientVersionKey | null = null;

    public nextVisitId: number | null = null;
    public nextVisitStartDateTime: Date | null = null;
}
