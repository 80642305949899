import Vue from 'vue';

import { GenderDto } from '@/model/Nomenclature/GenderDto';
import { genderService } from '@/service/Nomenclature/GenderService';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

const loadingItem = new GenderDto();
loadingItem.name = 'Зареждане...';
const loadErrorItem = new GenderDto();
loadErrorItem.name = 'Грешка при зареждане';

class GenderCache extends ArrayCacheBase<GenderDto> {
    constructor() {
        super(loadingItem, loadErrorItem);
    }

    protected async load() {
        const response = await genderService.getGenders();
        this._items = response.data;
    }
}

export const genderCache = Vue.observable(new GenderCache());
