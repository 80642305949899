<template>
    <v-dialog v-model="dialogIsVisible" persistent>
        <template #activator="{ on }">
            <btn action="List" v-on="on">НЗИС профилактика</btn>
        </template>
        <v-card>
            <v-row class="justify-space-between" style="position: fixed; z-index: 2; width: 100%">
                <v-col cols="auto">
                    <main-title>НЗИС профилактика</main-title>
                </v-col>
                <v-col cols="auto">
                    <btn action="Save" class="mr-10" @click="dialogIsVisible = false" />
                </v-col>
            </v-row>
            <v-card-text class="pt-15">
                <v-row v-for="(questionnaire, index) in value.questionnaries" :key="index + 400" no-gutters>
                    <nhis-questionnaire v-model="value.questionnaries[index]" />
                    <btn
                        v-if="value.questionnaries.length > 0"
                        icon
                        action="Delete"
                        @click="removeQuestionnaire(index)"
                    />
                </v-row>
                <v-card-actions>
                    <btn action="New" @click="addQuestionnaire"> Добавяне на въпросник </btn>
                </v-card-actions>
                <v-row v-for="(procedure, index) in value.procedures" :key="index + 300" class="mt-5" no-gutters>
                    <v-col cols="11">
                        <nhis-exam-medical-procedure :value="procedure" :position="index + 1" />
                    </v-col>
                    <btn
                        v-if="value.procedures && value.procedures.length > 0"
                        icon
                        action="Delete"
                        @click="removeMedicalProcedure(index)"
                        >Изтриване на процедура</btn
                    >
                </v-row>
                <v-row v-for="(labTest, index) in value.labTests" :key="index + 500" class="mt-1">
                    <v-col cols="3" md="1">
                        <text-field v-model="labTest.nhifCode" label="Код по НЗОК" readonly />
                    </v-col>
                    <v-col cols="3" md="1">
                        <text-field v-model="labTest.nhisCode" label="Код по НЗИС" readonly />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field v-model="labTest.name" label="Изследване" readonly />
                    </v-col>
                    <v-col cols="3" md="1">
                        <text-field v-model="labTest.fundCodeName" label="Финансиране" readonly />
                    </v-col>
                    <btn
                        v-if="value.labTests && value.labTests.length > 0"
                        icon
                        action="Delete"
                        @click="removeLabTest(index)"
                    />
                </v-row>
                <v-row>
                    <v-col md="3" cols="6">
                        <v-row v-for="(nrnAlegy, index) in value.nrnAllergies" :key="index + 100" no-gutters>
                            <v-col class="d-flex flex-row">
                                <text-field
                                    v-model="value.nrnAllergies[index]"
                                    :rules="[$validator.nrn]"
                                    :label="`НРН Алергия ${index + 1}`"
                                />
                                <btn
                                    v-if="value.nrnAllergies.length > 0"
                                    icon
                                    action="Delete"
                                    @click="removeAllergy(index)"
                                />
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <btn action="New" block @click="addAllergy"> Добавяне на алергия </btn>
                        </v-card-actions>
                    </v-col>
                    <v-col md="3" cols="6">
                        <v-row v-for="(familyHistory, index) in value.nrnFamilyHistories" :key="index + 200" no-gutters>
                            <v-col class="d-flex flex-row">
                                <text-field
                                    v-model="value.nrnFamilyHistories[index]"
                                    :rules="[$validator.nrn]"
                                    :label="`НРН Фамилна обремененост ${index + 1}`"
                                />
                                <btn
                                    v-if="value.nrnFamilyHistories.length > 0"
                                    icon
                                    action="Delete"
                                    @click="removeFamilyHistory(index)"
                                />
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <btn action="New" block @click="addFamilyHistory"> Добавяне на фамилна обремененост </btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import Btn from '@/component/Common/Btn.vue';
    import MainTitle from '@/component/Common/MainTitle.vue';
    import TextField from '@/component/Common/TextField.vue';
    import { NhisMedicalHistoryDto } from '@/model/Nhis/Exam/NhisMedicalHistoryDto';
    import { NhisQuestionnaireDto } from '@/model/Nhis/Exam/PreventiveActivities/Questionnaire/NhisQuestionnaireDto';
    import { arrayUtil } from '@/util/ArrayUtil';
    import NhisExamMedicalProcedure from '@/view/Nhis/Exam/NhisExamMedicalProcedure.vue';
    import NhisQuestionnaire from '@/view/Nhis/Exam/NhisQuestionnaire.vue';

    @Component({
        components: { MainTitle, NhisExamMedicalProcedure, NhisQuestionnaire, Btn, TextField }
    })
    export default class NhisMedicalHistory extends Vue {
        @Prop()
        value!: NhisMedicalHistoryDto;

        private dialogIsVisible: boolean = false;

        private addAllergy() {
            this.value.nrnAllergies.push('');
        }

        private removeAllergy(index: number) {
            arrayUtil.removeAt(this.value.nrnAllergies, index);
        }

        private addFamilyHistory() {
            this.value.nrnFamilyHistories.push('');
        }

        private removeFamilyHistory(index: number) {
            arrayUtil.removeAt(this.value.nrnFamilyHistories, index);
        }

        private addQuestionnaire() {
            this.value.questionnaries.push(new NhisQuestionnaireDto());
        }

        private removeQuestionnaire(index: number) {
            arrayUtil.removeAt(this.value.questionnaries, index);
        }

        private removeLabTest(index: number) {
            arrayUtil.removeAt(this.value.labTests, index);
        }

        private removeMedicalProcedure(index: number) {
            arrayUtil.removeAt(this.value.procedures, index);
        }

        @Watch('value.nhisPreventiveExamTypeCode')
        onNhisPreventiveExamTypeCodeChange(newVal: string[] | null) {
            if (newVal) {
                this.dialogIsVisible = true;
            }
        }
    }
</script>

<style scoped></style>
