<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col>
                    <v-menu open-on-hover bottom offset-y pointer>
                        <template #activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <btn action="Print" :disabled="!canPrint" block v-bind="attrs" v-on="on"
                                    >Печат на график</btn
                                >
                            </div>
                        </template>
                        <v-list v-if="canPrint">
                            <v-list-item @click="printSchedule()">
                                <v-list-item-title>За избран ден</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printSchedule(true)">
                                <v-list-item-title>За седмицата</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
                <v-col>
                    <dropdown label="Кабинет" :items="[]"></dropdown>
                </v-col>
                <v-col>
                    <dropdown v-model="selectedDoctorId" clearable :items="doctors" label="Лекар"></dropdown>
                </v-col>
                <v-col>
                    <dropdown
                        v-model="selectedSpecialtyCode"
                        :items="specialties"
                        item-value="code"
                        label="Специалност"
                        clearable
                    ></dropdown>
                </v-col>
                <v-col>
                    <dropdown
                        v-model="selectedWorkTimeVisitReasonId"
                        :items="workTimeVisitReasons"
                        label="Тип"
                        clearable
                    ></dropdown>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-row>
                        <v-col cols="4" class="text-start">
                            <btn icon action="Back" @click="previousButtonClicked" />
                        </v-col>
                        <v-col cols="4" class="text-center">
                            <div>
                                <span v-if="$refs.calendar">{{ $refs.calendar.title }}</span>
                            </div>
                        </v-col>
                        <v-col cols="4" class="text-end">
                            <btn icon action="Next" @click="nextButtonClicked" />
                        </v-col>
                    </v-row>
                    <v-calendar
                        ref="calendar"
                        v-model="selectedDate"
                        :locale="locale"
                        :type="calendarType"
                        :start="calendarStartDate"
                        show-month-on-first
                        :events="scheduleExamEvents.filter((x) => !x.disabled)"
                        :interval-count="26"
                        :interval-minutes="intervalMinutes"
                        :interval-height="intervalHeight"
                        :first-interval="13"
                        :weekdays="weekDaysOrder"
                        color="primary"
                        @click:event.native.stop
                        @mousedown:event.native.stop.prevent
                        @mouseup:event.native.stop.prevent
                    >
                        <template #event="{ event }">
                            <div
                                @click="
                                    () => {
                                        _ = getCurrentScheduledExamByInterval(
                                            event,
                                            `${getTimeStringFromDate(event.start)}-${getTimeStringFromDate(event.end)}`
                                        );
                                        _[0] ? scheduledExamClicked(event, _[0]) : freeIntervalClicked(event);
                                    }
                                "
                            >
                                {{ getTimeStringFromDate(event.start) }} - {{ getTimeStringFromDate(event.end) }}
                                <span
                                    v-for="(scheduledExam, index) in getCurrentScheduledExamByInterval(
                                        event,
                                        `${getTimeStringFromDate(event.start)}-${getTimeStringFromDate(event.end)}`
                                    )"
                                    :key="index"
                                >
                                    {{ scheduledExam.patientName }}
                                </span>
                            </div>
                        </template>
                    </v-calendar>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-10 pb-0">
                    <work-time-visit-reason-colors-legend
                        :work-time-visit-reasons="workTimeVisitReasons"
                    ></work-time-visit-reason-colors-legend>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { RRule, RRuleSet } from 'rrule';
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { calendarConfig, VuetifyCalendarType } from '@/model/Calendar/CalendarConfig';
    import { DoctorDropdownDto } from '@/model/Doctor/DoctorDropdownDto';
    import { ExamScheduleCalendarEventModel } from '@/model/ExamSchedule/ExamScheduleCalendarEventModel';
    import { ScheduledExamCalendarDto } from '@/model/ExamSchedule/ScheduledExamCalendarDto';
    import { ScheduledExamFilterCommand } from '@/model/ExamSchedule/ScheduledExamFilterCommand';
    import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';
    import { WorkTimeVisitReasonDto } from '@/model/Nomenclature/WorkTimeVisitReasonDto';
    import { EventDto } from '@/model/WorkTime/EventDto';
    import { doctorService } from '@/service/Doctor/DoctorService';
    import { examScheduleService } from '@/service/ExamSchedule/ExamScheduleService';
    import { workTimeVisitReasonService } from '@/service/Nomenclature/WorkTimeVisitReasonService';
    import { workTimeService } from '@/service/WorkTime/WorkTimeService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';
    import WorkTimeVisitReasonColorsLegend from '@/view/WorkTime/WorkTimeVisitReasonColorsLegend.vue';

    const daysInWeek = 7;

    @Component({
        components: { WorkTimeVisitReasonColorsLegend }
    })
    export default class ScheduledExamsCalendar extends Vue {
        @Prop()
        private eventId!: number | null;

        @Prop()
        private date!: Date | null;

        private locale: string = calendarConfig.locale;
        private calendarType: VuetifyCalendarType = calendarConfig.defaultType;
        private weekDaysOrder: number[] = calendarConfig.weekDaysOrder;
        private intervalHeight: number = calendarConfig.intervalHeight;
        private intervalMinutes: number = calendarConfig.intervalMinutes;
        private calendarStartDate: Date = new Date(new Date().toDateString());
        private initialLoad: boolean = false;
        private doctors: DoctorDropdownDto[] = [];
        private selectedDoctorId: number | null = null;
        private specialties: SpecialtyDto[] = [];
        private selectedSpecialtyCode: SpecialtyCode | null = null;
        private workTimeVisitReasons: WorkTimeVisitReasonDto[] = [];
        private selectedWorkTimeVisitReasonId: number | null = null;
        private selectedDate: Date | null = null;

        private rawEvents: EventDto[] = [];
        private calendarEvents: ExamScheduleCalendarEventModel[] = [];
        private scheduleExamEvents: ExamScheduleCalendarEventModel[] = [];

        private get calendar() {
            return this.$refs.calendar;
        }

        private get canPrint() {
            return this.selectedDoctorId && this.selectedSpecialtyCode;
        }

        private getTimeStringFromDate(date: Date) {
            const padLength = 2;
            return `${date.getHours().toString().padStart(padLength, '0')}:${date
                .getMinutes()
                .toString()
                .padStart(padLength, '0')}`;
        }

        private getTimeStringFromDateWithInterval(date: Date, intervalInMinutes: number) {
            const newDate = new Date(date);
            const minutesInHour = 60;
            const milliseconds = 1000;
            newDate.setTime(date.getTime() + intervalInMinutes * minutesInHour * milliseconds);
            const padLength = 2;
            return `${newDate.getHours().toString().padStart(padLength, '0')}:${newDate
                .getMinutes()
                .toString()
                .padStart(padLength, '0')}`;
        }

        private getCurrentScheduledExamByInterval(event: ExamScheduleCalendarEventModel, title: string) {
            if (event.scheduledExams.length) {
                const result = event.scheduledExams.filter(
                    (scheduledExam) =>
                        `${this.getTimeStringFromDate(
                            scheduledExam.examDateTime as Date
                        )}-${this.getTimeStringFromDateWithInterval(
                            scheduledExam.examDateTime as Date,
                            event.examDurationInMinutes as number
                        )}` === title
                );

                if (result.length) {
                    return result;
                }
            }
            return [];
        }

        private freeIntervalClicked(event: ExamScheduleCalendarEventModel) {
            this.$emit('dateSelected', event.id, event.start);
        }

        private scheduledExamClicked(event: ExamScheduleCalendarEventModel, exam: ScheduledExamCalendarDto) {
            this.$emit('onUpdateSelected', event.id, exam.examDateTime, exam.id);
        }

        private async mounted() {
            this.calendarStartDate = this.date ? new Date(this.date) : new Date();
            this.doctors = await doctorService.getDoctorsByPracticeId(userContextCache.current?.practiceId ?? 0);
            this.getAllSpecialtiesForPractice();
            this.getWorkTimeVisitReasonTypes();

            if (userContextCache.current && userContextCache.current.doctorUin && !this.eventId) {
                this.selectedDoctorId = userContextCache.current.doctorId;
                this.selectedSpecialtyCode = userContextCache.current.specialtyCode;
            } else {
                this.loadEvents();
            }
            this.selectedDate = this.calendarStartDate;
        }

        private async previousButtonClicked() {
            this.calendarInstance.prev();

            const newStartDate =
                this.calendarStartDate.getDate() - calendarConfig.getCalendarOffsetByType(this.calendarType);
            this.calendarStartDate = new Date(this.calendarStartDate.setDate(newStartDate));
            this.$loading.show();
            try {
                await this.addRepeatedEvents(this.rawEvents);
            } finally {
                this.$loading.hide();
            }
        }

        private async nextButtonClicked() {
            this.calendarInstance.next();

            const newStartDate =
                this.calendarStartDate.getDate() + calendarConfig.getCalendarOffsetByType(this.calendarType);
            this.calendarStartDate = new Date(this.calendarStartDate.setDate(newStartDate));
            this.$loading.show();
            try {
                await this.addRepeatedEvents(this.rawEvents);
            } finally {
                this.$loading.hide();
            }
        }

        private get calendarInstance(): Vue & {
            prev: () => void;
            next: () => void;
        } {
            return this.$refs.calendar as Vue & {
                prev: () => void;
                next: () => void;
            };
        }

        private async loadEvents() {
            this.$loading.show();
            try {
                const practiceId = userContextCache.current?.practiceId ?? 0;
                const response = await workTimeService.getExamScheduleEventsFiltered(
                    practiceId,
                    this.selectedDoctorId,
                    this.selectedSpecialtyCode,
                    this.selectedWorkTimeVisitReasonId
                );

                this.rawEvents = response.data;
                await this.addRepeatedEvents(this.rawEvents);
                this.setFilters();
            } finally {
                this.$loading.hide();
            }
        }

        private setFilters() {
            if (this.eventId && !this.initialLoad) {
                // eslint-disable-next-line prefer-destructuring
                const result = this.calendarEvents.filter((ev) => ev.id === this.eventId)[0];
                this.selectedDoctorId = result.doctorId;
                this.selectedSpecialtyCode = result.specialtyCode;
            }
            this.initialLoad = true;
        }

        async addRepeatedEvents(events: EventDto[]) {
            const scheduledExams = await this.getScheduledExams();

            this.calendarEvents = [];
            this.scheduleExamEvents = [];
            for (const event of events) {
                if (event.recurrence) {
                    const rule = new RRule({
                        freq: event.recurrence.rRuleRecurrenceType ?? 0,
                        interval: event.recurrence.interval ?? 1,
                        count: event.recurrence.count,
                        dtstart: event.recurrence.startDate,
                        until: event.recurrence.endDate || this.calendarEndDate
                    });

                    const rruleSet = new RRuleSet();
                    rruleSet.rrule(rule);

                    if (event.recurrence.eventRecurrenceExclusions) {
                        for (const exclusionDate of event.recurrence.eventRecurrenceExclusions) {
                            rruleSet.exdate(exclusionDate);
                        }
                    }

                    const startDate = new Date(this.calendarStartDate);
                    startDate.setDate(startDate.getDate() - daysInWeek);
                    const dates = rruleSet.between(startDate, this.calendarEndDate);

                    for (const date of dates) {
                        const filteredScheduledExams = scheduledExams.filter(
                            (se) =>
                                se.examDateTime?.toDateString() === date.toDateString() &&
                                se.employeeSeqNumber === event.employeeSeqNumber &&
                                se.workTimeVisitReasonId === event.workTimeVisitReasonId
                        );
                        this.calendarEvents.push(
                            examScheduleService.createExamScheduleEvent(event, filteredScheduledExams, date)
                        );
                    }
                } else {
                    this.calendarEvents.push(examScheduleService.createExamScheduleEvent(event, scheduledExams, null));
                }
            }
            this.createCalendarEventsFromExamSchedules(
                this.calendarEvents,
                this.createWorkTimeEventItemByExamScheduleDto(scheduledExams ?? [])
            );
        }

        private createCalendarEventsFromExamSchedules(
            events: ExamScheduleCalendarEventModel[],
            examSchFromDb: ExamScheduleCalendarEventModel[]
        ) {
            for (const event of events) {
                const examScheduleArray = examScheduleService.createWorkTimeEvents(event);
                for (const examSchedule of examScheduleArray) {
                    this.scheduleExamEvents.push(examSchedule);
                }
            }
            for (const exam of examSchFromDb) {
                this.unionCalendarEvents(exam);
            }
        }

        private createWorkTimeEventItemByExamScheduleDto(items: ScheduledExamCalendarDto[]) {
            const array = [];
            if (items.length > 0) {
                for (const item of items) {
                    array.push(examScheduleService.createWorkTimeEventItemByExamScheduleDto(item));
                }
            }
            return array;
        }

        private async getScheduledExams() {
            const filter = new ScheduledExamFilterCommand();
            filter.practiceId = userContextCache.currentPracticeId ?? 0;
            filter.fromDate = new Date(this.calendarStartDate);
            filter.fromDate.setDate(filter.fromDate.getDate() - daysInWeek);
            filter.toDate = this.calendarEndDate;
            filter.doctorId = this.selectedDoctorId;
            filter.specialtyCode = this.selectedSpecialtyCode;

            return (await examScheduleService.getScheduledExamsForDates(filter)).data;
        }

        private get calendarEndDate() {
            const endDate = new Date(this.calendarStartDate);
            endDate.setDate(this.calendarStartDate.getDate() + daysInWeek);
            return endDate;
        }

        private async getAllSpecialtiesForPractice() {
            this.specialties = (
                await workTimeService.getAllSpecialtiesForPractice(userContextCache.currentPracticeId ?? 0)
            ).data;
        }

        private async getWorkTimeVisitReasonTypes() {
            this.workTimeVisitReasons = (await workTimeVisitReasonService.getWorkTimeVisitReasons()).data;
        }

        private async getSelectedDoctorSpecialties() {
            if (this.selectedDoctorId) {
                this.specialties = (
                    await workTimeService.getRegisteredSpecialtiesForDoctorAndPractice(
                        userContextCache.currentPracticeId ?? 0,
                        this.selectedDoctorId ?? 0
                    )
                ).data;
            } else {
                this.getAllSpecialtiesForPractice();
            }
        }

        private printSchedule(isWeek: boolean = false) {
            const selectedDate = this.selectedDate ?? this.calendarStartDate;
            let printStartDate = '';
            let printEndDate = '';
            ({ printStartDate, printEndDate } = this.getFromAndToPrintDates(isWeek, selectedDate));
            this.$router.push(
                `/Print/ExamSchedule.ExamSchedule/ExamSchedule/${userContextCache.currentPracticeId ?? 0}/${
                    this.selectedDoctorId
                }/${this.selectedSpecialtyCode}/${printStartDate}/${printEndDate}`
            );
        }

        private getFromAndToPrintDates(isWeek: boolean, selectedDate: Date) {
            let printStartDate = '';
            let printEndDate = '';
            if (isWeek) {
                const dayOfWeek = this.calendarStartDate.getDay();
                const startDate = new Date(this.calendarStartDate);
                const endDate = new Date(this.calendarStartDate);

                if (dayOfWeek === 1) {
                    endDate.setDate(endDate.getDate() + daysInWeek - 1);
                } else if (dayOfWeek === 0) {
                    startDate.setDate(endDate.getDate() - daysInWeek);
                } else {
                    startDate.setDate(startDate.getDate() - dayOfWeek + 1);
                    endDate.setDate(endDate.getDate() + daysInWeek - dayOfWeek);
                }

                printStartDate = dateUtil.formatBgDate(startDate);
                printEndDate = dateUtil.formatBgDate(endDate);
            } else {
                printStartDate = dateUtil.formatBgDate(selectedDate);
                printEndDate = dateUtil.formatBgDate(selectedDate);
            }

            return { printStartDate, printEndDate };
        }

        private unionCalendarEvents(value: ExamScheduleCalendarEventModel) {
            for (const item of this.scheduleExamEvents) {
                const start = examScheduleService.getTimeInMinutes(item.start as Date);
                const end = examScheduleService.getTimeInMinutes(item.end as Date);
                if (value.start?.getDate() === item.start?.getDate()) {
                    const currentElStart = examScheduleService.getTimeInMinutes(value.start as Date);
                    const currentElEnd = examScheduleService.getTimeInMinutes(value.end as Date);
                    if (start === currentElStart) {
                        item.disabled = true;
                    }
                    if (currentElStart < end && currentElEnd > start) {
                        item.disabled = true;
                    }
                }
            }
            this.scheduleExamEvents.push(value);
        }

        @Watch('selectedDoctorId')
        private async onSelectedDoctorIdChanged() {
            this.$loading.show();
            this.getSelectedDoctorSpecialties();
            await this.loadEvents();
            this.$loading.hide();
        }

        @Watch('selectedSpecialtyCode')
        private async onSelectedSpecialtyCodeChanged() {
            // TODO: Да се оптимизира филтрирането на лекар да става client-side
            if (!this.selectedDoctorId && this.selectedSpecialtyCode) {
                this.doctors = await doctorService.getDoctorsByPracticeAndSpecialty(
                    userContextCache.currentPracticeId ?? 0,
                    this.selectedSpecialtyCode
                );
            } else {
                this.doctors = await doctorService.getDoctorsByPracticeId(userContextCache.current?.practiceId ?? 0);
            }
            this.$loading.show();
            await this.loadEvents();
            this.$loading.hide();
        }

        @Watch('selectedWorkTimeVisitReasonId')
        private async onSelectedWorkTimeVisitReasonIdChanged() {
            this.$loading.show();
            await this.loadEvents();
            this.$loading.hide();
        }
    }
</script>

<style lang="scss" scoped>
    .freeInterval,
    .occupiedInterval {
        position: absolute;
        left: 0;
        right: 0;
    }

    .freeInterval:hover {
        opacity: 0.5;
    }

    .occupiedInterval {
        filter: brightness(120%);
    }

    .occupiedInterval:hover {
        cursor: not-allowed;
    }
    .templateSpan {
        max-width: fit-content;
        font-size: 90%;
        text-wrap: wrap;
    }
</style>
