<template>
    <exam-layout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Искане за образно изследване"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <main-title>Искане за образно изследване</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6"><PatientInfo /></v-col>
                    <v-col cols="12" md="6"><DoctorInfo /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <text-field v-model="document.department" label="Лечебно заведение" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <city-picker
                            v-model="document.departmentCityId"
                            label="Местоположение на заведението:"
                        ></city-picker>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3">
                        <date-picker v-model="document.issueDate" required label="Дата:" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <text-field v-model="document.probableIcd" label="Вероятно заболяване" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <date-picker v-model="document.lastMenstruation" label="ПРМ:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea v-model="document.requestFor" label="С молба за" dense />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="document.specialInstructions" label="Специални указания" dense />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            v-model="document.labTestsResult"
                            label="Данни от статуса и проведените изследвания"
                            dense
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </exam-layout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import CityPicker from '@/component/Picker/CityPicker.vue';
    import { RequestForImagingTestDto } from '@/model/Exam/RequestForImagingTest/RequestForImagingTestDto';
    import { requestForImagingTestService } from '@/service/Exam/RequestForImagingTestService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: { UnsavedChanges, CityPicker, DoctorInfo, PatientInfo, ExamLayout },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class RequestForImagingTest extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        documentId!: number;

        @Prop()
        examId!: number;

        private document: RequestForImagingTestDto = new RequestForImagingTestDto();
        private initialValue: RequestForImagingTestDto = new RequestForImagingTestDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                await requestForImagingTestService.deleteRequestForImagingTest(this.documentId);
                this.copyObject();
                this.$router.push(`/Exam/RequestForImagingTest/Create/${this.document.examId}`);
                eventBus.$emit(
                    'delete-referral-event',
                    this.documentId,
                    'RequestForImagingTest',
                    'Искане за образно изследване'
                );
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (!this.documentId) {
                    this.document.examId = this.examId;
                    const response = await requestForImagingTestService.createRequestForImagingTest(this.document);
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit(
                        'create-referral-event',
                        documentId,
                        'RequestForImagingTest',
                        'Искане за образно изследване'
                    );
                    this.$router.push(`/Exam/RequestForImagingTest/Edit/${documentId}`);
                } else {
                    await requestForImagingTestService.updateRequestForImagingTest(this.document);
                    eventBus.$emit('update-referral-event', 'Искане за образно изследване');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.RequestForImagingTest/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.RequestForImagingTest';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                if (this.documentId) {
                    this.document = await requestForImagingTestService.getRequestForImagingTest(this.documentId);
                } else {
                    this.document.probableIcd = currentVisitCache.value.exam?.examDiagnoses[0]?.icdName ?? '';
                    this.document.issueDate = currentVisitCache.value.startDateTime;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }
    }
</script>
