<template>
    <div>
        <v-card v-if="!examId">
            <v-card-title>Групово изпращане на документи към НЗИС</v-card-title>
            <v-card-text>
                <report-date-picker v-model="filter" :disabled="$loading.isVisible" by-day @input="getUploadSummary" />
                <v-textarea v-model="filesSummary" label="Обобщено" rows="11" />
                <v-textarea v-if="uploadResult" v-model="uploadResult" label="Резултат от изпращането" rows="10" />

                <btn action="Send" block @click="sendForms">Изпращане на всички бланки</btn>
            </v-card-text>
        </v-card>
        <v-row justify="center" class="mx-5">
            <v-dialog v-model="uploadResultDialogVisible" persistent max-width="75%">
                <v-card>
                    <v-card-title>
                        <span class="headline">Резултат от изпращането</span>
                    </v-card-title>
                    <v-card-text>
                        <v-textarea v-if="uploadResult" v-model="uploadResult" rows="10" />
                    </v-card-text>
                    <v-card-actions>
                        <btn action="Close" @click="uploadResultDialogVisible = false">Затваряне</btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { NhisExamDto } from '@/model/Nhis/Exam/NhisExamDto';
    import { NhisGroupSummaryDto } from '@/model/Nhis/GroupUpload/NhisGroupSummaryDto';
    import { NhisGroupUploadDto } from '@/model/Nhis/GroupUpload/NhisGroupUploadDto';
    import { NhisGroupUploadFilterDto } from '@/model/Nhis/GroupUpload/NhisGroupUploadFilterDto';
    import { NhisGroupUploadResultDto } from '@/model/Nhis/GroupUpload/NhisGroupUploadResultDto';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { nhisGroupUploadService } from '@/service/Nhis/NhisGroupUploadService';
    import { signXmlService } from '@/service/Report/SignXmlService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';

    @Component({
        components: { ReportDatePicker }
    })
    export default class NhisGroupUpload extends Vue {
        @Prop()
        examId!: number;

        @Prop()
        examDate!: Date;

        @Prop({ default: false })
        usePatientSignature!: boolean;

        private filter: NhisGroupUploadFilterDto = new NhisGroupUploadFilterDto();
        private summary: NhisGroupSummaryDto = new NhisGroupSummaryDto();
        private uploadResult: string = '';
        private uploadResultDialogVisible: boolean = false;

        private get filesSummary() {
            return `Файлове за изпращане:
            ${
                this.summary.examIdsToOpen.length +
                this.summary.examIdsToClose.length +
                this.summary.examIdsToEdit.length
            } - Прегледи,
            ${this.summary.referral3Ids.length} - Направление 3,
            ${this.summary.referral3aIds.length} - Направление 3а,
            ${this.summary.referral4Ids.length} - Направление 4,
            ${this.summary.referral6Ids.length} - Направление 6,
            ${this.summary.referral7Ids.length} - Направление 7 Хоспитализация,
            ${this.summary.referralTelkIds.length} - Направление ТЕЛК,
            ${this.summary.medicalNoteIds.length} - Медицинска бележка,
            ${this.summary.prescriptionCount} - Рецепти,
            ${this.summary.immunizationCount} - Имунизации
            `;
        }

        private async mounted() {
            this.fillDate();
            if (!this.examId) {
                this.$loading.show();
                try {
                    await this.getUploadSummary();
                } finally {
                    this.$loading.hide();
                }
            }
        }

        public async sendForms() {
            this.$loading.show();
            try {
                this.uploadResultDialogVisible = this.examId ? true : false;
                this.uploadResult = 'Изпращането започна, моля изчакайте';
                if (await localServerDiagnosticService.checkIsLocalServerActive()) {
                    this.fillFilterData();
                    await this.processExamsToOpen();
                    await this.processNonExamBlanks();
                    await this.processExamCloseEdit();
                    await this.getUploadSummary();
                }
            } finally {
                this.$loading.hide();
                this.uploadResult += '\r\nИзпращането завърши';
            }
        }

        //Използваме го само при подписване с таблет, защото подписването трябва да е след като са изпратени бланки
        public async sendOpenExamWithForms() {
            this.$loading.show();
            try {
                this.uploadResultDialogVisible = this.examId ? true : false;
                this.uploadResult = 'Изпращането започна, моля изчакайте';
                if (await localServerDiagnosticService.checkIsLocalServerActive()) {
                    this.fillFilterData();
                    await this.processExamsToOpen();
                    await this.processNonExamBlanks();
                }
            } finally {
                this.uploadResult += '\r\nИзпращането на бланките към прегледа завърши';
                this.$loading.hide();
                this.uploadResultDialogVisible = false;
            }
        }

        //Използваме го само при подписване с таблет, защото преди да затворим трябва да вземем подписа, но преди това трябва да са изпратени всички бланки
        public async sendCloseEditForms() {
            this.$loading.show();
            try {
                this.uploadResultDialogVisible = true;
                this.uploadResult += '\r\n Затваряне/Редакция на прегледа започна';
                if (await localServerDiagnosticService.checkIsLocalServerActive()) {
                    this.fillFilterData();
                    await this.processExamCloseEdit();
                }
            } finally {
                this.$loading.hide();
                this.uploadResult += '\r\nИзпращането завърши';
            }
        }

        private async processExamsToOpen() {
            const response = await nhisGroupUploadService.getExamOpenXmls(this.filter);
            await this.processingBlanks(response);
            const result = await nhisGroupUploadService.postToNhis(response, 'PostExamOpen');
            this.processResult(result);
            if (this.examId) {
                await this.getExamStatus(result);
            }
        }

        private async processNonExamBlanks() {
            const response = await nhisGroupUploadService.getNonExamXmls(this.filter);
            await this.processingBlanks(response);
            const result = await nhisGroupUploadService.postToNhis(response, 'PostNonExamBlank');
            this.processResult(result);
        }

        private async processExamCloseEdit() {
            const response = await nhisGroupUploadService.getExamCloseEditXmls(this.filter);
            await this.processingBlanks(response);
            const result = await nhisGroupUploadService.postToNhis(response, 'PostExamCloseEdit');
            this.processResult(result);
            if (this.examId) {
                await this.getExamStatus(result);
            }
        }

        private async getExamStatus(result: NhisGroupUploadResultDto) {
            let nhisExam = new NhisExamDto();
            if (result?.examCloseResult?.length > 0) {
                [nhisExam] = result.examCloseResult;
            } else if (result?.examEditResult?.length > 0) {
                [nhisExam] = result.examEditResult;
            } else if (result?.examOpenResult?.length > 0) {
                [nhisExam] = result.examOpenResult;
            }
            if (nhisExam.statusCode) {
                this.$emit('input', nhisExam);
                await currentVisitCache.updateNhisExam(nhisExam.nrn, nhisExam.statusCode);
            }
        }

        private processResult(item: NhisGroupUploadResultDto) {
            for (let itemIndex = 0; itemIndex < Object.keys(item)?.length; itemIndex++) {
                const property = Object.keys(item)[itemIndex] as keyof typeof item;
                for (let formIndex = 0; formIndex < item[property]?.length; formIndex++) {
                    const result = item[property][formIndex];
                    if (result.error) {
                        this.uploadResult = this.uploadResult.concat('\n', result.error);
                    }
                }
            }
            console.log(item);
        }

        private async getUploadSummary() {
            this.$loading.show();
            try {
                this.fillFilterData();
                const response = await nhisGroupUploadService.getUploadSummary(this.filter);
                console.log(response);
                this.summary = response;
            } finally {
                this.$loading.hide();
            }
        }

        private fillFilterData() {
            this.filter.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
            this.filter.doctorUin = userContextCache.current?.doctorUin ?? '';
            this.filter.practiceId = userContextCache.currentPracticeId ?? 0;
            this.filter.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
            this.filter.examId = this.examId;
            this.filter.usePatientSignature = this.usePatientSignature;
        }

        private fillDate() {
            this.filter.startDate = this.examDate ?? dateUtil.today();
            this.filter.endDate = this.examDate ?? dateUtil.today();
        }

        private async processingBlanks(item: NhisGroupUploadDto) {
            for (let itemIndex = 0; itemIndex < Object.keys(item)?.length; itemIndex++) {
                const property = Object.keys(item)[itemIndex] as keyof typeof item;
                if (Array.isArray(item[property])) {
                    for (let formIndex = 0; formIndex < item[property]?.length; formIndex++) {
                        const form = item[property][formIndex];
                        if (typeof form !== 'string' && Object.prototype.hasOwnProperty.call(form, 'xmlString')) {
                            /* eslint-disable no-await-in-loop */
                            form.xmlString = await this.singXml(form.xmlString);
                        }
                    }
                }
            }
            console.log(item);
        }

        private async singXml(xml: string) {
            const response = await signXmlService.signXml(xml);
            if (response.isError) {
                this.$notifier.showWarning('', 'Възникна проблем при подписване на файла');
            } else {
                return response.contents ?? '';
            }
            return '';
        }
    }
</script>

<style scoped></style>
