import { Type } from 'class-transformer';

import { formatters } from '@/util/Formatters';

import { PracticeFeaturesDto } from './PracticeFeaturesDto';

export class CustomerLicenseDto {
    public customerId: number = 0;
    public practiceId: number = 0;
    public seqNumber: number = 0;
    public practiceNumberAndName: string = '';
    public isOtherPractice: boolean = false;
    public validFrom: Date = new Date();
    public months: number = 0;
    public validTo: Date = new Date();
    public employeeCount: number = 0;
    public isAdFree: boolean = false;
    public priceWithVat: number = 0;
    public balanceWithVat: number = 0;
    public balanceWithoutVat: number = 0;

    // Изчислени полета server-side.

    // Нула означава, че лицензът е започнал днес в началото на деня.
    public elapsedDays: number = 0;
    // Нула означава, че лицензът изтича днес в края на деня.
    public remainingDays: number = 0;
    // Нула означава, че пробният период изтича днес в края на деня; null - че няма пробен период.
    public remainingTrialDays: number | null = null;
    public startsSoon: boolean = false;
    public expiresSoon: boolean = false;

    // Изчислени полета client-side.

    public get extras() {
        return this.isAdFree ? 'без инф. полета' : null;
    }

    private get isCurrent() {
        return this.elapsedDays >= 0 && this.remainingDays >= 0;
    }

    // Съдържа моментното състояние на практиката (брой служители, поддръжка на таблет и т.н.).
    // Еднакво е за всички лицензи. Допълва се client-side, защото вече е заредено в licensingCache.
    @Type(() => PracticeFeaturesDto)
    public practiceFeatures: PracticeFeaturesDto = new PracticeFeaturesDto();

    // Дали покрива нуждите на практиката (брой служители и т.н.)
    private get isValid() {
        return this.employeeCount >= this.practiceFeatures.employeeCount;
    }

    public get isPaid() {
        return this.balanceWithVat >= 0;
    }

    public get message() {
        let result = '';
        if (this.isCurrent && !this.isValid) {
            result += `Невалиден: в практиката има ${this.practiceFeatures.employeeCount} лекари.`;
        }
        if (this.isPaid) {
            // Known bug: Жълтият цвят и надписът "изтича след" остават дори следващият лиценз да е платен.
            if (this.expiresSoon) {
                result += ` Изтича ${formatters.remainingDays(this.remainingDays)}.`;
            }
            result += ' Платен';
        } else {
            if (this.elapsedDays >= 0) {
                if (this.remainingTrialDays !== null) {
                    const expireWord = this.remainingTrialDays >= 0 ? 'изтича' : 'изтече';
                    result += ` Пробният период ${expireWord} ${formatters.remainingDays(this.remainingTrialDays)}.`;
                } else {
                    result += ` Просрочие!`;
                }
            } else if (this.startsSoon) {
                // Би могло да пише, че скоро започва следваният лиценз. Донякъде дублира смисъла на горния надпис "изтича след".
                result += ` Започва ${formatters.remainingDays(-this.elapsedDays)}.`;
            }
            result += ` Платежно за ${formatters.currency(-this.balanceWithVat)}`;
        }
        return result;
    }

    // Започналите неплатени лицензи са в червено, защо се ползват в просрочие.
    // Първите неплатени лицензи обаче имат 31 дни пробен период, през които са в жълто.
    // Текущите лицензи, които не покриват нуждите на практиката, дори да са платени, са в червено.
    // Скоро изтичащите платени и скоро започващите неплатени са в жълто, за да подсещат лекаря да планира плащане.
    // Known bug: Скоро изтичащите платени остават в жълто дори следващият лиценз да е платен.
    // Изтеклите платени и бъдещите неплатени (които не започват скоро) нямат цвят, защото не са интересни.
    // Лицензите на другите практики са в сиво, но също имат надписи "платен", "просрочие", "изтича след", ...
    public get itemClass() {
        return this.isOtherPractice
            ? 'secondary2'
            : this.isCurrent && !this.isValid
              ? 'error2'
              : this.isPaid
                ? this.expiresSoon
                    ? 'warning2'
                    : this.remainingDays >= 0
                      ? 'success2'
                      : ''
                : this.elapsedDays >= 0
                  ? this.remainingTrialDays !== null && this.remainingTrialDays >= 0
                      ? 'warning2'
                      : 'error2'
                  : this.startsSoon
                    ? 'warning2'
                    : '';
    }
}
