import Vue from 'vue';

import { NotificationCreateModel } from '@/model/Notification/NotificationCreateModel';
import { NotificationModel } from '@/model/Notification/NotificationModel';
import { NotificationCategory, NotificationSeverity } from '@/model/Notification/NotificationSeverity';
import { INotifier } from '@/plugin/Notifier/INotifier';
import { notifications } from '@/store/Notifications';

const toastConfig = {
    durationInMs: 10000,
    speed: 300
};

// Low-level методи, които не са описани в INotifier и се използват рядко.
const showToastMessage = (createModel: NotificationCreateModel) => {
    // Съхранение.
    notifications.add(new NotificationModel(createModel));
    // Показване.
    // Показва се в елемента <notifications group="notifications-group" ... />, който се намира в App.vue.

    Vue.notify({
        title: createModel.title,
        text: createModel.text,
        type: createModel.severity,
        group: 'notifications-group',
        duration: toastConfig.durationInMs,
        speed: toastConfig.speed
    });
};

const showModalDialog = (createModel: NotificationCreateModel) => {
    const notification = new NotificationModel(createModel);
    // Съхранение.
    notifications.add(notification);
    // Показване.
    notifications.displayedModalNotification = notification;
};

const notifierService: INotifier = {
    // Методи на INotifier, често използвани.
    showSuccess(title: string, text: string): void {
        showToastMessage(
            new NotificationCreateModel(title, text, NotificationSeverity.Success, NotificationCategory.User)
        );
    },

    showWarning(title: string, text: string): void {
        showToastMessage(
            new NotificationCreateModel(title, text, NotificationSeverity.Warn, NotificationCategory.Validation)
        );
    },

    showError(title: string, text: string): void {
        showModalDialog(
            new NotificationCreateModel(title, text, NotificationSeverity.Error, NotificationCategory.System)
        );
    }
};

export { notifierService, showModalDialog, showToastMessage };
