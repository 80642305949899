export class SickSheetCounterDto {
    public id: number = 0;
    public practiceId: number = 0;
    public prefix: string = 'E';
    public year: number = new Date().getFullYear();
    public startNumber: string = '';
    public endNumber: string = '';
    public count: number = 0;
    public nextNumber: number = 0;
    public usedCount: number = 0;
    public isDeleted: boolean = false;
    public alternativePracticeId: number | null = null;
    public alternativePracticeNumber: string | null = null;

    private get isDeletedString() {
        return this.isDeleted ? 'Да' : 'Не';
    }
}
