<template>
    <ExamLayout
        :can-delete="Boolean(documentId)"
        :can-print="Boolean(documentId)"
        title="Етапна епикриза"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <v-card-text class="pt-0">
                <v-select
                    v-model="examSearchModel.examFilterTypeCode"
                    :items="examFilterTypes"
                    item-text="name"
                    item-value="code"
                    label="Метод на попълване"
                    @change="onExamFilterTypeChanged"
                />
            </v-card-text>

            <template v-if="examSearchModel.examFilterTypeCode === 'Period'">
                <v-card-title class="py-0">Търсене на прегледи за период</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="4">
                            <date-picker v-model="examSearchModel.fromDate" label="От дата:" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <date-picker v-model="examSearchModel.toDate" label="До дата:" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <btn action="Refresh" @click="buildStagedEpicrisisBody">Зареждане епикриза</btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </template>
            <template v-else-if="examSearchModel.examFilterTypeCode === 'Selected'">
                <template v-if="examSearchModel.patientExams.length > 0">
                    <v-card-title class="py-0">Списък с издадени амбулаторни листове</v-card-title>
                    <v-card-text>
                        <v-data-table
                            v-model="examSearchModel.selectedExams"
                            :headers="headers"
                            show-select
                            :single-select="false"
                            :items="examSearchModel.patientExams"
                            item-key="ambulatorySheetNumber"
                            class="elevation-1"
                        >
                            <template #[`item.startDateTime`]="{ item }">
                                <date-label v-model="item.startDateTime" show-time />
                            </template>
                            <v-progress-linear color="primary" indeterminate />
                        </v-data-table>
                        <btn action="Refresh" @click="buildStagedEpicrisisBody">
                            Зареждане епикриза за избрани амбулаторни листове
                        </btn>
                    </v-card-text>
                </template>
                <alert :value="!examSearchModel.patientExams.length > 0" type="warning">
                    Няма издадени амбулаторни листове!
                </alert>
            </template>
            <v-card-text class="py-0">
                <v-row>
                    <v-col md="4"><main-title>Етапна епикриза</main-title></v-col>
                    <!-- Датата е редактируема само ако това е етапна епикриза без преглед и без БЛ. -->
                    <v-col v-if="isPrimaryDocument" md="4">
                        <date-picker
                            v-model="body.visitStartDateTime"
                            show-time
                            label="Дата на издаване и час на посещението"
                        />
                    </v-col>
                    <v-col v-else md="4">
                        <date-field v-model="body.visitStartDateTime" label="Издадена на" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <h3>Данни за пациент</h3>
                <br />
                <v-row>
                    <v-col cols="12" md="6">
                        <text-field v-model="patientFullName" readonly label="Име на пациента" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field v-model="currentPatient.personIdentification.identifier" readonly label="ЕГН/ЛНЧ" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3">
                        <date-field v-model="currentPatient.personIdentification.birthDate" label="Дата на раждане" />
                    </v-col>
                    <v-col cols="6" md="3"><text-field v-model="patientGenderName" readonly label="Пол" /></v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model="currentPatient.address.cityName" readonly label="град/село" />
                    </v-col>
                    <v-col cols="12" md="3"><text-field v-model="patientAddress" readonly label="Адрес" /></v-col>
                </v-row>
                <h3>Данни за лекар</h3>
                <br />

                <v-row>
                    <v-col cols="12" md="4"><text-field v-model="doctorName" readonly label="Име на лекар" /></v-col>
                    <v-col cols="12" md="2"><text-field v-model="doctorUin" readonly label="УИН" /></v-col>
                    <v-col cols="12" md="2"><text-field v-model="doctorSpecialtyName" readonly label="спец" /></v-col>
                    <v-col cols="12" md="2"><text-field v-model="practiceNumber" readonly label="РЦЗ" /></v-col>
                    <v-col cols="12" md="2"><text-field v-model="doctorPhone" readonly label="тел" /></v-col>
                </v-row>
                <h3>Водеща диагноза</h3>
                <v-textarea v-model="body.diagnosis" rows="2" />
                <h3>Анамнеза</h3>
                <v-textarea v-model="body.symptoms" rows="2" />
                <h3>Обективно състояние</h3>
                <v-textarea v-model="body.condition" rows="2" />
                <h3>Извършени изследвания</h3>
                <v-textarea v-model="body.tests" rows="2" />
                <h3>Назначена терапия</h3>
                <v-textarea v-model="body.treatment" rows="2" />
                <h3>Назначена консултация</h3>
                <v-textarea v-model="body.consultation" rows="2" />
                <h3>Заключение</h3>
                <v-textarea v-model="body.conclusion" rows="2" />

                <v-row>
                    <v-col offset-md="9" cols="6" md="3"><text-field disabled label="Подпис" /></v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import DateLabel from '@/component/Date/DateLabel.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { StagedEpicrisisBodyDto } from '@/model/Exam/StagedEpicrisis/StagedEpicrisisBodyDto';
    import {
        examFilterTypes,
        StagedEpicrisisExamFilterTypeCode
    } from '@/model/Exam/StagedEpicrisis/StagedEpicrisisExamFilterType';
    import { StagedEpicrisisExamSearchModel } from '@/model/Exam/StagedEpicrisis/StagedEpicrisisExamSearchModel';
    import { stagedEpicrisisService } from '@/service/Exam/StagedEpicrisisService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { genderCache } from '@/store/Nomenclature/GenderCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: { UnsavedChanges, ExamLayout, DateLabel },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.body, next);
        }
    })
    export default class StagedEpicrisis extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private visitId!: number;

        @Prop()
        private documentId!: number;

        private isPrimaryDocument: boolean = false;
        private body: StagedEpicrisisBodyDto = new StagedEpicrisisBodyDto();
        private initialValue: StagedEpicrisisBodyDto = new StagedEpicrisisBodyDto();
        private examSearchModel: StagedEpicrisisExamSearchModel = new StagedEpicrisisExamSearchModel();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private headers: IDataTableHeader[] = [
            { text: 'АЛ Номер', value: 'ambulatorySheetNumber', align: 'start', sortable: false },
            { text: 'Дата на издаване', value: 'startDateTime' },
            { text: 'Водеща диагноза', value: 'icdCode' }
        ];

        private selected: Record<string, unknown>[] = [];

        private get examFilterTypes() {
            const visitHasExam = Boolean(currentVisitCache.value.exam);
            return examFilterTypes.filter((type) => (visitHasExam ? type.supportsExam : type.supportsNoExam));
        }

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get patientFullName() {
            return `${currentPatientCache.value.firstName} ${currentPatientCache.value.middleName} ${currentPatientCache.value.lastName}`;
        }

        private get patientGenderName() {
            return genderCache.items.find(
                (gender) => gender.code === currentPatientCache.value.personIdentification.genderCode
            )?.name;
        }

        private get patientAddress() {
            return `${this.currentPatient.address.cityName} ${
                this.currentPatient.address.street !== null ? this.currentPatient.address.street : ' '
            } ${this.currentPatient.address.streetNumber !== null ? this.currentPatient.address.streetNumber : ' '} ${
                this.currentPatient.address.neighborhood !== null ? this.currentPatient.address.neighborhood : ' '
            }`;
        }

        private get doctorName() {
            return currentVisitCache.isLoaded
                ? currentVisitCache.value.doctorNames
                : userContextCache.current?.doctorName;
        }

        private get doctorUin() {
            return currentVisitCache.isLoaded ? currentVisitCache.value.doctorUin : '';
        }

        // TODO
        private get doctorPhone() {
            return currentVisitCache.isLoaded ? currentVisitCache.value.doctorPhone : '';
        }

        private get doctorSpecialtyName() {
            return currentVisitCache.isLoaded
                ? currentVisitCache.value.doctorSpecialtyName
                : userContextCache.current?.specialtyName;
        }

        private get practiceNumber() {
            return userContextCache.current?.practiceNumber;
        }

        private async mounted() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                if (this.documentId) {
                    const editDto = await stagedEpicrisisService.getStagedEpicrisisById(this.documentId);
                    await currentVisitCache.load(editDto.visitId);
                    this.body = editDto.body;
                    this.isPrimaryDocument = editDto.isPrimaryDocument;
                } else if (this.visitId) {
                    // Започва добавяне на етапна епикриза към посещение или преглед.
                    const visit = currentVisitCache.value;
                    this.body.visitStartDateTime = visit.startDateTime ?? new Date();
                    // Проверка дали има други видове документи към това посещение.
                    this.isPrimaryDocument = await stagedEpicrisisService.stagedEpicrisisIsPrimaryDocument(
                        this.visitId
                    );
                } else {
                    // Започва попълване на нова етапна епикриза като основен документ в ново посещение.
                    this.body.visitStartDateTime = new Date();
                    this.isPrimaryDocument = true;
                }
                this.copyObject();
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.body);
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                if (!this.documentId) {
                    const newId = this.visitId
                        ? await stagedEpicrisisService.createStagedEpicrisis(this.visitId, this.body)
                        : await stagedEpicrisisService.createStagedEpicrisisVisit(this.body);

                    // Ако е разрешено датата на посещението да се обнови в базата, се обновява и в кеша.
                    if (currentVisitCache.isLoaded && this.isPrimaryDocument) {
                        currentVisitCache.value.startDateTime = this.body.visitStartDateTime;
                    }
                    eventBus.$emit('create-referral-event', newId, 'StagedEpicrisis', 'Етапна епикриза');
                    // Потиска предупреждението за незаписани промени преди навигирането.
                    this.copyObject();
                    // Дори да е било създадено ново посещение, навигирането към .../Edit/newId изпълнява
                    // currentVisitCache.load(editDto.visitId) в mounted() и зарежда правилното посещение и меню.
                    this.$router.push(`/Exam/StagedEpicrisis/Edit/${newId}`);
                } else {
                    await stagedEpicrisisService.updateStagedEpicrisis(this.documentId, this.body);

                    // Ако е разрешено датата на посещението да се обнови в базата, се обновява и в кеша.
                    if (currentVisitCache.isLoaded && this.isPrimaryDocument) {
                        currentVisitCache.value.startDateTime = this.body.visitStartDateTime;
                    }
                    eventBus.$emit('update-referral-event', 'Етапна епикриза');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const visitIsDeleted = await stagedEpicrisisService.deleteStagedEpicrisis(this.documentId);
                this.copyObject();
                this.$router.push(
                    visitIsDeleted
                        ? `/Patient/VisitSummary/${currentPatientCache.value.key.patientId}`
                        : `/Exam/StagedEpicrisis/Create/${currentVisitCache.value.id}`
                );
                eventBus.$emit('delete-referral-event', this.documentId, 'StagedEpicrisis', 'Етапна епикриза');
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.StagedEpicrisis/${this.documentId}`);
            } else {
                // TODO: notification
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.StagedEpicrisis';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private onExamFilterTypeChanged(examFilterTypeCode: StagedEpicrisisExamFilterTypeCode) {
            if (examFilterTypeCode === StagedEpicrisisExamFilterTypeCode.Selected) {
                this.loadPatientExamsOnce();
            } else if (examFilterTypeCode !== StagedEpicrisisExamFilterTypeCode.Period) {
                this.buildStagedEpicrisisBody();
            }
        }

        private async loadPatientExamsOnce() {
            if (this.examSearchModel.patientExams.length === 0) {
                this.examSearchModel.patientExams = await stagedEpicrisisService.getExamsForCurrentPatient();
            }
        }

        private async buildStagedEpicrisisBody() {
            const newBody = await stagedEpicrisisService.buildStagedEpicrisisBody(this.examSearchModel);
            newBody.visitStartDateTime = this.body.visitStartDateTime;
            this.body = newBody;
        }
    }
</script>
