<template>
    <v-row>
        <v-col cols="12">
            <v-row class="align-baseline">
                <v-col md="3" cols="6">
                    <dropdown
                        v-model="consultation.specialtyCode"
                        :items="specialties"
                        :disabled="consultation.isNecessary"
                        item-value="code"
                        label="Специалист"
                        required
                    />
                </v-col>
                <v-col v-if="isSent" md="3" cols="6">
                    <date-picker v-model="consultation.examDate" label="Дата на преглед" required />
                </v-col>
                <v-col v-if="isSent" md="3" cols="6">
                    <v-checkbox
                        v-model="consultation.examinationByGp"
                        label="Прегледан от ОПЛ"
                        hide-details
                        @change="clearData"
                    />
                </v-col>
                <v-col v-if="isSent && !consultation.examinationByGp" md="3" cols="6">
                    <text-field
                        v-model="consultation.consultationNrn"
                        :required="consultation.examinationByGp ? false : true"
                        :rules="[$validator.nrn]"
                        label="НРН на консултацията"
                /></v-col>
            </v-row>
            <v-row>
                <v-col v-if="isSent && consultation.examinationByGp" md="3" cols="6">
                    <icd-picker v-model="consultation.icdCode" label="Основно МКБ" />
                </v-col>
                <v-col v-if="isSent && consultation.examinationByGp" md="3" cols="6">
                    <icd-picker v-model="consultation.icdCodeAdd" label="Допълнително МКБ" />
                </v-col>
                <v-col v-if="isSent && consultation.examinationByGp" md="3" cols="6">
                    <v-textarea
                        v-model="consultation.condition"
                        :class="consultation.examinationByGp ? 'required' : ''"
                        :rules="consultation.examinationByGp ? [$validator.required] : []"
                        label="Обективно състояние"
                        rows="3"
                    />
                </v-col>
                <v-col md="3" cols="6">
                    <v-textarea v-model="consultation.notes" :readonly="isSent" label="Бележки" rows="3" />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import TextField from '@/component/Common/TextField.vue';
    import DatePicker from '@/component/Date/DatePicker.vue';
    import Dropdown from '@/component/Dropdown/Dropdown.vue';
    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import { CertificateDriveConsultationDto } from '@/model/Exam/Certificate/CertificateDriveConsultationDto';
    import { specialtyCache } from '@/store/Nomenclature/SpecialtyCache';

    @Component({
        components: { IcdPicker, TextField, DatePicker, Dropdown }
    })
    export default class CertificateDriveConsultation extends Vue {
        @Prop()
        consultation!: CertificateDriveConsultationDto;

        @Prop({ default: false })
        isSent!: boolean;

        private get specialties() {
            return specialtyCache.items;
        }

        clearData() {
            if (this.consultation.examinationByGp) {
                this.consultation.consultationNrn = null;
            } else {
                this.consultation.condition = null;
                this.consultation.icdCode = null;
                this.consultation.icdCodeAdd = null;
            }
        }
    }
</script>

<style scoped></style>
