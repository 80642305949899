import { Type } from 'class-transformer';

import { BatchChildDto } from './BatchChildDto';

export class BatchTariffDto {
    public tariffSeqNumber: number | null = null;
    public patientPrice: number | null = null;

    @Type(() => BatchChildDto)
    public selectedOffers: BatchChildDto[] = [];

    @Type(() => BatchChildDto)
    public offers: BatchChildDto[] = [];

    // Допълнителни client-side полета.
    public tariffName: string = '';
    public isSelected: boolean = false;
}
