import { plainToInstance } from 'class-transformer';

import { PatientVisitSummaryCommand } from '@/model/VisitList/PatientVisitSummary/PatientVisitSummaryCommand';
import { PatientVisitSummaryDto } from '@/model/VisitList/PatientVisitSummary/PatientVisitSummaryDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientVisitSummaryService = {
    async getPatientVisitSummary(
        patientId: number,
        employeeSeqNumber: number,
        allVisits: boolean
    ): Promise<PatientVisitSummaryDto> {
        const command = new PatientVisitSummaryCommand(patientId, employeeSeqNumber, allVisits);
        const response = await httpService.get<PatientVisitSummaryDto>('PatientVisitSummary/GetPatientVisitSummary', {
            params: command
        });
        return plainToInstance(PatientVisitSummaryDto, response.data);
    },

    async exportPatientVisitSummaryToExcel(patientId: number, employeeSeqNumber: number): Promise<void> {
        const command = new PatientVisitSummaryCommand(patientId, employeeSeqNumber, true);
        await httpService.download('PatientVisitSummary/ExportPatientVisitSummaryToExcel', {
            params: command
        });
    }
};
