export class LkkCommitteeViewDto {
    public practiceId: number = 0;
    public seqNumber: number = 0;
    public name: string = '';
    public number: string = '';
    public isDeleted: boolean = false;
    public isSpecialized: boolean = false;

    public get isDeletedString() {
        return this.isDeleted ? 'Да' : 'Не';
    }

    public get isSpecializedString() {
        return this.isSpecialized ? 'Да' : 'Не';
    }
}
