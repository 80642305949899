<template>
    <exam-layout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Карта за профилактика на бременността"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <v-card-title>Карта за профилактика на бременността</v-card-title>
            <v-tabs>
                <v-tab>Анамнеза</v-tab>
                <v-tab>Прегледи и изследвания</v-tab>
                <v-tab-item>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <PatientInfo />
                            </v-col>
                            <v-col cols="12" md="6">
                                <DoctorInfo />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">
                                <date-picker v-model="document.issueDate" label="Дата на издаване:" />
                            </v-col>
                            <v-col cols="6" md="3">
                                <text-field v-model="document.number" type="number" label="Пореден номер:" />
                            </v-col>
                        </v-row>
                        <anamnestic-data v-model="document.anamnesticData" />
                        <exam-data v-model="document.examData" />
                    </v-card-text>
                </v-tab-item>

                <v-tab-item>
                    <v-card-text>
                        <lab-tests-data v-model="document.labTestsData" />
                        <ultrasound-data v-model="document.ultrasoundData" />
                        <pregnancy-at-risk-tests-data v-model="document.pregnancyAtRiskTestsData" />
                        <consultation-data v-model="document.consultationData"></consultation-data>
                        <pregnancy-result v-model="document.pregnancyResult" />
                    </v-card-text>
                    <v-card-title>Данни за лекаря, водил раждането</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6" md="3">
                                <text-field v-model="document.birthDoctor" type="number" label="Лекар: (име,фамилия)" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </exam-layout>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { PregnancyCardDto } from '@/model/Register/Pregnancy/PregnancyCard/PregnancyCardDto';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { pregnancyCardService } from '@/service/Register/PregnancyCardService';
    import AnamnesticData from '@/view/Register/Pregnancy/PregnancyCard/AnamnesticData.vue';
    import ConsultationData from '@/view/Register/Pregnancy/PregnancyCard/ConsultationData.vue';
    import ExamData from '@/view/Register/Pregnancy/PregnancyCard/ExamData.vue';
    import LabTestsData from '@/view/Register/Pregnancy/PregnancyCard/LabTestsData.vue';
    import PregnancyAtRiskTestsData from '@/view/Register/Pregnancy/PregnancyCard/PregnancyAtRiskTestsData.vue';
    import PregnancyResult from '@/view/Register/Pregnancy/PregnancyCard/PregnancyResult.vue';
    import UltrasoundData from '@/view/Register/Pregnancy/PregnancyCard/UltrasoundData.vue';

    @Component({
        components: {
            PregnancyAtRiskTestsData,
            LabTestsData,
            ConsultationData,
            PregnancyResult,
            UltrasoundData,
            ExamLayout,
            ExamData,
            AnamnesticData,
            DoctorInfo,
            PatientInfo
        }
    })
    export default class PregnancyCard extends Vue {
        @Prop()
        private pregnancyId!: number;

        @Prop()
        private documentId!: number;

        private document: PregnancyCardDto = new PregnancyCardDto();

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (!this.documentId) {
                    this.document.pregnancyId = this.pregnancyId;
                    const response = await pregnancyCardService.createPregnancyCard(this.document);
                    documentId = Number(response.data);
                    eventBus.$emit(
                        'create-referral-event',
                        documentId,
                        'PregnancyCard',
                        'Карта за профилактика на бременността'
                    );
                    this.$router.push(`/Register/PregnancyRegister/PregnancyCard/Edit/${documentId}`);
                } else {
                    await pregnancyCardService.updatePregnancyCard(this.document);
                    eventBus.$emit('update-referral-event', 'Карта за профилактика на бременността');
                }
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Register.PregnancyCard.PregnancyCard/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Register.PregnancyCard.PregnancyCard';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                await pregnancyCardService.deletePregnancyCard(this.documentId);
                this.$router.push(`/Register/PregnancyRegister/PregnancyCard/Create/${this.document.pregnancyId}`);
                eventBus.$emit(
                    'delete-referral-event',
                    this.documentId,
                    'PregnancyCard',
                    'Карта за профилактика на бременността'
                );
            } finally {
                this.$loading.hide();
            }
        }

        private async getPregnancyCard() {
            this.$loading.show();
            try {
                this.document = await pregnancyCardService.getPregnancyCard(this.documentId);
            } finally {
                this.$loading.hide();
            }
        }

        private async getInfoByPregnancyId() {
            this.$loading.show();
            try {
                this.document = await pregnancyCardService.getInfoByPregnancyId(this.pregnancyId);
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            if (this.documentId) {
                await this.getPregnancyCard();
            } else {
                await this.getInfoByPregnancyId();
            }
        }
    }
</script>
