// Следният css трябва да се import-не след App.vue, затова в main.ts, Vuetify.ts се импортира след App.vue.
// В противен случай UI-ът се размества. Причината е, че класовете v-input и col-... се дефинират в грешен ред,
// class="col-..." спира да работи за <v-text-field>-овете и те започват да заемат цялата ширина.
// Конкретният проблем е, че v-input указва flex: 1 1 auto, а (примерно) col-md-3 указва flex: 0 0 25%.
// Второто трябва да се дефинира по-късно от първото и именно това прави vuetify.min.css.
// Странното е, че дори този import да се изтрие, класовете пак се появяват от някъде: v-input е дефиниран в друг стил,
// а col-... са дефинирани в трети стил. При различни версии на npm пакетите, вторият и третият стил понякога се зареждат
// в правилния ред и проблемът не се забелязва, но когато се заредят в грешния ред проблемът се открива много трудно.
// Този .css файл дефинира всички класове повторно, но в правилния ред. Жалко е, че се зареждат и другите излишни стилове.
// Ако се използваха само <v-row> и <v-col> и никога class="col-...", което е и по-правилно, изобщо нямаше да има такъв казус.
import 'vuetify/dist/vuetify.min.css';

import Vue from 'vue';
import { UserVuetifyPreset } from 'vuetify';
//
// Вариант 1: Зареждат се всички компоненти. Така при build се получава с 800 KB по-голям bundle.
//import Vuetify from 'vuetify';
//
// Вариант 2: Зареждат се само използваните компоненти. Vuetify-loader автоматично засича кои са използвани.
// Така обаче TypeScript типовете не се зареждат, поради което в tsconfig.json е добавено types: [ "vuetify" ].
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
//
// * Vuetify-loader се активира автоматично от vue-cli-plugin-vuetify, а може и ръчно чрез vue.config.js ето така:
//const VuetifyLoaderPlugin = require('vuetify-loader/lib/plugin');
//module.exports = {
//    configureWebpack: {
//        plugins: [ new VuetifyLoaderPlugin() ];
//    },
//},
Vue.use(Vuetify);

// Вариант 3: В миналото се е налагало всички използвани Vuetify компоненти да се "регистрират" една по една.
// Това се нарича a-la-carte installation и не се използва от години - откакто има vuetify-loader.
// https://stackoverflow.com/questions/46680467/what-is-a-la-carte-components-should-i-use-it
// Тъпото е, че шаблонът за проект с vuetify (vue add vuetify) генерира такъв примерен код и заблуждава, че това още е нужно.
//import Vuetify, {
//    VAlert,
//    VApp,
//    ...
//    VToolbarTitle,
//    VTreeview,
//} from 'vuetify/lib';
//Vue.use(Vuetify, {
//    components: {
//        VAlert,
//        VApp,
//        ...
//        VToolbarTitle,
//        VTreeview,
//    },
//});

const options: UserVuetifyPreset = {
    theme: {
        options: {
            // Позволява използването на цветовете от темите в <style> секциите на компонентните.
            // Пример: .some-class { background-color: var(--v-accent-lighten2 или --v-error-base); }
            customProperties: true
        },

        themes: {
            light: {
                // Стандартна светла тема на Vuetify с цветове от material design палитрата (https://vuetifyjs.com/en/styles/colors/#material-colors):
                //primary: colors.blue.darken2,
                //secondary: colors.grey.darken3,
                //accent: colors.blue.accent1,
                //error: colors.red.accent2,
                //warning: colors.orange.darken1,
                //info: colors.blue,
                //success: colors.green,

                // Overrides на стандартните светли цветове:
                primary: colors.indigo,
                secondary: colors.blueGrey.darken1,

                // Допълнителни(custom) имена на цветове, базирани на material design палитрата.

                // Изсветлени стандартни цветове, когато не искаме да са крещящи на светла тема.
                primary2: colors.indigo.lighten3,
                secondary2: colors.blueGrey.lighten3,
                accent2: colors.cyan.lighten4,
                error2: colors.red.lighten3,
                warning2: colors.orange.lighten3,
                info2: colors.blue.lighten3,
                success2: colors.lightGreen.lighten2,

                primary3: colors.indigo.lighten4,
                secondary3: colors.blueGrey.lighten5,
                accent3: colors.cyan.lighten5,
                error3: colors.red.lighten4,
                warning3: colors.amber.lighten4,
                info3: colors.blue.lighten4,
                success3: colors.lightGreen.lighten4,

                // Цветове на специфични компоненти.
                // Фон на заглавната лента в светла тема.
                titleBar: colors.teal.lighten1,
                // Фон на главното меню в светла тема: още по-светъл вариант на colors.amber.lighten5.
                mainMenu: '#FFFAF0',
                // Текст на линковете в таблица с цветни редове.
                contrast: colors.shades.black,
                // Фонът на бутоните контрастира при hover върху ред от v-data-table.
                contrast2: colors.shades.white
            },

            dark: {
                // Стандартна тъмна тема на Vuetify с цветове от material design палитрата (https://vuetifyjs.com/en/styles/colors/#material-colors):
                //primary: colors.blue,
                //secondary: colors.grey.darken3,
                //accent: colors.pink.accent2,
                //error: colors.red.accent2,
                //warning: colors.orange.darken1,
                //info: colors.blue,
                //success: colors.green,

                // Overrides на стандартните тъмни цветове:
                primary: colors.indigo.lighten1,
                secondary: colors.blueGrey.lighten1,
                accent: colors.brown,
                success: colors.teal,

                // Допълнителни(custom) имена на цветове.

                // Потъмнени стандартни цветове, когато не искаме да са крещящи на тъмна тема.
                primary2: colors.deepPurple.darken3,
                secondary2: colors.blueGrey.darken2,
                accent2: colors.brown.darken3,
                error2: colors.red.accent4,
                //colors.yellow.darken4 darken-2
                warning2: '#B54B00',
                info2: colors.blue.darken3,
                success2: colors.teal.darken3,

                //colors.purple.darken4 darken-1
                primary3: '#2D0072',
                secondary3: colors.blueGrey.darken4,
                //colors.brown.darken4 darken-1 (като фона на главното меню).
                accent3: '#28130D',
                //colors.red.darken4 darken-4.
                error3: '#480000',
                //colors.lime.darken4 darken-3.
                warning3: '#383000',
                //colors.blue.darken4 darken-3.
                info3: '#000454',
                //colors.teal.darken4 darken-1 (като фона на заглавната лента).
                success3: '#00362A',

                // Цветове на специфични компоненти.
                // Фон на заглавната лента в тъмна тема: colors.teal.darken4 darken-1.
                titleBar: '#00362A',
                // Фон на главното меню в тъмна тема: colors.brown.darken4 darken-1.
                mainMenu: '#28130D',
                // Текст на линковете в таблица с цветни редове.
                contrast: colors.shades.white,
                // Фонът на бутоните контрастира при hover върху ред от v-data-table.
                contrast2: colors.shades.black
            }
        }
    }
};

export const vuetify = new Vuetify(options);
