import { Type } from 'class-transformer';

import { StagedEpicrisisBodyDto } from './StagedEpicrisisBodyDto';

export class StagedEpicrisisEditDto {
    public visitId: number = 0;

    @Type(() => StagedEpicrisisBodyDto)
    public body: StagedEpicrisisBodyDto = new StagedEpicrisisBodyDto();

    public isPrimaryDocument: boolean = false;
}
