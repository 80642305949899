import { AxiosResponse } from 'axios';

import { RequestForHistopathologicalTestDto } from '@/model/Exam/RequestForHistopathologicalTest/RequestForHistopathologicalTestDto';
import { RequestForHistopathologicalTestViewDto } from '@/model/Exam/RequestForHistopathologicalTest/RequestForHistopathologicalTestViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const requestForHistopathologicalTestService = {
    getRequestForHistopathologicalTest(patientId: number): Promise<AxiosResponse<RequestForHistopathologicalTestDto>> {
        return httpService.get(`/RequestForHistopathologicalTest/GetRequestForHistopathologicalTest/${patientId}`);
    },

    createRequestForHistopathologicalTest(
        data: RequestForHistopathologicalTestDto
    ): Promise<AxiosResponse<RequestForHistopathologicalTestDto>> {
        return httpService.post('/RequestForHistopathologicalTest/CreateRequestForHistopathologicalTest', data);
    },

    updateRequestForHistopathologicalTest(
        data: RequestForHistopathologicalTestDto
    ): Promise<AxiosResponse<RequestForHistopathologicalTestDto>> {
        return httpService.put('/RequestForHistopathologicalTest/UpdateRequestForHistopathologicalTest/', data);
    },

    deleteRequestForHistopathologicalTest(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/RequestForHistopathologicalTest/DeleteRequestForHistopathologicalTest/${id}`);
    },

    getRequestForHistopathologicalTestInfoByExamId(
        examId: number
    ): Promise<AxiosResponse<RequestForHistopathologicalTestDto>> {
        return httpService.get(
            `/RequestForHistopathologicalTest/GetRequestForHistopathologicalTestInfoByExamId/${examId}`
        );
    },

    getRequestForHistopathologicalTestsByExamId(
        examId: number
    ): Promise<AxiosResponse<RequestForHistopathologicalTestViewDto[]>> {
        return httpService.get(`/RequestForHistopathologicalTest/GetRequestForHistopathologicalTestViewsByExam`, {
            params: { examId }
        });
    }
};
