enum NhisPeriodUnitCode {
    // Стойностите са НЗИС кодовете.
    Second = 's',
    Minute = 'min',
    Hour = 'h',
    Day = 'd',
    Week = 'wk',
    Month = 'mo',
    Year = 'a'
}

const nhisPeriodUnits = [
    // { code: NhisPeriodUnitCode.Second, name: 'секунда', pluralName: 'секунди' },
    // { code: NhisPeriodUnitCode.Minute, name: 'минута', pluralName: 'минути' },
    // { code: NhisPeriodUnitCode.Hour, name: 'час', pluralName: 'часа' },
    { code: NhisPeriodUnitCode.Day, name: 'ден', pluralName: 'дни' },
    { code: NhisPeriodUnitCode.Week, name: 'седмица', pluralName: 'седмици' },
    { code: NhisPeriodUnitCode.Month, name: 'месец', pluralName: 'месеца' }
    // { code: NhisPeriodUnitCode.Year, name: 'година', pluralName: 'години' }
];

export { NhisPeriodUnitCode, nhisPeriodUnits };
