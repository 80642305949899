import { AxiosResponse } from 'axios';

import { FindRiskCheckDto } from '@/model/Exam/FindRiskCheckDto';
import { MedicalDiagnosticTestDto } from '@/model/Exam/Gp/RiskAssessmentCard/MedicalDiagnosticTestDto';
import { RiskAssessmentCardDto } from '@/model/Exam/Gp/RiskAssessmentCard/RiskAssessmentCardDto';
import { RiskAssessmentCardViewDto } from '@/model/Exam/Gp/RiskAssessmentCard/RiskAssessmentCardViewDto';
import { RiskGroupCheckDto } from '@/model/Exam/Gp/RiskAssessmentCard/RiskGroupCheckDto';
import { RiskGroupRuleCardDto } from '@/model/Exam/Gp/RiskAssessmentCard/RiskGroupRuleCardDto';
import { ScoreCheckDto } from '@/model/Exam/ScoreCheckDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const riskAssessmentCardService = {
    getRiskAssessmentCardInfoByExamId(examId: number): Promise<AxiosResponse<RiskAssessmentCardDto>> {
        return httpService.get(`/RiskAssessmentCard/GetRiskAssessmentCardInfoByExamId/${examId}`);
    },

    getLastRiskAssessmentCardInfo(patientId: number): Promise<AxiosResponse<RiskAssessmentCardDto>> {
        return httpService.get('/RiskAssessmentCard/GetLastRiskAssessmentCardInfo', {
            params: { patientId }
        });
    },

    getRiskAssessmentCard(id: number): Promise<AxiosResponse<RiskAssessmentCardDto>> {
        return httpService.get(`/RiskAssessmentCard/GetRiskAssessmentCard/${id}`);
    },

    createRiskAssessmentCard(data: RiskAssessmentCardDto): Promise<AxiosResponse<RiskAssessmentCardDto>> {
        return httpService.post('/RiskAssessmentCard/CreateRiskAssessmentCard', data);
    },

    updateRiskAssessmentCard(data: RiskAssessmentCardDto): Promise<AxiosResponse<RiskAssessmentCardDto>> {
        return httpService.put('/RiskAssessmentCard/UpdateRiskAssessmentCard/', data);
    },

    deleteRiskAssessmentCard(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/RiskAssessmentCard/DeleteRiskAssessmentCard/${id}`);
    },

    canSaveRiskAssessmentCard(data: RiskAssessmentCardDto): Promise<AxiosResponse<string[]>> {
        return httpService.post('/RiskAssessmentCard/CanSaveRiskAssessmentCard', data);
    },

    calculateScore(data: ScoreCheckDto): Promise<AxiosResponse<number>> {
        return httpService.post('/RiskAssessmentCard/CalculateScore', data);
    },

    calculateFindRisk(data: FindRiskCheckDto): Promise<AxiosResponse<number>> {
        return httpService.post('/RiskAssessmentCard/CalculateFindRisk', data);
    },

    getCardsByExamId(examId: number): Promise<AxiosResponse<RiskAssessmentCardViewDto[]>> {
        return httpService.get(`/RiskAssessmentCard/GetRiskAssessmentCardViewsByExam`, {
            params: { examId }
        });
    },

    checkForRiskGroups(data: RiskGroupCheckDto): Promise<AxiosResponse<RiskGroupRuleCardDto[]>> {
        return httpService.post(`/RiskAssessmentCard/CheckForRiskGroups`, data);
    },

    getMedicalDiagnosticTestResult(id: number): Promise<AxiosResponse<MedicalDiagnosticTestDto>> {
        return httpService.get(`/RiskAssessmentCard/GetMedicalDiagnosticTestResult/${id}`);
    }
};
