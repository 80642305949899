import { NhisReferralDto } from '@/model/Nhis/Referral/NhisReferralDto';

export class Referral6Dto {
    public id: number = 0;
    public examId: number = 0;
    public number: number = 0;
    public issueDate: Date | null = null;
    public referralSubtypeId: number = 0;
    public specialty1Id: number = 0;
    public specialty2Id: number | null = null;
    public specialty3Id: number | null = null;
    public specialty4Id: number | null = null;
    public specialty5Id: number | null = null;
    public diagnosis1Id: number | null = null;
    public diagnosis2Id: number | null = null;
    public nhisReferral: NhisReferralDto = new NhisReferralDto();
}
