export enum PisFileTypeCode {
    PatientList = 'PatientList',
    ExamClaim = 'ExamClaim',
    RiskAssessmentReport = 'RiskAssessmentReport',
    LaboratoryReport = 'LaboratoryReport',
    FiscalReceiptReport = 'FiscalReceiptReport',
    FiscalReport = 'FiscalReport',
    Invoice = 'Invoice',
    CreditNote = 'CreditNote',
    DebitNote = 'DebitNote'
}
