<template>
    <v-card>
        <v-card-title>Потребителски настройки</v-card-title>
        <v-card-text>
            <v-row class="align-baseline">
                <v-col cols="auto"><v-checkbox v-model="isDarkTheme" label="Тъмна тема"></v-checkbox></v-col>
                <v-col cols="auto"><v-checkbox v-model="buttonsAreOutlined" label="Леки бутони"></v-checkbox></v-col>
                <v-col cols="auto"
                    ><v-checkbox v-model="hasReferralsBackground" label="Фон на бланки"></v-checkbox
                ></v-col>
                <v-col cols="auto"
                    ><v-checkbox
                        v-model="useMinutesBetweenVisits"
                        label="Проверка за минути между прегледи"
                    ></v-checkbox
                ></v-col>
                <v-col cols="auto">
                    <text-field v-model="scaleRatio" type="number" :min="0.1" :max="2" required label="Мащабиране" />
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-title>Цветова легенда</v-card-title>
        <v-card-text>
            <v-row class="text-center">
                <v-col cols="3">
                    <div class="primary">Главен</div>
                    <div class="primary2">&nbsp;</div>
                    <div class="primary3">&nbsp;</div>
                </v-col>
                <v-col cols="3">
                    <div class="secondary">Второстепенен</div>
                    <div class="secondary2">&nbsp;</div>
                    <div class="secondary3">&nbsp;</div>
                </v-col>
                <v-col cols="3">
                    <div class="accent">Акцент</div>
                    <div class="accent2">&nbsp;</div>
                    <div class="accent3">&nbsp;</div>
                </v-col>
            </v-row>
            <v-row class="text-center">
                <v-col cols="3">
                    <div class="success">Успех</div>
                    <div class="success2">&nbsp;</div>
                    <div class="success3">&nbsp;</div>
                </v-col>
                <v-col cols="3">
                    <div class="info">Информация</div>
                    <div class="info2">&nbsp;</div>
                    <div class="info3">&nbsp;</div>
                </v-col>
                <v-col cols="3">
                    <div class="warning">Предупреждение</div>
                    <div class="warning2">&nbsp;</div>
                    <div class="warning3">&nbsp;</div>
                </v-col>
                <v-col cols="3">
                    <div class="error">Грешка</div>
                    <div class="error2">&nbsp;</div>
                    <div class="error3">&nbsp;</div>
                </v-col>
            </v-row>
            <v-row class="text-center">
                <v-col cols="3"><div class="titleBar">Заглавна лента</div></v-col>
                <v-col cols="3"><div class="mainMenu">Главно меню</div></v-col>
                <v-col cols="3"><div class="contrast2 contrast--text">Контрастен текст</div></v-col>
            </v-row>
        </v-card-text>

        <v-card-title>Примерни пояснения</v-card-title>
        <v-card-text>
            <v-row>
                <v-col class="pb-0"><alert type="success">Успех</alert></v-col>
                <v-col class="pb-0"><alert type="info">Информация</alert></v-col>
                <v-col class="pb-0"><alert type="warning">Внимание</alert></v-col>
                <v-col class="pb-0"><alert type="error">Проблем</alert></v-col>
            </v-row>
            <v-row>
                <v-col><alert type="success" prominent>Успех</alert></v-col>
                <v-col><alert type="info" prominent>Информация</alert></v-col>
                <v-col><alert type="warning" prominent>Внимание</alert></v-col>
                <v-col><alert type="error" prominent>Проблем</alert></v-col>
            </v-row>
        </v-card-text>

        <v-card-title>Примерни бутони</v-card-title>
        <v-card-text>
            <v-row>
                <template v-for="action of Object.keys(actions)">
                    <v-col
                        v-if="actions[action].icon"
                        :key="action"
                        cols="auto"
                        :class="action === 'Email' ? 'titleBar' : ''"
                    >
                        <btn icon :action="action" block>{{ actions[action].defaultTitle ?? action }}</btn>
                    </v-col>
                </template>
            </v-row>
            <v-row>
                <v-col
                    v-for="action of Object.keys(actions)"
                    :key="action"
                    cols="6"
                    md="4"
                    xl="3"
                    :class="action === 'Email' ? 'titleBar' : ''"
                >
                    <btn :action="action" block>{{ actions[action].defaultTitle ?? action }}</btn>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-title>Примерни текстови полета</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="3"><text-field v-model="value" label="Текстово поле" hint="Подсказка"></text-field></v-col>
                <v-col cols="3">
                    <text-field
                        v-model="value"
                        required
                        label="Задължително текстово поле"
                        hint="Подсказка"
                    ></text-field>
                </v-col>
                <v-col cols="3">
                    <text-field
                        v-model="value"
                        readonly
                        label="Текстово поле само за четене"
                        hint="Подсказка"
                    ></text-field>
                </v-col>
                <v-col cols="3">
                    <text-field v-model="value" disabled label="Забранено текстово поле" hint="Подсказка"></text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3">
                    <text-field
                        v-model="numericValue"
                        type="number"
                        :min="2"
                        :max="5"
                        label="Числово поле"
                        hint="от 2 до 5"
                    ></text-field>
                </v-col>
                <v-col cols="3">
                    <text-field
                        v-model="numericValue"
                        type="number"
                        required
                        :min="1"
                        :max="999"
                        label="Задължително числово поле"
                        hint="от 1 до 999"
                    ></text-field>
                </v-col>
                <v-col cols="3">
                    <text-field
                        v-model="numericValue"
                        type="number"
                        readonly
                        label="Числово поле само за четене"
                        hint="Подсказка"
                    ></text-field>
                </v-col>
                <v-col cols="3">
                    <text-field
                        v-model="numericValue"
                        type="number"
                        disabled
                        label="Забранено числово поле"
                        hint="Подсказка"
                    ></text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <search-field
                        v-model="vSearch"
                        label="Поле за търсене"
                        hint="Подсказка"
                        @search="onSearch"
                        @input="onInput"
                    ></search-field>
                </v-col>
                <v-col cols="3">{{ stext }}</v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import TextField from '@/component/Common/TextField.vue';
    import { actions } from '@/model/Common/Action';
    import { userSettingsState } from '@/store/User/UserSettingsState';

    const initialNumericValue = 100;

    @Component({
        components: { TextField }
    })
    export default class UserSettings extends Vue {
        private get isDarkTheme() {
            return userSettingsState.userSettings.ui.isDarkTheme;
        }

        private set isDarkTheme(newValue: boolean) {
            userSettingsState.setDarkTheme(newValue);
        }

        private get buttonsAreOutlined() {
            return userSettingsState.userSettings.ui.buttonsAreOutlined;
        }

        private set buttonsAreOutlined(newValue: boolean) {
            userSettingsState.setOutlinedButtons(newValue);
        }

        private get hasReferralsBackground() {
            return userSettingsState.userSettings.ui.hasReferralsBackground;
        }

        private set hasReferralsBackground(newValue: boolean) {
            userSettingsState.setReferralsBackground(newValue);
        }

        private get useMinutesBetweenVisits() {
            return userSettingsState.userSettings.ui.useMinutesBetweenVisits;
        }

        private set useMinutesBetweenVisits(newValue: boolean) {
            userSettingsState.setMinutesBetweenVisits(newValue);
        }

        private get scaleRatio() {
            return userSettingsState.userSettings.ui.scaleRatio;
        }

        private set scaleRatio(newValue: number | null) {
            userSettingsState.setScaleRatio(newValue ?? 1);
        }

        private onSearch(val: string) {
            this.stext = `Търсене по:${val}`;
        }

        private onInput(val: string) {
            this.stext = `Текст: ${val}`;
        }

        private get actions() {
            return actions;
        }

        private value: string = 'Някакъв текст';
        private numericValue: number | null = initialNumericValue;
        private vSearch: string = '';
        private stext: string = '';
    }
</script>
